export const adminReleasesForm = {
	urlGET: "api/releases/:releaseId",
	urlPOST: "api/releases/",
	urlDELETE: "api/releases/:releaseId",
	urlPUT: "api/releases/:releaseId",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	idKey: "_id",
	header: {
		create: { label: "Add New Release" },
		edit: { label: "Edit Release" },
		delete: { label: "Delete Release" },
		view: { label: "View Release" },
	},
	messages: {
		delete: { success: "Release deleted.", disabled: false },
		create: { success: "Release added.", disabled: false },
		edit: { success: "Release updated.", disabled: false },
	},
	content: {
		edit: {
			items: [
				{ key: "version", type: "number", label: "Version" },
				{
					key: "status",
					type: "select",
					label: "Status",
					options: [
						{
							label: "Published",
							value: "PUBLISHED",
						},
						{
							label: "Draft",
							value: "DRAFT",
						},
					],
				},
				{ key: "notes", type: "text", label: "Release Notes", multiline: { rows: 10 } },
				{
					type: "action",
					action: "SUBMIT",
				},
			],
		},
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to permanently delete this Release?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};

export default adminReleasesForm;
