import { useState, useMemo } from "react";
import { Tab, Tabs } from "@material-ui/core";

function SmarthopQuickActionView(props) {
	const onChange = props.onChange;
	const config = props.config;
	const requestData = props.requestData;

	const items = useMemo(() => {
		const items = [];
		config.items?.forEach((item) => {
			items.push(item);
		});

		return items;
		// eslint-disable-next-line
	}, [config]);

	const [tab, setTab] = useState(0);

	const indexSelected = useMemo(() => {
		let selectedIndex = -1;
		items.forEach((item, i) => {
			let match = true;
			Object.keys(item?.filters ?? {}).forEach((key) => {
				if (item?.filters?.[key] !== requestData?.filters?.[key]) {
					match = false;
				}
			});

			if (match) {
				selectedIndex = i;
			}
		});

		if (selectedIndex !== tab) {
			setTab(selectedIndex);
			return selectedIndex;
		} else {
			return tab;
		}
		// eslint-disable-next-line
	}, [requestData, tab]);

	const handleOnChange = (i) => {
		setTab(i);
		const newRequest = {
			filters: { ...(requestData.filters ?? {}), ...(items[i].filters ?? {}) },
			groups: { ...(requestData.groups ?? {}), ...(items[i].groups ?? {}) },
			sort: { ...(requestData.sort ?? {}), ...(items[i].sort ?? {}) },
			page: { offset: 0, size: 10 },
		};

		// Deleting groups values that are set to 'false'
		Object.keys(newRequest.groups).forEach((key) => {
			if (!newRequest.groups[key]) {
				delete newRequest.groups[key];
			}
		});

		onChange(newRequest);
	};

	return (
		<div className="flex flex-col w-full">
			<Tabs value={indexSelected} classes={{ root: "min-w-120 min-h-36" }} onChange={(e, v) => handleOnChange(v)}>
				<Tab
					key={"tab_" + items.length}
					classes={{ root: "min-w-0 min-h-36" }}
					className="text-13 p-0"
					label={""}
					value={-1}
				/>
				{items.map((item, i) => (
					<Tab
						key={"tab_" + i}
						classes={{ root: "min-w-120 min-h-36 px-28" }}
						className="text-13 p-0"
						label={item.label}
						value={i}
					/>
				))}
			</Tabs>
			<div key="presetsView" className="w-full flex flex-col bg-grey-300 h-1"></div>
		</div>
	);
}

export default SmarthopQuickActionView;
