// Constans
import { type_user } from "constants.js";

const users = ({ minified = false, accountSubset = false, profileSubset = false, isView = false } = {}) => {
	const filters = [
		{
			key: "accountStatus",
			type: "select",
			label: "Account Status",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					label: "Active",
					value: "active",
				},
				{
					label: "Inactive",
					value: "inactive",
				},
			],
		},
		{
			key: "roles",
			type: "select",
			label: "Role",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					value: "__CONTAINS_INT__0",
					label: "Administrator",
				},
				{
					value: "__CONTAINS_INT__1",
					label: "Ops Dispatcher",
				},
				{
					value: "__CONTAINS_INT__12",
					label: "Ops Support",
				},
				{
					value: "__CONTAINS_INT__2",
					label: "Carrier Owner",
				},
				{
					value: "__CONTAINS_INT__21",
					label: "Carrier Driver",
				},
				{
					value: "__CONTAINS_INT__22",
					label: "Carrier Manager",
				},
				{
					value: "__CONTAINS_INT__23",
					label: "Carrier Dispatcher",
				},
				{
					value: "__CONTAINS_INT__27",
					label: "Carrier User",
				},
				{
					value: "__CONTAINS_INT__9",
					label: "Bot",
				},
			],
		},
		{
			key: "online",
			type: "select",
			label: "Online",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					value: "ONLINE",
					label: "Online",
				},
			],
		},
		{
			key: "phoneConfirmation",
			type: "select",
			label: "Has Confirm Phone",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					value: "__PHONE_YES__",
					label: "Yes",
				},
				{
					value: "__PHONE_NO__",
					label: "No",
				},
			],
		},
		{
			key: "emailConfirmation",
			type: "select",
			label: "Has Confirm Email",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					value: "__EMAIL_YES__",
					label: "Yes",
				},
				{
					value: "__EMAIL_NO__",
					label: "No",
				},
			],
		},
		{
			key: "staging_server_access",
			type: "select",
			label: "Staging Server On",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					value: "__ACCESS_YES__",
					label: "Yes",
				},
				{
					value: "__ACCESS_NO__",
					label: "No",
				},
			],
		},
		{
			key: "position",
			type: "select",
			label: "Position",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					label: "CAM",
					value: "CAM",
				},
				{
					label: "FAM",
					value: "FAM",
				},
				{
					label: "CAS",
					value: "CAS",
				},
				{
					label: "BA",
					value: "BA",
				},
				{
					label: "AHBA",
					value: "AHBA",
				},
				{
					label: "ODBA",
					value: "ODBA",
				},
			],
		},
		{
			key: "manager",
			type: "autocomplete",
			label: "Managed By",
			autocomplete: {
				provider: "smarthop",
				url: "api/profile/users",
				preloadDefault: true,
			},
		},
	];

	return {
		urlGET: accountSubset
			? "api/profile/accounts/:accountId/users"
			: profileSubset
			? "api/profile/profiles/:profileId/users"
			: "api/profile/users",
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "INTERNAL_USERS",
		cache: {
			key: accountSubset ? "profile_users_:accountId" : profileSubset ? "profile_users_:profileId" : null,
		},
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: ["email", "created_at", "first_name", "last_name"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "created_at",
					dir: "desc",
				},
			},
			defaults:
				profileSubset || accountSubset
					? {}
					: {
							initial: {
								filters: {
									accountStatus: "__NOT_SELECTED__",
								},
							},
							items: [
								{
									label: "All",
									description: "All users",
									filters: {
										accountStatus: "__NOT_SELECTED__",
									},
								},
								{
									label: "Active",
									description: "Active users",
									filters: {
										accountStatus: "active",
									},
								},
								{
									label: "Inactive",
									description: "Inactive users",
									filters: {
										accountStatus: "inactive",
									},
								},
							],
					  },
			filters: {
				search: !minified,
				items: minified || profileSubset || accountSubset ? [] : filters,
			},
			groups: {
				items:
					minified || profileSubset || accountSubset
						? []
						: [
								{
									key: "internal",
									type: "checkbox",
									label: "Show Internal",
								},
								{
									key: "gatekeepers",
									type: "checkbox",
									label: "Show Gatekeepers",
								},
								{
									key: "position",
									type: "checkbox",
									label: "Show Manager and Position",
								},
						  ],
			},
			items: [
				{ key: "_id", type: "id", label: "ID", group: "internal" },
				{ key: "role", type: "text", label: "Role", group: "internal" },
				{ key: "roleType", type: "text", label: "Type", group: "internal" },
				{ key: "ip", type: "text", label: "Auth IP", group: "internal" },
				{ key: "ip_api", type: "text", label: "API IP", group: "internal" },
				{ key: "accessAt", type: "date", label: "Access At", group: "internal" },
				{
					key: "creator__view",
					type: "user__view",
					label: "Creator",
					viewConfig: {
						showEmail: true,
					},
					group: "internal",
				},
				{
					key: "user__view",
					type: "user__view",
					label: "User",
					viewConfig: {
						showEmail: true,
						showOnline: profileSubset || accountSubset ? false : true,
						showConfirmedEmail: profileSubset || accountSubset ? false : true,
						showConfirmedPhone: profileSubset || accountSubset ? false : true,
						showAccountStatus: true,
					},
				},
				{
					key: "carrier__view",
					type: "carrier__view",
					label: "Carrier",
					viewConfig: {
						showMcNumber: true,
						showStatus: true,
						showTestCarrier: true,
						showCarrierAccountType: true,
					},
				},
				{
					key: "position",
					type: "text",
					label: "Position",
					group: profileSubset || accountSubset ? "hidden" : "position",
				},
				{
					key: "manager__view",
					type: "user__view",
					label: "Manager",
					viewConfig: {
						showEmail: true,
					},
					group: profileSubset || accountSubset ? "hidden" : "position",
				},
				{ key: "created_at", type: "date", label: "Created" },
				{ key: "first_name", type: "text", label: "First Name" },
				{ key: "last_name", type: "text", label: "Last Name" },
				{
					key: "roles",
					builder: (item) => {
						let labels = "";
						item?.roles?.forEach((role) => {
							let found = type_user.find((item) => item.value === role);
							let label = found?.label ? found.label : "Role " + role;
							if (labels) labels += ", ";
							labels += label;
						});
						return labels;
					},
					type: "text",
					label: "Role",
				},

				// Gatekeepers

				{
					key: "gatekeepers.permission_rates_on",
					type: "bool",
					label: "Rates On",
					group: "gatekeepers",
				},
				{
					key: "gatekeepers.permission_messaging_on",
					type: "bool",
					label: "Messaging On",
					group: "gatekeepers",
				},
				{
					key: "gatekeepers.permission_search_on",
					type: "bool",
					label: "Search On",
					group: "gatekeepers",
				},
				{
					key: "gatekeepers.permission_book_on",
					type: "bool",
					label: "Book On",
					group: "gatekeepers",
				},
				{
					key: "gatekeepers.has_staging_server_access",
					type: "bool",
					label: "Stagging Server On",
					group: "gatekeepers",
				},
				// Menu
				...(minified || profileSubset || accountSubset
					? []
					: [
							{
								key: "menu",
								type: "menu",
								label: "Menu",
								items: [
									{
										key: "socket",
										label: "Socket Action",
										dialogBuilder: (item, dataIds) => ({
											formId: "ADMIN_SOCKET_FORM",
											mode: "CREATE",
											dataIds: {
												id: item?._id,
											},
										}),
									},
								],
							},
					  ]),
			],
		},
	};
};

export default users;
