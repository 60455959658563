import { Typography } from "@material-ui/core";

const BANNER_EXTERNAL_BROKER = (isExternalUser) => {
	return {
		key: "external_broker_banner",
		type: "banner",
		label: `${isExternalUser ? "You have" : "The carrier has"} overwritten the information of this broker`,
		banner: {
			icon: "info",
			classes: { icon: "text-blue-600" },
		},
	};
};

const BANNER_SMARTPAY_EXTERNAL_BROKER = {
	key: "smartpay_banner",
	type: "banner",
	label: "Some conditions apply",
	banner: {
		icon: "info",
		classes: { icon: "text-blue-600" },
	},
};

const BANNER_WARNING_EXTERNAL_EDIT = {
	key: "smartpay_banner",
	type: "banner",
	label: "You are editing a carrier's customer info, be careful",
	banner: {
		icon: "warning",
		classes: { root: "-mt-6 bg-orange-100", icon: "text-orange-600" },
	},
};

const CARRIER_SELECTOR = {
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
		params: {
			options_active_only: true,
			options_onboarding_completed: true,
		},
	},
};

const COMPANY_NAME = {
	key: "clientid",
	type: "text",
	label: "Company Name",
	translate: "COMPANY_NAME",
	minCharacter: 1,
	maxCharacter: 60,
	required: true,
};

const COMPANY_MCNUMBER = {
	key: "mcnumber",
	type: "number",
	label: "MC #",
	translate: "MC_NUMBER",
	required: true,
};

const COMPANY_CONTACT_NAME = {
	key: "contact_name",
	type: "text",
	label: "Contact Name",
	translate: "BROKER_CONTACT",
};

const COMPANY_CONTACT_PHONE = {
	key: "contact",
	type: "text",
	label: "Phone",
	translate: "OFFICE_PHONE",
};

const COMPANY_CONTACT_EMAIL = {
	key: "email",
	type: "email",
	label: "Email",
	translate: "BROKER_EMAIL",
};

const CUSTOMER_EIN = {
	key: "customerId",
	type: "number",
	label: "EIN",
};

const CUSTOMER_EXTRA_CONTACTS = {
	key: "extraContacts",
	type: "pattern",
	label: "Extra Contacts",
	addButton: { label: "Add Contact" },
	pattern: {
		classes: { label: "font-semibold text-14 ml:text-15 tracking-wide text-grey-A800" },
		showAllDefault: true,
		variant: "large",
	},
	content: {
		items: [
			{
				type: "group",
				content: {
					items: [
						{
							key: "contact",
							type: "text",
							label: "Contact Name",
							required: true,
						},
						{
							key: "phone",
							type: "text",
							maxCharacter: 20,
							label: "Phone",
						},
						{
							key: "email",
							type: "email",
							label: "Email",
						},
					],
				},
			},
		],
	},
};

const BROKER_CREDIT_SCORE = {
	key: "creditScore",
	type: "number",
	label: "Credit Score",
	translate: "CREDIT_SCORE",
	min: 0,
	max: 100,
	field: { decimals: 0 },
};

const BROKER_CREDIT_SCORE_SOURCE = {
	key: "creditScoreSource",
	type: "select",
	label: "Credit Score Source",
	translate: "CREDIT_SCORE_SOURCE",
	options: [
		{
			value: "DAT",
			label: "DAT",
		},
		{
			value: "Smarthop",
			label: "Smarthop",
		},
	],
};

const BROKER_POSSIBLE_SCAMMER = {
	key: "possible_scammer",
	type: "checkbox",
	label: "Possible scammer",
	translate: "POSSIBLE_SCAMMER",
};

const BROKER_NET_PAYMENT_TERM = {
	key: "net_payment_term",
	type: "number",
	label: "Net Payment Term",
	defaultValue: 30,
	min: 0,
	max: 99,
	description: "0 to 99 days",
};

const SMARTPAY_ENABLED = {
	key: "smartpayEnabled",
	type: "checkbox",
	label: "SmartPay eligible",
	translate: "SMARTPAY_ENABLED",
};

const SMARTPAY_COLLECTION_LIMIT = {
	key: "smartpayCollectionLimit",
	type: "currency",
	label: "Collection Limit",
	translate: "SMARTPAY_COLLECTION_LIMIT",
	min: 1,
	rules: {
		disabled: {
			ifEmptyRef: "smartpayEnabled",
		},
		required: { fieldKey: "smartpayEnabled", equalsTo: true },
	},
};

const SMARTPAY_EMAIL = {
	key: "smartpayEmail",
	type: "email",
	label: "Broker email",
	translate: "SMARTPAY_EMAIL",
	rules: {
		disabled: {
			ifEmptyRef: "smartpayEnabled",
		},
	},
};

const AUTO_PARSING_ENABLED = {
	key: "automated_parsing_supported",
	type: "checkbox",
	label: "Automated Parsing Supported",
};

const PROCESS_BROKER_PARTNER = {
	key: "partner",
	type: "checkbox",
	label: "Partner",
	translate: "PARTNER",
};

const PROCESS_ONBOARDING_METHOD = {
	key: "onboarding_method",
	type: "select",
	label: "Onboarding Method",
	options: [
		{
			value: "rmis",
			label: "RMIS",
			translate: "RMIS",
		},
		{
			value: "MyCarrierPackage",
			label: "MyCarrierPackage",
			translate: "MY_CARRIER_PACKAGE",
		},
		{
			value: "DAT Onboard",
			label: "DAT Onboard",
			translate: "DAT_ONBOARD",
		},
		{
			value: "Internal",
			label: "Internal",
			translate: "INTERNAL",
		},
		{
			value: "PDF to email",
			label: "PDF to email",
			translate: "PDF_TO_EMAIL",
		},
		{
			value: "Mcleod",
			label: "Mcleod",
			translate: "MCLEOD",
		},
		{
			value: "Other",
			label: "Other",
			translate: "OTHER",
		},
	],
};

const PROCESS_ONBOARDING_PHONE = {
	key: "onboarding_phone",
	type: "text",
	label: "Onboarding Phone",
	translate: "ONBOARDING_PHONE",
};

const PROCESS_ONBOARDING_EMAIL = {
	key: "onboarding_email",
	type: "text",
	label: "Onboarding Email",
	translate: "ONBOARDING_EMAIL",
};

const PROCESS_INTEGRATED = {
	key: "integrated",
	type: "select",
	label: "Integrated",
	options: [
		{
			value: "DIRECTLY",
			label: "Yes (Directly)",
			translate: "DIRECTLY",
		},
		{
			value: "PARADE",
			label: "Yes (Parade)",
			translate: "PARADE",
		},
		{
			value: "NEWTRUL",
			label: "Yes (Newtrul)",
			translate: "NEWTRUL",
		},
		{
			value: "NO",
			label: "No",
			translate: "NO",
		},
	],
};

const PROCESS_AFTEROFFICE = {
	key: "after_office",
	type: "checkbox",
	label: "Work After Hours?",
	translate: "WORK_AFTER_HOURS",
};

const PROCESS_CARRIER_REP_EMAIL = {
	key: "carrier_rep_email",
	type: "text",
	label: "Carrier Rep. Team Email",
	translate: "CARRIER_REP_TEAM_EMAIL",
};

const PROCESS_CARRIER_REP_PHONE = {
	key: "carrier_rep_phone",
	type: "text",
	label: "Carrier Rep. Team Phone",
	translate: "CARRIER_REP_TEAM_PHONE",
};

const PROCESS_AFTERHOUR_PHONE = {
	key: "after_hours_telf",
	type: "text",
	label: "After Hours Phone Number",
	translate: "AFTER_HOURS_PHONE",
};

const PROCESS_AFTERHOUR_EMAIL = {
	key: "after_hours_email",
	type: "text",
	label: "After Hours Email",
	translate: "AFTER_HOURS_EMAIL",
};

const REQUIREMENT_MC_LENGTH = {
	key: "mc_age",
	type: "select",
	label: "MC length requirement",
	options: [
		{
			value: 0,
			label: "0 days",
			translate: "DAYS_0",
		},
		{
			value: 30,
			label: "30 days",
			translate: "DAYS_30",
		},
		{
			value: 60,
			label: "60 days",
			translate: "DAYS_60",
		},
		{
			value: 90,
			label: "90 days",
			translate: "DAYS_90",
		},
		{
			value: 120,
			label: "120 days",
			translate: "DAYS_120",
		},
		{
			value: 150,
			label: "150 days",
			translate: "DAYS_150",
		},
		{
			value: 180,
			label: "180 days",
			translate: "DAYS_180",
		},
		{
			value: 365,
			label: "365 days",
			translate: "DAYS_365",
		},
	],
};

const REQUIREMENT_AUTO_LIABILITY = {
	key: "auto_liability_insurance_amount",
	type: "number",
	label: "Auto Liability Insurance Amount",
	translate: "AUTO_LIABILITY_INSURANCE_AMOUNT",
};

const REQUIREMENT_CARGO_INSURANCE = {
	key: "cargo_insurance_amount",
	type: "number",
	label: "Cargo Insurance Amount",
	translate: "CARGO_INSURANCE_AMOUNT",
};

const REQUIREMENT_QUICKPAY = {
	key: "quick_pay_active",
	type: "checkbox",
	label: "Quick Pay",
};

const REQUIREMENT_QUICKPAY_PERCENTAGE = {
	key: "quick_pay",
	type: "number",
	label: "%",
	translate: "PERCENTAGE",
	rules: {
		disabled: {
			ifEmptyRef: "quick_pay_active",
		},
	},
};

const REQUIREMENT_STANDARD_PAY = {
	key: "standard_pay_active",
	type: "checkbox",
	label: "Standard Pay",
	translate: "DAYS",
};
const REQUIREMENT_STANDARD_PAY_DAYS = {
	key: "standard_pay",
	type: "number",
	label: "Days",
	translate: "DAYS",
	rules: {
		disabled: {
			ifEmptyRef: "standard_pay_active",
		},
	},
};

const MAIN_COMPANY_NAME = {
	key: "main_clientid",
	type: "text",
	label: "Company Name",
	translate: "COMPANY_NAME",
};

const MAIN_COMPANY_CONTACT_NAME = {
	key: "main_contact_name",
	type: "text",
	label: "Contact Name",
	translate: "BROKER_CONTACT",
};

const MAIN_COMPANY_CONTACT_PHONE = {
	key: "main_contact",
	type: "text",
	label: "Phone",
	translate: "OFFICE_PHONE",
};

const MAIN_COMPANY_CONTACT_EMAIL = {
	key: "main_email",
	type: "email",
	label: "Email",
	translate: "BROKER_EMAIL",
};

const MAIN_BROKER_NET_PAYMENT_TERM = {
	key: "main_net_payment_term",
	type: "number",
	label: "Net Payment Term",
};

const createBrokerEditFrom = (
	{ isExternalBroker, isSmartpayEnabled, isExternalUser, isBrokerOverride, hasSubaccounts } = {},
	{ mode } = {}
) => {
	const showForExternal = isExternalBroker || isExternalUser;

	if (mode === "DELETE") {
		return {
			noInitValidation: true,
			items: [
				{
					key: "message",
					type: "message",
					label: "Would you like to delete this customer?",
					message: { classes: { root: " mt-10 pb-12 -mb-1" } },
				},
				{
					type: "group",
					group: { classes: { root: "mt-10" } },
					content: {
						items: [
							{ key: "cancel", type: "action", action: "CANCEL" },
							{ key: "action", type: "action", action: "DELETE", label: "Continue", button: { alwaysEnabled: true } },
						],
					},
				},
			],
		};
	} else if (mode === "VIEW") {
		return {
			banner: {
				items: [...(isBrokerOverride ? [BANNER_EXTERNAL_BROKER(isExternalUser)] : [])],
			},
			summary: {
				items: [COMPANY_NAME, COMPANY_MCNUMBER, ...(isExternalBroker ? [CUSTOMER_EIN] : [])],
			},
			contactInformation: {
				items: [COMPANY_CONTACT_NAME, COMPANY_CONTACT_PHONE, COMPANY_CONTACT_EMAIL],
			},
			credit: {
				items: [
					...(!showForExternal
						? [BROKER_CREDIT_SCORE, BROKER_CREDIT_SCORE_SOURCE]
						: [BROKER_NET_PAYMENT_TERM, BROKER_CREDIT_SCORE]),
				],
			},
			smartpay: {
				items: [
					...(isSmartpayEnabled && !isExternalUser ? [SMARTPAY_COLLECTION_LIMIT, SMARTPAY_EMAIL] : []),
					...(isSmartpayEnabled && isExternalUser ? [BANNER_SMARTPAY_EXTERNAL_BROKER] : []),
				],
			},
			mainBroker: {
				items: [
					MAIN_COMPANY_NAME,
					MAIN_COMPANY_CONTACT_NAME,
					MAIN_COMPANY_CONTACT_PHONE,
					MAIN_COMPANY_CONTACT_EMAIL,
					BROKER_CREDIT_SCORE,
					MAIN_BROKER_NET_PAYMENT_TERM,
				],
			},
		};
	} else if (mode === "COMPARE") {
		return {
			items: [
				{
					type: "group",
					content: {
						items: [COMPANY_NAME, COMPANY_MCNUMBER],
					},
				},
				{
					type: "group",
					content: {
						items: [BROKER_CREDIT_SCORE, BROKER_CREDIT_SCORE_SOURCE],
					},
				},
				{
					type: "group",
					content: {
						items: [SMARTPAY_COLLECTION_LIMIT, SMARTPAY_ENABLED],
					},
				},
			],
		};
	}

	return {
		noInitValidation: true,
		items: [
			...(isExternalBroker && !isExternalUser ? [BANNER_WARNING_EXTERNAL_EDIT] : []),
			...(isExternalBroker && hasSubaccounts
				? [
						{
							type: "group",
							content: {
								items: [CARRIER_SELECTOR, { type: "stub" }],
							},
						},
				  ]
				: []),
			{
				key: "companyToggle",
				type: "toggle",
				label: "Broker information",
				toggle: {
					icon: "business",
					defaultVisible: true,
				},
				summary: (data) => {
					const text = data.mcnumber ? `MC ${data.mcnumber}` : "-";
					return (
						<Typography className={"text-12 " + (text.length > 0 ? " text-grey " : " text-grey ")}>
							{text.length > 0 ? text : "Not Selected"}
						</Typography>
					);
				},
				content: {
					items: [
						{
							type: "group",
							content: {
								items: [COMPANY_NAME, COMPANY_MCNUMBER],
							},
						},
						...(isExternalBroker || isExternalUser
							? [
									{
										type: "group",
										content: {
											items: [CUSTOMER_EIN, { type: "stub" }],
										},
									},
							  ]
							: []),
					],
				},
			},
			{
				key: "companyMainContactToggle",
				type: "toggle",
				label: "Contact information",
				toggle: {
					icon: "call",
					defaultVisible: false,
				},
				summary: (data) => {
					const text = !data.contact_name && !data.contact && !data.email ? "Empty" : "";
					return (
						<Typography className={"text-12 " + (text.length > 0 ? " text-red-600 " : " text-grey ")}>
							{text.length > 0 ? text : ""}
						</Typography>
					);
				},
				content: {
					items: [
						{
							type: "group",
							content: {
								items: [COMPANY_CONTACT_NAME, COMPANY_CONTACT_PHONE, COMPANY_CONTACT_EMAIL],
							},
						},
						...(isExternalBroker || isExternalUser ? [CUSTOMER_EXTRA_CONTACTS] : []),
					],
				},
			},
			{
				key: "companyCreditToggle",
				type: "toggle",
				label: "Credit",
				toggle: {
					icon: "account_balance",
					defaultVisible: false,
				},
				summary: (data) => {
					let textCreditScore = data.creditScore ? `Score ${data.creditScore} ` : "";
					return (
						<Typography className={"text-12 " + (data.creditScore > 90 ? " text-green-600 " : " text-grey ")}>
							{textCreditScore.length > 0 ? textCreditScore : ""}
						</Typography>
					);
				},
				content: {
					items: [
						{
							type: "group",
							content: {
								items: [BROKER_NET_PAYMENT_TERM, !showForExternal ? BROKER_POSSIBLE_SCAMMER : { type: "stub" }],
							},
						},
						...(!showForExternal
							? [
									{
										type: "group",
										content: {
											items: [BROKER_CREDIT_SCORE, BROKER_CREDIT_SCORE_SOURCE],
										},
									},
							  ]
							: []),
					],
				},
			},
			...(!showForExternal
				? [
						{
							key: "smartpayToggle",
							type: "toggle",
							label: "SmartPay",
							toggle: {
								icon: "attach_money",
								defaultVisible: false,
							},
							summary: (data) => {
								let text = data.smartpayEnabled ? "Enabled" : "Disabled";
								return (
									<Typography className={"text-12 " + (text === "Enabled" ? " text-green-600 " : " text-grey ")}>
										{text}
									</Typography>
								);
							},
							content: {
								items: [
									{
										type: "group",
										content: {
											items: [SMARTPAY_ENABLED, { type: "stub" }],
										},
									},
									{
										type: "group",
										content: {
											items: [SMARTPAY_COLLECTION_LIMIT, SMARTPAY_EMAIL],
										},
									},
								],
							},
						},
				  ]
				: []),
			...(!showForExternal
				? [
						{
							key: "processToggle",
							type: "toggle",
							label: "Broker Process",
							toggle: {
								icon: "build_circle",
								defaultVisible: false,
							},
							content: {
								items: [
									{
										type: "group",
										content: {
											items: [PROCESS_BROKER_PARTNER, PROCESS_ONBOARDING_METHOD],
										},
									},
									{
										type: "group",
										content: {
											items: [PROCESS_ONBOARDING_PHONE, PROCESS_ONBOARDING_EMAIL],
										},
									},
									{
										type: "group",
										content: {
											items: [PROCESS_CARRIER_REP_PHONE, PROCESS_CARRIER_REP_EMAIL],
										},
									},
									{
										type: "group",
										content: {
											items: [PROCESS_INTEGRATED, PROCESS_AFTEROFFICE],
										},
									},
									{
										type: "group",
										content: {
											items: [PROCESS_AFTERHOUR_PHONE, PROCESS_AFTERHOUR_EMAIL],
										},
									},
								],
							},
						},
						{
							key: "requirementsToggle",
							type: "toggle",
							label: "Requirements",
							toggle: {
								icon: "playlist_add_check",
								defaultVisible: false,
							},
							content: {
								items: [
									{
										type: "group",
										content: [REQUIREMENT_MC_LENGTH, { type: "stub" }],
									},
									{
										type: "group",
										content: {
											items: [REQUIREMENT_AUTO_LIABILITY, REQUIREMENT_CARGO_INSURANCE],
										},
									},
									{
										type: "group",
										content: {
											items: [REQUIREMENT_QUICKPAY, REQUIREMENT_QUICKPAY_PERCENTAGE],
										},
									},
									{
										type: "group",
										content: {
											items: [REQUIREMENT_STANDARD_PAY, REQUIREMENT_STANDARD_PAY_DAYS],
										},
									},
								],
							},
						},
						{
							key: "automationToggle",
							type: "toggle",
							label: "Automation",
							toggle: {
								icon: "precision_manufacturing",
								defaultVisible: false,
							},
							summary: (data) => {
								let text = data.automated_parsing_supported ? "Enabled" : "Disabled";
								return (
									<Typography className={"text-12 " + (text === "Enabled" ? " text-green-600 " : " text-grey ")}>
										{text}
									</Typography>
								);
							},
							content: {
								items: [
									{
										type: "group",
										content: {
											items: [AUTO_PARSING_ENABLED],
										},
									},
								],
							},
						},
				  ]
				: []),
			{ key: "action", type: "action" },
		],
	};
};

export { createBrokerEditFrom };
