import { Controller } from "react-hook-form";
import { useEffect } from "react";

import ReCAPTCHA from "react-google-recaptcha";

function SmarthopCaptchaField(props) {
	const onReset = props.onReset;
	let captcha;

	const setCaptchaRef = (ref) => {
		if (ref) {
			return (captcha = ref);
		}
	};

	useEffect(() => {
		captcha.reset();
		onReset();
		// eslint-disable-next-line
	}, [props.errors, captcha]);

	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field }) => {
				return (
					<div className="w-full flex items-center justify-center pt-8 pb-16">
						<ReCAPTCHA {...field} ref={(r) => setCaptchaRef(r)} sitekey={process.env.REACT_APP_RECAPTCHA} />
					</div>
				);
			}}
		/>
	);
}

export default SmarthopCaptchaField;
