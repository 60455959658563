import TermsView from "app/main/terms/TermsView";

function PrivacyPolicy() {
	return (
		<div className="flex-1 overflow-scroll p-10">
			<TermsView type={"PRIVACY_POLICY"} />
		</div>
	);
}

export default PrivacyPolicy;
