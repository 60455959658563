import { Typography } from "@material-ui/core";

const PlatformPlanHeader = (props) => {
	const isTrialAllowed = props.isTrialAllowed;
	const plan = props.plan;

	return (
		<>
			{/* plan trial */}
			<div className="text-center">
				{isTrialAllowed && plan.trialDays > 0 && (
					<>
						<Typography className="text-12 ml:text-13 text-green font-semibold tracking-tight" color="inherit">
							No charge for {plan.trialDays} days
						</Typography>
					</>
				)}
			</div>

			{/* title */}
			<div className="pb-14 ml:pb-16 text-center">
				<Typography variant="subtitle1" color="inherit" className="text-20 ml:text-24 font-semibold">
					{plan.nickname}
				</Typography>
			</div>
		</>
	);
};

export default PlatformPlanHeader;
