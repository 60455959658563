import { SMARTHOP_WALLET_SECTION_LABEL } from "app/main/utils/financeUtils";

const TITLE = "Improve your cashflow with pay at pickup";

const DESCRIPTION =
	"Receive 60% of your future earnings on pickups, so you will always have the funds to make the trip without taking on loans or interest";

const APPLY_BUTTON_TEXT = `Apply for ${SMARTHOP_WALLET_SECTION_LABEL} Account`;

const STEPS = [
	{
		title: "Activate Pay at Pickup",
		active: false,
		bullets: ["Apply for SmartHop Walet", "Redirect your factoring Deposit", "Enable Pay at Pickup"],
	},
	{
		title: "Book Your Load",
		active: false,
		bullets: ["Confirm your truck plan", "Find a load", "Receive your trip itinerary"],
	},
	{
		title: "Proof of Pickup",
		active: false,
		bullets: ["Pickup your load", "Receive the BOL", "Upload BOL in SmartHop App"],
	},
	{
		title: "Get Paid Fast!",
		active: true,
		bullets: ["SmartHop reviews and confirms BOL", "SmartHop imitates cash advance", "You receive funds immediately"],
	},
];

const TEXT_FONT_SIZE = "text-12 lg:text-14";

const TITLE_FONT_SIZE = "text-28 md:text-32";

const STEPS_FONT_SIZE = "text-14 md:text-16";

export { TITLE, DESCRIPTION, APPLY_BUTTON_TEXT, STEPS, TEXT_FONT_SIZE, TITLE_FONT_SIZE, STEPS_FONT_SIZE };
