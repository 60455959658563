import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { createMPIView } from "app/main/utils/brokerUtils";

function CardDestination(props) {
	const { destination, bestDestination, handleCheckLocation, index } = props;
	return (
		<>
			<Card variant="outlined" className="w-3/4">
				<CardContent className="p-10" style={{ paddingBottom: 10 }}>
					<div>
						<div className="flex">
							<Typography className="text-start text-12">{destination?.destination}</Typography>
							<Checkbox
								checked={bestDestination[index]?.value}
								className="-mt-10 ml-auto"
								size="small"
								onChange={(e) => handleCheckLocation(index, e)}
								disabled={
									!bestDestination[index]?.value &&
									bestDestination?.filter((destination) => destination?.value)?.length === 1
								}
							/>
						</div>
						<div className="flex">
							{createMPIView({ rank_mci: destination?.rank_mci }, true)}
							<Typography className="text-12 ml-auto">{`# loads ${destination.count}`}</Typography>
						</div>
					</div>
				</CardContent>
			</Card>
			<Typography
				className={
					"flex-nowrap text-14 md:text-12 self-center ml-10 " +
					(destination?.balanced_profit >= 0 ? "text-green-400" : "text-red-500")
				}
			>
				{`$${destination?.balanced_profit?.toFixed(2)}`}
			</Typography>
		</>
	);
}

export default CardDestination;
