const adminCouponsForm = {
	urlPOST: "api/coupons",
	urlPUT: "api/coupons/:id",
	urlDELETE: "api/coupons/:id",
	urlGET: "api/coupons/:id",
	idKey: "_id",
	triggerEvent: "couponRevision",
	listenEvent: "couponRevision",
	header: { create: { label: "Create coupon" }, delete: { label: "Delete coupon" }, edit: { label: "Edit coupon" } },
	content: {
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to permanently delete the coupon?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
							},
						],
					},
				},
			],
		},
		edit: { sameAs: "create" },
		create: {
			items: [
				{
					key: "coupon",
					type: "autocomplete",
					label: "Stripe Coupons",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/stripe-coupons",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{ key: "description", type: "text", label: "Description", required: true },
				{ key: "promotion_code", type: "text", label: "Code" },
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminCouponsForm;
