import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import PageWrapper from "./PageWrapper";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { getConfigurationOnboardingStatus } from "app/main/utils/onboardingUtils";
import { getCarrierId, getUserId, isDeactivatedCarrierStatus } from "app/services/LoginService";
import { Button } from "@material-ui/core";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";

function PageWarning(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const carrierId = getCarrierId();
	const userId = getUserId();

	const roleOnlyCarrier =
		user.rolesLabels.includes("carrier") ||
		user.rolesLabels.includes("carrier_driver") ||
		user.rolesLabels.includes("carrier_manager") ||
		user.rolesLabels.includes("carrier_dispatcher") ||
		user.rolesLabels.includes("carrier_generic") ||
		["2", "22", "23", "24", "27"].includes(localStorage.getItem("roleUser"));
	const roleCarrierOrCarrierManager =
		roleOnlyCarrier || user.rolesLabels.includes("driver") || ["21"].includes(localStorage.getItem("roleUser"));

	const configurationOnboardingStatus = useMemo(() => getConfigurationOnboardingStatus(user), [user]);
	const accountDeactivated = useMemo(() => isDeactivatedCarrierStatus(), []);
	const resourceInformation = getResourceByKey(props.resource);

	const row1 =
		props.type === "ACCESS_DENIED"
			? "You don't have access to this functionality"
			: props.type === "SUBSCRIBE_PLAN"
			? "Access to this page requires an active subscription"
			: props.type === "UPDATE_PLAN"
			? "Upgrade your subscription to access this functionality"
			: props.type === "MAX_SEARCHES_MC"
			? "You have reached your limit of searches without verifying ownership of your MC or DOT"
			: props.type === "PAYROLL_ACTIVATION"
			? "Payroll is now available! "
			: props.type === "ACTIVATION"
			? accountDeactivated
				? "Your account is suspended"
				: "Your account is being activated..."
			: props.type === "ONBOARDING"
			? "Welcome, and thanks for checking out the SmartHop app!"
			: props.type === "MAX_SEARCHES"
			? "You have reached your limit of free searches"
			: "Access Restricted";
	const row2P1 =
		props.type === "PAYROLL_ACTIVATION"
			? "Please contact our help desk (786) 558-0852 to schedule your demo and kickoff call."
			: props.type === "ACTIVATION"
			? accountDeactivated
				? "Please contact our team to re-activate your account."
				: "To access this functionality your account has to be activated, our team is in the process of validating your information and will get back to you shortly."
			: props.type === "ONBOARDING"
			? configurationOnboardingStatus?.componentInfo
			: props.type === "MAX_SEARCHES" || props.type === "MAX_SEARCHES_MC"
			? ""
			: "Access to this functionality is restricited to your account";

	const handledAction = (action) => {
		if (action === "email") {
			dispatch(openFormDialog({ viewId: "EMAIL_CONFIRM" }));
		} else if (action === "phone") {
			dispatch(openFormDialog({ viewId: "PHONE_CONFIRM" }));
		}
	};
	return (
		<PageWrapper
			title={props.title}
			classes={{
				...{ ...(props.classes ?? {}) },
				content:
					"w-full max-w-4xl flex flex-1 mx-auto px-20 overflow-hidden mb-96 " + (!props.title ? " mt-36 pb-1 " : ""),
			}}
		>
			<div
				className={
					"md:flex md:flex-col md:h-full flex-auto items-center justify-center flex-shrink-0 bg-zinc-400 " +
					(props.title?.length > 0 ? "  " : " pt-24 ")
				}
			>
				<div className={"flex items-center justify-center mt-60 bg-white border-2 rounded-lg mb-92"}>
					<CardContent className={"flex flex-col items-center justify-center w-full py-32 px-20 md:px-96 mb-10"}>
						<div className="flex items-center justify-center">
							<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
						</div>
						{resourceInformation && (
							<div className="flex items-center mt-24 mb-12 justify-center">
								<ResourceView
									type={"video"}
									description={resourceInformation?.description}
									link={resourceInformation?.link}
								/>
							</div>
						)}
						{props.type === "CONFIRMATION" ? (
							<>
								<Typography variant="h6" color="textSecondary" className="mt-10 mb-10 text-center font-light">
									Thanks for checking out Smarthop App!
								</Typography>
								<Typography variant="h6" color="textSecondary" className="mb-20 text-center font-light">
									{`In order to search a load you need to ${
										!user?.phone_confirmed && !user?.email_confirmed
											? "corfirm your email and phone"
											: !user?.phone_confirmed
											? "corfirm your phone"
											: "corfirm your email"
									}`}
								</Typography>
								{!user.email_confirmed && (
									<Link
										className="cursor-pointer mr-12"
										color="primary"
										onClick={() => {
											handledAction("email");
										}}
									>
										<Typography variant="h6" className="font-light underline whitespace-nowrap hover:text-orange-800">
											Please Confirm email
										</Typography>
									</Link>
								)}
								{!user.phone_confirmed && (
									<Link
										className="cursor-pointer mr-12"
										color="primary"
										onClick={() => {
											handledAction("phone");
										}}
									>
										<Typography variant="h6" className="font-light underline whitespace-nowrap hover:text-orange-800">
											Please Confirm phone
										</Typography>
									</Link>
								)}
							</>
						) : props.type === "UPDATE_PLAN" ||
						  props.type === "SUBSCRIBE_PLAN" ||
						  props.type === "MAX_SEARCHES" ||
						  props.type === "MAX_SEARCHES_MC" ? (
							<>
								<Typography variant="h6" color="textSecondary" className="mt-10 mb-10 text-center font-light">
									{row1}
								</Typography>
								{user.role === "CARRIER_OWNER" && !user?.hasParentCarrier ? (
									<Button
										className="tracking-wide min-w-120 mx-12 mt-6 text-14 font-medium"
										variant="contained"
										color="secondary"
										onClick={() =>
											dispatch(
												openLoadedFormDialog({
													...(props.type === "MAX_SEARCHES_MC"
														? { formId: "CARRIER_COMPANY_VALIDATE_ONLY_FORM", mode: "EDIT" }
														: props.type === "MAX_SEARCHES"
														? { viewId: "BILLING_PLAN_CHANGE" }
														: { viewId: "BILLING_PLAN_CHANGE" }),
													dataIds: { carrierId, userId, updateSoftPlan: true, restrictions: props.restrictions },
												})
											)
										}
									>
										{props.type === "SUBSCRIBE_PLAN"
											? "Subscribe Now"
											: props.type === "MAX_SEARCHES"
											? "Upgrade"
											: props.type === "MAX_SEARCHES_MC"
											? "Verify Now"
											: "Learn More"}
									</Button>
								) : roleOnlyCarrier ? (
									<Typography variant="h6" color="textSecondary" className="mt-10 mb-10 text-center font-light">
										Please contact account owner to upgrade current plan
									</Typography>
								) : null}
							</>
						) : (
							<>
								<Typography variant="h6" color="textSecondary" className="mt-10 mb-10 text-center font-light">
									{row1}
								</Typography>
								{roleCarrierOrCarrierManager ? (
									<Typography variant="h6" color="textSecondary" className="mb-20 text-center font-light">
										{row2P1}
										{configurationOnboardingStatus?.naviationgLink && props.type === "ONBOARDING" && (
											<NavLink
												exact
												to={configurationOnboardingStatus?.naviationgLink?.url}
												style={{ textDecoration: "none" }}
											>
												<span className="text-blue-700 pr-3 pl-3 font-medium">
													{configurationOnboardingStatus?.naviationgLink?.text}
												</span>
											</NavLink>
										)}
									</Typography>
								) : (
									<Typography variant="h6" color="textSecondary" className="mb-20 text-center font-light">
										This functionality is restricted to your account
									</Typography>
								)}
							</>
						)}
					</CardContent>
				</div>
			</div>
		</PageWrapper>
	);
}

export default PageWarning;
