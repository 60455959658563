import moment from "moment";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { TextField, Button, IconButton, Menu } from "@material-ui/core";
import { DateRange } from "react-date-range";
import ClearIcon from "@material-ui/icons/Clear";
import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";

import { NARROW_FIELDS } from "@fuse/default-settings";

const styles = {
	datePicker: {
		// position: "absolute",
		// zIndex: 99,
		// top: "60px",
	},
	backdrop: {
		zIndex: 98,
	},
};

const parseValue = (rawText) => {
	if (!rawText) return { key: "selection" };
	const [startDate, endDate] = rawText.split("__DATE_RANGE__").map((d) => moment(parseInt(d)).toDate());
	return {
		key: "selection",
		startDate,
		endDate,
	};
};

function SmarthopDateRangeField({
	label,
	name,
	value,
	onChangeCommitted,
	required = false,
	disabled = false,
	shortcutPriority = "short",
	fieldObject,
	errors,
	field,
}) {
	const [dateRange, setDateRange] = useState(parseValue(value));
	const [currentValue, setCurrentValue] = useState(value ?? "");
	const notifiedValueRef = useRef(value ?? "");

	useDebouncedEffect(
		() => {
			if (notifiedValueRef.current !== currentValue) {
				notifiedValueRef.current = currentValue;
				fieldObject.onChange(currentValue);
				onChangeCommitted?.(name, currentValue);
			}
		},
		[currentValue],
		fieldObject?.commitDelay ?? 250
	);

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [mobile, setMobile] = useState(window.innerWidth <= 1280);

	const ranges = useMemo(() => {
		return dateRange
			? [dateRange]
			: [{ key: "selection", startDate: moment().startOf("day").toDate(), endDate: moment().endOf("day").toDate() }];
	}, [dateRange]);

	const textVal = useMemo(() => {
		if (!dateRange || !dateRange.startDate || !dateRange.endDate) return "";

		const format = "MMM DD";

		if (moment(dateRange.startDate).startOf("day").valueOf() === moment(dateRange.endDate).startOf("day").valueOf()) {
			return moment(dateRange.startDate).format(format);
		}

		return `${moment(dateRange.startDate).format(format)} - ${moment(dateRange.endDate).format(format)}`;
	}, [dateRange]);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 650 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 650 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const onDateRangeChange = (range) => {
		setDateRange(range);
		let val = null;
		if (range) {
			if (moment(range.startDate).valueOf() === moment(range.endDate).valueOf()) {
				range.startDate = moment(range.startDate).startOf("day").valueOf();
				range.endDate = moment(range.endDate).endOf("day").valueOf();
			}
			val = `${moment(range.startDate).valueOf()}__DATE_RANGE__${moment(range.endDate).valueOf()}`;
		}
		setCurrentValue(val);
	};

	const setDateRangePastDays = (lookbackDays) => {
		const newRange = {
			startDate: moment()
				.subtract(lookbackDays - 1, "days")
				.toDate(),
			endDate: moment().toDate(),
			key: "selection",
		};

		onDateRangeChange(newRange);
	};

	const setDateRangeCase = (type) => {
		const newRange = {
			startDate: moment().toDate(),
			endDate: moment().toDate(),
			key: "selection",
		};

		switch (type) {
			case "yesterday":
				newRange.startDate = moment().subtract(1, "day").startOf("day").toDate();
				newRange.endDate = moment().subtract(1, "day").endOf("day").toDate();
				break;
			case "today":
				newRange.startDate = moment().startOf("day").toDate();
				newRange.endDate = moment().endOf("day").toDate();
				break;
			case "month":
				newRange.startDate = moment().startOf("month").toDate();
				newRange.endDate = moment().endOf("month").toDate();
				break;
			case "lastMonth":
				newRange.startDate = moment().subtract(1, "month").startOf("month").toDate();
				newRange.endDate = moment().subtract(1, "month").endOf("month").toDate();
				break;
			case "week":
				newRange.startDate = moment().startOf("week").toDate();
				newRange.endDate = moment().endOf("week").toDate();
				break;
			case "lastWeek":
				newRange.startDate = moment().subtract(1, "week").startOf("week").toDate();
				newRange.endDate = moment().subtract(1, "week").endOf("week").toDate();
				break;
			case "last3Months":
				newRange.startDate = moment().subtract(3, "month").toDate();
				newRange.endDate = moment().toDate();
				break;
			case "last6Months":
				newRange.startDate = moment().subtract(6, "month").toDate();
				newRange.endDate = moment().toDate();
				break;
			case "year":
				newRange.startDate = moment().startOf("year").toDate();
				newRange.endDate = moment().endOf("year").toDate();
				break;
			case "lastYear":
				newRange.startDate = moment().subtract(1, "year").startOf("year").toDate();
				newRange.endDate = moment().subtract(1, "year").endOf("year").toDate();
				break;
			default:
				break;
		}

		onDateRangeChange(newRange);
	};

	return (
		<div className="w-full mb-4 mt-4 px-4">
			{open && (
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={(e) => setOpen(false)}
					anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
					getContentAnchorEl={null}
				>
					<div style={styles.datePicker}>
						<div className={mobile ? "flex flex-col" : "flex flex-row"}>
							<div className={(mobile ? "flex flex-row " : "flex flex-col ") + "bg-white"}>
								{shortcutPriority === "short" && (
									<>
										<Button onClick={() => setDateRangeCase("today")}>Today</Button>
										<Button onClick={() => setDateRangeCase("yesterday")}>Yesterday</Button>
										<Button onClick={() => setDateRangePastDays(3)}>Past 3 Days</Button>
										<Button onClick={() => setDateRangePastDays(7)}>Past 7 Days</Button>
									</>
								)}
								{!mobile && (
									<>
										<Button onClick={() => setDateRangeCase("week")}>This Week</Button>
										<Button onClick={() => setDateRangeCase("lastWeek")}>Last Week</Button>
										<Button onClick={() => setDateRangePastDays(15)}>Past 15 Days</Button>
										<Button onClick={() => setDateRangePastDays(30)}>Past 30 Days</Button>
										<Button onClick={() => setDateRangeCase("month")}>This Month</Button>
										<Button onClick={() => setDateRangeCase("lastMonth")}>Last Month</Button>
									</>
								)}
								{shortcutPriority === "long" && (
									<>
										<Button onClick={() => setDateRangeCase("last3Months")}>Last 3 Months</Button>
										<Button onClick={() => setDateRangeCase("last6Months")}>Last 6 Months</Button>
										<Button onClick={() => setDateRangeCase("year")}>This Year</Button>
										<Button onClick={() => setDateRangeCase("lastYear")}>Last Year</Button>
									</>
								)}
							</div>
							<DateRange
								moveRangeOnFirstSelection={false}
								ranges={ranges}
								editableDateInputs={false}
								autoFocus={true}
								onChange={(r) => onDateRangeChange(r.selection)}
							/>
						</div>
					</div>
				</Menu>
			)}
			<TextField
				{...fieldObject}
				FormHelperTextProps={{ classes: { root: "mt-0 ml-1 mr-1 mb-1" } }}
				label={label}
				variant="outlined"
				type="text"
				className="w-full pr-0"
				value={textVal}
				error={!disabled ? !!errors?.[name]?.message : false}
				helperText={!disabled && !field?.noErrorMessage ? errors?.[name]?.message : ""}
				onChange={(event, value) => {}}
				onClick={(event) => {
					setAnchorEl(event.currentTarget);
					setOpen((val) => !val);
				}}
				required={required}
				disabled={disabled}
				InputProps={{
					endAdornment:
						dateRange && dateRange?.startDate ? (
							<div className="opacity-80" style={{ position: "absolute", right: 2, top: 4 }}>
								<IconButton
									key="close"
									aria-label="Close"
									color="default"
									disabled={disabled}
									onClick={(e) => {
										e.stopPropagation();
										onDateRangeChange(null);
									}}
								>
									<ClearIcon className={"w-16 h-16"} />
								</IconButton>
							</div>
						) : (
							""
						),
				}}
			/>
			{field?.variant !== "row" && field?.variant !== "skinny" && !field?.noErrorMessage && (
				<div style={!disabled && !!errors?.[name]?.message ? {} : { height: NARROW_FIELDS ? "1.6rem" : "1.9rem" }} />
			)}
		</div>
	);
}

export default SmarthopDateRangeField;
