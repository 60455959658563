import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import i18next from "i18next";
import es from "./i18n/es";
import { getPublicMarket } from "app/services/publicMarketServices";
import LandingMarketWidget from "./LandingMarketWidget";

i18next.addResourceBundle("es", "auth", es);

// WORKAROUND: dirty workaround to avoid calling API
// multiple times, need to build proper storage in redux
let _GLOBAL_MARKET_STATS = null;

function LandingMPIPreview(props) {
	const [markets, setMarkets] = useState(_GLOBAL_MARKET_STATS);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 1400);
	const height = smallScreen ? 210 : 300;

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth <= 1400 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth >= 1400 && smallScreen) {
				setSmallScreen(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		if (markets) return;
		getPublicMarket().then(
			(data) => {
				setMarkets(data);
				_GLOBAL_MARKET_STATS = data;
			},
			(err) => {
				console.log(err, " Public Markets");
			}
		);
		// eslint-disable-next-line
	}, []);

	useSelector(({ i18n }) => i18n.language);

	return (
		<div className={"flex flex-col max-w-lg items-center justify-center"}>
			<div className={"flex flex-row w-full"}>
				<div className="flex flex-col items-center justify-center w-full">
					{markets && (
						<Typography className="text-18 text-center tracking-wide text-white text-24 ml:text-32 px-24">
							Live Market Analysis <i>updated</i> daily
						</Typography>
					)}
				</div>
			</div>
			<div className={"flex flex-row w-full"}>
				<div className={"flex flex-col w-1/2 m-20"}>
					{markets && (
						<div className="w-full flex h-full flex-col items-center justify-center bg-opacity-60 bg-black rounded-lg border-1 border-orange">
							<Typography className="text-18 text-center tracking-wide text-white text-13 ml:text-16 p-24">
								SmartHop monitors RPM and a market profitability across the U.S. to help carriers book loads on the most
								profitable lanes to maximize profits.
								<br />
								<br />
								Log in to discover which markets and lanes are profitable today!
							</Typography>
						</div>
					)}
				</div>
				<div className={"flex flex-col w-1/2 m-20"}>
					{markets?.van && <LandingMarketWidget market={markets?.van} type="VAN" height={height} color={"#E7683D"} />}
				</div>
			</div>
			<div className={"flex flex-row w-full"}>
				<div className={"flex flex-col w-1/2 m-20"}>
					{markets?.reefer && (
						<LandingMarketWidget market={markets?.reefer} type="REEFER" height={height} color={"#E7683D"} />
					)}
				</div>
				<div className={"flex flex-col w-1/2 m-20"}>
					{markets?.flatbed && (
						<LandingMarketWidget market={markets?.flatbed} type="FLATBED" height={height} color={"#E7683D"} />
					)}
				</div>
			</div>
		</div>
	);
}

export default LandingMPIPreview;
