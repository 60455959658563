import moment from "moment";

import { centsToDollars } from "../../../app/main/utils/financeUtils";
import { formatCurrency } from "app/main/utils/tableUtils";

// Consts
const PLAN_NAME = "Platform";

const platformPlanSummaryForm = {
	urlGET: "billing/carriers/:carrierId/platform-plans",
	urlPOST: "billing/carriers/:carrierId/platform-plans",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision", "discountRevision"],
	header: { view: { label: `${PLAN_NAME} Plan` }, create: { label: `Assign ${PLAN_NAME} Plan` } },
	messages: { create: { success: () => `${PLAN_NAME} plan assigned.` } },
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "overridePlanNickname",
								type: "text",
								label: "Trial Plan",
								hidden: (item) => !item?.overridePlan && !item?.overrideTier && !item?.overrideTierExpiration,
							},
							{
								key: "overrideTierExpiration",
								type: "text",
								label: "Trial Ends",
								builder: (item) =>
									!!item?.overrideTierExpiration &&
									moment(item?.overrideTierExpiration).local().format("MMMM Do, YYYY"),
								hidden: (item) => !item?.overridePlan && !item?.overrideTier && !item?.overrideTierExpiration,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "nickname",
								type: "text",
								label: "Plan",
								hidden: (item) => !!item && !item?.active,
								builder: (item) => item?.nickname ?? item?.metadata?.price?.nickname,
							},
							{
								key: "plan-status",
								type: "text",
								label: "Status",
								builder: (item) =>
									item?.deactivated
										? "Deactivated"
										: item?.customer?.unsubscription_date
										? "Payment Pending"
										: item?.subscription?.cancel_at
										? "Canceled"
										: item?.subscription?.status === "trialing"
										? "Free Trial"
										: item?.active
										? "Active"
										: "Inactive",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "end-of-service-date",
								type: "text",
								label: "End of Service Date",
								builder: (item) =>
									!!item?.subscription?.cancel_at &&
									moment.unix(item?.subscription?.cancel_at).local().format("MMMM Do, YYYY"),
								hidden: (item) => item?.type === "CUSTOM" || !item?.active || !item?.subscription?.cancel_at,
							},
							{
								key: "cancellation-date",
								type: "text",
								label: "Cancelation Date",
								builder: (item) =>
									!!item?.subscription?.cancel_at &&
									moment.unix(item?.subscription?.canceled_at).local().format("MMMM Do, YYYY"),
								hidden: (item) => item?.type === "CUSTOM" || !item?.active || !item?.subscription?.cancel_at,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "next-payment-day",
								type: "text",
								label: "Next Payment Date",
								builder: (item) =>
									!item?.subscription?.cancel_at &&
									moment.unix(item?.subscription?.current_period_end).local().format("MMMM Do, YYYY"),
								hidden: (item) =>
									!!item &&
									(item?.type === "CUSTOM" ||
										!item?.active ||
										!!item?.subscription?.cancel_at ||
										!!item?.customer?.unsubscriptionDate),
							},
							{
								key: "termination_date",
								type: "text",
								label: "Termination Date",
								builder: (item) =>
									item?.customer?.unsubscriptionDate
										? moment(item?.customer?.unsubscriptionDate).format("MMMM Do, YYYY")
										: moment(item?.customer?.terminationDate).format("MMMM Do, YYYY"),
								hidden: (item) => !!item && !item?.customer?.unsubscriptionDate,
							},
							{
								key: "value",
								type: "text",
								label: "Next Payment Amount",
								builder: (item) => {
									const value =
										item?.upcomingInvoice?.amount_due ??
										item?.subscription?.items?.data?.[0]?.price?.unit_amount ??
										item?.value;
									const recurring = item?.subscription?.items?.data?.[0]?.price?.recurring?.interval_count;
									return value
										? `${formatCurrency(centsToDollars(value))} ${recurring === 3 ? "(quaterly)" : ""}`
										: undefined;
								},
								hidden: (item) => !item?.upcomingInvoice || item?.type === "CUSTOM",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "value",
								type: "currency",
								label: "Available Balance",
								builder: (item) => {
									const value = item?.customer?.balance;
									return value && centsToDollars(Math.abs(value));
								},
								hidden: (item) => !item?.customer?.balance || item?.customer?.balance >= 0,
							},
						],
					},
				},
				{
					type: "message",
					builder: () => `This account is no longer active. Please subscribe again to continue using our services.`,
					hidden: (item) => !!item && !item?.deactivated,
				},
			],
		},
		create: {
			items: [
				{
					key: "platform_plan",
					type: "autocomplete",
					label: `${PLAN_NAME} Plan`,
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/platform-plans",
						params: {
							sortOrder: "asc",
							sortBy: "nickname",
						},
						preloadDefault: true,
						listenEvent: "planRevision",
						actionOptions: [
							{
								value: "ADD_NEW_PLATFORM_PLAN",
								label: `Add New ${PLAN_NAME} Plan`,
								icon: "add",
								dialog: {
									formId: "ADMIN_PLATFORM_PLAN_FORM",
									mode: "CREATE",
								},
							},
						],
					},
				},
				{ key: "platform_trial_days_override", type: "number", label: "Trial Days" },
				{ type: "action", action: "SUBMIT", label: "Save", disableAtClick: true },
			],
		},
	},
};

export default platformPlanSummaryForm;
