import { centsToDollars } from "app/main/utils/financeUtils";

const factoringRequests = {
	urlGET: "factoring/factoring-requests",
	idKey: "_id",
	segmentKey: "ADMIN_FACTORING_REQUESTS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		click: {
			dialogBuilder: (item, dataIds) => ({
				viewId: "FACTORING_REQUEST_DETAIL_VIEW",
				dataIds: {
					...dataIds,
					item,
					factoringRequestId: item._id,
				},
			}),
		},
		filters: {
			search: true,
			items: [],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
				{
					key: "account_info",
					type: "checkbox",
					label: "Show Account Information",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{
				key: "accounts_receivable",
				type: "text",
				label: "Debtor",
				builder: (item) => item.accounts_receivable.account.name,
			},
			{
				key: "beneficiary",
				type: "text",
				label: "Beneficiary",
				builder: (item) => item.accounts_receivable.borrower?.name,
			},
			{
				key: "amount",
				type: "currency",
				label: "AR Amount",
				builder: (item) => centsToDollars(item.accounts_receivable.amount),
			},
			{
				key: "accounts_receivable.due_date",
				type: "date",
				label: "AR Due Date",
			},
			{
				key: "accounts_receivable.issue_date",
				type: "date",
				label: "AR Issue Date",
			},
			{ key: "disbursement_account_id", type: "id", label: "Disbursement Account", group: "account_info" },
			{ key: "collection_account_id", type: "id", label: "Collection Account", group: "account_info" },
			{ key: "createdAt", type: "date", label: "Date Requested" },
			{ key: "created_by", type: "text", label: "Requester", builder: (item) => item.created_by.name },
			{ key: "status", type: "text", label: "Status" },
		],
	},
};

export default factoringRequests;
