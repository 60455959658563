// Utils
import { formatCurrency } from "app/main/utils/tableUtils";

const checkBalance = {
	urlPOST: "billing/source/:sourceId/balance",
	idKey: "_id",
	triggerEvent: "billingRevision",
	listenEvent: "billingRevision",
	header: {
		create: { label: "Check Balance" },
	},
	messages: {
		create: {
			success: (data) => {
				const message = data?.available
					? `Balance available to collect ${formatCurrency(data?.amount)}`
					: `Insufficient balance to collect ${formatCurrency(data?.amount)} `;
				return message;
			},
		},
	},
	content: {
		create: {
			items: [
				{
					key: "amount",
					type: "number",
					label: "Amount",
					translate: "",
					required: true,
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default checkBalance;
