import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const videoSlice = createSlice({
	name: "video",
	initialState,
	reducers: {
		setVideos: (state, action) => action.payload,
	},
	extraReducers: {},
});

export const { setVideos } = videoSlice.actions;

export default videoSlice.reducer;
