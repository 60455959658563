import { formatDate } from "app/main/utils/dateUtils";
import CellContainerRow from "@smarthop/list/views/CellContainerRow";
import CellFirstRow from "@smarthop/list/views/CellFirstRow";
import CellSecondRow from "@smarthop/list/views/CellSecondRow";
import Icon from "@material-ui/core/Icon";

const columns = {
	CARRIER: {
		key: "carrier__view",
		builder: (item) => item.carrier__view,
		type: "carrier__view",
		label: "Carrier",
		viewConfig: {
			showMcNumber: true,
			showTestCarrier: true,
		},
		table: { width: 200 },
	},
	TRANSACTION_NUMBER: {
		key: "fuel_transaction__view",
		label: "Transaction",
		type: "fuel_transaction__view",
		viewConfig: {
			showTestTransaction: true,
			showDriver: true,
		},

		table: { width: 240 },
	},
	USER: {
		key: "driver__view",
		type: "driver__view",
		label: "Driver",
		viewConfig: {
			showStatus__top: true,
		},
		export: {
			builder: (item) => item?.driver__view?.label ?? " ",
		},
		table: { width: 200 },
	},
	INVESTOR: {
		key: "investor_user__view",
		type: "user__view",
		label: "Investor",
		viewConfig: {
			showStatus__top: true,
		},
		export: {
			builder: (item) => item?.investor__view?.label ?? " ",
		},
		table: { width: 200 },
	},
	TRUCK: {
		key: "truck__view",
		type: "truck__view",
		label: "Truck",
		viewConfig: {
			showStatus: true,
			showTeam: true,
		},
	},
	TRANSACTION_DATE: {
		key: "data.transaction_date",
		title: "Load",
		label: "Date",
		type: "component",
		builder: (item) => {
			return (
				<CellContainerRow
					variant={"skinny"}
					first={
						<CellFirstRow
							textLimit={40}
							variant={"skinny"}
							label={formatDate(item?.data?.transaction_date, item?.data?.transaction_date, false, false, true, false)}
						/>
					}
					second={
						<div className="flex flex-wrap">
							<CellSecondRow label={item?.data?.transaction_time} variant={"skinny"} color={"text-grey-600"} />
						</div>
					}
				/>
			);
		},
		export: {
			builder: (item) =>
				`${formatDate(item?.data?.transaction_date, item?.data?.transaction_date, false, false, true, false)} ${
					item?.data?.transaction_time
				}`,
		},
		grid: { headerTitle: true, valuePefix: "Date" },
		table: { width: 100 },
	},
	CARD: {
		key: "data.driver_name",
		label: "Driver",
		type: "component",
		builder: (item) => {
			return (
				<>
					{item?.data?.driver_name ? (
						<CellContainerRow
							variant={"skinny"}
							first={<CellFirstRow textLimit={40} variant={"skinny"} label={item?.data?.driver_name} />}
							second={
								<div className="flex flex-wrap">
									<CellSecondRow label={"Driver #" + item?.data?.driver_number} color={"text-grey-600"} />
								</div>
							}
						/>
					) : (
						"-"
					)}
				</>
			);
		},
		export: {
			builder: (item) => item?.data?.driver_name,
		},
	},
	LOCATION: {
		key: "data.merchant_city",
		label: "Location",
		type: "component",
		builder: (item) => {
			return (
				<CellContainerRow
					variant={"skinny"}
					first={<CellFirstRow textLimit={32} variant={"skinny"} label={item?.data?.truck_stop_name} />}
					second={
						<div className="flex flex-wrap">
							<CellSecondRow
								label={item?.data?.merchant_city + ", " + item?.data?.merchant_state}
								color={"text-grey-600"}
							/>
						</div>
					}
				/>
			);
		},
		export: {
			builder: (item) => item?.data?.merchant_city + ", " + item?.data?.merchant_state,
		},
		table: { width: 200 },
	},
	PAYROLL: {
		key: "payroll__view",
		group: "payroll_details",
		type: "component",
		label: "Payroll",
		viewConfig: {
			showState: true,
		},
		builder: (item) =>
			item?.payroll_eligible ? (
				<Icon className="text-20">check</Icon>
			) : (
				<Icon className="text-20 text-grey">close</Icon>
			),
		export: {
			builder: (item) => (item?.payroll_eligible ? "Enabled" : null),
		},
		table: { align: "center", width: 100 },
	},
	PRODUCT_TYPE: {
		key: "data.product_name",
		label: "Product Type",
		type: "text",
		group: "product_type",
		table: { width: 200 },
	},
	GALLONS: {
		key: "data.gallons",
		label: "Gallons",
		type: "number",
		table: { width: 100 },
	},
	TOTAL_AMOUNT: {
		key: "data.total_amount",
		label: "Total",
		type: "currency",
		table: { width: 100, sticky: true },
	},
	PPG: {
		key: "data.ppg",
		label: ["Retail", "PPG"],
		type: "currency",
		group: "price_details",
		table: { width: 100 },
	},
	PPG_DISCOUNTED: {
		key: "data.ppg_discounted",
		label: ["Discounted", "PPG"],
		type: "currency",
		group: "price_details",
		table: { width: 100 },
	},
	ACTUAL_COST: {
		key: "data.actual_cost",
		label: ["Retail", "Cost"],
		type: "currency",
		group: "price_details",
		table: { width: 100 },
	},
	DISCOUNT_TOTAL: {
		key: "data.discount_total",
		label: "Saving",
		type: "currency",
		group: "price_details",
		table: { width: 100 },
	},
};

const groups = {
	PRODUCT_DETAILS: {
		key: "product_type",
		type: "checkbox",
		label: "Product Details",
	},
	PAYROLL_DETAILS: {
		key: "payroll_details",
		type: "checkbox",
		label: "Payroll Details",
	},
	PRICE_DETAILS: {
		key: "price_details",
		type: "checkbox",
		label: "Pricing Details",
	},
};

const orderBy = [
	"data.transaction_date",
	"data.discount",
	"data.actual_cost",
	"data.driver",
	"data.merchant_city",
	"data.gallons",
	"data.total_amount",
	"data.ppg",
	"data.ppg_discounted",
	"data.discount_total",
	"data.actual_cost_discounted",
];

const filters = {
	CARRIER: {
		key: "carrier",
		type: "autocomplete",
		label: "Carrier",
		field: {
			noErrorMessage: true,
			classes: {},
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/carriers",
			preloadDefault: true,
			listenEvent: "profileRevision",
			params: {
				options_active_only: true,
				carrier_id: ":carrierId",
			},
		},
	},
	TRANSACTION_DATE: {
		key: "data.transaction_date",
		type: "dateRange",
		label: "Date",
	},
	ASSIGMENT_STATUS: {
		key: "assigment_status",
		type: "select",
		label: "Assigment Status",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Not Selected",
			},
			{
				value: "ASSIGNED",
				label: "Assigned",
			},
			{
				value: "NOT_ASSIGNED",
				label: "Not Assigned",
			},
		],
	},
};

export { columns, groups, orderBy, filters };
