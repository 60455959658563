import { reportFiltersForm } from "app/main/utils/fileUtils";

const declined_transactions_report = reportFiltersForm(
	"billing/carriers/:carrierId/declined-transactions/download/report",
	"Download Report",
	"Declined transactions report.csv",
	"text/csv"
);

export default declined_transactions_report;
