import { global } from "app/services/requestUtil";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const TYPE_COMPANY = {
	key: "typeCompany",
	type: "select",
	label: "What describes you best?",
	defaultValue: "NOT_SELECTED",
	options: [
		{ value: "NOT_SELECTED", label: "Not Selected" },
		{
			value: "FLEET",
			label: "Fleet",
		},
		{ value: "DISPATCH_SERVICE", label: "Dispatch Service" },
		{ value: "OWNER_OPERATOR", label: "Owner Operator" },
	],
};

const ROLE = {
	key: "roleCompany",
	type: "select",
	label: "What is your role?",
	defaultValue: "NOT_SELECTED",
	options: [
		{ value: "NOT_SELECTED", label: "Not Selected" },
		{ value: "CARRIER_OWNER", label: "Carrier Owner" },
		{ value: "DISPATCHER", label: "Dispatcher" },
		{ value: "DRIVER", label: "Driver" },
	],
};

const NUMBERS_TRUCKS = {
	key: "numberTrucks",
	type: "number",
	label: "How many trucks?",
};

const EQUIPMENT = {
	key: "equipment",
	type: "select",
	label: "Equipment",
	required: true,
	options: [
		{
			value: "VAN",
			label: "Van",
			translate: "VAN",
		},
		{
			value: "REEFER",
			label: "Reefer",
			translate: "REEFER",
		},
		{
			value: "FLATBED",
			label: "Flatbed",
		},
		{
			value: "OTHERS",
			label: "Others",
		},
	],
};

const createRegistrationForm = (
	onDialogOpen,
	hideFilledElements = false,
	typeCompany,
	roleCompany,
	hideQualifier,
	isSimple,
	providerId
) => {
	const showMcAndDot = typeCompany !== "DISPATCH_SERVICE";
	const McDotNotOptional = ["FLEET", "OWNER_OPERATOR"]?.includes(typeCompany);
	const generateTermsLink = (viewId, label) => {
		return (
			<Link
				className="cursor-pointer text-blue-900 whitespace-nowrap pr-4"
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					onDialogOpen({
						viewId: viewId,
					});
				}}
			>
				<Typography>{label}</Typography>
			</Link>
		);
	};

	return {
		noInitValidation: true,
		items: [
			...(!hideFilledElements && providerId !== "apple.com"
				? [
						{
							type: "group",
							content: {
								items: [
									{
										key: "first_name",
										type: "text",
										label: "First Name",
										translate: "FIRST_NAME",
										required: true,
										maxCharacter: 60,
									},
									{
										key: "last_name",
										type: "text",
										label: "Last Name",
										translate: "LAST_NAME",
										required: true,
										maxCharacter: 60,
									},
								],
							},
						},
						{
							type: "group",
							content: {
								items: [
									{ key: "email", type: "email", label: "Email", translate: "EMAIL", required: true, maxCharacter: 60 },
									{ key: "phone", type: "phone", label: "Phone", translate: "PHONE", required: true, maxCharacter: 60 },
								],
							},
						},
				  ]
				: []),
			...(providerId === "apple.com"
				? [
						{
							type: "group",
							content: {
								items: [
									{ key: "phone", type: "phone", label: "Phone", translate: "PHONE", required: true, maxCharacter: 60 },
								],
							},
						},
				  ]
				: []),
			...(hideFilledElements
				? [
						{
							type: "group",
							content: {
								items: [
									{ key: "email", type: "email", label: "Email", translate: "EMAIL", required: true, maxCharacter: 60 },
								],
							},
						},
				  ]
				: []),
			{
				type: "group",
				content: {
					items: [
						...(!hideQualifier?.typeCompany ? [TYPE_COMPANY] : []),
						...(!hideQualifier?.numberTrucks && !isSimple ? [NUMBERS_TRUCKS] : []),
					],
				},
			},
			{
				type: "group",
				content: {
					items: [
						...(!hideQualifier?.roleCompany && typeCompany === "FLEET" && !isSimple ? [ROLE] : []),
						...(!hideQualifier?.equipment ? [EQUIPMENT] : []),
					],
				},
			},
			...(showMcAndDot
				? [
						{
							type: "group",
							content: {
								items: [
									{
										key: "carrier_mc_number",
										type: "number",
										label: "MC#" + (McDotNotOptional ? "" : "(Optional)"),
										translate: "MC",
										maxCharacter: 60,
									},
									{
										key: "carrier_dot_number",
										type: "number",
										label: "DOT#" + (McDotNotOptional ? "" : "(Optional)"),
										translate: "MC",
										maxCharacter: 60,
									},
								],
							},
						},
				  ]
				: []),
			...(providerId !== "apple.com"
				? [
						{
							type: "group",
							content: {
								items: [
									{
										key: "password",
										type: "password",
										label: "Password",
										translate: "PASSWORD",
										required: true,
										maxCharacter: 60,
									},
									{
										key: "passwordConfirm",
										type: "passwordConfirm",
										label: "Confirm Password",
										translate: "CONFIRM_PASSWORD",
										required: true,
										maxCharacter: 60,
									},
								],
							},
						},
				  ]
				: []),
			{
				key: "terms_and_condition",
				type: "checkbox",
				labelShort: "T&C",
				label: (
					<div className="flex flex-wrap mt-0 items-center pt-6 -mb-6 sm:mb-4 ">
						<Typography className="whitespace-nowrap pr-4">I have read and agree to</Typography>
						{generateTermsLink("SMARTHOP_TERMS_VIEW", "SmartHop's Terms Of Service Agreement")}
						<Typography className="whitespace-nowrap pr-4">and</Typography>
						{generateTermsLink("PRIVACY_POLICY_VIEW", "Privacy Policy")}
						<Typography className="whitespace-nowrap">*</Typography>
					</div>
				),
				translate: "REGISTRATION_I_HAVE_READ_T_AND_C_CHECKBOX",
				required: true,
				checkbox: { classes: { root: "-mt-10" } },
			},
			{
				key: "sms_opt_in",
				type: "checkbox",
				labelShort: "SMS Terms",
				label: (
					<div className="flex flex-wrap mt-0 items-center pt-6 -mb-6 sm:mb-4 ">
						<Typography className="whitespace-nowrap pr-4">I have read and agree to</Typography>
						{generateTermsLink("SMS_TERMS_VIEW", "SMS Terms")}
						<Typography className="whitespace-nowrap">*</Typography>
					</div>
				),
				required: true,
				checkbox: { classes: { root: "-mt-10" } },
			},
			// Disabling capcha when using IP override on local machine,
			// because capcha would not work if URL has ip insteaad of domain
			...(!global?.LOCALHOST_OVERRIDE_IP
				? [
						{
							key: "captcha_key",
							type: "captcha",
							required: true,
						},
				  ]
				: []),
			{ type: "action", label: "Register", action: "SUBMIT", button: { alwaysEnabledIfValid: true } },
		],
	};
};

export default createRegistrationForm;
