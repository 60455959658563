import { createSlice } from "@reduxjs/toolkit";

const popoversSlice = createSlice({
	name: "popovers",
	initialState: {
		popoverPanel: ""
	},
	reducers: {
		openPopoverPanel: (state, action) => {
			if (!action?.payload?.popoverId || !action?.payload?.currentTarget) return;

			state.popoverPanel = [{
				popoverId: action.payload?.popoverId,
				currentTarget: action?.payload?.currentTarget
			}]
		},
		closePopoverPanel: (state) => {
			state.popoverPanel = [];
		},
		setOpenPopoverLoadsPanel: (state, action) => {
			state.popoverPanel = action.payload;
		},
	},
});

export const { openPopoverPanel, closePopoverPanel, setOpenPopoverLoadsPanel } = popoversSlice.actions;

export default popoversSlice.reducer;
