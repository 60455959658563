// We display the same fields for any existing payment type
const PAYMENT_TYPE_SCHEMA = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "base_transfer_fee_cents",
					type: "currency",
					label: "Base Transfer Fee",
					field: { manageCents: true },
					required: true,
				},
				{
					key: "transfer_fee_percentage",
					type: "percentage",
					label: "Transfer Fee %",
					required: true,
				},
			],
		},
	},
	{
		key: "max_transfer_fee_cents",
		type: "currency",
		label: "Max Transfer Fee",
		field: { manageCents: true },
		required: true,
	},
];

// Form schema
const adminSmartpayFeePlanForm = {
	urlPOST: "billing/factoring-fee-plan",
	urlPUT: "billing/factoring-fee-plan/:id",
	urlDELETE: "billing/factoring-fee-plan/:id",
	urlGET: "billing/factoring-fee-plan/:id",
	idKey: "_id",
	triggerEvent: "smartpayFeeRevision",
	listenEvent: "smartpayFeeRevision",
	header: { create: { label: "Create plan" }, delete: { label: "Delete plan" }, edit: { label: "Edit plan" } },
	content: {
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to permanently delete this plan?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
							},
						],
					},
				},
			],
		},
		edit: { sameAs: "create" },
		create: {
			items: [
				{ key: "nickname", type: "text", label: "Name", required: true },
				{ key: "smartpay_fee_percentage", type: "percentage", label: "SmartPay Fee %", required: true },

				{ key: "ach_section", type: "section", label: "ACH" },
				{
					key: "ach",
					type: "object",
					content: {
						items: PAYMENT_TYPE_SCHEMA,
					},
				},
				{ key: "wire_section", type: "section", label: "Same Day" },
				{
					key: "wire",
					type: "object",
					content: {
						items: PAYMENT_TYPE_SCHEMA,
					},
				},

				{
					key: "default_for_tier",
					type: "select",
					label: "Default for Tier",
					required: false,
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ value: "__NOT_SELECTED__", label: "Not Selected" },
						{
							value: "TIER_LIMITED",
							label: "Limited Tier",
						},
						{
							value: "TIER_STARTER",
							label: "Starter Tier",
						},
						{
							value: "TIER_BASIC",
							label: "Basic Tier",
						},
						{
							value: "TIER_BASIC_PLUS",
							label: "Basic Plus Tier",
						},
						{
							value: "TIER_PROFESSIONAL",
							label: "Professional Tier",
						},
						{
							value: "TIER_GROWTH",
							label: "Growth Tier",
						},
						{
							value: "TIER_ENTERPRISE",
							label: "Enterprise Tier",
						},
					],
				},

				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminSmartpayFeePlanForm;
