import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

// Form
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { debugLocationExpire } from "app/services/tripsServices";
import { replaceFormDialog } from "app/store/tools/formDialogSlice";

const formConfig = {
	items: [
		{
			key: "type",
			type: "select",
			label: "Type",
			defaultValue: "TEXT",
			options: [
				{
					value: "EXPIRE",
					label: "Expire Tracking",
				},
				{
					value: "EXPIRE_AND_RUN",
					label: "Expire Tracking and Send Warning",
				},
				{
					value: "CLEAR_GEO_HISTORY",
					label: "Clear GeoFence Notification History",
				},
				{
					value: "DEBUG_EVENT",
					label: "Add Debug Event",
				},
				{
					value: "EXPIRE_ETA",
					label: "Expire ETA",
				},
				{
					value: "CLEAN_NOTIFICATION_FLAGS",
					label: "Clean Notification Flags",
				},
			],
		},
		{ type: "action", action: "SUBMIT" },
	],
};

function DebugTrackingForm(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [data, setData] = useState();
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		props?.setTitle?.("Tracking Debug Form");
		props?.setSize?.("max-w-md");
		// eslint-disable-next-line
	}, []);

	const handledSave = () => {
		setError(undefined);
		if (data.type === "DEBUG_EVENT") {
			dispatch(
				replaceFormDialog({
					viewId: "TRIP_TRACKING",
					mode: "CREATE",
					dataIds: {
						carrierId: props?.dataIds?.carrierId,
						tripId: props?.dataIds?.tripId,
						data: props?.dataIds?.trip,
						debugMode: true,
					},
				})
			);
		} else {
			setProcessing(true);
			debugLocationExpire({
				type: data.type,
				carrierId: props?.dataIds?.carrierId,
				tripId: props?.dataIds?.tripId,
			}).then(
				() => {
					setProcessing(false);
					showSnackbar(snackbar, "Done", "success");

					dispatch(incrementDataRevision({ event: "profileRevision" }));
					dispatch(incrementDataRevision({ event: "dataRevision" }));
					dispatch(incrementDataRevision({ event: "historyRevision" }));
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					props?.onDone?.();
				},
				(err) => {
					setProcessing(false);
					setError(err.errors?.[0]?.message ?? "Opps, operation failed...");
					showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
				}
			);
		}
	};

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<div className="p-10">
			<SmarthopFormView
				key={"DebugTrackingForm"}
				mode={"EDIT"}
				content={formConfig}
				data={data}
				noInitValidation={true}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					setData({ ...data, ...model });
				}}
				onSubmit={() => {
					handledSave();
				}}
				processing={processing}
			/>
		</div>
	);
}

export default DebugTrackingForm;
