import { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { authRoles } from "app/main/consts";

// Dependencies
import PageTabWrapper from "app/main/common/PageTabWrapper";

// Pages
import DriverTrucksPage from "../trucks/DriverTrucksPage";
import UsersDetailsPayrollPage from "./UsersDetailsPayrollPage";

// Configs
import { enterprisePayeesPayrollConf } from "app/main/finance/enterprise-payroll/enterprisePayeesPayrollConf.js";

// List
import { SmarthopList, payeePayrollLedgerList, enterprisePayrollRulesList } from "@smarthop/list";

// Services
import { getDriverId, getCarrierId, getUserId } from "app/services/LoginService";
import { generateDriverPayrollForDriver } from "app/services/billingServices";
import { isEnabled } from "app/services/featureStorageService";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { downloadFile } from "app/main/utils/fileUtils";
import useUserDetails from "@smarthop/hooks/useUserDetails";
import PayrollHelper from "@smarthop/utils/payrollHelpers";
import useSHViewParam from "@smarthop/hooks/useSHViewParam";

const EnterpriseUsersPayrollPage = (props) => {
	const snackbar = useSnackbar();
	const user = useSelector(({ auth }) => auth.user);

	const [selected, setSelected] = useState([]);

	const userId = useSHViewParam(props, "userId", getUserId);
	const carrierId = useSHViewParam(props, "carrierId", getCarrierId);
	const userIdData = useUserDetails(userId, carrierId);

	const driverId = useSHViewParam(props, "driverId", getDriverId);

	const isAdmin = user?.rolesLabels?.includes("administrator");
	const isInvestor = useSHViewParam(props, "isInvestor", PayrollHelper.isInvestor(userIdData));
	const isDispatcher = PayrollHelper.isDispatcher(userIdData);
	const hasParentCarrier = useSelector(({ auth }) => !!auth?.user?.parentCarrier);
	const parentCarrierTier = useSelector(({ auth }) => auth?.user?.parentCarrierTier);

	const downloadDriverPayroll = async (items) => {
		try {
			const report = await generateDriverPayrollForDriver(carrierId, driverId, userId, { data: items });
			if (report) {
				downloadFile(report, "Payroll Report", "application/pdf");
			}
		} catch (e) {
			showSnackbar(snackbar, e.errors[0]?.message, "error", 500);
		}
	};

	const exportPayrollButton = {
		label: `Export PDF`,
		auth: [...authRoles.externalUsers, ...authRoles.admin],
		disabled: !selected.length,
		onClick: () => downloadDriverPayroll(selected),
	};

	const addPayrollRule = {
		action: "add",
		auth: ["carrier", "carrier_manager"],
		label: "Add Rule",
		dialog: {
			viewId: "PAYROLL_RULES_VIEW",
			mode: "CREATE",
			dataIds: { userId, carrierId },
		},
	};

	const payrollActions = [exportPayrollButton];
	const payrollRulesActions = [addPayrollRule];
	let restrictions = ["ONBOARDING", "USER_PAYROLL_PERMISSION", "TIER_PROFESSIONAL"];

	// Check if it's a sub-account of a professional tier
	if (hasParentCarrier && parentCarrierTier === "TIER_PROFESSIONAL") {
		restrictions = restrictions.filter((r) => r !== "TIER_PROFESSIONAL");
	}

	const handleSelectItem = (type, ids) => {
		if (type === "CLEAR") {
			setSelected([]);
		} else if (type === "ADD") {
			setSelected([...selected, ...ids]);
		} else if (type === "REMOVE") {
			setSelected(selected.filter((selectedId) => !ids.includes(selectedId)));
		}
	};

	let tabsComponents = [
		...(!isEnabled("SIMPLIFIED_USER_PREVIEW")
			? [
					{
						label: "Settings",
						actions: [],
						component: (
							<UsersDetailsPayrollPage params={{ carrierId, userId, isInvestor, isDispatcher }} isView hideTitle />
						),
					},
			  ]
			: []),
		...(isInvestor && !isEnabled("SIMPLIFIED_USER_PREVIEW")
			? [
					{
						label: "Trucks",
						actions: [],
						component: (
							<DriverTrucksPage params={{ carrierId, driverId, userId, isInvestor, allowActions: true }} isView />
						),
					},
			  ]
			: []),
		{
			label: "Recurring",
			actions: payrollRulesActions,
			component: (
				<SmarthopList
					key="payroll-rules"
					mode="table"
					isView={2}
					config={enterprisePayrollRulesList({ isInvestor })}
					dataIds={{ carrierId, userId }}
					renderEmptyListView={() => "Add your first recurring rule"}
				/>
			),
		},
		{
			label: "Ledger",
			component: (
				<SmarthopList
					key="ledger"
					mode="table"
					isView={2}
					config={payeePayrollLedgerList({ isAdmin, isActionsEnable: false, isInvestor, isView: true })}
					dataIds={{ userId, carrierId }}
				/>
			),
		},
		{
			label: "Reports",
			actions: payrollActions,
			component: (
				<SmarthopList
					key="payroll"
					mode="table"
					isView={2}
					config={enterprisePayeesPayrollConf({ isUser: true, payrollStatus: "approved", isInvestor, isView: true })}
					multiselect
					dataIds={{ userId, carrierId }}
					selectedItems={selected}
					onSelectItems={handleSelectItem}
				/>
			),
		},
	];

	return (
		<PageTabWrapper
			title="Payroll"
			variant="narrow"
			isView={props.isView}
			restrictions={restrictions}
			tabs={tabsComponents}
		/>
	);
};

export default EnterpriseUsersPayrollPage;
