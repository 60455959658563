import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

/**
 * @description Get Fuel transaction Details by id
 * @param {String} transactionId
 * @returns
 */
export const getFuelTransactionById = (transactionId) => {
	return createRequest()
		.get(`api/fuel/transactions/${transactionId}`, { headers: createHeaders() })
		.then((res) => res?.data)
		.catch((error) => throwResErrors(error));
};

/**
 * @description Execute the same cronjob run for a job key SYNC_FUEL_CARRIERS
 * but restricted to a single user.
 * @param {String} param.userId
 * @param {String} param.carrierId
 * @returns
 */
export const syncFuelCarrier = (carrierId) => {
	return createRequest()
		.post(`api/fuel/carriers/${carrierId}/sync`, {}, { headers: createHeaders() })
		.then((res) => res?.data)
		.catch((error) => throwResErrors(error));
};

/**
 * @description Retrieve nearby fuel locations from a trip
 * @param {String} tripId
 * @param {String} carrierId
 * @returns fuel locations with coordinates
 */
export const getFuelLocationsNearByCarrier = (carrierId, lat, long) => {
	return createRequest()
		.get(`api/fuel/carriers/${carrierId}/locations-nearby`, { headers: createHeaders(), params: { lat, long } })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * @description
 * @param {String} carrierId
 * @param {Object} data
 * @returns
 */
export const addFuelEntryJournal = (carrierId, data) => {
	return createRequest()
		.post(`api/fuel/carriers/${carrierId}/journal-entry`, data, { headers: createHeaders() })
		.then((res) => res?.data)
		.catch((error) => throwResErrors(error));
};

/**
 * @description
 * @param {String} carrierId
 * @returns
 */
export const getFuelCarrierBalance = (carrierId) => {
	return createRequest()
		.get(`api/fuel/carriers/${carrierId}/balance`, { headers: createHeaders() })
		.then((res) => res?.data)
		.catch((error) => throwResErrors(error));
};
