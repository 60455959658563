import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const PayrollTripsAffectedWarningConfirmDialog = (props) => {
	const title = "Payroll Warning";
	const subtitle = "All payrolls for these listed trips will be affected, click 'Accept' to continue.";
	const message = props.trips?.map((trip) => trip.label).join(", ");
	const trips = props.trips;

	const onClose = () => {
		props.onClose();
	};

	const onAccept = () => {
		props.onAccept();
	};

	return (
		<SmarthopConfirmDialog
			open={props?.open}
			title={title}
			message={subtitle}
			onClose={onClose}
			onAccept={onAccept}
			acceptMsg="Accept"
			closeMsg="No"
			maxWidth={"md"}
		>
			<div className="flex flex-col justify-center mb-14 -mt-8">
				<div key={"trips-payroll-affected"} className={"flex flex-row pt-12"}>
					<Alert key="error" severity={"warning"} className="w-full">
						<AlertTitle>{`${trips.length} trips will be affected`}</AlertTitle>
						<Typography className="text-left w-full text-12 ml:text-13 leading-snug">{message}</Typography>
					</Alert>
				</div>
			</div>
		</SmarthopConfirmDialog>
	);
};

export default PayrollTripsAffectedWarningConfirmDialog;
