import Typography from "@material-ui/core/Typography";
import { CellTypes } from "@smarthop/list/SmarthopCellTypes";

const CELL_TYPES_HEADERS = {
	account__view: "Account",
	carrier__view: "Carrier",
	profile__view: "Profile",
	user__view: "User",
	truck__view: "Truck",
	driver__view: "Driver",
	payee__view: "Payroll",
	invoicing__view: "Invoice",
	trips__view: "Trip",
	broker__view: "Broker",
	investor__view: "Investor",
};

const _GENERIC_FIELDS = [
	{ key: "period", type: "id", label: "Period ID", group: "internal" },
	{ key: "periodDate", type: "date", label: "Period Date", group: "internal" },
	{ key: "period__view.label", type: "period__view", builder: (item) => item.period__view, label: "Period" },
	{ key: "ownerId", type: "id", builder: (item) => item.owner, label: "Owner ID", group: "internal" },
	{ key: "entityId", type: "id", builder: (item) => item.entity, label: "Entity ID", group: "internal" },
	{ key: "linkIds", type: "text", builder: (item) => item.links?.join(", "), label: "Link IDs", group: "internal" },
	{
		key: "links",
		builder: (item) => {
			return (
				<div className="grid grid-cols-3 my-2">
					{item.owner__view?._view_type ? (
						<div className="flex flex-col my-2 mx-2 p-1 min-w-180">
							<Typography className="text-12 font-bold text-grey-400 -mb-2">
								{CELL_TYPES_HEADERS[item.owner__view?._view_type] ?? item.owner__view?._view_type}
							</Typography>
							{CellTypes[item.owner__view?._view_type]?.({
								item,
								cellValue: item.owner__view,
								viewConfig: item.owner__view?._view_config,
								variant: "extra-skinny",
							}) ?? "No View Found"}
						</div>
					) : item.owner__view ? (
						JSON.stringify(item.owner__view)
					) : null}
					{item.entity__view?._view_type ? (
						<div className="flex flex-col my-2 mx-2 p-1 min-w-180">
							<Typography className="text-12 font-bold text-grey-400 -mb-2">
								{CELL_TYPES_HEADERS[item.entity__view?._view_type] ?? item.entity__view?._view_type}
							</Typography>
							{CellTypes[item.entity__view?._view_type]?.({
								item,
								cellValue: item.entity__view,
								viewConfig: item.entity__view?._view_config,
								variant: "extra-skinny",
							}) ?? "No View Found"}
						</div>
					) : item.entity__view ? (
						JSON.stringify(item.entity__view)
					) : null}
					{item.links__views?.map((cellValue) => (
						<div className="flex flex-col my-2 mx-2 p-1 min-w-180">
							<Typography className="text-12 font-bold text-grey-400 -mb-2">
								{CELL_TYPES_HEADERS[cellValue?._view_type] ?? cellValue?._view_type ?? "No View Type"}
							</Typography>
							{CellTypes[cellValue?._view_type]?.({
								item,
								cellValue: { ...cellValue, value: cellValue.overrideValue ?? cellValue.value },
								viewConfig: cellValue?._view_config,
								variant: "extra-skinny",
							}) ?? "No View Found"}
						</div>
					))}
				</div>
			);
		},
		type: "component",
		label: "Entities",
	},
	{ key: "params", type: "text", label: "Params" },
	{ key: "count", type: "text", label: "Count" },
	{ key: "sum", type: "text", label: "Sum" },
	{ key: "max", type: "text", label: "Max" },
	{ key: "avg", type: "text", label: "Avg" },
	{ key: "min", type: "text", label: "Min" },
	{ key: "p99", type: "text", label: "P99", group: "percentile" },
	{ key: "p95", type: "text", label: "P95", group: "percentile" },
	{ key: "p90", type: "text", label: "P90", group: "percentile" },
	{ key: "p80", type: "text", label: "P80", group: "percentile" },
];

const _GENERIC_GROUPS = [
	{
		key: "internal",
		type: "checkbox",
		label: "Show System Fields",
	},
	{
		key: "percentile",
		type: "checkbox",
		label: "Show Percentile Values",
	},
];

const _GENERIC_FILTERS = [
	{
		key: "carrier",
		type: "autocomplete",
		label: "Carrier",
		field: {
			noErrorMessage: true,
			classes: {},
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/carriers",
			preloadDefault: true,
			listenEvent: "profileRevision",
			params: {
				options_active_only: true,
			},
		},
	},
	{
		key: "truck",
		type: "autocomplete",
		label: "Truck",
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/trucks",
			params: {
				options_active_only: true,
			},
			preloadDefault: true,
			preloadImmedately: true,
			listenEvent: "profileRevision",
		},
		rules: {
			params: {
				valueRef: "carrier",
				paramKey: "options_carrier_id_search",
			},
		},
	},
	{
		key: "params",
		type: "text",
		label: "Params",
	},
	{
		key: "test",
		type: "select",
		label: "Test Data",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Not Selected",
			},
			{
				value: "__FALSE_OR_EMPTY__",
				label: "Exclude Test Data",
			},
			{
				value: "__TRUE__",
				label: "Show Only Test Data",
			},
		],
	},
];

const _AGGREGATION_FILTERS = (metricId) => [
	{
		key: "aggregationPeriod",
		type: "select",
		label: "Aggregation Period",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Default",
			},
			{
				value: "HOUR",
				label: "Hour",
			},
			{
				value: "DAY",
				label: "Day",
			},
			{
				value: "WEEK",
				label: "Week",
			},
			{
				value: "MONTH",
				label: "Month",
			},
			{
				value: "YEAR",
				label: "Year",
			},
			{
				value: "ALL",
				label: "All",
			},
		],
	},
	{
		key: "aggregationWindow",
		type: "select",
		label: "Aggregation Window",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Not Selected",
			},
			{
				value: "DAY_1",
				label: "Today",
			},
			{
				value: "DAY_2_1",
				label: "Yesterday",
			},
			{
				value: "DAY_7",
				label: "Last 7 Days",
			},
			{
				value: "DAY_14_7",
				label: "Previous 7 Days",
			},
			{
				value: "WEEK_1",
				label: "This Week",
			},
			{
				value: "WEEK_2_1",
				label: "Prev Week",
			},
			{
				value: "WEEK_2",
				label: "Last 2 Weeks",
			},
			{
				value: "MONTH_1",
				label: "This Month",
			},
			{
				value: "MONTH_2_1",
				label: "Prev Month",
			},
			{
				value: "MONTH_2",
				label: "Last 2 Months",
			},
			{
				value: "MONTH_6",
				label: "Last 6 Months",
			},
			{
				value: "MONTH_12",
				label: "Last 12 Months",
			},
			{
				value: "YEAR_1",
				label: "This Year",
			},
			{
				value: "YEAR_2_1",
				label: "Prev Year",
			},
		],
	},
	{
		key: "aggregationFields",
		type: "autocomplete",
		label: "Aggregation Fields",
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/analytics/data/metrics-fields",
			multiple: true,
			preloadDefault: true,
			listenEvent: "analyticsJobsRevision",
		},
	},
];

const _DEFAULT_SETTINGS = {
	grid: {
		tilesPerRow: 2,
		itemsPerRow: 2,
	},
	table: {
		variant: "skinny",
		orderBy: ["period__view.label", "owner", "entity"],
	},
	pagination: {
		enabled: true,
		resetScroll: true,
	},
	order: {
		defaut: {
			key: "period__view.label",
			dir: "desc",
		},
	},
};

const adminAnalyticsDataPointsList = {
	urlGET: "api/analytics/data/datapoints",
	idKey: "_id",
	listenEvent: "analyticsJobsRevision",
	segmentKey: "ADMIN_ANALYTICS_DATA_POINTS",
	content: {
		..._DEFAULT_SETTINGS,
		filters: {
			search: true,
			items: [
				{
					key: "metric",
					type: "autocomplete",
					label: "Metric",
					autocomplete: {
						provider: "smarthop",
						url: "api/analytics/data/metrics",
						preloadDefault: true,
					},
				},
				..._AGGREGATION_FILTERS(),
				{
					key: "period",
					type: "autocomplete",
					label: "Period",
					autocomplete: {
						provider: "smarthop",
						url: "api/analytics/data/periods",
						preloadDefault: true,
					},
					rules: {
						params: {
							valueRef: "metric",
							paramKey: "options_metric_id_search",
						},
					},
				},
				..._GENERIC_FILTERS,
			],
		},
		groups: { items: [..._GENERIC_GROUPS] },
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "test", type: "bool", label: "Test", group: "internal" },
			{ key: "metric", type: "id", label: "Metric ID", group: "internal" },
			{ key: "metric__view.label", type: "metric__view", builder: (item) => item.metric__view, label: "Metric" },
			..._GENERIC_FIELDS,
		],
	},
};

const adminAnalyticsMetricDataPointsList = {
	urlGET: "api/analytics/data/datapoints",
	idKey: "_id",
	listenEvent: "analyticsJobsRevision",
	segmentKey: "ADMIN_ANALYTICS_METRIC_DATA_POINTS",
	cache: { key: "metric_data_points:metricId", disableInit: true },
	content: {
		..._DEFAULT_SETTINGS,
		table: {
			variant: "skinny",
			orderBy: ["period__view.label", "owner", "entity"],
			fixHeight: 0.5,
		},
		filters: {
			search: true,
			items: [..._AGGREGATION_FILTERS(), ..._GENERIC_FILTERS],
		},
		groups: { items: [..._GENERIC_GROUPS] },
		items: [{ key: "_id", type: "id", label: "ID", group: "internal" }, ..._GENERIC_FIELDS],
	},
};

export { adminAnalyticsDataPointsList, adminAnalyticsMetricDataPointsList };
