// Dependencies
import Typography from "@material-ui/core/Typography";

// Utils
import { payrollEntrySection } from "app/main/profile/drivers/EnterprisePayrollJournalEntrySection";
import { DRIVER_JOURNAL_ENTRY_STATUSES, PAYROLL_STATUSES } from "app/main/consts";

const createForm = ({ errors, payrollId, carrierId, payrollStatus, data, isInvestor, userId }) => {
	const isOpen = payrollStatus === "open";
	let actions = [
		{
			type: "action",
			action: "SUBMIT",
			label: "Save",
			disabled: errors.length,
			confirmation: {
				enable: true,
				message: `You are going to update a addition/deduction. Do you want to continue?`,
			},
		},
	];
	return {
		bottom: {
			classes: { root: "pt-4 md:pt-12" },
			items: actions,
		},
		items: [
			...payrollEntrySection(data?.category !== "MANUAL"),
			...(isInvestor
				? [
						{
							type: "group",
							content: {
								items: [
									{
										key: "truck",
										type: "autocomplete",
										label: "Truck",
										autocomplete: {
											provider: "smarthop",
											url: "api/profile/carriers/:carrierId/investor/:userId/trucks",
											preloadDefault: true,
											params: { options_format_show_carrier: true, options_active_only: true },
										},
									},
								],
							},
						},
				  ]
				: []),
			{
				type: "section",
				label: "Location",
				section: { classes: { label: " -mt-1 " } },
			},
			{
				type: "group",
				content: {
					items: [
						{
							type: "group",
							content: {
								items: [
									{
										key: "payroll_source",
										type: "text",
										label: "Move From",
										viewOnly: true,
										builder: (item) => {
											return (
												<>
													<Typography className="break-words whitespace-normal tracking-wide min-w-100 text-13 lg:text-14">
														{item?.payroll_source}
													</Typography>
													{item?.payroll__view?.description && (
														<Typography className="break-words whitespace-normal tracking-wide text-grey-500 text-12 lg:text-13 mt-2">
															{item?.payroll__view?.description}
														</Typography>
													)}
													{item?.payroll__view?.metadata?.state && (
														<Typography className="break-words whitespace-normal tracking-wide text-grey-500 text-12 lg:text-13 mt-2">
															{PAYROLL_STATUSES[item?.payroll__view?.metadata?.state]}
														</Typography>
													)}
												</>
											);
										},
									},
									{
										key: "payroll_destination",
										type: "autocomplete",
										label: "Move To",
										autocomplete: {
											provider: "smarthop",
											url: `billing/v3/payroll/carrier/${carrierId}`,
											preloadDefault: true,
											listenEvent: "payrollRevision",
											params: {
												enterprise_payroll_destination: true,
												filters: {
													carrier: carrierId,
													payee: userId,
													status: payrollStatus === "open" ? "closed" : "open",
												},
											},
										},
										description: `Moves event from ${
											isOpen ? DRIVER_JOURNAL_ENTRY_STATUSES["PENDING"] : DRIVER_JOURNAL_ENTRY_STATUSES["IN_REVIEW"]
										} to ${
											isOpen ? DRIVER_JOURNAL_ENTRY_STATUSES["IN_REVIEW"] : DRIVER_JOURNAL_ENTRY_STATUSES["PENDING"]
										}`,
									},
								],
							},
						},
					],
				},
			},
		],
	};
};

export default createForm;
