import { useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import moment from "moment";
import uniqid from "uniqid";
import "../../../tree-visualizer/TreeVisualizer.css";
import * as d3 from "d3";
import _ from "lodash";
import createNodeSummary from "../../../tree-visualizer/NodeSummary";
import { selectByType, fetchMap } from "app/store/marketConditions/mapSlice";
import { polygonColors } from "app/main/utils/colorUtils";
import { useSelector, useDispatch } from "react-redux";
import { strategyConnect } from "../../providers/StrategyContextProvider";
import { addDiagnosticMessage, selectPath, unselectPath } from "../../providers/reducer/actions";
import StrategyDrawer from "../strategy-drawer/StrategyDrawer";
import DrawerPortal from "../strategy-drawer/DrawerPortal";
import { useHorizontalScroll } from "@smarthop/hooks/useHorizontalScroll";
import { createPatterns } from "../../utils/strategyUtils";
import StrategyDrawerPortal from "../strategy-drawer/StrategyDrawerPortal";

const adaptStrategyResult = ({ strategy, truckEmptyLocation }) => {
	const { o_lat: lat, o_lng: long, o_city: location, ocluster: marketId } = strategy.paths[0].moves[0].lane;

	const strategyData = {
		strategyResult: {
			legs: strategy.paths
				.map((path) =>
					path.moves.map((move) => ({ ...move, ...{ ...move.lane, _id: null }, path_id: path._id, moveId: move._id }))
				)
				.reduce((acc, curr) => [...acc, ...curr], []),
			paths: strategy.paths.map((path) => ({
				...path,
				legs: path.moves.map((move) => ({ ...move, ...{ ...move.lane, _id: null }, path_id: path._id })),
			})),
		},
		load: {
			city: {
				lat,
				long,
				location,
				marketId,
			},
		},
		truckPosition: truckEmptyLocation,
	};
	return strategyData;
};

function restoreTree(root, treeState) {
	const branchNodes = [];
	function addBranchNodes(node) {
		if (node.children) {
			branchNodes.push(node?.data?.id);
			node.children.forEach(addBranchNodes);
		}
	}
	addBranchNodes(treeState);

	function removeChildren(node) {
		const id = node?.data?.id;
		if (!branchNodes.includes(id)) {
			node._children = node?.children;
			node?._children?.forEach(removeChildren);
			node.children = null;
		} else {
			node._children = null;
			node?.children?.forEach(removeChildren);
		}
	}
	removeChildren(root);
}

function countLeafNodes(tree) {
	let leafCount = 0;
	function helperCount(node) {
		if (!node.children.length) leafCount++;
		else node.children.forEach(helperCount);
	}
	if (!tree.children.length) return 1;
	else tree.children.forEach(helperCount);
	return leafCount;
}

// D3 TREE TOOLS //

function getAllPathDestinations(tree) {
	if (!tree.children?.length) return [];
	const children = [...tree.children];
	const destinations = [];

	for (let child of children) {
		if (!child.children?.length) destinations.push(child);
		else children.push(...child.children);
	}

	return destinations;
}

const validPath = (pathId) => Number.isInteger(pathId);

function isFromPath(node, pathId) {
	if (!validPath(pathId)) return false;
	const { pathId: path, pathIds } = node.data.stop;
	return validPath(path) ? path === pathId : !!pathIds?.includes(pathId);
}

function isAddedToCart(node, selectedPaths) {
	if (_.isEmpty(selectedPaths)) return false;
	return Object.entries(selectedPaths).some(
		([pathId, selectedMoves]) =>
			isFromPath(node, +pathId) && !!selectedMoves.find((selectedMove) => selectedMove.data.id === node.data.id)
	);
}

function getPathMoves(node) {
	const path = [];
	while (node) {
		path.push(node);
		node = node.parent;
	}
	return path;
}

function getPathMovesOrdered(node) {
	return [...getPathMoves(node).map((n) => ({ ...n }))].reverse();
}

const getCummulativeProfit = (index, moveArr) => {
	return moveArr.reduce((acc, d, i) => acc + (i <= index ? d.data.stop.profit : 0), 0);
};

// FORMATTERS
const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

//COLOR TOOL
const nodeColor = (d, i, mapData, pallete) => {
	let marketData = mapData?.heatMap?.find(
		(market) => market.cluster === (!i ? d.data?.stop?.ocluster : d.data?.stop?.dcluster),
		0
	);
	return marketData
		? polygonColors(marketData.profit_best_norm_smooth, 1, Number(marketData.profit_best_exp_smooth), 0)
		: pallete[!i ? d.data?.stop?.o_metacluster : d.data?.stop?.d_metacluster];
};

const filterTreeByFlex = (tree) => {
	const treeCopy = JSON.parse(JSON.stringify(tree));

	const filterFlex = (node) => {
		node.children = node.children.filter((n) => !n.stop?.flex);
		node.children.forEach(filterFlex);
	};

	filterFlex(treeCopy);
	return treeCopy;
};

const StrategyVisualizerContainer = ({
	result,
	controlPanel,
	selectedPath,
	selectedPaths,
	selectPath,
	unselectPath,
	addDiagnosticMessage,
	entryPoint,
}) => {
	const [treeState, setTreeState] = useState(null);
	const [drawerHeader, setDrawerHeader] = useState(null);
	const [drawerBody, setDrawerBody] = useState(null);
	const [firstOpen, setFirstOpen] = useState(true);
	const [closedDrawerHeight, setClosedDrawerHeight] = useState(null);

	const drawerPortalRef = useRef();

	const { useControlPanelState, setControlPanelValue } = controlPanel ?? {};
	const { tree, dateRange, resData } = result ?? {};
	const topLevel = entryPoint === "topLevel";
	let equipment = result?.resData?.strategy?.equipment?.toLowerCase();
	let meanProfit = result?.resData?.strategy?.response?.statistics?.total_profit?.mean || 0;
	let stdProfit = result?.resData?.strategy?.response?.statistics?.total_profit?.std || 0;
	let options = { equipment, strategyProfit: meanProfit, strategyProfitStd: stdProfit };

	useEffect(() => {
		// Cleanup on strategy change
		if (result) unselectPath();
		// eslint-disable-next-line
	}, [result]);

	return tree && !_.isEmpty(controlPanel) ? (
		<>
			<StrategyVisualizer
				treeState={treeState}
				setTreeState={setTreeState}
				tree={tree}
				dateRange={dateRange}
				resData={resData}
				setDrawerHeader={setDrawerHeader}
				setDrawerBody={setDrawerBody}
				useControlPanelState={useControlPanelState}
				setControlPanelValue={setControlPanelValue}
				selectedPath={selectedPath}
				selectedPaths={selectedPaths}
				selectPath={selectPath}
				unselectPath={unselectPath}
				addDiagnosticMessage={addDiagnosticMessage}
				options={options}
			/>
			{!!drawerHeader &&
				(topLevel ? (
					<DrawerPortal ref={drawerPortalRef}>
						<StrategyDrawerPortal
							ref={drawerPortalRef}
							header={drawerHeader}
							body={drawerBody}
							firstOpen={firstOpen}
							strategyReqData={result?.resData?.strategy?.response?.queryData}
							setDrawerHeader={setDrawerHeader}
							setFirstOpen={setFirstOpen}
							closedDrawerHeight={closedDrawerHeight}
							setClosedDrawerHeight={setClosedDrawerHeight}
							disableSaving={() => !!result?.resData?.isMongo}
							topLevel={true}
						/>
					</DrawerPortal>
				) : (
					<StrategyDrawer
						header={drawerHeader}
						body={drawerBody}
						firstOpen={firstOpen}
						strategyReqData={result?.resData?.strategy?.response?.queryData}
						setDrawerHeader={setDrawerHeader}
						setFirstOpen={setFirstOpen}
						closedDrawerHeight={closedDrawerHeight}
						setClosedDrawerHeight={setClosedDrawerHeight}
						disableSaving={() => !!result?.resData?.isMongo}
					/>
				))}
		</>
	) : null;
};

// TREE VISUALIZER LOGIC //

const StrategyVisualizer = ({
	tree,
	dateRange,
	resData,
	setDrawerHeader,
	setDrawerBody,
	options = {},
	useControlPanelState,
	setControlPanelValue,
	treeState,
	setTreeState,
	selectedPath,
	selectedPaths,
	selectPath,
	unselectPath,
	addDiagnosticMessage,
	...props
}) => {
	const { targetProfit, strategyProfit, equipment } = options;
	// THE TREE VISUALIZER V1 NEEDS A BIG REFACTOR TO ACCOUNT FOR MERGING STRATEGIES
	const [profitLine, setProfitLine] = useState([]);
	const [rootState, setRootState] = useState(null);
	const [minMaxProfit, setMinMaxProfit] = useState({});
	//eslint-disable-next-line
	const [strategyCumulativeProfitAvg, setStrategyCumulativeProfitAvg] = useState();
	const [svg, setSvg] = useState(null);

	const { tooltipEnabled, flexPathsEnabled, edgeWidthEnabled, folded, expanded, highlightFlexEnabled } =
		useControlPanelState();

	const SVG_BOTTOM = 350;
	const SCALE_MARGIN_TOP = 100;

	const resetDrawerData = useCallback(() => {
		setProfitLine([]);
		setDrawerHeader && setDrawerHeader();
		setDrawerBody && setDrawerBody();
	}, [setDrawerHeader, setDrawerBody]);

	const svgRef = React.useRef(null);
	let toggleIndex = 0;

	const treeWithoutFlex = filterTreeByFlex(tree);

	// Width of circle node and date visual block
	const nodeRadius = 13;
	const nodeDiameter = nodeRadius * 2;
	const dateRectWidth = nodeDiameter + 120;
	const maxStrokeWidth = 30;
	const totalLeafNodes = countLeafNodes(tree);
	const nodeGapScaler = 2;
	const blurOpacity = 0.1;

	const margin = { left: -120, top: 30, bottom: 30, right: 120 };
	const width = Math.max(window.innerWidth, dateRectWidth * dateRange.length) - margin.left - margin.right;
	// tree height based on leaf nodes
	// window height
	const windowHeight = window.innerHeight - margin.top - margin.bottom;
	const nodeHeightCalc = nodeDiameter * nodeGapScaler * totalLeafNodes;
	const unfoldedHeight = Math.max(nodeHeightCalc, windowHeight);
	const height = folded ? windowHeight : unfoldedHeight;

	const twoDecimals = (num) => Number(num.toFixed(2));

	// Pull market conditions from redux store
	const field = props.inverse ? "dcluster" : "ocluster";
	const toplanes = props.toplanes || props.toplanes === undefined ? "toplanes" : "top100";
	const mapData = useSelector(({ marketConditions }) => selectByType(marketConditions, equipment, field, toplanes));
	const dispatch = useDispatch();

	const xScrollRef = useHorizontalScroll();

	useEffect(() => {
		setTreeState(null);
		resetDrawerData();
	}, [expanded, setTreeState, resetDrawerData]);

	useEffect(() => {
		if (!mapData?.status || mapData?.status === "rejected") {
			dispatch(fetchMap({ equipment, field, toplanes }));
		}
		// eslint-disable-next-line
	}, []);

	//Meta cluster pallete
	const pallete = [
		"#0084ff",
		"#44bec7",
		"#ffc300",
		"#fa3c4c",
		"#d696bb",
		"#839e4d",
		"#cc8d6a",
		"#54243a",
		"#79172d",
		"#606060",
	];

	// Set SVG container
	useEffect(() => {
		setSvg(
			d3
				.select(svgRef.current)
				.attr("width", margin.left + width + margin.right)
				.attr("height", margin.bottom + height + margin.top)
		);
		// eslint-disable-next-line
	}, [folded]);

	useEffect(() => {
		const allPathsFlex = tree?.children?.every((node) => node.stop.flex);
		if (allPathsFlex && !flexPathsEnabled) {
			addDiagnosticMessage({
				type: "warning",
				componentName: "FlexPaths",
				props: {
					enableFlexPaths: () => setControlPanelValue("flexPathsEnabled", true),
				},
			});
		}
		// eslint-disable-next-line
	}, [flexPathsEnabled]);

	// Virtual Contract Mini
	useEffect(() => {
		if (svg) {
			// Reset Strategy Details
			if (!validPath(selectedPath)) {
				resetDrawerData();
			}

			svg.selectAll("*").remove();
			const duration = 750;
			// declares a tree layout and assigns the size
			const treemap = d3.tree().size([height, width]);

			// Assigns parent, children, height, depth, and reset root profit
			let root = d3.hierarchy(flexPathsEnabled ? tree : treeWithoutFlex, (d) => d.children);
			root.x0 = height / 2;
			root.y0 = 0;
			root.isRoot = true;
			root.data.stop.profit = 0;
			setRootState(root);

			// Calculate destinations min and max profit
			const destinations = getAllPathDestinations(root);
			const movesArr = destinations.map(getPathMovesOrdered);
			const allCummulativeProfits = movesArr.map((movArr) => movArr.map((m, i, arr) => getCummulativeProfit(i, arr)));
			const allCummulativeProfitsSorted = allCummulativeProfits.flat().sort((a, b) => a - b);
			const { 0: minProfit, [allCummulativeProfitsSorted.length - 1]: maxProfit } = allCummulativeProfitsSorted;
			setMinMaxProfit({ minProfit, maxProfit });

			// get cummulative profit average of all paths
			const strategyCumulativeProfitAverage = allCummulativeProfits.reduce(
				(acc, currArr, i, arr) => acc + currArr[currArr.length - 1] / arr.length,
				0
			);
			setStrategyCumulativeProfitAvg(strategyCumulativeProfitAverage);

			// Collapse tree after the second node
			if (expanded) {
				root?.children?.forEach(expand);
			} else if (!treeState) {
				root?.children?.forEach(collapse);
			} else {
				restoreTree(root, treeState);
			}

			// Object Blocks
			const dateBlock = svg.append("g").attr("id", "datesBlock");
			const linksBlock = svg.append("g").attr("id", "linksBlock");
			const nodesBlock = svg.append("g").attr("id", "nodesBlock");

			// Set profitLinesBlock to append profitLine inside it for visual order
			svg.append("g").attr("id", "profitLinesBlock");

			// Generate date squares
			const dateGroups = dateBlock
				.selectAll("g.dates")
				.data(dateRange)
				.enter()
				.append("g")
				.classed("dates", true)
				.style("fill-opacity", 0.3);

			// Create the Date Rectangles
			dateGroups
				.append("rect")
				.attr("x", (d, i) => 180 - nodeRadius + i * dateRectWidth)
				.attr("y", 0)
				.attr("width", dateRectWidth)
				.attr("height", height + margin.top + SVG_BOTTOM)
				.attr("fill", (d, i) => {
					// Create diagonal pattern
					const { diagonalPatternId, filledDiagonalPatternId } = createPatterns(svg, uniqid());
					const dow = moment(d, "ddd MMM-DD").day();
					const weekend = dow === 6 || dow === 0;
					return i % 2
						? weekend
							? `url(#${diagonalPatternId})`
							: "none"
						: weekend
						? `url(#${filledDiagonalPatternId})`
						: "grey";
				})
				.attr("stroke-width", 3);

			dateGroups
				.append("text")
				.text((d) => d)
				.attr("y", 25)
				.attr("x", (d, i) => 180 - nodeRadius + i * dateRectWidth + 40)
				.attr("fill", (d) => {
					const dow = moment(d, "ddd MMM-DD").day();
					const weekend = dow === 6 || dow === 0;
					return weekend ? "rgb(255,125, 0)" : "black";
				})
				.style("font-size", 15);

			update(root);

			// Collapse node and decendants
			function collapse(d) {
				if (d.children) {
					d._children = d.children;
					d._children.forEach(collapse);
					d.children = null;
				}
			}

			function expand(d) {
				if (d._children) {
					d.children = d._children;
					d.children.forEach(expand);
					d._children = null;
				}
			}

			function update(origin) {
				// Assigns the x and y position for the nodes
				const treeData = treemap(root);

				// Compute the new tree layout.
				const nodes = treeData.descendants();
				const links = treeData.descendants().slice(1);
				const hourToDateColumnWidthScale = d3.scaleLinear().domain([0, 2359]).range([0, dateRectWidth]);

				const getDayDiff = (date) => moment(date).diff(root.data.stop.start_date, "days");
				const getHoursDiff = (date) => moment(date).diff(root.data.stop.start_date, "hours") - getDayDiff(date) * 24;
				const getHoursDiffMilitary = (date) => getHoursDiff(date) * 100;
				const initialOffset = hourToDateColumnWidthScale(moment(root.data.stop.start_date).hours() * 100);
				const rootPosition = 180 + initialOffset;
				nodes.forEach((d, i) => {
					d.x += margin.top;
					if (!i) d.y = rootPosition;
					else {
						const daysOffset = getDayDiff(d.data.stop.end_date) * dateRectWidth;
						const hoursOffset = hourToDateColumnWidthScale(getHoursDiffMilitary(d.data.stop.end_date));
						d.y = rootPosition + daysOffset + hoursOffset;
					}
				});

				/* =================
					NODE LOGIC
				================= */

				const node = nodesBlock.selectAll("g.node").data(nodes, (n) => n.id || (n.id = ++toggleIndex));

				// Use parent position for new node
				const nodeEnter = node
					.enter()
					.append("g")
					.attr("class", "node")
					.attr("transform", () => `translate(${origin.y0},${origin.x0})`)
					.on("click", click);

				// Tooltip div
				let tooltip = d3
					.select("#top-tree-container")
					.append("div")
					.attr("class", "tooltip")
					.style("position", "fixed")
					.style("z-index", "10000")
					.style("visibility", "hidden")
					.style("width", "300px");

				// Circle
				nodeEnter
					.append("circle")
					.attr("class", "node")
					.attr("r", 1e-6)
					.style("stroke", (d, i) => `${!i || +d.data.stop.payment ? "green" : "#AB2E13"}`)
					.style("stroke-width", (d) => `${(+d.data.stop.rpm ? +d.data.stop.rpm : 1) * 1.5}px`)
					.style(
						"opacity",
						(d) => d.data.id === "root" || !validPath(selectedPath) || (isFromPath(d, selectedPath) ? 1 : blurOpacity)
					);

				nodeEnter
					.on("mousemove", function () {
						return tooltip.style("top", "0px").style("right", "50px");
					})
					.on("mouseout", function () {
						return tooltip.style("visibility", "hidden");
					})
					.on("mouseover", function (d, i) {
						if (!tooltipEnabled) return;
						tooltip.html(createNodeSummary(d.data.stop, i));
						return tooltip.style("visibility", "visible");
					});

				nodeEnter
					.append("line")
					.style("stroke", "lightgreen")
					.style("stroke-width", (d) => (!d.children && !d._children ? 3 : 0))
					.attr("y1", (d) => (+d.data.stop.maxPayment / +d.data.stop.totalPayment) * -20 || 0)
					.attr("y2", (d) => (+d.data.stop.minPayment / +d.data.stop.totalPayment) * 20 || 0);

				// Add easy tooltip using title tag
				nodeEnter
					.append("title")
					.text((d) =>
						d.data.cumulativeProfit ? `Route Cumulative Profit: $${twoDecimals(d.data.cumulativeProfit)}` : ""
					);

				// Label
				nodeEnter
					.append("text")
					.attr("dy", ".35em")
					.attr("x", (d) => (d.children || d._children ? -nodeRadius : nodeRadius))
					.attr("text-anchor", (d) => (d.children || d._children ? "end" : "start"))
					.text((d, i) => (!i ? d.data.stop.o_city : d.data.stop.d_city)); // city

				nodeEnter
					.select("text")
					.style(
						"fill-opacity",
						(d) => d.data.id === "root" || !validPath(selectedPath) || (isFromPath(d, selectedPath) ? 1 : blurOpacity)
					);

				// ======
				// UPDATE
				// ======

				const nodeUpdate = nodeEnter.merge(node);

				// Translate to coordinates
				nodeUpdate
					.transition()
					.duration(duration)
					.attr("transform", (d) => `translate(${d.y},${d.x})`);

				// Style
				nodeUpdate
					.select("circle.node")
					.attr("r", nodeRadius)
					.style("fill", (d, i) => nodeColor(d, i, mapData, pallete))
					.attr("cursor", "pointer");

				// Clear visible nodes
				const nodeExit = node
					.exit()
					.transition()
					.duration(duration)
					.attr("transform", () => `translate(${origin.y},${origin.x})`)
					.remove();

				// Reduce radius to hide node
				nodeExit.select("circle").attr("r", 1e-8);

				// Reduce label opacity
				nodeExit.select("text").style("fill-opacity", 1e-6);

				/* =================
					LINK LOGIC
				================= */

				const link = linksBlock.selectAll("path.link").data(links, (d) => d.id);

				// Use parent position for new link
				const linkEnter = link
					.enter()
					.insert("path", "g")
					.attr("class", "link")
					.attr("d", () => {
						const o = { x: origin.x0, y: origin.y0 };
						return bezier(o, o);
					})
					.style(
						"stroke",
						(d) =>
							`${
								isAddedToCart(d, selectedPaths)
									? "purple"
									: highlightFlexEnabled && d.data.stop.flex && d.data.stop.load
									? "Orange"
									: highlightFlexEnabled && d.data.stop.flex
									? "Yellow"
									: d.data.stop.load
									? "LightSeaGreen"
									: d.data.stop.isTripLine
									? "purple"
									: d.data.stop.empty && !d.data.stop.loaded_miles
									? "red"
									: d.data.stop.taken
									? "#062246"
									: d.data.stop.booked
									? "orange"
									: "#778c6b"
							}`
					)
					.style("stroke-width", (d) =>
						!d.data.stop.loaded_miles
							? "1px"
							: d.data.stop.avg_volume && !isNaN(d.data.stop.avg_volume) && edgeWidthEnabled
							? `${Math.round(d.data.stop.avg_volume > maxStrokeWidth ? maxStrokeWidth : d.data.stop.avg_volume)}px`
							: "4px"
					)
					.style("opacity", (d) => !validPath(selectedPath) || (isFromPath(d, selectedPath) ? 1 : blurOpacity));

				const linkUpdate = linkEnter.merge(link);

				// Transition to parent's origin
				linkUpdate
					.transition()
					.duration(duration)
					.style("stroke-dasharray", (d) => (+d.data.stop.payment || d.data.stop.isTripLine ? 0 : "5,5"))
					.attr("d", (d) => bezier(d, d.parent));

				link
					.exit()
					.transition()
					.duration(duration)
					.attr("d", () => {
						const o = { x: origin.x, y: origin.y };
						return bezier(o, o);
					})
					.remove();

				// Record previous position
				nodes.forEach((d) => {
					d.x0 = d.x;
					d.y0 = d.y;
				});

				svgRef.current.style.height = height + SVG_BOTTOM;

				// Generate bezier curve for links
				function bezier(s, d) {
					const path = `M ${s.y} ${s.x}
										C ${(s.y + d.y) / 2} ${s.x},
											${(s.y + d.y) / 2} ${d.x},
											${d.y} ${d.x}`;

					return path;
				}

				function click(d) {
					if (!flexPathsEnabled && !treeWithoutFlex.children.length) {
						addDiagnosticMessage({
							type: "warning",
							componentName: "FlexPaths",
							props: {
								enableFlexPaths: () => setControlPanelValue("flexPathsEnabled", true),
							},
						});
						return;
					}
					if (!d.children && !d._children) {
						tooltip.style("visibility", "hidden");
						// traverse nodes all the way up to the root
						const pathMoves = [...getPathMoves(d).map((n) => ({ ...n }))].reverse();
						setDrawerHeader && setDrawerHeader(pathMoves);
						setProfitLine(pathMoves);
						setDrawerBody && setDrawerBody(adaptStrategyResult(resData));
						const pathId = d.data.stop.pathId;
						selectPath(pathId);
						setTreeState(root);
					} else {
						resetDrawerData();
						unselectPath();
					}
					if (d.children) {
						d._children = d.children;
						d.children = null;
					} else {
						d.children = d._children;
						d._children = null;
					}
					update(d);
				}
			}
		}
		// eslint-disable-next-line
	}, [
		tree,
		expanded,
		folded,
		svg,
		edgeWidthEnabled,
		tooltipEnabled,
		flexPathsEnabled,
		highlightFlexEnabled,
		selectedPath,
		treeState,
	]);

	useEffect(() => {
		/* =================
				PROFIT LINES LOGIC
			================= */

		if (svg && svgRef.current) {
			// Clear Axis and profit line
			svg.select("#profitLinesBlock").select("*").remove();

			// Reset profit line values
			if (profitLine.length) {
				profitLine[0].data.stop.profit = 0;
				profitLine[0].data.stop.payment = 0;
				profitLine[0].data.id = "root";
			}

			const paymentDots = profitLine;
			const paymentMinima = minMaxProfit?.minProfit * 1.333; // add 25% padding
			const paymentMaxima = minMaxProfit?.maxProfit * 1.333; // add 25% padding
			const paymentScale = d3
				.scaleLinear()
				.domain([paymentMinima, paymentMaxima])
				.range([0, height - SCALE_MARGIN_TOP]);

			const t = d3.transition().duration(1000);

			paymentDots.forEach((pd, i) => (pd.data.cummulativeProfit = getCummulativeProfit(i, paymentDots)));

			const profitLinesBlock = svg.select("#profitLinesBlock");

			// Add Y Axis
			const yAxisScale = d3
				.scaleLinear()
				.domain([paymentMaxima, paymentMinima])
				.range([0, height - SCALE_MARGIN_TOP]);
			const yAxis = d3.axisRight(yAxisScale);
			profitLinesBlock.append("g").attr("class", "vertical-axis").attr("transform", "translate(25,100)").call(yAxis);

			// Payment circles
			const circlesGroup = profitLinesBlock.selectAll("g.profit").data(paymentDots, (d) => d.data.id);

			const circlesGroupEnter = circlesGroup.enter().append("g").classed("profit", true);

			// Reference line for Zero
			circlesGroupEnter
				.append("rect")
				.attr("x", (d) => 0)
				.attr("y", (d) => height - paymentScale(d.data.cummulativeProfit))
				.attr("width", (d, i) => (!i ? svgRef.current.clientWidth : 0))
				.attr("height", (d, i) => (!i ? 2 : 0))
				.attr("stroke-width", 0)
				.attr("fill", "#aaa");
			// Reference line for Target Profit
			circlesGroupEnter
				.append("line")
				.style("stroke", "#aaa")
				.style("stroke-width", 2)
				.style("stroke-dasharray", "4,4")
				.attr("x1", (d) => 0)
				.attr("y1", (d) => height - paymentScale(targetProfit))
				.attr("x2", (d, i) => svgRef.current.clientWidth)
				.attr("y2", (d) => height - paymentScale(targetProfit));
			// Reference line for Mean Strategy Profit
			circlesGroupEnter
				.append("line")
				.style("stroke", "#00a")
				.style("stroke-width", 2)
				.style("stroke-dasharray", "4,4")
				.attr("x1", (d) => 0)
				.attr("y1", (d) => height - paymentScale(strategyProfit))
				.attr("x2", (d, i) => svgRef.current.clientWidth)
				.attr("y2", (d) => height - paymentScale(strategyProfit));

			const circlesEnter = circlesGroupEnter
				.append("circle")
				.style("fill", "black")
				.attr("cy", (d) => height + 100)
				.attr("cx", (d) => d.y)
				.attr("r", 7);

			const circlesUpdate = circlesEnter.merge(circlesGroup);

			circlesUpdate
				.transition(t)
				.attr("cy", (d) => height - paymentScale(d.data.cummulativeProfit))
				.attr("cx", (d) => d.y);

			// Reference line from profit circle to move node
			circlesGroupEnter
				.append("line")
				.style("stroke", "#333")
				.style("stroke-width", 2)
				.style("stroke-dasharray", "3,3")
				.attr("x1", (d) => d.y)
				.attr("y1", (d) => d.x)
				.attr("x2", (d) => d.y)
				.attr("y2", (d) => height - paymentScale(d.data.cummulativeProfit));

			circlesGroup
				.exit()
				.transition()
				.duration(333)
				.attr("cy", (d, i) => height + 100)
				.attr("cx", (d, i) => d.y)
				.remove();

			// cummulativeProfit labels
			const labelGroup = circlesGroupEnter.append("g");

			labelGroup
				.append("rect")
				.attr("x", (d) => d.y - 10)
				.attr("y", (d) => height - paymentScale(d.data.cummulativeProfit) + 10)
				.attr("width", 66)
				.attr("height", 30)
				.style("fill", "white");

			labelGroup
				.append("text")
				.text((d, i) => currencyFormatter.format(getCummulativeProfit(i, paymentDots)))
				.style("font-size", "18px")
				.style("fill", (d, i) =>
					getCummulativeProfit(i, paymentDots) < 0
						? "red"
						: getCummulativeProfit(i, paymentDots) > targetProfit
						? "green"
						: "#222"
				)
				.attr("x", (d) => d.y)
				.attr("y", (d) => height - paymentScale(d.data.cummulativeProfit) + 32)
				.attr("text-anchor", "start");

			// PAYMENT LINES
			const line = profitLinesBlock.selectAll("path.profit").data(paymentDots.slice(1), (d) => d.id);

			// Use parent position for new link
			const lineEnter = line
				.enter()
				.insert("path", "g")
				.attr("class", "profit")
				.style("stroke-width", 3)
				.style("stroke", "black")
				.style("fill", "none")
				.attr("d", flatCalc);

			lineEnter
				.append("text")
				.attr("dy", ".35em")
				.attr("x", (d) => 20)
				.attr("text-anchor", (d) => (d.children || d._children ? "end" : "start"))
				.text((d, i) => (!i ? d.data.stop.o_city : d.data.stop.d_city));

			const lineUpdate = lineEnter.merge(line);

			lineUpdate.transition(t).attr("d", edgeCalc);

			line.exit().transition().duration(333).attr("d", flatCalc).remove();

			// CREATE ZERO LINE
			line
				.insert("path", "g")
				.attr("class", "profit")
				.style("stroke-width", 2)
				.style("stroke", "red")
				.style("fill", "none")
				.attr("d", flatCalc);

			// PROFIT LINE TOOLS
			function edgeCalc(d, i) {
				const o = paymentDots[i];
				const result = calcEdge(o, d);
				return result;
			}
			function calcEdge(s, d) {
				const path = `M ${s.y} ${height - paymentScale(s.data.cummulativeProfit)}
										L ${d.y} ${height - paymentScale(d.data.cummulativeProfit)}`;

				return path;
			}
			function flatCalc(d, i) {
				if (!d?.data || !paymentDots.length) return [];
				const o = paymentDots[i];
				const result = flatLine(o, d);
				return result;
			}

			function flatLine(s, d) {
				const path = `M ${s?.y || 0} ${height + 100}
										L ${d?.y || 0} ${height + 100}`;

				return path;
			}
		}
		// eslint-disable-next-line
	}, [profitLine, minMaxProfit, rootState, resData, svg, targetProfit]);

	return (
		<div id="top-tree-container" style={{ position: "relative", overflow: "hidden" }}>
			<div ref={xScrollRef} className="tree-container" width={width}>
				<svg id="tree-visualizer-svg" ref={svgRef} width={width} height={height} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	controlPanel: state.controlPanel,
	result: state.result,
	selectedPath: state.selectedPath,
	selectedPaths: state.selectedPaths,
	entryPoint: state.entryPoint,
});

const mapDispatchToProps = (dispatch) => ({
	selectPath: (pathId) => dispatch(selectPath(pathId)),
	unselectPath: () => dispatch(unselectPath()),
	addDiagnosticMessage: (diagnosticMessage) => dispatch(addDiagnosticMessage(diagnosticMessage)),
});

export default strategyConnect(mapStateToProps, mapDispatchToProps)(StrategyVisualizerContainer);
