const loadCacheLogs = {
	urlGET: "api/cache/loads_logs",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "ADMIN_LOAD_CACHE_LOGS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["owner", "processTime"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "owner",
					type: "autocomplete",
					label: "Broker",
					autocomplete: {
						provider: "smarthop",
						url: "api/consts/owners",
						preloadDefault: true,
					},
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID" },
			{ key: "owner", type: "text", label: "Broker" },
			{ key: "loadsProcessedCount", type: "text", label: "Loads processed" },
			{ key: "loadsRejectedCount", type: "text", label: "Loads rejected" },
			{ key: "loadsExpiredCount", type: "text", label: "Loads expired" },
			{ key: "loadDeletedCount", type: "text", label: "Loads deleted" },
			{ key: "processTime", type: "text", label: "Process time" },
			{ key: "created_at", type: "date", label: "Created at" },
		],
	},
};

export default loadCacheLogs;
