import moment from "moment";
import { Icon, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import {
	COLLECTION_STATUSES_LABELS,
	INVOICE_STATUSES_ICONS,
	INVOICE_STATUSES_LABELS,
	INVOICE_STATUSES_SP_LABELS_ADMIN,
} from "app/main/consts/tripInvoiceConsts";

const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const adminTripInvoicingConfig = ({ isAdmin, brokerId, variant }) => ({
	urlGET: `api/trip-invoicing${brokerId ? "/broker/" + brokerId : "/invoices"}`,
	idKey: "_id",
	listenEvent: ["tripsRevision"],
	segmentKey: "TRIP_INVOICING",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["createdAt", "submitted_date", "due_date", "days_age"],
		},
		order: {
			defaut: {
				key: variant === "SMARTPAY" ? "submitted_date" : "createdAt",
				dir: "desc",
			},
		},
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		filters: {
			countVisible: 4,
			search: true,
			items: [
				{
					key: "smartpay_eligible",
					type: "select",
					label: "Factoring Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "All Invoices",
						},
						{
							value: "__TRUE__",
							label: "SmartPay",
						},
						{
							value: "__FALSE_OR_EMPTY__",
							label: "Self-Managed",
						},
					],
				},
				{
					key: "status",
					type: "select",
					label: "Invoice Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "PENDING",
							label: "[Self-Managed] Pending",
						},
						{
							value: "GENERATED",
							label: "[Self-Managed] Generated",
						},
						{
							value: "SUBMITTED",
							label: "[Self-Managed] Submitted",
						},
						{
							value: "PAID",
							label: "[Self-Managed] Paid",
						},
						{
							value: "CANCELED",
							label: "[Self-Managed] Canceled",
						},
						{
							value: "__ACTIONABLE__",
							label: "[SmartPay] Actionable (Submitted, In Review, Approved)",
						},
						{
							value: "SP_SUBMITTED",
							label: "[SmartPay] Submitted",
						},
						{
							value: "SP_IN_REVIEW",
							label: "[SmartPay] In Review",
						},
						{
							value: "SP_APPROVED",
							label: "[SmartPay] Approved",
						},
						{
							value: "SP_FUNDED",
							label: "[SmartPay] Funded",
						},
						{
							value: "SP_CHANGE_REQUESTED",
							label: "[SmartPay] Change Requested",
						},
						{
							value: "SP_REJECTED",
							label: "[SmartPay] Rejected",
						},
					],
				},
				{
					key: "collection_status",
					type: "select",
					label: "Collection Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__ACTIONABLE__",
							label: "[SmartPay] Actionable (In Collection)",
						},
						{
							value: "AGING",
							label: "[SmartPay] Aging",
						},
						{
							value: "IN_COLLECTION",
							label: "[SmartPay] In Collection",
						},
						{
							value: "COLLECTED",
							label: "[SmartPay] Collected",
						},
						{
							value: "COLLECTED_WITH_WARNINGS",
							label: "[SmartPay] Collected with Warnings",
						},
						{
							value: "UNCOLLECTIBLE",
							label: "[SmartPay] Uncollectible",
						},
					],
				},
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					translate: "",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						params: {
							sortOrder: "asc",
							sortBy: "carrierid",
							filters: { customer_id: "__NOT_EMPTY__" },
						},
					},
				},
				{
					key: "delivered_date",
					type: "dateRange",
					label: "Trip Delivery Date",
					shortcutPriority: "long",
				},
				{
					key: "carriers_type",
					type: "select",
					label: "Internal Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
			],
		},
		defaults:
			variant === "SMARTPAY"
				? {
						initial: {
							filters: {
								smartpay_eligible: "__TRUE__",
								status: "__NOT_SELECTED__",
								collection_status: "__NOT_SELECTED__",
								...(PROD_DB ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
							},
							groups: { collectionStatus: true, submittionDate: true, fundAmount: true },
						},
						items: [
							{
								label: "All",
								filters: {
									smartpay_eligible: "__TRUE__",
									status: "__NOT_SELECTED__",
									collection_status: "__NOT_SELECTED__",
								},
							},
							{
								label: "Actionable (To Fund)",
								filters: {
									smartpay_eligible: "__TRUE__",
									status: "__ACTIONABLE__",
									collection_status: "__NOT_SELECTED__",
								},
							},
							{
								label: "Funded",
								filters: {
									status: "SP_FUNDED",
									smartpay_eligible: "__TRUE__",
									collection_status: "__NOT_SELECTED__",
								},
							},
							{
								label: "Aging",
								filters: {
									status: "__NOT_SELECTED__",
									smartpay_eligible: "__TRUE__",
									collection_status: "AGING",
								},
							},
							{
								label: "Actionable (To Collect)",
								filters: {
									smartpay_eligible: "__TRUE__",
									status: "__NOT_SELECTED__",
									collection_status: "__ACTIONABLE__",
								},
							},
							{
								label: "Collected",
								filters: {
									status: "__NOT_SELECTED__",
									smartpay_eligible: "__TRUE__",
									collection_status: "__COLLECTED__",
								},
							},
						],
				  }
				: {
						initial: {
							filters: {
								smartpay_eligible: "__FALSE_OR_EMPTY__",
								status: "__NOT_SELECTED__",
								...(PROD_DB ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
							},
							groups: { creationDate: true, submittionDate: true },
						},
						items: [
							{
								label: "All",
								filters: {
									smartpay_eligible: "__FALSE_OR_EMPTY__",
									status: "__NOT_SELECTED__",
								},
							},
							{
								label: "Pending",
								filters: {
									smartpay_eligible: "__FALSE_OR_EMPTY__",
									status: "PENDING",
								},
							},
							{
								label: "Generated",
								filters: {
									smartpay_eligible: "__FALSE_OR_EMPTY__",
									status: "GENERATED",
								},
							},
							{
								label: "Submitted",
								filters: {
									smartpay_eligible: "__FALSE_OR_EMPTY__",
									status: "SUBMITTED",
								},
							},
							{
								label: "Paid",
								filters: {
									smartpay_eligible: "__FALSE_OR_EMPTY__",
									status: "PAID",
								},
							},
							{
								label: "Cancelled",
								filters: {
									smartpay_eligible: "__FALSE_OR_EMPTY__",
									status: "CANCELED",
								},
							},
						],
				  },
		groups: {
			items: [
				{
					key: "collectionStatus",
					type: "checkbox",
					label: "Collection Status",
				},
				{
					key: "creationDate",
					type: "checkbox",
					label: "Creation Date",
				},
				{
					key: "submittionDate",
					type: "checkbox",
					label: "Submittion Date",
				},
				{
					key: "dueDate",
					type: "checkbox",
					label: "Due Date",
				},
				{
					key: "fundAmount",
					type: "checkbox",
					label: "Fund Amount",
				},
			],
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			dialogBuilder: (item) => ({
				viewId: "TRIP_INVOICE_VIEW",
				dataIds: { carrierId: item.carrier, tripId: item.trip_data.trip, invoiceId: item._id },
			}),
		},
		items: [
			{
				key: "status",
				type: "component",
				builder: (item) => {
					const label = INVOICE_STATUSES_LABELS[item.status] ?? INVOICE_STATUSES_SP_LABELS_ADMIN[item.status];
					const icon = INVOICE_STATUSES_ICONS[item.status];
					return (
						<div className="flex flex-row items-center">
							<Icon className={(icon?.color ?? "") + " text-22 ml:text-24"}>{icon?.name ?? "receipt"}</Icon>
							<Typography className="text-12 ml:text-13 ml-3">{label ?? item.status}</Typography>
						</div>
					);
				},
				label: ["Invoice", "Status"],
			},
			{
				key: "collection_status",
				type: "component",
				label: ["Collection", "Status"],
				group: "collectionStatus",
				builder: (item) => {
					const label = COLLECTION_STATUSES_LABELS[item.collection_status];
					const icon = INVOICE_STATUSES_ICONS[item.collection_status];
					return (
						<div className="flex flex-row items-center">
							{icon && <Icon className={(icon?.color ?? "") + " text-22 ml:text-24"}>{icon?.name}</Icon>}
							<Typography className="text-12 ml:text-13 ml-3">{label ?? item.collection_status ?? "-"}</Typography>
						</div>
					);
				},
			},
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
					showCarrierAccountType: true,
				},
			},
			{
				key: "invoice__view",
				type: "invoicing__view",
				label: "Invoice",
				viewConfig: {
					showSmartpayEligibility: true,
				},
			},
			{
				key: "trip__view",
				type: "trip__view",
				label: "Trip",
				viewConfig: {
					showBroker: true,
				},
			},
			{
				key: "createdAt",
				label: "Created On",
				type: "date",
				group: "creationDate",
			},
			{
				key: "submitted_date",
				label: "Submitted On",
				type: "date",
				group: "submittionDate",
			},
			{
				key: "due_date",
				label: "Due Date",
				type: "date",
				group: "dueDate",
			},
			{
				key: "days_age",
				label: "Age",
				type: "text",
				table: { align: "right" },

				builder: (item) =>
					!item.days_age ? null : ["UNCOLLECTIBLE", "COLLECTED"].includes(item.collection_status) ||
					  ["PAID", "CANCELED"].includes(item.status) ? null : item.days_age >= 0 ? (
						<Tooltip
							classes={{
								tooltip:
									"bg-white text-13 font-normal border-1 border-grey-400 " +
									(item.days_to_due_date < 0 ? " text-red " : " text-black  "),
							}}
							title={
								item.days_to_due_date < 0
									? "Missed due date " +
									  moment(item.due_date).format("MMM Do YYYY") +
									  " by " +
									  item.days_to_due_date * -1 +
									  " day(s)"
									: item.days_to_due_date + " day(s) till due date " + moment(item.due_date).format("MMM Do YYYY")
							}
						>
							<div className="flex flex-row">
								{item.days_to_due_date < 0 && <Icon className="text-16 text-red mt-1">report_problem</Icon>}
								<Typography className={"text-12 ml:text-13 ml-3 " + (item.days_to_due_date < 0 ? " text-red " : "")}>
									{item.days_age}
								</Typography>
							</div>
						</Tooltip>
					) : null,
			},
			{
				key: "amount",
				label: ["Invoice", "Amount"],
				type: "currency",
			},
			{
				key: "smartpay_fund_amount",
				label: ["Fund", "Amount"],
				type: "currency",
				group: "fundAmount",
				builder: (item) => item?.smartpay?.amount_to_fund,
			},
		],
	},
});

export default adminTripInvoicingConfig;
