import ViewTitleSection from "./ViewTitleSection";
import ViewContentSection from "./ViewContentSection";
import Typography from "@material-ui/core/Typography";
import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";

function MessageViewTruckReminder(props) {
	const { message } = props;
	const variant = props.variant;

	return (
		<SmarthopChatMessageViewContainer
			useFullWidth={false}
			enableLeftBorder={true}
			variant={variant}
			headerConfig={{
				showDivider: true,
				title: `Truck Reminder: ${message?.content?.type}`,
			}}
			contentFields={[
				{
					type: "LABEL_VALUE",
					label: "Frequency",
					value: message?.content?.repeats,
				},
				{
					type: "TEXT",
					value: message?.content?.notes,
				},
			]}
			headerComponents={
				<ViewTitleSection
					variant={variant}
					title={`Truck Reminder: ${message?.content?.type}`}
					message={""}
					badge={message?.content?.repeats}
				/>
			}
			contentComponents={
				<ViewContentSection variant={variant}>
					<ViewTitleSection
						variant={variant}
						title={`Truck Reminder: ${message?.content?.type}`}
						message={""}
						badge={message?.content?.repeats}
					/>
					<Typography className="font-normal text-14 md:text-15 mt-4 max-w-450 md:max-w-600 lg:max-w-450">
						{message?.content?.notes}
					</Typography>
				</ViewContentSection>
			}
		/>
	);
}
export default MessageViewTruckReminder;
