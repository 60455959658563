import { reportFiltersForm } from "app/main/utils/fileUtils";
import { startCurrentMonth, endCurrentMonth } from "app/main/utils/performanceUtils";

const datePickerConfig = {
	startDateTitle: "Start Delivery Date",
	endDateTitle: "End Delivery Date",
	type: "datetime",
	startDefaultValue: startCurrentMonth,
	endDefaultValue: endCurrentMonth,
	notClearable: true,
	buttonConfig: { alwaysEnabled: true },
};

const adminCarriersPerformanceReportForm = reportFiltersForm(
	"api/reports/users/:userId/performance/carriers/download/csv",
	"Download Carriers Report",
	"Carriers performance report.csv",
	"text/csv",
	datePickerConfig
);

const adminTrucksPerformanceReportForm = reportFiltersForm(
	"api/reports/users/:userId/performance/trucks/download/csv",
	"Download Trucks Report",
	"Trucks performance report.csv",
	"text/csv",
	datePickerConfig
);

const adminDispatchersPerformanceReportForm = reportFiltersForm(
	"api/reports/users/:userId/performance/dispatchers/download/csv",
	"Download Dispatchers Report",
	"Dispatchers performance report.csv",
	"text/csv",
	datePickerConfig
);

const trucksPerformanceReportByCarrierForm = reportFiltersForm(
	"api/reports/carriers/:carrierId/performance/trucks/download/csv",
	"Download Trucks Report",
	"Trucks performance report.csv",
	"text/csv",
	datePickerConfig
);

const trucksPerformanceReportByDispatcherForm = reportFiltersForm(
	"api/reports/dispatchers/:dispatcher/performance/trucks/download/csv",
	"Download Trucks Report",
	"Trucks performance report.csv",
	"text/csv",
	datePickerConfig
);

const truckPerformanceTripsReportForm = reportFiltersForm(
	"api/reports/carriers/:carrierId/performance/trucks/:truckId/download/csv",
	"Download Truck Trips Report",
	"Truck performance trips report.csv",
	"text/csv",
	datePickerConfig
);

const adminTruckPerformanceTripsReportForm = reportFiltersForm(
	"api/reports/carriers/:carrierId/performance/trucks/:truckId/download/csv?admin=true",
	"Download Truck Trips Report",
	"Truck performance trips report.csv",
	"text/csv",
	datePickerConfig
);

const adminCarrierPerformanceTripsReportForm = reportFiltersForm(
	"api/reports/carriers/:carrierId/performance/download/csv?admin=true",
	"Download Carrier Trips Report",
	"Carrier performance trips report.csv",
	"text/csv",
	datePickerConfig
);

const CarrierPerformanceTripsExternalReportForm = reportFiltersForm(
	"api/reports/carriers/:carrierId/performance/external/download/csv",
	"Download External Trips Report",
	"External trips report.csv",
	"text/csv",
	datePickerConfig
);

const carrierPerformanceTripsReportForm = reportFiltersForm(
	"api/reports/carriers/:carrierId/performance/download/csv",
	"Download Carrier Trips Report",
	"Carrier performance trips report.csv",
	"text/csv",
	datePickerConfig
);

const dispatcherPerformanceTripsReportForm = reportFiltersForm(
	"api/reports/dispatchers/:dispatcher/performance/download/csv",
	"Download Dispatcher Trips Report",
	"Dispatcher performance trips report.csv",
	"text/csv",
	datePickerConfig
);

export {
	adminCarriersPerformanceReportForm,
	adminTrucksPerformanceReportForm,
	adminDispatchersPerformanceReportForm,
	adminCarrierPerformanceTripsReportForm,
	adminTruckPerformanceTripsReportForm,
	trucksPerformanceReportByCarrierForm,
	trucksPerformanceReportByDispatcherForm,
	truckPerformanceTripsReportForm,
	carrierPerformanceTripsReportForm,
	CarrierPerformanceTripsExternalReportForm,
	dispatcherPerformanceTripsReportForm,
};
