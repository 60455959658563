const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const trucks = {
	urlGET: "api/profile/trucks",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "INTERNAL_TRUCKS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["plan__view.metadata.current_available_date", "carrier__view.metadata.carrierid"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "carrierid",
				dir: "desc",
			},
		},
		defaults: {
			// Initial value of fields
			initial: {
				filters: {
					truck_verified: "__NOT_SELECTED__",
					truck_active: "ACTIVE",
					carrier_active: "ACTIVE",
					...(PROD_DB ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
				},
			},
			// Additional quick filters
			items: [
				{
					label: "Active",
					filters: { truck_active: "ACTIVE", carrier_active: "ACTIVE" },
				},
				{
					label: "Inactive",
					filters: { truck_active: "INACTIVE", carrier_active: "ACTIVE" },
				},
			],
		},
		filters: {
			search: true,
			items: [
				{
					key: "status_truck_plan",
					type: "select",
					label: "Plan Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ONSALES",
							label: "Current (With Sales)",
						},
						{
							value: "PUBLISHED",
							label: "Current (Active)",
						},
						{
							value: "DRAFT",
							label: "Current (Draft)",
						},
						{
							value: "__CURRENT__",
							label: "Current (All)",
						},
						{
							value: "COVERED",
							label: "Last (Covered)",
						},
						{
							value: "EXPIRED",
							label: "Last (Expired)",
						},
						{
							value: "NO_TRUCK_PLAN",
							label: "Last (No plan)",
						},
						{
							value: "CANCELLED",
							label: "Last (Cancelled)",
						},
						{
							value: "__EMPTY_ALL__",
							label: "Last (All)",
						},
					],
				},
				{
					key: "status_truck_load",
					type: "select",
					label: "Load Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "HAS_LOAD",
							label: "Has Active Load",
						},
						{
							value: "EMPTY",
							label: "Empty",
						},
					],
				},
				{
					key: "truck_active",
					type: "select",
					label: "Truck Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ACTIVE",
							label: "Active",
						},
						{
							value: "INACTIVE",
							label: "Inactive",
						},
					],
				},
				{
					key: "carrier_active",
					type: "select",
					label: "Carrier Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ACTIVE",
							label: "Active",
						},
						{
							value: "INACTIVE",
							label: "Inactive",
						},
					],
				},
				{
					key: "carrierAccountType",
					type: "select",
					label: `Carrier Account Type`,
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "MAIN_ACCOUNT",
							label: "Main Account",
						},
						{
							value: "SUB_ACCOUNT",
							label: "Sub Account",
						},
					],
				},
				{
					key: "carriers_type",
					type: "select",
					label: "Internal Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
				{
					key: "show_only_team_trucks",
					type: "checkbox",
					label: "Show Only Team Trucks",
				},
			],
		},
		groups: {
			items: [
				{
					key: "tags",
					type: "checkbox",
					label: "Show Tags",
				},
				{
					key: "tracking",
					type: "checkbox",
					label: "Show Tracking",
				},
				{
					key: "status",
					type: "checkbox",
					label: "Show Status",
				},
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "Truck ID", group: "internal" },
			{ key: "carrier", type: "id", label: "Carrier ID", group: "internal" },
			{ key: "driver", type: "id", label: "Driver ID", group: "internal" },
			{
				key: "carrier__view.metadata.carrierid",
				builder: (item) => item.carrier__view,
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
					showTestCarrier: true,
					showCarrierAccountType: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showTeam: true,
					showStatus: true,
				},
			},
			{
				key: "driver__view",
				type: "driver__view",
				label: "Primary Driver",
				viewConfig: {
					showPhone: true,
				},
			},
			{
				key: "driver_secondary__view",
				type: "driver__view",
				label: "Secondary Driver",
				viewConfig: {
					showPhone: true,
				},
			},
			{
				key: "dispatcher__view",
				type: "user__view",
				label: "Dispatcher",
				viewConfig: {
					showEmail: true,
				},
			},
			{
				key: "active_trips__view",
				builder: (item) => item.active_trips__view,
				type: "trips__view",
				label: "Trips",
				viewConfig: {
					showStatus: true,
				},
			},
			{
				key: "plan__view.metadata.current_available_date",
				builder: (item) => (item.plan__view?.value ? item.plan__view : { carrier: item.carrier, truck: item.truck }),
				type: "plan__view",
				label: "Plan",
				viewConfig: {
					showStatus: true,
				},
			},
			{
				key: "tags",
				type: "text",
				label: "Tags",
				maxLengthStart: 25,
				group: "tags",
			},
			{ key: "verified", label: "Verified", type: "bool", group: "status" },
			{ key: "active", label: "Active", type: "bool", group: "status" },
		],
	},
};

export default trucks;
