import PrivacyPolicy from "app/main/home/PrivacyPolicy";

const PrivacyPolicyConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: "/privacy-policy",
			component: PrivacyPolicy,
		},
	],
};

export default PrivacyPolicyConfig;
