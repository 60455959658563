export const CARRIER_EXTERNAL_FACTORING_CONFIG = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "company",
					type: "text",
					label: "Company",
					required: true,
				},
				{
					key: "address",
					type: "text",
					label: "Address",
					required: true,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "notes",
					type: "text",
					multiline: { rows: 2 },
					label: "Details",
				},
			],
		},
	},
	{ type: "action", action: "SUBMIT" },
];

export const carrierExternalFactoringFormConfig = {
	urlGET: "api/profile/carriers/:carrierId/factoring-settings",
	urlPUT: "api/profile/carriers/:carrierId/factoring-settings",
	urlPOST: "api/profile/carriers/:carrierId/factoring-settings",
	idKey: "_id",
	triggerEvent: ["profileRevision", "invoiceOnboardingRevision"],
	listenEvent: ["profileRevision", "invoiceOnboardingRevision"],
	header: {
		create: { label: "Add Payment Information" },
		edit: { label: "Edit Payment Information" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [...CARRIER_EXTERNAL_FACTORING_CONFIG],
		},
	},
};
