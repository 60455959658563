import { lazy } from "react";
import { build } from "../tools/ConfigBuilder";
import { authRoles } from "app/main/consts";

export default build(
	[
		{
			path: "/home",
			component: lazy(() => import("./HomePage")),
		},
	],
	null,
	[...authRoles.all, ...authRoles.bot, ...authRoles.deactivated]
);
