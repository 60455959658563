import { combineReducers } from "@reduxjs/toolkit";
import login from "./loginSlice";
import register from "./registerSlice";
import user from "./userSlice";
import clock from "./clockSlice";
import account from "./accountSlice";

const authReducers = combineReducers({
	user,
	login,
	register,
	clock,
	account,
});

export default authReducers;
