import moment from "moment";
import { useState, useMemo } from "react";
import { Icon, Button, IconButton, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import clsx from "clsx";
// Utils
import { DisplaySection } from "app/main/utils/uiUtils";
import SmarthopAutocompleteField from "@smarthop/form/fields/SmarthopAutocompleteField";
import { SmarthopPickerField } from "@smarthop/form";
import { useEffect } from "react";
import SmarthopNumberField from "@smarthop/form/fields/SmarthopNumberField";

function LoadSearchViewLocationsEdit({ data, innerRef }) {
	const [form, setForm] = useState({ data: null, window: { pickup: false, locations: [], delivery: false } });
	const [showErrors, setShowErrors] = useState(false);

	const validation = useMemo(() => {
		if (!form.data) return null;

		let invalid = false;
		let pickup = null;
		let locations = [];
		let delivery = null;

		if (form?.window?.pickup && moment(form.data.ready) > moment(form.data.pickupby)) {
			pickup = "Latest pickup date must be after earliest puckup date";
			invalid = true;
		}

		form.data.locations.forEach((s, i) => {
			let message = null;
			if (!s.location || !s.location_date) {
				message = "Provide pickup/delivery date(s)";
				invalid = true;
			} else if (
				form?.window?.locations?.[i] &&
				moment(form.data.location_date) > moment(form.data.location_window_date)
			) {
				message = "Latest date must be after earliest date";
				invalid = true;
			}
			locations.push(message);
		});

		if (moment(form.data.ready) > moment(form.data.dropready)) {
			delivery = "Delivery date must be after pickup date";
			invalid = true;
		} else if (form?.window?.delivery && moment(form.data.dropready) > moment(form.data.deliverby)) {
			delivery = "Latest delivery date must be after earliest delivery date";
			invalid = true;
		}

		return { invalid, errors: { pickup, locations, delivery } };
	}, [form]);

	const invalid = validation?.invalid;
	useEffect(() => {
		if (!innerRef) return;

		innerRef.current = {
			getValues: () => {
				if (invalid) {
					setShowErrors(true);
					return;
				} else {
					return form.data;
				}
			},
		};
		return () => {
			innerRef.current = null;
		};
	}, [innerRef, form, invalid]);

	const loadId = data.load_id;
	useEffect(() => {
		const copy = JSON.parse(JSON.stringify(data));
		const flags = { pickup: false, locations: [], delivery: false };
		if (!copy.locations) copy.locations = [];

		if (copy.ready === copy.pickupby) {
			flags.pickup = false;
		} else if (copy.ready && !copy.pickupby) {
			flags.pickup = false;
			copy.pickupby = copy.ready;
		} else if (!copy.ready && copy.pickupby) {
			flags.pickup = false;
			copy.ready = copy.pickupby;
		} else {
			flags.pickup = true;
		}

		copy.locations.forEach((item, i) => {
			if (item.location_date === item.location_window_date) {
				flags.locations[i] = false;
			} else if (item.location_date && !item.location_window_date) {
				flags.locations[i] = false;
				item.location_window_date = item.location_date;
			} else if (!item.location_date && item.location_window_date) {
				flags.locations[i] = false;
				item.location_window_date = item.location_date;
			} else {
				flags.locations[i] = true;
			}
		});

		if (copy.dropready === copy.deliverby) {
			flags.delivery = false;
		} else if (copy.dropready && !copy.deliverby) {
			flags.delivery = false;
			copy.deliverby = copy.dropready;
		} else if (!copy.dropready && copy.deliverby) {
			flags.delivery = false;
			copy.deliverby = copy.dropready;
		} else {
			flags.delivery = true;
		}

		setForm({ window: flags, data: copy });
		// eslint-disable-next-line
	}, [loadId]);

	const updateFormData = (update) => {
		setForm((currentData) => {
			return { window: currentData.window, data: { ...currentData.data, ...update } };
		});
	};

	const onStopAction = (key, action) => {
		let locations = [...form.data.locations];
		let locationsW = [...form.window.locations];

		if (action === "remove") {
			locations.splice(key, 1);
			locationsW.splice(key, 1);
		} else if (action === "up") {
			const element = locations[key];
			const replace = locations[key - 1];
			locations[key - 1] = element;
			locations[key] = replace;
			const elementW = locationsW[key];
			const replaceW = locationsW[key - 1];
			locationsW[key - 1] = elementW;
			locationsW[key] = replaceW;
		} else if (action === "down") {
			const element = locations[key];
			const replace = locations[key + 1];
			locations[key + 1] = element;
			locations[key] = replace;
			const elementW = locationsW[key];
			const replaceW = locationsW[key - 1];
			locationsW[key + 1] = elementW;
			locationsW[key] = replaceW;
		}

		setForm((currentData) => {
			return { window: { ...currentData.window, locations: locationsW }, data: { ...currentData.data, locations } };
		});
	};

	const onAddStop = (type) => {
		let locations = [...form.data.locations];
		let locationsW = [...form.window.locations];
		locations.push({
			location: null,
			location__view: null,
			location_date: null,
			location_window_date: null,
			type,
		});
		locationsW.push(false);

		setForm((currentData) => {
			return { window: { ...currentData.window, locations: locationsW }, data: { ...currentData.data, locations } };
		});
	};

	const enableWindow = (value, key, index) => {
		const window = { ...form.window };
		const data = { ...form.data };
		if (index >= 0) {
			window[key][index] = value;
			data.locations[index].location_window_date = null;
		} else {
			window[key] = value;
			if (key === "delivery") data.deliverby = null;
			if (key === "pickup") data.pickupby = null;
		}

		setForm((currentData) => {
			return { window, data };
		});
	};

	if (!form.data) return null;

	return (
		<div className="flex flex-col w-full md:min-w-600 max-w-600">
			<div className="flex mx-2">
				<SmarthopNumberField
					label="DH-O"
					value={form.data?.distance_to_origin}
					onChange={(val) => updateFormData({ distance_to_origin: val })}
					suffix={" Miles"}
					field={{ noErrorMessage: true }}
					required={true}
				/>
			</div>
			<div className="-mx-10">
				<DisplaySection
					title={
						<div className="w-full flex-1 flex flex-row">
							<div className={"flex flex-1"}>Pickup</div>
							<div className="flex">
								<FormControlLabel
									className="w-full -mt-16 -mb-14"
									control={
										<Checkbox
											checked={form?.window?.pickup}
											onChange={(e) => enableWindow(e.target.checked, "pickup")}
											name="gilad"
											size="small"
										/>
									}
									label={<Typography className="text-12 -ml-4 -mr-8">Use Date Windows</Typography>}
								/>
							</div>
						</div>
					}
				>
					<div className="flex flex-col">
						<div className={`w-full`}>
							<SmarthopAutocompleteField
								name="origin"
								label="Origin"
								optionsValue={[form.data.origin__view ?? { label: form.data.origin, value: form.data.origin }]}
								required={true}
								autocomplete={{
									provider: "trimblemaps",
									params: { componentRestrictions: { country: "us" } },
									icon: "location",
								}}
								field={{ noErrorMessage: true }}
								onSelected={(optionsValues) => {
									updateFormData({ origin__view: optionsValues[0], origin: optionsValues?.[0]?.value });
								}}
							/>
						</div>
						<div className={`w-full flex flex-row space-x-8`}>
							<SmarthopPickerField
								name="ready"
								label={(form?.window?.pickup ? "Earliest " : "") + `Pickup`}
								picker={{ notClearable: true, type: "datetime" }}
								field={{ noErrorMessage: true }}
								required={true}
								value={form.data?.ready}
								onChange={(value) => {
									updateFormData({ ready: value });
								}}
							/>
							{form?.window?.pickup && (
								<SmarthopPickerField
									name="pickupby"
									label="Latest Pickup"
									picker={{ notClearable: false, type: "datetime" }}
									field={{ noErrorMessage: true }}
									value={form.data?.pickupby}
									onChange={(value) => {
										updateFormData({ pickupby: value });
									}}
								/>
							)}
						</div>
						{showErrors && validation?.errors?.pickup && (
							<Typography className="text-12 text-red ml-2 -mt-4">{validation.errors.pickup}</Typography>
						)}
					</div>
				</DisplaySection>

				{form.data.locations.map((location, key) => {
					return (
						<DisplaySection
							key={"stop_" + key}
							title={
								<div className="w-full flex flex-row -mt-10">
									<div className={"flex"}>Stop #{key + 1}</div>
									<div className="flex flex-1 space-x-4 ml-10">
										<IconButton
											className={clsx("bg-red-300 h-20 w-20 text-white hover:bg-gray-200")}
											color="secondary"
											onClick={() => onStopAction(key, "remove")}
											size="small"
										>
											<Icon className="text-16">remove</Icon>
										</IconButton>
										{key !== 0 && (
											<IconButton
												className={clsx("bg-gray-500 h-20 w-20 text-white hover:bg-gray-200")}
												onClick={() => onStopAction(key, "up")}
												size="small"
											>
												<Icon className="text-16">arrow_drop_up</Icon>
											</IconButton>
										)}
										{key !== form.data.locations.length - 1 && (
											<IconButton
												className={clsx("bg-gray-500 h-20 w-20 text-white hover:bg-gray-200")}
												onClick={() => onStopAction(key, "down")}
												size="small"
											>
												<Icon className="text-16">arrow_drop_down</Icon>
											</IconButton>
										)}
									</div>
									<div className="flex">
										<FormControlLabel
											className="w-full -mt-16 -mb-14"
											control={
												<Checkbox
													checked={form?.window?.locations?.[key]}
													onChange={(e) => enableWindow(e.target.checked, "locations", key)}
													name="gilad"
													size="small"
												/>
											}
											label={<Typography className="text-12 -ml-4 -mr-8">Use Date Windows</Typography>}
										/>
									</div>
								</div>
							}
						>
							<div className="flex flex-col">
								<div className={`w-full`}>
									<SmarthopAutocompleteField
										name="location"
										label={location.type === "PICKUP" ? "Pickup" : "Delivery"}
										optionsValue={[location?.location__view]}
										required={true}
										autocomplete={{
											provider: "trimblemaps",
											params: { componentRestrictions: { country: "us" } },
											icon: "location",
										}}
										field={{ noErrorMessage: true }}
										onSelected={(optionsValues) => {
											const updatedRecord = {
												...location,
												location: optionsValues[0]?.value,
												location__view: optionsValues[0],
											};
											const updatedLocations = [...form.data.locations];
											updatedLocations[key] = updatedRecord;
											updateFormData({ locations: updatedLocations });
										}}
									/>
								</div>
								<div className={`w-full flex flex-row space-x-8`}>
									<SmarthopPickerField
										name="location_date"
										label={
											(form?.window?.locations?.[key] ? "Earliest " : "") +
											(location.type === "PICKUP" ? `Pickup` : `Delivery`)
										}
										picker={{ notClearable: true, type: "datetime" }}
										field={{ noErrorMessage: true }}
										required={true}
										value={location?.location_date}
										onChange={(value) => {
											const updatedRecord = { ...location, location_date: value };
											const updatedLocations = [...form.data.locations];
											updatedLocations[key] = updatedRecord;
											updateFormData({ locations: updatedLocations });
										}}
									/>
									{form?.window?.locations?.[key] && (
										<SmarthopPickerField
											name="location_window_date"
											label={location.type === "PICKUP" ? "Latest Pickup" : "Latest Delivery"}
											picker={{ notClearable: false, type: "datetime" }}
											field={{ noErrorMessage: true }}
											value={location?.location_window_date}
											onChange={(value) => {
												const updatedRecord = { ...location, location_window_date: value };
												const updatedLocations = [...form.data.locations];
												updatedLocations[key] = updatedRecord;
												updateFormData({ locations: updatedLocations });
											}}
										/>
									)}
								</div>
								{showErrors && validation?.errors?.locations?.[0] && (
									<Typography className="text-12 text-red ml-2 -mt-4">{validation?.errors?.locations?.[0]}</Typography>
								)}
							</div>
						</DisplaySection>
					);
				})}
				<div className="flex flex-row w-full space-x-12 items-center justify-center -mt-6">
					<Button size="small" variant="outlined" color="primary" onClick={() => onAddStop("PICKUP")}>
						Add Pickup
					</Button>
					<Button size="small" variant="outlined" color="primary" onClick={() => onAddStop("DELIVERY")}>
						Add Delivery
					</Button>
				</div>
				<DisplaySection
					title={
						<div className="w-full flex-1 flex flex-row">
							<div className={"flex flex-1"}>Delivery</div>
							<div className="flex">
								<FormControlLabel
									className="w-full -mt-16 -mb-14"
									control={
										<Checkbox
											checked={form?.window?.delivery}
											onChange={(e) => enableWindow(e.target.checked, "delivery")}
											name="gilad"
											size="small"
										/>
									}
									label={<Typography className="text-12 -ml-4 -mr-8">Use Date Windows</Typography>}
								/>
							</div>
						</div>
					}
				>
					<div className="flex flex-col">
						<div className={`w-full`}>
							<SmarthopAutocompleteField
								name="destination"
								label="Destination"
								optionsValue={[
									form.data.destination__view ?? {
										label: form.data.destination,
										value: form.data.destination,
									},
								]}
								required={true}
								autocomplete={{
									provider: "trimblemaps",
									params: { componentRestrictions: { country: "us" } },
									icon: "location",
								}}
								field={{ noErrorMessage: true }}
								onSelected={(optionsValues) => {
									updateFormData({ destination__view: optionsValues?.[0], destination: optionsValues?.[0]?.value });
								}}
							/>
						</div>
						<div className={`w-full flex flex-row space-x-8`}>
							<SmarthopPickerField
								name="dropready"
								label={(form?.window?.delivery ? "Earliest " : "") + `Delivery`}
								required={true}
								picker={{ notClearable: true, type: "datetime" }}
								field={{ noErrorMessage: true }}
								value={form.data?.dropready}
								onChange={(value) => {
									updateFormData({ dropready: value });
								}}
							/>
							{form?.window?.delivery && (
								<SmarthopPickerField
									name="delivery"
									label="Latest Delivery"
									picker={{ type: "datetime", notClearable: false }}
									field={{ noErrorMessage: true }}
									value={form.data?.deliverby}
									onChange={(value) => {
										updateFormData({ deliverby: value });
									}}
								/>
							)}
						</div>
						{showErrors && validation?.errors?.delivery && (
							<Typography className="text-12 text-red ml-2 -mt-4">{validation?.errors?.delivery}</Typography>
						)}
					</div>
				</DisplaySection>
			</div>
		</div>
	);
}

export default LoadSearchViewLocationsEdit;
