import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

// Form
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import adminReleaseFormConfig from "./adminReleaseFormConfig";

// Service
import { createRelease, getRelease, updateRelease } from "app/services/releaseServices";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

function AdminReleaseForm(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const dataIds = props?.dataIds;
	const onDone = props?.onDone;

	const mode = dataIds?.mode;
	const releaseId = dataIds?.releaseId;
	const isCreate = mode === "CREATE";

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		props?.setTitle?.(isCreate ? "Add New Release" : "Edit Release");
		props?.setSize?.("max-w-5xl");
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (dataIds?.mode === "CREATE") return;
		setLoading(true);

		getRelease(releaseId).then(
			(response) => {
				setLoading(false);
				setData(response);
			},
			(err) => {
				setLoading(false);
				setError(err.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);
	}, [releaseId, dataIds?.mode]);

	const handledSave = () => {
		setProcessing(true);
		const promise = isCreate ? createRelease(data) : updateRelease(releaseId, data);

		promise.then(
			() => {
				setProcessing(false);
				showSnackbar(snackbar, "Successfully Saved Information", "success");

				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "dataRevision" }));
				onDone?.();
			},
			(err) => {
				setProcessing(false);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);
	};

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
					Loading...
				</Typography>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<>
			<SmarthopFormView
				key={"truck-view"}
				mode={mode}
				content={adminReleaseFormConfig}
				data={data}
				noInitValidation={true}
				dataIds={dataIds}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					setData({ ...data, ...model });
				}}
				onSubmit={() => {
					// Handle logic for updated traile
					handledSave();
				}}
				processing={processing}
			/>
		</>
	);
}

export default AdminReleaseForm;
