import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Stores
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";

//Services
import { getDetailsHistory } from "app/services/historyServices";

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "relative",
		padding: "250px",
	},
}));

function RedirectHistoryForm(props) {
	useEffect(() => {
		props?.setTitle?.("History View");
		// eslint-disable-next-line
	}, []);
	let dataIds = props?.dataIds;
	const { entityId, type, id, view } = dataIds;
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const classes = useStyles();
	useEffect(() => {
		getDetailsHistory(entityId, type, id).then(
			(history) => {
				dispatch(closeFormDialog());
				if (history.compare) {
					const compareHistoryRedirect = {
						viewId: "HISTORY_VIEW",
						dataIds: {
							dataIds: dataIds,
							historyData: history?.arraySelected,
							view,
							viewId: view,
							allHistoryData: history?.allDataHistory,
							historyView: true,
						},
						mode: "VIEW",
					};
					dispatch(openLoadedFormDialog(compareHistoryRedirect));
				} else if (!!dataIds.noHistoryRedirect) {
					dispatch(closeFormDialog());
					dispatch(
						openLoadedFormDialog({
							...dataIds.noHistoryRedirect,
							// Making sure we only load history data, not latest data info
							dataIds: { ...dataIds.noHistoryRedirect?.dataIds, historyView: true },
						})
					);
				}
				setLoading(false);
			},
			(err) => {
				setLoading(false);
				setError("Opps... Something went wrong...");
				console.log(err, "Err");
			}
		);

		//eslint-disable-next-line
	}, []);

	if (loading) {
		return (
			<div className={clsx(classes.progress, "flex flex-col items-center justify-center")}>
				<Typography color="primary" className="flex text-13 text-grey-600 pt-10 pb-8 items-center justify-center">
					Loading...
				</Typography>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex justify-center">
				<Typography
					className="w-full text-center"
					style={{ padding: window?.innerWidth < 960 ? "100px" : "250px" }}
					color="error"
				>
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<Typography
			className="w-full text-center text-16"
			style={{ padding: window?.innerWidth < 960 ? "100px" : "250px" }}
			color="error"
		>
			{"No data found to compare"}
		</Typography>
	);
}
export default RedirectHistoryForm;
