import { createHeaders, createRequest, throwResErrors } from "./requestUtil";

export const getStrategyByLocation = (truckId, carrierId, params) => {
	return createRequest()
		.get(`api/strategy/carriers/${carrierId}/trucks/${truckId}`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
export const getStrategyTreeByTruckId = (truckId, carrierId, params) => {
	return createRequest()
		.get(`api/strategy/carriers/${carrierId}/truck/${truckId}`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const requestStrategy = (truckId, carrierId, params) => {
	return createRequest()
		.post(`api/strategy/carriers/${carrierId}/truck/${truckId}`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLatestStrategyTreeByTruckId = (truckId, carrierId, params) => {
	return createRequest()
		.get(`api/strategy/carriers/${carrierId}/truck/${truckId}/latest`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getStrategyTreeById = (strategyId) => {
	return createRequest()
		.get(`api/strategy/tree/${strategyId}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getStrategyTreeByVirtualContractId = (virtualContractId) => {
	return createRequest()
		.get(`api/strategy/vc/${virtualContractId}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getStrategyFromTempVc = (virtualContractId, params) => {
	return createRequest()
		.get(`api/strategy/vc/${virtualContractId}/compose`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveStrategy = (virtualContractId, data) => {
	return createRequest()
		.post(`api/strategy/vc/${virtualContractId}/save`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLatestStrategyWithLoad = (truckId, carrierId, searchId, loadId) => {
	return createRequest()
		.get(`api/strategy/carriers/${carrierId}/truck/${truckId}/requests/${searchId}/loads/${loadId}/strategy`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const composeStrategy = (data) => {
	return createRequest()
		.post(`api/strategy/compose`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
