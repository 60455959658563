const carrierFuelJournalEntryForm = {
	urlGET: "api/fuel/journal-entries/:entryId",
	urlDELETE: "api/fuel/journal-entries/:entryId",
	urlPOST: "api/fuel/carriers/:carrierId/journal-entries",
	listenEvent: "fuelRevision",
	triggerEvent: "fuelRevision",
	idKey: "_id",
	header: {
		create: { label: "Add Entry" },
		delete: { label: "Delete Entry" },
	},
	messages: {
		create: { success: "Entry created" },
		delete: { success: "Entry deleted" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to permanently delete the entry?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
							},
						],
					},
				},
			],
		},
		view: {
			sameAs: "create",
		},
		create: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "description",
								type: "text",
								label: "Description",
								multiline: { rows: 3 },
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "amount",
								label: "Credit Amount",
								type: "currency",
								required: true,
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierFuelJournalEntryForm;
