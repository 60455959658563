const coordinatesLogs = {
	urlGET: "/api/booking/trips/tracking-locations",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "COORDINATES_LOGS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["loadId", "carrier"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		groups: {
			items: [
				{
					key: "os_data",
					type: "checkbox",
					label: "Show OS",
				},
			],
		},
		filters: {
			search: true,
			items: [
				{
					key: "source",
					type: "select",
					label: "Source",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "TMS", value: "TMS" },
						{ label: "GPS", value: "APP" },
						{ label: "AXLE", value: "AXLE" },
					],
				},
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					field: {
						noErrorMessage: true,
						classes: {},
					},
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						preloadDefault: true,
						listenEvent: "profileRevision",
						params: {
							options_active_only: true,
							options_my_assigned: false,
						},
					},
				},
				{
					key: "truck",
					type: "autocomplete",
					label: "Truck",
					translate: "",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/trucks",
						params: {
							options_active_only: true,
							options_format_show_driver_phone: true,
						},
						preloadDefault: true,
						preloadImmedately: true,
						listenEvent: "profileRevision",
					},
					rules: {
						params: {
							valueRef: "carrier",
							paramKey: "options_carrier_id_search",
						},
					},
				},
			],
		},
		items: [
			{
				key: "data.broker",
				type: "trips__view",
				builder: (item) => item?.trip__view,
				label: "Trip",
				viewConfig: {
					showBroker: true,
				},
			},
			{
				key: "carrier__view.metadata.carrierid",
				type: "carrier__view",
				label: "Carrier",
				builder: (item) => item?.carrier__view,
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showPhone: true,
				},
			},
			{ key: "location", type: "text", label: "Location" },
			{ key: "coordinates", type: "text", label: "Coordinates (lat, long)" },
			{ key: "last_date", type: "date", label: "Date" },
			{ key: "source", type: "text", label: "Source" },
			{ key: "isTest", type: "bool", label: "Test" },
			{
				key: "user__view",
				type: "user__view",
				label: "User",
				viewConfig: {
					showRole: true,
				},
			},
			{ key: "os", type: "text", label: "OS", group: "os_data" },
		],
	},
};

export default coordinatesLogs;
