// Constants
import { FUEL_PIN_VALIDATION_TYPE, FUEL_CUSTOM_PIN, FUEL_TRUCK_ID } from "app/main/consts";

const card_pin = {
	urlGET: "billing/carriers/:carrierId/fuel-cards/:id",
	urlPUT: "billing/carriers/:carrierId/fuel-cards/:id/pin",
	idKey: "_id",
	triggerEvent: "fuelRevision",
	listenEvent: "fuelRevision",
	header: { edit: { label: "Set Fuel Card Pin" } },
	messages: { edit: { success: "Fuel card pin updated." } },
	content: {
		edit: {
			items: [
				{
					key: "pin_validation_type",
					type: "select",
					label: "Select Validation Type",
					options: [
						{
							value: FUEL_CUSTOM_PIN,
							label: FUEL_PIN_VALIDATION_TYPE[FUEL_CUSTOM_PIN],
							showItems: [
								{
									key: "pin_number",
									type: "number",
									label: "Pin Number",
									field: { maxLength: 24 },
									minCharacter: 4,
								},
							],
						},
						{
							value: FUEL_TRUCK_ID,
							label: FUEL_PIN_VALIDATION_TYPE[FUEL_TRUCK_ID],
							showItems: [
								{
									type: "message",
									builder: (item) => `Would you like your pin to be "${item?.truck__view?.description}" ?`,
								},
							],
						},
					],
				},
				{ type: "action", action: "SUBMIT", label: "Update" },
			],
		},
	},
};

export default card_pin;
