import { SmarthopList, postTruckHistoryLogs } from "@smarthop/list";
import { useEffect } from "react";

function PostTruckLogsView(props) {
	useEffect(() => {
		props?.setTitle?.("Post truck transactions logs");
		// eslint-disable-next-line
	}, []);

	const id = props.dataIds.id;
	return <SmarthopList mode="table" config={postTruckHistoryLogs} dataIds={{ id }} />;
}

export default PostTruckLogsView;
