import { Typography, Icon, CircularProgress } from "@material-ui/core";

function LoadSearchAvailability({ load, loading, hasError }) {
	const availabilityInProgress = !hasError && loading && load.hasAvailabilityCheck;
	const loadIsAvailable = !hasError && !loading && load.hasAvailabilityCheck && !load.deleted;
	const notAvailabilityCheck = !hasError && !load.hasAvailabilityCheck;
	const bidOrBookAvailable = load.bidnow || load.booknow;
	const loadNotFullyLoaded = !load.load_id;
	const textColor = loadNotFullyLoaded
		? "text-gray-600"
		: hasError
		? "text-gray-600"
		: loadIsAvailable
		? "text-green-600"
		: notAvailabilityCheck && bidOrBookAvailable
		? "text-gray-600"
		: notAvailabilityCheck
		? "text-gray-600"
		: "";
	const label = loadNotFullyLoaded
		? "Loading full details"
		: hasError
		? "Unable to validate availability digitally"
		: notAvailabilityCheck && !bidOrBookAvailable
		? "Contact the broker to confirm availability"
		: notAvailabilityCheck && bidOrBookAvailable
		? `You can book this load online in just few clicks`
		: availabilityInProgress
		? "Checking availability..."
		: loadIsAvailable
		? "Availability confirmed digitally"
		: "";

	if (load.deleted) {
		return null;
	}

	return (
		<div className="flex flex-row items-center min-h-24 ml:min-h-32">
			{(availabilityInProgress || loadNotFullyLoaded) && <CircularProgress size={16} className="mr-8 text-grey" />}
			{loadIsAvailable && !load.actionInProgress && <Icon className="text-20 text-green-600 mr-4">check_circle</Icon>}
			{!load.actionInProgress && (
				<Typography color="textSecondary" className={`text-12 ml:text-13 text-center leading-tight ${textColor}`}>
					{label}
				</Typography>
			)}
		</div>
	);
}

export default LoadSearchAvailability;
