import { formatDate } from "app/main/utils/dateUtils";

const payrollTripsList = ({ isView }) => ({
	urlGET: "billing/v3/payroll/:payrollId/details/trips",
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision"],
	segmentKey: "PAYROLL_TRIPS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["trips.data.delivery_date", "trips.data.pickup_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "trips.data.delivery_date",
				dir: "desc",
			},
		},
		items: [
			{
				key: "trip__view",
				type: "trip__view",
				label: "Trip",
			},
			{
				key: "pickup_date",
				builder: (item) =>
					formatDate(
						item.data?.pickup_date ?? item.pickup_date,
						item.data?.pickup_window_date ?? item.pickup_window_date
					),
				type: "text",
				label: "Pickup Date",
			},
			{
				key: "delivery_date",
				builder: (item) =>
					formatDate(
						item.data?.delivery_date ?? item.delivery_date,
						item.data?.delivery_window_date ?? item.delivery_window_date
					),
				type: "text",
				label: "Delivery Date",
			},
			{ key: "trip_pay", type: "currency", label: "Trip Pay" },
			{ key: "trip_additions", type: "currency", label: "Trip Additions" },
			{ key: "trip_deductions", type: "currency", label: "Trip Deductions" },
			{
				key: "driver_pay",
				type: "currency",
				label: "Payee Pay",
				viewConfig: {
					colorPositive: "font-medium text-black",
					colorNegative: "text-orange-800",
					colorZero: "text-grey",
				},
			},
		],
	},
});

export default payrollTripsList;
