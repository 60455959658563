const carrierOnboardingInsurance = {
	urlGET: "api/profile/carriers/:carrierId/documents/insurance_certificate",
	urlPUT: "api/profile/carriers/:carrierId/documents/insurance_certificate",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Insurance Certificate" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					type: "group",
					group: { classes: { direction: "flex-col" } },
					content: {
						items: [
							{
								key: "insurance_certificate",
								type: "upload",
								label: "COI",
								upload: {
									url: "api/profile/carriers/:carrierId/files",
									documentType: "carrier",
									fileName: "insurance_certificate",
								},
							},
							{ type: "action", action: "SUBMIT" },
						],
					},
				},
			],
		},
	},
};

export default carrierOnboardingInsurance;
