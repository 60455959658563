import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { global } from "app/services/requestUtil";

export const fetchActions = createAsyncThunk("actionUser/home", async ({ carrierId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.get(`/api/dashboard/carriers/${carrierId}`, { headers: headers });

	return {
		actions: response.data.actions,
		numberActions: response.data?.numberActions,
		fuelCondition: response.data?.fuelCondition,
	};
});

const actionsUserSlice = createSlice({
	name: "actions/list",
	initialState: {},
	reducers: {
		cleanActions: (state) => {
			state.actions = null;
			state.numberActions = 0;
			state.fuelCondition = null;
		},
	},
	extraReducers: {
		[fetchActions.fulfilled]: (state, action) => {
			state.numberActions = action?.payload?.numberActions;
			state.fuelCondition = action?.payload?.fuelCondition;
			state.actions = action?.payload?.actions;
		},
		[fetchActions.rejected]: (state, action) => {
			state.numberActions = 0;
			state.fuelCondition = null;
			state.actions = [];
		},
	},
});
export const { cleanActions } = actionsUserSlice.actions;
export default actionsUserSlice.reducer;
