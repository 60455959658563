import SmartHopWalletPage from "app/main/profile/smarthop-wallet/SmartHopWalletPage.js";
import { useEffect } from "react";
import { SMARTHOP_WALLET_SECTION_LABEL } from "app/main/utils/financeUtils";

const SmartHopWalletView = (props) => {
	useEffect(() => {
		props?.setTitle?.(
			SMARTHOP_WALLET_SECTION_LABEL + (props?.dataIds?.companyName ? ` - ${props?.dataIds?.companyName}` : "")
		);
		// eslint-disable-next-line
	}, [props?.dataIds]);
	return <SmartHopWalletPage params={props.dataIds} isView />;
};

export default SmartHopWalletView;
