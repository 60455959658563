import { Redirect } from "react-router-dom";
import FuseUtils from "@fuse/utils";
import LoginConfig from "app/main/auth/login/LoginConfig";
import LogoutConfig from "app/main/auth/logout/LogoutConfig";
import RegisterConfig from "app/main/auth/register/RegisterConfig";
import RegisterSimpleConfig from "app/main/auth/register/RegisterSimpleConfig";
import Error500PageConfig from "app/main/errors/500/Error500PageConfig";
import Error404PageConfig from "app/main/errors/404/Error404PageConfig";

// Search pages
import LoadSearchRequestConfig from "app/main/search/request/LoadSearchRequestConfig";
import LoadSearchResultConfig from "app/main/search/result/LoadSearchResultConfig";

// Strategy pages
import StrategyConfig from "app/main/strategy/StrategyConfig";

// Admin pages
import UserActionsPageConfig from "app/main/profile/admin/UserActionsPageConfig";
import AdminSearchesPageConfig from "app/main/profile/admin/AdminSearchesPageConfig";
import AdminSearchesDetailsPageConfig from "app/main/profile/admin/AdminSearchesDetailsPageConfig";
import AdminChannelsDashboardPageConfig from "app/main/profile/admin/AdminChannelsDashboardPageConfig";
import AdminChatsDetailsPageConfig from "app/main/profile/admin/AdminChatsDetailsPageConfig";
import AdminBrokersPageConfig from "app/main/profile/admin/AdminBrokersPageConfig";
import AdminInternalLoadsPageConfig from "app/main/profile/admin/AdminInternalLoadsPageConfig";
import AdminFuelPageConfig from "app/main/profile/admin/AdminFuelPageConfig";
import AdminBillingPageConfig from "app/main/profile/admin/AdminBillingPageConfig";
import AdminPlansPageConfig from "app/main/profile/admin/AdminPlansPageConfig";
import AdminCronDashboardPageConfig from "app/main/profile/admin/AdminCronDashboardPageConfig";
import AdminAnalyticsDashboardPageConfig from "app/main/profile/admin/AdminAnalyticsDashboardPageConfig";
import AdminAnalyticsBoardBuilderPageConfig from "app/main/profile/admin/AdminAnalyticsBoardBuilderPageConfig";
import AdminLoadTrackingPageConfig from "app/main/profile/admin/AdminLoadTrackingPageConfig";
import AdminLoadCachePageConfig from "app/main/profile/admin/AdminLoadCacheLogsPageConfig";
import AdminCredentialsPageConfig from "app/main/profile/admin/AdminCredentialsPageConfig";
import AdminFeaturesPageConfig from "app/main/profile/admin/AdminFeaturesPageConfig";
import AdminSavedStrategiesPageConfig from "app/main/profile/admin/AdminSavedStrategiesPageConfig";
import AdminCostsPageConfig from "app/main/profile/admin/AdminCostsPageConfig";
import AdminCommunicationsPageConfig from "app/main/profile/admin/AdminCommunicationsPageConfig";
import AdminVideosPageConfig from "app/main/profile/admin/AdminVideosPageConfig";
import AdminReleasesPageConfig from "app/main/profile/admin/AdminReleasesPageConfig";
import AdminTripInvoicingPageConfig from "app/main/profile/admin/AdminTripInvoicingPageConfig";
import AdminSystemIssuesPageConfig from "app/main/profile/admin/AdminSystemIssuesPageConfig";
import AdminParsingToolsPageConfig from "app/main/profile/admin/AdminParsingToolsPageConfig";
import AdminArchiveCarriersPageConfig from "app/main/profile/admin/AdminArchiveCarriersPageConfig";

// Profile pages
import UserDetailsPageConfig from "app/main/profile/users/UserDetailsPageConfig";
import UsersDashboardPageConfig from "app/main/profile/users/UsersDashboardPageConfig";
import CarriersAdvancedDashboardPageConfig from "app/main/profile/carriers/CarriersAdvancedDashboardPageConfig";
import CarriersDashboardPageConfig from "app/main/profile/carriers/CarriersDashboardPageConfig";
import CarriersCompanyPageConfig from "app/main/profile/carriers/CarriersCompanyPageConfig";
import CarrierUsersPageConfig from "app/main/profile/carriers/CarrierUsersPageConfig";
import DispatcherPageConfig from "app/main/profile/carriers/dispatching/DispatcherPageConfig";
import CarrierSubAccountsPageConfig from "app/main/profile/carriers/dispatching/CarrierSubAccountsPageConfig";
import TripBillingPageConfig from "app/main/profile/carriers/dispatching/TripBillingPageConfig";
import DriverTrucksPageConfig from "app/main/profile/trucks/DriverTrucksPageConfig";
import EnterpriseDriversPayrollPageConfig from "app/main/profile/drivers/EnterpriseDriversPayrollPageConfig";
import EnterpriseInvestorPayrollPageConfig from "app/main/profile/drivers/EnterpriseInvestorPayrollPageConfig";
import TrucksDetailsPageConfig from "app/main/profile/trucks/TrucksDetailsPageConfig";
import AssetsPageConfig from "app/main/profile/assets/AssetsPageConfig";
import ImporterPageConfig from "app/main/profile/axle/ImporterPageConfig";
import BrokersPage from "app/main/profile/integrations/BrokersPageConfig";
import CostsPageConfig from "app/main/profile/costs/CostsPageConfig";
import DeclinedTransactionsPageConfig from "app/main/profile/transactions/DeclinedTransactionsPageConfig";
import LoadDashboardPageConfig from "app/main/profile/load/LoadDashboardPageConfig";
import ChatTruckPageCongfig from "app/main/profile/chat-trucks/ChatTrucksPageConfig";
import UberOAuthPageConfig from "app/main/profile/carriers/oauth/UberOAuthPageConfig";
import SmartHopWalletPageConfig from "app/main/profile/smarthop-wallet/SmartHopWalletPageConfig";
import AdminFactoringRequestsPageConfig from "app/main/profile/admin/factoring/AdminFactoringRequestsPageConfig";
import GoalsPageConfig from "app/main/profile/goals/GoalsPageConfig";
import HomePageConfig from "app/main/home/HomePageConfig";
import PrivacyPolicyConfig from "app/main/home/PrivacyPolicyConfig";
import EnterprisePayrollPageConfig from "app/main/profile/carriers/enterprise-payroll/EnterprisePayrollPageConfig.js";
import InvoicingPageConfig from "app/main/profile/carriers/trip-invoicing/TripInvoicingPageConfig";
import CustomersPageConfig from "app/main/profile/customers/CustomersPageConfig";
import SmartfuelConfig from "app/main/profile/carriers/fuel/SmartfuelConfig";
import DriverSmartfuelPageConfig from "app/main/profile/drivers/DriverSmartfuelPageConfig";

import HistoryPageConfig from "app/main/history/HistoryPageConfig";

// State Machine / Transaction State
import TransactionsStateBookingPageConfig from "app/main/profile/transactionsState/TransactionsStateBookingPageConfig";
import TransactionsStateFinanceTransferFundsPageConfig from "app/main/profile/transactionsState/TransactionsStateFinanceTransferFundsPageConfig";
import TransactionsStateSmarthopWalletClosePageConfig from "app/main/profile/transactionsState/TransactionsStateSmarthopWalletClosePageConfig";
import TransactionsStateCreateBusinessDebitCardPageConfig from "app/main/profile/transactionsState/TransactionsStateCreateBusinessDebitCardPageConfig";
import TransactionsStateCreationTripPageConfig from "app/main/profile/transactionsState/TransactionsStateCreationTripPageConfig";
import TransactionsStateMultiUploadFileConfig from "app/main/profile/transactionsState/TransactionsStateMultiUploadFileConfig";
import TransactionsStateUploadedFileAnalizeConfig from "app/main/profile/transactionsState/TransactionsStateUploadedFileAnalizeConfig";

// Billing pages
import PaymentMethodErrorsPageConfig from "app/main/billing/payments/PaymentMethodErrorsPageConfig";
import BillingPageConfig from "app/main/billing/payments/BillingPageConfig";
import OAuthLinkConfig from "app/main/billing/payments/plaid/OAuthLinkConfig";

// On Boarding
import OnboardingPageConfig from "app/main/onboarding/OnboardingPageConfig";

// Reports
import CarrierReportsPageConfig from "app/main/reports/CarrierReportsPageConfig";

// Native mobile
import NativeFormConfig from "app/main/native/NativeFormConfig";
import NativeTableConfig from "app/main/native/NativeTableConfig";

//direct link
import linkConfig from "app/main/direct-link/linkConfig";
import internalLinkConfig from "app/main/direct-link/internalLinkConfig";

// Short Link
import ShortLinkConfig from "app/main/shorLink/ShortLinkConfig";

// Public View
import ConfirmEmailAliasConfig from "app/main/public-view/confirm-email-alias/ConfirmEmailAliasConfig.js";
import PublicTripEditConfig from "app/main/public-view/trip-edit/PublicTripEditConfig.js";
import PublicTripUploadFilesConfig from "app/main/public-view/trip-upload-files/PublicTripUploadFilesConfig.js";

// Service
import { getRoleLabel, getAuthToken } from "app/services/LoginService";

const routeConfigs = [
	LoginConfig,
	LogoutConfig,
	RegisterConfig,
	RegisterSimpleConfig,
	// Search pges
	LoadSearchRequestConfig,
	LoadSearchResultConfig,
	// Strategy pages
	StrategyConfig,
	// Profile pages
	ChatTruckPageCongfig,
	DriverTrucksPageConfig,
	TrucksDetailsPageConfig,
	AssetsPageConfig,
	ImporterPageConfig,
	BillingPageConfig,
	UserDetailsPageConfig,
	UsersDashboardPageConfig,
	CarriersAdvancedDashboardPageConfig,
	CarriersDashboardPageConfig,
	CarriersCompanyPageConfig,
	CarrierUsersPageConfig,
	DispatcherPageConfig,
	CarrierSubAccountsPageConfig,
	TripBillingPageConfig,
	BrokersPage,
	CostsPageConfig,
	LoadDashboardPageConfig,
	EnterpriseDriversPayrollPageConfig,
	EnterpriseInvestorPayrollPageConfig,
	UserActionsPageConfig,
	UberOAuthPageConfig,
	SmartHopWalletPageConfig,
	AdminFactoringRequestsPageConfig,
	GoalsPageConfig,
	HistoryPageConfig,
	//Home page
	HomePageConfig,
	PrivacyPolicyConfig,
	// Billing Pages
	DeclinedTransactionsPageConfig,
	PaymentMethodErrorsPageConfig,
	OAuthLinkConfig,
	// Admin pages
	AdminSearchesDetailsPageConfig,
	AdminSearchesPageConfig,
	AdminChatsDetailsPageConfig,
	AdminChannelsDashboardPageConfig,
	AdminBrokersPageConfig,
	AdminInternalLoadsPageConfig,
	AdminFuelPageConfig,
	AdminTripInvoicingPageConfig,
	AdminBillingPageConfig,
	EnterprisePayrollPageConfig,
	InvoicingPageConfig,
	SmartfuelConfig,
	DriverSmartfuelPageConfig,
	AdminPlansPageConfig,
	AdminCronDashboardPageConfig,
	AdminAnalyticsDashboardPageConfig,
	AdminAnalyticsBoardBuilderPageConfig,
	AdminLoadTrackingPageConfig,
	AdminLoadCachePageConfig,
	AdminCredentialsPageConfig,
	AdminFeaturesPageConfig,
	AdminSavedStrategiesPageConfig,
	AdminCostsPageConfig,
	AdminCommunicationsPageConfig,
	AdminVideosPageConfig,
	AdminReleasesPageConfig,
	AdminSystemIssuesPageConfig,
	AdminParsingToolsPageConfig,
	AdminArchiveCarriersPageConfig,
	// State Machine / Transaction State
	TransactionsStateBookingPageConfig,
	TransactionsStateCreationTripPageConfig,
	TransactionsStateMultiUploadFileConfig,
	TransactionsStateUploadedFileAnalizeConfig,
	TransactionsStateFinanceTransferFundsPageConfig,
	TransactionsStateSmarthopWalletClosePageConfig,
	TransactionsStateCreateBusinessDebitCardPageConfig,
	// On Boarding
	OnboardingPageConfig,
	CustomersPageConfig,
	// Reports
	CarrierReportsPageConfig,
	// Native
	NativeFormConfig,
	NativeTableConfig,
	// Direct Link
	linkConfig,
	internalLinkConfig,
	// Short Link
	ShortLinkConfig,
	// Public View
	ConfirmEmailAliasConfig,
	PublicTripEditConfig,
	PublicTripUploadFilesConfig,
	// Error pages
	Error500PageConfig,
	Error404PageConfig,
];

function getInitialRedirect() {
	if (window.location.search.includes("redirectLogin")) {
		if (!getAuthToken()) {
			return window?.location?.pathname + window.location.search;
		}
		if (window?.location?.search.includes("PERFORMANCE") && getRoleLabel() === "carrier") {
			return "/profile/goals";
		}
	}
	if (getRoleLabel() === "carrier" && !["IN_REVIEW", "ACCEPTED"].includes(localStorage.getItem("onboardingStatus"))) {
		return "/onboarding?step=-1";
	}
	return "/home";
}

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: "/",
		component: () => <Redirect to={getInitialRedirect()} />,
	},
];

export default routes;
