import React from "react";
import { useState } from "react";
import Typography from "@material-ui/core/Typography";

import Carousel from "react-material-ui-carousel";
import SmarthopConfirmDialog from "../SmarthopConfirmDialog";

function SmarthopImageField(props) {
	const [previewImage, setPreviewImage] = useState(null);

	return (
		<div className="flex w-full px-4">
			<Carousel
				className="w-full"
				stopAutoPlayOnHover={true}
				navButtonsAlwaysVisible={props.items?.length > 1}
				navButtonsAlwaysInvisible={props.items?.length <= 1}
				indicators={props.items?.length > 1}
			>
				{props.items?.map((item) => (
					<div key={item.name} className="flex flex-col items-center cursor-pointer px-10">
						<div className="mb-5">
							<Typography>{item.name}</Typography>
						</div>
						<img
							className={
								"flex justify-center align-center object-contain px-56 " +
								(props.classes?.image ?? " max-h-330 min-h-330 ")
							}
							src={item.url}
							alt={item.name}
							onClick={() => setPreviewImage({ url: item.url, name: item.name })}
						/>
					</div>
				))}
			</Carousel>
			{previewImage?.url && (
				<SmarthopConfirmDialog
					open={true}
					title={previewImage?.name ?? null}
					acceptMsg="Exit"
					onAccept={() => setPreviewImage(null)}
				>
					<div className="w-full h-full">
						<img src={previewImage?.url} alt={previewImage?.name} />
					</div>
				</SmarthopConfirmDialog>
			)}
		</div>
	);
}

export default SmarthopImageField;
