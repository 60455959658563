import React from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { formatTitle } from "./utils";
import { formatDate } from "app/main/utils/dateUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

function ActiveTripsContainer(props) {
	const dispatch = useDispatch();

	const { data } = props;

	return (
		<>
			{formatTitle("Active Trips", false)}
			{!data?.length ? (
				<div className="w-full absolute top-1/2 left-0">
					<Typography className="text-13 text-center" color="textSecondary">
						No Active Trips
					</Typography>
				</div>
			) : (
				<TableContainer>
					<Table sx={{}} style={{}}>
						<TableHead>
							<TableRow>
								{["Load Id", "Status", "Pickup Date", "Delivery Date"].map((n) => (
									<TableCell key={`trips` + n} align="center">
										<Typography color="primary">{n}</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{data?.map((trip, index) => (
								<TableRow key={`trips` + index} sx={{}}>
									<TableCell align="center">
										{
											<Link
												className="cursor-pointer"
												onClick={() => {
													dispatch(
														openLoadedFormDialog({
															viewId: "TRIP_VIEW",
															dataIds: { tripId: trip._id, carrierId: trip.carrier },
														})
													);
												}}
											>
												<Typography className="font-medium text-13 decoration-blue underline" color="primary">
													{trip?.data?.load_id ?? "Load# " + (index + 1)}
												</Typography>
											</Link>
										}
									</TableCell>
									<TableCell align="center">{trip?.data?.status}</TableCell>
									<TableCell align="center">{formatDate(trip?.data?.pickup_date)}</TableCell>
									<TableCell align="center">{formatDate(trip?.data?.delivery_date)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	);
}

export default ActiveTripsContainer;
