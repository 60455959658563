const BROKERS = (equipment) => {
	return {
		key: "brokers",
		type: "autocomplete",
		label: "Select brokers",
		disabled: "",
		autocomplete: {
			provider: "smarthop",
			url: "api/post-truck/brokers/:carrierId",
			multiple: true,
			preloadDefault: true,
			params: { equipment },
		},
	};
};

const COMMENTS = {
	key: "comments",
	type: "text",
	label: "Public comments",
	multiline: { rows: 2 },
	field: { maxLength: 70 },
};

const SUBMIT = () => ({
	type: "action",
	action: "SUBMIT",
});

const BANNER_POWER_ONLY = {
	key: "disclamer",
	type: "banner",
	label: "Only DAT allows to post Power Only trucks",
	banner: {
		icon: "info",
		classes: { icon: "text-blue-600" },
	},
};

const postTruckForm = (equipment) => {
	return {
		items: [...(equipment === "PO" ? [BANNER_POWER_ONLY] : []), BROKERS(equipment), COMMENTS, SUBMIT()],
	};
};

export { postTruckForm };
