import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { markTransactionAsRead, fetchTransactions } from "app/store/transactions/transactionsSlice";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";

function TransactionDialog() {
	const dispatch = useDispatch();
	//States
	const [open, setOpen] = useState(false);
	//Selector
	const notificationTransactions = useSelector(
		({ transactions }) => transactions.transactionsUser.notificationTransactions
	);
	const user = useSelector(({ auth }) => auth.user);
	// get recent transaction
	const transaction = notificationTransactions?.[0];

	useEffect(() => {
		// show recent transaction pop up
		if (notificationTransactions?.length) {
			setOpen(true);
		}
	}, [notificationTransactions]);

	const onCloseDialog = () => {
		setOpen(false);
		dispatch(markTransactionAsRead({ transactionId: transaction?._id }));
		dispatch(fetchTransactions({ userId: user?._id }));
	};

	const onAction = () => {
		onCloseDialog();
		dispatch(openLoadedFormDialog(transaction?.action?.dialog));
	};

	if (!transaction) return null;

	return (
		<Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={onCloseDialog}>
			<DialogContent>
				<div className="flex flex-col w-full py-10">
					<Typography className="text-17 font-semibold border-b-1 w-full mb-10">Notification</Typography>
					<Typography className="text-14 mb-10">{transaction?.action?.message}</Typography>
					<div className="flex flex-row justify-end items-center content-center">
						<Button variant="contained" color="secondary" onClick={() => onCloseDialog()}>
							Close
						</Button>
						{transaction?.action?.dialog && (
							<Button variant="contained" color="primary" className="ml-10" onClick={onAction}>
								{transaction?.action?.dialog?.label ?? "Action"}
							</Button>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default TransactionDialog;
