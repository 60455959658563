import { ADD_TRUCK_ID, ADD_CARRIER_ID } from "../constants";

export const addTruckId = (truckId) => ({
	type: ADD_TRUCK_ID,
	payload: truckId,
});

export const addCarrierId = (carrierId) => ({
	type: ADD_CARRIER_ID,
	payload: carrierId,
});
