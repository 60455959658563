import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { formatCurrency } from "app/main/utils/tableUtils";

import { useEffect, useMemo, useState } from "react";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { useDispatch } from "react-redux";

import { createWidgetMonitor, getMonitorResult } from "app/services/searchServices";
import { getUserId } from "app/services/LoginService";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import { createTooltip } from "app/main/utils/tableUtils";
//Component
import CardDestination from "./CardDestination";
import { DisplaySection } from "app/main/utils/uiUtils";

const _createFilters = (params, date) => {
	return {
		dh_origin: 250,
		dh_destination: 250,
		equipment: params?.equipment,
		trailer: "53",
		max_weight: 44000,
		carrier: params?.carrier,
		truck: params?.truck,
		driver: params?.driver,
		location_origin: params?.origin,
		location_destination: params?.destination,
		pickup_ds__after: moment(date).startOf("day").tz(moment.tz.guess(), true).format(),
		pickup_ds__after__tz_offset: new Date().getTimezoneOffset(),
		pickup_ds__after__tz: moment.tz.guess() ?? "America/New_York",
	};
};

export function MarketGraphComponent(props) {
	const data = props.data;
	const type = props.type;
	const title = props.title;
	const height = props.height ?? 200;

	const categories =
		data?.map((item, i) => (item.min < 0 ? formatCurrency(item.min, 0) : formatCurrency(item.max, 0))) ?? [];

	let maxY = 25;
	let minY = 0;
	let tickAmountX = type === "RATE" ? 3 : 4;
	let tickAmountPlusY = 2;
	let tickAmountMinusY = 0;
	data?.forEach((item) => {
		// TODO: Refactor this, to a simple function
		if (item.count > 400 && maxY < 500) {
			maxY = 500;
			tickAmountPlusY = 5;
		} else if (item.count > 300 && maxY < 400) {
			maxY = 400;
			tickAmountPlusY = 4;
		} else if (item.count > 200 && maxY < 300) {
			maxY = 300;
			tickAmountPlusY = 3;
		} else if (item.count > 100 && maxY < 200) {
			maxY = 200;
		} else if (item.count > 50 && maxY < 100) {
			maxY = 100;
		} else if (item.count > 25 && maxY < 50) {
			maxY = 50;
		}
		// TODO: Refactor this, to a simple function
		if (item.count < -400 && minY > -500) {
			minY = -500;
			tickAmountMinusY = 2;
		} else if (item.count < -300 && minY > -400) {
			minY = -400;
			tickAmountMinusY = 2;
		} else if (item.count < -200 && minY > -300) {
			minY = -300;
			tickAmountMinusY = 2;
		} else if (item.count < -100 && minY > -200) {
			minY = -200;
			tickAmountMinusY = 1;
		} else if (item.count < -50 && minY > -100) {
			minY = -100;
			tickAmountMinusY = 1;
		} else if (item.count < -25 && minY > -50) {
			minY = -50;
			tickAmountMinusY = 1;
		} else if (item.count < 0 && minY === 0) {
			minY = -25;
			tickAmountMinusY = 1;
		}
	});

	const series = [{ name: "Loads", data: data?.map((item) => item.count) ?? [] }];
	const options = {
		chart: {
			id: "chart",
			height: height,
			type: type === "RATE" ? "area" : "bar",
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		dataLabels: { enabled: false },
		stroke: type === "RATE" ? { curve: "smooth" } : { show: false },
		plotOptions: {
			bar: {
				colors: {
					ranges: [
						{ from: -99999, to: 0, color: "#F15B46" },
						{ from: 0, to: 99999, color: "#00E396" },
					],
				},
				columnWidth: "80%",
			},
		},
		markers: { size: 5, hover: { size: 9 } },
		xaxis: {
			tooltip: { enabled: false },
			categories: categories,
			tickAmount: tickAmountX,
			labels: { rotate: 0 },
			title: {
				text: title,
				style: {
					color: "#444",
					fontSize: 13,
				},
			},
		},
		yaxis: {
			min: minY,
			max: maxY,
			tooltip: { enabled: false },
			tickAmount: tickAmountPlusY + tickAmountMinusY,
			labels: { formatter: (y) => (y.toFixed(0) < 0 ? y.toFixed(0) * -1 : y.toFixed(0)) },
		},
	};

	return (
		<div className={"flex flex-col mx-0 mt-12 -mb-20 md:mx-12"}>
			<ReactApexChart options={options} series={series} type={type === "RATE" ? "area" : "bar"} height={height} />
		</div>
	);
}

function MarketAnalysisWidget(props) {
	const config = props.config;
	const mode = props.mode;
	const searchId = props.searchId;
	const dispatch = useDispatch();
	const text = props?.isAllView ? "text-14" : "text-13";

	const [monitor, setMonitor] = useState(null);
	const [data, setData] = useState(null);
	const [tab, setTab] = useState(0);
	const [typeTab, setTypeTab] = useState(0);
	const [dateTab, setDateTab] = useState(0);
	const [estTab, setEstTab] = useState(0);
	const [error, setError] = useState(null);
	const [enabled, setEnabled] = useState(!!props.searchOnInit);
	const [intialized, setIntialized] = useState(false);
	const [loading, setLoading] = useState(false);
	const [analyzing, setAnalyzing] = useState(false);
	const [revision, setRevision] = useState(0);
	const [balanceProfit, setBalanceProfit] = useState(true);
	const [bestDestinations, setBestDestinations] = useState(null);
	const [filterDataDestination, setFilterDataDestination] = useState(null);

	const height = config?.height ?? 300;
	const types = ["RATE", "PROFIT"];
	const estType = ["EST_OFF", "EST_ON"];
	let datesOptions = ["TODAY", "TOMORROW"];

	const userId = useMemo(() => getUserId(), []);
	const searchModel = useMemo(
		() =>
			searchId
				? {}
				: _createFilters(
						config?.params,
						datesOptions[dateTab] === "TODAY"
							? moment().toISOString()
							: datesOptions[dateTab] === "TOMORROW"
							? moment().add(1, "day").toISOString()
							: config?.params?.pickupDs
				  ),
		// eslint-disable-next-line
		[config?.params?.origin, config?.params?.destination, dateTab]
	);

	useEffect(() => {
		if ((!config?.params?.carrier || !config?.params?.truck) && !searchId) {
			return;
		}

		let stopped = false;
		(async () => {
			try {
				setLoading(true);

				// We would try to load existing result by default, new search
				// request can be only triggered manually
				const monitor = searchId ? { id: searchId } : await createWidgetMonitor(userId, searchModel, !enabled);
				if (stopped) return;
				setMonitor(monitor);

				if (!monitor.id) {
					setLoading(false);
					setAnalyzing(false);
					setIntialized(true);
					// Search job was not created, and no existing search job found
					return;
				}

				let result;
				const options = { estimationEnabled: estType[estTab] === "EST_ON" };
				if (monitor.new) {
					setAnalyzing(true);
					for (let i = 0; i < 15 && result?.status !== "FAILED" && result?.status !== "DONE"; i++) {
						await new Promise((r) => setTimeout(r, 2000));
						result = await getMonitorResult(userId, monitor.id, options);
						if (stopped) return;
					}
				} else {
					result = await getMonitorResult(userId, monitor.id, options);
					if (stopped) return;
				}

				setLoading(false);
				setAnalyzing(false);
				setIntialized(true);
				setEnabled(true);
				if (result?.status !== "DONE") {
					setError("Failed to analyze, please retry again...");
					setData(null);
				} else {
					setError(null);
					setBestDestinations(
						new Array(result?.data?.stats?.bestDestinations?.length).fill({ value: false, index: 0 })
					);
					setData(result);
				}
			} catch (e) {
				setLoading(false);
				setAnalyzing(false);
				setError(e.message);
			}
		})();

		return () => {
			stopped = true;
		};
		// eslint-disable-next-line
	}, [revision, searchId, searchModel, estTab]);

	if (config?.params?.pickupDs) {
		datesOptions = [...datesOptions, moment(config?.params?.pickupDs).format("MM/DD")];
	}

	if ((!config?.params?.carrier || !config?.params?.truck) && !searchId) {
		return <Typography color="error">Invalid Params</Typography>;
	}

	const counters = data?.data?.counters;
	const stats = data?.data?.stats;
	const ranges = data?.data?.ranges;

	const handleCheckLocation = (position) => {
		let currentValue;
		const updatedCheckedState = bestDestinations?.map((item, index) => {
			let value = index === position ? !item?.value : item?.value;
			if (index === position) {
				currentValue = !item?.value;
			}
			return { value, index };
		});

		setBestDestinations(updatedCheckedState);
		if (!currentValue) return setFilterDataDestination(null);
		const destinationRanges = stats?.bestDestinations?.[position].ranges?.[tab];
		setFilterDataDestination({
			profit: destinationRanges?.profit,
			profitBalance: destinationRanges?.profitBalance,
			rate: destinationRanges?.rate,
		});
	};

	let origin = config?.params?.origin?.replace(", USA", "") ?? "";
	let destination = config?.params?.destination?.replace(", USA", "") ?? "";

	origin = origin?.length > 24 ? origin.substring(0, 18) + "..." : origin;
	destination = destination?.length > 24 ? destination.substring(0, 22) + "..." : destination;

	const refreshButton = (
		<IconButton
			key="reload"
			className="p-4 ml-6 mr-6"
			disabled={loading || !enabled}
			onClick={() => {
				setRevision(revision + 1);
			}}
		>
			<Icon className="text-18 ml:text-20">refresh</Icon>
		</IconButton>
	);

	const showLoadsButton = (
		<Button
			key="view"
			className="px-12 py-0 ml-6 h-24 ml:h-28 text-12 ml:text-13 whitespace-nowrap"
			variant="contained"
			color="secondary"
			disabled={loading || !enabled || !!error}
			onClick={() => {
				if (!monitor?.id) return;
				dispatch(
					openLoadedFormDialog({
						viewId: "LOADS_SEARCH_RESULTS_VIEW",
						dataIds: { searchId: monitor.id, carrierId: config?.params?.carrier, truckId: config?.params?.truck },
					})
				);
			}}
		>
			View Loads
		</Button>
	);

	const showDetailsButton = (
		<IconButton
			key="details"
			className="p-6 ml-6"
			disabled={loading || !enabled || !!error}
			onClick={() => {
				if (!monitor?.id) return;
				dispatch(
					openLoadedFormDialog({
						viewId: "LOADS_SEARCH_MARKET_ANALYSIS_VIEW",
						dataIds: { config, userId, searchId },
					})
				);
			}}
		>
			<Icon className="text-18 md:text-20">open_in_full</Icon>
		</IconButton>
	);

	const selectorClasses = " text-12 ";
	const menuClasses = " text-12 ";

	const selectType = (
		<div key={"type_selector"} className="flex">
			{types?.length > 0 && (
				<FormControl className="" variant="filled">
					<Select
						className={selectorClasses}
						classes={{ select: "py-8" }}
						value={typeTab}
						onChange={(event) => setTypeTab(event.target.value)}
					>
						{types?.map((item, i) => (
							<MenuItem className={menuClasses} key={item} value={i}>
								{item === "RATE" ? "By Rate" : item === "PROFIT" ? "By Profit" : item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</div>
	);

	const estTypeSelect = (
		<div key={"est_type_selector"} className="flex">
			{estType?.length > 0 && (
				<FormControl className="" variant="filled">
					<Select
						className={selectorClasses}
						classes={{ select: "py-8" }}
						value={estTab}
						onChange={(event) => setEstTab(event.target.value)}
					>
						{estType?.map((item, i) => (
							<MenuItem className={menuClasses} key={item} value={i}>
								{item === "EST_OFF" ? "Est. Off" : item === "EST_ON" ? "Est. On" : item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</div>
	);

	const selectRange = (
		<div key={"range_selector"} className="flex">
			{ranges?.length > 0 && (
				<FormControl className="" variant="filled">
					<Select
						className={selectorClasses}
						classes={{ select: "py-8" }}
						value={tab}
						onChange={(event) => setTab(event.target.value)}
					>
						{ranges?.map((item, i) => (
							<MenuItem className={menuClasses} key={item.type} value={i}>
								{item.type === "ALL"
									? "All Ranges"
									: item.type === "SHORT_RANGE"
									? "< 800 mi"
									: item.type === "MID_RANGE"
									? "800 mi - 1600 mi"
									: "1600+ mi"}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</div>
	);

	const selectDate = (
		<div key={"date_selector"} className="flex">
			{data && datesOptions?.length > 0 && (
				<FormControl className="" variant="filled">
					<Select
						className={selectorClasses}
						classes={{ select: "py-8" }}
						value={dateTab}
						onChange={(event) => setDateTab(event.target.value)}
					>
						{datesOptions?.map((item, i) => (
							<MenuItem className={menuClasses} key={item} value={i}>
								{item === "TODAY" ? "Today" : item === "TOMORROW" ? "Tomorrow" : item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</div>
	);

	const centralMessage = error ? (
		<div
			key={"error_message"}
			className="flex flex-col w-full absolute items-center justify-between pt-2"
			style={{ top: "45%" }}
		>
			<Typography className="w-full text-center" color="error">
				{error}
			</Typography>
			<Button
				className="flex py-4 bg-red text-white mt-3"
				variant="contained"
				onClick={() => {
					setError(null);
					setRevision(revision + 1);
				}}
			>
				Retry
			</Button>
		</div>
	) : analyzing ? (
		<div
			key={"loading_animation"}
			className="flex flex-col w-full absolute items-center justify-between pt-2"
			style={{ top: "45%" }}
		>
			<Typography
				key={"analyzing_message"}
				className="font-medium text-12 ml:text-13 absolute animate-pulse bg-grey-400 rounded-20 px-20 py-4 text-white"
			>
				Analyzing Market...
			</Typography>
		</div>
	) : null;

	const analysisButton =
		intialized && !enabled ? (
			<div
				key={"loading_animation"}
				className="flex flex-col w-full absolute items-center justify-between"
				style={{ top: "45%" }}
			>
				<Button
					key={"analyze_button"}
					className="text-12 ml:text-13 py-4 px-12 font-normal"
					variant="contained"
					color="secondary"
					onClick={() => {
						if (!data) {
							setAnalyzing(true);
							setEnabled(true);
						}
						setRevision(revision + 1);
					}}
				>
					<Icon className="text-18 mr-6">youtube_searched_for</Icon>Analyze Market
				</Button>
			</div>
		) : !intialized ? (
			<div
				key={"loading_animation"}
				className="flex flex-col w-full absolute items-center justify-between"
				style={{ top: "45%" }}
			>
				<CircularProgress color="secondary" />
			</div>
		) : null;

	const containerClasses = loading || !!error || !enabled ? " opacity-40 " : "";

	if (mode === "FULL") {
		const rawDataRates = ranges?.[tab]?.rate;
		const rawDataProfit = ranges?.[tab]?.profit;
		const rawDataProfitBalance = ranges?.[tab]?.profitBalance;

		return (
			<div className={"flex flex-col w-full h-full " + (props.classes?.container ?? "")}>
				<DisplaySection
					title={`Total Loads ${counters?.total ?? "-"}`}
					classes={{ root: "mx-0 mb-4 md:flex" }}
					views={[
						<div className="flex flex-row">
							<Typography className={"font-semibold mr-4 " + text}>{"Rate"}</Typography>
							<Typography className={text}>{`${counters?.hasRate ?? "-"}`}</Typography>
							<div className="flex flex-row ml-4 md:ml-14">
								<Typography className={"font-semibold mr-4 " + text}>{"Profitable"}</Typography>
								<Typography className={text}>{`${counters?.profitable ?? "-"}`}</Typography>
							</div>
						</div>,
					]}
				/>
				<div className={"flex flex-col md:flex-row md:items-center md:justify-between mt-0 mb-4 " + containerClasses}>
					<div className="flex flex-row mb-8">
						<div className="hidden md:flex">
							<Typography className="flex text-13 font-semibold">
								Best Destinations
								<Icon className="text-16 mt-2 text-red-500 ml-2">local_fire_department</Icon>
							</Typography>
						</div>
					</div>
					<div className="flex flex-row items-center">
						{!searchId && <div className="flex mx-10" />}
						<div className="flex flex-row  items-center -ml-32 md:ml-0">
							<Switch
								size={"small"}
								checked={balanceProfit}
								onChange={(event) => setBalanceProfit(event.target.checked)}
							/>
							<Typography className="flex text-12">Combined</Typography>
							{createTooltip(
								<Icon className="text-12 text-black align-middle flex">{"info"}</Icon>,
								<Typography className="text-12">{"Combined profit"}</Typography>,
								"bg-grey"
							)}
						</div>
						{estTypeSelect}
						{selectRange}
						{!searchId && selectDate}
						{refreshButton}
						<div className="flex"> {!searchId && showLoadsButton}</div>
					</div>
					<div className="w-full flex mb-10 mt-10 md:hidden mb-0 ml-10">
						<Typography className="w-full flex text-15 self-center md:text-16 font-semibold ml-4">
							Best Destinations
							<Icon className="text-15 mt-2 text-red-500 ml-2">local_fire_department</Icon>
						</Typography>
					</div>
				</div>
				<div className={"flex flex-col w-full -mt-14 md:-ml-6 relative " + (loading ? " pointer-events-none " : "")}>
					<div className={"flex flex-col md:flex-row " + (!enabled ? " opacity-40 " : loading ? " opacity-40 " : "")}>
						<div className={"flex ml-10 -mt-6 mb-0 md:mb-10 flex-col w-full md:w-3/12"}>
							{stats?.bestDestinations?.length === 0 && (
								<Typography className="flex text-11 mt-40 text-red md:text-12">No best destinations found</Typography>
							)}
							{stats?.bestDestinations?.map((location, index) => (
								<div className={"flex flex-row w-full pt-14 md:pt-12 "} key={index}>
									<CardDestination
										destination={location}
										bestDestination={bestDestinations}
										handleCheckLocation={handleCheckLocation}
										index={index}
									/>
								</div>
							))}
						</div>
						{rawDataRates && rawDataProfitBalance ? (
							<div className={"flex flex-col  pl-0 md:pl-2 mr-0 md:-mr-10 md:w-9/12"}>
								<MarketGraphComponent
									key={"RATE"}
									data={filterDataDestination ? filterDataDestination?.rate : rawDataRates}
									type={"RATE"}
									height={height / (props?.isAllView ? 1.5 : 2) - 10}
									title={"Loads Grouped By Rate"}
								/>
								<MarketGraphComponent
									key={"PROFIT"}
									data={
										filterDataDestination && balanceProfit
											? filterDataDestination?.profitBalance
											: filterDataDestination && !balanceProfit
											? filterDataDestination?.profit
											: !filterDataDestination && balanceProfit
											? rawDataProfitBalance
											: rawDataProfit
									}
									type={"PROFIT"}
									height={height / (props?.isAllView ? 1.5 : 2) - 10}
									title={"Loads Grouped By Profit"}
								/>
							</div>
						) : (
							<div className={"flex flex-col w-full pl-0 md:pl-20"}>
								<div
									className={"flex w-full bg-grey-200 rounded-lg ml-0 md:ml-14 mt-28 mb-28"}
									style={{ height: height / 2 - 30 }}
								/>
								<div
									className={"flex w-full bg-grey-200 rounded-lg ml-0 md:ml-14"}
									style={{ height: height / 2 - 30 }}
								/>
							</div>
						)}
					</div>
					{centralMessage}
					{analysisButton}
				</div>
			</div>
		);
	} else {
		const rawData = typeTab === 0 ? ranges?.[tab]?.rate : typeTab === 1 ? ranges?.[tab]?.profit : null;
		return (
			<div className="flex flex-col w-full h-full">
				<div className={"flex flex-row items-center justify-between -mb-2 " + containerClasses}>
					<Typography className="flex font-medium tracking-wide -mb-4">
						{origin + (destination ? " - " + destination : "")}
					</Typography>
					<div className="flex flex-1" />
					{refreshButton}
					{showLoadsButton}
					{showDetailsButton}
					{selectDate}
				</div>
				<div className={"flex flex-row items-center justify-between mt-2 md:-mt-2 " + containerClasses}>
					{counters?.total && <Typography className="flex tracking-wide">{counters?.total + " Loads"}</Typography>}
					<div className="flex flex-1" />
					{estTypeSelect}
					{selectType}
					{selectRange}
				</div>
				<div className={"flex flex-col w-full -mt-6 -ml-6 relative " + (loading ? " pointer-events-none " : "")}>
					<div className={"flex flex-col " + (!enabled ? " opacity-40 " : loading ? " opacity-40 " : "")}>
						<MarketGraphComponent key={types[typeTab]} data={rawData} type={types[typeTab]} height={height} />
					</div>
					{centralMessage}
					{analysisButton}
				</div>
			</div>
		);
	}
}

export default MarketAnalysisWidget;
