import Box from "@material-ui/core/Box";
import PathDetails from "./PathDetails";

const DrawerBody = ({ index, strategyData, strategyReqData, pathMoves, selectedMoves }) => {
	return (
		<Box>
			{Number.isInteger(index) && index !== -1 && (
				<PathDetails
					isGeneralStrategy={true}
					pathMoves={pathMoves}
					index={index}
					load={strategyData?.load}
					truckPosition={strategyData?.truckPosition}
					strategy={strategyData?.strategyResult?.paths[index]}
					legs={strategyData?.strategyResult?.legs}
					strategyReqData={strategyReqData}
					selectedMoves={selectedMoves}
				/>
			)}
		</Box>
	);
};

export default DrawerBody;
