import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function TimelineCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const label = props?.view?.label;
	const past = props?.view?.metadata?.past;
	const today = props?.view?.metadata?.today;
	const tomorrow = props?.view?.metadata?.tomorrow;
	const after_tomorrow = props?.view?.metadata?.after_tomorrow;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellSecondRow mobile={mobile} label={label} variant={variant} />}
			second={
				<>
					{config.showPast && (
						<CellSecondRow
							mobile={mobile}
							label={past + ""}
							icon={config.icon}
							variant={variant}
							color={"text-red-600 pr-5"}
						/>
					)}
					{config.showToday && (
						<CellSecondRow
							mobile={mobile}
							label={today + ""}
							icon={config.icon}
							variant={variant}
							color={"text-orange-800 pr-5"}
						/>
					)}
					{config.showTomorrow && (
						<CellSecondRow
							mobile={mobile}
							label={tomorrow + ""}
							icon={config.icon}
							variant={variant}
							color={"text-blue-800 pr-5"}
						/>
					)}
					{config.showAfterTomorrow && (
						<CellSecondRow
							mobile={mobile}
							label={after_tomorrow + ""}
							icon={config.icon}
							variant={variant}
							color={"text-green-500 pr-5"}
						/>
					)}
				</>
			}
		/>
	);
}

export default TimelineCellView;
