import { CircularProgress, Icon, Typography } from "@material-ui/core";
import { isStepCompleted } from "./onboardUtil";
import { getCarrierId, getUserId } from "app/services/LoginService";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import OnboardSubSteps from "./OnboardSubSteps";

function OnboardContent({ loading, step, config, data, switchToStep, onCompleteSteps, loadingLastStep, props }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const dataIds = props.dataIds;
	const isCreateSubAccount = !!dataIds?.createSubAccount;

	const carrierId = isCreateSubAccount ? dataIds?.carrierId : getCarrierId();
	const userId = isCreateSubAccount ? dataIds?.userId : getUserId();

	config.list = config.list.filter((list) => {
		if (list.hidden && list.hidden(data)) {
			return false;
		}
		return true;
	});
	const activeConfig = useMemo(() => config.list[step], [config, step]);

	const renderHeader = () => {
		const stepCompleted = isStepCompleted({ step, config, data });
		const iconClass = "text-24 md:text-28 mr-6 " + (stepCompleted ? " text-green-400 " : " text-grey-800 ");

		return (
			<div className="flex flex-col w-full items-center pb-16 px-6 ml:pb-24">
				<div className="flex flex-row items-center px-16 pb-6">
					<Icon className={iconClass}>{stepCompleted ? "check_circle" : ""}</Icon>
					<Typography className="text-16 md:text-17 text-center">{activeConfig.pageTitle}</Typography>
					<Icon className={iconClass}></Icon>
				</div>
				{Array.isArray(activeConfig.pageSubtitle) ? (
					activeConfig.pageSubtitle.map((line, i) => (
						<Typography
							key={"pageSubtitle_" + i}
							color={"textSecondary"}
							className={"text-13 text-center max-w-780 " + (i === 0 ? " -mt-4 " : "")}
						>
							{line}
						</Typography>
					))
				) : (
					<Typography color={"textSecondary"} className="text-13 text-center max-w-780 -mt-4">
						{activeConfig.pageSubtitle}
					</Typography>
				)}
			</div>
		);
	};

	if (!activeConfig) return null;

	return (
		<div className="flex flex-col w-full items-center">
			<div
				className={`flex flex-col items-center w-full mt-0 md:-mt-4 ${
					activeConfig?.classes?.contentContainer ?? "md:w-11/12"
				}`}
			>
				{renderHeader(step)}
				<div className="flex w-full items-center justify-center">
					{loading && (
						<div className="absolute left-1/2 top-1/2 ranslate-y-1/2 translate-x-1/2 z-50 bg-opacity-40 bg-black p-20">
							<CircularProgress className="" />
						</div>
					)}
					{activeConfig?.renderSteps && (
						<OnboardSubSteps
							data={data}
							substepsViews={activeConfig?.renderSteps({
								data,
								carrierId,
								userId,
								config,
								switchToStep,
								onCompleteSteps,
								dispatch,
								history,
								step,
								loadingLastStep,
								props,
							})}
						/>
					)}
					{activeConfig?.render &&
						typeof activeConfig?.render === "function" &&
						activeConfig?.render({
							data,
							carrierId,
							userId,
							config,
							switchToStep,
							onCompleteSteps,
							dispatch,
							history,
							step,
							loadingLastStep,
							props,
						})}
					{activeConfig?.render && typeof activeConfig?.render !== "function" && activeConfig?.render}
				</div>
			</div>
		</div>
	);
}

export default OnboardContent;
