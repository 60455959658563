import { SmarthopList } from "@smarthop/list";
import adminTripInvoicingConfig from "app/main/billing/invoicing/adminTripInvoicingConfig";
import PageWrapper from "app/main/common/PageWrapper";

const AdminBrokerInvoicingPage = (props) => {
	const brokerId = props?.params?.brokerId;

	return (
		<PageWrapper>
			<SmarthopList
				isView={props.isView}
				key={`trips_invoicing`}
				mode="table"
				brokerId={brokerId.brokerId}
				config={adminTripInvoicingConfig({ brokerId, variant: "SMARTPAY" })}
				dataIds={{ brokerId }}
			/>
		</PageWrapper>
	);
};

export default AdminBrokerInvoicingPage;
