const adminPlanPromosForm = {
	urlPOST: "billing/plan-promos",
	urlPUT: "billing/plan-promos/:id",
	urlDELETE: "billing/plan-promos/:id",
	urlGET: "billing/plan-promos/:id",
	idKey: "_id",
	triggerEvent: "planRevision",
	listenEvent: "planRevision",
	header: {
		create: { label: "Create Plan Promo" },
		delete: { label: "Delete Plan Promo" },
		edit: { label: "Edit Plan Promo" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete plan promo?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
								confirmation: {
									enable: true,
									typedConfirmation: "delete",
									message: "Are you sure you want to continue?",
								},
							},
						],
					},
				},
			],
		},
		create: { sameAs: "edit" },
		edit: {
			items: [
				{
					type: "section",
					label: "Summary",
					section: { classes: { label: "" } },
				},
				{
					key: "service",
					type: "select",
					label: "Plan Type",
					required: true,
					options: [
						{
							value: "PLATFORM",
							label: "Platform Plan",
							showItems: [
								{
									key: "plan",
									type: "autocomplete",
									label: "Platform Plan",
									required: true,
									autocomplete: {
										provider: "smarthop",
										url: "billing/platform-plans",
										params: {
											sortOrder: "asc",
											sortBy: "nickname",
										},
										preloadDefault: true,
										showDescription: true,
									},
								},
							],
						},
					],
				},
				{
					key: "expirationType",
					type: "select",
					label: "Expidation Condition",
					required: true,
					options: [
						{
							value: "EXPIRE_AFTER_DATE",
							label: "Expires after specified Date",
							showItems: [
								{
									key: "expirationDate",
									type: "picker",
									label: "Expiration Date",
									required: true,
									picker: {
										disablePast: true,
										notClearable: true,
										type: "datetime",
										classes: {},
									},
								},
							],
						},
						{
							value: "PERMANENT",
							label: "Permanent (Never expires)",
						},
					],
				},
				{
					key: "overrideType",
					type: "select",
					label: "Override Type",
					required: true,
					options: [
						{
							value: "OVERRIDE_PUBLIC_TIER",
							label: "Overrides Existing (Overrides public plan with the same tier)",
							showItems: [
								{
									key: "overridePriority",
									type: "number",
									required: true,
									label: "Override priority (If eligible for multiple promos, highest priority would be used)",
								},
							],
						},
						{
							value: "COMPLIMENTARY",
							label: "Complimentary (Shown as an additional plan)",
						},
					],
				},
				{
					key: "availabilityType",
					type: "select",
					label: "Availability Type",
					required: true,
					options: [
						{
							value: "ALWAYS",
							label: "Visible Always (Would be visible after activation)",
						},
						{
							value: "INACTIVE_ONLY",
							label: "Onboarding Only (Visible before carrier is activated, onboarding only)",
						},
						{
							value: "MILTIPLE_ACCOUNTS",
							label: "If adding new accounts (Overrides all other promos if eligible, onboarding only)",
							showItems: [
								{
									key: "availabilityAccountsCount",
									type: "number",
									label: "Min number of accounts required",
									required: true,
								},
							],
						},
					],
				},
				{
					key: "description",
					type: "text",
					label: "Description (Optional text visible to users)",
				},
				{ key: "disabled", type: "checkbox", label: "Disabled" },
				{
					type: "section",
					label: "Eligible",
					section: { classes: { label: "-mt-10" } },
				},
				{
					key: "emails",
					type: "pattern",
					addButton: { label: "Add Email" },
					pattern: { disableMoveButton: true, classes: { root: "-mt-6", label: "font-semibold" } },
					content: {
						items: [
							{
								key: "email",
								type: "text",
								label: "Email",
								required: true,
							},
						],
					},
				},
				{
					key: "carriers",
					type: "pattern",
					addButton: { label: "Add Carrier" },
					pattern: { disableMoveButton: true, classes: { root: "-mt-10 mb-10", label: "font-semibold" } },
					content: {
						items: [
							{
								key: "carrier",
								type: "autocomplete",
								label: "Carrier",
								required: true,
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/carriers",
									preloadDefault: true,
									listenEvent: "profileRevision",
								},
							},
						],
					},
				},
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "Are you sure you want to continue?",
					},
				},
			],
		},
	},
};

export default adminPlanPromosForm;
