import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import TooltipView from "./TooltipView";
import IconButton from "@material-ui/core/IconButton";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

function CellSecondRow(props) {
	const dispatch = useDispatch();
	const icon = props.icon;
	const label = props.label;
	const variant = props.variant;
	const color = variant === "widget-calendar" ? "text-grey-600" : props.color;
	const classes = props.classes;
	const iconButton = props.iconButton;
	const tooltip = props.tooltip;

	if (!label && !iconButton) {
		return null;
	}

	const font =
		variant === "widget-calendar"
			? " text-12 ml:text-13 "
			: variant === "large"
			? " text-13 ml:text-14 "
			: variant === "spacious"
			? " text-12 ml:text-13 "
			: variant === "view"
			? " text-11 "
			: variant === "skinny" || variant === "extra-skinny"
			? " text-11 ml:text-12 "
			: variant === "extra-large"
			? " text-13 "
			: " text-11 ml:text-12 ";

	let textLimit =
		variant === "large"
			? 36
			: variant === "spacious"
			? 28
			: variant === "skinny" || variant === "extra-skinny"
			? 22
			: 24;

	if (props.textLimit > 0) {
		textLimit = props.textLimit;
	}

	const handleClickButton = () => {
		if (props?.dialog) {
			dispatch(openLoadedFormDialog(props?.dialog));
		}
	};

	const textClasses = "mr-4 pb-0 rounded-md leading-tight whitespace-nowrap " + (color ?? "") + " " + font;
	const iconClasses = " text-12 ml:text-13 -mb-2 ml:-mb-3 mr-2";
	if (label?.length > textLimit || tooltip) {
		return (
			<TooltipView
				title={
					<Typography className="text-13">
						{(label?.length > textLimit ? label + ". " : "") + (tooltip ?? "")}
					</Typography>
				}
			>
				<Typography component="div" className={textClasses + " " + (classes?.label ?? "")}>
					{icon && <Icon className={iconClasses + " " + (classes?.icon ?? "")}>{icon}</Icon>}
					{label?.length > textLimit ? label.substring(0, textLimit - 3) + "..." : label}
				</Typography>
			</TooltipView>
		);
	} else if (iconButton) {
		return (
			<IconButton
				color={iconButton?.icon?.color}
				aria-label="Follow"
				className={`${variant === "extra-large" ? "-mt-8 " : "-mt-10 "}` + iconButton?.classes}
				onClick={(event) => handleClickButton()}
			>
				<Icon className={iconButton?.icon?.classes ?? "text-16"}> {iconButton?.icon?.name} </Icon>
			</IconButton>
		);
	} else {
		return (
			<Typography component="div" className={textClasses + " " + (classes?.label ?? "")}>
				{icon && <Icon className={iconClasses + " " + (classes?.icon ?? "")}>{icon}</Icon>}
				{label}
			</Typography>
		);
	}
}

export default CellSecondRow;
