import { formaterItinerary } from "app/main/utils/loadHistoryUtils";

const itinerarySavedForm = {
	urlGET: "api/tracking/carries/:carrierId/tracking-itinerary/trips/:tripId/records/:recordId",
	idKey: "_id",
	header: {
		view: { label: "Itinerary" },
	},
	content: {
		view: {
			noInitValidation: true,
			items: [
				{
					key: "itinerary",
					builder: (item) => formaterItinerary(item.comments),
					viewOnly: true,
					type: "text",
					label: "itinerary",
				},
			],
		},
	},
};

export default itinerarySavedForm;
