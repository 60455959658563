import { global } from "app/services/requestUtil";

import { Typography } from "@material-ui/core";

const companyLogoDescription =
	"Logos are applied to all documents generated by the carrier. Supports PNG/JPG file types smaller than 512KB with pixel count at least 128x128";

export const BRANDING_LOGO_EDIT_FIELDS = [
	{
		key: "company_logo_description",
		type: "component",
		label: "Company Logo",
		builder: () => (
			<div key={"company_logo_description_field"} className="w-full pl-6 pr-6  pt-4 pb-14">
				<div className="flex flex-row">
					<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
						{companyLogoDescription}
					</Typography>
				</div>
			</div>
		),
	},
	{
		key: "company_logo",
		type: "upload",
		label: "Company Logo",
		description: companyLogoDescription,
		upload: {
			url: "api/profile/carriers/:carrierId/files",
			documentType: "carrier_branding",
			fileName: "branding_company_logo",
		},
		disabled: "",
	},
];

export const brandingForm = () => {
	return brandingFormConf;
};

export const brandingFormConf = {
	urlGET: "api/profile/carriers/:carrierId/branding",
	urlPUT: "api/profile/carriers/:carrierId/branding",
	urlPOST: "api/profile/carriers/:carrierId/branding",
	idKey: "_id",
	triggerEvent: ["onboardingRevision", "invoiceOnboardingRevision"],
	listenEvent: ["onboardingRevision", "invoiceOnboardingRevision"],
	header: {
		edit: { label: "Edit Branding" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			items: [
				{
					key: "company_logo",
					type: "upload",
					label: "Company Logo",
					description: companyLogoDescription,
					upload: {
						url: "api/profile/carriers/:carrierId/files",
						documentType: "carrier",
						fileName: "branding_company_logo",
					},
					disabled: "",
				},
				{
					key: "company_logo_preview",
					type: "component",
					builder: (item) => {
						const token = localStorage.getItem("tokenSmarthop");
						const companyLogoURI = `${global.SERVER_NAME}/api/profile/carriers/${item?.id}/files/:fileId?token=${token}`;
						if (!item?.company_logo?.[0]) {
							return null;
						} else {
							return (
								<div className="flex flex-1 justify-end pr-20">
									<img
										width={128}
										src={companyLogoURI.replace(":fileId", item?.company_logo?.[0])}
										alt="Company Logo Preview"
									/>
								</div>
							);
						}
					},
				},
			],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [...BRANDING_LOGO_EDIT_FIELDS, { type: "action", action: "SUBMIT", label: "Save" }],
		},
	},
};
