import { Link } from "@material-ui/core";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useDispatch } from "react-redux";

const VideoComponent = ({ videoId }) => {
	const dispatch = useDispatch();
	return (
		<Link
			onClick={() => {
				dispatch(
					openFormDialog({
						viewId: "VIDEO_DIALOG",
						dataIds: { videoId },
					})
				);
			}}
		>
			{videoId}
		</Link>
	);
};

const videosList = {
	urlGET: "api/videos/",
	idKey: "_id",
	segmentKey: "VIDEOS",
	listenEvent: "videoRevision",
	triggerEvent: "videoRevision",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt", "to", "from"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			search: false,
		},
		items: [
			{
				key: "path",
				type: "component",
				label: "Page",
				grid: { headerTitle: true },
				builder: (item) => {
					return <Link href={item.path}>{item.path}</Link>;
				},
			},
			{
				key: "videoId",
				type: "component",
				label: "Video",
				builder: (item) => {
					return <VideoComponent videoId={item.videoId} />;
				},
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "Edit",
						label: "Edit",
						dialogBuilder: (item, dataIds) => {
							return {
								formId: "VIDEO_FORM",
								mode: "EDIT",
								dataIds: { ...dataIds, videoId: item._id },
							};
						},
					},
					{
						key: "delete",
						label: "Delete",
						dialogBuilder: (item, dataIds) => {
							return {
								formId: "VIDEO_FORM",
								mode: "DELETE",
								dataIds: { ...dataIds, videoId: item._id },
							};
						},
					},
				],
			},
		],
	},
};

export default videosList;
