import { type_user } from "constants.js";
import { isRoleExternal } from "app/services/LoginService";
import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import Icon from "@material-ui/core/Icon";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getSourceText } from "app/main/utils/liveTrackingUtils";
import { MDYHMFormatter } from "helpers/Formatter";

function UserCellView(props) {
	// disable user link view on mobile for now
	// becuase it links to views that are not mobile friendly yet
	const nativeMobile = useSelector(({ auth }) => auth?.user?.nativeMobile);
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const view = Array.isArray(props.view) ? props.view?.[0] : props.view;
	const viewerInternal = useMemo(() => !isRoleExternal(), []);

	const buildItem = (view, more) => {
		const email = view?.metadata?.email;
		const phone = view?.metadata?.phone;
		const roleObj = type_user.find(
			(item) => item.value === view?.metadata?.role || item.value === view?.metadata?.roles?.[0]
		);
		const role = roleObj?.label;
		const emailCorfirmed = view?.metadata?.emailConfirmed;
		const phoneCorfirmed = view?.metadata?.phoneConfirmed;
		const deactivated = view?.metadata?.deactivated;
		const online = view?.metadata?.online;
		const overrideUser = view?.metadata?.overrideUser;
		const actionSource = view?.metadata?.actionSource;
		const createdAt = MDYHMFormatter(view?.metadata?.createdAt);

		let clickUser;
		if (viewerInternal || (!viewerInternal && ![0, 1, 12, 9].includes(roleObj?.value))) {
			clickUser = true;
		} else {
			clickUser = false;
		}

		const dialog = {
			viewId: "USER_DETAILS_VIEW",
			dataIds: { userId: view?.value, carrierId: view?.metadata?.carrier },
		};

		const firstCell =
			!config?.showOnline || !online ? (
				<CellFirstRow
					mobile={props.mobile}
					view={view}
					variant={props.variant}
					dialog={clickUser ? dialog : null}
					disabled={config?.disableLink || nativeMobile}
				/>
			) : (
				<div className="flex flex-row items-center">
					<CellFirstRow
						mobile={props.mobile}
						view={view}
						variant={props.variant}
						dialog={dialog}
						disabled={config?.disableLink || nativeMobile}
					/>
					<div className="text-green pt-4">{online && <Icon className={"text-18"}>radio_button_checked</Icon>}</div>
				</div>
			);

		if (!view?.value) {
			return <CellEmptyRow mobile={mobile} variant={variant} />;
		}

		return (
			<CellContainerRow
				key={view.value}
				mobile={mobile}
				variant={variant}
				first={config?.readOnly ? view?.label : firstCell}
				second={
					<>
						{config?.showAccountStatus && deactivated && (
							<CellSecondRow
								mobile={mobile}
								icon={!deactivated ? "done" : "close"}
								label={!deactivated ? "Active" : "Inactive"}
								color={!deactivated ? " text-green-700" : " text-red-600"}
							/>
						)}
						{config?.showRole && (
							<CellSecondRow mobile={mobile} icon={"person_outline"} label={role ?? "No Role"} variant={variant} />
						)}
						{config?.showDate && (
							<CellSecondRow mobile={mobile} icon={"access_time"} label={createdAt ?? "-"} variant={variant} />
						)}
						{config?.showEmail && (
							<CellSecondRow mobile={mobile} icon={"mail"} label={email ?? "No Email"} variant={variant} />
						)}
						{config?.showPhone && (
							<CellSecondRow mobile={mobile} icon={"phone"} label={phone ?? "No Phone"} variant={variant} />
						)}
						{config?.showPosition && (
							<CellSecondRow mobile={mobile} icon="engineering" label={view?.metadata?.position} variant={variant} />
						)}
						{config?.showConfirmedEmail && !emailCorfirmed && (
							<CellSecondRow
								mobile={mobile}
								icon={emailCorfirmed ? "done" : "close"}
								label={"Email"}
								color={emailCorfirmed ? " text-green-700" : " text-red-600"}
							/>
						)}
						{config?.showConfirmedPhone && !phoneCorfirmed && (
							<CellSecondRow
								mobile={mobile}
								icon={phoneCorfirmed ? "done" : "close"}
								label={"Phone"}
								color={phoneCorfirmed ? " text-green-700" : " text-red-600"}
							/>
						)}
						{config?.showOverrideUser && overrideUser && (
							<CellSecondRow
								mobile={mobile}
								icon={"how_to_reg"}
								label={"By Main"}
								color={"text-blue font-medium rounded-full"}
								variant={variant}
							/>
						)}
						{config?.showActionSource && actionSource && (
							<CellSecondRow
								mobile={mobile}
								label={getSourceText(actionSource)}
								color={"text-blue font-medium rounded-full"}
								variant={variant}
							/>
						)}
					</>
				}
				more={more}
			/>
		);
	};

	let extra = [];
	if (Array.isArray(props.view)) {
		for (let i = 1; i < props.view.length; i++) {
			extra.push(buildItem(props.view[i]));
		}
	}

	return buildItem(view, extra);
}

export default UserCellView;
