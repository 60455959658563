import { createRequest, throwResErrors } from "./requestUtil";

export const getPublicTrip = (tokenId, publicId) => {
	return createRequest()
		.get(`api/public/url/${tokenId}/${publicId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLongLink = (id) => {
	return createRequest()
		.get(`api/url/short/${id}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
