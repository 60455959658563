import { useState } from "react";
import { Switch, Typography } from "@material-ui/core";
import { isEnabled } from "app/services/featureStorageService";

function SearchVersionSwitch(props) {
	const isSearchSwitchEnabled = isEnabled("ENABLE_SEARCH_V3");

	const [searchVersion, setSearchVersion] = useState(localStorage.getItem("SEARCH_VERSION"));

	const handleMode = (value) => {
		const newVersion = value ? "3" : "2";
		setSearchVersion(newVersion);
		localStorage.setItem("SEARCH_VERSION", newVersion);
		if (props.isMobile) {
			window.history.replaceState(null, "", "/search");
		}
		window.location.reload();
	};

	const switchField = (
		<Switch
			className="-mt-2 mr-5"
			size="small"
			checked={searchVersion !== "2"}
			onChange={(_, value) => handleMode(value)}
		/>
	);

	if (!isSearchSwitchEnabled) return null;

	return (
		<div
			className={`flex flex-1 ${
				props.isMobile ? "flex-row items-center pt-10" : "flex-col items-end py-5"
			} cursor-pointer text-center self-end px-10 `}
			style={{ minWidth: props.minWidth }}
		>
			<Typography className="text-10">Search V3</Typography>
			{switchField}
		</div>
	);
}

export default SearchVersionSwitch;
