import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

function ChatEmptyListView() {
	return (
		<div className="flex flex-col flex-1">
			<div className="flex flex-col flex-1 items-center justify-center">
				<Icon className="text-128" color="disabled">
					wb_incandescent
				</Icon>
				<Typography className="px-16 pb-32 mb-24 text-center max-w-md pt-24 text-16" color="textSecondary">
					Empty Inbox
				</Typography>
			</div>
		</div>
	);
}

export default ChatEmptyListView;
