import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import { validateMove } from "app/services/moveServices";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUIFiltersSearch } from "app/services/searchServices";
import { formatCurrency } from "app/main/utils/tableUtils";

const getDataRangeMinMax = (range) => {
	const filteredRange = range.filter((r) => r.count);
	const reducedRange = filteredRange.reduce((acc, curr) => [...acc, ...[curr.min, curr.max]], []);
	const sortedRange = reducedRange.sort((r1, r2) => r1 - r2);
	return [sortedRange.shift(), sortedRange.pop()];
};

// Only validate against Volume, Rate, and RPM
const validateWithData = (validationData) =>
	validationData.filter((item) => !item.label.match(/profit/i)).reduce((acc, curr) => acc && !!curr?.validated, true);

const ValidationLabel = ({ isVerified, onClick }) => {
	return (
		<Button
			variant="text"
			style={{
				color: isVerified ? "#228B22" : "#8B2222",
				minHeight: 0,
				minWidth: 0,
				padding: 0,
				margin: 0,
				lineHeight: 1.5,
			}}
			onClick={onClick}
		>
			{isVerified ? "Show loads" : "Unverified"}
		</Button>
	);
};

const ValidateMoveButton = ({ moveId, legData, loadSearchId, variant, strategyReqData = {}, autoValidate }) => {
	const [validationLoading, setValidationLoading] = useState(false);
	const [validationInfo, setValidationInfo] = useState(null);
	const [rangesData, setRangesData] = useState(null);
	const [errors, setErrors] = useState(null);
	const [listSearchId, setListSearchId] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		if (autoValidate && !validationInfo) {
			const validationCallback = moveId ? handleValidateWithMoveId(moveId) : handleValidateWithLegData(legData);
			validationCallback();
		}
		// eslint-disable-next-line
	}, [autoValidate, validationInfo]);

	const handleOpenInfo = () => {
		if (!listSearchId) return;
		dispatch(
			openLoadedFormDialog({
				viewId: "VALIDATED_MOVE",
				dataIds: { searchId: listSearchId, validationInfo, rangesData },
			})
		);
	};

	const handleValidateWithLegData = (legData) => async () => {
		setValidationLoading(true);
		try {
			const searchFields = loadSearchId ? await getUIFiltersSearch(loadSearchId) : null;
			const { carrier, truck } = searchFields ?? strategyReqData;
			const { data, searchId } = await validateMove({ legData, carrier, truck });
			setRangesData(data);
			setListSearchId(searchId);
			getValidationInfoFromData(data);
		} catch (error) {
			setErrors(error);
			setValidationLoading(false);
		}
	};

	const handleValidateWithMoveId =
		(moveId, onlyExisting = false) =>
		async () => {
			setValidationLoading(true);
			try {
				const { data, searchId } = await validateMove({ moveId, onlyExisting });
				setRangesData(data);
				setListSearchId(searchId);
				getValidationInfoFromData(data);
			} catch (error) {
				setErrors(error);
				setValidationLoading(false);
			}
		};

	const getValidationInfoFromData = (data) => {
		const countTotal = data?.counters?.total;
		if (!countTotal) {
			setValidationInfo("No loads right now");
			setValidationLoading(false);
			return;
		}

		const { payment, profit, volume, rpm, loadedMiles, emptyMiles } = legData;
		const totalMiles = loadedMiles + emptyMiles;
		const currencyNoDecimals = (num) => formatCurrency(num, 0);
		const rangeAll = data?.ranges?.find((r) => r.type === "ALL");
		const profitRange = getDataRangeMinMax(rangeAll?.profit)?.map(currencyNoDecimals);
		const rateRange = getDataRangeMinMax(rangeAll?.rate)?.map(currencyNoDecimals);
		const rpmRange = getDataRangeMinMax(rangeAll?.rate)
			?.map((rate) => rate / totalMiles)
			?.map((rpm) => formatCurrency(rpm));

		const { mean: rateMean, std: rateStd } = data?.stats?.rate;
		const { mean: profitMean, std: profitStd } = data?.stats?.profit;
		const [rpmMean, rpmStd] = [rateMean / totalMiles, rateStd / totalMiles];

		const getStdOrMean = (std, mean, sign) => (countTotal > 3 ? mean + std * sign : mean);
		const getStdOrMeanBottom = (std, mean) => getStdOrMean(std, mean, -2);
		const getStdOrMeanTop = (std, mean) => getStdOrMean(std, mean, 2);

		const volumeThreshold = Math.round(volume / 10);
		const bottomRateThreshold = getStdOrMeanBottom(rateStd, rateMean);
		const bottomProfitThreshold = getStdOrMeanBottom(profitStd, profitMean);
		const bottomRpmThreshold = getStdOrMeanBottom(rpmStd, rpmMean);
		const topRateThreshold = getStdOrMeanTop(rateStd, rateMean);
		const topProfitThreshold = getStdOrMeanTop(profitStd, profitMean);
		const topRpmThreshold = getStdOrMeanTop(rpmStd, rpmMean);

		const validateVal = (val, min, max, mean) => (countTotal > 3 ? val >= min && val <= max : val <= mean);

		const volumeValidated = countTotal >= volumeThreshold;
		const rateValidated = validateVal(payment, bottomRateThreshold, topRateThreshold, rateMean);
		const profitValidated = validateVal(profit, bottomProfitThreshold, topProfitThreshold, profitMean);
		const rpmValidated = validateVal(rpm, bottomRpmThreshold, topRpmThreshold, rpmMean);

		const computeRow = (label, range, moveValue, mean, std, min, max, validated, formatter) => ({
			label,
			value: `[${range?.[0]} - ${range?.[1]}]`,
			moveValue: formatter(moveValue),
			mean: formatter(mean),
			std: formatter(std),
			threshold: countTotal > 3 ? `[${formatter(min)} - ${formatter(max)}]` : `> ${formatter(mean)}`,
			validated,
		});

		setValidationInfo([
			{
				label: "Volume",
				value: countTotal ?? 0,
				moveValue: volume,
				threshold: `> ${volumeThreshold}`,
				validated: volumeValidated,
			},
			computeRow(
				"Rate",
				rateRange,
				payment,
				rateMean,
				rateStd,
				bottomRateThreshold,
				topRateThreshold,
				rateValidated,
				currencyNoDecimals
			),
			computeRow(
				"RPM",
				rpmRange,
				rpm,
				rpmMean,
				rpmStd,
				bottomRpmThreshold,
				topRpmThreshold,
				rpmValidated,
				formatCurrency
			),
			computeRow(
				"Profit",
				profitRange,
				profit,
				profitMean,
				profitStd,
				bottomProfitThreshold,
				topProfitThreshold,
				profitValidated,
				currencyNoDecimals
			),
		]);

		setValidationLoading(false);
	};

	return (
		<div>
			{validationInfo ? (
				Array.isArray(validationInfo) ? (
					variant !== "text" ? (
						<Chip
							label={validateWithData(validationInfo) ? "See loads" : "Warnings"}
							style={{
								backgroundColor: validateWithData(validationInfo) ? "#ECFFDC" : "#FFECDC",
								color: validateWithData(validationInfo) ? "#228B22" : "#8B2222",
							}}
							clickable
							onClick={handleOpenInfo}
							onDelete={handleOpenInfo}
							deleteIcon={
								validateWithData(validationInfo) ? (
									<Done fontSize="small" style={{ color: "#228B22", opacity: ".4" }} />
								) : (
									<Close fontSize="small" style={{ color: "#8B2222", opacity: ".4" }} />
								)
							}
						></Chip>
					) : (
						<ValidationLabel isVerified={validateWithData(validationInfo)} onClick={handleOpenInfo} />
					)
				) : variant !== "text" ? (
					<Chip label={validationInfo} style={{ backgroundColor: "#DEDEDE", color: "#454545" }} />
				) : (
					<Typography style={{ color: "#454545" }}> {validationInfo}</Typography>
				)
			) : errors ? (
				variant !== "text" ? (
					<Chip
						label="Risky"
						style={{ backgroundColor: "#FFECDC", color: "#8B2222" }}
						onDelete={() => {}}
						deleteIcon={<Close fontSize="small" />}
					/>
				) : (
					<Typography style={{ color: "#8B2222" }}>Risky</Typography>
				)
			) : validationLoading ? (
				<CircularProgress
					size={variant !== "text" ? "2rem" : "1.42rem"}
					color="secondary"
					style={variant === "text" ? { minHeight: 0, minWidth: 0, padding: 0, lineHeight: 1.5 } : {}}
				/>
			) : (
				<Button
					style={variant === "text" ? { minHeight: 0, minWidth: 0, padding: 0, lineHeight: 1.5 } : {}}
					variant={variant !== "text" ? "contained" : "text"}
					color="secondary"
					onClick={moveId ? handleValidateWithMoveId(moveId) : handleValidateWithLegData(legData)}
				>
					Check Loads
				</Button>
			)}
		</div>
	);
};

export default ValidateMoveButton;
