import { centsToDollars } from "app/main/utils/financeUtils";

// Consts
import { ENTERPRISE_PAYROLL_LIST_STATUSES, DRIVER_JOURNAL_ENTRY_CATEGORIES } from "app/main/consts";

const PAYROLL_SECTION = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "state",
					label: "Status",
					type: "text",
					builder: (item) => {
						const status = ENTERPRISE_PAYROLL_LIST_STATUSES.find(({ value }) => value === item?.state);
						return status?.label;
					},
				},
				{
					key: "tripsSubtotal",
					label: "Total Trip Pay",
					type: "currency",
				},
				{
					key: "grandTotal",
					label: "Total Payroll",
					type: "currency",
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "totalAdditions",
					label: "Total Additions",
					type: "currency",
				},
				{
					key: "totalDeductions",
					label: "Total Deductions",
					type: "currency",
				},
				{ key: "", type: "stub", label: "" },
			],
		},
	},
];

const PAYROLL_PERIOD_SECTION = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "period_date",
					type: "text",
					label: "Period",
					builder: (item) => {
						return item?.period?.period_start_date && item?.period?.period_end_date
							? `${item?.period?.period_start_date} - ${item?.period?.period_end_date}`
							: "-";
					},
				},
			],
		},
	},
];

const TRIPS_SECTION = [
	{
		key: "trips__view",
		type: "pattern",
		label: "Trips",
		component: { preview: { classes: { label: "" }, labelDivider: true } },
		content: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "label",
								type: "text",
								label: "Load ID",
							},
							{
								key: "metadata.payroll", // FIXME: FAC-2052 Invalid key format
								type: "currency",
								label: "Payroll",
							},
							{
								key: "",
								type: "stub",
								label: "",
							},
						],
					},
				},
			],
		},
	},
];

const ENTRIES_SECTION = [
	{
		key: "entries",
		type: "pattern",
		label: "Ledger",
		component: { preview: { classes: { label: "" }, labelDivider: true } },
		content: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "category",
								type: "text",
								label: "Type",
								builder: (item) => {
									const status = DRIVER_JOURNAL_ENTRY_CATEGORIES.find(({ value }) => value === item?.category);
									return status?.label;
								},
							},
							{
								key: "description",
								type: "text",
								label: "Description",
							},
							{
								key: "amount_cents",
								type: "currency",
								label: "Amount",
								builder: (item) => centsToDollars(item?.amount_cents),
							},
						],
					},
				},
			],
		},
	},
];

const createForm = ({ isHistoryItem }) => {
	return {
		items: [
			...PAYROLL_SECTION,
			...(isHistoryItem ? [...PAYROLL_PERIOD_SECTION, ...TRIPS_SECTION, ...ENTRIES_SECTION] : []),
		],
	};
};

export default createForm;
