import moment from "moment";
import clsx from "clsx";
import { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import MessageListItem from "./MessageListItem";
import MessageEmptyListView from "./MessageEmptyListView";
import MessageSendPanel from "./MessageSendPanel";
import { buildChatLabels } from "../utils/chatUtils";
import MobileDetect from "mobile-detect";

import { makeMessageRowStyles } from "./rowStyles";
import { selectMessages, setVisible, fetchMessages } from "app/store/messenger/messageSlice";
import { selectChats, setLoading, fetchChats, selectChatsById } from "app/store/messenger/chatSlice";
import { addUserChatAssignment } from "app/services/usersServices";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";

import { makeStyles } from "@material-ui/core/styles";
import { isRoleExternal, getUserId } from "app/services/LoginService";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const useStyles = makeStyles((theme) => makeMessageRowStyles(theme));

const SECONDS_TO_CONNECT = 3 * 60;
const SECONDS_GAP_NO_CONVERSATION = 20 * 60;

function MessageList(props) {
	const nativeMobile = props.nativeMobile;
	const onEvent = props.onEvent;

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const classes = useStyles(props);
	const chatRef = useRef(null);
	const viewerIsOwner = isRoleExternal();
	const viewerUserId = getUserId();

	const chats = useSelector(selectChats);
	const chatIds = useMemo(() => chats.map((chat) => chat._id), [chats]);

	const initialized = useSelector(({ messenger }) => messenger.messages.initialized);
	const loading = useSelector(({ messenger }) => messenger.messages.loading);
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);
	const errors = useSelector(({ messenger }) => messenger.messages.errors);
	const hasMore = useSelector(({ messenger }) => messenger.messages.loadMore);
	const messages = useSelector(selectMessages);
	const socket = useSelector(({ socket }) => socket.connection.socket);
	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const user = useSelector(({ auth }) => auth.user);
	const isDriver = user?.rolesLabels?.includes("driver");

	const [scrollState, setScrollState] = useState({});
	const [messageSummary, setMessageSummary] = useState({});
	const [locationPermission, setLocationPermission] = useState(false);
	const [addingChat, setAddingChat] = useState(false);
	const [scrollOffset, setScrollOffset] = useState(false);

	const activeChatComplete = useSelector((state) => selectChatsById(state, activeChat._id));

	useEffect(() => {
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "GET_PERMISSIONS", data: {} }));
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);

		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
		// eslint-disable-next-line
	}, [locationPermission]);

	const handleAssignTruck = async () => {
		console.log(`[MessagesList] chat ${activeChat._id} to user ${viewerUserId}`);
		let timeout;
		try {
			setAddingChat(true);
			await addUserChatAssignment(viewerUserId, activeChat._id);
			dispatch(setLoading(true));
			dispatch(fetchChats({ userId: viewerUserId }));

			// Giving extra time for chats to be fetched
			// before we hide loading inidcation
			timeout = setTimeout(() => {
				setAddingChat(false);
			}, 5000);
		} catch (error) {
			setAddingChat(false);
			showErrorSnackbar(snackbar, error);

			// WORAROUND: Reloading chats if faield to assign chat
			// because already assigned to it
			if (error.errors?.[0]?.message?.includes("assigned")) {
				dispatch(setLoading(true));
				dispatch(fetchChats({ userId: viewerUserId }));
			}
		}

		return () => {
			clearTimeout(timeout);
		};
	};

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "PhoneLocationPermissions":
					setLocationPermission(dataParsed.data?.permission);
					return;
				default:
					break;
			}
		} catch (e) {}
	};

	const loadMoreChat = () => {
		setScrollOffset(chatRef?.current?.scrollHeight);
		let beforeUnix = messages?.[0]?.sentUnix;
		dispatch(fetchMessages({ userId: viewerUserId, chatId: activeChat?._id, beforeUnix }));
	};

	useEffect(() => {
		let lastMessage;
		let lastSenderOwner;
		let lastInternalMessage;
		let lastExternalMessage;
		let earliestTodayUnasweredMessage;
		let replyBy;

		const isUserOwner = (user) => {
			return (
				user?.roleLabel === "carrier" ||
				user?.roleLabel === "driver" ||
				user?.roleLabel === "carrier_manager" ||
				user?.roleLabel === "carrier_dispatcher" ||
				user?.roleLabel === "carrier_generic"
			);
		};

		if (messages?.length > 0) {
			lastMessage = messages[messages.length - 1];
			lastSenderOwner = isUserOwner(lastMessage?.metadata?.user);

			for (let i = messages.length - 1; i >= 0; i--) {
				if (!lastInternalMessage && !isUserOwner(messages[i]?.metadata?.user)) {
					lastInternalMessage = messages[i];
				}
				if (!lastExternalMessage && isUserOwner(messages[i]?.metadata?.user)) {
					lastExternalMessage = messages[i];
				}
				if (lastInternalMessage && lastExternalMessage) {
					break;
				}
			}

			if (lastSenderOwner) {
				// Finding first message where gap bettween > 20 mins
				for (let i = messages.length - 2; i >= 0; i--) {
					let timeDiff = messages[i + 1].sentUnix - messages[i].sentUnix;
					if (timeDiff >= SECONDS_GAP_NO_CONVERSATION) {
						// Found earliers message with gap of 20 mins between messages
						earliestTodayUnasweredMessage = messages[i + 1];
						break;
					} else if (!isUserOwner(messages[i]?.metadata?.user) && timeDiff < SECONDS_GAP_NO_CONVERSATION) {
						// There is active conversation going on
						break;
					}
				}

				// If only one message and it's sent by customer using it
				if (!earliestTodayUnasweredMessage) {
					earliestTodayUnasweredMessage = messages[0];
				}

				// If message send not today ignorring it
				if (!moment.unix(earliestTodayUnasweredMessage?.sentUnix).isSame(moment(), "day")) {
					earliestTodayUnasweredMessage = null;
				}
			}

			// If reply is needed we going to calcualte time since the first unreplied message and show
			let noResponseSeconds =
				lastSenderOwner && earliestTodayUnasweredMessage
					? moment().diff(moment.unix(earliestTodayUnasweredMessage?.sentUnix), "seconds")
					: -1;

			// We need to reply after short period of time or show call banner
			if (noResponseSeconds > -1) {
				replyBy = moment.unix(earliestTodayUnasweredMessage?.sentUnix).add(SECONDS_TO_CONNECT, "seconds");
			}
		}

		if (messageSummary.lastMessage?.sentUnix !== lastMessage?.sentUnix) {
			setMessageSummary({ lastMessage, lastSenderOwner, lastInternalMessage, replyBy });
			if (!messageSummary.replyBy && replyBy) {
				scrollToBottom();
				// Delay is required to scroll messages after rendering of the bar
				setTimeout(() => scrollToBottom(), 50);
				// Delay is required to scroll messages on native mobile, rendering takes longer
				setTimeout(() => scrollToBottom(), 500);
			}
		}
		// eslint-disable-next-line
	}, [activeChat, scrollState, messages?.length, loading, chatRef]);

	useEffect(() => {
		dispatch(setVisible(true));
		return () => {
			dispatch(setVisible(false));
		};
	}, [dispatch]);

	useEffect(() => {
		const chatChanged = scrollState.chatId !== activeChat?._id || scrollState.chatType !== activeChat?.type;
		const messagesChanged = scrollState.messagesLength !== messages?.length;

		if (messages?.length > 0 && !loading && (chatChanged || messagesChanged)) {
			if (scrollOffset > 0 && !chatChanged) {
				scrollTo(scrollOffset);
			} else {
				scrollToBottom();
				// Delay is required to scroll messages after rendering of the bar
				setTimeout(() => scrollToBottom(), 50);
				// Delay is required to scroll messages on native mobile, rendering takes longer
				setTimeout(() => scrollToBottom(), 500);
			}
			setScrollOffset(null);
			setScrollState({
				chatId: activeChat?._id,
				chatType: activeChat?.type,
				messagesLength: messages?.length,
			});

			if (onEvent) onEvent("SCROLLED_DOWN");
		}
		// eslint-disable-next-line
	}, [activeChat, scrollState, messages?.length, loading, chatRef]);

	useEffect(() => {
		if (!chatIds.includes(activeChat._id)) {
			return;
		}

		if (!messages?.length) {
			// Checking if chack is empty but just craeted and unread because of that
			const chatUnread = activeChat?.metadata?.status?.unread;
			// Checking if chack is empty but just craeted and unread because of that
			const nativeOverride = activeChat?.nativeOverried;
			if (loading || (!chatUnread && !nativeOverride)) {
				return;
			}

			// Updating read status of the chat if chat is unread
			let data = { chatId: activeChat._id, lastSentUnix: activeChat.lastSentUnix, lastSentUnixSetNow: nativeOverride };
			socket.emit("message", { type: "CHAT_READ", data });
		} else {
			// Checking if last message is unread
			let lastMessage = messages[messages.length - 1];
			const messageUnread = lastMessage?.metadata?.status?.unread;
			if (!messageUnread) {
				return;
			}
			// Updating read status of the chat if chat is unread
			let data = { chatId: lastMessage.chat, lastSentUnix: lastMessage.sentUnix };
			socket.emit("message", { type: "CHAT_READ", data });
		}
		// eslint-disable-next-line
	}, [messages, socket, isAuthenticated, loading]);

	const scrollToBottom = () => {
		if (chatRef?.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight;
		}
	};

	const scrollTo = (scrollOffset) => {
		if (chatRef?.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight - scrollOffset;
		}
	};

	return (
		<div className={clsx("flex flex-1 flex-col relative min-h-0")}>
			{errors?.length ? (
				<div className="flex flex-col flex-auto h-full flex flex-col pb-20 items-center justify-center">
					<Typography color="error">{errors[0].message}</Typography>
				</div>
			) : loading && !initialized && !nativeMobile ? (
				<div className="flex flex-col flex-auto h-full flex flex-col items-center justify-center pb-80">
					<CircularProgress color="secondary" />
				</div>
			) : (
				<>
					{buildChatLabels({ chat: activeChat, truck: activeChat?.metadata?.truck }, viewerIsOwner)}
					{activeChatComplete?.mute && activeChatComplete?.mute !== "NONE" && (
						<div className="bg-blue-100 py-4">
							<Typography className="text-center">
								This chat is muted.
								{` ${
									activeChatComplete.mute === "ALL"
										? "You won't receive any notifications"
										: activeChatComplete.mute === "AUTOMATED"
										? "You won't receive notifications for automated messages"
										: ""
								}`}
							</Typography>
						</div>
					)}
					{!viewerIsOwner && !loading && messageSummary?.lastSenderOwner && activeChat.type === "TRUCK_EXTERNAL" && (
						<div className="flex py-14 px-12 mb-2 items-center justify-center bg-red">
							<Typography className={"text-white text-12 font-medium"}>
								You need to reply to the latest customer's message(s)
							</Typography>
						</div>
					)}
					<FuseScrollbars position={"none"} ref={chatRef} className="flex flex-1 flex-col overflow-y-auto">
						{messages.length > 0 && hasMore && (
							<div className="self-center pt-14">
								<Button variant="contained" color="primary" onClick={loadMoreChat}>
									Load more
								</Button>
							</div>
						)}
						{messages.length > 0 ? (
							<div
								className={
									"flex flex-col pt-16 px-16 ltr:pl-56 rtl:pr-56 pb-40 " +
									(scrollOffset > 0 ? " opacity-50 " : " opacity-100 ")
								}
							>
								{messages.map((item, i) => (
									<MessageListItem
										key={item.localId ? item.user + "_" + item.localId : item._id}
										prevMessage={messages[i - 1]}
										currentMessage={item}
										nextMessage={messages[i + 1]}
										isLastMessage={i + 1 === messages.length}
										classes={{ row: classes.messageRow }}
										viewerIsOwner={viewerIsOwner}
										locationPermission={locationPermission}
										nativeMobile={nativeMobile}
									/>
								))}
							</div>
						) : initialized && !loading ? (
							<MessageEmptyListView />
						) : null}
					</FuseScrollbars>
					{!initialized || (!chatIds.includes(activeChat._id) && isDriver) ? null : !chatIds.includes(
							activeChat._id
					  ) ? (
						<div className="flex w-full items-center justify-center bg-orange-400 py-20 px-32">
							<div className="flex flex-1 items-center justify-center">
								<Typography>
									This truck is not assigned to you. To receive notifications and be able to send messages you can
									temporarily become a participant in that chat.
								</Typography>
							</div>
							<Button disabled={addingChat} variant="contained" color="primary" onClick={handleAssignTruck}>
								{addingChat ? "Entering..." : "Enter Chat"}
							</Button>
						</div>
					) : (
						<MessageSendPanel
							chatId={activeChat?._id}
							nativeMobile={nativeMobile}
							onEvent={onEvent}
							onMessageSent={() => scrollToBottom()}
							chatTruckView={props.chatTruckView}
						/>
					)}
				</>
			)}
		</div>
	);
}

export default MessageList;
