import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import ChatListChannels from "./ChatListChannels";
import ChatEmptyListView from "./ChatEmptyListView";
import { selectChats, setLoading, fetchChats } from "app/store/messenger/chatSlice";
import { setActiveChat, setVisible, showError } from "app/store/messenger/messageSlice";
import { isRoleExternal, getUserId, isSearchEnabled } from "app/services/LoginService";
import { addUserChatAssignment } from "app/services/usersServices";
import { SmarthopConfirmDialog } from "@smarthop/form";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import ChatListChannelItem from "./ChatListChannelItem";
import { readURLParameters } from "app/main/utils/urlUtils";
import { buildChannelId } from "../utils/chatUtils";
import { isEnabled } from "app/services/featureStorageService";

import Collapse from "@mui/material/Collapse";
import ChatListSingle from "./ChatListSingle";

//NOTE: If you add a new channel, also add it in the mobile app
const UNREAD_CHANNEL = "UNREAD_CHANNEL";
const GENERIC_CHANNELS = [UNREAD_CHANNEL];
const CHANNEL_NAME = { UNREAD_CHANNEL: "Unread Messages" };

function ChatList(props) {
	const chatTruckView = props.chatTruckView;
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const chats = useSelector(selectChats);
	const loading = useSelector(({ messenger }) => messenger.chats.loading);
	const initialized = useSelector(({ messenger }) => messenger.chats.initialized);
	const errors = useSelector(({ messenger }) => messenger.chats.errors);
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);
	const chatType = useSelector(({ messenger }) => messenger.chats.chatType);
	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const user = useSelector(({ auth }) => auth.user);

	const [requestedChatId] = useState(readURLParameters()?.chat_id);
	const [requestedChatStatus, setRequestedChatStatus] = useState(false);

	const viewerIsOwner = isRoleExternal();
	const viewerUserId = getUserId();
	const permissionSearch = isSearchEnabled();
	const isNewUIEnabledMessages = isEnabled("CHAT_NEW_UI_MESSAGES");

	//By default the collapsibles will be closed. At the start we don't know all keys
	const [collapsibleStatus, setCollapsibleStatus] = useState({});

	const handleOpenCloseClick = (channel) => {
		const newStatus = { ...collapsibleStatus };
		newStatus[channel] = !collapsibleStatus?.[channel];
		setCollapsibleStatus(newStatus);
	};

	const handleAssignTruck = async () => {
		console.log(`[ChatList] handleAssignTruck: assigning truck for chat ${requestedChatId}`, viewerUserId);
		try {
			await addUserChatAssignment(viewerUserId, requestedChatId);
			dispatch(setLoading(true));
			dispatch(fetchChats({ userId: viewerUserId }));
			setRequestedChatStatus(null);
		} catch (error) {
			showErrorSnackbar(snackbar, error);
		}
	};

	const openChat = (chat) => {
		if (activeChat?._id === chat._id) {
			return;
		}
		if (props.nativeMobile) {
			window.location.href = window?.location?.href + "&chatId=" + chat._id;
		} else {
			dispatch(setActiveChat(chat));
			if (!isAuthenticated) dispatch(showError("Offline"));
		}
	};

	useEffect(() => {
		if (!initialized || loading || !requestedChatId || !!requestedChatStatus) {
			return;
		}

		const chat = chats.find((chat) => chat._id === requestedChatId);
		if (chat) {
			dispatch(setActiveChat(chat));
			setRequestedChatStatus("OPENED");
		} else if (!viewerIsOwner) {
			setRequestedChatStatus("NEED_TO_ADD");
		} else {
			setRequestedChatStatus("NOT_FOUND");
		}
		// eslint-disable-next-line
	}, [initialized, loading, requestedChatId]);

	useEffect(() => {
		dispatch(setVisible(true));
		return () => {
			dispatch(setVisible(false));
		};
	}, [dispatch]);

	const items = useMemo(() => {
		//Group by accounts
		const groupByAccountAndChannel = chats.reduce((group, chat) => {
			const chatType = chat.type;
			const carrier = chat.metadata.truck.carrier;
			if (!group[carrier])
				group[carrier] = {
					data: {
						company: chat.metadata.truck.company,
						mcnumber: chat.metadata.truck.mcnumber,
						status: { unread: false, chatsUnread: [] },
					},
				};
			if (!group[carrier][chatType]) {
				group[carrier][chatType] = [chat];
			} else {
				group[carrier][chatType].push(chat);
			}
			//Don't add silent or selected chats to unread
			//Add previous active chat unread to avoid UI jumps
			if (
				(chat.metadata?.status?.unread && !chat.metadata?.status?.silent && activeChat?._id !== chat._id) ||
				(activeChat?._id === chat._id && activeChat?.metadata?.status?.unread && !activeChat?.metadata?.status?.silent)
			) {
				group[carrier].data.status.unread = true;
				group[carrier].data.status.chatsUnread.push(chat._id);
				if (!group[UNREAD_CHANNEL]) {
					group[UNREAD_CHANNEL] = [chat];
				} else {
					group[UNREAD_CHANNEL].push(chat);
				}
			}
			return group;
		}, {});

		let fullList = {};
		//Main account with multiple carriers
		if (groupByAccountAndChannel && user.linkedSubAccounts?.length > 0) {
			const sortedAccounts = Object.keys(groupByAccountAndChannel).sort((a, b) => {
				if (a === "UNREAD_CHANNEL") return -1;
				if (b === "UNREAD_CHANNEL") return 1;
				if (b - a) {
					return -1;
				}
				if (a - b) {
					return 1;
				}
				return 0;
			});

			fullList = sortedAccounts.map((key) => (
				<>
					<ChatListChannelItem
						key={"_" + buildChannelId(key)}
						type={!GENERIC_CHANNELS.includes(key) ? "CARRIER" : "UNREAD"}
						title={
							GENERIC_CHANNELS.includes(key)
								? CHANNEL_NAME[key]
								: `${groupByAccountAndChannel[key].data.company} ${
										groupByAccountAndChannel[key].data.mcnumber
											? " - " + groupByAccountAndChannel[key].data.mcnumber
											: ""
								  }`
						}
						unread={groupByAccountAndChannel[key].data?.status?.unread && key !== activeChat?.metadata?.truck?.carrier}
						onClick={(channelId) => handleOpenCloseClick(channelId)}
						channelId={buildChannelId(key)}
						channelIsOpen={collapsibleStatus?.[buildChannelId(key)]}
						iconAligment="right"
					/>
					<Collapse
						key={"collapse_" + buildChannelId(key)}
						in={!collapsibleStatus?.[buildChannelId(key)]}
						timeout="auto"
						unmountOnExit
					>
						{GENERIC_CHANNELS.includes(key) ? (
							<ChatListSingle
								key={"_generic_" + buildChannelId(key)}
								chats={groupByAccountAndChannel[key]}
								onClick={(chat) => openChat(chat)}
							/>
						) : (
							<ChatListChannels
								key={"_channels_" + buildChannelId(key)}
								chats={groupByAccountAndChannel}
								subAccount={key}
								collapsibleStatuses={collapsibleStatus}
								onClick={(chat) => openChat(chat)}
								onClickCollapse={(channelId) => handleOpenCloseClick(channelId)}
							/>
						)}
					</Collapse>
					{GENERIC_CHANNELS.includes(key) && <Divider />}
				</>
			));
		} else if (groupByAccountAndChannel) {
			//Subaccount or regular account
			const accountKey = Object.keys(groupByAccountAndChannel)?.[0];
			let chatsGeneric = GENERIC_CHANNELS.map((channel) => {
				if (!groupByAccountAndChannel[channel]) return <></>;
				return (
					<>
						<ChatListChannelItem
							title={CHANNEL_NAME[channel]}
							unread={groupByAccountAndChannel[channel].data?.status?.unread}
							onClick={(channelId) => handleOpenCloseClick(channelId)}
							channelId={buildChannelId(channel)}
							channelIsOpen={collapsibleStatus?.[buildChannelId(channel)]}
							iconAligment="right"
						/>
						<Collapse in={!collapsibleStatus?.[buildChannelId(channel)]} timeout="auto" unmountOnExit>
							<ChatListSingle
								key={"_generic_" + channel}
								chats={groupByAccountAndChannel[channel]}
								onClick={(chat) => openChat(chat)}
							/>
						</Collapse>
					</>
				);
			});
			fullList = (
				<>
					{chatsGeneric}
					<ChatListChannels
						chats={groupByAccountAndChannel}
						subAccount={accountKey}
						collapsibleStatuses={collapsibleStatus}
						onClick={(chat) => {
							if (activeChat?._id === chat._id) {
								return;
							}
							if (props.nativeMobile) {
								window.location.href = window?.location?.href + "&chatId=" + chat._id;
							} else {
								dispatch(setActiveChat(chat));
								if (!isAuthenticated) dispatch(showError("Offline"));
							}
						}}
						iconAligment="right"
						onClickCollapse={(channelId) => handleOpenCloseClick(channelId)}
					/>
				</>
			);
		}
		return fullList;

		// eslint-disable-next-line
	}, [
		props.nativeMobile,
		chats,
		activeChat,
		viewerIsOwner,
		viewerUserId,
		isAuthenticated,
		dispatch,
		chatTruckView,
		chatType,
		permissionSearch,
		collapsibleStatus,
	]);

	return (
		<div className={`flex flex-col flex-auto h-full flex flex-col ${isNewUIEnabledMessages ? "bg-gray-100" : ""}`}>
			{errors?.length ? (
				<div className="flex flex-col flex-auto h-full flex flex-col items-center justify-center">
					<Typography color="error">{errors[0].message}</Typography>
				</div>
			) : loading && !initialized ? (
				<div className="flex flex-col flex-auto h-full flex flex-col items-center justify-center pb-80">
					<CircularProgress color="secondary" />
				</div>
			) : initialized && !loading && (Object.keys(items).length === 0 || items.length === 0) ? (
				<div className="flex flex-col flex-auto h-full flex flex-col items-center justify-center pb-80">
					<ChatEmptyListView />
				</div>
			) : (
				<FuseScrollbars position={"none"} className="overflow-y-auto flex-1 pb-80">
					<List className="w-full m-0 p-0">{items}</List>
				</FuseScrollbars>
			)}
			<SmarthopConfirmDialog
				open={requestedChatStatus === "NEED_TO_ADD"}
				title="Chat Not Found"
				acceptMsg="Assign Temporarely"
				onAccept={() => handleAssignTruck()}
				onClose={() => setRequestedChatStatus("CANCELED")}
			>
				<List dense>
					<ListItem>
						<ListItemText
							primary={`It seems you don't have access to this chat yet. Probably truck is not assigned to you.`}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={`Would you like to temporarely assign that truck to yourself? Truck would be automatically unassigned from you after 4h.`}
						/>
					</ListItem>
				</List>
			</SmarthopConfirmDialog>
		</div>
	);
}
ChatList.defaultProps = {
	chatTruckView: false,
};
export default ChatList;
