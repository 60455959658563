import moment from "moment";

const objectMapper = (obj, keysAdapter = new Map()) => {
	const adaptedObj = {};
	keysAdapter.forEach((value, key) => {
		if (typeof key === "string") {
			if (typeof value === "string") {
				const newKey = value;
				adaptedObj[newKey] = obj[key];
			} else if (Array.isArray(value)) {
				const objValue = obj[key];
				const [newKey, adapterFunc] = value;
				adaptedObj[newKey] = adapterFunc(objValue);
			} else if (typeof value === "function") {
				const adapterFunc = value;
				adaptedObj[key] = adapterFunc(obj[key]);
			}
		} else if (Array.isArray(key)) {
			if (Array.isArray(value)) {
				const adapterFuncArgs = key.map((k) => obj[k]);
				const [newKey, adapterFunc] = value;
				adaptedObj[newKey] = adapterFunc(...adapterFuncArgs);
			}
		}
	});
	return { ...obj, ...adaptedObj };
};

const cityStateToTextField = (city, state) => {
	return city && state
		? {
				label: `${city.toUpperCase()}, ${state}`,
				value: `${city.toUpperCase()}, ${state}`,
		  }
		: {};
};

const transformedOrDefault = (fn, defaultVal) => (val) => fn(val) || defaultVal;

const resToModelKeysAdapter = new Map([
	[
		["city", "state"],
		["location_origin__view", cityStateToTextField],
	],
	["start_date", (start_date) => moment(start_date)],
	[
		["dcity", "dstate"],
		["location_destination__view", cityStateToTextField],
	],
	["max_paths", transformedOrDefault(Number, 50)],
	["path_length", transformedOrDefault(Number, 3)],
	["min_volume", transformedOrDefault(Number, 3)],
	["end_market_profit", transformedOrDefault(Number, 0)],
	["min_profit", transformedOrDefault(Number, -10000)],
	["min_profit_per_leg", transformedOrDefault(Number, -500)],
	["min_distance", ["miles_range__min", transformedOrDefault(Number, 600)]],
	["max_distance", ["miles_range__max", transformedOrDefault(Number, 6000)]],
	[
		"carrierObj",
		[
			"carrier__view",
			(carrierObj) => ({
				value: carrierObj?._id,
				label: carrierObj?.carrier_company_name,
				description: `MC ${carrierObj?.carrier_mc_number ?? "Empty"}`,
			}),
		],
	],
	[
		["truckObj", "carrierObj"],
		[
			"truck__view",
			(truckObj, carrierObj) => ({
				value: truckObj?._id,
				label: `${truckObj?.equipment}-${truckObj?.driver__view?.label ?? carrierObj?.carrier_company_name}`,
				description: `Truck #${truckObj?.truckid}`,
			}),
		],
	],
]);

const strategyResToModelKeysAdapter = new Map([
	[
		["city", "state"],
		["location_origin__view", cityStateToTextField],
	],
	[
		["city", "state"],
		["location_origin", (city, state) => `${city.toUpperCase()}, ${state}`],
	],
	["start_date", (start_date) => moment(start_date)],
	[
		["dcity", "dstate"],
		["location_destination__view", cityStateToTextField],
	],
	[
		["dcity", "dstate"],
		["location_destination", (city, state) => city && state && `${city.toUpperCase()}, ${state}`],
	],
	["max_paths", transformedOrDefault(Number, 50)],
	["path_length", transformedOrDefault(Number, 3)],
	["min_volume", transformedOrDefault(Number, 3)],
	["end_market_profit", transformedOrDefault(Number, 0)],
	["min_profit", transformedOrDefault(Number, -10000)],
	["min_profit_per_leg", transformedOrDefault(Number, -500)],
	[
		["min_distance", "max_distance"],
		["miles_range", (min, max) => [min, max]],
	],
	["min_distance", ["miles_range__min", transformedOrDefault(Number, 600)]],
	["max_distance", ["miles_range__max", transformedOrDefault(Number, 6000)]],
]);

function responseToModel(res = {}) {
	const adaptedResponse = objectMapper(res, resToModelKeysAdapter);
	// _v is a hack to avoid getting the reset method from SmarthopFormView
	// and bypass the caching of the defaultValues through useForm instantiation
	return { ...adaptedResponse, _v: Math.random() };
}

function strategyResponseToModel(res = {}) {
	return objectMapper(res, strategyResToModelKeysAdapter);
}

export { responseToModel, strategyResponseToModel };
