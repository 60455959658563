import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getTimelineTrips = (truckId, params) => {
	return createRequest()
		.get(`api/timeline/truck/${truckId}/trips`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
