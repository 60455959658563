import { DisplayField, DisplaySection } from "app/main/utils/uiUtils";

const TruckInfo = ({ truckInfo }) => {
	return (
		<>
			<DisplaySection title="Driver Details">
				<div className="flex flex-row ">
					<DisplayField label="Carrier" value={truckInfo?.carrier__view?.label ?? "No information"} />
					<DisplayField
						label="Truck"
						value={`${truckInfo?.truck__view?.metadata?.equipment} - ${truckInfo?.truck__view?.metadata?.truckid}`}
					/>
					<DisplayField label="Driver Name" value={truckInfo?.driver__view?.label ?? "No information"} />
				</div>
			</DisplaySection>
			<DisplaySection title="Plan Details">
				<div className="flex flex-row ">
					<DisplayField
						label="Available Date"
						value={truckInfo?.plan__view?.metadata?.current_available_date__pretty ?? "No information"}
					/>
					<DisplayField
						label="Available Location"
						value={truckInfo?.plan__view?.metadata?.current_location ?? "No information"}
					/>
					<DisplayField
						label="Desired destination"
						value={truckInfo?.plan__view?.metadata?.current_destination ?? "No information"}
					/>
				</div>
			</DisplaySection>
		</>
	);
};

export default TruckInfo;
