import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

const getJournalEntriesByPayrollId = async ({ payrollId, entryId, userId, payeeType }) => {
	const url = `billing/v3/payroll/${payrollId}/journalEntry/${entryId}${
		payeeType === "USER" ? `?userId=${userId}` : ""
	}`;
	try {
		const res = await createRequest().get(url, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

const editJournalEntriesByPayrollId = async ({ payrollId, entryId, data }) => {
	const url = `billing/v3/payroll/${payrollId}/journalEntry/${entryId}`;
	try {
		const res = await createRequest().put(url, { data }, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

/**
 * @description Endpoint for getting a recurring payroll rule
 * @async
 * @param {String} recurrentPaymentId recurrent payment mongo reference Id
 */
const getPayrollRule = async ({ recurrentPaymentId }) => {
	const url = `billing/v3/recurrent-payment/${recurrentPaymentId}`;
	try {
		const res = await createRequest().get(url, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

/**
 * @description Endpoint for creating a recurring payroll rule
 * @async
 * @param {String} carrierId carrier mongo reference Id
 * @param {String} driverId driver mongo reference Id
 * @param {Object} data data to create
 */
const createPayrollRule = async ({ carrierId, userId, data }) => {
	const url = `billing/v3/carrier/${carrierId}/user/${userId}/recurrent-payment`;
	try {
		const res = await createRequest().post(url, { data }, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

/**
 * @description Endpoint for updating a recurring payroll rule
 * @async
 * @param {String} carrierId carrier mongo reference Id
 * @param {String} driverId driver mongo reference Id
 * @param {String} recurrentPaymentId recurrent payment mongo reference Id
 * @param {Object} data data to update
 */
const editPayrollRule = async ({ carrierId, userId, recurrentPaymentId, data }) => {
	const url = `billing/v3/carrier/${carrierId}/user/${userId}/recurrent-payment/${recurrentPaymentId}`;
	try {
		const res = await createRequest().put(url, { data }, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

/**
 * Retreives payroll settings for this specific trip payees
 * @param {*} carrierId
 * @param {*} tripId
 * @param {*} payeeId
 * @param {*} isDriver
 * @param {*} isSecondaryDriver
 */
const getTripPayeesPayrollSettings = async ({ carrierId, tripId }) => {
	const tripSettingsUrl = `billing/v3/carriers/${carrierId}/trips/${tripId}/payroll-settings`;

	try {
		const res = await createRequest().get(tripSettingsUrl, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

/**
 * Retreives payroll settings for this specific trip payees
 * @param {*} carrierId
 * @param {*} tripId
 * @param {*} payeeId
 * @param {*} isDriver
 * @param {*} isSecondaryDriver
 */
const getFuelPayeesPayrollSettings = async ({ carrierId, transactionId }) => {
	const tripSettingsUrl = `api/fuel/transactions/${transactionId}/payroll-settings`;

	try {
		const res = await createRequest().get(tripSettingsUrl, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

export {
	getPayrollRule,
	createPayrollRule,
	editPayrollRule,
	getJournalEntriesByPayrollId,
	editJournalEntriesByPayrollId,
	getTripPayeesPayrollSettings,
	getFuelPayeesPayrollSettings,
};
