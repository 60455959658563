import { Typography, Icon } from "@material-ui/core";

// Utils
import { createTooltip } from "app/main/utils/tableUtils";
import { getLogoConfig, getLogoImage } from "app/main/utils/brokerUtils";

function BrokerComponent(props) {
	const item = props.item;
	const smallScreen = props.smallScreen;

	const creditScore = item.creditScore;
	const clientid = item.clientid;
	const isOnBoarded = item.isOnBoarded;
	const flexibleReasons = item.flexibleReasons;
	const brokeIcon = getLogoConfig(item?.mcnumber, true);

	let icon = null;
	let iconColor = "text-green";
	let brokerBlacklisted = false;
	if (flexibleReasons?.includes("BROKER_POSSIBLE_SCAMMER")) {
		icon = "report";
		iconColor = "text-red";
	} else if (flexibleReasons?.includes("BROKER_BLACKLISTED")) {
		icon = "close";
		iconColor = "text-red";
		brokerBlacklisted = true;
	} else if (isOnBoarded) {
		icon = "done";
	}

	const CreditScoreView = creditScore ? (
		<Typography
			component={"div"}
			className={`cursor-pointer text-10 ml:text-11 text-grey pl-3 pr-3 ml:px-4 mr-4 font-menium w-20 ml:w-48 leading-3`}
		>
			CS {creditScore ?? " -"}
		</Typography>
	) : null;
	const CreditScoreTooltips = () => {
		const risk = creditScore < 70 ? "high risk" : creditScore < 87 ? "medium risk" : "low risk";
		return (
			<>
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-semibold">
					{!item.smartpayElegible ? "No " : ""}Eligible for SmartPay
				</Typography>
				<Typography color="inherit" variant="body2" className="p-6 font-light">
					This load is {!item.smartpayElegible ? "no " : ""} eligible for SmartPay factoring
				</Typography>
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-semibold">
					{`Credit score - ${risk.toUpperCase()}`}
				</Typography>
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
					{`This broker has a credit score of `}
					<span className={"font-semibold "}>{creditScore}</span>
					{`, It’s considered a ${risk} broker`}
				</Typography>
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
					{`0-69 high-risk broker`}
				</Typography>
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
					{`70-86 medium-risk broker`}
				</Typography>
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
					{`87-100 low-risk broker`}
				</Typography>
				{item.creditScoreSource && (
					<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
						{`Source: ${item.creditScoreSource}`}
					</Typography>
				)}
			</>
		);
	};

	return (
		<div className="flex flex-row">
			{!creditScore ? CreditScoreView : createTooltip(CreditScoreView, CreditScoreTooltips(), "")}
			{icon && <Icon className={"text-16 -mt-2 " + iconColor}>{icon}</Icon>}

			<div className="whitespace-nowrap flex flex-row items-center">
				<div
					className={
						"whitespace-nowrap flex items-center space-x-4 flex-row " + (brokerBlacklisted ? "line-through" : "")
					}
				>
					{brokeIcon.src ? (
						getLogoImage(brokeIcon)
					) : (
						<Typography component={"div"} className={"p-0 m-0 -mt-2 -mb-2 text-12 ml:text-13 "}>
							{clientid.length < (smallScreen ? 16 : 28)
								? clientid
								: clientid.substring(0, smallScreen ? 16 : 26) + "..."}
						</Typography>
					)}
					{brokeIcon.noLogoName ? (
						<Typography component={"div"} className={"tracking-wide text-12 ml:text-13 text-grey-800"}>
							{brokeIcon.label}
						</Typography>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default BrokerComponent;
