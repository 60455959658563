import { Children, cloneElement, useState } from "react";

// mui
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";

// styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import ExpandMore from "@material-ui/icons/ExpandMore";

// components
import LegendSummary from "./LegendSummary";

const PALETTE = ["#CCD1D1", "#D5DBDB", "#E5E7E9", "#EAEDED", "#F3F5F8"];
const DARK_PALETTE = ["#5D6D7E", "#808B96", "#85929E", "#ABB2B9", "#AEB6BF"];

const useStyles = makeStyles((theme) => ({
	icon: {
		color: ({ level }) => DARK_PALETTE[level] ?? DARK_PALETTE[DARK_PALETTE.length - 1],
	},
	legendText: {
		marginBottom: 20,
		paddingLeft: "8.88%",
	},
	summary: ({ level }) => ({
		backgroundColor: PALETTE[level],
	}),
	content: ({ level }) => ({
		backgroundColor: PALETTE[level + 1] ?? "white",
	}),
}));

const Accordion = withStyles({
	root: {
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
	},
	expanded: {
		margin: "0 !important",
	},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
	root: {
		marginBottom: -1,
		height: "20px",
	},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		display: "flex",
		flexDirection: "column",
	},
}))(MuiAccordionDetails);

const Legend = (props) => {
	const { icon, imgSrc, label, description, level = 0, parentChange, expandFromParent, children } = props;
	const [expanded, setExpanded] = useState(false);
	const [itemExpanded, setItemExpanded] = useState(null);
	const classes = useStyles({ level });

	const handleChange = () => {
		setExpanded((e) => !e);
	};

	const childChange = (childId) => (ev, expanded) => {
		setItemExpanded(expanded ? childId : null);
	};

	return (
		<Accordion square expanded={expandFromParent || expanded} onChange={parentChange || handleChange}>
			<AccordionSummary
				className={classes.summary}
				expandIcon={<ExpandMore className={classes.icon} color="inherit" />}
			>
				<LegendSummary icon={icon} imgSrc={imgSrc} label={label} />
			</AccordionSummary>
			<AccordionDetails className={classes.content}>
				{description && <Typography className={classes.legendText}> {description} </Typography>}
				{Children.map(children, (child) =>
					cloneElement(child, {
						...child.props,
						level: level + 1,
						parentChange: childChange(child.props.label),
						expandFromParent: itemExpanded === child.props.label,
					})
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default Legend;
