import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { formatDate } from "app/main/utils/dateUtils";

function PeriodCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const startAt = props.view?.metadata?.startAt;
	const endAt = props.view?.metadata?.endAt;
	const periodType = props.view?.metadata?.periodType;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={props.view} variant={variant} />}
			second={
				<div className="flex flex-wrap">
					{startAt && (
						<CellSecondRow
							icon={"not_started"}
							mobile={mobile}
							label={formatDate(startAt)}
							variant={variant}
							color={"text-green-900"}
						/>
					)}
					{startAt && (
						<CellSecondRow
							icon={"stop_circle"}
							mobile={mobile}
							label={formatDate(endAt)}
							variant={variant}
							color={"text-orange-900"}
						/>
					)}
					{periodType && (
						<CellSecondRow
							icon={"access_time"}
							mobile={mobile}
							label={periodType}
							variant={variant}
							color={"text-blue-900"}
						/>
					)}
				</div>
			}
		/>
	);
}

export default PeriodCellView;
