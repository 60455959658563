import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getTrackingLocations = (tripId) => {
	return createRequest()
		.get(`api/booking/trips/${tripId}/tracking-locations`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getPublicTrackingLocations = (tripId) => {
	return createRequest()
		.get(`api/public/url/trips/${tripId}/tracking-locations`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const addTrackingEvent = (carrierId, tripId, data) => {
	return createRequest()
		.post(`api/booking/trips/${tripId}/tracking-locations`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTrip = (tripId, carrierId) => {
	return createRequest()
		.get(`api/booking/carriers/${carrierId}/trips/${tripId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripLocationsMiles = (locations, changedAddress, truck, carrier, weight, tripId) => {
	return createRequest()
		.post(`api/booking/locations/miles`, { locations, changedAddress, truck, carrier, weight, tripId })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTrip = (tripId, carrierId, data) => {
	return createRequest()
		.put(`api/booking/carriers/${carrierId}/trips/${tripId}`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const parseTripDocument = (carrierId, fileId, data) => {
	return createRequest()
		.post(`api/booking/carriers/${carrierId}/files/${fileId}/parse`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const geturl = (data) => {
	return createRequest()
		.post(`api/url`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTruckLocation = (tripId, data, source = "TMS") => {
	data.source = source;
	return createRequest()
		.post(`api/booking/trips/${tripId}/tracking-locations`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => error);
};

export const getTripRoutePlan = (tripId) => {
	return createRequest()
		.get(`api/booking/trips/${tripId}/route-plan`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripPlanInformation = (tripId) => {
	return createRequest()
		.get(`api/booking/trips/${tripId}/route-plan/information`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripPlanInformationFromTruck = (truckId) => {
	return createRequest()
		.get(`api/booking/truck/${truckId}/route-plan/information`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTripRoutePlan = (tripId, data) => {
	return createRequest()
		.post(`api/booking/trips/${tripId}/route-plan`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const calculateTripPlan = (data) => {
	return createRequest()
		.post(`api/booking/calculate-route-plan`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTripStatus = ({ status, tripId, carrierId, ignoreWarnings = false }) => {
	return createRequest()
		.put(`api/booking/carriers/${carrierId}/trips/${tripId}/status`, { data: { status, ignoreWarnings } })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
export function getTrackingTripNotifications(tripId) {
	return createRequest()
		.get(`/api/tracking/trips/${tripId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export const saveTripRC = ({ rcFile, tripId, carrierId }) => {
	return createRequest()
		.put(`api/booking/carriers/${carrierId}/trips/${tripId}/rc`, {
			data: { rate_con_file__flag: true, rate_con_file: rcFile },
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createTrip = (data) => {
	return createRequest()
		.post("api/booking/create", { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const uploadTripsCsv = (carrierId, metadata) => {
	return createRequest()
		.post(`api/import-trip/carriers/${carrierId}/upload`, metadata)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createTripsFromImport = (carrierId, metadata) => {
	return createRequest()
		.post(`api/import-trip/carriers/${carrierId}/create`, metadata)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const checkPendingImport = (carrierId) => {
	return createRequest()
		.get(`api/import-trip/carriers/${carrierId}/check`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getPendingImport = (carrierId, params = {}) => {
	return createRequest()
		.get(`api/import-trip/carriers/${carrierId}/pending`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const discardPendingImport = (carrierId) => {
	return createRequest()
		.get(`api/import-trip/carriers/${carrierId}/discard`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateImportRecord = (carrierId, record) => {
	return createRequest()
		.put(`api/import-trip/carriers/${carrierId}/update`, record)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getUploadStatus = (carrierId, loadIds) => {
	return createRequest()
		.post(`api/import-trip/carriers/${carrierId}/status`, loadIds)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTripFiles = (carrierId, tripId, data) => {
	return createRequest()
		.put(`api/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripFiles = (carrierId, tripId) => {
	return createRequest()
		.get(`api/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendItinerary = (carrierId, tripId) => {
	return createRequest()
		.post(`api/booking/carriers/${carrierId}/trips/${tripId}/send-itinerary`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendBolNotification = (carrierId, tripId) => {
	return createRequest()
		.post(`api/booking/carriers/${carrierId}/trips/${tripId}/send-bol-notification`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendBrokerUpdate = (carrierId, tripId) => {
	return createRequest()
		.post(`api/booking/carriers/${carrierId}/trips/${tripId}/send-broker-update`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripCarrier = (tripId) => {
	return createRequest()
		.get(`api/booking/trips/${tripId}/get-carrier`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendFiles = (carrierId, tripId, data) => {
	return createRequest()
		.post(`api/booking/carriers/${carrierId}/trips/${tripId}/send-files`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const debugLocationExpire = async ({ type, carrierId, tripId }) => {
	return createRequest()
		.post(`api/booking/carriers/${carrierId}/trips/${tripId}/debug_location_expire`, { data: { type } })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
