import { useDispatch } from "react-redux";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

import Button from "@material-ui/core/Button";

function ActionButton(props) {
	const dispatch = useDispatch();
	return (
		<Button
			className={
				"mx-12 " +
				(props.action === "delete" ? "bg-red" : "") +
				" " +
				(props.classes?.button ?? "") +
				(props.variant === "section" ? " text-12 py-4 " : " text-12 py-6 ")
			}
			variant="contained"
			color="secondary"
			aria-label="Follow"
			disabled={props.disabled}
			onClick={() => {
				if (props.dialog) {
					dispatch(
						openLoadedFormDialog({
							viewId: props.dialog.viewId,
							formInstance: props.dialog.form,
							formId: props.dialog.formId,
							mode: props.dialog.mode,
							dataIds: props.dialog.dataIds ?? {},
						})
					);
				} else if (props.onClick) {
					props.onClick(props.action);
				}
			}}
		>
			{props.label}
		</Button>
	);
}

export default ActionButton;
