import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const SearchMobileSort = (props) => {
	const value = props.value;
	const onChange = props.onChange;
	const mobile = props.mobile;
	return (
		<FormControl variant="outlined" className={mobile ? "w-100 mx-7 px-10 my-14" : "w-100 pr-0"}>
			<InputLabel className={mobile ? "pl-10 ml-4" : ""} id="orderBy">
				Order By
			</InputLabel>
			<Select label="orderBy" id="orderBy" value={value} onChange={(event) => onChange(event.target.value)}>
				<MenuItem value="updated">{"Age"}</MenuItem>
				<MenuItem value="distance_to_origin">{"DH-O"}</MenuItem>
				<MenuItem value="rank_mci">{"MPI"}</MenuItem>
				<MenuItem value="profit_trip_carrier">{"Profit"}</MenuItem>
				<MenuItem value="price">{"Rate"}</MenuItem>
				<MenuItem value="rpm">{"RPM"}</MenuItem>
			</Select>
		</FormControl>
	);
};

export default SearchMobileSort;
