import Icon from "@material-ui/core/Icon";
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import Typography from "@material-ui/core/Typography";

const getStatusIcon = (data) => {
	if (!data) {
		return null;
	}

	let lastRefresh;
	let allRefreshLoadsFound = 0;
	let refreshesCount = 0;
	if (data.refreshHistory?.length > 0) {
		refreshesCount = data.refreshHistory.length;
		lastRefresh = data.refreshHistory[data.refreshHistory.length - 1];
		data.refreshHistory.forEach((item) => {
			allRefreshLoadsFound += item.countFound;
		});
	}

	const getIcon = (data, refresh) => {
		if (!data) {
			return null;
		}

		if (data?.status === "QUEUED") {
			return <Icon className="text-orange">{refresh ? "refresh" : "hourglass_top"}</Icon>;
		} else if (data?.status === "NOT_SUPPORTED") {
			return refresh ? null : "-";
		} else if (data?.status === "FINISHED") {
			return (
				<TooltipClickAwayView
					classes={{ tooltip: "drop-shadow-md " }}
					title={
						refresh
							? "Refreshed loads " + refreshesCount + " times, total loads added  " + allRefreshLoadsFound
							: "Started execution in " +
							  data.queueTime +
							  "ms, processed search in " +
							  data.processTime +
							  "ms, loads found " +
							  data.countFound +
							  (data.isGeneric ? ". TRIAL credential" : "")
					}
				>
					<div className="flex flex-row align-items-center">
						{refresh ? (
							<>
								<Icon className="text-green ml-1">refresh</Icon>
								<Typography color="inherit" variant="body2" className="py-4 px-2 font-light">
									{refreshesCount}
								</Typography>
							</>
						) : (
							<Icon className="text-green">check_circle</Icon>
						)}
						{data.isGeneric && !refresh && <Typography>Trial</Typography>}
					</div>
				</TooltipClickAwayView>
			);
		} else if (data?.status === "FAILED") {
			return (
				<TooltipClickAwayView classes={{ tooltip: "drop-shadow-md " }} title={data.error}>
					<div className="flex flex-row align-items-center">
						{refresh ? (
							<>
								<Icon className="text-red ml-1">refresh</Icon>
								<Typography color="inherit" variant="body2" className="py-4 px-2 font-light">
									{refreshesCount}
								</Typography>
							</>
						) : (
							<Icon className="text-red">error_outline</Icon>
						)}
						{data.isGeneric && <Typography>Trial</Typography>}
					</div>
				</TooltipClickAwayView>
			);
		}
		return (
			<>
				<Icon>{"help_outline"}</Icon>
				{data.isGeneric && !refresh && <Typography>Trial</Typography>};
			</>
		);
	};

	return (
		<div className="flex flex-row">
			{getIcon(data)}
			{getIcon(lastRefresh, true)}
		</div>
	);
};

const getOverallStatus = (data) => {
	const allBrokers = [
		data?.dat,
		data?.smarthop,
		data?.chrobinson,
		data?.uber,
		data?.coyote,
		data?.loadsmart,
		data?.parade,
		data?.newtrul,
		data?.echo,
	];

	let queuedJob = allBrokers.filter((item) => item?.status === "QUEUED");
	let finishedJob = allBrokers.filter((item) => item?.status === "FINISHED");
	let failedJob = allBrokers.filter((item) => item?.status === "FAILED");

	let finishedRefreshJobs = [];
	let failedRefreshJobs = [];

	finishedJob.forEach((broker) => {
		if (broker.refreshHistory?.length > 0) {
			finishedRefreshJobs.push(...broker.refreshHistory.filter((item) => item?.status === "FINISHED"));
			failedRefreshJobs.push(...broker.refreshHistory.filter((item) => item?.status === "FAILED"));
		}
	});

	return (
		<div className="flex flex-row">
			{queuedJob.length > 0 && (
				<>
					<Icon className="text-orange">hourglass_top</Icon>
					<Typography color="inherit" variant="body2" className="p-4 font-light mr-8">
						{queuedJob.length}
					</Typography>
				</>
			)}
			{finishedJob.length > 0 && (
				<>
					<Icon className="text-green">check_circle</Icon>
					<Typography color="inherit" variant="body2" className="p-4 font-light">
						{finishedJob.length}
					</Typography>
				</>
			)}
			{failedJob.length > 0 && (
				<>
					<Icon className="text-red ml-1">error_outline</Icon>
					<Typography color="inherit" variant="body2" className="p-4 font-light">
						{failedJob.length}
					</Typography>
				</>
			)}
			{finishedRefreshJobs.length > 0 && (
				<>
					<Icon className="text-green ml-1">refresh</Icon>
					<Typography color="inherit" variant="body2" className="p-4 font-light">
						{finishedRefreshJobs.length}
					</Typography>
				</>
			)}
			{failedRefreshJobs.length > 0 && (
				<>
					<Icon className="text-red ml-1">refresh</Icon>
					<Typography color="inherit" variant="body2" className="p-4 font-light">
						{failedRefreshJobs.length}
					</Typography>
				</>
			)}
		</div>
	);
};

const getBooked = (data) => {
	if (!data) {
		return "-";
	} else {
		return (
			<TooltipClickAwayView
				classes={{ tooltip: "drop-shadow-md " }}
				title={
					"Load: " +
					data.load_id +
					", broker: " +
					data.broker +
					", origin: " +
					data.origin +
					", destination: " +
					data.destination +
					", created by: " +
					data.created_by +
					", rate: " +
					data.rate +
					", carrierid: " +
					data.carrier_id
				}
			>
				<Icon>bookmark</Icon>
			</TooltipClickAwayView>
		);
	}
};

const carriers = {
	urlGET: "api/search/requests",
	idKey: "_id",
	listenEvent: "searchRevision",
	segmentKey: "ADMIN_SEARCHES",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["pickup_after_ds", "search_job_expire_at", "createdAt", "location_origin"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "user",
					type: "autocomplete",
					label: "User",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
					},
				},
				{
					key: "bookedLoad",
					type: "select",
					label: "Booked Load",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__NOT_EMPTY__OBJECT__",
							label: "Has Booked Load",
						},
						{
							value: "__EMPTY__OBJECT__",
							label: "No Has Booked Load",
						},
					],
				},
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					field: {
						noErrorMessage: true,
						classes: {},
					},
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						preloadDefault: true,
						listenEvent: "profileRevision",
						params: {
							options_active_only: true,
						},
					},
				},
				{
					key: "truck",
					type: "autocomplete",
					label: "Truck",
					translate: "",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/trucks",
						params: {
							options_active_only: true,
							options_format_show_driver_phone: true,
						},
						preloadDefault: true,
						preloadImmedately: true,
						listenEvent: "profileRevision",
					},
					rules: {
						params: {
							valueRef: "carrier",
							paramKey: "options_carrier_id_search",
						},
					},
				},
				{
					key: "createdAt",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Last 3 Days",
						},
						{
							value: "__DATE_DAYS_GT__30",
							label: "Last 30 Days",
						},
						{
							value: "__DATE_DAYS_GT__90",
							label: "Last 90 Days",
						},
						{
							value: "__DATE_DAYS_GT__365",
							label: "Last 1 Year",
						},
						{
							value: "__DATE_DAYS_GT__3650",
							label: "Last 10 Years",
						},
					],
				},
				{
					key: "expirationReason",
					type: "select",
					label: "Expiration Override",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__NOT_EMPTY__",
							label: "Expidation Overridden",
						},
					],
				},
				{
					key: "refreshAllowed",
					type: "select",
					label: "Hot Reload",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "true",
							label: "Enabled",
						},
					],
				},
				{
					key: "platform",
					type: "select",
					label: "Platform",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "WEB_MOBILE",
							label: "Web Mobile",
						},
						{
							value: "WEB_NATIVE",
							label: "Web Native",
						},
						{
							value: "WEB_DESKTOP",
							label: "Web Desktop",
						},
					],
				},
				{
					key: "type",
					type: "select",
					label: "Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						// ATTENTION: Intentialonally swapped labeles, there is bug in types
						{
							value: "EXTERNAL",
							label: "Internal",
						},
						{
							value: "INTERNAL",
							label: "External",
						},
					],
				},
				{
					key: "trigger",
					type: "select",
					label: "Trigger",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "MARKET_MONITOR",
							label: "Market Monitor",
						},
						{
							value: "MARKET_SCAN",
							label: "Market Scan",
						},
						{
							value: "MARKET_WIDGET",
							label: "Market Widget",
						},
						{
							value: "STRATEGY_VALIDATOR",
							label: "Strategy Validator",
						},
						{
							value: "USER",
							label: "User",
						},
					],
				},
				{
					key: "recommendationSent",
					type: "select",
					label: "Recommendation",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__TRUE__",
							label: "Sent",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Data",
				},
				{
					key: "broker_details",
					type: "checkbox",
					label: "Show Broker Search Status",
				},
				{
					key: "broker_metadata_dynamic_status",
					type: "checkbox",
					label: "Show Search Dynamic Status",
				},
				{
					key: "search_analytics",
					type: "checkbox",
					label: "Show Result Analytics",
				},
				{
					key: "broker_metadata_dat",
					type: "checkbox",
					label: "Show Broker DAT Metadata",
				},
			],
		},
		items: [
			{
				key: "_id",
				type: "id",
				label: "ID",
				group: "internal",
			},
			{
				key: "ip",
				type: "text",
				label: "IP",
				group: "internal",
			},
			{
				key: "ip",
				type: "text",
				label: "Priority",
				group: "internal",
				builder: (item) => (!!item.lowPriority ? "Low" : "Default"),
			},
			{
				key: "createdAt",
				type: "group",
				label: "Created",
				items: [
					{ key: "createdAt", type: "date", label: "Created" },
					{
						key: "refreshAllowed",
						type: "component",
						builder: (item) =>
							item.refreshAllowed ? (
								<Typography
									color="inherit"
									variant="body2"
									className="px-5 mt-2 text-white text-12 font-medium bg-green rounded-8"
								>
									Hot Reload
								</Typography>
							) : (
								<></>
							),
					},
				],
			},
			{ key: "platform", type: "text", label: "Platform" },
			{
				key: "expiresAt",
				type: "group",
				label: "Expiration",
				items: [
					{ key: "expiresAt", type: "date", label: "Expiration" },
					{
						key: "expirationReason",
						type: "component",
						builder: (item) =>
							item.expirationReason ? (
								<Typography
									color="inherit"
									variant="body2"
									className="px-5 mt-2 text-white text-12 font-medium bg-red rounded-8"
								>
									{item.expirationReason === "PREV_HAS_FAILURES"
										? "Override: Failures"
										: item.expirationReason === "USER_OFFLINE"
										? "Override:  Offline"
										: item.expirationReason}
								</Typography>
							) : (
								<></>
							),
					},
				],
			},
			{
				key: "user__view",
				type: "user__view",
				label: "User",
				viewConfig: {
					showRole: true,
				},
			},
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showTeam: true,
					showStatus: true,
				},
			},
			{ key: "origin", type: "text", label: "Origin", maxLengthStart: 10 },
			{
				key: "destination",
				type: "text",
				label: "Destination",
				maxLengthStart: 10,
				builder: (item) => item.destination ?? item.destinationStates,
			},
			{ key: "availStart", type: "date", label: "Available" },
			{
				label: "Status",
				type: "component",
				builder: (item) => {
					return getOverallStatus(item);
				},
			},
			{ label: "Booked", type: "component", builder: (item) => getBooked(item?.bookedLoad), group: "broker_details" },
			{
				key: "trigger",
				type: "text",
				label: "Trigger",
				group: "internal",
			},
			{
				key: "recommendationSent",
				type: "text",
				label: "Recommendation",
				builder: (item) => (item?.recommendationSent ? "Sent" : "-"),
				group: "internal",
			},
			{
				key: "analytics",
				type: "text",
				label: "Analytics",
				builder: (item) => JSON.stringify(item.analytics, null, 6),
				collapsibleLength: 40,
				group: "search_analytics",
			},
			{
				key: "dynamicStatus",
				type: "text",
				label: "Dynamic Status",
				builder: (item) => JSON.stringify(item.dynamicStatus, null, 6),
				collapsibleLength: 40,
				group: "broker_metadata_dynamic_status",
			},
			{ label: "DAT", type: "component", builder: (item) => getStatusIcon(item?.dat), group: "broker_details" },
			{
				key: "dat_metadata",
				type: "text",
				label: "DAT Metadata",
				builder: (item) => JSON.stringify(item.dat, null, 6),
				collapsibleLength: 40,
				group: "broker_metadata_dat",
			},
			{ label: "SMHP", type: "component", builder: (item) => getStatusIcon(item?.smarthop), group: "broker_details" },
			{ label: "CHRN", type: "component", builder: (item) => getStatusIcon(item?.chrobinson), group: "broker_details" },
			{ label: "UBER", type: "component", builder: (item) => getStatusIcon(item?.uber), group: "broker_details" },
			{ label: "CYTE", type: "component", builder: (item) => getStatusIcon(item?.coyote), group: "broker_details" },
			{ label: "LDSM", type: "component", builder: (item) => getStatusIcon(item?.loadsmart), group: "broker_details" },
			{ label: "PRDE", type: "component", builder: (item) => getStatusIcon(item?.parade), group: "broker_details" },
			{ label: "NWTL", type: "component", builder: (item) => getStatusIcon(item?.newtrul), group: "broker_details" },
			{ label: "ECHO", type: "component", builder: (item) => getStatusIcon(item?.echo), group: "broker_details" },
			{
				key: "criticalError",
				type: "text",
				label: "Critical",
				collapsibleLength: 40,
				group: "internal",
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "search_results",
						label: "View Results",
						newTab: true,
						redirectURLBuilder: (item) => "/admin/search/requests/" + item._id + "/details/user/" + item.user,
					},
				],
			},
		],
	},
};

export default carriers;
