import { Typography } from "@material-ui/core";

const SmarthopDialogViewLoadingStub = (props) => {
	if (props.error) {
		return (
			<div
				className={
					"flex w-full h-512 flex-col items-center justify-center " +
					(props.nativeMobile ? " h-full pb-44 " : " h-512 ")
				}
			>
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48 text-red">
					{props.error}
				</Typography>
			</div>
		);
	}

	if (props.loading) {
		return (
			<div
				className={
					"flex w-full h-512 flex-col items-center justify-center " +
					(props.nativeMobile ? " h-full pb-44 " : " h-512 ")
				}
			>
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48 text-grey">
					Loading...
				</Typography>
			</div>
		);
	}

	return props.children;
};

export default SmarthopDialogViewLoadingStub;
