// Dependencies
import { useEffect, forwardRef } from "react";
import { Icon, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

// Services
import { getCarrierId } from "app/services/LoginService";

// Tools and Utils
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { DISTRIBUTION_PRIORITY } from "app/main/consts";

const PAYMENT_TYPES = {
	PAY_BY_PERCENTAGE: "Gross Income (%)",
	PAY_BY_PROFIT: "Profit (%)",
	PAY_BY_MILES: "Pay By Miles",
	FLAT_FEE: "Flat Fee",
};

const MINIMUM_PAYMENT_TYPES = {
	PERIOD: "Per Pay Period",
	TRIP: "Per Trip",
};
/**
 * PayeeSection Rendering Component
 * @param {*} params
 * @returns
 */
export const PayeeSection = ({
	data: { title, userId, carrierId, settings, formId, payeeType, driverId, isCarrier },
	hidden,
}) => {
	const dispatch = useDispatch();

	if (hidden) {
		return null;
	}

	return (
		<div className="flex w-full flex-col md:flex-row">
			<DisplaySection
				classes={{ root: "md:w-full" }}
				title={
					<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
						<div>Payee Summary</div>
					</div>
				}
			>
				<div className="-mt-10">
					{isCarrier && (
						<div className={"flex flex-row border-b-1 border-grey-300"}>
							<Typography className={`flex w-full text-black text-12 ml:text-13 break-normal mt-10`}>Payee</Typography>
							<Typography
								className={
									"cursor-pointer text-12 ml:text-13  break-normal  " +
									` mt-10 font-semibold text-blue-700 ${
										title?.length > 45 ? " whitespace-break-spaces " : " whitespace-nowrap "
									}  text-blue-700 `
								}
								style={{ textAlign: "end", padding: 0 }}
								onClick={() => {
									dispatch(
										openLoadedFormDialog({
											viewId: formId,
											dataIds: {
												carrierId,
												...(payeeType === "DRIVER" ? { driverId } : {}),
												...(payeeType === "USER" ? { userId } : {}),
											},
										})
									);
								}}
							>
								{title ?? "No Information"}
								{title && <Icon className="text-15 ml-4 mt-2">info</Icon>}
							</Typography>
						</div>
					)}
					{!isCarrier && renderDataItem("Payee", title, "", {})}
					{payeeType === "DRIVER" && (
						<>
							{renderDataItem("Payment Type", PAYMENT_TYPES[settings?.payment_type], "", {})}

							{settings?.payment_type === "PAY_BY_PERCENTAGE" &&
								renderDataItem("Gross", settings?.gross && settings?.gross + "%", "", {})}

							{settings?.payment_type === "PAY_BY_PROFIT" &&
								renderDataItem("Percentage", settings?.profit_pct && settings?.profit_pct + "%", "", {})}

							{settings?.payment_type === "PAY_BY_MILES" && (
								<>
									{renderDataItem("Empty Miles Payment", formatCurrency(settings?.empty_miles_payment), "", {})}
									{renderDataItem("Loaded Miles Payment", formatCurrency(settings?.loaded_miles_payment), "", {})}
								</>
							)}

							{settings?.payment_type === "FLAT_FEE" &&
								renderDataItem("Amount", formatCurrency(settings?.flat_fee_amount), "", {})}

							{renderDataItem("Minimum Payment Type", MINIMUM_PAYMENT_TYPES[settings?.minimum_payment_type], "", {})}
							{renderDataItem("Minimum Payment", formatCurrency(settings?.minimum_payment), "", {})}
							{renderDataItem("Extra Drop Payment", formatCurrency(settings?.extra_drops), "", {})}
						</>
					)}

					{payeeType === "USER" && settings.distribution_priority && (
						<>
							{renderDataItem(
								"Distribution priority",
								settings?.distribution_priority
									? `${DISTRIBUTION_PRIORITY[settings?.distribution_priority]} - ${
											settings?.distribution_priority_value
									  } %`
									: "-",
								"",
								{}
							)}
						</>
					)}
				</div>
			</DisplaySection>
		</div>
	);
};

const mapStateToStatus = (state) => {
	switch (state) {
		case "open":
			return "In Progress";
		case "closed":
			return "In Review";
		case "approved":
			return "Approved";
		default:
			return "No information";
	}
};

const EnterprisePayrollSummaryView = forwardRef(({ dataIds, setTitle, payrollDetails, userDetails }, _) => {
	const payeeType = dataIds?.payeeType;
	const isCarrier = dataIds?.isCarrier;
	const carrierId = dataIds?.carrierId ?? getCarrierId();
	const driverId = dataIds?.driverId;
	const userId = dataIds?.userId;

	useEffect(() => {
		setTitle?.("Payroll");
		// eslint-disable-next-line
	}, []);

	// Revisions

	return (
		<div className={"flex flex-col h-full w-full overflow-x-hidden"}>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<DisplaySection
					classes={{ root: "md:w-full" }}
					title={
						<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
							<div>Payroll Details</div>
						</div>
					}
				>
					<div className="-mt-10">
						{renderDataItem("Payroll #", payrollDetails?.carrier_ref, "", {})}
						{renderDataItem("Status", mapStateToStatus(payrollDetails?.state), "", {})}
						{renderDataItem(
							"Period",
							payrollDetails?.period?.period_start_date && payrollDetails?.period?.period_end_date
								? `${payrollDetails?.period?.period_start_date} - ${payrollDetails?.period?.period_end_date}`
								: "-",
							"",
							{}
						)}
						{renderDataItem("Count of trip", payrollDetails?.trips__view?.length, "", {})}
						{renderDataItem("Total Payroll", formatCurrency(payrollDetails?.grandTotal), "", {})}
					</div>
				</DisplaySection>
			</div>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<PayeeSection
					data={{
						title: userDetails?.user?.first_name + " " + userDetails?.user?.last_name,
						settings: userDetails?.payroll?.settings,
						userId,
						driverId,
						carrierId,
						formId: "USER_DETAILS_VIEW",
						payeeType,
						isCarrier,
					}}
				/>
			</div>
		</div>
	);
});

export default EnterprisePayrollSummaryView;
