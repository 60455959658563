const userDetailsForm = {
	urlGET: "api/profile/users/:userId/details",
	urlPUT: "api/profile/users/:userId/details",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit User Information" },
		view: { label: "View User Information" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "first_name",
								type: "text",
								label: "First Name",
								translate: "FIRST_NAME",
								required: true,
								field: {
									maxLength: 32,
								},
							},
							{
								key: "last_name",
								type: "text",
								label: "Last Name",
								translate: "LAST_NAME",
								required: true,
								field: {
									maxLength: 32,
								},
							},
						],
					},
				},
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "Are you sure to edit your user information ?",
					},
				},
			],
		},
	},
};

export default userDetailsForm;
