const adminReleasesList = {
	urlGET: "api/releases",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "adminReleasesList",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["version"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "version",
				dir: "desc",
			},
		},
		filters: {},
		items: [
			{ key: "version", type: "text", label: "Version" },
			{ key: "status", type: "text", label: "Status" },
			{
				key: "notes",
				type: "text",
				label: "Release Notes",
				collapsibleLength: 40,
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "delete",
						label: "Delete",
						translate: "",
						dialogBuilder: (item) => ({
							formId: "ADMIN_RELEASES",
							mode: "DELETE",
							dataIds: { releaseId: item._id },
						}),
					},
					{
						key: "edit",
						label: "Edit",
						translate: "",
						dialogBuilder: (item) => ({
							viewId: "ADMIN_RELEASES",
							mode: "EDIT",
							dataIds: { releaseId: item._id },
						}),
					},
				],
			},
		],
	},
};

export default adminReleasesList;
