import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function TimelineCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const label = props?.view?.label;

	const unanswered = props?.view?.metadata?.unanswered;
	const unanswered_requested = props?.view?.metadata?.unanswered_requested;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellSecondRow mobile={mobile} label={label} variant={variant} />}
			second={
				<>
					<CellSecondRow
						mobile={mobile}
						label={unanswered + ""}
						icon={"mark_chat_unread"}
						variant={variant}
						color={"text-red-600"}
					/>
					<CellSecondRow
						mobile={mobile}
						label={unanswered_requested + ""}
						icon={"chat"}
						variant={variant}
						color={"text-orange-800 pr-5"}
					/>
				</>
			}
		/>
	);
}

export default TimelineCellView;
