// Utils
import { SMARTHOP_WALLET_SECTION_LABEL } from "app/main/utils/financeUtils";
import { formatDate } from "app/main/utils/dateUtils";

// Services
// import { getRoleLabel } from "app/services/LoginService";

// Consts
const pendingReviewCustomMessage = `Your application is being reviewed. Your ${SMARTHOP_WALLET_SECTION_LABEL} account will appear here when approved.`;

const smartpayApplicationForm = ({ isAdmin }) => ({
	urlGET: `api/wallet/${isAdmin ? "admin/" : ""}application/carrier/:entityId`,
	triggerEvent: "smarthopWalletRevision",
	listenEvent: "smarthopWalletRevision",
	idKey: "_id",
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "application",
								type: "text",
								label: "Status",
								builder: (item) => item?.status,
							},
							{
								key: "application_continue",
								type: "text",
								builder: (item) => {
									if (item?.data?.applications[0].data.attributes.status === "Denied") {
										return item?.data?.applications[0].data.attributes.message;
									}
									return pendingReviewCustomMessage;
								},
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "application_date",
								type: "text",
								label: "Last updated",
								builder: (item) => formatDate(item?.data?.applications[0].data.attributes.updatedAt),
							},
							{
								key: "application_form",
								type: "text",
								label: "Stage",
								builder: (item) => item?.data?.applications[0].data.attributes.status,
							},
						],
					},
				},
			],
		},
	},
});

export default smartpayApplicationForm;
