const locale = {
	Login: "Inicio de sesión",
	Register: "Registro",
	OR: "O",
	"Don't have an account?": "¿No tienes una cuenta?",
	"Already have an account?": "¿Ya tienes una cuenta?",
	"Sign Up to Start Using SmartHop's Platform": "Regístrese para comenzar a usar la plataforma de SmartHop",
	"Hi! Welcome to SmartHop": "¡Hola! Bienvenido a SmartHop",
	"the all-in-one booking and fleet management platform": "la plataforma de reserva y gestión de flotas todo en uno",
	"Forgot your password?": "¿Olvidaste tu contraseña?",
	"Change Password": "Cambiar Contraseña",
	"The password was change successfully": "La contraseña fue cambiada exitosamente",
	"Go to Login": "Ir a inicio de sesión",
	"Log in with": "Iniciar sesión",
};

export default locale;
