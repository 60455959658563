import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { createMPIView } from "app/main/utils/brokerUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { selectByType } from "app/store/marketConditions/mapSlice";
import { useState, useEffect, forwardRef, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useMediaQuery, useTheme } from "@material-ui/core";

const MoveCard = forwardRef(({ data, selectedMoves, setSelectedMoves, allMoves, ...props }, ref) => {
	const {
		title,
		isOrigin,
		avg_volume,
		empty,
		cumulativeProfit,
		profit,
		equipment,
		dcluster,
		end_date,
		start_date,
		ocluster,
		first,
	} = data;
	const maxStrokeWidth = 12;
	const findCurrentMove = useCallback((m) => m?.data?.id === data?.id, [data]);
	const [checked, setChecked] = useState(!!selectedMoves?.find(findCurrentMove));

	const theme = useTheme();
	const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

	const mapData = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, equipment.toLowerCase(), "ocluster", "toplanes")
	);

	const clusterMove = !isOrigin ? dcluster : ocluster;
	const ppm_best_norm_smooth = mapData?.heatMap?.[clusterMove]?.ppm_best_norm_smooth;
	const move_date = !isOrigin ? moment(end_date).format("MMM DD") : moment(start_date).format("MMM DD");

	useEffect(() => {
		setChecked(!!selectedMoves?.find(findCurrentMove));
	}, [selectedMoves, findCurrentMove]);

	const handleClick = (e) => {
		e.stopPropagation();
		const selectedMoveIndex = selectedMoves?.findIndex(findCurrentMove);
		if (selectedMoveIndex === 1) return;
		setChecked((c) => !c);
		if (selectedMoveIndex !== -1) {
			setSelectedMoves((moves) => moves?.slice(0, selectedMoveIndex));
		} else {
			const moveIndex = allMoves?.findIndex(findCurrentMove);
			setSelectedMoves(allMoves?.slice(0, moveIndex + 1));
		}
	};

	return !isOrigin ? (
		<Box
			sx={{
				minWidth: 100,
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={handleClick}
		>
			<Card
				variant="outlined"
				style={{
					transition: "filter 500ms linear",
					height: "90%",
					boxSizing: "border-box",
					borderBottomWidth: empty
						? "3px"
						: avg_volume && !isNaN(avg_volume)
						? `${Math.round(avg_volume > maxStrokeWidth ? maxStrokeWidth : avg_volume)}px`
						: "4px",
					borderBottomColor: empty ? "red" : "green",
					borderBottomStyle: empty ? "dotted" : "solid",
					filter: !checked ? "grayscale(1)" : "none",
				}}
			>
				<CardContent>
					<Box style={{ display: "flex", alignItems: "center" }}>
						<Typography variant={largeScreen ? "h6" : "body1"} component="div">
							{title}
						</Typography>
					</Box>
					<Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "10px" }}>
						<Typography>{move_date}</Typography>
						{createMPIView({ rank_mci: ppm_best_norm_smooth }, false)}
					</Box>

					<Box style={{ marginTop: "0.5rem" }}>
						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Box style={{ display: "flex", flexDirection: "column" }} marginTop={2} marginBottom={2}>
								<Typography align="right"> Profit </Typography>
								<Typography
									style={{ color: profit <= 0 ? "red" : "green", fontSize: "1.85em", marginTop: "-5px" }}
									align="right"
								>
									{formatCurrency(profit || 0, 0)}
								</Typography>
							</Box>
							<Checkbox
								style={{
									transform: "scale(1)",
									padding: 0,
									marginBottom: "30px",
								}}
								className={first ? "hidden" : ""}
								checked={checked}
								onClick={handleClick}
							/>
						</Box>
						{!isOrigin ? (
							<Box>
								{largeScreen ? (
									<Typography style={{ fontSize: "1em" }} align="center">
										Running total&nbsp;
										<span style={{ color: cumulativeProfit <= 0 ? "red" : "green", fontSize: "1.1em" }}>
											{formatCurrency(cumulativeProfit || 0, 0)}
										</span>
									</Typography>
								) : null}
							</Box>
						) : (
							<Box>
								<Typography style={{ fontSize: "1em" }} align="center">
									Start location&nbsp;
								</Typography>
							</Box>
						)}
					</Box>
				</CardContent>
			</Card>
		</Box>
	) : (
		<Box
			sx={{
				minWidth: 100,
			}}
			ref={ref}
		>
			<Card
				variant="outlined"
				style={{
					transition: "filter 200ms linear",
					height: "90%",
					boxSizing: "border-box",
				}}
			>
				<CardContent style={{ paddingBottom: 0 }}>
					<Box style={{ display: "flex", alignItems: "center" }}>
						<Typography variant={largeScreen ? "h6" : "body1"} component="div">
							{title}
						</Typography>
					</Box>
					<Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "10px" }}>
						<Typography>{move_date}</Typography>
						{createMPIView({ rank_mci: ppm_best_norm_smooth }, false)}
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
});

export default MoveCard;
