import { CircularProgress, FormControl, InputLabel, Link, MenuItem, Select } from "@material-ui/core";
import { getRelease } from "app/services/releaseServices";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { useImperativeHandle } from "react";
import { useState, useEffect, forwardRef } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import moment from "moment";

const ReleaseList = forwardRef(({ setTitle, setSize, dataIds, onDone }, ref) => {
	useEffect(() => {
		setTitle?.("Releases");
		setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const [viewingRelease, setViewingRelease] = useState(false);
	const [releaseList, setReleaseList] = useState(false);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		const latestRelease = JSON.parse(localStorage.getItem("latestRelease"));
		const releaseList = JSON.parse(localStorage.getItem("releaseList"));
		setReleaseList(releaseList);
		setViewingRelease(latestRelease);
	}, []);

	const viewRelease = async (releaseId) => {
		const release = releaseList.find((r) => r._id === releaseId);
		setLoading(true);
		setViewingRelease(release);
		const fullReleaseRecord = await getRelease(release._id);
		setViewingRelease(fullReleaseRecord);
		setLoading(false);
	};

	useImperativeHandle(
		ref,
		() => {
			const btns = {};
			btns.version = {
				onClick: () =>
					dispatch(
						openLoadedFormDialog({
							formId: "VERSION_FORM",
							mode: "VIEW",
							dataIds,
						})
					),
				icon: "info",
				title: "Build Information",
			};
			return btns;
		},
		[dataIds, dispatch]
	);

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-col w-full">
				<div className="flex flex-row w-full items-center border-b-1 pb-16 mb-16">
					<div className="flex flex-row w-full mt-8">
						{viewingRelease._id && (
							<FormControl className="mb-12 w-full" fullWidth variant="outlined">
								<InputLabel id="test-select-label">Release Notes Version</InputLabel>
								<Select
									label="Release Notes Version"
									value={viewingRelease._id}
									variant="outlined"
									labelId="test-select-label"
									className="w-full"
									onChange={(e) => viewRelease(e.target.value)}
								>
									{releaseList?.map?.((r) => {
										return (
											<MenuItem className="block" value={r._id}>
												<div className="mb-2">V{r.version}</div>
												<div className="text-gray-600">{moment(r.createdAt).format("MMM Do, YYYY")}</div>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)}
					</div>
				</div>
				<div className="m-16 min-h-full" style={{ height: "70vh" }}>
					<div className="flex flex-col h-full w-full items-center">
						<ul className="list-disc">
							{loading ? (
								<CircularProgress />
							) : !viewingRelease?.notes ? (
								""
							) : (
								<div className="flex flex-col">
									<ReactMarkdown
										components={{
											a: (props) => {
												const { href, children, key } = props;

												const handleClick = (link) => {
													const reactNative = window?.ReactNativeWebView ?? false;
													if (reactNative) {
														window?.ReactNativeWebView?.postMessage(
															JSON.stringify({ type: "EXTERNAL_LINK", url: link })
														);
													} else {
														window.open(link);
													}
												};

												return (
													<Link key={key} className="cursor-pointer" onClick={() => handleClick(href)}>
														{children}
													</Link>
												);
											},
										}}
									>
										{viewingRelease?.notes}
									</ReactMarkdown>
								</div>
							)}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
});

export default ReleaseList;
