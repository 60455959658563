const TRIP_JOURNAL_ENTRY_CATEGORIES = [
	{
		value: "TRIP_RATE",
		label: "Trip",
	},
	{
		value: "TRIP_ADDITION_DEDUCTION",
		label: "Trip Addition/Deduction",
	},
	{
		value: "TRIP_EXTRA_DROP",
		label: "Trip Extra Drop",
	},
];

const DRIVER_JOURNAL_ENTRY_CATEGORIES = [
	{
		value: "__NOT_SELECTED__",
		label: "Not Selected",
	},
	...TRIP_JOURNAL_ENTRY_CATEGORIES,
	{
		value: "REPAYMENT",
		label: "Recurring",
	},
	{
		value: "MANUAL",
		label: "Manual",
	},
	{
		value: "FUEL",
		label: "Fuel",
	},
];

const PENDING = "PENDING";
const IN_REVIEW = "IN_REVIEW";
const APPROVED = "APPROVED";

const PAYROLL_STATUSES = {
	open: "In Progress",
	closed: "In Review",
	approved: "Approved",
};

const DRIVER_JOURNAL_ENTRY_STATUSES = {
	[PENDING]: "In Progress",
	[IN_REVIEW]: "In Review",
	[APPROVED]: "Approved",
};

const DRIVER_JOURNAL_ENTRY_STATUSES_OPTIONS = [
	{
		value: "__NOT_SELECTED__",
		label: "Not Selected",
	},
	{
		value: PENDING,
		label: DRIVER_JOURNAL_ENTRY_STATUSES[PENDING],
	},
	{
		value: IN_REVIEW,
		label: DRIVER_JOURNAL_ENTRY_STATUSES[IN_REVIEW],
	},
	{
		value: APPROVED,
		label: DRIVER_JOURNAL_ENTRY_STATUSES[APPROVED],
	},
];

export {
	TRIP_JOURNAL_ENTRY_CATEGORIES,
	DRIVER_JOURNAL_ENTRY_CATEGORIES,
	DRIVER_JOURNAL_ENTRY_STATUSES_OPTIONS,
	DRIVER_JOURNAL_ENTRY_STATUSES,
	PAYROLL_STATUSES,
};
