import React from "react";
import { createTooltip } from "app/main/utils/tableUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const successStyle = {
	color: "green-400",
	hoverColor: "green-500",
	icon: "check_circle",
};
const inprogressStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "hourglass_top",
};
const errorStyle = {
	color: "red-700",
	hoverColor: "red-800",
	icon: "highlight_off",
};
const progressStyle = {
	color: "#333",
	position: "absolute",
	top: -6,
	left: -6,
	zIndex: 1,
};
const declineStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "check_circle",
};
const progressBoxStyle = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const createAlertView = ({ status, statusProcess, state, lastMessage }) => {
	let style;
	status = statusProcess || status;
	if (status === "FAILED") {
		style = errorStyle;
	} else if (status === "IN_PROGRESS" || status === "POSTPONED" || status === "RETRYING") {
		style = inprogressStyle;
	} else if (status === "FINISHED" || status === "FINISH") {
		if (state === "POST_TRUCK_DECLINED") {
			style = declineStyle;
		} else {
			style = successStyle;
		}
	} else {
		return status;
	}

	const viewIcon = (statusView) => {
		if (statusView === "IN_PROGRESS" || statusView === "POSTPONED") {
			return (
				<Box sx={{ position: "relative", display: "inline-flex" }}>
					<CircularProgress size={30} style={{ color: "#06060633" }} sx={progressStyle} />
					<Box sx={progressBoxStyle}>
						<Icon className={`text-16 text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>
					</Box>
				</Box>
			);
		} else {
			return <Icon className={`text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>;
		}
	};

	return createTooltip(
		viewIcon(status),
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{lastMessage?.split(" ---------------- ")[0]}
		</Typography>,
		`bg-${style.hoverColor}`
	);
};

const adminPostTruckList = () => {
	return {
		urlGET: "api/transaction/users/:userId/records/POST_TRUCK",
		idKey: "_id",
		triggerEvent: "transactionsRevision",
		listenEvent: "transactionsRevision",
		segmentKey: "ADMIN_POST_TRUCK",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: [
					"createdAt",
					"transaction__view.metadata.slower_step.stepTime",
					"transaction__view.metadata.total_time",
				],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "createdAt",
					dir: "desc",
				},
			},
			filters: {
				search: true,
				items: [
					{
						// TODO should be dynamic as in dialog to transition from one state to another
						key: "state",
						type: "select",
						label: "State",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{ label: "Not Selected", value: "__NOT_SELECTED__" },
							{ label: "Transaction Started", value: "STARTED" },
							{ label: "Post Truck", value: "POST_TRUCK" },
							{ label: "Unpost Truck", value: "UNPOST_TRUCK" },
							{ label: "Done", value: "DONE" },
						],
					},
					{
						key: "status",
						type: "select",
						label: "Status",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{ label: "Not Selected", value: "__NOT_SELECTED__" },
							{ label: "Finished", value: "FINISHED" },
							{ label: "In Progress", value: "IN_PROGRESS" },
							{ label: "Postponed", value: "POSTPONED" },
							{ label: "Failed", value: "FAILED" },
						],
					},
					{
						key: "content.owner",
						type: "select",
						label: "Broker",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{ label: "Not Selected", value: "__NOT_SELECTED__" },
							{ label: "Parade", value: "parade" },
							{ label: "Dat", value: "dat" },
						],
					},
				],
			},
			groups: {
				items: [
					{
						key: "internal",
						type: "checkbox",
						label: "Show Internal Fields",
					},
				],
			},
			isHighlighted: (item) => item?.status === "POSTPONED" || item?.status === "FAILED",
			items: [
				{ key: "_id", type: "id", label: "ID" },
				{ key: "user", type: "id", label: "User ID" },
				{ key: "userEmail", type: "text", label: "User Email" },
				{ key: "createdAt", type: "date", label: "Created" },
				{ key: "type", type: "text", label: "Type" },
				{
					key: "state",
					type: "text",
					label: "State",
					builder: (item) => {
						return item.state || item.status;
					},
				},
				{
					key: "transaction__view.metadata.slower_step.stepTime",
					label: "Slower Status",
					type: "component",
					group: "internal",
					builder: (item) => {
						return (
							<div className="flex flex-col">
								<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
									{item.transaction__view?.metadata?.slower_step?.state
										? item.transaction__view?.metadata?.slower_step?.state.toUpperCase()
										: "-"}
								</Typography>
								<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
									{item.transaction__view?.metadata?.slower_step?.stepTime
										? (item.transaction__view?.metadata?.slower_step?.stepTime / 1000).toFixed(0) + "s"
										: "-"}
								</Typography>
							</div>
						);
					},
				},
				{
					key: "transaction__view.metadata.total_time",
					label: "Total Time",
					type: "component",
					group: "internal",
					builder: (item) => {
						return (
							<div className="flex flex-col">
								<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
									{item.transaction__view?.metadata?.total_time
										? (item.transaction__view?.metadata?.total_time / 1000).toFixed(0) + "s"
										: "-"}
								</Typography>
							</div>
						);
					},
				},
				{ key: "error", label: "Status", type: "component", builder: (item) => createAlertView(item) },
				{ key: "content.owner", type: "text", label: "Broker" },
				{ key: "content.carrierid", type: "id", label: "Carrier ID" },
				{ key: "content.truckid", type: "text", label: "Truck ID" },
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "transition_state",
							label: "Transition to a state",
							auth: ["administrator", "ops support"],
							dialogBuilder: (item, dataIds) => ({
								formId: item.type === "POST_TRUCK" ? "TRANSACTIONS_POST_TRUCK_FORM" : "TRANSACTIONS_UNPOST_TRUCK_FORM",
								mode: "EDIT",
								dataIds: { id: item._id, userId: dataIds.userId, transactionType: item.type },
							}),
						},
						{
							key: "execute_logs",
							label: "Logs",
							dialogBuilder: (item, dataIds) => ({
								mode: "view",
								viewId: "TRANSACTIONS_EXECUTE_LOGS",
								dataIds: { id: item._id, userId: dataIds.userId },
							}),
						},
						{
							key: "check_status_logs",
							label: "Retry Process",
							dialogBuilder: (item, dataIds) => ({
								mode: "VIEW",
								formId: "TRANSACTIONS_BOOKING_STATUS_FORM",
								dataIds: { id: item._id, userId: dataIds.userId },
							}),
							isVisible: (item) => {
								return item.status === "POSTPONED" || item.status === "FAILED";
							},
						},
					],
				},
			],
		},
	};
};

export default adminPostTruckList;
