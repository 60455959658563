// Components
import ActionButton from "app/main/common/ActionButton";

// Utils
import { formatDate } from "app/main/utils/dateUtils";

const continueApplicationButton = (url) => (
	<ActionButton
		key="unit_application_continue"
		onClick={() => window.location.assign(url)}
		label={"Continue your Application"}
	/>
);

const smartpayApplicationFormForm = ({ isCarrier, isAdmin }) => {
	return {
		urlGET: `api/wallet/${isAdmin ? "admin/" : ""}application/carrier/:entityId`,
		triggerEvent: "smarthopWalletRevision",
		listenEvent: "smarthopWalletRevision",
		idKey: "_id",
		content: {
			view: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "application",
									type: "text",
									label: "Status",
									builder: (item) => item?.status,
								},
								{
									key: "application_continue",
									type: "component",
									builder: (item) =>
										isCarrier
											? continueApplicationButton(item?.data?.applications[0].data.attributes.url)
											: "Customer should continue the Application",
								},
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "application_date",
									type: "text",
									label: "Last updated",
									builder: (item) => formatDate(item?.data?.applications[0].data.attributes.updatedAt),
								},
								{
									key: "application_form",
									type: "text",
									label: "Stage",
									builder: (item) =>
										Array.isArray(item?.data?.applications) && item?.data?.applications[0].data.attributes.status,
								},
							],
						},
					},
				],
			},
		},
	};
};

export default smartpayApplicationFormForm;
