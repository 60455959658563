import { Checkbox, Icon, Tooltip, Typography } from "@material-ui/core";
import PayoutCellView from "@smarthop/list/views/PayoutCellView";
import { formatDate } from "app/main/utils/dateUtils";
import { createTooltip, formatCurrency } from "app/main/utils/tableUtils";
import { DisplaySection } from "app/main/utils/uiUtils";

const STATUS_LABELS = {
	// Outstanding payouts entries are created in hold by default
	HOLD: "On-Hold",
	// Entries that can be processed any time
	PENDING: "Payable",
	// Final status
	PROCESSED: "Paid",
};

const renderAttribute = (attr, hide, renderer) => {
	if (hide || !attr) return "";
	return typeof renderer === "function" ? renderer(attr) : attr;
};

const onHoldTooltip = createTooltip(
	<Icon className="text-16 -mb-1 ml-2" color="action">
		{"info"}
	</Icon>,
	<Typography className="break-words whitespace-normal min-w-100 text-12 ml:text-13 font-light">
		This item is paid when the invoice amount is fully collected by SmartHop
	</Typography>,
	"bg-gray-600"
);

const SmartPayJournalEntriesComponent = ({
	title,
	payoutEntries,
	unselectedEntries,
	setUnselectedEntries,
	hideStatus,
	hideDescription,
	hideInvoice,
	hidePayout,
} = {}) => {
	return (
		<div className="flex w-full flex-col md:flex-row">
			<DisplaySection classes={{ root: "md:w-full" }} title={title}>
				{payoutEntries?.length > 0 ? (
					payoutEntries.map((entry, index) => {
						// Prepare entry label
						const invoiceRef = renderAttribute("Invoice " + entry.invoice__view?.label, hideInvoice);
						const invoiceStatus = entry.invoice__view?.metadata?.status;
						const invoiceApproved = ["SP_APPROVED", "SP_FUNDED"].includes(invoiceStatus);
						const status = renderAttribute(STATUS_LABELS[entry.status], hideStatus, (value) => (
							<div className="flex flex-row">
								<Typography component={"div"} className={`text-12 ml:text-13 break-normal`}>
									{value}
								</Typography>
								{entry.status === "HOLD" && <div>{onHoldTooltip}</div>}
								{entry.status === "PAID" && !!entry.processed_date && (
									<Typography component={"div"} className={`text-12 ml:text-13 break-normal ml-6 text-grey`}>
										{formatDate(entry.processed_date)}
									</Typography>
								)}
							</div>
						));
						const payout = renderAttribute(entry.payout__view, hidePayout || !entry.payout__view?.value, () => {
							return (
								<div className="flex flex-row">
									<Typography className={`text-12 ml:text-13 break-normal mr-4`}>Payout</Typography>
									{entry.payout__view?.value ? (
										<PayoutCellView item={entry} view={entry.payout__view} />
									) : (
										<Typography className={`text-11 ml:text-12 break-normal text-grey mr-4`}>#pending...</Typography>
									)}
								</div>
							);
						});
						const description = renderAttribute(entry.description, hideDescription);

						return (
							<div key={entry._id} className={"w-full flex flex-row pb-4 pt-0 " + (index === 0 ? " -mt-8 " : "")}>
								{/* Only Payout View requires entries selection */}
								{unselectedEntries && (
									<div className="text-12 md:text-13 font-semibold pr-3 mr-3 flex flex-row space-between items-center content-center -mb-6">
										<Checkbox
											disabled={invoiceApproved || payoutEntries.length === 1}
											className="pr-4 pl-0"
											size={"small"}
											checked={!unselectedEntries.includes(entry._id)}
											onChange={(event) => {
												if (event.target.checked) {
													setUnselectedEntries(unselectedEntries.filter((id) => id !== entry._id));
												} else {
													setUnselectedEntries([...unselectedEntries, entry._id]);
												}
											}}
										/>
										{!invoiceApproved && (
											<Tooltip
												classes={{ tooltip: "bg-white text-13 font-normal border-1 border-grey-400 text-black " }}
												title={`This invoice must be approved before it can be paid`}
											>
												<Icon className={"text-red text-18"}>warning</Icon>
											</Tooltip>
										)}
									</div>
								)}

								<div className={"w-full flex flex-row-reverse justify-between border-b-1 border-grey-300"}>
									<div className={`mt-10`}>
										<Typography className={`text-12 ml:text-13 break-normal font-semibold`}>
											{`${formatCurrency(entry.amount_cents / 100) ?? "-"}`}
										</Typography>
									</div>
									<div className={`flex flex-row`}>
										{[invoiceRef, status, payout, description]
											.filter((item) => !!item)
											.map((attr, index) => (
												<div key={index} className={`flex flex-row mt-10`}>
													{!!index && !!attr && <div className="ml-4 mr-4 -mt-4">|</div>}
													{typeof attr === "object" ? (
														attr
													) : (
														<Typography className={`text-12 ml:text-13 break-normal`}>{attr}</Typography>
													)}
												</div>
											))}
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className="flex flex-row -mt-4">
						<Typography className="w-1/2 text-12 md:text-13">{"None"}</Typography>
						<Typography className="w-1/2 text-10 mx-10">
							* Submit multiple invoices on the same day to receive a single payment and pay the transfer fee only once
						</Typography>
					</div>
				)}
			</DisplaySection>
		</div>
	);
};

export default SmartPayJournalEntriesComponent;
