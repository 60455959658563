import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

/**
 * @description get report pdf content
 * @param {*} carrierId
 * @param {*} invoiceId
 * @returns
 */
export const getReportFile = ({ carrierId, reportId, type }) => {
	return createRequest()
		.get(`/api/reports/carriers/${carrierId}/reports/${reportId}/file?type=${type}`, {
			responseType: "arraybuffer",
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * @description get report details
 * @param {*} carrierId
 * @param {*} invoiceId
 * @returns
 */
export function getReportDetails({ carrierId, reportId }) {
	const url = `/api/reports/carriers/${carrierId}/reports/${reportId}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
