import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { submitLogin, clearLoginErrors, dispatchLogin, loginError, setLoginStatus } from "app/store/auth/loginSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { changeLanguage } from "app/store/language/i18nSlice";
import { signInWithSocial } from "app/services/LoginService";

import SocialLogin from "./SocialLogin";
import loginForm from "../config/loginForm";
import createsSend2FAForm from "../config/send2FAForm";
import createValidate2FAForm from "../config/validate2FAForm";
import newPasswordForm from "../config/newPasswordForm";
import createAccountSelectionForm from "../config/accountSelectionForm";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { Typography } from "@material-ui/core";
//Utils
import { readURLParameters, convertURLParamsToModel } from "app/main/utils/urlUtils";

import i18next from "i18next";
import es from "../i18n/es";

i18next.addResourceBundle("es", "auth", es);

function LoginForm() {
	const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);
	const setEnglishLanguage = () => dispatch(changeLanguage("en"));
	const history = useHistory();
	const params = convertURLParamsToModel(readURLParameters(), {}, { notJson: true }) ?? {};

	useSelector(({ i18n }) => i18n.language);
	const [model, setModel] = useState(null);

	useEffect(() => {
		if (params?.email && params?.password) {
			onSubmit(params);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		return () => {
			dispatch(clearLoginErrors());
			setEnglishLanguage();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	function onSubmit(model) {
		if (login.inProgress) return;
		setModel(model);
		dispatch(
			submitLogin(model, null, () => {
				setEnglishLanguage();
			})
		);
	}

	// Step 1

	function onRequestCode(sendModel) {
		if (login.inProgress) return;
		let data = { ...model, verification_type: sendModel.type };
		dispatch(
			submitLogin(data, null, () => {
				setEnglishLanguage();
			})
		);
	}

	// Step 2

	function onSubmitCode(codeModel) {
		if (login.inProgress) return;
		let data = { ...model, verification_code: codeModel.code, verification_type: login.metadata?.verification_type };
		dispatch(
			submitLogin(data, null, () => {
				setEnglishLanguage();
			})
		);
	}

	// Step 3

	function onSelectedUser(userId) {
		if (login.inProgress) return;
		let data = {
			...model,
			selected_user_id: userId,
			// Every request should contain 2FA code and type, to make sure high level
			// of security for every request before we get auth token
			verification_type: login.metadata?.verification_type,
			verification_code: login.metadata?.verification_code,
			login_status: login.status,
		};

		if (!!login.metadata.social_token) {
			onSocialSubmit({ token: login.metadata.social_token, providerName: login.metadata.social_provider_name }, data);
		} else {
			dispatch(
				submitLogin(data, null, () => {
					setEnglishLanguage();
				})
			);
		}
	}

	// Step 4

	function onSubmitNewPassword(passwordModel) {
		if (login.inProgress) return;
		let data = {
			...model,
			new_password: passwordModel.password,
			// Every request should contain 2FA code and type, to make sure high level
			// of security for every request before we get auth token
			verification_type: login.metadata?.verification_type,
			verification_code: login.metadata?.verification_code,
			// Password reset happends in the very end of login sequence, after account was selected
			selected_user_id: login.metadata?.selected_user_id,
		};

		if (!!login.metadata.social_token) {
			onSocialSubmit({ token: login.metadata.social_token, providerName: login.metadata.social_provider_name }, data);
		} else {
			dispatch(
				submitLogin(data, null, () => {
					setEnglishLanguage();
				})
			);
		}
	}

	function onGoBack() {
		if (login.status === "2FA_CODE_REQUIRED") {
			let data = { ...login };
			data.status = "2FA_TYPE_REQUIRED";
			dispatch(setLoginStatus(data));
		} else {
			dispatch(setLoginStatus(null));
		}
	}

	async function onSocialSubmit(socialData, data) {
		if (login.inProgress) return;
		try {
			const loginResponse = await signInWithSocial(socialData.token, socialData.providerName, data);

			if (loginResponse.status === "PWD_RESET_REQUIRED") {
				console.log("[LoginSlice] password reset required");
				return dispatch(setLoginStatus(loginResponse));
			} else if (loginResponse.status === "ACCOUNT_SELECTION_REQUIRED") {
				console.log("[LoginSlice] account selection required");
				return dispatch(setLoginStatus(loginResponse));
			}

			if (!loginResponse.registered) {
				history.push("/register", loginResponse);
			} else {
				setEnglishLanguage();
				return dispatchLogin(dispatch, loginResponse);
			}
		} catch (e) {
			return dispatch(loginError([{ type: "generic", message: "Something went wrong, please try again later." }]));
		}
	}

	return (
		<>
			<div
				className={
					(login.status === "2FA_TYPE_REQUIRED" ||
					login.status === "2FA_CODE_REQUIRED" ||
					login.status === "PWD_RESET_REQUIRED" ||
					login.status === "ACCOUNT_SELECTION_REQUIRED"
						? " hidden "
						: login.inProgress
						? " opacity-60 "
						: "") + " w-full "
				}
			>
				<SocialLogin onSubmit={onSocialSubmit} />
				<SmarthopFormView
					key={"login_form"}
					content={loginForm}
					errors={login.errors}
					mode={"CREATE"}
					data={model}
					onSubmit={onSubmit}
				/>
				<Typography className="font-normal items-center flex justify-center" component="div">
					<Link
						to="#"
						className="cursor-pointer text-grey-900 whitespace-nowrap pl-4"
						onClick={() => dispatch(openLoadedFormDialog({ viewId: "PASSWORD_RECOVERY_VIEW" }))}
					>
						{i18next.t(`auth:Forgot your password?`)}
					</Link>
				</Typography>
			</div>
			{login.status === "2FA_TYPE_REQUIRED" && (
				<div className={(login.inProgress ? "opacity-60 " : "") + " w-full pt-10 "}>
					<SmarthopFormView
						key={"validation_type_" + login?.metadata?.email}
						content={createsSend2FAForm(login?.metadata?.email ?? "", login?.metadata?.phone ?? "")}
						errors={login.errors}
						mode={"CREATE"}
						onSubmit={onRequestCode}
						onCustom={onGoBack}
					/>
				</div>
			)}
			{login.status === "2FA_CODE_REQUIRED" && (
				<div className={(login.inProgress ? "opacity-60 " : "") + " w-full pt-10 "}>
					<SmarthopFormView
						key={"validation_code_" + login?.metadata?.email}
						content={createValidate2FAForm(
							login?.metadata?.verification_type,
							login?.metadata?.email ?? "",
							login?.metadata?.phone ?? ""
						)}
						errors={login.errors}
						mode={"CREATE"}
						onSubmit={onSubmitCode}
						onCustom={onGoBack}
					/>
				</div>
			)}
			{login.status === "ACCOUNT_SELECTION_REQUIRED" && (
				<div className={(login.inProgress ? "opacity-60 " : "") + " w-full pt-10 "}>
					<SmarthopFormView
						key={"account_selection" + login?.metadata?.email}
						content={createAccountSelectionForm(login?.metadata?.account_options, (id) => onSelectedUser(id))}
						errors={login.errors}
						mode={"CREATE"}
						onCustom={onGoBack}
					/>
				</div>
			)}
			{login.status === "PWD_RESET_REQUIRED" && (
				<div className={(login.inProgress ? "opacity-60 " : "") + " w-full pt-10 "}>
					<SmarthopFormView
						key={"pwd_reset_" + login?.metadata?.email}
						content={newPasswordForm}
						errors={login.errors}
						mode={"CREATE"}
						onSubmit={onSubmitNewPassword}
						onCustom={onGoBack}
					/>
				</div>
			)}
		</>
	);
}

export default LoginForm;
