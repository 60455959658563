import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { buildURLParameters } from "app/main/utils/urlUtils";
import axios from "axios";
import { global } from "app/services/requestUtil";
import moment from "moment";

export const fetchMap = createAsyncThunk("marketConditions/map", async (data) => {
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};
	let { equipment, field, toplanes } = data;
	let params = { equipment, orientation: field, filter_best: toplanes };
	if (data?.location) {
		params.location = data.location;
	}
	const url = buildURLParameters("/api/market", params);

	const response = await axios.create({ baseURL: global.SERVER_NAME, headers: headers }).get(url);

	return response.data;
});

export const selectByType = (state, type, field = "ocluser", toplanes = "toplanes", location = undefined) =>
	state?.map?.maps?.[type + "-" + field + "-" + toplanes + (location ? "-" + location : "")];

const mapSlice = createSlice({
	name: "map",
	initialState: {
		loading: false,
		maps: {
			"van-ocluster-toplanes": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"reefer-ocluster-toplanes": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"flatbed-ocluster-toplanes": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"van-dcluster-toplanes": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"reefer-dcluster-toplanes": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"flatbed-dcluster-toplanes": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"van-ocluster-top100": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"reefer-ocluster-top100": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"flatbed-ocluster-top100": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"van-dcluster-top100": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"reefer-dcluster-top100": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
			"flatbed-dcluster-top100": {
				status: "",
				errors: null,
				heatMap: [],
				expirationDate: "",
			},
		},
	},
	reducers: {},
	extraReducers: {
		[fetchMap.pending]: (state, action) => {
			let key = Object.values(action.meta.arg).join("-");
			state.loading = true;
			if (!state.maps[key]) {
				state.maps[key] = {
					status: "pending",
					errors: null,
					heatMap: [],
					expirationDate: "",
				};
			}
			state.maps[key].status = "pending";
		},
		[fetchMap.fulfilled]: (state, action) => {
			state.loading = false;
			let key = Object.values(action.meta.arg).join("-");
			state.maps[key].errors = null;
			state.maps[key].status = "fulfilled";
			state.maps[key].heatMap = action.payload;
			state.maps[key].expirationDate = moment(action.payload[0].last_update).add(1, "hour");
		},
		[fetchMap.rejected]: (state, action) => {
			state.loading = false;
			let key = Object.values(action.meta.arg).join("-");
			if (state.maps[key]) {
				state.maps[key].status = "rejected";
				state.maps[key].heatMap = [];
				state.maps[key].errors = [{ type: "generic", message: "Oops, failed to get heat map data" }];
			}
		},
	},
});

export default mapSlice.reducer;
