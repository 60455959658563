const uploadTripsConfig = {
	noInitValidation: true,
	items: [
		{
			key: "csv_file",
			type: "upload",
			label: "CSV file",
			required: true,
			upload: {
				url: "api/booking/carriers/:carrierId/files",
				fileField: true,
				documentType: "trip",
				fileName: "trip_csv",
				type: "trip_csv",
				allowedFiles: [".csv", ".xls", ".xlsx"],
			},
		},
		{
			key: "platform",
			type: "autocomplete",
			label: "Which platform are you importing from?",
			required: true,
			autocomplete: {
				provider: "smarthop",
				url: "api/consts/platforms",
				preloadDefault: true,
			},
		},
		{ type: "action", action: "SUBMIT", label: "Accept" },
	],
};

export default uploadTripsConfig;
