import React from "react";
import { Redirect } from "react-router-dom";

import clsx from "clsx";
import moment from "moment";
import { useState, useEffect, useMemo, useCallback } from "react";
import PageWrapper from "app/main/common/PageWrapper";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import {
	readURLParameters,
	convertURLParamsToModel,
	rewriteURLParameters,
	convertModelToURLParams,
	STRATEGY_URL_PARAMS_MAPPING,
} from "../utils/urlUtils";
import StrategyView from "./StrategyView";
import { useSelector } from "react-redux";
import { authRoles } from "app/main/consts";
import _ from "lodash";

const SAVED_TABS_KEY = "SAVED_STRATEGY_TABS";

const findTabIndex = (tabs, model) => {
	// TODO: [Patricio] Tweak logic to match strategy model
	if (!model) return -1;
	let i = tabs.findIndex((item) => {
		return (
			model?.truck === item.model?.truck &&
			model?.city === item.model?.city &&
			model?.state === item.model?.state &&
			model?.dcity === item.model?.dcity &&
			model?.dstate === item.model?.dstate &&
			model?.start_date === item.model?.start_date &&
			model?.max_end_date === item.model?.max_end_date
		);
	});
	return i;
};

const saveTabs = async (tabs) => {
	const sanitized = tabs.map((item) => ({
		id: item.id,
		empty: item.empty,
		model: item.model,
	}));
	localStorage.setItem(SAVED_TABS_KEY, JSON.stringify(sanitized));
};

function StrategyTab(props) {
	const user = useSelector(({ auth }) => auth.user);
	const roles = user.rolesLabels;
	const isDriver = roles.includes(authRoles.driver[0]);
	const isManager = roles.includes(authRoles.carrierManager[0]);
	const isInvestor = roles.includes(authRoles.carrierGeneric[0]);
	// Checking if default tab params specified in URL, if so that tab would be selected by default

	const { defaultTabs, defaultId, defaultIndex, defaultViewedIds, defaultEmpty } = useMemo(() => {
		let urlModel;
		try {
			urlModel = convertURLParamsToModel(readURLParameters(), STRATEGY_URL_PARAMS_MAPPING);
		} catch (e) {
			console.error("[StrategyTab] failed to parse url params", e?.message);
		}

		let defaultTabs = [];
		try {
			const saveTabsRaw = localStorage.getItem(SAVED_TABS_KEY);
			defaultTabs = saveTabsRaw ? JSON.parse(saveTabsRaw) : [];
		} catch (e) {
			console.error("[StrategyTab] failed to parse tabs object", e?.message);
		}

		// Finding default max tab id
		let defaultId = 0;
		defaultTabs.forEach((item) => {
			if (item.id >= defaultId) defaultId = item.id + 1;
		});

		// Adding empty tab if not found one
		if (!defaultTabs.find((item) => item.empty)) {
			defaultTabs.push({ id: defaultId, empty: true });
			defaultId++;
		}

		// Checking if url params correspond to any of the saved tabs, is so selecting that tab
		let defaultIndex = findTabIndex(defaultTabs, urlModel);
		let defaultEmpty = false;
		if (urlModel && defaultIndex >= 0) {
			defaultTabs[defaultIndex].model = urlModel;
		} else if (urlModel) {
			defaultTabs = [{ id: defaultId, empty: false, model: urlModel }, ...defaultTabs];
			defaultIndex = 0;
			defaultId++;
		} else {
			defaultIndex = defaultTabs.length - 1;
			defaultEmpty = true;
		}

		let defaultViewedIds = { [defaultTabs[defaultIndex].id]: true };
		return { defaultTabs, defaultId, defaultIndex, defaultViewedIds, defaultEmpty };
		// eslint-disable-next-line
	}, []);

	const [revision, setRevision] = useState(0);
	const [viewedIds, setViewedIds] = useState(defaultViewedIds);
	const [selectedTab, setSelectedTab] = useState(defaultIndex);
	const [idIndex, setIdIndex] = useState(defaultId);
	const [tabParams, setTabParams] = useState(defaultTabs);
	const [screenWidth, setScreenWidth] = useState(parseInt(window.innerWidth / 50) * 50);
	const [mobile, setMobile] = useState(window.innerWidth < 1100);

	// Monitoring screen width to adjust tab width

	let tabWidth = tabParams.length > 0 ? screenWidth / tabParams.length : 0;
	tabWidth = tabWidth > 360 ? 360 : tabWidth < 210 ? 210 : tabWidth;

	useEffect(() => {
		function handleResize() {
			let innerWidth = window.innerWidth;
			innerWidth = parseInt(innerWidth / 50) * 50;
			if (innerWidth !== screenWidth) setScreenWidth(innerWidth);

			let isNewWidthMobile = window.innerWidth < 1100;
			if (isNewWidthMobile !== mobile) setMobile(isNewWidthMobile);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	});

	// Rewriting url when tab is changed

	useEffect(() => {
		if (!tabParams?.length) return;
		const tab = tabParams[selectedTab];
		const URLParams = convertModelToURLParams(tab?.model ?? {}, STRATEGY_URL_PARAMS_MAPPING);
		rewriteURLParameters(!_.isEmpty(URLParams) ? { ...URLParams, refetched: true } : {});
		// eslint-disable-next-line
	}, [selectedTab, revision]);

	// Updating strategy tabs on submit, adding new empty tab

	const handleStrategySubmitted = useCallback(
		(i, model) => {
			setTabParams((tabParams) => {
				if (i === selectedTab) {
					let newModel = { ...(model ?? {}) };
					const currentEmpty = tabParams[i].empty;
					const newTabParams = [...tabParams];

					// Checing if there is tab with the same params, closing it if found
					const duplicateTabIndex = findTabIndex(newTabParams, model);
					if (duplicateTabIndex >= 0 && duplicateTabIndex !== selectedTab) {
						newTabParams.splice(duplicateTabIndex, 1);
						if (duplicateTabIndex < selectedTab) {
							setSelectedTab(selectedTab - 1);
							i = selectedTab - 1;
						}
					}

					// Replace empty strategy tab with new strategy request params
					newTabParams[i].empty = false;
					newTabParams[i].model = newModel;
					if (currentEmpty) newTabParams.push({ id: idIndex, empty: true });

					saveTabs(newTabParams);
					return newTabParams;
				}
				return tabParams;
			});
			setIdIndex(idIndex + 1);
			setRevision(revision + 1);
		},
		// eslint-disable-next-line
		[tabParams, selectedTab, idIndex]
	);

	if (mobile) {
		return <StrategyView entryPoint="topLevel" contentEmpty={defaultEmpty} />;
	}

	// Multitab support logic

	const onSelectTab = (e, index, id) => {
		e.stopPropagation();
		const newViewedIds = { ...viewedIds };
		newViewedIds[id] = true;
		setViewedIds(newViewedIds);
		setSelectedTab(index);
	};

	const onCloseTab = (e, index, tabs = null) => {
		e.stopPropagation();

		let newTabs = [...tabParams];
		newTabs.splice(index, 1);
		setTabParams(newTabs);
		saveTabs(newTabs);

		let newSelectedTab = selectedTab;
		if (newSelectedTab > 0 && index < newSelectedTab) {
			newSelectedTab = newSelectedTab - 1;
			setSelectedTab(newSelectedTab);
		} else if (newSelectedTab >= newTabs.length) {
			newSelectedTab = newTabs.length - 1;
			setSelectedTab(newSelectedTab);
		} else {
			setRevision(revision + 1);
		}

		const visibleTabId = newTabs?.[newSelectedTab]?.id;
		if (visibleTabId && !viewedIds[visibleTabId]) {
			const newViewedIds = { ...viewedIds };
			newViewedIds[visibleTabId] = true;
			setViewedIds(newViewedIds);
		}
	};

	const tabs = tabParams.map((tab, i) => {
		const model = tab.model;

		const destination = model?.dcity && model?.dstate ? ` - ${model?.dcity} ${model?.dstate}`.toUpperCase() : "";

		const titleLabel = tab.empty
			? "New Strategy"
			: moment(model?.start_date).format("MMM DD") + " | " + model?.equipment;
		const subtitleLabel = tab.empty ? null : `${model?.city} ${model?.state}`.toUpperCase() + destination;

		return (
			<div
				key={"tab_index_" + tab.id}
				onClick={(e) => onSelectTab(e, i, tab.id)}
				className={clsx(
					"flex h-52 px-10 cursor-pointer",
					"items-center justify-center rounded-tl-lg rounded-tr-lg",
					selectedTab === i ? "bg-white" : "opacity-80 bg-grey-50 hover:bg-grey-100"
				)}
				style={{ minWidth: tabWidth }}
			>
				{!tab.empty && (
					<div className={clsx("flex flex-col min-w-20")}>
						{tab.counters?.new > 0 && tab.state === "ACTIVE" && (
							<Typography
								className={clsx(
									"text-11 text-center px-6 pt-1 pb-1 rounded-full bg-cyan-700 text-white font-bold -mt-2 mb-2"
								)}
							>
								{tab.counters?.new}
							</Typography>
						)}
					</div>
				)}
				<div className={clsx("flex flex-1 flex-col items-center justify-center")}>
					<Typography
						color="primary"
						className={clsx("px-14 text-13 font-normal mb-2 max-w-210 truncate")}
						style={{ maxWidth: tabWidth - 70 }}
					>
						{titleLabel}
					</Typography>
					<Typography
						color="primary"
						className={clsx("px-14 text-13 font-normal -mt-4 max-w-210 truncate")}
						style={{ maxWidth: tabWidth - 70 }}
					>
						{subtitleLabel}
					</Typography>
				</div>
				{!tab.empty && (
					<IconButton
						key="close"
						aria-label="Close"
						className={clsx("-ml-12 -mr-10")}
						color="inherit"
						onClick={(e) => onCloseTab(e, i)}
					>
						<Icon className={clsx("text-20 text-grey-600")}>close</Icon>
					</IconButton>
				)}
			</div>
		);
	});

	const views = tabParams.map((tab, i) => {
		return (
			<StrategyView
				key={"content_index_" + tab.id}
				entryPoint="topLevel"
				onStrategySubmitted={(model) => handleStrategySubmitted(i, model)}
				contentEmpty={tab.empty}
				contentHidden={i !== selectedTab}
				contentListDisabled={!viewedIds[tab.id]}
			/>
		);
	});

	if ((isDriver || isManager || isInvestor) && !user?.gatekeepers?.permission_search_on) {
		return <Redirect to="/" />;
	}

	return (
		<PageWrapper
			restrictions={["ONBOARDING", "TIER_BASIC"]}
			classes={{
				content: "flex w-full",
			}}
			isView={true}
			noPadding={true}
		>
			<div className="flex flex-col flex-auto min-h-0 relative z-10">
				<div className="flex w-full h-56 flex-row bg-grey-200 pt-4 px-4 overflow-scroll scrollbar-hide items-end">
					{tabs}
				</div>
				{views}
			</div>
		</PageWrapper>
	);
}

export default StrategyTab;
