import { authRoles } from "app/main/consts";
import i18next from "i18next";
import en from "./i18n/en";
import es from "./i18n/es";

import LoadSearchResultTab from "./LoadSearchResultTab";
import SearchPage from "../../searchV3/SearchPage";
import { isEnabled } from "app/services/featureStorageService";

i18next.addResourceBundle("en", "LoadSearchResultPage", en);
i18next.addResourceBundle("es", "LoadSearchResultPage", es);

const LoadSearchResultConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false,
				},
				header: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.all,
	routes: [
		{
			path: "/search",
			component: () => {
				const version = localStorage.getItem("SEARCH_VERSION");
				if (version !== "2" && isEnabled("ENABLE_SEARCH_V3")) return <SearchPage />;
				return <LoadSearchResultTab />;
			},
		},
	],
};

export default LoadSearchResultConfig;
