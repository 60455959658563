const adminCommunicationsList = {
	urlGET: "api/communications/",
	idKey: "_id",
	segmentKey: "COMMUNICATIONS",
	content: {
		defaults: {
			initial: {
				filters: {
					type: "__NOT_SELECTED__",
				},
			},
			items: [
				{
					label: "All",
					filters: {
						type: "__NOT_SELECTED__",
					},
				},
				{
					label: "SMS",
					filters: {
						type: "sms",
					},
				},
				{
					label: "Email",
					filters: {
						type: "email",
					},
				},
			],
		},
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt", "to", "from"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					label: "type",
					key: "type",
					type: "select",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "All",
						},
						{
							value: "sms",
							label: "SMS",
						},
						{
							value: "email",
							label: "Email",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show System Fields",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "type", type: "text", label: "Type" },
			{
				key: "to",
				type: "text",
				label: "To",
			},
			{
				key: "from",
				type: "text",
				label: "From",
			},
			{
				key: "createdAt",
				type: "date",
				label: "Date",
			},
			{
				key: "data",
				type: "text",
				label: "Data",
				collapsibleLength: 40,
				builder: (item) => (item.type === "sms" ? item.data.body : item.data.subject),
			},
		],
	},
};

export default adminCommunicationsList;
