const carrierApplyCouponForm = {
	urlPOST: "api/discounts/carriers/:carrierId/apply",
	idKey: "_id",
	triggerEvent: "discountRevision",
	listenEvent: "discountRevision",
	header: { create: { label: "Apply coupon" } },
	content: {
		create: {
			items: [
				{
					key: "coupon",
					type: "autocomplete",
					label: "Coupons",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/coupons/list",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierApplyCouponForm;
