import { createRequest, throwResErrors, createHeaders } from "./requestUtil";
import { global } from "app/services/requestUtil";
import { PDFDocument, PageSizes } from "pdf-lib";

export const upload = ({ truckId, carrierId, userId, chatId, file, folder }) => {
	let data = new FormData();
	if (chatId) data.append("chatId", chatId);
	if (carrierId) data.append("carrierId", carrierId);
	if (truckId) data.append("truckId", truckId);

	data.append("file", file);
	data.append("folder", folder ? folder : userId);

	return createRequest()
		.post(`api/file/upload`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const get = (fileId) => {
	const url = `api/file/get/${fileId}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const remove = (fileId) => {
	return createRequest()
		.delete(`api/file/delete/${fileId}`, {})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const buildLink = (fileId, download = false, isPublic = false) => {
	const url = isPublic
		? `${global.SERVER_NAME}/api/public/url/file/get/${fileId}`
		: `${global.SERVER_NAME}/api/file/get/${fileId}`;
	return `${url}?disposition=${download ? "attachment" : "inline"}&token=${localStorage.getItem("tokenSmarthop")}`;
};

export const uploadRCFile = ({ carrierId, file }) => {
	let data = new FormData();
	if (carrierId) data.append("carrierId", carrierId);

	data.append("file", file);

	return createRequest()
		.post(`api/booking/carriers/${carrierId}/files`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

function base64ToArrayBuffer(base64) {
	var binaryString = atob(base64);
	var bytes = new Uint8Array(binaryString.length);
	for (var i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
}

export const convert = async (images) => {
	const pdfDoc = await PDFDocument.create();

	for (let image of images) {
		const pdfPage = pdfDoc.addPage(PageSizes.A4);
		const { width, height } = pdfPage.getSize();

		const pdfImage = await pdfDoc.embedJpg(base64ToArrayBuffer(image));

		let imageDims = pdfImage.size();
		// Make sure the image is not larger than the page, and scale down to fit if it is
		if (imageDims.width > width || imageDims.height > height) {
			imageDims = pdfImage.scaleToFit(width, height);
		}
		// Draw image in page, centered horizontally and vertically
		pdfPage.drawImage(pdfImage, {
			x: width / 2 - imageDims.width / 2,
			y: height / 2 - imageDims.height / 2,
			width: imageDims.width,
			height: imageDims.height,
		});
	}

	const pdfBase64 = await pdfDoc.saveAsBase64({ dataUri: true });
	return pdfBase64;
};
