import Button from "@material-ui/core/Button";

function ViewButton(props) {
	return (
		<div className={"flex flex-col w-full"}>
			<Button
				variant="contained"
				color={props.color ?? "primary"}
				className={"w-full mx-auto mt-5 text-white " + props.className}
				onClick={props?.onClick}
			>
				{props.label}
			</Button>
		</div>
	);
}

export default ViewButton;
