import { CHANGE_TRUCK_INFO, CLEAN_TRUCK_INFO } from "../constants";

export const changeTruckInfo = (truckInfo) => ({
	type: CHANGE_TRUCK_INFO,
	payload: truckInfo,
});

export const cleanTruckInfo = () => ({
	type: CLEAN_TRUCK_INFO,
});
