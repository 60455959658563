import { Typography, Button } from "@material-ui/core";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { formatDate } from "app/main/utils/dateUtils";
import { useDispatch } from "react-redux";

const color = {
	DONE: "bg-green-500",
	RESPONSE_REQUIRED: "bg-orange-300",
	CANCELED: "bg-red-200",
	IN_PROGRESS: "bg-orange-300",
	FINISHED: "bg-green-400",
};

const label = {
	DONE: "Done",
	RESPONSE_REQUIRED: "Response",
	CANCELED: "Canceled",
	IN_PROGRESS: "Progress",
	FINISHED: "Done",
};

function TransactionActionView(props) {
	const { transaction } = props;
	const data = transaction.action;
	console.log(data, "DAta");
	const dispatch = useDispatch();

	const handleProgressMessage = (type, content, status) => {
		if (type === "BOOK_NOW") {
			const load = content.load;
			if (status === "IN_PROGRESS") {
				return `The ${content.bid_now ? "Bid" : "Book"} of the load ${load?.load_id ?? content.loadId} is in progress`;
			}
			return transaction.lastMessage + ` with the load ${load?.load_id ?? content.loadId}`;
		}
		if (status === "IN_PROGRESS") {
			return `The report is in progress`;
		}
		return transaction.lastMessage + ` with the report`;
	};

	const onAction = () => {
		dispatch(openLoadedFormDialog(data.dialog));
	};

	return (
		<div className={"border border-grey-600 rounded-10 p-20"}>
			<div className="items-center flex">
				{transaction.unread && data && <div className="rounded w-10 h-6 bg-red mr-6"></div>}
				<div className="flex-col">
					<Typography className="text-14">
						{data?.message
							? data?.message + (data?.date ? "- " + formatDate(data?.date) : "")
							: handleProgressMessage(transaction.type, transaction.content, transaction.status)}
					</Typography>
				</div>
				<div className="ml-auto">
					<Typography
						className={
							"text-13 text-white font-medium rounded-full justify-center h-24 py-2 px-18 " + color[transaction.status]
						}
					>
						{label[transaction.status]}
					</Typography>
				</div>
			</div>
			{data?.dialog && (
				<Button variant="contained" color="primary" className="mt-10 flex py-4 px-12" onClick={onAction}>
					{data?.dialog?.label ?? "Action"}
				</Button>
			)}
		</div>
	);
}

export default TransactionActionView;
