import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import React from "react";
import Button from "@material-ui/core/Button";
import { getRoleLabel } from "app/services/LoginService";
import { authRoles } from "app/main/consts";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { updateTruckLocation } from "app/services/tripsServices";
import CircularProgress from "@material-ui/core/CircularProgress";

import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";

function MessageGeoFence(props) {
	const { message } = props;
	const incoming = props.incoming;
	const variant = props.variant;
	const { type, action, tripId, confirmed } = message?.content ?? {};
	const typeMsg = type === "pickup" ? "Pickup Area" : "Delivery Area";
	const actionMsg = action === "ENTER" ? "Entering" : "Exiting";

	const [loading, setLoading] = React.useState(false);
	const [isConfirmed, setIsConfirmed] = React.useState(confirmed);

	const role = getRoleLabel();
	const isOwner = authRoles.externalUsers.includes(role);
	const [openConfirmMessage, setOpenConfirmMessage] = React.useState(false);

	const onConfirm = async (result) => {
		setOpenConfirmMessage(false);
		if (result) {
			const data = { data: { type, action, confirmed: result, tripId, source: "GEOFENCE" } };
			setLoading(true);
			const response = await updateTruckLocation(tripId, data, "GEOFENCE");
			setIsConfirmed(response.confirmed);
			setLoading(false);
		}
	};

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={true}
			variant={variant}
			headerConfig={{ title: "Load Tracking", subtitle: `${actionMsg} ${typeMsg}`, showDivider: true }}
			contentFields={[
				...(action === "EXIT" && !isConfirmed
					? [
							{
								type: "TEXT",
								value: isOwner
									? `We have detected that your location has changed. Please confirm if load ${
											message?.content?.loadId
									  } was ${type === "pickup" ? "picked up" : "delivered"}`
									: "Awaiting user confirmation",
							},
					  ]
					: []),
				...(isConfirmed || action === "ENTER"
					? [
							{
								type: "TEXT",
								value: `Load status changed to: ${
									type === "pickup"
										? action === "ENTER"
											? "At Pickup"
											: "Picked Up"
										: action === "ENTER"
										? "At Delivery"
										: "Delivered"
								}`,
							},
					  ]
					: []),
			]}
			messageActions={[
				...(action === "EXIT" && !isConfirmed
					? [
							{
								key: "CONFIRM_STATUS",
								title: "Confirm",
								hideOnDesktop: false,
								hideOnMobile: false,
								onClick: () => {
									setOpenConfirmMessage(true);
								},
							},
					  ]
					: []),
			]}
			footerComponents={
				<div className={"flex items-center w-full mt-4 mr-4" + (variant === "notification" ? " pr-44 " : "  ")}>
					<SmarthopConfirmDialog
						open={!!openConfirmMessage}
						title={`Load ${message?.content?.loadId} was ${type === "pickup" ? "picked up" : "delivered"}`}
						onClose={() => onConfirm(false)}
						onAccept={() => onConfirm(true)}
						acceptMsg="Yes"
						closeMsg="No"
					/>
				</div>
			}
			//DEPRECATED by CHAT_NEW_UI_MESSAGES GK
			contentComponents={
				<>
					<div className="flex flex-col w-full px-4 text-12 md:text-13 md:min-w-300 text-black">
						<div className={"flex items-center w-full mt-4 mr-4" + (variant === "notification" ? " pr-44 " : "  ")}>
							<div className="flex flex-col">
								<Typography className={"text-14 md:text-18 font-medium"}>Load Tracking</Typography>
								<Typography className="font-normal text-12 md:text-13">{`${actionMsg} ${typeMsg}`}</Typography>
							</div>
						</div>
						{variant !== "notification" && <Divider className={"mt-8 mb-6 " + (incoming ? "" : "bg-grey-400")} />}
						{action === "EXIT" && !isConfirmed && (
							<div className={"flex flex-col items-center w-full mt-8"}>
								<Typography className="flex items-center font-normal text-12 md:text-13">
									{`We have detected that your location has changed. Please confirm if load ${
										message?.content?.loadId
									} was ${type === "pickup" ? "picked up" : "delivered"}`}
								</Typography>
							</div>
						)}
						<div className="flex flex-row items-center w-full pt-8">
							{isOwner && variant !== "notification" && !isConfirmed && action === "EXIT" && (
								<Button
									variant="contained"
									color="secondary"
									className={"text-white"}
									onClick={() => setOpenConfirmMessage(true)}
									disabled={loading}
								>
									{loading ? <CircularProgress size={15} /> : "Confirm"}
								</Button>
							)}
							{!isOwner && variant !== "notification" && !isConfirmed && action === "EXIT" && (
								<Typography className="font-normal text-12 md:text-13">Awaiting user confirmation</Typography>
							)}
							{variant !== "notification" && (isConfirmed || action === "ENTER") && (
								<>
									Load status changed to:{" "}
									<span className="text-green-600">
										{type === "pickup"
											? action === "ENTER"
												? "At Pickup"
												: "Picked Up"
											: action === "ENTER"
											? "At Delivery"
											: "Delivered"}
									</span>
								</>
							)}
						</div>
					</div>
					<SmarthopConfirmDialog
						open={!!openConfirmMessage}
						title={`Load ${message?.content?.loadId} was ${type === "pickup" ? "picked up" : "delivered"}`}
						onClose={() => onConfirm(false)}
						onAccept={() => onConfirm(true)}
						acceptMsg="Yes"
						closeMsg="No"
					/>
				</>
			}
		/>
	);
}
export default MessageGeoFence;
