import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { Typography } from "@material-ui/core";

const TruckPlanSearchChart = ({ history, data, height = 415, tickAmount = 5 }) => {
	const chartSettings = useMemo(() => {
		if (!history || !history?.items) return false;

		let filtersItems = history?.items?.filter(
			(item) =>
				moment().subtract(24, "hours").isBefore(item.createdAt) &&
				item.analytics?.counters?.total !== undefined &&
				item.trigger === "MARKET_MONITOR"
		);

		if (filtersItems?.length <= 0) return false;

		filtersItems.sort((a, b) => moment(a.createdAt) - moment(b.createdAt));
		filtersItems.unshift({
			createdAt: data?.plan?.createdAt ?? 0,
			analytics: {
				counters: {
					total: 0,
				},
			},
		});
		const s = {
			options: {
				chart: {
					toolbar: { show: false },
					height: height,
				},
				plotOptions: {},
				xaxis: {
					categories: filtersItems?.map((item, i) => moment(item.createdAt).format("h:mmA")),
					labels: {
						rotate: -45,
						rotateAlways: true,
					},
				},
				yaxis: {
					tickAmount: tickAmount,
				},
			},
			series: [
				{
					name: "# Loads",
					data: filtersItems?.map((item, i) => item.analytics?.counters?.total ?? 0),
				},
			],
		};

		if (filtersItems?.length > 6) {
			s.options.xaxis.range = 6;
		}

		return s;
	}, [history, height, tickAmount, data]);

	return (
		<>
			{history === false && (
				<div className="flex flex-col w-full h-full justify-center mt-9">
					<Typography className={"text-grey text-12"}>Loading...</Typography>
				</div>
			)}
			{chartSettings && history !== false && (
				<div className="flex flex-col">
					<ReactApexChart
						className="-mt-8 overflow-hidden"
						height={height}
						style={{ display: "flex", width: "100%" }}
						options={chartSettings.options}
						series={chartSettings.series}
						type="line"
					/>
				</div>
			)}
			{!chartSettings && history !== false && (
				<div className="flex flex-col w-full h-full justify-center mt-9">
					{data?.plan?.advancedFilters ? (
						<Typography className={"text-12"}>No Results</Typography>
					) : (
						<Typography className={"text-12"}>Disabled</Typography>
					)}
				</div>
			)}
		</>
	);
};

export default TruckPlanSearchChart;
