import { global } from "app/services/requestUtil";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { formatCurrency } from "app/main/utils/tableUtils";
import SmarthopTableView from "@smarthop/form/fields/SmarthopTableView";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { withStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

const formatPercentage = (value) => {
	return value ? value + "%" : value;
};

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
	},
})(TimelineItem);

const formatFields = (
	value,
	type,
	component,
	overrides,
	nativeMobile,
	dataIds,
	dispatch,
	content,
	options,
	history
) => {
	let text;
	if (value || value === false || value === 0 || type === "checkbox") {
		if (type === "autocomplete" && !component?.multiple) {
			let item = value;
			text = item?.label ?? item?.value ?? "No Information";
		} else if (type === "autocomplete" && component?.multiple) {
			if (component.preview?.format === "LIST") {
				return !value?.length ? (
					<Typography color={"textSecondary"} className="-mt-10 break-all ml:break-words">
						No information
					</Typography>
				) : (
					value?.map((item, index) => {
						return (
							<Typography key={index} color={"textSecondary"} className="break-all ml:break-words">
								<Icon className="text-10 -mb-2 mr-2">radio_button_unchecked</Icon> {item.label}
							</Typography>
						);
					})
				);
			}

			text = "";
			let hasMore = 0;
			value?.forEach((item, i) => {
				if (i > 50) {
					hasMore++;
					return;
				}
				if (text) text += ", ";
				text += item?.label ?? item?.value;
			});
			if (hasMore > 0) {
				text += " and " + hasMore + " more";
			}
			text = text ?? "No Information";
		} else if (type === "select") {
			let found = options?.find((item) => item.value + "" === value + "");
			text = found?.label ?? "No Information";
		} else if (type === "checkbox") {
			text = value === true ? (component?.inverted ? "No" : "Yes") : component?.inverted ? "Yes" : "No";
		} else if (type === "picker") {
			text = new Date(value).toLocaleString();
		} else if (type === "upload") {
			// FIXME: this is a dirty workaround to generate preview link for upload field
			let url = "/api/profile/carriers/:carrierId/files/";
			Object.keys(dataIds).forEach((key) => {
				if (dataIds.hasOwnProperty("historyData")) {
					let carrier = dataIds?.["historyData"]?.length
						? dataIds?.["historyData"]?.[0]?.carrier
						: dataIds?.["historyData"]?.carrier;
					url = url.replace(":carrierId", carrier);
				} else {
					url = url.replace(":" + key, dataIds[key]);
				}
			});

			if (value && value[0]) {
				let token = localStorage.getItem("tokenSmarthop");
				let viewUrl = global.SERVER_NAME + url + value[0] + "?token=" + token;
				text = (
					<Link
						className="cursor-pointer"
						onClick={() => {
							if (nativeMobile) {
								window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: viewUrl }));
							} else {
								window.open(viewUrl);
							}
						}}
					>
						View File
					</Link>
				);
			}

			text = text ?? "No File";
		} else if (type === "pattern") {
			return !value?.length ? (
				<Typography color={"textSecondary"} className="-mt-10 break-all ml:break-words">
					No information
				</Typography>
			) : history ? (
				<div className={"flex w-full overflow-x-scroll"}>
					<SmarthopTableView
						values={value}
						content={content}
						dataIds={dataIds}
						type={type}
						component={component}
						overrides={overrides}
						dispatch={dispatch}
						options={options}
						history={history}
					/>
				</div>
			) : (
				<Timeline className="flex w-full p-0 m-0" position="left">
					{value?.map((item, index) => {
						return (
							<StyledTimelineItem key={index} className="flex w-full p-0 m-0 ">
								{!component.hideIndex && (
									<TimelineSeparator>
										<TimelineDot color="primary" className={"items-center justify-center h-32 w-32 mt-12 ml-6 mr-6"}>
											{index + 1}
										</TimelineDot>
									</TimelineSeparator>
								)}
								<TimelineContent className="flex w-full p-0 m-0">
									<SmarthopFormView
										key={item.__key}
										nestedForm={true}
										content={content}
										overrides={overrides}
										data={item}
										dataIds={dataIds}
										mode={"VIEW"}
									/>
								</TimelineContent>
							</StyledTimelineItem>
						);
					})}
				</Timeline>
			);
		} else if (type === "object") {
			text = (
				<div className={"flex w-full"}>
					<SmarthopFormView
						nestedForm={true}
						content={content}
						overrides={overrides}
						data={value}
						dataIds={dataIds}
						mode={"VIEW"}
					/>
				</div>
			);
		} else if (type === "number" && value === 0) {
			text = value.toString();
		} else if (type === "currency") {
			text = formatCurrency(value);
		} else if (type === "percentage") {
			text = formatPercentage(value);
		} else {
			text = value;
		}
	}

	return text;
};

/**
 * Filters options in a select item
 * @param {*} item
 * @param {*} selectKey
 * @param {*} optionValuesArray
 * @returns
 */
const filterSelectOptions = (item, selectKey, optionValuesArray, { not }) => {
	if (item?.key === selectKey && item?.type === "select") {
		return {
			...item,
			options: item.options.filter((option) =>
				not ? !optionValuesArray.includes(option.value) : optionValuesArray.includes(option.value)
			),
		};
	}
	return item;
};

/**
 * Filter field items by key
 * @param {*} itemsArray Array of form items
 * @param {*} keysArray Array os strings with the item keys to filter
 */
const filterFieldsByKey = (itemsArray, keysArray, params = {}) => {
	const filteredItems = [];
	itemsArray.forEach((item) => {
		// If this is a group item recursively filter group items
		if (item.type === "group") {
			const filteredGroupItem = {
				...item,
				content: {
					items: filterFieldsByKey(item.content.items, keysArray, params),
				},
			};
			filteredItems.push(filteredGroupItem);
		}
		const included = keysArray.includes(item.key);
		if (item.key && params.not ? !included : included) {
			const newItem = params.forEach ? params.forEach(item) : item;
			filteredItems.push(newItem);
		}
	});
	return filteredItems;
};

/**
 * Update existing config
 * @param {*} items
 * @param {*} prefix
 */
const updateConfig = (items, rules) => {
	if (!rules) {
		return;
	}
	if (!Array.isArray(rules)) {
		rules = [rules];
	}

	const updatedItems = items.map((item) => {
		if (!item) return item;
		item = { ...item };

		if (["group", "menu", "toggle"].includes(item.type)) {
			if (item.content?.items?.length > 0) {
				item.content = { ...item.content };
				item.content.items = updateConfig(item.content.items, rules);
			}
		}

		if (item.type === "select") {
			item.options = item.options?.map((option) => {
				option = { ...(option ?? {}) };
				if (option.showItems?.length > 0) {
					option.showItems = updateConfig(option.showItems, rules);
				}
				return option;
			});
		}

		let removed = false;
		rules.forEach((rule) => {
			const { type, value } = rule;
			if (type === "KEY_PREFIX" && item.key) {
				item.key = value + item.key;
			} else if (type === "DISABLED") {
				item.disabled = value;
			} else if (type === "REQUIRED") {
				item.required = value;
			} else if (type === "REMOVE_FILED" && item.key === value) {
				removed = true;
			}
		});

		if (removed) return null;
		return item;
	});

	return updatedItems;
};

export { formatFields, filterFieldsByKey, filterSelectOptions, updateConfig };
