import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

import { Dialog, Checkbox, IconButton, Icon, Typography, Divider } from "@material-ui/core";

import ResourceView from "app/main/tools/ResourceView";
import FormattedTutorial from "./FormattedTutorial";
import { getResourceByKey } from "app/main/resource/resourceUtils";
import { closeTutorialDialog } from "app/store/tools/tutorialDialogSlice";

import { setTutorialLocalData } from "app/main/utils/tutorialUtils";

import { setTutorialInfo } from "app/services/flagsServices";
import { getProfileId } from "app/services/LoginService";

function TutorialDialog() {
	const dispatch = useDispatch();

	const tutorialDialog = useSelector(({ tools }) => tools.tutorialDialog.tutorialDialog ?? []);
	const tutorialId = tutorialDialog[0]?.tutorialId;
	const type = tutorialDialog[0]?.type ?? null;
	const [checkbox, setCheckbox] = useState(false);

	const resource = getResourceByKey(tutorialId);
	if (!resource?.link && !resource?.description) return null;

	const onCloseDialog = () => {
		if (type !== "permanent") {
			setTutorialLocalData(tutorialId, checkbox);
			if (checkbox) setTutorialInfo(getProfileId(), { tutorialId });
		}

		setCheckbox(false);
		dispatch(closeTutorialDialog());
	};
	const lgWidthFormats = ["VIDEO", "COMPLETE"];

	const formatTutorial = resource?.format ?? (resource?.link ? "VIDEO" : "DESCRIPTION");
	const maxWidth = lgWidthFormats.includes(formatTutorial) ? "lg" : "sm";

	return (
		<Dialog open={tutorialDialog.length > 0} fullWidth={true} maxWidth={maxWidth} disableBackdropClick>
			<div className="flex flex-col w-full relative ">
				<div className="flex flex-col px-14 py-14">
					<div className="flex flex-col items-center justify-center">
						{formatTutorial === "VIDEO" && (
							<ResourceView
								type={"video"}
								variant={"large"}
								description={resource?.description}
								link={resource?.link}
								onClose={onCloseDialog}
							/>
						)}
						{formatTutorial === "DESCRIPTION" && (
							<Typography color="textPrimary" className="text-center md:text-20 text-13">
								{resource?.description}
							</Typography>
						)}
						{formatTutorial === "COMPLETE" && <FormattedTutorial resource={resource} />}
					</div>
					{type !== "permanent" && (
						<>
							<Divider className="flex w-full h-1 mt-12 bg-grey-300" />
							<div className={"flex flex-row w-full items-center justify-end -mb-8 pr-4"}>
								<Checkbox value={checkbox} onChange={(event, value) => setCheckbox(value)} />
								<Typography className={"text-14"}>{"Don't show this again"}</Typography>
							</div>
						</>
					)}
				</div>
				<div className="flex w-full absolute justify-end items-end pt-10 pr-10">
					<IconButton aria-label="close" size="small" className="bg-white" onClick={() => onCloseDialog()}>
						<Icon className="text-24 text-grey-800">close</Icon>
					</IconButton>
				</div>
			</div>
		</Dialog>
	);
}

export default TutorialDialog;
