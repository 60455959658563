import SmarthopMenuContainer from "@smarthop/form/fields/SmarthopMenuContainer";
import ViewHistory from "app/main/tools/ViewHistory";

const HistoryView = (props) => {
	const { viewId, isViewCustom, dataIds, fromSection } = props;
	return (
		<div>
			<SmarthopMenuContainer
				key={"key"}
				label={""}
				icon={"history"}
				IconButton={true}
				button={{
					color: "inherit",
				}}
				renderOnce={true}
			>
				<ViewHistory viewId={viewId} isViewCustom={isViewCustom} dataIds={dataIds} fromSection={fromSection} />
			</SmarthopMenuContainer>
		</div>
	);
};

export default HistoryView;
