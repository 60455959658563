import { useDispatch, useSelector } from "react-redux";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { getCarrierId } from "app/services/LoginService";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { isOnboardingNotSubmitted } from "app/services/LoginService";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";

function CalculatorLoadMenuItem({ type, color }) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const hasSearchPermission = ["CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_search_on: true })
		: true;

	if (user.role === "CARRIER_DRIVER" || !hasSearchPermission) {
		return null;
	}
	if (isOnboardingNotSubmitted()) {
		return null;
	}
	const checkLoad = () =>
		dispatch(
			openLoadedFormDialog({
				viewId: "CALCULATOR_LOAD_VIEW",
				mode: "CREATE",
				dataIds: { carrierId: getCarrierId() },
			})
		);

	return type === "icon" ? (
		<Button className="mx-8 rounded-none" onClick={checkLoad}>
			<div className="flex flex-col justify-center items-center">
				<Icon style={{ color }} size="small">
					calculate
				</Icon>
				<Typography style={{ color }} className="text-10">
					PROFIT
				</Typography>
			</div>
		</Button>
	) : (
		<MenuItem onClick={checkLoad} role="button">
			<ListItemIcon className="min-w-40">
				<Icon>calculate</Icon>
			</ListItemIcon>
			<ListItemText primary="Profit" />
		</MenuItem>
	);
}

export default CalculatorLoadMenuItem;
