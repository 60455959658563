import { CircularProgress } from "@material-ui/core";
import { DisplaySection } from "app/main/utils/uiUtils";
import { getUserId, isRoleExternal } from "app/services/LoginService";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { createMarketMonitor } from "app/services/searchServices";
import { showSnackbar } from "../utils/snackbarUtil";
import { useSnackbar } from "notistack";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import TruckPlanLoadScoutResults from "./TruckPlanLoadScoutResults";

function TruckPlanSearches({ data, previewMode = false, title = "Load Scout Results" }) {
	const isExternal = useMemo(() => isRoleExternal(), []);
	const userId = useMemo(() => getUserId(), []);
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [moniterRunning, setMoniterRunning] = useState(null);

	const startMarketMonitor = async () => {
		setMoniterRunning(true);
		try {
			let result = await createMarketMonitor(userId, {
				carrierId: data?.carrier?._id,
				truckId: data?.truck?._id,
				planId: data?.plan?._id,
			});
			if (result.new) {
				showSnackbar(snackbar, "Monitor Launched", "success");
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			} else {
				showSnackbar(snackbar, "Already have existing active monitor", "warning");
			}
		} catch (err) {
			showSnackbar(snackbar, err.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
		}
		setMoniterRunning(false);
	};

	return (
		<div className="flex w-full flex-col">
			<DisplaySection
				title={title}
				classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-4" }}
				actions={
					!isExternal
						? [
								{
									label: "Run Monitor",
									onClick: () => startMarketMonitor(),
									disabled: moniterRunning,
									startIcon: moniterRunning ? <CircularProgress size={13} /> : null,
								},
						  ]
						: []
				}
			>
				<TruckPlanLoadScoutResults carrierId={data?.carrier?._id} truckId={data?.truck?._id} planId={data?.plan?._id} />
			</DisplaySection>
		</div>
	);
}

export default TruckPlanSearches;
