import Icon from "@material-ui/core/Icon";

const brokers = {
	urlGET: "api/credentials",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "ADMIN_CREDENTIALS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["client_id"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [],
		},
		items: [
			{ key: "client_id", type: "text", label: "Client ID", grid: { headerTitle: true } },
			{ key: "scopes", type: "text", label: "Scopes" },
			{ key: "broker", type: "text", label: "Broker" },
			{ builder: (item) => (item.active ? <Icon>done</Icon> : <Icon>clear</Icon>), label: "Active" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit_credential",
						label: "Edit",
						dialogBuilder: (item, dataIds) => ({
							formId: "CREDENTIAL_INFORMATION_FORM",
							mode: "EDIT",
							dataIds: { credentialId: item._id },
						}),
					},
					{
						key: "delete_credential",
						label: "Delete",
						dialogBuilder: (item, dataIds) => ({
							formId: "CREDENTIAL_INFORMATION_FORM",
							mode: "DELETE",
							dataIds: { credentialId: item._id },
						}),
					},
				],
			},
		],
	},
};

export default brokers;
