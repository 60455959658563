import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SmarthopList, internalTripsList } from "@smarthop/list";

// Utils
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

const TripsNeedDeliveredView = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Trips");
		// eslint-disable-next-line
	}, []);
	const { userId, carrierId } = dataIds;
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const subaccount = useSelector(({ auth }) => auth.account.subAccount);
	const isMainHandler = subaccount?.list?.length > 0;
	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const isInvoicingEnabled = useSelector(
		({ auth }) =>
			auth?.user?.rolesLabels?.includes("administrator") || auth?.user?.enterpriseFeatures?.invoicing_module_flag
	);

	return (
		<div>
			<SmarthopList
				key="trips"
				urlKey="trips"
				mode="TABLE"
				config={internalTripsList(
					user.rolesLabels[0],
					dispatch,
					isInvoicingEnabled,
					true,
					false,
					isMainHandler,
					hasEditTripsPermission
				)}
				dataIds={{ userId, carrierId }}
				params={{
					options_carrier_ids_search: true,
					options_trips_need_delivered: true,
				}}
			/>
		</div>
	);
};
export default TripsNeedDeliveredView;
