import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PageWrapper from "./PageWrapper";
import PageSubtitle from "./PageSubtitle";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { typeWarning, showWarning } from "app/main/utils/warningUtils";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";

import {
	readURLParameters,
	replaceURLParameters,
	convertURLParamsToModel,
	convertModelToURLParams,
} from "app/main/utils/urlUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

function PageTabWrapper(props) {
	const dispatch = useDispatch();
	const urlRevision = useSelector(({ tools }) => tools.revision?.urlRevision);

	const onTabChange = props.onTabChange;

	const [confirmationModel, setConfirmationModel] = useState(null);

	const getTabFromURL = () => {
		return convertURLParamsToModel(readURLParameters(), {})?.tab ?? 0;
	};

	// Loaded search params from urlKey
	const urlTab = useMemo(() => {
		if (props.initTab) return props.initTab;
		if (props.isView) return 0;
		const tab = getTabFromURL();
		if (!props.tabs?.[tab]) {
			replaceURLParameters(convertModelToURLParams({ tab: 0 }, {}));
			return 0;
		}
		return tab;
		// eslint-disable-next-line
	}, []);

	const user = useSelector(({ auth }) => auth.user);

	const [selectedTab, setSelectedTab] = useState(urlTab ?? 0);
	const [visibleTab, setVisibleTab] = useState(urlTab ?? 0);
	const [mobile, setMobile] = useState(window.innerWidth < 960);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});
	const handleTabChange = (_, value) => {
		if (!props.isView) {
			replaceURLParameters(convertModelToURLParams({ tab: value }, {}));
		}
		setSelectedTab(value);
		if (onTabChange) onTabChange(value);
		setTimeout(() => {
			setVisibleTab(value);
		}, 350);
	};

	const firstRun = useRef(false);
	useEffect(() => {
		if (!firstRun.current) {
			firstRun.current = true;
			return;
		}

		handleTabChange(null, getTabFromURL());
		// eslint-disable-next-line
	}, [urlRevision]);

	const tabs = useMemo(
		() =>
			props.tabs?.map((tab, i) => {
				let resourceInformation;
				if (tab?.resource?.icon) {
					resourceInformation = getResourceByKey(tab?.resource?.icon);
				}
				return (
					<Tab
						key={i}
						className="text-13 font-semibold min-h-36 min-w-92 mx-4 px-20 items-center"
						disableRipple
						label={
							<div className="flex">
								<span>{tab.label ?? " - "}</span>
								{tab.labelView}
								{resourceInformation && (
									<ResourceView
										type={"icon"}
										description={resourceInformation?.description}
										link={resourceInformation?.link}
									/>
								)}
							</div>
						}
					/>
				);
			}),
		[props.tabs]
	);

	useEffect(() => {
		const showAction = convertURLParamsToModel(readURLParameters(), {})?.show_action;
		if (showAction) {
			const openActionByDefault = props.tabs?.[visibleTab >= tabs.length ? 0 : visibleTab]?.actions?.find(
				(i) => i?.label === showAction
			);

			if (openActionByDefault?.dialog) {
				dispatch(
					openLoadedFormDialog({
						viewId: openActionByDefault.dialog.viewId,
						formInstance: openActionByDefault.dialog.form,
						formId: openActionByDefault.dialog.formId,
						mode: openActionByDefault.dialog.mode,
						dataIds: openActionByDefault.dialog.dataIds ?? {},
					})
				);
			} else if (openActionByDefault?.onClick) {
				openActionByDefault.onClick();
			}
		}
	}, [dispatch, props.action, props.tabs, tabs, visibleTab]);

	if (!mobile && props.variant === "mobile-only") {
		const components = props.tabs?.map((tab, i) => {
			return (
				<div key={i} className="flex flex-col w-full pb-40">
					<PageSubtitle label={tab.label} actions={tab.actions} />
					{tab.component}
				</div>
			);
		});
		return (
			<PageWrapper {...props}>
				{components}
				{props.children}
			</PageWrapper>
		);
	}

	let wrapperProps = { ...props };
	delete wrapperProps.tabs;
	delete wrapperProps.restrictions;

	let toolbar =
		tabs.length === 1 ? null : (
			<div className="flex flex-1 w-full whitespace-nowrap max-h-36">
				<Tabs
					value={selectedTab >= tabs.length ? 0 : selectedTab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="inherit"
					variant="scrollable"
					scrollButtons="off"
					className="w-full min-h-36"
					classes={{ indicator: "flex justify-center bg-transparent w-full h-full" }}
					TabIndicatorProps={{
						children: <Divider className="w-full h-full rounded-full opacity-50" />,
						style: { transitionDuration: "200ms" },
					}}
				>
					{tabs}
				</Tabs>
			</div>
		);

	const { resource, restrictions } = props;
	let warning = typeWarning(restrictions, user, props.requiredGateKeepers);
	if (warning) return showWarning(warning, props.title, props.classes, restrictions, resource?.onboarding);

	const titleActions = [
		...(props.tabs?.[visibleTab >= tabs.length ? 0 : visibleTab]?.actions ?? []),
		...(props.titleActions ?? []),
	];

	const titleView = props.titleView;

	const confirmDialog = (
		<SmarthopConfirmDialog
			open={!!confirmationModel?.message}
			title={confirmationModel?.message}
			checkboxLabel={confirmationModel?.checkboxLabel}
			checkboxValue={confirmationModel?.checkboxValue}
			setCheckboxValue={(value) => {
				typeof confirmationModel?.setCheckboxValue === "function" && confirmationModel?.setCheckboxValue(value);
			}}
			onClose={() => {
				typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
				setConfirmationModel(null);
			}}
			onAccept={() => {
				confirmationModel?.onAccept();
				setConfirmationModel(null);
			}}
		/>
	);

	const toolbarComponent = props.tabs?.[visibleTab >= tabs.length ? 0 : visibleTab]?.component;

	return (
		<PageWrapper {...wrapperProps} titleView={titleView} titleActions={titleActions} toolbarChildren={toolbar}>
			{toolbarComponent}
			{props.children}
			{confirmDialog}
		</PageWrapper>
	);
}

export default PageTabWrapper;
