import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import { capitalizaEveryWordFirstLetter } from "helpers/Formatter";
import { formatDate } from "app/main/utils/dateUtils";
import moment from "moment";

const getWebOrMobile = (item) => {
	const lastWeb = item?.lastLogin?.web?.loginDate;
	const lastMobile = item?.lastLogin?.mobile?.loginDate;

	if (!lastWeb && !lastMobile) return false;
	if (lastWeb && lastMobile) return moment(lastWeb).isAfter(moment(lastMobile)) ? "web" : "mobile";
	if (!lastWeb) return "mobile";
	return "web";
};

const formatAddress = (item, key) => {
	const data = item?.lastLogin?.[key];
	if (!data?.country) {
		return "-";
	}

	return `${data.city}, ${data.state}, ${data.country}`;
};

// Constans
const createUsersLinkedAccountsList = ({ isAdmin, enableSwitch, noHeader, noBottomBorder, viewerUserId }) => {
	return {
		urlGET: "api/profile/users/:userId/accounts",
		idKey: "_id",
		listenEvent: ["profileRevision", "onboardingRevision"],
		segmentKey: "INTERNAL_USERS",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 1,
				mobile: {
					tilesPerRow: 1,
					itemsPerRow: 2,
					layout: [["description"]],
				},
			},
			table: {
				variant: "skinny",
				theme: "dark",
				noHeader: noHeader,
				noBottomBorder: noBottomBorder,
			},
			items: [
				{
					key: "icon",
					type: "component",
					label: "Account",
					table: { align: "right" },
					builder: (item) => {
						return (
							<Avatar src={null} alt={""} className={"mr-10 " + (!item.carrier ? " bg-indigo-900 " : "")}>
								{!item.carrier ? (
									<img className="pl-2 pr-5 w-44" src="assets/images/logos/smarthop.png" alt="logo" />
								) : item.user.role === "CARRIER_OWNER" ? (
									<Icon className="text-20">local_shipping</Icon>
								) : item.user.role === "CARRIER_DRIVER" ? (
									<Icon className="text-20">airline_seat_recline_normal</Icon>
								) : item.user.role === "CARRIER_DISPATCHER" ? (
									<Icon className="text-20">support_agent</Icon>
								) : (
									<Icon className="text-20">account_circle</Icon>
								)}
							</Avatar>
						);
					},
				},
				{
					key: "description",
					type: "component",
					table: { width: noHeader ? "100%" : "80%" },
					builder: (item) => {
						return (
							<div className="flex flex-col pt-2">
								{item.carrier ? (
									<div className="flex flex-row whitespace-nowrap">
										<Typography className="text-13 max-w-180 truncate">
											{item.carrier?.carrier_company_name ?? "New Account"}
										</Typography>
										{item.carrier?.carrier_mc_number && (
											<>
												<Typography className="text-13 text-grey px-6">|</Typography>
												<Typography className="text-13 max-w-92 truncate">
													MC {item.carrier?.carrier_mc_number}
												</Typography>
												{item.carrier?.onboardingStatus && item.carrier?.onboardingStatus !== "ACCEPTED" && (
													<>
														<Typography className="text-13 text-grey px-6">|</Typography>
														<Typography className="text-13 font-medium text-orange-700 max-w-92 truncate">
															{capitalizaEveryWordFirstLetter(
																item.carrier?.onboardingStatus === "INCOMPLETE"
																	? "ONBOARDING"
																	: item.carrier?.onboardingStatus
															)}
														</Typography>
													</>
												)}
											</>
										)}
									</div>
								) : (
									<Typography className="text-13">Smarthop Internal Account {item.user.role}</Typography>
								)}
								<div className="flex flex-row whitespace-nowrap">
									<>
										<Typography className="text-12 font-medium text-grey -mt-2">
											{capitalizaEveryWordFirstLetter(item.user?.role ?? "UNKNWON_ROLE")}
										</Typography>
										{!!item.user && !item.user.accessAt && (
											<Typography className="text-10 font-bold text-green ml-5">NEW ACCOUNT</Typography>
										)}
										{item.carrier?.accountStatus === "DEACTIVATED" && (
											<Typography className="text-10 font-bold text-red ml-5">INACTIVE</Typography>
										)}
									</>
								</div>
							</div>
						);
					},
				},
				...(noHeader
					? []
					: [
							{
								key: "type",
								type: "text",
								label: "Access Type",
								hidden: !isAdmin,
								builder: (item) => {
									const deviceKey = getWebOrMobile(item.user);
									if (!deviceKey) return "N/A";
									return deviceKey === "web" ? "WEB" : "MOBILE";
								},
							},
							{
								key: "accessAt",
								type: "text",
								label: "Last Access",
								builder: (item) =>
									item.user._id === viewerUserId
										? "Online Now"
										: item.user?.accessAt
										? formatDate(item.user?.accessAt)
										: "-",
							},
							{
								key: "location",
								type: "text",
								label: "Location",
								table: { width: "25%" },
								hidden: !isAdmin,
								builder: (item) => {
									const deviceKey = getWebOrMobile(item.user);
									if (!deviceKey) return "N/A";
									return formatAddress(item.user, deviceKey);
								},
							},
					  ]),
				...(enableSwitch
					? [
							{
								key: "menu",
								type: "menu",
								label: "",
								items: [
									{
										isVisible: (item) =>
											item.user._id !== viewerUserId && item.carrier?.accountStatus !== "DEACTIVATED",
										key: "socket",
										label: "Switch",
										dialogBuilder: (item) => ({
											formId: "USER_SWITCH_ACCOUNT_FORM",
											mode: "CREATE",
											dataIds: { userId: item.user._id },
										}),
										variant: "button",
										button: {
											icon: "account_tree",
											color: "primary",
											variant: "outlined",
										},
									},
								],
							},
					  ]
					: []),
			],
		},
	};
};

export default createUsersLinkedAccountsList;
