import { useState, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";

// UI Library
import Typography from "@material-ui/core/Typography";

// Utils
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { EMPTY_LOCATION, EMPTY_MILES, EMPTY_LOCATION_ZIP_CODE } from "@smarthop/form/configs/tripDetailsContent";

// Services
import { getTrip, getTripLocationsMiles } from "app/services/tripsServices";

const TripEmptyLocationDialog = (props, ref) => {
	const snackbar = useSnackbar();

	const onCancel = props.onCancel;
	const onSubmit = props.onSubmit;
	const carrierId = props.carrierId;
	const tripId = props.tripId;
	const truckId = props.truckId;

	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);

	const [tripData, setTripData] = useState(null);
	const [updatedData, setUpdatedData] = useState(null);
	const [formData, setFormData] = useState(
		props.currentLocation
			? {
					current_location: props.currentLocation,
					current_location__view: { value: props.currentLocation, label: props.currentLocation },
			  }
			: {}
	);

	const canCalculateRRM = useMemo(() => tripData?.rate > 0, [tripData]);
	const canCalculateMiles = useMemo(
		() =>
			!!tripData?.pickup_address?.length &&
			!tripData?.locations?.find((item) => !item.location)?.length &&
			!!tripData?.delivery_address?.length,
		[tripData]
	);

	useEffect(() => {
		if (!tripId || !carrierId) {
			setError("Invalid input");
			return;
		}
		(async () => {
			try {
				const data = await getTrip(tripId, carrierId);
				setTripData(data);
			} catch (err) {
				setError(err?.errors?.[0]?.message ?? err.message ?? "Failed to load trip details...");
			}
		})();

		// eslint-disable-next-line
	}, [tripId, carrierId]);

	useEffect(() => {
		if (!formData || !tripData) {
			return;
		}
		if (!formData.current_location || !canCalculateMiles) {
			setUpdatedData({});
			return;
		}
		if (formData.current_location === updatedData?.current_location) {
			return;
		}

		(async () => {
			try {
				const locations = {
					empty: formData.current_location,
					pickup: tripData.pickup_address,
					stops: tripData.locations.map((item) => item.location ?? null),
					delivery: tripData.delivery_address,
				};

				setProcessing(true);
				const changed = formData.current_location;
				const calculated = await getTripLocationsMiles(locations, changed, truckId, carrierId, tripData.weight, tripId);

				const rpm =
					tripData.rate > 0 && Number(calculated.loaded) > 0
						? (Number(tripData.rate) / (Number(calculated.loaded) + Number(calculated.empty ?? 0))).toFixed(2)
						: null;

				setProcessing(false);
				setUpdatedData({
					current_location: changed,
					miles: calculated.loaded,
					emptymiles: calculated.empty,
					rpm: rpm ? parseFloat(rpm) : undefined,
				});
				setFormData({
					...formData,
					emptymiles: calculated.empty,
					miles: calculated.loaded,
					zip_code_current_location: calculated.changedAddressZip ?? undefined,
				});
			} catch (err) {
				console.log("[TripEmptyLocationDialog] failed to calculate", err);
				setProcessing(false);
				setError(err?.errors?.[0]?.message ?? err.message ?? "Failed to calculate trip miles...");
			}
		})();

		// eslint-disable-next-line
	}, [formData.current_location, tripData]);

	useEffect(() => {
		if (!formData || !tripData || !updatedData?.miles || formData?.emptymiles === updatedData?.emptymiles) {
			return;
		}
		if (!canCalculateRRM) {
			setUpdatedData({ ...updatedData, emptymiles: formData.emptymiles });
			return;
		}

		const rpm = (Number(tripData.rate) / (Number(updatedData.miles) + Number(formData.emptymiles ?? 0))).toFixed(2);
		setUpdatedData({ ...updatedData, emptymiles: formData.emptymiles, rpm: rpm ? parseFloat(rpm) : undefined });

		// eslint-disable-next-line
	}, [formData?.emptymiles, tripData?.rate]);

	const handeSubmit = () => {
		if (processing || !tripData) {
			return;
		}
		if (processing || !tripData || !formData?.current_location) {
			showSnackbar(snackbar, "Please provide empty location...", "warning");
			return;
		}

		const result = { ...updatedData, ...formData };
		onSubmit(result);
	};

	let content;
	if (error) {
		content = (
			<div className="flex min-w-480 min-h-240 items-center justify-center mt-64">
				<Typography color="error" className="flex pt-10 pb-8 items-center justify-center">
					{error}
				</Typography>
			</div>
		);
	} else if (!tripData || !updatedData) {
		content = (
			<div className="flex min-w-480 min-h-240 items-center justify-center opacity-60 mt-24">
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
					{!tripData ? "Loading..." : "Calculating Miles..."}
				</Typography>
			</div>
		);
	} else {
		content = (
			<div className={"flex flex-col min-w-480 min-h-240 " + (processing ? " pointer-events-none  opacity-60 " : "")}>
				<div className={"flex w-full flex-row border-b-1 mb-10 pt-6"}>
					<Typography className="text-12 uppercase text-grey font-bold">Truck Location</Typography>
				</div>
				<SmarthopFormView
					content={{
						form: { noErrorMessage: true },
						items: [
							EMPTY_LOCATION(tripId, true),
							{ type: "group", content: { items: canCalculateMiles ? [EMPTY_MILES, EMPTY_LOCATION_ZIP_CODE] : [] } },
						],
					}}
					data={formData}
					dataIds={{ tripId, carrierId, exclude_trip_id: tripId }}
					trackChangedFields={["ALL"]}
					onChangeCommitted={(model, _, value) => {
						setFormData(model, value);
					}}
				/>
				<div className={"flex w-full flex-row border-b-1 mb-10 pt-12 -mt-6"}>
					<Typography className="text-12 uppercase text-grey font-bold">Trip Route</Typography>
				</div>
				<div className={"flex w-full mx-2 flex-row mb-8"}>
					<Typography className="min-w-68 text-13 font-bold">Pickup</Typography>
					<Typography noWrap className="text-13">
						{tripData.pickup_address ?? "Unknown"}
					</Typography>
				</div>
				{tripData.locations.map((item, index) => (
					<div className={"flex w-full mx-2 flex-row mb-8"}>
						<Typography className="min-w-68 text-13 font-bold">Stop #{index + 1}</Typography>
						<Typography noWrap className="text-13">
							{item.location ?? "Unknown"}
						</Typography>
					</div>
				))}
				<div className={"flex w-full mx-2 flex-row mb-20"}>
					<Typography className="min-w-68 text-13 font-bold">Delivery</Typography>
					<Typography noWrap className="text-13">
						{tripData.delivery_address ?? "Unknown"}
					</Typography>
				</div>
				<div className={"flex w-full flex-row border-b-1 mb-10 pt-4"}>
					<Typography className="text-12 uppercase text-grey font-bold">Trip Updates</Typography>
				</div>
				{canCalculateMiles ? (
					<>
						<div className={"flex w-full mx-2 flex-row"}>
							<div className={"flex w-full flex-row"}>
								<Typography className="min-w-68 text-13 font-bold">Empty</Typography>
								<Typography className="text-13">{updatedData?.emptymiles ?? "-"} miles</Typography>
							</div>
							{canCalculateRRM && (
								<div className={"flex w-full flex-row"}>
									<Typography className="min-w-44 text-13 font-bold">Rate</Typography>
									<Typography className="text-13">${tripData?.rate ?? "-"}</Typography>
								</div>
							)}
						</div>
						<div className={"flex w-full flex-row mx-2 mt-8 mb-20"}>
							<div className={"flex w-full flex-row"}>
								<Typography className="min-w-68 text-13 font-bold">Loaded</Typography>
								<Typography className="text-13">{updatedData?.miles ?? "-"} miles</Typography>
							</div>
							{canCalculateRRM && (
								<div className={"flex w-full flex-row"}>
									<Typography className="min-w-44 text-13 font-bold">RPM</Typography>
									<Typography className="text-13">${updatedData?.rpm ?? "-"}</Typography>
								</div>
							)}
						</div>
					</>
				) : (
					<div className={"flex w-full mx-2 flex-row"}>
						<Typography className="text-13 mb-16">
							* Unfortunately, some of required fields are missing, and we are unable to calculate empty and loaded
							miles, please edit them directly in the trip
						</Typography>
					</div>
				)}
			</div>
		);
	}

	return (
		<SmarthopConfirmDialog
			open={true}
			handleClose={!error && (!tripData || !updatedData) ? null : onCancel}
			handleAccept={error || !tripData || !updatedData ? null : () => handeSubmit()}
		>
			{content}
		</SmarthopConfirmDialog>
	);
};

export default TripEmptyLocationDialog;
