import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

const FlexPaths = ({ enableFlexPaths }) => (
	<>
		<h3>All paths are flex</h3>
		<Box sx={{ py: 2 }}>
			<Typography>
				This strategy only contains flex paths, which are paths that do not fully match the criteria with which the
				strategy was created. To view the flex paths open the configuration in the subpanel
				<Icon color="secondary">tune</Icon> and toggle the <Icon>visibility_off</Icon> icon to activate it.
			</Typography>
		</Box>
		<Button
			variant="outlined"
			color="inherit"
			startIcon={<Icon>visibility</Icon>}
			onClick={enableFlexPaths}
			sx={{ my: 2 }}
		>
			View Flex Paths
		</Button>
	</>
);

export default FlexPaths;
