const planPromos = {
	urlGET: "billing/plan-promos",
	idKey: "_id",
	listenEvent: "planRevision",
	segmentKey: "ADMIN_PLATFORM_PLANS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["nickname", "value", "createdAt", "overridePriority"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "overridePriority",
				dir: "desc",
			},
		},
		filters: {
			search: true,
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{ key: "createdAt", type: "date", label: "Creation Date" },
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{
				key: "editor__view",
				type: "user__view",
				label: "Editor",
				viewConfig: {
					showRole: true,
				},
			},
			{
				key: "disabled",
				type: "checkbox",
				label: "Status",
				builder: (item) => (item.disabled ? "Disabled" : "Active"),
			},
			{
				label: "Carriers",
				key: "carrier__view",
				type: "carrier__view",
				builder: (item) => item.carriers?.map((item) => item.carrier__view),
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				key: "emails",
				label: "Emails",
				builder: (item) => item.emails?.map((item) => item.email)?.join("\n"),
				type: "text",
				collapsibleLength: 12,
			},
			{ key: "service", type: "text", label: "Service" },
			{
				key: "plan__view",
				type: "group",
				label: "Plan",
				items: [
					{ key: "plan__view.label", type: "text", label: "Plan", builder: (item) => item.plan__view?.label },
					{
						key: "plan__view.description",
						type: "text",
						label: "Plan",
						builder: (item) => item.plan__view?.description,
					},
				],
			},
			{
				key: "visiblity",
				type: "group",
				label: "Expiration",
				items: [
					{ key: "expirationType", type: "text", label: "Expiration Type" },
					{ key: "expirationDate", type: "date", label: "Expires On" },
				],
			},
			{
				key: "availability",
				type: "group",
				label: "Availability",
				items: [
					{ key: "availabilityType", type: "text", label: "Availability" },
					{
						key: "availabilityParams",
						type: "text",
						label: "Availability Params",
						builder: (item) =>
							item.availabilityAccountsCount > 0 || item.availabilityAccountsCount === 0
								? "Min " + item.availabilityAccountsCount + " Accounts"
								: null,
					},
				],
			},
			{
				key: "overridePriority",
				type: "group",
				label: "Override",
				items: [
					{ key: "overrideType", type: "text", label: "Override Type" },
					{
						key: "overrideParams",
						type: "text",
						label: "Override Params",
						builder: (item) =>
							item.overridePriority > 0 || item.overridePriority === 0 ? "Priority " + item.overridePriority : null,
					},
				],
			},
			{ key: "description", type: "text", label: "Description", collapsibleLength: 12 },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						dialogBuilder: (item) => ({
							formId: "ADMIN_PLAN_PROMO_FORM",
							mode: "EDIT",
							dataIds: { id: item._id },
						}),
					},
					{
						key: "delete",
						label: "Delete",
						dialogBuilder: (item) => ({
							formId: "ADMIN_PLAN_PROMO_FORM",
							mode: "DELETE",
							dataIds: { id: item._id },
						}),
					},
				],
			},
		],
	},
};

export default planPromos;
