import { makeStyles } from "@material-ui/core";
import { useMemo, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import Icon from "@material-ui/core/Icon";

import { isEnabled } from "app/services/featureStorageService";

import { createBrokerMessageTitle } from "app/main/utils/brokerUtils";
import { formatCurrency } from "app/main/utils/tableUtils";

import LoadSearchViewLocationsView from "app/main/search/result/LoadSearchViewLocationsView";

const useStyles = makeStyles((theme) => {
	return {
		leftBorder: {
			borderLeftWidth: 2,
			borderLeftColor: "#BDBDBD",
			borderStyle: "solid",
			paddingLeft: 5,
		},
	};
});

const SmarthopChatMessageViewContainer = ({
	nativeMobile,
	headerConfig,
	contentFields,
	contentComponents,
	messageActions,
	footerComponents,
	variant,
	enableLeftBorder,
}) => {
	const isNewMessagesUIEnabled = isEnabled("CHAT_NEW_UI_MESSAGES");
	const classes = useStyles();

	const [smallScreen, setSmallScreen] = useState(nativeMobile ? true : nativeMobile || window.innerWidth <= 960);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 50) * 50);
	const isNotification = variant === "notification";

	const borderStyle = isNewMessagesUIEnabled && enableLeftBorder && !isNotification ? classes.leftBorder : "";

	// handle screen size
	useEffect(() => {
		if (nativeMobile) return;

		function handleResize() {
			if (window.innerWidth <= 960 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth > 960 && smallScreen) {
				setSmallScreen(false);
			}
			if (parseInt(window.innerHeight / 50) * 50 !== screenHeight) {
				setScreenHeight(parseInt(window.innerHeight / 50) * 50);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const headerView = useMemo(() => {
		const headerTitle = headerConfig?.title;
		const message = headerConfig?.subtitle;
		const badgeTitle = headerConfig?.badge?.title;
		const badgeColor = headerConfig?.badge?.color ? headerConfig?.badge.color : "bg-blue";
		let titleView;

		if (headerConfig?.type === "BROKER") {
			if (!headerConfig.hasOwnProperty("data"))
				throw new Error(`[SmarthopChatMessageViewContainer] Header type BROKER requires a data object`);
			const brokerData = headerConfig.data;
			titleView = brokerData.clientid
				? createBrokerMessageTitle(brokerData.mcnumber, brokerData.clientid, brokerData.owner)
				: headerTitle;
		} else {
			titleView = <Typography className={"flex flex-1 text-14 md:text-16 font-medium"}>{headerTitle}</Typography>;
		}

		return (
			<div className="flex flex-col">
				<div className="flex flex-row flex-start items-start">
					{titleView}
					{badgeTitle && (
						<Typography
							className={`flex font-semibold rounded-full truncate h-24 py-2 px-12 -mt-3 text-white text-12 md:text-13 ml-10 ${badgeColor}`}
						>
							{badgeTitle}
						</Typography>
					)}
				</div>
				<div className="flex">
					{message && (
						<Typography
							className={`font-normal text-12 md:text-13 mt-8 ${headerConfig?.showDivider ? "border-b-1 pb-5" : ""}`}
						>
							{message}
						</Typography>
					)}
				</div>
			</div>
		);
	}, [headerConfig]);

	const contentView = useMemo(() => {
		let contentViews = [];

		contentFields?.forEach((field) => {
			const showField = field.condition === undefined ? true : !!field.condition;

			if (showField && (!isNotification || (isNotification && !field.hideOnNotification))) {
				const helpTooltip = field.description ? (
					<TooltipClickAwayView title={field.description} classes={{ tooltip: "drop-shadow-md " }}>
						<Icon className={"text-16 pt-1 text-blue"}>info</Icon>
					</TooltipClickAwayView>
				) : null;
				let fieldView, dividerView;
				let tooltipAlignment = "items-end";

				if (field.type === "TEXT") {
					if (!field.hasOwnProperty("value"))
						throw new Error(`[SmarthopChatMessageViewContainer] Content type TEXT field requires a value`);
					fieldView = (
						<Typography className={`${field.classes?.text ? field.classes.text : "text-12 md:text-13"} pr-5`}>
							{field.value}
						</Typography>
					);
				} else if (field.type === "LABEL_VALUE") {
					if (!field.hasOwnProperty("label"))
						throw new Error(`[SmarthopChatMessageViewContainer] Content type LABEL_VALUE field requires a label`);
					if (!field.hasOwnProperty("value"))
						throw new Error(`[SmarthopChatMessageViewContainer] Content type LABEL_VALUE field requires a value`);

					fieldView = (
						<div className="flex flex-row py-5">
							<Typography
								className={`${
									field.classes?.label ? field.classes.label : "text-12 md:text-13 font-semibold text-gray-900"
								} pr-5`}
							>
								{field.label}
							</Typography>
							<Typography className={`${field.classes?.value ? field.classes.value : "text-12 md:text-13"} pr-5`}>
								{field.value ?? "-"}
							</Typography>
						</div>
					);
				} else if (field.type === "DIVIDER") {
					if (field.label)
						fieldView = (
							<Typography className={"text-12 md:text-13 pr-5 font-semibold text-gray-900 mt-12"}>
								{field.label}
							</Typography>
						);
					dividerView = <Divider className={`${!field.label ? "mt-4" : ""} mb-6 bg-grey-400`} />;
				} else if (field.type === "BANNER") {
					if (!field.hasOwnProperty("message"))
						throw new Error(`[SmarthopChatMessageViewContainer] Content type BANNER field requires a message`);
					const bgColor = field.color ? "bg-" + field.color : "bg-blue";
					fieldView = (
						<div className={"flex flex-row flex-shrink-0 rounded-12 min-h-32 items-center mt-7 mb-7 " + bgColor}>
							{field.icon && (
								<Icon className={"text-24 text-white ml-14 "} color="action">
									{field.icon}
								</Icon>
							)}
							<Typography className={"font-normal p-12 text-12 md:text-13 text-white"}>{field.message}</Typography>
						</div>
					);
				} else if (field.type === "CUSTOM") {
					tooltipAlignment = "items-center";
					if (!field.hasOwnProperty("component"))
						throw new Error(`[SmarthopChatMessageViewContainer] Content type CUSTOM field requires a component`);
					if (field.label) {
						fieldView = (
							<div className="flex flex-row items-center">
								<Typography className={"text-12 md:text-13 pr-5 font-semibold text-gray-900"}>{field.label}</Typography>
								{field.component}
							</div>
						);
					} else {
						fieldView = field.component;
					}
				} else if (field.type === "TRIP") {
					let load = Object.assign({}, field.data);
					const options = field.options;
					//Workaround for backward compatibility
					if (!load.miles && load.totalMiles) load.miles = load.totalMiles;

					fieldView = (
						<div className="flex flex-col">
							{(load.load_id || load.loadId) && (
								<div className="flex py-5">
									<Typography className={"text-12 md:text-13 font-semibold pl-14 pr-14"}>#</Typography>
									<Typography className={"text-12 md:text-13 font-semibold ml-10"}>{`${
										load.load_id ?? load.loadId
									}`}</Typography>
								</div>
							)}
							{!options.hideRate && (
								<div className="flex py-5">
									<Icon className={"text-20 font-500 ml-10 mr-10"}>{"attach_money"}</Icon>
									<Typography className={"text-12 md:text-13 font-semibold ml-10"}>{`${
										load?.price && !isNaN(parseFloat(load?.price)) ? `${formatCurrency(load?.price)}` : "-"
									}`}</Typography>
								</div>
							)}
							{!options.hideTruck && (
								<div className="flex py-5">
									<Icon className={"text-20 font-500 ml-10 mr-10"}>{"local_shipping"}</Icon>
									<Typography className={"text-12 md:text-13  ml-10"}>{`${
										load.truckEquipment ? load.truckEquipment : ""
									} ${load.truckid ? "#" + load.truckid : "No truck"} - ${
										load.driverName ? load.driverName : "No Driver"
									}`}</Typography>
								</div>
							)}
							<div className="mb-20">
								<LoadSearchViewLocationsView load={load} hideEdit={true} showAllStops={false} hideOrigin={true} />
							</div>
						</div>
					);
				} else {
					throw new Error(`[SmarthopChatMessageViewContainer] Wrong field type for content field ${field.type}`);
				}
				if (helpTooltip) {
					contentViews.push(
						<div className={`flex flex-row ${tooltipAlignment}`}>
							{fieldView}
							{helpTooltip}
						</div>
					);
				} else {
					contentViews.push(fieldView);
				}
				if (dividerView) contentViews.push(dividerView);
			}
		});

		return contentViews;
	}, [contentFields, isNotification]);

	const { desktopActionViews, mobileActionViews } = useMemo(() => {
		let desktopActionViews = [];
		let mobileActionViews = [];

		messageActions?.forEach((config) => {
			const { hideOnMobile, hideOnDesktop, condition } = config;
			let createButton = ({ color, onClick, className, title }) => {
				let button = (
					<div className={"flex flex-col items-start"}>
						<Button
							variant="contained"
							color={color ?? "secondary"}
							className={"mx-auto mt-5 text-white " + className}
							onClick={onClick}
						>
							{title}
						</Button>
					</div>
				);
				return button;
			};
			const showButton = condition === undefined ? true : !!condition;
			if (showButton && !hideOnDesktop) {
				desktopActionViews.push(createButton(config));
			}
			if (showButton && !hideOnMobile) {
				mobileActionViews.push(createButton(config));
			}
		});

		return { desktopActionViews, mobileActionViews };
	}, [messageActions]);

	return (
		<div className={`py-5 ${borderStyle}`}>
			{!isNewMessagesUIEnabled && contentComponents}
			{isNewMessagesUIEnabled && (
				<div className={`flex flex-col text-12 md:text-13 `}>
					<div
						className={`flex flex-col w-full  text-12 md:text-13 ${borderStyle ? "px-4" : ""}
        `}
					>
						{headerView}
						{contentView && <div className="py-10">{contentView}</div>}
						{!smallScreen ? (
							desktopActionViews?.length && !isNotification ? (
								<div className={"flex items-start w-full pt-4 flex-col space-y-4"}>{desktopActionViews}</div>
							) : null
						) : mobileActionViews?.length && !isNotification ? (
							<div className={"flex items-start w-full pt-4 flex-col space-y-4 "}>{mobileActionViews}</div>
						) : null}
						{footerComponents}
					</div>
				</div>
			)}
		</div>
	);
};

export default SmarthopChatMessageViewContainer;
