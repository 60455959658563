import PageWarning from "../common/PageWarning";
import {
	getUserTier,
	isActiveCarrierStatus,
	isDeactivatedCarrierStatus,
	isOnboardingNotSubmitted,
	isRoleExternal,
} from "app/services/LoginService";
import { hasRequiredGateKeepers, processTierRestrictions, tierList } from "./rolesUtils";

const typeWarning = (restrictions, user, requiredGateKeepers = {}) => {
	const isExternalUser = isRoleExternal();
	const accountActive = isActiveCarrierStatus();
	const accountDeactivated = isDeactivatedCarrierStatus();
	const onboardingNotSubmitted = isOnboardingNotSubmitted();
	const phoneConfirmed = user?.phone_confirmed;
	const emailCorfirmed = user?.email_confirmed;
	const isMobile = window.ismobile() ?? false;
	const tierType = getUserTier();

	if (restrictions?.length > 0 && accountDeactivated) {
		// In case screen has any restriction and account got suspened, restrict access to this screen
		return "ACTIVATION";
	}

	if (
		(restrictions?.includes("ONBOARDING") ||
			restrictions?.includes("CONFIRMATION") ||
			restrictions?.includes("ACTIVATION")) &&
		onboardingNotSubmitted
	) {
		return "ONBOARDING";
	}

	if (restrictions?.includes("CONFIRMATION") && (!phoneConfirmed || !emailCorfirmed)) {
		return "CONFIRMATION";
	}

	if (restrictions?.includes("ACTIVATION") && !accountActive) {
		return "ACTIVATION";
	}

	if (restrictions?.includes("ACCESS_DENIED") || !hasRequiredGateKeepers(user, requiredGateKeepers)) {
		return "ACCESS_DENIED";
	}

	const hasTierRestriction = tierList.some(
		(rest) => restrictions?.includes(rest) || restrictions?.includes("ONLY_" + rest)
	);
	if (!isMobile && hasTierRestriction && isExternalUser && !processTierRestrictions({ restrictions, tier: tierType })) {
		return !!tierType ? "UPDATE_PLAN" : "SUBSCRIBE_PLAN";
	}

	if (restrictions?.includes("PAYROLL_ACTIVATION")) {
		return "PAYROLL_ACTIVATION";
	}

	if (restrictions?.includes("USER_PAYROLL_PERMISSION") && !hasRequiredGateKeepers(user, requiredGateKeepers)) {
		return "USER_PAYROLL_PERMISSION";
	}
};

const showWarning = (type, title, classes, restrictions, resource) => {
	return <PageWarning title={title} classes={classes} type={type} restrictions={restrictions} resource={resource} />;
};

export { typeWarning, showWarning };
