import { useState, useEffect } from "react";
import YouTubePlayer from "../video/YouTubePlayer";
import { Typography } from "@material-ui/core";

const VIDEO_REGEX = /(?:v=|\/)([0-9A-Za-z_-]{11}.*)/;

const FormattedTutorial = ({ resource }) => {
	const videoIdMatch = resource?.video?.match(VIDEO_REGEX);
	const videoId = videoIdMatch?.[1];
	const image = resource?.image;

	const showSidebarOnly = !videoId && !image;

	const [mobile, setMobile] = useState(window.innerWidth < 960);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<div className="px-6 flex flex-col w-full">
			{resource?.title && (
				<Typography className="font-semibold tracking-tight text-22 pb-12">{resource?.title}</Typography>
			)}
			{resource?.subtitle && <Typography className="py-12 text-18">{resource?.subtitle}</Typography>}
			<div className="flex flex-col md:flex-row w-full">
				{(videoId || image) && (
					<div className="flex flex-col flex-grow w-full mx-0 md:mx-6" style={{ maxHeight: "65vh" }}>
						{videoId ? (
							<YouTubePlayer
								videoId={videoId}
								mobile={mobile}
								customStyles={{ display: "flex", justifyContent: "center" }}
							/>
						) : image ? (
							<img style={{ width: "100%", height: "auto", objectFit: "cover" }} src={image} alt="Invoice" />
						) : null}
					</div>
				)}
				{resource?.sidebar && (
					<div
						className={`flex overflow-y-auto bg-grey-200 mx-0 mt-9 w-full md:mx-6 md:mt-0 ${
							showSidebarOnly ? "" : "md:w-1/2"
						}`}
						style={{ maxHeight: "65vh" }}
					>
						{resource?.sidebar}
					</div>
				)}
			</div>
			<Typography className="mt-16 text-14 text-center md:text-justify">{resource?.description}</Typography>
		</div>
	);
};

export default FormattedTutorial;
