// Dependencies
import { useState, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from "react";
import { CircularProgress, Typography, CardMedia } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import clsx from "clsx";

// Components
import { SmarthopConfirmDialog } from "@smarthop/form";
import TripInvoicingDetails from "./TripInvoicingDetails";

// Services
import { getInvoiceForTrip, updateInvoice, updateInvoiceStatus } from "app/services/tripInvoicingServices";
import { generateTripInvoice } from "app/services/tripInvoicingServices";
import { getRoleLabel, isCarrier } from "app/services/LoginService";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";

// Tools and Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { downloadFile } from "app/main/utils/fileUtils";
import { refreshDialog } from "app/store/tools/formDialogSlice";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";
import { smartpayInvoiceErrorsFormatter } from "./invoiceUtils";
import { global } from "app/services/requestUtil";

const TripInvoicingPage = forwardRef(({ nativeMobile, dataIds, setTitle, onLoading, onProcessing }, ref) => {
	const { carrierId, tripId } = dataIds;

	useEffect(() => {
		setTitle?.("Trip Invoice");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const role = useMemo(() => getRoleLabel(), []);

	// Selectors
	const user = useSelector(({ auth }) => auth.user);

	// Screen config
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 50) * 50);
	const [smallScreen, setSmallScreen] = useState(dataIds?.nativeMobile || window.innerWidth <= 600);

	// Revisions
	const tripsRevision = useSelector(({ tools }) => tools.revision["tripsRevision"]);
	const paymentRevision = useSelector(({ tools }) => tools.revision["paymentRevision"]);

	// States
	const [loading, setLoading] = useState(true);
	const [selectedLink, setSelectedLink] = useState(null);
	const [fileLoading, setFileLoading] = useState(false);
	const [tripInvoice, setTripInvoice] = useState();
	const [confirmationModel, setConfirmationModel] = useState();
	const [processing, setProcessing] = useState(false);

	// Flags
	const isAdmin = role === "administrator";
	const isOptOutOn = user?.hasOptOutOption;
	const isCarrierSmartpayEnabled = tripInvoice?.carrier_data?.hasSmartPayProgram;
	const isInvoiceSmartPayEligible = tripInvoice?.smartpay_eligible && isCarrierSmartpayEnabled;
	const isInvoiceSmartpayFlow = isInvoiceSmartPayEligible && tripInvoice?.status?.includes?.("SP_");

	// Consts
	const invoiceHasAccessEdit = hasRequiredGateKeepers(user, { permission_invoice_access: "editor" });
	const invoiceStatus = tripInvoice?.status;
	const doesStatusAllowEdit = ["PENDING", "GENERATED"].includes(invoiceStatus);
	const smartpayInvoiceErrors = smartpayInvoiceErrorsFormatter(tripInvoice);
	const smartpayInvoiceErrorsArray = Object.keys(smartpayInvoiceErrors);
	const invoiceId = tripInvoice?._id;
	const isBrokerResend = !!tripInvoice?.smartpay_last_sent_to_broker?.date;

	const generateSPActionHandler = useCallback(
		({ newStatus, message, acceptMsg, closeMsg }) =>
			() => {
				// If submitting for Smartpay, we show the Payment Type selector instead
				if (newStatus === "SP_SUBMITTED") {
					nativeMobile
						? window?.ReactNativeWebView?.postMessage(
								JSON.stringify({ type: "OPEN_SMARTPAY_SUBMISSION_VIEW", data: { carrierId, tripId } })
						  )
						: dispatch(
								openFormDialog({
									viewId: "SMARTPAY_SUBMISSION_VIEW",
									mode: "VIEW",
									dataIds: { carrierId, tripId },
								})
						  );
					return;
				}

				const confirmation = {
					message,
					acceptMsg,
					closeMsg,
					onCancel: () => {},
					onAccept: async () => {
						try {
							if (onProcessing) onProcessing(true);
							setProcessing(true);
							setFileLoading(true);
							await updateInvoiceStatus(carrierId, invoiceId, newStatus);
							dispatch(incrementDataRevision({ event: "tripsRevision" }));
							dispatch(incrementDataRevision({ event: "dashboardRevision" }));
							dispatch(incrementDataRevision({ event: "invoiceRevision" }));
							if (nativeMobile) {
								window?.ReactNativeWebView?.postMessage(
									JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } })
								);
							}
							setFileLoading(false);
							showSnackbar(snackbar, "Successful operation!", "success");
						} catch (e) {
							// If update failed, show error message
							showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
							setFileLoading(false);
						} finally {
							if (onProcessing) onProcessing(false);
							setProcessing(false);
						}
					},
				};
				setConfirmationModel(confirmation);
			},
		// eslint-disable-next-line
		[carrierId, dispatch, invoiceId, snackbar, tripId]
	);

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			// Admin can see account balance info
			if (isAdmin) {
				btns.account_balance = {
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								formId: "ADMIN_SMARTPAY_PAYOUT_ACCOUNT_FORM",
								mode: "VIEW",
								dataIds: { carrierId },
							})
						);
					},
					title: "View ACH Details",
				};
			}

			if (tripInvoice && invoiceHasAccessEdit && doesStatusAllowEdit && !isInvoiceSmartpayFlow) {
				btns.edit = {
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								formId: "TRIP_INVOICE_FORM",
								mode: "EDIT",
								dataIds: { carrierId, tripId },
							})
						);
					},
					title: "Edit Invoice",
				};
			}

			dispatch(refreshDialog());
			return btns;
		},
		// eslint-disable-next-line
		[tripInvoice]
	);

	// handle screen size
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth <= 960 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth > 960 && smallScreen) {
				setSmallScreen(false);
			}
			if (parseInt(window.innerHeight / 50) * 50 !== screenHeight) {
				setScreenHeight(parseInt(window.innerHeight / 50) * 50);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const optOutInvoice = async () => {
		const confirmation = {
			message: "You will not be able to submit this invoice if you decide to opt-out ",
			acceptMsg: "Yes, continue",
			onCancel: () => {},
			onAccept: async () => {
				try {
					if (onProcessing) onProcessing(true);
					setProcessing(true);
					await updateInvoice(carrierId, tripInvoice?.trip_data?.trip, { smartpay_optout: true });
					if (nativeMobile) {
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } }));
					}
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(incrementDataRevision({ event: "dashboardRevision" }));
					dispatch(incrementDataRevision({ event: "invoiceRevision" }));
					if (isCarrier()) {
						dispatch(fetchActions({ carrierId }));
					}
				} catch (e) {
					showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
				} finally {
					if (onProcessing) onProcessing(false);
					setProcessing(false);
				}
			},
		};
		setConfirmationModel(confirmation);
	};

	const onStatusChange = (status) => {
		let message = "Do you want to continue with the operation?";
		let acceptMsg = "Yes, continue";

		if (status === "GENERATED") {
			message = "Have you reviewed invoice details?";
			acceptMsg = "Yes, generate";
		}

		if (status === "PAID") {
			message = "Do you want to mark this invoice as paid?";
			acceptMsg = "Yes, pay";
		}

		const confirmation = {
			message,
			acceptMsg,
			onCancel: () => {},
			onAccept: async () => {
				try {
					setProcessing(true);
					if (status !== "PAID") setFileLoading(true);
					// Request changing the status to generated
					await updateInvoiceStatus(carrierId, invoiceId, status);
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(incrementDataRevision({ event: "dashboardRevision" }));
					dispatch(incrementDataRevision({ event: "invoiceRevision" }));
					if (nativeMobile) {
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } }));
					}
					setFileLoading(false);
					showSnackbar(snackbar, "Successful operation!", "success");
				} catch (e) {
					// If update failed, show error message
					showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
					setFileLoading(false);
				} finally {
					setProcessing(false);
				}
			},
		};
		setConfirmationModel(confirmation);
	};

	const donwloadInvoice = async () => {
		try {
			const report = await generateTripInvoice(carrierId, invoiceId);
			if (nativeMobile || window?.ReactNativeWebView)
				return window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: invoicePDFURI }));
			if (report) downloadFile(report, "Trip Invoice", "application/pdf");
		} catch (e) {
			showSnackbar(snackbar, e.errors[0]?.message, "error", 500);
		}
	};

	const conditionToHideOpt =
		isAdmin ||
		!isInvoiceSmartPayEligible ||
		!invoiceHasAccessEdit ||
		!isOptOutOn ||
		!["PENDING", "GENERATED"].includes(invoiceStatus);

	const conditionToHideCancel =
		!["GENERATED", "SP_SUBMITTED", "SP_CHANGE_REQUESTED"].includes(invoiceStatus) ||
		isAdmin ||
		!isInvoiceSmartpayFlow ||
		!invoiceHasAccessEdit;

	const hideSendBroker =
		!isAdmin ||
		!isInvoiceSmartPayEligible ||
		!invoiceHasAccessEdit ||
		!["SP_SUBMITTED", "SP_IN_REVIEW"].includes(invoiceStatus);

	const hideSendInvoice =
		isInvoiceSmartPayEligible || !invoiceHasAccessEdit || !["GENERATED", "SUBMITTED", "PAID"].includes(invoiceStatus);

	const hideAcknowledge =
		isAdmin || !isInvoiceSmartPayEligible || !isInvoiceSmartpayFlow || invoiceStatus !== "SP_REJECTED";

	useEffect(() => {
		(async () => {
			setFileLoading(true);
			if (loading) onLoading?.(true);
			const invoice = await getInvoiceForTrip(carrierId, tripId);
			setTripInvoice(invoice);
			onLoading?.(false);
			setLoading(false);
			setFileLoading(false);
		})();
		// eslint-disable-next-line
	}, [carrierId, tripId, tripsRevision, paymentRevision]);

	const buildInvoicePDFUrl = (invoiceId, carrierId) => {
		const baseUrl = `${global.SERVER_NAME}/api/trip-invoicing/carriers/${carrierId}/invoices/${invoiceId}`;
		const token = localStorage.getItem("tokenSmarthop");
		const url = `${baseUrl}/pdf?token=${token}`;
		return url;
	};

	const invoicePDFURI = useMemo(() => {
		if (tripInvoice) {
			const url = buildInvoicePDFUrl(invoiceId, carrierId);
			setSelectedLink?.({ id: tripInvoice?._id, link: url });
			return url;
		}
		// eslint-disable-next-line
	}, [tripInvoice]);

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	if (!tripInvoice) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="text-12 md:text-13 -mt-4">{"No Invoice available for this trip"}</Typography>
			</div>
		);
	}

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			sideComponents={[
				{
					key: "INVOICEL_PREVIEW",
					type: "PREVIEW",
					tabKeys: ["VIEW"],
					hideOnMobile: true,
					ratio: "5/12",
					component: (
						<div className={`flex flex-col h-full w-full  bg-grey-100 items-center `}>
							{fileLoading ? (
								<div className={clsx("flex w-full h-full items-center justify-center border-1")}>
									<CircularProgress className="text-grey-400" />
								</div>
							) : !tripInvoice?.invoice_file__flag && selectedLink?.id === invoiceId ? (
								<div className={clsx("flex w-full h-full items-center justify-center border-1")}>
									<Typography color="textSecondary">{"To preview invoice click generate"}</Typography>
								</div>
							) : (
								<CardMedia
									component="iframe"
									className={"flex h-full relative w-full bg-grey-200 "}
									image={selectedLink?.link}
									onLoad={() => {
										setFileLoading(false);
									}}
								/>
							)}
						</div>
					),
				},
			]}
			tabComponents={[
				{
					key: "VIEW",
					title: "View",
					component: (
						<div
							className={
								"flex flex-col w-full overflow-x-hidden" + (processing ? " opacity-60 pointer-events-none " : "")
							}
						>
							<div className={"mb-8"}>
								<div className={"flex flex-row w-full"}>
									<div className="w-full flex flex-col" key="main">
										<TripInvoicingDetails
											tripInvoice={tripInvoice}
											selectedLink={selectedLink}
											setSelectedLink={setSelectedLink}
											setFileLoading={setFileLoading}
											invoicePDFURI={invoicePDFURI}
											nativeMobile={nativeMobile}
										/>
									</div>
								</div>
								<SmarthopConfirmDialog
									open={!!confirmationModel?.message}
									title={confirmationModel?.message}
									acceptMsg={confirmationModel?.acceptMsg}
									closeMsg={confirmationModel?.closeMsg}
									onClose={() => {
										typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
										setConfirmationModel(null);
									}}
									onAccept={() => {
										confirmationModel?.onAccept();
										setConfirmationModel(null);
									}}
								/>
							</div>
						</div>
					),
				},
			]}
			footerActions={[
				{
					key: "donwloadInvoice",
					title: "Download Invoice",
					hideOnDesktop: invoiceStatus === "PENDING",
					hideOnMobile: invoiceStatus === "PENDING",
					onClick: () => donwloadInvoice(),
					style: {
						align: "LEFT",
						notCollapsible: !nativeMobile,
						icon: "download",
					},
				},
				{
					key: "viewTrip",
					title: "View Trip",
					hideOnDesktop: false,
					hideOnMobile: false,
					onClick: () =>
						nativeMobile
							? window?.ReactNativeWebView?.postMessage(
									JSON.stringify({ type: "OPEN_NATIVE_TRIP_VIEW", data: { carrierId, tripId } })
							  )
							: dispatch(
									openLoadedFormDialog({
										viewId: "TRIP_VIEW",
										dataIds: {
											tripId: tripId,
											carrierId: carrierId,
										},
									})
							  ),
					style: {
						align: "LEFT",
						notCollapsible: !nativeMobile,
						icon: "local_offer",
					},
				},
				{
					key: "optButton",
					title: "Opt Out",
					hideOnDesktop: conditionToHideOpt,
					hideOnMobile: conditionToHideOpt,
					onClick: () => optOutInvoice(),
					style: {
						align: "RIGHT",
						notCollapsible: !nativeMobile,
						icon: "not_interested",
						classes: "bg-grey-600 text-white",
					},
				},
				{
					key: "cancelButton",
					title: "Cancel",
					hideOnDesktop: conditionToHideCancel,
					hideOnMobile: conditionToHideCancel,
					onClick: generateSPActionHandler({
						message: "Would you like to cancel the invoice?",
						acceptMsg: "Yes",
						closeMsg: "No",
						newStatus: "PENDING",
					}),
					style: {
						align: "RIGHT",
						notCollapsible: !nativeMobile,
						icon: "cancel",
						classes: "bg-pink text-white md:mr-2",
					},
				},
				{
					key: "generateInvoice",
					title: "Generate Invoice",
					hideOnDesktop: invoiceStatus !== "PENDING" || !invoiceHasAccessEdit,
					hideOnMobile: invoiceStatus !== "PENDING" || !invoiceHasAccessEdit,
					onClick: () => onStatusChange("GENERATED"),
					style: { align: "RIGHT", primary: true, notCollapsible: true, icon: "receipt" },
				},
				{
					key: "submitSPButton",
					title: "Submit Invoice",
					disabled: smartpayInvoiceErrorsArray?.length > 0,
					hideOnDesktop: !isInvoiceSmartPayEligible || invoiceStatus !== "GENERATED" || !invoiceHasAccessEdit,
					hideOnMobile: !isInvoiceSmartPayEligible || invoiceStatus !== "GENERATED" || !invoiceHasAccessEdit,
					onClick:
						smartpayInvoiceErrorsArray?.length === 0
							? generateSPActionHandler({
									message: "Would you like to submit this SmartPay invoice for factoring?",
									acceptMsg: "Submit Invoice",
									newStatus: "SP_SUBMITTED",
							  })
							: undefined,

					style: { align: "RIGHT", primary: true, notCollapsible: true, icon: "send" },
				},
				{
					key: "editInvoice",
					title: "Edit Invoice",
					hideOnDesktop: true,
					hideOnMobile:
						!tripInvoice || !invoiceHasAccessEdit || !doesStatusAllowEdit || isInvoiceSmartpayFlow || !nativeMobile,
					onClick: () =>
						dispatch(
							nativeMobile
								? window?.ReactNativeWebView?.postMessage(
										JSON.stringify({ type: "OPEN_NATIVE_TRIP_INVOICE_EDIT", data: { carrierId, tripId } })
								  )
								: openLoadedFormDialog({
										formId: "TRIP_INVOICE_FORM",
										mode: "EDIT",
										dataIds: { carrierId, tripId },
								  })
						),
					style: { align: "LEFT", icon: "receipt" },
				},
				{
					key: "sendInvoice",
					title: "Send Invoice to Broker",
					hideOnDesktop: hideSendBroker,
					hideOnMobile: hideSendBroker,
					onClick: () =>
						dispatch(
							openLoadedFormDialog({
								viewId: "SEND_TRIP_INVOICE_VIEW",
								dataIds: { carrierId, tripId, invoiceId, resend: isBrokerResend, internalMode: true },
							})
						),
					style: { align: "LEFT", notCollapsible: true, icon: "send" },
				},
				{
					key: "sendInvoice",
					title: ["PENDING", "GENERATED"].includes(invoiceStatus) ? "Send Invoice" : "Re-Send Invoice",
					hideOnDesktop: hideSendInvoice,
					hideOnMobile: hideSendInvoice,
					onClick: () =>
						dispatch(
							nativeMobile
								? window?.ReactNativeWebView?.postMessage(
										JSON.stringify({
											type: "OPEN_NATIVE_TRIP_INVOICE_EXTERNAL_VIEW",
											data: { carrierId, tripId, resend: invoiceStatus !== "GENERATED", invoiceId },
										})
								  )
								: openLoadedFormDialog({
										viewId: "FILE_SHARING_DIALOG",
										dataIds: { carrierId, tripId, invoiceId, resend: invoiceStatus !== "GENERATED", from: "INVOICE" },
								  })
						),
					style: ["PENDING", "GENERATED"].includes(invoiceStatus)
						? { align: "RIGHT", notCollapsible: true, icon: "send", primary: true }
						: { align: "LEFT", icon: "send" },
				},
				{
					key: "acknowledgeSPButton",
					title: "Acknowledge",
					hideOnDesktop: hideAcknowledge,
					hideOnMobile: hideAcknowledge,
					onClick: async () => {
						try {
							setProcessing(true);
							await updateInvoiceStatus(carrierId, invoiceId, "PENDING");
							dispatch(incrementDataRevision({ event: "tripsRevision" }));
							dispatch(incrementDataRevision({ event: "dashboardRevision" }));
							dispatch(incrementDataRevision({ event: "invoiceRevision" }));
						} catch (e) {
							showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
						} finally {
							setProcessing(false);
						}
					},
					style: { align: "RIGHT", primary: true, notCollapsible: true, icon: "check" },
				},
			]}
		></SmarthopDialogViewContainer>
	);
});

export default TripInvoicingPage;
