const locale = {
	// Generic Fields
	Login: "Inicio de sesión",
	Save: "Guardar",
	Register: "Registro",
	Cancel: "Cancelar",
	Delete: "Eliminar",
	Update: "Actualización",
	Email: "Correo electrónico",
	Phone: "Teléfono",
	"Carrier Type": "Tipo de operador",
	"Trailer Type": "Tipo de tráiler",
	"Lane Type": "Tipo de carril",
	"Number of Trucks": "Número de camiones",
	Password: "Contraseña",
	"Confirm Password": "Confirmar contraseña",
	"Last Name": "Apellido",
	"First Name": "Nombre",

	// Registration Specific Fields 
	"(Optional) I am interested in learning more about our Insurance program?":
		"(Opcional) ¿Esta interesado en obtener más información sobre nuestro programa de seguros?",
	"I have read and agree to SmartHop's Terms Of Service, SMS Terms and Privacy Policy":
		"He leído y acepto los Términos de servicio, los Términos de SMS y la Política de privacidad de SmartHop",
	"Owner Operator": "Propietario Operador",
	"Company Driver": "Conductor de empresa",
	"Trucking Company": "Compañía de camiones",
	"53' Dry Van": "53 'Furgoneta seca",
	"53' Refeer": "53' Refeer",
	Both: "Ambas",
	Other: "Otra",
	"Over the Road": "Sobre el camino",
	"Home every weekend": "Casa todos los fines de semana",
	"Local  - Home Multiple times a week": "Local - Casa varias veces a la semana",

	// Errors
	"Please check your email address and try again": "Comprueba tu dirección de correo electrónico e inténtalo de nuevo",
	"Please correct entered information": "Corrija la información ingresada",
	"You must enter a valid email": "SDebes ingresar un correo electrónico válido",
	"Phone number is not valid": "El número de teléfono no es válido",
	"Password is too short - 6 chars minimum": "La contraseña es demasiado corta: mínimo 6 caracteres",
	"Passwords must match": "Las contraseñas deben coincidir",
	"is required": "es requerido",
	"Value must be greater than or equal to": "El valor debe ser mayor o igual a",
	"Value must be less than or equal to": "El valor debe ser menor o igual a",
	"The text is very short": "El campo es muy corto",
	"chars min": "caracteres mínimo",
	"The text is very long": "El campo es muy largo",
	"chars max": "caracteres máximo",
	"How many trucks do you have?": "¿Cuántos camiones tienes?"
};


export default locale;
