import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import ViewBanner from "app/main/chat/messages/ViewBanner";

const createForm = (title, items) => {
	return {
		classes: { root: "px-0" },
		items: [
			{
				key: "key_tooogle_" + title,
				type: "toggle",
				label: title,
				toggle: { defaultVisible: true, classes: { label: "text-12 md:text-13 font-normal", root: "flex mx-0" } },
				content: { items },
			},
		],
	};
};

function ViewToggleSection(props) {
	const incoming = props.incoming;
	const items = props.items;
	const title = props.title;
	const bannerContent = props.banner;
	const bannerIcon = props.bannerIcon ?? "info";
	const bannerColor = bannerIcon === "warning" ? "bg-red" : "bg-blue";

	const renderBanner = (content, icon, bannerColor) => {
		return (
			<ViewBanner
				variant=""
				incoming={incoming}
				icon={icon}
				message={content}
				classess={{ banner: `${{ bannerColor }}` }}
			/>
		);
	};

	const itemsMd = [];
	const itemsSm = [];
	let groupsMd = [];
	let groupsSm = [];

	if (bannerContent) {
		groupsMd.push({
			type: "component",
			key: "banner",
			builder: () => renderBanner(bannerContent, bannerIcon, bannerColor),
		});
		groupsSm.push({
			type: "component",
			key: "banner",
			builder: () => renderBanner(bannerContent, bannerIcon, bannerColor),
		});
	}

	items?.forEach((item) => {
		if (!item.label?.length) return;
		const content = {
			type: "text",
			label: item.label,
			icon: item.icon,
			key: "email",
			builder: () => item?.value ?? "-",
		};

		if (!itemsMd[itemsMd.length - 1] || itemsMd[itemsMd.length - 1].length === 3) itemsMd.push([]);
		itemsMd[itemsMd.length - 1].push(content);

		if (!itemsSm[itemsSm.length - 1] || itemsSm[itemsSm.length - 1].length === 2) itemsSm.push([]);
		itemsSm[itemsSm.length - 1].push(content);
	});

	itemsMd?.forEach((items) => {
		groupsMd.push({
			type: "group",
			content: {
				items,
			},
		});
	});
	itemsSm?.forEach((items) => {
		groupsSm.push({
			type: "group",
			content: {
				items,
			},
		});
	});

	return (
		<div className={props?.classes?.root}>
			<div key="toggleSm" className={"w-full hidden md:flex flex-row py-4 md:py-6"}>
				<SmarthopFormView
					mode={"VIEW"}
					content={createForm(title, groupsMd)}
					noInitValidation={true}
					dataIds={props}
					//data={itemsMdGroup}
				></SmarthopFormView>
			</div>
			<div key="toggleMd" className={"w-full flex md:hidden flex-row py-4 md:py-6"}>
				<SmarthopFormView
					mode={"VIEW"}
					content={createForm(title, groupsSm)}
					noInitValidation={true}
					dataIds={props}
				></SmarthopFormView>
			</div>
		</div>
	);
}

export default ViewToggleSection;
