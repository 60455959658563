import { centsToDollars } from "app/main/utils/financeUtils";

const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const STATUS_NAMES = {
	HOLD: "On-Hold",
	PENDING: "Pending",
	PROCESSED: "Paid",
};

const TYPE_NAMES = {
	IMMEDIATE: "Immediate",
	OUTSTANDING: "Outstanding",
};

const smartpayJournalEntriesConf = ({ isAdmin, payoutId, showCarrier } = {}) => {
	return {
		urlGET: !isAdmin ? `api/trip-invoicing/carriers/:carrierId/journal-entries` : `api/trip-invoicing/journal-entries`,
		idKey: "_id",
		listenEvent: ["tripsRevision"],
		segmentKey: "TRIP_INVOICING",
		content: {
			table: {
				variant: "skinny",
				orderBy: ["event_date", "status"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
				defaut: {
					size: 20,
				},
			},
			order: {
				defaut: {
					key: "event_date",
					dir: "desc",
				},
			},
			export: {
				fileName: "Ledger entries",
			},
			filters: {
				search: true,
				countVisible: 2,
				items: [
					{
						key: "status",
						type: "select",
						label: "Status",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "HOLD",
								label: "Hold",
							},
							{
								value: "PENDING",
								label: "Pending",
							},
							{
								value: "PROCESSED",
								label: "Paid",
							},
						],
					},
					{
						key: "payout_type",
						type: "select",
						label: "Type",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "IMMEDIATE",
								label: "Immediate",
							},
							{
								value: "OUTSTANDING",
								label: "Outstanding",
							},
						],
					},
					{
						key: "invoice",
						type: "autocomplete",
						label: "Invoice",
						field: {
							noErrorMessage: true,
							classes: {},
						},
						autocomplete: {
							provider: "smarthop",
							url: isAdmin ? "api/trip-invoicing/invoices" : "api/trip-invoicing/carriers/:carrierId/invoices",
							preloadDefault: true,
							listenEvent: "profileRevision",
						},
					},
					{
						key: "payout",
						type: "autocomplete",
						label: "Payout",
						field: {
							noErrorMessage: true,
							classes: {},
						},
						autocomplete: {
							provider: "smarthop",
							url: isAdmin ? "api/trip-invoicing/payouts" : "api/trip-invoicing/carriers/:carrierId/payouts",
							preloadDefault: true,
							listenEvent: "profileRevision",
						},
					},
					...(isAdmin || showCarrier
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									translate: "",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										preloadDefault: true,
										params: {
											sortOrder: "asc",
											sortBy: "carrierid",
											filters: { customer_id: "__NOT_EMPTY__" },
										},
									},
								},
						  ]
						: []),
					...(isAdmin
						? [
								{
									key: "carriers_type",
									type: "select",
									label: "Internal Carrier Type",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "REAL_CARRIERS",
											label: "Real Carriers",
										},
										{
											value: "TEST_CARRIERS",
											label: "Test Carriers",
										},
									],
								},
						  ]
						: []),
				],
			},
			groups: {
				items: [],
			},
			defaults: {
				initial: {
					filters: {
						status: "__NOT_SELECTED__",
						...(payoutId ? { "links.payout": payoutId } : {}),
						...(PROD_DB && isAdmin ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
					},
				},
				items: [
					{
						label: "All",
						filters: {
							status: "__NOT_SELECTED__",
						},
					},
					{
						label: isAdmin ? "Actionable (Pending)" : "Pending",
						filters: {
							status: "PENDING",
						},
					},
					{
						label: "Hold",
						filters: {
							status: "HOLD",
						},
					},
					{
						label: "Paid",
						filters: {
							status: "PROCESSED",
						},
					},
				],
			},
			items: [
				{
					key: "status",
					label: "Status",
					type: "text",
					builder: (item) => STATUS_NAMES[item?.status] ?? item?.status,
				},
				{
					key: "payout_type",
					label: "Type",
					type: "text",
					builder: (item) => TYPE_NAMES[item?.payout_type] ?? item?.payout_type,
				},
				...(!isAdmin && !showCarrier
					? []
					: [
							{
								key: "carrier__view",
								label: "Carrier",
								type: "carrier__view",
								viewConfig: {
									showMcNumber: true,
									showTestCarrier: true,
									showCarrierAccountType: true,
								},
							},
					  ]),
				{
					key: "invoice__view",
					label: "Invoice",
					type: "invoicing__view",
				},
				...(payoutId ? [] : [{ key: "payout__view", label: "Payout", type: "payout__view" }]),
				{
					key: "event_date",
					label: "Created On",
					type: "date",
				},
				{
					key: "processed_date",
					label: "Paid On",
					type: "date",
				},
				{
					key: "amount_cents",
					label: "Amount",
					type: "currency",
					builder: (item) => centsToDollars(item?.amount_cents),
					table: { align: "right", width: "10%" },
				},
			],
		},
	};
};

export default smartpayJournalEntriesConf;
