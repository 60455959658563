import { Chip, TextField } from "@material-ui/core";
import { useState } from "react";
import * as yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import _ from "@lodash";

const emailValidator = yup.string().email();

const useStyles = makeStyles((theme) => {
	return {
		chip: {
			lineHeight: "2rem",
		},
		errorChip: {
			lineHeight: "2rem",
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
		},
		deleteIcon: {
			color: theme.palette.error.contrastText,
		},
	};
});

const EmailFieldWithChips = ({ emailArr, emailErrors, setEmailErrors, setEmailValue }) => {
	const [originalOptions] = useState(emailArr ?? []);

	const classes = useStyles();

	const hasErrors = !!emailErrors?.length;

	const renderTags = (value, props) => {
		const emailsWithErrors = [];
		const renderedTags = [];
		value.forEach((option, index) => {
			const tag = option?.trim();
			if (tag === "") return;
			const validEmail = emailValidator.isValidSync(tag);

			if (!validEmail) {
				emailsWithErrors.push(option);
			}

			renderedTags.push(
				<Chip
					key={`${option}_${index}`}
					label={option}
					classes={validEmail ? { root: classes.chip } : { root: classes.errorChip, deleteIcon: classes.deleteIcon }}
					{...props({ index })}
				/>
			);
		});
		if (!_.isEqual(emailErrors, emailsWithErrors)) {
			setEmailErrors(emailsWithErrors);
		}
		return renderedTags;
	};

	const buildErrorText = (emailErrors) =>
		`Invalid email${emailErrors.length > 1 ? "s" : ""}: ` + emailErrors?.map?.((email) => `"${email}"`)?.join(", ");

	return (
		<Autocomplete
			onChange={(ev, values) => setEmailValue(values.toString())}
			options={originalOptions}
			freeSolo
			multiple
			renderTags={renderTags}
			defaultValue={emailArr}
			renderInput={(params) => (
				<TextField {...params} error={hasErrors} helperText={hasErrors ? buildErrorText(emailErrors) : " "} />
			)}
		/>
	);
};

export default EmailFieldWithChips;
