const carrierConfigurationForm = ({ features }) => ({
	urlGET: "api/profile/carriers/:carrierId/details/configuration",
	urlPUT: "api/profile/carriers/:carrierId/details/configuration",
	idKey: "_id",
	triggerEvent: ["profileRevision", "onboardingRevision"],
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit Carrier Configuration Fields" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "date_of_authority",
								type: "picker",
								label: "Date of Authority",
								translate: "DATE_OF_AUTHORITY",
								field: {
									classes: {},
									variant: undefined,
								},
								picker: {
									type: "datetime",
									classes: {},
								},
							},
							{ key: "manual_queue", type: "checkbox", label: "Manual Queue" },
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "disable_digital_booking",
								type: "checkbox",
								label: "Book Now Disabled",
							},
							{
								key: "disable_help_request",
								type: "checkbox",
								label: "On-Deman Load Help Request Disabled",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{ key: "isTestCarrier", type: "checkbox", label: "Test Carrier" },
							{
								key: "always_show_load_help",
								type: "checkbox",
								label: "Always show Help button on load",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "planTransferEnabled",
								type: "checkbox",
								label: "Enable option to transfer Truck Plan to Smarthop",
							},
							{
								key: "marketScanEnabled",
								type: "checkbox",
								label: "Enable Market Scan with this Carrier",
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
});

export default carrierConfigurationForm;
