const cronJobLogs = {
	urlGET: "api/cron/users/:userId/runs/:runId/logs",
	idKey: "currentState_timestamp",
	listenEvent: "cronJobRevision",
	segmentKey: "ADMIN_CRON_JOBS_LOGS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		items: [
			{ key: "timestamp", type: "date", label: "Date" },
			{ key: "status", type: "text", label: "Status" },
			{ key: "message", type: "text", label: "Message", collapsibleLength: 40 },
			{
				key: "metadata",
				type: "text",
				label: "Metadata",
				builder: (item) => (item.metadata ? JSON.stringify(item.metadata, null, 2) : null),
				collapsibleLength: 40,
			},
		],
	},
};

export default cronJobLogs;
