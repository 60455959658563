import React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import CardMedia from "@material-ui/core/CardMedia";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { global } from "app/services/requestUtil";
import axios from "axios";
import MobileDetect from "mobile-detect";
import uniqid from "uniqid";
import fetch from "cross-fetch";
import { buildLink, convert } from "app/services/fileServices";
import SmarthopConfirmDialog from "../SmarthopConfirmDialog";
import { useDispatch } from "react-redux";
import { openFormDialog } from "app/store/tools/formDialogSlice";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const useStyles = makeStyles((theme) => {
	return {
		"@keyframes progress": {
			"0%": { backgroundPosition: "0 0" },
			"100%": { backgroundPosition: "-70px 0" },
		},
		container: {
			position: "relative",
		},
		iconLeft: {
			zIndex: "1",
			position: "absolute",
			left: "0",
		},
		iconRight: {
			zIndex: "1",
			position: "absolute",
			right: "0",
		},
		dropzone: {
			minHeight: "4.8rem",
			borderWidth: "1px",
			backgroundColor: "#F8F8F8",
			borderColor: "#E9E9E9",
		},
		dropzoneActive: {
			minHeight: "4.8rem",
			animation: "$progress 2s linear infinite !important",
			// eslint-disable-next-line max-len
			backgroundImage: "repeating-linear-gradient(-45deg, "
				.concat(theme.palette.secondary.light, ", ")
				.concat(theme.palette.secondary.light, " 25px, ")
				.concat(theme.palette.background.default, " 25px, ")
				.concat(theme.palette.background.default, " 50px)"),
			backgroundSize: "150% 100%",
			border: "solid",
			borderWidth: "1px",
			borderColor: "#D29C89",
		},
		dropzoneUploaded: {
			minHeight: "4.8rem",
			border: "solid",
			backgroundColor: "#e6ebf2",
			borderWidth: "1px",
			borderColor: "#C3D6C4",
		},
		dropzoneError: {
			minHeight: "4.8rem",
			borderWidth: "1px",
			borderColor: "#E6A29D",
		},
		defaultPreviewHeight: {
			height: "calc(100vh - 315px) !important",
		},
	};
});

export default function SmarthopUploadField(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const manualInputFileRef = useRef(null);
	const [uploading, setUploading] = useState(false);
	const [cancelUploading, setCancelUploading] = useState(false);
	const [deteleFiles, setDeleteFiles] = useState([]);
	const [uploadError, setUploadError] = React.useState("");
	const [responseSuccess, setResponseSuccess] = React.useState("");
	const [responseError, setResponseError] = React.useState("");
	const [newFile, setNewFile] = useState(null);
	const [nativeUploadPrompt, setNativeUploadPrompt] = useState({});
	const fileNameId = useMemo(() => uniqid(`${props?.upload?.fileName}_`), [props.upload?.fileName]);

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);
		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	});

	useEffect(() => {
		let cancelRequest = false;

		if (!newFile) {
			return;
		}

		if (typeof uploadConfig.onFileUpload === "function") {
			uploadConfig.onFileUpload(newFile);
		}

		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		};

		let data = new FormData();
		data.append("file", newFile);
		data.append("type", uploadConfig.type);
		data.append("document_type", uploadConfig.documentType);
		data.append("field_name", uploadConfig.fileName);
		if (typeof uploadConfig.extraFormData === "object") {
			Object.entries(uploadConfig.extraFormData).forEach(([key, value]) => {
				// Explicit check to avoid falsy values not being sent
				if (value !== undefined && value !== null) {
					data.append(key, value);
				}
			});
		}

		if (dataIds) {
			data.append("dataIds", JSON.stringify(dataIds));
		}

		let url = uploadConfig.url;
		Object.keys(dataIds).forEach((key) => {
			url = url.replace(":" + key, dataIds[key]);
		});

		let params = { ...(uploadConfig.params ?? {}) };
		Object.keys(params).forEach((key) => {
			let keyValue = params[key];
			let newValue = keyValue?.includes?.(":") ? dataIds[keyValue.replace(":", "")] : null;
			if (newValue) params[key] = newValue;
		});

		console.log(`[SmarthopUploadField] uploading file, file=`, newFile);
		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.post(url, data, { headers: headers, params: params })
			.then((res) => {
				if (!cancelRequest) {
					console.log(`[SmarthopUploadField] POST, uploaded file, data=`, cancelUploading, res.data);
					setResponseSuccess(res);
					setNewFile(null);
				}
			})
			.catch((res) => {
				if (!cancelRequest) {
					console.error(`[SmarthopUploadField] error, status=${res?.status}`);
					setNewFile(null);
					setResponseError(res);
				}
			});

		return () => {
			cancelRequest = true;
		};
		// eslint-disable-next-line
	}, [newFile]);

	const currentFilesId = props.value;
	const uploadConfig = props.upload;
	const onChange = props.onChange;
	const dataIds = props.dataIds;
	const nativeMobile = window?.ReactNativeWebView ? true : false;

	if (uploadConfig && !uploadConfig.documentType) {
		// Document type defines which folder file should be storred in google drive
		uploadConfig.documentType = "carrier";
	}

	// checking all params for fetch query

	let queryMissingParams = "";
	if (uploadConfig?.url) {
		uploadConfig.url.split("/").forEach((section) => {
			if (section.startsWith(":") && (!dataIds || !dataIds[section.substring(1)])) {
				if (queryMissingParams) queryMissingParams += ",";
				queryMissingParams += section.substring(1);
			}
		});
	}

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "ScannedDocument":
					return OnScannedDocument(dataParsed.data);
				default:
					break;
			}
		} catch (e) {}
	};

	const dataURLtoFile = async (url, filename, mime) => {
		let res = await fetch(url);
		let resBlob = await res.blob();
		let file = await new File([resBlob], filename, { type: mime });
		return file;
	};

	const OnScannedDocument = async (msg) => {
		if (msg.fileName !== fileNameId) return;
		setUploading(true);

		let content = msg?.imgData;
		if (msg?.imageList) {
			content = await convert(msg.imageList);
		}

		const genFilename = `${Date.now()}.${msg?.contentType.split("/")[1]}`;
		const file = await dataURLtoFile(content, genFilename, msg?.contentType);
		setNewFile(file);
	};

	// process success response
	useEffect(() => {
		if (!responseSuccess) {
			return;
		}

		if (cancelUploading) {
			let deletedFiles = [responseSuccess.data.fileId, ...deteleFiles];
			setDeleteFiles(deletedFiles);
			if (onChange) onChange(currentFilesId, { status: "CANCELLED", deletedFiles });
		} else {
			let uploadedFile = [responseSuccess.data.fileId];
			let deletedFiles = [...currentFilesId, ...deteleFiles];
			setDeleteFiles(deletedFiles);
			if (onChange) onChange(uploadedFile, { status: "SUCCESS", metadata: newFile, deletedFiles: deletedFiles });
		}
		setUploadError("");
		setUploading(false);
		setCancelUploading(false);
		setResponseSuccess(null);
		setResponseError(null);
		// eslint-disable-next-line
	}, [responseSuccess]);

	// process error response
	useEffect(() => {
		if (!responseError) {
			return;
		}

		// Show errors
		if (responseError?.response?.data?.errors) {
			const errors = responseError?.response?.data?.errors;
			const message = errors?.[0]?.message ?? (errors?.length ? errors?.join(", ") : errors);
			setUploadError("File failed validation: " + message);
		} else if (!cancelUploading) {
			setUploadError("Failed to upload file, please try again later...");
		} else {
			setUploadError("");
		}

		setUploading(false);
		setCancelUploading(false);
		setResponseSuccess(null);
		// eslint-disable-next-line
	}, [responseError]);

	if (!uploadConfig || !uploadConfig.url) {
		return (
			<Typography color="textSecondary" className="mb-10 mt-10 font-normal">
				{`Invalid uploader config for field '${props.label ?? "<null>"}'`}
			</Typography>
		);
	}

	const handleOnAlert = (message, variant) => {
		if (variant === "error") {
			setUploadError(message);
		}
	};

	const onFileChangeCapture = (e) => {
		handleOnDrop(e?.target?.files);
	};

	const handleOnDrop = (files) => {
		if (files?.length === 0 || files?.length > 1) {
			return;
		}
		if (uploading || cancelUploading) {
			console.log(`[SmarthopUploadField] can't start uploading, operation in progress`);
			return;
		}
		setUploadError("");
		setUploading(true);
		setNewFile(files[0]);
	};

	const onCancelUploading = () => {
		if (uploading) {
			setCancelUploading(true);
		}
	};

	const onDelete = () => {
		if (currentFilesId?.length && !uploading && !cancelUploading) {
			let deletedFiles = [...currentFilesId, ...deteleFiles];
			setDeleteFiles(deletedFiles);
			setUploadError(null);
			if (onChange) onChange([], { status: "DELETED", deletedFiles });
		}
	};

	const getFileUrl = (download) => {
		if (!currentFilesId?.[0]) return null;
		return buildLink(currentFilesId?.[0], download, uploadConfig.public);
	};

	const urlFilePreivew = getFileUrl(false);

	const onPreviewFile = () => {
		let filePreviewUrl = getFileUrl(false);
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: { urlFilePreview: filePreviewUrl },
			})
		);
	};

	const onView = (download) => {
		let filePreviewUrl = getFileUrl(download);
		if (nativeMobile) {
			window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: filePreviewUrl }));
		} else {
			window.open(filePreviewUrl, "_blank");
		}
	};

	const onUpload = () => {
		manualInputFileRef.current.click();
	};

	const scan = () => {
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "DOCUMENT_SCAN", data: { fileName: fileNameId } }));
	};

	return (
		<div
			className={
				(props.disabled ? "opacity-60 " : "") +
				"w-full mb-4 -mt-14 pb-1 px-4 " +
				(props?.field?.noErrorMessage ? " pb-4 " : "")
			}
		>
			<div className={clsx("flex flex-row w-full pt-4 items-center")}>
				<div className={clsx("flex flex-col w-full -mb-2")}>
					<Typography color="textSecondary" variant="caption" className="flex ml-10 ">
						{props.label + (props.required ? " *" : "")}
					</Typography>
					{uploadConfig?.variant === "preview" && (
						<Typography
							color="textSecondary"
							variant="caption"
							className={clsx("flex ml-10 -mt-4 mb-2", !!currentFilesId?.length ? "cursor-pointer" : null)}
							onClick={!!currentFilesId?.length ? onUpload : null}
						>
							{!!currentFilesId?.length
								? "Click 'Upload' Button to Replace File"
								: "Drag and Drop File or Click to Upload"}
						</Typography>
					)}
				</div>

				{uploadConfig?.variant === "preview" && (
					<>
						<Tooltip title="Upload new file">
							<IconButton onClick={onUpload} className={clsx("p-6 mr-4")} disabled={uploading}>
								<Icon className={clsx("text-24", !uploading ? "text-secondary" : "text-grey-300")}>input</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip title="View in another tab">
							<IconButton
								onClick={() => onView(false)}
								className={clsx("p-6 mr-4")}
								disabled={!currentFilesId?.length || uploading}
							>
								<Icon
									className={clsx(
										"text-24",
										!!currentFilesId?.length && !uploading ? "text-secondary" : "text-grey-300"
									)}
								>
									launch
								</Icon>
							</IconButton>
						</Tooltip>
						<div className="w-1.5 h-18 bg-grey-400 mx-8" />
						<Tooltip title="Delete file">
							<IconButton onClick={onDelete} className={clsx("p-4")} disabled={!currentFilesId?.length || uploading}>
								<Icon
									className={clsx(
										"text-28",
										!!currentFilesId?.length && !uploading && !uploadConfig?.replaceOnly
											? "text-red-700"
											: "text-grey-300"
									)}
								>
									clear
								</Icon>
							</IconButton>
						</Tooltip>
					</>
				)}
			</div>
			<div className={clsx(classes.container, "flex flex-row items-center w-full")}>
				<div
					className={(props.disabled ? "opacity-60 " : "") + " w-full "}
					style={uploading ? { pointerEvents: "none" } : {}}
				>
					{manualInputFileRef && (
						<input type="file" ref={manualInputFileRef} className="hidden" onChangeCapture={onFileChangeCapture} />
					)}
					<DropzoneArea
						inputProps={{
							onClick: (e) => {
								if (nativeMobile) {
									e.preventDefault();
									e.stopPropagation();
									setNativeUploadPrompt({
										open: true,
									});
								}
							},
						}}
						fileObjects={[]}
						maxFileSize={50 * 1024 * 1024}
						showPreviews={false}
						showPreviewsInDropzone={false}
						showFileNamesInPreview={false}
						showFileNames={false}
						filesLimit={1}
						dropzoneClass={
							(uploadConfig?.classes?.dropzone ?? "") +
							(uploadConfig?.variant === "large"
								? " min-h-120 ml:min-h-380 "
								: uploadConfig?.variant === "preview"
								? // Setting height to max of what dialog size can be to show pdf / image file preview
								  uploadConfig?.classes?.sheight ?? classes.defaultPreviewHeight
								: "") +
							" " +
							(uploading
								? classes.dropzoneActive
								: !!currentFilesId?.length
								? classes.dropzoneUploaded
								: classes.dropzone)
						}
						dropzoneParagraphClass={
							(uploadConfig?.classes?.dropzoneParagraph ?? "") +
							(uploadConfig?.variant === "large" ? " pt-32 ml:pt-64 " : "") +
							(uploadConfig?.variant === "preview"
								? (uploadConfig?.classes?.sheight ?? classes.defaultPreviewHeight) +
								  " flex justify-center items-center pb-48 "
								: "") +
							" text-13 mt-16 mb-10 active truncate " +
							(!!currentFilesId?.length && !uploading && uploadConfig?.variant !== "preview"
								? " ml-20 pl-40 ml:pl-96 pr-40 mr-20 "
								: "")
						}
						Icon={"b"}
						dropzoneText={
							cancelUploading
								? "Canceling..."
								: uploading
								? `Uploading  file...`
								: window.ismobile()
								? `Click to Upload a File`
								: `Drag and Drop File or Click to Upload`
						}
						showAlerts={false}
						onChange={handleOnDrop}
						onAlert={handleOnAlert}
						acceptedFiles={props?.upload?.allowedFiles ?? []}
					/>

					<SmarthopConfirmDialog
						open={!!nativeUploadPrompt?.open}
						title="Document Upload"
						message="For multiple pages, scanner is recommended."
						hideCloseBtn={true}
						otherActions={[
							{
								onClick: () => {
									setNativeUploadPrompt({
										open: false,
									});
									scan();
								},
								label: "Scan Document",
							},
							{
								onClick: () => {
									setNativeUploadPrompt({
										open: false,
									});
									onUpload();
								},
								label: "Upload Image",
							},
						]}
						onClose={() => {
							setNativeUploadPrompt({
								open: false,
							});
						}}
					/>
				</div>

				{urlFilePreivew && uploadConfig?.variant === "preview" && (
					<>
						<CardMedia
							component="iframe"
							className={clsx(
								classes.iconLeft,
								"flex w-full bg-grey-200",
								uploadConfig?.classes?.sheight ?? classes.defaultPreviewHeight
							)}
							image={urlFilePreivew}
						/>
						{uploading && (
							<div
								className={clsx(
									classes.iconLeft,
									"flex w-full bg-grey opacity-50 items-center justify-center",
									uploadConfig?.classes?.sheight ?? classes.defaultPreviewHeight
								)}
							>
								<Typography className="flex text-14 px-20 py-2 text-white bg-black rounded-20">
									Uploading File...
								</Typography>
							</div>
						)}
					</>
				)}

				{!!currentFilesId?.length && !uploading && uploadConfig?.variant !== "preview" && (
					<>
						<Tooltip title="Preview in another tab">
							<IconButton onClick={() => onView(false)} className={clsx(classes.iconLeft, "p-6 mx-8 flex ml:hidden")}>
								<Icon className={clsx("text-24 text-secondary")}>launch</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip title="Preview file">
							<IconButton onClick={() => onPreviewFile()} className={clsx(classes.iconLeft, "p-6 mx-8 hidden ml:flex")}>
								<Icon className={clsx("text-24 text-secondary")}>slideshow</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip title="Download file">
							<IconButton
								onClick={() => onView(true)}
								className={clsx(classes.iconLeft, "p-6 mx-8 ml-48 hidden ml:flex")}
							>
								<Icon className="text-24 text-secondary">save_alt</Icon>
							</IconButton>
						</Tooltip>
					</>
				)}

				{!!currentFilesId?.length &&
					!uploading &&
					!uploadConfig?.replaceOnly &&
					uploadConfig?.variant !== "preview" && (
						<Tooltip title="Delete file">
							<IconButton onClick={onDelete} className={clsx(classes.iconRight, "p-6 mx-8")}>
								<Icon className="text-24 text-red-700">clear</Icon>
							</IconButton>
						</Tooltip>
					)}

				{uploading && !cancelUploading && uploadConfig?.variant !== "preview" && (
					<IconButton onClick={onCancelUploading} className={clsx(classes.iconRight, "p-6 mx-8")}>
						<Icon className="text-28" color="error">
							clear
						</Icon>
					</IconButton>
				)}
			</div>
			<div
				className={clsx(classes.container, "flex flex-row w-full pl-10 mt-1 pt-0 pb-4")}
				style={!props.disabled && !!uploadError ? {} : { height: "1.6rem" }}
			>
				<Typography color="error" variant="caption">
					{uploadError ? uploadError : props.error?.message ? props.error?.message : ""}
				</Typography>
			</div>
		</div>
	);
}
