// Utils
import { formatLedgerAmount } from "app/main/utils/financeUtils";
import { PAYROLL_STATUSES } from "app/main/consts";

const entityLedgerList = ({ isTrip, isFuel } = {}) => {
	return {
		urlGET: isTrip
			? "billing/v3/payroll/carriers/:carrierId/trips/:tripId/ledger"
			: isFuel
			? "api/fuel/transactions/:transactionId/ledger"
			: "",
		idKey: "_id",
		listenEvent: ["fuelRevision", "tripsRevision", "profileRevision", "payrollRevision"],
		segmentKey: "TRIP_PAYROLL",
		content: {
			table: {
				variant: "skinny",
				orderBy: ["date"],
			},
			order: {
				defaut: {
					key: "event_date",
					dir: "desc",
				},
			},
			items: [
				{
					key: "payee__view.label",
					type: "payee__view",
					label: "Payee",
					viewConfig: {
						showPayeeType: true,
						showStatus__top: true,
					},
					builder: (item) => ({
						...item.payee__view,
						metadata: {
							...item.payee__view?.metadata,
							payee_type: item.payee_type,
							payee_category: item.payee_category,
						},
					}),
				},
				{
					key: "payroll__view",
					type: "payroll__view",
					label: "Payroll",
					viewConfig: {
						showState: true,
					},
				},
				{
					key: "category__view",
					type: "category__view",
					label: "Type",
				},
				{ key: "event_date", type: "date", label: "Event Date" },
				{
					key: "description",
					type: "text",
					label: "Description",
					collapsibleLength: 50,
				},
				{
					key: "status",
					type: "text",
					label: "Status",
					builder: (item) => PAYROLL_STATUSES[item.status],
				},
				{
					key: "amount_cents",
					type: "component",
					label: "Amount",
					builder: (item) => formatLedgerAmount(item.amount_cents, item.type),
				},
			],
		},
	};
};

export default entityLedgerList;
