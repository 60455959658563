import PageTabWrapper from "app/main/common/PageTabWrapper";
import FormSection from "app/main/tools/FormSection";

import { internalUsersList, SmarthopList } from "@smarthop/list";
import { useEffect } from "react";

function ProfileInfoView(props) {
	useEffect(() => {
		props?.setTitle?.("Profile Information");
		// eslint-disable-next-line
	}, []);
	const profileId = props.profileId ?? props.dataIds?.profileId;
	const isView = true;

	return (
		<PageTabWrapper
			isView={isView}
			tabs={[
				{
					label: "Details",
					component: (
						<div className="flex flex-row h-screen60 py-14">
							<div className="flex flex-col w-1/2">
								<FormSection title="Information" formId="PROFILE_INFORMATION_FORM" dataIds={{ profileId }} />
							</div>
						</div>
					),
				},
				{
					label: "Users",
					component: (
						<SmarthopList
							key="profileUsers"
							urlKey="profileUsers"
							mode="TABLE"
							config={internalUsersList({ profileSubset: true, isView })}
							dataIds={{ profileId }}
						/>
					),
				},
			]}
		/>
	);
}

export default ProfileInfoView;
