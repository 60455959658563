const EFS_TIMEZONE = "America/Chicago";

// Payroll Payment Types
const PAYROLL_PAYMENT_TYPE_PERCENTAGE = "PAY_BY_PERCENTAGE";
const PAYROLL_PAYMENT_TYPE_MILES = "PAY_BY_MILES";
const PAYROLL_PAYMENT_TYPE_FLAT_FEE = "FLAT_FEE";

const FUEL_CUSTOM_PIN = "CUSTOM_PIN";
const FUEL_TRUCK_ID = "TRUCK_ID";
const READY_FOR_PAYROLL_STATUS = "READY_FOR_PAYROLL";
const PROCESSING_STATUS = "PROCESSING";
const PAID_TO_DRIVER_STATUS = "PAID_TO_DRIVER";
const PAYROLL_PAYMENT_TYPE_LABEL = {
	[PAYROLL_PAYMENT_TYPE_PERCENTAGE]: "Pay per %",
	[PAYROLL_PAYMENT_TYPE_MILES]: "Pay per miles",
	[PAYROLL_PAYMENT_TYPE_FLAT_FEE]: "Flat fee",
};

const DISTRIBUTION_PRIORITY = {
	CARRIER_FEE: "Carrier Fee",
	INVESTOR_SHARE: "Investor Share",
};

const PAYROLL_STATUS_LABEL = {
	[READY_FOR_PAYROLL_STATUS]: "Ready for Payroll",
	[PROCESSING_STATUS]: "Processing",
	[PAID_TO_DRIVER_STATUS]: "Paid to Driver",
};
const FUEL_PIN_VALIDATION_TYPE = {
	[FUEL_CUSTOM_PIN]: "By Custom PIN",
	[FUEL_TRUCK_ID]: "By Truck ID",
};
const PAYROLL_RECURRING_DEDUCTION = [
	{
		value: "fixed_trailer",
		label: "Fixed Trailer Charge",
	},
	{
		value: "fixed_truck",
		label: "Fixed Truck Charge",
	},
	{
		value: "total_fuel_consumptions",
		label: "Fuel Consumptions",
	},
	{
		value: "insurance_payment",
		label: "Insurance Down Payment",
	},
	{
		value: "liability_cargo_insurance",
		label: "Liability & Cargo Insurance",
	},
	{
		value: "monthly_eld",
		label: "Monthly ELD",
	},
	{
		value: "monthly_trailer",
		label: "Monthly Trailer",
	},
	{
		value: "monthly_yard",
		label: "Monthly Yard",
	},
	{
		value: "samsara",
		label: "Samsara",
	},
	{
		value: "trailer_physical_damage",
		label: "Trailer Physical Damage",
	},
	{
		value: "truck_physical_damage",
		label: "Truck Physical Damage",
	},
	{
		value: "weekly_deduction",
		label: "Weekly Deduction",
	},
	{
		value: "weekly_escrow",
		label: "Weekly Escrow",
	},
	{
		value: "weekly_fuel",
		label: "Weekly Fuel",
	},
	{
		value: "monthly_insurance",
		label: "Weekly Insurance",
	},
	{
		value: "others",
		label: "Others",
	},
];
const DRIVER_PAYROLL_LIST_STATUS = [
	{
		value: "closedPayrolls",
		label: "Finalized (Closed)",
	},
	{
		value: "paidPayrolls",
		label: "Paid (Paid)",
	},
];
const ADMIN_PARYOLL_LIST_STATUS = [
	{
		value: "draftPayrolls",
		label: "Previewed (Draft)",
	},
	{
		value: "openPayrolls",
		label: "Generated (Open)",
	},
	...DRIVER_PAYROLL_LIST_STATUS,
	{
		value: "rollbackPayrolls",
		label: "Canceled (Rollback)",
	},
	{
		value: "allNonDraft",
		label: "Show All (Non Draft)",
	},
];
const ENTERPRISE_PAYROLL_LIST_STATUSES = [
	{
		value: "open",
		label: "Open",
	},
	{
		value: "closed",
		label: "Closed",
	},
	{
		value: "approved",
		label: "Approved",
	},
];

export {
	EFS_TIMEZONE,
	FUEL_CUSTOM_PIN,
	FUEL_TRUCK_ID,
	FUEL_PIN_VALIDATION_TYPE,
	PAYROLL_PAYMENT_TYPE_LABEL,
	PAYROLL_PAYMENT_TYPE_PERCENTAGE,
	PAYROLL_STATUS_LABEL,
	PAYROLL_PAYMENT_TYPE_MILES,
	READY_FOR_PAYROLL_STATUS,
	PAID_TO_DRIVER_STATUS,
	PAYROLL_RECURRING_DEDUCTION,
	DRIVER_PAYROLL_LIST_STATUS,
	ADMIN_PARYOLL_LIST_STATUS,
	ENTERPRISE_PAYROLL_LIST_STATUSES,
	DISTRIBUTION_PRIORITY,
};
