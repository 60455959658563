// Dependencies
import { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { refreshDialog } from "app/store/tools/formDialogSlice";
import { isCarrierPermission, getRoleLabel } from "app/services/LoginService";

// Utils
import { readURLParameters, rewriteURLParameters } from "app/main/utils/urlUtils";

// Services
import { getFuelTransactionById } from "app/services/fuelServices";

// Components
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import FuelTransactionPayrollView from "./FuelTransactionPayrollView";
import FuelTransactionSummaryView from "./FuelTransactionSummaryView";

// Lists
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";

const FuelTransactionView = forwardRef(({ nativeMobile, dataIds, setTitle, setSize }, ref) => {
	useEffect(() => {
		setTitle?.(`Fuel Transaction Details`, dataIds?.label);
		// eslint-disable-next-line
	}, [dataIds?.label]);

	const dispatch = useDispatch();
	const role = useMemo(() => getRoleLabel(), []);
	const user = useSelector(({ auth }) => auth.user);

	// States
	const [processing, setProcessing] = useState(false);
	const [fuelTransactionDetails, setFuelTransactionDetails] = useState();
	const [error, setError] = useState();

	// Consts
	const isCarrier = useMemo(() => isCarrierPermission(role), [role]);
	const isDriver = user?.rolesLabels?.includes("driver");
	const isHistoryItem = !!dataIds.historyData;
	const transactionId = dataIds.transactionId;
	const carrierId = dataIds.carrierId;

	// Revision
	const payrollRevision = useSelector(({ tools }) => tools.revision["payrollRevision"] ?? 0);
	const dataRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);
	const tripsRevision = useSelector(({ tools }) => tools.revision["tripsRevision"] ?? 0);
	const fuelRevision = useSelector(({ tools }) => tools.revision["fuelRevision"] ?? 0);

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			if (transactionId && isCarrier) {
				btns.edit = {
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								formId: "FUEL_TRANSACTION_FORM",
								mode: "EDIT",
								dataIds: { transactionId, carrierId },
							})
						);
					},
					title: "Edit Transaction",
				};
			}

			dispatch(refreshDialog());
			return btns;
		},
		// eslint-disable-next-line
		[transactionId]
	);

	useEffect(() => {
		const params = readURLParameters();
		rewriteURLParameters({ tab: 0 });
		setProcessing(false);
		return () => {
			rewriteURLParameters(params);
		};
	}, []);

	const fetchFuelTransactionData = async () => {
		try {
			const fuelTransactionData = await getFuelTransactionById(transactionId);
			setFuelTransactionDetails({ ...fuelTransactionData });
		} catch (err) {
			setError(err.errors?.[0]?.message);
		} finally {
			setProcessing(false);
		}
	};

	useEffect(() => {
		setProcessing(true);
		fetchFuelTransactionData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payrollRevision, transactionId, dataRevision, tripsRevision, fuelRevision]);

	if (!fuelTransactionDetails || error) {
		return (
			<SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={!fuelTransactionDetails} error={error} />
		);
	}

	return isHistoryItem ? (
		<></>
	) : (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			processing={processing}
			tabComponents={[
				{
					key: "SUMMARY",
					title: "Summary",
					component: (
						<div className="flex flex-col w-full">
							<FuelTransactionSummaryView
								dataIds={{ ...dataIds }}
								isView={true}
								fuelTransactionDetails={fuelTransactionDetails}
								processing={processing}
								isCarrier={isCarrier}
								isDriver={isDriver}
							/>
						</div>
					),
				},
				{
					key: "PAYROLL",
					title: "Payroll",
					component: (
						<div className="flex flex-col w-full">
							<FuelTransactionPayrollView dataIds={{ ...dataIds }} isView={true} processing={processing} />
						</div>
					),
				},
			]}
		></SmarthopDialogViewContainer>
	);
});

export default FuelTransactionView;
