import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";

export default build([
	{
		path: "/billing/details",
		component: lazy(() => import("./BillingPage")),
	},
	{
		path: "/billing/details/:carrierId/payments",
		component: lazy(() => import("./BillingPage")),
	},
]);
