const chat = {
	urlPOST: "api/connection/users/:id/socket/action",
	idKey: "_id",
	header: {
		create: { label: "Create Socket Action" },
	},
	content: {
		create: {
			items: [
				{
					key: "action",
					type: "select",
					label: "Action Type",
					required: true,
					defaultValue: "SEND_PING",
					options: [
						{
							value: "SEND_PING",
							label: "Send Ping Message",
							translate: "",
							showItems: [
								{
									key: "message",
									type: "text",
									label: "Message",
									required: true,
								},
							],
						},
						{
							value: "KILL_CONNECTION",
							label: "Kill Connection",
							translate: "",
						},
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default chat;
