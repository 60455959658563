import { useState, useMemo } from "react";
import Button from "@material-ui/core/Button";
import {
	Checkbox,
	DialogContentText,
	DialogContent,
	Dialog,
	DialogActions,
	DialogTitle,
	Select,
	Typography,
	MenuItem,
	ListItemText,
	Box,
	Chip,
	OutlinedInput,
	FormControl,
	InputLabel,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import { ArrayUtils } from "app/main/utils/arrayUtils";

const PlatformCancelationDialog = (props) => {
	const [additionalComments, setAdditionalComments] = useState("");
	const [isDirtyComments, setIsDirtyComments] = useState(false);
	const [selectedList, setSelectedList] = useState([]);
	const [loading, setLoading] = useState(false);

	const open = props.open;
	const onClose = props.onClose;
	const onAccept = props.onAccept;

	const OTHER = "OTHER";

	const shuffleableOptions = useMemo(
		() =>
			ArrayUtils.shuffle([
				{ value: "CUSTOMER_SERVICE_ISSUE", label: "Customer Service issue" },
				{ value: "STATE_OF_BUSINESS", label: "State of business" },
				{ value: "PRODUCT_DIFFICULTY", label: "Difficulty in using the product (usability issues)" },
				{ value: "CHEAPER_ALTERNATIVES", label: "Found cheaper alternatives" },
				{ value: "LOADBOARD_REFRESH_RATE", label: "Insufficient load-board refresh rate" },
				{ value: "FULL_TMS_NOT_REQUIRED", label: "Not requiring a full TMS feature set" },
				{ value: "CANNOT_FIND_LOADS_OR_EQUIPMENT", label: "Inability to find different loads or equipment" },
			]),
		[]
	);
	const options = [...shuffleableOptions, { value: OTHER, label: "Other" }];

	const selectHanlder = (event, newValue) => {
		// If action is selected, removing it from set of current options
		const value = newValue.props?.value;
		const selectedAction = selectedList.find((option) => option?.value === value?.value);
		let newSelectedList = [...selectedList];
		if (!selectedAction) {
			newSelectedList.push(value);
		} else {
			newSelectedList = selectedList.filter((option) => option?.value !== value?.value);
		}
		setSelectedList(newSelectedList);
	};

	const onAcceptHandler = () => {
		setLoading(true);
		onAccept(selectedList, additionalComments);
		setLoading(true);
	};

	const valueLength = additionalComments?.length;
	let error = isDirtyComments && valueLength < 24;
	let msgError = "Additional comments are required and the number of characters must be greater than 25.";

	return (
		<div className={loading ? " opacity-60 pointer-events-none " : ""}>
			<Dialog
				open={open}
				keepMounted
				onClose={onClose}
				maxWidth="md"
				aria-describedby="alert-dialog-slide-description"
				PaperProps={{
					square: true,
					variant: "outlined",
				}}
			>
				<DialogTitle>
					<Typography className="tracking-wide text-16 text-grey-800 px-2 -mb-6">{"Cancel Subscription"}</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText component={"div"} classes={{ root: "text-14 px-2 pt-12 mb-14" }}>
						{
							"Please select your reason for cancelation. Please note, your access will remain available until your end of service date."
						}
					</DialogContentText>
					<FormControl variant="outlined" className="w-full pr-0">
						<InputLabel>Reasons</InputLabel>
						<Select
							multiple
							className={"w-full pr-0 pl-0"}
							value={selectedList}
							onChange={selectHanlder}
							input={<OutlinedInput label="Reasons" />}
							disabled={loading}
							renderValue={(selectedList) => (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selectedList.map((item) => (
										<Chip key={item.value} label={item.label} />
									))}
								</Box>
							)}
						>
							{options.map((opt) => (
								<MenuItem key={opt.value} value={opt}>
									<Checkbox checked={!!selectedList?.find((selected) => selected?.value === opt.value)} />
									<ListItemText primary={opt.label} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						error={error}
						required={true}
						variant="outlined"
						type="text"
						placeholder={"Addtional comments..."}
						className="w-full mt-16"
						value={additionalComments}
						multiline
						rows={6}
						onChange={(event) => {
							setAdditionalComments(event.target.value);
						}}
						onBlur={() => setIsDirtyComments(true)}
						helperText={error ? msgError : null}
						disabled={loading}
					/>
				</DialogContent>
				{(!!onClose || !!onAccept) && (
					<DialogActions>
						<div className="mx-6 mb-14">
							{!onClose ? null : (
								<Button
									className="tracking-wide min-w-120 ml-8 text-14 font-medium text-grey-800"
									variant="outlined"
									onClick={onClose}
									disabled={loading}
								>
									Cancel
								</Button>
							)}
							{!onAccept ? null : (
								<Button
									className="tracking-wide min-w-120 ml-8 text-14 font-medium"
									variant="outlined"
									color="secondary"
									onClick={() => onAcceptHandler()}
									disabled={!selectedList?.length || (!isDirtyComments && valueLength < 24) || error || loading}
								>
									{!loading ? "Continue" : <CircularProgress color="inherit" size="1.8em" />}
								</Button>
							)}
						</div>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default PlatformCancelationDialog;
