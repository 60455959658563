import { Typography } from "@material-ui/core";
import { isAllStepsCompleted } from "./OnboardSteps/onboardUtil";
import { useMemo } from "react";
import { getConfigurationOnboardingStatus } from "../utils/onboardingUtils";

function OnboardingSidebarDescription({ config, data }) {
	const configurationOnboardingStatus = useMemo(
		() => getConfigurationOnboardingStatus({ ...data, isAllStepsCompleted: isAllStepsCompleted({ config, data }) }),
		[config, data]
	);

	return (
		<>
			<Typography className={"text-13 -mt-8 font-bold mb-10 " + configurationOnboardingStatus.colorText}>
				{configurationOnboardingStatus.text}
			</Typography>
			<Typography color="textSecondary" className="text-13">
				{configurationOnboardingStatus.leftHeadText}
			</Typography>
			{configurationOnboardingStatus.leftTextBulletPoints?.map && (
				<div className="flex flex-col w-full items-start">
					{configurationOnboardingStatus.leftTextBulletPoints?.map?.((bulletPoint, i) => {
						return (
							<Typography key={"text_bullet_points_" + i} className="text-red font-medium text-13 pt-10">
								&#x2022; {bulletPoint}
							</Typography>
						);
					})}
				</div>
			)}
		</>
	);
}

export default OnboardingSidebarDescription;
