import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Typography, Icon, Button, makeStyles, CircularProgress } from "@material-ui/core";

import { isTestCarrier, getCarrierId, getUserTier } from "app/services/LoginService";

import { openLoadedFormDialog, openFormDialog } from "app/store/tools/formDialogSlice";
import { checkValidURL } from "app/main/search/searchUtils";
import { formatAge } from "app/main/utils/dateUtils";

const useStyles = makeStyles((theme) => {
	return {
		rootTable: {
			width: "100%",
			height: "calc(100vh - 250px) !important",
		},
		rootGridExt: {
			width: "100%",
			height: "calc(100vh - 210px) !important",
		},
		rootGridInt: {
			width: "100%",
			height: "calc(100vh - 150px) !important",
		},
		container: {
			width: "100%",
		},
		containerInformation: {
			height: "-webkit-fill-available",
		},
		"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
		flicker: {
			animationName: "$flicker",
			animationDuration: "600ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		gradient: {
			"--tw-gradient-from": "#fff var(--tw-gradient-from-position)",
			"--tw-gradient-from-position": " ",
			"--tw-gradient-to": "rgb(255 255 255 / 0) var(--tw-gradient-from-position)",
			"--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
		},
	};
});

function LoadSearchActions({
	flicker,
	loadToUse,
	permission,
	restrictions,
	handleAction,
	openFullScreen,
	openConfirmDialog,
	saving = false,
	fullDetailsMode = false,
	collapsable = true,
	truckData,
}) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isUserTestCarrier = useMemo(() => isTestCarrier(), []);
	const showBid = useMemo(
		() => (loadToUse?.bidnow || (loadToUse?.booknow && !loadToUse?.external)) && permission?.book,
		[loadToUse, permission]
	);
	const showBookExternal = useMemo(
		() =>
			!loadToUse?.bidnow &&
			loadToUse?.booknow &&
			loadToUse.external &&
			permission?.book &&
			checkValidURL(loadToUse.url),
		[loadToUse, permission]
	);

	const bidBtn = useMemo(
		() => (
			<Button
				className={
					"my-2 py-4 ml:py-6 text-12 ml:text-13 text-white bg-purple-800 h-32 ml:h-36  " +
					(flicker ? classes.flicker : "")
				}
				color={"default"}
				disabled={flicker || saving}
				variant="contained"
				onClick={() => {
					if (getUserTier() === "TIER_STARTER") {
						dispatch(
							openFormDialog({
								viewId: "PAYWALL_VIEW",
								dataIds: { type: "digital_booking_restricted", data: { callback: openConfirmDialog } },
							})
						);
						return;
					}
					if (!restrictions?.bookCarrierActive) {
						return openConfirmDialog("RESTRICTION_BOOK_CARRIER_STATUS");
					}
					openConfirmDialog("BID");
				}}
				startIcon={<Icon className="my-4">flash_on</Icon>}
			>
				{`${loadToUse?.bidnow ? "Bid Online" : "Book Online"} ${isUserTestCarrier ? "(Test)" : ""} `}
			</Button>
		),
		// eslint-disable-next-line
		[flicker, saving, classes, isUserTestCarrier, openConfirmDialog, loadToUse, restrictions]
	);

	const tripBtn = useMemo(
		() => (
			<Button
				className={
					"flex my-2 py-4 ml:py-6 text-12 ml:text-13 bg-secondary text-white h-32 ml:h-36 " +
					(flicker ? classes.flicker : "")
				}
				disabled={flicker || saving}
				variant="contained"
				onClick={() => openConfirmDialog("TRIP")}
				startIcon={<Icon>local_offer</Icon>}
			>
				Create Trip
			</Button>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[openConfirmDialog, saving, flicker]
	);

	const bookBtn = useMemo(
		() => (
			<Button
				className={
					"my-2 py-4 ml:py-6 text-12 ml:text-13 bg-purple-800 text-white h-32 ml:h-36 " +
					(flicker ? classes.flicker : "")
				}
				disabled={flicker || saving}
				variant="contained"
				onClick={() => {
					if (getUserTier() === "TIER_STARTER") {
						dispatch(
							openFormDialog({
								viewId: "PAYWALL_VIEW",
								dataIds: { type: "book_externally_restricted", data: { callback: handleAction } },
							})
						);
						return;
					}

					return handleAction("EXTERNAL_BOOK");
				}}
				startIcon={<Icon>flash_on</Icon>}
			>
				Book Online (Ext.)
			</Button>
		),
		// eslint-disable-next-line
		[flicker, classes, saving, handleAction]
	);

	if (loadToUse.actionInProgress) {
		return (
			<div className="flex flex-row justify-center items-center w-full pb-18 pt-4 ml:pt-2">
				<CircularProgress size={16} className="mr-16 text-14 text-grey" />
				<Typography className="text-grey text-12 ml:text-13">
					{loadToUse.actionType === "TRIP" ? "Creating New Trip..." : "Booking Load..."}
				</Typography>
			</div>
		);
	}

	return (
		<>
			{loadToUse?.deleted && !loadToUse?.covered_by_trip && (
				<div
					className={`flex flex-row py-4 px-8 mx-3 mt-4 bg-red rounded-lg items-center w-full ${
						!fullDetailsMode ? "cursor-pointer" : ""
					}`}
					onClick={() => !fullDetailsMode && openFullScreen()}
				>
					<Icon className="text-24 text-white mr-10 ml-6">warning</Icon>
					<Typography className="text-11 ml:text-12 text-white p-5">
						This load has been covered {loadToUse.deletedAt ? "(" + formatAge(loadToUse.deletedAt) + " ago)" : ""} and
						no longer available
					</Typography>
				</div>
			)}
			{loadToUse?.deleted && loadToUse?.covered_by_trip && (
				<Button
					className={"my-2 text-white bg-green w-full h-32 ml:h-36"}
					onClick={() =>
						dispatch(
							openLoadedFormDialog({
								viewId: "TRIP_VIEW",
								dataIds: { tripId: loadToUse?.covered_by_trip, carrierId: truckData?.carrier?._id ?? getCarrierId() },
							})
						)
					}
					variant="contained"
					startIcon={<Icon>local_offer</Icon>}
				>
					View Trip
				</Button>
			)}
			{!loadToUse?.deleted && (
				<div className={fullDetailsMode ? "flex flex-row w-full" : "flex flex-col-reverse w-full"}>
					<div className={fullDetailsMode ? "w-full flex flex-row space-x-8 justify-end" : "flex flex-col w-full"}>
						{showBid && bidBtn}
						{showBookExternal && bookBtn}
						{tripBtn}
					</div>
				</div>
			)}
		</>
	);
}

export default LoadSearchActions;
