import { SmarthopForm, registy } from "@smarthop/form";
import { useEffect } from "react";

const AddPlanAddonView = (props) => {
	const { carrierId } = props.dataIds;

	useEffect(() => {
		props?.setTitle?.("Add Plan Add-on");
		props?.setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="flex flex-col items-top w-full space-x-0">
				<SmarthopForm
					key={"carrier_add_plan_addon"}
					mode={"CREATE"}
					form={registy["CARRIER_PLATFORM_ADDON_PLAN_FORM"]}
					dataIds={{ carrierId }}
					trackChangedFields={["ALL"]}
				/>
			</div>
		</>
	);
};

export default AddPlanAddonView;
