import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";

export default build([
	{
		path: "/admin/analytics/builder",
		component: lazy(() => import("./AdminAnalyticsBoardBuilderPage")),
	},
	{
		path: "/admin/analytics/metrics",
		component: lazy(() => import("./AdminAnalyticsBoardMetricsPage")),
	},
]);
