// Dependencies
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import createForm from "./enterprisePayrollJournalEntryConf";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Services
import { editJournalEntriesByPayrollId, getJournalEntriesByPayrollId } from "app/services/enterprisePayrollServices";

const EnterprisePayrollJournalEntryDetailsView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Payroll Additions/Deductions");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();

	const [data, setData] = useState();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);
	const snackbar = useSnackbar();

	const dataIds = props.dataIds;

	const userId = dataIds.userId;
	const payrollId = dataIds?.payrollId;
	const entryId = dataIds?.entryId;
	const payeeType = dataIds.payeeType;
	const isInvestor = dataIds.isInvestor;

	const mode = "EDIT";
	const title = "Addition/Deduction";

	useEffect(() => {
		(async () => {
			try {
				if (entryId && payrollId) {
					setLoading(true);
					const data = await getJournalEntriesByPayrollId({ payrollId, entryId, userId, payeeType });
					data.payroll_source = data?.payroll__view?.label;
					setData(data);
					setLoading(false);
				}
			} catch (e) {
				console.error(e);
				setErrors([]);
			}
		})();
	}, [payrollId, entryId, payeeType, userId]);

	const handleSubmit = async () => {
		let message, success;
		try {
			await editJournalEntriesByPayrollId({ payrollId, entryId, data });

			dispatch(closeFormDialog());
			dispatch(incrementDataRevision({ event: "payrollRevision" }));

			message = `${title} updated`;
			success = true;
		} catch (e) {
			message = `${e.errors?.[0]?.message} Contact support`;
			success = false;
		}
		showSnackbar(snackbar, message, success ? "success" : "error", {
			newSnackBar: true,
			duration: 5000,
			newSnackBarUseGreenForSuccess: true,
		});
	};

	const customFormHandler = async (model) => {
		let newData = { ...data, ...model };
		setData(newData);
	};

	let form = createForm({ ...dataIds, errors, mode, data, isInvestor });

	return (
		<SmarthopFormView
			mode={mode}
			data={data}
			content={form}
			errors={errors}
			loading={loading}
			noInitValidation={true}
			dataIds={dataIds}
			trackChangedFields={["ALL"]}
			onChangeCommitted={customFormHandler}
			onSubmit={handleSubmit}
		>
			{props.children}
		</SmarthopFormView>
	);
};

export default EnterprisePayrollJournalEntryDetailsView;
