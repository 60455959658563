const analyticsPeriodsList = {
	urlGET: "api/analytics/data/periods",
	idKey: "_id",
	listenEvent: "analyticsJobsRevision",
	segmentKey: "ADMIN_ANALYTICS_PERIODS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["periodKey"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "periodKey",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "metric",
					type: "autocomplete",
					label: "Metric",
					autocomplete: {
						provider: "smarthop",
						url: "api/analytics/data/metrics",
						preloadDefault: true,
					},
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show System Fields",
				},
				{
					key: "aggregation",
					type: "checkbox",
					label: "Show Aggregation Groups",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "metric", type: "id", label: "Metric ID", group: "internal" },
			{ key: "metric__view.label", type: "metric__view", builder: (item) => item.metric__view, label: "Metric" },
			{ key: "periodKey", type: "text", label: "Period Key" },
			{ key: "periodDate", type: "text", label: "Period Date", group: "internal" },
			{ key: "startAt", type: "date", label: "Start" },
			{ key: "startAt", type: "text", label: "Start UTC", group: "internal" },
			{ key: "endAt", type: "date", label: "End" },
			{ key: "endAt", type: "text", label: "End UTC", group: "internal" },
			{ key: "size", type: "text", label: "Size" },
			{ key: "groups.hour", type: "text", label: "Hour Group", group: "aggregation" },
			{ key: "groups.day", type: "text", label: "Day Group", group: "aggregation" },
			{ key: "groups.week", type: "text", label: "Week Group", group: "aggregation" },
			{ key: "groups.month", type: "text", label: "Month Group", group: "aggregation" },
			{ key: "groups.year", type: "text", label: "Year Group", group: "aggregation" },
		],
	},
};

export default analyticsPeriodsList;
