const changePasswordForm = {
	urlPOST: "api/profile/users/:userId/password",
	idKey: "_id",
	header: {
		create: { label: "Change Password" },
	},
	content: {
		create: {
			items: [
				{ key: "current_password", type: "password", label: "Current Password", translate: "PASSWORD", required: true },
				{ key: "password", type: "password", label: "Password", translate: "PASSWORD", required: true },
				{
					key: "passwordConfirm",
					type: "passwordConfirm",
					label: "Confirm Password",
					translate: "CONFIRM_PASSWORD",
					required: true,
				},
				{
					type: "action",
					action: "SUBMIT",
					label: "Change Password",
				},
			],
		},
	},
};

export default changePasswordForm;
