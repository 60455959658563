import moment from "moment";

const FORMAT_DATE = "DD MMM (ddd) YYYY LT";

/** Convert Fields to a Text
 *  @param loadData
 *  @param copyFields
 */
const convertFieldsToText = (loadData, copyFields = [], converToHTML = false, plainText = false) => {
	let textFields = [],
		htmlFields = [];

	const getPlainAndHTML = converToHTML && plainText;
	const variantOptions = {
		price: (value) => `$${value}`,
		weight: (value) => `${value}lbs`,
		date: (value) => `${moment(value).format(FORMAT_DATE)}`,
		stop: (value, date) => `${value}. ${date ? moment(date).format(FORMAT_DATE) : ""}`,
		join: (value, value2) => `${value} - ${value2}`,
	};

	const getTextFromField = (value, label, options, loadData) => {
		let text = value ?? "-";
		if (options.variant && variantOptions[options.variant] && value)
			text = variantOptions[options.variant](value, loadData[options?.variantValue]);

		let plainText = `${label}: ${text}`;
		let html = `<p><strong>${label}</strong>: ${text}</p>`;
		return getPlainAndHTML ? [html, plainText] : converToHTML ? html : plainText;
	};
	const addToArrayResponse = (value, label, options = {}, loadData) => {
		let fieldToText = getTextFromField(value, label, options, loadData);
		if (getPlainAndHTML) {
			htmlFields.push(fieldToText?.shift());
			fieldToText = fieldToText?.pop();
		}
		textFields.push(fieldToText);
	};

	copyFields.forEach((copyProperties) => {
		let { accessor, label, ...options } = copyProperties;
		const value = loadData[accessor];
		if (options.variant === "locations") {
			value?.forEach(({ location, location_date, type }, idx) => {
				addToArrayResponse(
					location,
					`Stop#${idx + 1} (${type ?? ""})`,
					{
						variant: "stop",
						variantValue: "location_date",
					},
					{ location_date }
				);
			});
		} else {
			addToArrayResponse(value, label, options, loadData);
		}
	});

	return getPlainAndHTML
		? [htmlFields?.join(""), textFields?.join("")]
		: converToHTML
		? textFields?.join("")
		: textFields;
};

const openDialogTitle = (type) => {
	let message;
	if (type === "TRIP") {
		message = "Create a Trip for the Load?";
	} else if (type === "COVERED") {
		message = "Are you sure you want to mark as covered?";
	} else if (["RESTRICTION_BOOK_CARRIER_HAS_MCNUMBER", "RESTRICTION_BOOK_CARRIER_STATUS"].includes(type)) {
		message = "Restriction";
	}

	return message;
};

const openDialogMessage = (type) => {
	let message = null;
	if (type === "RESTRICTION_BOOK_CARRIER_HAS_MCNUMBER") {
		message =
			"You can not book loads digitally before we complete the validation of your onboarding information, if you have any questions please reach our support team.";
	} else if (type === "RESTRICTION_BOOK_CARRIER_STATUS") {
		message = "You can not book loads digitally because the carrier or truck is not active.";
	} else if (type === "TRIP") {
		message = "Please make sure you contact the broker and book this load before creating the trip.";
	}
	return message;
};

const handledActionMessage = (actionType) => {
	let message = "Finished";

	if (actionType === "VERIFY") {
		message = "Verified Load";
	} else if (actionType === "SAVE") {
		message = "Load Information Saved";
	} else if (actionType === "RECALCULATE") {
		message = "Load Recalculated";
	} else if (actionType === "TRIP") {
		message = "Trip Created";
	} else if (actionType === "SHARE") {
		message = "Load has been shared";
	}

	return message;
};

export { convertFieldsToText, openDialogTitle, openDialogMessage, handledActionMessage };
