const commentViewContentForm = {
	urlGET: "api/comments/:commentId",
	idKey: "_id",
	triggerEvent: [],
	listenEvent: "",
	header: {
		view: { label: "Content" },
	},
	content: {
		view: {
			items: [
				{
					key: "extraContent",
					builder: (item) => <div className="mb-12">{item.extraContent}</div>,
					viewOnly: true,
					type: "text",
					label: "",
					component: { preview: { paragraph: true } },
				},
			],
		},
	},
};

export default commentViewContentForm;
