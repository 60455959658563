const integrationUpdateStatusForm = {
	urlPUT: "api/profile/carriers/:carrierId/integrations/brokers/:brokerId/status",
	urlGET: "api/profile/carriers/:carrierId/integrations/brokers/:brokerId/status",
	idKey: "_id",
	triggerEvent: "profileRevision",
	header: {
		edit: { label: "Update Status Integration" },
	},
	content: {
		edit: {
			items: [
				{
					key: "statusExternal",
					label: "Status",
					type: "select",
					required: true,
					options: [
						{ value: "ACCEPTED", label: "Accepted" },
						{
							value: "REJECTED",
							label: "Rejected",
							showItems: [
								{
									key: "rejectReason",
									label: "Reason",
									required: true,
									type: "text",
								},
							],
						},
						{ value: "REQUESTED", label: "Requested" },
					],
				},
				{ type: "action", label: "Update" },
			],
		},
	},
};

export default integrationUpdateStatusForm;
