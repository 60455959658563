import { Icon, Typography } from "@material-ui/core";
import { formatCurrency } from "app/main/utils/tableUtils";

const buildDiscountStatus = (item) => {
	const style = { icon: item.active ? "done" : "close", color: item.active ? "text-green" : "text-red" };

	return (
		<div className="flex justify-center items-center">
			<Icon className={`${style.color} m-0 pr-4 text-24`}>{style.icon}</Icon>
			<Typography className="text-12 ml:text-13 pl-8 pr-4">{item.active ? "Active" : "Inactive"}</Typography>
		</div>
	);
};

const carrierDiscountsList = ({ isAdmin }) => ({
	urlGET: "api/discounts/carriers/:carrierId/list",
	idKey: "_id",
	listenEvent: "discountRevision",
	segmentKey: "ADMIN_PLATFORM_DISCOUNTS",
	content: {
		table: {
			variant: "skinny",
			theme: "dark",
			noHeader: false,
			noBottomBorder: true,
			orderBy: ["createdAt"],
			staticHeight: isAdmin ? 400 : undefined,
		},
		pagination: {
			enabled: false,
			resetScroll: true,
		},
		filters: {
			search: false,
		},
		sort: {
			key: "created_at",
			dir: "desc",
		},
		isHighlighted: (item) => !!item.active,
		items: [
			{ key: "status", type: "component", label: "Status", builder: (item) => buildDiscountStatus(item) },
			{ key: "created_at", type: "date", label: "Added On" },
			{ key: "customer_id", type: "id", label: "Customer ID", group: "internal" },
			{
				label: "Carrier",
				key: "carrier__view",
				type: "carrier__view",
				group: "internal",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				label: "Email",
				key: "email",
				type: "email",
				group: "internal",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				key: "couponData.name",
				type: "text",
				label: "Description",
				table: { width: "50%" },
			},
			{
				key: "amount",
				type: "text",
				label: "Amount",
				table: { align: "right" },
				builder: (item) =>
					item?.couponData?.percent_off
						? `${item.couponData.percent_off}%`
						: item?.couponData?.amount_off
						? formatCurrency(item.couponData.amount_off / 100)
						: "-",
			},
			{
				key: "couponData.duration",
				type: "text",
				label: "Duration",
				builder: (item) => {
					const duration = item.couponData?.duration;
					const durationInMonths = item.couponData?.duration_in_months ?? 0;

					return duration === "once"
						? "One time"
						: duration === "repeating"
						? `${durationInMonths} month${durationInMonths > 1 ? "s" : ""}`
						: "Permanent";
				},
			},
			{
				key: "disabled",
				type: "checkbox",
				label: "Status",
				group: "internal",
				builder: (item) => (!item.active ? "Disabled" : "Active"),
			},
			...(isAdmin
				? [
						{
							key: "menu",
							type: "menu",
							label: "Menu",
							items: [
								{
									key: "deactivate",
									label: "Deactivate",
									isVisible: (item) => item.active,
									dialogBuilder: (item) => ({
										formId: "APPLY_COUPON_FORM",
										mode: "DELETE",
										dataIds: { id: item._id },
									}),
								},
							],
						},
				  ]
				: []),
		],
	},
});

export default carrierDiscountsList;
