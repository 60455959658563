import { Typography } from "@material-ui/core";

// Services
import { getRoleLabel } from "app/services/LoginService";

const getConfigurationOnboardingStatus = ({
	onboardingStatus = "INCOMPLETE",
	onboardingRejectionReason,
	onboardingPreselects,
	isAllStepsCompleted = false,
}) => {
	onboardingStatus = getRoleLabel() === "carrier" ? onboardingStatus : "DEFAULT_DRIVER_AND_MANAGER";

	let onboardingConfiguration = {
		INCOMPLETE: {
			naviationgLink: { url: "/onboarding", text: "onboarding" },
			componentInfo: "Before you can book a load and access this page, complete the rest of your ",
			colorBanner: "warning",
			bannerTextMobile: "Complete onboarding",
			bannerText: "Please complete onboarding to book your first load using SmartHop.",
			leftHeadText: `To start using smarthop platform please complete onboarding. It will take you less than 3 minutes.`,
			text: isAllStepsCompleted ? "Ready To Submit" : "Incomplete",
			colorText: isAllStepsCompleted ? " text-blue-700 " : " text-grey-600 ",
			textSubmited:
				onboardingPreselects?.planId && onboardingPreselects?.isTrialAllowed
					? "Please complete all steps and click submit to start your Free Trial"
					: "Please complete all steps and click submit to complete onboarding",
			textBulletPoints:
				onboardingPreselects?.planId && onboardingPreselects?.isTrialAllowed
					? ["No Credit Card required for the Free Trial", "Cancel anytime. No hidden fees ever"]
					: null,
		},
		IN_REVIEW: {
			componentInfo: (
				<Typography variant="h6" className="text-center font-light">
					Your onboarding process is <strong className={"text-orange-700"}>Done</strong>. Now let's get you started with
					Smarthop.
				</Typography>
			),
			colorBanner: "warning",
			bannerText: "Your onboarding is done. Let's get you started with SmartHop.",
			bannerTextMobile: "Getting Started",
			text: "Getting Started",
			colorText: "text-orange-700",
			textSubmited: "Your onboarding is done. Let's get you started with SmartHop.",
			leftHeadText: "Your onboarding is done. Let's get you started with SmartHop.",
		},
		CHANGES_REQUESTED: {
			componentInfo: (
				<>
					<Typography variant="h6" className="text-center font-light mb-10">
						Unfortunately, your onboarding process is in a state{" "}
						<strong className={"text-red-300"}>changes requested</strong>{" "}
						{onboardingRejectionReason
							? `for the
						following reason: ${onboardingRejectionReason}.`
							: "."}
						<br />
					</Typography>
					Please update your
				</>
			),
			naviationgLink: { url: "/onboarding", text: "onboarding" },
			colorBanner: "error",
			bannerTextMobile: "Update onboarding now!",
			bannerText: "Your onboarding application requires changes.",
			text: "Changes requested",
			colorText: "text-red-300",
			textSubmited: `Your onboarding information requires some changes. Update your onboarding information and click 'Submit' again. Required changes:`,
			textBulletPoints: [onboardingRejectionReason],
			leftHeadText: `Your onboarding information requires some changes. Update your onboarding information and click 'Submit' again. Required changes:`,
			leftTextBulletPoints: [onboardingRejectionReason],
		},
		ACCEPTED: {
			componentInfo: null,
			colorBanner: "",
			bannerTextMobile: "",
			bannerText: "",
			colorText: "text-green-700",
			textSubmited: null,
		},
		REJECTED: {
			componentInfo: (
				<>
					<Typography variant="h6" className="text-center font-light mb-10">
						Unfortunately, your onboarding process was <strong className={"text-red-600"}>rejected</strong>{" "}
						{onboardingRejectionReason ? `due to the following reason: ${onboardingRejectionReason}.` : "."}
					</Typography>
					<Typography variant="h6" className="text-center font-light">
						Please contact our customer service center to get more information
					</Typography>
				</>
			),
			colorBanner: "error",
			bannerTextMobile: "Onboarding rejected",
			bannerText: "Your onboarding was rejected. Please contact us.",
			text: "Rejected",
			colorText: "text-red-600",
			textSubmited: `Your application was rejected. Please contact us. Rejection reason:`,
			textBulletPoints: [onboardingRejectionReason],
			leftHeadText: `Your application was rejected. Please contact us. Rejection reason:`,
			leftTextBulletPoints: [onboardingRejectionReason],
		},
		DEFAULT_DRIVER_AND_MANAGER: {
			componentInfo:
				"Your access to the platform will automatically unlock once your carrier completes the onboarding process.",
			colorBanner: "warning",
			bannerText: "Your access will be unlocked once your carrier completes the onboarding",
			bannerTextMobile: "Your access will be unlocked once your carrier completes the onboarding",
			bannerLinkHide: true,
		},
	};

	return { ...onboardingConfiguration[onboardingStatus] };
};

export { getConfigurationOnboardingStatus };
