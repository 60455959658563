import { Button, Icon, Typography, Box } from "@material-ui/core";

export const FlexPathsError = ({ enableFlexPaths }) => (
	<>
		<h3>All paths are flex</h3>
		<Box sx={{ py: 2 }}>
			<Typography>
				This strategy only contains flex paths, which are paths that do not fully match the criteria with which the
				strategy was created. To view the flex paths open the configuration in the subpanel{" "}
				<Icon color="secondary">tune</Icon> and toggle the <Icon>visibility_off</Icon> icon to activate it.
			</Typography>
		</Box>
		<Button
			variant="outlined"
			color="inherit"
			startIcon={<Icon>visibility</Icon>}
			onClick={enableFlexPaths}
			sx={{ my: 2 }}
		>
			View Flex Paths
		</Button>
	</>
);

export const ExpiredStrategyError = ({ regenerate }) => (
	<>
		<Box sx={{ py: 2 }}>
			<Typography>
				The strategy you're trying to load has expired and market might have shifted. Please request a new one with the
				same paremeters.
			</Typography>
		</Box>
		<Button variant="outlined" color="inherit" startIcon={<Icon>refresh</Icon>} onClick={regenerate} sx={{ my: 2 }}>
			Request again
		</Button>
	</>
);

export const NoLatestStrategy = () => (
	<>
		<h3>No latest strategy</h3>
		<Box sx={{ py: 2 }}>
			<Typography>Currently there is no strategy associated with this truck.</Typography>
		</Box>
	</>
);
