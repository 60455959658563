import { updateConfig } from "@smarthop/utils/fieldsHelpers";
import { DRIVER_PAYROLL_FIELDS } from "app/main/profile/drivers/EnterprisePayrollSection";

export const carrierPayrollDriverConfigurationForm = {
	urlGET: "api/profile/carriers/:carrierId/details/payroll-settings",
	urlPUT: "api/profile/carriers/:carrierId/details/payroll-settings",
	idKey: "_id",
	triggerEvent: ["profileRevision", "payrollOnboardingRevision"],
	listenEvent: ["profileRevision", "payrollOnboardingRevision"],
	header: {
		edit: { label: "Payroll Configuration" },
	},
	content: {
		view: { sameAs: "edit" },
		create: { sameAs: "edit" },
		edit: {
			form: { noInitValidation: true },
			items: [
				...updateConfig(DRIVER_PAYROLL_FIELDS(), {
					type: "KEY_PREFIX",
					value: "payee_defaults_driver_company_driver_",
				}),
				{ type: "action", action: "SUBMIT", label: "Save" },
			],
		},
	},
};
