import ReactDOMServer from "react-dom/server";
import { Typography, Divider, Paper, Grid, AppBar, Toolbar } from "@material-ui/core";
import { LocalShipping } from "@material-ui/icons";
import moment from "moment";

const LineItem = ({ item }) => {
	return (
		<Grid item key={item.label}>
			<Typography
				style={{
					fontSize: "1.5rem",
					display: "inline",
					fontWeight: 600,
				}}
			>{`${item.label}: `}</Typography>
			<Typography
				style={{
					fontSize: "1.5rem",
					display: "inline",
				}}
			>{`${item.value ?? "No value"}`}</Typography>
		</Grid>
	);
};

const NodeSummaryTotal = ({ stop }) => {
	const {
		totalPayment,
		paymentRange,
		totalProfit,
		totalDuration,
		dailyProfit,
		totalLoadedMiles,
		totalEmptyMiles,
		totalRPM,
		rpmRange,
	} = stop;

	const lineItems = [
		{ label: "Total Payment", value: totalPayment },
		{ label: "Payment Range", value: paymentRange },
		{ label: "Total Profit", value: totalProfit },
		{ label: "Duration", value: totalDuration },
		{ label: "Daily Profit", value: dailyProfit },
		{ label: "Total Loaded Miles", value: totalLoadedMiles },
		{ label: "Total Empty Miles", value: totalEmptyMiles },
		{ label: "Total RPM", value: totalRPM },
		{ label: "Average RPM", value: rpmRange },
	];

	return (
		<>
			<Divider
				style={{
					marginTop: 10,
					marginBottom: 10,
				}}
			/>
			<Typography
				align="center"
				style={{
					fontSize: "1.5rem",
					fontWeight: 100,
					marginBottom: 10,
				}}
			>
				Potential Future Earnings
			</Typography>
			{lineItems.map((item) => (
				<LineItem item={item} />
			))}
		</>
	);
};

export const NodeSummary = ({ stop, i }) => {
	const daysDiff = { label: "Days Diff", value: moment.utc(stop.end_date).diff(stop.start_date, "d") };
	const hoursDiff = { label: "Hours Diff", value: moment.utc(stop.end_date).diff(stop.start_date, "h") };
	const hoursRemainder = { label: "Hours Remainder", value: +hoursDiff.value % 24 };
	const minRpm = {
		label: "Minimum RPM",
		value: stop.loaded_miles ? (stop.min_payment / stop.loaded_miles).toFixed(2) : 0,
	};
	const maxRpm = {
		label: "Maximum RPM",
		value: stop.loaded_miles ? (stop.max_payment / stop.loaded_miles).toFixed(2) : 0,
	};

	const emptyMiles = { label: "Empty Miles", value: stop.empty_miles };
	const loadedMiles = { label: "Loaded Miles", value: stop.loaded_miles };
	const payment = { label: "Payment", value: stop.payment };
	const profit = { label: "Profit", value: stop.profit };
	const stopOriginCity = { label: "Origin City", value: stop.o_city };
	const stopStartDate = { label: "Start Date", value: moment(stop.start_date).format("YYYY-MM-DD HH:mm") };
	const stopEndDate = { label: "End Date", value: moment(stop.end_date).format("YYYY-MM-DD HH:mm") };
	const avgVolume = { label: "Average Volume", value: stop.avg_volume };

	const listItems = [
		stopOriginCity,
		daysDiff,
		hoursDiff,
		hoursRemainder,
		minRpm,
		maxRpm,
		payment,
		profit,
		emptyMiles,
		loadedMiles,
		stopStartDate,
		stopEndDate,
		avgVolume,
	];
	const rootItems = [stopOriginCity, stopStartDate, stopEndDate];

	return (
		<>
			<Paper
				elevation={24}
				style={{
					borderRadius: "10px",
					minWidth: 320,
				}}
			>
				<AppBar
					position="static"
					style={{
						backgroundColor: "#E7683D",
						borderRadius: "10px 10px 0px 0px",
						marginBottom: "10px",
					}}
				>
					<Toolbar
						style={{
							justifyContent: "space-around",
							fontSize: "3rem",
						}}
					>
						<LocalShipping edge="start" fontSize="inherit" />
						<Typography
							style={{
								fontSize: "2.8rem",
								color: "#ffffff",
							}}
							variant="h5"
						>
							Move summary
						</Typography>
					</Toolbar>
				</AppBar>
				<Grid container direction="column" spacing={1} style={{ padding: "10px 25px" }}>
					<Typography
						align="center"
						variant="h3"
						style={{
							fontSize: "2rem",
							fontWeight: 600,
							marginBottom: 10,
						}}
					>
						{i ? stop.d_city : stop.o_city}
					</Typography>
					{i
						? listItems.map((item, itemIndex) => <LineItem item={item} key={(item?.value ?? "") + itemIndex} />)
						: rootItems.map((item, itemIndex) => <LineItem item={item} key={(item?.value ?? "") + itemIndex} />)}
					{stop.totalProfit && <NodeSummaryTotal stop={stop} />}
				</Grid>
			</Paper>
		</>
	);
};

const nodeSummaryRendered = (stop, i) => ReactDOMServer.renderToString(<NodeSummary stop={stop} i={i} />);

export default nodeSummaryRendered;
