import { useDispatch, useSelector } from "react-redux";

import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

import { SmarthopList, registy } from "@smarthop/list";
import { closePopoverPanel } from "app/store/tools/popoverSlice";
import { getUserId } from "app/services/LoginService";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("md")]: {
			width: "40%",
		},
		width: 350,
		height: "80vh",
	},
}));

function PopoverPanel() {
	const dispatch = useDispatch();
	const popoverPanel = useSelector(({ tools }) => tools.popovers.popoverPanel ?? []);

	const classes = useStyles();
	const userId = getUserId();

	const popoverId = popoverPanel[0]?.popoverId;
	const popoverTarget = popoverPanel[0]?.currentTarget;
	const popoverOpen = Boolean(popoverTarget);
	const list = popoverId ? registy[popoverId] : undefined;

	if (!popoverOpen || !list) {
		return null;
	}

	return (
		<Popover
			classes={{ paper: classes.root + " bg-grey-200 p-10" }}
			id={"popover"}
			open={popoverOpen}
			anchorEl={popoverTarget}
			onClose={() => dispatch(closePopoverPanel())}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			{list && popoverOpen && <SmarthopList mode="GRID" config={list} dataIds={{ userId }} />}
		</Popover>
	);
}

export default PopoverPanel;
