import { useDispatch } from "react-redux";
import { useState, useCallback } from "react";
import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

// Services
import { isRoleExternal } from "app/services/LoginService";
import { cancelBidBookTransaction, retryBidBookTransactionRC } from "app/services/transactionsBidServices";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useSnackbar } from "notistack";
import { formatCurrency } from "app/main/utils/tableUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

import ViewContentSection from "app/main/chat/messages/ViewContentSection";
import ViewTitleSection from "app/main/chat/messages/ViewTitleSection";
import ViewButtonSection from "app/main/chat/messages/ViewButtonSection";
import ViewButton from "app/main/chat/messages/ViewButton";
import ViewDescriptionSection from "app/main/chat/messages/ViewDescriptionSection";
import ViewBanner from "app/main/chat/messages/ViewBanner";
import ViewToggleSection from "app/main/chat/messages/ViewToggleSection";

import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";
import { useMemo } from "react";

const RC_MESSAGE = (broker) => {
	switch (broker) {
		case "coyote":
		case "loadsmart":
			return "The RC will be sent to your email and saved in the trip details";
		default:
			return "The RC will be sent by the broker to your email";
	}
};

function TransactionBidBookView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const incoming = props.incoming;
	const variant = props.variant;
	const userId = props.userId;
	const hasSubaccounts = props.message?.hasSubaccounts ?? false;
	const transactionContent = props.message?.content ?? props.message;
	const actionType = transactionContent?.actionType;
	const isSkinny = variant === "skinny";
	const title = actionType ? actionType + " transaction" : "";
	const BID_TRANSACTION = "BID";
	const BOOK_TRANSACTION = "BOOK";
	const enableLeftBorder = props.enableLeftBorder ?? false;

	const transactionState = transactionContent?.state;
	const transactionStatus = transactionContent?.status;

	const [openConfirmDialog, setOpenConfirmDialog] = useState({ message: null, flag: false, typeAction: null });

	const setDialog = useCallback((type) => {
		let message = type === "CANCEL" ? "Are you sure to cancel this bid/book?" : "";
		let confirmDialog = { message, flag: true, typeAction: type };
		setOpenConfirmDialog(confirmDialog);
	}, []);

	const handleBidCounterRespondClick = useCallback(() => {
		dispatch(
			openLoadedFormDialog({
				formId: transactionContent.isFinalRate ? "BID_FINAL_OFFER_FORM" : "BID_COUNTER_OFFER_FORM",
				mode: "EDIT",
				dataIds: {
					userId,
					searchId: transactionContent?.searchId,
					loadId: transactionContent?.loadId,
					transactionId: transactionContent?.transactionId,
				},
			})
		);
	}, [dispatch, transactionContent, userId]);

	const handleViewTripClick = useCallback(() => {
		dispatch(
			openFormDialog({
				viewId: "TRIP_VIEW",
				dataIds: { carrierId: transactionContent?.carrier, tripId: transactionContent?.trip, openChat: true },
			})
		);
	}, [dispatch, transactionContent]);

	const handleRetryRcClick = useCallback(() => {
		retryBidBookTransactionRC(transactionContent?.transactionId).then(
			(response) => {
				showSnackbar(snackbar, "Retry RC process started", "success");
				dispatch(incrementDataRevision({ event: "transactionUpdateRevision" }));
			},
			(err) => {
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error", {
					persist: true,
					newSnackBar: true,
				});
			}
		);
	}, [dispatch, snackbar, transactionContent]);

	const messageActions = useMemo(() => {
		return [
			{
				key: "RESPOND_COUNTER",
				title: "Accept or Reject",
				condition: transactionState === "COUNTER_RECEIVED",
				hideOnDesktop: false,
				hideOnMobile: false,
				onClick: () => {
					handleBidCounterRespondClick();
				},
			},
			{
				key: "RETRY_RC",
				title: "Retry RC process",
				condition:
					(transactionState === "DONE" || !!transactionContent?.trip) && transactionState === "BROKER_RC_TIME_OUT",
				hideOnDesktop: false,
				hideOnMobile: false,
				onClick: () => {
					handleRetryRcClick();
				},
			},
			{
				key: "VIEW_TRIP",
				title: "View Trip Details",
				condition: !!transactionContent?.trip,
				hideOnDesktop: false,
				hideOnMobile: false,
				onClick: () => {
					handleViewTripClick();
				},
			},
			{
				key: "CANCEL_PROCESS",
				title: "Cancel",
				condition: transactionStatus === "IN_PROGRESS" && transactionState === "BROKER_PENDING",
				hideOnDesktop: false,
				hideOnMobile: false,
				onClick: () => {
					setDialog("CANCEL");
				},
			},
		];
	}, [
		transactionState,
		transactionContent?.trip,
		transactionStatus,
		handleBidCounterRespondClick,
		handleRetryRcClick,
		handleViewTripClick,
		setDialog,
	]);

	const STATE_BROKER_CONTACT_INFO = ["BROKER_RC_PENDING", "BROKER_RC_SENT", "BROKER_RC_TIME_OUT", "DONE"];

	const loadFields = [
		{ label: "Load ID", value: transactionContent?.loadId },
		{ label: "Origin", value: transactionContent?.origin },
		{ label: "Pickup Date", value: transactionContent?.ready },
	];

	const loadCarrierFields = [];
	if ((hasSubaccounts || !isRoleExternal()) && transactionContent?.carrier_company_name) {
		loadCarrierFields.push({ label: "Carrier", value: transactionContent.carrier_company_name });
	}

	if (transactionState === "DONE") {
		loadFields.push({ label: "Destination", value: transactionContent?.destination });
		loadFields.push({ label: "Delivery Date", value: transactionContent?.pickupby });
		loadFields.push({ label: "Total Miles", value: transactionContent?.miles });
	}

	let offerFields = [
		{ label: "Rate", value: formatCurrency(transactionContent?.price) },
		{
			label: "Bid Rate",
			value:
				formatCurrency(transactionContent?.bidPrice) +
				(!isNaN(transactionContent?.price)
					? "(+$" + Math.round(transactionContent?.bidPrice - transactionContent?.price) + ")"
					: ""),
		},
	];
	// Add counteroffer received field
	if (transactionContent?.counterRate) {
		offerFields.push({
			label: "Counteroffer Rate",
			value:
				formatCurrency(transactionContent?.counterRate) +
				(!isNaN(transactionContent?.price)
					? "(+$" + Math.round(transactionContent?.counterRate - transactionContent?.price) + ")"
					: ""),
		});
	}
	// Add user's new bid field
	if (transactionContent?.counterNewRate) {
		offerFields.push({
			label: "New bid",
			value:
				formatCurrency(transactionContent?.counterNewRate) +
				(!isNaN(transactionContent?.price)
					? "(+$" + Math.round(transactionContent?.counterNewRate - transactionContent?.price) + ")"
					: ""),
		});
	}
	// Add bid final rate field
	if (transactionContent?.counterFinalRate) {
		offerFields.push({
			label: "Final Rate",
			value:
				formatCurrency(transactionContent?.counterFinalRate) +
				(!isNaN(transactionContent?.price)
					? "(+$" + Math.round(transactionContent?.counterFinalRate - transactionContent?.price) + ")"
					: ""),
		});
	}

	//Add fields related to ratecon process by us
	let bookFields = [{ label: "Rate", value: formatCurrency(transactionContent?.price) }];

	//Add contact fields Only for offline bids
	if (transactionState === "BID_OFFLINE_PLACED") {
		offerFields.push({ label: "Contact Phone", value: transactionContent?.contactPhone });
		offerFields.push({ label: "Contact Email", value: transactionContent?.contactEmail });
	}

	//Broker rep data
	let brokerContactFields = [];
	if (STATE_BROKER_CONTACT_INFO.includes(transactionState)) {
		if (transactionContent?.brokerContactName)
			brokerContactFields.push({ label: "Name", value: transactionContent?.brokerContactName });
		brokerContactFields.push({ label: "Contact", value: transactionContent?.brokerContact });
		if (transactionContent?.rcStatus) {
			brokerContactFields.push({ label: "RC Status", value: transactionContent?.rcStatus });
		}
	}

	const parseTransactionStatus = (state, status) => {
		if ((state === "BROKER_ACCEPTED" || state === "BROKER_CONFIRMED") && status !== "FAILED")
			return { title: "Accepted", color: "bg-green" };
		if (state === "COUNTER_RECEIVED") return { title: "Counteroffer", color: "bg-green" };
		if (state === "BROKER_RC_SENT") return { title: "Sending RC", color: "bg-green" };
		if (state === "DONE") return { title: "Finished", color: "bg-green" };
		if (state === "BID_OFFLINE_PLACED") return { title: "Offline bid placed", color: "bg-green" };
		if (state === "BROKER_RC_TIME_OUT") return { title: "RC failed", color: "bg-red" };
		if (status === "FAILED") return { title: "Failed", color: "bg-red" };
		if (state === "CANCELED") return { title: "Canceled", color: "bg-red" };
		if (state === "BROKER_DECLINED") return { title: "Rejected by Broker", color: "bg-red" };
		if (state === "COUNTER_REJECT") return { title: "Rejected by User", color: "bg-red" };
		if (state === "EXPIRED") return { title: "Expired", color: "bg-red" };
		if (state === "BROKER_PENDING") return { title: "Waiting for Broker", color: "bg-orange" };
		if (state === "BROKER_RC_PENDING") return { title: "Getting RC", color: "bg-orange" };
		if (state === "BROKER_REQUEST") return { title: "Sent to Broker", color: "bg-blue" };
		if (state === "COUNTER_NEW_PRICE") return { title: "New Bid Sent", color: "bg-blue" };
		if (state === "CREATE_TRIP_STARTED" || state === "CONFIRM_TRIP")
			return { title: "Creating Trip", color: "bg-blue" };
		if (state === "CALCULATE_PROFIT") return { title: "Calculating profit", color: "bg-blue" };
		if (state === "TRIP_TRACKING" || state === "TRIMBLE_SEARCH_PLAN")
			return { title: "Validating stops", color: "bg-blue" };
		if (state === "ASYNC_PROCESS") return { title: "Sending notifications", color: "bg-blue" };

		return { title: "In progress", color: "bg-blue" };
	};

	const handleAction = (actionType) => {
		setOpenConfirmDialog({ message: null, flag: false, typeAction: null });
		if (actionType === "CANCEL") {
			cancelBidBookTransaction(transactionContent?.transactionId).then(
				(response) => {
					showSnackbar(snackbar, "Bid/Book canceled!", "success");
					dispatch(incrementDataRevision({ event: "transactionUpdateRevision" }));
				},
				(err) => {
					showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error", {
						persist: true,
						newSnackBar: true,
					});
				}
			);
		}
	};

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={enableLeftBorder}
			variant={variant}
			headerConfig={{
				type: "BROKER",
				data: {
					mcnumber: transactionContent?.mcnumber,
					clientid: transactionContent?.clientid,
					owner: transactionContent?.owner,
				},
				showDivider: true,
				subtitle: title,
				title: "Load Bid/Book",
				badge: parseTransactionStatus(transactionState, transactionStatus),
			}}
			contentFields={[
				{
					type: "LABEL_VALUE",
					label: "Carrier",
					condition: (hasSubaccounts || !isRoleExternal()) && !!transactionContent?.carrier_company_name,
					value: transactionContent.carrier_company_name,
				},
				{
					type: "TRIP",
					data: transactionContent,
					options: {
						hideRate: false,
						hideTruck: true,
					},
				},
				{
					type: "LABEL_VALUE",
					label: "Posted Bid",
					condition: actionType === BID_TRANSACTION,
					value:
						formatCurrency(transactionContent?.bidPrice) +
						(!isNaN(transactionContent?.price)
							? "(+$" + Math.round(transactionContent?.bidPrice - transactionContent?.price) + ")"
							: ""),
				},
				{
					type: "LABEL_VALUE",
					label: "Counteroffer Received",
					condition: !!transactionContent?.counterRate,
					value:
						formatCurrency(transactionContent?.counterRate) +
						(!isNaN(transactionContent?.price)
							? "(+$" + Math.round(transactionContent?.counterRate - transactionContent?.price) + ")"
							: ""),
				},
				{
					type: "LABEL_VALUE",
					label: "Counteroffer Posted",
					condition: !!transactionContent?.counterNewRate,
					value:
						formatCurrency(transactionContent?.counterNewRate) +
						(!isNaN(transactionContent?.price)
							? "(+$" + Math.round(transactionContent?.counterNewRate - transactionContent?.price) + ")"
							: ""),
				},
				{
					type: "LABEL_VALUE",
					label: "Final Rate",
					condition: !!transactionContent?.counterFinalRate,
					value:
						formatCurrency(transactionContent?.counterFinalRate) +
						(!isNaN(transactionContent?.price)
							? "(+$" + Math.round(transactionContent?.counterFinalRate - transactionContent?.price) + ")"
							: ""),
				},
				{
					type: "BANNER",
					message: `${RC_MESSAGE(
						transactionContent?.owner
					)}. If there are any issues, please use the contact information below.`,
					icon: "announcement",
					condition: STATE_BROKER_CONTACT_INFO.includes(transactionState),
				},
				{
					type: "LABEL_VALUE",
					label: "Contact Name",
					condition: STATE_BROKER_CONTACT_INFO.includes(transactionState) && transactionContent?.brokerContactName,
					value: transactionContent?.brokerContactName,
				},
				{
					type: "LABEL_VALUE",
					label: "Contact",
					condition: STATE_BROKER_CONTACT_INFO.includes(transactionState) && transactionContent?.brokerContact,
					value: transactionContent?.brokerContact,
				},
				{
					type: "LABEL_VALUE",
					label: "RC Status",
					condition: STATE_BROKER_CONTACT_INFO.includes(transactionState) && transactionContent?.rcStatus,
					value: transactionContent?.rcStatus,
				},
				{
					type: "BANNER",
					message: "This is an offline bid, the broker will contact you using the information bellow",
					icon: "announcement",
					condition: transactionState === "BID_OFFLINE_PLACED",
				},
				{
					type: "LABEL_VALUE",
					label: "Contact Phone",
					condition: transactionState === "BID_OFFLINE_PLACED",
					value: transactionContent?.contactPhone,
				},
				{
					type: "LABEL_VALUE",
					label: "Contact Email",
					condition: transactionState === "BID_OFFLINE_PLACED",
					value: transactionContent?.contactEmail,
				},
				{
					type: "BANNER",
					message: transactionContent?.actionMessage,
					icon: "warning",
					color: "red",
					condition: !!transactionContent?.actionMessage && transactionState === "BROKER_DECLINED",
				},
				{
					type: "BANNER",
					message: transactionContent?.errorMessage,
					icon: "warning",
					color: "red",
					condition: !!transactionContent?.errorMessage,
				},
			]}
			messageActions={messageActions}
			contentComponents={
				<ViewContentSection variant={variant} incoming={incoming}>
					<SmarthopConfirmDialog
						open={!!openConfirmDialog.flag}
						title={openConfirmDialog.message}
						handleClose={() => setOpenConfirmDialog({ message: null, flag: false, typeAction: null })}
						handleAccept={() => handleAction(openConfirmDialog?.typeAction)}
					/>
					{!isSkinny && (
						<ViewTitleSection
							variant={variant}
							incoming={incoming}
							brokerMC={transactionContent?.mcnumber}
							broker={transactionContent?.clientid}
							loadOwner={transactionContent?.owner}
							message={title}
							badge={parseTransactionStatus(transactionState, transactionStatus)?.title}
							classess={{ badge: parseTransactionStatus(transactionState, transactionStatus)?.color }}
						/>
					)}
					{!isSkinny && (
						<ViewDescriptionSection variant={variant} incoming={incoming} items={loadCarrierFields} oneColumn={true} />
					)}
					{!isSkinny && <ViewDescriptionSection variant={variant} incoming={incoming} items={loadFields} />}
					{actionType === BID_TRANSACTION && (
						<ViewTitleSection variant={variant} incoming={incoming} message={"Bid Information"} />
					)}
					{actionType === BID_TRANSACTION && (
						<ViewDescriptionSection variant={variant} incoming={incoming} items={offerFields} />
					)}
					{actionType === BOOK_TRANSACTION && (
						<ViewTitleSection variant={variant} incoming={incoming} message={"Book Information"} />
					)}
					{actionType === BOOK_TRANSACTION && (
						<ViewDescriptionSection variant={variant} incoming={incoming} items={bookFields} />
					)}
					{!isSkinny && brokerContactFields.length > 0 && (
						<ViewToggleSection
							variant={variant}
							incoming={incoming}
							items={brokerContactFields}
							title="RC information"
							banner={`${RC_MESSAGE(
								transactionContent?.owner
							)}. If there are any issues, please use the contact information below.`}
							bannerType="warning"
						/>
					)}
					{!isSkinny && transactionContent?.actionMessage && transactionState === "BROKER_DECLINED" && (
						<ViewBanner
							variant={variant}
							incoming={incoming}
							icon="warning"
							message={transactionContent?.actionMessage}
							classess={{ banner: "bg-red" }}
						/>
					)}
					{!isSkinny && transactionContent?.errorMessage && (
						<ViewBanner
							variant={variant}
							incoming={incoming}
							icon="warning"
							message={transactionContent?.errorMessage}
							classess={{ banner: "bg-red" }}
						/>
					)}
					{!isSkinny && transactionState === "BID_OFFLINE_PLACED" && (
						<ViewBanner
							variant={variant}
							incoming={incoming}
							icon="announcement"
							message={"Bid sent, the broker will contact you by email or phone"}
							classess={{ banner: "bg-blue" }}
						/>
					)}
					{!isSkinny && transactionState === "COUNTER_RECEIVED" && (
						<ViewButtonSection variant={variant} incoming={incoming}>
							<ViewButton
								variant={variant}
								incoming={incoming}
								color={"secondary"}
								onClick={handleBidCounterRespondClick}
								label={"Accept or Reject"}
							/>
						</ViewButtonSection>
					)}
					{!isSkinny && (transactionState === "DONE" || transactionContent?.trip) && (
						<ViewButtonSection variant={variant} incoming={incoming} orientation="horizontal">
							{!isSkinny && transactionState === "BROKER_RC_TIME_OUT" && (
								<ViewButton
									variant={variant}
									incoming={incoming}
									color={"secondary"}
									onClick={handleRetryRcClick}
									label={"Retry RC process"}
								/>
							)}
							<ViewButton
								variant={variant}
								incoming={incoming}
								color={"secondary"}
								onClick={handleViewTripClick}
								label={"View Trip Details"}
							/>
						</ViewButtonSection>
					)}
					{!isSkinny && transactionStatus === "IN_PROGRESS" && transactionState === "BROKER_PENDING" && (
						<ViewButtonSection variant={variant} incoming={incoming}>
							<ViewButton
								variant={variant}
								incoming={incoming}
								color={"secondary"}
								onClick={() => setDialog("CANCEL")}
								label={"Cancel"}
							/>
						</ViewButtonSection>
					)}
				</ViewContentSection>
			}
		/>
	);
}
export default TransactionBidBookView;
