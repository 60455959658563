import { useMemo, useState } from "react";
import { Button, Icon, Link, Typography, Box, CircularProgress } from "@material-ui/core";
import { isStepCompleted, isAllStepsCompleted, isComplete, isStepsCompletedTo } from "./OnboardSteps/onboardUtil";
import { getConfigurationOnboardingStatus } from "../utils/onboardingUtils";
import { useDispatch } from "react-redux";
import { openLoadedFormDialog, closeAllDialogs } from "app/store/tools/formDialogSlice";
import DownloadApp from "../download-app/DownloadApp";
import { useHistory } from "react-router";
import { fetchCredentialsStatus } from "app/store/search/credentialsSlice";
import { getCarrierId } from "app/services/LoginService";

const RenderSummary = ({ data, config, switchToStep, onCompleteSteps, loadingLastStep, props }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const carrierId = props?.isCreateSubAccount ? props?.dataIds?.carrierId : getCarrierId();

	const [submitInProgress, setSubmitInProgress] = useState(null);

	const renderDefaultAction = (title, actions) => {
		return (
			<div className={"flex flex-col w-full items-center"}>
				{title && (
					<Typography className={"text-grey-800 text-14 mb-20 max-w-450 text-center px-12"}>{title}</Typography>
				)}
				<div className="flex flex-col w-full justify-center max-w-420 md:max-w-300 px-5 py-5">
					{actions?.map((action, index) => (
						<Button
							key={"action_" + index}
							className={"mb-10"}
							variant={action.variant ?? "contained"}
							color={action.color ?? "secondary"}
							aria-label={action.label}
							disabled={!!action.disabled}
							onClick={() => {
								if (action.onClick) {
									action.onClick();
								} else {
									dispatch(
										openLoadedFormDialog({
											viewId: action.dialog.viewId,
											formId: action.dialog.formId,
											mode: action.dialog.mode,
											dataIds: action.dialog.dataIds ?? {},
										})
									);
								}
							}}
						>
							{action.label}
						</Button>
					))}
				</div>
			</div>
		);
	};

	const configurationOnboardingStatus = useMemo(
		() => getConfigurationOnboardingStatus({ ...data, isAllStepsCompleted: isAllStepsCompleted({ config, data }) }),
		[config, data]
	);

	const renderItem = (sectionStep) => {
		const stepCompleted = isStepCompleted({ step: sectionStep, data, config });
		const iconClass = "text-17 " + (stepCompleted ? " text-green-400 " : " text-grey-800 ");
		const titleClass = "text-14 md:text-16 " + (stepCompleted ? " text-grey-600 " : " text-grey-800 ");
		return (
			<div
				key={"step_" + sectionStep}
				className={`flex w-full items-center py-4  ${
					data?.onboardingStatus === "REJECTED" ? "cursor-not-allowed" : "cursor-pointer"
				}`}
				onClick={() => data?.onboardingStatus !== "REJECTED" && switchToStep(sectionStep)}
			>
				<div className="flex flex-row items-center ">
					<Icon className={iconClass}>{stepCompleted ? "check_circle" : "radio_button_unchecked"}</Icon>
					<div className="flex flex-1 flex-col items-start pr-10 pl-10">
						<Typography className={titleClass}>{config.list[sectionStep].title}</Typography>
					</div>
				</div>
			</div>
		);
	};

	if (loadingLastStep) {
		return (
			<div className="flex flex-col w-full items-center">
				<div className="flex flex-col min-w-300 max-w-300 justify-center items-center">
					<Box className="mt-80">
						<CircularProgress size={40} className="flex justify-center items-center" />
						<Typography color="textPrimary" className="text-14 -ml-6 mt-16 font-normal">
							Loading...
						</Typography>
					</Box>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col w-full items-center ">
			<div className="flex flex-col min-w-300 max-w-300">
				<Typography className={"text-grey-900 text-17 "}>Status</Typography>
				<Typography className={"text-13 -mt-2 font-bold mb-20 " + configurationOnboardingStatus.colorText}>
					{configurationOnboardingStatus.text}
				</Typography>

				{config.list.map((s, index) => {
					if (s.hidden && s.hidden(data)) return null;
					return renderItem(index);
				})}

				<Typography className="text-secondary font-medium text-13 pt-24 pb-14">
					{configurationOnboardingStatus.textSubmited}
				</Typography>
				{configurationOnboardingStatus.textBulletPoints?.map && (
					<div className="flex flex-col w-full items-start -mt-4 pb-10">
						{configurationOnboardingStatus.textBulletPoints?.map?.((bulletPoint, i) => {
							return (
								<Typography key={"text_bullet_points_" + i} className="text-secondary font-medium text-13 pb-6">
									&#x2022; {bulletPoint}
								</Typography>
							);
						})}
					</div>
				)}

				{isComplete({ data, config })
					? renderDefaultAction(null, [
							{
								label: props?.nativeMobile ? "Close" : "Get Started!",
								variant: "outlined",
								color: "secondary",
								onClick: () => {
									if (props?.nativeMobile) {
										props?.onDone?.();
										switchToStep(-1);
									} else if (props?.isCreateSubAccount) {
										dispatch(closeAllDialogs());
										dispatch(openLoadedFormDialog({ viewId: "CARRIER_INFO_VIEW", dataIds: { carrierId } }));
									} else {
										history.push("/home");
									}
									dispatch(fetchCredentialsStatus({ carrierId }));
								},
							},
					  ])
					: renderDefaultAction(null, [
							{
								label: submitInProgress
									? "Processing..."
									: data.onboardingPreselects?.planId && data.onboardingPreselects?.isTrialAllowed
									? "Start Free Trial"
									: "Submit",
								disabled:
									submitInProgress ||
									!isStepsCompletedTo({ config, data, step: config.list.length - 1 }) ||
									data.onboardingStatus === "REJECTED", // || saving ||
								onClick: () => {
									setSubmitInProgress(true);
									onCompleteSteps(() => {
										dispatch(fetchCredentialsStatus({ carrierId }));
										setSubmitInProgress(false);
									});
								},
							},
					  ])}
			</div>
			{!props.nativeMobile && isComplete({ data, config }) && (
				<div className="flex flex-col w-full items-center">
					<Typography className={"text-grey-800 mt-4 mb-10 max-w-480 text-center px-12"}>
						Visit our resource portal and watch training videos to learn more about our platform.
						<Link
							to={"https://sites.google.com/smarthop.co/customer-resources/videos"}
							className="px-6"
							onClick={() => {
								window.open(`https://sites.google.com/smarthop.co/customer-resources/videos`, "_blank");
							}}
						>
							Learn More
						</Link>
					</Typography>
					<DownloadApp />
					<Typography color="textSecondary" className="text-13 pb-10">
						Please download our mobile app
					</Typography>
				</div>
			)}
		</div>
	);
};

export default RenderSummary;
