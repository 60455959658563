import { SmarthopList, transactionsStateLogsList } from "@smarthop/list";
import { useEffect } from "react";

function AdminTransactionsPage(props) {
	useEffect(() => {
		props?.setTitle?.("Transactions Logs");
		props?.setSize?.("max-w-6xl");
		// eslint-disable-next-line
	}, []);
	const id = props.dataIds.id;
	const userId = props.dataIds.userId;

	return <SmarthopList mode="table" inDialog={true} config={transactionsStateLogsList} dataIds={{ id, userId }} />;
}

export default AdminTransactionsPage;
