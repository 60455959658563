import Box from "@material-ui/core/Box";

import { capitalizeFirstLetter } from "app/main/utils/tableUtils";
import { DisplaySection } from "app/main/utils/uiUtils";

import { ordinals } from "app/main/strategy/utils/strategyUtils";
import TruckInfo from "./TruckInfo";
import TableCarousel from "./TableCarousel";
import MovesTableView from "./MovesTableView";

// TODO: Enabled when defined what stats to use from Axle response
// const StatSection = ({ summary }) => {
// 	const displayFields = Object.entries(summary.statistics).map(([stat, { mean: avg }]) => {
// 		const isMoneyValue = stat.includes("profit") || stat.includes("payment");
// 		return (
// 			<DisplayField
// 				key={stat}
// 				label={capitalizeFirstLetter(stat.replace(/_/g, " "))}
// 				value={isMoneyValue ? formatCurrency(avg) : Number(avg).toFixed(2)}
// 			/>
// 		);
// 	});

// 	const sections = displayFields.reduce((acc, curr, i) => {
// 		if (!(i % 3)) {
// 			acc.push([curr]);
// 		} else {
// 			acc[acc.length - 1].push(curr);
// 		}
// 		return acc;
// 	}, []);

// 	return (
// 		<DisplaySection title="Statistics">
// 			{sections.map((s) => (
// 				<div className="flex flex-row ">{s.map((f) => f)}</div>
// 			))}
// 		</DisplaySection>
// 	);
// };

const StrategySummary = ({
	strategy,
	strategyReqData,
	truckInfo,
	saving,
	setTablePaths,
	tablePaths,
	selectedPaths,
	summary,
	selectAll,
	setSelectAll,
}) => {
	return (
		<>
			{strategy && (
				<Box p={2}>
					{truckInfo && <TruckInfo truckInfo={truckInfo} />}
					<TableCarousel
						items={summary.summaryArr.map((summary, i) => (
							<DisplaySection
								key={`${ordinals[i]}-ordinal-section`}
								title={`${capitalizeFirstLetter(ordinals[i])} Loaded Moves`}
							>
								<MovesTableView
									key={ordinals[i]}
									tableIndex={i}
									summary={summary}
									tablePaths={tablePaths}
									setTablePaths={setTablePaths}
									selectAll={selectAll}
									setSelectAll={setSelectAll}
									truckInfo={truckInfo}
									strategyReqData={strategyReqData}
									selectedPaths={selectedPaths}
									saving={saving}
								/>
							</DisplaySection>
						))}
					/>
					{/* Use again when defined what stats to use from Axle response
					<StatSection summary={summary} />
					*/}
				</Box>
			)}
		</>
	);
};

export default StrategySummary;
