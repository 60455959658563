import { Typography, Icon } from "@material-ui/core";

function TypeComponent(props) {
	const item = props.item;
	const smallScreen = props.smallScreen;

	// Badge List
	const bookBadge = item.booknow || item.bidnow;
	const testBadge = item.test;

	return (
		<>
			{bookBadge ? (
				<div className={"flex flex-row h-18 rounded-4 border-1 border-purple"}>
					<div className={`min-w-16 h-16 justify-center` + (smallScreen ? " bg-transparent " : " bg-purple-800 ")}>
						<Icon className={`font-500 text-12 p-2 ` + (smallScreen ? " text-purple-800 " : " text-white ")}>
							flash_on
						</Icon>
					</div>
					{!smallScreen && (
						<Typography className={"font-500 px-2 text-purple-800 text-12 ml:text-11"}>{"Book"}</Typography>
					)}
				</div>
			) : null}
			{testBadge ? (
				<div className={"flex flex-row h-18 rounded-4 border-1 border-blue ml-2"}>
					<div
						className={`min-w-16 h-16 justify-center` + (smallScreen || bookBadge ? " bg-transparent " : " bg-blue ")}
					>
						<Icon className={`font-500 text-13 p-1.5 ` + (smallScreen || bookBadge ? " text-blue " : " text-white ")}>
							star
						</Icon>
					</div>
					{!(smallScreen || bookBadge) && (
						<Typography className={"font-500 px-2 pt-1 text-blue text-12 ml:text-10"}>{"Test"}</Typography>
					)}
				</div>
			) : null}
		</>
	);
}

export default TypeComponent;
