import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getRelease = (releaseId) => {
	return createRequest()
		.get(`api/releases/${releaseId}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateRelease = (releaseId, update) => {
	return createRequest()
		.put(`api/releases/${releaseId}`, {
			headers: createHeaders(),
			data: update,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createRelease = (release) => {
	return createRequest()
		.post(`api/releases`, {
			headers: createHeaders(),
			data: release,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
