import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Icon, Typography, Link, Divider } from "@material-ui/core";
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import TooltipHoverView from "app/main/tools/TooltipHoverView";
import YouTubePlayer from "app/main/video/YouTubePlayer";
import { openTutorialDialog } from "app/store/tools/tutorialDialogSlice";

//Const
const VIDEO_REGEX = /(?:v=|\/)([0-9A-Za-z_-]{11}.*)/;

function ResourceView(props) {
	const dispatch = useDispatch();

	const [mobile, setMobile] = useState(window.innerWidth < 960);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const createIconInfo = (link, description, variant) => {
		if (!description) {
			return <Typography className="p-6 text-13 text-red">{"Need to provide a description"}</Typography>;
		}

		const TooltipView = variant === "hover" ? TooltipHoverView : TooltipClickAwayView;
		return (
			<TooltipView
				classes={{ tooltip: "drop-shadow-md mt-8" }}
				custom={{ color: "#062246" }}
				title={
					<>
						<Typography color="inherit" className="text-13 p-6">
							{description}
						</Typography>
						{link && (
							<Link onClick={() => window.open(link)} color="primary">
								<Typography color="inherit" className="text-15 p-6 cursor-pointer">
									{"Learn More"}
								</Typography>
							</Link>
						)}
					</>
				}
			>
				<Icon
					className={
						"text-grey ml-1 " + props?.classes?.icon + " " + (props?.classes?.iconSize ?? "text-15 md:text-18")
					}
				>
					info
				</Icon>
			</TooltipView>
		);
	};

	const createVideo = (link, description) => {
		if (!link) return <Typography className="p-6 text-13 text-red">{"Need to provide a video"}</Typography>;
		const videoIdMatch = link.match(VIDEO_REGEX);
		const videoId = videoIdMatch?.[1];

		return (
			<>
				{description && (
					<Typography color="textPrimary" className="md:text-16 text-14 pl-0 md:pl-32 pr-32">
						{description}
					</Typography>
				)}
				{description && <Divider className="flex w-full h-1 mt-4 mb-10 bg-grey-300" />}
				<YouTubePlayer videoId={videoId} mobile={mobile} variant={props?.variant} />
			</>
		);
	};

	const createTutorialDialog = (tutorialId) => {
		if (!tutorialId) return <Typography className="p-6 text-13 text-red">{"Need to provide a tutorial Id"}</Typography>;

		return (
			<Icon
				className={"text-grey ml-1 text-15 md:text-18 " + props?.classes?.icon}
				onClick={() => dispatch(openTutorialDialog({ tutorialId, type: "permanent" }))}
			>
				info
			</Icon>
		);
	};

	const createCustomInfo = (link, description, variant, customIcon) => {
		if (!description) {
			return <Typography className="p-6 text-13 text-red">{"Need to provide a description"}</Typography>;
		}

		const TooltipView = variant === "hover" ? TooltipHoverView : TooltipClickAwayView;
		return (
			<TooltipView
				classes={{ tooltip: "drop-shadow-md mt-8" }}
				custom={{ color: "#062246" }}
				title={
					<>
						<Typography color="inherit" className="text-13 p-6">
							{description}
						</Typography>
						{link && (
							<Link onClick={() => window.open(link)} color="primary">
								<Typography color="inherit" className="text-13 p-6  cursor-pointer">
									{"Learn More"}
								</Typography>
							</Link>
						)}
					</>
				}
			>
				{customIcon}
			</TooltipView>
		);
	};

	const type = props?.type;
	if (type === "video") {
		return createVideo(props?.link, props?.description);
	} else if (type === "icon") {
		return createIconInfo(props?.link, props?.description, props?.variant);
	} else if (type === "tutorial") {
		return createTutorialDialog(props?.tutorialId);
	} else if (type === "custom") {
		return createCustomInfo(props?.link, props?.description, props?.variant, props?.customIcon);
	}
}

export default ResourceView;
