import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Image from "material-ui-image";

import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellContainerRow from "./CellContainerRow";
import CellEmptyRow from "./CellEmptyRow";

import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import Icon from "@material-ui/core/Icon";

import { getStatusFromPlanData, getStatusConfig } from "app/main/utils/planUtils";
import { createMPIViewManual } from "app/main/utils/brokerUtils";
import { formatTimeAgo } from "app/main/utils/dateUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

const POSTING_ICONS = {
	dat: (
		<Image
			disableSpinner={true}
			disableTransition={true}
			imageStyle={{ width: "36px", height: "11px" }}
			style={{ width: "36px", height: "11px", paddingTop: null }}
			color="none"
			src={"assets/images/broker-logos/dat.png"}
		/>
	),
	parade: (
		<Image
			disableSpinner={true}
			disableTransition={true}
			imageStyle={{ width: "48px", height: "15px" }}
			style={{ width: "48px", height: "15px", paddingTop: null }}
			color="none"
			src={"assets/images/broker-logos/parade2.png"}
		/>
	),
};

function PlanCellView(props) {
	const dispatch = useDispatch();
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const mode = props.config?.mode;
	const item = props.item;
	const published = props.view?.metadata?.published;
	const covered = props.view?.metadata?.covered;
	const cancelled = props.view?.metadata?.cancelled;
	const expired = props.view?.metadata?.expired;
	// const outdated = props.view?.metadata?.outdated__flag;
	const onsales = props.view?.metadata?.onsales;
	const postingsOn = props.view?.metadata?.post_status === "POSTED" || props.view?.metadata?.post_status === "PENDING";
	const statusTruckPlan = props.view?.metadata?.status_truck_plan;
	const type = props?.view?.metadata?.type;
	const mci = props?.view?.metadata?.market_condition?.mci;
	const mvi = props?.view?.metadata?.market_condition?.mvi;
	const advancedFilters = props?.view?.metadata?.advancedFilters;
	const triggerAutobook = props?.view?.metadata?.triggerAutobook;

	if (mode === "POSTINGS") {
		if (!props.view?.value || !item.plan__view?.metadata?.post_brokers?.length || !postingsOn) {
			return <CellEmptyRow mobile={mobile} variant={variant} />;
		}

		let views = [];
		item.plan__view?.metadata?.post_brokers?.forEach((item) => {
			views.push(POSTING_ICONS[item] ?? <Typography className="text-12 ml:text-13">{item}</Typography>);
		});
		return <div className="flex flex-wrap space-x-6 justify-center items-center">{views.length > 0 ? views : "-"}</div>;
	}

	if (mode === "LOAD_SCOUNT") {
		if (!props.view?.value || !advancedFilters) {
			return <CellEmptyRow mobile={mobile} variant={variant} />;
		}

		const loadScout = props.view?.metadata?.lastLoadScoutResults;
		return (
			<CellContainerRow
				mobile={mobile}
				variant={variant}
				left={
					triggerAutobook && (
						<TooltipClickAwayView
							classes={{ tooltip: "drop-shadow-md" }}
							title={
								<Typography className={"text-12 p-6"} component={"div"}>
									Load Scout Auto Book Enabled
									<br />
									<br />
									If Load Scout finds a matching digital load, we will automatically try to book it
								</Typography>
							}
						>
							<Icon className="text-18 text-purple mt-6 mr-2">flash_on</Icon>
						</TooltipClickAwayView>
					)
				}
				first={
					props.view?.metadata?.lastLoadScoutResults?.searchId ? (
						<CellFirstRow
							mobile={mobile}
							textLimit={28}
							view={
								props.view
									? {
											value: loadScout?.searchId,
											label: `Matched ${loadScout?.planMatch}/${loadScout?.total}`,
									  }
									: null
							}
							dialog={{
								viewId: "LOADS_SEARCH_RESULTS_VIEW",
								dataIds: {
									searchId: loadScout?.searchId,
									carrierId: item.carrier ?? item.carrier__view?.value,
									truckId: item.truck ?? item.truck__view?.value,
									planId: props.view.value,
								},
							}}
							variant={variant}
						/>
					) : (
						<CellFirstRow mobile={mobile} view={{ value: null, label: `Enabled` }} variant={variant} />
					)
				}
				second={
					<div className="flex flex-wrap">
						{props.view?.metadata?.lastLoadScoutResults?.searchId ? (
							<CellSecondRow
								mobile={mobile}
								label={`${formatTimeAgo(loadScout?.createdAt)} ago`}
								color={"text-grey"}
								variant={variant}
							/>
						) : (
							<CellSecondRow mobile={mobile} label={`No results yet`} color={"text-grey"} variant={variant} />
						)}
					</div>
				}
			/>
		);
	}

	if (!props.view?.value) {
		return (
			<CellEmptyRow mobile={mobile} variant={variant}>
				<Button
					onClick={() =>
						dispatch(
							openLoadedFormDialog({
								viewId: "TRUCK_PLAN_VIEW",
								dataIds: {
									carrierId: item.carrier ?? item.carrier__view?.value,
									truckId: item.truck ?? item.truck__view?.value,
									planId: item.plan__view?.value,
								},
							})
						)
					}
					className={"overflow-visible ml-1 py-6 px-9 bg-blue-100"}
				>
					<Typography className={"whitespace-nowrap font-medium text-black text-11"}>+ Add</Typography>
				</Button>
			</CellEmptyRow>
		);
	}

	if (config?.readOnly) {
		return item.plan__view?.label;
	}

	const status = getStatusFromPlanData({ published, covered, cancelled, expired, onsales, statusTruckPlan });
	const statusConfig = getStatusConfig(status);
	const active = status === "CURRENT";
	const label = props.view.label?.replace?.(", USA", "");
	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			left={config?.showMPI && mci > 0 && <div className="mr-4 my-2 pb-0">{createMPIViewManual(mci, mvi, true)} </div>}
			first={
				<CellFirstRow
					mobile={mobile}
					textLimit={28}
					view={
						props.view
							? {
									value: props.view.value,
									label: label?.length > 0 ? label : "Not Configured",
							  }
							: null
					}
					dialog={{
						viewId: "TRUCK_PLAN_VIEW",
						dataIds: {
							status: status,
							carrierId: item.carrier ?? item.carrier__view?.value,
							truckId: item.truck ?? item.truck__view?.value,
							id: item._id,
							planId: item.plan__view?.value,
						},
					}}
					variant={variant}
				/>
			}
			second={
				<div className="flex flex-wrap">
					{config?.showStatus && (
						<CellSecondRow
							mobile={mobile}
							label={statusConfig.label}
							color={status === "CURRENT" ? null : "text-grey"}
							variant={variant}
						/>
					)}
					{config?.showTypeHistory && <CellSecondRow mobile={mobile} label={type ?? "No Type"} variant={variant} />}
					{config?.showLoadScout && advancedFilters && active && (
						<CellSecondRow
							mobile={mobile}
							icon={"alarm_on"}
							label={"On"}
							variant={variant}
							tooltip="Load Scout Enabled"
						/>
					)}
					{config?.showLoadScout && triggerAutobook && active && (
						<CellSecondRow
							mobile={mobile}
							icon={"flash_on"}
							label={"On"}
							variant={variant}
							tooltip="Load Scout Auto Book Enabled"
						/>
					)}
					{config?.showPosted && postingsOn && (
						<CellSecondRow mobile={mobile} icon={"settings_input_antenna"} label={"Posted"} variant={variant} />
					)}
				</div>
			}
		/>
	);
}

export default PlanCellView;
