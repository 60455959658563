import { LinearProgress, Typography } from "@material-ui/core";

export const loadingViewComponent = (text) => {
	return (
		<div className="flex w-full h-512 flex-col items-center justify-center">
			<div className="w-1/4">
				<Typography variant="h6" color="primary" className="flex pt-10 pb-8 items-center justify-center">
					{ text ?? "Loading..." }
				</Typography>
				<LinearProgress color="secondary" className="mt-24 mb-10 w-full" />
			</div>
		</div>
	);
};
