export const tripDeleteForm = {
	urlGET: "api/booking/carriers/:carrierId/trips/:tripId",
	urlDELETE: "api/booking/carriers/:carrierId/trips/:tripId",
	triggerEvent: "tripsRevision",
	listenEvent: "tripsRevision",
	header: {
		delete: { label: "Delete Trip" },
	},
	messages: {
		delete: { success: "Trip deleted.", disabled: false },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete trip #${item.load_id}?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};

export default tripDeleteForm;
