const carrierPerformanceEmailForm = {
	urlPOST: "api/profile/carriers/:carrierId/report-performance/type/:typeId",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		create: { label: "Send Performace report" },
	},
	content: {
		create: {
			items: [
				{
					key: "config",
					type: "select",
					label: "Send email to",
					options: [
						{
							value: "DEFAULT",
							label: "Carrier Email",
						},
						{
							value: "Specific",
							label: "Specific Email",
							showItems: [
								{
									key: "email",
									type: "text",
									label: "Email",
									required: true,
								},
							],
						},
					],
				},
				{ type: "action", action: "SUBMIT", label: "Send" },
			],
		},
	},
};

export default carrierPerformanceEmailForm;
