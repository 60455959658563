import { Icon, Typography } from "@material-ui/core";
import { formatCurrency } from "app/main/utils/tableUtils";
import { costSectionsFields } from "./CostStructureCalculatorSettings";
import { useMemo } from "react";
import ProfitWarnings from "./ProfitWarnings";

function CostsBreakdownTooltip({ data, profitResults, width = "200px", className = "", displayProfit = true }) {
	const expenses = useMemo(() => Object.keys(profitResults?.expenseList ?? false), [profitResults]);

	if (!expenses?.length) return null;

	return (
		<div className={`flex flex-col p-3 ${className}`} style={{ width }}>
			{data && <ProfitWarnings data={data} />}
			{expenses.map((label) => (
				<div key={label} className="flex flex-row justify-between items-center my-1">
					<div className="flex flex-row">
						<Icon className="flex mr-4 text-16">
							{typeof costSectionsFields[label].icon === "function"
								? costSectionsFields[label].icon(16)
								: costSectionsFields[label].icon}
						</Icon>
						<Typography className="text-12 ">{costSectionsFields[label].short}</Typography>
					</div>
					<Typography className="text-12 text-red-200 font-medium">
						{formatCurrency(profitResults.expenseList[label])}
					</Typography>
				</div>
			))}
			<hr className="bg-white my-8" />
			<div className="flex flex-row justify-between items-center my-1">
				<Typography className="text-12 ">Expenses</Typography>
				<Typography className="text-12 text-red-200 text-right font-medium">
					{formatCurrency(profitResults?.totalExpenses)}
				</Typography>
			</div>

			<div className="flex flex-row justify-between items-center my-1">
				<Typography className="text-12 ">Revenue</Typography>
				<Typography className="text-12 text-green-200 text-right font-medium">
					{formatCurrency(profitResults?.revenue ?? 0)}
				</Typography>
			</div>
			{displayProfit && (
				<>
					<hr className="bg-white my-8" />
					<div className="flex flex-row justify-between items-center my-1">
						<Typography className="text-12 ">Profit</Typography>
						<Typography
							className={
								(profitResults?.profit > 0 ? "text-green-200 " : "text-red-200 ") + " text-12 text-right font-medium"
							}
						>
							{formatCurrency(profitResults?.profit)}
						</Typography>
					</div>
				</>
			)}
		</div>
	);
}

export default CostsBreakdownTooltip;
