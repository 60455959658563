import { SmarthopList } from "@smarthop/list";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import smartPayTripInvoicingConf from "../billing/invoicing/smartPayTripInvoicingNeedAttention";

const SmartPayInvoicesNeedAttentionView = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Invoices");
		// eslint-disable-next-line
	}, []);
	const { carrierId } = dataIds;
	const subaccount = useSelector(({ auth }) => auth.account.subAccount);
	const isMainHandler = subaccount?.list?.length > 0;

	return (
		<div>
			<SmarthopList
				key="invoices"
				urlKey="invoices"
				mode="TABLE"
				config={smartPayTripInvoicingConf({ isMainHandler })}
				dataIds={{ carrierId }}
				params={{
					options_invoices_need_attention: true,
				}}
			/>
		</div>
	);
};
export default SmartPayInvoicesNeedAttentionView;
