import moment from "moment";

const MONTH = () => ({
	default: { label: "Last 30d", main: "MONTH_-1", secondary: "MONTH_-2_-1" },
	options: [
		{ label: "Last 30d", main: "MONTH_-1", secondary: "MONTH_-2_-1" },
		{ label: "This Month", main: "MONTH_1", secondary: "MONTH_2_1" },
		{ label: "Last Month", main: "MONTH_2_1", secondary: "MONTH_3_2" },
		{ label: "6 Months", main: "MONTH_6_0", secondary: "MONTH_12_6", period: "MONTH" },
		{ label: "12 Months", main: "MONTH_12_0", secondary: "MONTH_24_12", period: "MONTH" },
		{ label: "YTD", main: "YEAR_1_0", secondary: "YEAR_2_1", period: "MONTH" },
	],
});

const MONTH_RECENT = () => ({
	default: { label: "Last 30d", main: "MONTH_-1", secondary: "MONTH_-2_-1" },
	options: [
		{ label: "Last 7 Days", main: "DAY_7", secondary: "DAY_14_7" },
		{ label: "Last 14 Days", main: "DAY_14", secondary: "DAY_28_14" },
		{
			label: `This Week (${moment().startOf("isoWeek").format("MM/DD")} - ${moment()
				.endOf("isoWeek")
				.format("MM/DD")})`,
			main: "WEEK_1",
			secondary: "WEEK_2_1",
		},
		{
			label: `Last Week (${moment().startOf("isoWeek").subtract(7, "days").format("MM/DD")} - ${moment()
				.endOf("isoWeek")
				.subtract(7, "days")
				.format("MM/DD")})`,
			main: "WEEK_2_1",
			secondary: "WEEK_3_2",
		},
		{
			label: `Last 2 Weeks (${moment().startOf("isoWeek").subtract(1, "week").format("MM/DD")} - ${moment()
				.endOf("isoWeek")
				.format("MM/DD")})`,
			main: "WEEK_2",
			secondary: "WEEK_4_2",
		},
		{ label: "Last 6 Weeks", main: "WEEK_6", secondary: "WEEK_12_6" },
		{ label: "Last 12 Weeks", main: "WEEK_12", secondary: "WEEK_24_12" },
		{ label: "Last 30d", main: "MONTH_-1", secondary: "MONTH_-2_-1" },
		{ label: "This Month", main: "MONTH_1", secondary: "MONTH_2_1" },
		{ label: "Last Month", main: "MONTH_2_1", secondary: "MONTH_3_2" },
		{ label: "6 Months", main: "MONTH_6_0", secondary: "MONTH_12_6", period: "MONTH" },
		{ label: "12 Months", main: "MONTH_12_0", secondary: "MONTH_24_12", period: "MONTH" },
		{ label: "YTD", main: "YEAR_1_0", secondary: "YEAR_2_1", period: "MONTH" },
	],
});

const MONTH_3_LAST = () => ({
	default: { label: "Last 30d", main: "MONTH_-1", secondary: "MONTH_-2_-1" },
	options: [
		{ label: "Last 30d", main: "MONTH_-1", secondary: "MONTH_-2_-1" },
		{ label: "This Month", main: "MONTH_1", secondary: "MONTH_2_1" },
		{ label: "Last Month", main: "MONTH_2_1", secondary: "MONTH_3_2" },
		{ label: "Before Last Month", main: "MONTH_3_2", secondary: "MONTH_4_3" },
	],
});

const WINDOW_MAPPINGS = () => ({
	MONTH: MONTH(),
	MONTH_3_LAST: MONTH_3_LAST(),
	MONTH_RECENT: MONTH_RECENT(),
});

export { WINDOW_MAPPINGS };
