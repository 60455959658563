const userLoadTrackingLocations = () => {
	return {
		urlGET: "/api/booking/trips/:tripId/tracking",
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "USER_LOAD_TRACKING_LOCATIONS",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: ["loadId", "carrier"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "last_date",
					dir: "desc",
				},
			},
			groups: {
				items: [
					{
						key: "os_data",
						type: "checkbox",
						label: "Show OS",
					},
				],
			},
			filters: {
				search: true,
				items: [
					{
						key: "source",
						type: "select",
						label: "Source",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{ label: "Not Selected", value: "__NOT_SELECTED__" },
							{ label: "TMS", value: "TMS" },
							{ label: "GPS", value: "GPS" },
							{ label: "ELD", value: "AXLE" },
						],
					},
				],
			},
			items: [
				{ key: "location", type: "text", label: "Location" },
				{ key: "coordinates", type: "text", label: "Coordinates (lat, long)" },
				{ key: "last_date", type: "date", label: "Date" },
				{ key: "source", type: "text", label: "Source" },
				{ key: "isTest", type: "bool", label: "Test" },
				{ key: "os", type: "text", label: "OS", group: "os_data" },
			],
		},
	};
};

export default userLoadTrackingLocations;
