import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getPostings = (carrierId, truckId) => {
	return createRequest()
		.get(`api/post-truck/carriers/${carrierId}/trucks/${truckId}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const removePostedBrokerFromPlan = (carrierId, truckId, planId, broker) => {
	return createRequest()
		.put(`api/post-truck/carriers/${carrierId}/trucks/${truckId}/plan/${planId}`, {
			data: { broker },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const deletePosting = (postTruckId) => {
	return createRequest()
		.delete(`api/post-truck/${postTruckId}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
