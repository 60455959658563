import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DropdownButton from "./DropdownButton";

import {
	closeFormDialog,
	closeMinimizedStack,
	maximizeStack,
	minimizeStack,
	setFormDialogSize,
	updateFormDialog,
} from "app/store/tools/formDialogSlice";
import SmarthopForm from "@smarthop/form/SmarthopForm";
import { buildForm } from "@smarthop/form/registy";

import LoadStrategyTabsView from "app/main/search/load/LoadStrategyTabsView";
import LoadSearchView from "app/main/search/result/LoadSearchView";
import LoadStrategyVisualizer from "app/main/search/load/LoadStrategyVisualizer";
import FuelTermsView from "app/main/terms/FuelTermsView";
import TermsView from "app/main/terms/TermsView";
import ChatApp from "app/main/chat/ChatApp";
import CreditCardView from "app/main/billing/payments/CreditCardView";
import PlatformPlanView from "app/main/billing/plans/PlatformPlanView";
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import PlarformOfferLowerPlanView from "app/main/billing/plans/PlarformOfferLowerPlanView";
import ResetPasswordView from "app/main/auth/password/ResetPasswordView";
import ResetPasswordSuccessView from "app/main/auth/password/ResetPasswordSuccessView";
import TransactionStateLogsView from "app/main/profile/transactionsState/TransactionStateLogsView";
import AdminCronJobLogsView from "app/main/profile/admin/AdminCronJobLogsView";
import AdminAnalyticsJobLogsView from "app/main/profile/admin/AdminAnalyticsJobLogsView";
import AdminLoadTrackingDetailsPage from "app/main/profile/admin/AdminLoadTrackingDetailsPage";
import InvoiceChargeView from "app/main/billing/invoice/InvoiceChargeView";
import TripView from "app/main/profile/trips/TripView";
import DownloadApp from "app/main/download-app/DownloadApp";
import TripEditView from "app/main/profile/trips/TripEditView";
import UserAccountsView from "app/main/profile/users/UserAccountsView";
import ProfileInfoView from "app/main/profile/users/ProfileInfoView";
import AccountInfoView from "app/main/profile/carriers/AccountInfoView";
import CostStructureForm from "app/main/profile/cost-structure/CostStructureForm";
import CostStructureViewDialog from "app/main/profile/cost-structure/CostStructureViewDialog";
import SearchRequestView from "../search/load/SearchRequestView";
import SearchRequestRedirectView from "../search/load/SearchRequestRedirectView";
import TruckPlanEdit from "../truck-plans/TruckPlanEdit";
import TruckPlanView from "../truck-plans/TruckPlanView";
import PostTruckHistory from "../post-truck/PostTruckHistoryView";
import PostTruckLogs from "../post-truck/PostTruckLogsView";
import PostTruck from "../post-truck/PostTruckView";
import UserDetailsView from "../profile/users/UserDetailsView";
import CarrierUsersEditView from "../profile/carriers/CarrierUsersEditView";
import TruckDetailsDialog from "app/main/profile/trucks/TruckDetailsDialog";
import CarrierInfoView from "../search/load/CarrierInfoView";
import TreeVisualizerView from "../tree-visualizer/TreeVisualizerView";
import SmartHopWalletTransferFundsView from "app/main/profile/smarthop-wallet/SmartHopWalletTransferFundsView";
import SmartHopWalletView from "app/main/profile/smarthop-wallet/SmartHopWalletView";
import SmartHopWalletPurchaseDebitCardView from "app/main/profile/smarthop-wallet/SmartHopWalletPurchaseDebitCardView";
import SmartHopWallet2FAVerificationView from "app/main/profile/smarthop-wallet/SmartHopWallet2FAVerificationView";
import { createTrackOrPage } from "../segment/segmentEvent";
import { FORM_DIALOG_EVENT } from "../segment/segmentType";
import PhoneConfirmView from "app/main/profile/validation/PhoneConfirmView";
import EmailConfirmationView from "app/main/profile/validation/EmailConfirmationView";
import CompanyInfoConfirmationView from "app/main/profile/validation/CompanyInfoConfirmationView";
import HistoryViewForm from "app/main/history/HistoryViewForm";
import HistoryView from "app/main/history/HistoryView";
import AdminFactoringRequestDetailView from "app/main/profile/admin/factoring/AdminFactoringRequestDetailView";
import LoadSearchResultView from "app/main/search/result/LoadSearchResultView";
import LoadSearchMarketAnalysisView from "app/main/search/result/LoadSearchMarketAnalysisView";
import TripTimelineView from "app/main/trip-timeline/TripTimelineView";
import ValidatedMoveInfoView from "app/main/search/load/ValidatedMoveInfoView";
import CreateGoalsView from "app/main/profile/goals/CreateGoalsView";
import CreateNewGoals from "app/main/profile/costs/CreateNewGoalsView";
import TripImport from "app/main/profile/trips/TripImport";
import StrategySummary from "../tree-visualizer/StrategySummary";
import ExportListView from "@smarthop/list/views/export/ExportListView";
import AdminAnalyticsMetricView from "app/main/profile/admin/AdminAnalyticsMetricView";
import EnterprisePayrollView from "app/main/finance/enterprise-payroll/EnterprisePayrollView";
import EnterprisePayrollRulesView from "app/main/finance/enterprise-payroll/EnterprisePayrollRulesView";
import EnterprisePayrollJournalEntryDetailsView from "app/main/finance/enterprise-payroll/EnterprisePayrollJournalEntryDetailsView";
import FileSharingDialog from "app/main/tools/FileSharingDialog";
import StrategyDialog from "../strategy/StrategyDialog";
import RedirectHistoryForm from "app/main/history/RedirectHistoryForm";
import TripsNeedDeliveredView from "app/main/widgets/static/TripsNeedDeliveredView";
import CalculatorLoadView from "app/main/calculator/CalculatorLoadView";
import TripImportConfirm from "../profile/trips/TripImportConfirm";
import VideoDialog from "../video/VideoDialog";
import MultiFileUploadView from "../multi-file-upload/MultiFileUploadView";
import FileVisualizerView from "../files/FileVisualizerView";
import TripInvoicingView from "../profile/trips/invoicing/TripInvoicingView";
import TripInvoicingSendInvoiceView from "../profile/trips/invoicing/TripInvoicingSendInvoiceView";
import TripInvoicingHistoryView from "../profile/trips/invoicing/TripInvoicingHistoryView";
import AddPlanAddonView from "../profile/carriers/add-ons/AddPlanAddonView";
import AddPlanAddonCarrierView from "../profile/carriers/add-ons/AddPlanAddonCarrierView";
import TruckView from "../profile/trucks/TruckView";
import TrailerView from "../profile/trucks/TrailerView";
import TripFilesForm from "../profile/trips/TripFilesForm";
import BrokerInfoView from "../profile/brokers/BrokerInfoView";
import BrokerEditView from "../profile/brokers/BrokerEditView";
import InternalLoadView from "../searchV3/brokerage/InternalLoadView";
import TransferOwnershipView from "../profile/carriers/TransferOwnershipView";
import OnboardingPage from "app/main/onboarding/OnboardingPage";
import TripTestDataView from "../profile/trips/TripTestDataView";
import SmartPaySubmissionView from "../profile/trips/invoicing/SmartPaySubmissionView";
import SmartPayInvoicesNeedAttentionView from "../widgets/SmartPayInvoicesNeedAttentionView";
import SmartPayPayoutView from "../finance/smartpay/SmartPayPayoutView";
import SmartPayPayoutEditView from "../profile/trips/invoicing/SmartPayPayoutEditView";
import FuelTransactionView from "app/main/finance/fuel/FuelTransactionView";
import TripsNeedPod from "app/main/widgets/static/TripsNeedPod";
import MarketView from "app/main/market-conditions/MarketView";
import DebugTrackingForm from "./DebugTrackingForm";
import ChangeStatusView from "../status/ChangeStatusView";
import TripTracking from "../profile/trips/TripTracking";
import PaywallView from "app/main/billing/paywall/PaywallView.js";

// Consts
import { HISTORY_VIEWS } from "app/main/consts";
import { authRoles } from "app/main/consts";

// Utils
import Draggable from "react-draggable";
import CarrierReportView from "../reports/CarrierReportView";
import AdminReleaseForm from "../version/release/AdminReleaseForm";
import CarrierEmailAuthTypeForm from "../profile/carriers/CarrierEmailAuthTypeForm";
import ReleaseList from "./ReleaseList";

const useStyles = makeStyles((theme) => ({
	paper: {
		color: theme.palette.text.primary,
	},
	alignBottom: {
		marginTop: "100vh",
	},
}));

// try to generate unique key, will only detect toplevel changes in dataIds
const genKey = (viewId, dataIds) => {
	if (!dataIds) return viewId;
	return (
		viewId +
		Object.entries(dataIds)
			.sort((a, b) => a[0].localeCompare(b[0]))
			.reduce((prev, cur) => {
				return prev + cur[0] + cur[1];
			}, viewId)
	);
};

const renderView = (
	viewId,
	dataIds,
	ref,
	onDone,
	onProgress,
	onMinimize,
	mode,
	readOnly,
	updateDialog,
	setTitle,
	setSize,
	setClasses,
	onClose
) => {
	const key = genKey(viewId, dataIds);
	const callbacks = {
		onDone,
		onProgress,
		onMinimize,
		updateDialog,
		setTitle,
		setSize,
		setClasses,
		onClose,
	};
	if (viewId === "DOWNLOAD_APP_VIEW") {
		return <DownloadApp key={key} />;
	} else if (viewId === "LOAD_STRATEGY_VIEW" || viewId === "CURRENT_STRATEGY_VIEW") {
		return <LoadStrategyTabsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "LOAD_SUGGEST_VIEW") {
		return <LoadSearchView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CHAT_APP") {
		return <ChatApp key={key} {...callbacks} />;
	} else if (viewId === "CHAT_APP_PREVIEW_TRUCK_EXTERNAL") {
		return <ChatApp key={key} dataIds={dataIds} {...callbacks} type={"TRUCK_EXTERNAL"} />;
	} else if (viewId === "CREDIT_CARD") {
		return <CreditCardView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "BILLING_PLAN") {
		return <PlatformPlanView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "BILLING_PLAN_CHANGE") {
		return <PlatformAvailablePlansView variant="dialog" key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "OFFER_LOWER_PLAN") {
		return <PlarformOfferLowerPlanView variant="dialog" key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "FUEL_TERMS_VIEW") {
		return <FuelTermsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMS_TERMS_VIEW") {
		return <TermsView key={key} dataIds={{ ...(dataIds ?? {}), type: "SMS_TEMRS" }} {...callbacks} />;
	} else if (viewId === "SMARTHOP_TERMS_VIEW") {
		return <TermsView key={key} dataIds={{ ...(dataIds ?? {}), type: "SERVICE_TERMS" }} {...callbacks} />;
	} else if (viewId === "PRIVACY_POLICY_VIEW") {
		return <TermsView key={key} dataIds={{ ...(dataIds ?? {}), type: "PRIVACY_POLICY" }} {...callbacks} />;
	} else if (viewId === "PASSWORD_RECOVERY_VIEW") {
		return <ResetPasswordView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "PASSWORD_RECOVERY_SUCCESS_VIEW") {
		return <ResetPasswordSuccessView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRANSACTIONS_EXECUTE_LOGS") {
		return <TransactionStateLogsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ADMIN_CHRON_JOB_LOGS") {
		return <AdminCronJobLogsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ADMIN_ANALYTICS_JOB_LOGS") {
		return <AdminAnalyticsJobLogsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ADMIN_LOAD_TRACKING_DETAILS") {
		return <AdminLoadTrackingDetailsPage key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "INVOICE_CHARGE_VIEW") {
		return <InvoiceChargeView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_VIEW") {
		return <TripView ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_EDIT_VIEW") {
		return <TripEditView key={key} ref={ref} dataIds={dataIds} {...callbacks} mode={mode} />;
	} else if (viewId === "USER_ACCOUNTS_VIEW") {
		return <UserAccountsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SEARCH_REQUEST") {
		return <SearchRequestView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SEARCH_REQUEST_REDIRECT") {
		return <SearchRequestRedirectView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "COST_STRUCTURE") {
		return <CostStructureForm ref={ref} key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "COST_STRUCTURE_VIEW") {
		return <CostStructureViewDialog ref={ref} key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRUCK_PLAN_VIEW") {
		return <TruckPlanView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRUCK_PLAN") {
		return <TruckPlanEdit key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "UPLOAD_FILES_FORM") {
		return <TripFilesForm key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "POST_TRUCK_HISTORY") {
		return <PostTruckHistory key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "POST_TRUCK_LOGS") {
		return <PostTruckLogs key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "POST_TRUCK") {
		return <PostTruck key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "USER_DETAILS_VIEW") {
		return <UserDetailsView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CARRIER_USER_EDIT_VIEW") {
		return <CarrierUsersEditView key={key} mode={mode} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRUCK_DETAILS_VIEW") {
		return <TruckDetailsDialog ref={ref} key={key} dataIds={dataIds} {...callbacks} readOnly={readOnly} />;
	} else if (viewId === "CARRIER_INFO_VIEW") {
		return <CarrierInfoView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "PROFILE_INFO_VIEW") {
		return <ProfileInfoView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ACCOUNT_INFO_VIEW") {
		return <AccountInfoView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TREE_VISUALIZER") {
		return <TreeVisualizerView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "LOAD_STRATEGY_VISUALIZER") {
		return <LoadStrategyVisualizer key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "STRATEGY_SUMMARY") {
		return <StrategySummary key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "HISTORY_VIEW") {
		return <HistoryViewForm key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "PHONE_CONFIRM") {
		return <PhoneConfirmView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "EMAIL_CONFIRM") {
		return <EmailConfirmationView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "COMPANY_INFO_CONFIRM") {
		return <CompanyInfoConfirmationView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTHOP_WALLET_TRANSFER_FUNDS_VIEW") {
		return <SmartHopWalletTransferFundsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTHOP_WALLET_VIEW") {
		return <SmartHopWalletView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTHOP_WALLET_VERIFICATION_CODE_VIEW") {
		return <SmartHopWallet2FAVerificationView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTPAY_PURCHASE_DEBIT_CARD_VIEW") {
		return <SmartHopWalletPurchaseDebitCardView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "FACTORING_REQUEST_DETAIL_VIEW") {
		return <AdminFactoringRequestDetailView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "LOADS_SEARCH_RESULTS_VIEW") {
		return <LoadSearchResultView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "LOADS_SEARCH_MARKET_ANALYSIS_VIEW") {
		return <LoadSearchMarketAnalysisView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_TIMELINE") {
		return <TripTimelineView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "VALIDATED_MOVE") {
		return <ValidatedMoveInfoView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CREATE_GOALS_CARRIER_VIEW") {
		return <CreateGoalsView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CREATE_NEW_GOALS_VIEW") {
		return <CreateNewGoals key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIPS_CSV_IMPORT_VIEW") {
		return <TripImport key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "EXPORT_LIST") {
		return <ExportListView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ANALYTICS_METRIC_DETAILS") {
		return <AdminAnalyticsMetricView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "PAYROLL_VIEW") {
		return <EnterprisePayrollView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "REPORT_VIEW") {
		return <CarrierReportView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "PAYROLL_RULES_VIEW") {
		return <EnterprisePayrollRulesView key={key} dataIds={dataIds} {...callbacks} mode={mode} />;
	} else if (viewId === "PAYROLL_JOURNAL_ENTRY_DETAILS_VIEW") {
		return <EnterprisePayrollJournalEntryDetailsView key={key} dataIds={dataIds} {...callbacks} mode={mode} />;
	} else if (viewId === "TRIP_IMPORT_CONFIRM") {
		return <TripImportConfirm key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "STRATEGY_VISUALIZER") {
		return <StrategyDialog key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "REDIRECT_HISTORY_VIEW") {
		return <RedirectHistoryForm key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIPS_NEED_DELIVERED") {
		return <TripsNeedDeliveredView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "INVOICES_NEED_USER_ATTENTION") {
		return <SmartPayInvoicesNeedAttentionView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CALCULATOR_LOAD_VIEW") {
		return <CalculatorLoadView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "VIDEO_DIALOG") {
		return <VideoDialog key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "MULTI_FILE_UPLOAD_VIEW") {
		return <MultiFileUploadView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "FILE_VISUALIZER_VIEW") {
		return <FileVisualizerView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_INVOICE_VIEW") {
		return <TripInvoicingView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTPAY_SUBMISSION_VIEW") {
		return <SmartPaySubmissionView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTPAY_PAYOUT_EDIT_VIEW") {
		return <SmartPayPayoutEditView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SEND_TRIP_INVOICE_VIEW") {
		return <TripInvoicingSendInvoiceView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "FILE_SHARING_DIALOG") {
		return <FileSharingDialog key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ADD_PLAN_ADDON") {
		return <AddPlanAddonView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ADD_PLAN_ADDON_CARRIER") {
		return <AddPlanAddonCarrierView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRUCK_VIEW") {
		return <TruckView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRAILER_VIEW") {
		return <TrailerView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "BROKER_INFO_VIEW") {
		return <BrokerInfoView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "BROKER_EDIT_VIEW") {
		return <BrokerEditView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "INTERNAL_LOAD_VIEW") {
		return <InternalLoadView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_INVOICE_HISTORY_VIEW") {
		return <TripInvoicingHistoryView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRANSFER_VIEW") {
		return <TransferOwnershipView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ONBOARDING_VIEW") {
		return <OnboardingPage key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "SMARTPAY_PAYOUT_VIEW") {
		return <SmartPayPayoutView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_TEST_DATA_VIEW") {
		return <TripTestDataView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "FUEL_TRANSACTION_VIEW") {
		return <FuelTransactionView key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIPS_NEED_POD") {
		return <TripsNeedPod key={key} ref={ref} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "ADMIN_RELEASES") {
		return <AdminReleaseForm key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "MARKET_VIEW") {
		return <MarketView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CARRIER_EMAIL_AUTH_TYPE") {
		return <CarrierEmailAuthTypeForm key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "DEBUG_TRACKING") {
		return <DebugTrackingForm key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "CHANGE_STATUS") {
		return <ChangeStatusView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "TRIP_TRACKING") {
		return <TripTracking key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "FUEL_TRANSACTION_HISTORY_VIEW") {
		return <TripInvoicingHistoryView key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "RELEASE_LIST") {
		return <ReleaseList ref={ref} key={key} dataIds={dataIds} {...callbacks} />;
	} else if (viewId === "PAYWALL_VIEW") {
		return <PaywallView ref={ref} key={key} dataIds={dataIds} {...callbacks} />;
	}
};

const findDirectFormAncestorIndex = (stack) => {
	const newStack = stack.map((dialog) => (!dialog?.viewId ? dialog : null));
	const lastAncestorIndex = newStack.findLastIndex((i) => i === null) + 1;
	return lastAncestorIndex;
};

const getFormTitle = (stack, user) => {
	const lastAncestorIndex = findDirectFormAncestorIndex(stack);
	const newStack = stack.slice(lastAncestorIndex);

	let title = "";
	newStack.forEach((dialog) => {
		const formId = dialog?.formId;
		const mode = dialog?.mode;
		const form = dialog?.formInstance ?? (formId ? buildForm(formId, { user }) : undefined);

		if (title) title += " > ";
		if (mode === "CREATE") {
			title += form?.header?.create?.label ?? formId;
		} else if (mode === "EDIT") {
			title += form?.header?.edit?.label ?? formId;
		} else if (mode === "DELETE") {
			title += form?.header?.delete?.label ?? formId;
		} else if (mode === "VIEW") {
			title += form?.header?.view?.label ?? formId;
		} else if (mode === "COMPARE") {
			title += form?.header?.compare?.label ?? formId;
		} else {
			console.error(`[FormDialog] Can't open dialog, not supported mode ${mode}`);
			return null;
		}
	});
	return title;
};

const isMinimizeEnabled = (viewId, dataIds) => {
	if (viewId === "LOAD_SUGGEST_VIEW") {
		return false;
	} else {
		return true;
	}
};

const showRawView = (viewId, dataIds) => {
	if (viewId === "CHAT_APP" || viewId === "CHAT_APP_PREVIEW_TRUCK_EXTERNAL") {
		return true;
	}
};

const getCurrentViewMaxSize = (formStack, user) => {
	const visibleDialog = formStack[formStack.length - 1];
	const viewId = visibleDialog?.viewId;
	if (viewId) {
		return "max-w-4xl";
	} else {
		const formId = visibleDialog?.formId;
		const formInstance = visibleDialog?.formInstance;
		const mode = visibleDialog?.mode;
		const form = formInstance ?? (formId ? buildForm(formId, { user }) : undefined);
		if (form) {
			if (form.size) return `${form.size}`;

			let pannels =
				mode === "DELETE"
					? 1 // No pannels for delete mode
					: 1 + // Central
					  (!!form.content?.create?.right || !!form.content?.edit?.right || !!form.content?.view?.right ? 1 : 0) + // Right
					  (!!form.content?.create?.left || !!form.content?.edit?.left || !!form.content?.view?.left ? 1 : 0); // Left

			// Giving more space for dialog in case that dialog has side panels
			return `max-w-${pannels > 2 ? "3" : pannels > 1 ? "2" : ""}xl`;
		}
	}
};

const disableEnforceFocus = (viewId) => {
	// Dialogs where plaid component is used require this prop
	const plaidViews = ["CARRIER_INFO_VIEW", "SMARTHOP_WALLET_TRANSFER_FUNDS_VIEW"];
	return plaidViews.includes(viewId) ?? false;
};

function FormDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const renderDialogStack = useSelector(({ tools }) => tools.formDialog.renderDialogStack ?? []);
	const minimizedStacks = useSelector(({ tools }) => tools.formDialog.minimizedStacks ?? []);
	const stack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const onPreventClose = useSelector(({ tools }) => tools.formTools.preventCloseCallback);
	const [processing, setProcessing] = useState(false);
	const [buttons, setButtons] = useState([]);
	const [buttonsViewId, setButtonsViewId] = useState(null);
	const [buttonsRevision, setButtonsRevision] = useState(0);

	const [dialogTitle, setDialogTitle] = useState({});
	const [dialogSubtitle, setDialogSubtitle] = useState({});
	const [dialogSize, setDialogSize] = useState({});
	const [dialogClasses, setDialogClasses] = useState({});
	const [mobile, setMobile] = useState(window.innerWidth < 960);

	const setTitle = (newTitle, subTitle) => {
		const visibleForm = stack.length > 0 ? stack[stack.length - 1] : null;
		if (visibleForm?.viewId) {
			setDialogTitle((val) => ({ ...val, [visibleForm?.viewId]: newTitle }));
			setDialogSubtitle((val) => ({ ...val, [visibleForm?.viewId]: subTitle }));
		}
	};

	const setSize = (newSize) => {
		const visibleForm = stack.length > 0 ? stack[stack.length - 1] : null;
		if (visibleForm?.viewId) {
			setDialogSize((val) => ({ ...val, [visibleForm?.viewId]: newSize }));
		}
	};

	const setClasses = (newClasses) => {
		const visibleForm = stack.length > 0 ? stack[stack.length - 1] : null;
		if (visibleForm?.viewId) {
			setDialogClasses((val) => ({ ...val, [visibleForm?.viewId]: newClasses }));
		}
	};

	const ref = useRef();
	const user = useSelector(({ auth }) => auth.user);
	const role = user.rolesLabels;
	const visibleForm = stack.length > 0 ? stack[stack.length - 1] : null;
	const isViewCustom = visibleForm?.viewId ? true : false;
	const visibleDialogId = visibleForm?.viewId ?? visibleForm?.formId;
	const visibleFormSize = visibleForm?.size;
	const seeHistory =
		HISTORY_VIEWS.includes(visibleDialogId) &&
		(!visibleForm?.mode || visibleForm?.mode === "EDIT") &&
		visibleForm?.dataIds &&
		!visibleForm?.dataIds?.historyData &&
		[...authRoles.carrierPowerUsers, ...authRoles.internalUsers]?.includes(role?.[0]) &&
		!mobile;
	const dialogBtnsRevision = useSelector(({ tools }) => tools.revision["dialogBtnsRevision"]);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const minimize = (event) => {
		document.body.style.overflow = "auto";
		dispatch(minimizeStack());
	};

	const maximize = (dialogIndex) => {
		document.body.style.overflow = "hidden";
		dispatch(maximizeStack(dialogIndex));
	};

	const closeMinimized = (dialogIndex) => {
		dispatch(closeMinimizedStack(dialogIndex));
	};

	const close = (event) => {
		if (typeof onPreventClose === "function") {
			onPreventClose();
		} else {
			visibleForm?.dataIds?.DANGEROUS_onDone?.();
			dispatch(closeFormDialog());
			ref?.current?.lifecyle?.onClose?.();
		}
	};

	const onClickBtn = (btn) => {
		const btnConfig = ref.current[btn];
		if (btnConfig instanceof Function) {
			return btnConfig();
		}

		btnConfig?.onClick();
	};

	useEffect(() => {
		let mode = visibleForm?.mode ?? "VIEWED";
		const viewId = visibleForm?.viewId;
		if (visibleDialogId) {
			const title = viewId ? dialogTitle[viewId] : getFormTitle(stack, user);
			console.log("[FormDialog] - Vising Form Dialog using viewId ", visibleDialogId);
			createTrackOrPage(
				FORM_DIALOG_EVENT(visibleDialogId, mode),
				{ title: title, view: visibleDialogId },
				"page",
				title
			);
		}
		//eslint-disable-next-line
	}, [visibleDialogId]);

	const getBtnTitle = (btn) => {
		const btnConfig = ref.current[btn];
		return btnConfig instanceof Function ? btn : btnConfig?.title ? btnConfig.title : btn;
	};

	const getBtnIcon = (btn) => {
		const btnConfig = ref.current[btn];
		return btnConfig instanceof Function ? btn : btnConfig?.icon ? btnConfig.icon : btn;
	};

	const DialogBtn = () => {
		if (visibleFormSize === "MINIMIZED" || buttonsViewId !== visibleDialogId || !buttons) {
			return null;
		}

		let buttonMenu = [];
		let buttonOption = [];

		buttons.forEach((btn) => {
			let current = ref?.current?.[btn];
			if (!current) return;

			current.icon = getBtnIcon(btn);
			current.title = getBtnTitle(btn);
			if ((current instanceof Function || !current?.onSelect) && current?.onClick) {
				current.onClick = current instanceof Function ? () => onClickBtn(btn) : current.onClick;
				buttonMenu.push(current);
			} else if (current.options?.length) {
				current.options = current.options.map((item) => {
					return typeof item === "string" ? { title: item, onSelect: current.onSelect } : item;
				});
				buttonOption.push(current);
			}
		});

		return (
			<div className="flex">
				{/* BUTTONS WITH OPTIONS */}
				{buttonOption.map((btn) => {
					return (
						<DropdownButton
							key={"button-" + btn?.icon}
							icon={btn?.icon}
							options={btn?.options}
							onSelect={btn?.onSelect}
						/>
					);
				})}

				{/* BUTTONS WITHOUT OPTIONS (WEB) */}
				<div className="hidden sm:flex">
					{buttonMenu.map((btn) => {
						return (
							<Tooltip key={"button-" + btn?.title} title={btn?.title} placement="top">
								<IconButton color="inherit" onClick={btn?.onClick}>
									<Icon className="text-24">{btn?.icon}</Icon>
								</IconButton>
							</Tooltip>
						);
					})}
				</div>

				{/* BUTTONS WITHOUT OPTIONS (MOBILE) */}
				<div className="flex sm:hidden">
					<DropdownButton key={"button-more_ver"} icon="more_vert" options={buttonMenu} />
				</div>
			</div>
		);
	};

	useEffect(() => {
		let timout = setTimeout(() => {
			setButtonsRevision(buttonsRevision + 1);
		}, 100);
		return () => {
			clearTimeout(timout);
		};
		// eslint-disable-next-line
	}, [stack, dialogBtnsRevision]);

	useEffect(() => {
		const buttons = ref.current ? Object.keys(ref.current) : [];
		setButtonsViewId(visibleDialogId);
		setButtons(buttons);
		// eslint-disable-next-line
	}, [buttonsRevision]);

	let dialogContent = renderDialogStack.map((item) => {
		const dataIds = item?.dataIds;
		const viewId = item?.viewId;
		const mode = item?.mode;
		const readOnly = item?.readOnly;
		const titleOverrides = item?.titleOverrides;
		const dialogId = item.dialogId;
		const formStackIndex = stack.findIndex((dialog) => dialogId === dialog.dialogId);
		const dialogInVisibleStack = formStackIndex !== -1;
		const isVisibleDialog = dialogInVisibleStack && formStackIndex === stack.length - 1;

		if (viewId) {
			let view = renderView(
				viewId,
				dataIds,
				isVisibleDialog ? ref : null,
				(data) => {
					dataIds?.DANGEROUS_onDone?.(data);
					dispatch(closeFormDialog());
					ref?.current?.lifecyle?.onClose?.();
				},
				(progress) => {
					setProcessing(progress);
				},
				() => {
					if (window.innerWidth > 960) {
						dispatch(setFormDialogSize("MINIMIZED"));
						dispatch(minimizeStack());
					} else {
						dispatch(closeFormDialog());
						ref?.current?.lifecyle?.onClose?.();
					}
				},
				mode,
				readOnly,
				(updateData) => {
					dispatch(updateFormDialog(updateData));
				},
				setTitle,
				setSize,
				setClasses,
				() => {
					close();
				}
			);
			const replaceTitle = titleOverrides?.replace;
			let title = dialogTitle[viewId];
			let subTitle = dialogSubtitle[viewId];
			let classes = dialogClasses[viewId];
			if (!view) {
				console.error(`[FormDialog] Can't open dialog, view ${view} not found`);
				return null;
			}
			if (showRawView(viewId)) {
				return (
					<div key={"content_" + dialogId} className={"p-0 m-0 " + (isVisibleDialog ? "" : "hidden")}>
						{view}
					</div>
				);
			} else {
				return [
					<DialogTitle
						key={"title_" + dialogId}
						component="div"
						className={"p-0 " + (isVisibleDialog ? "" : "hidden") + " " + (classes?.title ?? "")}
					>
						<AppBar position="static" elevation={0}>
							<Toolbar className={"flex w-full overflow-x-auto px-8 sm:px-16 " + (classes?.toolbar ?? "")}>
								<div className="flex flex-1 items-center text-14 md:text-15 px-10">
									{visibleFormSize !== "MINIMIZED" ? (
										<div className="flex flex-col">
											<Typography className="font-medium text-14 ml:text-15 tracking-wide" component="div">
												{title}
											</Typography>
											{subTitle && (
												<Typography
													className="hidden md:flex font-light -mt-1 text-11 ml:text-12 tracking-wide"
													component="div"
												>
													{subTitle}
												</Typography>
											)}
										</div>
									) : (
										<>
											{`${!replaceTitle && title + " "}${titleOverrides?.content ?? ""}`}
											{titleOverrides?.component}
										</>
									)}
								</div>

								{buttons.length > 0 && <DialogBtn />}
								{visibleFormSize !== "MINIMIZED" && seeHistory && (
									<div>
										<HistoryView
											viewId={visibleDialogId}
											isViewCustom={isViewCustom}
											dataIds={visibleForm?.dataIds}
										></HistoryView>
									</div>
								)}
								{isMinimizeEnabled(viewId) && (
									<Tooltip title={visibleFormSize === "MINIMIZED" ? "Maximize" : "Minimize"} placement="top">
										<IconButton color="inherit" className="hidden lg:flex" onClick={minimize}>
											<Icon className="text-22">
												{visibleFormSize === "MINIMIZED" ? "open_in_full" : "close_fullscreen"}
											</Icon>
										</IconButton>
									</Tooltip>
								)}
								<div className="flex items-center justify-center min-w-52">
									{processing ? (
										<Box mt={1}>
											<CircularProgress size={30} color="inherit" className="p-4" />
										</Box>
									) : (
										<IconButton color="inherit" onClick={close}>
											<Icon>close</Icon>
										</IconButton>
									)}
								</div>
							</Toolbar>
						</AppBar>
					</DialogTitle>,
					<DialogContent
						key={"content_" + dialogId}
						className={
							"px-4 py-4 md:px-8 md:py-4 sm:px-12 sm:py-8 " +
							(classes?.content ?? "") +
							(isVisibleDialog && visibleFormSize !== "MINIMIZED" ? " " : " hidden ")
						}
					>
						{view}
					</DialogContent>,
				];
			}
		} else {
			const formId = item?.formId;
			const formInstance = item?.formInstance;
			const mode = item?.mode;
			const dataIds = item?.dataIds;
			const dataOverrides = item?.dataOverrides;
			const data = item?.data;
			const form = formInstance ?? (formId ? buildForm(formId, { user }) : undefined);

			let error = item?.error;
			if (!form) {
				console.error(`[FormDialog] Can't open dialog, form ${formId} not found`);
				error = new Error("Form not found");
			}
			if (mode !== "CREATE" && !data && !error) {
				console.error(`[FormDialog] Can't open dialog, entity ${formId} not loaded for form ${formId}`);
				return null;
			}
			if (!mode) {
				console.error(`[FormDialog] Can't open dialog, dialog mode is not specified`);
				return null;
			}

			const title = isVisibleDialog ? getFormTitle(stack, user) : "";
			return [
				<DialogTitle key={"title_" + dialogId} component="div" className={"p-0" + (isVisibleDialog ? "" : "hidden")}>
					<AppBar position="static" elevation={0}>
						<Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16">
							<div className="flex flex-1 text-16 md:text-18 px-10">{title}</div>
							{processing && <CircularProgress color="inherit" className="p-4" />}
							{seeHistory && (
								<div>
									<HistoryView
										viewId={visibleDialogId}
										isViewCustom={isViewCustom}
										dataIds={visibleForm?.dataIds}
									></HistoryView>
								</div>
							)}
							<IconButton color="inherit" className="hidden lg:flex" onClick={minimize}>
								<Icon>{visibleFormSize === "MINIMIZED" ? "open_in_full" : "close_fullscreen"}</Icon>
							</IconButton>
							<IconButton color="inherit" onClick={close}>
								<Icon>close</Icon>
							</IconButton>
						</Toolbar>
					</AppBar>
				</DialogTitle>,
				<DialogContent
					key={"content_" + dialogId}
					className={
						"px-4 pb-16 mt-2 md:px-8 md:pb-4 " +
						(error ? "" : " pt-16 md:pt-16 ") +
						(isVisibleDialog && visibleFormSize !== "MINIMIZED" ? "" : "hidden")
					}
				>
					{error ? (
						<Typography color="error" variant="body1" className="m-20 p-20 mb-24" align="center">
							{error ? error.message : "Something went wrong, please try again..."}
						</Typography>
					) : (
						<SmarthopForm
							form={form}
							dataIds={dataIds}
							data={data}
							dataOverrides={dataOverrides}
							mode={mode}
							onDone={() => {
								dispatch(closeFormDialog());
								ref?.current?.lifecyle?.onClose?.();
							}}
							onProgress={(progress) => {
								setProcessing(progress);
							}}
						/>
					)}
				</DialogContent>,
			];
		}
	});

	const minimized = !stack.length && !!minimizedStacks.length;
	const size = dialogSize[visibleDialogId] ?? getCurrentViewMaxSize(stack, user);
	return (
		<>
			<Dialog
				disableEnforceFocus={disableEnforceFocus(visibleDialogId)}
				disableBackdropClick={minimized}
				classes={{
					root: minimized ? clsx("ml-0 md:ml-56 max-w-480 max-h-96", classes.alignBottom) : null,
					paper: clsx(
						classes.paper,
						minimized ? "w-full max-w-480 max-h-96 -mt-72" : size + " w-full m-12 md:m-24 z-10"
					),
				}}
				BackdropProps={minimized ? { style: { width: "0px", height: "0px" } } : null}
				onClose={close}
				open={renderDialogStack.length > 0}
			>
				{dialogContent}
			</Dialog>
			{minimizedStacks?.map((dStack, i) => {
				const topMinimizedDialog = dStack[dStack.length - 1];
				const titleOverrides = topMinimizedDialog?.titleOverrides;
				const replaceTitle = titleOverrides?.replace;
				const { viewId } = topMinimizedDialog ?? {};
				const title = viewId ? dialogTitle[viewId] : getFormTitle(dStack, user);
				const dialogId = topMinimizedDialog?.dialogId;

				return (
					<Draggable key={"minimized_drag_" + dialogId} handle=".dragDialog">
						<Dialog
							disableBackdropClick={true}
							disableEnforceFocus
							classes={{
								root: clsx("ml-0 md:ml-56 max-w-480 max-h-96", classes.alignBottom),
								paper: clsx(classes.paper, `w-full max-w-480 max-h-96`),
							}}
							BackdropProps={{ style: { width: "0px", height: "0px" } }}
							onClose={close}
							open={minimizedStacks.length > 0 && !stack.length}
							PaperProps={{
								style: { marginTop: `${-18 - 15 * i}rem` },
							}}
						>
							<DialogTitle key={"minimized_title_" + dialogId} component="div" className={"p-0"}>
								<AppBar position="static" elevation={0} className="dragDialog cursor-move">
									<Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16">
										<div className="flex flex-1 items-center text-16 md:text-18 px-10">
											{`${!replaceTitle && title + " "}${titleOverrides?.content ?? ""}`}
											{titleOverrides?.component}
										</div>
										{processing && (
											<Box mt={1}>
												<CircularProgress color="inherit" className="p-4" />
											</Box>
										)}
										{isMinimizeEnabled(topMinimizedDialog.viewId) && (
											<Tooltip title={"Maximize"} placement="top">
												<IconButton color="inherit" className="hidden lg:flex" onClick={() => maximize(i)}>
													<Icon className="text-24">open_in_full</Icon>
												</IconButton>
											</Tooltip>
										)}
										<IconButton color="inherit" onClick={() => closeMinimized(i)}>
											<Icon>close</Icon>
										</IconButton>
									</Toolbar>
								</AppBar>
							</DialogTitle>
						</Dialog>
					</Draggable>
				);
			}) ?? null}
		</>
	);
}

export default FormDialog;
