import Typography from "@material-ui/core/Typography";

function CellEmptyRow(props) {
	const mobile = props.mobile;
	const variant = props.variant;

	return (
		<Typography className={"pr-4 " + (variant === "extra-skinny" || mobile ? "" : "min-w-120")}>
			{props.children ?? "-"}
		</Typography>
	);
}

export default CellEmptyRow;
