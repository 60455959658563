import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { createForm } from "./TripTimelineForm";
import { makeStyles } from "@material-ui/core/styles";
import { NARROW_FIELDS } from "@fuse/default-settings";

const useStyles = makeStyles((theme) => {
	return {
		buttonNormal: {
			borderRadius: "4px",
			height: NARROW_FIELDS ? "4.6rem" : "5.2rem",
		},
		buttonDisabled: {
			background: theme.palette.grey["400"] + " !important",
		},
	};
});

const TripTimelinePanel = ({ onSubmit, defaultValues, errors, loadingError }) => {
	const classes = useStyles();
	let form = createForm(classes);

	return (
		<SmarthopFormView
			content={form}
			mode={"CREATE"}
			noInitValidation={true}
			onSubmit={onSubmit}
			loadingError={loadingError}
			errors={errors}
			data={defaultValues}
		/>
	);
};

export default TripTimelinePanel;
