import Box from "@material-ui/core/Box";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { StatusCount } from "./configs/tripImportConfigComponents";

const summaryLabels = {
	error: "Errors",
	duplicate: "Duplicate",
	valid: "Valid",
	uploaded: "Uploaded",
	total: "Total",
};

const createConfig = (summary) => ({
	items: [
		{
			type: "group",
			group: {
				classes: {
					root: "",
					direction: "flex-row",
				},
			},
			content: {
				items: Object.entries(summary).map(([status, count]) => ({
					key: status,
					type: "component",
					classes: { root: "text-center" },
					builder: () => <StatusCount status={status} count={count} label={summaryLabels[status]} />,
				})),
			},
		},
	],
});

const TripImportSummary = ({ summary }) => {
	return (
		<Box maxWidth={"300px"}>
			<SmarthopFormView mode="VIEW" content={createConfig(summary)} data={summary} />
		</Box>
	);
};

export default TripImportSummary;
