const itinerarySavedForm = {
	urlPOST: "api/profile/carriers/:carrierId/users/:userId/invitation/resend",
	idKey: "_id",
	header: {
		create: { label: "Invitation" },
	},
	content: {
		create: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to resend an invitation to this user?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL", label: "No", button: { alwaysEnabled: true } },
							{ type: "action", action: "SUBMIT", label: "Yes", button: { alwaysEnabled: true } },
						],
					},
				},
			],
		},
	},
};

export default itinerarySavedForm;
