import { build } from "../tools/ConfigBuilder";
import { lazy } from "react";

export default build(
	[
		{
			path: "/onboarding",
			component: lazy(() => import("./OnboardingPage")),
		},
	],
	null,
	["carrier"]
);
