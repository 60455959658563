import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export function resetPassword(email, code, password) {
	let url = `api/users/password-reset`;
	return createRequest()
		.post(url, { email, code, password }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function sendEmailForgotPassword(email) {
	let url = `api/users/password-recover`;
	return createRequest()
		.post(url, { email }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
