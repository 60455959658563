import { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Form
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { transferOwnershipConfig } from "./configs/transferOwnershipConfig";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

// Service
import { getNameOwnership, changeOwnership } from "app/services/carrierServices";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Conts
const _TITLE_CHANGE_OWNERSHIP = (
	beforeOwner,
	currentOwner
) => `Are you sure you want to transfer the Onwership of ${beforeOwner} to the carrier 
${currentOwner}? `;

function TransferOwnershipView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const { carrierId } = props?.dataIds;
	const onDone = props?.onDone;

	const [data, setData] = useState(null);
	const [beforeOwner, setBeforeOwner] = useState(null);
	const [currentOwner, setCurrentOwner] = useState(null);
	const [title, setTitle] = useState(null);
	const [loading, setLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState({ flag: false, title: null });

	useEffect(() => {
		props?.setTitle?.("Transfer Ownership");
		props?.setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getNameOwnership(carrierId).then(
			(response) => {
				setLoading(false);
				setBeforeOwner(response.name);
				setTitle(response.text);
			},
			(err) => {
				setLoading(false);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed..", "error");
			}
		);

		// eslint-disable-next-line
	}, []);

	const form = useMemo(() => transferOwnershipConfig(), []);

	const handledTransfer = () => {
		setLoading(true);
		setOpenDialog({ flag: false, title: null });

		changeOwnership(carrierId, data)
			.then((res) => {
				setLoading(false);
				showSnackbar(snackbar, "Successfully transfer ownership", "success");
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				onDone?.();
			})

			.catch((err) => {
				setLoading(false);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			});
	};

	return (
		<>
			<SmarthopFormView
				key={"transfer-owner-ship"}
				mode={"CREATE"}
				content={form}
				data={data}
				noInitValidation={true}
				dataIds={props?.dataIds}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					if (key === "currentUser") {
						setCurrentOwner(model?.currentUser__view?.label);
					}

					setData(model);
				}}
				onSubmit={() => {
					setOpenDialog({ flag: true, title: _TITLE_CHANGE_OWNERSHIP(beforeOwner, currentOwner) + ". " + title });
				}}
				processing={loading}
			/>
			<SmarthopConfirmDialog
				open={!!openDialog.flag}
				title={openDialog?.title}
				onClose={() => setOpenDialog({ flag: false, title: null })}
				onAccept={() => handledTransfer()}
				acceptMsg="Yes"
				closeMsg="No"
			/>
		</>
	);
}

export default TransferOwnershipView;
