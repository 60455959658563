import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import uploadTripsConfig from "../trips/configs/uploadTripsConfig";
import { openFormDialog, closeFormDialog } from "app/store/tools/formDialogSlice";
import { checkPendingImport } from "app/services/tripsServices";

const TripImport = (props) => {
	useEffect(() => {
		props?.setTitle?.("Import Trips");
		// eslint-disable-next-line
	}, []);
	const { carrierId } = props.dataIds;
	const dispatch = useDispatch();

	const dispatchImportDialog = useCallback(
		(dataIds) =>
			dispatch(
				openFormDialog({
					viewId: "TRIP_IMPORT_CONFIRM",
					dataIds,
				})
			),
		[] //eslint-disable-line
	);

	const [pendingImport, setPendingImport] = useState();

	useEffect(
		() => {
			(async () => {
				const isPending = await checkPendingImport(carrierId);
				setPendingImport(isPending);
			})();
		},
		[] //eslint-disable-line
	);

	useEffect(() => {
		if (pendingImport) {
			dispatch(closeFormDialog());
			dispatchImportDialog({ carrierId });
		}
	}, [dispatch, carrierId, dispatchImportDialog, pendingImport]);

	const handleSubmit = async (model) => {
		const fileId = model.csv_file[0];
		const platform = model.platform;
		dispatch(closeFormDialog());

		dispatchImportDialog({
			fileId,
			platform,
			carrierId,
		});
	};

	return (
		<div style={{ paddingTop: "20px" }}>
			<SmarthopFormView mode="CREATE" content={uploadTripsConfig} dataIds={{ carrierId }} onSubmit={handleSubmit} />
		</div>
	);
};

export default TripImport;
