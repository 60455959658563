import { formatDate } from "app/main/utils/dateUtils";

const formatAddress = (item, key) => {
	const data = item?.lastLogin?.[key];

	if (!data?.country) {
		return "-";
	}

	return `${data.city}, ${data.state}, ${data.country}`;
};

const userDetailsForm = {
	urlGET: "api/profile/users/:userId/lastLogin",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "lastLogin.web.loginDate",
								type: "text",
								label: "Web",
								builder: (item) =>
									!item?.lastLogin?.web?.loginDate
										? ""
										: formatDate(item?.lastLogin?.web?.loginDate) + " - " + formatAddress(item, "web"),
							},
							{
								key: "lastLogin.web.loginDate",
								type: "text",
								label: "Mobile",
								builder: (item) =>
									!item?.lastLogin?.mobile?.loginDate
										? ""
										: formatDate(item?.lastLogin?.mobile?.loginDate) + " - " + formatAddress(item, "mobile"),
							},
						],
					},
				},
			],
		},
	},
};

export default userDetailsForm;
