import _ from "lodash";
import { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { createMPIView } from "app/main/utils/brokerUtils";
import ValidateMoveButton from "app/main/search/load/ValidateMoveButton";
import { formatPercentage } from "app/main/utils/tableUtils";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

const CustomTableCell = (props) => {
	return <TableCell style={{ verticalAlign: "top" }} {...props} />;
};

const TableCheckBox = (props) => {
	return <Checkbox color="primary" size="small" {...props} style={{ padding: 0 }} />;
};

const MemoCheckBox = memo(TableCheckBox);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(even)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const createArrayOrPush = (arr, i, val) => {
	if (Array.isArray(arr[i])) {
		arr[i].push(val);
	} else {
		arr[i] = val;
	}
};

const validateButtonIndex = 4;

const MovesTable = ({
	isFirstLevel,
	saving,
	tablePaths,
	selectedPaths,
	selectAll,
	setSelectAll,
	setTablePaths,
	summary,
	truckInfo,
	marketConditions,
	strategyReqData,
	classes,
}) => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{}}>
				<TableHead>
					<TableRow>
						<CustomTableCell>
							<Typography>Origin</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>Destination</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>RPM</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>MPI</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>Chance</Typography>
						</CustomTableCell>
						{saving && isFirstLevel && tablePaths && (
							<CustomTableCell style={{ textAlign: "center" }}>
								<Typography>Scan</Typography>
								<TableCheckBox
									disabled={selectedPaths && !_.isEmpty(selectedPaths)}
									checked={selectAll}
									onChange={() => {
										setSelectAll(!selectAll);
										const updatedTablePaths = { ...tablePaths };
										Object.keys(updatedTablePaths).forEach((key) => {
											updatedTablePaths[key].selected = !selectAll;
										});
										setTablePaths(updatedTablePaths);
									}}
								/>
							</CustomTableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.values(summary).map((origin, i) => (
						<StyledTableRow key={`${origin.city}-${i}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell component="th" scope="row" style={{ verticalAlign: "top" }}>
								<Typography>{origin.city}</Typography>
								{origin.prevIsEmpty && (
									<Typography style={{ color: "red", fontSize: "0.7em" }}>
										{`${origin.empty_ocity} - ${origin.city}`}
									</Typography>
								)}
							</TableCell>
							{Object.values(origin.dests)
								.reduce((acc, dest, deepIndex) => {
									const equipment = truckInfo?.truck__view?.metadata?.equipment?.toLowerCase();
									const ppm_best_norm_smooth =
										marketConditions?.map?.maps?.[equipment + "-ocluster-toplanes"]?.heatMap?.[dest.cluster]
											?.ppm_best_norm_smooth;

									createArrayOrPush(acc, 0, <Typography key={`row-${i}-dest-${deepIndex}`}>{dest.city}</Typography>);

									createArrayOrPush(
										acc,
										1,
										<Typography key={`row-${i}-rpm-${deepIndex}`}>{Number(dest.rpm).toFixed(2)}</Typography>
									);

									createArrayOrPush(
										acc,
										2,
										<div key={`row-${i}-mpi-${deepIndex}`}>
											{createMPIView({ rank_mci: ppm_best_norm_smooth }, true)}
										</div>
									);

									createArrayOrPush(
										acc,
										3,
										<Typography key={`row-${i}-probability-${deepIndex}`}>
											{dest.probability ? formatPercentage(1, dest.probability) : "-"}
										</Typography>
									);

									createArrayOrPush(
										acc,
										validateButtonIndex,
										<ValidateMoveButton
											key={`row-${i}-validate-${deepIndex}`}
											legData={dest.rawData}
											strategyReqData={strategyReqData}
											moveId={dest.moveId}
											variant="text"
										/>
									);

									if (saving && isFirstLevel && tablePaths) {
										createArrayOrPush(
											acc,
											5,
											<MemoCheckBox
												key={`row-${i}-check-${deepIndex}`}
												color="primary"
												className={classes.field}
												checked={tablePaths[dest.path_id]?.selected}
												onChange={() => {
													const tablePath = tablePaths[dest.path_id];
													const updatedTablePaths = {
														...tablePaths,
														[dest.path_id]: { ...tablePath, selected: !tablePath.selected },
													};
													setTablePaths(updatedTablePaths);
												}}
												disabled={selectedPaths && !_.isEmpty(selectedPaths)}
											/>
										);
									}

									return acc;
								}, [])
								.map((children, j) => (
									<TableCell key={`row-${i}-cell-${j}`} className={j === validateButtonIndex ? "hidden" : ""}>
										{children}
									</TableCell>
								))}
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MovesTable;
