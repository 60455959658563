import { Typography } from "@material-ui/core";

import { STEPS, TEXT_FONT_SIZE, STEPS_FONT_SIZE } from "./smarthopWalletLandingUtils";

const stepNumber = (value) => (
	<div className="flex justify-center items-center w-32 h-32 rounded-full mr-0 mb-16 md:mr-10 md:mb-0" style={{ background: "#FD6D35" }}>
		<Typography className={STEPS_FONT_SIZE + " font-semibold text-white"} variant="h6">
			{value}
		</Typography>
	</div>
);

const stepTitle = (title, active, index) => (
	<div className="flex flex-col md:flex-row items-center mb-20 justify-center md:justify-start">
		{stepNumber(index + 1)}
		<Typography
			className={STEPS_FONT_SIZE + " font-semibold tracking-tight"}
			style={{ color: active ? "#FD6D35" : "" }}
			variant="h6"
		>
			{title}
		</Typography>
	</div>
);

const stepBullet = (text, index) => (
	<Typography
		key={index}
		variant="body2"
		color="textPrimary"
		className={TEXT_FONT_SIZE + " mx-auto md:mx-0 font-light mb-10"}
	>
		• {text}
	</Typography>
);

const renderStep = ({ title, bullets, active }, index) => (
	<div key={index} className="w-max md:w-1/4 flex flex-col mx-auto md:mx-0 md:mr-12 mb-20 md:mb-0">
		{stepTitle(title, active, index)}
		{bullets.map((bullet, idx) => stepBullet(bullet, idx))}
	</div>
);

const SmartHopWalletLandingSteps = () => (
	<div className="w-full flex flex-col sm:grid sm:grid-cols-2 md:flex md:flex-row bg-white rounded-2xl items-start justify-between p-28">
		{STEPS.map((step, index) => renderStep(step, index))}
	</div>
);

export default SmartHopWalletLandingSteps;
