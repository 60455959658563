// Assets
import onboardingIrsW9 from "../../../assets/images/onboarding/onboarding_irs_w9.png";

const carrierOnboardingIRS = {
	urlGET: "api/profile/carriers/:carrierId/documents/completed_form",
	urlPUT: "api/profile/carriers/:carrierId/documents/completed_form",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Completed I.R.S form W9" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					type: "group",
					group: { classes: { child0: "w-full md:w-5/12", child1: "w-full md:w-7/12" } },
					content: {
						items: [
							{
								type: "image",
								items: [{ name: "I.R.S form W9", url: onboardingIrsW9 }],
							},
							{
								type: "group",
								group: { classes: { direction: "flex-col" } },
								content: {
									items: [
										{
											type: "message",
											label: `Internal Revenue Service (IRS) tax form used to confirm a person's name, 
												address, and taxpayer identification number (TIN) for employment or other 
												income-generating purposes.`,
										},
										{
											key: "completed_form",
											type: "upload",
											label: "Completed I.R.S form W9",
											translate: "COMPLETED_IRS_FORM_W9",
											upload: {
												url: "api/profile/carriers/:carrierId/files",
												documentType: "carrier",
												fileName: "completed_form",
												variant: "large",
											},
										},
										{ type: "action", action: "SUBMIT" },
									],
								},
							},
						],
					},
				},
			],
		},
	},
};

export default carrierOnboardingIRS;
