// Assets
import onboardingMcAuthorityLetter from "../../../assets/images/onboarding/onboarding_mc_authority_letter.png";
import onboardingIrsW9 from "../../../assets/images/onboarding/onboarding_irs_w9.png";
import onboardingFactoryCompany from "../../../assets/images/onboarding/onboarding_factoring_notice_of_assignment.png";
import onboardingInsurance from "../../../assets/images/onboarding/onboarding_cert_of_liability_insurance.webp";
import onboardingVoidCheck from "../../../assets/images/onboarding/onboarding_void_check_.png";

const carrierOnboardingAllDocuments = {
	urlGET: "api/profile/carriers/:carrierId/documents/packetOnboardingDocuments",
	urlPUT: "api/profile/carriers/:carrierId/documents/packetOnboardingDocuments",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Onboarding Documents (Single PDF)" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					type: "group",
					group: { classes: { child0: "w-full md:w-6/12 ml:pr-28", child1: "w-full md:w-6/12" } },
					content: {
						items: [
							{
								type: "image",
								items: [
									{ name: "MC authority", url: onboardingMcAuthorityLetter },
									{ name: "I.R.S form W9", url: onboardingIrsW9 },
									{ name: "Insurance", url: onboardingInsurance },
									{ name: "Pay (Factoring)", url: onboardingFactoryCompany },
									{ name: "Pay (Void Check)", url: onboardingVoidCheck },
								],
							},
							{
								type: "group",
								group: { classes: { direction: "flex-col" } },
								content: {
									items: [
										{
											type: "message",
											label: "Remember, the file to be uploaded must contain the following documents",
										},
										{
											type: "message",
											message: { classes: { root: " -mt-14 mb-20 " } },
											builder: () => (
												<>
													- MC authority acceptance page <br />
													- Completed I.R.S form W9 <br />
													- Insurance Certificate <br />
													- Factoring Assignment Letter or Void Check <br />
												</>
											),
										},
										{
											key: "packetOnboardingDocuments",
											type: "upload",
											label: "Onboarding Documents (Single PDF)",
											upload: {
												url: "api/profile/carriers/:carrierId/files",
												documentType: "carrier",
												fileName: "packetOnboardingDocuments",
												type: "packetOnboardingDocuments",
												variant: "large",
											},
										},
										{ type: "action", action: "SUBMIT" },
									],
								},
							},
						],
					},
				},
			],
		},
	},
};

export default carrierOnboardingAllDocuments;
