import { incrementDataRevision } from "app/store/tools/revisionSlice";

const payrollEditForm = {
	urlGET: "/billing/v3/payroll/:payrollId",
	urlPUT: "/billing/v3/payroll/:payrollId",
	listenEvent: "tripsRevision",
	triggerEvent: "tripsRevision",
	idKey: "_id",
	setSize: "max-w-m",
	header: {
		edit: { label: "Edit Payroll" },
	},
	content: {
		edit: {
			items: [
				{
					key: "period_date",
					type: "dateRange",
					label: "Period Range",
					shortcutPriority: "long",
				},
				{ type: "action", action: "SUBMIT" },
			],
			onFinished: (_data, dispatch) => {
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
			},
		},
	},
};

export default payrollEditForm;
