import { formatDate } from "app/main/utils/dateUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { getHistoryId, getCorrectView } from "../utils/historyUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Services
import { getHistory } from "app/services/historyServices";

const _buildDesctiption = (item) => {
	if (item?.description) {
		return item?.description;
	}
	return null;
};

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "relative",
		right: 0,
		"padding-right": "170px",
		"padding-left": "170px",
	},
}));

function ViewHistory(props) {
	const { viewId, isViewCustom, dataIds, fromSection } = props;
	const dispatch = useDispatch();
	const [historyData, setHistory] = useState(null);
	const [loading, setLoading] = useState(true);
	const [checkView, setCheckView] = useState(null);
	let arrayCheck = checkView?.filter((item) => item?.value);
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);

	const handleCheckHistory = (position) => {
		const updatedCheckedState = checkView.map((item, index) => {
			let value = index === position ? !item?.value : item?.value;
			return { value, index };
		});
		setCheckView(updatedCheckedState);
	};
	const classes = useStyles();

	useEffect(() => {
		(async () => {
			let { id, type, msg } = await getHistoryId(dataIds, viewId);
			if (id) {
				getHistory(id, type).then(
					(history) => {
						setCheckView(new Array(history?.length).fill({ value: false, index: 0 }));
						setHistory(history);
						setLoading(false);
					},
					(err) => {
						setError(err.message ?? err.errors?.[0]?.message ?? "Opps... Something went wrong...");
						setLoading(false);
						console.log(err, "Err");
					}
				);
			} else if (msg) {
				setMessage(msg);
				setLoading(false);
			} else {
				setError("Id not found");
				setLoading(false);
			}
		})();
		//eslint-disable-next-line
	}, [viewId]);

	const handleCompare = () => {
		if (fromSection) dispatch(incrementDataRevision({ event: "menu" }));
		const arraySelected = arrayCheck?.map((value) => {
			historyData[value.index].data.historyCreationDate = historyData?.[value.index]?.createdAt;
			historyData[value.index].data.historyCreationName = historyData?.[value.index]?.name;
			historyData[value.index].data.index = value.index;
			historyData[value.index].data.description = historyData?.[value.index]?.description;
			return historyData[value.index].data;
		});
		let allHistoryData = historyData?.map((item, index) => {
			item.data.historyCreationName = item?.name;
			item.data.historyCreationDate = item?.createdAt;
			item.data.index = index;
			return item.data;
		});
		setTimeout(
			() => {
				dispatch(
					openLoadedFormDialog({
						viewId: "HISTORY_VIEW",
						dataIds: { dataIds: dataIds, historyData: arraySelected, view: viewId, allHistoryData, historyView: true },
						mode: "VIEW",
					})
				);
			},
			fromSection ? 100 : 0
		);
	};

	if (loading) {
		return (
			<div className={clsx(classes.progress, "flex w-full")}>
				<CircularProgress size={30} />
			</div>
		);
	}

	if (error || message) {
		return (
			<div className="flex flex-1 flex-col h-full items-center justify-center">
				<Typography className="w-full text-center" color={error ? "error" : "inherit"}>
					{error ?? message}
				</Typography>
			</div>
		);
	}

	const viewHistory = () => {
		if (!historyData) return;
		let views = [];
		if (!historyData?.length) {
			return (
				<div key={"key"} className="px-4 flex w-full">
					<Typography className="font-medium ml-8 text-orange-800 text-14">{"No History"}</Typography>
				</div>
			);
		}

		historyData?.forEach((item, index) => {
			views.push(
				<div key={"key" + index} className="flex w-full">
					<div>
						<Checkbox
							checked={checkView[index]?.value}
							onChange={(e) => handleCheckHistory(index, e)}
							disabled={!checkView[index]?.value && arrayCheck?.length >= 2}
						/>
					</div>
					<div className="flex mr-40 my-6">
						<div className="flex flex-col">
							<Link
								className={
									"cursor-pointer tracking-wide whitespace font-medium ml-8 text-orange-800 text-14 break-words"
								}
								underline="always"
								onClick={(event) => {
									const data = {
										...item.data,
										editor: item.name,
										createdAt: item.createdAt,
										description: item.description,
									};
									if (fromSection) dispatch(incrementDataRevision({ event: "menu" }));
									setTimeout(
										() => {
											dispatch(
												openLoadedFormDialog({
													viewId: isViewCustom ? getCorrectView(viewId) : null,
													formId: viewId,
													dataIds: { historyData: data, historyView: true, mode: "VIEW" },
													mode: "VIEW",
												})
											);
										},
										fromSection ? 100 : 0
									);
								}}
							>
								{item?.name + " - " + formatDate(item?.createdAt)}
							</Link>
							<Typography className="ml-8 text-grey-600 text-13 -mt-2 whitespace-break-spaces max-w-360">
								{_buildDesctiption(item)}
							</Typography>
							<ul className="list-disc ml-40">
								{item?.events?.map((event, index) => {
									return (
										<li>
											<Typography key={"paragraph" + index} className="text-13">
												{event.editor + " - " + formatDate(event?.createdAt)}
											</Typography>
											<Typography className="text-13 text-grey-600">{event.description}</Typography>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			);
		});

		return views;
	};

	return (
		<div class="flex flex-col">
			<div style={{ maxHeight: "550px", overflow: "auto" }}>{viewHistory()}</div>
			<div class="flex justify-center">
				<Button
					variant="contained"
					className="w-full mx-auto mt-10 bg-blue-900 text-white"
					value="legacy"
					onClick={handleCompare}
					disabled={arrayCheck?.length < 2}
				>
					Compare history
				</Button>
			</div>
		</div>
	);
}
export default ViewHistory;
