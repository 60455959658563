import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";

const LocationMarker = ({ moveIndex, isEmpty, isSelected = true, classes, connector = true }) => {
	return (
		<TimelineSeparator>
			<TimelineDot
				color={moveIndex === 0 ? "primary" : "secondary"}
				className={`py-5 px-10 ml-5 mr-5 ${moveIndex && isEmpty ? "bg-red-700" : !isSelected ? "bg-grey-700" : ""}`}
			>
				{moveIndex + 1}
			</TimelineDot>
			{connector && <TimelineConnector className={isEmpty ? classes.emptyMove : ""} />}
		</TimelineSeparator>
	);
};

export default LocationMarker;
