import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const createForm = ({ type, carrierId, handleAddAch, sourceAccountSelected }) => {
	const isCounterPartyPayment = type === "counterparty";
	let actions = [
		{
			type: "action",
			action: "CUSTOM",
			label: "Transfer Funds",
		},
	];
	return {
		bottom: {
			classes: { root: "pt-4 md:pt-12" },
			items: actions,
		},
		items: [
			{
				type: "group",
				content: {
					items: [
						{
							key: "source_account",
							type: "autocomplete",
							label: "Pay From",
							required: true,
							field: {
								classes: {},
								variant: undefined,
							},
							autocomplete: {
								provider: "smarthop",
								url: "api/wallet/carriers/:carrierId/all-accounts",
								preloadDefault: true,
								preloadImmedately: true,
								required: true,
								actionOptions: [
									{
										value: "ADD_ACH_ACCOUNT",
										label: "Add ACH Account",
										icon: "add",
										onClick: () => handleAddAch(carrierId),
									},
								],
								params: {
									public_accounts_only: true,
									options_bank_account_only: true,
								},
							},
							description: "Source account.",
						},
						...(!isCounterPartyPayment
							? [
									{
										key: "destination_account",
										type: "autocomplete",
										label: "Pay To",
										required: true,
										autocomplete: {
											provider: "smarthop",
											url: "api/wallet/carriers/:carrierId/all-accounts",
											params: { options_bank_account_only: true, public_accounts_only: true },
											preloadDefault: true,
											listenEvent: "paymentRevision",
											preloadImmedately: true,
											actionOptions: [
												{
													value: "ADD_ACH_ACCOUNT",
													label: "Add ACH Account",
													icon: "add",
													onClick: () => handleAddAch(carrierId),
												},
											],
										},
										rules: {
											params: {
												valueRef: "source_account",
												paramKey: "filterAccountId",
											},
										},
										description: "Destination account.",
										disabled: !sourceAccountSelected,
									},
							  ]
							: []),
						...(isCounterPartyPayment
							? [
									{
										key: "counterparty_account",
										type: "autocomplete",
										label: "Counterparty Account",
										required: true,
										field: {
											classes: {},
											variant: undefined,
										},
										autocomplete: {
											provider: "smarthop",
											url: "api/wallet/carriers/:carrierId/counterparties-accounts",
											preloadDefault: true,
											preloadImmedately: true,
										},
									},
							  ]
							: []),
					],
				},
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "amount",
							type: "currency",
							label: "Amount",
							translate: "",
							required: true,
							min: 1000, // Value in cents
							errorMessage: { min: "Transfer amount must be at least" },
							field: { placeholder: "$0.00", manageCents: true },
							description: "Transaction amount (in dollars).",
						},
						...(!isCounterPartyPayment
							? [
									{
										key: "transfer_date",
										picker: {
											notClearable: true,
											type: "date",
											classes: {},
										},
										type: "picker",
										defaultValue: "NOW",
										label: "Transfer Date",
										translate: "",
										required: true,
										description: "Transfer date",
										disabled: true,
									},
							  ]
							: []),
					],
				},
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "addenda",
							type: "text",
							label: "Add a memo",
							multiline: { rows: 2 },
							builder: (item) => item?.accounts[0].data.attributes.status,
							field: { maxLength: 80 },
							description:
								"Optional, additional payment description (maximum of 80 characters), not all institutions present that.",
						},
					],
				},
			},
			{
				type: "message",
				builder: () => (
					<div className="flex flex-row justify-center space-x-10">
						<ErrorOutlineIcon />
						<span className="font-bold">ACH transfers can take 3-5 business days to complete</span>
					</div>
				),
			},
		],
	};
};

export default createForm;
