import { formatDate } from "app/main/utils/dateUtils";

const filterHard = {
	urlGET: "api/search/requests/:id/filters/type/:type",
	header: {
		view: { label: "Filters Hard" },
	},
	content: {
		view: {
			items: [ 
        {
					type: "group",
					content: {
						items: [
							{
                key: "location_origin",
                type: "text",
                label: "Origin"
              },
              {
                key: "origin_dh",
                type: "text",
                label: "DH-O"
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                key: "location_destination",
                type: "text",
                label: "Destination"
              },
              {
                key: "destination_dh",
                type: "text",
                label: "DH-D"
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                key: "carrierid",
                type: "text",
                label: "Carrier id"
              },
              {
                key: "truckid",
                type: "text",
                label: "Truck id"
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                builder: (item) => item?.availStart? formatDate(item.availStart) : item?.availStart,
                type: "text",
                label: "Start Date",
              },
              {
                builder: (item) => item?.availStart? formatDate(item.availEnd) : item?.availEnd,
                type: "text",
                label: "End Date",
              },
						],
					},
        },
        {
          key: "equipment",
          type: "text",
          label: "Equipment"
        }, 
			],
		},
	},
};

export default filterHard;
