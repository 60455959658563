import { useRef, useEffect } from "react";

export const useHorizontalScroll = () => {
	const elRef = useRef();
	useEffect(() => {
		const el = elRef.current;

		if (el) {
			el.style.overflow = "scroll";
			el.style.whiteSpace = "no-wrap";
			const onWheel = (e) => {
				if (e.shiftKey) {
					e.preventDefault();
					el.scrollLeft += e.deltaX ? e.deltaX : e.deltaY;
				}
			};
			el.addEventListener("wheel", onWheel);
			return () => el.removeEventListener("wheel", onWheel);
		}
	}, [elRef]);
	return elRef;
};
