import LoginForm from "./LoginForm";
import LandingPageContainer from "../LandingPageContainer";

function Login() {
	return (
		<LandingPageContainer viewType="login">
			<LoginForm />
		</LandingPageContainer>
	);
}

export default Login;
