const newPasswordForm = {
	items: [
		{
			key: "message",
			type: "message",
			label: `To complete login please choose new password`,
		},
		{
			key: "password",
			type: "password",
			label: "Password",
			translate: "PASSWORD",
			required: true,
		},
		{
			key: "passwordConfirm",
			type: "passwordConfirm",
			label: "Confirm Password",
			translate: "CONFIRM_PASSWORD",
			required: true,
		},
		{ type: "action", label: "Update Password", action: "SUBMIT" },
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
				classes: { root: "mt-16" },
			},
		},
	],
};

export default newPasswordForm;
