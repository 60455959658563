import { makeStyles } from "@material-ui/core";

import FormSection from "app/main/tools/FormSection";
import PageSection from "../../common/PageSection";

const useStyles = makeStyles(() => ({
	maxHeight: {
		height: "60vh",
	},
}));

const CarrierExternalIdsPage = (props) => {
	const classes = useStyles();

	const carrierId = props.params.carrierId;

	return (
		<div className={"flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20 " + classes.maxHeight}>
			<PageSection variant="full" title="List External Sources">
				<div className="grid grid-cols-1 md:gap-x-20 md:grid-cols-2 w-full">
					<FormSection
						title={"Salesforce"}
						formId={"EXTERNAL_ID_SALESFORCE"}
						dataIds={{ carrierId }}
						actions={[
							{
								action: "edit",
								mode: "EDIT",
								label: "Edit",
								auth: ["administrator"],
							},
						]}
					/>
				</div>
			</PageSection>
		</div>
	);
};

export default CarrierExternalIdsPage;
