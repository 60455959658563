const carrierCostStructureGoalsForm = {
	urlPOST: "api/cost-structures/:costStructureId/goals",
	urlGET: "api/cost-structures/goals/:goalId",
	urlPUT: "api/cost-structures/goals/:goalId",
	idKey: "_id",
	triggerEvent: ["costStructureRevision", "goalsCarrierRevision"],
	listenEvent: "costStructureRevision",
	header: {
		create: { label: "Add Cost Structure Goal" },
		edit: { label: "Edit Cost Structure Goal" },
		view: { label: "View Cost Structure Goal" },
	},
	messages: {
		create: { success: "Goals created." },
		edit: { success: "Goals updated." },
	},
	content: {
		view: { sameAs: "edit" },
		create: { sameAs: "edit" },
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "miles_month",
								type: "number",
								label: "Miles Per Month",
								translate: "MILES_PER_MONTH",
								required: true,
							},
							{
								key: "revenue_month",
								type: "currency",
								label: "Revenue Per Month",
								translate: "REVENUE_PER_MONTH",
								required: true,
							},
							{
								key: "min_rpm_month",
								type: "currency",
								label: "Min RPM",
								translate: "MIN_RPM",
								required: true,
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierCostStructureGoalsForm;
