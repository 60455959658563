import { authRoles } from "app/main/consts";
import moment from "moment";

import {
	LOCATION_ORIGIN,
	LOCATION_DESTINATION,
	CARRIER_ALL,
	TRUCK_ALL,
	CARRIER_ASSIGNED,
	TRUCK_ASSIGNED,
	CARRIER_TRUCKS,
	DRIVER_TRUCKS,
} from "../../search/config/searchFields";

const { actionOptions, ...autocomplete } = CARRIER_TRUCKS().autocomplete;
const CARRIER_TRUCKS_FILTERED = { ...CARRIER_TRUCKS(), autocomplete };

const createPanel = (classes, options) => {
	const entryPoint = options?.entryPoint;
	const role = options?.role;
	const topLevel = entryPoint === "topLevel";

	const isAdmin = authRoles.internalPowerUsers.includes(role);
	const isDispatch = authRoles.dispatcher.includes(role);
	const isCarrier = authRoles.externalUsers.includes(role);

	return {
		form: {
			noErrorMessage: true,
			classes: {},
		},
		items: [
			{
				type: "group",
				group: {
					classes: {
						root: "flex-col md:flex-row",
						direction: undefined,
					},
				},
				content: {
					items: [
						...(topLevel
							? isAdmin || isDispatch
								? [isAdmin ? CARRIER_ALL : CARRIER_ASSIGNED, isAdmin ? TRUCK_ALL : TRUCK_ASSIGNED]
								: [isCarrier ? CARRIER_TRUCKS_FILTERED : DRIVER_TRUCKS]
							: []),
						LOCATION_ORIGIN,
						{
							key: "start_date",
							type: "picker",
							label: "Start date",
							translate: "",
							field: {
								noErrorMessage: true,
								classes: {},
								variant: undefined,
							},
							picker: {
								disablePast: true,
								type: "date",
								classes: {},
								maxDate: moment().add(3, "d"),
							},
						},
						{
							key: "max_end_date",
							type: "picker",
							label: "Max. Deliver date",
							translate: "",
							field: {
								noErrorMessage: true,
								classes: {},
								variant: undefined,
							},
							picker: {
								disablePast: true,
								type: "date",
								classes: {},
								maxDate: moment().add(13, "d"),
							},
						},
						{ ...LOCATION_DESTINATION, disabled: !!options?.cycle },
						{
							type: "menu",
							icon: "tune",
							button: { color: "primary", classes: {} },
							content: {
								items: [
									{
										key: "max_paths",
										type: "slider",
										label: "Max Paths",
										translate: "",
										defaultValue: 50,
										slider: {
											type: "input",
											prefix: "",
											postfix: "",
											step: 5,
											min: 5,
											max: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},

									{
										key: "path_length",
										type: "slider",
										label: "Max Legs",
										translate: "",
										defaultValue: 3,
										slider: {
											type: "input",
											prefix: "",
											postfix: " legs",
											step: 1,
											min: 1,
											max: 5,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "min_volume",
										type: "slider",
										label: "Min Volume",
										translate: "",
										defaultValue: 3,
										slider: {
											type: "input",
											prefix: "",
											postfix: " loads/day",
											step: 1,
											min: 3,
											max: 50,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "end_market_profit",
										type: "slider",
										label: "Ending Market Profit",
										translate: "",
										defaultValue: 0,
										slider: {
											type: "input",
											step: 100,
											prefix: "$",
											postfix: "",
											min: -2000,
											max: 2000,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "min_profit",
										type: "number",
										label: "Target Profit",
										translate: "",
										defaultValue: -10000,
									},
									{
										key: "min_profit_per_leg",
										type: "slider",
										label: "Min Profit per Leg",
										translate: "",
										defaultValue: -500,
										slider: {
											type: "input",
											step: 50,
											prefix: "$ ",
											postfix: "",
											min: -2000,
											max: 200,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "miles_range",
										type: "slider",
										label: "Miles Range",
										translate: "",
										defaultValueMin: 600,
										defaultValueMax: 6000,
										slider: {
											type: "range",
											postfix: " mi",
											min: 600,
											max: 6000,
											step: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "sort_by",
										type: "select",
										label: "Sort by",
										defaultValue: "balanced_profit",
										field: {
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
										options: [
											{
												value: "balanced_profit",
												label: "Balanced Profit",
												selected: true,
												translate: "balanced_profit",
											},
											{
												value: "total_profit",
												label: "Total profit",
												translate: "total_profit",
											},
											{
												value: "total_risk",
												label: "Total Risk",
												translate: "total_risk",
											},
											{
												value: "total_payment",
												label: "Total Payment",
												translate: "total_payment",
											},
											{
												value: "total_rpm",
												label: "Total RPM",
												translate: "total_rpm",
											},
											{
												value: "total_miles",
												label: "Total Miles",
												translate: "total_miles",
											},
										],
									},
								],
							},
						},
						{
							type: "action",
							action: "SUBMIT",
							label: "Submit",
							icon: undefined,
							button: {
								alwaysEnabled: true,
								classes: {
									root: classes.buttonNormal,
									disabled: classes.buttonDisabled,
								},
							},
						},
					],
				},
			},
			{
				type: "action",
				button: {
					classes: {
						root: "hidden",
					},
				},
				confirmation: {
					enable: !!options?.cycle,
					message:
						"You have selected the cycle option, which means your destination is the same as your origin. Do you want to continue?",
				},
			},
		],
	};
};

export default createPanel;
