import { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatDate } from "app/main/utils/dateUtils";
import moment from "moment";

// Services
import { getCarrierUpcomingPlatformAddonPlan } from "app/services/platformPlanServices";

const ProrationMessage = ({ upcomingInvoice } = {}) => {
	const proratedPeriodStart = moment(upcomingInvoice?.subscription_proration_date * 1000);
	const proratedPeriodEnd = moment(upcomingInvoice?.period_end * 1000);
	return (
		<>
			<Typography className="mb-10">
				Customer will be charged immediately for the current subscription period. Recurring charges will sync with
				active subscription.{" "}
			</Typography>
			<Typography>{`Total Amount: ${formatCurrency(upcomingInvoice?.planProratedAmount / 100)}`}</Typography>
			<Typography>{`Prorated Period: ${formatDate(proratedPeriodStart)} to ${formatDate(
				proratedPeriodEnd
			)}`}</Typography>
		</>
	);
};

const AddPlanAddonProrationComponent = (props) => {
	const { carrierId, planId } = props;

	const [loading, setLoading] = useState(false);
	const [upcomingInvoice, setUpcomingInvoice] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		if (carrierId && planId) {
			setLoading(true);
			setError(null);
			getCarrierUpcomingPlatformAddonPlan(carrierId, planId)
				.then((proration) => {
					setUpcomingInvoice(proration);
					setLoading(false);
				})
				.catch((err) => {
					setError(err);
					setLoading(false);
				});
		} else {
			setUpcomingInvoice(null);
			setLoading(false);
		}
	}, [carrierId, planId]);

	if (!carrierId || !planId) {
		return <Typography> </Typography>;
	}

	if (loading) {
		return (
			<div className="absolute flex flex-col items-center justify-center w-full h-full top-0 left-0">
				<CircularProgress color="inherit" size="1.8em" />
			</div>
		);
	}

	if (error) {
		return <Typography className="mb-10">Non-Stripe plans must be charged manually.</Typography>;
	}

	return <ProrationMessage upcomingInvoice={upcomingInvoice} />;
};

export default AddPlanAddonProrationComponent;
