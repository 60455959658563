import { fade } from "@material-ui/core/styles/colorManipulator";

const makeMessageRowStyles = (theme) => ({
	messageRow: {
		"&.contact": {
			"& .bubble": {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.getContrastText(theme.palette.background.paper),
				borderTopLeftRadius: 5,
				borderBottomLeftRadius: 5,
				borderTopRightRadius: 20,
				borderBottomRightRadius: 20,
				"& .time": {
					marginLeft: 12,
				},
			},
			"&.first-of-group": {
				"& .bubble": {
					borderTopLeftRadius: 20,
				},
			},
			"&.last-of-group": {
				"& .bubble": {
					borderBottomLeftRadius: 20,
				},
			},
		},
		"&.me": {
			paddingLeft: 20,

			"& .avatar": {
				order: 2,
				margin: "0 0 0 16px",
			},
			"& .bubble": {
				marginLeft: "auto",
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				borderTopLeftRadius: 20,
				borderBottomLeftRadius: 20,
				borderTopRightRadius: 5,
				borderBottomRightRadius: 5,
				"& .time": {
					justifyContent: "flex-end",
					right: 0,
					marginRight: 12,
				},
			},
			"&.first-of-group": {
				"& .bubble": {
					borderTopRightRadius: 20,
				},
			},

			"&.last-of-group": {
				"& .bubble": {
					borderBottomRightRadius: 20,
				},
			},
		},
		"&.contact + .me, &.me + .contact": {
			paddingTop: 20,
			marginTop: 20,
		},
		"&.first-of-group": {
			"& .bubble": {
				borderTopLeftRadius: 20,
				paddingTop: 13,
			},
		},
		"&.last-of-group": {
			"& .bubble": {
				borderBottomLeftRadius: 20,
				paddingBottom: 13,
				"& .time": {
					display: "flex",
				},
			},
		},
	},
});

const drawerWidth = 400;
const headerHeight = 200;
const makeChatAppStyles = (theme) => ({
	"@global": {
		"#fuse-main": {
			height: "100vh",
		},
	},
	root: {
		display: "flex",
		flexDirection: "row",
		minHeight: "100%",
		position: "relative",
		flex: "1 1 auto",
		height: "auto",
		backgroundColor: theme.palette.background.default,
	},
	topBg: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: "cover",
		pointerEvents: "none",
	},
	contentCardWrapper: {
		position: "relative",
		padding: 24,
		maxWidth: 1400,
		display: "flex",
		flexDirection: "column",
		flex: "1 0 auto",
		width: "100%",
		minWidth: "0",
		maxHeight: "600px",
		margin: "0 auto",
		[theme.breakpoints.down("sm")]: {
			padding: 16,
		},
		[theme.breakpoints.down("xs")]: {
			padding: 12,
		},
	},
	contentCard: {
		display: "flex",
		position: "relative",
		flex: "1 1 100%",
		flexDirection: "row",
		backgroundImage: 'url("/assets/images/patterns/rain-grey.png")',
		backgroundColor: theme.palette.background.paper,
		height: "70vh",
		overflow: "hidden",
	},
	messageList: {
		display: "flex",
		position: "relative",
		flex: "1 1 100%",
		flexDirection: "row",
		backgroundColor: theme.palette.background.paper,
		height: "70vh",
		overflow: "hidden",
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: "100%",
		overflow: "hidden",
		height: "100%",
		[theme.breakpoints.up("md")]: {
			position: "relative",
		},
	},
	contentWrapper: {
		display: "flex",
		flexDirection: "column",
		flex: "1 1 100%",
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`,
	},
	content: {
		display: "flex",
		flex: "1 1 100%",
		minHeight: 0,
	},
	contentHeight: {
		[theme.breakpoints.down("md")]: {
			height: "65vh",
		},
		height: "80vh",
	},
});

export { makeMessageRowStyles, makeChatAppStyles };
