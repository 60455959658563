import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GoogleMap from "google-map-react";
import { Icon } from "@material-ui/core";
import useHeatMap from "app/main/market-conditions/hooks/useHeatMap";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

const StyledBadge = withStyles((theme) => ({
	badge: {
		top: -5,
		padding: "4px 4px",
	},
}))(Badge);

const useStyles = makeStyles({
	markerTruck: {
		position: "absolute",
		left: "50%",
		transform: "translate(-50%, 0)",
		bottom: 0,
		fontSize: "25px",
	},
	mapRadius: {
		borderRadius: "20px",
	},
});

const StrategyMap = ({ listPolyLine, locations, truckLocation, equipment = "van" }) => {
	const classes = useStyles();
	const snackbar = useSnackbar();

	const [mapObj, setMapObj] = useState({
		map: null,
		maps: null,
	});

	const [listMoves, setListMoves] = useState([]);

	let props = {
		data: { equipment: equipment },
		inverse: false,
		toplanes: true,
		highlighted: locations?.map((location) => location?.cluster),
		activeHeatMap: true,
	};

	useHeatMap({ props, mapObj, snackbar });

	useEffect(() => {
		if (!mapObj.map || !mapObj.maps) return;
		renderPolyline(mapObj.map, mapObj.maps);
		getMapBounds(mapObj.map, mapObj.maps);
		//setHighlighted(locations?.map((location) => location?.cluster));

		// eslint-disable-next-line
	}, [mapObj, listPolyLine, locations]);

	function renderPolyline(map, maps) {
		if (!listPolyLine?.length) {
			return;
		}

		removeLines();
		let listMoves = [];
		for (let polyLine of listPolyLine) {
			listMoves.push(updatedPolygon(map, maps, polyLine.line, polyLine.color, polyLine.dashedLine));
		}
		setListMoves(listMoves);
	}

	function updatedPolygon(map, maps, listLatLong, color, dashedLine = false) {
		const lineSymbol = [
			{
				icon: {
					path: "M 0,-1 0,1",
					strokeOpacity: 1,
					scale: 4,
				},
				offset: "0",
				repeat: "20px",
			},
		];

		// Create the polyline and add the symbols via the 'icons' property.
		const polylineDashed = new maps.Polyline({
			path: listLatLong,
			strokeOpacity: dashedLine ? 0 : 1,
			strokeColor: color,
			strokeWeight: 3,
			icons: dashedLine ? lineSymbol : [],
		});
		polylineDashed.setMap(map);

		return polylineDashed;
	}

	function removeLines() {
		for (let polyLine of listMoves) {
			if (polyLine) polyLine.setMap(null);
		}
	}

	function Marker({ text, index, empty }) {
		let color = index === 0 ? "primary" : empty ? "error" : "secondary";
		let badgeContent = index + 1;
		return (
			<Tooltip title={text?.toUpperCase()} placement="top">
				<StyledBadge badgeContent={badgeContent} color={color}></StyledBadge>
			</Tooltip>
		);
	}

	function MarkerTruckPosition({ text }) {
		return (
			<Tooltip title={text?.toUpperCase()} placement="top">
				<Icon className={classes.markerTruck + " secondary"}>places</Icon>
			</Tooltip>
		);
	}

	const getMapBounds = (map, maps) => {
		const bounds = new maps.LatLngBounds();

		// Truck Position
		if (truckLocation?.lat) {
			bounds.extend(new maps.LatLng(truckLocation.lat, truckLocation.long));
		}

		for (let coordinates of locations) {
			bounds.extend(new maps.LatLng(coordinates.lat, coordinates.lng));
		}
		map.fitBounds(bounds);
	};

	let mapView;
	try {
		mapView = (
			<GoogleMap
				bootstrapURLKeys={{
					key: process.env.REACT_APP_MAP_KEY,
				}}
				defaultZoom={10} // Disable animation to avoid issues with performance
				defaultCenter={[37.09024, -95.712891]}
				onGoogleApiLoaded={({ map, maps }) => {
					setMapObj({ map, maps });
				}}
				yesIWantToUseGoogleMapApiInternals
			>
				{truckLocation?.lat && (
					<MarkerTruckPosition text={truckLocation.location} lat={truckLocation.lat} lng={truckLocation.long} />
				)}
				{locations?.slice(1).map((path, index) => (
					<Marker
						key={`${path.lat}-${path.lng}-${index}`}
						text={path.name}
						index={index}
						empty={path.empty}
						lat={path.lat}
						lng={path.lng}
					/>
				))}
			</GoogleMap>
		);
	} catch (err) {
		console.error(`[LoadStrategy] failed to load GoogleMap ${err}`);
	}

	return mapView ?? null;
};

export default StrategyMap;
