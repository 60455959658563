import { Icon, Link } from "@material-ui/core";
import { createTooltip } from "app/main/utils/tableUtils";
import * as fileService from "app/services/fileServices";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useDispatch } from "react-redux";
import FilesIconAnalize from "app/main/files/FilesIconAnalize.js";
import { isEnabled } from "app/services/featureStorageService";

function TripFilesView({ trip }) {
	const dispatch = useDispatch();
	const onclickFileLink = (e, link) => {
		e.preventDefault();
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: link,
				},
			})
		);
	};

	return (
		<>
			{trip?.rate_con_file__flag && (
				<div className="border-b-1 border-grey-300 text-12 font-medium break-normal items-center flex flex-row w-full justify-between h-36 pt-2">
					<Link onClick={(e) => onclickFileLink(e, fileService.buildLink(trip?.rate_con_file))}>
						<div>Rate Confirmation</div>
					</Link>
					{createTooltip(<Icon className="text-20 text-primary-400 -mb-4">request_quote</Icon>, "Rate Confirmation")}
				</div>
			)}
			{trip?.pod_files
				?.filter((f) => f.type === "pod") // POD === BOL
				.map((f, i) => {
					return (
						<div
							key={i + "_pod"}
							className="border-b-1 border-grey-300 text-12 font-medium break-normal items-center flex flex-row w-full justify-between h-36 pt-2"
						>
							<Link key={f.file_id[0]} onClick={(e) => onclickFileLink(e, fileService.buildLink(f.file_id[0]))}>
								<div>{"Bill Of Lading"}</div>
							</Link>

							<div className="flex flex-row">
								<div className="flex mr-5">
									{isEnabled("ANALIZE_FILES_UPLOADED") && (
										<FilesIconAnalize data={f?.analize} style={{ icon: "text-20 mt-0 mr-5" }} />
									)}
								</div>
								{createTooltip(
									<Icon className="text-20 text-primary-400 font -mb-4">receipt_long</Icon>,
									"Bill Of Lading"
								)}
							</div>
						</div>
					);
				})}
			{trip?.pod_files
				?.filter((f) => f.type !== "pod")
				.map((f, i) => {
					return (
						<div
							key={i + "_not_pod"}
							className="border-b-1 border-grey-300 text-12 font-medium break-normal items-center flex flex-row w-full justify-between h-36 pt-2"
						>
							<Link key={f.file_id[0]} onClick={(e) => onclickFileLink(e, fileService.buildLink(f.file_id[0]))}>
								{f.description ? f.description : `Custom File ${i === 0 ? "" : i + 1}`}
							</Link>

							<div className="flex flex-row">
								<div className="flex mr-5">
									{isEnabled("ANALIZE_FILES_UPLOADED") && (
										<FilesIconAnalize data={f?.analize} style={{ icon: "text-20 mt-0 mr-5" }} />
									)}
								</div>
								{createTooltip(
									<Icon className="text-20 text-primary-400 font -mb-4">insert_drive_file</Icon>,
									"Other File"
								)}
							</div>
						</div>
					);
				})}
		</>
	);
}

export default TripFilesView;
