import moment from "moment";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { NARROW_FIELDS } from "@fuse/default-settings";
import MobileDetect from "mobile-detect";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";

import { DatePicker } from "@material-ui/pickers";
import SmarthopCustomDateTimePicker from "./SmarthopCustomDateTimePicker";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const useStyles = makeStyles((theme) => {
	return {
		disabled: {
			color: theme.palette.grey[900],
		},
	};
});

function SmarthopPicker(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);
	const defaultValue = props.control?.defaultValuesRef?.current?.[props.name];
	const fromSmarthopForm = !!props.fieldObject;
	const field = fromSmarthopForm ? props.fieldObject : {};
	const val = fromSmarthopForm ? field.value : props.value;
	const onChange = fromSmarthopForm ? field.onChange : props.onChange;
	const uuid = useMemo(() => Math.random(), []);

	const handleMessageFromNative = useCallback(
		(msg) => {
			try {
				const dataParsed = JSON.parse(msg.data);
				switch (dataParsed.type) {
					case "DATE_SELECTED":
						if (uuid === dataParsed?.data?.fieldKey) {
							onChange(moment(dataParsed.data.value));
						}
						return;
					default:
						break;
				}
			} catch (e) {}
		},
		[onChange, uuid]
	);

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);

		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	}, [handleMessageFromNative]);

	const label =
		props.label +
		(props.required ? " *" : "") +
		(props.orignialValue || props.orignialValue === ""
			? new Date(props.orignialValue) + "" !== (new Date(val) ?? "") + ""
				? " (Edited)"
				: ""
			: "");
	let config = {
		clearable: "true",
		inputRef: field.ref,
		name: field.name,
		value: val === "" || !val ? null : val,
		onChange: (value) => {
			if (!value) {
				onChange(value);
				if (props.onChangeCommitted) props.onChangeCommitted(props.name, value);
			} else {
				let date = new Date(value);
				if (props.picker?.type === "date") {
					date.setHours(0, 0, 1, 0);
				}
				onChange(date.toISOString());
				if (props.onChangeCommitted) props.onChangeCommitted(props.name, date.toISOString());
			}
		},
		onClose: () => {
			if (props.onBlur) props.onBlur(props.name);
			setOpen(false);
		},
		onBlur: field.onBlur,
		disableToolbar: !!props.picker?.disableToolbar,
		variant: props.picker?.variant === "row" ? "standard" : props.picker?.variant ?? "inline",
		classes: props.picker?.classes ?? {},
		inputVariant: props.field?.variant === "row" ? "standard" : props.field?.variant ?? "outlined",
		format: props.picker?.format ?? undefined,
		disablePast: props.picker?.disablePast ?? undefined,
		disableFuture: props.picker?.disableFuture ?? undefined,
		minDate: props.picker?.minDate ? props.picker?.minDate : props.picker?.initialValAsMin ? defaultValue : undefined,
		maxDate: props.picker?.maxDate ?? undefined,
		className: "w-full pr-0",
		label: props.field?.variant === "row" ? "" : label,
		error: !props.disabled ? !!props?.errors?.[props.name]?.message : false,
		helperText: !props.disabled && !props?.field?.noErrorMessage ? props?.errors?.[props.name]?.message : "",
		disabled: props.disabled,
		FormHelperTextProps: { classes: { root: "mt-0 ml-1 mr-1 mb-1" } },
		InputProps: {
			classes: { ...(props.input?.classes ?? {}), disabled: classes.disabled },
		},
		minutesStep: props.picker?.minutesStep ?? 5,
		onMouseDown: (e) => {
			if (nativeMobile && !props.disabled) {
				e.preventDefault();
				e.stopPropagation();
				window?.ReactNativeWebView?.postMessage(
					JSON.stringify({
						type: props?.picker?.type === "date" ? "DATE_SELECT" : "DATE_TIME_SELECT",
						data: {
							date: { value: val === "" || !val ? null : val },
							fieldKey: uuid,
						},
					})
				);
			} else if (!props.disabled) {
				setOpen(true);
			}
		},
		onClick: (e) => {
			if (nativeMobile) {
				e.preventDefault();
				e.stopPropagation();
			} else if (!props.disabled) {
				setOpen(true);
			}
		},
		open: open,
	};

	const contenView = (
		<div
			className={
				" w-full px-4 " +
				(props.disabled ? "opacity-60 " : "") +
				(props.field?.variant !== "row" ? "" : " mb-2 ") +
				(props.field?.variant !== "row" && props?.field?.noErrorMessage ? " pt-4 pb-4 " : "")
			}
			style={{ position: "relative" }}
		>
			{props?.picker?.type === "date" ? (
				<DatePicker {...config} />
			) : props?.picker?.type === "datetime" ? (
				<SmarthopCustomDateTimePicker {...config} />
			) : (
				<Typography color="textSecondary" className="mb-10 mt-16 font-normal">
					{`Not supported picker type '${props?.picker?.type ?? "<null>"}`}
				</Typography>
			)}
			{props.field?.variant !== "row" && !props?.field?.noErrorMessage && (
				<div
					style={
						!props.disabled && !!props?.errors?.[props.name]?.message
							? {}
							: { height: NARROW_FIELDS ? "1.6rem" : "1.9rem" }
					}
				/>
			)}
			{!props.picker?.notClearable && !!val && (
				<div
					className="opacity-80"
					style={{
						position: "absolute",
						right: props.field?.variant === "row" ? 0 : 3,
						top: props.field?.variant === "row" ? -2 : 8,
					}}
				>
					<IconButton
						key="close"
						aria-label="Close"
						color="default"
						disabled={props.disabled}
						size={props.field?.variant === "row" ? "small" : undefined}
						onClick={(e) => {
							e.stopPropagation();
							onChange("");
							if (props.onChangeCommitted) props.onChangeCommitted(props.name, "");
						}}
					>
						<ClearIcon className={"w-16 h-16"} />
					</IconButton>
				</div>
			)}
		</div>
	);

	if (props.field?.variant === "row") {
		return (
			<div className={"flex w-full flex-row justify-between border-b-1 border-grey-300 mt-10"}>
				<Typography
					className={"flex flex-1 text-black text-12 ml:text-13 pt-2 " + (props.disabled ? " opacity-60 " : "")}
				>
					{label}
				</Typography>
				<div className={"flex flex-row w-1/4"}>{contenView}</div>
			</div>
		);
	} else {
		return contenView;
	}
}

export default SmarthopPicker;
