import { columns, groups, orderBy, filters } from "app/main/profile/carriers/fuel/SmartfuelUtils.js";

const driverSmartfuelTransactionsList = {
	// TODO | URL for admin view
	urlGET: `api/fuel/carriers/:carrierId/drivers/:driverId/transactions`,
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision", "fuelRevision"],
	segmentKey: "SMARTFUEL_TRANSACTIONS_DRIVER",
	content: {
		table: {
			variant: "skinny",
			orderBy,
		},
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		export: {
			fileName: "SmartFuel Transactions",
		},
		order: {
			defaut: {
				key: columns.TRANSACTION_DATE.key,
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [filters.TRANSACTION_DATE],
		},
		groups: {
			items: [groups.PRODUCT_DETAILS, groups.PAYROLL_DETAILS, groups.PRICE_DETAILS],
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			dialogBuilder: (item) => ({
				viewId: "FUEL_TRANSACTION_VIEW",
				mode: "VIEW",
				dataIds: {
					transactionId: item._id,
					carrierId: item.carrier,
				},
			}),
		},
		items: [
			columns.TRANSACTION_NUMBER,
			columns.TRANSACTION_DATE,
			columns.DRIVER,
			columns.LOCATION,
			columns.PRODUCT_TYPE,
			columns.GALLONS,
			columns.PPG,
			columns.PPG_DISCOUNTED,
			columns.ACTUAL_COST,
			columns.DISCOUNT_TOTAL,
			{ type: "separator", table: { width: 8, sticky: true } },
			columns.TOTAL_AMOUNT,
		],
	},
};

export default driverSmartfuelTransactionsList;
