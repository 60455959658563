import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// Dependencies
// import IdleTimer from "react-idle-timer";

// Common
import PageTabWrapper from "app/main/common/PageTabWrapper";
import PageWrapper from "app/main/common/PageWrapper";
import { loadingViewComponent } from "app/main/common/LoadingView";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import {
	SMARTHOP_WALLET_SECTION_LABEL,
	cleanSmarthpWalletTokenStorage,
	UNAUTHORIZED_WALLET_ACCESS,
} from "app/main/utils/financeUtils";
import { capitalizeFirstLetter } from "app/main/utils/tableUtils";

// Tools
import FormSection from "app/main/tools/FormSection";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Lists
import {
	SmarthopList,
	carrierSmartHopWalletTransactionsList,
	carrierSmartHopWalletStatementsList,
	carrierSmartHopWalletDebitCardsList,
} from "@smarthop/list";

// Forms
import {
	smartpayWalletAccountForm,
	smartpayPaymentsAccountForm,
} from "@smarthop/form/configs/smartHopWalletAccountForm";

// Views
import SmartHopWallet2FAVerificationView from "./SmartHopWallet2FAVerificationView";

// Services
import { getCarrierId, getRoleLabel } from "app/services/LoginService";
import { UnitFinanceService } from "app/services/unitFinanceServices";

const SmartHopWalletCustomerPage = (props) => {
	const dispatch = useDispatch();

	const params = useParams();
	const snackbar = useSnackbar();

	const isAdmin = getRoleLabel() === "administrator";
	const isCarrier = getRoleLabel() === "carrier";

	const [shWalletData, setShWalletData] = useState(null);
	const hasSmarthopWalletToken = useSelector(({ wallet }) => !!wallet.smarthopWallet?.token);
	const is2faEnabled = hasSmarthopWalletToken || isAdmin;

	const carrierId = props?.params?.carrierId ?? params.carrierId ?? (isCarrier ? getCarrierId() : undefined);

	let accountTypes = shWalletData?.data?.accounts?.map((account) => ({
		value: account.data.id,
		purpose: account.data.attributes.tags.purpose,
		label: capitalizeFirstLetter(account.data.attributes.tags.purpose),
	}));
	let customerId = shWalletData?.data?.customer?.data?.id;

	const handleCloseSmarthopWallet = async () => {
		const closeResult = await UnitFinanceService.closeSmarthopWallet({
			entityId: carrierId,
		});

		if (closeResult.ok) {
			showSnackbar(snackbar, `Smarthop Wallet closed`, "success", 5000);
			dispatch(incrementDataRevision({ event: "smarthopWalletRevision" }));
		} else {
			showSnackbar(snackbar, `Something went wrong.`, "error", 5000);
		}
	};

	const fetchData = useCallback(async () => {
		if (is2faEnabled) {
			try {
				const shWalletData = await UnitFinanceService.getWalletData({ entityId: carrierId, isAdmin });
				setShWalletData(shWalletData);
			} catch (err) {
				if (err?.errors?.[0].code === UNAUTHORIZED_WALLET_ACCESS) {
					cleanSmarthpWalletTokenStorage(dispatch);
					return;
				}
				showSnackbar(snackbar, `Something went wrong.`, "error", 5000);
			}
		}
	}, [carrierId, isAdmin, snackbar, is2faEnabled, dispatch]);

	useEffect(() => fetchData(), [fetchData]);

	//********* ACTIONS START ***************/
	const transferFundsExternalAccountAction = {
		action: "transfer-funds",
		label: "Transfer Funds",
		auth: ["carrier"],
		dialog: { viewId: "SMARTHOP_WALLET_TRANSFER_FUNDS_VIEW", dataIds: { carrierId } },
	};

	const closeSmarthopAccountAction = {
		action: {
			confirmation: {
				message: "Are you sure you want to close this wallet?",
				onAccept: () => handleCloseSmarthopWallet(),
			},
		},
		mode: "VIEW",
		label: "Close this Wallet",
		auth: ["administrator"],
	};

	const purchaseDebitCardAction = {
		action: "purchase-debit-card",
		label: "Purchase Debit Card",
		auth: ["carrier"],
		dialog: { viewId: "SMARTPAY_PURCHASE_DEBIT_CARD_VIEW", dataIds: { carrierId } },
	};

	const formConfig = [
		{
			exp: (data) => data?.status === "CUSTOMER",
			key: "wallet",
			title: "Checking",
			form: smartpayWalletAccountForm(isAdmin),
			titleActions: [
				transferFundsExternalAccountAction,
				closeSmarthopAccountAction,
				...(false ? [purchaseDebitCardAction] : []), // TODO: WALLET_FEATURE gk
			],
		},
		{
			exp: (data) => data?.status === "CUSTOMER",
			key: "payments",
			title: "Payments",
			form: smartpayPaymentsAccountForm(isAdmin),
		},
	];

	const renderForm = formConfig.filter((item) => item.exp(shWalletData));
	const createForm = () => {
		return renderForm.map((form) => (
			<FormSection
				key={form.key}
				title={form.title}
				formId={form.formId}
				form={form.form}
				actions={form.actions}
				dataIds={{ entityId: carrierId }}
				otp={true}
			/>
		));
	};

	const render2FAVerification = () => {
		const dataIds = { carrierId };
		return (
			<PageWrapper
				title="2-Factor Verification"
				variant="narrow"
				restrictions={["ONBOARDING", "ACTIVATION", "TIER_LIMITED"]}
			>
				<div className="flex flex-col flex-1 items-center justify-center p-24">
					<SmartHopWallet2FAVerificationView dataIds={dataIds} />
				</div>
			</PageWrapper>
		);
	};

	const renderAccountDetails = () => {
		return (
			<PageTabWrapper
				title={SMARTHOP_WALLET_SECTION_LABEL}
				restrictions={["ONBOARDING", "ACTIVATION", "TIER_LIMITED"]}
				variant="narrow"
				isView={props.isView}
				tabs={[
					{
						label: "Account Details",
						actions: renderForm ? renderForm[0]?.titleActions : [],
						component: (
							<div key="summary" className="grid grid-cols-1 gap-24">
								{renderForm && (
									<div key="summary" className="flex flex-col md:flex-row items-center w-full space-x-0 md:space-x-20">
										{createForm()}
									</div>
								)}
							</div>
						),
					},
					{
						label: "Transactions",
						component: (
							<SmarthopList
								key="transactions"
								mode="table"
								config={carrierSmartHopWalletTransactionsList({ accountTypes, isAdmin })}
								dataIds={{ entityId: customerId }}
							/>
						),
					},
					{
						label: "Statements",
						component: (
							<SmarthopList
								key="statements"
								mode="table"
								config={carrierSmartHopWalletStatementsList({ accountTypes, isAdmin })}
								dataIds={{ entityId: customerId }}
							/>
						),
					},
					{
						label: "Cards",
						component: (
							<SmarthopList
								key="cards"
								mode="table"
								config={carrierSmartHopWalletDebitCardsList({ accountTypes, isAdmin })}
								dataIds={{ entityId: customerId }}
							/>
						),
					},
				]}
			/>
		);
	};

	// Customer Page with 2FA page (?)
	return !is2faEnabled && !isAdmin && !shWalletData
		? render2FAVerification()
		: !shWalletData
		? loadingViewComponent()
		: renderAccountDetails();
};

export default SmartHopWalletCustomerPage;
