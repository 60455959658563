import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function PodCellView(props) {
	const mobile = props.mobile;
	const config = props.config;
	const variant = props.variant;
	const type = props.view?.metadata?.type;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	if (config?.readOnly) {
		return props.view?.label;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<CellFirstRow
					mobile={mobile}
					view={props.view}
					variant={variant}
					dialog={{ formId: "TASKS_POD_FORM", mode: "VIEW", dataIds: { podId: props.view?.value } }}
				/>
			}
			second={
				<>
					{config?.showType && (
						<CellSecondRow icon={"home_repair_service"} mobile={mobile} label={type} variant={variant} />
					)}
				</>
			}
		/>
	);
}

export default PodCellView;
