import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import i18next from "i18next";
import es from "../i18n/es";
import { useEffect } from "react";
i18next.addResourceBundle("es", "auth", es);

const goToLogin = (history, dispatch) => {
	dispatch(closeFormDialog());
	history.push("/login");
};

function ResetPasswordSuccessView(props) {
	useEffect(() => {
		props?.setTitle?.("Password Updated");
		props?.setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	const history = useHistory();
	const dispatch = useDispatch();
	return (
		<div>
			<Typography color="textSecondary" className="text-18 mb-24 mt-20 text-center font-light">
				{i18next.t(`auth:The password was changed successfully`)}
			</Typography>
			<div className="flex w-full flex-row items-top justify-center divide-x pb-10">
				<Button
					className="w-50"
					variant="contained"
					color="secondary"
					aria-label="Follow"
					onClick={() => goToLogin(history, dispatch)}
				>
					{i18next.t(`auth:Go to Login`)}
				</Button>
			</div>
		</div>
	);
}

export default ResetPasswordSuccessView;
