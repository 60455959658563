import OtpInput from "react-otp-input";

// Dependencies
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Avatar, Button, Link, CircularProgress } from "@material-ui/core";

// Icon
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Consts
const RESEND_CODE_LABEL = "Resend Code";

const useStyles = makeStyles((theme) => ({
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		margin: theme.spacing(3, 4, 2),
		[theme.breakpoints.down("md")]: {
			minWidth: "16vw",
		},
		minWidth: "180px",
	},
	hover: {
		"&:active:hover": {
			textDecoration: "underline",
			backgroundColor: "transparent",
		},
	},
}));

const renderLoading = (text) => {
	return (
		<div className="pt-24 text-center">
			<CircularProgress />
			<Typography color="primary" variant="subtitle1" className="mt-10">
				{text ?? "Loading..."}
			</Typography>
		</div>
	);
};

const countFormatter = (countDown) => {
	let minute = Math.floor((countDown / 60) % 60);
	let second = countDown % 60;
	minute = minute < 10 ? "0" + minute : minute;
	second = second < 10 ? "0" + second : second;
	return { minute, second };
};

const Smarthop2FAField = (props) => {
	const classes = useStyles();
	const countDown = props.countDown;
	const phone = props.phone;
	const { minute, second } = countFormatter(countDown);
	const shouldShowMessage = !isNaN(+countDown) && countDown !== null;
	return (
		<div className="p-12">
			<Grid item container justify="center">
				<Grid item container alignItems="center" direction="column">
					<Grid item>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
					</Grid>
					<Grid item>
						<Typography component="h1" variant="h5" className="pt-12 pb-48">
							Verification Code
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			{!props?.verificationToken && !props?.generatingCodeLoading ? (
				<>
					<Grid item className="text-center">
						<Typography variant="h6" color="textPrimary" className="pb-32 font-light ">
							To log in to Smarthop Wallet, you must request a verification code.
						</Typography>
					</Grid>
					<Grid container justify="center" direction="row">
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={props?.onSendCodeClick}
						>
							Send Code
						</Button>
					</Grid>
				</>
			) : props?.generatingCodeLoading ? (
				renderLoading("Generating OTP...")
			) : props?.verificationToken ? (
				<>
					<Grid item className="text-center">
						<Typography variant="h6" color="textPrimary" className="pb-48 font-light">
							{`Please enter the 6-digit verification code sent to your phone ending in **** ${phone}. ${
								shouldShowMessage && !props?.disabled
									? `The verification code will only be valid for ${minute} minutes and ${second} seconds.`
									: ""
							}`}
						</Typography>
					</Grid>
					<Grid item xs={12} container alignItems="center" direction="column" className="pt-12 pb-12">
						<Grid item spacing={3} justify="center">
							<OtpInput
								numInputs={6}
								isInputNum={true}
								onChange={props?.onChange}
								value={props?.code}
								isDisabled={props?.disabled || countDown === 0}
								hasErrored={props?.error}
								shouldAutoFocus={true}
								errorStyle={{
									appearance: "none",
									outline: "0",
									boxShadow: "0 0 0 3px rgb(255 0 0 / 50%)",
								}}
								focusStyle={{
									appearance: "none",
									outline: "0",
									boxShadow: "0 0 0 3px rgb(131 192 253 / 50%)",
								}}
								inputStyle={{
									width: "4.5rem",
									height: "4.5rem",
									margin: "0 0.5rem",
									fontSize: "2rem",
									color: "black",
									border: "1px solid #d6d6d6",
									borderRadius: "4px",
									backgroundClip: "padding-box",
								}}
							/>
						</Grid>
					</Grid>
					{props?.loading ? (
						renderLoading()
					) : (
						<Grid container item justify="center" textAlign="center" direction="row">
							<Typography variant="subtitle1" color="textSecondary" className="pt-12 font-light">
								{"Didn't receive the code or request a new code."}
							</Typography>
							{countDown === 0 ? (
								<Link className="font-normal" onClick={props?.onSendCodeClick}>
									<Typography variant="subtitle1" className="font-light pt-12 pl-6">
										{RESEND_CODE_LABEL}
									</Typography>
								</Link>
							) : (
								<Typography variant="subtitle1" color="textSecondary" className=" pl-6 pt-12 font-light underline">
									{RESEND_CODE_LABEL}
								</Typography>
							)}
						</Grid>
					)}
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default Smarthop2FAField;
