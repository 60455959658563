// Dependencies
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Tools
import { closeFormDialog } from "app/store/tools/formDialogSlice";

// Configs
import createForm from "./SmartHopWalletPurchaseDebitCardConfig";

// Utils
import {
	OTP_ERROR_MESSAGE_REQUIRED,
	UNAUTHORIZED_WALLET_ACCESS,
	cleanSmarthpWalletTokenStorage,
} from "app/main/utils/financeUtils";

// Services
import { requestCreateDebitCard } from "app/services/smarthopWalletServices";
import { getOnboardingCompanyData } from "app/services/onboardingServices";
import { UnitFinanceService } from "app/services/unitFinanceServices";

const SmartHopWalletPurchaseDebitCardView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Purchase Debit Card");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const dataIds = props.dataIds;
	const [loading, setLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(false);
	const [errors, setErrors] = useState(null);
	const [data, setData] = useState(null);

	// * Smarthop Wallet: OTP (2FA verification)
	const hasSmarthopWalletToken = useSelector(({ wallet }) => !!wallet.smarthopWallet?.token);

	const handledAction = async (data) => {
		const params = { type: dataIds.type, carrierId: dataIds.carrierId };
		if (hasSmarthopWalletToken) {
			try {
				setLoading(true);
				await requestCreateDebitCard(params, data);
				dispatch(closeFormDialog());
				setLoading(false);
				showSnackbar(snackbar, "Operation successfully completed.", "success", { duration: 10000 });
			} catch (err) {
				setLoading(false);
				if (err?.errors?.[0].code === UNAUTHORIZED_WALLET_ACCESS) {
					setLoadingError(true);
					setErrors(err?.errors);
					return;
				}
				showSnackbar(snackbar, err?.errors?.[0].message, "error", { duration: 10000 });
				dispatch(closeFormDialog());
			}
		} else {
			setLoadingError(true);
			setErrors(OTP_ERROR_MESSAGE_REQUIRED?.response?.data?.errors);
		}
	};

	const handleCancelAction = () => {
		dispatch(closeFormDialog());
	};

	useEffect(() => {
		(async () => {
			setLoadingError(false);
			setErrors([]);

			// If the smarthop wallet token is not saved in storage
			if (!hasSmarthopWalletToken) {
				setLoadingError(true);
				setErrors(OTP_ERROR_MESSAGE_REQUIRED?.response?.data?.errors);
				return;
			}

			setLoading(true);

			// TODO: remove this endpoint when the endpoint to retrieve carrier data is signed by double token verification.
			// Verify if the smarthopWallet token is still valid
			try {
				await UnitFinanceService.getWalletData({ entityId: dataIds.carrierId });
			} catch (err) {
				setLoading(false);
				if (err?.errors?.[0].code === UNAUTHORIZED_WALLET_ACCESS) {
					return cleanSmarthpWalletTokenStorage(dispatch);
				}
				return showSnackbar(snackbar, err?.errors?.[0].message, "error", { duration: 10000 });
			}

			// TODO: Create a banking endpoint that uses the same backend logic, but the endpoint is signed by double token verification.
			// Prefill Carrier Data
			const res = await getOnboardingCompanyData(dataIds?.carrierId);
			if (res) {
				setData(res);
				setLoading(false);
			}
		})();
	}, [dataIds?.carrierId, hasSmarthopWalletToken, snackbar, dispatch]);

	const mode = "CREATE";
	const form = useMemo(() => createForm({ carrierInfo: data }), [data]);

	return (
		<SmarthopFormView
			mode={mode}
			content={form}
			data={data}
			noInitValidation={true}
			dataIds={dataIds}
			loading={loading}
			loadingError={loadingError}
			errors={errors}
			onSubmit={handledAction}
			onCancel={handleCancelAction}
		>
			{props.children}
		</SmarthopFormView>
	);
};

export default SmartHopWalletPurchaseDebitCardView;
