const externalIdSalesforceForm = {
	urlPUT: "api/profile/carriers/:carrierId/external/SALESFORCE",
	urlGET: "api/profile/carriers/:carrierId/external/SALESFORCE",
	idKey: "_id",
	triggerEvent: "salesforceRevision",
	listenEvent: "salesforceRevision",
	header: {
		edit: { label: "Edit Salesforce Ids" },
		view: { label: "Salesforce" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{ key: "salesforceId", type: "text", label: "Account Id", required: true },
							{ key: "salesforceLeadId", type: "text", label: "Lead Id", required: true },
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default externalIdSalesforceForm;
