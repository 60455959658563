import SmarthopDialog from "@smarthop/form/SmarthopDialog";
import PaywallView from "./PaywallView";

function PaywallDialog(props) {
	const data = props.data ?? {};
	const onHandlerPaywall = props.onHandlerPaywall;
	const onHandlerClosePaywall = props.onHandlerClosePaywall ?? props.onHandlerPaywall;

	if (!data.open) return null;

	return (
		<SmarthopDialog open={data.open} title={"Upgrade Required"} onClose={() => onHandlerClosePaywall()}>
			<PaywallView keyId="PAYWALL_DIALOG" dataIds={data.dataIds} onHandlerPaywall={() => onHandlerPaywall()} />
		</SmarthopDialog>
	);
}

export default PaywallDialog;
