const adminDriverConfigurationForm = {
	urlGET: "api/profile/carriers/:carrierId/drivers/:driverId",
	urlPUT: "api/profile/carriers/:carrierId/drivers/:driverId/internal/config",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Driver Internal Config" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{ key: "verified", type: "checkbox", label: "Verified" },
				{ key: "notes", type: "text", label: "Notes", multiline: { rows: 12 } },
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminDriverConfigurationForm;
