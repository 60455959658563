const notification = {
	urlPOST: "api/mobile/users/:userId/devices/:deviceId/push",
	urlGET: "api/profile/users/:userId/devices/:deviceId",
	urlDELETE: "api/profile/users/:userId/devices/:deviceId",
	idKey: "_id",
	listenEvent: "profileRevision",
	triggerEvent: "profileRevision",
	header: {
		create: { label: "Notification Action" },
		delete: { label: "Delete Device" },
	},
	content: {
		create: {
			items: [
				{
					key: "title",
					type: "text",
					label: "Title",
					required: true,
				},
				{
					key: "message",
					type: "text",
					label: "Message",
					required: true,
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete device with ID '${item.deviceId._id}'?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};

export default notification;
