import { createRequest, throwResErrors } from "./requestUtil";

export function unsubscribeChat({ chatId, truckId }) {
	let url = `/api/messenger/chats/${chatId}/trucks/${truckId}/unsubscribe`;
	return createRequest()
		.delete(url)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getAllParticipantsChat(chatId) {
	let url = `/api/messenger/chats/${chatId}/participants`;
	return createRequest()
		.get(url)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
