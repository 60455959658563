import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Chip, Icon, IconButton, Menu, Button, Typography, CircularProgress } from "@material-ui/core";

import { NARROW_FIELDS } from "@fuse/default-settings";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
		boxShadow: "0px 0px 8px rgba(180, 180, 180, .6)",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

const useStyles = makeStyles((theme) => {
	return {
		field: {
			background: "white",
			borderRadius: "4px",
		},
		buttonNormal: {
			borderRadius: "4px",
			height: NARROW_FIELDS ? "4.6rem" : "5.2rem",
		},
		buttonDisabled: {
			background: theme.palette.grey["400"] + " !important",
		},
	};
});

export default function SmarthopUploadField(props) {
	const classes = useStyles();
	const [moreMenuEl, setMoreMenuEl] = useState(null);
	const [rendered, setRendered] = useState(false);

	// Data revision can trigger reload of the date externally
	const menuRevision = useSelector(({ tools }) => tools.revision["menu"]);
	const [revision, setRevision] = useState(menuRevision);
	const ButtonType = props?.IconButton ? IconButton : Button;

	useEffect(() => {
		if (menuRevision !== revision) {
			handleMoreMenuClose();
			setRevision(menuRevision);
		}
		// eslint-disable-next-line
	}, [menuRevision]);

	function handleMoreMenuClose() {
		setMoreMenuEl(null);
	}
	function handleMoreMenuClick(event) {
		setRendered(true);
		setMoreMenuEl(event.target);
	}

	const containerMenu =
		"flex sm:max-w-sm md:max-w-md flex-col items-top pl-10 md:pl-24 pr-44 md:pr-24 pt-0 md:pt-10 pb-0 md:pb-10" +
		(props?.IconButton || props?.chip ? " w-full" : " w-screen");

	const itemColor = props?.button?.color ?? "secondary";
	return (
		<div className="px-4">
			{!props?.chip ? (
				<div className="flex relative justify-end">
					<ButtonType
						color={itemColor}
						variant={props?.button?.variant ?? "contained"}
						classes={{
							root: classes.buttonNormal,
							disabled: classes.buttonDisabled,
							...(props?.button?.classes ?? {}),
						}}
						className={(props?.button?.classes?.root ?? "") + " -mt-1 "}
						aria-label="more"
						disabled={props.disabled}
						aria-owns={!!moreMenuEl ? "chats-more-menu" : null}
						aria-haspopup="true"
						onClick={(event) => handleMoreMenuClick(event)}
					>
						{props?.label ? props?.label : props?.icon ? <Icon>{props?.icon}</Icon> : <Icon>more_vert</Icon>}
					</ButtonType>
					{props.badge?.label && (
						<div
							className={
								"flex absolute top-0 -mt-10 -mr-4 h-24 min-w-24 rounded-20 items-center justify-center border-2" +
								(itemColor === "secondary" ? " bg-secondary border-white " : " bg-primary border-white ")
							}
						>
							<Typography className="text-12 font-semibold text-white p-4">{props.badge?.label}</Typography>
						</div>
					)}
				</div>
			) : (
				<Chip
					className={props?.button?.classes}
					color={props?.button?.color}
					label={props?.label}
					onClick={(event) => handleMoreMenuClick(event)}
				/>
			)}
			<StyledMenu
				id="chats-more-menu"
				anchorEl={moreMenuEl}
				open={!!moreMenuEl}
				onClose={handleMoreMenuClose}
				variant="menu"
			>
				<div
					className={
						"w-full  " +
						(props?.menu?.classes?.container?.includes("pr-") ? "" : " pr-20 md:pr-0 ") +
						(props?.menu?.classes?.container ?? "")
					}
					style={{ position: "relative" }}
				>
					<div style={{ position: "absolute", right: -2, top: -10 }}>
						{!props?.IconButton && !props?.chip && (
							<div className="flex">
								{props?.actions?.length > 0 &&
									props?.actions?.map((action, index) => {
										return (
											<div className="mt-12 mr-10" key={index}>
												<Button
													color={action.color ?? "secondary"}
													className={"h-auto md:min-w-120 " + action?.button?.classes?.root}
													variant="contained"
													disabled={!action?.button?.alwaysEnabled}
													onClick={() => {
														handleMoreMenuClose();
														action.click();
													}}
												>
													{action.button?.loadingState ? (
														<CircularProgress size="1.5em" color="white" />
													) : (
														action?.label
													)}
												</Button>
											</div>
										);
									})}

								<IconButton key="close" aria-label="Close" color="secondary" onClick={handleMoreMenuClose}>
									<Icon className="m-5 text-28">close</Icon>
								</IconButton>
							</div>
						)}
					</div>
					{props?.groups?.length ? (
						props?.children?.map((group, index) => {
							return (
								<div
									className={
										"flex sm:max-w-sm md:max-w-480 flex-col w-screen pr-20 pl-20 md:pr-0 md:pl-0 " +
										(index !== 0 || index !== props?.groups?.length - 1 ? "md:pr-10 md:pl-10" : "")
									}
								>
									{group}
								</div>
							);
						})
					) : (
						<div className={containerMenu}>{props?.children}</div>
					)}
				</div>
			</StyledMenu>

			{/* WORKAROUNDL: In order to properly render elements they should always be part of html, however hidden */}
			{!rendered && !props?.renderOnce && <div className={"hidden"}>{props.children ?? ""}</div>}
		</div>
	);
}
