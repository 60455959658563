import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { formatDate } from "app/main/utils/dateUtils";

function SearchesCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;

	const carrier = props.view?.metadata?.carrier;
	const truck = props.view?.metadata?.truck;

	const countInternalSearches = props.view?.metadata?.countInternalSearches;
	const countExternalSearches = props.view?.metadata?.countExternalSearches;
	const lastInternalSearchDs = props.view?.metadata?.lastInternalSearchDs;
	const lastExternalSearchDs = props.view?.metadata?.lastExternalSearchDs;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const url =
		`/admin/search/requests?p=%7B%22filters%22%3A%7B` +
		`%22carrier__view%22%3A%22${props?.item?.carrier__view?.label ?? carrier}%22%2C` +
		`%22truck__view%22%3A%22${props.item.truck__view.label ?? truck}%22%2C` +
		`%22truck%22%3A%22${truck}%22%2C` +
		`%22carrier%22%3A%22${carrier}%22%2C` +
		`%22createdAt%22%3A%22__DATE_DAYS_GT__30%22%7D%7D`;

	if (config?.readOnly) {
		return props.item?.plan__view?.label;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={props.view} url={url} variant={variant} />}
			second={
				<>
					{config?.showMetadata && (
						<>
							<CellSecondRow
								mobile={mobile}
								label={lastInternalSearchDs ? formatDate(lastInternalSearchDs) : "X"}
								variant={variant}
								icon={"support_agent"}
								color={"text-blue-800"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"search"}
								label={countInternalSearches + ""}
								variant={variant}
								color={"text-blue-900 pr-8"}
							/>
							<CellSecondRow
								mobile={mobile}
								label={lastExternalSearchDs ? formatDate(lastExternalSearchDs) : "X"}
								variant={variant}
								icon={"person"}
								color={"text-green-700"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"search"}
								label={countExternalSearches + ""}
								variant={variant}
								color={"text-green-800"}
							/>
						</>
					)}
				</>
			}
		/>
	);
}

export default SearchesCellView;
