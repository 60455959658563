import React from "react";
import { createTooltip } from "app/main/utils/tableUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import formatHelper from "@smarthop/utils/formatHelpers";

const createAlertView = ({ status, statusProcess, state, lastMessage }) => {
	let style;
	status = statusProcess || status;
	if (status === "FAILED") {
		style = formatHelper.errorStyle;
	} else if (status === "IN_PROGRESS" || status === "POSTPONED" || status === "RETRYING") {
		style = formatHelper.inprogressStyle;
	} else if (status === "FINISHED" || status === "FINISH") {
		if (state === "BROKER_DECLINED" || state === "EXPIRED") {
			style = formatHelper.declineStyle;
		} else {
			style = formatHelper.successStyle;
		}
	} else {
		return status;
	}

	const viewIcon = (statusView) => {
		if (statusView === "IN_PROGRESS" || statusView === "POSTPONED") {
			return (
				<Box sx={{ position: "relative", display: "inline-flex" }}>
					<CircularProgress size={30} style={{ color: "#06060633" }} sx={formatHelper.progressStyle} />
					<Box sx={formatHelper.progressBoxStyle}>
						<Icon className={`text-16 text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>
					</Box>
				</Box>
			);
		} else {
			return <Icon className={`text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>;
		}
	};

	return createTooltip(
		viewIcon(status),
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{lastMessage?.split(" ---------------- ")[0]}
		</Typography>,
		`bg-${style.hoverColor}`
	);
};

const actionTypeView = (item) => {
	if (item?.content?.actionType) {
		return item?.content?.actionType;
	}
	return "BOOK";
};

const booking = {
	urlGET: "api/transaction/users/:userId/records/BOOK_NOW",
	idKey: "_id",
	triggerEvent: "transactionsRevision",
	listenEvent: "transactionsRevision",
	segmentKey: "TRANSACTIONS_STATE_BOOKING",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: [
				"createdAt",
				"transaction__view.metadata.slower_step.stepTime",
				"carrier__view.label",
				"transaction__view.metadata.total_time",
			],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					// TODO should be dynamic as in dialog to transition from one state to another
					key: "state",
					type: "select",
					label: "State",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "Transaction Started", value: "STARTED" },
						{ label: "Broker Booking Request", value: "BROKER_REQUEST" },
						{ label: "Broker Booking Accepted", value: "BROKER_ACCEPTED" },
						{ label: "Broker Booking Pending", value: "BROKER_PENDING" },
						{ label: "Broker Booking Declined", value: "BROKER_DECLINED" },
						{ label: "Broker Booking Expired", value: "EXPIRED" },
						{ label: "Broker Counter Canceled", value: "CANCELED" },
						{ label: "Broker Counter Received", value: "COUNTER_RECEIVED" },
						{ label: "Broker Counter Rejected", value: "COUNTER_REJECT" },
						{ label: "Ticket Created", value: "TICKET_CREATED" },
						{ label: "Trip Created", value: "TRIP_CREATED" },
						{ label: "Send Slack Message", value: "SLACK_SENT" },
						{ label: "Broker RC Pending", value: "BROKER_RC_PENDING" },
						{ label: "Broker RC Sent", value: "BROKER_RC_SENT" },
						{ label: "Done", value: "DONE" },
					],
				},
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "Finished", value: "FINISHED" },
						{ label: "In Progress", value: "IN_PROGRESS" },
						{ label: "Postponed", value: "POSTPONED" },
						{ label: "Failed", value: "FAILED" },
					],
				},
				{
					key: "content.load.owner",
					type: "select",
					label: "Broker",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "CH Robinson", value: "chrobinson" },
						{ label: "Coyote", value: "coyote" },
						{ label: "DAT", value: "dat" },
						{ label: "Echo", value: "echo" },
						{ label: "Loadsmart", value: "loadsmart" },
						{ label: "Newtrul", value: "newtrul" },
						{ label: "Parade", value: "parade" },
					],
				},
				{
					key: "createdAt",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__DATE_DAYS_GT__7",
							label: "Last 7 Days",
						},
						{
							value: "__DATE_DAYS_GT__30",
							label: "Last 30 Days",
						},
						{
							value: "__DATE_DAYS_GT__90",
							label: "Last 90 Days",
						},
						{
							value: "__DATE_DAYS_GT__365",
							label: "Last 1 Year",
						},
					],
				},
				{
					key: "carriers_type",
					type: "select",
					label: "Internal Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
				{
					key: "system_show_only_my_books",
					type: "checkbox",
					label: "Show Only My Books",
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		isHighlighted: (item) => item?.status === "POSTPONED" || item?.status === "FAILED",
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "user", type: "id", label: "User ID", group: "internal" },
			{
				key: "content.user__view",
				type: "user__view",
				label: "User",
				builder: (item) => item?.user__view,
				viewConfig: {
					showRole: true,
				},
			},
			{ key: "createdAt", type: "date", label: "Created" },
			{ key: "content.load.load_id", type: "id", label: "Broker Load ID" },
			{ key: "content.load.price", type: "id", label: "Rate" },
			{ key: "content.load.bidPrice", type: "id", label: "Bid Rate" },
			{
				key: "trip__view",
				type: "trips__view",
				builder: (item) => item?.trip__view,
				label: "Trip",
				viewConfig: {
					showBroker: true,
				},
			},
			{
				key: "carrier__view.",
				type: "carrier__view",
				label: "Carrier",
				builder: (item) => item.carrier__view,
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
			},
			{
				key: "state",
				type: "text",
				label: "State",
				builder: (item) => {
					return item.state || item.status;
				},
			},
			{
				key: "transaction__view.metadata.slower_step.stepTime",
				label: "Slower Status",
				type: "component",
				group: "internal",
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.transaction__view?.metadata?.slower_step?.state
									? item.transaction__view?.metadata?.slower_step?.state.toUpperCase()
									: "-"}
							</Typography>
							<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
								{item.transaction__view?.metadata?.slower_step?.stepTime
									? (item.transaction__view?.metadata?.slower_step?.stepTime / 1000).toFixed(0) + "s"
									: "-"}
							</Typography>
						</div>
					);
				},
			},
			{
				key: "transaction__view.metadata.total_time",
				label: "Total Time",
				type: "component",
				group: "internal",
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.transaction__view?.metadata?.total_time
									? (item.transaction__view?.metadata?.total_time / 1000).toFixed(0) + "s"
									: "-"}
							</Typography>
						</div>
					);
				},
			},
			{ key: "error", label: "Status", type: "component", builder: (item) => createAlertView(item) },
			{ key: "content.load.owner", type: "text", label: "Broker" },
			{
				key: "content.load.actionType",
				label: "Action Type",
				type: "component",
				builder: (item) => actionTypeView(item),
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "transition_state",
						label: "Transition to a state",
						auth: ["administrator", "ops support"],
						dialogBuilder: (item, dataIds) => ({
							formId: "TRANSACTIONS_BOOKING_FORM",
							mode: "EDIT",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
					},
					{
						key: "execute_logs",
						label: "Logs",
						dialogBuilder: (item, dataIds) => ({
							mode: "view",
							viewId: "TRANSACTIONS_EXECUTE_LOGS",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
					},
					{
						key: "check_status_logs",
						label: "Retry Process",
						dialogBuilder: (item, dataIds) => ({
							mode: "VIEW",
							formId: "TRANSACTIONS_BOOKING_STATUS_FORM",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
						isVisible: (item) => {
							return item.status === "POSTPONED" || item.status === "FAILED";
						},
					},
					{
						key: "check_status_logs",
						label: "Retry RC email",
						dialogBuilder: (item, dataIds) => ({
							mode: "VIEW",
							formId: "TRANSACTIONS_RETRY_RC_FORM",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
						isVisible: (item) => {
							return item.state === "BROKER_RC_TIME_OUT";
						},
					},
				],
			},
		],
	},
};

export default booking;
