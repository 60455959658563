import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';

// eslint-disable-next-line
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
// eslint-disable-next-line
import FuseSearch from '@fuse/core/FuseSearch';
// eslint-disable-next-line
import FuseShortcuts from '@fuse/core/FuseShortcuts';
// eslint-disable-next-line
import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
// eslint-disable-next-line
import AdjustFontSize from '../../shared-components/AdjustFontSize';
// eslint-disable-next-line
import FullScreenToggle from '../../shared-components/FullScreenToggle';


const useStyles = makeStyles(theme => ({
	root: {}
}));

function ToolbarLayout2(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(selectToolbarTheme);

	const classes = useStyles(props);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx(classes.root, 'flex relative z-20 shadow-md', props.className)}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
			>
				<Toolbar className="container p-0 lg:px-24 min-h-48 md:min-h-64">
					{config.navbar.display && (
						<Hidden lgUp>
							<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
						</Hidden>
					)}

					<div className="flex flex-1">
						{/* <Hidden mdDown>
							<FuseShortcuts />
						</Hidden> */}
					</div>

					<div className="flex items-center px-8 h-full overflow-x-auto">
						{/* <LanguageSwitcher /> */}

						{/* <AdjustFontSize /> */}

						{/* <FullScreenToggle /> */}

						{/* <FuseSearch /> */}

						{/* <QuickPanelToggleButton /> */}

						<UserMenu />
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(ToolbarLayout2);
