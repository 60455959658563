import { createSlice } from "@reduxjs/toolkit";

const MAX_CACHE_SIZE = 30;
const CACHE_KEYS = "__cache_keys";

const tableSlice = createSlice({
	name: "table",
	initialState: {
		/**
		 *  Each table metadata can be found in this slice using request
		 *  URL which is unique key for each item
		 *
		 *  Example:
		 *   __unique_key__: {
		 *    data: {}
		 *   }
		 */
		barOffsetOn: false,
	},
	reducers: {
		setCache: (state, action) => {
			let payload = action.payload;
			if (!payload || !payload.key) {
				return;
			}

			let newItem = !state[payload.key];
			if (newItem) {
				let newKeys = [payload.key, ...(state[CACHE_KEYS] ?? [])];
				if (newKeys.length > MAX_CACHE_SIZE) {
					let poppedKey = newKeys.pop();
					delete state[poppedKey];
				}
				state[CACHE_KEYS] = newKeys;
			}

			state[payload.key] = {
				url: payload.url,
				result: payload.result,
				request: payload.request,
			};
		},
		setBarOffsetOn: (state, action) => {
			state.barOffsetOn = action.payload;
		},
	},
});

export const { setCache, setBarOffsetOn } = tableSlice.actions;

export default tableSlice.reducer;
