import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasReachOneHundredPercent: false
};

const onboardingSlice = createSlice({
	name: "onboarding",
	initialState,
	reducers: {
		setHasReachOneHundredPercent: (state, action) => {
			state.hasReachOneHundredPercent = action.payload
		}
	},
	extraReducers: {},
});

export const { setHasReachOneHundredPercent } = onboardingSlice.actions;

export default onboardingSlice.reducer;
