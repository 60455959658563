import { createBrokerView, createMPIView } from "app/main/utils/brokerUtils";
import { formatDate } from "app/main/utils/dateUtils";
import { createPriceView } from "app/main/utils/priceUtils";
import { createStatusView } from "./searchResultList";
import { getUserId } from "app/services/LoginService";

const search = {
	urlGET: "api/search/requests/:searchId/details",
	idKey: "tripid",
	listenEvent: "searchRevision",
	segmentKey: "ADMIN_SEARCH_DETAILS",
	cache: {
		key: "search_result_:searchId",
		disableInit: true,
	},
	content: {
		grid: {
			variant: "skinny",
			theme: "light",
			tilesPerRow: 2,
			itemsPerRow: 8,
			mobile: {
				tilesPerRow: 1,
				itemsPerRow: 3,
				layout: [
					["origin", "ready"],
					["destination", "deliverby"],
					["distance_to_origin", "miles", "price", "rpm"],
				],
			},
		},
		table: {
			variant: "extra-skinny",
			orderBy: [
				"price",
				"rpm",
				"miles",
				"updated",
				"distance_to_origin",
				"distance_to_dest",
				"rank",
				"weight",
				"profit_trip_carrier",
				"ready",
			],
		},
		pagination: {
			enabled: true,
			auto: {
				enabled: false,
			},
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		order: {
			defaut: {
				key: "profit_trip_carrier",
				dir: "desc",
			},
		},
		click: {
			dialogBuilder: (item, dataIds) => ({
				viewId: "LOAD_SUGGEST_VIEW",
				dataIds: {
					load: item,
					loadId: item.tripid,
					userId: getUserId(),
					searchId: dataIds.searchId,
					carrierId: item.carrierId,
					truckId: item.truckId,
					historicalView: true,
				},
			}),
		},
		isHighlighted: (item) => item && item.seen,
		items: [
			{
				builder: (item) => createStatusView(item, true),
				type: "component",
				key: "match_status",
				label: "Match",
				grid: { headerTitle: true },
			},
			{
				builder: (item, dispatch, snackbar) =>
					createBrokerView(item, undefined, dispatch, snackbar, { skinny: true, showSource: false }),
				key: "clientid",
				type: "component",
				label: "Broker",
				grid: { headerTitle: true },
			},
			{ key: "miles", type: "text", label: "Miles" },
			{ key: "weight", type: "text", label: "Weight" },
			{ key: "distance_to_origin", type: "number", label: "DH-O" },
			{ key: "origin", type: "text", label: "Origin" },
			{
				builder: (item) => formatDate(item.ready, item.pickupby, true, true, true, true),
				key: "ready",
				label: "Pickup",
			},
			{
				key: "rank_mci",
				type: "component",
				label: "MPI",
				builder: (item) => createMPIView(item, true),
			},
			{ key: "distance_to_dest", type: "number", label: "DH-D" },
			{ key: "destination", type: "text", label: "Destination" },
			{
				builder: (item) =>
					item.owner === "dat" ? "-" : formatDate(item.dropready, item.deliverby, true, true, true, true),
				key: "deliverby",
				label: "Delivery",
			},
			{
				builder: (item) => createPriceView(item.price),
				key: "price",
				type: "text",
				label: "Rate",
			},
			{ builder: (item) => createPriceView(item.rpm, 2), key: "rpm", type: "text", label: "RPM" },
			{
				builder: (item) =>
					createPriceView(item.profit_trip_carrier) + (item.price > 0 || !item.profit_trip_carrier ? "" : "*"),
				type: "component",
				key: "profit_trip_carrier",
				label: "Profit",
			},
		],
	},
};

export default search;
