const adminSmartPayPayoutAccountForm = {
	urlGET: "billing/admin/carriers/:carrierId/payout-account",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision", "discountRevision"],
	header: { view: { label: `Payout Account Summary` } },
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "accountName",
								type: "text",
								label: "Name",
							},
							{
								key: "accountNumber",
								type: "text",
								label: "Account #",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "routing",
								type: "text",
								label: "Routing #",
							},
							{
								key: "wire_routing",
								type: "text",
								label: "Wire Routing #",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "status",
								type: "text",
								label: "Status",
							},
						],
					},
				},
			],
		},
	},
};

export default adminSmartPayPayoutAccountForm;
