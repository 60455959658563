import GoogleMap from "google-map-react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { useState, useEffect } from "react";

const StyledBadge = withStyles((theme) => ({
	badge: {
		top: -5,
		padding: "4px 4px",
	},
}))(Badge);

const useStyles = makeStyles((theme) => {
	return {
		mapRadius: {
			borderRadius: "20px",
		},
		markerTruck: {
			position: "absolute",
			left: "50%",
			transform: "translate(-50%, 0)",
			bottom: 0,
			fontSize: "25px",
		},
		infoStyle: {
			bottom: "10px",
			width: "max-content",
			backgroundColor: "#062246",
			position: "absolute",
			left: "50%",
			transform: "translate(-50%, 0)",
		},
	};
});

function TripMap(props) {
	const classes = useStyles();
	const [loadInfo, setLoadInfo] = useState(null);
	const [dataCoordinates, setListCoordinates] = useState(null);
	const [mapObj, setMapObj] = useState({
		map: null,
		maps: null,
	});
	const [polylines, setPolylines] = useState([]);

	useEffect(() => {
		setLoadInfo(props.load);
	}, [props.load]);

	useEffect(() => {
		const dataCoordinates = [];

		// Locations Data
		if (loadInfo?.all_locations?.length > 0) {
			let locations = loadInfo.all_locations;
			for (let location of locations) {
				if (location?.coordinates?.lat && location?.coordinates?.long) {
					dataCoordinates.push({ lat: location?.coordinates?.lat, long: location?.coordinates?.long });
				}
			}
		}
		setListCoordinates(dataCoordinates);
	}, [loadInfo]);

	const removePolylines = () => {
		polylines.forEach((polyline) => {
			polyline.setMap(null);
		});

		setPolylines([]);
	};

	useEffect(() => {
		if (dataCoordinates?.length && mapObj.maps) {
			removePolylines();

			const { map, maps } = mapObj;
			const updatedPolylines = [];

			// All Locations
			getMapBounds(map, maps);
			for (let i = 0; i < dataCoordinates.length - 1; i++) {
				let coord1 = dataCoordinates[i];
				let coord2 = dataCoordinates[i + 1];
				const type = loadInfo?.all_locations?.[i]?.type;
				const DirectionsService = new maps.DirectionsService();
				DirectionsService.route(
					{
						origin: { lat: coord1.lat, lng: coord1.long },
						destination: { lat: coord2.lat, lng: coord2.long },
						travelMode: maps.TravelMode.DRIVING,
						frameRoute: false,
						frameOptions: {
							animate: false,
						},
					},
					(result, status) => {
						if (result?.routes[0]?.overview_path) {
							const polyline = new maps.Polyline({
								geodesic: true,
								path: result && result.routes[0].overview_path,
								strokeOpacity: 1,
								strokeColor: type === "EMPTY" ? "rgb(6, 34, 70)" : "rgb(231, 104, 61)",
								strokeWeight: 2,
							});
							polyline.setMap(map);
							updatedPolylines.push(polyline);
						}
					}
				);
			}
			setPolylines(updatedPolylines);
		}

		// eslint-disable-next-line
	}, [dataCoordinates, mapObj]);

	const getMapBounds = (map, maps) => {
		const bounds = new maps.LatLngBounds();

		// All Locations
		for (let coordinates of dataCoordinates) {
			bounds.extend(new maps.LatLng(coordinates?.lat, coordinates?.long));
		}
		map.fitBounds(bounds);
	};

	const Marker = ({ index, color }) => {
		return <StyledBadge badgeContent={index} color={color} />;
	};

	const onLoadedMap = ({ map, maps }) => setMapObj({ map, maps });

	return (
		// TODO: [FAC-652] Create a generic component to create maps
		<div className={classes.mapRadius + " " + props?.classes?.root}>
			<GoogleMap
				bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
				defaultZoom={6} // Disable animation to avoid issues with performance
				defaultCenter={[37.09024, -95.712891]}
				onGoogleApiLoaded={onLoadedMap}
				yesIWantToUseGoogleMapApiInternals
			>
				{dataCoordinates?.map((path, index) => {
					const type = loadInfo?.all_locations?.[index]?.type;
					return (
						<Marker
							key={index}
							index={index + 1}
							lat={path.lat}
							lng={path.long}
							color={type === "EMPTY" ? "primary" : "secondary"}
						/>
					);
				})}
			</GoogleMap>
		</div>
	);
}

export default TripMap;
