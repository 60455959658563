/* eslint import/no-extraneous-dependencies: off*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	online: false,
  clockInTime: null,
};

const clockSlice = createSlice({
	name: "auth/user",
	initialState,
	reducers: {
		setOnline: (state, action) => {
      state.online = action.payload;
    },
		setClockInTime: (state, action) => {
      state.clockInTime = action.payload;
    },
	},
	extraReducers: {},
});

export const { setOnline, setClockInTime } = clockSlice.actions;

export default clockSlice.reducer;
