import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// utils
import { formatCurrency } from "app/main/utils/tableUtils";

// Components and views
import { SmarthopConfirmDialog } from "@smarthop/form";
import BannerAlertView from "app/main/alerts/BannerAlertView";

// Services
import { cancelTrialPlatformPlan } from "app/services/platformPlanServices";

const TrialCancelationBar = (props) => {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [confirmationModel, setConfirmationModel] = useState();

	const endTrialDate = moment(props?.currentPlan?.postSubscriptionPlan?.endTrialDate).format("ddd MMM Do, YYYY");

	const currentAmount = props.currentPlan?.subscription?.plan?.amount / 100;
	const currentInterval = props?.currentPlan?.subscription?.plan?.interval;
	const currentIntervalCount = props?.currentPlan?.subscription?.plan?.interval_count;
	const currentIntervalMessage = `${
		currentIntervalCount > 1 ? "every " + currentIntervalCount + " " : "per "
	}${currentInterval}${currentIntervalCount > 1 ? "(s)" : ""}`;

	const nextAmount = props.nextPlan?.price__view?.metadata?.stripe?.unit_amount / 100;
	const nextInterval = props?.nextPlan?.price__view?.metadata?.stripe?.recurring?.interval;
	const nextIntervalCount = props?.nextPlan?.price__view?.metadata?.stripe?.recurring?.interval_count;
	const nextIntervalMessage = `${nextIntervalCount > 1 ? "every " + nextIntervalCount + " " : "per "}${nextInterval}${
		nextIntervalCount > 1 ? "(s)" : ""
	}`;

	const cancelTrialHandler = async () => {
		try {
			await cancelTrialPlatformPlan(props.carrierId);
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			showSnackbar(snackbar, "Post trial plan canceled.", "success", { duration: 5000 });
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error", {
				duration: 5000,
			});
		}
	};

	const banner = {
		severity: "warning",
		messageClass:
			"font-light tracking-wide text-center text-13 text-yellow-900 font-semibold items-center justify-center",
		iconClass: "mr-10",
		message: `You still be charged your current subscription of ${formatCurrency(
			currentAmount
		)} ${currentIntervalMessage} while the trial is active. After that, you will be charged the new plan amount of ${formatCurrency(
			nextAmount
		)} ${nextIntervalMessage} starting on ${endTrialDate}. Pro-rates will apply.`,
		buttonAction: {
			label: "Cancel",
			onClick: () =>
				setConfirmationModel({
					message:
						"If you cancel trial now you won't be able to get it again. Are you sure you want to cancel the free trial?",
					acceptMsg: "Cancel Anyway",
					closeMsg: "Close",
					onAccept: cancelTrialHandler,
				}),
			classes: "",
		},
		bannerClass:
			"flex w-full items-center justify-center intems-center px-32 md:px-20 h-58 border-yellow-900 text-yellow-900 border-2 rounded-16 bg-yellow-50",
	};

	return (
		<div>
			<BannerAlertView banner={banner} startView={true} noWrap={false} />
			<SmarthopConfirmDialog
				open={!!confirmationModel?.message}
				title={confirmationModel?.message}
				acceptMsg={confirmationModel?.acceptMsg}
				closeMsg={confirmationModel?.closeMsg}
				onClose={() => {
					typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
					setConfirmationModel(null);
				}}
				onAccept={() => {
					confirmationModel?.onAccept();
					setConfirmationModel(null);
				}}
			/>
		</div>
	);
};

export default TrialCancelationBar;
