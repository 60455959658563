import { createRequest, throwResErrors } from "./requestUtil";

export const setDefaultCostStructure = (costStructureId, data) =>
	createRequest()
		.put(`api/cost-structures/${costStructureId}/default`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const createtCostStructure = (data) =>
	createRequest()
		.post(`api/cost-structures`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const getCostStructure = (costStructureId) =>
	createRequest()
		.get(`api/cost-structures/${costStructureId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const getCostStructureView = (costStructureId, carrierId) =>
	createRequest()
		.get(`api/cost-structures/carriers/${carrierId}/${costStructureId}/view`, { carrierId })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const updateCostStructure = (costStructureId, data) =>
	createRequest()
		.put(`api/cost-structures/${costStructureId}`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const costStructureCalculate = (data) =>
	createRequest()
		.post(`api/cost-structures/calculate-load-profit`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const deleteCostStructure = (costStructureId) =>
	createRequest()
		.delete(`api/cost-structures/${costStructureId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const updateTripCostStructure = (tripId, data) =>
	createRequest()
		.put(`api/cost-structures/trips/${tripId}`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
