import TreeVisualizer from "./TreeVisualizer";
import DrawerPortal from "../strategy/components/strategy-drawer/DrawerPortal";
import { useState } from "react";
import StrategyDrawer from "../strategy/components/strategy-drawer/StrategyDrawer";

const TreeVisualizerWithDrawer = (props) => {
	const [drawerHeader, setDrawerHeader] = useState(null);
	const [treeState, setTreeState] = useState(null);
	const [drawerBody, setDrawerBody] = useState(null);
	const [firstOpen, setFirstOpen] = useState(true);
	const [closedDrawerHeight, setClosedDrawerHeight] = useState(null);

	const { result, targetProfit, useControlPanelState, setControlPanelValue, setErrors, topLevel, disableSaving } =
		props;
	let equipment = result?.resData?.strategy?.equipment?.toLowerCase();
	let meanProfit = result?.resData?.strategy?.response?.statistics?.total_profit?.mean || 0;
	let stdProfit = result?.resData?.strategy?.response?.statistics?.total_profit?.std || 0;
	let options = { equipment, targetProfit: targetProfit, strategyProfit: meanProfit, strategyProfitStd: stdProfit };

	return (
		<>
			<TreeVisualizer
				tree={result.tree}
				dateRange={result.dateRange}
				resData={result.resData}
				setDrawerHeader={setDrawerHeader}
				setDrawerBody={setDrawerBody}
				options={options}
				useControlPanelState={useControlPanelState}
				setControlPanelValue={setControlPanelValue}
				treeState={treeState}
				setTreeState={setTreeState}
				setErrors={setErrors}
			/>
			{!!drawerHeader &&
				(topLevel ? (
					<DrawerPortal>
						<StrategyDrawer
							header={drawerHeader}
							body={drawerBody}
							firstOpen={firstOpen}
							strategyReqData={result?.resData?.strategy?.response?.queryData}
							setDrawerHeader={setDrawerHeader}
							setFirstOpen={setFirstOpen}
							closedDrawerHeight={closedDrawerHeight}
							setClosedDrawerHeight={setClosedDrawerHeight}
							disableSaving={disableSaving}
							topLevel={topLevel}
						/>
					</DrawerPortal>
				) : (
					<StrategyDrawer
						header={drawerHeader}
						body={drawerBody}
						firstOpen={firstOpen}
						strategyReqData={result?.resData?.strategy?.response?.queryData}
						setDrawerHeader={setDrawerHeader}
						setFirstOpen={setFirstOpen}
						closedDrawerHeight={closedDrawerHeight}
						setClosedDrawerHeight={setClosedDrawerHeight}
						disableSaving={disableSaving}
					/>
				))}
		</>
	);
};

export default TreeVisualizerWithDrawer;
