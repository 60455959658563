const PAYEE_TYPES = {
	DRIVER: "DRIVER",
	DISPATCHER: "DISPATCHER",
	INVESTOR: "INVESTOR",
	// Exception
	NOT_A_PAYEE: "NOT_A_PAYEE",
};

// Any user enrolled in payroll
const isEnrolled = (user) => !!user?.payroll_enrolled || user?.payroll_accessible;

// Complex cases
const isDriver = (user) => !!user?.driverId || user?.driver;
const isInvestor = (user) => !!user?.gatekeepers?.permission_investor_on;
const isDispatcher = (user) => user?.role === "CARRIER_DISPATCHER" && isEnrolled(user);

/**
 * True if user is a payee, false otherwise.
 * A payee is a user who is a driver, dispatcher, investor or enrolled in payroll.
 * @param {*} user
 * @returns boolean
 */
const isPayee = (user) => {
	const resolvers = [
		// All drivers are payees
		isDriver,
		// Investors are enrolled in payroll
		isInvestor,
		// Users with enrollement
		isEnrolled,
	];
	return resolvers.some((res) => res(user));
};

/**
 * Returns the type of payee
 * @param {*} user
 * @returns PAYEE_TYPES
 */
const getPayeeType = (user) => {
	const isAPayee = isPayee(user);
	if (!isAPayee) {
		return PAYEE_TYPES.NOT_A_PAYEE;
	}

	const resolversMatrix = [
		[isDriver, PAYEE_TYPES.DRIVER],
		[isDispatcher, PAYEE_TYPES.DISPATCHER],
		[isInvestor, PAYEE_TYPES.INVESTOR],
	];
	return resolversMatrix.find(([resolver]) => resolver(user))[1];
};

const PayrollHelper = { isDriver, isInvestor, isDispatcher, isPayee, getPayeeType };
export default PayrollHelper;
