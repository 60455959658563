import { forwardRef, useImperativeHandle, useState } from "react";

// third party
import Draggable from "react-draggable";

// styles
import makeStyles from "@material-ui/core/styles/makeStyles";

// mui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

// icons
import Close from "@material-ui/icons/Close";

// components
import LegendList from "./LegendList";
import Legend from "./Legend";

const useStyles = makeStyles({
	dialog: {
		margin: "33vh auto 0",
		height: "fit-content",
		width: "fit-content",
	},
	content: {
		padding: 0,
		maxHeight: "75vh",
	},
	titleBox: { display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "move" },
});

const LegendDialog = ({ config }, ref) => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	useImperativeHandle(ref, () => ({
		open: () => {
			setOpen(true);
		},
		close: () => {
			setOpen(false);
		},
		toggle: () => {
			setOpen((open) => !open);
		},
	}));

	const handleClose = (ev, reason) => {
		if (reason !== "backdropClick") setOpen(false);
	};

	const renderItem = (item) => (
		<Legend key={item.label} label={item.label} imgSrc={item?.imgSrc} icon={item?.icon} description={item?.description}>
			{item?.items?.map(renderItem)}
		</Legend>
	);

	return (
		open && (
			<Draggable handle=".dragDialog">
				<Dialog
					open={open}
					onClose={handleClose}
					disableAutoFocus
					hideBackdrop
					disableScrollLock
					className={classes.dialog}
				>
					<DialogTitle>
						<Box className={`${classes.titleBox} dragDialog`}>
							{config?.title}
							<IconButton onClick={handleClose}>
								<Close />
							</IconButton>
						</Box>
					</DialogTitle>
					<DialogContent className={classes.content}>
						<LegendList>{config?.items?.map(renderItem)}</LegendList>
					</DialogContent>
				</Dialog>
			</Draggable>
		)
	);
};

export default forwardRef(LegendDialog);
