import moment from "moment";

const currentYear = new Date().getFullYear();
const getYearlyOptions = () => {
	const currentYear = new Date().getFullYear();

	const yearOptions = [];
	for (let year = currentYear; year >= 2023; year--) {
		yearOptions.push({
			value: year.toString(),
			label: year.toString(),
		});
	}

	return yearOptions;
};

const getQuarterlyOptions = () => {
	const currentYear = new Date().getFullYear();
	const currentMonth = 2; //new Date().getMonth() + 1; // Months are 0-indexed

	const quarters = ["Q1", "Q2", "Q3", "Q4"];

	const pastQuarterOptions = [];

	for (let year = currentYear; year >= 2023; year--) {
		for (let quarterIndex = quarters.length - 1; quarterIndex >= 0; quarterIndex--) {
			const quarter = quarters[quarterIndex];
			const startMonth = quarterIndex * 3;
			const endMonth = startMonth + 2;

			// Check if the current month is between the start and end month of the quarter
			// and also check if the end month of the quarter has already passed
			if (currentYear > year || (currentYear === year && currentMonth >= startMonth)) {
				const label = `${year}-${quarter}`;
				const value = `${moment().year(year).month(startMonth).startOf("month").valueOf()}__DATE_RANGE__${moment()
					.year(year)
					.month(endMonth)
					.endOf("month")
					.valueOf()}`;

				pastQuarterOptions.push({ label, value });
			}
		}
	}
	return pastQuarterOptions;
};

const generateReportForm = {
	urlGET: "api/reports/carriers/:carrierId/report/:reportId",
	urlPOST: "api/reports/carriers/:carrierId/report",
	idKey: "_id",
	triggerEvent: ["profileRevision", "transactionUpdateRevision"],
	listenEvent: "profileRevision",
	header: {
		create: { label: "Generate Report" },
	},
	messages: {
		create: { success: "Report is being generated..." },
	},
	content: {
		view: {
			sameAs: "create",
		},
		create: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "type",
								type: "select",
								label: "Type",
								required: true,
								defaultValue: "__NOT_SELECTED__",
								options: [
									{ value: "__NOT_SELECTED__", label: "Not Selected" },
									{
										value: "IFTA",
										label: "Basic IFTA",
										showItems: [
											{
												key: "aggregated_by",
												type: "select",
												label: "Grouping",
												required: true,
												defaultValue: "DELIVERY",
												options: [
													{
														value: "DELIVERY",
														label: "By Delivery Date",
													},
													{
														value: "PICKUP",
														label: "By Pickup Date",
													},
												],
											},
											{
												key: "period",
												type: "select",
												label: "Period",
												required: true,
												defaultValue: "YEARLY",
												options: [
													{
														value: "YEARLY",
														label: "Yearly",
														showItems: [
															{
																key: "yearly_period",
																type: "select",
																label: "Year",
																defaultValue: currentYear,
																options: getYearlyOptions(),
															},
														],
													},
													{
														value: "QUARTERLY",
														label: "Quarterly",
														showItems: [
															{
																key: "quarterly_range",
																type: "select",
																label: "Quarter",
																defaultValue: "__NOT_SELECTED__",
																options: [
																	{ value: "__NOT_SELECTED__", label: "Not Selected" },
																	...getQuarterlyOptions(),
																],
															},
														],
													},
													{
														value: "CUSTOMER",
														label: "Custom",
														showItems: [
															{
																key: "customer_range",
																type: "dateRange",
																label: "Date",
																required: true,
																shortcutPriority: "long",
															},
														],
													},
												],
											},
										],
									},
								],
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default generateReportForm;
