import { useState } from "react";
import { Controller } from "react-hook-form";

import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { withStyles } from "@material-ui/core/styles";
import FuseUtils from "@fuse/utils";

import SmarthopFormView from "../SmarthopFormView";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
		minHeight: "10px",
	},
})(TimelineItem);

function SmarthopPatternField(props) {
	const dataIds = props.dataIds;
	const noInitValidation = props.noInitValidation;
	const onChange = props.onChange;
	const pattern = props.pattern ?? {};
	const addButton = props.addButton;
	const header = props.header;
	const onBlurField = props.onBlur;
	const __setValue = props.__setValue;

	const [showAll, setShowAll] = useState(!!pattern?.showAllDefault);

	const onBlur = (keyName) => {
		if (onBlurField) onBlurField(keyName);
	};

	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field }) => {
				const patternItems = [...(field?.value?.items ?? [])];

				const isValid = (items) => {
					let valid = true;
					items.forEach((item) => {
						if (!item.__valid) {
							valid = false;
						}
					});
					return valid;
				};

				const handleInsertItem = (pos) => {
					let value = { items: [...patternItems] };
					value.items.splice(pos, 0, { __valid: false, __key: FuseUtils.generateGUID() });
					value.valid = isValid(value.items);
					value.length = value.items?.length ?? 0;
					if (field?.value?.valid) {
						field.onChange(value);
					} else {
						__setValue(props.name, value, { shouldValidate: false });
					}
					if (onChange) onChange(value, { action: "ADDED", pos });
					setShowAll(true);
				};

				const handleRemoveItem = (pos) => {
					let value = { items: [...patternItems] };
					// If pattern requires minimun number of items, we would clean existing one but not remove it
					const onlyErase = pattern?.min > 0 && value.items.length === pattern?.min;
					if (onlyErase) {
						value.items[pos] = { __valid: false, __key: value.items[pos].__key };
					} else {
						value.items.splice(pos, 1);
					}
					value.valid = isValid(value.items);
					value.length = value.items?.length ?? 0;

					if (!onlyErase) {
						field.onChange(value);
						if (onChange) onChange(value, { action: "REMOVED", pos });
					} else {
						__setValue(props.name, value, {
							shouldValidate: true,
						});
						if (onChange) onChange(value, { action: "EDIT", pos, key: "ALL", model: {} });
					}
					setShowAll(true);
				};

				const handleMoveUpItem = (pos) => {
					let value = { items: [...patternItems] };
					let topItem = value.items[pos - 1];
					value.items[pos - 1] = value.items[pos];
					value.items[pos] = topItem;
					value.valid = isValid(value.items);
					value.length = value.items?.length ?? 0;
					field.onChange(value);
					if (onChange) onChange(value, { action: "MOVE_UP", pos });
					setShowAll(true);
				};

				const handleMoveDownItem = (pos) => {
					let value = { items: [...patternItems] };
					let topItem = value.items[pos + 1];
					value.items[pos + 1] = value.items[pos];
					value.items[pos] = topItem;
					value.valid = isValid(value.items);
					value.length = value.items?.length ?? 0;
					field.onChange(value);
					if (onChange) onChange(value, { action: "MOVE_DOWN", pos });
					setShowAll(true);
				};

				const handleSubmitModelItem = (pos, model, { valid, errors }, key, options) => {
					let value = { items: [...patternItems] };
					value.items[pos] = { ...patternItems[pos], ...model, __valid: valid };
					value.valid = isValid(value.items);
					value.length = value.items?.length ?? 0;

					if (valid) {
						field.onChange(value);
					} else {
						const hasVisibleErrors = Object.keys(errors?.visible ?? {}).length > 0;
						__setValue(props.name, value, {
							shouldValidate: hasVisibleErrors,
						});
					}

					if (onChange) onChange(value, { action: "EDIT", pos, key, model, options });
					setShowAll(true);
				};

				return (
					<div
						key="top_panel"
						className={" flex flex-col items-start justify-top w-full " + (pattern?.classes?.root ?? "")}
					>
						<div className={"flex flex-col w-full"}>{header?.builder(patternItems)}</div>
						{props.label?.length > 0 && (
							<Typography
								color={"primary"}
								className={"break-all ml:break-words px-6 mt-10 mb-10 tracking-wide " + (pattern?.classes?.label ?? "")}
							>
								{props.label}
							</Typography>
						)}

						<Timeline className="flex w-full p-0 m-0" position="left">
							{patternItems?.map((item, index) => {
								let countHidden = !showAll && patternItems?.length > 2 ? patternItems?.length - 2 : 0;
								if (countHidden > 0 && index > 0 && index < patternItems?.length - 1) {
									if (index === 1) {
										return (
											<StyledTimelineItem key={index} className="flex w-full p-0 m-0 -mb-24">
												<TimelineSeparator>
													<Icon className="text-20 pl-3 mt-2">more_horiz</Icon>
													<TimelineConnector className="mt-2 ml-7 mb-16" />
												</TimelineSeparator>
												<TimelineContent className="flex w-full p-0 m-0 ml-6 mr-10">
													<div className={"flex w-full flex-row px-6 mb-12 mt-8 h-20"}>
														<div className={"flex w-full bg-grey-200 mt-4 mx-10"} style={{ height: "1px" }} />
														<Button
															color="secondary"
															className="font-light text-12 -mt-12 p-2"
															onClick={() => setShowAll(true)}
														>
															View All
														</Button>
														<div className={"flex w-full bg-grey-200 mt-4 mx-10"} style={{ height: "1px" }} />
													</div>
												</TimelineContent>
											</StyledTimelineItem>
										);
									}

									return null;
								}

								return (
									<StyledTimelineItem key={index} className="flex w-full p-0 m-0 ">
										<TimelineSeparator>
											{!pattern.hideIndex && (
												<TimelineDot
													color="primary"
													className={
														(index + 1 > 9 ? "pr-4 pl-3 " : "pr-8 pl-8 ") +
														(pattern.variant === "narrow" ? " mt-6 " : " mt-16 ") +
														" pt-1 pb-1 "
													}
												>
													{index + 1}
												</TimelineDot>
											)}
											{!pattern.hideIndex &&
												props?.content?.items?.length > 1 &&
												patternItems?.length !== index + 1 && <TimelineConnector className="mt-3 -mb-5" />}
											{!pattern.hideIndex &&
												!pattern.disableInsertButtons &&
												!pattern.disableMoveButton &&
												countHidden === 0 &&
												patternItems?.length !== index + 1 && (
													<IconButton
														className="font-light text-12 p-0 text-blue mt-8"
														onClick={() => handleInsertItem(index + 1)}
													>
														<Icon className="text-20">add_circle</Icon>
													</IconButton>
												)}
											{!pattern.hideIndex &&
												!pattern.disableInsertButtons &&
												!pattern.disableMoveButton &&
												countHidden === 0 &&
												props?.content?.items?.length > 1 &&
												patternItems?.length !== index + 1 && <TimelineConnector className="mt-3 -mb-5" />}
										</TimelineSeparator>
										<TimelineContent className="flex w-full p-0 m-0 ml-6">
											<SmarthopFormView
												key={item.__key}
												noInitValidation={noInitValidation}
												nestedKey={props.name}
												nestedIndex={index}
												nestedForm={true}
												content={props.content}
												data={item}
												originalData={props.orignialValue ? props.orignialValue[index] : null}
												dataIds={dataIds}
												mode={"EDIT"}
												trackChangedFields={["ALL"]}
												overrides={props.styleOverrides}
												onChangeCommitted={(model, key, { valid, errors }, options) => {
													handleSubmitModelItem(index, model, { valid, errors }, key, options);
												}}
												onBlurField={onBlur}
											/>
										</TimelineContent>
										<TimelineSeparator className={"px-4" + (pattern.variant !== "narrow" ? " pt-5 " : " pt-12 ")}>
											{patternItems?.length > 1 && pattern.variant !== "narrow" && (
												<Tooltip title="Move up">
													<IconButton
														className="font-light text-12 w-14 h-12"
														disabled={index === 0}
														size={"small"}
														onClick={() => handleMoveUpItem(index)}
													>
														<Icon className={"text-28 " + (index > 0 ? " text-primary " : " text-grey ")}>
															arrow_drop_up
														</Icon>
													</IconButton>
												</Tooltip>
											)}
											{patternItems?.length > 1 && pattern.variant !== "narrow" && (
												<Tooltip title="Move down">
													<IconButton
														className="font-light text-12 w-14 h-12 mb-5"
														disabled={index >= patternItems?.length - 1}
														size={"small"}
														onClick={() => handleMoveDownItem(index)}
													>
														<Icon
															className={
																"text-28 " + (index < patternItems?.length - 1 ? " text-primary " : " text-grey ")
															}
														>
															arrow_drop_down
														</Icon>
													</IconButton>
												</Tooltip>
											)}
											{!pattern.disablDeleteButton && (
												<Tooltip title="Delete">
													<IconButton
														className={
															"font-light text-12 w-14 h-14" +
															(patternItems?.length > 1 || pattern.variant === "narrow" ? "" : " mt-16 ")
														}
														onClick={() => handleRemoveItem(index)}
														size={"small"}
													>
														<Icon className={"text-18 text-red"}>clear</Icon>
													</IconButton>
												</Tooltip>
											)}
											<div className={"flex h-full"} />
										</TimelineSeparator>
									</StyledTimelineItem>
								);
							})}
						</Timeline>

						{!pattern?.disablAddButton && (
							<div className={"flex w-full flex-row px-6 mb-12 mt-8 h-20 " + (addButton?.classes?.root ?? "")}>
								{!addButton?.noSeparators && (
									<div className={"flex flex-1 bg-grey-200 mt-5 mx-10"} style={{ height: "1px" }} />
								)}
								<Button
									color="secondary"
									variant="outlined"
									className="font-light text-12 -mt-6 py-2 px-14 whitespace-nowrap"
									onClick={() => handleInsertItem(patternItems?.length ?? 0)}
								>
									{addButton?.label ?? "Add"}
								</Button>
								{!addButton?.noSeparators && (
									<div className={"flex flex-1 bg-grey-200 mt-5 mx-10"} style={{ height: "1px" }} />
								)}
							</div>
						)}
						{props.errors?.[props.name]?.items?.message && (
							<Typography variant="caption" color="error">
								{!props.disabled ? props.errors[props.name]?.items?.message : ""}
							</Typography>
						)}
					</div>
				);
			}}
		/>
	);
}

export default SmarthopPatternField;
