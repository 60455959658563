import { useState, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Icon, IconButton, Button, Typography } from "@material-ui/core";

// Utils
import SmartHopMap from "app/main/profile/trips/SmartHopMap";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import createForm from "./config/createCalculatorForm";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";
import { getDistanceMaps, getRPM } from "app/main/utils/calculatorUtils";

//Services
import { getEstimatedPriceAndMarketIndexes } from "app/services/calculatorServices";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import CostsBreakdownTooltip from "../profile/cost-structure/calculator/CostBreakdownTooltip";
import { calculateProfit } from "../profile/cost-structure/calculator/CostStructureCalculatorSettings";
import { createTooltip } from "../utils/tableUtils";

const keysAffectEmpty = ["truck_origin", "origin"];
const keysAffectLoaded = ["origin", "destination", "locations"];
const keysAffectRPM = ["truck", "origin", "destination", "truck_origin", "price", "locations"];
const keysAffectProfit = ["price", "origin", "destination", "truck", "ready", "dropready", "truck_origin", "locations"];
const keysAffectEstRate = ["truck", "truck_origin", "origin", "destination", "locations"];
const keysAffectSmartpay = ["broker"];

const CalculatorLoadView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Profit Calculator");
		props?.setSize?.("max-w-2xl");
		// eslint-disable-next-line
	}, []);
	const dispatch = useDispatch();
	const dataIds = props.dataIds;
	const nativeMobile = props.nativeMobile;

	const user = useSelector(({ auth }) => auth.user);
	const viewerInternal = user.roleType === "INTERNAL";

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const dirty = useRef({});

	const [initialized, setInitialized] = useState(false);
	const [data, setData] = useState(null);
	const [loadedMiles, setLoadedMiles] = useState(-1);
	const [emptyMiles, setEmptyMiles] = useState(-1);
	const [rpm, setRpm] = useState(-1);
	const [estRate, setEstRate] = useState(null);
	const [profit, setProfit] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [revision, setRevision] = useState(0);
	const [created, setCreated] = useState(false);
	const [clear, setClear] = useState(false);
	// const [smartpayEligible, setSmartpayEligible] = useState(null);

	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const formContent = useMemo(() => {
		const carrierId = data?.carrier ?? dataIds?.carrierId;
		return createForm(viewerInternal || hasSubaccounts, carrierId, user.role, nativeMobile, user?.hasSmartPayProgram);
		// eslint-disable-next-line
	}, [viewerInternal, data?.carrier, dataIds?.carrierId, data?.broker__view, data?.price, hasSubaccounts]);

	useEffect(() => {
		const cacheCalculator = JSON.parse(localStorage.getItem("calculatorCache"));
		setInitialized(true);
		if (!cacheCalculator) return;
		setData(cacheCalculator);
		setRpm(cacheCalculator?.rpm);
		setEmptyMiles(cacheCalculator?.emptyMiles);
		setLoadedMiles(cacheCalculator?.loadedMiles);
		setProfit(cacheCalculator?.profit);
		setEstRate(cacheCalculator?.estRate);
	}, []);

	useEffect(() => {
		const cacheData = { ...data, emptyMiles, rpm, estRate, profit, loadedMiles };
		localStorage.setItem("calculatorCache", JSON.stringify(cacheData));
		// eslint-disable-next-line
	}, [data]);

	const mapLocations = useMemo(() => {
		// to clean map
		if (clear) return [];
		if (loadedMiles === -1 || emptyMiles === -1) {
			return null;
		}

		const locations = [];

		if (data?.truck_origin__view?.metadata?.coords) {
			locations.push({
				type: "EMPTY",
				coordinates: data?.truck_origin__view?.metadata?.coords,
				location: data?.truck_origin,
			});
		}
		if (data?.origin__view?.metadata?.coords) {
			locations.push({
				type: "PICKUP",
				coordinates: data?.origin__view?.metadata?.coords,
				location: data?.origin,
			});
		}
		data?.locations?.forEach((item) => {
			if (item?.location__view?.metadata?.coords) {
				locations.push({
					coordinates: item?.location__view?.metadata?.coords,
					location: item?.location,
				});
			}
		});
		if (data?.destination__view?.metadata?.coords) {
			locations.push({
				type: "DELIVERY",
				coordinates: data?.destination__view?.metadata?.coords,
				location: data?.destination,
			});
		}

		return locations;
		// eslint-disable-next-line
	}, [loadedMiles, emptyMiles, data?.truck_origin, data?.origin, data?.destination, data?.locations]);

	useEffect(() => {
		if (dirty.current.emptyMiles || dirty.current.loadedMiles) {
			return;
		}
		if (
			loadedMiles === -1 ||
			emptyMiles === -1 ||
			!data?.truck ||
			!data?.truck_origin ||
			!data?.origin ||
			!data?.destination
		) {
			setEstRate(null);
			return;
		}

		let cleanup = false;
		(async () => {
			try {
				setProcessing(true);
				const result = await getEstimatedPriceAndMarketIndexes({
					truck_origin: data?.truck_origin__view?.metadata,
					origin: data?.origin__view?.metadata,
					destination: data?.destination__view?.metadata,
					miles: loadedMiles,
					distance_to_origin: emptyMiles,
					carrier: data?.truck__view?.metadata?.carrier,
					equipment: data?.truck__view?.metadata?.equipment,
					truck: data?.truck,
				});

				if (cleanup) return;
				const avg = result?.estimated?.est_price ? result?.estimated?.est_price?.toFixed(0) : null;
				const min = result?.estimated?.min_price ? result?.estimated?.min_price?.toFixed(0) : null;
				const max = result?.estimated?.max_price ? result?.estimated?.max_price?.toFixed(0) : null;
				setEstRate({ avg, min, max, error: null });
				setProcessing(false);
			} catch (e) {
				const message = e?.errors?.[0]?.message ?? e?.message;
				console.error("[CalculatorLoadView] failed to process rate =>", message);
				setEstRate({ avg: -1, min: -1, max: -1, error: "Failed to estimate" });
				setProcessing(false);
			}
			dirty.current.estRate = false;
		})();

		return () => {
			cleanup = true;
		};

		// eslint-disable-next-line
	}, [revision, loadedMiles, emptyMiles, data?.truck, data?.truck_origin, data?.origin, data?.destination]);

	useEffect(() => {
		if (!data?.origin || !data?.destination) {
			setLoadedMiles(-1);
			return;
		}

		let cleanup = false;
		(async () => {
			try {
				const res = await getDistanceMaps(
					data.origin,
					data.destination,
					data.locations?.map((item) => item.location)
				);
				if (cleanup) return;
				setLoadedMiles(res?.totalMiles);
			} catch (e) {
				setLoadedMiles(-1);
			}
			dirty.current.loadedMiles = false;
		})();

		return () => {
			cleanup = true;
		};
	}, [data?.origin, data?.destination, data?.locations]);

	useEffect(() => {
		if (!data?.truck_origin || !data?.origin) {
			setEmptyMiles(-1);
			return;
		}

		let cleanup = false;
		(async () => {
			try {
				const res = await getDistanceMaps(data.truck_origin, data.origin);
				if (cleanup) return;
				setEmptyMiles(res?.totalMiles);
			} catch (e) {
				setEmptyMiles(-1);
			}
			dirty.current.emptyMiles = false;
		})();

		return () => {
			cleanup = true;
		};
	}, [data?.truck_origin, data?.origin]);

	useEffect(() => {
		if (dirty.current.emptyMiles || dirty.current.loadedMiles) {
			return;
		}
		if (!data?.price || emptyMiles === -1 || loadedMiles === -1) {
			setRpm(-1);
			return;
		}

		const rpm = getRPM(data.price, emptyMiles, loadedMiles);
		setRpm(rpm);
	}, [data?.price, loadedMiles, emptyMiles]);

	useEffect(
		() => {
			if (!data?.origin || !data?.destination || !data?.price) {
				if (profit) setProfit(null);
				return null;
			}

			const truckMetadata = data?.truck__view?.metadata;
			const costStructure = truckMetadata?.cost_structure__view?.cost_structure;
			const primaryDriver = {
				...(truckMetadata?.driver__view ?? {}),
				...(truckMetadata?.driver__view?.metadata ?? {}),
			};
			const secondaryDriver = truckMetadata?.driver_secondary__view?.value
				? {
						...(truckMetadata?.driver_secondary__view ?? {}),
						...(truckMetadata?.driver_secondary__view?.metadata ?? {}),
				  }
				: false;

			const load = {
				miles: loadedMiles,
				emptymiles: emptyMiles,
				stops: data?.locations,
				delivery_date: data?.dropready,
				pickup_date: data?.ready,
				rate: data?.price,
			};

			const allData = {
				load,
				costStructure,
				driver: primaryDriver,
				secondaryDriver,
				truck: truckMetadata,
			};

			const response = calculateProfit(allData);

			setProfit({
				...response,
				allData,
				value: response?.profit ?? null,
				duration: response?.duration ?? null,
			});
		},
		//eslint-disable-next-line
		[
			data?.truck__view,
			loadedMiles,
			emptyMiles,
			data?.locations,
			data?.dropready,
			data?.ready,
			data?.price,
			data?.origin,
			data?.destination,
		]
	);

	const onChangeCommitted = async (model, key) => {
		if (key === "truck") {
			const truckMetadata = model?.truck__view?.metadata;
			model.truck_origin = truckMetadata?.current_location;
			model.truck_origin__view = {
				label: truckMetadata?.current_location,
				value: truckMetadata?.current_location,
				metadata: { coords: truckMetadata?.plan_view?.metadata?.current_location_coords },
			};

			if (model.truck_origin !== data?.truck_origin) {
				dirty.current.emptyMiles = true;
			}
		}

		if (keysAffectEmpty.includes(key)) {
			dirty.current.emptyMiles = true;
		}
		if (keysAffectLoaded.includes(key)) {
			dirty.current.loadedMiles = true;
		}
		if (keysAffectRPM.includes(key)) {
			dirty.current.rpm = true;
		}
		if (keysAffectProfit.includes(key)) {
			dirty.current.profit = true;
		}
		if (keysAffectEstRate.includes(key)) {
			dirty.current.estRate = true;
		}
		if (keysAffectSmartpay.includes(key)) {
			dirty.current.smartpayEligible = true;
		}

		setData(model);
		if (clear) setClear(false);
	};

	const renderResultItem = (label, value, { prefix, postfix, loading, onRetry, color } = {}) => {
		const textColor = color === "green" ? "text-green-700" : color === "red" ? "text-red-700" : "text-grey-700";
		return (
			<div className={"flex flex-row items-center border-b-1 border-grey-300 pb-2 pt-14 "}>
				<Typography className={`flex text-black text-13 break-normal`}>{label}</Typography>
				<div className={"flex flex-1 justify-end items-center " + (loading ? " opacity-40 " : "")}>
					{loading && <CircularProgress size={12} className="mr-6" />}
					<Typography className={"flex text-13 break-normal " + textColor}>
						{!value ? "" : prefix}
						{!value ? "-" : value}
						{!value ? "" : postfix}
					</Typography>
				</div>
				{onRetry && (
					<IconButton size="small" onClick={onRetry}>
						<Icon className={"text-16 md:text-13 " + textColor}>refresh</Icon>
					</IconButton>
				)}
			</div>
		);
	};

	const getCarrierId = () => data?.truck__view?.metadata?.carrier;
	const getEditableTripData = (type) => {
		return {
			truck: data?.truck,
			truck__view: data?.truck__view,
			carrier: data?.truck__view?.metadata?.carrier,
			carrier__view: data?.truck__view?.metadata?.carrier__view,
			driver: data?.truck__view?.metadata?.driver,
			driver__view: data?.truck__view?.metadata?.driver__view,
			driver_secondary: data?.truck__view?.metadata?.driver_secondary,
			driver_secondary__view: data?.truck__view?.metadata?.driver_secondary__view,
			truck_trailer: data?.truck__view?.metadata?.truck_trailer,
			truck_trailer__view: data?.truck__view?.metadata?.truck_trailer__view,
			locations: data?.locations,
			...(type === "QUICK"
				? {
						price: data?.price,
						pickup_date: data?.ready,
						origin: data?.origin,
						origin__view: data?.origin__view,
						delivery_date: data?.dropready,
						destination: data?.destination,
						destination__view: data?.destination__view,
						distance_to_origin: emptyMiles,
						emptymiles: emptyMiles,
				  }
				: {
						rate: data?.price,
						current_location: data?.truck_origin,
						current_location__view: data?.truck_origin__view,
						pickup_date: data?.ready,
						pickup_address: data?.origin,
						pickup_address__view: data?.origin__view,
						delivery_date: data?.dropready,
						delivery_address: data?.destination,
						delivery_address__view: data?.destination__view,
				  }),
			rpm: rpm !== -1 ? rpm : undefined,
			emptymiles: emptyMiles !== -1 ? emptyMiles : undefined,
			miles: loadedMiles !== -1 ? loadedMiles : undefined,
			equipment: data?.truck__view?.metadata?.equipment,
			status: "Pending",
			status__view: { value: "Pending", label: "Not Booked", description: "Pending" },
			broker__view: data?.broker__view,
			mcnumber: data?.broker__view?.metadata?.mcnumber,
			broker_contact: data?.broker__view?.metadata?.broker_contact,
			mail_broker: data?.broker__view?.metadata?.mail_broker,
			phone_broker: data?.broker__view?.metadata?.phone_broker,
			customerId: data?.broker__view?.metadata?.customerId,
			send_broker_dispatched: data?.truck__view?.metadata?.carrier__view?.metadata?.notificationDispatched,
			send_broker_at_pick_up: data?.truck__view?.metadata?.carrier__view?.metadata?.notificationAtPickup,
			send_broker_at_delivery: data?.truck__view?.metadata?.carrier__view?.metadata?.notificationAtDelivery,
		};
	};

	const cleanCalculator = () => {
		const data = { broker__view: null, carrier__view: null };
		setData(data);
		setLoadedMiles(-1);
		setEmptyMiles(-1);
		setRpm(-1);
		setEstRate(null);
		setProfit(null);
		if (mapLocations?.length > 0) setClear(true);
		localStorage.removeItem("calculatorCache");
	};

	const onCreateNewTrip = async () => {
		dispatch(
			openFormDialog({
				viewId: "TRIP_EDIT_VIEW",
				mode: "CREATE",
				dataIds: {
					carrierId: getCarrierId(),
					isExternal: false,
					rcDataEditable: getEditableTripData(),
					mode: "CREATE",
					DANGEROUS_onDone: (data) => {
						if (data) setCreated(true);
					},
				},
			})
		);
	};
	const formatResults = (component1, component2) => {
		return (
			<div className="md:flex flex-row w-full md:space-x-36">
				<div className="md:w-1/2">{component1}</div>
				<div className="md:w-1/2">{component2}</div>
			</div>
		);
	};

	if (!initialized) return null;
	const hasTotalMiles = loadedMiles >= 0 && emptyMiles >= 0;
	return (
		<div className={"flex flex-col w-full " + (nativeMobile ? " px-14 pb-32 pt-10 " : " px-4 md:pb-0 ")}>
			{created && (
				<div className="flex flex-row bg-green py-4 px-8 mb-6 rounded-lg items-center">
					<Icon className="text-white text-18 mr-4">check_circle</Icon>
					<Typography className={`flex text-white text-12 ml:text-13 break-normal`}>
						Trip has been successfully saved
					</Typography>
				</div>
			)}
			<div className="flex flex-row">
				<div className="flex flex-col w-full md:w-2/3">
					<SmarthopFormView
						mode={"CREATE"}
						data={data}
						content={formContent}
						noInitValidation={true}
						dataIds={dataIds}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model, key) => onChangeCommitted(model, key)}
					>
						{props.children}
					</SmarthopFormView>
					<div className="flex flex-col px-6 py-20">
						{formatResults(
							renderResultItem(
								"Est. Rate",
								estRate?.error
									? estRate?.error
									: estRate?.min > 0 && estRate?.max > 0
									? `$${estRate?.min} - $${estRate?.max}`
									: estRate?.avg > 0
									? `$${estRate?.avg}`
									: null,
								{
									color: !!estRate?.error ? "red" : null,
									loading: processing,
									onRetry: !estRate?.error ? null : () => setRevision(revision + 1),
								}
							),
							renderResultItem(
								"Profit",
								profit?.value >= 0 || profit?.value < 0
									? createTooltip(
											<div className="flex flex-row">
												<Typography className="text-13 font-medium tracking-wider">{profit?.value}</Typography>
												<Icon className="text-15 md:text-13 ml-4 mt-3">info</Icon>
											</div>,
											<CostsBreakdownTooltip data={profit.allData} profitResults={profit} />
									  )
									: null,
								{
									prefix: "$",
									color: profit?.value > 0 ? "green" : profit?.value < 0 ? "red" : null,
								}
							)
						)}

						{formatResults(
							renderResultItem("RPM", rpm >= 0 ? rpm : null, { prefix: "$" }),
							renderResultItem(
								"Miles ",
								(emptyMiles >= 0 ? emptyMiles + " (DH)" : "") +
									(hasTotalMiles ? " + " : "") +
									(loadedMiles >= 0 ? loadedMiles + " (Loaded)" : "") +
									(hasTotalMiles ? " = " + (emptyMiles + loadedMiles) : ""),
								{ postfix: hasTotalMiles ? " mile(s)" : null }
							)
						)}
						{formatResults(
							renderResultItem("Duration", profit?.duration >= 0 ? profit?.duration : null, { postfix: " day(s)" })
						)}
					</div>
				</div>
				<div className="flex flex-col w-0 pl-10 pt-4 pb-4 md:w-1/3">
					<SmartHopMap
						provider={"TRIMBLE MAPS"}
						classes={{ root: "w-0 md:w-full h-full" }}
						locations={mapLocations}
						zoom={4}
						map="myMap"
					/>
				</div>
			</div>

			<div className="flex flex-col md:flex-row w-full mt-6 border-t-1 pt-14 pb-8">
				{hasEditTripsPermission && !nativeMobile && (
					<Button
						className={"flex-1 md:flex-none bg-blue-500 text-white text-12 ml:text-13 hover:bg-blue-200 my-8 md:my-0"}
						size={"large"}
						aria-label="Quick Trip"
						onClick={() => onCreateNewTrip()}
						color={"primary"}
						variant={"contained"}
						startIcon={<Icon>add_circle_outline</Icon>}
						disabled={!data?.truck || created}
					>
						Add Trip
					</Button>
				)}
				<div className="ml-auto" />
				<Button
					className={"flex-1 md:flex-none bg-red-500 text-white text-12 ml:text-13 hover:bg-red-200"}
					size={"large"}
					aria-label="Clear"
					onClick={() => cleanCalculator()}
					color={"primary"}
					variant={"contained"}
					startIcon={<Icon>remove_circle</Icon>}
				>
					Clear
				</Button>
			</div>
		</div>
	);
};

export default CalculatorLoadView;
