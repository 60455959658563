import { useEffect, useState, useMemo } from "react";
import { SmarthopErrorView, SmarthopList } from "@smarthop/list";
import { global } from "app/services/requestUtil";
import axios from "axios";

import { readURLParameters, convertURLParamsToModel } from "../utils/urlUtils";
import { setAuthToken, setUserId } from "app/services/LoginService";
import { useDispatch, useSelector } from "react-redux";

import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { set } from "app/services/featureService";
import { setUser } from "app/store/auth/userSlice";

import MobileDetect from "mobile-detect";
import { buildList } from "@smarthop/list/registy";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const NO_AUTH_REQUIRED = [
	"PRIVACY_POLICY_VIEW",
	"SMARTHOP_TERMS_VIEW",
	"SMS_TERMS_VIEW",
	"REGISTRATION_VIEW",
	"PASSWORD_RECOVERY_VIEW",
];

function NativeTable() {
	const dispatch = useDispatch();
	const [dataIds] = useState(convertURLParamsToModel(readURLParameters(), {}, { notJson: true }));
	const mode = dataIds.mode ?? "VIEW";
	const tableId = dataIds?.tableId;
	const user = useSelector(({ auth: { user } }) => user);

	const formatString = (value) => {
		return value && value !== "null" && value !== "undefined" ? value : null;
	};

	// Saving user related data to local storage, some forms rely on that data
	if (dataIds.token) localStorage.setItem("tokenSmarthop", dataIds.token);
	if (dataIds.userId) localStorage.setItem("userId", formatString(dataIds.userId));
	if (dataIds.carrierId) localStorage.setItem("carrierId", formatString(dataIds.carrierId));
	if (dataIds.driverId) localStorage.setItem("driverId", formatString(dataIds.driverId));
	if (dataIds.isOnboarding) localStorage.setItem("isOnboarding", dataIds.isOnboarding);
	if (dataIds.accountStatus) localStorage.setItem("accountStatus", formatString(dataIds.accountStatus));
	if (dataIds.onboardingStatus) localStorage.setItem("onboardingStatus", formatString(dataIds.onboardingStatus));
	if (dataIds.profileId) localStorage.setItem("profileId", dataIds.profileId);
	if (dataIds.username)
		localStorage.setItem("username", formatString(dataIds.username) ?? formatString(dataIds.userEmail));
	if (dataIds.profilename)
		localStorage.setItem(
			"firstName",
			formatString(dataIds.profilename) ??
				(formatString(dataIds.first_name) ?? "") + " " + (formatString(dataIds.last_name) ?? "")?.trim()
		);
	if (dataIds.phone) localStorage.setItem("phone", formatString(dataIds.userPhone) ?? "");
	// WORKAROUND: for backward compatibility consider every user who is using native
	// mobile app as carrier owner to show proper UI
	if (dataIds.roleUser) localStorage.setItem("roleUser", formatString(dataIds.roleUser) ?? "2");
	if (dataIds.carrierDisabledBooking) {
		localStorage.setItem("disabledDigitalBooking", formatString(dataIds.carrierDisabledBooking));
	}
	if (dataIds.userTier) localStorage.setItem("user_tier", formatString(dataIds.userTier));

	// Set principal permission
	const gatekeepers = {
		permission_rates_on: dataIds.permission_rates_on === "true",
		permission_messaging_on: dataIds.permission_messaging_on === "true",
		permission_search_on: dataIds.permission_search_on === "true",
		permission_book_on: dataIds.permission_book_on === "true",
	};
	localStorage.setItem("gatekeepers", JSON.stringify(gatekeepers));

	const features = useMemo(() => {
		const res = {};
		Object.keys(dataIds)
			.filter((k) => k.includes("feature_"))
			.forEach((f) => {
				res[f.replace("feature_", "")] = dataIds[f] === "true";
			});
		localStorage.setItem("features", JSON.stringify(res));
		localStorage.setItem("nativeMobile", true);

		return res;
	}, [dataIds]);

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "INCREMENT_ALL_REVISIONS":
					dispatch(incrementDataRevision({ event: "onboardingRevision" }));
					dispatch(incrementDataRevision({ event: "paymentRevision" }));
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					return;
				case "INCREMENT_REVISION":
					if (dataParsed.event) {
						dispatch(incrementDataRevision({ event: dataParsed.event }));
					}
					return;
				default:
					break;
			}
		} catch (e) {}
	};

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);
		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	});

	useEffect(() => {
		dispatch(
			setUser({
				_id: formatString(dataIds.userId),
				userId: formatString(dataIds.userId),
				carrierId: formatString(dataIds.carrierId),
				driverId: formatString(dataIds.driverId),

				email: formatString(dataIds.username) ?? formatString(dataIds.userEmail) ?? "",
				phone: formatString(dataIds.userPhone) ?? "",

				isOnboarding: formatString(dataIds.isOnboarding),
				accountStatus: formatString(dataIds.accountStatus),
				onboardingStatus: formatString(dataIds.onboardingStatus),
				// TODO improve way name is passed to native mobile
				first_name: formatString(dataIds.firstName),
				last_name: formatString(dataIds.lastName),
				// DEPRECATED use role and roleType instead
				roles: [parseInt(dataIds.roleUser) >= 0 ? parseInt(dataIds.roleUser) : 2],
				rolesLabels: [dataIds.role],

				role: formatString(dataIds.role),
				roleType: formatString(dataIds.roleType),

				disable_digital_booking: !!formatString(dataIds.carrierDisabledBooking),
				gatekeepers: gatekeepers,
				nativeMobile: window?.location?.href?.includes("/native/"),
			})
		);
		// eslint-disable-next-line
	}, []);

	const handleDone = (data) => {
		console.log(`[NativeTable] handleDone: data => ${JSON.stringify(data)}`);
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "DONE", data: data ?? {} }));
	};

	const handleLoading = (inProgress, error) => {
		console.log(`[NativeTable] handleLoading: inProgress => ${inProgress}`);
		window?.ReactNativeWebView?.postMessage(
			JSON.stringify({ type: "LOADING", status: inProgress ? "STARTED" : "FINISHED", error })
		);
	};

	const handleProcessing = (inProgress, error) => {
		console.log(`[NativeTable] handleProcessing: inProgress => ${inProgress}`);
		window?.ReactNativeWebView?.postMessage(
			JSON.stringify({ type: "PROCESSING", status: inProgress ? "STARTED" : "FINISHED", error })
		);
	};

	useEffect(() => {
		set(features);
	}, [features]);

	useEffect(() => {
		if (NO_AUTH_REQUIRED.includes(tableId)) {
			// No auth required
			console.log(`[NativeTable] auth is not required for ${tableId}`);
		} else {
			if (!dataIds?.token || !dataIds?.userId) {
				setError("Token is misisng...");
				handleLoading(false);
				return;
			}
			if (!dataIds?.userId) {
				setError("User Id is misisng...");
				handleLoading(false);
				return;
			}

			setAuthToken(dataIds.token);
			setUserId(dataIds.userId);
		}

		if (tableId) {
			const list = tableId ? buildList(tableId, { user }) : undefined;
			if (!list) {
				setError(`List not found for ${tableId}...`);
				handleLoading(false, "Invalid form Id...");
				return;
			}

			let queryMissingParams = "";
			list?.urlGET?.split("/").forEach((section) => {
				if (section.startsWith(":") && (!dataIds || !dataIds[section.substring(1)])) {
					if (queryMissingParams) queryMissingParams += ",";
					queryMissingParams += section.substring(1);
				}
			});

			if (queryMissingParams) {
				let message = `Params ${queryMissingParams} for GET url ${list?.urlGET} not provided...`;
				setError(message);
				handleLoading(false, message);
				return;
			}

			let urlGET = list?.urlGET;
			Object.keys(dataIds).forEach((key) => {
				urlGET = urlGET?.replace(":" + key, dataIds[key]);
			});

			let headers = {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
			};

			axios
				.create({ baseURL: global.SERVER_NAME, headers: headers })
				.get(urlGET, { headers: headers, dataIds })
				.then((res) => {
					setData(res.data);
					handleLoading(false);
				})
				.catch((error) => {
					let response = error?.response;
					let message =
						response?.data?.errors?.[0]?.message ??
						(error?.message ? "Unexpected Error: " + error?.message : "Something went wrong, please try again later");
					setError(message);
					handleLoading(false, message);
				});
		} else {
			setError("Missing form Id, list Id or view Id...");
			return;
		}

		// eslint-disable-next-line
	}, [dataIds?.token, dataIds?.userId]);

	if (error) {
		return <SmarthopErrorView message={error} />;
	} else if (!data) {
		return null;
	}
	if (tableId) {
		const list = buildList(tableId, { user });
		return (
			<div className="flex w-full items-center justify-center mt-10 mb-28 px-4">
				<SmarthopList
					nativeMobile={true}
					data={data}
					config={list}
					dataIds={dataIds}
					mode={mode}
					onLoading={handleLoading}
					onProcessing={handleProcessing}
					onDone={handleDone}
				/>
			</div>
		);
	} else {
		handleLoading(false);
		return <SmarthopErrorView message="Missing form or view Id..." />;
	}
}

export default NativeTable;
