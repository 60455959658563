// Services
import { getTripLocationsMiles, parseTripDocument } from "app/services/tripsServices";
import { uploadRCFile } from "app/services/fileServices";
import { getUserTier } from "app/services/LoginService";
import { processTierRestrictions } from "app/main/utils/rolesUtils";

// Function to update the tips miles locations and empty miles
const handledLocationsUpdated = async (model, data) => {
	const locations = {
		empty: model.current_location?.length > 0 ? model.current_location : null,
		pickup: model.pickup_address?.length > 0 ? model.pickup_address : null,
		stops: model.locations?.map?.((item) => item.location ?? null) ?? [],
		delivery: model.delivery_address?.length > 0 ? model.delivery_address : null,
	};

	model?.all_locations?.unshift?.({ location: model.current_location, type: "EMPTY" });

	if (!locations.empty || !locations.pickup || locations.stops.includes(null) || !locations.delivery) {
		console.log("[TripView] Trip locations changed, but some fields are empty, can't recalculate...");
		return;
	}

	let info;
	await getTripLocationsMiles(locations, null, data?.truck, data?.carrier, model.weight, null).then(
		(calulatedMiles) => {
			let newData = { ...model } ?? {};
			newData.emptymiles = calulatedMiles.empty;
			newData.miles = newData.tonu ? 1 : calulatedMiles.loaded;
			newData.total_miles = calulatedMiles.empty + newData.miles;

			let tonu = (Number(newData.rate) / (Number(newData.miles) + Number(newData.emptymiles ?? 0))).toFixed(2);
			newData.rpm = newData.tonu ? 0 : tonu;

			info = newData;
			return info;
		},
		(err) => {
			console.log(err?.errors?.[0]?.message ?? err.message ?? "Failed to racalcualte miles...");
			return;
		}
	);

	return info;
};

const handleFileUpload = async (carrierId, trip, files, hasFileId = null) => {
	let fileId = hasFileId;
	if (!hasFileId) {
		const file = await uploadRCFile({ carrierId, file: files[0] });
		fileId = file.fileId;
	}

	let result = { ...trip };

	const userTier = await getUserTier();
	if (
		trip?.broker__view?.metadata?.automated_parsing_supported &&
		fileId &&
		processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier })
	) {
		await parseTripDocument(carrierId, fileId, { trip: { ...trip } }).then(
			(parsedData) => {
				let newData = { ...trip } ?? {};
				Object.keys(parsedData).forEach((key) => {
					if (JSON.stringify(parsedData[key] ?? "") !== JSON.stringify(newData[key] ?? "")) {
						newData[key] = parsedData[key];
					}
				});
				result = newData;
				return;
			},
			(error) => {
				result = error;

				return;
			}
		);
	}

	result.rate_con_file = [fileId];
	result.rate_con_file__flag = true;
	return result;
};

export { handleFileUpload, handledLocationsUpdated };
