const tripCommentsList = (nativeMobile = false) => {
	return {
		urlGET: "api/comments/trips/:tripId",
		idKey: "_id",
		segmentKey: "COMMENTS_LIST",
		listenEvent: "profileRevision",
		triggerEvent: "profileRevision",
		cache: { disableInit: true },
		content: {
			pagination: {
				enabled: true,
				resetScroll: true,
				defaut: {
					size: 20,
				},
			},
			table: {
				variant: "skinny",
				orderBy: ["createdAt", "user__view"],
				fixHeight: 0.5,
			},
			grid: {
				variant: "skinny",
				tilesPerRow: 1,
				itemsPerRow: 1,
			},
			order: { defaut: { key: "createdAt", dir: "desc" } },
			items: [
				{
					key: "createdAt",
					type: "date",
					label: "Created",
					table: { width: "8%" },
				},
				{
					key: "user__view",
					type: "user__view",
					label: "User",
					viewConfig: { showEmail: true, showRole: true, disableLink: nativeMobile },
					grid: { headerTitle: true },
					table: { width: "30%" },
				},

				{
					key: "content",
					type: "text",
					label: "Comment",
					required: true,
					table: { align: "right", width: "30%" },
				},
				{
					key: "menu",
					type: "menu",
					label: "",
					table: { width: "0%" },
					items: [
						{
							key: "preview_message",
							label: "View Content",
							isVisible: (item) => !!item.extraContent,
							dialogBuilder: (item) => ({
								formId: "COMMENT_VIEW_CONTENT",
								dataIds: { commentId: item?._id },
								mode: "VIEW",
							}),
						},
					],
				},
			],
		},
	};
};

export default tripCommentsList;
