import { createContext, useReducer, memo, useContext, forwardRef } from "react";
import { strategyInitialState, strategyReducer } from "./reducer/strategyReducer";

export const StrategyStateContext = createContext();
export const StrategyActionContext = createContext();

function StrategyContextProvider(props) {
	const [state, dispatch] = useReducer(strategyReducer, {
		...strategyInitialState,
		...props,
	});
	return (
		<StrategyStateContext.Provider value={state}>
			<StrategyActionContext.Provider value={dispatch}>{props.children}</StrategyActionContext.Provider>
		</StrategyStateContext.Provider>
	);
}

export const strategyConnect = (mapStateToProps, mapDispatchToProps) => (Component) => {
	const MemoizedComponent = memo(Component);
	const StateWrapper = forwardRef((ownProps, ref) => {
		const state = useContext(StrategyStateContext);
		const dispatch = useContext(StrategyActionContext);

		const mappedState = mapStateToProps?.(state, ownProps) ?? {};
		const mappedDispatch = mapDispatchToProps?.(dispatch, ownProps, state) ?? {};
		return <MemoizedComponent {...ownProps} {...mappedState} {...mappedDispatch} ref={ref} />;
	});
	return StateWrapper;
};

export default StrategyContextProvider;
