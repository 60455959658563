import { Typography } from "@material-ui/core";

// Utilsd
import { createTooltip } from "app/main/utils/tableUtils";
import { getFlexibleDescription, LOAD_EQUIPMENTS } from "app/main/search/searchUtils";

const _FLEXIBLE_DESCRIPTION = (item) => getFlexibleDescription(item);
const _HAZMAT_DESCRIPTION = "This load is hazmat, please be sure you meet all the requirements before taking this load";
const _TEAM_DESCRIPTION = "Team load";
const _TRAILER_DESCRIPTION = (item) => `The load length is ${item.trailerSize}`;
const _SPECIAL_DESCRIPTION = (item) => LOAD_EQUIPMENTS[item.special] ?? item.special ?? "Special equipment";

function FeatureComponent(props) {
	const item = props.item;
	const smallScreen = props.smallScreen;

	// Feature List
	let features = [];
	let extraFeatures = 0;
	const flexible = item.flexible;
	const hazmat = item.hazmat;
	const team = item.team;
	const trailer = item.trailerSize && item.trailerSize !== 0 && item.trailerSize !== 1;
	const special = item.special && !item.team && !item.hazmat;

	const FlexibleView = (
		<div
			className={
				"flex items-center justify-center h-16 min-w-16 rounded-4 px-2 mr-3 border-1 border-orange text-orange"
			}
		>
			<Typography className={"font-500 p-2 text-orange text-11"}>{"F"}</Typography>
		</div>
	);
	const HazmatView = (
		<div className={"flex items-center justify-center h-16 min-w-16 rounded-4 px-2 mr-3 border-1 border-red text-red"}>
			<Typography className={"font-500 p-2 text-red text-11"}>{"H"}</Typography>
		</div>
	);
	const TeamView = (
		<div
			className={"flex items-center justify-center h-16 min-w-16 rounded-4 px-2 mr-3 border-1 border-cyan text-cyan"}
		>
			<Typography className={"font-500 p-2 text-cyan text-11"}>{"T"}</Typography>
		</div>
	);
	const TrailerView = (
		<div
			className={"flex items-center justify-center h-16 min-w-16 rounded-4 px-2 mr-3 border-1 border-grey text-grey"}
		>
			<Typography className={"font-500 p-2 text-grey text-11"}>{item.trailerSize}'</Typography>
		</div>
	);
	const SpecialView = (
		<div
			className={"flex items-center justify-center h-16 min-w-16 rounded-4 px-2 mr-3 border-1 border-grey text-grey"}
		>
			<Typography className={"font-500 p-2 text-grey text-11"}>{item.special ?? "VR"}</Typography>
		</div>
	);

	if (flexible) {
		features.push(createTooltip(FlexibleView, _FLEXIBLE_DESCRIPTION(item), "bg-emerald-400"));
	}
	if (hazmat) {
		features.push(createTooltip(HazmatView, _HAZMAT_DESCRIPTION, "bg-emerald-400"));
	}
	if (team) {
		features.push(createTooltip(TeamView, _TEAM_DESCRIPTION, "bg-emerald-400"));
	}
	if (special) {
		features.push(createTooltip(SpecialView, _SPECIAL_DESCRIPTION(item), "bg-emerald-400"));
	}
	if (trailer > 0) {
		features.push(createTooltip(TrailerView, _TRAILER_DESCRIPTION(item), "bg-emerald-400"));
	}

	//For small screens only show the first
	if (smallScreen && features.length > 1) {
		extraFeatures = features.length - 1;
		features = features.slice(0, 1);
	}

	return (
		<>
			{features.map((feature) => feature)}
			{extraFeatures > 0 && <Typography className="text-grey text-11 mr-5">+{extraFeatures}</Typography>}
		</>
	);
}

export default FeatureComponent;
