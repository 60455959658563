import TreeVisualizerView from "app/main/tree-visualizer/TreeVisualizerView";
import { getLatestStrategyWithLoad } from "app/services/strategyServices";
import { useEffect, useState } from "react";
import { loadingViewComponent } from "../../common/LoadingView";
import Box from "@material-ui/core/Box";

const LoadStrategyVisualizer = (props) => {
	useEffect(() => {
		props?.setTitle?.("Load Strategy Analyzer");
		// eslint-disable-next-line
	}, []);

	const [errors, setErrors] = useState();
	const { dataIds } = props;
	const { truckId, carrierId, searchId, loadId } = dataIds;

	const [resultData, setResultData] = useState(undefined);

	useEffect(() => {
		const getLoadStrategyAndSetState = async () => {
			const result = await getLatestStrategyWithLoad(truckId, carrierId, searchId, loadId);
			if (result?.warnings) {
				setErrors(result);
			} else {
				setResultData(result);
			}
		};
		getLoadStrategyAndSetState().catch((error) => {
			const errorMessages = error.errors?.map((e) => e.message);
			setErrors({ errorMessages });
		});
		//eslint-disable-next-line
	}, []);

	return (
		<Box>
			{resultData || errors ? (
				<TreeVisualizerView dataIds={dataIds} propsErrors={errors} resultData={resultData} />
			) : (
				loadingViewComponent()
			)}
		</Box>
	);
};

export default LoadStrategyVisualizer;
