import { Typography } from "@material-ui/core";

import ProfileCellView from "./views/ProfileCellView";
import AccountCellView from "./views/AccountCellView";
import CarrierCellView from "./views/CarrierCellView";
import ChatCellView from "./views/ChatCellView";
import ReplyChatCellView from "./views/ReplyChatCellView";
import DriverCellView from "./views/DriverCellView";
import InvoiceCellView from "./views/InvoiceCellView";
import PlanCellView from "./views/PlanCellView";
import ReasonsCellView from "./views/ReasonsCellView";
import SearchesCellView from "./views/SearchesCellView";
import TooltipView from "./views/TooltipView";
import TrackingCellView from "./views/TrackingCellView";
import TripsCellView from "./views/TripsCellView";
import TruckCellView from "./views/TruckCellView";
import UserCellView from "./views/UserCellView";
import StrategyCellView from "./views/StrategyCellView";
import TimelineCellView from "./views/TimelineCellView";
import TicketCellView from "./views/TicketCellView";
import CashAdvanceCellView from "./views/CashAdvanceCellView";
import CurrencyCellView from "./views/CurrencyCellView";
import SmartHopWalletCellView from "./views/SmartHopWalletCellView";
import UnitPaymentStatusCellView from "./views/UnitPaymentStatusCellView";
import TripStatusPayrollCellView from "./views/TripStatusPayrollCellView";
import MetricCellView from "./views/MetricCellView";
import PeriodCellView from "./views/PeriodCellView";
import PayeeCellView from "./views/PayeeCellView";
import AddonsCellView from "./views/AddonsCellView";
import PayrollCellView from "./views/PayrollCellView";
import BrokerCellView from "./views/BrokerCellView";
import InvoicingCellView from "./views/InvoicingCellView";
import CategoryCellView from "./views/CategoryCellView";
import PayoutCellView from "./views/PayoutCellView";
import FuelTransactionCellView from "./views/FuelTransactionCellView";
import TripBillCellView from "./views/TripBillCellView";

const CurrencyView = ({ item, cellValue, variant, viewConfig, valueConfig }) => (
	<CurrencyCellView item={item} view={cellValue} variant={variant} config={viewConfig} valueConfig={valueConfig} />
);

const ProfileView = ({ item, cellValue, variant, viewConfig }) => (
	<ProfileCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const AccountView = ({ item, cellValue, variant, viewConfig }) => (
	<AccountCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const CarrierView = ({ item, cellValue, variant, viewConfig }) => (
	<CarrierCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TruckView = ({ item, cellValue, variant, viewConfig }) => (
	<TruckCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const ChatView = ({ item, cellValue, variant, viewConfig }) => (
	<ChatCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const ReplyChatView = ({ item, cellValue, variant, viewConfig }) => (
	<ReplyChatCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const SearchesView = ({ item, cellValue, variant, viewConfig }) => (
	<SearchesCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const DriverView = ({ item, cellValue, variant, viewConfig }) => (
	<DriverCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const AddonsView = ({ item, cellValue, variant, viewConfig }) => (
	<AddonsCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const PayeeView = ({ item, cellValue, variant, viewConfig }) => (
	<PayeeCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const UserView = ({ item, cellValue, variant, viewConfig }) => (
	<UserCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const PlanView = ({ item, cellValue, variant, viewConfig }) => (
	<PlanCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TripsView = ({ item, cellValue, variant, viewConfig }) => (
	<TripsCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const ReasonsView = ({ item, cellValue, variant, viewConfig }) => (
	<ReasonsCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TrackingView = ({ item, cellValue, variant, viewConfig }) => (
	<TrackingCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const InvoiceView = ({ item, cellValue, variant, viewConfig }) => (
	<InvoiceCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const StrategyView = ({ item, cellValue, variant, viewConfig }) => (
	<StrategyCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TimelineView = ({ item, cellValue, variant, viewConfig }) => (
	<TimelineCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TicketView = ({ item, cellValue, variant }) => {
	return <TicketCellView item={item} view={cellValue} variant={variant} />;
};

const CashAdvanceView = ({ item, cellValue, variant, viewConfig }) => (
	<CashAdvanceCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const SmartHopWalletView = ({ item, cellValue, variant, viewConfig }) => (
	<SmartHopWalletCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const UnitPaymentStatusView = ({ item, cellValue, variant, viewConfig }) => (
	<UnitPaymentStatusCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TripPayrollView = ({ item, cellValue, variant, viewConfig }) => (
	<TripStatusPayrollCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const MetricView = ({ item, cellValue, variant, viewConfig }) => (
	<MetricCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const PeriodView = ({ item, cellValue, variant, viewConfig }) => (
	<PeriodCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const PayrollView = ({ item, cellValue, variant, viewConfig }) => (
	<PayrollCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const BrokerView = ({ item, cellValue, variant, viewConfig }) => (
	<BrokerCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const InvoicingView = ({ item, cellValue, variant, viewConfig, valueConfig }) => (
	<InvoicingCellView item={item} view={cellValue} variant={variant} config={viewConfig} valueConfig={valueConfig} />
);

const PayoutView = ({ item, cellValue, variant, viewConfig, valueConfig }) => (
	<PayoutCellView item={item} view={cellValue} variant={variant} config={viewConfig} valueConfig={valueConfig} />
);

const CategoryView = ({ item, cellValue, variant, viewConfig }) => (
	<CategoryCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const FuelTransactionView = ({ item, cellValue, variant, viewConfig }) => (
	<FuelTransactionCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const TripBillView = ({ item, cellValue, variant, viewConfig }) => (
	<TripBillCellView item={item} view={cellValue} variant={variant} config={viewConfig} />
);

const ComponentView = ({ cellValue }) => {
	if (!cellValue) {
		return "-";
	} else {
		return cellValue;
	}
};

const IdView = ({ cellValue, typoClassName }) => {
	return cellValue?.length > 12 ? (
		<TooltipView mode="copy" title={cellValue}>
			{"..." + cellValue.substring(cellValue.length - 8, cellValue.length)}
		</TooltipView>
	) : (
		<Typography className={typoClassName}>{cellValue}</Typography>
	);
};

const UnixView = ({ cellValue, typoClassName }) => {
	return cellValue > 0 ? (
		<TooltipView mode="copy" title={cellValue}>
			{new Date(cellValue * 1000).toLocaleString()}
		</TooltipView>
	) : (
		<Typography className={typoClassName}>-</Typography>
	);
};

/**
 * CellTypes functions take an {
 *  item,
 *  cellValue,
 *  variant,
 *  viewConfig,
 *  valueConfig,
 *  typoClassName,
 * } object and return a cellContent
 */
export const CellTypes = {
	id: IdView,
	unix: UnixView,
	currency: CurrencyView,
	component: ComponentView,
	timeline: TimelineView,
	profile__view: ProfileView,
	account__view: AccountView,
	carrier__view: CarrierView,
	truck__view: TruckView,
	chat__view: ChatView,
	reply_chat__view: ReplyChatView,
	searches__view: SearchesView,
	driver__view: DriverView,
	addons__view: AddonsView,
	payee__view: PayeeView,
	user__view: UserView,
	plan__view: PlanView,
	trips__view: TripsView,
	trip__view: TripsView,
	reasons__view: ReasonsView,
	tracking__view: TrackingView,
	invoice__view: InvoiceView,
	support__view: TicketView,
	strategy__view: StrategyView,
	cash_advance__view: CashAdvanceView,
	wallet__view: SmartHopWalletView,
	unitPaymentStatus__view: UnitPaymentStatusView,
	trip_status_payroll__view: TripPayrollView,
	metric__view: MetricView,
	period__view: PeriodView,
	payroll__view: PayrollView,
	broker__view: BrokerView,
	invoicing__view: InvoicingView,
	payout__view: PayoutView,
	category__view: CategoryView,
	investor__view: UserView,
	fuel_transaction__view: FuelTransactionView,
	trip_bill__view: TripBillView,
};
