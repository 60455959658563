import { openFormDialog } from "app/store/tools/formDialogSlice";

const userDetailsForm = {
	urlGET: "api/profile/users/:userId/details",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		view: { label: "Email Information" },
		edit: { label: "Email Information" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	onSubmitOverride: (model, dataIds, onDone, dispatch, snackbar, history) => {
		onDone?.();
		dispatch(openFormDialog({ viewId: "EMAIL_CONFIRM", dataIds: { userId: dataIds.userId } }));
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "email",
								type: "email",
								label: "Email",
								translate: "EMAIL",
								viewOnly: true,
							},
							{
								key: "status",
								type: "text",
								label: "Status",
								viewOnly: true,
								builder: (data) => (data?.email_confirmed ? "Confirmed" : "Not Confirmed"),
							},
						],
					},
				},
				{
					type: "action",
					action: "SUBMIT",
					label: "Edit and Confirm",
					button: { alwaysEnabled: true, classes: { root: "mt-10" } },
				},
			],
		},
	},
};

export default userDetailsForm;
