import { useEffect } from "react";
import { loadHistoryList, SmarthopList } from "@smarthop/list";

// Services
import { getTrackingTripNotifications } from "app/services/tripsServices";

function LoadHistoryPage(props) {
	const tripId = props?.dataIds?.tripId;
	const carrierId = props?.dataIds?.carrierId;
	const publicView = !!props?.dataIds?.public;
	const fixedHeight = props?.dataIds?.fixedHeight;

	useEffect(() => {
		const interval = setInterval(() => {
			getTrackingTripNotifications(tripId)
				.then((res) => res)
				.catch((err) => err);
		}, 300000);
		return () => clearInterval(interval);
		//eslint-disable-next-line
	}, []);

	return (
		<SmarthopList
			isView
			mode="TABLE"
			config={loadHistoryList({ publicView, fixedHeight })}
			dataIds={{ carrierId, tripId }}
			renderEmptyListView={() => "No Tracking information found"}
		/>
	);
}

export default LoadHistoryPage;
