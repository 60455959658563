import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Icon, Link, Typography } from "@material-ui/core";

import { formatDate } from "app/main/utils/dateUtils";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
	},
})(TimelineItem);

function TruckTimeline({ schedule, outdated }) {
	const dispatch = useDispatch();

	const onClickTrip = (trip) => {
		dispatch(
			openLoadedFormDialog({
				viewId: "TRIP_VIEW",
				dataIds: { tripId: trip.trip ?? trip._id, carrierId: trip.carrier },
			})
		);
	};

	const onClickPlan = (plan) => {
		dispatch(
			openLoadedFormDialog({
				viewId: "TRUCK_PLAN_VIEW",
				dataIds: { planId: plan.plan ?? plan._id, carrierId: plan.carrier, truckId: plan.truck },
			})
		);
	};

	const outdatedViews = [];
	outdated.forEach((trip, index) => {
		outdatedViews.push(
			<div key={index + "_" + trip.data.load_id} className="flex flex-row">
				<Link className="text-12" onClick={() => onClickTrip(trip)}>
					{"# " + trip.data.load_id}
				</Link>
				<Typography className="text-12 px-6">| {trip.data.status}</Typography>
			</div>
		);
	});

	const views = [];
	schedule?.forEach((item, index) => {
		const start =
			(item.pos === 0 && item?.location?.type !== "EMPTY") ||
			(item.pos === 1 && schedule[index + -1].location?.type === "EMPTY");
		const end =
			(item.pos === 0 && item?.location?.type === "EMPTY") ||
			index + 1 === schedule.length ||
			item?.trip !== schedule[index + 1]?.trip;

		if (start && item?.type === "TRIP") {
			views.push(
				<StyledTimelineItem key={index + "_header"}>
					<TimelineSeparator>
						<TimelineDot
							className={
								"items-center justify-center h-28 w-28 border-3 font-semibold shadow-none " + (item.classes?.dot ?? "")
							}
						>
							<Icon className="text-16">local_shipping</Icon>
						</TimelineDot>
						<TimelineConnector className={"opacity-70 " + item.classes?.dot} />
					</TimelineSeparator>
					<TimelineContent className={""}>
						<div className="flex flex-col">
							<Link className="text-13 font-semibold tracking-wide" onClick={() => onClickTrip(item)}>
								{"#" + item.data.load_id}
							</Link>
							<div className="flex flex-row">
								<Typography className="text-12 px-6 bg-primary-300 text-white rounded-lg">
									{item.data.status}
								</Typography>
							</div>
						</div>
					</TimelineContent>
				</StyledTimelineItem>
			);
		}
		views.push(
			<StyledTimelineItem key={index}>
				<TimelineSeparator>
					<TimelineDot
						className={
							"items-center justify-center h-28 w-28 text-12 border-1 font-semibold shadow-none " +
							(item.classes?.dot ?? "")
						}
					>
						{index + 1}
					</TimelineDot>
					{schedule.length !== index + 1 && (
						<TimelineConnector
							className={
								"opacity-60 " +
								(item?.location?.type === "EMPTY" ? " bg-grey-400 " : end ? " bg-grey-400 " : item.classes?.dot ?? "")
							}
						/>
					)}
				</TimelineSeparator>
				<TimelineContent className={"" + (end ? " mb-10 pb-20 border-grey-300 border-b-2 border-dashed " : "")}>
					{item?.type === "TRIP" ? (
						<div className={"flex flex-col "}>
							<div className="flex flex-row">
								<Typography className="font-bold text-13">
									{item?.location?.type === "EMPTY"
										? "Empty"
										: item?.location?.type === "DELIVERY"
										? "Delivery"
										: item?.location?.type === "PICKUP"
										? "Pickup"
										: "Stop"}
								</Typography>

								<Typography className="text-14 mx-4 text-grey -mt-1">|</Typography>
								<Typography className="text-12">{formatDate(item.location.location_date)}</Typography>
								{item.location.location_window_date &&
									item.location.location_window_date !== item.location.location_date && (
										<Typography className="text-12"> - {formatDate(item.location.location_window_date)}</Typography>
									)}
							</div>
							<Typography className="text-12 -mt-2">{item?.location?.location}</Typography>
							{item?.location?.type !== "EMPTY" && (
								<Typography className="text-grey -mt-2">
									{item?.location?.location_shipper?.length ? item?.location?.location_shipper : "No Details"}
								</Typography>
							)}
						</div>
					) : item?.type === "PLAN" ? (
						<div className="flex flex-col">
							<div className="flex flex-row">
								<Link className="text-13" onClick={() => onClickPlan(item)}>
									{"Next Plan"}
								</Link>
								{item.metadata.outdated && (
									<div className="flex flex-col justify-center">
										<Typography className="text-11 mx-6 text-white bg-red px-6 rounded-lg">Outdated</Typography>
									</div>
								)}
							</div>
							<div className="flex flex-row">
								<Typography className="font-bold text-13">Empty</Typography>
								<Typography className="text-14 mx-4 text-grey -mt-1">|</Typography>
								<Typography className="flex flex-row text-12">{formatDate(item.location.location_date)}</Typography>
							</div>
							<Typography className="text-12">{item?.location?.location}</Typography>
						</div>
					) : null}
				</TimelineContent>
			</StyledTimelineItem>
		);
	});

	return (
		<div className="flex flex-col">
			{outdatedViews.length > 0 && (
				<div className="flex flex-col bg-red-50 border-1 border-red rounded-lg p-10 mt-6">
					<Typography className="text-red-700 text-12 mb-8">
						<Icon className="text-18 -mb-5 text-red-400 mr-3">warning</Icon>It seems that you have{" "}
						{outdatedViews.length} old trips(s) that should have been marked as delivered, please make sure old trips
						are always marked as delivered or canceled to see correct analytics
					</Typography>
					{outdatedViews}
				</div>
			)}
			<Timeline className="-ml-14 mr-4 mt-4 -mb-6">{views}</Timeline>
		</div>
	);
}

export default TruckTimeline;
