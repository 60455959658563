import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { NARROW_FIELDS } from "@fuse/default-settings";

const BoxShadowNormal = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)";
const BoxShadowHover = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const getSliderStype = (inverted) => ({
	root: {
		pointerEvents: "none !important",
		color: !inverted ? "#E7683D" : "#D4D4D4",
		height: 2,
	},
	thumb: {
		pointerEvents: "all !important",
		height: 28,
		width: 28,
		backgroundColor: "#fff",
		boxShadow: BoxShadowHover,
		marginTop: -14,
		marginLeft: -28,
		marginRight: 28,
		"&:focus, &:hover, &$active": {
			boxShadow: BoxShadowNormal,
			// Reset on touch devices, it doesn't add specificity
			"@media (hover: none)": {
				boxShadow: BoxShadowHover,
			},
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 12px)",
		top: -16,
		"& *": {
			background: "#E7683D",
			color: "#ffffff",
		},
	},
	track: {
		height: 2,
		marginLeft: -14,
	},
	rail: {
		height: 2,
		opacity: !inverted ? 0.5 : 1,
		marginLeft: -14,
		backgroundColor: !inverted ? "#AEAEAE" : "#E7683D",
	},
	mark: {
		backgroundColor: !inverted ? "#AEAEAE" : "#E7683D",
		height: 8,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor",
	},
});

const StyledSliderNormal = withStyles(getSliderStype(false))(Slider);
const StyledSliderInverted = withStyles(getSliderStype(true))(Slider);

function SmarthopSliderField(props) {
	const getLabelFormat = (value) => {
		if (props.slider.builder) {
			return props.slider.builder(value)?.label;
		} else {
			return (props.slider?.prefix ?? "") + value + (props.slider?.postfix ?? "");
		}
	};
	const getLabelClasses = (value) => {
		if (props.slider.builder) {
			return props.slider.builder(value)?.classes ?? "";
		} else {
			return "";
		}
	};
	const minValue = props.slider?.min ?? 0;
	const maxValue = props.slider?.max ?? 100;
	const step = props.slider?.step ?? (maxValue - minValue) / 100;
	const sliderParams = {
		value: props.value ? props.value : props.slider?.type === "range" ? [0, 0] : 0,
		valueLabelDisplay: props.slider?.valueLabelDisplay ?? "auto",
		variant: props.slider?.variant ?? "outlined",
		classes: props.slider?.classes ?? {},
		min: minValue,
		max: maxValue,
		step: step,
		className: "w-full pr-0",
		disabled: props.disabled,
		valueLabelFormat: (value) => (props.slider.builder ? props.slider.builder(value)?.label : value),
		"aria-labelledby": props.slider?.type === "range" ? "range-slider" : "input-slider",
		onChange: (event, value) => {
			if (props.onChange) props.onChange(value);
		},
		onChangeCommitted: (event, value) => {
			if (props.onChange) props.onChange(value);
			if (props.onChangeCommitted) props.onChangeCommitted(props.name, value);
		},
	};
	const value = props.value;
	return (
		<div
			className={
				(props.disabled ? "opacity-40 " : "") +
				"w-full mt-4 pl-20 px-4 " +
				(props?.slider?.noErrorMessage ? " pt-4 pb-4 " : "")
			}
		>
			<div className={"flex flex-row items-center w-full"}>
				<Typography className="left flex flex-1 w-full flex-col pr-16 -ml-16">
					{props.label + (props.required ? " *" : "")}
				</Typography>
				<Typography
					className={"right flex-col font-normal pr-10 " + (!Array.isArray(value) ? getLabelClasses(value) : "")}
					align="right"
				>
					{Array.isArray(value)
						? getLabelFormat(value[0] ?? minValue) +
						  " - " +
						  getLabelFormat(value[1] ? value[1] + (value[1] === maxValue ? "+" : "") : maxValue)
						: getLabelFormat(value ?? minValue)}
				</Typography>
			</div>

			<div className={"w-full pl-8 pr-8 pt-3 " + (!props?.slider?.noErrorMessage ? " -mb-8 " : " -mb-16 ")}>
				{props.slider?.inverted ? <StyledSliderInverted {...sliderParams} /> : <StyledSliderNormal {...sliderParams} />}
			</div>
			<Typography color="error" variant="caption" className="ml-10">
				{props.errors[props.name]?.message ?? ""}
			</Typography>
			{!props?.slider?.noErrorMessage && (
				<div
					style={
						!props.disabled && !!props.errors[props.name]?.message
							? {}
							: { height: NARROW_FIELDS ? "1.6rem" : "1.9rem" }
					}
				/>
			)}
		</div>
	);
}

export default SmarthopSliderField;
