import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { clearDiagnosticMessages } from "../../providers/reducer/actions";
import { strategyConnect } from "../../providers/StrategyContextProvider";
import * as diagnosticComponents from "./components";

const useStyles = makeStyles({
	textColor: {
		color: "rgb(102, 60, 0)",
	},
	backgroundColor: {
		backgroundColor: "rgb(255, 244, 229)",
	},
});

const StrategyDiagnosticMessages = ({ diagnosticMessages, clearDiagnosticMessages }) => {
	const classes = useStyles();
	return (
		<Dialog
			fullWidth={true}
			maxWidth="md"
			open={!!diagnosticMessages.length}
			onClose={clearDiagnosticMessages}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{ className: classes.backgroundColor }}
		>
			<DialogContent>
				<Alert key="error" severity={"warning"}>
					<AlertTitle>Strategy Warning</AlertTitle>
					{diagnosticMessages.map((diagnosticMessage, i) => {
						const DiagnosticComponent = diagnosticComponents[diagnosticMessage.componentName];
						return DiagnosticComponent ? (
							<DiagnosticComponent
								key={`diagnostic-message-${i}`}
								message={diagnosticMessage.message}
								{...diagnosticMessage.props}
							/>
						) : (
							<Typography className={classes.textColor}> {diagnosticMessage.message} </Typography>
						);
					})}
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button onClick={clearDiagnosticMessages} color="primary">
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => ({
	diagnosticMessages: state.diagnosticMessages,
});

const mapDispatchToProps = (dispatch) => ({
	clearDiagnosticMessages: () => dispatch(clearDiagnosticMessages()),
});

export default strategyConnect(mapStateToProps, mapDispatchToProps)(StrategyDiagnosticMessages);
