const terminationDateForm = {
	urlGET: "billing/carriers/:carrierId/subscription/termination-date",
	urlPUT: "billing/carriers/:carrierId/subscription/termination-date",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision"],
	header: { edit: { label: "Change Termination Date" } },
	content: {
		edit: {
			items: [
				{
					key: "terminationDate",
					type: "picker",
					label: "Termination Date",
					picker: {
						disablePast: true,
						notClearable: true,
						type: "date",
						classes: {},
					},
				},

				{ type: "action", action: "Change" },
			],
		},
		view: {
			sameAs: "edit",
		},
	},
};

export default terminationDateForm;
