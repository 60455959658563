import { useState, useEffect } from "react";
import { Typography, Link, makeStyles } from "@material-ui/core";

// Service
import { getDotInformation } from "app/services/carrierServices";

import PageSection from "../../common/PageSection";
import { DisplayField } from "app/main/utils/uiUtils";

const useStyles = makeStyles(() => ({
	maxHeight: {
		height: "60vh",
	},
}));

const CarrierDotInformationPage = (props) => {
	const classes = useStyles();

	const carrierId = props.params.carrierId;

	const [data, setData] = useState(null);
	const [errors, setErrors] = useState(null);

	useEffect(() => {
		if (!carrierId) {
			return;
		}

		getDotInformation(carrierId).then(
			(response) => {
				setData(response);
			},
			(err) => {
				setErrors(err.errors);
			}
		);
		// eslint-disable-next-line
	}, [carrierId]);

	if (errors?.length > 0) {
		return (
			<div className={"flex w-full flex-col items-center justify-center " + classes.maxHeight}>
				<Typography color="error" className="flex text-13 pt-10 pb-8 items-center justify-center">
					{errors[0].message}
				</Typography>
			</div>
		);
	}

	if (!data) {
		return (
			<div className={"flex w-full flex-col items-center justify-center " + classes.maxHeight}>
				<div className="w-1/4">
					<Typography color="primary" className="flex text-13 pt-10 pb-8 items-center text-grey-600 justify-center">
						Loading...
					</Typography>
				</div>
			</div>
		);
	}

	return (
		<div className={"flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20 " + classes.maxHeight}>
			<PageSection variant="full" title="DOT Information">
				<DisplayField
					label="Url"
					value={
						<Link color="secondary" className="cursor-pointer" onClick={() => window.open(data?.url)}>
							{data?.url}
						</Link>
					}
				/>
				<DisplayField label="Metadata" value={<pre id="json">{data?.dot_metadata}</pre>} />
			</PageSection>
		</div>
	);
};

export default CarrierDotInformationPage;
