import { createEntityAdapter, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { global } from "app/services/requestUtil";

export const markAllChatsAsRead = createAsyncThunk("chats/list/mark/read", async ({ userId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.put(`/api/messenger/users/${userId}/chats/read`, { headers: headers });

	return response.data.items;
});

export const handleChatMute = createAsyncThunk("chats/list/mute", async ({ userId, chatId, scope }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.put(`/api/messenger/users/${userId}/chats/${chatId}/mute`, { scope }, { headers: headers });

	return response.data;
});

export const fetchChats = createAsyncThunk("chats/list/fetch", async ({ userId }, { dispatch }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.get(`/api/messenger/users/${userId}/chats`, { headers: headers });

	return response.data.items;
});

const chatsAdapter = createEntityAdapter({
	selectId: (chat) => chat._id,
	sortComparer: (c1, c2) => {
		let ls2 = c2.lastSentUnix ?? 0;
		let ls1 = c1.lastSentUnix ?? 0;
		if (ls2 === ls1) {
			return c2._id < c1._id ? -1 : 1;
		} else {
			return ls2 - ls1;
		}
	},
});
const initialState = chatsAdapter.upsertMany(
	chatsAdapter.getInitialState({
		visible: false,
		initialized: false,
		loading: false,
		errors: null,
		chatType: "ALL",
	}),
	[]
);

export const { selectAll: selectChats, selectById: selectChatsById } = chatsAdapter.getSelectors(
	(state) => state.messenger.chats
);

const chatSlice = createSlice({
	name: "chats/list",
	initialState,
	reducers: {
		updateAllChats: chatsAdapter.upsertMany,
		removeChat: (state, action) => chatsAdapter.removeOne(state, action.payload),
		removeChats: (state, action) => chatsAdapter.removeMany(state, action.payload),
		addChat: (state, action) => chatsAdapter.upsertOne(state, action.payload),
		setVisible: (state, action) => {
			state.visible = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
			state.errors = null;
		},
		setChatType: (state, action) => {
			state.chatType = action.payload;
		},
	},
	extraReducers: {
		[fetchChats.fulfilled]: (state, action) => {
			state.initialized = true;
			state.loading = false;
			state.errors = null;

			// In case if called from react native, calling webhook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "CHATS_FETCH_FULFILLED",
				})
			);

			return chatsAdapter.setAll(state, action.payload);
		},
		[fetchChats.rejected]: (state, action) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to load chats..." }];

			// In case if called from react native, calling webhook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "CHATS_FETCH_REJECTED",
				})
			);
		},
		[markAllChatsAsRead.fulfilled]: (state, action) => {
			state.initialized = true;
			state.loading = false;
			state.errors = null;
		},
		[markAllChatsAsRead.rejected]: (state, action) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to load chats..." }];
		},
		[handleChatMute.fulfilled]: (state, action) => {
			state.initialized = true;
			state.loading = false;
			state.errors = null;
			chatsAdapter.updateOne(state, { id: action.payload._id, changes: { mute: action.payload.mute } });

			// In case if called from react native, calling webhook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "CHAT_MUTE_FULFILLED",
				})
			);
		},
		[handleChatMute.rejected]: (state, action) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to mute the channel..." }];
		},
	},
});

export const { removeChat, addChat, setVisible, setLoading, updateAllChats, setChatType, removeChats } =
	chatSlice.actions;
export default chatSlice.reducer;
