// Utils
import { formatLedgerAmount } from "app/main/utils/financeUtils";
import { PAYROLL_STATUSES } from "app/main/consts";

// Consts
import { DRIVER_JOURNAL_ENTRY_CATEGORIES, DRIVER_JOURNAL_ENTRY_STATUSES_OPTIONS } from "app/main/consts";

const payeePayrollLedgerList = ({
	isAdmin,
	filterByPayroll,
	isActionsEnable,
	isInvestor,
	payeeType,
	payrollStatus,
}) => ({
	urlGET: filterByPayroll ? "billing/v3/payroll/:payrollId/ledger" : `billing/v3/payroll/users/:userId/ledger`,
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision"],
	segmentKey: "DRIVER_PAYROLL",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "event_date",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "event_date",
					type: "dateRange",
					label: "Date Range",
				},
				{
					key: "category",
					type: "select",
					label: "Type",
					defaultValue: "__NOT_SELECTED__",
					options: DRIVER_JOURNAL_ENTRY_CATEGORIES,
				},
				...(isInvestor
					? [
							{
								key: "truck",
								type: "autocomplete",
								label: "Truck",
								translate: "",
								field: {
									classes: {},
									variant: undefined,
								},
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/carriers/:carrierId/investor/:userId/trucks",
									params: {
										params: { options_investor: true },
									},
									preloadDefault: true,
									listenEvent: "profileRevision",
								},
							},
					  ]
					: []),
				...(!isActionsEnable
					? [
							{
								key: "status",
								type: "select",
								label: "Status",
								defaultValue: "__NOT_SELECTED__",
								options: DRIVER_JOURNAL_ENTRY_STATUSES_OPTIONS,
							},
					  ]
					: []),
			],
		},
		groups: {
			items: [
				...(isAdmin
					? [
							{
								key: "internal",
								type: "checkbox",
								label: "Show Internal Fields",
							},
					  ]
					: []),
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal", hidden: !isAdmin },
			...(isInvestor
				? [
						{
							key: "truck__view",
							type: "truck__view",
							label: "Truck",
							viewConfig: {
								showStatus: true,
								showTeam: true,
							},
						},
				  ]
				: []),
			{
				key: "category__view",
				type: "category__view",
				label: "Type",
			},
			{ key: "event_date", type: "date", label: "Event Date" },
			{
				key: "description",
				type: "text",
				label: "Description",
				collapsibleLength: 50,
			},
			{ key: "status", type: "text", label: "Status", builder: (item) => PAYROLL_STATUSES[item.status] },
			{
				key: "amount_cents",
				type: "component",
				label: "Amount",
				builder: (item) => formatLedgerAmount(item.amount_cents, item.type),
			},
			...(isActionsEnable
				? [
						{
							key: "menu",
							type: "menu",
							label: "Actions",
							items: [
								{
									key: "edit",
									variant: "iconButton",
									label: "Edit",
									button: {
										icon: "edit",
										color: "primary",
									},
									dialogBuilder: (item, dataIds) => ({
										viewId: "PAYROLL_JOURNAL_ENTRY_DETAILS_VIEW",
										mode: "EDIT",
										dataIds: {
											entryId: item._id,
											payrollId: dataIds.payrollId,
											carrierId: dataIds.carrierId,
											payrollStatus,
											isInvestor,
											userId: dataIds.userId,
											payeeType,
										},
									}),
								},
								{
									key: "delete",
									variant: "iconButton",
									label: "Delete",
									button: {
										icon: "delete",
										color: "primary",
									},
									dialogBuilder: (item, dataIds) => ({
										formId: "PAYROLL_ADDITIONS_DEDUCTIONS_FORM",
										mode: "DELETE",
										dataIds: {
											entryId: item._id,
											payrollId: dataIds.payrollId,
										},
									}),
								},
							],
						},
				  ]
				: []),
		],
	},
});

export default payeePayrollLedgerList;
