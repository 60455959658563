import { useEffect, forwardRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Material-UI components
import { Button } from "@material-ui/core";

import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import usePayrollHelper from "@smarthop/hooks/usePayrollHelper";
import {
	carrierUsersList,
	historyList,
	carrierReportsList,
	carrierDriversList,
	carrierTrucksList,
	carrierTrailerList,
	goalsList,
	costsList,
	carrierTransactionsList,
	carrierFuelTransactionsList,
	smartpayJournalEntriesConf,
	smartpayPayoutsConf,
	enterprisePayrollTripsErrors,
	enterprisePayrollList,
	performanceCarrierTrips,
	carrierSubAccountList,
} from "@smarthop/list";
import tripInvoicingConf from "app/main/billing/invoicing/tripInvoicingConf";

// Views
import CarriersCompanyView from "app/main/profile/carriers/CarriersCompanyView";
import BrokersView from "app/main/profile/integrations/BrokersView";
import DispatcherView from "app/main/profile/carriers/dispatching/DispatcherView";
import CarrierDotInformationPage from "app/main/profile/carriers/CarrierDotInformationPage";
import CarrierActionsPage from "app/main/profile/carriers/CarrierActionsPage";
import CarrierExternalIdsPage from "app/main/profile/carriers/CarrierExternalIdsPage";
import BillingView from "app/main/billing/payments/BillingView";
import AnalitycsView from "app/main/profile/goals/AnalitycsView.js";

// Service
import { setGoalAsDefault } from "app/services/goalsServices";
import { setDefaultCostStructure } from "app/services/costStructureServices";
import { isRoleExternal, isRoleHasFullCarrierAccess, getRoleLabel, getUserId } from "app/services/LoginService";
import { getCarrierMainAccountDetails } from "app/services/usersServices";
import { getCarrierConfig } from "app/services/carrierServices";

// Tools
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

// Utils
import { showSnackbar, showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";

const CarrierInfoView = forwardRef(({ nativeMobile, dataIds, setTitle, setSize }, _) => {
	useEffect(() => {
		setTitle?.("Carrier Information");
		setSize?.("max-w-4xl");
		// eslint-disable-next-line
	}, []);

	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const role = user.rolesLabels?.[0];

	const [isMainHandler, setIsMainHandler] = useState(false);
	const [subAccounts, setSubAccounts] = useState();
	const [accountId, setAccountId] = useState();
	const [hasSmartPayProgram, setHasSmartPayProgram] = useState();

	const userId = getUserId();
	const carrierId = dataIds.carrierId;

	const isAdmin = role.includes("administrator");
	const isInternal = !isRoleExternal();
	const isCarrier = isRoleHasFullCarrierAccess();
	const isInvestor = user.gatekeepers?.permission_investor_on;
	const {
		auth: { showPayrollFieldsToSessionUser },
	} = usePayrollHelper();
	const invoiceHasAccessEdit = hasRequiredGateKeepers(user, { permission_invoice_access: "editor" });
	const onlyViewDetailTab = role.includes("carrier_dispatcher")
		? !user.gatekeepers?.trucks_full_access_all_context
		: false;

	useEffect(() => {
		(async () => {
			const result = await getCarrierMainAccountDetails(carrierId);
			setIsMainHandler(result?.isMainAccount);
			setSubAccounts(result?.subAccounts);
			setAccountId(result?.accountId);
		})();
	}, [carrierId]);

	useEffect(() => {
		(async () => {
			if (carrierId) {
				const config = await getCarrierConfig(carrierId);
				setHasSmartPayProgram(config.hasSmartPayProgram);
			}
		})();
		// eslint-disable-next-line
	}, [carrierId, user]);

	const setDefaultGoals = async (goaldId) => {
		try {
			await setGoalAsDefault(goaldId, { carrierId });
			dispatch(incrementDataRevision({ event: "goalsRevision" }));
			showSnackbar(snackbar, "Goals updated", "success");
		} catch (error) {
			showErrorSnackbar(snackbar, error);
		}
	};

	const setDefault = async (costStructureId, data) => {
		data.carrier = carrierId;
		try {
			await setDefaultCostStructure(costStructureId, data);
			dispatch(incrementDataRevision({ event: "costStructureRevision" }));
			showSnackbar(snackbar, "Cost structure updated", "success");
		} catch (error) {
			showErrorSnackbar(snackbar, error);
		}
	};

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			tabComponents={[
				{
					key: "DETAILS",
					title: "Details",
					variant: "narrow",
					component: (
						<div className="flex flex-col w-full">
							<CarriersCompanyView carrierId={carrierId} />
						</div>
					),
				},

				...(!onlyViewDetailTab
					? [
							...(!isMainHandler
								? [
										{
											key: "DISPATCHER",
											title: "Dispatcher",
											variant: "narrow",
											component: <DispatcherView carrierId={carrierId} fromCarrierView={true} />,
										},
								  ]
								: [
										{
											key: "DISPATCHER",
											title: "Dispatcher",
											type: "TABS",
											tabComponents: [
												{
													key: "DISPATCHER_PLAN_ACCESS",
													title: "Plan & Access",
													variant: "narrow",
													component: <DispatcherView carrierId={carrierId} fromCarrierView={true} />,
												},
												{
													key: "DISPATCHER_SUBACCOUNTS",
													title: `Sub-accounts
														${
															subAccounts?.tier && subAccounts?.tier !== "TIER_ENTERPRISE"
																? `(${subAccounts?.subAccounts?.length}/${subAccounts?.totalSitsEnabled} Seats)`
																: ""
														}`,
													type: "LIST",
													params: {
														config: carrierSubAccountList(user, subAccounts, snackbar),
														dataIds: { accountId, carrierId, options_active_only: true },
													},
												},
											],
										},
								  ]),
							{
								key: "ASSETS",
								title: "Assets",
								type: "TABS",
								tabComponents: [
									{
										key: "DRIVERS",
										title: "Drivers",
										type: "LIST",
										params: {
											...(["carrier", "carrier_manager", "administrator"].includes(role)
												? {
														footer: (
															<div className="flex flex-row w-full items-center justify-end border-t-1 broger-grey mt-4">
																<div className="flex flex-col pt-10">
																	<Button
																		className={"ml-12 text-12 pb-6"}
																		variant="contained"
																		color="secondary"
																		aria-label="Follow"
																		onClick={() =>
																			dispatch(
																				openLoadedFormDialog({
																					viewId: "CARRIER_USER_EDIT_VIEW",
																					mode: "CREATE",
																					dataIds: { carrierId, origin: "DRIVER" },
																				})
																			)
																		}
																	>
																		Add New Driver
																	</Button>
																</div>
															</div>
														),
												  }
												: {}),
											config: carrierDriversList,
											dataIds: { carrierId, options_my_assigned: isCarrier ? true : false },
										},
									},
									{
										key: "TRUCKS",
										title: "Trucks",
										type: "LIST",
										params: {
											...(["administrator", "ops support", "carrier", "carrier_manager"].includes(role)
												? {
														footer: (
															<div className="flex flex-row w-full items-center justify-end border-t-1 broger-grey mt-4">
																<div className="flex flex-col pt-10">
																	<Button
																		className={"ml-12 text-12 py-6"}
																		variant="contained"
																		color="secondary"
																		aria-label="Follow"
																		onClick={() =>
																			dispatch(
																				openLoadedFormDialog({
																					viewId: "TRUCK_VIEW",
																					mode: "CREATE",
																					dataIds: { carrierId, mode: "CREATE" },
																				})
																			)
																		}
																	>
																		Add New Truck
																	</Button>
																</div>
															</div>
														),
												  }
												: {}),
											config: carrierTrucksList,
											dataIds: { carrierId, options_my_assigned: isCarrier ? true : false },
										},
									},
									{
										key: "TRAILERS",
										title: "Trailers",
										type: "LIST",
										params: {
											...(["administrator", "ops support", "carrier", "carrier_manager"].includes(role)
												? {
														footer: (
															<div className="flex flex-row w-full items-center justify-end border-t-1 broger-grey mt-4">
																<div className="flex flex-col pt-10">
																	<Button
																		className={"ml-12 text-12 py-6"}
																		variant="contained"
																		color="secondary"
																		aria-label="Follow"
																		onClick={() =>
																			dispatch(
																				openLoadedFormDialog({
																					viewId: "TRAILER_VIEW",
																					mode: "CREATE",
																					dataIds: { carrierId, mode: "CREATE" },
																				})
																			)
																		}
																	>
																		Add New Trailer
																	</Button>
																</div>
															</div>
														),
												  }
												: {}),
											config: carrierTrailerList,
											dataIds: { carrierId, options_my_assigned: isCarrier ? true : false },
										},
									},
								],
							},
							{
								key: "USERS",
								title: "User",
								type: "LIST",
								auth: ["carrier", "carrier_manager", "administrator"],
								...(["carrier", "carrier_manager", "administrator"].includes(role)
									? {
											footer: (
												<div className="flex flex-row w-full items-center justify-end border-t-1 broger-grey mb-4">
													{["carrier", "carrier_manager", "administrator"].includes(role) ? (
														<div className="flex flex-col pt-10">
															<Button
																className={"ml-12 text-12 py-6"}
																variant="contained"
																color="secondary"
																aria-label="Follow"
																onClick={() =>
																	dispatch(
																		openLoadedFormDialog({
																			viewId: "CARRIER_USER_EDIT_VIEW",
																			mode: "CREATE",
																			dataIds: { carrierId },
																		})
																	)
																}
															>
																Create User
															</Button>
														</div>
													) : null}
													{["administrator"].includes(role) ? (
														<div className="flex flex-col pt-10">
															<Button
																className={"ml-12 text-12 py-6"}
																variant="contained"
																color="secondary"
																aria-label="Follow"
																onClick={() =>
																	dispatch(
																		openLoadedFormDialog({
																			viewId: "TRANSFER_VIEW",
																			mode: "CREATE",
																			dataIds: { carrierId },
																		})
																	)
																}
															>
																Transfer Ownership
															</Button>
														</div>
													) : null}
												</div>
											),
									  }
									: {}),
								params: {
									config: carrierUsersList({ showPayrollFields: showPayrollFieldsToSessionUser }),
									dataIds: { carrierId, options_active_only: true },
								},
							},
							{
								key: "BILLING",
								title: "Billing",
								type: "TABS",
								auth: ["administrator"],
								tabComponents: [
									{
										key: "SETTINGS",
										title: "Settings",
										variant: "narrow",
										component: (
											<div className="flex flex-col w-full pb-40">
												<BillingView carrierId={carrierId} variant="full" />
											</div>
										),
									},
									{
										key: "TRANSACTION",
										title: "Transaction",
										type: "LIST",
										params: {
											config: carrierTransactionsList,
											dataIds: { carrierId },
										},
									},
								],
							},
							{
								key: "PERFORMANCE",
								title: "Performance",
								type: "TABS",
								tabComponents: [
									{
										key: "PERFORMANCE_ANALYTICS",
										title: "Analytics",
										component: (
											<div className="w-full -mx-10 -mt-10 pb-16">
												<AnalitycsView carrierId={carrierId} isMainHandler={false} isView={true} />
											</div>
										),
									},
									{
										key: "PERFORMANCE_TRIPS",
										title: "Trips",
										type: "LIST",
										params: {
											config: performanceCarrierTrips({
												dispatch,
												readOnly: isInvestor,
												isMainHandler,
											}),
											dataIds: { carrierId, userId, onlyAssigned: isInvestor },
										},
									},
								],
							},
							{
								key: "COSTS",
								title: "Costs",
								type: "TABS",
								tabComponents: [
									{
										key: "GOALS",
										title: "Goals",
										type: "LIST",
										params: {
											...(["administrator", "carrier", "carrier_manager"].includes(role)
												? {
														footer: (
															<div className="flex flex-row w-full items-center justify-end border-t-1 broger-grey mt-4">
																<div className="flex flex-col pt-10">
																	<Button
																		className={"ml-12 text-12 py-6"}
																		variant="contained"
																		color="secondary"
																		aria-label="Follow"
																		onClick={() =>
																			dispatch(
																				openLoadedFormDialog({
																					viewId: "CREATE_NEW_GOALS_VIEW",
																					mode: "CREATE",
																					dataIds: { carrierId },
																				})
																			)
																		}
																	>
																		Add New Goal
																	</Button>
																</div>
															</div>
														),
												  }
												: {}),
											config: goalsList({ onSetDefault: setDefaultGoals, role: getRoleLabel() }),
											dataIds: { carrierId, options_my_assigned: isCarrier ? true : false },
										},
									},
									{
										key: "COSTS_INTERN",
										title: "Costs",
										type: "LIST",
										params: {
											...(["administrator", "carrier", "carrier_manager"].includes(role)
												? {
														footer: (
															<div className="flex flex-row w-full items-center justify-end border-t-1 broger-grey mt-4">
																<div className="flex flex-col pt-10">
																	<Button
																		className={"ml-12 text-12 py-6"}
																		variant="contained"
																		color="secondary"
																		aria-label="Follow"
																		onClick={() =>
																			dispatch(
																				openLoadedFormDialog({
																					viewId: "COST_STRUCTURE",
																					mode: "CREATE",
																					dataIds: { carrierId },
																				})
																			)
																		}
																	>
																		Add Cost Structure
																	</Button>
																</div>
															</div>
														),
												  }
												: {}),
											config: costsList({ onSetDefault: setDefault, role: getRoleLabel() }),
											dataIds: { carrierId, options_my_assigned: isCarrier ? true : false },
										},
									},
								],
							},
							{
								key: "HISTORY",
								title: "History",
								type: "LIST",
								params: {
									config: historyList(carrierId, isMainHandler || isInternal),
								},
							},
							{
								key: "INTEGRATIONS",
								title: "Integrations",
								variant: "narrow",
								component: (
									<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-20">
										<BrokersView carrierId={carrierId} />
									</div>
								),
							},
							{
								key: "PAYROLL",
								title: "Payroll",
								type: "TABS",
								auth: ["administrator"],
								tabComponents: [
									{
										key: "PAYROLL_CARRIERS",
										title: "Reports",
										type: "LIST",
										params: {
											config: enterprisePayrollList({ showCarrier: isMainHandler }),
											dataIds: { carrierId },
										},
									},
									{
										key: "PAYROLL_ERRORS",
										title: "Errors",
										type: "LIST",
										params: {
											config: enterprisePayrollTripsErrors({ showCarrier: isMainHandler }),
											dataIds: { carrierId },
										},
									},
								],
							},
							...(hasSmartPayProgram
								? [
										{
											key: "INVOICES_TABS",
											title: "Invoices",
											type: "TABS",
											auth: ["administrator"],
											tabComponents: [
												{
													key: "INVOICES_INTERN",
													title: "Invoices",
													type: "LIST",
													params: {
														config: tripInvoicingConf({
															isAdmin,
															hasCarrierSmartpayEnabled: hasSmartPayProgram,
															readOnly: invoiceHasAccessEdit,
															showCarrier: isMainHandler,
														}),
														dataIds: { carrierId },
													},
												},
												{
													key: "INVOICES_LEDGER",
													title: "Ledger",
													type: "LIST",
													params: {
														config: smartpayJournalEntriesConf({ showCarrier: isMainHandler }),
														dataIds: { carrierId },
													},
												},
												{
													key: "INVOICES_PAYOUTS",
													title: "Payouts",
													type: "LIST",
													params: {
														config: smartpayPayoutsConf({ showCarrier: isMainHandler }),
														dataIds: { carrierId },
													},
												},
											],
										},
								  ]
								: [
										{
											key: "INVOICES_LIST",
											title: "Invoices",
											type: "LIST",
											auth: ["administrator"],
											params: {
												config: tripInvoicingConf({
													isAdmin,
													hasCarrierSmartpayEnabled: hasSmartPayProgram,
													readOnly: invoiceHasAccessEdit,
													isMainHandler,
												}),
												dataIds: { carrierId },
											},
										},
								  ]),
							{
								key: "SMARTFUEL",
								title: "Smartfuel",
								type: "LIST",
								auth: ["administrator"],
								params: {
									config: carrierFuelTransactionsList({ showCarrier: isMainHandler }),
									dataIds: { carrierId },
								},
							},
							{
								key: "REPORTS",
								title: "Reports",
								type: "LIST",
								auth: ["administrator"],
								params: {
									config: carrierReportsList,
									dataIds: { carrierId },
								},
							},
							{
								key: "Internal",
								title: "Internal",
								type: "TABS",
								auth: ["administrator", "ops support", "dispatcher"],
								tabComponents: [
									{
										key: "DOT",
										title: "DOT Info",
										variant: "narrow",
										component: <CarrierDotInformationPage params={{ carrierId }} />,
									},
									{
										key: "ACTIONS",
										title: "Actions",
										variant: "narrow",
										component: <CarrierActionsPage params={{ carrierId }} />,
									},
									{
										key: "EXTERNAL_IDS",
										title: "External IDs",
										variant: "narrow",
										component: <CarrierExternalIdsPage params={{ carrierId }} />,
									},
								],
							},
					  ]
					: []),
			]}
		></SmarthopDialogViewContainer>
	);
});

export default CarrierInfoView;
