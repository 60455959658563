import { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// View
import PageWrapper from "../../common/PageWrapper";
import TripEditView from "app/main/profile/trips/TripEditView";

// Services
import { getVerifiedTokenEditTrip } from "app/services/publicServices";

// Utils
import { readURLParameters, convertURLParamsToModel } from "app/main/utils/urlUtils";

const PublicTripEditView = () => {
	const params = convertURLParamsToModel(readURLParameters(), {}, { notJson: true });
	const token = params.token;

	const [viewParams, setViewParams] = useState(null);
	const [userInfo, setUserInfo] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setLoading(true);
		if (!token) {
			setLoading(false);
			setError("Oops, something went wrong...");
			return;
		}

		getVerifiedTokenEditTrip(token).then(
			(response) => {
				const responseParams = {
					dataIds: {
						tripId: response.tripId,
						carrierId: response.carrierId,
						userId: response.userId,
						emailAlias: response.emailFromAlias,
						public: true,
					},
				};
				setUserInfo({ username: response.userName, useremail: response.emailFromAlias ?? response.userEmail });
				setViewParams(responseParams);
				setLoading(false);
			},
			(error) => {
				console.error("[PublicTripEditView] Failed to load data", error);
				setLoading(false);
				setError(error.message ?? error.errors?.[0]?.message ?? "Oops, something went wrong...");
			}
		);
	}, [token]);

	const CardView = (props) => {
		return (
			<div className="flex flex-col h-full flex-auto items-center justify-center">
				<Card className={"max-w-510 flex flex-col w-full items-center justify-center sm:text-center py-24 "}>
					<CardContent className="w-full flex flex-col  items-center justify-center px-32">
						{props.children}
					</CardContent>
				</Card>
			</div>
		);
	};

	if (loading) {
		return (
			<CardView>
				<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
				<Typography className="pt-28 text-14 font-light" color="textSecondary">
					Loading...
				</Typography>
			</CardView>
		);
	}

	if (error) {
		return (
			<CardView>
				<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
				<Typography className="pt-28 text-14 text-red font-light">{error}</Typography>
			</CardView>
		);
	}

	return (
		<PageWrapper title={`Edit Trip`}>
			<span className="-mt-16 mb-10 ml-6 text-14">
				{userInfo?.username} ({userInfo?.useremail})
			</span>
			<div className="p-0 md:p-10 m-0 md:my-10 border-1 bg-white rounded-lg">
				<TripEditView {...viewParams} />
			</div>
		</PageWrapper>
	);
};

export default PublicTripEditView;
