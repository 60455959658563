import { useEffect, useRef, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { formatAge } from "app/main/utils/dateUtils";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useHeatMap from "app/main/market-conditions/hooks/useHeatMap";
import SmarthopMenuContainer from "@smarthop/form/fields/SmarthopMenuContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { selectByType } from "app/store/marketConditions/mapSlice";
//utils
import { getValueLocalStorage } from "app/main/utils/liveTrackingUtils";
import AccountCarrierSelect from "app/main/common/AccountCarrierSelect";
import { isRoleHasFullCarrierAccess } from "app/services/LoginService";

var randomColor = require("randomcolor");

TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;
const useStyles = makeStyles((theme) => {
	return {
		mapRadius: {
			borderRadius: "20px",
			width: "100%",
			height: "calc(100% - 55px)",
		},
	};
});

function LiveTrackingContainer(props) {
	const [showTrips, setShowTrips] = useState(getValueLocalStorage("showTripsTracking", true));
	const [globalMap, setGlobalMap] = useState(null);
	const [status, setStatus] = useState(getValueLocalStorage("statusTracking"));
	const [showProfit, setShowProfit] = useState(getValueLocalStorage("showProfitTracking", true));
	const [showSmooth, setShowSmooth] = useState(getValueLocalStorage("showSmoothTracking", true));
	const [inverse, setInverse] = useState(getValueLocalStorage("inverseTracking", true));
	const [weather, setWeather] = useState(getValueLocalStorage("weathereTracking", true));
	const indexes = ["all"];
	const classes = useStyles();
	const stateRef = useRef();
	const [loading, setLoading] = useState(false);
	const dataMarkets = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, status?.toLowerCase(), inverse ? "dcluster" : "ocluster", "toplanes")
	);
	const subaccounts = useMemo(() => JSON.parse(localStorage.getItem("subAccounts")), []);
	const [hasFocus, setHasFocus] = useState(false);
	const isCarrier = isRoleHasFullCarrierAccess();

	const data = useMemo(() => {
		if (status !== "NO_MARKETS") {
			return props?.data?.filter((truck) => truck.equipment === status);
		}
		return props?.data;
		// eslint-disable-next-line
	}, [status, props.data]);

	const heatMap = useHeatMap({
		props: {
			activeHeatMap: status !== "NO_MARKETS",
			equipment: status === "NO_MARKETS" ? "VAN" : status,
			showProfit,
			showSmooth,
			inverse,
			map: `liveTrackingMap-${props?.id}`,
			weather,
		},
	});
	const heatMapString = useMemo(() => JSON.stringify(heatMap), [heatMap]);

	const checkBox = (title, setValue, value, type, description) => {
		return (
			<>
				<div className="flex flex-row ">
					<Switch
						checked={value}
						onChange={(event) => {
							setValue(event.target.checked);
							localStorage.setItem(type, event.target.checked);
						}}
					/>
					<div className="flex flex-col flex-1">
						<Typography className="flex pt-10">{title}</Typography>
						<Typography className="text-grey-600 -mt-4 text-12">{`${description}`}</Typography>
					</div>
				</div>
			</>
		);
	};

	useEffect(() => {
		if (dataMarkets?.heatMap?.length === 0 && status !== "NO_MARKETS") {
			setLoading(true);
		}
		if (["rejected"]?.includes(dataMarkets?.status) || dataMarkets?.heatMap?.length > 0) {
			setLoading(false);
		}
	}, [dataMarkets, status]);

	useEffect(() => {
		hasFocus && globalMap?.scrollZoom?.enable?.();
	}, [hasFocus, globalMap]);

	useEffect(() => {
		const myMap = new TrimbleMaps.Map({
			container: `live-tracking-map-${props?.id}`,
			center: new TrimbleMaps.LngLat(-93, 39),
			zoom: 3.5,
			region: TrimbleMaps.Common.Region.NA,
			scrollZoom: false,
		});

		stateRef.current = { sourceIds: [], statuses: [] };

		setGlobalMap(myMap);

		// eslint-disable-next-line
	}, [heatMapString, showTrips, weather, data]);

	useEffect(() => {
		let mpiRenderingTimeout;
		if (globalMap) {
			stateRef.current.statuses.push(status);
			mpiRenderingTimeout = setTimeout(() => {
				try {
					heatMap?.forEach((data, index) => {
						if (!!stateRef.current.statuses && !stateRef.current.statuses.includes(status)) return;
						const geoJsonData = {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: [
									{
										type: "Feature",
										properties: {},
										geometry: {
											type: "Polygon",
											coordinates: [data.polygon],
										},
									},
								],
							},
						};

						// Add GeoJSON data source to the map
						globalMap.addSource("hqSource" + index + status, geoJsonData);

						// Add a layer to draw circles for each point in the data source
						globalMap.addLayer({
							id: "hqPoly" + index + status,
							type: "fill",
							source: "hqSource" + index + status,
							paint: {
								"fill-color": data?.infoColor?.fillColor ?? "rgba(255, 255, 255, 0)",
								"fill-opacity": 0.5,
							},
						});
						// Add a black outline around the polygon.
						globalMap.addLayer({
							id: "hqoutline" + index + status,
							type: "line",
							source: "hqSource" + index + status,
							layout: {},
							paint: {
								"line-color": data?.infoColor?.strokeColor ?? "rgba(255, 255, 255, 0)",
								"line-width": 1,
							},
						});

						// Listen for clicks on the hqPoints layer
						globalMap.on("click", "hqPoly" + index + status, function (evt) {
							//eslint-disable-next-line
							const popupLocation = {
								lng: data.polygon[0][0],
								lat: data.polygon[0][1],
							};
							const popupContent = data.infoWindow.content;

							new TrimbleMaps.Popup().setLngLat(evt.lngLat).setHTML(popupContent).addTo(globalMap);
						});

						// Change cursor when hovering over a feature on the hqPoints layer
						globalMap.on("mouseenter", "hqPoly" + index + status, function () {
							globalMap.getCanvas().style.cursor = "pointer";
						});

						// Change cursor back
						globalMap.on("mouseleave", "hqPoly" + index + status, function () {
							globalMap.getCanvas().style.cursor = "";
						});
					});
				} catch (e) {
					console.error("Failed to render heat map", e);
				}
			}, 500);
		}
		try {
			if (globalMap && data.length > 0) {
				data?.forEach((truck, index) => {
					if (!!stateRef.current.sourceIds && stateRef.current.sourceIds.includes(truck.id)) return;
					let stopPoint = {
						type: "FeatureCollection",
						features: [
							{
								type: "Feature",
								properties: {},
								geometry: {
									type: "Point",
									coordinates: [truck?.truckLocationCoords?.long, truck?.truckLocationCoords.lat],
								},
							},
						],
					};

					let stopPoints = [];
					let myRoute;
					if (showTrips) {
						if (truck.allLocations?.length > 0) {
							stopPoints = truck.allLocations.map((location) => {
								return {
									type: "FeatureCollection",
									features: [
										{
											type: "Feature",
											properties: {},
											geometry: {
												type: "Point",
												coordinates: [
													location?.coordinates?.long ?? location?.lng,
													location?.coordinates?.lat ?? location?.lat,
												],
											},
										},
									],
								};
							});
							const stops = truck.allLocations.map((location) => {
								return new TrimbleMaps.LngLat(location?.coordinates?.long, location?.coordinates?.lat);
							});

							myRoute = new TrimbleMaps.Route({
								stops: stops,
								dataVersion: TrimbleMaps.Common.DataVersion.NA,
								useSites: true,
								routeColor: randomColor({
									hue: "random",
								}),
								showStops: false,
							});
						}
					}

					globalMap.on("load", function () {
						if (showTrips) {
							let i = 0;
							let stopsTrip = 1;
							for (let stopPoint of stopPoints) {
								if (indexes?.includes("all")) {
									globalMap.addSource("tripPoint" + i + index, {
										type: "geojson",
										data: stopPoint,
									});
									globalMap.addLayer({
										id: "tripPoint" + i + index,
										source: "tripPoint" + i + index,
										type: "symbol",
										paint: {
											"text-color": "#ffffff",
										},
										layout: {
											"icon-ignore-placement": true,
											"icon-image": "circle-fill-orange",
											"text-field": stopsTrip.toString(),
										},
									});
									stopsTrip++;
									i++;
								}
							}
							if (indexes?.includes("all")) {
								myRoute.addTo(globalMap);
							}
						}

						//set weather radar
						globalMap.setWeatherRadarVisibility(weather);

						// WORKAROUND: map is not rendeder in an optimal way, we have to add logic to prevet
						// re-rendering the same map points
						stateRef.current.sourceIds.push(truck.id);
						globalMap.addSource("stopPoint_" + truck.id + index, {
							type: "geojson",
							data: stopPoint,
						});

						let warningTracking = moment.duration(moment().diff(moment(truck.truckLastLocationDate))).asHours();
						globalMap.addLayer({
							id: "stopPoint_" + truck.id + index,
							source: "stopPoint_" + truck.id + index,
							type: "symbol",
							paint: {
								"text-color": "#ffffff",
							},
							layout: {
								"icon-ignore-placement": true,
								"icon-image": warningTracking > 4 ? "truck-fill-red" : "truck-fill-blue",
							},
						});
						globalMap.on("click", "stopPoint_" + truck.id + index, function (evt) {
							const popupLocation = evt.features[0].geometry.coordinates.slice();
							const popupContent = `
									<div class="px-8 py-4">
										<div class="flex flex-row items-center mt-4">
											<span class="text-grey-800 material-icons MuiIcon-root-667 text-20 mr-14 self-center">
												local_shipping
											</span>
											<p class="text-grey-800 font-sans font-medium text-13 mb-2 leading-tight">
												#${truck.truckId} ${truck?.driver ? "- " + truck?.driver : ""}
											</p>
										</div>
										<div class="flex flex-row items-center mt-12">
											<span class="text-grey-800 material-icons MuiIcon-root-667 text-20 mr-14 self-center">
												location_on
											</span>
											<p class="text-grey-800 font-sans font-medium text-13 mb-2 leading-tight">
												#${truck?.loadId?.trim()} - ${truck?.truckLocation?.toUpperCase()}
											</p>
										</div>
										<div class="flex flex-row items-center mt-12">
											<span class="text-grey-800 material-icons MuiIcon-root-667 text-20 mr-12 self-center">
												cached
											</span>
											<p class="text-grey-800 font-sans pl-2 text-13 contents font-medium">
												Tracked  
											</p>
											<p class="${warningTracking > 4 ? "text-red-800" : "text-grey-800"} text-13 contents font-sans font-medium">
											${formatAge(truck.truckLastLocationDate)} ago  
										</p>
										</div>
									</div>
								`;
							new TrimbleMaps.Popup({ contentDisplayClass: "pr-20" })
								.setLngLat(popupLocation)
								.setHTML(popupContent)
								.addTo(globalMap);
						});

						globalMap.on("mouseenter", "stopPoint_" + truck.id + index, function () {
							globalMap.getCanvas().style.cursor = "pointer";
						});

						// Change cursor back
						globalMap.on("mouseleave", "stopPoint_" + truck.id + index, function () {
							globalMap.getCanvas().style.cursor = "";
						});
					});
				});
			}
		} catch (e) {
			console.error("[LiveTrackingContainer] failed to generate map", e);
		}
		return () => {
			clearTimeout(mpiRenderingTimeout);
		};
		// eslint-disable-next-line
	}, [globalMap, data]);

	return (
		<div className="h-full">
			<div className="flex flex-row space-x-4 pt-2 pb-8 items-center px-4">
				<div className="flex flex-col flex-1">
					<Typography className="font-medium text-16 text-black">Live Tracking</Typography>
					<Typography className="-mt-4 text-12 text-grey-600">{`${data?.length} trips found`}</Typography>
				</div>
				{(!dataMarkets || dataMarkets?.status === "rejected") && status !== "NO_MARKETS" && (
					<div className="flex justify-center items-center pr-20">
						<Typography className="font-normal text-12 text-center" color="error">
							{`Currently we do not have enough information for ${status} equipment.`}
						</Typography>
					</div>
				)}
				{subaccounts?.length && isCarrier && (
					<AccountCarrierSelect classes={{ select: "py-10" }} onChange={(val) => props?.setSelectedCarrier?.(val)} />
				)}
				<Select
					classes={{ select: "py-10" }}
					value={status}
					variant="outlined"
					onChange={(e) => {
						setStatus(e.target.value);
						localStorage.setItem("statusTracking", e.target.value);
					}}
				>
					<MenuItem value="NO_MARKETS">
						<Typography display="inline">Only Tracking</Typography>
					</MenuItem>
					<MenuItem value="VAN">
						<Typography display="inline">Van Market</Typography>
					</MenuItem>
					<MenuItem value="REEFER">
						<Typography display="inline">Reefer Market</Typography>
					</MenuItem>
					<MenuItem value="FLATBED">
						<Typography display="inline">Flatbed Market</Typography>
					</MenuItem>
				</Select>
				<SmarthopMenuContainer
					key={`key-${props?.id}`}
					label={""}
					icon={"more_vert"}
					IconButton={true}
					button={{
						color: "inherit",
					}}
					renderOnce={true}
				>
					{checkBox("Show all trips path", setShowTrips, showTrips, "showTripsTracking", "Show all trips path")}
					{checkBox("Weather", setWeather, weather, "weathereTracking", "Show weather radar")}
					{checkBox("MPI/Profit", setShowProfit, showProfit, "showProfitTracking", "MPI or Nominal Profit color scale")}
					{checkBox("Weekly/Today", setShowSmooth, showSmooth, "showSmoothTracking", "7 days average or today's data")}
					{checkBox("Inbound/Outbound", setInverse, inverse, "inverseTracking", "Show markets going out or into")}
				</SmarthopMenuContainer>
				{loading && (
					<div className="">
						<CircularProgress style={{ color: "#707070" }} size={20} />
					</div>
				)}
			</div>
			<div id={`live-tracking-map-${props?.id}`} className={classes.mapRadius + " overflow-hidden relative "}>
				{!hasFocus && (
					<div
						onClick={() => setHasFocus(true)}
						className="cursor-pointer absolute m-auto bg-white text-blue-700 rounded-lg px-10 border-1 opacity-70 border-blue py-6 text-grey right-10 top-10 z-40"
					>
						Click To Enable Zoom
					</div>
				)}
			</div>
		</div>
	);
}

export default LiveTrackingContainer;
