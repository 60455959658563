import NativeTable from "./NativeTable";

const config = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: "/native/table",
			component: NativeTable,
		},
	],
};

export default config;
