const chat = {
	urlGET: "api/messenger/chats/:id",
	urlDELETE: "api/messenger/chats/:id",
	idKey: "_id",
	triggerEvent: "chatRevision",
	listenEvent: "chatRevision",
	header: {
		delete: { label: "Delete Chat" },
		view: { label: "View Chat" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) =>
						`Would you like to permanently delete chat with ID '${item._id}' created for carrier id 
						'${item?.metadata?.truck?.carrierid ?? "<Not Found>"}' and truck id '${
							item?.metadata?.truck?.truckid ?? "<Not Found>"
						}'?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};

export default chat;
