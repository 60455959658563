import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMap, selectByType } from "app/store/marketConditions/mapSlice";
import { polygonColors } from "app/main/utils/colorUtils";
import moment from "moment";

const useHeatMap = ({ props, mapObj, marketMapEnabled, marketMapEquipment }) => {
	props = props ?? {};
	const data = props.data;
	const equipment =
		marketMapEquipment?.toLowerCase() ?? data?.equipment?.toLowerCase() ?? props.equipment?.toLowerCase();
	const activeHeatMap = marketMapEnabled ?? data?.activeHeatMap ?? !!props?.activeHeatMap;

	const dispatch = useDispatch();
	const showProfit = props.showProfit ?? false;
	const showSmooth = props.showSmooth ?? true;
	const field = props.inverse ? "dcluster" : "ocluster";
	const toplanes = props.toplanes || props.toplanes === undefined ? "toplanes" : "top100";
	const step = props.step ?? 1;
	const highlighted = props.highlighted ?? [];

	const mapData = useSelector(({ marketConditions }) => selectByType(marketConditions, equipment, field, toplanes));
	const [polygons, setPolygons] = useState([]);

	const mapDataError = mapData?.errors;

	const removePolygons = () => {
		if (mapObj?.map || mapObj?.maps) {
			polygons.forEach(({ polygon, infowindow }) => {
				if (polygon?.setMap) {
					polygon.setMap(null);
				}
				if (infowindow?.close) {
					infowindow?.close();
				}
			});
		}

		setPolygons([]);
	};

	useEffect(() => {
		if (mapDataError) {
			removePolygons();
		}

		// eslint-disable-next-line
	}, [mapDataError]);

	useEffect(() => {
		if (activeHeatMap && equipment && (equipment === "van" || equipment === "reefer" || equipment === "flatbed")) {
			if (
				!mapData?.expirationDate ||
				(mapData?.expirationDate && moment().diff(mapData?.expirationDate, "minutes") > 60)
			) {
				dispatch(fetchMap({ equipment, field, toplanes }));
			}
		} else if (!activeHeatMap) {
			polygons && removePolygons();
		}
		// eslint-disable-next-line
	}, [mapObj?.map, equipment, activeHeatMap, field, toplanes]);

	useEffect(() => {
		if (mapData?.heatMap?.length && activeHeatMap && mapData?.status === "fulfilled") {
			removePolygons();

			const updatedPolygons = [];
			if (mapObj?.map && mapObj?.maps) {
				const { map, maps } = mapObj;
				mapData?.heatMap?.forEach((market) => {
					if (highlighted?.length && !highlighted.includes(market.cluster)) return;

					let p = new maps.Polygon({
						map,
						path: market.polygon,
						strokeOpacity: 0.7 + market.vol_perc * 0.3,
						strokeWeight: 1.3 + (market.vol_perc * 2 ?? 0),
						fillOpacity: 0.4,
					});

					const { infoWindowTest, colorInfo } = getPolygonsData(market);
					p.setOptions(colorInfo);
					const infowindow = new maps.InfoWindow(infoWindowTest);
					maps.event.addListener(p, "click", function (event) {
						infowindow.setPosition(event.latLng);
						infowindow.open({
							map,
							shouldFocus: false,
						});
					});

					updatedPolygons.push({
						infowindow,
						polygon: p,
					});
				});
			} else {
				mapData?.heatMap?.forEach((market) => {
					let p = market.polygon.map((poly) => {
						return [poly.lng, poly.lat];
					});

					const { infoWindowTest, colorInfo } = getPolygonsData(market);

					updatedPolygons.push({
						infoWindow: infoWindowTest,
						infoColor: colorInfo,
						polygon: p,
					});
				});
			}

			setPolygons(updatedPolygons);
		}
		// eslint-disable-next-line
	}, [mapData?.heatMap, mapObj?.map, mapObj?.maps, activeHeatMap, showProfit, showSmooth, step, field]);

	const getPolygonsData = (market) => {
		let p_data = {
			indexID: market.cluster,
			mci: (market["profit_best_norm" + (showSmooth ? "_smooth" : "")] * 100).toFixed(0),
			mvi: (market["vol_perc" + (showSmooth ? "_smooth" : "")] * 100).toFixed(0),
			profit_expected: market["profit_best_exp" + (showSmooth ? "_smooth" : "")]?.toFixed(2),
			rpm_expected: market["rpm_best_exp" + (showSmooth ? "_smooth" : "")]?.toFixed(2),
			ppm_expected: market["ppm_best_exp" + (showSmooth ? "_smooth" : "")]?.toFixed(2),
			main_city: market.main_city,
			last_update: moment.parseZone(market.last_update).utc(true).format("LLL"),
			volume: market.volume,
			vol_evid: (market.vol_evid * 100).toFixed(0),
			next_steps_expected: step > 1 ? market["profit_best_steps_acum_" + step] : "N/A",
		};

		let infoWindowTest;
		let colorInfo;
		const metric =
			step > 1
				? market["profit_best_steps_norm_" + step]
				: showSmooth
				? market?.profit_best_norm_smooth
				: market?.profit_best_norm;

		if (metric >= 0) {
			const colorOpt = metric > 0 ? metric.toFixed(2) : 0.01;
			colorInfo = {
				strokeColor: polygonColors(
					colorOpt,
					step,
					Number(p_data.profit_expected),
					p_data.next_steps_expected,
					market?.equipment,
					showProfit
				),
				fillColor: polygonColors(
					colorOpt,
					step,
					Number(p_data.profit_expected),
					p_data.next_steps_expected,
					market?.equipment,
					showProfit
				),
			};

			infoWindowTest = {
				content:
					"<b>#" +
					p_data.indexID +
					", " +
					p_data.main_city +
					"</b> " +
					(showSmooth ? "(Smoothed)" : "") +
					"<br>" +
					"MPI: " +
					p_data.mci +
					" - MVI: " +
					p_data.mvi +
					"<br>" +
					"Exp Profit: " +
					p_data.profit_expected +
					"<br>" +
					"Future Profit: " +
					p_data.next_steps_expected +
					"<br>" +
					"PPM: " +
					p_data.ppm_expected +
					"<br>" +
					"RPM: " +
					p_data.rpm_expected +
					"<br>" +
					"Volume: " +
					p_data.volume +
					"<br>" +
					"Evidence: " +
					p_data.vol_evid +
					"%<br>" +
					"<small>Last update: " +
					p_data.last_update +
					"</small>",
			};
		} else {
			colorInfo = {
				strokeColor: "#ffffff",
				fillColor: "#ffffff",
				fillOpacity: 0.2,
			};

			infoWindowTest = {
				content:
					"<b>#" +
					p_data.indexID +
					", " +
					p_data.main_city +
					"</b><br>" +
					"<br>We continue to collect information.<br><br>" +
					"Volume: " +
					p_data.volume +
					"<br>" +
					"Evidence: " +
					p_data.vol_evid +
					"%<br>" +
					"<small>Last update: " +
					p_data.last_update +
					"</small>",
			};
		}

		return { infoWindowTest, colorInfo };
	};

	if (!mapObj?.map && !mapObj?.maps) {
		return [...polygons];
	}
};

export default useHeatMap;
