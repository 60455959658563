import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { createBrokerMessageTitle } from "app/main/utils/brokerUtils";

function ViewTitleSection(props) {
	const variant = props.variant;
	const incoming = props.incoming;
	const title = props.title;
	const message = props.message;
	const badge = props.badge;
	const { brokerMC, broker, loadOwner } = props;
	const titleTextClasses = "text-14 md:text-16 font-medium";

	return (
		<div className="flex flex-col">
			<div className={"flex items-center w-full mt-2 mr-4" + (variant === "notification" ? " pr-44 " : "  ")}>
				<div className="flex flex-col w-full">
					<div className="flex flex-row w-full">
						{(broker || brokerMC || loadOwner) && (
							<div className="flex flex-1">
								{createBrokerMessageTitle(brokerMC, broker, loadOwner, titleTextClasses)}
							</div>
						)}
						{title && <Typography className={"flex flex-1 " + titleTextClasses}>{title}</Typography>}
						{badge && (
							<Typography
								className={
									"flex font-semibold rounded-full truncate h-24 py-2 px-12 -mt-3 " +
										(!props.classess?.badge?.includes("bg-") ? " bg-blue " : "") +
										(!props.classess?.badge?.includes("text-") ? " text-white text-12 md:text-13 " : "") +
										props.classess?.badge ?? ""
								}
							>
								{badge}
							</Typography>
						)}
					</div>
					{message && (
						<Typography className="font-normal text-12 md:text-13 mt-4 max-w-450 md:max-w-600 lg:max-w-450">
							{message}
						</Typography>
					)}
				</div>
			</div>
			<Divider className={"mt-4 mb-6 " + (incoming ? "" : "bg-grey-400")} />
		</div>
	);
}

export default ViewTitleSection;
