import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { capitalizeFirstLetter } from "app/main/utils/tableUtils";
import { getRoleLabel } from "app/services/LoginService";
import { showSnackbar } from "app/main/utils/snackbarUtil";

const isAdmin = getRoleLabel() === "administrator";

const smartHopConfirmCardFormTemplate = (action) => ({
	urlPOST: `api/wallet/${isAdmin ? "admin/" : ""}cards/:id/${action}`,
	idKey: "id",
	header: {
		create: { label: `${capitalizeFirstLetter(action)} Card` },
	},
	messages: { create: { success: "Card updated." } },
	content: {
		create: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to ${action?.toLowerCase()} this card?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "SUBMIT", button: { alwaysEnabled: true }, label: "Accept" },
						],
					},
				},
			],
			onFinished: (_data, dispatch) => {
				dispatch(incrementDataRevision({ event: "smarthopWalletRevision" }));
			},
			onError: (errors, dispatch, snackbar) => {
				showSnackbar(snackbar, `Something went wrong.`, "error", 5000);
			},
		},
	},
});

const smartHopConfirmCardReplacementForm = smartHopConfirmCardFormTemplate("replace");
const smartHopConfirmCardFreezeForm = smartHopConfirmCardFormTemplate("freeze");
const smartHopConfirmCardUnFreezeForm = smartHopConfirmCardFormTemplate("unfreeze");
const smartHopConfirmCardCloseForm = smartHopConfirmCardFormTemplate("close");

export {
	smartHopConfirmCardReplacementForm,
	smartHopConfirmCardFreezeForm,
	smartHopConfirmCardUnFreezeForm,
	smartHopConfirmCardCloseForm,
};
