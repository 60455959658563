import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const loadsAdapter = createEntityAdapter({
	selectId: (item) => item.tripid,
});

export const { selectAll: selectLoads, selectById: selectLoadsById } = loadsAdapter.getSelectors(
	(state) => state.loads
);

const loadsSlice = createSlice({
	name: "loads/list",
	initialState: loadsAdapter.getInitialState(),
	reducers: {
		setLoad: loadsAdapter.upsertOne,
	},
});

export const { setLoad } = loadsSlice.actions;
export default loadsSlice.reducer;
