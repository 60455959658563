import { combineReducers } from "@reduxjs/toolkit";
import chats from "./chatSlice";
import messages from "./messageSlice";
import status from "./statusSlice";

const reducer = combineReducers({
	chats,
	messages,
	status,
});

export default reducer;
