import Typography from "@material-ui/core/Typography";

export function formaterItinerary(item) {
	return (
		<div className="w-full">
			<Typography className="font-semibold" paragraph={true}>
				<pre style={{ fontFamily: "inherit" }}>{item}</pre>
			</Typography>
		</div>
	);
}
