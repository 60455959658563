import { useEffect } from "react";
import { SmarthopList, internalTripsList } from "@smarthop/list";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

const TripsNeedPod = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Trips");
		// eslint-disable-next-line
	}, []);
	const { userId, carrierId } = dataIds;
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const isInvoicingEnabled = useSelector(
		({ auth }) =>
			auth?.user?.rolesLabels?.includes("administrator") ||
			auth?.user?.enterpriseFeatures?.invoicingModuleOnboarding === "COMPLETE"
	);

	return (
		<div>
			<SmarthopList
				key="trips"
				urlKey="trips"
				mode="TABLE"
				config={internalTripsList(
					user.rolesLabels[0],
					dispatch,
					isInvoicingEnabled,
					true,
					false,
					false,
					hasEditTripsPermission
				)}
				dataIds={{ userId, carrierId }}
				filters={{
					carriers_type: "__NOT_SELECTED__",
					createdAt: "__DATE_DAYS_GT__30",
					trip_status: "__DELIVERED__",
					pod_file_status: "__NOT_POD_FILE__",
				}}
			/>
		</div>
	);
};
export default TripsNeedPod;
