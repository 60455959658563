import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
	name: "menu",
	initialState: {
		menuPanel: [],
		highlightId: null,
	},
	reducers: {
		openMenuPanel: (state, action) => {
			if (!action?.payload?.menuId) return;

			state.menuPannel = [
				{
					menuId: action.payload?.menuId,
					dataIds: action.payload?.dataIds,
					data: action.payload?.data,
				},
			];
		},
		closeMenuPanel: (state) => {
			state.menuPannel = [];
			state.highlightId = null;
		},
		setHighlightItem: (state, action) => {
			state.highlightId = action.payload?.id;
		},
	},
});

export const { openMenuPanel, closeMenuPanel, setHighlightItem } = menuSlice.actions;

export default menuSlice.reducer;
