import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography, CircularProgress, Icon, Button, Checkbox } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { PLAN_SELECTED, PLAN_CHANGED } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

// Stores
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { closeAllDialogs } from "app/store/tools/formDialogSlice";
import { setUserData } from "app/store/auth/userSlice";

//Services
import {
	getPlatformPublicPlanById,
	getCarrierDefaultPaymentMethod,
	assignCarrierPlatformPlan,
	getPlatformPlanForCarrier,
	getPlatformPublicPlans,
} from "app/services/platformPlanServices";
import { addPaymentMethod } from "app/services/billingServices";

// Utils
import { processRestrictionsDowngradeTier } from "app/main/utils/rolesUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { signInWithToken } from "app/services/LoginService";
import { getCarrierCoupon } from "app/services/couponServices";

// Components
import BannerAlertView from "app/main/alerts/BannerAlertView";
import PlatformPlanPrice from "./PlatformPlanPrice";
import PlatformPlanHeader from "./PlatformPlanHeader";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const createOptions = (fontSize) => ({
	style: {
		base: {
			fontSize,
			color: "#424770",
			letterSpacing: "0.025em",
			fontFamily: "Source Code Pro, monospace",
			"::placeholder": { color: "#b1b1b1" },
		},
		invalid: { color: "#fa755a" },
	},
});

function PlatfromPlanTerms(prop) {
	return (
		<div className="flex flex-wrap items-center pt-12 px-4">
			<Typography className="pr-4 text-12 text-grey-700">
				{prop.isTrialAllowed ? "Trial offer is for new and eligible returning subscribers only. " : ""}Cancel anytime
				through plan settings at least a day before each renewal date. Plan automatically renews each month until
				cancelled.
			</Typography>
		</div>
	);
}

const AlertMessage = (props) => {
	const componentMessage = (
		<>
			<Icon className={"text-24 mr-14 text-yellow-900"}>{"warning"}</Icon>
			<div className="flex flex-col">
				<span className="my-0 text-13 font-semibold">To downgrade to a lower plan you must:</span>
				<ul className="my-0">
					{props?.messages?.map((message) => {
						return (
							<li className="my-0 " disablePadding={true} disableGutters={true}>
								<span className="my-0 text-13 font-semibold">{message}</span>
							</li>
						);
					})}
				</ul>
			</div>
		</>
	);

	const banner = {
		severity: "warning",
		componentMessage,
		bannerClass:
			"flex items-center justify-center intems-center mb-8 h-42 border-yellow-900 text-yellow-900 border-2 rounded-16 bg-yellow-50",
	};
	return <BannerAlertView banner={banner} startView={true} noWrap={false} />;
};

const PlanNewSubscribeComponent = (props) => {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const stripe = useStripe();
	const elements = useElements();

	const update = props.update;
	const carrierId = props.carrierId;
	const planId = props.planId;
	const priceId = props.priceId;
	const isTrialAllowed = props.isTrialAllowed;
	const tier = props.tier;
	const callback = props.callback;
	const onDone = props.onDone;
	const onProgress = props.onProgress;

	// eslint-disable-next-line
	let _element = React.createRef();

	const [noCCRequired, setNoCCRequired] = useState(false);
	const [noCCProvided, setNoCCProvided] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		setNoCCProvided(!e.complete);
	};

	const handleSubscribe = async () => {
		onProgress?.(false);
		setLoading(true);

		try {
			if (!noCCRequired) {
				const cardElement = elements.getElement(CardElement);
				let payload = await stripe.createSource(cardElement, { type: "card" });
				if (!payload?.source?.id) {
					throw new Error("Failed to add creadit card, please try again later...");
				}
				await addPaymentMethod(carrierId, { sourceId: payload.source.id, allow: true });
			}

			createTrackOrPage(PLAN_SELECTED, { planId, priceId, carrierId, isTrialAllowed }, "track");
			if (update) createTrackOrPage(PLAN_CHANGED, { planId, priceId, carrierId, isTrialAllowed }, "track");

			await assignCarrierPlatformPlan(carrierId, planId, priceId, isTrialAllowed, props.dataIds?.equipment);
			const userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData));

			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingMoveToSummaryRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			dispatch(incrementDataRevision({ event: "planRevision" }));
			dispatch(closeAllDialogs());
			showSnackbar(snackbar, "Successful subscription.", "success", {
				duration: 5000,
			});

			// Mobile
			const reactNative = window?.ReactNativeWebView ?? false;
			if (reactNative) {
				onDone?.();
				window?.ReactNativeWebView?.postMessage(
					JSON.stringify({ type: "REFRESH_PLATFORM_PLAN", data: { tier: tier } })
				);
				return;
			}

			if (callback) {
				dispatch(closeAllDialogs());
				if (callback?.restriction?.(tier)) {
					return callback.function?.(dispatch);
				}
			} else {
				setTimeout(() => onDone?.(), 100);
			}
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error", {
				duration: 5000,
			});
		} finally {
			onProgress?.(false);
			setLoading(false);
		}
	};

	const actionLabel = update ? "Change Plan" : "Select Plan";
	return (
		<div className="flex flex-col w-full mt-10 px-6">
			{isTrialAllowed && (
				<div className="flex w-full flex-row items-center -ml-10">
					<Checkbox
						checked={noCCRequired}
						onChange={(event, value) => {
							setNoCCRequired(value);
						}}
					/>
					<Typography className="flex flex-1 text-12 ml:text-13 items-center">
						I will provide Credit Card later during Free Trial
					</Typography>
				</div>
			)}
			<div
				className={
					"bg-grey-400 bg-opacity-100 shadow-sm p-1 rounded-md mt-8 mb-20 " + (noCCRequired ? " opacity-40 " : "")
				}
			>
				<div className="bg-white p-10">
					<CardElement onChange={(e) => handleChange(e)} {...createOptions(15)} onReady={(c) => (_element = c)} />
				</div>
			</div>
			<div className="flex w-full flex-col items-top justify-center pb-6">
				<Button
					className="flex w-full"
					variant="contained"
					color="secondary"
					aria-label="Subscribe"
					disabled={(noCCProvided && !noCCRequired) || loading}
					onClick={handleSubscribe}
				>
					{!loading ? actionLabel : <CircularProgress color="inherit" size="1.8em" />}
				</Button>
				<PlatfromPlanTerms isTrialAllowed={isTrialAllowed} />
			</div>
		</div>
	);
};

function PlatformPlanView(props) {
	useEffect(() => {
		props?.setTitle?.("Billing Plan");
		props?.setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const user = useSelector(({ auth }) => auth.user);
	const subAccount = useSelector(({ auth }) => auth.account.subAccount);

	const carrierId = props.dataIds.carrierId;
	const planId = props.dataIds.planId;
	const priceId = props.dataIds.priceId;
	const restrictions = props.dataIds.restrictions;
	const updatePlan = props.dataIds.updatePlan;
	const updateSoftPlan = props.dataIds.updateSoftPlan;
	const callback = props.dataIds.callback;
	const onDone = props.onDone;
	const onProgress = props.onProgress;

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const plan = planId ? await getPlatformPublicPlanById(carrierId, planId) : null;
				const [payments, current, plans, coupon] = await Promise.all([
					getCarrierDefaultPaymentMethod(carrierId),
					getPlatformPlanForCarrier(carrierId),
					getPlatformPublicPlans(carrierId),
					getCarrierCoupon(carrierId),
				]);

				setData({ plan, payments, current, plans, coupon });
			} catch (err) {
				setError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			} finally {
				onProgress?.(false);
			}
		})();

		// eslint-disable-next-line
	}, [carrierId, planId]);

	const isStillInTrial = data?.current?.subscription?.status === "trialing";
	const hasOrHasSubscriptionBefore = !!data?.current?.deactivated || !!data?.current?.subscription;
	const isTrialAllowed = (isStillInTrial || !hasOrHasSubscriptionBefore) && data?.plan?.trialDays > 0;

	const handleSubscribe = async () => {
		onProgress?.(false);
		setLoading(true);

		try {
			await assignCarrierPlatformPlan(
				carrierId,
				planId ?? data?.plan?._id,
				priceId,
				isTrialAllowed,
				props.dataIds?.equipment
			);
			const userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData, { skip_settings_set: true }));

			// Event of switching a plan
			createTrackOrPage(PLAN_SELECTED, { planId: planId ?? data?.plan?._id, carrierId, isTrialAllowed }, "track");
			if (updatePlan || updateSoftPlan) createTrackOrPage(PLAN_CHANGED, { planId, carrierId, isTrialAllowed }, "track");

			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingMoveToSummaryRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			dispatch(incrementDataRevision({ event: "planRevision" }));
			dispatch(closeAllDialogs());
			if (updatePlan) dispatch(incrementDataRevision({ event: "planChangeRevision" }));
			if (updateSoftPlan) dispatch(incrementDataRevision({ event: "planChangeRevisionSoft" }));

			showSnackbar(
				snackbar,
				isTrialAllowed
					? "Successful subscription update."
					: "Successful payment of prorated amount required to unlock features.",
				"success",
				{ duration: 5000 }
			);

			const reactNative = window?.ReactNativeWebView ?? false;
			if (reactNative) {
				onDone?.();
				window?.ReactNativeWebView?.postMessage(
					JSON.stringify({ type: "REFRESH_PLATFORM_PLAN", data: { tier: data.plan.tier } })
				);
				return;
			}

			if (callback) {
				dispatch(closeAllDialogs());
				if (callback?.restriction?.(data.plan.tier)) {
					return callback.function?.(dispatch);
				}
			} else if (updateSoftPlan) {
				dispatch(closeAllDialogs());
			} else {
				setTimeout(() => onDone?.(), 1000);
			}
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error", {
				duration: 5000,
			});
		} finally {
			onProgress?.(false);
			setLoading(false);
		}
	};

	const DowngradingAlert = () => {
		const totalSubAccounts = subAccount?.list?.length;
		const totalUsers = user?.usersRelated?.length;

		const { isEnabledToApply, failedRestrictions, restrictionsToDowngrade } = processRestrictionsDowngradeTier({
			tier: user.userTier,
			tierToDowngrade: data?.plan?.tier,
			restrictions: { LIMIT_SUBACCOUNTS: totalSubAccounts, LIMIT_USERS: totalUsers },
		});

		const warnings = [];
		if (!isEnabledToApply) {
			Object.keys(failedRestrictions).forEach((key) => {
				if (key === "LIMIT_USERS" && failedRestrictions[key]) {
					warnings.push(
						`- Stay with ${restrictionsToDowngrade[key]} user(s). You now have ${totalUsers} active user(s).`
					);
				}

				if (key === "LIMIT_SUBACCOUNTS" && failedRestrictions[key]) {
					warnings.push(
						`-  Stay with ${restrictionsToDowngrade[key]} subaccount(s). You now have ${totalSubAccounts} active subaccount(s).`
					);
				}
			});
		}

		return (
			<div className="pb-8 self-center w-fit">{warnings?.length ? <AlertMessage messages={warnings} /> : null}</div>
		);
	};

	if (error) {
		return (
			<div className="flex w-full min-h-510 items-center justify-center">
				<Typography className="text-14 text-red">{error}</Typography>
			</div>
		);
	}

	// TODO: Check if is necesary make change here
	if (
		!data?.plan &&
		(data?.current?.tier === "TIER_LIMITED" ||
			data?.current?.tier === "TIER_BASIC" ||
			data?.current?.tier === "TIER_PROFESSIONAL")
	) {
		var filtered = restrictions?.filter((restriction) => String(restriction).startsWith("TIER"));
		let newTier;
		if (filtered?.includes("TIER_ENTERPRISE")) {
			newTier = data.plans.items.find((element) => element.tier === "TIER_ENTERPRISE");
		} else if (data?.current?.tier === "TIER_LIMITED" && filtered?.includes("TIER_BASIC")) {
			newTier = data.plans.items.find((element) => element.tier === "TIER_BASIC");
		} else if (
			["TIER_BASIC", "TIER_LIMITED"].includes(data?.current?.tier) &&
			filtered?.includes("TIER_PROFESSIONAL")
		) {
			newTier = data.plans.items.find((element) => element.tier === "TIER_PROFESSIONAL");
		}
		if (newTier) setData({ ...data, plan: newTier });
	}

	const isLimitedPlan = data?.plan?.tier === "TIER_LIMITED"; // Logic to evaluate plan

	if (!data?.plan) {
		return (
			<div className="flex w-full min-h-510 items-center justify-center">
				<Typography className="text-14 text-grey-400">Loading...</Typography>
			</div>
		);
	}

	let price, isSecondaryPrice;
	if (priceId === data?.plan?.metadata?.price?.id) {
		price = data?.plan.price__view?.metadata?.stripe;
	} else if (priceId === data?.plan?.metadata?.secondary_price?.id) {
		isSecondaryPrice = true;
		price = data?.plan.secondary_price__view?.metadata?.stripe;
	}

	const actionLabel = !!data?.current?._id || data?.current?.deactivated ? "Change Plan" : "Select Plan";
	const stripePromise = loadStripe(STRIPE_KEY);

	return (
		<div className="flex w-full flex-col pt-4">
			<DowngradingAlert />
			<div className="flex w-full flex-col md:flex-row items-center md:items-start md:min-h-360 pt-6">
				<div className="flex flex-col w-full md:w-5/12 text-center">
					<PlatformPlanHeader plan={data?.plan} isTrialAllowed={isTrialAllowed} />

					<PlatformPlanPrice
						isLimitedPlan={isLimitedPlan}
						customer={data?.current}
						plan={data?.plan}
						price={price}
						coupon={data.coupon}
						period={isSecondaryPrice ? "quaterly" : "monthly"}
					/>

					{/* {isTrialAllowed && data?.plan?.trialDays > 0 && (
						<Typography className="text-15 text-green font-semibold tracking-tight mt-10 md:mt-0" color="inherit">
							No charge for {data.plan?.trialDays} days
						</Typography>
					)}

					<Typography variant="subtitle1" color="inherit" className="text-20 pt-6 pb-8 font-semibold">
						{data?.plan?.nickname}
					</Typography>
					{planView.header}

					{data?.plan?.promo && (
						<div className="flex flex-col mt-20">
							<Typography variant="subtitle1" className="text-red font-bold">
								Promotion
							</Typography>
							{data?.plan?.promoExpiresAt && (
								<Typography variant="subtitle1" className="-mt-6 text-red-400 text-13">
									Expires on {moment(data?.plan?.promoExpiresAt).format("MMM DD")}
								</Typography>
							)}
						</div>
					)} */}

					{isTrialAllowed && data?.plan?.trialDays > 0 && (
						<div className="bg-secondary mb-20 mt-20 md:mb-0 mr-12 ml-6 rounded-md">
							<Typography color="secondary" className="text-13 p-10 text-white">
								Cancel anytime you wont be charged today.
							</Typography>
						</div>
					)}
				</div>
				<div className="flex w-full flex-col pl-10 md:pl-14 pr-10 pb-4 md:w-7/12 h-full md:max-h-360 md:min-h-360 md:overflow-y-scroll overflow-x-hidden">
					{data?.plan?.description?.split("\n").map((paragraph, i) => {
						return (
							<Typography key={"paragraph_" + i} className="text-13 border-b-1 pt-4 pb-4">
								{paragraph}
							</Typography>
						);
					})}
					<div className="flex w-full flex-col pt-8">
						{data?.plan?.bulletPoints?.split("\n").map((bulletPoint, i) => {
							return (
								<Typography key={"bullet_point_" + i} className="text-13 mb-2 mt-1">
									&#x2022; {bulletPoint}
								</Typography>
							);
						})}
					</div>
				</div>
			</div>
			<div className="flex w-full flex-col md:flex-row items-center md:items-start md:min-h-150 border-t-1 mt-6">
				{!data?.payments?.source_id ? (
					<Elements stripe={stripePromise}>
						<PlanNewSubscribeComponent
							update={!!data?.current?._id}
							carrierId={carrierId}
							planId={planId ?? data?.plan?._id}
							priceId={priceId}
							isTrialAllowed={isTrialAllowed}
							tier={data?.plan?.tier}
							callback={callback}
							onDone={onDone}
							onProgress={onProgress}
						/>
					</Elements>
				) : (
					<div className="flex w-full flex-col mt-20 px-10 pb-6">
						<Typography className="text-grey text-14">Payment Method</Typography>
						<Typography className="text-14">
							{data?.payments?.payment_method__view?.label ?? "Stripe Account ID " + data?.payments?.source_id}
						</Typography>
						<Button
							className="flex w-full mt-10"
							variant="contained"
							color="secondary"
							aria-label="Subscribe"
							disabled={loading}
							onClick={handleSubscribe}
						>
							{!loading ? actionLabel : <CircularProgress color="inherit" size="1.8em" />}
						</Button>
						<PlatfromPlanTerms isTrialAllowed={isTrialAllowed} />
					</div>
				)}
			</div>
		</div>
	);
}

export default PlatformPlanView;
