import { DisplaySection, DisplayField } from "app/main/utils/uiUtils";

const getStatusFromPlanData = ({ covered, cancelled, expired, published, onsales, statusTruckPlan }) => {
	const isActivePlan = !covered && !cancelled && !expired;
	const isOnsales = isActivePlan && onsales;
	const isPublished = isActivePlan && published;
	const isCovered = covered && !cancelled && !expired;
	const isCancelled = cancelled;
	const isExpired = expired && !cancelled;
	const isChurned = expired && statusTruckPlan === "CHURNED";
	const isCoverageNotNeeded = expired && statusTruckPlan === "COVERAGE_NOT_NEEDED";

	if (isChurned) {
		return "CHURNED";
	} else if (isCoverageNotNeeded) {
		return "COVERAGE_NOT_NEEDED";
	} else if (isExpired) {
		return "EXPIRED";
	} else if (isCancelled) {
		return "CANCELLED";
	} else if (isCovered) {
		return "COVERED";
	} else if (isOnsales) {
		return "ONSALES";
	} else if (isPublished) {
		return "CURRENT";
	} else {
		return "DRAFT";
	}
};

const getStatusConfig = (status) => {
	let label;
	let icon = "help_outline";
	let color = "text-black";
	if (status === "CANCELLED") {
		label = "Last (Cancelled)";
		icon = "pause_circle_outline";
		color = "text-orange-800";
	} else if (status === "NO_TRUCK_PLAN") {
		label = "Last (No Plan)";
		color = "text-red-600";
	} else if (status === "COVERAGE_NOT_NEEDED") {
		label = "Last (No Coverage Needed)";
		icon = "alarm";
		color = "text-red-600";
	} else if (status === "CHURNED") {
		label = "Last (Churned)";
		icon = "alarm";
		color = "text-red-600";
	} else if (status === "EXPIRED") {
		label = "Last (Expired)";
		icon = "alarm";
		color = "text-red-600";
	} else if (status === "COVERED") {
		label = "Last (Covered)";
		icon = "pause_circle_outline";
		color = "text-grey-500";
	} else if (status === "ONSALES") {
		label = "Current (With Sales)";
		icon = "point_of_sale";
		color = "text-blue-700";
	} else if (status === "CURRENT") {
		label = "Edited";
		icon = "check_circle";
		color = "text-green-700";
	} else if (status === "DRAFT") {
		label = "Auto-Generated";
		color = "text-blue-700";
	} else {
		label = "Invalid Status";
		color = "text-red-600";
	}
	return { label, icon, color };
};

const getReasonConfig = (status) => {
	let label;
	let icon = null;
	let color = "text-black";
	if (status === "NEW") {
		label = "New";
	} else if (status === "CONFIRMED") {
		label = "Confirmed";
	} else if (status === "NOT_AVAILABLE") {
		label = "Delayed";
	} else if (status === "WRONG_DATE") {
		label = "Wrong Date";
	} else if (status === "ALREADY_DISPATCHED") {
		label = "Booked Elsewhere";
	} else if (status === "HOME_TIME") {
		label = "Home-Time";
	} else if (status === "NO_RESPONSE") {
		label = "Unresponsive";
	} else if (status === "CHURNED") {
		label = "Churned";
		icon = "error_outline";
		color = "text-red-600";
	} else if (status === "REJECTING_LOADS") {
		label = "Rejecting Loads";
	} else if (status === "MECHANICAL") {
		label = "Mechanical";
	} else if (status === "PENDING_HOLD") {
		label = "Pending hold";
	} else {
		label = status;
	}
	return { label, icon, color };
};

const createGoalsSection = (goals, stats) => {
	return (
		<DisplaySection title="Goals to Date">
			<div className="flex flex-row ">
				<DisplayField label="WTD Profit" value={goals?.profit_wtd} />
				<DisplayField label="WTD Gross" value={goals?.revenue_wtd} />
				<DisplayField label="WTD RPM" value={goals?.rpm_wtd} />
			</div>
			<div className="flex flex-row ">
				<DisplayField label="Profit Target" value={goals?.profit_remaining} />
				<DisplayField label="Gross Remain" value={goals?.revenue_remaining} />
				<DisplayField label="RPM Target" value={goals?.target_rpm} />
			</div>
			<div className="flex flex-row ">
				<DisplayField label="Strategy Profit" value={stats?.total_profit?.mean} />
				<DisplayField label="Strategy Gross" value={stats?.total_payment?.mean} />
				<DisplayField label="Stategy RPM" value={stats?.total_rpm?.mean} />
			</div>
		</DisplaySection>
	);
};

export { getStatusFromPlanData, getReasonConfig, getStatusConfig, createGoalsSection };
