import React from "react";
import { formatDate } from "app/main/utils/dateUtils";
import { getLogoConfig, getLogoImage } from "app/main/utils/brokerUtils";

const createOwnerView = (item) => {
	let ownerIcon = getLogoConfig(item?.owner, true);
	let ownerLogo = ownerIcon.src ? getLogoImage(ownerIcon) : null;
	return (
		<div className="whitespace-nowrap flex flex-row items-center">
			<div className={"whitespace-nowrap flex flex-col flex items-start " + (ownerIcon.aggregator ? " -mb-6 " : "")}>
				<div className="whitespace-nowrap flex flex-row">{ownerLogo}</div>
			</div>
		</div>
	);
};

const postTruckHistory = () => {
	return {
		urlGET: "api/post-truck/carriers/:carrierId/trucks/:truckId",
		idKey: "_id",
		triggerEvent: "profileRevision",
		listenEvent: "profileRevision",
		segmentKey: "POST_TRUCK_HISTORY",
		content: {
			grid: {
				theme: "light",
				tilesPerRow: 2,
				itemsPerRow: 4,
				noMinHeight: true,
				layout: [["origin", "destination", "availableDate", "equipment"], ["comments"]],
				mobile: {
					tilesPerRow: 1,
					itemsPerRow: 2,
					layout: [["origin", "destination"], ["availableDate", "equipment"], ["comments"]],
				},
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "createdAt",
					dir: "desc",
				},
			},
			filters: {
				search: false,
				items: [],
			},
			items: [
				{
					key: "owner",
					type: "component",
					label: "Broker",
					builder: (item) => createOwnerView(item),
					grid: { headerTitle: true },
				},
				{ key: "equipment", type: "text", label: "Equipment" },
				{ key: "origin", type: "text", label: "Origin" },
				{ key: "destination", type: "text", label: "Destination" },
				{
					key: "availableDate",
					type: "text",
					label: "Available date",
					builder: (item) => formatDate(item?.availableDate),
				},
				{ key: "updatedAt", type: "date", label: "Updated" },
				{ key: "comments", type: "text", label: "Comments" },
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "delete",
							label: "Unpost",
							variant: "button",
							button: {
								color: "primary",
							},
							dialogBuilder: (item) => ({
								mode: "DELETE",
								formId: "UNPOST_TRUCK_FORM",
								dataIds: { id: item._id },
							}),
						},
						{
							key: "execute_logs",
							label: "Logs",
							dialogBuilder: (item, dataIds) => ({
								mode: "view",
								viewId: "POST_TRUCK_LOGS",
								dataIds: { id: item._id, userId: dataIds.userId },
							}),
						},
					],
				},
			],
		},
	};
};
export default postTruckHistory;
