const PERMISSION_SECTION = {
	type: "section",
	label: "Permissions",
};

const INTERNAL_SECTION = {
	type: "section",
	label: "Internal",
};

const GENERIC_PERMISSIONS_CONFIGURATION = [
	PERMISSION_SECTION,
	{
		label: "Has access to staging server",
		key: "gatekeepers.has_staging_server_access", // FIXME: FAC-2052 Invalid key format
		type: "checkbox",
	},
];

const ADMIN_CONFIGURATION = [
	...GENERIC_PERMISSIONS_CONFIGURATION,
	{
		key: "gatekeepers.permission_account_impersonation_on", // FIXME: FAC-2052 Invalid key format
		type: "checkbox",
		label: "Allow Account Impersonation",
	},
];

const userConfigForm = () => ({
	urlGET: "api/profile/users/:id/config",
	urlPUT: "api/profile/users/:id/config",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit User Information" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "role",
								type: "select",
								label: "Role",
								required: true,
								options: [
									PERMISSION_SECTION,
									{
										value: "0",
										label: "Administrator",
										showItems: ADMIN_CONFIGURATION,
									},
									{
										value: "1",
										label: "Ops Dispatcher",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "12",
										label: "Ops Support",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "2",
										label: "Carrier Owner",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "21",
										label: "Carrier Driver",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "22",
										label: "Carrier Manager",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "23",
										label: "Carrier Dispatcher",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "27",
										label: "Carrier User",
										showItems: GENERIC_PERMISSIONS_CONFIGURATION,
									},
									{
										value: "9",
										label: "Bot",
									},
								],
							},
						],
					},
				},
				INTERNAL_SECTION,
				{
					type: "group",
					content: {
						items: [
							{
								key: "timezone",
								type: "text",
								label: "Timezone",
								viewOnly: true,
							},
							{
								key: "ip",
								type: "text",
								label: "IP",
								viewOnly: true,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "lastLogin.web.location",
								type: "text",
								label: "Last Web Location",
								viewOnly: true,
								builder: (item) =>
									`${item?.lastLogin?.web?.city}, ${item?.lastLogin?.web?.state}, ${item?.lastLogin?.web?.country}`,
							},
							{
								key: "lastLogin.mobile.location",
								type: "text",
								label: "Last Mobile Location",
								viewOnly: true,
								builder: (item) =>
									`${item?.lastLogin?.mobile?.city}, ${item?.lastLogin?.mobile?.state}, ${item?.lastLogin?.mobile?.country}`,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "deactivated",
								type: "checkbox",
								label: "Deactivated",
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
});
export default userConfigForm;
