const transactionCreation = (optionsType) => ({
	urlGET: "api/transaction/users/:userId/records/:id/detail-status",
	urlPUT: "api/transaction/users/:userId/records/:id/detail-status",
	idKey: "_id",
	triggerEvent: "transactionsRevision",
	listenEvent: "transactionsRevision",
	header: {
		edit: { label: "Transition to a state" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "state",
					type: "autocomplete",
					label: "State",
					translate: "",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/transaction/users/:userId/get-states",
						params: { options_type: optionsType },
						preloadDefault: true,
					},
				},
				{
					key: "status",
					type: "autocomplete",
					label: "Transaction Status",
					translate: "",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/transaction/users/:userId/get-transaction-status",
						preloadDefault: true,
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
});

export default transactionCreation;
