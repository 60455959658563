import { fuseDark } from "@fuse/colors";
import _ from "@lodash";
import { lightBlue, red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import qs from "qs";

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
	customScrollbars: true,
	direction: "ltr",
	theme: {
		main: "default",
		navbar: "mainThemeDark",
		toolbar: "mainThemeLight",
		footer: "mainThemeDark",
	},
};

/**
 * Changes theme to show narrow fields
 */
export const NARROW_FIELDS = true;

export function getParsedQuerySettings() {
	const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

	if (parsedQueryString && parsedQueryString.defaultSettings) {
		return JSON.parse(parsedQueryString.defaultSettings);
	}
	return {};

	// Generating route params from settings
	/* const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings); */
}

/**
 * THEME DEFAULTS
 */
var isSafari =
	navigator.vendor &&
	navigator.vendor.indexOf("Apple") > -1 &&
	navigator.userAgent &&
	navigator.userAgent.indexOf("CriOS") === -1 &&
	navigator.userAgent.indexOf("FxiOS") === -1;

export const defaultThemeOptions = {
	typography: {
		fontFamily: ["Poppins", "Roboto", '"Helvetica"', "Arial", "sans-serif"].join(","),
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		useNextVariants: true,
		suppressDeprecationWarnings: true,
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: "none",
				borderRadius: "18px",
				minWidth: NARROW_FIELDS ? "48px" : "64px",
			},
			sizeSmall: {
				borderRadius: "15px",
			},
			sizeLarge: {
				borderRadius: "21px",
			},
			contained: {
				boxShadow: "none",
				"&:hover, &:focus": {
					boxShadow: "none",
				},
			},
		},
		MuiTab: {
			root: {
				textTransform: "none",
			},
		},
		MuiToolbar: !NARROW_FIELDS
			? {}
			: {
					regular: {
						minHeight: "48px",
					},
			  },
		MuiTypography: !NARROW_FIELDS
			? {}
			: {
					paragraph: { marginBottom: "12px" },
			  },
		MuiFormHelperText: !NARROW_FIELDS
			? {}
			: {
					root: { fontSize: "1rem" },
			  },
		MuiInputBase: !NARROW_FIELDS
			? {}
			: {
					root: {
						lineHeight: "0.8em",
					},
					input: {
						height: "0.8em",
					},
			  },
		MuiInputLabel: !NARROW_FIELDS
			? {
					outlined: {
						transform: "translate(14px, 19px) scale(1)",
					},
					shrink: {
						transform: "translate(14px, -6px) scale(1)",
						fontSize: "1.5rem",
					},
			  }
			: {
					outlined: {
						transform: "translate(14px, 16px) scale(1)",
					},
					shrink: {
						transform: "translate(14px, -6px) scale(1)",
						fontSize: "1.45rem",
					},
			  },
		PrivateNotchedOutline: {
			legendLabelled: {
				fontSize: "1.11rem",
			},
		},
		MuiSelect: !NARROW_FIELDS
			? {}
			: {
					root: {
						height: "0.8em",
					},
					selectMenu: {
						minHeight: "0.8em",
					},
			  },
		MuiDialog: {
			paper: {
				borderRadius: 16,
			},
		},
		MuiPaper: {
			rounded: {
				borderRadius: !NARROW_FIELDS ? 16 : 12,
			},
			root: isSafari
				? {
						transition: "none !important",
				  }
				: undefined,
		},
		MuiPopover: {
			paper: {
				borderRadius: 8,
			},
		},
		// MuiFilledInput: {
		// 	root: {
		// 		borderRadius: 4,
		// 		'&:before, &:after': {
		// 			display: 'none'
		// 		}
		// 	}
		// },
		MuiFilledInput: {
			root: {
				borderRadius: 4,
				backgroundColor: "white",
				"&:before, &:after": {
					display: "none",
				},
				"&:hover": {
					backgroundColor: "white",
				},
				"&:focused": {
					backgroundColor: "white",
				},
			},
		},
	},
};

export const mustHaveThemeOptions = {
	typography: {
		htmlFontSize: 10,
		fontSize: 13,
		body1: {
			fontSize: "1.3rem",
		},
		body2: {
			fontSize: "1.3rem",
		},
	},
};

export const defaultThemes = {
	default: {
		palette: {
			type: "light",
			primary: fuseDark,
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700],
			},
			error: red,
		},
		status: {
			danger: "orange",
		},
	},
	defaultDark: {
		palette: {
			type: "dark",
			primary: fuseDark,
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700],
			},
			error: red,
		},
		status: {
			danger: "orange",
		},
	},
};

export function extendThemeWithMixins(obj) {
	const theme = createMuiTheme(obj);
	return {
		border: (width = 1) => ({
			borderWidth: width,
			borderStyle: "solid",
			borderColor: theme.palette.divider,
		}),
		borderLeft: (width = 1) => ({
			borderLeftWidth: width,
			borderStyle: "solid",
			borderColor: theme.palette.divider,
		}),
		borderRight: (width = 1) => ({
			borderRightWidth: width,
			borderStyle: "solid",
			borderColor: theme.palette.divider,
		}),
		borderTop: (width = 1) => ({
			borderTopWidth: width,
			borderStyle: "solid",
			borderColor: theme.palette.divider,
		}),
		borderBottom: (width = 1) => ({
			borderBottomWidth: width,
			borderStyle: "solid",
			borderColor: theme.palette.divider,
		}),
	};
}

export function mainThemeVariations(theme) {
	return {
		mainThemeDark: _.merge({}, theme, {
			palette: {
				type: "dark",
				background: {
					paper: "#1E2125",
					default: "#121212",
				},
				text: {
					primary: "rgb(255,255,255)",
					secondary: "rgb(229, 231, 235)",
					disabled: "rgb(156, 163, 175)",
				},
			},
		}),
		mainThemeLight: _.merge({}, theme, {
			palette: {
				type: "light",
				background: {
					paper: "#FFFFFF",
					default: "#F7F7F7",
				},
				text: {
					primary: "rgb(17, 24, 39)",
					secondary: "rgb(107, 114, 128)",
					disabled: "rgb(149, 156, 169)",
				},
			},
		}),
	};
}
