const createAnalyticsJob = {
	urlPOST: "api/analytics/users/:userId/runs",
	idKey: "_id",
	triggerEvent: "analyticsRevision",
	listenEvent: "analyticsRevision",
	header: {
		create: { label: "Start Job" },
	},
	content: {
		create: {
			items: [
				{
					key: "jobKey",
					type: "autocomplete",
					label: "Analytics Job Name",
					translate: "",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/analytics/jobs",
						preloadDefault: true,
					},
				},
				{
					key: "period",
					type: "select",
					label: "Period",
					options: [
						{
							value: "MONTH_1",
							label: "This Month",
						},
						{
							value: "MONTH_2",
							label: "This Month + Last Month",
						},
						{
							value: "MONTH_3",
							label: "This Month + 3 Prev Months",
						},
						{
							value: "MONTH_6",
							label: "This Month + 5 Prev Months",
						},
						{
							value: "YEAR_1",
							label: "This Year",
						},
						{
							value: "YEAR_2",
							label: "This Year + Last Year",
						},
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default createAnalyticsJob;
