import { fetchActions } from "app/store/actions/actionsUserSlice";
import { isCarrier } from "app/services/LoginService";

const brokers = {
	urlGET: "api/profile/carriers/:carrierId/brokers",
	urlPUT: "api/profile/carriers/:carrierId/brokers",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Edit Brokers" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			items: [
				{
					key: "ops_onboarded_brokers",
					type: "autocomplete",
					label: "Preferred Brokers",
					translate: "ONBOARDED_BROKERS",
					autocomplete: {
						provider: "smarthop",
						url: "api/consts/brokers",
						multiple: true,
						preloadDefault: true,
					},
				},
				{
					key: "ops_blacklisted_brokers",
					type: "autocomplete",
					label: "Blacklisted Brokers",
					translate: "BLACKLISTED_BROKERS",
					autocomplete: {
						provider: "smarthop",
						url: "api/consts/brokers",
						multiple: true,
						preloadDefault: true,
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "ops_onboarded_brokers",
					type: "autocomplete",
					label: "Preferred Brokers",
					disabled: "",
					autocomplete: {
						provider: "smarthop",
						url: "api/consts/brokers",
						multiple: true,
						preloadDefault: true,
					},
					rules: {
						disabled: {
							ifNotEmptyRef: "ops_no_onboarded_brokers",
						},
					},
				},
				{
					key: "ops_no_onboarded_brokers",
					type: "checkbox",
					label: "No Preferred Brokers",
					translate: "NO_ONBOARDER-BROKERS",
					rules: {
						disabled: {
							ifNotEmptyRef: "ops_onboarded_brokers",
						},
					},
				},
				{
					key: "ops_blacklisted_brokers",
					type: "autocomplete",
					label: "Blacklisted Brokers",
					autocomplete: {
						provider: "smarthop",
						url: "api/consts/brokers",
						multiple: true,
						preloadDefault: true,
					},
					rules: {
						disabled: {
							ifNotEmptyRef: "ops_no_blacklisted_brokers",
						},
					},
				},
				{
					key: "ops_no_blacklisted_brokers",
					type: "checkbox",
					label: "No Blacklisted Brokers",
					rules: {
						disabled: {
							ifNotEmptyRef: "ops_blacklisted_brokers",
						},
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
			onFinished: (data, dispatch) => {
				if (isCarrier()) {
					dispatch(fetchActions({ carrierId: data.id }));
				}
			},
		},
	},
};

export default brokers;
