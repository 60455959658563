const carrierDispatchingPlanForm = {
	urlPOST: "billing/sub-dispatching-plan",
	urlPUT: "billing/carriers/:carrierId/sub-dispatching-plan",
	urlGET: "billing/carriers/:carrierId/sub-dispatching-plan",
	idKey: "_id",
	triggerEvent: "planRevision",
	listenEvent: "planRevision",
	header: {
		create: { label: "Create Dispatching Plan" },
		edit: { label: "Edit Dispatching Plan" },
	},
	messages: {
		create: { success: "Dispatching plan added." },
		edit: { success: "Dispatching plan updated." },
	},
	content: {
		create: { sameAs: "edit" },
		view: {
			items: [
				{
					key: "plan",
					type: "text",
					label: "Plan",
					builder: (item) => item?.plan__view?.description,
				},
			],
		},
		edit: {
			items: [
				{
					key: "plan",
					type: "autocomplete",
					label: "Plan",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/carriers/:carrierId/sub-dispatching-plans",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierDispatchingPlanForm;
