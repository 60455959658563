// Redirect when click a widget
const clickWidget = (type, history) => {
	if (type === "trip") {
		history.push("/dispatching?tab=0");
	} else if (type === "goal") {
		history.push("/profile/goals");
	}
};

const parseTimeFrameOverrides = (overrides, mainTimeWindow) => {
	const parsedOverrides = Array.isArray(overrides) ? overrides : overrides ? [overrides] : [];

	const overridesToApply = parsedOverrides.filter((o) => o.timePeriod === mainTimeWindow);

	return overridesToApply;
};

export { clickWidget, parseTimeFrameOverrides };
