import { recurringDeductionItems } from "app/main/profile/drivers/EnterprisePayrollSection";

const title = "Recurring Payroll Rule";

const payrollRulesForm = {
	urlPOST: "billing/v3/carrier/:carrierId/driver/:driverId/recurrent-payment",
	urlGET: "billing/v3/recurrent-payment/:recurrentPaymentId",
	urlPUT: "billing/v3/carrier/:carrierId/driver/:driverId/recurrent-payment/:recurrentPaymentId",
	urlDELETE: "billing/v3/recurrent-payment/:recurrentPaymentId",
	idKey: "_id",
	triggerEvent: "payrollRevision",
	header: {
		view: { label: `View ${title}` },
		delete: { label: `Delete ${title}` },
		create: { label: `Create ${title}` },
		edit: { label: `Edit ${title}` },
	},
	messages: {
		create: { success: () => `${title} created.` },
		edit: { success: () => `${title} updated.` },
		delete: { success: () => `${title} deleted.` },
	},
	content: {
		view: {
			items: [
				...recurringDeductionItems({ edit: true }),
				{
					key: "deleted",
					type: "checkbox",
					label: "Deleted",
				},
			],
		},
		create: {
			items: [
				...recurringDeductionItems({ edit: true }),
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "You are going to create a recurring payroll rule. Do you want to continue?",
					},
				},
			],
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete this recurring payroll rule '${item.name}'?.`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		edit: {
			items: [
				...recurringDeductionItems({ edit: true }),
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "You are going to update a rule. Do you want to continue?",
					},
				},
			],
		},
	},
};

export default payrollRulesForm;
