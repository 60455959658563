import { useMemo, useRef, useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Divider, Tab, Tabs, Icon, Button, Typography } from "@material-ui/core";

import SmartHopMap from "app/main/profile/trips/SmartHopMap";
import { SmarthopList } from "@smarthop/list";
import { TooltipContainer } from "app/main/utils/tableUtils";
import { isVisibleForUser } from "app/main/utils/rolesUtils";
import { createTooltip } from "app/main/utils/tableUtils";

const CONTAINER_OFFSET = 270;
const CONTAINER_MAX_HEIGHT = 750;

const useStyles = makeStyles((theme) => {
	return {
		container: { height: `calc(100vh - ${CONTAINER_OFFSET}px)` },
		containerFixed: { height: CONTAINER_MAX_HEIGHT + "px" },
		mobile: { height: "calc(75vh - 100px)" },
		native: { height: `calc(90vh - 100px)` },
	};
});

const SmarthopDialogViewContainer = forwardRef(
	(
		{ nativeMobile, sideComponents, tabComponents, headerBadges, footerActions, children, processing, initTab },
		ref
	) => {
		const classes = useStyles();
		const user = useSelector(({ auth }) => auth.user);
		const [smallScreen, setSmallScreen] = useState(nativeMobile ? true : nativeMobile || window.innerWidth <= 960);
		const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 50) * 50);
		const [tabKey, setTabKey] = useState(initTab);
		const [expandButtons, setExpandButtons] = useState();
		const [refresh, setRefresh] = useState(0);

		// To avoid re-rendering map, it should be hidden with the last state
		const mapConfig = useRef(null);

		// To re-rendering not yet shown components
		const viewedKeys = useRef([]);

		// Ref subtabs
		const subTabsKey = useRef({});

		// handle screen size
		useEffect(() => {
			if (nativeMobile) return;

			function handleResize() {
				if (window.innerWidth <= 960 && !smallScreen) {
					setSmallScreen(true);
				} else if (window.innerWidth > 960 && smallScreen) {
					setSmallScreen(false);
				}
				if (parseInt(window.innerHeight / 50) * 50 !== screenHeight) {
					setScreenHeight(parseInt(window.innerHeight / 50) * 50);
				}
			}
			window.addEventListener("resize", handleResize);
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		});

		useEffect(() => {
			if (ref) {
				ref.current = {
					setTab: (key) => setTabKey(key),
				};
			}

			return () => {
				if (ref) ref.current = null;
			};
		});

		const selectedKey = useMemo(() => {
			return tabKey ?? tabComponents?.[0]?.key;
			// eslint-disable-next-line
		}, [tabKey, tabComponents, refresh]);

		const containerHight = useMemo(
			() =>
				nativeMobile
					? classes.native
					: smallScreen
					? classes.mobile
					: screenHeight > CONTAINER_MAX_HEIGHT + CONTAINER_OFFSET
					? classes["containerFixed"]
					: classes["container"],
			[nativeMobile, smallScreen, screenHeight, classes]
		);

		const tabViewsAux = (tabComponents) => {
			return tabComponents?.map((config) => {
				const { hideOnMobile, hideOnDesktop, auth } = config;
				if (hideOnMobile && hideOnDesktop) return null;
				if (hideOnMobile && smallScreen) return null;
				if (hideOnDesktop && !smallScreen) return null;
				if (auth?.length > 0 && !isVisibleForUser(auth, user)) return null;
				return (
					<Tab
						key={config.key}
						className={`flex text-11 font-bold tracking-wide min-h-36 min-w-92 items-center uppercase`}
						disableRipple
						value={config.key}
						label={
							<div className="flex flex-row items-center">
								{config.title}
								{config.labelView}
							</div>
						}
					/>
				);
			});
		};

		const contentViewAux = (tabComponents, parentKey, nested) => {
			return tabComponents?.map((config) => {
				const { hideOnMobile, hideOnDesktop, auth } = config;
				if (hideOnMobile && hideOnDesktop) return null;
				if (hideOnMobile && smallScreen) return null;
				if (hideOnDesktop && !smallScreen) return null;
				if (auth?.length > 0 && !isVisibleForUser(auth, user)) return null;

				const visible = config.key === selectedKey || config.key === subTabsKey.current[parentKey];
				if (!visible && !viewedKeys.current.includes(config.key)) return null;
				if (!viewedKeys.current.includes(config.key)) viewedKeys.current.push(config.key);

				const flex =
					config.key === selectedKey || config.key === subTabsKey.current[parentKey] ? "flex h-full w-full" : "hidden";
				const width = config.variant === "narrow" ? " mx-auto max-w-3xl " : "w-full";

				if (config.type === "LIST") {
					return (
						<div key={config.key} className={flex + " " + width}>
							<div key={config.key} className={"flex h-full w-full "}>
								<SmarthopList
									header={config.header}
									headerHeight={config.headerHeight}
									footer={config.footer}
									key={config.key}
									mode="TABLE"
									size={"FULL"}
									isView={nested ? 2 : 1}
									{...(config.params ?? {})}
								/>
							</div>
						</div>
					);
				}
				if (config.type === "TABS") {
					const selectedSubKey = subTabsKey.current[config.key] ?? config.tabComponents[0].key;
					subTabsKey.current[config.key] = selectedSubKey;
					return (
						<div key={config.key} className={flex + " " + width}>
							<div key={config.key} className={"flex flex-col w-full "}>
								<div>
									<Tabs
										value={selectedSubKey}
										onChange={(_, val) => {
											subTabsKey.current[config.key] = val;
											setRefresh(refresh + 1);
										}}
										aria-label="basic tabs example"
										indicatorColor="primary"
										textColor="inherit"
										variant="scrollable"
										scrollButtons="off"
										className="w-full min-h-36 mt-4"
										classes={{ indicator: "flex justify-center bg-transparent w-full h-full" }}
										TabIndicatorProps={{
											children: <Divider className="w-full h-full rounded-full opacity-50" />,
											style: { transitionDuration: "200ms" },
										}}
									>
										{tabViewsAux(config.tabComponents)}
									</Tabs>
								</div>
								<div className={"flex w-full ml-6 mr-0 mt-16 h-full overflow-x-hidden " + (containerHight ?? "")}>
									{contentViewAux(config.tabComponents, config.key, true)}
								</div>
							</div>
						</div>
					);
				}
				return (
					<div key={config.key} className={flex + " " + width}>
						{config.component}
					</div>
				);
			});
		};

		const badgesViews = useMemo(() => {
			return headerBadges?.length > 0 ? (
				<div className="flex items-center space-x-8">
					{headerBadges.map((badge) =>
						createTooltip(
							<Typography
								className={
									"flex flex-row items-center text-center whitespace-nowrap " +
									(badge.style?.badge?.includes("bg-none") ? "" : " px-12 py-4 rounded-full ") +
									(badge.style?.badge?.includes("bg-") ? "" : " bg-grey ") +
									(badge.style?.badge?.includes("text-") ? "" : " text-white text-12 ") +
									(badge.style?.badge ?? "")
								}
							>
								{badge.icon && <Icon className="text-16 mr-4">{badge.icon}</Icon>}
								{badge.label instanceof Function ? badge.label?.() : badge.label}
							</Typography>,
							<Typography className="text-center font-light">{badge.description}</Typography>
						)
					)}
				</div>
			) : null;
		}, [headerBadges]);

		const tabViews = useMemo(() => {
			const tabs = tabViewsAux(tabComponents);
			return tabs.filter((item) => item != null);
			// eslint-disable-next-line
		}, [tabComponents, smallScreen, refresh]);

		const contentViews = useMemo(() => {
			return contentViewAux(tabComponents);
			// eslint-disable-next-line
		}, [tabComponents, selectedKey, smallScreen, refresh]);

		const { leftActionViews, rightActionsViews, mobileActionViews, mobileCollapsibleViews } = useMemo(() => {
			const leftActionViews = [];
			const rightActionsViews = [];
			const mobileActionViews = [];
			const mobileCollapsibleViews = [];
			footerActions?.forEach((config) => {
				const { hideOnMobile, hideOnDesktop } = config;

				let createSeparator = (mobile) => {
					return <div className={"h-28 w-1 bg-grey-300"}></div>;
				};

				let createButton = (mobile) => {
					let button = (
						<TooltipContainer description={config?.description}>
							{config.component && mobile && config.style?.componentAlone ? (
								<div className="flex w-full justify-end">{config.component}</div>
							) : (
								<Button
									key={config.key}
									onClick={config.onClick}
									startIcon={config.style?.icon ? <Icon>{config.style?.icon}</Icon> : null}
									variant={
										config.style?.classes || config.style?.primary || config.style?.secondary || mobile
											? "contained"
											: "text"
									}
									className={
										"flex min-w-92 ml:min-w-120 ml:min-w-150 " +
										(config.style?.classes || config.style?.primary || config.style?.secondary || mobile
											? " text-13 md:text-12 tracking-wide " +
											  (config.style?.primary
													? ""
													: config.style?.secondary || mobile
													? " bg-primary-400 text-white "
													: "")
											: " text-13 md:text-11 font-bold text-primary tracking-wider uppercase py-6 px-20 ") +
										(mobile ? " w-full " : "") +
										(config.style?.classes ?? "")
									}
									color={config.style?.primary ? "secondary" : config.style?.secondary ? "primary" : "default"}
									disabled={config.disabled || processing}
									{...(!!config.component ? { value: "legacy", component: "span" } : {})}
								>
									{config.component}
									{config.title}
								</Button>
							)}
						</TooltipContainer>
					);

					button = (
						<label key={config.key} className={"flex " + (mobile ? " w-full " : "")} htmlFor={config.key}>
							{button}
						</label>
					);

					return button;
				};

				if (!hideOnDesktop) {
					if (config.style?.align === "RIGHT") {
						if (!config.style?.primary && !config.style?.secondary && !config.style?.classes)
							rightActionsViews.push(createSeparator(true));
						rightActionsViews.push(createButton(false));
					} else {
						leftActionViews.push(createButton(false));
						if (!config.style?.primary && !config.style?.secondary && !config.style?.classes)
							leftActionViews.push(createSeparator(true));
					}
				}
				if (!hideOnMobile) {
					if (config.style?.notCollapsible) {
						mobileActionViews.push(createButton(true));
					} else {
						mobileCollapsibleViews.push(createButton(true));
					}
				}
			});

			if (mobileCollapsibleViews.length > 0) {
				mobileActionViews.push(
					<Button
						key={"expand_button"}
						onClick={() => setExpandButtons(!expandButtons)}
						startIcon={<Icon>{!expandButtons ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>}
						variant="outlined"
						className={"flex w-full bg-grey-100 border-1"}
						style={{ minWidth: "120px" }}
					>
						{!expandButtons ? "More Actions" : "Hide Actions"}
					</Button>
				);
			}

			return { leftActionViews, rightActionsViews, mobileActionViews, mobileCollapsibleViews };
		}, [footerActions, processing, expandButtons]);

		const { sidePanel, sideRatio } = useMemo(() => {
			let open = false;
			const views = [];
			const sideRatio = { left: "w-8/12", right: "w-4/12" };

			sideComponents?.forEach((config) => {
				const { hideOnMobile, hideOnDesktop } = config;
				if (hideOnMobile && hideOnDesktop) return null;
				if (hideOnMobile && smallScreen) return null;
				if (hideOnDesktop && !smallScreen) return null;

				const visible = config.tabKeys === "ALL" || config.tabKeys?.includes?.(selectedKey);

				if (config.ratio && config.ratio.split("/")?.length === 2) {
					const right = parseInt(config.ratio.split("/")[0]);
					const total = parseInt(config.ratio.split("/")[1]);
					sideRatio.left = "w-" + (total - right) + "/" + total;
					sideRatio.right = "w-" + right + "/" + total;
				}

				if (config.type === "MAP") {
					// Map rendered statically, we only can provide
					// different values for the same map
					if (visible) {
						// Finding visible map and updating config
						if (!mapConfig.current) {
							mapConfig.current = {};
						}
						mapConfig.current.key = config.key;
						mapConfig.current.params = config.params;
						mapConfig.current.flex = "flex";
					}
				} else {
					// rendering components dynamically
					const flex = visible ? "flex w-full h-full" : "hidden";
					views.push(
						<div key={config.key} className={flex}>
							{config.component}
						</div>
					);
				}

				if (visible) open = true;
			});

			return { sidePanel: { open, views }, sideRatio };
		}, [sideComponents, selectedKey, smallScreen]);

		const sidePanelContainer = (
			<div
				className={
					sidePanel.open
						? " flex flex-col" +
						  (smallScreen
								? " w-full pb-12 "
								: " h-full pl-4 " +
								  sideRatio.right +
								  (leftActionViews?.length || rightActionsViews?.length ? " pb-12 " : " pb-4 "))
						: " hidden "
				}
			>
				{mapConfig.current && (
					<div
						className={
							`${mapConfig.current.flex} flex-row w-full relative bg-grey-200 rounded-3xl items-center ` +
							(smallScreen ? " h-160 " : " h-full ")
						}
					>
						<SmartHopMap
							provider="TRIMBLE MAPS"
							key={mapConfig.current.key ?? "side_map"}
							map={mapConfig.current.key ?? "side_map"}
							classes={{ root: "w-full h-full" }}
							{...(mapConfig.current.params ?? {})}
						/>
					</div>
				)}
				{sidePanel.views}
			</div>
		);

		return (
			<div
				className={
					"flex flex-col w-full overflow-x-hidden " +
					(nativeMobile ? " h-full h-screen " : "") +
					(processing ? " opacity-60 pointer-events-none " : "")
				}
			>
				{tabViews.length > 1 && (
					<div
						className={
							"flex justify-between w-full flex-col md:flex-row " +
							(smallScreen
								? " border-b-1 absolute top-0 left-0 bg-white " + (nativeMobile ? "" : " mt-48 md:mt-64 ")
								: "")
						}
						style={{ zIndex: 10000 }}
					>
						<div className={"w-full mt-8 md:mt-6 px-14 md:px-6 mb-10 md:mb-12"}>
							{
								<Tabs
									value={selectedKey}
									onChange={(_, val) => setTabKey(val)}
									indicatorColor="primary"
									textColor="inherit"
									variant="scrollable"
									scrollButtons="off"
									className="w-full min-h-36 mt-4"
									classes={{ indicator: "flex justify-center bg-transparent w-full h-full" }}
									TabIndicatorProps={{
										children: <Divider className="w-full h-full rounded-full opacity-50" />,
										style: { transitionDuration: "200ms" },
									}}
								>
									{tabViews}
								</Tabs>
							}
						</div>
						<div className="hidden md:flex flex-row items-center mb-10 my-4 mx-8">{badgesViews}</div>
					</div>
				)}
				<div
					className={
						smallScreen
							? " ml-6 mr-0 " + (tabViews.length === 1 ? "mt-10" : "mt-56")
							: " ml-0 mr-0 " + (tabViews.length === 1 ? "mt-10" : "")
					}
				>
					<div className={"flex w-full overflow-x-hidden " + (containerHight ?? "")}>
						<div
							className={
								"flex flex-col overflow-x-hidden overflow-y-scroll   " +
								(sidePanel.open ? (smallScreen ? " w-full " : sideRatio.left) : " w-full ")
							}
							style={{ marginBottom: !smallScreen ? 0 : 15 }}
						>
							<div
								className={"flex flex-col w-full h-full " + (smallScreen ? " pl-4 pb-10 pt-12 pr-10 " : " pl-12 pr-8 ")}
							>
								{smallScreen && sidePanelContainer}
								{smallScreen && tabComponents?.[0]?.key === selectedKey && badgesViews?.length > 0 && (
									<div className="md:hidden flex flex-row items-center px-2 min-h-36 justify-end">{badgesViews}</div>
								)}
								{contentViews}
							</div>
						</div>
						{!smallScreen && sidePanelContainer}
					</div>
					{children}
					{!smallScreen ? (
						leftActionViews?.length || rightActionsViews?.length ? (
							<div className="flex flex-col w-full border-t-1">
								<div className="w-full flex flex-row mt-12 mb-6 justify-between">
									<div className="flex flex-row space-x-8 items-center">{leftActionViews}</div>
									<div className="flex flex-row space-x-8 items-center">{rightActionsViews}</div>
								</div>
							</div>
						) : null
					) : mobileActionViews?.length || mobileCollapsibleViews?.length ? (
						<div
							className={
								"flex flex-col w-full border-t-1 space-y-8 mt-14 absolute bottom-0 left-0 bg-white px-10 " +
								(nativeMobile ? " pb-24 " : " pb-14 ")
							}
						>
							<div className="w-full flex flex-col mt-14 justify-between space-y-8">{mobileActionViews}</div>
							{expandButtons && (
								<div className="w-full flex flex-col justify-between space-y-8">{mobileCollapsibleViews}</div>
							)}
						</div>
					) : null}
				</div>
			</div>
		);
	}
);

export default SmarthopDialogViewContainer;
