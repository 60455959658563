import _ from "lodash";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { SmarthopList } from "@smarthop/list";
import Typography from "@material-ui/core/Typography";
import TransactionStateCurrentList from "app/main/profile/transactionsState/pinned/TransactionStateCurrentList";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { closePinnedPanel } from "app/store/tools/pannelsSlice";
import { markTransactionsAsRead, fetchTransactions } from "app/store/transactions/transactionsSlice";
import { getUserId, isRoleExternal } from "app/services/LoginService";

import registy from "./registy";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("md")]: {
			width: "80%",
		},
		width: 510,
	},
}));

function PinnedPanel() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const pinnedPanel = useSelector(({ tools }) => tools.pannels.pinnedPanel ?? []);
	const panelId = pinnedPanel[0]?.panelId;
	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const unreadTotalTransactions = useSelector(
		({ transactions }) => transactions.transactionsUser.unreadTotalTransactions
	);

	const userId = getUserId();
	const list = useMemo(() => {
		const panel = registy?.[panelId];
		if (_.isFunction(panel)) {
			return panel?.(isRoleExternal(), hasSubaccounts);
		}
		return panel;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panelId]);

	const getTitle = (panelId) => {
		if (panelId === "PINNED_SEARCHES") {
			return "Your Search History";
		} else if (panelId === "PINNED_LOADS") {
			return "Your Bookmark Loads";
		} else if (panelId === "PINNED_TRANSACTION") {
			return "Transaction List";
		} else {
			return null;
		}
	};

	const getSubtitle = {
		PINNED_LOADS: "Bookmarking a load does not guarantee its availability",
	};

	const getFilters = {
		PINNED_LOADS: { status: "ACTIVE" },
	};

	const closePanel = () => {
		if (panelId === "CURRENT_TRANSACTIONS" && unreadTotalTransactions > 0) {
			// Marking all transction as read only if there any unread notifications
			dispatch(markTransactionsAsRead({ userId }));
			setTimeout(() => {
				// To avoid race-conidtion, refreshing counters one more time using real data
				dispatch(fetchTransactions({ userId }));
			}, 500);
		}
		dispatch(closePinnedPanel());
	};

	return (
		<SwipeableDrawer
			classes={{ paper: classes.root + " bg-grey-200 p-10" }}
			open={pinnedPanel.length > 0}
			anchor="right"
			onOpen={() => {}}
			onClose={closePanel}
			disableSwipeToOpen
		>
			{getTitle(panelId) && (
				<Typography
					color="textSecondary"
					className={`text-20 p-6 mt-4 text-black ${getSubtitle[panelId] ? "" : "mb-10"}`}
				>
					{getTitle(panelId)}
				</Typography>
			)}
			{getSubtitle[panelId] && (
				<Typography component={"h4"} className={"px-6 mb-10 text-black"}>
					{getSubtitle[panelId]}
				</Typography>
			)}
			{panelId === "CURRENT_TRANSACTIONS" && <TransactionStateCurrentList />}
			{list && <SmarthopList mode="GRID" config={list} dataIds={{ userId }} filters={getFilters[panelId] ?? {}} />}
		</SwipeableDrawer>
	);
}

export default PinnedPanel;
