let featureList = null;

export const cleanData = () => {
	featureList = null;
}

export const isEnabled = (feature) => {
  if (!featureList || (featureList && !Object.keys(featureList).length)) {
    featureList = localStorage.getItem("features");
    featureList = featureList ? JSON.parse(featureList) : featureList;
  }

  // first time loading smarthop/or visting after logging out
  if (!featureList) {
    return false;
  }

	return featureList[feature];
};