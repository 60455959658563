import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import tools from "./tools";
import fuse from "./fuse";
import socket from "./socket";
import messenger from "./messenger";
import i18n from "./language/i18nSlice";
import marketConditions from "./marketConditions";
import feature from "./feature";
import popUpsFeatures from "./popUpsFeatures";
import onboarding from "./onboarding/onboardingSlice";
import wallet from "./wallet";
import video from "./video";
import actions from "./actions";
import transactions from "./transactions";
import loads from "./loads";
import upload from "./upload";
import search from "./search";

const createReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		auth,
		tools,
		fuse,
		i18n,
		socket,
		messenger,
		marketConditions,
		feature,
		popUpsFeatures,
		onboarding,
		video,
		upload,
		wallet,
		actions,
		transactions,
		loads,
		search,
		...asyncReducers,
	});

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === "auth/user/userLoggedOut") {
		state = undefined;
	}

	return combinedReducer(state, action);
};

export default createReducer;
