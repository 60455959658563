import { useState, useEffect, Fragment } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import LoadStrategyWarning from "./LoadStrategyWarning";
import ValidateMoveButton from "./ValidateMoveButton";
import SmartHopMap from "app/main/profile/trips/SmartHopMap";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
	},
})(TimelineItem);

const useStyles = makeStyles((theme) => {
	return {
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		emptyMove: {
			backgroundImage: "linear-gradient(#d10e0e 0%, #d10e0e 50% , #ffffff 51% )",
			backgroundSize: "16px 25px, 100% 25px",
		},
		mapRadius: {
			borderRadius: "20px",
		},
		markerTruck: {
			position: "absolute",
			left: "50%",
			transform: "translate(-50%, 0)",
			bottom: 0,
			fontSize: "25px",
		},
		maxH70vh: {
			maxHeight: "70vh",
		},
	};
});

function LoadStrategy(props) {
	const classes = useStyles();
	const nativeMobile = props.nativeMobile || window.ismobile();

	const [loadInfo, setLoadInfo] = useState(null);
	const [loadStrategy, setLoadStrategy] = useState(null);
	const [strategyReqData, setStrategyReqData] = useState(null);
	const [searchId, setSearchId] = useState(null);
	const [legs, setLegs] = useState(null);
	const [truckPosition, setTruckPosition] = useState(null);
	const [listCoordinates, setListCoordinates] = useState(null);
	const [listPolyLine, setListPolyLine] = useState(null);
	const [calSummary, setCalSummary] = useState(null);
	const [stopsData, setStopsData] = useState(null);
	const [typeShow, setType] = useState("STRATEGY_DETAILS");
	const [autoValidate, setAutoValidate] = useState(false);

	useEffect(() => {
		setLoadInfo(props.load);
		setLoadStrategy(props.strategy);
		setTruckPosition(props.truckPosition);
		setLegs(props.legs);
		setStrategyReqData(props.strategyReqData);
		setSearchId(props.searchId);
	}, [props.load, props.strategy, props.truckPosition, props.legs, props.strategyReqData, props.searchId]);

	useEffect(() => {
		if (!truckPosition || !loadInfo || !loadStrategy) {
			return;
		}

		let { city, ecity, origin, destination } = loadInfo;
		let arrayCoordinates = [];
		let arrayPolyline = [];

		// Truck -> Origin
		let polyLineTruckToOrigin = [
			{ lat: truckPosition?.lat, lng: truckPosition.long },
			{ lat: city?.lat, lng: city?.long },
		];
		arrayPolyline.push({ line: polyLineTruckToOrigin, color: "rgb(6, 34, 70)", dashedLine: true });

		// Origin -> Destination
		let polyLineOriginToDestination = [
			{ lat: city?.lat, lng: city?.long },
			{ lat: ecity?.lat, lng: ecity?.long },
		];
		arrayCoordinates.push({ name: origin, lat: city?.lat, lng: city?.long });
		if (destination && !props?.isGeneralStrategy) {
			arrayCoordinates.push({ name: destination, lat: ecity?.lat, lng: ecity?.long });
			arrayPolyline.push({ line: polyLineOriginToDestination, color: "rgb(6, 34, 70)", dashedLine: false });
		}
		let last_empty =
			loadInfo?.destination && !props?.isGeneralStrategy
				? [{ lat: ecity?.lat, lng: ecity?.long }]
				: [{ lat: city?.lat, lng: city?.long }];
		let final_legs = [];

		let path_legs = loadStrategy.legs;

		for (let eachLeg of path_legs) {
			let path_leg = legs.find((leg) => leg.ocluster === eachLeg.ocluster && leg.dcluster === eachLeg.dcluster);
			if (!path_leg) continue;
			// Last Empty -> Next Origin
			let polyLineDestinationToFirstStop = [...last_empty, { lat: path_leg.o_lat, lng: path_leg.o_lng }];
			arrayPolyline.push({ line: polyLineDestinationToFirstStop, color: "rgb(6, 34, 70)", dashedLine: true });
			arrayCoordinates.push({
				name: path_leg.o_city,
				lat: path_leg.o_lat,
				lng: path_leg.o_lng,
			});

			// Stops
			let polyLineStops = [
				{ lat: path_leg.o_lat, lng: path_leg.o_lng },
				{ lat: path_leg.d_lat, lng: path_leg.d_lng },
			];
			arrayCoordinates.push({
				name: path_leg.d_city,
				lat: path_leg.d_lat,
				lng: path_leg.d_lng,
			});
			arrayPolyline.push({
				line: polyLineStops,
				color: "rgb(231, 104, 61)",
				dashedLine: path_leg.empty || path_leg.is_empty,
			});
			last_empty = [polyLineStops[1]];
			final_legs.push(path_leg);
		}

		setListCoordinates(arrayCoordinates);
		setListPolyLine(arrayPolyline);
		calculateStops(loadInfo, final_legs);
		calculateSummary(loadInfo, final_legs);
		// eslint-disable-next-line
	}, [loadInfo, loadStrategy, truckPosition]);

	function calculateStops(load, strategy) {
		if (!load || !strategy?.length) {
			return;
		}

		const setStopData = (
			d_city,
			price,
			profit,
			duration,
			totalMiles,
			totalEmptyMiles,
			rpm,
			loadedRPM,
			o_city,
			empty = false,
			avg_volume,
			moveId
		) => {
			let rawData = {
				origin: o_city,
				destination: d_city,
				payment: price,
				profit,
				volume: avg_volume,
				rpm,
				loadedRPM,
				loadedMiles: totalMiles,
				emptyMiles: totalEmptyMiles,
				empty,
			};
			let data = [
				{
					label: null,
					value: o_city ? o_city.toUpperCase() + " - " + d_city.toUpperCase() : o_city?.toUpperCase(),
					moveId,
					rawData,
				},
			];
			return empty
				? data.concat({ label: "Empty Miles:", value: totalEmptyMiles?.toFixed(0) ?? "-", postfix: " mi" })
				: data.concat([
						{ label: "Est. Payment:", value: price?.toFixed(0) ?? "-", prefix: "$" },
						{ label: "Est. Miles:", value: totalMiles?.toFixed(0) ?? "-", postfix: " mi" },
						{ label: "Est. RPM", value: loadedRPM?.toFixed(2) ?? "-", prefix: "$", range: true, toFixed: 2 },
						{ label: "Empty Miles:", value: totalEmptyMiles?.toFixed(0) ?? "-", postfix: " mi" },
						{ label: "RPM (All In):", value: rpm?.toFixed(2) ?? "-", prefix: "$", range: true, toFixed: 2 },
						{ label: "Duration:", value: duration ?? "-", postfix: " days" },
						{ label: "Daily Avg. Vol:", value: avg_volume?.toFixed(0) ?? 0, postfix: " loads", toFixed: 0 },
						profit ? { label: "Profit:", value: profit?.toFixed(0) ?? "-", prefix: "$", range: true } : {},
				  ]);
		};

		let data = [];

		// Trip Data
		if (!props.isGeneralStrategy) {
			let tripData = setStopData(
				load.destination,
				Number(load.price || load.est_price || 0),
				Number(load.profit_trip_carrier || 0),
				Number(load.duration),
				Number(load.miles),
				Number(load.empty_miles) ? Number(load.empty_miles) : Number(load.total_miles) - Number(load.miles),
				Number(load.rpm || load.est_rpm || 0),
				Number(load.price || load.est_price || 0) / Number(load.miles),
				load.origin,
				false,
				1 //Current load
			);
			data.push(tripData);
		}

		// Stops Data
		for (let eachStrategy of strategy) {
			let { payment, profit, loaded_miles, empty_miles, o_city, d_city, empty, avg_volume, rpm, moveId } = eachStrategy;

			let duration = Math.ceil(Number(loaded_miles + empty_miles) / 600);
			let loadedRPM = Number(loaded_miles) > 0 ? Number(payment) / Number(loaded_miles) : 0;
			let stopData = setStopData(
				d_city,
				payment,
				profit,
				duration,
				Number(loaded_miles),
				Number(empty_miles),
				rpm,
				loadedRPM,
				o_city,
				empty,
				avg_volume,
				moveId
			);
			data.push(stopData);
		}

		setStopsData(data);
	}

	function calculateSummary(load, strategy) {
		if (!load || !strategy?.length) {
			return;
		}
		let stops = strategy.length;
		stops += props.isGeneralStrategy ? 0 : 1;
		let summary = {
			totalPayment: { label: "Total Payment:", value: 0, prefix: "$", toFixed: 0, range: true },

			// TODO: re-enable
			// dailyEstProfit: { label: "Daily Profit:", value: 0, prefix: "$", toFixed: 0, range: true },
			totalMiles: { label: "Total Loaded Miles:", value: 0, postfix: " mi", toFixed: 0 },
			totalEmptyMiles: { label: "Total Empty Miles:", value: 0, postfix: " mi", toFixed: 0 },
			rpm: { label: "Average RPM:", value: 0, prefix: "$", toFixed: 2 },
			avgVolume: { label: "Average Lane Volume:", value: 0, postfix: " loads/day", toFixed: 0 },
			duration: { label: "Duration:", value: 0, postfix: " days", toFixed: 0 },
			estProfit: { label: "Total Profit:", value: 0, prefix: "$", toFixed: 0 },
		};

		// Data Trip
		if (!props.isGeneralStrategy) {
			summary.totalPayment.value = Number(load.price || load.est_price || load.payment || 0);
			// TODO: re-enable
			summary.estProfit.value = Number(load.profit_trip_carrier || 0);
			summary.totalMiles.value = Number(load.miles ?? 0);
			summary.totalEmptyMiles.value = Number(load.empty_miles ?? 0);
			summary.duration.value = Math.ceil(Number(load.total_miles ?? 0) / 600);
		}
		// Data Stops
		strategy.map((data) => {
			summary.totalPayment.value += Number(data.payment);
			// TODO: re-enable
			summary.estProfit.value += Number(data.profit);
			summary.avgVolume.value += Number(data.avg_volume);
			summary.totalMiles.value += Number(data.loaded_miles);
			summary.totalEmptyMiles.value += data.empty_miles ? Number(data.empty_miles) : 0;
			summary.duration.value += Math.ceil(Number(data.loaded_miles + data.empty_miles) / 600);
			return summary;
		});

		// TODO: re-enable
		// summary.dailyEstProfit.value = summary.estProfit.value / summary.duration.value;
		summary.rpm.value = summary.totalPayment.value / summary.totalMiles.value;
		summary.avgVolume.value = summary.avgVolume.value / stops;
		setCalSummary(summary);
	}

	const onTabChange = (_, value) => {
		setType(value);
	};

	if (!loadInfo || !loadStrategy || !truckPosition) {
		return !props.isGeneralStrategy ? "" : "Invalid  params";
	}

	const formatLabel = (prefix, value, postfix) => {
		return (prefix || "") + value + (postfix || "");
	};

	let stopsDataView = (
		<div className={props?.classes?.root}>
			{!nativeMobile && (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Typography className="font-semibold text-16 pl-10 pb-10 ">{"Recommended Moves"}</Typography>
					<Button
						className="mr-10 text-8"
						size="small"
						color="primary"
						variant="contained"
						disabled={autoValidate}
						onClick={() => {
							setAutoValidate(true);
						}}
					>
						Validate All
					</Button>
				</div>
			)}
			<Timeline className="-ml-14">
				{stopsData?.map((data, index, array) => {
					// skip the first element
					//const loadedMiles = data.find((d) => d.label && d.label.toLowerCase().includes("loaded"));
					const isEmptyMove = data[0].rawData.empty ?? false; //+loadedMiles?.value === 0 ? true : false;
					//const emptyMove = data.find((d) => d.label && d.label.toLowerCase().includes("empty"));
					//data = isEmptyMove ? data.filter((d) => !d.label).concat(emptyMove) : data;
					return (
						<StyledTimelineItem key={index}>
							<TimelineSeparator>
								<TimelineDot
									color={index === 0 ? "primary" : "secondary"}
									className={`py-5 px-10 ml-5 mr-5 ${index && isEmptyMove ? "bg-red-700" : ""}`}
								>
									{index + 1}
								</TimelineDot>
								<TimelineConnector className={isEmptyMove ? classes.emptyMove : ""} />
							</TimelineSeparator>
							<TimelineContent>
								{data?.map((item, index, dataArray) => {
									if (index && !item?.label) return null;
									return isEmptyMove ? (
										<div
											key={item.label}
											className={"flex flex-inline " + (index === 0 ? "pt-8 pb-12 justify-between" : "pt-0")}
										>
											{item.label && (
												<Typography className="text-13 ml:text-14 tracking-wide text-gray-600 pr-10 pb-4 min-w-120 ">
													{item.label}
												</Typography>
											)}
											<Typography className="text-13 ml:text-14 tracking-wide">
												{item.range
													? formatLabel(
															item.prefix,
															Number(item.value * 0.8 || 0).toFixed(item.toFixed),
															item.postfix
													  ) +
													  " - " +
													  formatLabel(item.prefix, item.value, item.postfix)
													: !index
													? formatLabel("", item.value).split("-")[0].trim()
													: formatLabel(item.prefix, item.value, item.postfix)}
											</Typography>
										</div>
									) : (
										<div
											key={item.label}
											className={"flex flex-inline " + (index === 0 ? "pt-8 pb-12 justify-between" : "pt-0")}
										>
											{item.label && (
												<Typography className="text-13 ml:text-14 tracking-wide text-gray-600 pr-10 pb-4 min-w-120 ">
													{item.label}
												</Typography>
											)}
											<Typography className="text-13 ml:text-14 tracking-wide">
												{item.range
													? formatLabel(
															item.prefix,
															Number(item.value * 0.8 || 0).toFixed(item.toFixed),
															item.postfix
													  ) +
													  " - " +
													  formatLabel(item.prefix, item.value, item.postfix)
													: !index
													? formatLabel("", item.value).split("-")[0].trim()
													: formatLabel(item.prefix, item.value, item.postfix)}
											</Typography>
											{!index && (
												<ValidateMoveButton
													key={item.moveId}
													moveId={item?.moveId}
													legData={item.rawData}
													strategyReqData={strategyReqData}
													loadSearchId={searchId}
													autoValidate={autoValidate}
												/>
											)}
										</div>
									);
								})}
							</TimelineContent>
						</StyledTimelineItem>
					);
				})}

				{stopsData?.length && (
					<StyledTimelineItem key={stopsData?.length}>
						<TimelineSeparator>
							<TimelineDot color="secondary" className="py-5 px-10 ml-5 mr-5">
								{stopsData?.length + 1}
							</TimelineDot>
						</TimelineSeparator>
						<TimelineContent>
							<Typography className="text-13 ml:text-14 tracking-wide">
								{formatLabel("", stopsData?.[stopsData?.length - 1][0].value)
									.split("-")
									.slice(-1)[0]
									.trim()}
							</Typography>
						</TimelineContent>
					</StyledTimelineItem>
				)}
			</Timeline>
		</div>
	);

	let summaryDataView = (
		<div className={props?.classes?.root + " px-10"}>
			{!nativeMobile && (
				<Typography className="font-semibold font-medium text-16 pb-20">{"Potential Future Earnings"}</Typography>
			)}
			{calSummary &&
				Object?.entries(calSummary)?.map(([key, value], index) => {
					return (
						<Fragment key={key}>
							{index > 0 && <Divider className="mt-10" />}
							<div className="flex flex-row justify-between mt-10">
								<Typography className="text-13 ml:text-14 text-gray-600 tracking-wide">{value.label}</Typography>
								<Typography className="text-13 ml:text-14 text-gray-800 tracking-wide">
									{value.range
										? formatLabel(value.prefix, Number(value.value * 0.8 || 0).toFixed(value.toFixed), value.postfix) +
										  " - " +
										  formatLabel(value.prefix, value.value.toFixed(value.toFixed), value.postfix)
										: formatLabel(value.prefix, value.value.toFixed(value.toFixed), value.postfix)}
								</Typography>
							</div>
						</Fragment>
					);
				})}
		</div>
	);

	return (
		<div className={classes.maxH70vh + " flex w-full flex-col mt-5 mb-20 ml:p-10"}>
			{nativeMobile ? (
				<div className="w-full h-full flex flex-col md:flex-row md:h-512">
					<div className="md:hidden flex">
						<LoadStrategyWarning />
					</div>
					<div className={classes.mapRadius + " w-full h-400 overflow-hidden mx-5 md:w-2/4 md:h-512"}>
						<SmartHopMap
							provider={"GOOGLE MAPS"} // for now until trimble renders correctly
							recommendedStrtegy={true}
							classes={{ root: "w-0 md:w-full h-full overflow-hidden m-0 hidden md:flex" }}
							locations={listCoordinates}
							truckLocation={truckPosition}
							listPolyLine={listPolyLine}
							map="RecommendedStrtegyMap"
						/>
					</div>
					<Tabs className="mt-10" value={typeShow} onChange={onTabChange} variant="fullWidth">
						<Tab label={"Moves"} value={"STRATEGY_DETAILS"} />
						<Tab label={"Earnings"} value={"FUTURE_EARNINGS"} />
					</Tabs>
					<div className="flex overflow-x-hidden overflow-scroll flex-col">
						{typeShow === "STRATEGY_DETAILS" && (
							<div className="w-full h-full pt-20 flex justify-center ml:pt-0 md:w-1/4 ml:pl-20 md:h-512">
								{stopsDataView}
							</div>
						)}
						{typeShow === "FUTURE_EARNINGS" && (
							<div className="w-full h-full p-20 ml:pt-0 md:w-1/4 ml:pl-20 md:h-512">{summaryDataView}</div>
						)}
					</div>
				</div>
			) : (
				<div className="w-full h-full flex flex-col md:flex-row md:h-512 px-10">
					<div className="ml:hidden flex">
						<LoadStrategyWarning />
					</div>
					<div className={classes.mapRadius + " w-full h-400 overflow-hidden md:w-2/4 md:h-512"}>
						<SmartHopMap
							provider={"GOOGLE MAPS"} // for now until trimble renders correctly
							recommendedStrtegy={true}
							classes={classes}
							locations={listCoordinates}
							truckLocation={truckPosition}
							listPolyLine={listPolyLine}
							map="RecommendedStrtegyMap"
						/>
					</div>
					<div className="w-full h-full pt-20 ml:pt-0 overflow-y-scroll md:w-1/4 ml:pl-10 md:h-512">
						{stopsDataView}
					</div>
					<div className="w-full h-full pt-0 md:w-1/4 ml:pl-10 md:h-512 pb-28">{summaryDataView}</div>
				</div>
			)}
		</div>
	);
}

export default LoadStrategy;
