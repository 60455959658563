import moment from "moment";

const overrideTierForm = {
	urlGET: "billing/carriers/:carrierId/subscription/override-plan",
	urlPUT: "billing/carriers/:carrierId/subscription/override-plan",
	urlPOST: "billing/carriers/:carrierId/subscription/override-plan",
	urlDELETE: "billing/carriers/:carrierId/subscription/override-plan",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision"],
	header: {
		edit: { label: "Edit Trial Plan" },
		create: { label: "Add Trial Plan" },
		view: { label: "Trial Plan" },
		delete: { label: "Remove Trial Plan" },
	},
	content: {
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "overridePlan",
					type: "autocomplete",
					label: `Trial Plan`,
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/platform-plans",
						params: {
							sortOrder: "asc",
							sortBy: "nickname",
						},
						preloadDefault: true,
						listenEvent: "planRevision",
					},
				},
				{
					key: "overrideTierExpiration",
					type: "picker",
					label: "Expiration",
					defaultValue: moment().add(60, "days"),
					required: true,
					picker: {
						disablePast: true,
						notClearable: true,
						type: "date",
						classes: {},
					},
				},
				{
					key: "overrideForceUpgrade",
					type: "checkbox",
					label: "Force Upgrade",
				},
				{ type: "action", action: "Change" },
			],
		},
		delete: {
			items: [
				{ type: "message", builder: (item) => `Would you like to remove the trial plan?` },
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		view: { sameAs: "edit" },
	},
};

export default overrideTierForm;
