import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { uploadFiles } from "app/main/multi-file-upload/MultiFileUploadView";
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

// Services
import { getUserTier } from "app/services/LoginService";

// Utils
import { clickWidget } from "./WidgetUtils.js";
import { formatLabel, formatTitle } from "./utils";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

function TripContainer(props) {
	const { data, carrierId, isTripUser } = props;

	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const [uploading, setUploading] = useState(false);
	const [paywallDialog, setPaywallDialog] = useState({});
	const [error, setError] = useState(null);

	const fileInputRef = useRef();
	const formStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const visiblePopup = formStack.length > 0 ? formStack[formStack.length - 1] : null;

	useEffect(() => {
		function cleanEvent(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		function handleDocument(e) {
			const dt = e.dataTransfer;
			const files = dt.files;
			handleOnDrop(files);
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
		}

		window.addEventListener("drop", handleDocument);
		window.addEventListener("dragover", cleanEvent);

		return () => {
			window.removeEventListener("drop", handleDocument);
			window.removeEventListener("dragover", cleanEvent);
		};
	});

	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const hasRatesOnPermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;

	const canNoSeeTrips =
		["CARRIER_GENERIC", "CARRIER_MANAGER"].includes(user.role) && !user?.gatekeepers?.permission_search_on;

	const handleOnDrop = async (files) => {
		if (visiblePopup) return;
		setError(null);
		if (!files?.length) return;
		if (getUserTier() === "TIER_STARTER") {
			setPaywallDialog({
				open: true,
				dataIds: {
					type: "rc_transcription_restriction",
					data: { callback: handleOnDrop },
					extraData: { dataSend: files },
				},
			});
			return;
		}
		setUploading(true);
		try {
			await uploadFiles(Array.from(files), dispatch);
		} catch (err) {
			setUploading(false);
			return setError("An error occurred while uploading the file");
		}
		setUploading(false);
		dispatch(openFormDialog({ viewId: "MULTI_FILE_UPLOAD_VIEW", dataIds: { carrierId } }));
	};

	return (
		<>
			<div
				className={"mb-14 " + (canNoSeeTrips ? "" : "cursor-pointer")}
				onClick={() => (canNoSeeTrips ? "" : clickWidget("trip", history))}
			>
				{formatTitle("Trips")}
				<Divider className="w-full bg-grey-300 mt-10" />
				{formatLabel("Total Trips", data?.Count ?? 0)}
				<Divider className="w-full bg-grey-300" />
				{formatLabel("Total Miles", data?.Total ?? 0)}
				<Divider className="w-full bg-grey-300" />
				{formatLabel("Empty Miles", data?.Empty ?? 0)}
				<Divider className="w-full bg-grey-300" />
			</div>

			{hasEditTripsPermission && hasRatesOnPermission && (
				<>
					<Typography style={{ color: "#002756", fontWeight: "500" }} className={"text-16 mb-4"}>
						Booked a Load?
					</Typography>

					<div
						id="drop-area"
						className={
							(isTripUser ? "min-h-85 " : " min-h-115 ") +
							"cursor-pointer items-center justify-center flex " +
							(uploading ? "pointer-events-none" : "")
						}
						style={{
							background: "rgba(231, 104, 61, 0.5)",
							border: "2px dotted",
							borderColor: "#E7683D",
						}}
						onClick={() => fileInputRef?.current?.click()}
					>
						<div className={"flex " + (uploading ? "" : "px-40")}>
							<Typography color="secondary" className={"text-14 text-center font-semibold"}>
								{uploading ? (
									"Uploading ..."
								) : (
									<>
										<span className="underline">Upload</span> or Drag and Drop multiple RateCons here
									</>
								)}
							</Typography>
						</div>

						<input
							type="file"
							style={{ display: "none" }}
							ref={fileInputRef}
							multiple
							onChange={(e) => handleOnDrop(e.target.files)}
							accept={".jpg, .jpeg, .png, .pdf"}
						/>
					</div>
					{error && (
						<div className="flex items-center justify-center">
							<Typography className="w-full text-center text-12 mt-2" color="error">
								{error}
							</Typography>
						</div>
					)}
					<Typography className={"font-medium text-11 text-grey-600 italic " + (error ? "mt-3" : "mt-6")}>
						{`*Reduce trip management time by 90% by having SmartHop transcribe your RateCons`}
					</Typography>
				</>
			)}

			<PaywallDialog data={paywallDialog} onHandlerPaywall={() => setPaywallDialog({})} />
		</>
	);
}

export default TripContainer;
