import { Typography, Icon } from "@material-ui/core";
import { formatDate } from "app/main/utils/dateUtils";
import { useMemo } from "react";

function LoadSearchSummaryBrokerView(props) {
	const load = props.load;
	const onShowBrokerSummary = props.onShowBrokerSummary;

	const brokerLink = useMemo(() => {
		if (!load?.contact?.includes("@")) return `tel:${load?.contact}`;

		const newLine = `%0D%0A`;
		const mailSubject = load?.hasBrokerId
			? `Load ${load?.load_id}`
			: `Load from ${load?.origin} to ${load?.destination}`;
		const mailBody = `Hi,${newLine}${newLine}I'm interested in load ${
			load?.hasBrokerId ? `ID ${load?.load_id}` : ""
		} from ${load?.origin} to ${load?.destination} on ${formatDate(load.ready)}.${newLine}${newLine}Thanks`;
		return `mailto:${load?.contact}?subject=${mailSubject}&body=${mailBody}`;
	}, [load]);

	return (
		<div className={"flex flex-col mb-28 -mt-10 "}>
			<div className={"flex flex-row border-b-1 border-grey-300 mt-10"}>
				<Typography className="flex text-black text-12 ml:text-13 break-normal">Name</Typography>
				<Typography
					className={`text-black text-12 ml:text-13 w-full ${
						props?.isPublicView ? "" : "cursor-pointer text-blue-700"
					}`}
					style={{ textAlign: "end", padding: 0 }}
					onClick={props?.isPublicView ? null : onShowBrokerSummary}
				>
					{load?.clientid && load?.clientidOverride
						? `${load?.clientid} (${load?.clientidOverride})`
						: load?.clientid ?? "No Information"}
					{load?.clientid && <Icon className="text-15 ml-4 -mb-2">info</Icon>}
				</Typography>
			</div>
			<div className={"flex flex-row border-b-1 border-grey-300 mt-10"}>
				<Typography className="flex w-full text-black text-12 ml:text-13 break-normal">Contact</Typography>
				{load?.contact ? (
					<Typography
						onClick={() => window.open(brokerLink)}
						className={`text-12 ml:text-13 w-full cursor-pointer text-blue-700`}
						style={{ textAlign: "end", padding: 0 }}
					>
						{load?.contact}
					</Typography>
				) : (
					"-"
				)}
			</div>
		</div>
	);
}

export default LoadSearchSummaryBrokerView;
