const config = {
	urlGET: "api/user-actions",
	idKey: "_id",
	segmentKey: "ADMIN_USERS_ACTIONS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["created_at", "executionTime"],
		},
		export: {
			fileName: "User Actions Report",
		},
		filters: {
			search: true,
			items: [
				{
					key: "created_at",
					type: "dateRange",
					label: "Period",
				},
				{
					key: "editor",
					type: "autocomplete",
					label: "Editor",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
					},
				},
				{
					key: "method",
					type: "select",
					label: "Method",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "GET",
							label: "GET",
						},
						{
							value: "POST",
							label: "POST",
						},
						{
							value: "PUT",
							label: "PUT",
						},
						{
							value: "DELETE",
							label: "DELETE",
						},
					],
				},
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "SUCCESS",
							label: "Success",
						},
						{
							value: "FAILURE",
							label: "Failure",
						},
					],
				},
				{
					key: "user",
					type: "autocomplete",
					label: "Target User",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
					},
				},
				{
					key: "carrier",
					type: "autocomplete",
					label: "Target Carrier",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
					},
				},
				{
					key: "truck",
					type: "autocomplete",
					label: "Target Truck",
					autocomplete: {
						provider: "smarthop",
						listenEvent: "profileRevision",
						url: "api/profile/trucks",
					},
				},
				{
					key: "code",
					type: "text",
					label: "Code",
				},
				{
					key: "environment",
					type: "select",
					label: "Environment",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "production",
							label: "Production",
						},
						{
							value: "staging",
							label: "Staging",
						},
						{
							value: "staging.local",
							label: "Staging (Local)",
						},
						{
							value: "development",
							label: "Development",
						},
						{
							value: "development.local",
							label: "Development (Local)",
						},
					],
				},
				{
					key: "impersonator",
					type: "select",
					label: "Impersonator",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__NOT_EMPTY__",
							label: "Impersonated Actions",
						},
						{
							value: "__EMPTY__",
							label: "Real Users Actions",
						},
					],
				},
				{
					key: "created_at",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__DATE_DAYS_GT__1",
							label: "Last 1 Day",
						},
						{
							value: "__DATE_DAYS_GT__3",
							label: "Last 3 Days",
						},
						{
							value: "__DATE_DAYS_GT__7",
							label: "Last 7 Days",
						},
						{
							value: "__DATE_DAYS_GT__30",
							label: "Last 30 Days",
						},
					],
				},
			],
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal",
				},
				{
					key: "entities",
					type: "checkbox",
					label: "Show Linked Entities",
				},
			],
		},
		items: [
			{
				key: "created_at",
				type: "date",
				label: "Date",
			},
			{
				key: "environment",
				type: "text",
				label: "Environment",
				group: "internal",
			},
			{
				key: "ip",
				type: "text",
				label: "IP",
			},
			{
				key: "impersonator__view",
				type: "user__view",
				label: "Impersonator",
				viewConfig: {
					showRole: true,
				},
				group: "internal",
			},
			{
				key: "editor__view",
				type: "user__view",
				label: "Editor",
				viewConfig: {
					showRole: true,
				},
			},
			{
				key: "user__view",
				type: "user__view",
				label: "User",
				viewConfig: {
					showRole: true,
				},
				group: "entities",
			},
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
				group: "entities",
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showTeam: true,
				},
				group: "entities",
			},
			{
				key: "trip__view",
				type: "trips__view",
				label: "Trip",
				viewConfig: {
					showBroker: true,
				},
				group: "entities",
			},
			{
				key: "method",
				type: "text",
				label: "Method",
			},
			{
				key: "route",
				type: "text",
				label: "Route",
			},
			{
				key: "executionTime",
				builder: (item) => (item.executionTime > 0 ? item.executionTime + "ms" : "-"),
				label: "Execution",
			},
			{
				key: "status",
				type: "text",
				label: "Status",
			},
			{
				key: "code",
				type: "text",
				label: "Code",
			},
			{
				key: "type_event",
				type: "text",
				label: "Event",
			},
			{
				key: "input_data",
				type: "text",
				label: "Input Data",
				builder: (item) => JSON.stringify(item.input_data, null, 6),
				collapsibleLength: 40,
				group: "internal",
			},
			{
				key: "error",
				type: "text",
				label: "Error",
				collapsibleLength: 30,
				group: "internal",
			},
		],
	},
};

export default config;
