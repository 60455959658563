import { formatCurrency } from "app/main/utils/tableUtils";

const getTypeDetails = (fee, base, max) => {
	const text = (
		(base > 0 ? `${formatCurrency(base / 100)}` : "") +
		(fee > 0 ? ` + ${fee}%` : "") +
		(max > 0 ? `  with a cap of ${formatCurrency(max / 100)}` : "")
	).trim();

	return text.length > 0 ? text : "$0";
};

const smartpayPayoutPlanSummaryForm = {
	urlGET: "billing/carriers/:carrierId/factoring-fee-plan",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision", "discountRevision"],
	header: { view: { label: `Payout Plan Summary` } },
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "smartpay_fee_percentage",
								type: "password",
								label: "SmartPay Fee",
								builder: (item) => `${item?.smartpay_fee_percentage}%`,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "ach",
								type: "text",
								label: "ACH",
								builder: (item) =>
									`${getTypeDetails(
										item?.payment_types?.ach.transfer_fee_percentage,
										item?.payment_types?.ach.base_transfer_fee_cents,
										item?.payment_types?.ach.max_transfer_fee_cents
									)}`,
							},
							{
								key: "wire",
								type: "text",
								label: "Same Day",
								builder: (item) =>
									`${getTypeDetails(
										item?.payment_types?.wire.transfer_fee_percentage,
										item?.payment_types?.wire.base_transfer_fee_cents,
										item?.payment_types?.wire.max_transfer_fee_cents
									)}`,
							},
						],
					},
				},
			],
		},
	},
};

export default smartpayPayoutPlanSummaryForm;
