import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

const TripTestDataConfirmDialog = (props) => {
	const userMetadata = props.userMetadata;

	const title = "Overwrite";
	const message =
		"Saving now will overwrite annotatedBy metadata with the following. Are you sure you want to continue?";

	const onClose = () => {
		props.onClose();
	};

	const onAccept = () => {
		props.onAccept();
	};

	return (
		<SmarthopConfirmDialog
			open={props?.open}
			title={title}
			message={message}
			onClose={onClose}
			onAccept={onAccept}
			maxWidth={"md"}
		>
			<div className="flex flex-col items-center border rounded-lg border-black p-4 mr-8">
				<pre className="w-full whitespace-pre-wrap px-10 py-5 overflow-x-hidden">
					{JSON.stringify(userMetadata, 0, 4)}
				</pre>
			</div>
		</SmarthopConfirmDialog>
	);
};

export default TripTestDataConfirmDialog;
