import { formatCurrency } from "app/main/utils/tableUtils";

const SMARTPAY_STATUS = {
	key: "hasSmartPayProgram",
	type: "select",
	label: "Status",
	defaultValue: false,
	options: [
		{
			value: true,
			label: "Enabled",
		},
		{
			value: false,
			label: "Disabled",
		},
	],
};

const SMARTPAY_CONTRACT = {
	key: "contract",
	type: "upload",
	label: "Contract",
	description: "",
	upload: {
		url: "api/profile/carriers/:carrierId/files",
		documentType: "carrier_smartpay",
		fileName: "smartpay_company_contract",
	},
	rules: {
		required: {
			fieldKey: "hasSmartPayProgram",
			equalsTo: true,
		},
	},
};

const SMARTPAY_NOTICE_ASSIGNMENT = {
	key: "noticeAssignment",
	type: "upload",
	label: "Notice of Assignment",
	description: "",
	upload: {
		url: "api/profile/carriers/:carrierId/files",
		documentType: "carrier_smartpay",
		fileName: "smartpay_notice_of_assignment",
	},
	rules: {
		required: {
			fieldKey: "hasSmartPayProgram",
			equalsTo: true,
		},
	},
};

const SMARTPAY_FEE_PLAN = {
	key: "custom_plan",
	type: "autocomplete",
	label: `Custom Plan`,
	required: false,
	autocomplete: {
		provider: "smarthop",
		url: "billing/factoring-fee-plan",
		params: {
			sortOrder: "asc",
			sortBy: "nickname",
		},
		showDescription: true,
		preloadDefault: true,
		listenEvent: "profileRevision",
	},
};

const SMARTPAY_OPT_OUT = {
	type: "group",
	content: {
		items: [
			{
				key: "smartpayOptOut__flag",
				type: "checkbox",
				label: "Enable Opt-Out",
			},
		],
	},
};

const getTransferPaymentText = ({ smartpayFee, ach, wire } = {}) => {
	const getTypeDetails = (fee, base, max) => {
		const text = (
			(base > 0 ? `${formatCurrency(base / 100)}` : "") +
			(fee > 0 ? ` + ${fee}%` : "") +
			(max > 0 ? `  with a cap of ${formatCurrency(max / 100)}` : "")
		).trim();

		return text.length > 0 ? text : "$0";
	};

	const achDetails = getTypeDetails(
		ach?.transfer_fee_percentage,
		ach?.base_transfer_fee_cents,
		ach?.max_transfer_fee_cents
	);
	const wireDetails = getTypeDetails(
		wire?.transfer_fee_percentage,
		wire?.base_transfer_fee_cents,
		wire?.max_transfer_fee_cents
	);

	return `(${smartpayFee}% | ACH: ${achDetails} | WIRE: ${wireDetails})`;
};

const carrierSmartpayForm = {
	urlGET: "api/profile/carriers/:carrierId/details/smartpay",
	urlPUT: "api/profile/carriers/:carrierId/details/smartpay",
	listenEvent: "profileRevision",
	triggerEvent: "profileRevision",
	idKey: "_id",
	header: {
		edit: { label: "Edit SmartPay Module" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [SMARTPAY_CONTRACT, SMARTPAY_NOTICE_ASSIGNMENT],
					},
				},
				{
					type: "group",
					content: {
						items: [SMARTPAY_STATUS, SMARTPAY_OPT_OUT],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "custom_plan_data",
								type: "text",
								label: "Custom Plan",
								builder: (item) => {
									const isCustom = item?.custom_plan_data;
									const plan = isCustom ? item?.custom_plan_data : item?.default_plan;
									const smartpayFee = plan?.smartpay_fee_percentage;
									const { ach, wire } = plan?.payment_types ?? {};
									return `${
										isCustom ? item?.custom_plan_data?.nickname : "Default for " + item?.default_plan?.nickname
									} ${getTransferPaymentText({ smartpayFee, ach, wire })}`;
								},
							},
						],
					},
				},
			],
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [SMARTPAY_CONTRACT],
					},
				},
				{
					type: "group",
					content: {
						items: [SMARTPAY_NOTICE_ASSIGNMENT],
					},
				},
				{
					type: "group",
					content: {
						items: [SMARTPAY_OPT_OUT],
					},
				},
				{
					type: "group",
					content: {
						items: [SMARTPAY_STATUS],
					},
				},
				{
					type: "group",
					content: {
						items: [SMARTPAY_FEE_PLAN],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierSmartpayForm;
