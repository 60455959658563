import Typography from "@material-ui/core/Typography";

import { createBrokerView } from "app/main/utils/brokerUtils";

import LoadStatus from "./LoadStatus";

function MessageHeader(props) {
	const message = props.message;
	const incoming = props.incoming;
	const variant = props.variant;
	const showBroker = props.showBroker;
	const showLoadStatus = props.showLoadStatus;
	const title = props.title;
	const description = props.description;
	const onComplete = props.onComplete;

	const brokeView = createBrokerView(message?.content, undefined, undefined, undefined, {
		skinny: false,
		variant: incoming ? "light" : "dark",
		showSource: true,
	});

	return (
		<div className={"flex flex-col w-full mt-4 mr-4" + (variant === "notification" ? " pr-44 " : "  ")}>
			<div className={"flex flex-1 flex-row" + (variant !== "notification" ? " flex-wrap " : " ")}>
				{showBroker && <div className="flex flex-1 flex-row mb-10 md:mb-0">{brokeView}</div>}

				{title && (
					<Typography className={"flex flex-1 whitespace-nowrap text-16 mr-16 md:text-18 font-medium"}>
						{title}
					</Typography>
				)}

				{showLoadStatus && (
					<LoadStatus
						sentUnix={message?.sentUnix}
						load={message?.content}
						variant={incoming ? "light" : "dark"}
						onComplete={onComplete}
					/>
				)}
			</div>

			{description && <Typography className="mt-8 font-normal text-12 md:text-13 md:mt-12">{description}</Typography>}
		</div>
	);
}

export default MessageHeader;
