import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import NavbarToggleButton from "app/fuse-layouts/shared-components/NavbarToggleButton";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import PhoneMenu from "app/fuse-layouts/shared-components/PhoneMenu";
import clsx from "clsx";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectToolbarTheme } from "app/store/fuse/settingsSlice";

import { authRoles } from "app/main/consts";
import BannerAlerts from "app/main/alerts/BannerAlerts";
import UploadPanelMenuItem from "app/main/multi-file-upload/UploadPanelMenuItem";
import ChatAppMenuItem from "app/main/chat/ChatAppMenuItem";
import PopoverPanel from "app/main/tools/PopoverPanel";
import HelpRequestMenuItem from "app/main/support/HelpRequestMenuItem";
import CurrentTransactionsPanelMenuItem from "app/main/profile/transactionsState/pinned/CurrentTransactionsPanelMenuItem";
import CalculatorLoadMenuItem from "app/main/calculator/CalculatorLoadMenuItem";
import MarketMenuItem from "app/main/market-conditions/MarketMenuItem";

// eslint-disable-next-line
import LanguageSwitcher from "../../shared-components/LanguageSwitcher";
// eslint-disable-next-line
import FuseSearch from "@fuse/core/FuseSearch";
// eslint-disable-next-line
import FuseShortcuts from "@fuse/core/FuseShortcuts";
// eslint-disable-next-line
import QuickPanelToggleButton from "app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton";
// eslint-disable-next-line
import AdjustFontSize from "../../shared-components/AdjustFontSize";
// eslint-disable-next-line
import FullScreenToggle from "../../shared-components/FullScreenToggle";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const iconStyle = {
	color: "#A3A3A3",
};

function ToolbarLayout1(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const toolbarTheme = useSelector(selectToolbarTheme);

	const classes = useStyles(props);

	const user = useSelector(({ auth }) => auth.user);
	const roles = user.rolesLabels;
	const isInactvie = roles.includes(authRoles.bot[0]) || roles.includes(authRoles.deactivated[0]);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx(classes.root, "flex relative z-20 shadow-md", props.className)}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
				position="static"
			>
				<Toolbar className="p-0 min-h-48 md:min-h-54">
					<div className="flex px-16 sm:flex-1">
						{config.navbar.display && config.navbar.position === "left" && (
							<>
								<Hidden mdDown>
									{(config.navbar.style === "style-3" || config.navbar.style === "style-3-dense") && (
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
									)}

									{config.navbar.style === "style-1" && !navbar.open && (
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
									)}
								</Hidden>

								<Hidden lgUp>
									<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
								</Hidden>
							</>
						)}

						{/* <Hidden mdDown>
							<FuseShortcuts />
						</Hidden> */}

						<div className="hidden md:flex">
							<UploadPanelMenuItem type="btn" />
						</div>
					</div>

					<div className="flex flex-1 justify-end items-center px-8 h-full">
						{!isInactvie && (
							<div className="hidden md:flex sm:my-5">
								{/* <LanguageSwitcher /> */}

								{/* <AdjustFontSize /> */}

								{/* <FullScreenToggle /> */}

								{/* <FuseSearch /> */}

								{/* <QuickPanelToggleButton /> */}

								<ChatAppMenuItem type="icon" color={iconStyle.color} />

								<CalculatorLoadMenuItem type="icon" color={iconStyle.color} />

								<MarketMenuItem type="icon" color={iconStyle.color} />

								<CurrentTransactionsPanelMenuItem type="icon" color={iconStyle.color} />

								<HelpRequestMenuItem type="icon" color={iconStyle.color} />
							</div>
						)}

						{!isInactvie && <PopoverPanel />}

						{!isInactvie && <PhoneMenu />}

						<Divider className="my-12 mx-10 md:mx-16" orientation="vertical" flexItem />
						<UserMenu />
					</div>

					{config.navbar.display && config.navbar.position === "right" && (
						<>
							<Hidden mdDown>{!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}</Hidden>

							<Hidden lgUp>
								<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
							</Hidden>
						</>
					)}
				</Toolbar>

				<BannerAlerts hide={isInactvie} />
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(ToolbarLayout1);
