import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "app/main/utils/dateUtils";

const statusColor = {
	duplicate: "#D99530",
	error: "red",
	valid: "green",
	uploaded: "green",
	total: "#888",
};

const statusIcon = {
	duplicate: "warning",
	error: "error_outlined",
	valid: "done",
	uploaded: "cloud_done",
	total: "list",
};

const useStyles = makeStyles({
	recordStatus: ({ color }) => ({ color, margin: "auto" }),
	recordLabel: {
		fontSize: "1.3rem",
	},
});

const RecordStatus = ({ item }) => {
	const classes = useStyles({ color: statusColor[item?.recordStatus] ?? "black" });
	const icon = statusIcon[item?.recordStatus];
	return (
		<Box display={"flex"} alignItems={"center"}>
			{icon && (
				<Icon fontSize="small" className={classes.recordStatus}>
					{icon}
				</Icon>
			)}
		</Box>
	);
};

const IngestedRecordStatus = ({ item, ingestionStatus }) => {
	return !ingestionStatus[item.load_id] ? (
		<div className="flex justify-center">
			<CircularProgress size={"2rem"} />
		</div>
	) : (
		<RecordStatus item={{ recordStatus: "uploaded" }} />
	);
};

const StatusCount = ({ status, count, label }) => {
	const classes = useStyles({ color: statusColor[status] ?? "black" });
	const icon = statusIcon[status];
	return (
		<Box display={"flex"} flexDirection="column" marginRight={2}>
			<Typography className={classes.recordLabel}>{label}</Typography>
			<Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
				{icon && (
					<Icon fontSize="small" className={classes.recordStatus}>
						{icon}
					</Icon>
				)}
				&nbsp;
				<Typography className={classes.recordStatus}>{count}</Typography>
			</Box>
		</Box>
	);
};

const dateComponent = (date) => {
	return <div style={{ color: "#666" }}>{date ? formatDate(date) : "-"}</div>;
};

export { RecordStatus, IngestedRecordStatus, StatusCount, dateComponent };
