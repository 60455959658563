const createForm = (classes) => ({
	form: {
		noErrorMessage: true,
		classes: {},
	},
	items: [
		{
			type: "group",
			group: {
				classes: {
					root: "flex-col md:flex-row",
					direction: undefined,
				},
			},
			content: {
				items: [
					{
						key: "carrier",
						type: "autocomplete",
						label: "Carrier",
						required: true,
						field: {
							noErrorMessage: true,
							classes: {},
						},
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers",
							preloadDefault: true,
							preloadImmedately: true,

							listenEvent: "profileRevision",
							params: {
								options_my_assigned: false,
							},
						},
					},
					{
						key: "truck",
						type: "autocomplete",
						label: "Truck",
						translate: "",
						required: true,
						field: {
							noErrorMessage: true,
							classes: {},
							variant: undefined,
						},
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/trucks",
							params: {
								options_metadata_truck: true,
								options_metadata_driver: true,
								options_active_only: true,
								options_format_show_driver_phone: true,
							},
							preloadDefault: true,
							preloadImmedately: true,
							listenEvent: "profileRevision",
						},
						rules: {
							params: {
								valueRef: "carrier",
								paramKey: "options_carrier_id_search",
							},
						},
					},
					{
						key: "trip_date",
						type: "picker",
						label: "Trip date",
						translate: "",
						field: {
							noErrorMessage: true,
							classes: {},
							variant: undefined,
						},
						picker: {
							type: "date",
							classes: {},
						},
					},
					{
						key: "window_size",
						type: "slider",
						label: "Trip window size",
						defaultValue: 3,
						translate: "",
						slider: {
							type: "input",
							prefix: "",
							postfix: " trips",
							step: 2,
							min: 3,
							max: 31,
							classes: {},
							variant: undefined,
						},
					},
					{
						type: "action",
						action: "SUBMIT",
						label: "Submit",
						icon: undefined,
						button: {
							alwaysEnabled: true,
							classes: {
								root: classes.buttonNormal,
								disabled: classes.buttonDisabled,
							},
						},
					},
				],
			},
		},
	],
});
export { createForm };
