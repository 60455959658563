export const CARRIER_INVOICE_CONFIG = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "invoice_reference_source",
					type: "select",
					label: "Invoice Reference",
					defaultValue: "COUNTER",
					description:
						"Counter will consecutively assign a number to new Invoices References. Load Id will copy the Load Id to the Invoice Reference",
					options: [
						{
							value: "COUNTER",
							label: "Counter",
							showItems: [
								{
									key: "counter_next_ref",
									type: "number",
									label: "Next Reference Number",
									defaultValue: 1,
									min: 1,
									max: 1000 * 1000 * 1000,
									required: true,
									description: "Next Invoice Reference Number. This number increases for each new Invoice.",
								},
							],
						},
						{
							value: "LOAD_ID",
							label: "Load Id",
						},
					],
				},
			],
		},
	},
];

export const carrierInvoicingProtocolForm = () => {
	return carrierInvoicingProtocolFormConfig;
};

export const carrierInvoicingProtocolFormConfig = {
	urlGET: "api/profile/carriers/:carrierId/invoicing-settings",
	urlPUT: "api/profile/carriers/:carrierId/invoicing-settings",
	urlPOST: "api/profile/carriers/:carrierId/invoicing-settings",
	idKey: "_id",
	triggerEvent: ["profileRevision", "invoiceOnboardingRevision"],
	listenEvent: ["profileRevision", "invoiceOnboardingRevision"],
	header: {
		create: { label: "Set Invoicing Configuration" },
		edit: { label: "Edit Invoicing Configuration" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			items: [...CARRIER_INVOICE_CONFIG],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [...CARRIER_INVOICE_CONFIG, { type: "action", action: "SUBMIT", label: "Save" }],
		},
	},
};
