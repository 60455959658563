const analyticsMetricsList = {
	urlGET: "api/analytics/data/metrics",
	idKey: "_id",
	listenEvent: "analyticsJobsRevision",
	segmentKey: "ADMIN_ANALYTICS_METRICS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["metricKey", "ownerType", "entityType", "paramType", "periodType"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "metricKey",
				dir: "asc",
			},
		},
		filters: {
			search: true,
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show System Fields",
				},
				{
					key: "keys",
					type: "checkbox",
					label: "Show Keys and Types",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "metric__view", type: "metric__view", label: "Metric" },
			{ key: "metricKey", type: "text", label: "Metric Key", group: "keys" },
			{ key: "datasetType", type: "text", label: "Dataset Type" },
			{ key: "flavorType", type: "text", label: "Flavor Type", collapsibleLength: 20 },
			{ key: "ownerType", type: "text", label: "Owner Type", group: "keys" },
			{ key: "entityType", type: "text", label: "Entity Type", group: "keys" },
			{ key: "linkType", type: "text", label: "Links Types", group: "keys", collapsibleLength: 20 },
			{ key: "paramType", type: "text", label: "Params Types", collapsibleLength: 20 },
			{ key: "valueType", type: "text", label: "Value Type" },
			{ key: "periodType", type: "text", label: "Period Type" },
			{ key: "periodTZ", type: "text", label: "Period TZ" },
			{
				key: "menu",
				type: "menu",
				label: "Actions",
				items: [
					{
						key: "deleteMetric",
						label: "Delete",
						dialogBuilder: (item) => ({
							formId: "ADMIN_ANALYTCIS_METRICS_FORM",
							mode: "DELETE",
							dataIds: { id: item._id },
						}),
					},
				],
			},
		],
	},
};

export default analyticsMetricsList;
