import FuseAuthorization from "@fuse/core/FuseAuthorization";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import history from "@history";
import { createGenerateClassName, jssPreset, StylesProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { create } from "jss";
import jssExtend from "jss-plugin-extend";
import rtl from "jss-rtl";
import Provider from "react-redux/es/components/Provider";
import { Router } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";

import AppContext from "./AppContext";
import routes from "./fuse-configs/routesConfig";
import store from "./store";
import Auth from "./main/auth/Auth";
import FormDialog from "./main/tools/FormDialog";
import TutorialDialog from "./main/tools/TutorialDialog";
import ConnectionHandler from "./main/tools/ConnectionHandler";
import SnackbarHandler from "./main/tools/SnackbarHandler";
import { createTrackOrPage } from "./main/segment/segmentEvent";
import navigationConfig from "./fuse-configs/navigationConfig";
import { VISIT_PAGE_EVENT } from "./main/segment/segmentType";
import ReleaseDialog from "./main/tools/ReleaseDialog";
import TripCreationReminder from "./main/tools/TripCreationReminder";
import TransactionDialog from "./main/tools/TransactionDialog";

require("dotenv").config();

const usePageViews = () => {
	history.listen((location) => {
		console.log(`Visiting ${location.pathname} page`);
		const idx = navigationConfig.findIndex((nav) => location.pathname.includes(nav.url));
		if (idx !== -1) {
			const dataNavConfig = navigationConfig[idx];
			const sendData = { location: { href: dataNavConfig.url, search: location.search } };
			createTrackOrPage(VISIT_PAGE_EVENT(dataNavConfig.title), sendData, "page", dataNavConfig.title);
		}
	});
};

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById("jss-insertion-point"),
});
const generateClassName = createGenerateClassName({ disableGlobal: true });
const App = () => {
	usePageViews();

	return (
		<AppContext.Provider
			value={{
				routes,
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Auth>
							<Router history={history}>
								<FuseAuthorization>
									<FuseTheme>
										<SnackbarHandler>
											<ConnectionHandler>
												<FuseLayout />
												<FormDialog />
												<TutorialDialog />
												<ReleaseDialog />
												<TripCreationReminder />
												<TransactionDialog />
											</ConnectionHandler>
										</SnackbarHandler>
									</FuseTheme>
								</FuseAuthorization>
							</Router>
						</Auth>
					</MuiPickersUtilsProvider>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
