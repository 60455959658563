import { buildMessagePreview, buildChatTitle } from "./chatUtils";

const showBrowserNotification = (message) => {
	if (!("Notification" in window)) {
		return;
	}
	if (Notification.permission !== "granted") {
		return;
	}

	const { chat, user, truck } = message?.metadata ?? {};
	let titleText = buildChatTitle({ chat, truck });
	let previewText = buildMessagePreview({ message, user, incoming: true });

	const browserNotification = new Notification(titleText, {
		title: titleText,
		body: previewText,
		tag: message._id,
	});

	browserNotification.onclick = () => {
		window.focus();
		browserNotification.close();
	};
};

const requestBrowserNotificationPermission = async () => {
	if (!("Notification" in window)) {
		return;
	}
	if (Notification.permission === "default") {
		await Notification.requestPermission();
	}
};

export { showBrowserNotification, requestBrowserNotificationPermission };
