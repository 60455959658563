import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { geocode } from "app/services/trimbleMapsService";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;

async function reverseGeocode(coords) {
	return new Promise((resolve, reject) => {
		TrimbleMaps.Geocoder.reverseGeocode({
			lonLat: new TrimbleMaps.LngLat(coords.lng, coords.lat),
			region: TrimbleMaps.Common.Region.NA,
			success: function (response) {
				if (!response || !response[0]) {
					return resolve(false);
				}
				const address = response[0]?.Address;
				// match search format
				const countryAbbreviation = address.CountryAbbreviation === "US" ? "USA" : address.CountryAbbreviation;
				const addressString = `${address.StreetAddress} ${address.City}, ${address.StateAbbreviation}, ${countryAbbreviation} (${coords.lat}, ${coords.lng})`;
				const val = {
					description: addressString,
					label: addressString,
					value: addressString,
					metadata: {
						street: address.StreetAddress,
						city: address.City,
						coords: { long: coords.lng, lat: coords.lat },
						country: countryAbbreviation,
						countryName: address.Country,
						state: address.StateAbbreviation,
						stateName: address.StateName,
						zip: address.Zip,
					},
				};
				return resolve(val);
			},
			failure: function (response) {
				return reject(response);
			},
		});
	});
}

const states = {
	AL: "Alabama",
	AK: "Alaska",
	AS: "American Samoa",
	AZ: "Arizona",
	AR: "Arkansas",
	CA: "California",
	CO: "Colorado",
	CT: "Connecticut",
	DE: "Delaware",
	DC: "District Of Columbia",
	FM: "Federated States Of Micronesia",
	FL: "Florida",
	GA: "Georgia",
	GU: "Guam",
	HI: "Hawaii",
	ID: "Idaho",
	IL: "Illinois",
	IN: "Indiana",
	IA: "Iowa",
	KS: "Kansas",
	KY: "Kentucky",
	LA: "Louisiana",
	ME: "Maine",
	MH: "Marshall Islands",
	MD: "Maryland",
	MA: "Massachusetts",
	MI: "Michigan",
	MN: "Minnesota",
	MS: "Mississippi",
	MO: "Missouri",
	MT: "Montana",
	NE: "Nebraska",
	NV: "Nevada",
	NH: "New Hampshire",
	NJ: "New Jersey",
	NM: "New Mexico",
	NY: "New York",
	NC: "North Carolina",
	ND: "North Dakota",
	MP: "Northern Mariana Islands",
	OH: "Ohio",
	OK: "Oklahoma",
	OR: "Oregon",
	PW: "Palau",
	PA: "Pennsylvania",
	PR: "Puerto Rico",
	RI: "Rhode Island",
	SC: "South Carolina",
	SD: "South Dakota",
	TN: "Tennessee",
	TX: "Texas",
	UT: "Utah",
	VT: "Vermont",
	VI: "Virgin Islands",
	VA: "Virginia",
	WA: "Washington",
	WV: "West Virginia",
	WI: "Wisconsin",
	WY: "Wyoming",
};

const addressForm = {
	form: {
		classes: {},
	},
	items: [
		{
			key: "address",
			label: "Address",
			type: "text",
			required: true,
		},
		{
			key: "city",
			type: "autocomplete",
			label: "City",
			required: true,
			autocomplete: {
				provider: "trimblemaps",
				params: {
					componentRestrictions: { country: "us", includeOnly: "city" },
					types: ["(cities)"],
				},
				icon: "location",
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: "zipcode",
						label: "Zip code",
						type: "number",
						required: true,
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						type: "action",
						action: "CANCEL",
						label: "Cancel",
						button: {
							alwaysEnabled: true,
							classes: { root: "" },
						},
					},
					{
						type: "action",
						action: "SUBMIT",
						label: "Submit",
					},
				],
			},
		},
	],
};

const NewLocationSelect = ({ open, setOpen, location, selectLocation }) => {
	let startingLocation;
	const [coords, setCoords] = useState(null);
	const [addressData, setAddressData] = useState({});
	const shoulSetCoordsInit = false;

	const runReverseGeocode = async (c) => {
		try {
			const result = await reverseGeocode(c);
			if (result) {
				const city = `${result.metadata?.city}, ${result.metadata?.state}`;
				const city__view = {
					label: city,
					value: city,
					metadata: result.metadata,
				};

				setAddressData({
					...result.metadata,
					zipcode: result.metadata.zip,
					address: result.metadata.street,
					city__view,
				});
			}
		} catch (e) {
			console.error("[NewLocationSelect] runReverseGeocode:", e);
		}
	};

	const setCoordsWrapper = (c) => {
		if (!c.lng) {
			c.lng = c.long;
		}

		c.lat = parseFloat(c.lat ?? 0).toFixed(6);
		c.lng = parseFloat(c.lng ?? 0).toFixed(6);
		setCoords(c);
	};

	const initMap = async () => {
		let loc = startingLocation;
		if (loc?.label && !loc?.metadata?.coords) {
			const decoded = await geocode(loc?.label);
			if (decoded?.options && decoded.options[0]) {
				loc = decoded.options[0];
			}
		}

		const latLong = loc?.metadata?.coords ?? { long: -96, lat: 35 };

		let myMap;
		try {
			myMap = new TrimbleMaps.Map({
				container: "myMapManualLocationSelect",
				center: new TrimbleMaps.LngLat(latLong.long, latLong.lat),
				zoom: loc?.metadata?.coords ? 10 : 3,
			});
		} catch (e) {
			// sometimes takes a second or 2 for the myMap container element to be ready
			return setTimeout(initMap, 100);
		}

		const marker = new TrimbleMaps.Marker({
			draggable: true,
		})
			.setLngLat([latLong.long, latLong.lat])
			.addTo(myMap);

		marker.on("dragend", function (e) {
			const lngLat = e.target.getLngLat();
			setCoordsWrapper(lngLat);
		});

		myMap.on("click", function (e) {
			marker.setLngLat(e.lngLat);
			setCoordsWrapper(e.lngLat);
		});

		if (shoulSetCoordsInit) {
			setCoordsWrapper(latLong);
		}
	};

	const onClickSave = async (model) => {
		const { city__view, zipcode, address } = model;
		const { city, state, coords } = city__view.metadata;
		const fullAddress = `${address} ${city}, ${state}, USA (${coords.lat}, ${coords.long})`;

		const locationData = {
			value: fullAddress,
			label: fullAddress,
			description: `${city}, ${state}`,
			icon: "place",
			metadata: {
				street: address,
				city,
				country: "USA",
				countryName: "United States",
				state,
				stateName: states[state],
				zip: zipcode,
			},
		};

		selectLocation(locationData);

		const manualLocationsStr = localStorage.getItem("manualLocations");
		const manualLocations = manualLocationsStr ? JSON.parse(manualLocationsStr) : [];

		manualLocations.push(locationData);
		localStorage.setItem("manualLocations", JSON.stringify(manualLocations));

		setOpen(false);
	};

	useEffect(() => {
		if (!coords?.lat || !coords?.lng) return;

		runReverseGeocode(coords);
		// eslint-disable-next-line
	}, [coords, open]);

	useEffect(
		() => {
			if (!open) return;

			initMap();
		},
		// eslint-disable-next-line
		[open]
	);

	return (
		<Modal
			className="flex items-center justify-center"
			onClick={(event) => event.stopPropagation()}
			onMouseDown={(event) => event.stopPropagation()}
			onClose={() => setOpen(false)}
			open={open}
			keepMounted={false}
			disablePortal={false}
		>
			<Paper
				className="w-full flex bg-white flex-col p-16"
				elevation={3}
				variant={"elevation"}
				style={{ maxWidth: "700px" }}
			>
				<div className="px-4">
					<div
						id="myMapManualLocationSelect"
						className="flex w-full"
						style={{ minHeight: window.innerHeight > 880 ? "40vh" : "20vh", marginBottom: "1.9rem" }}
					></div>
				</div>
				<div className="flex flex-row w-full px-4" style={{ marginBottom: "1.9rem" }}>
					<div className="flex flex-col w-full">
						<Box className="flex flex-col w-full mb-16 rounded-md bg-gray-100 p-8 align-center">
							<Typography align="center" variant="caption">
								Your input:
							</Typography>
							<Typography align="center">{location}</Typography>
						</Box>
						<SmarthopFormView
							data={addressData}
							content={addressForm}
							onCancel={() => {
								setOpen(false);
							}}
							onSubmit={onClickSave}
						/>
					</div>
				</div>
			</Paper>
		</Modal>
	);
};

export default NewLocationSelect;
