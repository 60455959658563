// Assets
import onboardingMcAuthorityLetter from "../../../assets/images/onboarding/onboarding_mc_authority_letter.png";

const carrierOnboardingMcAuthority = {
	urlGET: "api/profile/carriers/:carrierId/documents/dot_authority_acceptance",
	urlPUT: "api/profile/carriers/:carrierId/documents/dot_authority_acceptance",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "MC authority acceptance page" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					type: "group",
					group: { classes: { child0: "w-full md:w-5/12", child1: "w-full md:w-7/12" } },
					content: {
						items: [
							{
								type: "image",
								items: [{ name: "MC authority", url: onboardingMcAuthorityLetter }],
							},
							{
								type: "group",
								group: { classes: { direction: "flex-col" } },
								content: {
									items: [
										{
											type: "message",
											label: `Remember, the file to be uploaded must contain documents shown in example`,
										},
										{
											key: "dot_authority_acceptance",
											type: "upload",
											label: "MC Authority Acceptance Page",
											translate: "MC_AUTHORITY_ACCEPTANCE_PAGE",
											upload: {
												url: "api/profile/carriers/:carrierId/files",
												documentType: "carrier",
												fileName: "dot_authority_acceptance",
												variant: "large",
											},
										},
										{ type: "action", action: "SUBMIT" },
									],
								},
							},
						],
					},
				},
			],
		},
	},
};

export default carrierOnboardingMcAuthority;
