import { CHANGE_CONTROL_PANEL, CLEAN_CONTROL_PANEL, TOGGLE_LEGEND } from "../constants";

export const changeControlPanel = (controlPanel) => ({
	type: CHANGE_CONTROL_PANEL,
	payload: controlPanel,
});

export const cleanControlPanel = () => ({
	type: CLEAN_CONTROL_PANEL,
});

export const toggleLegend = () => ({
	type: TOGGLE_LEGEND,
});
