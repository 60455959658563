import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

const DrawerButtons = ({ open, animateOpen, handleCollapse, handleClose }) => {
	return (
		<Box
			sx={{
				position: "absolute",
				top: "-45px",
				right: "-1px",
			}}
			style={{
				transition: "all 0.5s ease-out",
				transform: !animateOpen ? null : `translateY(45px)`,
			}}
		>
			<Paper elevation={0} style={{ border: 0, borderRadius: "20px 0 0 0" }}>
				<IconButton key="expandless" aria-label="Toggle drawer" color="inherit" onClick={handleCollapse}>
					<Icon
						style={{
							transition: "transform 0.5s ease-out",
							transitionDelay: "0.5s",
							transform: open ? null : `rotate(180deg)`,
						}}
					>
						expand_more
					</Icon>
				</IconButton>
				<IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
					<Icon>close</Icon>
				</IconButton>
			</Paper>
		</Box>
	);
};

export default DrawerButtons;
