import { Dialog, Typography, DialogContent, LinearProgress } from "@material-ui/core";

function LoadingDialog(props) {
	const open = props.open;
	const message = props.message;

	return (
		<Dialog open={open} maxWidth="xs" disableBackdropClick>
			<DialogContent>
				<div className={"flex flex-1 flex-col items-center justify-center p-24 mb-10"}>
					<Typography variant="subtitle1" color="textSecondary" className="pb-12 font-semibold">
						{message}
					</Typography>
					<LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="secondary" />
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default LoadingDialog;
