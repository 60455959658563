import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";
import { INVOICE_STATUSES_LABELS, INVOICE_STATUSES_SP_LABELS_ADMIN } from "app/main/consts/tripInvoiceConsts";

import Typography from "@material-ui/core/Typography";

const renderItem = (view, props, more) => {
	const mobile = props.mobile;
	const variant = props.variant;
	const status = view?.metadata?.status;
	const broker = view?.metadata?.broker;
	const config = props.config;

	if (!view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "TRIP_INVOICE_VIEW",
		dataIds: {
			carrierId: view?.metadata?.carrier,
			tripId: view?.metadata?.trip,
			invoiceId: view?.metadata?._id,
		},
	};

	const invoiceStatus = INVOICE_STATUSES_LABELS[status] ?? INVOICE_STATUSES_SP_LABELS_ADMIN[status];

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={view} variant={variant} dialog={dialog} />}
			second={
				<>
					{config?.showStatus && <CellSecondRow mobile={mobile} label={invoiceStatus ?? status} variant={variant} />}
					{config?.showBroker && <CellSecondRow mobile={mobile} label={broker} variant={variant} />}
					{config?.showSmartpayEligibility && view?.metadata?.smartpay_eligible && (
						<div className="flex flex-row mr-4 -mt-2">
							<Typography className="text-primary text-12 font-bold">Smart</Typography>
							<Typography className="text-secondary text-12 font-bold">Pay</Typography>
						</div>
					)}
				</>
			}
			more={more}
		/>
	);
};

function InvoicingCellView(props) {
	const hasMore = Array.isArray(props.view);
	// Render first
	const root = hasMore ? props.view[0] : props.view;
	const more = hasMore ? props.view.slice(1).map((view) => renderItem(view, props)) : null;
	return renderItem(root, props, more);
}

export default InvoicingCellView;
