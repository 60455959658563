const accountInformationForm = {
	urlGET: "api/profile/accounts/:accountId/details",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		view: { label: "Account Information" },
	},
	content: {
		view: {
			items: [
				{
					key: "email",
					label: "Email",
					type: "text",
				},
			],
		},
	},
};

export default accountInformationForm;
