import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { calculateCost, getActiveExpenses } from "./CostStructureCalculatorSettings";

const COLOR_MAP = {
	"Driver Pay": "#86198f",
	"Secondary Driver Pay": "#f87171",
	"Allocated Monthly Expenses": "#312e81",
	"GMV Percentage Cost": "#881337",
	"Per Mile Expenses": "#fb923c",
	"Carrier Cost": "#d8b4fe",
	"Fuel Cost": "#bf26d3",
};

const ExpenseRevenueChart = ({ revenue, data, height = 300, fromCalculator = false, withFixed = true }) => {
	const settings = useMemo(() => {
		const opt = {
			options: {
				chart: {
					toolbar: {
						show: false,
					},
					type: "bar",
					stacked: true,
				},
				xaxis: {
					categories: ["Expenses", "Revenue"],
				},
			},
			series: [],
		};

		const activeExpenses = getActiveExpenses(data);

		activeExpenses.forEach((expense) => {
			if (fromCalculator && expense.label === "Secondary Driver Pay" && !data.secondaryDriver?.secondaryDriver) return;
			if (!withFixed && expense.label === "Allocated Monthly Expenses") return;

			const cost = calculateCost(expense.equation, data, fromCalculator);

			if (!fromCalculator && expense.label === "Secondary Driver Pay" && !cost) return;

			opt.series.push({
				name: expense.label,
				data: [Math.round(cost), 0],
				color: COLOR_MAP[expense.label],
			});
		});
		opt.series.push({ name: "Revenue", data: [0, revenue], color: "#34d399" });

		return opt;
	}, [revenue, data, fromCalculator, withFixed]);

	return (
		<div className="w-full bg-white" style={{ maxHeight: `${height + 10}px` }}>
			<ReactApexChart options={settings.options} series={settings.series} type="bar" height={`${height}px`} />
		</div>
	);
};

export default ExpenseRevenueChart;
