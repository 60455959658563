import { formatCurrency } from "app/main/utils/tableUtils";
import { formatDate } from "app/main/utils/dateUtils";

const findAccountByPurpose = (accounts, type) =>
	accounts?.find((account) => account.data.attributes.tags.purpose?.toLowerCase() === type.toLowerCase());

const getAccountAttributes = (accounts, type) => findAccountByPurpose(accounts, type)?.data.attributes;

const smartpayAccountForm = (accountType, isAdmin) => {
	return {
		urlGET: `api/wallet/${isAdmin ? "admin/" : ""}carrier/:entityId`,
		listenEvent: "smarthopWalletRevision",
		idKey: "_id",
		otp: !isAdmin && ["WALLET_ACCESS"],
		content: {
			view: {
				items: [
					...(isAdmin
						? [
								{
									type: "group",
									content: {
										items: [
											{
												key: "application_status",
												type: "text",
												label: "Application Status",
												builder: (item) => item?.status,
											},
											{
												key: "account_status",
												type: "text",
												label: "Account Status",
												builder: (item) => getAccountAttributes(item?.data?.accounts, accountType)?.status,
											},
										],
									},
								},
						  ]
						: []),
					{
						type: "group",
						content: {
							items: [
								{
									key: "routing_number",
									type: "text",
									label: "Routing #",
									builder: (item) => getAccountAttributes(item?.data?.accounts, accountType)?.routingNumber,
								},
								{
									key: "account_number",
									type: "text",
									label: "Account #",
									builder: (item, mode) => {
										let value = getAccountAttributes(item?.data?.accounts, accountType)?.accountNumber;
										if (mode === "VIEW") value = value.replace(/.(?=.{4})/g, "*");
										return value;
									},
								},
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								...(isAdmin
									? [
											{
												key: "account_balance",
												type: "text",
												label: "Balance",
												builder: (item) =>
													`${formatCurrency(getAccountAttributes(item?.data?.accounts, accountType)?.balance / 100)}`,
											},
									  ]
									: []),
								{
									key: "account_available",
									type: "text",
									label: "Available",
									builder: (item) =>
										`${formatCurrency(getAccountAttributes(item?.data?.accounts, accountType)?.available / 100)}`,
								},
							],
						},
					},
					...(isAdmin
						? [
								{
									type: "group",
									content: {
										items: [
											{
												key: "application_date",
												type: "text",
												label: "Created At",
												builder: (item) =>
													formatDate(getAccountAttributes(item?.data?.accounts, accountType)?.createdAt),
											},
										],
									},
								},
						  ]
						: []),
				],
			},
		},
	};
};

const smartpayWalletAccountForm = (isAdmin) => smartpayAccountForm("checking", isAdmin);
const smartpayPaymentsAccountForm = (isAdmin) => smartpayAccountForm("payments", isAdmin);

export { smartpayWalletAccountForm, smartpayPaymentsAccountForm };
