const statesOptions = [
	{ state: "AL", label: "Alabama", value: 0 },
	{ state: "AK", label: "Alaska", value: 1 },
	{ state: "AZ", label: "Arizona", value: 2 },
	{ state: "AR", label: "Arkansas", value: 3 },
	{ state: "CA", label: "California", value: 4 },
	{ state: "CO", label: "Colorado", value: 5 },
	{ state: "CT", label: "Connecticut", value: 6 },
	{ state: "DE", label: "Delaware", value: 7 },
	{ state: "FL", label: "Florida", value: 8 },
	{ state: "GA", label: "Georgia", value: 9 },
	{ state: "HI", label: "Hawaii", value: 10 },
	{ state: "ID", label: "Idaho", value: 11 },
	{ state: "IL", label: "Illinois", value: 12 },
	{ state: "IN", label: "Indiana", value: 13 },
	{ state: "IA", label: "Iowa", value: 14 },
	{ state: "KS", label: "Kansas", value: 15 },
	{ state: "KY", label: "Kentucky", value: 16 },
	{ state: "LA", label: "Louisiana", value: 17 },
	{ state: "ME", label: "Maine", value: 18 },
	{ state: "MD", label: "Maryland", value: 19 },
	{ state: "MA", label: "Massachusetts", value: 20 },
	{ state: "MI", label: "Michigan", value: 21 },
	{ state: "MN", label: "Minnesota", value: 22 },
	{ state: "MS", label: "Mississippi", value: 23 },
	{ state: "MO", label: "Missouri", value: 24 },
	{ state: "MT", label: "Montana", value: 25 },
	{ state: "NE", label: "Nebraska", value: 26 },
	{ state: "NV", label: "Nevada", value: 27 },
	{ state: "NH", label: "New Hampshire", value: 28 },
	{ state: "NJ", label: "New Jersey", value: 29 },
	{ state: "NM", label: "New Mexico", value: 30 },
	{ state: "NY", label: "New York", value: 31 },
	{ state: "NC", label: "North Carolina", value: 32 },
	{ state: "ND", label: "North Dakota", value: 33 },
	{ state: "OH", label: "Ohio", value: 34 },
	{ state: "OK", label: "Oklahoma", value: 35 },
	{ state: "OR", label: "Oregon", value: 36 },
	{ state: "PA", label: "Pennsylvania", value: 37 },
	{ state: "RI", label: "Rhode Island", value: 38 },
	{ state: "SC", label: "South Carolina", value: 39 },
	{ state: "SD", label: "South Dakota", value: 40 },
	{ state: "TN", label: "Tennessee", value: 41 },
	{ state: "TX", label: "Texas", value: 42 },
	{ state: "UT", label: "Utah", value: 43 },
	{ state: "VT", label: "Vermont", value: 44 },
	{ state: "VA", label: "Virginia", value: 45 },
	{ state: "WA", label: "Washington", value: 46 },
	{ state: "WV", label: "West Virginia", value: 47 },
	{ state: "WI", label: "Wisconsin", value: 48 },
	{ state: "WY", label: "Wyoming", value: 49 },
];

const toStateViews = (stateIds) => {
	let states__view = [];
	stateIds?.forEach((stateId) => {
		let stateFound = statesOptions.find((state) => state.value === stateId);
		if (!stateFound) return;
		states__view.push({ value: stateId, label: stateFound.label, description: stateFound.state });
	});
	return states__view;
};

const toShortStateViews = (longState) => {
	let stateFound = statesOptions.find((state) => state.label === longState);
	return stateFound?.state
}

const getAllStates = () => {
	return statesOptions.map(state => ({state: state.state, label:state.label}));
}

const toBlackListCities = ["Crane, IN, USA"]

export { toStateViews, toShortStateViews, toBlackListCities, getAllStates };
