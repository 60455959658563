import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import TripStopPanel from "./TripStopPanel";
import trackingEventForm from "./configs/tripTrackingFormConfig";
import { addTrackingEvent } from "app/services/tripsServices";
import { useMemo } from "react";

function TripTracking({ setTitle, setSize, dataIds, onDone }) {
	useEffect(() => {
		setTitle?.(`Update Truck Location${dataIds?.debugMode ? " (Debug Mode) " : ""}`);
		setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const snackbar = useSnackbar();
	const dispatch = useDispatch();
	const carrierId = dataIds.carrierId;
	const tripId = dataIds.tripId;
	const debugMode = dataIds.debugMode;
	const [processing, setProcessing] = useState(false);
	// Search stop location by address
	let stopLocation;
	if (dataIds.status__view?.label?.includes("At")) {
		stopLocation = dataIds.data?.all_locations?.find(
			(location) => location.location === dataIds.status__view?.description
		);
	}
	const newStatus = dataIds.status__view?.label;
	//If the tracking add event came from status change disable geofencing
	const disableGeofence = !!dataIds.status__view;

	const formData = {
		last_location__view: dataIds.data?.last_location__view
			? dataIds.data.last_location__view
			: stopLocation
			? {
					metadata: { coords: { lat: stopLocation.coordinates.lat, long: stopLocation.coordinates.long } },
					value: stopLocation.location,
					label: stopLocation.location,
			  }
			: { value: dataIds.data?.last_location, label: dataIds.data?.last_location },
		comments: dataIds.comments,
	};

	const eventForm = useMemo(() => trackingEventForm(debugMode, dataIds.data), [debugMode, dataIds.data]);

	const handleSubmit = async (model) => {
		try {
			setProcessing(true);
			const city = model.last_location__view?.metadata?.city;
			const state = model.last_location__view?.metadata?.state;
			const trackingLog = {
				source: debugMode ? model.source : "TMS",
				lat: model.last_location__view?.metadata?.coords?.lat,
				long: model.last_location__view?.metadata?.coords?.long,
				city: city,
				street: model.last_location__view?.metadata?.street,
				state: state,
				address: model.last_location,
				eta: model.eta ? moment(model.eta).toISOString() : undefined,
				etaStop: model.eta ? model.eta_stop : undefined,
				comments: model.comments,
				newStatus,
				disableGeofence,
				debugMode,
				debugForceEvent: !!model.trigger_event,
				debugForceEventDate: model.event_date,
			};
			await addTrackingEvent(carrierId, tripId, { data: trackingLog });

			onDone?.();
			dispatch(incrementDataRevision({ event: "historyRevision" }));
			dispatch(incrementDataRevision({ event: "tripsRevision" }));
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			showSnackbar(snackbar, "Truck Location Added!", "success");
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
			setProcessing(false);
		}
	};

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-col md:flex-row md:space-x-16 py-10 px-10">
				<div className={"w-full md:w-6/12"}>
					<div className="flex flex-col md:flex-row md:space-x-16">
						<div className="flex flex-col w-full pt-10">
							<SmarthopFormView
								key="add_tracking_event"
								content={eventForm}
								data={formData}
								noInitValidation={true}
								onSubmit={handleSubmit}
								processing={processing}
							/>
						</div>
					</div>
				</div>
				<div className={"w-full md:w-6/12 "}>
					<div className="flex flex-col md:flex-row md:space-x-16">
						<div className="flex flex-col w-full">
							<TripStopPanel condensed={true} classes={{ root: "w-full" }} load={dataIds.data} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TripTracking;
