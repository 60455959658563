import ButtonShowMore from "app/main/tools/ButtonShowMore";

const showMessage = (message) => {
	let textLength = 60;
	console.log(message);
	if (message?.length > textLength) {
		return <ButtonShowMore text={message} length={textLength} />;
	}
	return message;
};

const postTruckLogs = {
	urlGET: "api/post-truck/:id/logs",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "POST_TRUCK_HISTORY_LOGS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		items: [
			{ key: "updatedAt", type: "date", label: "Updated" },
			{ key: "userEmail", type: "text", label: "User" },
			{ key: "updated_by", type: "text", label: "Updated by" },
			{ key: "action", type: "text", label: "Action", builder: (item) => item?.action.toUpperCase() },
			{ key: "request", type: "text", label: "Request", builder: (item) => showMessage(JSON.stringify(item.request)) },
			{
				key: "response",
				type: "text",
				label: "Response",
				builder: (item) => showMessage(JSON.stringify(item.response)),
			},
		],
	},
};

export default postTruckLogs;
