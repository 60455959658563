import moment from "moment";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import { SmarthopConfirmDialog } from "@smarthop/form";
import { getConfigurationOnboardingStatus } from "../utils/onboardingUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { setBarOffsetOn } from "app/store/tools/tableSlice";

import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import { isOnboardingCompleted, isRoleExternal } from "app/services/LoginService";
import { getIssues } from "app/services/issueServices";

import BannerAlertView from "./BannerAlertView";

function BannerAlerts({ hide }) {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const [moreMenuEl, setMoreMenuEl] = useState(null);
	const [detailsPreview, setDetailsPreview] = useState(null);

	// App version
	const frontendRevision = useSelector(({ tools }) => tools.revision["frontendRevision"]);

	// Onboarding
	const finishOnboardingRevision = useSelector(({ tools }) => tools.revision["onboardingUpdateBannerRevision"]);
	const onboardingStatus = user?.onboardingStatus;
	const onboardingConfig = useMemo(() => getConfigurationOnboardingStatus({ onboardingStatus }), [onboardingStatus]);
	const showOnboardingAlert =
		user.roleType !== "INTERNAL" && !["IN_REVIEW", "ACCEPTED", "INCOMPLETE"].includes(onboardingStatus);

	// Action Required Notification
	const requiredActionTransactions = useSelector(
		({ transactions }) => transactions.transactionsUser.requiredActionTransactions
	);

	// Browser Notifications
	const browserNotificationSupported = !!("Notification" in window);
	const [permission, setPermission] = useState(!browserNotificationSupported ? "none" : Notification?.permission);
	const [incognitoState, setIncognitoState] = useState(null);
	const [notificationOff, setNotificationOff] = useState(
		"OFF" === localStorage.getItem("browser_notification_warning")
	);

	// Reported issues with the app
	const [issues, setIssues] = useState([]);
	const issueRevision = useSelector(({ tools }) => tools.revision["issueRevision"]);

	useEffect(() => {
		if (!browserNotificationSupported) {
			return;
		}

		let inactive = false;
		(async () => {
			try {
				if ("storage" in navigator && "estimate" in navigator.storage) {
					const { usage, quota } = await navigator.storage.estimate();
					if (inactive) return;
					console.log(`[BrowserNotificationAlert] Browser using ${usage} out of ${quota} bytes.`);
					setIncognitoState(quota < 1000000000 ? "CAN_BE_INCOGNITO" : "NOT_INCOGNITO");
				} else {
					setIncognitoState("NOT_INCOGNITO");
				}
			} catch (e) {
				setIncognitoState("NOT_INCOGNITO");
			}
		})();

		if (permission === "granted" && notificationOff) {
			localStorage.setItem("browser_notification_warning", "ON");
			setNotificationOff(false);
		}

		navigator?.permissions?.query({ name: "notifications" }).then((notificationStatus) => {
			notificationStatus.onchange = () => {
				setPermission(notificationStatus.state);
			};
		});
		// eslint-disable-next-line
	}, [browserNotificationSupported]);

	useEffect(() => {
		const fetchIssues = async () => {
			try {
				const res = await getIssues();
				setIssues(res);
			} catch (err) {
				setIssues([]);
			}
		};

		fetchIssues();
		let interval = setInterval(fetchIssues, 5 * 60 * 1000);
		return () => clearInterval(interval);
	}, [issueRevision]);

	const userSummary = useMemo(() => {
		return {
			activationCompleted: localStorage.getItem("onboardingStatus"),
			onboardingCompleted: isOnboardingCompleted(),
			viewerIsExternal: isRoleExternal(),
			onboardingStatus: user?.onboardingStatus,
			accountStatus: user?.accountStatus,
		};
		// eslint-disable-next-line
	}, [finishOnboardingRevision]);

	let banners = [];

	// Required Transaction Actions
	if (requiredActionTransactions?.length) {
		banners.push({
			severity: "error",
			message: requiredActionTransactions?.[0]?.action?.message,
			onAction: () => dispatch(openLoadedFormDialog(requiredActionTransactions?.[0]?.action?.dialog)),
		});
	}

	// Reported issues with the app
	if (issues?.items?.length) {
		for (let data of issues?.items) {
			banners.push({
				severity: data?.priority === "high" ? "error" : "warning",
				message: data?.issue,
				details: data?.description,
			});
		}
	}

	// Detecten new version of web app
	if (frontendRevision > 0) {
		banners.push({
			severity: "warning",
			message: "Reload this page to get latest version of the Smarthop app",
			action: "Reload Now",
			onAction: () => window.location.reload(true),
		});
	}

	// Onboarding
	if (showOnboardingAlert) {
		banners.push({
			severity: onboardingConfig?.colorBanner,
			message: onboardingConfig?.bannerText,
			navigateTo: !onboardingConfig?.bannerLinkHide ? "/onboarding" : null,
		});
	}

	// Billing
	if (userSummary.viewerIsExternal && userSummary.onboardingCompleted && !!user?.paymentMethodError) {
		banners.push({
			severity: "error",
			message: "Recent payment failure. Please update your payment method.",
			navigateTo: user.role === "CARRIER_OWNER" ? "/billing/details" : null,
		});
	}

	// Plan subscriptions / trial
	if (userSummary.viewerIsExternal && !user.hasDefaultPayment && user.hasStripeSubscription) {
		let message;
		if (user.freeTrialActive && moment(user.freeTrialEndDate).diff(moment.now(), "days") <= 5) {
			const trialEndFormatted = moment(user.freeTrialEndDate).format("MMM DD HH:mma");
			message = `Your trial is approaching its end. Please update your payment method before ${trialEndFormatted}.`;
		} else if (
			!user.freeTrialActive &&
			!user.hasDefaultPayment &&
			moment(user.freeTrialEndDate).diff(moment.now(), "days") <= 3
		) {
			message = "Please update your payment method.";
		} else if (user.hasStripeSubscription && !user.hasDefaultPayment) {
			message = "Please update your payment method.";
		}

		if (message) {
			banners.push({
				severity: "error",
				message,
				navigateTo: user.role === "CARRIER_OWNER" ? "/billing/details" : null,
			});
		}
	}

	// Profile
	if (userSummary.onboardingCompleted && !user?.email_confirmed) {
		banners.push({
			severity: "error",
			message: "Please confirm your email address",
			onAction: () => dispatch(openLoadedFormDialog({ viewId: "EMAIL_CONFIRM" })),
		});
	}
	if (userSummary.onboardingCompleted && !user?.phone_confirmed) {
		banners.push({
			severity: "error",
			message: "Please confirm your phone number",
			onAction: () => dispatch(openLoadedFormDialog({ viewId: "PHONE_CONFIRM" })),
		});
	}

	// Browser notifciations not enabled
	if (
		browserNotificationSupported &&
		permission !== "granted" &&
		incognitoState &&
		!(incognitoState === "CAN_BE_INCOGNITO" && permission === "denied") &&
		!notificationOff
	) {
		if (permission === "default") {
			banners.push({
				severity: "info",
				message: "Browser notifications disabled. Please activate them to be notified on new messages.",
				onAction: () => Notification.requestPermission(),
			});
		} else if (permission === "denied") {
			banners.push({
				severity: "info",
				message: "Browser notifications disabled. Enable notifications in site settings.",
				action: "Hide",
				onAction: () => {
					localStorage.setItem("browser_notification_warning", "OFF");
					setNotificationOff(true);
				},
			});
		}
	}

	const bannersCount = banners.length;
	const [hideTemporary, setHideTemporary] = useState(null);
	const barHidden = useMemo(
		() =>
			// Disabled outside, probably deactivated account
			hide ||
			// No banners to show
			bannersCount === 0 ||
			// Temporary hide banner, for 5 mins, or until number of banners changes
			(hideTemporary?.start.isAfter(moment().subtract(5, "minutes")) && hideTemporary.count === bannersCount),
		[hideTemporary, hide, bannersCount]
	);

	// Updating redux to add offset for tables
	useEffect(() => {
		dispatch(setBarOffsetOn(!barHidden));
		// eslint-disable-next-line
	}, [barHidden]);

	if (barHidden) return null;

	return (
		<div className={"flex w-full items-center justify-center"}>
			<BannerAlertView
				banner={banners[0]}
				startView={
					<Tooltip title={"Temporary hide notification banner"}>
						<IconButton size="small" onClick={() => setHideTemporary({ start: moment(), count: bannersCount })}>
							<Icon className="text-18 text-white">clear</Icon>
						</IconButton>
					</Tooltip>
				}
				endView={
					banners?.length - 1 > 0 && (
						<Tooltip title={"Show other notifications"}>
							<IconButton size="small" onClick={(event) => setMoreMenuEl(event.target)}>
								<Badge
									badgeContent={banners?.length - 1}
									color="secondary"
									classes={{
										badge: `border-3 border-orange-700 bg-white text-orange-700 font-semibold px-2 -mt-2 -mr-2`,
									}}
								>
									<Icon className="font-bold text-white">expand_more</Icon>
								</Badge>
							</IconButton>
						</Tooltip>
					)
				}
				linkOnClickHandler={setDetailsPreview}
			/>
			{banners?.length > 1 && (
				<>
					<Popover
						classes={{ paper: "pl-10 pr-10 pb-10" }}
						id={"popover"}
						anchorEl={moreMenuEl}
						open={!!moreMenuEl}
						onClose={() => setMoreMenuEl(null)}
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
						transformOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<div className={"flex flex-col pt-10"}>
							{banners.map(
								(item, i) =>
									i > 0 && (
										<div key={i} className={i === banners?.length - 1 ? "" : "pb-10"}>
											<BannerAlertView banner={item} linkOnClickHandler={setDetailsPreview} />
										</div>
									)
							)}
						</div>
					</Popover>
				</>
			)}
			<SmarthopConfirmDialog
				open={detailsPreview}
				title={detailsPreview?.title}
				message={detailsPreview?.message}
				handleClose={() => setDetailsPreview(null)}
				closeMsg={"Close"}
			/>
		</div>
	);
}

export default BannerAlerts;
