import React, { useEffect } from "react";
import StrategyView from "./StrategyView";

function StrategyDialog(props) {
	useEffect(() => {
		props?.setTitle?.("Strategy Analyzer");
		// eslint-disable-next-line
	}, []);
	const { entryPoint, ...dataIds } = props.dataIds;
	return <StrategyView dataIds={dataIds} entryPoint={entryPoint} />;
}

export default StrategyDialog;
