// Dependencies
import { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { axios } from "app/services/requestUtil";
import _ from "@lodash";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Services
import { createHeaders } from "app/services/requestUtil";

// Utils
import createForm from "./exportListForm";
import { formatDate } from "app/main/utils/dateUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { generateCSV, downloadFile } from "app/main/utils/fileUtils";

const ITEMS_PER_REQUEST = 200;
const MAX_ITEMS_PER_FILE = 1000;

const uniqueObjectArrayById = (array) =>
	array.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));

const findCorrectField = (item) => {
	let value = item;
	if (item?.label) {
		value = item.label;
	} else if (item?.reason) {
		value = item.reason;
	}
	return value;
};

const formatValue = (value, type) => {
	if (type === "currency") {
		return formatCurrency(value);
	}
	if (!value && type !== "text") {
		return "";
	}
	if (type === "date") {
		return formatDate(value);
	}
	if (_.isString(value) || _.isNumber(value)) {
		return value;
	}
	if (_.isBoolean(value)) {
		return value ? "Yes" : "No";
	}

	return "";
};

const buildRequest = ({ notUrlDataIds, requestData, offset, limit }) => {
	let processedRequestFilters = {};
	Object.keys(requestData?.filters ?? {}).forEach((key) => {
		if (!key.includes("__view") && key !== "search") {
			processedRequestFilters[key] = requestData.filters[key];
		}
	});
	return {
		offset,
		limit,
		...notUrlDataIds,
		sortOrder: requestData.sort?.dir,
		sortBy: requestData.sort?.key,
		search: requestData.filters?.search ?? "",
		filters: { ...(processedRequestFilters ?? {}), ...(requestData.staticFilters ?? {}) },
		groups: { ...(requestData.groups ?? {}) },
		settings: requestData.settings ?? {},
		...(requestData.staticParams ?? {}),
	};
};

const ExportListView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Export File");
		props?.setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const { content, notUrlDataIds, resultData, requestData, urlGET } = props.dataIds;

	// Max 1000 items per file
	const totalItems = resultData?.page?.total;
	const exceedsMax = totalItems > MAX_ITEMS_PER_FILE;
	const limit = Math.min(totalItems, MAX_ITEMS_PER_FILE);

	const elegibleItems = useMemo(() => {
		let visibleColumns = [];
		content?.items?.forEach((item) => {
			const hidden = !!item?.hidden;
			const skip = !!item?.export?.skip;
			if ((item.internal && !content?.internalView) || hidden) return;
			if (item.group && !resultData.groups?.[item.group]) return;
			if (item.type === "menu") return;
			if (skip) return;
			const key = item?.key?.replaceAll(".", "-");
			if (!key) return;
			visibleColumns.push({
				key,
				value: key,
				type: item.type,
				label: Array.isArray(item.label) ? item.label.join(" ") : item.label,
				builder: item?.export?.builder,
			});
			const extraColumns = item?.export?.extra;
			if (Array.isArray(extraColumns)) {
				extraColumns.forEach((extraColumn) => {
					const { label, value } = extraColumn;
					const [key, builder] = _.isFunction(value) ? [label, value] : [value, null];
					visibleColumns.push({
						key,
						value: key,
						type: item.type,
						label,
						builder,
					});
				});
			}
		});

		return visibleColumns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content?.items, resultData.groups]);

	const defaultValues = useMemo(
		() =>
			elegibleItems.reduce((accumulator, value) => {
				return { ...accumulator, [value.key]: true };
			}, {}),
		[elegibleItems]
	);

	const [data, setData] = useState(defaultValues);
	const [loading, setLoading] = useState(false);

	const customFormHandler = (model) => {
		let newData = { ...data, ...model };
		setData(newData);
	};

	const handledAction = async () => {
		setLoading(true);

		// Split requests, 200 items per request
		const requestNumber = Math.ceil(limit / ITEMS_PER_REQUEST);
		const requests = [];

		for (let i = 0; i < requestNumber; i++) {
			const request = buildRequest({
				notUrlDataIds,
				requestData,
				offset: ITEMS_PER_REQUEST * i,
				limit: ITEMS_PER_REQUEST,
			});
			requests.push(axios.get(urlGET, { params: request, headers: createHeaders() }));
		}

		try {
			const res = await Promise.all(requests);
			const mixedRes = res.reduce((accumulator, value) => {
				return [...accumulator, ...value.data.items];
			}, []);

			// Remove duplicated items
			const uniqueRes = uniqueObjectArrayById(mixedRes);
			console.log(`[ExportListView] GET: loaded data, url=${urlGET} length=${uniqueRes?.length}`);

			// Getting fields and items to export
			const fields = elegibleItems.filter((item) => data[item.key]);
			const items = uniqueRes.map((item) => {
				let row = {};
				// Iterate column keys
				for (const { key, type, builder } of fields) {
					let value;
					const objKeys = key?.split("-");
					objKeys?.forEach((objKey) => {
						value = value ? value[objKey] : item[objKey];
					});

					value = value?.label ?? value;

					if (Array.isArray(value)) {
						value = value.map((item) => findCorrectField(item)).join(", ");
					} else if (!!value && typeof value === "object") {
						value = JSON.stringify(value);
					} else if (builder) {
						value = builder(item);
					}

					row[key] = formatValue(value, type);
				}
				return row;
			});

			// Generate and download CSV file
			const { content: fileContent, mime } = generateCSV(fields, items);
			const name = `${content?.export?.fileName} - ${moment().format("YYYY-MM-DD")}.csv`;
			downloadFile(fileContent, name, mime);

			setLoading(false);
			dispatch(closeFormDialog());
		} catch (error) {
			console.warn(`[ExportListView] GET: failed to load data, url=${urlGET}`, error);
		}
	};

	const form = createForm({ elegibleItems, exceedsMax });
	const mode = "CREATE";

	return (
		<SmarthopFormView
			mode={mode}
			data={data}
			content={form}
			loading={loading}
			noInitValidation={true}
			trackChangedFields={["ALL"]}
			onChangeCommitted={customFormHandler}
			onCustom={handledAction}
		>
			{props.children}
		</SmarthopFormView>
	);
};

export default ExportListView;
