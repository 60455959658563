import ReactApexChart from "react-apexcharts";
import { formatCurrency } from "app/main/utils/tableUtils";
import Typography from "@material-ui/core/Typography";

function LandingMarketWidget(props) {
	const { market, type, height, color } = props;
	const value = (market?.n_top_lanes > 0 ? market?.n_top_lanes_balanced_profit / market?.n_top_lanes : 0) * 100;
	const series = [Math.round(value)];
	const rpm = market?.rpm;
	const fontSize = height < 250 ? "14px" : "17px";
	const options = {
		plotOptions: {
			radialBar: {
				track: {
					background: "#e1e9f4",
					strokeWidth: "60%",
					margin: 10,
				},
				dataLabels: {
					showOn: "always",
					name: {
						show: true,
						color: "white",
						fontWeight: 600,
						fontSize: fontSize,
						offsetY: -4,
					},
					value: {
						color: "white",
						fontSize: fontSize,
						fontWeight: 550,
						show: true,
						offsetY: 2,
					},
					style: {
						colors: ["#333"],
					},
				},
			},
		},
		fill: {
			opacity: 2,
			colors: [color],
			type: "fill",
		},
		labels: ["Profitablity"],
	};

	return (
		<div className="w-full flex flex-col bg-opacity-60 bg-black rounded-lg border-1 border-white tracking-wider">
			<Typography className="self-center text-white -mb-24 mt-28 text-13 ml:text-15 font-semibold tracking-wider">{`${type} - RPM ${formatCurrency(
				rpm
			)}`}</Typography>
			<ReactApexChart options={options} series={series} type="radialBar" height={height} />
		</div>
	);
}

export default LandingMarketWidget;
