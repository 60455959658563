import { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { IconButton, Icon, Menu, MenuItem } from "@material-ui/core";
import { postSmartAction } from "app/services/messengerServices";

const _LIST_ITEM_TITLES = {
	TRUCK_PLAN_REMINDED: "Plan Reminder",
};

const SmartMessageButton = (props) => {
	const disabled = props.disabled;

	const snackbar = useSnackbar();
	const [smartMsgBtnEl, setSmartMsgBtnEl] = useState(null);
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);

	const onSelectSmartMsg = async (smartMsgType) => {
		try {
			const data = {
				notificationType: smartMsgType,
			};

			if (smartMsgType === "TRUCK_PLAN_REMINDED") {
				data.carrierId = activeChat?.metadata?.truck?.carrier;
				data.truckId = activeChat?.truck;

				await postSmartAction(data);
			}
		} catch (error) {
			showErrorSnackbar(snackbar, { message: error?.errors?.[0]?.message ?? "Something went wrong, please try again" });
		}

		setSmartMsgBtnEl(null);
	};

	return (
		<>
			<IconButton aria-label="smart message" disabled={disabled} onClick={(e) => setSmartMsgBtnEl(e.currentTarget)}>
				<Icon>add</Icon>
			</IconButton>
			<Menu id="smart-message" anchorEl={smartMsgBtnEl} open={!!smartMsgBtnEl} onClose={() => setSmartMsgBtnEl(null)}>
				{Object.keys(_LIST_ITEM_TITLES).map((itemKey) => (
					<MenuItem key={itemKey} onClick={() => onSelectSmartMsg(itemKey)}>
						{_LIST_ITEM_TITLES[itemKey]}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default SmartMessageButton;
