import { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import MoveDetails from "./MoveDetails";
import LocationMarker from "./LocationMarker";

const useStyles = makeStyles((theme) => {
	return {
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		emptyMove: {
			backgroundImage: "linear-gradient(#d10e0e 0%, #d10e0e 50% , #ffffff 51% )",
			backgroundSize: "16px 25px, 100% 25px",
		},
	};
});

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
	},
})(TimelineItem);

const MovesTimeline = (props) => {
	const { nativeMobile, pathMoves, strategyReqData, searchId } = props;
	const [autoValidate, setAutoValidate] = useState(false);
	const classes = useStyles();
	const lastMove = pathMoves?.slice(-1).pop();
	const moves = pathMoves?.slice(1);

	return (
		<div>
			{!nativeMobile && (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Typography className="font-semibold text-16 pl-10 pb-10 ">{"Recommended Moves"}</Typography>
					{pathMoves?.some((m) => !m?.data?.stop?.empty) && (
						<Button
							className="mr-10 text-8"
							size="small"
							color="primary"
							variant="contained"
							disabled={autoValidate}
							onClick={() => {
								setAutoValidate(true);
							}}
						>
							Search All
						</Button>
					)}
				</div>
			)}
			<Timeline className="-ml-14">
				{moves?.map((move, moveIndex) => {
					const isEmpty = move?.data?.stop?.empty;
					const selected = move?.data?.stop?.selected;
					return (
						<StyledTimelineItem key={move?.data?.stop?._id}>
							<LocationMarker moveIndex={moveIndex} isEmpty={isEmpty} isSelected={selected} classes={classes} />
							<MoveDetails
								move={move}
								strategyReqData={strategyReqData}
								searchId={searchId}
								autoValidate={autoValidate}
								selected={selected}
							/>
						</StyledTimelineItem>
					);
				})}
				<StyledTimelineItem>
					<LocationMarker
						moveIndex={pathMoves.length - 1}
						isEmpty={lastMove.data.stop.empty}
						isSelected={lastMove.data.stop.selected}
						classes={classes}
						connector={false}
					/>
					<MoveDetails move={lastMove} isLastMove={true} />
				</StyledTimelineItem>
			</Timeline>
		</div>
	);
};

export default MovesTimeline;
