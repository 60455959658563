import { deleteFavoriteLoad } from "app/services/searchServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import { formatCurrency } from "app/main/utils/tableUtils";

const onDeleteFavoriteLoad = async (item, _, dispatch) => {
	try {
		await deleteFavoriteLoad(item._id);
		dispatch(incrementDataRevision({ event: "pinnedLoadsRevision" }));
	} catch (error) {
		dispatch(
			showMessage({
				message: error.errors?.[0]?.message,
				variant: "error",
			})
		);
	}
};

const viewTypeFavoriteLoad = (item) => {
	let typeLoad = item?.type;
	let color = typeLoad === "LIKED" ? "bg-orange" : "bg-blue";
	if (typeLoad === "SHARED") {
		color = "bg-green";
	}
	return <div className={"rounded-full py-2 px-5 text-xs text-white " + color}>{typeLoad}</div>;
};

const favorireLoadsList = (isExternalUser = false, hasSubaccounts = false) => ({
	urlGET: "/api/search/users/:userId/favorite/loads",
	idKey: "_id",
	listenEvent: "pinnedLoadsRevision",
	segmentKey: "FAVORITE_LOADS",
	content: {
		grid: {
			theme: "light",
			variant: "skinny",
			tilesPerRow: 1,
			itemsPerRow: 3,
			layout:
				isExternalUser && !hasSubaccounts
					? [
							["truck", "driver", "origin"],
							["destination", "rate", "rpm"],
					  ]
					: [["carrier"], ["truck", "driver", "origin"], ["destination", "rate", "rpm"]],
			mobile: {
				tilesPerRow: 1,
				itemsPerRow: 2,
			},
		},
		order: {
			defaut: { key: "createdAt", dir: "desc" },
		},
		items: [
			{
				key: "trip_id",
				type: "text",
				label: "Load Id",
				grid: { headerTitle: true },
			},
			{
				key: "type",
				type: "component",
				label: "type",
				grid: { headerTitle: true },
				builder: (item) => viewTypeFavoriteLoad(item),
			},
			{
				key: "type",
				type: "component",
				label: "type",
				grid: { headerTitle: true },
				builder: (item) => {
					return <div className={"rounded-full py-2 px-10 text-xs text-white bg-blue-500"}>{item?.timeLive}</div>;
				},
			},
			...(isExternalUser && !hasSubaccounts
				? []
				: [
						{
							key: "carrier",
							type: "text",
							label: "Carrier",
							builder: (item) => item.carrier__view?.label ?? "No Information",
						},
				  ]),
			{
				key: "truck",
				type: "text",
				label: "Truck",
				builder: (item) => item.truck__view?.description ?? "No Information",
			},
			{
				key: "driver",
				type: "text",
				label: "Driver",
				builder: (item) => item.truck__view?.label ?? "No Information",
			},
			{
				key: "origin",
				type: "text",
				label: "Origin",
			},
			{
				key: "destination",
				type: "text",
				label: "Destination",
			},
			{
				key: "rate",
				type: "number",
				label: "Rate",
				builder: (item) => formatCurrency(item.rate),
			},
			{
				key: "rpm",
				type: "number",
				label: "RPM",
				builder: (item) => formatCurrency(item.rpm),
			},
			{
				type: "menu",
				items: [
					{
						key: "details",
						label: "Details",
						variant: "button",
						button: { color: "primary" },
						dialogBuilder: (item) => ({
							viewId: "LOAD_SUGGEST_VIEW",
							dataIds: {
								loadId: item.trip_id,
								userId: item.user,
								searchId: item.searchId,
								carrierId: item.carrier,
								carrier__view: item.carrier__view,
								truckId: item.truck,
								truck__view: item.truck__view,
								driverId: item.driver,
								driver__view: item.driver__view,
								isFromSearch: true,
							},
						}),
					},
					{
						key: "delete",
						label: "Delete",
						dispatchAction: onDeleteFavoriteLoad,
					},
				],
			},
		],
	},
});

export default favorireLoadsList;
