// Dependencies
import { Typography, Icon, Card, CardContent, Button, CardActions } from "@material-ui/core";

// Components
import PlatformPricePlan from "./PlatformPlanPrice";
import PlatformPlanHeader from "./PlatformPlanHeader";

const PlatformPlanCard = (props) => {
	const plan = props.plan;
	const customer = props.customer;
	const price = props.price;
	const disabled = props.disabled;
	const isTrialAllowed = props.isTrialAllowed;
	const allowToPreselectPlan = props.allowToPreselectPlan;
	const isMostPopular = props.isMostPopular;
	const onClick = props.onClick;

	const currentPrice = customer?.subscription?.plan?.id;

	const isLimitedPlan = plan?.tier === "TIER_LIMITED";
	const isPostTrial = customer?.postSubscriptionPlan?.plan === plan._id && customer?.postSubscriptionPlan?.endTrialDate;

	return (
		<Card
			className={"flex flex-col p-24 h-full relative rounded-16 min-w-240 ml:min-w-256 max-w-300"}
			style={isMostPopular ? { border: "4px solid #E7683D" } : {}}
			raised
		>
			{/* title and trial */}
			<PlatformPlanHeader {...props} />

			<CardContent className="h-full text-center p-0">
				{/* price */}
				<PlatformPricePlan {...props} isLimitedPlan={isLimitedPlan} />

				{/* description */}
				<div className="flex flex-col w-full items-start pt-10 pb-10">
					{plan.description?.split("\n").map((paragraph, i) => {
						return paragraph?.length > 0 ? (
							<Typography
								key={"paragraph" + i}
								className="w-full text-center text-13 ml:text-14 pb-10 pt-10 leading-snug"
							>
								{paragraph}
							</Typography>
						) : null;
					})}
				</div>
			</CardContent>

			<CardActions className="justify-center relative">
				{isPostTrial ? (
					<div className="flex flex-row items-center justify-center pt-16 mb-4">
						<Icon className={"text-green text-28 -ml-10 mr-6"}>check_circle</Icon>
						<Typography
							variant="subtitle1"
							color="inherit"
							className="text-16 ml:text-18 font-semibold text-green tracking-wide"
						>
							{"On Trial"}
						</Typography>
					</div>
				) : (currentPrice && price && currentPrice === price?.id) || (isLimitedPlan && !currentPrice) ? (
					<div className="flex flex-row items-center justify-center pt-16 mb-4">
						<Icon className={"text-green text-28 -ml-10 mr-6"}>check_circle</Icon>
						<Typography
							variant="subtitle1"
							color="inherit"
							className="text-16 ml:text-18 font-semibold text-green tracking-wide"
						>
							{allowToPreselectPlan && !customer?._id ? "Selected" : "Subscribed"}
						</Typography>
					</div>
				) : (
					<div className={"mt-12 w-full"}>
						<Button variant="contained" color="primary" className="h-44 w-full" disabled={disabled} onClick={onClick}>
							{allowToPreselectPlan && isTrialAllowed && plan.trialDays > 0
								? "Select Plan"
								: isTrialAllowed && plan.trialDays > 0
								? "Start Trial"
								: "Subscribe"}
						</Button>
					</div>
				)}
			</CardActions>
		</Card>
	);
};

export default PlatformPlanCard;
