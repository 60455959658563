const ROLES_LABLES = {
	CARRIER_MANAGER: "Manager",
	CARRIER_OWNER: "Onwer",
	CARRIER_DRIVER: "Driver",
	CARRIER_DISPATCHER: "Dispatcher",
	CARRIER_GENERIC: "Investor",
	INTERNAL: "Internal",
};

const ucWords = (str) => {
	return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

const getUsers = (users) => {
	let usersLabels = "";
	for (let user of users) {
		if (usersLabels.length > 0) usersLabels += ", ";
		usersLabels += ROLES_LABLES[user];
	}
	return usersLabels;
};
const getProducts = (products) => {
	let productsLabels = "";
	for (let product of products) {
		if (productsLabels.length > 0) productsLabels += ", ";
		productsLabels += ucWords(product);
	}
	return productsLabels;
};
const adminSystemIssuesList = {
	urlGET: "api/issues",
	idKey: "_id",
	listenEvent: "issueRevision",
	segmentKey: "ADMIN_ISSUES_REVISION",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["created_at"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		defaults: {
			initial: {
				filters: {
					issue_active: "ACTIVE",
				},
			},
			items: [
				{
					label: "Active",
					filters: {
						issue_active: "ACTIVE",
					},
				},
				{
					label: "Deactivate",
					filters: {
						issue_active: "INACTIVE",
					},
				},
			],
		},
		filters: {
			search: true,
			searchDescription: "Search by priority or issue",
		},
		groups: {},
		items: [
			{ key: "createdAt", type: "date", label: "Creation Date" },
			{
				key: "priority",
				type: "text",
				label: "Priority",
			},

			{
				key: "issue",
				type: "text",
				label: "Issue",
				collapsibleLength: 60,
			},
			{
				key: "users",
				type: "component",
				label: "Users",
				builder: (item) => {
					return !item?.users?.length ? "Any" : getUsers(item?.users);
				},
			},
			{
				key: "products",
				label: "Products",
				type: "component",
				builder: (item) => {
					return !item?.products?.length ? "Any" : getProducts(item?.products);
				},
			},
			{
				key: "deactivateDate",
				type: "date",
				label: "Deactivate Date",
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						dialogBuilder: (item, dataIds) => ({
							formId: "ADMIN_ISSUES",
							mode: "EDIT",
							dataIds: { id: item._id },
						}),
						isVisible: (item) => item.active,
					},
					{
						key: "deactivate",
						label: "Deactivate",
						dialogBuilder: (item, dataIds) => ({
							formId: "ADMIN_ISSUES_DEACTIVATE",
							mode: "EDIT",
							dataIds: { id: item._id },
						}),
						isVisible: (item) => item.active,
					},
				],
			},
		],
	},
};

export default adminSystemIssuesList;
