import { ADD_DIAGNOSTIC_MESSAGE, CLEAR_DIAGNOSTIC_MESSAGES, SET_DIAGNOSTIC_MESSAGES } from "../constants";

export const addDiagnosticMessage = (message) => ({
	type: ADD_DIAGNOSTIC_MESSAGE,
	payload: message,
});

export const setDiagnosticMessages = (messages) => ({
	type: SET_DIAGNOSTIC_MESSAGES,
	payload: messages,
});

export const clearDiagnosticMessages = () => ({
	type: CLEAR_DIAGNOSTIC_MESSAGES,
});
