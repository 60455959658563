import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { global } from "app/services/requestUtil";

export const fetchSubAccount = createAsyncThunk("subAccount", async (data, { dispatch }) => {
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const accountId = data.accountId;
	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.get(`api/profile/accounts/${accountId}/sub-accounts-context`, { headers: headers });

	return response.data;
});

const accountSlice = createSlice({
	name: "auth/account",
	initialState: {
		accountId: null,
		subAccount: {
			tier: null,
			list: null,
			hasSubaccounts: false,
			totalSitsEnabled: null,
		},
	},
	reducers: {
		setAccount: (state, action) => {
			return { ...(action.payload ?? {}) };
		},
		setSubAccount: (state, action) => {
			state.subAccount = {
				tier: action.payload.tier,
				list: action.payload.subAccounts,
				hasSubaccounts: action.payload.hasSubaccounts,
				totalSitsEnabled: action.payload.totalSitsEnabled,
			};
		},
		setCurrentAccount: (state, action) => {
			state.currentAccount = action.payload;
		},
	},
	extraReducers: {
		[fetchSubAccount.fulfilled]: (state, action) => {
			state.subAccount = {
				tier: action.payload.tier,
				list: action.payload.subAccounts,
				hasSubaccounts: action.payload.hasSubaccounts,
				totalSitsEnabled: action.payload.totalSitsEnabled,
			};
		},
	},
});

export const { setAccount, setSubAccount, setCurrentAccount } = accountSlice.actions;

export default accountSlice.reducer;
