import { formatDate } from "app/main/utils/dateUtils";

const carrierTruckRemindersList = {
	urlGET: "api/profile/carriers/:carrierId/trucks/:truckId/reminders",
	idKey: "_id",
	listenEvent: "profileRevision",
	triggerEvent: "profileRevision",
	segmentKey: "CARRIER_TRUCK_REMINDERS",
	content: {
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		filters: {
			search: false,
		},
		table: {
			variant: "skinny",
		},
		items: [
			{
				key: "repeats",
				type: "text",
				label: "Repeats",
				grid: { headerTitle: true, valuePostfix: " Reminder" },
			},
			{
				key: "type",
				type: "text",
				label: "Type",
			},
			{
				builder: (item) => formatDate(item.start_date),
				key: "start_date",
				type: "text",
				label: "Start Date",
			},
			{
				key: "notes",
				type: "text",
				label: "Notes",
				grid: { longRow: true },
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "Edit",
						label: "Edit",
						dialogBuilder: (item, dataIds) => {
							return {
								formId: "TRUCK_REMINDER_FORM",
								mode: "EDIT",
								dataIds: { ...dataIds, reminderId: item._id },
							};
						},
					},
					{
						key: "delete",
						label: "Delete",
						dialogBuilder: (item, dataIds) => {
							return {
								formId: "TRUCK_REMINDER_FORM",
								mode: "DELETE",
								dataIds: { ...dataIds, reminderId: item._id },
							};
						},
					},
				],
			},
		],
	},
};

export { carrierTruckRemindersList };
