import { makeStyles } from "@material-ui/core/styles";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Utils
import createForm from "app/main/finance/enterprise-payroll/enterprisePayrollDetailsConf";

const useStyles = makeStyles(() => {
	return {
		maxH70vh: {
			maxHeight: "70vh",
		},
	};
});

const EnterprisePayrollDetailsView = (props) => {
	const classes = useStyles();

	const dataIds = props.params;
	const isHistoryItem = props.isHistoryItem;
	const historyData = dataIds.historyData ?? null;
	const mode = "VIEW";

	return (
		<div className={"flex " + classes.maxHheightView70vh}>
			<SmarthopFormView
				mode={mode}
				data={historyData}
				content={createForm({ isHistoryItem })}
				noInitValidation={true}
				dataIds={dataIds}
			/>
		</div>
	);
};

export default EnterprisePayrollDetailsView;
