import MarketAnalysisWidget from "app/main/widgets/custom/MarketAnalysisWidget";
import { useEffect } from "react";

function LoadSearchMarketAnalysisView(props) {
	useEffect(() => {
		props?.setTitle?.("Market Analysis");
		props?.setSize?.("max-w-4xl");
		// eslint-disable-next-line
	}, []);

	return (
		<MarketAnalysisWidget
			config={props.dataIds?.config}
			searchId={props.dataIds?.searchId}
			userId={props.dataIds?.userId}
			mode="FULL"
			isAllView={true}
		/>
	);
}

export default LoadSearchMarketAnalysisView;
