import favoriteLoadsList from "@smarthop/list/configs/favorireLoads";
import pinnedSearchesList from "@smarthop/list/configs/pinnedSearchesList";
import transactionsPinnedStateAllList from "@smarthop/list/configs/transactionsPinnedStateAllList";

const registy = {
  PINNED_LOADS: favoriteLoadsList,
  PINNED_SEARCHES: pinnedSearchesList,
  PINNED_TRANSACTION: transactionsPinnedStateAllList
};

export default registy;