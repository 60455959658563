import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";

// Utils/Tools
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

// Components
import LoadDescription from "./LoadDescription";
import MessageHeader from "./MessageHeader";

const useStyles = makeStyles((theme) => ({
	width: {
		[theme.breakpoints.down("md")]: {
			minWidth: "65vw",
		},
		minWidth: "460px",
	},
	widthNotification: {
		[theme.breakpoints.down("md")]: {
			minWidth: "90vw",
		},
		minWidth: "460px",
	},
}));

function MessageViewLoadShared(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { message, variant } = props;
	const incoming = props.incoming;
	const userId = props.userId;
	const nativeMobile = props.nativeMobile;

	// Check for Notification if a driver can see the rate
	const user = useSelector(({ auth }) => auth.user);
	const hideRate = roleRestrictions.permission_rates_on.includes(user.role)
		? !hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;

	const handleClick = () => {
		dispatch(
			openFormDialog({
				viewId: "LOAD_SUGGEST_VIEW",
				dataIds: {
					nativeMobile,
					userId,
					searchId: message?.content?.search,
					loadId: message?.content?.load,
					carrierId: message?.content?.carrier,
					truckId: message?.content?.truck,
					action: ["BOOK", "BID"],
					acceptDisabled: false,
					bidDisabled: false,
					rejectDisabled: true,
					isFromChat: true,
				},
			})
		);
	};

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={true}
			variant={variant}
			headerConfig={{
				type: "BROKER",
				data: {
					mcnumber: message?.content?.mcnumber,
					clientid: message?.content?.clientid,
					owner: message?.content?.owner,
				},
				showDivider: true,
				title: "Load Share",
				subtitle: incoming ? "Load Sharing" : "You shared this Load",
			}}
			contentFields={[
				{
					type: "TRIP",
					data: message?.content,
					options: {
						hideRate,
						hideTruck: true,
					},
				},
			]}
			messageActions={[
				{
					key: "VIEW_LOAD",
					title: "View Load",
					hideOnDesktop: false,
					hideOnMobile: false,
					onClick: () => {
						handleClick();
					},
				},
			]}
			contentComponents={
				<div className={`flex flex-col w-full px-4 text-12 md:text-13 ${classes.width}`}>
					<MessageHeader
						message={message}
						incoming={incoming}
						variant={variant}
						showBroker={true}
						showLoadStatus={true}
						description={incoming ? "Load Sharing" : "You shared this Load"}
					/>

					<Divider className={"mt-10 mb-6 " + (incoming ? "" : "bg-grey-400")} />
					<LoadDescription load={message?.content} variant={variant ?? (incoming ? "light" : "dark")} />

					<div className={"flex items-center w-full space-x-16 pt-12"}>
						<div className="flex flex-col w-full">
							<Button variant="contained" color="secondary" onClick={handleClick}>
								View Load
							</Button>
						</div>
					</div>
				</div>
			}
		/>
	);
}
export default MessageViewLoadShared;
