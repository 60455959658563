// Utils
import { buildSourceStatus } from "app/main/utils/billingUtils";

const typeBuilder = (pmType) => {
	let type = "Payment Method";
	switch (pmType) {
		case "card":
			type = "Credit Card";
			break;

		case "bank_account":
			type = "Bank Account";
			break;

		case "ach_credit_transfer":
			type = "ACH Credit Transfer";
			break;

		case "us_bank_account":
			type = "Bank Account";
			break;

		default:
			break;
	}
	return type;
};

const payments = (isAdmin) => {
	return {
		urlGET: "billing/carriers/:carrierId/stripe-sources",
		idKey: "_id",
		listenEvent: "paymentRevision",
		segmentKey: "CARRIER_PAYMENT_METHOD",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				theme: "dark",
				noHeader: false,
				noBottomBorder: true,
				staticHeight: isAdmin ? 400 : undefined,
			},
			filters: {
				search: isAdmin ? true : false,
			},
			...(isAdmin
				? {
						groups: {
							items: [
								{
									key: "internal",
									type: "checkbox",
									label: "Show Internal Fields",
								},
							],
						},
				  }
				: {}),
			isHighlighted: (item) => !!item.isDefaultSource,
			items: [
				{
					key: "source_id",
					label: "Source ID",
					type: "id",
					grid: { headerSubtitle: isAdmin, valuePefix: "Source ID " },
					hidden: !isAdmin,
					group: "internal",
				},
				{
					type: "component",
					key: "status",
					label: "Status",
					builder: (item) => buildSourceStatus(item),
				},
				{
					builder: (item) => item?.payment_method__view?.label,
					type: "text",
					label: "Details",
					collapsibleLength: 50,
				},
				{
					builder: (item) => typeBuilder(item.type),
					type: "text",
					label: "Type",
					grid: { headerTitle: true },
					table: { width: "20%", align: "right" },
				},
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "check_balance",
							label: "Check Balance",
							translate: "",
							isVisible: (item) => isAdmin && item.type === "bank_account",
							dialogBuilder: (item) => ({
								formId: "CHECK_BALANCE_FORM",
								mode: "CREATE",
								dataIds: { sourceId: item.source_id },
							}),
						},
						{
							key: "set_default",
							label: "Use as Default Payment Method",
							translate: "",
							isVisible: (item) => !item.isDefaultSource,
							dialogBuilder: (item, dataIds) => ({
								formId: "MARK_PAYMENT_METHOD_AS_DEFAULT_FORM",
								mode: "EDIT",
								dataIds: { sourceId: item.source_id, carrierId: dataIds.carrierId },
							}),
						},
						{
							key: "delete",
							label: "Delete",
							translate: "",
							dialogBuilder: (item, dataIds) => ({
								formId: "DELETE_PAYMENT_METHOD_FORM",
								mode: "DELETE",
								dataIds: { sourceId: item.source_id, carrierId: dataIds.carrierId },
							}),
						},
					],
				},
			],
		},
	};
};

export default payments;
