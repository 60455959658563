import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { Typography, Link } from "@material-ui/core";
import { closeTutorialDialog } from "app/store/tools/tutorialDialogSlice";

export const PayrollTutorialDescription = () => {
	const [redirect, setRedirect] = useState(false);
	const dispatch = useDispatch();
	const handleLinkClick = () => {
		setRedirect(true);
	};

	useEffect(() => {
		if (redirect) dispatch(closeTutorialDialog());
	}, [dispatch, redirect]);

	return (
		<>
			<Typography className="text-14">
				{"You can always change default configuration of payroll module in "}
				<Link onClick={handleLinkClick} color="primary">
					{"Settings"}
				</Link>
			</Typography>
			{redirect && <Redirect to={"/profile/company#payroll-settings"} />}
		</>
	);
};

const materialLinks = [
	{
		title: "Driver Settings",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to manage driver pay settings",
	},
	{
		title: "Recurring Payroll Rules",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to configure recurring rules for your drivers",
	},
	{
		title: "Payee Ledger",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How and when entries are created",
	},
	{
		title: "Payroll Reports",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to access approved payrolls",
	},
	{
		title: "Trip Management & Payroll",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How trips enter payroll and interact with trip details",
	},
	{
		title: "Payroll Errors",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to fix payroll errors",
	},
	{
		title: "In Progress Payroll",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to manage In Progress Payroll",
	},
	{
		title: "In Review Payroll",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to manage In Review Payroll",
	},
	{
		title: "Approved Payroll",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to manage Approved Payroll",
	},
	{
		title: "Dispatcher Payroll",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to enroll and manage a Dispatcher in Payroll",
	},
	{
		title: "Investor Payroll",
		link: "https://sites.google.com/smarthop.co/customer-resources/videos#h.djw86outizeg",
		description: "How to enroll and manage a Dispatcher in Payroll",
	},
];

export const PayrollTutorialSidebar = () => {
	return (
		<div className="flex flex-col w-full p-32 pt-6">
			<Typography className=" text-justify text-primary text-14 py-12">
				<strong>Resources</strong>
			</Typography>
			<div className="flex flex-wrap md:flex-nowrap">
				<Typography className=" text-left text-primary text-12 mt-16">
					{materialLinks.map((item, index) => (
						<p key={index}>
							<Link
								href={item.link}
								onClick={() => {
									window.open(item.link, "_blank");
								}}
							>
								<strong>{item.title}</strong>
							</Link>{" "}
							- {item.description}
						</p>
					))}
				</Typography>
			</div>
		</div>
	);
};
