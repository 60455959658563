import { useEffect, useImperativeHandle, forwardRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Typography } from "@material-ui/core";
import PageTabWrapper from "app/main/common/PageTabWrapper";
import AdminBrokerInvoicingPage from "app/main/profile/admin/AdminBrokerInvoicingPage";
import BrokerEditView from "./BrokerEditView";
import { openFormDialog, refreshDialog } from "app/store/tools/formDialogSlice";

import { insertURLParameter, readURLParameters, rewriteURLParameters } from "app/main/utils/urlUtils";

import { getCarrierId } from "app/services/LoginService";
import { getBrokerInformation } from "app/services/brokersServices.js";
import { parseRolesInfo } from "app/main/utils/rolesUtils";

const BrokerInfoView = forwardRef((props, ref) => {
	const dispatch = useDispatch();

	useEffect(() => {
		props?.setTitle?.("Broker/Customer Information");
		// eslint-disable-next-line
	}, []);
	const dataIds = props.dataIds;

	const user = useSelector(({ auth }) => auth.user);
	const dataRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);

	const [cache, setCache] = useState(props?.dataIds?.cache);
	const brokerId = props.brokerId ?? props?.dataIds?.brokerId;
	const customerId = props.customerId ?? props?.dataIds?.customerId;

	const [loading, setLoading] = useState(true);
	const [loadingError, setLoadingError] = useState(null);
	const [data, setData] = useState(null);
	const isInternalUser = parseRolesInfo(["administrator"], user).visible;
	const carrierId = props.carrierId ?? props?.dataIds?.carrierId ?? (!isInternalUser ? getCarrierId() : undefined);

	useEffect(() => {
		setLoading(true);
		if (cache) {
			setData(cache);
			setLoading(false);
			setCache(null);
			return;
		}
		(async () => {
			try {
				const brokerData = await getBrokerInformation(brokerId, carrierId);
				setData(brokerData);
			} catch (err) {
				setLoadingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			}
			setLoading(false);
		})();
		// eslint-disable-next-line
	}, [brokerId, customerId, carrierId, dataRevision]);

	useImperativeHandle(
		ref,
		() => {
			const editIntent = {
				viewId: "BROKER_EDIT_VIEW",
				dataIds: {
					brokerId: dataIds?.brokerId,
					carrierId: dataIds?.carrierId,
					customerId: dataIds?.customerId,
					mode: "EDIT",
				},
			};
			dispatch(refreshDialog());
			return {
				edit: { onClick: () => dispatch(openFormDialog(editIntent)), title: "Edit" },
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dataIds]
	);

	const handleTabChange = () => {
		insertURLParameter("tab", 0);
	};

	useEffect(() => {
		const params = readURLParameters();
		rewriteURLParameters({ tab: 0 });

		return () => {
			rewriteURLParameters(params);
		};
	}, []);

	if (loadingError || loading) {
		return (
			<div className={"flex w-full flex-col items-center justify-center h-512 "}>
				{loadingError ? (
					<Typography color="error" className="text-red text-13 font-light mx-20 py-6 md:pb-48">
						{loadingError}
					</Typography>
				) : loading ? (
					<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
						Loading...
					</Typography>
				) : null}
			</div>
		);
	}

	return (
		<PageTabWrapper
			tabs={[
				{
					label: "Details",
					component: (
						<BrokerEditView
							isView
							carrierId={carrierId}
							customerId={customerId}
							brokerId={brokerId}
							dataIds={{ mode: "VIEW" }}
							// To avoid loading user data multiple times passing
							// preloaded data as cache param
							cache={data}
						/>
					),
				},
				...(isInternalUser && !data?.carrier
					? [{ label: "SmartPay invoices", component: <AdminBrokerInvoicingPage params={dataIds} isView hideTitle /> }]
					: []),
			]}
			onTabChange={handleTabChange}
			isView
		/>
	);
});

export default BrokerInfoView;
