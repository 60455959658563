import { useMemo, useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { replaceFormDialog } from "app/store/tools/formDialogSlice";

import { createBrokerEditFrom } from "./configs/brokerEditConfig.js";

import { tripHelpers } from "app/main/profile/trips/tools/helpers";

import { getCarrierId } from "app/services/LoginService";
import {
	getBrokerInformation,
	saveBrokerInformation,
	createBroker,
	deleteCustomer,
} from "app/services/brokersServices.js";

function BrokerEditView(props) {
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const isAdmin = user?.rolesLabels?.includes("administrator");
	const onDone = props.onDone;
	const carrierId = props.carrierId ?? props?.dataIds?.carrierId;
	const mode = props?.mode ?? props?.dataIds?.mode ?? "CREATE";

	const externalUser = !isAdmin;
	const currentCarrier = externalUser ? props?.dataIds?.carrierId ?? getCarrierId() : undefined;
	const cache = props?.cache;
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const [processingError, setProcessingError] = useState(null);
	const [formData, setFormData] = useState(null);
	const [formSettings, setFormSettings] = useState(null);
	const brokerId = props.brokerId ?? props?.dataIds?.brokerId;
	const customerId = props.customerId ?? props?.dataIds?.customerId;

	useEffect(() => {
		props?.setTitle?.(
			props?.dataIds?.historyData
				? "View Broker"
				: mode === "EDIT"
				? "Edit Broker"
				: mode === "CREATE" && currentCarrier
				? "Create Customer"
				: mode === "DELETE"
				? "Delete Customer"
				: "Create Broker"
		);
		props?.setSize?.(mode === "DELETE" ? "max-w-xl" : "max-w-xl");
		// eslint-disable-next-line
	}, [mode]);

	useEffect(() => {
		if (props?.dataIds?.historyData) {
			setLoading(false);
			updateFormSettings(props?.dataIds?.historyData);
			return;
		}

		if (mode === "CREATE") {
			setLoading(false);
			updateFormSettings({ carrier: carrierId });
			return;
		}
		if (cache) {
			updateFormSettings(cache);
			setLoading(false);
			return;
		}

		(async () => {
			try {
				const brokerData = await getBrokerInformation(brokerId, carrierId);
				updateFormSettings(brokerData);
				setLoading(false);
			} catch (err) {
				setLoadingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			}
		})();
		// eslint-disable-next-line
	}, [carrierId, brokerId, customerId]);

	const updateFormSettings = (data) => {
		let updatedData = { ...data };
		if (updatedData?.extraContacts)
			updatedData.extraContactsFormated = tripHelpers.mapExtraContact(updatedData?.extraContacts);
		setFormData(updatedData);
		setFormSettings({
			broker: updatedData._id,
			isExternalBroker: !!updatedData?.carrier,
			isExternalUser: !isAdmin,
			isSmartpayEnabled: !!updatedData?.smartpayEnabled,
			isBrokerOverride: !!updatedData?.mainbroker,
			hasSubaccounts: hasSubaccounts,
		});
	};

	const handleFormChange = (model, key) => {
		// UI Workaround to avoid animation rendering issues
		setTimeout(() => {
			let data = { ...model };
			if (mode === "EDIT" && !formData.carrier && currentCarrier) {
				data.overwriteBroker = true;
				data.carrier = currentCarrier;
				updateFormSettings(data);
			}
		}, 0);
	};

	const handleFormSubmit = async (model) => {
		try {
			setProcessingError(null);
			setProcessing(true);

			if (mode === "DELETE") {
				await deleteCustomer(brokerId);
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				onDone?.();
			} else if (mode === "EDIT" && !formData?.overwriteBroker) {
				const carrier = formData?.carrier;
				await saveBrokerInformation(brokerId, carrier, model);
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				onDone?.();
			} else if (mode === "CREATE" || formData?.overwriteBroker) {
				if (formData?.carrier) model.carrier = formData?.carrier;
				//Create a customer
				const broker = await createBroker(model);
				if (formData?.overwriteBroker) {
					onDone?.();
					dispatch(
						replaceFormDialog({
							viewId: "BROKER_INFO_VIEW",
							dataIds: {
								carrierId: carrierId,
								brokerId: broker._id,
								cache: broker,
							},
						})
					);
				} else {
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					onDone?.();
				}
			}
			showSnackbar(snackbar, "Done!", "success");
		} catch (err) {
			setProcessingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
			setProcessing(false);
		}
	};

	const formContent = useMemo(
		() => (!formSettings ? null : createBrokerEditFrom(formSettings, { mode })),
		[formSettings, mode]
	);

	const getSmartpayStatus = (active, externalUser = false) => {
		return (
			<Typography className={"text-13 font-bold " + (active ? " text-green " : " text-red ")}>
				{!externalUser ? (active ? "Enabled" : "Disabled") : active ? "Eligible" : "Not Eligible"}
			</Typography>
		);
	};

	const _buildContactSection = (isExternalBroker, extraContacts) => {
		return (
			<>
				{isExternalBroker && (
					<DisplaySection title="Contact" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
						<SmarthopFormView
							content={formContent?.contactInformation}
							data={formData}
							mode={"VIEW"}
							overrides={{
								group: { component: { variant: "vertical", classes: null } },
								ALL_EDITABLE: { component: { preview: { variant: "row" } } },
							}}
							dataIds={{ carrierId }}
						/>
					</DisplaySection>
				)}
				{isExternalBroker && extraContacts?.length > 0 && (
					<DisplaySection classes={{ root: "mx-0" }} title="Extra Contacts">
						{extraContacts.map((data, index) => (
							<div key={index} className={"w-full flex flex-row pb-4 pt-0 " + (index === 0 ? " -mt-8 " : "")}>
								{extraContacts.length > 1 && (
									<Typography className="text-12 md:text-13 font-semibold pr-3 mr-3 pt-10">
										{index + 1 + ")"}
									</Typography>
								)}
								<div className={"w-full flex flex-col "}>
									{data?.map((elem, index2) => (
										<div key={"contact" + index2}>
											{renderDataItem(elem.label, elem.value, null, { vertical: elem.vertical })}
										</div>
									))}
								</div>
							</div>
						))}
					</DisplaySection>
				)}
			</>
		);
	};

	if (loadingError || loading) {
		return (
			<div
				className={
					"flex w-full flex-col items-center justify-center " +
					(mode === "DELETE" ? " h-224 " : mode === "EDIT" || mode === "ACTIVATE" ? " h-320 " : " h-320 ")
				}
			>
				{loadingError ? (
					<Typography color="error" className="text-red text-13 font-light mx-20 py-6 md:pb-48">
						{loadingError}
					</Typography>
				) : loading ? (
					<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
						Loading...
					</Typography>
				) : null}
			</div>
		);
	}

	return (
		<div className={" relative " + (processing ? " opacity-60 pointer-events-none " : "")}>
			{mode === "DELETE" ? (
				<SmarthopFormView
					key="delete_user"
					content={formContent}
					noInitValidation={true}
					errors={processingError ? [{ type: "generic", message: processingError }] : null}
					dataIds={{ carrierId }}
					onDelete={handleFormSubmit}
					onCancel={() => onDone?.()}
				/>
			) : mode === "VIEW" ? (
				<div className="flex flex-col md:flex-row w-full md:space-x-40 px-20 mt-10">
					<div className="flex flex-col w-full">
						<SmarthopFormView
							content={formContent?.banner}
							data={formData}
							mode={"VIEW"}
							overrides={{
								group: { component: { variant: "vertical", classes: null } },
								ALL_EDITABLE: { component: { preview: { variant: "row" } } },
							}}
							dataIds={{ carrierId }}
						/>
						<DisplaySection title="Company Information" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
							<SmarthopFormView
								content={formContent?.summary}
								data={formData}
								mode={"VIEW"}
								overrides={{
									group: { component: { variant: "vertical", classes: null } },
									ALL_EDITABLE: { component: { preview: { variant: "row" } } },
								}}
								dataIds={{ carrierId }}
							/>
						</DisplaySection>
						{isAdmin && _buildContactSection(formSettings.isExternalBroker, formData?.extraContactsFormated)}
						<DisplaySection title="Credit" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
							<SmarthopFormView
								content={formContent?.credit}
								data={formData}
								mode={"VIEW"}
								overrides={{
									group: { component: { variant: "vertical", classes: null } },
									ALL_EDITABLE: { component: { preview: { variant: "row" } } },
								}}
								dataIds={{ carrierId }}
							/>
						</DisplaySection>
						{formSettings.isExternalBroker && formSettings.isBrokerOverride && (
							<DisplaySection
								title="SmartPay"
								views={[getSmartpayStatus(formSettings.isSmartpayEnabled, externalUser)]}
								classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
							>
								<SmarthopFormView
									content={formContent?.smartpay}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
						{!formSettings.isExternalBroker && (
							<DisplaySection
								title="SmartPay"
								views={[getSmartpayStatus(formSettings.isSmartpayEnabled, externalUser)]}
								classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
							>
								<SmarthopFormView
									content={formContent?.smartpay}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
					</div>
					<div className="flex flex-col w-full">
						{isAdmin && formSettings.isBrokerOverride && (
							<DisplaySection title="Main Broker Information" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
								<SmarthopFormView
									content={formContent?.mainBroker}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
						{!isAdmin && _buildContactSection(formSettings.isExternalBroker, formData?.extraContactsFormated)}
						{!formSettings.isExternalBroker && (
							<DisplaySection title="Contact" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
								<SmarthopFormView
									content={formContent?.contactInformation}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
					</div>
				</div>
			) : (
				<SmarthopFormView
					key="edit_broker"
					content={formContent}
					noInitValidation={true}
					data={formData}
					errors={processingError ? [{ type: "generic", message: processingError }] : null}
					mode={mode}
					dataIds={{ carrierId }}
					trackChangedFields={["ALL"]}
					onChangeCommitted={handleFormChange}
					onSubmit={handleFormSubmit}
				/>
			)}
		</div>
	);
}

export default BrokerEditView;
