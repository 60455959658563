import { createSlice } from "@reduxjs/toolkit";

const revisionSlice = createSlice({
	name: "revision",
	initialState: {
		/**
		 * Version of some data like profile, to update componetns which have single source
		 * of data and do not require subsriber mechanism.
		 * 
		 * Example:
		 *   __data_revision__: 0,
		 */
	},
	reducers: {
		incrementDataRevision: (state, action) => {
			state[action.payload.event] = (state[action.payload.event] ?? 0) + 1;
		},
	},
});

export const { incrementDataRevision } = revisionSlice.actions;

export default revisionSlice.reducer;
