export const SIGN_UP_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: SIGN_UP_TRACK";
export const SIGN_UP_PAGE_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: SIGN_UP_PAGE";
export const FIND_LOAD_PAGE_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") +
	"CHAM: FIND-Load - Page";
export const COMPLETE_OBOARDING_DOCUMENTS_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") +
	"CHAM: COMPLETE_ONBOARDING_DOCUMENT";
export const COMPLETE_ONBOARDING_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") +
	"CHAM: FINISH_ONBOARDING";
export const PLAN_SELECTED =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: PLAN_SELECTED";
export const PLAN_CHANGED =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: PLAN_CHANGED";
export const VERIFY_CONTACT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: VERIFY_CONTACT";
export const CALENDAR_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: CALENDAR_EVENT";
export const SMARTPAY_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: SMARTPAY_EVENT";
export const SMARTFUEL_EVENT =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: SMARTFUEL_EVENT";
export const LOAD_CALL_TO_ACTION =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") +
	"CHAM: LOAD_CALL_TO_ACTION";
export const BOOK_REMINDER =
	(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: BOOK_REMINDER";

export const VISIT_PAGE_EVENT = (view) => {
	return (
		(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: PAGE " + view
	);
};
export const FORM_DIALOG_EVENT = (id, mode) => {
	return (
		(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") +
		"CHAM: FORM " +
		id +
		"__" +
		modeConstants(mode)
	);
};
export const FORM_LIST_EVENT = (id) => {
	return (
		(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "TEST" ? "dev-" : "") + "CHAM: LIST " + id
	);
};

const modeConstants = (mode) => {
	if (mode === "CREATE") {
		return "CREATED";
	} else if (mode === "EDIT") {
		return "EDITED";
	} else if (mode === "DELETE") {
		return "DELETED";
	} else {
		return "VIEWED";
	}
};
