import FuseUtils from "@fuse/utils";
import NotificationTemplate from "../tools/NotificationTemplate";
import MessageNotificationTemplate from "../chat/MessageNotificationTemplate";
import NotificationMessage from "../tools/NotificationMessage";
import Grow from "@material-ui/core/Grow";

const showSnackbar = (snackbar, message, variant, options) => {
	let { enqueueSnackbar, closeSnackbar } = snackbar ?? {};
	if (!enqueueSnackbar || !closeSnackbar || !message) {
		return;
	}
	// There could be only one "newSnackBar" since it's used for showing snack bar "dialog-like"
	let viewId = options?.id ?? (options?.newSnackBar ? "dialog-like" : FuseUtils.generateGUID());

	enqueueSnackbar(message, {
		key: viewId,
		preventDuplicate: options?.newSnackBar ? true : !!options?.preventDuplicate,
		autoHideDuration: options?.duration ?? 3000,
		persist: !!options?.persist,
		...(options?.newSnackBar && {
			TransitionComponent: Grow,
			anchorOrigin: { vertical: "top", horizontal: "center" },
		}),
		content: () =>
			!options?.newSnackBar ? (
				<NotificationTemplate
					item={{ id: viewId, message: message, options: { variant: variant } }}
					onClose={() => closeSnackbar(viewId)}
				/>
			) : (
				<NotificationMessage
					item={{
						message: message,
						option: variant,
						acceptButtonLabel: options.acceptButtonLabel,
						handleAccept: options.handleAccept,
					}}
					onClose={() => closeSnackbar(viewId)}
					useGreenForSuccess={!!options?.newSnackBarUseGreenForSuccess}
				/>
			),
	});

	return viewId;
};

const quickSnackbar = (snackbar, message, variant, options) => {
	return showSnackbar(snackbar, message, variant, { ...(options ?? {}), duration: 1500 });
};

const showErrorSnackbar = (snackbar, error, options) => {
	let message =
		error?.errors?.length > 0
			? error?.errors[0].message
			: error?.message ?? "Oops... something went wrong, please try again...";
	return showSnackbar(snackbar, message, "error", options);
};

const showSuccessSnackbar = (snackbar, message, options) => {
	return showSnackbar(snackbar, message ?? "Done", "success", options);
};

const hideSnackbar = (snackbar, id) => {
	let { closeSnackbar } = snackbar ?? {};
	if (!closeSnackbar || !id) {
		return;
	}

	closeSnackbar(id);
};

const showMessageSnackbar = (snackbar, message, options) => {
	let { enqueueSnackbar, closeSnackbar } = snackbar ?? {};
	if (!enqueueSnackbar || !closeSnackbar || !message) {
		return;
	}
	enqueueSnackbar(message, {
		key: message._id,
		autoHideDuration: options?.duration ?? 3000,
		...((message?.type === "BID_BOOK_TRANSACTION" ||
			message?.type === "REQUEST_GPS_TRACKING" ||
			!!message?.content?.notification?.persistent) && { persist: true }),
		content: () => <MessageNotificationTemplate message={message} onClose={(id) => closeSnackbar(id)} />,
	});
	return message._id;
};

export { showSnackbar, showErrorSnackbar, showSuccessSnackbar, quickSnackbar, hideSnackbar, showMessageSnackbar };
