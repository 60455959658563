const resetPasswordForm = {
	items: [
		{
			key: "code",
			type: "text",
			label: "Validation Code",
			required: true,
			maxCharacter: 10,
		},
		{
			key: "password",
			type: "password",
			label: "Password",
			translate: "PASSWORD",
			required: true,
		},
		{
			key: "passwordConfirm",
			type: "passwordConfirm",
			label: "Confirm Password",
			translate: "CONFIRM_PASSWORD",
			required: true,
		},
		{
			type: "action",
			action: "SUBMIT",
			label: "Update",
			button: {
				classes: { root: "mb-8" },
			},
		},
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
			},
		},
	],
};

export default resetPasswordForm;
