import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState, forwardRef } from "react";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { parseRolesInfo } from "../utils/rolesUtils";
import {
	getCurrentTruckPlan,
	getTrucksInformation,
	createPostTruck,
	postTruckPrefill,
	getTruckPlan,
} from "app/services/truckServices";
import { truckPlanForm, selectCarrierAndTruck } from "../truck-plans/config/truckPlanForm";
import { postTruckForm } from "./config/postTruckFormConfig";
import { loadingViewComponent } from "../common/LoadingView";
import { showErrorSnackbar, showSnackbar } from "app/main/utils/snackbarUtil";
import { useSnackbar } from "notistack";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const PostTruckView = forwardRef((props, ref) => {
	useEffect(() => {
		props?.setTitle?.("Create post truck");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const [data, setData] = useState(null);
	const [dataTruckPlan, setDataTruckPlan] = useState(null);

	const user = useSelector(({ auth }) => auth.user);
	const [loading, setLoading] = useState(true);
	const dataIds = props.dataIds;
	const onDone = props.onDone;
	const roleStatus = parseRolesInfo(
		["administrator", "dispatcher", "ops support", "carrier", "carrier_manager", "carrier_dispatcher"],
		user
	);
	const isCarrier = roleStatus.visible === "carrier";

	const [dataCarrier, setDataCarrier] = useState({
		carrier: dataIds?.carrierId,
		truck: null,
	});

	const carrierTruckForm = useMemo(
		() =>
			selectCarrierAndTruck({
				isCarrier,
				isAdmin: roleStatus?.visible === "administrator" || roleStatus?.visible === "ops support",
				isDispatcher: roleStatus?.visible === "dispatcher",
			}),
		// eslint-disable-next-line
		[roleStatus, isCarrier]
	);

	const truckPlanFormConfig = useMemo(
		() =>
			truckPlanForm({
				data: dataTruckPlan,
				isCarrier,
				isAdmin: roleStatus?.visible === "administrator" || roleStatus?.visible === "ops support",
				isDispatcher: roleStatus?.visible === "dispatcher",
				isPostTruck: true,
			}),
		// eslint-disable-next-line
		[roleStatus, isCarrier]
	);

	const postTruckFormConfig = useMemo(() => postTruckForm(dataIds.equipment), [dataIds.equipment]);

	const postTruckCreated = () => {
		if (!data) return;
		setLoading(true);
		createPostTruck(dataCarrier?.carrier, dataCarrier?.truck, data).then(
			(response) => {
				setLoading(false);
				response.forEach((value) => {
					showSnackbar(snackbar, value.message, value.status === "SUCCESS" ? "success" : "error");
				});
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				onDone?.();
			},
			(error) => {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				showErrorSnackbar(snackbar, error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		if (!dataIds?.truckId) {
			setLoading(false);
			return;
		}
		getTrucksInformation({
			options_active_only: true,
			options_carrier_ids_search: dataCarrier?.carrier,
			options_truck_ids_search: dataIds?.truckId,
		}).then((truckInformation) => {
			truckInformation = truckInformation?.items?.find((truckId) => truckId._id === dataIds?.truckId);
			const { carrier, carrier__view, truck__view } = truckInformation;
			setDataCarrier({ carrier, carrier__view, truck: dataIds?.truckId, truck__view });
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!dataCarrier?.truck) return;
		async function getInformation() {
			try {
				let [currentTruckPlan, postTruckComments] = await Promise.all([
					dataIds.planId
						? getTruckPlan(dataCarrier?.carrier, dataCarrier?.truck, dataIds.planId)
						: getCurrentTruckPlan(dataCarrier?.carrier, dataCarrier?.truck),
					postTruckPrefill(dataCarrier?.carrier, dataCarrier?.truck),
				]);
				setDataTruckPlan(currentTruckPlan);
				setData(postTruckComments);
				setLoading(false);
			} catch (error) {}
		}
		getInformation();
		// eslint-disable-next-line
	}, [dataCarrier]);

	if (loading) {
		return loadingViewComponent();
	}

	return (
		<div className={""}>
			<SmarthopFormView
				key={`${dataCarrier?.carrier}-${dataCarrier?.truck}`}
				content={carrierTruckForm}
				data={dataCarrier}
				mode={"VIEW"}
				noInitValidation={true}
			/>
			{dataCarrier?.truck && (
				<SmarthopFormView
					key={`truck-plan-${JSON.stringify(dataCarrier)}`}
					mode={"VIEW"}
					content={truckPlanFormConfig}
					data={{ ...dataTruckPlan.plan }}
					noInitValidation={true}
					dataIds={dataIds}
				/>
			)}

			<SmarthopFormView
				key={`post-truck-${JSON.stringify(dataCarrier)}`}
				mode={"CREATE"}
				content={postTruckFormConfig}
				data={data}
				noInitValidation={true}
				dataIds={dataIds}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					setData(model);
				}}
				onSubmit={() => {
					postTruckCreated();
				}}
			/>
		</div>
	);
});

export default PostTruckView;
