import { Icon, Typography } from "@material-ui/core";

function CostStructureWarnings({ warnings, className = "" }) {
	if (warnings?.length <= 0) return null;

	return (
		<div className={`flex flex-row justify-center content-center space-x-8 ${className}`}>
			<div className="flex flex-initial justify-center content-center items-center">
				<Icon className="text-orange-700 text-22">warning</Icon>
			</div>
			<div className="flex flex-col">
				{warnings.map((msg) => (
					<Typography key={msg} component="div">
						{msg}
					</Typography>
				))}
			</div>
		</div>
	);
}

export default CostStructureWarnings;
