import Typography from "@material-ui/core/Typography";
import { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { getAllParticipantsChat } from "app/services/chatServices";
import { capitalizeFirstLetter } from "app/main/utils/chatUtils";
// Constans
import { type_user } from "constants.js";

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "relative",
		"padding-right": "90px",
		"padding-left": "90px",
		top: "50%",
	},
}));
function ParticipantList(props) {
	const chatId = props?.chatId;
	const [loading, setLoading] = useState(true);
	const [participants, setParticipants] = useState(null);
	const [error, setError] = useState(null);
	const classes = useStyles();

	useEffect(() => {
		if (chatId) {
			getAllParticipantsChat(chatId).then(
				(data) => {
					setParticipants(data);
					setLoading(false);
				},
				(err) => {
					setError("Something went wrong loading the participants");
					setLoading(false);
					console.log(err, "Err");
				}
			);
		}
		//eslint-disable-next-line
	}, [chatId]);

	const viewParticipants = () => {
		return participants?.map((item, index) => {
			let userFound;
			if (item?.role || item?.role === 0) {
				userFound = type_user.find((user) => user.value === item?.role);
			}
			return (
				<div key={"key" + index}>
					<div className="flex-row">
						<Typography color="primary" className="text-13">
							{capitalizeFirstLetter(item?.name)}
						</Typography>
						<Typography className="text-grey-600 text-13 mb-10">{userFound?.label}</Typography>
					</div>
				</div>
			);
		});
	};

	if (loading) {
		return (
			<div className={clsx(classes.progress, "flex w-full")}>
				<CircularProgress size={30} />
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex flex-1 flex-col h-full items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	if (!participants?.length) {
		return (
			<div className="px-4 flex w-full">
				<Typography color="primary" className="font-medium ml-8 text-14">
					{"There are not participants in this chat"}
				</Typography>
			</div>
		);
	}
	return (
		<div className="overflow-y" style={{ height: participants?.length > 10 ? "400px" : "auto" }}>
			{viewParticipants()}
		</div>
	);
}
export default ParticipantList;
