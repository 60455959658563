import { useState } from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import ChatAppMenuItem from "app/main/chat/ChatAppMenuItem";
import CurrentTransactionsPanelMenuItem from "app/main/profile/transactionsState/pinned/CurrentTransactionsPanelMenuItem";
import CalculatorLoadMenuItem from "app/main/calculator/CalculatorLoadMenuItem";
import HelpRequestMenuItem from "app/main/support/HelpRequestMenuItem";

const actions = [ChatAppMenuItem, CalculatorLoadMenuItem, CurrentTransactionsPanelMenuItem, HelpRequestMenuItem];

function PhoneMenu(props) {
	const [phoneMenu, setPhoneMenu] = useState(null);

	const phoneMenuClick = (event) => {
		setPhoneMenu(event.currentTarget);
	};

	const phoneMenuClose = () => {
		setPhoneMenu(null);
	};

	return (
		<div className="flex md:hidden">
			<IconButton className="mr-0 w-40 h-40" onClick={phoneMenuClick}>
				<Icon>more_vert</Icon>
			</IconButton>
			<Popover
				open={Boolean(phoneMenu)}
				anchorEl={phoneMenu}
				onClose={phoneMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				classes={{
					paper: "py-8",
				}}
			>
				{actions.map((Action, index) => (
					<Action key={index} />
				))}
			</Popover>
		</div>
	);
}

export default PhoneMenu;
