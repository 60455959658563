const profit_pallete = {
	blue: "#003764",
	light_blue: "#97D4F3",
	white: "#eff1f2",
	orange: "#fd6d35",
};

const SCALE = 30;
const PROFIT_LEVELS = 1200;

const polygonColors = (percentile, level, profit, expected, equipment = "VAN", showProfit = false) => {
	let { blue, light_blue, white, orange } = profit_pallete;
	//Use Different profit level depending on equipment
	let multiplier = equipment === "REEFER" ? 1.25 : 1;
	profit = level > 1 ? expected : profit;
	profit =
		profit > PROFIT_LEVELS * multiplier
			? PROFIT_LEVELS * multiplier
			: profit < -PROFIT_LEVELS * multiplier
			? -PROFIT_LEVELS * multiplier
			: profit;
	let colors = showProfit
		? profit < 0
			? gradient(blue, light_blue, SCALE)
			: gradient(white, orange, SCALE)
		: gradient(blue, light_blue, SCALE).concat(gradient(white, orange, SCALE));
	let scale = !showProfit
		? Math.ceil(percentile * colors.length) - 1
		: profit < 0
		? SCALE - Math.ceil((-1 * profit) / ((PROFIT_LEVELS * multiplier) / SCALE))
		: Math.ceil(profit / ((PROFIT_LEVELS * multiplier) / SCALE));
	let step = scale - 1;
	return colors[step];
};

const polygonColorsRelative = (percentile) => {
	//Use Different scale depeinding on profit's level
	let { blue, light_blue, white, orange } = profit_pallete;
	let colors = gradient(blue, light_blue, SCALE).concat(gradient(white, orange, SCALE));
	let step = Math.ceil(percentile * colors.length) - 1;
	return colors[step];
};

const gradient = (colorStartHex, colorEndHex, steps = 10) => {
	// Convert to number if hex string
	let colorStart = hexToRgb(colorStartHex);
	let colorEnd = hexToRgb(colorEndHex);

	// Linit to the spectrum
	var diff = {
		r: colorEnd.r - colorStart.r,
		g: colorEnd.g - colorStart.g,
		b: colorEnd.b - colorStart.b,
	};
	var scale = steps - 1;
	var output = [];
	for (var i = 0; i < steps; i++) {
		output.push(
			rgbToHex([
				colorStart.r + (i / scale) * diff.r,
				colorStart.g + (i / scale) * diff.g,
				colorStart.b + (i / scale) * diff.b,
			])
		);
	}
	return output;
};

const gradientHSL = (colorStartHex, colorEndHex, steps = 10, rgb = true) => {
	// Convert to number if hex string
	let colorStartRGB = hexToRgb(colorStartHex);
	let colorEndRGB = hexToRgb(colorEndHex);
	// Convert to HSL mode
	let colorStart = rgbToHsl(colorStartRGB);
	let colorEnd = rgbToHsl(colorEndRGB);

	// Linit to the spectrum
	var diff = {
		h: colorEnd.h - colorStart.h,
		s: colorEnd.s - colorStart.s,
		l: colorEnd.l - colorStart.l,
	};
	var scale = steps - 1;
	var output = [];
	for (var i = 0; i < steps; i++) {
		let rgb = hslToRgb(
			colorStart.h + (i / scale) * diff.h,
			colorStart.s + (i / scale) * diff.s,
			colorStart.l + (i / scale) * diff.l
		);
		let hex = rgbToHex([rgb.r, rgb.g, rgb.b]);
		output.push(hex);
	}
	return output;
};

const hexToRgb = (hex) => {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (result) {
		return { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) };
	} else {
		throw Error("Not Valid Color");
	}
};

const rgbToHex = (rgb_array) => {
	return (
		"#" +
		rgb_array
			.map((c) => Math.floor(c).toString(16))
			.map((c) => (c.length === 1 ? "0" + c : c))
			.join("")
	);
};

const rgbToHsl = (r, g, b) => {
	r /= 255;
	g /= 255;
	b /= 255;
	var max = Math.max(r, g, b);
	var min = Math.min(r, g, b);
	var d = max - min;
	var h;
	if (d === 0) h = 0;
	else if (max === r) h = ((g - b) / d) % 6;
	else if (max === g) h = (b - r) / d + 2;
	else if (max === b) h = (r - g) / d + 4;
	var l = (min + max) / 2;
	var s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1));
	return { h: h * 60, s: s, l: l };
};

const hslToRgb = (h, s, l) => {
	var c = (1 - Math.abs(2 * l - 1)) * s;
	var hp = h / 60.0;
	var x = c * (1 - Math.abs((hp % 2) - 1));
	var rgb1;
	if (isNaN(h)) rgb1 = [0, 0, 0];
	else if (hp <= 1) rgb1 = [c, x, 0];
	else if (hp <= 2) rgb1 = [x, c, 0];
	else if (hp <= 3) rgb1 = [0, c, x];
	else if (hp <= 4) rgb1 = [0, x, c];
	else if (hp <= 5) rgb1 = [x, 0, c];
	else if (hp <= 6) rgb1 = [c, 0, x];
	var m = l - c * 0.5;
	return [Math.round(255 * (rgb1[0] + m)), Math.round(255 * (rgb1[1] + m)), Math.round(255 * (rgb1[2] + m))];
};

var stringToColour = (str) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	var colour = "#";
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xff;
		colour += ("00" + value.toString(16)).substr(-2);
	}
	return colour;
};

export { gradient, gradientHSL, polygonColors, polygonColorsRelative, stringToColour };
