import transactionsPinnedStateAllList from "@smarthop/list/configs/transactionsPinnedStateAllList";
import { payeePayrollLedgerList, carrierTrucksListBuilder, enterprisePayrollRulesList } from ".";
import carrierFuelTransactionsList from "./configs/carrierFuelTransactionsList";
import driverSmartfuelTransactionsList from "./configs/driverSmartfuelTransactionsList";

const registy = {
	PINNED_TRANSACTION: transactionsPinnedStateAllList,
	LEDGER_USER_PAYROLL: payeePayrollLedgerList({ isAdmin: false, isActionsEnable: false, isDriver: false }),
	RECURRENT_USER_PAYROLL: enterprisePayrollRulesList({}),
	TRUCKS_INVESTOR_PAYROLL: ({ user }) =>
		carrierTrucksListBuilder({ userId: user._id, allowActions: false, readOnly: true }),
	DRIVER_FUEL_TRANSACTION_LIST: driverSmartfuelTransactionsList,
	CARRIER_FUEL_TRANSACTIONS: carrierFuelTransactionsList({ showCarrier: false }),
};

/**
 * Enables passing params to a form builder function
 * For example:
 * PINNED_TRANSACTIONS: ({ user }) => driverForm(params),
 * returns a function instead of the list object to build the
 * list on the go with fresh context parameters
 * @param {*} tableId
 * @param {*} params | user
 * @returns The list shape object
 */
export const buildList = (tableId, { user }) => {
	const listBuilder = registy[tableId];
	if (typeof listBuilder === "function") {
		return listBuilder({ user });
	}
	return listBuilder;
};

export default registy;
