import { centsToDollars } from "app/main/utils/financeUtils";

const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const STATUS_NAMES = {
	OPEN: "Open",
	PENDING: "Pending",
	COMPLETED: "Processed",
};

const smartpayPayoutsConf = ({ isAdmin, showCarrier } = {}) => {
	return {
		urlGET: !isAdmin ? `api/trip-invoicing/carriers/:carrierId/payouts` : `api/trip-invoicing/payouts`,
		idKey: "_id",
		listenEvent: ["tripsRevision"],
		segmentKey: "TRIP_INVOICING",
		content: {
			table: {
				variant: "skinny",
				orderBy: ["closed_date", "status"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
				defaut: {
					size: 20,
				},
			},
			order: {
				defaut: {
					key: "closed_date",
					dir: "desc",
				},
			},
			export: {
				fileName: "Payouts",
			},
			filters: {
				search: true,
				countVisible: 2,
				items: [
					{
						key: "status",
						type: "select",
						label: "Status",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "OPEN",
								label: "Open",
							},
							{
								value: "PENDING",
								label: "Pending",
							},
							{
								value: "COMPLETED",
								label: "Processed",
							},
						],
					},
					{
						key: "invoice",
						type: "autocomplete",
						label: "Invoice",
						field: {
							noErrorMessage: true,
							classes: {},
						},
						autocomplete: {
							provider: "smarthop",
							url: isAdmin ? "api/trip-invoicing/invoices" : "api/trip-invoicing/carriers/:carrierId/invoices",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: {
								options_linked_to_payouts: true,
							},
						},
					},
					...(isAdmin || showCarrier
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									translate: "",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										preloadDefault: true,
										params: {
											sortOrder: "asc",
											sortBy: "carrierid",
											filters: { customer_id: "__NOT_EMPTY__" },
										},
									},
								},
						  ]
						: []),
					...(isAdmin
						? [
								{
									key: "carriers_type",
									type: "select",
									label: "Internal Carrier Type",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "REAL_CARRIERS",
											label: "Real Carriers",
										},
										{
											value: "TEST_CARRIERS",
											label: "Test Carriers",
										},
									],
								},
						  ]
						: []),
				],
			},
			groups: {
				items: [],
			},
			defaults: {
				initial: {
					filters: {
						status: "__NOT_SELECTED__",
						...(PROD_DB && isAdmin ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
					},
				},
				items: [
					{
						label: "All",
						filters: {
							status: "__NOT_SELECTED__",
						},
					},
					{
						label: isAdmin ? "Actionable (Open)" : "Open",
						filters: {
							status: "OPEN",
						},
					},
					{
						label: "Pending",
						filters: {
							status: "PENDING",
						},
					},
					{
						label: "Completed",
						filters: {
							status: "COMPLETED",
						},
					},
				],
			},
			click: {
				label: "View",
				grid: { variant: "menu" },
				dialogBuilder: (item) => ({
					viewId: "SMARTPAY_PAYOUT_VIEW",
					dataIds: { carrierId: item.carrier, payoutId: item._id },
				}),
			},
			items: [
				{
					key: "status",
					label: "Status",
					type: "text",
					builder: (item) => STATUS_NAMES[item?.status] ?? item?.status,
				},
				...(!isAdmin && !showCarrier
					? []
					: [
							{
								key: "carrier__view",
								label: "Carrier",
								type: "carrier__view",
								viewConfig: {
									showMcNumber: true,
									showTestCarrier: true,
									showCarrierAccountType: true,
								},
							},
					  ]),
				{
					key: "ref", // TODO use payout cell
					label: "Payout",
					type: "payout__view",
					builder: (item) => item?.payout__view,
				},
				{
					key: "invoices__view",
					label: "Invoices",
					// View supports arrays
					type: "invoicing__view",
					builder: (item) => item?.invoices__view,
					viewConfig: {
						showBroker: true,
					},
				},
				{
					key: "closed_date",
					label: "Processed On",
					type: "date",
				},
				{
					key: "payment_type",
					label: "Type",
					builder: (item) =>
						item?.payment_type === "ACH_PAYMENT"
							? "ACH"
							: item?.payment_type === "WIRE_PAYMENT"
							? "Same Day"
							: item?.payment_type,
				},
				{
					key: "transfer_fee_cents",
					label: "Transfer Fee",
					type: "currency",
					builder: (item) => centsToDollars(item?.transfer_fee_cents),
				},
				{
					key: "net_amount_cents",
					label: "Net Amount",
					type: "currency",
					builder: (item) => {
						// Some invoices may not have a net_amount_cents in sync. We fallback to calculation
						const netAmountCents =
							item?.net_amount_cents || item?.smartpay?.ledgerTotals?.immediateCents - item?.transfer_fee_cents;
						return centsToDollars(netAmountCents);
					},
					table: { align: "right", width: "10%" },
				},
			],
		},
	};
};

export default smartpayPayoutsConf;
