const successStyle = {
	color: "green-400",
	hoverColor: "green-500",
	icon: "check_circle",
};

const inprogressStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "hourglass_top",
};

const errorStyle = {
	color: "red-700",
	hoverColor: "red-800",
	icon: "highlight_off",
};

const progressStyle = {
	color: "#333",
	position: "absolute",
	top: -6,
	left: -6,
	zIndex: 1,
};

const progressBoxStyle = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const declineStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "check_circle",
};

const formatHelper = {
	successStyle,
	inprogressStyle,
	errorStyle,
	progressStyle,
	progressBoxStyle,
	declineStyle,
};

export default formatHelper;
