import { useState, useRef, useMemo, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { getCarrierId, getProfileId } from "app/services/LoginService";
import moment from "moment";
import { useDispatch } from "react-redux";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { SmarthopConfirmDialog } from "@smarthop/form";
import { formatDate } from "../utils/dateUtils";
import { isEnabled } from "app/services/featureStorageService";
import { createTrackOrPage } from "../segment/segmentEvent";
import * as segmentType from "../segment/segmentType";

function TripCreationReminder() {
	const [open, setOpen] = useState(false);
	const [lastSearch, setLastSearch] = useState(null);
	const profileId = useMemo(() => getProfileId(), []);
	const carrierId = useMemo(() => getCarrierId(), []);
	const dispatch = useDispatch();
	const ranTripCreationReminder = useRef(false);

	useEffect(() => {
		if (!profileId || window?.ReactNativeWebView) return;
		// run check one time
		if (ranTripCreationReminder.current) return;
		ranTripCreationReminder.current = true;

		let lastSearchRun = localStorage.getItem("lastSearchRun");
		if (!lastSearchRun) return;
		setLastSearch(lastSearchRun);

		// if is not older than 2 hours
		if (
			moment(lastSearchRun).isAfter(
				moment.utc().subtract(4, isEnabled("QA_TRIP_REMINDER_INSTANT") ? "seconds" : "hours")
			)
		) {
			return;
		}

		// if trip was created after the search
		const lastTrip = localStorage.getItem("lastTrip");
		if (lastTrip && moment(lastTrip).isAfter(lastSearchRun)) {
			return;
		}

		// only trigger one time and clear lastSearchRun
		localStorage.removeItem("lastSearchRun");
		setOpen(true);
	}, [profileId, carrierId, dispatch]);

	return (
		<SmarthopConfirmDialog
			open={open}
			title=""
			acceptMsg="Yes"
			closeMsg="No"
			onAccept={() => {
				createTrackOrPage(segmentType.BOOK_REMINDER, { type: "YES" }, "track");
				dispatch(
					openFormDialog({
						viewId: "MULTI_FILE_UPLOAD_VIEW",
						dataIds: {
							carrierId,
							customMsg: (
								<div className="flex flex-col m-8">
									<Typography variant="h5">Great News!</Typography>
									<Typography variant="caption">Upload your RateCon below for effortless trip management!</Typography>
								</div>
							),
							onUploadFile: () => {
								createTrackOrPage(segmentType.BOOK_REMINDER, { type: "YES_AND_UPLOADED_FILE" }, "track");
							},
						},
					})
				);
				setOpen(false);
			}}
			onClose={() => {
				createTrackOrPage(segmentType.BOOK_REMINDER, { type: "NO" }, "track");
				setOpen(false);
			}}
			message={`We noticed you were searching for loads on ${formatDate(lastSearch?.createdAt)}, did you book a load?`}
		/>
	);
}

export default TripCreationReminder;
