import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageWrapper from "app/main/common/PageWrapper";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import LoadSearchResultContent from "./LoadSearchResultContent";
import SearchVersionSwitch from "../../searchV3/components/SearchVersionSwitch";

import { closeMenuPanel } from "app/store/tools/menuSlice";
import ResourceView from "app/main/tools/ResourceView";

import {
	readURLParameters,
	convertURLParamsToModel,
	rewriteURLParameters,
	convertModelToURLParams,
	SEARCH_LOAD_URL_PARAMS_MAPPING,
} from "../../utils/urlUtils";

const SAVED_TABS_KEY = "SAVED_TABS";

const useStyles = makeStyles({
	"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
	flicker: {
		animationName: "$flicker",
		animationDuration: "1000ms",
		animationIterationCount: "infinite",
		animationDirection: "alternate",
		animationTimingFunction: "ease-in-out",
	},
	withAnimation: ({ disabled }) => ({
		animationPlayState: disabled ? "paused" : "running",
	}),
});

const findTabIndex = (tabs, model) => {
	if (!model) return -1;
	const newSortedDestinationStates = model?.destinationStates ? [...model.destinationStates].sort().toString() : "";
	const newPickup_ds__after = model?.pickup_ds__after ? moment(model.pickup_ds__after).format("YYYY-MM-DD") : "";
	let i = tabs.findIndex((item) => {
		const tabSortedDestinationStates = item.model?.destinationStates
			? [...item.model.destinationStates].sort().toString()
			: "";
		const tabPickup_ds__after = item.model?.pickup_ds__after
			? moment(item.model.pickup_ds__after).format("YYYY-MM-DD")
			: "";

		return (
			model?.truck === item.model?.truck &&
			model?.location_origin === item.model?.location_origin &&
			newPickup_ds__after === tabPickup_ds__after &&
			(model?.location_destination ?? "") === (item.model?.location_destination ?? "") &&
			newSortedDestinationStates === tabSortedDestinationStates
		);
	});
	return i;
};

const saveTabs = async (tabs) => {
	const sanitized = tabs.map((item) => ({
		id: item.id,
		empty: item.empty,
		searchId: item.searchId,
		model: item.model,
		values: item.values,
	}));
	localStorage.setItem(SAVED_TABS_KEY, JSON.stringify(sanitized));
};

function LoadSearchResultTab(props) {
	const dispatch = useDispatch();

	const { flicker, withAnimation } = useStyles({ disabled: false });
	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);

	const restrictions = ["ONBOARDING", "TIER_LIMITED"];

	// Checking if default tab params specified in URL, if so that tab would be selected by default

	const { defaultTabs, defaultId, defaultIndex, defaultViewedIds, defaultEmpty } = useMemo(() => {
		let urlModel;
		try {
			urlModel = convertURLParamsToModel(readURLParameters(), SEARCH_LOAD_URL_PARAMS_MAPPING);
		} catch (e) {
			console.error("[LoadSearchResultTab] failed to parse url params", e?.message);
		}

		let defaultTabs = [];
		try {
			const saveTabsRaw = localStorage.getItem(SAVED_TABS_KEY);
			defaultTabs = saveTabsRaw ? JSON.parse(saveTabsRaw) : [];
		} catch (e) {
			console.error("[LoadSearchResultTab] failed to parse tabs object", e?.message);
		}

		// Finding default max tab id
		let defaultId = 0;
		defaultTabs.forEach((item) => {
			if (item.id >= defaultId) defaultId = item.id + 1;
		});

		// Adding empty tab if not found one
		if (!defaultTabs.find((item) => item.empty)) {
			defaultTabs.push({ id: defaultId, empty: true });
			defaultId++;
		}

		// Checking if url params correspond to any of the saved tabs, is so selecting that tab
		let defaultIndex = findTabIndex(defaultTabs, urlModel);
		let defaultEmpty = false;
		if (urlModel && defaultIndex >= 0) {
			defaultTabs[defaultIndex].model = urlModel;
			// If reloading with URL of existng tab, we should allow to reload the data, to do so we need to clear searchId
			delete defaultTabs[defaultIndex].searchId;
		} else if (urlModel) {
			defaultTabs = [{ id: defaultId, empty: false, model: urlModel }, ...defaultTabs];
			defaultIndex = 0;
			defaultId++;
		} else {
			defaultIndex = defaultTabs.length - 1;
			defaultEmpty = true;
		}

		let defaultViewedIds = { [defaultTabs[defaultIndex].id]: true };
		return { defaultTabs, defaultId, defaultIndex, defaultViewedIds, defaultEmpty };
		// eslint-disable-next-line
	}, []);

	const [revision, setRevision] = useState(0);
	const [viewedIds, setViewedIds] = useState(defaultViewedIds);
	const [selectedTab, setSelectedTab] = useState(defaultIndex);
	const [idIndex, setIdIndex] = useState(defaultId);
	const [tabParams, setTabParams] = useState(defaultTabs);
	const [screenWidth, setScreenWidth] = useState(parseInt(window.innerWidth / 50) * 50);
	const [mobile, setMobile] = useState(window.innerWidth < 960);
	const memory = useMemo(() => navigator.deviceMemory, []);

	// Monitoring screen width to adjust tab width

	let tabWidth = tabParams.length > 0 ? screenWidth / tabParams.length : 0;
	tabWidth = tabWidth > 360 ? 360 : tabWidth < 210 ? 210 : tabWidth;

	useEffect(() => {
		function handleResize() {
			let innerWidth = window.innerWidth;
			innerWidth = parseInt(innerWidth / 50) * 50;
			if (innerWidth !== screenWidth) setScreenWidth(innerWidth);

			let isNewWidthMobile = window.innerWidth < 960;
			if (isNewWidthMobile !== mobile) setMobile(isNewWidthMobile);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	});

	// Rewriting url when tab is changed

	useEffect(() => {
		if (!tabParams?.length) return;
		const tab = tabParams[selectedTab];
		rewriteURLParameters(convertModelToURLParams(tab?.model ?? {}, SEARCH_LOAD_URL_PARAMS_MAPPING));
		// eslint-disable-next-line
	}, [selectedTab, revision]);

	// Updating search tabs on submitting search, adding new empty tab

	const handleSearchSubmitted = useCallback(
		(i, searchId, model) => {
			setTabParams((tabParams) => {
				if (i === selectedTab) {
					// Removing search id from model, if searchId is not removed
					// it would lead to not restarting search request
					let newModel = { ...(model ?? {}) };
					delete newModel.searchId;
					const currentEmpty = tabParams[i].empty;
					const newTabParams = [...tabParams];

					// Checing if there is tab with the same params, closing it if found
					const duplicateTabIndex = findTabIndex(newTabParams, model);
					if (duplicateTabIndex >= 0 && duplicateTabIndex !== selectedTab) {
						newTabParams.splice(duplicateTabIndex, 1);
						if (duplicateTabIndex < selectedTab) {
							setSelectedTab(selectedTab - 1);
							i = selectedTab - 1;
						}
					}

					// Replace empty search tab with new search request params
					newTabParams[i].searchId = searchId;
					newTabParams[i].empty = false;
					newTabParams[i].model = newModel;
					if (currentEmpty) newTabParams.push({ id: idIndex, empty: true });

					saveTabs(newTabParams);
					return newTabParams;
				}
				return tabParams;
			});
			setIdIndex(idIndex + 1);
			setRevision(revision + 1);
			dispatch(closeMenuPanel());
		},
		// eslint-disable-next-line
		[tabParams, selectedTab, idIndex]
	);

	// Updating tabs search indexes when status changed

	const handleSearchStatusChanged = useCallback(
		(i, state, counters) => {
			setTabParams((tabParams) => {
				const currentTab = tabParams[i];
				if (currentTab.counters?.new !== counters?.new || currentTab.state !== state) {
					const newTabParams = [...tabParams];
					newTabParams[i].state = state;
					if (!newTabParams[i].counters) newTabParams[i].counters = {};
					newTabParams[i].counters.new = counters?.new ?? 0;
					return newTabParams;
				}
				return tabParams;
			});
		},
		// eslint-disable-next-line
		[tabParams]
	);

	const handlePanelValueChanged = useCallback(
		(i, key, value) => {
			setTabParams((tabParams) => {
				const newTabParams = [...tabParams];
				if (!newTabParams[i].values) newTabParams[i].values = {};
				newTabParams[i].values[key] = value;
				saveTabs(newTabParams);
				return tabParams;
			});
		},
		// eslint-disable-next-line
		[tabParams]
	);

	const reachedTabLimit = useMemo(() => {
		return (memory < 8 && tabParams?.length > 3) || (memory >= 8 && tabParams?.length > 6);
	}, [tabParams, memory]);

	if (mobile) {
		return (
			<PageWrapper
				restrictions={restrictions}
				resource={{ onboarding: "SEARCH_ONBOARDING" }}
				isView={true}
				noPadding={true}
				noFuseWrapper={true}
			>
				<LoadSearchResultContent {...props} contentEmpty={defaultEmpty} />;
			</PageWrapper>
		);
	}

	// Multitab support logic

	const onSelectTab = (e, index, id) => {
		e.stopPropagation();
		const newViewedIds = { ...viewedIds };
		newViewedIds[id] = true;
		setViewedIds(newViewedIds);
		setSelectedTab(index);
		dispatch(closeMenuPanel());
	};

	const onCloseTab = (e, index, tabs = null) => {
		e.stopPropagation();

		let newTabs = [...tabParams];
		newTabs.splice(index, 1);
		setTabParams(newTabs);
		saveTabs(newTabs);

		let newSelectedTab = selectedTab;
		if (newSelectedTab > 0 && index < newSelectedTab) {
			newSelectedTab = newSelectedTab - 1;
			setSelectedTab(newSelectedTab);
		} else if (newSelectedTab >= newTabs.length) {
			newSelectedTab = newTabs.length - 1;
			setSelectedTab(newSelectedTab);
		} else {
			setRevision(revision + 1);
		}

		const visibleTabId = newTabs?.[newSelectedTab]?.id;
		if (visibleTabId && !viewedIds[visibleTabId]) {
			const newViewedIds = { ...viewedIds };
			newViewedIds[visibleTabId] = true;
			setViewedIds(newViewedIds);
		}

		dispatch(closeMenuPanel());
	};

	const tabs = tabParams.map((tab, i) => {
		const model = tab.model;

		const destination =
			model?.destinationStates__view?.length > 0
				? " - " + model?.destinationStates__view?.map((state) => state.description).join(", ")
				: model?.location_destination
				? " - " + model?.location_destination?.replaceAll?.(", USA", "")
				: "";

		const titleLabel = tab.empty
			? "New Search"
			: moment(model?.pickup_ds__after).format("MMM DD") + " | " + (model?.truck__view?.label ?? model?.truck);
		const subtitleLabel = tab.empty ? null : model?.location_origin?.replaceAll?.(", USA", "") + destination;

		return (
			<div
				key={"tab_index_" + tab.id}
				onClick={(e) => onSelectTab(e, i, tab.id)}
				className={clsx(
					"flex h-48 px-10 cursor-pointer",
					"items-center justify-center rounded-tl-lg rounded-tr-lg",
					selectedTab === i ? "bg-white" : "opacity-80 bg-grey-50 hover:bg-grey-100"
				)}
				style={{ minWidth: tabWidth }}
			>
				{!tab.empty && (
					<div className={clsx("flex flex-col min-w-20")}>
						<div
							className={clsx(
								(!isAuthenticated || tab.state !== "ACTIVE") && !!tab.state
									? `text-red`
									: !tab.state
									? `${flicker} ${withAnimation} text-orange`
									: `${flicker} ${withAnimation} text-green`
							)}
						>
							<Icon className={"text-18"}>
								{!isAuthenticated && !!tab.state
									? "cloud_off"
									: !tab.state
									? "cached"
									: tab.state !== "ACTIVE"
									? "stop_circle"
									: "cached"}
							</Icon>
						</div>
						{tab.counters?.new > 0 && tab.state === "ACTIVE" && (
							<Typography
								className={clsx(
									"text-11 text-center px-6 pt-1 pb-1 rounded-full bg-cyan-700 text-white font-bold -mt-2 mb-2"
								)}
							>
								{tab.counters?.new}
							</Typography>
						)}
					</div>
				)}
				<div className={clsx("flex flex-1 flex-col items-center justify-center")}>
					<Typography
						color="primary"
						className={clsx("px-14 text-12 font-normal max-w-210 truncate")}
						style={{ maxWidth: tabWidth - 70 }}
					>
						{titleLabel}
					</Typography>
					<Typography
						color="primary"
						className={clsx("px-14 text-12 font-normal -mt-4 max-w-210 truncate")}
						style={{ maxWidth: tabWidth - 70 }}
					>
						{subtitleLabel}
					</Typography>
				</div>
				{!tab.empty && (
					<IconButton
						key="close"
						aria-label="Close"
						className={clsx("-ml-12 -mr-10")}
						color="inherit"
						onClick={(e) => onCloseTab(e, i)}
					>
						<Icon className={clsx("text-20 text-grey-600")}>close</Icon>
					</IconButton>
				)}
				{tab.empty && <ResourceView type={"tutorial"} tutorialId={"SEARCH_TUTORIAL"} classes={{ icon: "mt-2" }} />}
			</div>
		);
	});

	const views = tabParams.map((tab, i) => {
		let initModel;
		let initValues;
		if (tabParams[i].model) {
			initValues = tabParams[i].values ?? {};
			initModel = { ...tabParams[i].model };
			if (tabParams[i]?.searchId) {
				initModel.searchId = tabParams[i].searchId;
			}
		}

		return (
			<LoadSearchResultContent
				{...props}
				key={"content_index_" + tab.id}
				tabsVisible={true}
				initModel={initModel}
				initValues={initValues}
				contentEmpty={tab.empty}
				contentHidden={i !== selectedTab}
				contentListDisabled={!viewedIds[tab.id]}
				onSearchSubmitted={(searchId, model) => handleSearchSubmitted(i, searchId, model)}
				onSearchStatusChanged={(state, counters) => handleSearchStatusChanged(i, state, counters)}
				onPanelValuesChanged={(key, value) => handlePanelValueChanged(i, key, value)}
				reachedTabLimit={reachedTabLimit && tab.empty}
			/>
		);
	});

	return (
		<PageWrapper
			restrictions={restrictions}
			resource={{ onboarding: "SEARCH_ONBOARDING" }}
			classes={{
				content: "flex w-full",
			}}
			isView={true}
			noPadding={true}
		>
			<div className="flex flex-col flex-auto min-h-0 relative z-10">
				<div className="flex w-full h-54 flex-row bg-grey-200 pt-4 px-4 overflow-scroll scrollbar-hide items-end">
					{tabs}
					<SearchVersionSwitch minWidth={tabWidth / 2} key="searchV3_switch" />
				</div>
				{views}
			</div>
		</PageWrapper>
	);
}

export default LoadSearchResultTab;
