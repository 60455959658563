import React from "react";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import { formatDate, formatTimeAgo } from "app/main/utils/dateUtils";

import { formatCurrency, formatNumber } from "app/main/utils/tableUtils";
import { createBadgeView } from "app/main/search/searchUtils";
import { createMPIView } from "app/main/utils/brokerUtils";

import {
	TimelineItem,
	Timeline,
	TimelineSeparator,
	TimelineDot,
	TimelineConnector,
	TimelineContent,
} from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
		minHeight: "20px",
	},
})(TimelineItem);

function LoadCardView({ id, item, options }) {
	if (options?.stub) {
		return (
			<div key={id} className="flex w-full min-h-180 items-center justify-center">
				<Typography className="text-13 text-grey-600 animate-pulse">Loading...</Typography>
			</div>
		);
	}

	return (
		<div key={id} className="flex flex-col w-full bg-white p-10">
			<div className="flex flex-row w-full mb-20">
				<Typography className="flex flex-1 text-15 leading-snug text-grey-700">{item.clientid}</Typography>
				<Typography className="flex text-13">{formatTimeAgo(item.updated)} ago</Typography>
			</div>
			<div className="flex flex-row">
				<Timeline className="-ml-14 -mt-16 mb-3">
					<StyledTimelineItem>
						<TimelineSeparator>
							<TimelineDot color="secondary" variant="outlined" className="py-5 px-5 bg-secondary">
								<Icon className={"text-14 font-500 text-white "}>{"storefront"}</Icon>
							</TimelineDot>
							<TimelineConnector className="-mt-8 -mb-8 bg-secondary" />
						</TimelineSeparator>
						<TimelineContent>
							<div className="flex flex-row items-center -ml-6">
								<div className="flex flex-col">
									<Typography className="text-14 break-normal font-semibold">
										{item.origin} {item.distance_to_origin > 0 ? "(" + item.distance_to_origin + " mi)" : ""}
									</Typography>
									<Typography className="text-13 break-normal text-grey-700">
										{formatDate(item.ready, item.pickupby, true, true, true)}
									</Typography>
								</div>
							</div>
						</TimelineContent>
					</StyledTimelineItem>
					<StyledTimelineItem>
						<TimelineSeparator>
							<TimelineDot color="secondary" variant="outlined" className="py-5 px-5">
								<Icon className={"text-14 font-500 "}>{"location_on"}</Icon>
							</TimelineDot>
						</TimelineSeparator>
						<TimelineContent>
							<div className="flex flex-row items-center  -ml-6">
								<div className="flex flex-col">
									<Typography className="text-14 break-norma font-semibold">
										{item.destination} {item.distance_to_dest > 0 ? "(" + item.distance_to_dest + " mi)" : ""}
									</Typography>
									<div className="flex space-x-16">
										<Typography className="text-13 break-normal text-grey-700">
											{formatDate(item.dropready, item.deliverby, true, true, true)}
										</Typography>
										{item?.rank_mci && createMPIView(item, true)}
									</div>
								</div>
							</div>
						</TimelineContent>
					</StyledTimelineItem>
				</Timeline>
				<div className="flex flex-col items-end justify-center pb-24">
					<Typography className="flex text-20 font-semibold">
						{item.price > 0 ? formatCurrency(item.price, 0) : null}
					</Typography>
					{item.profit_trip_carrier > 0 || item.profit_trip_carrier < 0 ? (
						<Typography
							className={
								"flex -mt-5 mb-3 text-13 whitespace-nowrap " +
								(item.profit_trip_carrier > 0 ? "text-green" : "text-red")
							}
						>
							{formatCurrency(item.profit_trip_carrier, 0, "en-US", true)}{" "}
							{item.profit_trip_carrier > 0 ? "profit" : "loss"}
						</Typography>
					) : null}
					<Typography className="flex text-13 text-grey-600">
						{item.rpm > 0
							? formatCurrency(item.rpm, 2) + "/mi"
							: item.est_rpm > 0
							? "est. " + formatCurrency(item.rpm, 2) + "/mi"
							: null}
					</Typography>
				</div>
			</div>
			<div className="flex flex-row w-full pb-1">
				<div className="flex flex-row-reverse flex-1 flex-wrap space-y-4 justify-end items-center mr-10">
					{/* Empty div nessesary to solve issue with flex-wrap, first param is never algned propery somehow  */}
					<div></div>
					{createBadgeView(item, "MOBILE_CARD")}
				</div>
				<div className="flex flex-row">
					<Typography className="flex pt-4 text-13">
						{item.miles > 0 ? formatNumber(item.miles, 0) + " mi" : null}
					</Typography>
				</div>
			</div>
		</div>
	);
}

export default LoadCardView;
