import { SmarthopList, adminAnalyticsMetricDataPointsList } from "@smarthop/list";
import { useState, useEffect } from "react";
import { makeStyles, Tab, Tabs, Typography } from "@material-ui/core";

import { getMetric } from "app/services/analyticsServices";

import WidgetDynamic from "app/main/widgets/dynamic/WidgetDynamic";
import WidgetMenuContainer from "app/main/widgets/WidgetMenuContainer";
import WidgetContentConfig from "app/main/widgets/WidgetContentConfig";

const useStyles = makeStyles((theme) => {
	return {
		container: {
			minHeight: "70vh",
		},
		widget: {
			minHeight: "60vh",
		},
	};
});

function AdminAnalyticsMetricView(props) {
	useEffect(() => {
		props?.setTitle?.("Metric Details");
		props?.setSize?.("max-w-6xl");
		// eslint-disable-next-line
	}, []);

	const [tab, setTab] = useState(0);
	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState(null);
	const [error, setError] = useState(null);
	const classes = useStyles();

	const { metricId } = props.dataIds;

	useEffect(() => {
		if (!metricId || !!config) {
			return;
		}

		let stopped = false;
		(async () => {
			try {
				setLoading(true);
				const metric = await getMetric(metricId);
				if (stopped) return;

				setConfig({
					graphType: "BAR",
					aggregationPeriod: metric.periodType,
					aggregationWindowMain:
						metric.periodType === "HOUR"
							? "HOUR_24"
							: metric.periodType === "DAY"
							? "DAY_7"
							: metric.periodType === "MONTH"
							? "MONTH_12"
							: metric.periodType === "YEAR"
							? "YEAR_2"
							: null,
					aggregationWindowSecondary: null,
					metrics: [
						{
							metric: metric._id,
							metric__view: { value: metric._id, label: metric.metricKey },
							aggregationType: "SUM",
							aggregationFields: ["0__METRIC"],
							aggregationFields__view: [{ value: "0__METRIC", label: "  All" }],
							carrier: null,
							truck: null,
							owner: null,
							entity: null,
							group: null,
						},
					],
				});

				setLoading(false);
			} catch (e) {
				setError(e.message);
				setLoading(false);
			}
		})();

		return () => {
			stopped = true;
		};
		// eslint-disable-next-line
	}, [metricId]);

	return (
		<div className={`flex w-full flex-col ${classes.container}`}>
			<div className="flex flex-row w-full justify-between mb-10">
				<Tabs className="flex-shrink-0" value={tab} onChange={(e, v) => setTab(v)}>
					<Tab label="Widget" value={0} />
					<Tab label="Data" value={1} />
				</Tabs>
			</div>
			<div className={`flex flex-1 w-full h-full flex-row items-center justify-center`}>
				{tab === 0 &&
					(error ? (
						<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48 text-red">
							{error}
						</Typography>
					) : loading ? null : (
						// WORAROUND: add height 1px so widget scales properly
						<div className={`flex w-full rounded-lg h-full ${classes.widget} mt-10`} style={{ height: "1px" }}>
							<WidgetMenuContainer
								type={"PERMANENT"}
								menu={
									<WidgetContentConfig
										type={"DYNAMIC_GRAPH"}
										config={config}
										onConfigChanged={(config) => setConfig(config)}
									/>
								}
							>
								{config && (
									<WidgetDynamic
										config={config}
										configPermanent={true}
										onConfigChanged={(config) => setConfig(config)}
									/>
								)}
							</WidgetMenuContainer>
						</div>
					))}
				{tab === 1 && (
					<div className={`flex w-full flex-col`}>
						<SmarthopList
							mode="TABLE"
							inDialog={true}
							config={adminAnalyticsMetricDataPointsList}
							filters={{ metric: metricId }}
							dataIds={{ metricId }}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default AdminAnalyticsMetricView;
