import { Typography } from "@material-ui/core";
import { DisplaySection } from "app/main/utils/uiUtils";

const TripLogisticsCostsComponent = (props) => {
	const styleClasses = props.styleClasses;
	const title = props.title;
	const logisticsCosts = props.logisticsCosts;

	return (
		<DisplaySection classes={styleClasses} title={title}>
			{logisticsCosts?.length > 0 ? (
				logisticsCosts.map((data, index) => (
					<div key={index} className={"w-full flex flex-row pb-4 pt-6"}>
						{logisticsCosts.length > 1 && (
							<Typography className="text-12 md:text-13 font-semibold pr-3 mr-3 min-w-16">{index + 1 + "."}</Typography>
						)}
						<div className={"w-full flex flex-row-reverse justify-between border-b-1 border-grey-300"}>
							{data.map((elem, index2) => (
								<div
									key={"addition_" + index2}
									className={` ${
										!index2 ? (elem.label === "Addition" ? "text-green-600" : "text-red-600") : "text-black"
									}`}
								>
									<Typography className={`text-12 break-normal font-medium`}>{`${
										!index2 && elem.value ? (elem.label === "Addition" ? "+" : "-") : ""
									}${elem.value ?? "-"}`}</Typography>
								</div>
							))}
						</div>
					</div>
				))
			) : (
				<Typography className="text-12 md:text-13 pt-1">{"No information"}</Typography>
			)}
		</DisplaySection>
	);
};

export default TripLogisticsCostsComponent;
