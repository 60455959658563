import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getDashboard = (boardId) => {
	return createRequest()
		.get(`api/analytics/dashboards/${boardId}?configAsObject=true`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getMetric = (metricId) => {
	return createRequest()
		.get(`api/analytics/data/metrics/${metricId}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getDatapointsSeries = (config, forceReload) => {
	return createRequest()
		.get(`api/analytics/data/datapoints/series`, { params: { ...config, forceReload }, headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierDatapointsSeries = (carrierId, config, forceReload) => {
	return createRequest()
		.get(`api/analytics/carriers/${carrierId}/data/datapoints/series`, {
			params: { ...config, forceReload },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierUserDatapointsSeries = (carrierId, userId, config, forceReload) => {
	return createRequest()
		.get(`api/analytics/carriers/${carrierId}/users/${userId}/data/datapoints/series`, {
			params: { ...config, forceReload },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getUserDatapointsSeries = (userId, config, forceReload) => {
	return createRequest()
		.get(`api/analytics/users/${userId}/data/datapoints/series`, {
			params: { ...config, forceReload },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getAccountDatapointsSeries = (accountId, config, forceReload) => {
	return createRequest()
		.get(`api/analytics/accounts/${accountId}/data/datapoints/series`, {
			params: { ...config, forceReload },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTruckDatapointsSeries = (carrierId, truckId, config, forceReload) => {
	return createRequest()
		.get(`api/analytics/carriers/${carrierId}/trucks/${truckId}/data/datapoints/series`, {
			params: { ...config, forceReload },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getAccountProfileDatapointsSeries = (accountId, profileId, config, forceReload) => {
	return createRequest()
		.get(`api/analytics/accounts/${accountId}/profiles/${profileId}/data/datapoints/series`, {
			params: { ...config, forceReload },
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
