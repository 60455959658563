// Dependencies
import { useEffect, forwardRef } from "react";
import { Icon, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import CellSecondRow from "@smarthop/list/views/CellSecondRow.js";

// Tools and Utils
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatDate } from "app/main/utils/dateUtils";
import SavingsIcon from "@mui/icons-material/Savings";

const FuelTransactionSummaryView = forwardRef(({ setTitle, fuelTransactionDetails, isCarrier, isDriver }, _) => {
	const dispatch = useDispatch();

	const data = fuelTransactionDetails.data;
	const generalData = {
		merchant_city: data.merchant_city,
		merchant_state: data.merchant_state,
		driver_name: data.driver_name,
		driver_number: data.driver_number,
		product_name: data.product_name,
		pump: data.pump,
		transaction_date: data.transaction_date,
		transaction_number: data.transaction_number,
		transaction_time: data.transaction_time,
		truck: data.truck,
		truck_stop_name: data.truck_stop_name,
		payroll_eligible: fuelTransactionDetails.payroll_eligible,
	};

	const pricingData = {
		actual_cost: data.actual_cost,
		actual_cost_discounted: data.actual_cost_discounted,
		discount_total: data.discount_total,
		gallons: data.gallons,
		grand_total: data.grand_total,
		ppg: data.ppg,
		ppg_discounted: data.ppg_discounted,
		ppu: data.ppu,
		ppu_discounted: data.ppu_discounted,
		quantity: data.quantity,
		total_amount: data.total_amount,
	};

	const ledgerEntryData = fuelTransactionDetails.journal_entry_data
		? {
				price: fuelTransactionDetails.journal_entry_data?.amount_cents,
		  }
		: null;

	useEffect(() => {
		setTitle?.("Transaction Details");
		// eslint-disable-next-line
	}, []);

	const driverName = fuelTransactionDetails.driver__view?.label;
	const investorName = fuelTransactionDetails.investor_user__view?.label;
	const truckLabel = fuelTransactionDetails.truck__view?.label;
	const transactionId = generalData?.transaction_number;

	const renderUserDetails = (labelField, userLabel, dataIds) => {
		return (
			<div className={"flex flex-row border-b-1 border-grey-300"}>
				<Typography className={`flex w-full text-black text-12 ml:text-13 break-normal mt-10`}>{labelField}</Typography>
				<Typography
					className={
						"cursor-pointer text-12 ml:text-13  break-normal  " +
						` mt-10 font-semibold text-blue-700 ${
							userLabel?.length > 45 ? " whitespace-break-spaces " : " whitespace-nowrap "
						}  text-blue-700 `
					}
					style={{ textAlign: "end", padding: 0 }}
					onClick={() => {
						dispatch(openLoadedFormDialog({ viewId: "USER_DETAILS_VIEW", dataIds }));
					}}
				>
					{userLabel ?? "-"}
					{userLabel && <Icon className="text-15 ml-4 mt-2">info</Icon>}
				</Typography>
			</div>
		);
	};

	return (
		<div className={"flex flex-col h-full w-full overflow-x-hidden"}>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<DisplaySection
					classes={{ root: "md:w-full" }}
					title={
						<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
							<div>Transaction Details</div>
							{fuelTransactionDetails?.test && (
								<CellSecondRow
									label="Test"
									color="text-white bg-blue font-medium px-5 font-medium"
									variant={"extra-skinny"}
								/>
							)}
						</div>
					}
				>
					<div className="-mt-10">
						{renderDataItem("Transaction", `#${transactionId}` ?? "-", "", {})}
						{renderDataItem(
							"Date",
							generalData.transaction_date
								? `${formatDate(generalData.transaction_date)} ${generalData.transaction_time}`
								: "-",
							"",
							{}
						)}
						{renderDataItem(
							"Card",
							generalData.driver_name && generalData.driver_number
								? `# ${generalData.driver_number} - ${generalData.driver_name}`
								: "-",
							"",
							{}
						)}

						{driverName && isCarrier ? (
							renderUserDetails("Driver", driverName, { driverId: fuelTransactionDetails.driver })
						) : (
							<>{renderDataItem("Driver", driverName ?? "-", "", {})}</>
						)}

						{investorName && isCarrier ? (
							renderUserDetails("Investor", investorName, {
								userId: fuelTransactionDetails.investor_user__view?.value,
							})
						) : (
							<>{renderDataItem("Investor", investorName ?? "-", "", {})}</>
						)}

						{truckLabel && isCarrier ? (
							<div className={"flex flex-row border-b-1 border-grey-300"}>
								<Typography className={`flex w-full text-black text-12 ml:text-13 break-normal mt-10`}>
									Truck
								</Typography>
								<Typography
									className={
										"cursor-pointer text-12 ml:text-13  break-normal  " +
										` mt-10 font-semibold text-blue-700 ${
											truckLabel?.length > 45 ? " whitespace-break-spaces " : " whitespace-nowrap "
										}  text-blue-700 `
									}
									style={{ textAlign: "end", padding: 0 }}
									onClick={() => {
										dispatch(
											openLoadedFormDialog({
												viewId: "TRUCK_DETAILS_VIEW",
												dataIds: {
													truckId: fuelTransactionDetails.truck__view?.value,
													carrierId: fuelTransactionDetails.carrier,
												},
											})
										);
									}}
								>
									{truckLabel ?? "-"}
									{truckLabel && <Icon className="text-15 ml-4 mt-2">info</Icon>}
								</Typography>
							</div>
						) : (
							<>{renderDataItem("Truck", truckLabel ?? "-", "", {})}</>
						)}
						{renderDataItem(
							"Location",
							generalData.merchant_city && generalData.merchant_state
								? `${generalData.merchant_city}, ${generalData.merchant_state}`
								: "-",
							"",
							{}
						)}
						{renderDataItem("Truck Stop", generalData.truck_stop_name ?? "-", "", {})}
						{renderDataItem("Pump", generalData.pump ? `# ${generalData.pump}` : "-", "", {})}
						{renderDataItem("Product Type", generalData.product_name, "", {})}
					</div>
				</DisplaySection>
			</div>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<DisplaySection
					classes={{ root: "md:w-full" }}
					title={
						<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
							<div>Pricing Details</div>
						</div>
					}
				>
					<div className="-mt-10">
						{renderDataItem("Gallons", pricingData?.gallons, "", {})}

						{isDriver ? (
							ledgerEntryData ? (
								renderDataItem("Total", formatCurrency(ledgerEntryData.price / 100), "", {})
							) : null
						) : (
							<>
								<div className={"flex flex-row border-b-1 border-grey-300"}>
									<Typography className={`flex w-full text-black text-12 ml:text-13 break-normal mt-10`}>
										Retail PPG - Discounted PPG
									</Typography>
									<Typography
										className={
											"cursor-pointer text-12 ml:text-13 break-normal mt-10 mr-10 font-semibold text-primary-700 whitespace-nowrap line-through"
										}
										style={{ textAlign: "end", padding: 0 }}
									>
										{`${formatCurrency(pricingData?.ppg)}`}
									</Typography>
									<Typography
										className={
											"cursor-pointer text-12 ml:text-13 break-normal mt-10 font-semibold text-primary-700 whitespace-nowrap "
										}
										style={{ textAlign: "end", padding: 0 }}
									>
										{`${formatCurrency(pricingData?.ppg_discounted)}`}
									</Typography>
								</div>

								<div className={"flex flex-row border-b-1 border-grey-300"}>
									<Typography className={`flex w-full text-black text-12 ml:text-13 break-normal mt-10`}>
										Retail Cost - Total Amount
									</Typography>
									<Typography
										className={
											"cursor-pointer text-12 ml:text-13 break-normal mt-10 mr-10 font-semibold text-primary-700 whitespace-nowrap line-through"
										}
										style={{ textAlign: "end", padding: 0 }}
									>
										{`${formatCurrency(pricingData?.actual_cost)}`}
									</Typography>
									<Typography
										className={
											"cursor-pointer text-12 ml:text-13 break-normal mt-10 font-semibold text-primary-700 whitespace-nowrap "
										}
										style={{ textAlign: "end", padding: 0 }}
									>
										{`${formatCurrency(pricingData?.total_amount)}`}
									</Typography>
								</div>

								<div className={"flex flex-row border-b-1 border-grey-300"}>
									<Typography className={`flex w-full text-black text-12 ml:text-13 break-normal mt-10`}>
										Saving
									</Typography>
									<Typography
										className={
											"cursor-pointer text-12 ml:text-13 break-normal mt-10 text-green-600 font-semibold whitespace-nowrap "
										}
										style={{ textAlign: "end", padding: 0 }}
									>
										{<SavingsIcon className="text-15 mr-4 mb-2" />}
										{`${formatCurrency(pricingData?.discount_total)}`}
									</Typography>
								</div>
							</>
						)}
					</div>
				</DisplaySection>
			</div>
		</div>
	);
});

export default FuelTransactionSummaryView;
