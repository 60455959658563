import { createRequest, throwResErrors } from "./requestUtil";

export const getCarrierCoupon = (carrierId) => {
	return createRequest()
		.get(`/api/discounts/carriers/${carrierId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const applyPromotionCode = (carrierId, promotionCode) => {
	return createRequest()
		.post(`/api/discounts/carriers/${carrierId}/applyCode`, { promotionCode })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const deactiveCarrierDiscount = (carrierId) => {
	return createRequest()
		.delete(`/api/discounts/carriers/${carrierId}/deactivate`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
