// Services

const plans = {
	urlGET: "billing/dispatching-plans",
	idKey: "_id",
	listenEvent: "planRevision",
	segmentKey: "ADMIN_PLANS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["nickname", "percentage", "flat_fee_amount"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		filters: {
			search: true,
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		isHighlighted: (item) => !!item.default,
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{
				key: "nickname",
				type: "text",
				label: "Name",
				translate: "",
				grid: { headerTitle: true, valuePefix: "Plan " },
				builder: (item) => {
					if (!item?.default) return item.nickname;
					return `${item.nickname} (DEFAULT)`;
				},
			},
			{
				key: "percentage",
				type: "text",
				label: "Fee per Trip (%)",
				translate: "",
				builder: (item) => item?.percentage && `${item.percentage}%`,
			},
			{
				key: "flat_fee_amount",
				type: "currency",
				label: "Flat Fee ($)",
			},
			{ key: "created_at", type: "date", label: "Creation Date" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						translate: "",
						dialogBuilder: (item) => ({
							formId: "ADMIN_DISPATCHING_PLAN_FORM",
							mode: "EDIT",
							dataIds: { id: item._id },
						}),
					},
					{
						key: "delete",
						label: "Delete",
						translate: "",
						isVisible: (item) => !item?.default,
						dialogBuilder: (item) => ({
							formId: "ADMIN_DISPATCHING_PLAN_FORM",
							mode: "DELETE",
							dataIds: { id: item._id },
						}),
					},
				],
			},
		],
	},
};

export default plans;
