/**
 * @deprecated Use tableUtils instead
 *
 * @param {*} value
 * @param {*} decimalPlaces
 * @param {*} locale
 * @returns
 */
const createPriceView = (value, decimalPlaces = 0, locale = "en-US") => {
	return !isNaN(value) && value !== null
		? `${value < 0 ? "-" : ""} $${new Intl.NumberFormat(locale, {
				minimumFractionDigits: decimalPlaces,
		  }).format(Number.parseFloat(Math.abs(value)).toFixed(decimalPlaces))}`
		: "-";
};

export { createPriceView };
