import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import PageWrapper from "app/main/common/PageWrapper";
import WidgetsBoard from "app/main/widgets/WidgetsBoard";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

import { readURLParameters, convertURLParamsToModel } from "app/main/utils/urlUtils";

const HomePage = (props) => {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const role = user.role;

	const homeURLParams = convertURLParamsToModel(readURLParameters(), {}, { notJson: true }) ?? {};
	useEffect(() => {
		if (homeURLParams?.OPEN_WEB_DIALOG) {
			const dialog = {
				formId: homeURLParams?.OPEN_WEB_DIALOG,
				viewId: homeURLParams?.OPEN_WEB_DIALOG,
				dataIds: {
					carrierId: homeURLParams?.carrierId,
				},
			};
			dispatch(openLoadedFormDialog(dialog));
		}
		// eslint-disable-next-line
	}, [homeURLParams]);

	if (
		(role === "ADMIN" || role === "OPS_DISPATCHER" || role === "OPS_SUPPORT") &&
		!user?.email?.includes("@ryder.com")
	) {
		return (
			<div className="px-12 py-4">
				<PageWrapper restrictions={["CONFIRMATION"]} noPadding={true} noFuseWrapper={true}>
					<WidgetsBoard boardKey="ADMIN_HOME" />
				</PageWrapper>
			</div>
		);
	} else if (role === "OPS_DISPATCHER" && user?.email?.includes("@ryder.com")) {
		return (
			<PageWrapper restrictions={["CONFIRMATION"]}>
				<Redirect to="/search" />
			</PageWrapper>
		);
	} else if (role === "CARRIER_OWNER" || role === "CARRIER_MANAGER" || role === "CARRIER_DISPATCHER") {
		return (
			<>
				<div className="flex h-full pl-12 pr-16 py-4">
					<PageWrapper restrictions={["ONBOARDING", "TIER_LIMITED"]} noPadding={true} noFuseWrapper={true}>
						<WidgetsBoard boardKey="CARRIER_HOME" margin={[20, 20]} />
					</PageWrapper>
				</div>
			</>
		);
	} else if (role === "CARRIER_DRIVER") {
		return (
			<PageWrapper restrictions={["ONBOARDING", "TIER_LIMITED"]} noPadding={true} noFuseWrapper={true}>
				<WidgetsBoard boardKey="DRIVER_HOME" margin={[20, 20]} />
			</PageWrapper>
		);
	} else if (role === "CARRIER_GENERIC") {
		return (
			<PageWrapper restrictions={["ONBOARDING", "TIER_LIMITED"]} noPadding={true} noFuseWrapper={true}>
				<WidgetsBoard boardKey="INVESTOR_HOME" margin={[20, 20]} />
			</PageWrapper>
		);
	} else {
		return (
			<PageWrapper>
				<div className="flex flex-col h-screen70 items-center justify-center">
					<Typography color="textPrimary" className={"text-32 text-primary text-center"}>
						Welcome to SmartHop!
					</Typography>
				</div>
			</PageWrapper>
		);
	}
};

export default HomePage;
