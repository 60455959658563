import { createRequest, createHeaders, throwResErrors } from "./requestUtil";

export const postSmartAction = (data) => {
  return createRequest()
    .post(
      "api/messenger/smart-actions",
      { data },
      { headers: createHeaders () }
    )
    .then((res) => res.data)
    .catch((error) => throwResErrors(error))
};