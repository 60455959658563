import Typography from "@material-ui/core/Typography";
import { motion } from "framer-motion";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";

/**
 * SmarthopErrorView allows to show an error and trigger list reload.
 */
function SmarthopErrorView(props) {
	const dispatch = useDispatch();

	return (
		<motion.div
			className="flex-col w-full h-full flex items-center justify-center"
			initial={{ opacity: 0, y: 40 }}
			animate={{ opacity: 1, y: 0, transition: { delay: 0.5 } }}
		>
			<Typography color="error" className="m-10">
				{props.message ?? "Unknown Error"}
			</Typography>
			{props.onReloadClick && !props?.otpButtonComponent && !props.onboardingButtonComponent && (
				<Button
					className="mx-8 m-10 mb-40"
					variant="contained"
					color="primary"
					aria-label="Follow"
					onClick={() => {
						props.onReloadClick();
					}}
				>
					Reload
				</Button>
			)}
			{props?.otpButtonComponent && props.otpButtonComponent(dispatch)}
		</motion.div>
	);
}

export default SmarthopErrorView;
