import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

// Utils
import { getStatusConfig } from "app/main/utils/tripsUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatDate } from "app/main/utils/dateUtils";
import { createTypeView } from "app/main/search/searchUtils";

// Gatekeeper
import { isEnabled } from "app/services/featureStorageService";

// Consts
import { DRIVER_JOURNAL_ENTRY_CATEGORIES } from "app/main/consts";

const getPodFilesStyle = (podFiles, hasSendDriverBolNotification) => {
	if (podFiles.length === 0 && hasSendDriverBolNotification) {
		return "orange";
	} else if (podFiles.length === 0) {
		return "red";
	} else {
		return "green";
	}
};

function TripsCellView(props) {
	const mobile = props.mobile;
	const config = props.config;
	const variant = props.variant;
	const item = props.item;
	const podfiles = item.pod_files?.filter((item) => item.type === "pod");
	const hasSendDriverBolNotification = item.hasSendDriverBolNotification;

	const view = Array.isArray(props.view) ? props.view?.[0] : props.view;
	if (!view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const buildItem = (view, more) => {
		const statusConfig = getStatusConfig(view?.metadata?.status);

		return (
			<CellContainerRow
				mobile={mobile}
				key={view.value}
				variant={variant}
				first={
					<div className="flex flex-wrap">
						<CellFirstRow
							mobile={mobile}
							view={view}
							dialog={{
								viewId: "TRIP_VIEW",
								dataIds: {
									tripId: view.value,
									carrierId: view.metadata?.carrier,
									isExternal: config?.isExternal,
									readOnly: config?.readOnly,
								},
							}}
							variant={variant}
						/>
						{config?.showStatus__top && (
							<CellSecondRow
								mobile={mobile}
								label={`${statusConfig?.label}`}
								variant={variant}
								classes={{ label: "ml-4" }}
							/>
						)}
					</div>
				}
				second={
					<div className="flex flex-wrap">
						<div className="flex flex-row whitespace-nowrap">
							{config?.showCategory && (
								<CellSecondRow
									mobile={mobile}
									label={`${
										DRIVER_JOURNAL_ENTRY_CATEGORIES.find(
											(category) => category.value === view.metadata?.entryCategoryType
										)?.label
									}`}
									variant={variant}
								/>
							)}
							{config?.showSmartPay && view?.metadata?.smartpay_eligible && (
								<div className="flex flex-row mr-4 -mt-2">
									<Typography className="text-primary text-12 font-bold">Smart</Typography>
									<Typography className="text-secondary text-12 font-bold">Pay</Typography>
								</div>
							)}
							{config?.showEquipment && (
								<CellSecondRow
									mobile={mobile}
									label={view?.metadata?.equipment ? createTypeView(view?.metadata?.equipment, true) : ""}
									variant={variant}
								/>
							)}
							{config?.showBroker && <CellSecondRow mobile={mobile} label={view?.metadata?.broker} variant={variant} />}
							{config?.showBol && item.data.status === "Delivered" && isEnabled("SEND_DRIVER_BOL_NOTIFICATION") && (
								<div
									className={`flex justify-center items-center h-16 -mt-1 border-1 border-${getPodFilesStyle(
										podfiles,
										hasSendDriverBolNotification
									)}`}
								>
									<Typography
										className={`text-10 px-3 text-${getPodFilesStyle(podfiles, hasSendDriverBolNotification)}`}
									>
										BOL
									</Typography>
									{podfiles.length === 0 && hasSendDriverBolNotification && (
										<Icon className="text-orange text-12 mr-3">access_time</Icon>
									)}
								</div>
							)}
						</div>
						{config?.showStatus && <CellSecondRow mobile={mobile} label={`${statusConfig?.label}`} variant={variant} />}
						{config?.showDeliveryDs && (
							<CellSecondRow
								mobile={mobile}
								icon={"flight_land"}
								label={formatDate(view?.metadata?.delivery_date)}
								variant={variant}
							/>
						)}
						{config?.showPayroll && (
							// TODO showPayroll: Need Refactor. Not currently supported. Performs calculation on demand
							// and not based on entries
							<CellSecondRow
								mobile={mobile}
								icon={formatCurrency(view?.metadata?.payroll) ? "request_quote" : "money_off"}
								label={formatCurrency(view?.metadata?.payroll) ?? "No Payroll"}
								variant={variant}
								color={formatCurrency(view?.metadata?.payroll) ? " text-blue-700" : " text-red-600"}
							/>
						)}
						{config?.showTypeHistory && (
							<CellSecondRow mobile={mobile} label={view?.metadata?.type ?? "No Type"} variant={variant} />
						)}
					</div>
				}
				more={more}
			/>
		);
	};

	let extra = [];
	if (Array.isArray(props.view)) {
		for (let i = 1; i < props.view.length; i++) {
			extra.push(buildItem(props.view[i]));
		}
	}

	return buildItem(view, extra);
}

export default TripsCellView;
