const config = {
	urlGET: "api/user-actions/stats/performance",
	idKey: "_id",
	segmentKey: "ADMIN_USERS_ACTIONS_API_PREFORMANCE",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["created_at", "count", "executionTime"],
		},
		filters: {
			search: true,
			items: [
				{
					key: "created_at",
					type: "dateRange",
					label: "Period",
				},
				{
					key: "method",
					type: "select",
					label: "Method",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "GET",
							label: "GET",
						},
						{
							value: "POST",
							label: "POST",
						},
						{
							value: "PUT",
							label: "PUT",
						},
						{
							value: "DELETE",
							label: "DELETE",
						},
					],
				},
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "SUCCESS",
							label: "Success",
						},
						{
							value: "FAILURE",
							label: "Failure",
						},
					],
				},
				{
					key: "environment",
					type: "select",
					label: "Environment",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "production",
							label: "Production",
						},
						{
							value: "staging",
							label: "Staging",
						},
						{
							value: "staging.local",
							label: "Staging (Local)",
						},
						{
							value: "development",
							label: "Development",
						},
						{
							value: "development.local",
							label: "Development (Local)",
						},
					],
				},
				{
					key: "created_at",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__DATE_DAYS_GT__1",
							label: "Last 1 Day",
						},
						{
							value: "__DATE_DAYS_GT__3",
							label: "Last 3 Days",
						},
						{
							value: "__DATE_DAYS_GT__7",
							label: "Last 7 Days",
						},
						{
							value: "__DATE_DAYS_GT__30",
							label: "Last 30 Days",
						},
					],
				},
			],
		},
		order: {
			defaut: {
				key: "executionTime",
				dir: "desc",
			},
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		items: [
			{
				key: "executionTime",
				builder: (item) => (item.executionTime > 0 ? item.executionTime + "ms" : "-"),
				label: "Execution",
			},
			{
				key: "count",
				type: "text",
				label: "Count",
			},
			{
				key: "_id",
				type: "text",
				label: "Route",
			},
		],
	},
};

export default config;
