import _ from "lodash";
import MarketConditionsMap from "app/main/market-conditions/MarketConditionsMap";
import { strategyConnect } from "../../providers/StrategyContextProvider";

const StrategyHeatmap = ({ dataMap, result }) => {
	return dataMap && _.isEmpty(result) ? <MarketConditionsMap data={dataMap} /> : null;
};

const mapStateToProps = (state) => ({
	dataMap: state.dataMap,
	result: state.result,
});

export default strategyConnect(mapStateToProps)(StrategyHeatmap);
