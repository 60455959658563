import SmartHopWalletLandingHeader from "./SmartHopWalletLandingHeader";
import SmartHopWalletLandingSteps from "./SmartHopWalletLandingSteps";

const SmartHopWalletLanding = (props) => (
	<div className="mx-auto my-48 md:m-auto w-5/6 xl:w-4/6">
		<SmartHopWalletLandingHeader {...props} />
		<SmartHopWalletLandingSteps />
	</div>
);

export default SmartHopWalletLanding;
