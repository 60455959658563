import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import LoadStrategyWarning from "app/main/search/load/LoadStrategyWarning";
import StrategyMap from "../../strategy-map/StrategyMap";
import MovesTimeline from "./MovesTimeline";
import { StrategyStateContext } from "../../../providers/StrategyContextProvider";

const useStyles = makeStyles((theme) => {
	return {
		mapRadius: {
			borderRadius: "20px",
		},
	};
});

function PathDetails(props) {
	const { load: loadInfo, strategy, truckPosition, pathMoves, strategyReqData, searchId, selectedMoves } = props;
	const classes = useStyles();
	const nativeMobile = props.nativeMobile || window.ismobile();
	const pathId = pathMoves[pathMoves.length - 1].data.stop.pathId;
	const [moves, setMoves] = useState(pathMoves);
	const [listCoordinates, setListCoordinates] = useState(null);
	const [listPolyLine, setListPolyLine] = useState(null);
	const [typeShow, setType] = useState("STRATEGY_DETAILS");

	const { selectedPaths } = useContext(StrategyStateContext);

	useEffect(() => {
		if (!truckPosition || !loadInfo || !strategy || !pathMoves) {
			return;
		}

		const arrayCoordinates = [];
		const arrayPolyline = [];

		let all_moves = moves;

		selectedMoves?.forEach((move, moveIndex, moves) => {
			arrayCoordinates.push({
				name: move?.data?.stop?.o_city,
				empty: move?.data?.stop?.empty,
				lat: move?.data?.stop?.o_lat,
				lng: move?.data?.stop?.o_lng,
				cluster: move?.data?.stop?.ocluster,
			});
			if (moves.length > 1 && moveIndex === moves.length - 1) {
				arrayCoordinates.push({
					name: move?.data?.stop?.d_city,
					lat: move?.data?.stop?.d_lat,
					lng: move?.data?.stop?.d_lng,
					cluster: move?.data?.stop?.dcluster,
				});
			}

			const line = [
				{ lat: move?.data?.stop?.o_lat, lng: move?.data?.stop?.o_lng },
				{ lat: move?.data?.stop?.d_lat, lng: move?.data?.stop?.d_lng },
			];
			moveIndex &&
				arrayPolyline.push({
					line,
					color: move?.data?.stop?.empty ? "rgb(255, 25, 25)" : "rgb(125, 125, 125)",
					dashedLine: move?.data?.stop?.empty,
				});
		});

		setListCoordinates(arrayCoordinates);
		setListPolyLine(arrayPolyline);
		setMoves(all_moves);
		// eslint-disable-next-line
	}, [loadInfo, strategy, truckPosition, pathMoves, selectedPaths, selectedMoves]);

	const onTabChange = (_, value) => {
		setType(value);
	};

	if (!loadInfo || !strategy || !truckPosition) {
		return !props.isGeneralStrategy ? "" : "Invalid  params";
	}

	let stopsDataView = (
		<MovesTimeline
			nativeMobile={nativeMobile}
			pathMoves={selectedMoves}
			strategyReqData={strategyReqData}
			searchId={searchId}
		/>
	);

	return (
		<div className={classes.maxH70vh + " flex w-full flex-col mt-5 mb-20 md:p-10"}>
			{nativeMobile ? (
				<div className="w-full h-full flex flex-col lg:flex-row lg:h-512">
					<div className="md:hidden flex">
						<LoadStrategyWarning />
					</div>
					<div className={classes.mapRadius + " w-full h-400 overflow-hidden mx-5 lg:w-2/4 lg:h-512"}>
						<StrategyMap
							key={pathId + "_" + pathMoves?.length}
							classes={classes}
							locations={listCoordinates}
							listPolyLine={listPolyLine}
							equipment={strategyReqData?.equipment}
						/>
					</div>
					<Tabs className="mt-10" value={typeShow} onChange={onTabChange} variant="fullWidth">
						<Tab label={"Moves"} value={"STRATEGY_DETAILS"} />
						<Tab label={"Earnings"} value={"FUTURE_EARNINGS"} />
					</Tabs>
					<div className="flex overflow-x-hidden flex-col">
						{/* <div className="flex overflow-x-hidden overflow-scroll flex-col"> */}
						{typeShow === "STRATEGY_DETAILS" && (
							<div className="w-full h-full pt-20 flex justify-center md:pt-0 lg:w-1/4 md:pl-20 lg:h-512">
								{stopsDataView}
							</div>
						)}
					</div>
				</div>
			) : (
				<div className="w-full h-full flex flex-col lg:flex-row lg:h-512 px-10">
					<div className="md:hidden flex">
						<LoadStrategyWarning />
					</div>
					<div className={classes.mapRadius + " w-full h-400 overflow-hidden lg:w-3/4 lg:h-512"}>
						<StrategyMap
							key={pathId + "_" + pathMoves?.length}
							classes={classes}
							locations={listCoordinates}
							listPolyLine={listPolyLine}
							equipment={strategyReqData?.equipment}
						/>
					</div>
					<div className="w-full h-full pt-20 md:pt-0 lg:w-1/4 md:pl-10 lg:h-512">{stopsDataView}</div>
				</div>
			)}
		</div>
	);
}

export default PathDetails;
