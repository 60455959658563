import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { Typography, Link } from "@material-ui/core";
import { closeTutorialDialog } from "app/store/tools/tutorialDialogSlice";

export const InvoiceTutorialDescription = () => {
	const [redirect, setRedirect] = useState(false);
	const dispatch = useDispatch();
	const handleLinkClick = () => {
		setRedirect(true);
	};

	useEffect(() => {
		if (redirect) dispatch(closeTutorialDialog());
	}, [dispatch, redirect]);

	return (
		<>
			<Typography className="text-14">
				{"You can always change default configuration of invoice module in "}
				<Link onClick={handleLinkClick} color="primary">
					{"Settings"}
				</Link>
			</Typography>
			{redirect && <Redirect to={"/profile/company#invoice-settings"} />}
		</>
	);
};

export const InvoiceTutorialSidebar = () => {
	return (
		<div className="flex flex-col p-32 pt-6">
			<Typography className=" text-justify text-primary text-14 py-12">
				<strong>Instructions</strong>
			</Typography>
			<Typography className=" text-left text-primary text-12 mt-16">
				<strong>Step 1 - </strong>Once the trip is delivered, confirm all trip details match the Rate Confirmation and
				any additions/deductions applicable to the invoice have been captured. The Required Action field determines how
				the addition/deduction applies to your account.
			</Typography>
			<Typography className=" text-left text-primary text-12 mt-10">
				<strong>Step 2 - </strong>Upload all documentation applicable to the invoice. All documents uploaded will be
				included in the invoice package.
			</Typography>
			<Typography className=" text-left text-primary text-12 mt-10">
				<strong>Step 3 - </strong>Confirm, generate and distribute your invoice.
			</Typography>
			<Typography className=" text-left text-primary text-12 mt-16">
				<strong>Step 4 - </strong>Manage collections.
			</Typography>
		</div>
	);
};
