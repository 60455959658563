import moment from "moment";
import * as yup from "yup";
import _ from "@lodash";
import clsx from "clsx";
import { useEffect, useMemo, useState, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardContent from "@material-ui/core/CardContent";
import { useSelector, useDispatch } from "react-redux";

import SmarthopDateRangeField from "./fields/SmarthopDateRangeField";
import SmarthopTextField from "./fields/SmarthopTextField";
import SmarthopAutocompleteField from "./fields/SmarthopAutocompleteField";
import SmarthopRadioButtonField from "./fields/SmarthopRadioButtonField";
import SmarthopCheckboxField from "./fields/SmarthopCheckboxField";
import SmarthopUploadField from "./fields/SmarthopUploadField";
import SmarthopPickerField from "./fields/SmarthopPickerField";
import SmarthopMenuContainer from "./fields/SmarthopMenuContainer";
import SmarthopSliderField from "./fields/SmarthopSliderField";
import SmarthopPreviewField from "./fields/SmarthopPreviewField";
import SmarthopPatternField from "./fields/SmarthopPatternField";
import SmarthopObjectField from "./fields/SmarthopObjectField";
import SmarthopCaptchaField from "./fields/SmarthopCaptchaField";
import SmarthopNumberField from "./fields/SmarthopNumberField";
import SmarthopImageField from "./fields/SmarthopImageField";

import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

import FuseUtils from "@fuse/utils";

import i18next from "i18next";
import es from "./i18n/es";

import { setPreventDialogCloseCallback } from "app/store/tools/formToolsSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { centsToDollars, renderUpdateTierMessage, UNAUTHORIZED_TIER_ACCESS } from "app/main/utils/financeUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { validatorDictionary } from "./validation/validators";

// Utils
import { renderOtpRequiredMessage, UNAUTHORIZED_WALLET_ACCESS } from "app/main/utils/financeUtils";
import { getUserTier } from "app/services/LoginService";
import { Tooltip } from "@material-ui/core";

i18next.addResourceBundle("es", "forms", es);

const phoneRegExp = /^(\s*|(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?))?$/;

const EDITABLE_FIELDS = [
	"text",
	"phone",
	"email",
	"password",
	"passwordConfirm",
	"number",
	"currency",
	"percentage",
	"zipcode",
	"picker",
	"slider",
	"select",
	"radio",
	"checkbox",
	"upload",
	"group",
	"autocomplete",
	"dateRange",
];

const NUMERIC_FIELDS = ["number", "currency", "percentage", "zipcode", "slider"];
const DATE_FIELDS = ["dateRange", "picker"];

const TooltipStyle = withStyles((theme) => ({
	tooltip: {
		fontSize: "13px",
		color: "black",
		backgroundColor: "#f1f1f1",
		maxWidth: "none",
		border: "solid thin black",
	},
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "absolute",
		right: 0,
	},
	form: {},
	formInvisible: {
		visibility: "hidden",
	},
}));

const formatRangeError = (item, value) => {
	if (item.type === "currency") {
		if (item?.field?.manageCents) {
			value = centsToDollars(value);
		}
		value = formatCurrency(value);
	}
	return value;
};

const applyOverrides = (config, overrides) => {
	if (!overrides) return config;
	// Avoiding modification of original values
	config = !config ? {} : { ...config };

	// WORKAROUND: Applying workaround to move all component specific params into the same field - "component",
	// so config in fields like "autocomplete: {}" or similar would be moved to generic "component"
	if (config.type && config[config.type]) {
		config.component = config[config.type];
		delete config[config.type];
	}

	Object.keys(overrides).forEach((key) => {
		const overrideValue = overrides[key];
		const configValue = config[key];
		if (!_.isArray(overrideValue) && _.isObject(overrideValue) && !_.isArray(configValue) && _.isObject(configValue)) {
			config[key] = applyOverrides(configValue, overrideValue);
		} else {
			config[key] = overrideValue;
		}
	});

	return config;
};

const defaultValuesIterator = (items, data, level) => {
	if (!level) level = 0;
	let defaultValues = {};
	if (!data) {
		data = {};
	}

	items?.forEach((item) => {
		if (!item) return;

		let dataValue = data[item.key];
		// WORKAROUND: allow to dispaly values of nested object, need to be careful since
		// that logic won't work in case of '__view' or '__flag' fields
		if (item.key?.includes(".")) {
			dataValue = data;
			item.key.split(".").forEach((subkey) => {
				dataValue = dataValue?.[subkey];
			});
		}

		if (item.type === "banner" || item.type === "message") {
			return;
		}
		if (item.type === "tabs") {
			const tabItems = [];
			item?.tabs?.forEach((tab) => tabItems.push(...(tab?.content?.items ?? [])));
			let internal = defaultValuesIterator(tabItems, data, level + 1);
			defaultValues = { ...defaultValues, ...internal };
			return;
		}
		if (item.type === "group" || item.type === "menu" || item.type === "toggle") {
			let internal = defaultValuesIterator(item?.content?.items, data, level + 1);
			defaultValues = { ...defaultValues, ...internal };
			return;
		}
		if (item.type === "upload") {
			if (dataValue?.length) {
				defaultValues[item.key] = dataValue;
				defaultValues[item.key + "__flag"] = true;
			} else {
				defaultValues[item.key] = [];
				defaultValues[item.key + "__flag"] = false;
			}
			return;
		}
		if (item.type === "autocomplete") {
			let itemView = data[item.key + "__view"];
			// WORKAROUND: if someone passes string instead if correct value,
			// it would break autocomplete components,
			if (itemView === "undefined" || itemView === "null") {
				console.warn("[SmarthopFromView] autocomplete contains invalid string value:", itemView);
				itemView = undefined;
			}
			if (item.autocomplete?.multiple) {
				if (itemView && itemView.length > 0) {
					defaultValues[item.key + "__view"] = { options: itemView, input: "" };
					defaultValues[item.key + "__flag"] = true;
				} else {
					defaultValues[item.key + "__view"] = { options: [], input: "" };
					defaultValues[item.key + "__flag"] = false;
				}
			} else {
				if (itemView && !_.isEmpty(itemView)) {
					defaultValues[item.key + "__view"] = {
						options: itemView ? [itemView] : [],
						input: itemView?.label ?? "",
					};
					defaultValues[item.key + "__flag"] = true;
				} else {
					defaultValues[item.key + "__view"] = { options: [], input: "" };
					defaultValues[item.key + "__flag"] = false;
				}
			}
			return;
		}
		if (item.type === "checkbox") {
			if (dataValue === true || dataValue === false) {
				defaultValues[item.key] = dataValue;
			} else {
				defaultValues[item.key] = item.defaultValue ?? false;
			}
			return;
		}
		if (item.type === "number" || item.type === "percentage" || item.type === "zipcode" || item.type === "currency") {
			if (dataValue || dataValue === 0) {
				defaultValues[item.key] = dataValue;
			} else {
				defaultValues[item.key] = item.defaultValue ?? "";
			}
			return;
		}
		if (item.type === "picker") {
			if (dataValue) {
				defaultValues[item.key] = moment(dataValue).toISOString();
			} else if (item.defaultValue) {
				if (["NOW", "START_MONTH", "NOW_WITH_TIME"].includes(item.defaultValue)) {
					let date = new Date();
					if (item.defaultValue === "START_MONTH") {
						// now we provide "1" to set the date to the first day to current month
						date.setDate(1);
					}
					if (item.defaultValue !== "NOW_WITH_TIME" && item.picker?.type === "date") {
						date.setHours(0, 0, 1, 0);
					}
					defaultValues[item.key] = date.toISOString();
					return;
				}
				defaultValues[item.key] = moment(item.defaultValue).toISOString();
			}
			return;
		}
		if (item.type === "pattern") {
			// If patter requires minimum number of elements, we need to add those by default
			while (item?.pattern?.min > 0 && (!dataValue?.length || dataValue?.length < item?.pattern?.min)) {
				if (!dataValue) dataValue = [];
				dataValue.push({});
			}

			if (dataValue && dataValue.map) {
				dataValue = _.cloneDeep(dataValue);
				// Every item has to be validated first to make sure form validations
				//works well when openning with some invalid default values
				const patternSchema = yup.object().shape(schemaIterator(item.content.items));
				let valid = true;
				dataValue.forEach((patternItem, i) => {
					const formattedItem = defaultValuesIterator(item.content.items, patternItem, level + 1);
					patternItem.__valid = patternSchema.isValidSync(formattedItem);

					// Simplifiying keys when there is not ability to move items around
					patternItem.__key = item?.pattern?.disableMoveButton ? "item_" + i : FuseUtils.generateGUID();
					// If any value is invalid, the whole patter component would be considered invalid
					if (!patternItem.__valid) {
						valid = false;
					}
				});
				defaultValues[item.key] = { items: dataValue, valid, length: dataValue.length };
			} else {
				defaultValues[item.key] = { items: [], valid: true, length: 0 };
			}
			return;
		}
		if (item.type === "object") {
			const objectSchema = item.object?.schema?.() ?? yup.object().shape(schemaIterator(item.content.items));
			const formattedItem = defaultValuesIterator(item.content.items, dataValue ?? {}, level + 1);
			const defaultData = postprocessData(item.content.items, formattedItem);

			let valid = false;
			let validationError = null;
			try {
				objectSchema.validateSync(formattedItem ?? {});
				valid = true;
			} catch (error) {
				validationError = error.message;
			}

			defaultValues[item.key] = { value: defaultData, valid, errors: { validation: validationError, visible: {} } };
			return;
		}
		if (item.type === "slider") {
			let value;
			if (item.slider?.type === "range") {
				value = [
					data[item.key + "__min"] ?? item.defaultValueMin ?? item.slider?.min ?? 0,
					data[item.key + "__max"] ?? item.defaultValueMax ?? item.slider?.max ?? 100,
				];
			} else {
				value = dataValue ?? item.defaultValue ?? 0;
			}
			defaultValues[item.key] = value;
			return;
		}

		if (item.type === "select" || item.type === "radio") {
			item.options?.forEach((option) => {
				let selectDefaultValues = defaultValuesIterator(option.showItems, data, level + 1);
				defaultValues = {
					...defaultValues,
					...selectDefaultValues,
				};
			});
			defaultValues[item.key] = dataValue ?? item.defaultValue ?? "";
			return;
		}
		if (item.key) {
			if (dataValue) {
				defaultValues[item.key] = dataValue;
			} else {
				if (item.defaultValue === "__RANDOM__") {
					defaultValues[item.key] = FuseUtils.generateGUID() + FuseUtils.generateGUID();
				} else {
					defaultValues[item.key] = item.defaultValue ?? "";
				}
			}
		}
	});

	return defaultValues;
};

const labelIterator = (items) => {
	let labels = {};

	items?.forEach((item) => {
		if (!item) return;

		if (item.key && typeof item.label === "function") {
			if (!labels[item.key]) labels[item.key] = {};
			labels[item.key].label = item.label;
		}
		if (item.key && typeof item.summary === "function") {
			if (!labels[item.key]) labels[item.key] = {};
			labels[item.key].summary = item.summary;
		}
		if (item.key && typeof item.description === "function") {
			if (!labels[item.key]) labels[item.key] = {};
			labels[item.key].description = item.description;
		}
		if (item.key && item.type === "component") {
			if (typeof item.value === "function") {
				if (!labels[item.key]) labels[item.key] = {};
				labels[item.key].value = item.value;
			} else if (typeof item.builder === "function") {
				if (!labels[item.key]) labels[item.key] = {};
				labels[item.key].builder = item.builder;
			}
		}

		if (["select", "radio", "autocomplete"].includes(item.type)) {
			item.options?.forEach((option) => {
				const internal = labelIterator(option.showItems);
				labels = { ...labels, ...internal };
			});
			return;
		}

		if (item?.content?.items) {
			const internal = labelIterator(item?.content?.items);
			labels = { ...labels, ...internal };
			return;
		}
	});

	return labels;
};

const createLabelsOverrides = (labels, data, dispatch, dataIds) => {
	let labelOverrides = {};
	if (!data) {
		return labelOverrides;
	}

	const labelsKeys = labels ? Object.keys(labels) : [];
	labelsKeys.forEach((labelKey) => {
		labelOverrides[labelKey] = {};
		Object.keys(labels[labelKey]).forEach((methodKey) => {
			labelOverrides[labelKey][methodKey] = labels[labelKey][methodKey](data, dispatch, dataIds);
		});
	});

	return labelOverrides;
};

const rulesIterator = (items, data) => {
	let rules = {};
	if (!data) {
		data = {};
	}

	items?.forEach((item) => {
		if (!item) return;
		if (item.rules && item.key) {
			if (item.rules?.required?.fieldKey && item.rules?.required?.equalsTo) {
				let refKey = item.rules?.required?.fieldKey;
				if (!rules[refKey]) rules[refKey] = {};
				if (!rules[refKey].ifEqualsRequireFields) rules[refKey].ifEqualsRequireFields = [];
				rules[refKey].ifEqualsRequireFields.push({ key: item.key, equals: item.rules?.required?.equalsTo });
			}

			if (item.rules?.required?.fieldKey && item.rules?.required?.noEqualsTo) {
				let refKey = item.rules?.required?.fieldKey;
				if (!rules[refKey]) rules[refKey] = {};
				if (!rules[refKey].ifNoEqualsRequireFields) rules[refKey].ifNoEqualsRequireFields = [];
				rules[refKey].ifNoEqualsRequireFields.push({ key: item.key, equals: item.rules?.required?.noEqualsTo });
			}

			// Rule 1: Disable fileds if field is empty
			if (item.rules?.disabled?.ifEmptyRef) {
				let refKey = item.rules?.disabled?.ifEmptyRef;
				if (!rules[refKey]) rules[refKey] = {};
				if (!rules[refKey].ifEmptyDisableFileds) rules[refKey].ifEmptyDisableFileds = [];
				rules[refKey].ifEmptyDisableFileds.push(item.key);
			}

			// Rule 2: Disable fileds if field is not empty
			if (item.rules?.disabled?.ifNotEmptyRef) {
				let refKey = item.rules?.disabled?.ifNotEmptyRef;
				if (!rules[refKey]) rules[refKey] = {};
				if (!rules[refKey].ifNotEmptyDisableFileds) rules[refKey].ifNotEmptyDisableFileds = [];
				rules[refKey].ifNotEmptyDisableFileds.push(item.key);
			}

			// Rule 3: Value ref for build
			if (item.rules?.params?.valueRef) {
				let refKey = item.rules?.params?.valueRef;
				let paramKey = item.rules?.params?.paramKey;
				if (!rules[refKey]) rules[refKey] = {};
				if (!rules[refKey].provideAsParamToFields) rules[refKey].provideAsParamToFields = [];
				rules[refKey].provideAsParamToFields.push({ paramKey: paramKey, field: item.key });
			}

			// Rule 4: Hidden fields if no equals to
			if (item.rules?.hidden?.length > 0) {
				item.rules?.hidden.forEach((field) => {
					if (!field.fieldKey || !field.noEqualsTo) return;
					let refKey = field.fieldKey;
					if (!rules[refKey]) rules[refKey] = {};
					if (!rules[refKey].ifNoEqualsHiddenFields) rules[refKey].ifNoEqualsHiddenFields = [];
					rules[refKey].ifNoEqualsHiddenFields.push({ key: item.key, equals: field.noEqualsTo });
				});
			}
		}

		const mergeArray = (type, key, to, from) => {
			if (to[key]?.[type] && from[key]?.[type]) to[key][type].push(...from[key][type]);
			if (!to[key]?.[type] && from[key]?.[type]) {
				if (!to[key]) to[key] = {};
				to[key][type] = [...from[key][type]];
			}
		};

		const mergeValue = (key, to, from) => {
			mergeArray("ifEmptyDisableFileds", key, to, from);
			mergeArray("ifNotEmptyDisableFileds", key, to, from);
			mergeArray("provideAsParamToFields", key, to, from);
			mergeArray("ifEqualsRequireFields", key, to, from);
			mergeArray("ifNoEqualsRequireFields", key, to, from);
			mergeArray("ifNoEqualsHiddenFields", key, to, from);
		};

		const mergeObj = (to, from) => {
			Object.keys(from).forEach((key) => {
				mergeValue(key, to, from);
			});
		};

		if (["select", "radio", "autocomplete"].includes(item.type)) {
			item.options?.forEach((option) => {
				let internal = rulesIterator(option.showItems, data);
				mergeObj(rules, internal);
			});
			return;
		}

		if (item?.content?.items) {
			let internal = rulesIterator(item?.content?.items, data);
			mergeObj(rules, internal);
			return;
		}
	});

	return rules;
};

const createRulesOverrides = (rules, data) => {
	let rulesOverrides = {};
	if (!data) {
		return rulesOverrides;
	}

	const dataKeys = data ? Object.keys(data) : [];
	const rulesKeys = rules ? Object.keys(rules) : [];
	if (!rulesKeys?.length) {
		return rulesOverrides;
	}

	rulesKeys.forEach((ruleKey) => {
		if (!dataKeys.includes(ruleKey)) {
			dataKeys.push(ruleKey);
		}
	});

	dataKeys.forEach((key) => {
		if (!key.includes("__flag")) {
			let originalKey = key.replace("__view", "");
			rulesOverrides = updatedOverride(rulesOverrides, rules, originalKey, data?.[key], null, data);
		}
	});

	return rulesOverrides;
};

const updatedOverride = (rulesOverrides, rules, key, value, setValue, data) => {
	if (!key || !rules || !rules[key]) {
		return rulesOverrides;
	}

	let rule = rules[key];
	let newOverrides = { ...rulesOverrides };

	// signals
	let signalIsEmpty = Array.isArray(value) ? !value.length : !value || value === "__NOT_SELECTED__";

	// override logic
	if (rule.ifEqualsRequireFields) {
		rule.ifEqualsRequireFields.forEach((field) => {
			if (!newOverrides[field.key]) newOverrides[field.key] = {};
			newOverrides[field.key].required = field.equals === value;
		});
	}

	if (rule.ifNoEqualsRequireFields) {
		rule.ifNoEqualsRequireFields.forEach((field) => {
			if (!newOverrides[field.key]) newOverrides[field.key] = {};
			newOverrides[field.key].required = field.equals !== value;
		});
	}
	if (rule.ifEmptyDisableFileds) {
		rule.ifEmptyDisableFileds.forEach((field) => {
			if (!newOverrides[field]) newOverrides[field] = {};
			newOverrides[field].disabled = signalIsEmpty;
		});
	}
	if (rule.ifNotEmptyDisableFileds) {
		rule.ifNotEmptyDisableFileds.forEach((field) => {
			if (!newOverrides[field]) newOverrides[field] = {};
			newOverrides[field].disabled = !signalIsEmpty;
		});
	}
	if (rule.provideAsParamToFields) {
		rule.provideAsParamToFields.forEach(({ paramKey, field }) => {
			if (!newOverrides[field]) newOverrides[field] = {};
			if (!newOverrides[field].paramsValue) newOverrides[field].paramsValue = {};

			let paramValue = Array.isArray(value) ? value[0] : value;
			paramValue = paramValue?.options || paramValue?.value ? paramValue?.value : paramValue;

			// if reference value is changed we need to reset dependant field
			if (newOverrides[field].paramsValue[paramKey] !== paramValue) {
				newOverrides[field].paramsValue[paramKey] = paramValue;
				if (setValue) {
					setValue(field, null, { shouldValidate: false });
					setValue(field + "__view", null, { shouldValidate: false });
					setValue(field + "__flag", false, { shouldValidate: false });
				}
			}
		});
	}
	if (rule.ifNoEqualsHiddenFields) {
		let ifNoEqualsHiddenFields = [];
		Object.keys(rules).forEach((key) => {
			rules[key].ifNoEqualsHiddenFields?.map((item) => ifNoEqualsHiddenFields.push({ ...item, originalKey: key }));
		});

		rule.ifNoEqualsHiddenFields.forEach((field) => {
			let hidden = false;
			ifNoEqualsHiddenFields = ifNoEqualsHiddenFields.filter((item) => item.key === field.key);
			for (let item of ifNoEqualsHiddenFields) {
				if (item.equals !== data[item.originalKey]) {
					hidden = true;
					break;
				}
			}

			if (!newOverrides[field.key]) newOverrides[field.key] = {};
			newOverrides[field.key].hidden = hidden;
		});
	}

	return newOverrides;
};

const postprocessData = (items, data, hidden, processedKey, cleanupKey) => {
	const topLevel = !processedKey && !cleanupKey;
	if (!processedKey) {
		// Processed keys are used to save keys that might be found in parallel branches or invisible
		// elements, usually when hidden or shown elements are nested, for example if select has hidden
		// elements and some the elements share the same nested items
		processedKey = {};
	}
	if (!cleanupKey) {
		// Some of the values can be hidden which would require cleanup, however it can be done only in the end,
		// since the same key can be used in visible and hidden sections
		cleanupKey = {};
	}

	if (topLevel) {
		// Removing keys with dots to avoid issues
		const dataCleaned = {};
		Object.keys(data).forEach((key) => {
			if (key.includes(".")) {
				console.warn("[SmarthopFromView] deleted key with invalid format, (includes '.')", key);
			} else {
				// Can be speeded up by closing only objects
				dataCleaned[key] = _.cloneDeep(data[key]);
			}
		});
		data = dataCleaned;
	}

	items?.forEach((item) => {
		if (!item) {
			return;
		}
		if (item.type === "tabs") {
			const tabItems = [];
			item?.tabs?.forEach((tab) => tabItems.push(...(tab?.content?.items ?? [])));
			postprocessData(tabItems, data, hidden, processedKey, cleanupKey);
			return;
		}
		if (item.type === "group" || item.type === "menu" || item.type === "toggle") {
			postprocessData(item?.content?.items, data, hidden, processedKey, cleanupKey);
			return;
		}
		if (!hidden) {
			if (item.type === "autocomplete") {
				let view = data[item.key + "__view"];
				if (item.autocomplete?.multiple) {
					data[item.key] = !hidden && view?.options ? view?.options?.map((option) => option.value) : [];
					data[item.key + "__view"] = !hidden && view?.options ? view?.options : [];
				} else {
					data[item.key] = !hidden && view?.options && view?.options[0]?.value ? view?.options[0]?.value : null;
					data[item.key + "__view"] = !hidden && view?.options && view?.options[0] ? view?.options[0] : {};
				}
				delete data[item.key + "__flag"];
			}
			if (item.type === "number" || item.type === "percentage" || item.type === "zipcode" || item.type === "currency") {
				if (data[item.key] === "" || isNaN(data[item.key])) {
					data[item.key] = null;
				}
			}
			if (item.type === "upload") {
				if (data[item.key]?.[0] === "") {
					data[item.key] = [];
				}
				if (!hidden && data[item.key]?.length) {
					data[item.key + "__flag"] = true;
				} else {
					data[item.key + "__flag"] = false;
				}
			}
			if (item.type === "picker") {
				if (!hidden && data[item.key]?.length) {
					data[item.key] = moment(new Date(data[item.key])).tz(moment.tz.guess(), true).format();
					data[item.key + "__tz_offset"] = new Date().getTimezoneOffset();
					data[item.key + "__tz"] = moment.tz.guess();
				} else {
					data[item.key + "__tz_offset"] = null;
					data[item.key + "__tz"] = null;
				}
			}
			if (item.type === "pattern") {
				data[item.key] = !hidden && data[item.key]?.items ? data[item.key]?.items : [];
				data[item.key]?.forEach((item) => {
					delete item.__valid;
					delete item.__key;
				});
			}
			if (item.type === "object") {
				data[item.key] = !hidden && data[item.key]?.value ? data[item.key]?.value : {};
			}
			if (item.type === "slider") {
				if (item.slider?.type === "range") {
					data[item.key + "__min"] = hidden ? null : (data[item.key] && data[item.key][0]) ?? item.slider?.min ?? 0;
					data[item.key + "__max"] = hidden ? null : (data[item.key] && data[item.key][1]) ?? item.slider?.max ?? 100;
				} else {
					data[item.key] = hidden ? null : data[item.key];
				}
			}
			if (item.type === "select" || item.type === "radio") {
				item.options?.forEach((option) => {
					let hidden = data[item.key] !== option.value;
					postprocessData(option.showItems, data, hidden, processedKey, cleanupKey);
				});
			}
			if (!!item.key) {
				// Saving visible keys, if element is ever marked as visible
				// other would be ignorred
				processedKey[item.key] = true;
			}
		} else {
			// Resetting hidden fields values to empty string before saving them
			if (item.type === "select" || item.type === "radio") {
				// If component hidden all internal items are hidden
				item.options?.forEach((option) => {
					postprocessData(option.showItems, data, true, processedKey, cleanupKey);
				});
				cleanupKey[item.key] = "";
			} else if (item.type === "autocomplete") {
				cleanupKey[item.key] = null;
				cleanupKey[item.key + "__view"] = null;
			} else if (item.type === "upload") {
				cleanupKey[item.key] = item.type === "upload" ? [] : "";
				cleanupKey[item.key + "__flag"] = false;
			} else if (
				item.type === "pattern" ||
				item.type === "object" ||
				NUMERIC_FIELDS.includes(item.type) ||
				DATE_FIELDS.includes(item.type)
			) {
				cleanupKey[item.key] = null;
			} else if (item.type === "checkbox") {
				cleanupKey[item.key] = item?.checkbox?.inverted || item?.component?.inverted ? true : false;
			} else {
				cleanupKey[item.key] = "";
			}
		}
	});

	if (topLevel && cleanupKey) {
		Object.keys(cleanupKey).forEach((key) => {
			if (!key.includes(".") && !processedKey[key] && !processedKey[key?.split?.("__")?.[0]]) {
				data[key] = cleanupKey[key];
			}
		});
	}

	return data;
};

const schemaIterator = (items, clinic, options, level) => {
	let shape = {};
	level = level > 0 ? level + 1 : 1;

	items?.forEach((item) => {
		if (!item || item.disabled) return;

		// Custom field schema validator
		if (item.schema) {
			if (!item.key) {
				// Custom validator requires key
				console.error("[SmarthopFormView] can not use custom schema without field key");
			} else {
				const schemaOverride = item.schema(yup);
				if (schemaOverride) {
					shape[item.key] = item.schema(yup);
				}
				// It's possible to disable schema by overriding it to null
				return;
			}
		}

		if (item.type === "tabs") {
			const tabItems = [];
			item?.tabs?.forEach((tab) => tabItems.push(...(tab?.content?.items ?? [])));
			let internal = schemaIterator(item?.content?.items, clinic, options, level);
			shape = { ...shape, ...internal };
			return;
		}
		if (item.type === "group" || item.type === "menu" || item.type === "toggle") {
			let internal = schemaIterator(item?.content?.items, clinic, options, level);
			shape = { ...shape, ...internal };
			return;
		}

		if (item.type === "select" || item.type === "radio") {
			let selectSection = {};
			item.options?.forEach((option) => {
				let clinic = {
					key: item.key,
					is: (val) => val === option.value,
				};

				let selectInternal = schemaIterator(option.showItems, clinic, { ...(options ?? {}), canBeHidden: true }, level);
				selectSection = {
					...selectSection,
					...selectInternal,
				};
			});
			shape = {
				...shape,
				...selectSection,
			};
		}

		let dataKey = item.key;
		let fieldYup = null;

		if (item.type === "email") {
			fieldYup = yup.string().email(i18next.t(`forms:You must enter a valid email`));
		} else if (item.type === "phone") {
			fieldYup = yup.string().matches(phoneRegExp, i18next.t(`forms:Phone number is not valid`));
		} else if (item.type === "password") {
			fieldYup = yup.string().min(6, i18next.t(`forms:Password is too short - 6 chars minimum`));
		} else if (item.type === "passwordConfirm") {
			fieldYup = yup.string().oneOf([yup.ref("password")], i18next.t(`forms:Passwords must match`));
		} else if (item.type === "pattern" && !options?.canBeHidden) {
			// ATTNETION: validation is currently not supported for patters shown inside of hidden selector components
			fieldYup = yup
				.object()
				.shape({
					valid: yup.boolean().oneOf([true], "Some fields have invalid values"),
					...(item.pattern?.min > 0
						? {
								items: yup.array().min(item.pattern.min, `Required ${item.pattern.min} items minimun`).required(),
						  }
						: {}),
				})
				.nullable();
		} else if (item.type === "object" && !options?.canBeHidden) {
			// ATTNETION: validation is currently not supported for object shown inside of hidden selector components
			fieldYup = fieldYup = yup.object().shape({
				valid: yup.boolean().oneOf([true], "Some fields have invalid values"),
			});
		}

		if (item.required) {
			if (item.type === "checkbox") {
				fieldYup = yup
					.boolean()
					.oneOf([true], i18next.t(`forms:Must check`) + " " + (item.labelShort ?? item.label))
					.defined();
			} else if (item.type === "upload") {
				fieldYup = yup.array().min(1, item.label + " is required");
			} else if (item.type === "autocomplete") {
				const errorMessage = item.label + " " + i18next.t(`forms:is required`);
				if (!options?.pattern && !options?.object) {
					dataKey += "__flag";
					fieldYup = yup.boolean().oneOf([true], errorMessage).defined();
				} else {
					// If autocomplete is part of nested form there is not need to check __flag field,
					// pattern already has final value, __flag field was created to improvve performance
					// but will be remvoed in the future
					fieldYup = yup.string().required(errorMessage);
				}
			} else if (item.type === "number") {
				fieldYup = yup
					.string()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required(item.label + " " + i18next.t(`forms:field is required`));
			} else {
				if (!fieldYup) fieldYup = yup.string();
				fieldYup = fieldYup.required(item.label + " " + i18next.t(`forms:field is required`));
			}
		}

		if (item.minCharacter || item.minCharacter === 0) {
			if (!fieldYup) fieldYup = yup.string();
			if (item.type === "number") {
				fieldYup = fieldYup.min(
					item.minCharacter,
					`${i18next.t("forms:The number is very short")} (${item.minCharacter} ${i18next.t("forms:digits min")})`
				);
			} else {
				fieldYup = fieldYup.min(
					item.minCharacter,
					`${i18next.t("forms:The text is very short")} (${item.minCharacter} ${i18next.t("forms:chars min")})`
				);
			}
		}

		if (item.maxCharacter) {
			if (!fieldYup) fieldYup = yup.string();
			fieldYup = fieldYup.max(
				item.maxCharacter,
				`${i18next.t("forms:The text is very long")} (${item.maxCharacter} ${i18next.t("forms:chars max")})`
			);
		}

		if (item.min || item.min === 0) {
			let itemMin = formatRangeError(item, item.min);
			if (!fieldYup) fieldYup = yup.string();
			fieldYup = fieldYup.test(
				"min-number",
				`${i18next.t(`forms:${item.errorMessage?.min ?? "Value must be greater than or equal to"}`)} ${itemMin}`,
				(v) => {
					if (isNaN(v) || v === "") return true;
					else return parseFloat(v) >= item.min;
				}
			);
		}

		if (item.max) {
			let itemMax = formatRangeError(item, item.max);
			if (!fieldYup) fieldYup = yup.string();
			fieldYup = fieldYup.test(
				"max-value",
				`${i18next.t(`forms:${item.errorMessage?.max ?? "Value must be less than or equal to"}`)} ${itemMax}`,
				(v) => {
					if (isNaN(v) || v === "") return true;
					else return parseFloat(v) <= item.max;
				}
			);
		}

		// Extra validators for string type
		// Assumes yup.string() has been chained!
		fieldYup = chainValidators(fieldYup, item);

		if (fieldYup) {
			if (clinic) {
				let key = clinic.key;
				clinic.then = fieldYup;
				if (fieldYup.type === "boolean") {
					clinic.otherwise = yup.boolean().notRequired();
					shape[dataKey] = yup.boolean().when(key, clinic);
				} else if (fieldYup.type === "array") {
					clinic.otherwise = yup.array().notRequired();
					shape[dataKey] = yup.array().when(key, clinic);
				} else {
					clinic.otherwise = yup.string().nullable().notRequired();
					shape[dataKey] = yup.string().when(key, clinic);
				}
			} else {
				shape[dataKey] = fieldYup;
			}
		}
	});
	return shape;
};

const chainValidators = (fieldYup, item) => {
	item?.validators?.forEach((validator) => {
		const chainedYup = validatorDictionary[validator](fieldYup, item);
		fieldYup = chainedYup;
	});

	return fieldYup;
};

const filterContentItems = (filters, input, result) => {
	if (!result) {
		result = [];
	}

	if (input?.length) {
		input.forEach((item) => {
			if (item?.content?.items) {
				filterContentItems(filters, item.content.items, result);
			} else {
				filterContentItems(filters, item, result);
			}
		});
	} else if (filters?.keys?.includes(input?.key)) {
		result.push(input);
	}

	return result;
};

const toggleIterator = (items, curToggles, isViewMode) => {
	let toggles = {};
	items?.forEach((item) => {
		if (!item || !item.type) return;
		if (item.type === "tabs") {
			const tabItems = [];
			item?.tabs?.forEach((tab) => tabItems.push(...(tab?.content?.items ?? [])));
			const moreToggles = toggleIterator(tabItems);
			toggles = { ...toggles, ...moreToggles };
		} else if (item.type === "group" || item.type === "menu") {
			const moreToggles = toggleIterator(item?.content?.items);
			toggles = { ...toggles, ...moreToggles };
		} else if (item.type === "toggle") {
			toggles[item.key] =
				curToggles && curToggles[item.key]
					? curToggles[item.key]
					: // Differnt default state for view mode if present
					isViewMode && item.toggle?.hasOwnProperty("viewModeDefaultVisible")
					? item.toggle?.viewModeDefaultVisible
					: item.toggle?.defaultVisible;
			const moreToggles = toggleIterator(item?.content?.items);
			toggles = { ...toggles, ...moreToggles };
		}
	});

	return toggles;
};

function SmarthopFormView(props) {
	const onCustom = props.onCustom;
	const onSubmit = props.onSubmit;
	const onCancel = props.onCancel;
	const onDelete = props.onDelete;
	const data = props.data;
	const mode = props.mode;
	const schemaOverride = props.schema;
	const dataIds = props.dataIds;
	const content = props.content;
	const loading = props.loading;
	const processing = props.processing;
	const loadingError = props.loadingError;
	const styleOverrides = props.overrides;
	const nativeMobile = props.nativeMobile;
	const isCreateMode = mode === "CREATE";
	const isViewMode = mode === "VIEW" || mode === "COMPARE";
	const formKey = props.Key ?? "form";
	const renderPanel = props.renderPanel;
	const nestedForm = props.nestedForm;
	const historyView = dataIds?.historyView;
	const carrierId = dataIds?.carrierId;
	const userTier = getUserTier();

	const languageId = useSelector(({ i18n }) => i18n.language);

	// disabling init validation for specific fields would allow to avoid
	// highlighting those fields red when value mandatory value is not defined or empty
	const noInitValidation =
		props.noInitValidation || content?.noInitValidation || content?.form?.noInitValidation || isViewMode;

	// original data is used to show indication for changed fields in edit mode,
	// key of a field should be present in tha object even if key is empty,
	// otherwise change indication will be ignorred
	const originalData = props.originalData;

	// allows to subscribe for changes in the form
	const onErrors = props.onErrors;
	const onBlurField = props.onBlurField;
	const onReInitialized = props.onReInitialized;
	const onChangeCommitted = props.onChangeCommitted;
	const trackChangedFields = props.trackChangedFields;

	// Prevent dialog close if changes made
	const dispatch = useDispatch();

	const preventCloseIfFieldsDirty = (dirtyFields) => {
		const preventClose = content?.form?.preventClose;
		if (preventClose?.enable && !_.isEmpty(dirtyFields)) {
			dispatch(
				setPreventDialogCloseCallback(() => {
					setOpenDialog({
						message:
							preventClose?.message ??
							"You have unsaved changes. Are you sure you want to ingore changes and close this form?",
						closeMsg: preventClose?.closeMsg ?? "Keep Editing",
						acceptMsg: preventClose?.acceptMsg ?? "Ignore And Close",
					});
					setEventDialog("CONFIRMCLOSE");
				})
			);
		} else {
			dispatch(setPreventDialogCloseCallback(null));
		}
	};

	const onConfirmClose = () => {
		dispatch(closeFormDialog());
		preventCloseIfFieldsDirty(null);
	};

	useEffect(() => {
		preventCloseIfFieldsDirty(null);
		// eslint-disable-next-line
	}, []);

	const contentItems = useMemo(() => {
		const items = [
			...(content?.items ?? []),
			...(content?.top?.items ?? []),
			...(content?.left?.items ?? []),
			...(content?.right?.items ?? []),
			...(content?.bottom?.items ?? []),
		];

		const validateRecursive = (obj) => {
			for (var key in obj) {
				if (key === "key" && obj[key]?.includes?.(".")) {
					console.warn("[SmarthopFromView] form contain invalid key format, (includes '.')", obj[key]);
				} else if (["showItems", "items", "options", "content"].includes(key)) {
					validateRecursive(obj[key]);
				} else if (Array.isArray(obj[key])) {
					for (let item of obj[key]) {
						validateRecursive(item);
					}
				}
			}
		};

		validateRecursive(items);
		return items;
	}, [content]);

	const classes = useStyles();

	const initilDataRef = useRef(props.data ?? {});
	const initiFieldsKeysRef = useRef(null);

	const [openDialog, setOpenDialog] = useState(null);
	const [modelData, setModelData] = useState(null);
	const [eventDialog, setEventDialog] = useState(null);
	const [customTypeDialog, setCustomTypeDialog] = useState(null);
	const [toggles, setToggles] = useState({});
	const [openTabs, setOpenTabs] = useState({});
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);

	useEffect(() => {
		setToggles(toggleIterator(content?.items, toggles, isViewMode));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content]);

	const askBeforeSend = (message, closeMsg, acceptMsg, typedConfirmation, event, customType) => {
		setOpenDialog({ message, closeMsg, acceptMsg, typedConfirmation });
		setEventDialog(event);
		customType && setCustomTypeDialog(customType);
	};

	const handleAccept = () => {
		setOpenDialog(null);
		modelData ? onSubmitDirty() : callCorrectEvent();
	};

	const callCorrectEvent = () => {
		const action = eventDialog;
		const custonType = customTypeDialog;
		if (action === "DELETE") {
			const values = getValues();
			onDelete(values);
		} else if (action === "CANCEL") {
			onCancel();
		} else if (action === "CONFIRMCLOSE") {
			onConfirmClose();
		} else if ("CUSTOM") {
			onCustom && onCustom(custonType);
		}
		return null;
	};

	const handleDialogClose = () => {
		setOpenDialog(null);
		modelData && setModelData(null);
	};

	// Refresh error

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			trigger(Object.keys(errors));
		}
		// eslint-disable-next-line
	}, [languageId]);

	// Building default values
	const defaultValues = useMemo(() => defaultValuesIterator(contentItems, data), [contentItems, data]);

	// Loading fields rules
	const rules = useMemo(() => rulesIterator(contentItems, data), [contentItems, data]);

	const [rulesOverrides, setRulesOverrides] = useState(
		useMemo(() => createRulesOverrides(rules, data ?? defaultValues), [rules, data, defaultValues])
	);

	// Loading fields labels
	const labels = useMemo(() => labelIterator(contentItems), [contentItems]);

	const [labelOverrides, setLabelOverrides] = useState(
		// Labels created using default values object to keep it consistend with logic in "commit dyrty" method
		useMemo(
			() => createLabelsOverrides(labels, postprocessData(contentItems, _.cloneDeep(defaultValues)), dispatch, dataIds),
			[contentItems, labels, defaultValues, dispatch, dataIds]
		)
	);

	// Building yup fields validation policies
	const schema = useMemo(
		() => schemaOverride ?? yup.object().shape(schemaIterator(contentItems)),
		// eslint-disable-next-line
		[contentItems, languageId, rulesOverrides]
	);

	// Building form
	const { control, formState, handleSubmit, setError, getValues, setValue, trigger, reset, clearErrors } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});
	const { isValid, dirtyFields, errors } = formState;

	// Trigger value update for uploaded data or new fetched data
	// Happens if form responsible for object fetch, this is not happening
	// if data is fetched by dialog slice before showing the form

	useEffect(() => {
		if (onErrors) {
			const errorsMessages = {};
			Object.keys(errors).forEach((key) => {
				errorsMessages[key] = errors.message;
			});
			onErrors(errorsMessages);
		}
		// eslint-disable-next-line
	}, [errors, isValid]);

	useEffect(() => {
		if (nestedForm || !schema) return;

		const currentFieldsKeys = Object.keys(schema.fields);

		if (!initiFieldsKeysRef.current) {
			initiFieldsKeysRef.current = currentFieldsKeys;
		} else if (!_.isEqual(currentFieldsKeys, initiFieldsKeysRef.current)) {
			const wasCompleted = isValid && !_.isEmpty(dirtyFields);
			initiFieldsKeysRef.current = currentFieldsKeys;
			// WORKAROUND: Timeout is required to trigger validation after all data is updated in form
			setTimeout(() => {
				const values = getValues();
				const newCompleted = schema.isValidSync(values);

				const modelProcessed = postprocessData(contentItems, values);
				const newLabelOverrides = createLabelsOverrides(labels, modelProcessed, dispatch, dataIds);
				setLabelOverrides(newLabelOverrides);

				if (wasCompleted !== newCompleted) {
					if (newCompleted) {
						// If number of fieds decreased we need to trigger validation becuase fields that are left
						// could all have valid values and it would allow to enable 'action' button
						trigger();
					} else {
						// If number of fieds increased and 'actioin' button is enabled we need to reset form status
						// to disable 'action' button, on any new input it would be re-enabled if all data is valid
						reset({}, { keepValues: true });
					}
				}
			}, 100);
		}
		// eslint-disable-next-line
	}, [content, schema]);

	const [initialized, setInitialized] = useState(false);
	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
			const values = getValues();
			const modelCopy = postprocessData(contentItems, values);
			onReInitialized?.(modelCopy);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!data || JSON.stringify(initilDataRef.current) === JSON.stringify(data)) {
			return;
		}

		initilDataRef.current = data;
		setRulesOverrides(createRulesOverrides(rules, data));

		if (isViewMode) {
			return;
		}

		const valueHash = (value) => {
			let preHashValue;
			if (value?.items?.map) {
				preHashValue = value?.items?.map((item) => {
					const copy = {};
					Object.keys(item).forEach((key) => {
						// Removing form internal values before comparison
						if (key.startsWith("__")) return;
						copy[key] = item[key];
					});
					return copy;
				});
			} else {
				preHashValue = value;
			}

			// TODO add more efficient hash fuction
			return JSON.stringify(preHashValue);
		};

		const newValues = defaultValuesIterator(contentItems, data);
		const updatedKeys = [];
		const oldValues = getValues();
		for (const [key, newValue] of Object.entries(newValues)) {
			// WORKAROUND: creating hash to compare previous and new version of data,
			// that would improve re-rendering performance of the form, hashing logic can be improved
			const newValueHash = !newValue ? "NONE" : valueHash(newValue);
			const oldValueHash = !oldValues[key] ? "NONE" : valueHash(oldValues[key]);
			if (newValueHash !== oldValueHash) {
				setValue(key, newValue, { shouldValidate: !noInitValidation, shouldDirty: true });
				updatedKeys.push(key);
			}
		}

		if (updatedKeys.length === 0) {
			return;
		}

		const modelProcessed = postprocessData(contentItems, newValues);

		if (!_.isEmpty(labels)) {
			const newLabelOverrides = createLabelsOverrides(labels, modelProcessed, dispatch, dataIds);
			setLabelOverrides(newLabelOverrides);
		}

		if (onReInitialized) {
			onReInitialized(modelProcessed);
		}
		// eslint-disable-next-line
	}, [data]);

	// Trigger value validation for pre-downloaded data that is required for
	// migration purposes, some old saved information might have been saved
	// incorrectly, forcing user to update the data before the save

	useEffect(() => {
		if (!loading && !isCreateMode && !noInitValidation) {
			trigger();
		}
		// eslint-disable-next-line
	}, [trigger]);

	// Updating error from the response
	useEffect(() => {
		clearErrors();
		if (props?.errors?.forEach) {
			props?.errors?.forEach((error) => {
				setError(error.type, {
					type: "manual",
					message: i18next.t(`forms:${error.message}`),
					code: i18next.t(`forms:${error.code}`),
					metadata: error.metadata,
				});
			});
		}
	}, [props?.errors, setError, languageId, clearErrors]);

	const onSubmitAskDirty = (model) => {
		const action = contentItems?.find((value) => value?.type === "action");
		if (!!action?.disableAtClick) {
			setDisableSubmitButton(true);
		}
		const confirmation = action?.confirmation;
		const enable =
			typeof confirmation?.enable === "function" ? confirmation?.enable(getValues()) : confirmation?.enable;
		if (confirmation && enable) {
			setOpenDialog({
				message: confirmation.message,
				closeMsg: confirmation.closeMsg,
				acceptMsg: confirmation.acceptMsg,
				typedConfirmation: confirmation.typedConfirmation,
			});
			setModelData(model);
		} else {
			onSubmitDirty(model);
		}
	};

	const onSubmitDirty = (modelD) => {
		let model = modelData ? modelData : modelD;
		let modelCopy = postprocessData(contentItems, model);

		if (onSubmit) onSubmit(modelCopy);
		modelData && setModelData(null);
		preventCloseIfFieldsDirty(null);
	};

	const onBlur = (keyName) => {
		const values = getValues();
		if (onBlurField) onBlurField(keyName, postprocessData(contentItems, values));
	};

	const onChangeCommittedDirty = (key, value, options) => {
		const values = getValues();

		let originalKey = key.replace("__view", "").replace("__flag", "");
		if (!key.includes("__flag")) {
			if (rules[originalKey]) {
				let newOverride = updatedOverride(rulesOverrides, rules, originalKey, value, setValue, values);
				setRulesOverrides(newOverride);
			}
		}

		preventCloseIfFieldsDirty(dirtyFields);

		// Continue only if there are tackable fields, logic that follows trackable fileds
		// is usually quick slow so we need to make sure we do not abuse that and tack only required fields
		const notTrackingChange =
			!trackChangedFields || (!trackChangedFields.includes(originalKey) && !trackChangedFields.includes("ALL"));
		if (notTrackingChange) {
			return;
		}
		const processedData = postprocessData(contentItems, values);

		// Updating dynamic labeles if some present, labels will be updated only on changes of trackable fields
		if (!_.isEmpty(labels)) {
			const newLabelOverrides = createLabelsOverrides(labels, processedData, dispatch, dataIds);
			if (!_.isEqual(newLabelOverrides, labelOverrides)) {
				setLabelOverrides(newLabelOverrides);
			}
		}

		// Collecting validation issues
		let valid = false;
		let validationError = null;
		try {
			schema.validateSync(values);
			valid = true;
		} catch (error) {
			validationError = error.message;
		}

		const visibleErrors = {};
		Object.keys(errors ?? {}).forEach((key) => {
			visibleErrors[key] = errors[key].message;
		});
		const status = { valid, errors: { validation: validationError, visible: visibleErrors } };

		// Notifying regardig changes if there is a listener
		onChangeCommitted?.(processedData, originalKey, status, options, dirtyFields);
	};

	// Building form fields views

	const labelBuilder = (label, data) => {
		return typeof label === "function" ? label(data) : label ? i18next.t(`forms:${label}`) : label;
	};

	const renderers = {
		captcha: (viewKey, config) => {
			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<SmarthopCaptchaField
					key={formKey + "_" + (config.key ?? viewKey)}
					control={control}
					errors={errors}
					type={config.type}
					name={config.key}
					label={
						labelOverrides[config.key]?.label ?? (config.label ? i18next.t(`forms:${config.label}`) : config.label)
					}
					required={config.required}
					disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
					onReset={() => {
						setValue(config.key, "", { shouldDirty: true });
					}}
				/>
			);
		},
		message: (viewKey, config) => {
			return (
				<Typography
					key={viewKey}
					color="primary"
					className={
						"pt-6 pb-6 text-12 ml:text-13 font-normal w-full mb-6 pl-6 text-primary " +
						(config?.message?.classes?.root ?? "")
					}
				>
					{labelOverrides[config.key]?.label ??
						(config.builder
							? config.builder(data, config.viewOnly ? "VIEW" : mode, dispatch)
							: config.label
							? i18next.t(`forms:${config.label}`)
							: config.label)}
				</Typography>
			);
		},
		banner: (viewKey, config) => {
			let banner = config.banner && typeof config.banner === "function" ? config.banner(data, dataIds) : config.banner;
			let defaultRootBg = banner?.classes?.root?.includes("bg-") ? "" : "bg-blue-100";

			return (
				<div
					key={viewKey}
					className={clsx(
						"flex flex-row w-full mt-2 mb-20 min-h-60 items-center rounded-lg py-10 px-14",
						defaultRootBg,
						banner?.classes?.root ?? ""
					)}
				>
					{banner?.icon && (
						<Icon className={"text-28 mr-10 " + (banner?.classes?.icon ?? "")} color="action">
							{banner.icon}
						</Icon>
					)}
					<Typography className={"flex-1 text-13 " + (banner?.classes?.message ?? "")}>
						{labelOverrides[config.key]?.label ??
							(config.builder
								? config.builder(data, config.viewOnly ? "VIEW" : mode, dispatch)
								: config.label
								? i18next.t(`forms:${config.label}`)
								: config.label)}
					</Typography>
					{banner?.action?.onClick && mode !== "VIEW" && !config.viewOnly && (
						<Button
							variant="contained"
							color="primary"
							className={"ml-10 " + (banner?.classes?.action ?? "")}
							onClick={() => {
								const result = banner?.action?.onClick?.(dispatch, dataIds, data);
								if (result?.overrides) {
									let overrideKeys = Object.keys(result?.overrides);
									let overrideItems = filterContentItems({ keys: overrideKeys }, contentItems);
									let overrideData = defaultValuesIterator(overrideItems, result?.overrides);
									for (const [key, value] of Object.entries(result?.overrides)) {
										// In case we need to reset value to null we need to add that
										// null to override data separately
										if (!overrideData[key]) {
											overrideData[key] = value;
										}
									}
									for (const [key, value] of Object.entries(overrideData)) {
										setValue(key, value, { shouldValidate: true, shouldDirty: true });
										onChangeCommittedDirty(key, value);
									}
								}
							}}
						>
							{labelOverrides[config.key]?.label ?? banner?.action?.label ?? "Action"}
						</Button>
					)}
				</div>
			);
		},
		image: (viewKey, config) => {
			return (
				<SmarthopImageField
					key={formKey + "_" + (config.key ?? viewKey)}
					classes={config.image?.classes}
					items={config.items}
					label={
						labelOverrides[config.key]?.label ?? (config.label ? i18next.t(`forms:${config.label}`) : config.label)
					}
				/>
			);
		},
		text: (viewKey, config) => {
			if (mode === "VIEW" || config.viewOnly || mode === "COMPARE") {
				return renderers.view(viewKey, config);
			}

			let options = config?.options?.forEach ? [...config.options] : [];
			options = options.map((option) => {
				if (option.label) {
					return { ...option, label: i18next.t(`forms:${option.label}`) };
				}
				return option;
			});

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<Controller
					name={config.key}
					control={control}
					render={({ field }) => {
						return (
							<SmarthopTextField
								fieldObject={field}
								key={formKey + "_" + (config.key ?? viewKey)}
								control={control}
								errors={errors}
								type={config.type}
								name={config.key}
								label={
									labelOverrides[config.key]?.label ??
									(config.label ? i18next.t(`forms:${config.label}`) : config.label)
								}
								required={config.required || override?.required}
								disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
								orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
								icon={config.icon}
								resource={config.resource}
								description={labelOverrides[config.key]?.description ?? config.description}
								options={options}
								multiline={config.multiline}
								preventCharacter={config.preventCharacter}
								// TODO rename field to component
								field={config.component ?? config.field}
								onBlur={onBlur}
								onChangeCommitted={(key, value) => {
									if (config.type === "select") {
										// WORKAROUND, in some cases form is not being re-drawn when selec value is changes
										// we need to force re-draw to show hidden elements, we may need to rework that solution
										// to use "rules" and "refs" in config
										trigger([config.key]);
									}
									onChangeCommittedDirty(key, value);
								}}
							/>
						);
					}}
				/>
			);
		},
		// TODO remove types currency, percentage and zipcode, use number instead with params like "mask"
		phone: (viewKey, config) => renderers.text(viewKey, config),
		email: (viewKey, config) => renderers.text(viewKey, config),
		password: (viewKey, config) => renderers.text(viewKey, config),
		passwordConfirm: (viewKey, config) => renderers.text(viewKey, config),
		"datetime-local": (viewKey, config) => renderers.text(viewKey, config),
		number: (viewKey, config) => {
			if (mode === "VIEW" || config.viewOnly || mode === "COMPARE") {
				return renderers.view(viewKey, config);
			}

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<Controller
					name={config.key}
					control={control}
					render={({ field }) => {
						return (
							<SmarthopNumberField
								key={formKey + "_" + (config.key ?? viewKey)}
								InputProps={config.InputProps}
								className={config.className}
								value={field.value}
								errors={errors}
								type={config.type}
								name={config.key}
								label={
									labelOverrides[config.key]?.label ??
									(config.label ? i18next.t(`forms:${config.label}`) : config.label)
								}
								required={config.required}
								disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
								orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
								icon={config.icon}
								description={labelOverrides[config.key]?.description ?? config.description}
								resource={config.resource}
								// TODO rename field to component
								field={config.component ?? config.field}
								onBlur={onBlur}
								onChange={(value) => field.onChange(value)}
								onChangeCommitted={onChangeCommittedDirty}
							/>
						);
					}}
				/>
			);
		},
		// TODO remove types currency, percentage and zipcode, use number instead with params like "mask"
		currency: (viewKey, config) => renderers.number(viewKey, config),
		percentage: (viewKey, config) => renderers.number(viewKey, config),
		zipcode: (viewKey, config) => renderers.number(viewKey, config),
		picker: (viewKey, config) => {
			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<Controller
					name={config.key}
					control={control}
					render={({ field }) => {
						return (
							<SmarthopPickerField
								key={formKey + "_" + (config.key ?? viewKey)}
								fieldObject={field}
								control={control}
								errors={errors}
								type={config.type}
								name={config.key}
								label={
									labelOverrides[config.key]?.label ??
									(config.label ? i18next.t(`forms:${config.label}`) : config.label)
								}
								required={config.required}
								disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
								orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
								// TODO need to merge all styles into a single field
								field={config.component ?? config.field} // DEPRECATED
								input={config.component ?? config.input} // DEPRECATED
								// TODO rename to component
								picker={config.component ?? config.picker}
								onBlur={onBlur}
								onChangeCommitted={onChangeCommittedDirty}
							/>
						);
					}}
				/>
			);
		},
		slider: (viewKey, config) => {
			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<Controller
					name={config.key}
					control={control}
					render={({ field }) => {
						return (
							<SmarthopSliderField
								key={formKey + "_" + (config.key ?? viewKey)}
								control={control}
								errors={errors}
								type={config.type}
								name={config.key}
								label={
									labelOverrides[config.key]?.label ??
									(config.label ? i18next.t(`forms:${config.label}`) : config.label)
								}
								value={field.value}
								required={config.required}
								disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
								orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
								// TODO rename to component
								slider={config.component ?? config.slider}
								onChangeCommitted={onChangeCommittedDirty}
								onChange={field.onChange}
							/>
						);
					}}
				/>
			);
		},
		select: (viewKey, config, loptions) => {
			let optionViews = [];
			if (!isViewMode) {
				config.options?.forEach((option, index) => {
					let selectedValue = getValues(config.key);
					if (option.value !== selectedValue) {
						// If option is not selected, we should not render/display it
						return;
					}
					if (!option?.showItems) {
						return;
					}
					let key = viewKey + "_value_" + selectedValue + "_select_" + index;
					optionViews.push(
						<div
							key={key}
							className={
								config?.select?.classes?.root ??
								(config.select?.alignment === "horizontal"
									? " flex flex-col md:flex-row items-center w-full "
									: "flex flex-col items-center w-full ")
							}
						>
							{!historyView && itemsIterator(key, option?.showItems, null, (loptions?.level ?? 0) + 1)}
						</div>
					);
				});
			}
			let content;
			if (isViewMode || config.viewOnly) {
				content = renderers.view(viewKey, config);
			} else {
				content = renderers.text(viewKey, config);
			}

			return (
				<div
					key={viewKey}
					className={
						config?.select?.classes?.root ??
						(config.select?.alignment === "horizontal"
							? " flex flex-col md:flex-row items-center w-full "
							: " flex flex-col items-center w-full")
					}
				>
					<div
						key={viewKey}
						className={
							" flex w-full " + (config.select?.alignment === "horizontal" ? "flex-col md:flex-row" : "flex-col")
						}
					>
						{content}
					</div>
					{optionViews}
				</div>
			);
		},
		radio: (viewKey, config, loptions) => {
			let optionViews = config.options?.map((option, index) => {
				let selectedValue = getValues(config.key);
				let isSelected = option.value === selectedValue;
				let key = viewKey + "_value_" + selectedValue + "_select_" + index;
				return (
					<div
						key={key}
						className={
							(isSelected ? "" : " hidden ") +
							(config.radio?.alignment === "horizontal"
								? " flex flex-col md:flex-row items-center w-full "
								: " flex flex-col items-center w-full ")
						}
					>
						{itemsIterator(key, option?.showItems, null, (loptions?.level ?? 0) + 1)}
					</div>
				);
			});

			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}

			let options = config?.options?.forEach ? [...config.options] : [];
			options = options.map((option) => {
				if (option.label) {
					return { ...option, label: i18next.t(`forms:${option.label}`) };
				}
				return option;
			});

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<div key={viewKey} className="flex flex-col items-center w-full">
					<div key={viewKey} className={"flex w-full"}>
						<SmarthopRadioButtonField
							key={formKey + "_" + (config.key ?? viewKey)}
							control={control}
							errors={errors}
							name={config.key}
							label={
								labelOverrides[config.key]?.label ??
								(config.label && (typeof config.label === "string" || config.label instanceof String)
									? i18next.t(`forms:${config.label}`)
									: config.label)
							}
							options={options}
							required={config.required}
							disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
							orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
							// TODO rename to component
							radio={config.component ?? config.radio}
							onChangeCommitted={onChangeCommittedDirty}
						/>
					</div>
					{optionViews}
				</div>
			);
		},
		checkbox: (viewKey, config) => {
			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<Controller
					name={config.key}
					control={control}
					render={({ field }) => {
						return (
							<SmarthopCheckboxField
								key={formKey + "_" + (config.key ?? viewKey)}
								value={field.value}
								control={control}
								errors={errors}
								name={config.key}
								label={
									labelOverrides[config.key]?.label ??
									(config.label && (typeof config.label === "string" || config.label instanceof String)
										? i18next.t(`forms:${config.label}`)
										: config.label && typeof config.label === "function"
										? config.label(getValues(), dispatch)
										: config.label)
								}
								required={config.required}
								disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
								orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
								// TODO rename to component
								checkbox={config.component ?? config.checkbox}
								description={labelOverrides[config.key]?.description ?? config.description}
								onChangeCommitted={onChangeCommittedDirty}
								onChange={field.onChange}
							/>
						);
					}}
				/>
			);
		},
		upload: (viewKey, config) => {
			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<Controller
					key={formKey + "_" + (config.key ?? viewKey)}
					name={config.key}
					control={control}
					render={({ field }) => {
						return (
							<SmarthopUploadField
								innerRef={field.ref}
								value={field.value}
								error={errors[config.key]}
								label={
									labelOverrides[config.key]?.label ??
									(config.label ? i18next.t(`forms:${config.label}`) : config.label)
								}
								required={config.required}
								disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
								orignialValue={originalData ? originalData[config.key] ?? defaultValues[config.key] ?? "" : null}
								// TODO rename to component
								upload={config.component ?? config.upload}
								dataIds={dataIds}
								nativeMobile={nativeMobile}
								onChange={(fileId, options) => {
									field.onChange(fileId);
									onChangeCommittedDirty(config.key, fileId, options);
								}}
							/>
						);
					}}
				/>
			);
		},
		group: (viewKey, config, loptions) => {
			const component = config.component ?? config.group;
			return (
				<div
					key={viewKey}
					className={clsx(
						"flex w-full",
						component?.classes?.root?.includes("items-") ? "" : "items-center",
						component?.variant === "vertical"
							? "flex-col"
							: component?.classes?.direction ?? (isViewMode ? "flex-row" : "flex-col md:flex-row"),
						component?.classes?.root ?? ""
					)}
				>
					{itemsIterator(viewKey + "_group", config?.content?.items, component?.classes, (loptions?.level ?? 0) + 1)}
				</div>
			);
		},
		menu: (viewKey, config, loptions) => {
			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<SmarthopMenuContainer
					key={formKey + "_" + (config.key ?? viewKey)}
					label={
						labelOverrides[config.key]?.label ?? (config.label ? i18next.t(`forms:${config.label}`) : config.label)
					}
					// TODO move all component specific configs into component field
					badge={config.badge} // DEPRECATED should be meived into menu
					icon={config.icon} // DEPRECATED should be meived into menu
					button={config.button} // DEPRECATED should be meived into menu
					// TODO rename to component
					menu={config.component ?? config.menu}
					disabled={config.disabled || override?.disabled}
					groups={config?.groups ?? []}
					actions={config?.actions ?? []}
				>
					{config?.groups?.length
						? config.groups?.map((group, index) => {
								return itemsIterator(viewKey + "_menu" + index, group, null, (loptions?.level ?? 0) + 1);
						  })
						: itemsIterator(viewKey + "_menu", config?.content?.items, null, (loptions?.level ?? 0) + 1)}
				</SmarthopMenuContainer>
			);
		},
		autocomplete: (viewKey, config, loptions) => {
			let optionViews = config.options?.map((hiddenSection, index) => {
				if (!hiddenSection?.showItems?.length || !hiddenSection?.values?.length) {
					return null;
				}

				let selectedValue = getValues(config.key + "__view");
				let findMatch = selectedValue?.options?.find((selectedOption) =>
					hiddenSection.values.includes(selectedOption.value)
				);
				if (!findMatch) {
					// If option is not selected, we should not render/display it
					return null;
				}
				let key = viewKey + "_value_" + findMatch.value + "_select_" + index;
				return (
					<div key={key} className="flex flex-col items-center w-full">
						{itemsIterator(key, hiddenSection?.showItems, null, (loptions?.level ?? 0) + 1)}
					</div>
				);
			});

			let content;
			if (isViewMode || config.viewOnly) {
				content = renderers.view(viewKey, config);
			} else {
				let override = rulesOverrides[config.key];
				if (override?.hidden) return null;

				content = (
					<Controller
						key={formKey + "_" + (config.key ?? viewKey)}
						name={config.key + "__view"}
						control={control}
						render={({ field }) => {
							return (
								<SmarthopAutocompleteField
									formData={data}
									description={labelOverrides[config.key]?.description ?? config.description}
									warning={labelOverrides[config.key]?.warning ?? config.warning}
									innerRef={field.ref}
									name={field.name}
									variant={config.variant}
									optionsValue={field?.value?.options ?? []}
									inputValue={field?.value?.input ?? ""}
									error={errors[config.key + "__flag"] ?? errors[config.key]}
									label={
										labelOverrides[config.key]?.label ??
										(config.label ? i18next.t(`forms:${config.label}`) : config.label)
									}
									required={config.required}
									disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled || override?.disabled}
									orignialValue={originalData ? originalData[config.key + "__view"] ?? {} : null}
									field={config.field}
									input={config.input}
									dataIds={dataIds}
									// TODO rename to component
									autocomplete={config.component ?? config.autocomplete}
									referenceParam={config?.rules?.params}
									referenceValue={
										config?.rules?.params?.paramKey && override?.paramsValue
											? override?.paramsValue[config?.rules?.params?.paramKey]
											: null
									}
									onChange={(optionsValues, inputValue) => {
										let newFlag = !!optionsValues.length;
										if (getValues(config.key + "__flag") !== newFlag) {
											setValue(config.key + "__flag", newFlag, { shouldValidate: true, shouldDirty: true });
										}
										field.onChange({ options: optionsValues, input: inputValue });
									}}
									onSelected={(optionsValues) => {
										onChangeCommittedDirty(config.key + "__view", optionsValues);

										if (!!config.options?.length) {
											// WORKAROUND, in some cases form needs to not be re-drawn when autocomplete field has
											// some hidden fields that can be shown based on the selected value
											trigger([config.key]);
										}
									}}
									onBlur={onBlur}
								/>
							);
						}}
					/>
				);
			}

			return (
				<div key={viewKey} className="flex flex-col items-center w-full">
					<div key={viewKey} className={"flex w-full"}>
						{content}
					</div>
					{optionViews}
				</div>
			);
		},
		action: (viewKey, config) => {
			if (isViewMode) {
				return null;
			}
			// TODO replace button param with component or action
			const component = config.component ?? config.button;
			// TODO move intp component subconfig
			const showConfirmation = config.confirmation?.enable;

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			const rootClasses = component?.classes?.root;

			const design = {
				// TODO move action const into action or component object
				color: component?.color ?? (config.action === "CANCEL" ? "default" : "secondary"),
				variant: component?.variant ?? "contained",
				classes: component?.classes ?? {},
				startIcon: component?.icon && <Icon>{component?.icon}</Icon>,
				className: clsx(
					!rootClasses?.includes("w-") ? "w-full" : "",
					!rootClasses?.includes("mb-") && !rootClasses?.includes("my-") && !rootClasses?.includes("mt-")
						? "my-6 ml:my-0"
						: "",
					component?.classes?.root ?? ""
				),
			};

			const text =
				labelOverrides[config.key]?.label ??
				(config.label
					? i18next.t(`forms:${config.label}`)
					: config.action === "DELETE"
					? "Delete"
					: config.action === "CANCEL"
					? "Cancel"
					: mode === "CREATE"
					? "Create"
					: mode === "EDIT"
					? "Save"
					: "Do");

			// WORKAROUD: need to show proper colors on mobile, color does not switching
			// properly when enabling and disabling a button, so always going to keep it white
			const icon = <Icon>{config.icon}</Icon>;
			const label = (
				<Typography
					className={"font-semibold text-13 md:text-12 tracking-wide " + (window.ismobile() ? " text-white " : "")}
				>
					{text}
				</Typography>
			);

			let buttonContent = config.icon ? (
				<>
					{(config.iconStart || (!config.iconStart && !config.iconEnd)) && icon}
					{config.iconWithLabel && label}
					{config.iconEnd && icon}
				</>
			) : (
				label
			);
			const defaultLoadingProps = { color: "inherit", size: "1.5em" };
			buttonContent = config.button?.loadingState ? (
				<div className="flex items-center justify-center px-2">
					<CircularProgress {...{ ...defaultLoadingProps, ...(component?.loadingProps ?? {}) }} />
				</div>
			) : (
				buttonContent
			);

			let disabled = !!config?.disabled || (!!config.disableAtClick && disableSubmitButton);
			if (!disabled) {
				if (component?.alwaysEnabled) {
					disabled = false;
				} else if (component?.alwaysEnabledIfValid) {
					disabled = (!isValid && !errors["generic"]) || override?.disabled;
				} else {
					disabled = ((_.isEmpty(dirtyFields) || !isValid) && !errors["generic"]) || override?.disabled;
				}
			}

			let button;
			if (config.action === "DELETE") {
				const onClick = () => {
					if (showConfirmation) {
						askBeforeSend(
							// TODO move confirmation into action or component param
							config.confirmation?.message,
							config.confirmation?.closeMsg,
							config.confirmation?.acceptMsg ?? "Delete",
							config.confirmation?.typedConfirmation,
							config.action
						);
					} else {
						const values = getValues();
						onDelete(values);
					}
				};
				button =
					component?.variant === "icon" ? (
						<IconButton {...design} onClick={onClick}>
							{buttonContent}
						</IconButton>
					) : (
						<Button {...design} onClick={onClick}>
							{buttonContent}
						</Button>
					);
			} else if (config.action === "CANCEL") {
				const onClick = () => {
					showConfirmation
						? askBeforeSend(
								// TODO move confirmation into action or component param
								config.confirmation?.message,
								config.confirmation?.closeMsg,
								config.confirmation?.acceptMsg,
								config.confirmation?.typedConfirmation,
								config.action
						  )
						: onCancel();
				};
				button =
					component?.variant === "icon" ? (
						<IconButton {...design} onClick={onClick}>
							{buttonContent}
						</IconButton>
					) : (
						<Button {...design} onClick={onClick}>
							{buttonContent}
						</Button>
					);
			} else if (config.action === "CUSTOM") {
				const onClick = () => {
					showConfirmation
						? askBeforeSend(
								// TODO move confirmation into action or component param
								config.confirmation?.message,
								config.confirmation?.closeMsg,
								config.confirmation?.acceptMsg,
								config.confirmation?.typedConfirmation,
								config.action,
								component?.customType
						  )
						: onCustom && onCustom(component?.customType, component?.customMetadata);
				};

				button =
					component?.variant === "icon" ? (
						<IconButton {...design} disabled={disabled} onClick={onClick}>
							{buttonContent}
						</IconButton>
					) : (
						<Button {...design} disabled={disabled} onClick={onClick}>
							{buttonContent}
						</Button>
					);
			} else {
				button =
					component?.variant === "icon" ? (
						<IconButton {...design} type="submit" disabled={disabled} value="legacy">
							{buttonContent}
						</IconButton>
					) : (
						<Button {...design} type="submit" disabled={disabled} value="legacy">
							{buttonContent}
						</Button>
					);
			}

			return (
				<div key={viewKey} className={clsx("flex flex-col mx-4 w-full")}>
					{button}
				</div>
			);
		},
		section: (viewKey, config, loptions) => {
			const section = config?.section ?? config.component;
			let defaultTracking = section?.classes?.label?.includes("tracking-") ? "" : "tracking-wide";
			let defaultText = section?.classes?.label?.includes("text-") ? "" : "text-14";
			let defaultFont = section?.classes?.label?.includes("font-") ? "" : "font-semibold";
			let defaultDividerMB = section?.classes?.divider?.includes("mb-") ? "" : "mb-4";

			let defaultRootMT = section?.classes?.root?.includes("mt-")
				? ""
				: loptions.level === 0 && loptions.index === 0
				? "-mt-2"
				: "mt-6 ml:mt-10";
			let defaultRootMB = section?.classes?.root?.includes("mb-") ? "" : config.viewOnly ? "mb-4" : "mb-10";
			let defaultRootMX = section?.classes?.root?.includes("mx-") ? "" : config.viewOnly ? "mx-6" : "mx-4";

			const getBtn = (action) => {
				return (
					<Button
						key={"actions_button_" + action.label}
						className={`text-12 py-0 -mb-1 ml-4 ${action.className}`}
						variant={action.variant ?? "contained"}
						color={action.color ?? "secondary"}
						onClick={action.onClick}
						size={"small"}
					>
						{action.icon ? <Icon className="text-15 ml:text-16">{action.icon}</Icon> : action.label}
						{action.description && <Icon className="text-12 ml-4 -mr-4">info</Icon>}
					</Button>
				);
			};

			return (
				<div
					key={viewKey}
					className={clsx("flex flex-col w-full ", defaultRootMT, defaultRootMB, defaultRootMX, section?.classes?.root)}
				>
					<div className="flex flex-row w-full items-center py-4 ml:py-2">
						{section?.icon && <Icon className="mr-8 text-24 text-grey-600">{section?.icon}</Icon>}
						<Typography
							color={section?.color ?? undefined}
							variant={section?.variant ?? "body2"}
							className={clsx(defaultTracking, defaultText, defaultFont, section?.classes?.label ?? "")}
						>
							{labelOverrides[config.key]?.label ?? labelBuilder(config.label, data)}
						</Typography>
						{config.actions && (
							<div className="flex flex-1 flex-row -mt-8 item-end justify-end">
								{config.actions.map((action, index) =>
									action.description ? (
										<TooltipStyle
											index={action.description + "_" + index}
											title={<Typography className="text-12 max-w-230">{action.description}</Typography>}
										>
											{getBtn(action)}
										</TooltipStyle>
									) : (
										getBtn(action)
									)
								)}
							</div>
						)}
					</div>
					{!section?.noDivider && <Divider className={clsx(defaultDividerMB, section?.classes?.divider ?? "")} />}
				</div>
			);
		},
		divider: (viewKey, config) => {
			return <Divider className={clsx(" w-full mt-10 mb-6 ", config?.divider?.classes?.root ?? "")} />;
		},
		pattern: (viewKey, config) => {
			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}
			return (
				<SmarthopPatternField
					key={formKey + "_" + (config.key ?? viewKey)}
					control={control}
					dataIds={dataIds}
					errors={errors}
					name={config.key}
					addButton={config.addButton}
					header={config.header}
					label={
						labelOverrides[config.key]?.label ?? (config.label ? i18next.t(`forms:${config.label}`) : config.label)
					}
					content={config.content}
					pattern={config.component ?? config.pattern}
					noInitValidation={noInitValidation}
					orignialValue={originalData ? originalData[config.key] ?? {} : null}
					styleOverrides={styleOverrides}
					onChange={(value, options) => {
						onChangeCommittedDirty(config.key, value, options);
					}}
					onBlur={onBlur}
					// WORKAROUND: needed to allow to set values without triggering validation when nested
					// form values are not valid but nested yup validation did not kick in
					__setValue={setValue}
				/>
			);
		},
		object: (viewKey, config) => {
			if (isViewMode || config.viewOnly) {
				return renderers.view(viewKey, config);
			}
			return (
				<SmarthopObjectField
					key={formKey + "_" + (config.key ?? viewKey)}
					control={control}
					dataIds={dataIds}
					errors={errors}
					mode={mode}
					name={config.key}
					header={config.header}
					label={
						labelOverrides[config.key]?.label ?? (config.label ? i18next.t(`forms:${config.label}`) : config.label)
					}
					content={config.content}
					object={config.component ?? config.object}
					noInitValidation={noInitValidation}
					orignialValue={originalData ? originalData[config.key] ?? {} : null}
					styleOverrides={styleOverrides}
					onChange={(value) => {
						onChangeCommittedDirty(config.key, value);
					}}
					onBlur={onBlur}
					// WORKAROUND: needed to allow to set values without triggering validation when nested
					// form values are not valid but nested yup validation did not kick in
					__setValue={setValue}
				/>
			);
		},
		dateRange: (viewKey, config) => {
			return (
				<Controller
					name={config.key}
					control={control}
					render={({ field }) => (
						<SmarthopDateRangeField
							fieldObject={field}
							field={config.field}
							key={formKey + "_" + (config.key ?? viewKey)}
							value={field.value}
							// TODO move to component subfield
							shortcutPriority={config.shortcutPriority}
							label={labelOverrides[config.key]?.label ?? config.label}
							control={control}
							errors={errors}
							name={config.key}
							required={config.required}
							component={config.component}
							disabled={(!isCreateMode && config.enabledOnCreateOnly) || config.disabled}
							onChangeCommitted={onChangeCommittedDirty}
						/>
					)}
				/>
			);
		},
		unsupported: (viewKey, config) => {
			return (
				<Typography key={viewKey} color="textSecondary" className="mb-10 mt-10 font-normal">
					{`Not supported type '${config.type ?? "<null>"} ' for field '${
						i18next.t(`forms:${config.label}`) ?? "<null>"
					}'`}
				</Typography>
			);
		},
		view: (viewKey, config, loptions) => {
			let nestedValue;
			if (config.key?.includes(".")) {
				nestedValue = data;
				config.key?.split(".").forEach((subkey) => {
					nestedValue = nestedValue?.[subkey];
				});
			}
			let value = config.builder
				? config.builder(data, config.viewOnly ? "VIEW" : mode, dispatch)
				: !data && data !== 0
				? null
				: data[config.key + "__view"] ?? data[config.key] ?? nestedValue ?? config?.defaultValue;

			let optionViews;
			let alignment;
			if (config.type === "select") {
				alignment = config.select?.alignment;
				let selectedOption = config.options?.find((option) => option.value === value);
				optionViews = itemsIterator(
					viewKey + "_value_items_" + value,
					selectedOption?.showItems,
					null,
					(loptions?.level ?? 0) + 1
				);
			}

			const label = labelOverrides[config.key]?.label ?? labelBuilder(config.label, data);

			let override = rulesOverrides[config.key];
			if (override?.hidden) return null;

			return (
				<div
					key={viewKey}
					className={
						"flex items-center w-full " +
						(alignment === "horizontal" ? "flex-col md:flex-row " : " flex-col ") +
						(isViewMode ? "" : " -mt-6 ")
					}
				>
					<SmarthopPreviewField
						key={formKey + "_" + (config.key ?? viewKey)}
						name={config.key}
						label={label}
						type={config.type}
						loading={loading}
						processing={processing}
						value={value}
						mode={mode}
						component={
							config.component ??
							// DEPRECATED all below are deprecated, we need to start using component params
							// and generic way to provide componet stylying params
							config.autocomplete ??
							config.checkbox ??
							config.pattern ??
							config.object ??
							config.field ??
							config.upload ??
							config.radio ??
							config.input
						}
						styleOverrides={styleOverrides}
						options={config.options}
						content={config.content}
						nativeMobile={nativeMobile}
						dataIds={dataIds}
						data={data}
						description={config.description}
						descriptionShowPreview={config.descriptionShowPreview}
						differentObjectHistory={props?.differentObjectHistory}
						differentObjectType={props?.differentObjectType}
					/>
					<div
						className={
							"flex items-center w-full " + (alignment === "horizontal" ? "flex-col md:flex-row " : " flex-col  ")
						}
					>
						{optionViews}
					</div>
				</div>
			);
		},
		component: (viewKey, config) => {
			return config.builder
				? labelOverrides[config.key]?.builder
				: config.value
				? labelOverrides[config.key]?.value
				: null;
		},
		stub: (viewKey, config) => {
			return <div />;
		},
		tabs: (viewKey, config, loptions) => {
			const openTabIndex = openTabs[viewKey] ?? 0;
			let tabViews = config.tabs?.map((tab, index) => {
				let key = viewKey + "_tab_item_" + index;
				return <Tab key={key} label={tab.label} value={index} />;
			});
			let contentViews = config.tabs?.map((tab, index) => {
				let key = viewKey + "_tab_content" + index;
				let defaultClasses = openTabIndex === index ? " flex flex-col items-center w-full " : " hidden ";
				return (
					<div key={key} className={defaultClasses + " " + (tab?.classes?.container ?? "")}>
						{itemsIterator(viewKey, tab?.content?.items, null, (loptions?.level ?? 0) + 1)}
					</div>
				);
			});

			return (
				<div key={viewKey} className="flex-col w-full flex">
					<Tabs
						className="mb-24"
						value={openTabIndex}
						onChange={(_, index) => setOpenTabs({ openTabs, [viewKey]: index })}
					>
						{tabViews}
					</Tabs>
					{contentViews}
				</div>
			);
		},
		toggle: (viewKey, config, loptions) => {
			let open = toggles[config.key];
			let defaultLabelTr = config?.toggle?.classes?.label?.includes("tracking-") ? "" : "tracking-wide";
			let defaultLabelText = config?.toggle?.classes?.label?.includes("text-") ? "" : "text-14 ml:text-15";
			let defaultLabelFont = config?.toggle?.classes?.label?.includes("font-") ? "" : "font-semibold";
			let defaultLabelMT = config?.toggle?.classes?.root?.includes("mt-") ? "" : "mt-6";
			let defaultLabelMB = open
				? "mb-4"
				: config?.toggle?.classes?.root?.includes("mb-")
				? ""
				: config.viewOnly
				? "mb-4"
				: "mb-8";
			let defaultMarginX = config?.toggle?.classes?.root?.includes("mx-") ? "" : config.viewOnly ? "mx-6" : "mx-8";
			let className = "flex-row w-full flex items-center justify-top ";
			if (!config.alwaysVisible) {
				className += "cursor-pointer";
			}

			const summaryView =
				labelOverrides[config.key]?.summary ??
				(typeof config.summary === "function"
					? "-"
					: config.summary ?? (toggles[config.key] ? "Click to Close" : "Click to Open"));
			return (
				<div
					key={viewKey}
					className={clsx(
						"flex flex-col w-full",
						defaultLabelMT,
						defaultLabelMB,
						defaultMarginX,
						config?.toggle?.classes?.root ?? ""
					)}
				>
					<div
						className={className}
						onClick={() => {
							if (config.alwaysVisible) return;
							setToggles((current) => ({ ...current, [config.key]: !current[config.key] }));
						}}
					>
						{config?.toggle?.icon && <Icon className="mr-8 text-24 text-grey-600">{config?.toggle?.icon}</Icon>}
						<div key={viewKey} className="flex-col w-full flex flex-1">
							<Typography
								color={config?.toggle?.color ?? undefined}
								variant={config?.toggle?.variant ?? "body2"}
								className={clsx(
									defaultLabelTr,
									defaultLabelText,
									defaultLabelFont,
									config?.toggle?.classes?.label ?? ""
								)}
							>
								{labelOverrides[config.key]?.label ??
									(config.label ? i18next.t(`forms:${config.label}`) : config.label)}
							</Typography>
							<div className="-mt-4 text-12">
								{!config.alwaysVisible &&
									(typeof summaryView === "string" ? (
										<Typography className={clsx("text-grey text-12")}>{summaryView}</Typography>
									) : (
										summaryView
									))}
							</div>
						</div>
						{!config.alwaysVisible && <Typography>{toggles[config.key] ? "Hide" : "Show"}</Typography>}
						{!config.alwaysVisible && <Icon>{toggles[config.key] ? "expand_less" : "expand_more"}</Icon>}
					</div>
					{!config?.toggle?.noDivider && <Divider className="mb-10" />}

					<div className={`flex-col w-full flex ${toggles[config.key] ? "" : "hidden"}`}>
						{itemsIterator(viewKey + "_toggle", config?.content?.items, null, (loptions?.level ?? 0) + 1)}
					</div>
				</div>
			);
		},
	};

	// Allows to show actions separately from the form and make sure button shown at the bottom of the screen

	const itemsIterator = (keyPrefix, items, classes, level) => {
		if (!items) return [];
		let views = [];
		let stickyViews = [];
		const isTopLevel = !level;

		items?.forEach((item, index) => {
			if (!item) return;

			// DEPRECATED need to remove hidden from everywhere
			// If hidden function returns true, then we are going to skip item element building, so item is not
			// displayed to the user
			if (item.hidden && (item.hidden(data) || !data)) return;

			// Applying style overrides if present
			const configOverride =
				// 1) Use specific component override
				styleOverrides?.[item.type]
					? styleOverrides?.[item.type]
					: // 2) Use override for all editable components
					styleOverrides?.["ALL_EDITABLE"] && EDITABLE_FIELDS.includes(item.type)
					? styleOverrides?.["ALL_EDITABLE"]
					: // 3) Use generic override for all components
					styleOverrides?.["ALL"]
					? styleOverrides?.["ALL"]
					: // 4) No override values found
					  null;
			item = applyOverrides(item, configOverride);

			let render = renderers[item.type] ?? renderers.unsupported;
			let viewKey = keyPrefix + "_" + index;
			let view = render(viewKey, item, { level: level ?? 0, index });

			let actinableView = item?.type === "menu" || (item?.type === "action" && !!item?.icon);
			const finalView = (
				<div
					key={viewKey}
					className={
						" flex " + (actinableView ? "" : classes?.["child" + index] ?? classes?.["childAny"] ?? " w-full ")
					}
				>
					{view}
				</div>
			);

			if ((nativeMobile || item.button?.sticky) && item.type === "action" && isTopLevel) {
				stickyViews.push(finalView);
			} else {
				views.push(finalView);
			}
		});

		return isTopLevel ? [views, stickyViews] : views;
	};

	const [centerPanelviews, stickyViews] = itemsIterator("root", content?.items);
	const [leftPanelViews, stickyViewsLeft] = itemsIterator("left", content?.left?.items);
	const [rightPanelViews, stickyViewsRight] = itemsIterator("right", content?.right?.items);
	const [topPanelViews] = itemsIterator("top", content?.top?.items);
	const [bottomPanelViews] = itemsIterator("bottom", content?.bottom?.items);

	const maxHClass =
		nativeMobile || topPanelViews?.length || leftPanelViews?.length || rightPanelViews?.length ? "h-full" : "";

	const errorMessage = errors["generic"]?.message
		? i18next.t(`forms:${errors["generic"]?.message}`)
		: !_.isEmpty(errors)
		? i18next.t(`forms:Please make sure that all required fields are filled correctly.`)
		: "";

	const showError =
		!content?.form?.noErrorMessage &&
		!loadingError &&
		!isViewMode &&
		!loading &&
		errors["generic"]?.code !== UNAUTHORIZED_TIER_ACCESS;

	const hasStickyActions = stickyViews?.length > 0 || stickyViewsLeft?.length > 0 || stickyViewsRight?.length > 0;
	const errorView = showError && (
		<div
			className={
				hasStickyActions
					? "w-full flex items-center justify-center md:justify-start px-10 pb-6"
					: "w-full flex items-center justify-center md:min-h-20 px-10 -mb-4"
			}
		>
			<Typography className="text-12 ml:text-13 leading-tight" color="error">
				{errorMessage}
			</Typography>
		</div>
	);

	const createStickyContainer = (views) => {
		return (
			views?.length > 0 && (
				<div
					className={
						"sticky bottom-0 z-99 flex-col w-full flex-1 bg-white pt-10 border-t-1 px-10 " +
						(nativeMobile ? " pb-24 " : " -mb-20 pb-6 ")
					}
				>
					{showError && errorMessage && <div className="flex w-full">{errorView}</div>}
					{views}
				</div>
			)
		);
	};

	const formContent = (
		<div className={clsx("flex flex-col w-full", maxHClass, nativeMobile ? "py-6 px-8" : "")}>
			{topPanelViews?.length > 0 && (
				<div
					key="top_panel"
					className={clsx("flex flex-row items-center justify-top w-full", content?.top?.classes?.root ?? "")}
				>
					{renderPanel && renderPanel("top_panel", "top")}
					{topPanelViews}
					{renderPanel && renderPanel("top_panel", "bottom")}
				</div>
			)}

			{topPanelViews?.length > 0 && content?.top?.showDivider && (
				<div className={"flex flex-col w-full bg-grey-200"} style={{ height: "1px" }} />
			)}

			<div
				className={clsx(
					"flex w-full flex-col md:flex-row items-top justify-center",
					maxHClass,
					content?.classes?.container ?? ""
				)}
			>
				{leftPanelViews?.length > 0 && (
					<div
						key="lf_panel"
						className={clsx(
							"flex flex-col",
							!content?.left?.classes?.root?.includes("w-") ? "w-full" : "",
							maxHClass,
							content?.left?.classes?.root ?? ""
						)}
					>
						<div
							key="lf_panel_container"
							className={clsx(
								"flex flex-col w-full",
								nativeMobile ? "" : "overflow-y-scroll overflow-x-hidden",
								maxHClass,
								content?.left?.classes?.container ?? ""
							)}
						>
							{renderPanel && renderPanel("left_panel", "top")}
							{leftPanelViews}
							{renderPanel && renderPanel("left_panel", "bottom")}
							{createStickyContainer(stickyViewsLeft)}
						</div>
					</div>
				)}

				{(props.children || centerPanelviews?.length || renderPanel) && (
					<div className={clsx("flex flex-row w-full h-full")}>
						{leftPanelViews?.length > 0 && content?.left?.showDivider && (
							<div className={"hidden ml:flex flex-col h-auto bg-grey-200"} style={{ width: "1px" }} />
						)}

						<div
							key="center_panel"
							className={clsx("flex flex-col w-full h-full", maxHClass, content?.classes?.root ?? "")}
						>
							{renderPanel && renderPanel("center_panel", "top")}
							{centerPanelviews}
							{props.children}
							{renderPanel && renderPanel("center_panel", "bottom")}
							{createStickyContainer(stickyViews)}
						</div>

						{rightPanelViews?.length > 0 && content?.right?.showDivider && (
							<div className={"hidden ml:flex flex-col h-auto bg-grey-200"} style={{ width: "1px" }} />
						)}
					</div>
				)}

				{rightPanelViews?.length > 0 && (
					<div
						key="rt_panel"
						className={clsx(
							"flex flex-col",
							!content?.right?.classes?.root?.includes("w-") ? "w-full" : "",
							maxHClass,
							content?.right?.classes?.root ?? ""
						)}
					>
						<div
							key="rt_panel_container"
							className={clsx(
								"flex flex-col w-full overflow-y-scroll overflow-x-hidden",
								maxHClass,
								content?.right?.classes?.container ?? ""
							)}
						>
							{renderPanel && renderPanel("right_panel", "top")}
							{rightPanelViews}
							{renderPanel && renderPanel("right_panel", "bottom")}
							{createStickyContainer(stickyViewsRight)}
						</div>
					</div>
				)}
			</div>

			{bottomPanelViews?.length > 0 && content?.bottom?.showDivider && (
				<div className={"flex flex-col w-full bg-grey-200"} style={{ height: "1px" }} />
			)}

			{bottomPanelViews?.length > 0 && (
				<div
					key="bottom_panel"
					className={clsx("flex flex-row items-center justify-top w-full", content?.bottom?.classes?.root ?? "")}
				>
					{renderPanel && renderPanel("bottom_panel", "top")}
					{bottomPanelViews}
					{renderPanel && renderPanel("bottom_panel", "bottom")}
				</div>
			)}
		</div>
	);

	if (nestedForm) {
		return <div className={"flex flex-col w-full"}>{formContent}</div>;
	}

	return (
		<div
			className={clsx("w-full", maxHClass, processing ? "opacity-60 pointer-events-none" : "")}
			style={{ position: "relative" }}
		>
			{loading &&
				(nativeMobile ? null : (
					<div
						className={clsx(
							classes.progress,
							"flex flex-col items-center justify-center w-full pt-6 pb-10 mt-auto mb-auto" +
								(contentItems?.length > 6 ? " min-h-420 ml:min-h-full " : " h-full ")
						)}
					>
						<CircularProgress />
					</div>
				))}
			{!content?.form?.noErrorMessage &&
				!loadingError &&
				!isViewMode &&
				!loading &&
				errors["generic"]?.code === UNAUTHORIZED_TIER_ACCESS && (
					<div
						className={clsx(classes.progress, "flex flex-col items-center justify-center w-full h-full pt-10 pb-10")}
					>
						<CardContent className={"flex flex-col items-center justify-center w-full py-40 px-20 ml:px-96"}>
							<div className="flex items-center mb-12 justify-center">
								<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
							</div>
							<Typography variant="h6" color="textSecondary" className="mt-10 mb-10 text-center font-light">
								{errors["generic"]?.message
									? i18next.t(`forms:${errors["generic"]?.message}`)
									: !_.isEmpty(errors)
									? i18next.t(`forms:Unknown loading error...`)
									: ""}
							</Typography>
							{errors["generic"]?.code === UNAUTHORIZED_TIER_ACCESS &&
								renderUpdateTierMessage(dispatch, userTier, carrierId, errors["generic"]?.metadata?.restrictions)}
						</CardContent>
					</div>
				)}
			{loadingError && (
				<div className={clsx(classes.progress, "flex flex-col items-center justify-center w-full h-full pt-10 pb-10")}>
					<Typography color="error">
						{errors["generic"]?.message
							? i18next.t(`forms:${errors["generic"]?.message}`)
							: !_.isEmpty(errors)
							? i18next.t(`forms:Unknown loading error...`)
							: ""}
					</Typography>
					{errors["generic"]?.code === UNAUTHORIZED_WALLET_ACCESS && renderOtpRequiredMessage(dispatch)}
				</div>
			)}
			<form
				className={clsx(
					loading || loadingError || errors["generic"]?.code === UNAUTHORIZED_TIER_ACCESS
						? classes.formInvisible
						: classes.form,
					"flex flex-col w-full ",
					maxHClass,
					content?.form?.classes?.root ?? ""
				)}
				onSubmit={nestedForm ? null : handleSubmit(onSubmitAskDirty)}
			>
				{formContent}
			</form>
			{hasStickyActions ? null : errorView}
			<SmarthopConfirmDialog
				open={!!openDialog}
				title={openDialog?.message}
				typedConfirmation={openDialog?.typedConfirmation}
				handleClose={handleDialogClose}
				handleAccept={handleAccept}
				closeMsg={openDialog?.closeMsg}
				acceptMsg={openDialog?.acceptMsg}
			/>
		</div>
	);
}

export default SmarthopFormView;

export { defaultValuesIterator, postprocessData };
