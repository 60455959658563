import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { formatDate } from "app/main/utils/dateUtils";

function InvoiceCellView(props) {
	const mobile = props.mobile;
	const config = props.config;
	const variant = props.variant;
	const url = props.view?.metadata?.invoice_pdf ?? null;
	const billedDate = formatDate(props.view?.metadata?.billedDate) ?? "No Date";
	const status = props.view?.metadata?.status;
	const icon = "receipt";

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	if (!props.view?.value) return;

	let color = "text-grey-600";
	if (status === "uncollectible") {
		color = "text-orange-800";
	} else if (status === "deleted" || status === "void") {
		color = "text-red-600";
	} else if (status === "paid") {
		color = "text-green-700";
	} else if (status === "open" || status === "draft") {
		color = "text-blue-700";
	}

	const openUrlInNewTab = (url) => {
		if (url) {
			const newWindow = window.open(url, "_blank", "noopener,noreferrer");
			if (newWindow) newWindow.opener = null;
		}
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<CellFirstRow
					mobile={mobile}
					view={props.view}
					onClick={() => openUrlInNewTab(url)}
					variant={variant}
					color={url ? null : "text-black"}
				/>
			}
			second={
				<>
					{config?.showDate && <CellSecondRow mobile={mobile} label={billedDate} variant={variant} />}
					{config?.showStatus && (
						<CellSecondRow mobile={mobile} icon={icon} label={status} color={color} variant={variant} />
					)}
				</>
			}
		/>
	);
}

export default InvoiceCellView;
