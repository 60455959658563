import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import _ from "lodash";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { loadingViewComponent } from "app/main/common/LoadingView";
import TripImportTableHeader from "./TripImportTableHeader";
import TripImportConfirmHeader from "./TripImportConfirmHeader";
import TripImportPagination from "./TripImportPagination";
import { uploadTripsCsv, getPendingImport, updateImportRecord, getUploadStatus } from "app/services/tripsServices";
import useInterval from "./hooks/useInterval";
import { setPreventDialogCloseCallback } from "app/store/tools/formToolsSlice";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import TripImportMultipleUpdate from "./TripImportMultipleUpdate";
import { createConfigs } from "./configs/tripImportConfig";
import { Typography } from "@material-ui/core";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { useHorizontalScroll } from "@smarthop/hooks/useHorizontalScroll";

const useStyles = makeStyles({
	action: {
		padding: "1px 12px",
		marginBottom: "4px",
	},
});

const UploadInProgress = ({ callback, delay = 2000 }) => {
	useEffect(
		() => {
			// Run callback one time before its interval execution
			callback();
		},
		[] // eslint-disable-line
	);

	useInterval(callback, delay);
	return (
		<div className="w-full flex items-center justify-center">
			<LinearProgress color="secondary" className="w-full" />
		</div>
	);
};

const createLocationView = (location) => ({
	value: location,
	label: location,
});

const getPopulatedTripFields = (trip) => {
	const current_location__view = trip.current_location && createLocationView(trip.current_location);
	const pickup_address__view = trip.pickup_address && createLocationView(trip.pickup_address);
	const delivery_address__view = trip.delivery_address && createLocationView(trip.delivery_address);
	return { current_location__view, pickup_address__view, delivery_address__view };
};

const findDuplicateErrors = (rowModel, trip, rowDirtyFields, trips) => {
	const modifiedFields = Array.from(
		new Set(
			Object.keys(rowDirtyFields)
				.filter((key) => !key.includes("flag"))
				.map((key) => key.replace("__view", ""))
		)
	);
	const modFieldsWithErrors = modifiedFields.filter((modField) =>
		trip.errors.find((error) => error.type === modField && error.message !== "Not provided or valid")
	);
	const otherRecords = trips.filter((tripRecord) => tripRecord.index !== trip.index);

	const duplicateErrorsSummary = otherRecords.reduce((acc, record) => {
		const errorKeys = record.errors.map((error) => error.type);
		modFieldsWithErrors.forEach((modField) => {
			if (errorKeys.includes(modField)) {
				const errorObj = record.errors.find((err) => err.type === modField);
				const tripErrorObj = trip.errors.find((err) => err.type === modField);
				const isSameError = errorObj?.message === tripErrorObj?.message;
				if (!isSameError) return;
				if (acc[errorObj.type]) {
					acc[errorObj.type].rowNums.push(record.index);
				} else {
					const value = rowModel[modField];
					const label = rowModel?.[`${modField}__view`]?.label ?? value;
					acc[errorObj.type] = {
						rowNums: [record.index],
						error: tripErrorObj?.message,
						value,
						label,
						applyChange: false,
					};
				}
			}
		});
		return acc;
	}, {});
	return duplicateErrorsSummary;
};

const getTripFuzzyErrors = (trip) =>
	trip.errors.reduce((errors, error) => {
		if (error.message.includes("Confidence")) {
			return { ...errors, [error.type]: true };
		}
		return errors;
	}, {});

const TripRow = ({ trip, trips, getTripsFromRedis, dataIds, ingestionStatus, setDirtyRows, uploading }) => {
	const [isEditMode, setIsEditMode] = useState(!!trip?.errors?.length);
	const [rowModel, setRowModel] = useState(null);
	const [rowDirtyFields, setRowDirtyFields] = useState(null);
	const [isDirty, setIsDirty] = useState(!_.isEmpty(getTripFuzzyErrors(trip)));
	const [duplicateSummary, setDuplicateSummary] = useState(null);
	const populatedFields = getPopulatedTripFields(trip);
	const tripData = { ...trip, ...populatedFields };
	const classes = useStyles();

	useEffect(() => {
		setIsEditMode(!!trip?.errors?.length);
		setIsDirty(!_.isEmpty(rowDirtyFields) || !_.isEmpty(getTripFuzzyErrors(trip)));
	}, [trip]); //eslint-disable-line

	const updateRecord = async () => {
		const filteredSummary = !_.isEmpty(duplicateSummary)
			? Object.entries(duplicateSummary)
					.filter(([, summary]) => summary.applyChange)
					.reduce((dupSum, [key, summary]) => ({ ...dupSum, [key]: summary }), {})
			: {};
		await updateImportRecord(dataIds.carrierId, {
			...(rowModel ?? trip),
			index: trip.index,
			rowDirtyFields,
			duplicateSummary: filteredSummary,
		});
		setDuplicateSummary(null);
		setRowDirtyFields(null);
		await getTripsFromRedis(dataIds.carrierId);
	};

	const handleRowChange = (model, originalKey, valid, options, dirtyFields) => {
		const hasDirtyFields = !_.isEmpty(dirtyFields);
		setRowDirtyFields({ ...dirtyFields, ...getTripFuzzyErrors(trip) });
		setIsDirty(hasDirtyFields);
		setRowModel(model);
		setDirtyRows((dirtyRows) => {
			const isInDirtyRows = dirtyRows.includes(trip.index);
			if (hasDirtyFields) {
				if (!isInDirtyRows) {
					dirtyRows.push(trip.index);
				}
			} else {
				const tripIndex = dirtyRows.indexOf(trip.index);
				if (tripIndex !== -1) {
					dirtyRows.splice(tripIndex, 1);
				}
			}
			return [...dirtyRows];
		});
	};

	const handleRowUpdate = async () => {
		const fuzzyMatchErrors = getTripFuzzyErrors(trip);
		const modRowDirtyFields = { ...rowDirtyFields, ...fuzzyMatchErrors };
		setRowDirtyFields(modRowDirtyFields);
		let model = rowModel;
		if (_.isEmpty(model)) {
			model = trip;
			setRowModel(trip);
		}
		const duplicateSummary = findDuplicateErrors(model, trip, modRowDirtyFields, trips);
		if (!_.isEmpty(duplicateSummary)) {
			setDuplicateSummary(duplicateSummary);
		} else {
			await updateRecord();
		}
	};

	const handleModeChange = () => {
		setIsEditMode((e) => !e);
		setIsDirty(false);
	};

	const actions = (item) =>
		!item?.uploaded && (
			<>
				{isEditMode && (
					<Button
						variant={"outlined"}
						color="secondary"
						disabled={!isDirty}
						onClick={handleRowUpdate}
						className={classes.action}
					>
						Update
					</Button>
				)}
				<Button variant="outlined" onClick={handleModeChange} className={classes.action}>
					{!isEditMode ? "Edit" : "Cancel"}
				</Button>
			</>
		);

	const config = createConfigs(actions, uploading, ingestionStatus);

	return (
		<>
			<SmarthopFormView
				key={`${trip.load_id} ${trip.index} ${isEditMode}`}
				mode={!trip?.uploaded && isEditMode ? "EDIT" : "VIEW"}
				content={!trip?.uploaded && isEditMode ? config.edit : config.view}
				data={tripData}
				errors={trip.errors}
				dataIds={dataIds}
				trackChangedFields={["ALL"]}
				onChangeCommitted={handleRowChange}
				noInitValidation
				nestedForm
			/>
			{duplicateSummary && (
				<SmarthopConfirmDialog
					open={duplicateSummary}
					maxWidth="lg"
					title="Update All"
					message="Found multiple records that share the same error, select Apply to fix all"
				>
					<TripImportMultipleUpdate
						duplicateSummary={duplicateSummary}
						updateRecord={updateRecord}
						setDuplicateSummary={setDuplicateSummary}
						handleSkip={() => {
							setDuplicateSummary(null);
							updateRecord();
						}}
						handleApply={() => {
							updateRecord();
						}}
						handleChange={(event) => {
							setDuplicateSummary((duplicateSummary) => {
								duplicateSummary[event.target.name].applyChange = event.target.checked;
								return { ...duplicateSummary };
							});
						}}
					/>
				</SmarthopConfirmDialog>
			)}
		</>
	);
};

const TripImportConfirm = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Confirm Trips Import");
		// eslint-disable-next-line
	}, []);

	const [trips, setTrips] = useState(null);
	const [summary, setSummary] = useState(null);
	const [uploading, setUploading] = useState(null);
	const [checkingStatus, setCheckingStatus] = useState(false);
	const [ingestionStatus, setIngestionStatus] = useState(null);
	const [processStatus, setProcessStatus] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [dirtyRows, setDirtyRows] = useState([]);
	const [paginationData, setPaginationData] = useState({ limit: 25, offset: 0 });
	const [recordFilter, setRecordFilter] = useState(null);
	const [visibleRecordCount, setVisibleRecordCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(null);
	const [loading, setLoading] = useState(null);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const xScrollRef = useHorizontalScroll();

	const setPreventClose = useCallback((isDirty = true) => {
		if (isDirty) {
			dispatch(
				setPreventDialogCloseCallback(() => {
					setOpenDialog({
						message: "You have unsaved changes. Are you sure you want to ingore changes and close this form?",
						closeMsg: "Keep Editing",
						acceptMsg: "Ignore And Close",
					});
				})
			);
		} else {
			dispatch(setPreventDialogCloseCallback(null));
		}
	}, []); // eslint-disable-line

	useEffect(() => {
		setPreventClose(!!dirtyRows.length);
	}, [dirtyRows, setPreventClose]);

	useEffect(() => {
		if (!ingestionStatus) return;
		const ingestionValues = Object.values(ingestionStatus);
		setProcessStatus(
			ingestionValues.every(Boolean) ? "Wrapping up" : ingestionValues.some(Boolean) ? "Ingesting trips" : "Uploading"
		);
	}, [ingestionStatus]);

	const uploadTripsFile = async (carrierId, metadata) => {
		try {
			const { tripRecords, totalRecords, summary } = await uploadTripsCsv(carrierId, metadata);
			setTotalRecords(totalRecords);
			setVisibleRecordCount(totalRecords.length);
			setTrips(tripRecords);
			setSummary(summary);
		} catch ({ errors }) {
			dispatch(closeFormDialog());
			errors.forEach((error) => {
				showErrorSnackbar(snackbar, { message: error?.message }, { duration: 3000 });
			});
		}
	};

	const getTripsFromRedis = async (carrierId) => {
		setLoading(true);
		const {
			tripRecords,
			totalRecords,
			summary,
			ingestionStatus: savedIngestionStatus,
			recordCount,
		} = await getPendingImport(carrierId, { ...paginationData, recordFilter });
		setVisibleRecordCount(recordCount);
		setIngestionStatus(savedIngestionStatus);
		setTrips(tripRecords);
		setSummary(summary);
		setTotalRecords(totalRecords);
		setLoading(false);
	};

	const checkTripIngestionStatus = async () => {
		if (!checkingStatus) {
			setCheckingStatus(true);
			const validTrips = totalRecords.filter((trip) => trip.recordStatus === "valid");
			const loadIds = validTrips.map((trip) => trip.load_id);
			if (!ingestionStatus) {
				const ingestionStatusInit = loadIds.reduce((acc, loadId) => ({ ...acc, [loadId]: false }), {});
				setIngestionStatus(ingestionStatusInit);
			}
			getUploadStatus(dataIds.carrierId, loadIds).then((data) => {
				setCheckingStatus(false);
				setIngestionStatus(data);
			});
		}
	};

	useEffect(
		() => {
			const { carrierId, platform, fileId } = dataIds;
			if (!trips && dataIds?.fileId) {
				uploadTripsFile(carrierId, { fileId, platform });
			} else {
				getTripsFromRedis(carrierId);
			}
		},
		[paginationData, recordFilter] // eslint-disable-line
	);

	return !trips ? (
		loadingViewComponent()
	) : (
		<div style={{ overflow: "auto" }}>
			{summary && (
				<TripImportConfirmHeader
					summary={summary}
					carrierId={dataIds.carrierId}
					setUploading={setUploading}
					uploading={uploading}
					ingestionStatus={ingestionStatus}
					processStatus={processStatus}
					getTripsFromRedis={getTripsFromRedis}
					setRecordFilter={setRecordFilter}
					setPaginationData={setPaginationData}
				/>
			)}
			{uploading && <UploadInProgress callback={checkTripIngestionStatus} />}

			<div ref={xScrollRef} className="h-512">
				<Box>
					<TripImportTableHeader />
					<Box py="2rem" className={`${loading || uploading ? "pointer-events-none opacity-50" : ""}`}>
						{!loading && !trips?.length && (
							<div className="flex w-full h-512 flex-col items-center justify-center">
								<div className="w-1/4">
									<Typography variant="h6" color="primary" className="flex pt-10 pb-8 items-center justify-center">
										{`No ${recordFilter ?? ""} records found`}
									</Typography>
								</div>
							</div>
						)}
						{trips.map((trip) => (
							<TripRow
								key={`${trip?.index}`}
								trips={totalRecords}
								trip={trip}
								getTripsFromRedis={getTripsFromRedis}
								dataIds={dataIds}
								ingestionStatus={ingestionStatus}
								uploading={uploading}
								setDirtyRows={setDirtyRows}
							/>
						))}
					</Box>
				</Box>
			</div>
			<TripImportPagination
				uploading={uploading}
				totalRecords={visibleRecordCount}
				paginationData={paginationData}
				setPaginationData={setPaginationData}
			/>
			<SmarthopConfirmDialog
				key={`tripImportCloseConfirm`}
				open={!!openDialog}
				message={openDialog.message}
				handleClose={() => {
					setOpenDialog(false);
				}}
				handleAccept={() => {
					dispatch(setPreventDialogCloseCallback(null));
					dispatch(closeFormDialog());
				}}
			/>
		</div>
	);
};
export default TripImportConfirm;
