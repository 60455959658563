import axios from "axios";
import uniqid from "uniqid";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { global } from "app/services/requestUtil";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { addDummyFile } from "app/store/upload/filesSlice";

// Services
import { getUserTier } from "app/services/LoginService";

// Components
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 1301,
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgb(0,0,0)",
		opacity: 0,
		transitiom: "opacity 0.5s",
		"&.visible": {
			opacity: 0.8,
		},
	},
	box: {
		border: "5px dotted",
		borderRadius: "5px",
		padding: "13%",
		display: "flex",
		flexDirection: "column",
		rowGap: 1,
		alignItems: "center",
		fontSize: "4rem",
	},
}));

const DropZone = () => {
	const [visible, setVisible] = useState(false);
	const [paywallDialog, setPaywallDialog] = useState({});

	const classes = useStyles();
	const dispatch = useDispatch();

	const formStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const visibleForm = formStack.length > 0 ? formStack[formStack.length - 1] : null;

	const uploadFiles = async (files) => {
		if (!files.length) return;

		if (getUserTier() === "TIER_STARTER") {
			setPaywallDialog({
				open: true,
				dataIds: {
					type: "rc_transcription_restriction",
					data: { callback: uploadFiles },
					extraData: { dataSend: files },
				},
			});
			return;
		}

		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		};

		let data = new FormData();
		files.forEach((file) => {
			const uploadId = uniqid();
			data.append("files", file);
			data.append("uploadIds", uploadId);
			// The fileId doesn't exist at this point where the file is dragged in the dropzone,
			// adding a random placeholder here until all these dummy records get replaced
			dispatch(addDummyFile({ status: "uploading", dummy: true, metadata: { name: file.name, type: "RC", uploadId } }));
		});
		data.append("type", "rate_con_file");
		data.append("document_type", "trip");
		data.append("field_name", "rate_confirmation");

		let url = "api/multi-upload/upload";

		console.log(`[DropZone] uploading files, files=`, files);
		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.post(url, data, headers)
			.then((res) => {
				console.log(`[DropZone] POST, uploaded files, data=`, res.data);
			})
			.catch((res) => {
				console.error(`[DropZone] error, status=${res?.status}`);
			});
	};

	const onDrop = useCallback(
		async (acceptedFiles) => {
			uploadFiles(acceptedFiles);
			const isCurrentlyOpened = visibleForm && visibleForm?.viewId === "MULTI_FILE_UPLOAD_VIEW";
			if (!isCurrentlyOpened) dispatch(openFormDialog({ viewId: "MULTI_FILE_UPLOAD_VIEW" }));
			setVisible(false);
		},
		// eslint-disable-next-line
		[formStack]
	);

	const onDragLeave = useCallback(() => {
		setVisible(false);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		onDragLeave,
		noClick: true,
		noKeyboard: true,
	});

	useEffect(() => {
		if (!visible) {
			document.ondragenter = (ev) => setVisible(true);
		} else {
			document.ondragenter = null;
		}
	}, [visible]);

	return (
		<div className={classes.root} {...getRootProps()} style={{ display: visible ? "block" : "none" }} id="dropzone">
			<input {...getInputProps()} />
			{isDragActive && (
				<Paper className={`${classes.paper} ${visible ? "visible" : ""}`} elevation={3}>
					<Box className={classes.box}>
						<Typography style={{ fontSize: "4rem" }}>Drop your RC files here</Typography>
						<Icon style={{ fontSize: "10rem" }}>cloud_upload</Icon>
					</Box>
				</Paper>
			)}
			<PaywallDialog data={paywallDialog} onHandlerPaywall={() => setPaywallDialog({})} />
		</div>
	);
};

export default DropZone;
