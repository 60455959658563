const settingsConfig = {
	layout: {
		style: "layout1", // layout1 layout2 layout3
		config: {}, // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
	},
	customScrollbars: true,
	direction: "ltr", // rtl, ltr
	theme: {
		main: window?.location?.href?.includes("/native/") ? "native" : "default",
		navbar: "default",
		toolbar: "default",
		footer: "default",
	},
};

export default settingsConfig;
