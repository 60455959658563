import { SwipeableDrawer, Box } from "@material-ui/core";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { unselectPath } from "../../providers/reducer/actions";
import { StrategyActionContext, StrategyStateContext } from "../../providers/StrategyContextProvider";
import DrawerBody from "./drawer-body/DrawerBody";
import DrawerButtons from "./DrawerButtons";
import DrawerHeader from "./DrawerHeader";

const useDynamicDrawerHidingHeight = (
	openState,
	cardRef,
	drawerRef,
	headerRef,
	closedDrawerHeight,
	setClosedDrawerHeight,
	topLevel
) => {
	const clearNumberUnit = (numstr) => (isNaN(numstr) ? +numstr.replace(/\D+/, "") : +numstr);

	useEffect(() => {
		const calcDrawerHiddingHeight = (headerPadding, moveCardHeight, drawerTop) =>
			window.innerHeight - (clearNumberUnit(headerPadding) * 2 + moveCardHeight + (!topLevel ? drawerTop : 0));
		const handleWindowResize = (openState) => {
			const headerPadding = headerRef?.current?.style.padding ?? 0;
			const moveCardHeight = cardRef?.current?.clientHeight ?? 0;
			const drawerTop = drawerRef?.current?.getBoundingClientRect().top ?? 0;
			if (!openState) {
				const calculatedHeight = calcDrawerHiddingHeight(headerPadding, moveCardHeight, drawerTop);
				if (calculatedHeight > 0 && closedDrawerHeight !== calculatedHeight) {
					setClosedDrawerHeight(calculatedHeight);
				}
			}
		};

		window.addEventListener("resize", handleWindowResize);
		handleWindowResize(openState);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [cardRef, drawerRef, headerRef, openState, setClosedDrawerHeight, closedDrawerHeight, topLevel]);
};

const findPathIndex = (moves, paths) => {
	const pathId = moves[moves.length - 1].data.stop.pathId;
	return paths.findIndex((path) => path.path_id === pathId);
};

const StrategyDrawer = (props) => {
	const {
		header,
		body: strategyData,
		setDrawerHeader,
		strategyReqData,
		firstOpen,
		setFirstOpen,
		closedDrawerHeight,
		setClosedDrawerHeight,
		disableSaving,
		topLevel,
	} = props;
	const [open, setOpen] = useState(firstOpen && header);
	const [index, setIndex] = useState();
	const cardRef = useRef();
	const headerRef = useRef();
	const drawerRef = useRef();
	const { selectedPaths } = useContext(StrategyStateContext);
	const contextDispatch = useContext(StrategyActionContext);
	const isSaved = disableSaving();

	const getInitialSelectedMoves = useCallback(() => {
		// Access pathId located in last node
		const pathId = header[header.length - 1].data.stop.pathId;
		const selectedMoves = selectedPaths[pathId];
		return selectedMoves ?? header;
	}, [header, selectedPaths]);
	const [selectedMoves, setSelectedMoves] = useState(getInitialSelectedMoves);

	const handleCollapse = () => {
		setOpen((o) => !o);
	};

	const handleClose = () => {
		setDrawerHeader(null);
		contextDispatch(unselectPath());
	};

	useEffect(() => {
		if (!!open && firstOpen) {
			setTimeout(() => {
				setOpen(false);
				setFirstOpen(false);
			}, 1500);
		}
	}, [open, firstOpen, setFirstOpen]);

	useEffect(() => {
		if (!header || !strategyData) return;
		setSelectedMoves(getInitialSelectedMoves);
		setIndex(findPathIndex(header, strategyData?.strategyResult?.paths));
	}, [header, strategyData, getInitialSelectedMoves]);

	useDynamicDrawerHidingHeight(
		open,
		cardRef,
		drawerRef,
		headerRef,
		closedDrawerHeight,
		setClosedDrawerHeight,
		topLevel
	);

	// const paperRef = useRef(null)

	const [animateOpen, setAnimateOpen] = useState(false);
	const [overfllowAuto, setOverflowAuto] = useState(true);

	const handleTransitionEnd = useCallback(() => {
		setAnimateOpen(!!open);
		let timeOutId;
		if (!!open) {
			timeOutId = setTimeout(() => {
				setOverflowAuto(!!open);
			}, 750);
		} else {
			setOverflowAuto(!!open);
		}

		return () => clearTimeout(timeOutId);
	}, [open]);

	return (
		<SwipeableDrawer
			open={!!open}
			anchor="bottom"
			onClose={props.handleClose || (() => {})}
			onOpen={props.handleOpen || (() => {})}
			variant="permanent"
			// ref={paperRef}
			PaperProps={{
				style: {
					borderRadius: "30px 0 0 0",
					borderRight: "1px solid #e0e0e0",
					overflow: overfllowAuto ? "auto" : "visible",
					// overflow: "visible",
					transition: "all 1s ease-in-out",
					transform: open ? null : `translateY(${closedDrawerHeight}px)`,
				},
				onTransitionEnd: handleTransitionEnd,
			}}
		>
			<DrawerButtons open={open} animateOpen={animateOpen} handleCollapse={handleCollapse} handleClose={handleClose} />
			<Box ref={drawerRef}>
				<DrawerHeader
					ref={headerRef}
					header={header}
					handleCollapse={handleCollapse}
					selectedMoves={selectedMoves}
					setSelectedMoves={setSelectedMoves}
					cardRef={cardRef}
					isSaved={isSaved}
				/>
				<DrawerBody
					index={index}
					strategyData={strategyData}
					strategyReqData={strategyReqData}
					pathMoves={header}
					selectedMoves={selectedMoves}
				/>
			</Box>
		</SwipeableDrawer>
	);
};

export default StrategyDrawer;
