const environments = {
	DEV: "dev",
	STAGING: "staging",
	PROD: "prd",
};

export const FEATURE_TYPE = {
	key: "feature_type",
	type: "select",
	label: "Feature type",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Not Selected" },
		{ value: "QA", label: "QA" },
		{ value: "STANDARD", label: "Standard" },
	],
};

export const createStatusConfig = (env) => {
	const envKey = env.toUpperCase();
	const key = `status_${environments[envKey]}`;
	const label = `Release status (${envKey?.toLowerCase()})`;

	return {
		key,
		type: "select",
		label,
		defaultValue: "__NOT_SELECTED__",
		options: [
			{ value: "__NOT_SELECTED__", label: "Not Selected" },
			{ value: "RELEASED", label: "Released" },
			{ value: "NOT_RELEASED", label: "Not Released" },
		],
	};
};

export const createUserEnabledConfig = (env) => {
	const envKey = env.toUpperCase();
	const key = `access_user_${environments[envKey]}`;
	const label = `User enabled (${envKey?.toLowerCase()})`;

	return {
		key,
		type: "autocomplete",
		label,
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/users",
			types: [],
			preloadDefault: true,
		},
	};
};

export const createCarrierEnabledConfig = (env) => {
	const envKey = env.toUpperCase();
	const key = `access_carrier_${environments[envKey]}`;
	const label = `Carrier enabled (${envKey?.toLowerCase()})`;

	return {
		key,
		type: "autocomplete",
		label,
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/carriers",
			types: [],
			preloadDefault: true,
		},
	};
};
