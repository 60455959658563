import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Config
import createGoalsForm from "@smarthop/form/configs/createGoalsCarrierForm";

// Services
import { createGoalsCarrier } from "app/services/goalsServices";

// Tools
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const CreateGoalsView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Create Goals");
		// eslint-disable-next-line
	}, []);

	const onDone = props.onDone;
	const dataIds = props.dataIds;
	const carrierId = dataIds.carrierId;

	const dispatch = useDispatch();

	const [errors, setErrors] = useState(null);

	const handleSubmit = (model) => {
		const data = { ...model };

		if (dataIds.equipment) {
			data.equipment = dataIds.equipment;
		}

		createGoalsCarrier(carrierId, data).then(
			() => {
				onDone?.();
				dispatch(incrementDataRevision({ event: "goalsCarrierRevision" }));
				dispatch(incrementDataRevision({ event: "goalsRevision" }));
				dispatch(incrementDataRevision({ event: "costStructureRevision" }));
			},
			(error) => {
				const errors = error?.errors ?? [
					{ type: "generic", message: "Oops... something went wrong, please try again..." },
				];
				setErrors(errors);
			}
		);
	};

	const form = useMemo(() => createGoalsForm(dataIds.equipment), [dataIds]);

	return (
		<SmarthopFormView
			mode="CREATE"
			content={form.content.create}
			dataIds={{ carrierId }}
			errors={errors}
			onSubmit={handleSubmit}
		/>
	);
};

export default CreateGoalsView;
