import _ from "@lodash";
import {
	SECTION_SEARCH_TITLE,
	SECTION_PREFERENCES_TITLE,
	SECTION_FILTERS_TITLE,
	CARRIER_ASSIGNED,
	CARRIER_ALL,
	CARRIER_TRUCKS,
	DRIVER_TRUCKS,
	TRUCK_ASSIGNED,
	TRUCK_ALL,
	LOCATION_ORIGIN,
	DH_ORIGIN,
	LOCATION_DESTINATION,
	DH_DESTINATION,
	DESTINATION_STATES,
	PICKUP_AFTER_DS,
	PICKUP_BEFORE_DS,
	EXTRA_STOPS_PREFERRED,
	DRIVER_BLAKLISTED_STATES,
	TRAILER_EQUIPMENT,
	RATE_TOTAL_PREFERRED,
	RATE_PER_MILE_PREFERRED,
	MILES_PREFERRED,
	PROFIT_PREFERRED,
	AGE_PREFERRED,
	MIN_RANK_MCI,
	MIN_CREDIT_SCORE,
	SECTION_SYSTEM_TITLE,
	SYSTEM_SHOW_FLEXIBLE,
	SYSTEM_SHOW_DETAILED_CONTENT,
	SYSTEM_ON_DEMAND_RESULT_ONLY,
	SYSTEM_SHOW_ESTIMATE_RATES,
	SYSTEM_BID_NOW_RESULT_ONLY,
	SYSTEM_SHOW_ONLY_ONBOARDED,
	SYSTEM_SHOW_TEAM_LOADS_ONLY,
	SYSTEM_SHOW_SPECIAL_LOAD,
	SYSTEM_NEW_LOADS_ONLY,
	SYSTEM_SHOW_WITH_PRICE_ONLY,
	SYSTEM_SPECIFIC_OWNERS_ONLY,
	SYSTEM_SHOW_PLAN_MATCH_ONLY,
	SYSTEM_SHOW_ONLY_FACTORABLE,
	SYSTEM_SHOW_HAZMAT_LOADS_ONLY,
	SECTION_BROKERS_TITLE,
	SEARCH_BROKERS,
	SECTION_TYPE_TITLE,
	LOAD_TYPE,
	SYSTEM_SHOW_OUTLIERS_ONLY,
	SECTION_ROUTE_TITLE,
	HIDE_INTERNATIONAL_LOADS,
} from "./searchFields";
import authRoles from "../../consts/authRoles";

const createForm = (
	type,
	mobile,
	role,
	outdated,
	classes,
	onSave,
	onResetPref,
	searchDialog = false,
	loading = false,
	hasSubaccounts = false,
	submit,
	data,
	nativeMobile
) => {
	const isAdmin = authRoles.internalPowerUsers.includes(role);
	const isDispatch = authRoles.dispatcher.includes(role);
	const isCarrier = authRoles.carrier.includes(role);
	const isDriver = authRoles.driver.includes(role);
	const isManager = authRoles.carrierManager.includes(role);
	const isInvestor = authRoles.carrierGeneric.includes(role);
	const isExtDispatcher = authRoles.carrierDispatcher.includes(role);
	const isInternal = authRoles.internalUsers.includes(role);
	const isMainHandler = hasSubaccounts && (isCarrier || isManager || isExtDispatcher);

	const classesBgWhite = {
		button: { root: classes.buttonNormal, disabled: classes.buttonDisabled },
		field: { root: classes.field },
		input: { root: classes.field, input: classes.field },
	};
	const varianBgWhite = {
		field: "outlined",
	};

	const form = (cfg, main, top, left) => {
		return {
			form: {
				noErrorMessage: true,
				classes: {},
			},
			classes: {
				root: loading ? "opacity-50" : null,
				container: type === "result" ? "bg-white" : null,
			},
			items: main,
			top: { items: top ?? [], showDivider: true, classes: { root: "bg-white px-14 py-6" } },
			left: {
				items: left ?? [],
				showDivider: true,
				classes: {
					root: `bg-white max-w-xs ml:max-w-sm ${classes.leftPanelHeight ?? ""}`,
					container: "px-14 py-6",
				},
			},
		};
	};
	const menu = (cfg, items, groups, actions) => {
		return {
			type: "menu",
			icon: cfg?.icon,
			button: { color: cfg?.color, classes: {} },
			menu: {
				classes: {
					container:
						"pb-16 " +
						(groups ? "flex flex-col md:flex-row justify-between md:pl-10 md:pr-10" : "") +
						// WORKAROUND: weird alignment bug that happens only on mobile and only in "request" style UI
						(type === "request" ? " -ml-16 ml:ml-0 " : ""),
				},
			},
			groups: groups ?? [],
			actions: actions,
			content: {
				items: items,
			},
		};
	};

	const group = (cfg, items) => {
		return {
			type: "group",
			group: { classes: { root: cfg?.classes?.root, direction: cfg?.classes?.direction } },
			content: {
				items: items,
			},
		};
	};

	const action = (cfg, disableLoading) => {
		return {
			type: "action",
			action: "SUBMIT",
			label: "Search",
			icon: cfg?.icon ? cfg.icon : undefined,
			button: {
				loadingState: !disableLoading && loading,
				alwaysEnabled: cfg?.enabled ? cfg?.enabled?.button : true,
				classes: { root: type === "result" && outdated ? `${classes.flicker} ${classes.withAnimation}` : "" },
			},
		};
	};

	let carrierSelector = isAdmin
		? CARRIER_ALL(true)
		: isDispatch || isMainHandler
		? CARRIER_ASSIGNED(isDispatch, true)
		: null;
	let truckSelector = isAdmin
		? TRUCK_ALL(true)
		: isDispatch || isMainHandler
		? TRUCK_ASSIGNED(isDispatch, true, isMainHandler)
		: isCarrier || isManager || isInvestor || isExtDispatcher
		? CARRIER_TRUCKS(isExtDispatcher, isInvestor, true)
		: isDriver
		? DRIVER_TRUCKS
		: null;

	let preferences = [
		mobile ? SECTION_PREFERENCES_TITLE() : SECTION_PREFERENCES_TITLE(onSave, onResetPref, mobile),
		RATE_PER_MILE_PREFERRED,
		RATE_TOTAL_PREFERRED,
		MILES_PREFERRED,
		AGE_PREFERRED,
		PROFIT_PREFERRED,
		MIN_RANK_MCI,
		MIN_CREDIT_SCORE,
		EXTRA_STOPS_PREFERRED,
	];
	let brokers = [SECTION_BROKERS_TITLE, SEARCH_BROKERS, SYSTEM_SHOW_ONLY_ONBOARDED];

	let filters = [
		SECTION_FILTERS_TITLE,
		...(!mobile ? [SYSTEM_NEW_LOADS_ONLY] : []),
		SYSTEM_SHOW_ONLY_FACTORABLE,
		SYSTEM_BID_NOW_RESULT_ONLY,
		SYSTEM_SHOW_PLAN_MATCH_ONLY,
		SYSTEM_SHOW_FLEXIBLE,
		HIDE_INTERNATIONAL_LOADS,
		SYSTEM_SHOW_TEAM_LOADS_ONLY,
		SYSTEM_SHOW_WITH_PRICE_ONLY,
		SYSTEM_SHOW_HAZMAT_LOADS_ONLY,
		...(isInternal
			? [
					SECTION_SYSTEM_TITLE,
					group({}, [SYSTEM_SHOW_DETAILED_CONTENT, SYSTEM_ON_DEMAND_RESULT_ONLY]),
					group({}, [SYSTEM_SHOW_ESTIMATE_RATES, SYSTEM_SHOW_OUTLIERS_ONLY]),
					group({}, [SYSTEM_SHOW_SPECIAL_LOAD]),
					SYSTEM_SPECIFIC_OWNERS_ONLY,
			  ]
			: []),
	];

	let routes = [
		SECTION_TYPE_TITLE,
		LOAD_TYPE,
		TRAILER_EQUIPMENT,
		SECTION_ROUTE_TITLE,
		PICKUP_BEFORE_DS,
		DH_ORIGIN,
		DESTINATION_STATES,
		LOCATION_DESTINATION,
		DH_DESTINATION,
		DRIVER_BLAKLISTED_STATES,
	];

	const actionsButtons = [
		{
			...action(
				{
					enabled: { button: data?.carrier && data?.location_origin && data?.truck },
					icon: mobile ? "search" : null,
				},
				true
			),
			click: submit,
		},
	];

	let result;
	if (type === "result") {
		if (mobile) {
			result = form(
				{},
				null,
				[
					group({ classes: { root: "flex flex-col" } }, [
						group({ classes: { direction: "horizontal" } }, [
							LOCATION_ORIGIN,
							menu(
								{ icon: "tune", color: "primary" },
								[
									SECTION_SEARCH_TITLE,
									carrierSelector,
									truckSelector,
									...preferences,
									...routes,
									...brokers,
									...filters,
								],
								null,
								actionsButtons
							),
						]),
						group({ classes: { direction: "horizontal" } }, [PICKUP_AFTER_DS, action({ icon: "search" })]),
					]),
				],
				null
			);
		} else {
			result = form(
				{},
				null,
				[
					carrierSelector,
					truckSelector,
					LOCATION_ORIGIN,
					PICKUP_AFTER_DS,
					menu(
						{ icon: "tune", color: "primary" },
						[...preferences, ...brokers, ...filters, ...routes],
						[[...preferences], [...routes], [...brokers, ...filters]],
						actionsButtons
					),
					action(),
				],
				null
			);
		}
	} else if (type === "request") {
		result = form({}, [
			group({ classes: { root: "flex flex-col md:flex-row" } }, [
				carrierSelector,
				truckSelector,
				LOCATION_ORIGIN,
				PICKUP_AFTER_DS,
				group({ classes: { direction: "horizontal" } }, [
					menu(
						{ icon: "tune", color: "primary" },
						[...preferences, ...brokers, ...filters, ...routes],
						[[...preferences], [...routes], [...brokers, ...filters]]
					),
					action(),
				]),
			]),
		]);
	}

	if (result?.items?.length > 0) {
		updateStyle(result.items, classesBgWhite, varianBgWhite, classesBgWhite, varianBgWhite);
	}
	if (result?.top?.items?.length > 0) {
		updateStyle(result.top.items, classesBgWhite, varianBgWhite, classesBgWhite, varianBgWhite);
	}
	if (result?.left?.items?.length > 0) {
		updateStyle(result.left.items, classesBgWhite, varianBgWhite, classesBgWhite, varianBgWhite);
	}

	return result;
};

const updateStyle = (element, classes, variant, menuClasses, menuVariant) => {
	if (!element) return;

	if (Array.isArray(element)) {
		element.forEach((item) => {
			if (!element) return;
			if (_.isObject(item)) {
				updateStyle(item, classes, variant, menuClasses, menuVariant);
			}
		});
	} else if (_.isObject(element) && !_.isEmpty(element)) {
		if (_.has(element.button, "classes") && classes["button"]) {
			if (element.type === "action") {
				element.button.classes = {
					root: (classes["button"].root ?? "") + " " + (element.button?.classes?.root ?? ""),
					disabled: (classes["button"].disabled ?? "") + " " + (element.button?.classes?.disabled ?? ""),
				};
			} else if (element.type === "menu") {
				element.button.classes = classes["button"];
			}
		}

		if (_.has(element.field, "classes") && classes["field"]) element.field.classes = classes["field"];
		if (_.has(element.input, "classes") && classes["input"]) element.input.classes = classes["input"];
		if (_.has(element.field, "variant") && variant["field"]) element.field.variant = variant["field"];

		Object.keys(element).forEach((key) => {
			if (!element) return;

			if (element.type === "menu") {
				updateStyle(element[key], menuClasses, menuVariant, menuClasses, menuVariant);
			} else {
				updateStyle(element[key], classes, variant, menuClasses, menuVariant);
			}
		});
	}
};

export default createForm;
