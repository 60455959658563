import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { createBadgeView } from "app/main/search/searchUtils";

function ViewDescriptionSection(props) {
	const variant = props.variant;
	const items = props.items;
	const oneColumn = !!props.oneColumn;

	const colorPrimary = variant === "dark" ? "text-white font-semibold" : "font-semibold text-gray-900";
	const colorSecondary = variant === "dark" ? "text-white" : "text-gray-900";
	const colorIcon = variant === "dark" ? "text-white" : "text-blue-800";

	const itemsMd = [];
	const itemsSm = [];

	items?.forEach((item) => {
		if (!item.label?.length) return;
		const content = {
			value: item.value ?? "-",
			label: item.label,
			icon: item.icon,
			badge: item.badge,
			builder: item.builder,
		};

		if (!itemsMd[itemsMd.length - 1] || itemsMd[itemsMd.length - 1].length === 3) itemsMd.push([]);
		itemsMd[itemsMd.length - 1].push(content);

		if (!itemsSm[itemsSm.length - 1] || itemsSm[itemsSm.length - 1].length === 2) itemsSm.push([]);
		itemsSm[itemsSm.length - 1].push(content);
	});

	return (
		<div className={props?.classes?.root}>
			{itemsMd.map((data, index) => (
				<div key={index} className={"w-full hidden md:flex flex-row py-4 md:py-6"}>
					<div className={"w-1/3 " + (oneColumn ? " w-full " : "")}>
						{data[0] && (
							<>
								<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[0]?.label}</Typography>
								<div className="flex flex-row">
									{data[0]?.icon && <Icon className={"text-16 mt-2 mr-6 " + colorIcon}>{data[0]?.icon}</Icon>}
									{!data[0]?.icon && data[0]?.badge && createBadgeView(null, "STATUS", data[0].badge)}
									{data[0]?.value && (
										<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[0]?.value}</Typography>
									)}
									{!data[0]?.value && data[0]?.builder}
								</div>
							</>
						)}
					</div>
					<div className={"w-1/3 " + (oneColumn ? " hidden " : "")}>
						{data[1] && (
							<>
								<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[1]?.label}</Typography>
								<div className="flex flex-row">
									{data[1]?.icon && <Icon className={"text-16 mt-2 mr-6 " + colorIcon}>{data[1]?.icon}</Icon>}
									{!data[1]?.icon && data[1]?.badge && createBadgeView(null, "STATUS", data[1].badge)}
									{data[1]?.value && (
										<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[1]?.value}</Typography>
									)}
									{!data[1]?.value && data[1]?.builder}
								</div>
							</>
						)}
					</div>
					<div className={"w-1/3 " + (oneColumn ? " hidden " : "")}>
						{data[2] && (
							<>
								<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[2]?.label}</Typography>
								<div className="flex flex-row">
									{data[2]?.icon && <Icon className={"text-16 mt-2 mr-6 " + colorIcon}>{data[2]?.icon}</Icon>}
									{!data[2]?.icon && data[2]?.badge && createBadgeView(null, "STATUS", data[2]?.badge)}
									{data[2]?.value && (
										<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[2]?.value}</Typography>
									)}
									{!data[2]?.value && data[2]?.builder}
								</div>
							</>
						)}
					</div>
				</div>
			))}
			{itemsSm.map((data, index) => (
				<div key={index} className={"w-full flex md:hidden flex-row py-4 md:py-6"}>
					<div className={"w-1/2 " + (oneColumn ? " w-full " : "")}>
						{data[0] && (
							<>
								<Typography className={"text-12 md:text-13 pr-10 font-semibold " + colorPrimary}>
									{data[0]?.label}
								</Typography>
								<div className="flex flex-row">
									{data[0]?.icon && <Icon className={"text-16 mt-2 mr-6 " + colorIcon}>{data[0]?.icon}</Icon>}
									{!data[0]?.icon && data[0]?.badge && createBadgeView(null, "STATUS", data[0].badge)}
									{data[0]?.value && (
										<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[0]?.value}</Typography>
									)}
									{!data[0]?.value && data[0]?.builder}
								</div>
							</>
						)}
					</div>
					<div className={"w-1/2 " + (oneColumn ? " hidden " : "")}>
						{data[1] && (
							<>
								<Typography className={"text-12 md:text-13 pr-10 font-semibold " + colorPrimary}>
									{data[1]?.label}
								</Typography>
								<div className="flex flex-row">
									{data[1]?.icon && <Icon className={"text-16 mt-2 mr-6 " + colorIcon}>{data[1]?.icon}</Icon>}
									{!data[1]?.icon && data[1]?.badge && createBadgeView(null, "STATUS", data[1].badge)}
									{data[1]?.value && (
										<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[1]?.value}</Typography>
									)}
									{!data[1]?.value && data[1]?.builder}
								</div>
							</>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

export default ViewDescriptionSection;
