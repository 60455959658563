import { Link } from "@material-ui/core";

function ViewLink(props) {
	return (
		<div className={"flex flex-col w-full"}>
			<Link
				className={
					"cursor-pointer tracking-wide text-12 md:text-13 tracking-wide text-blue-800 text-start justify-start py-5 mt-5 " +
					props.className
				}
				component="button"
				underline="always"
				onClick={props?.onClick}
			>
				{props.label}
			</Link>
		</div>
	);
}

export default ViewLink;
