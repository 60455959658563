import { useState } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";
import { DisplaySection } from "app/main/utils/uiUtils";

import { removePostedBrokerFromPlan, deletePosting } from "app/services/postTruckServices";
import { getLogoConfig, getLogoImage } from "../utils/brokerUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { useMemo } from "react";
import { formatDate } from "../utils/dateUtils";

const TruckPosting = ({ broker, posting, plan }) => {
	const dispatch = useDispatch();
	const brokerLogo = useMemo(() => getLogoImage(getLogoConfig(broker, true)), [broker]);
	const [loading, setLoading] = useState(false);

	const onClickUnpost = (item) => {
		setLoading(true);
		deletePosting(item._id)
			.then((res) => {
				setLoading(false);
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			})
			.catch((e) => {
				setLoading(false);
			});
	};

	const onClickCancel = (broker) => {
		setLoading(true);
		removePostedBrokerFromPlan(plan.carrier, plan.truck, plan._id, broker)
			.then((res) => {
				setLoading(false);
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			})
			.catch((e) => {
				setLoading(false);
			});
	};

	const status = useMemo(() => {
		if (posting?.status === "SUCCESS") {
			return 1;
		} else if (plan?.post_brokers?.includes(broker) && plan?.auto_post_externally) {
			return 2;
		} else {
			return 0;
		}
	}, [posting, plan, broker]);

	return (
		<div className="flex flex-row justify-between content-center items-center border-b-1 pt-4">
			<div className="flex flex-row w-full content-center items-center space-x-8 pt-6 pb-1">
				<div className="flex flex-row flex-1">{brokerLogo}</div>
				<Typography className="text-primary text-12 ">
					{status === 2 ? "Pending " + formatDate(plan?.post_date) : status === 1 ? "Posted" : "Not Posted"}
				</Typography>
			</div>
			{status === 1 && (
				<Button
					size="small"
					disabled={loading}
					variant="contained"
					color="secondary"
					className="text-11 h-18 -mb-3 ml-5"
					onClick={() => onClickUnpost(posting)}
				>
					{loading ? <CircularProgress size={12} color="white" /> : "Unpost"}
				</Button>
			)}
			{status === 2 && (
				<Button
					size="small"
					disabled={loading}
					variant="contained"
					color="secondary"
					className="text-11 h-18 -mb-3 ml-5"
					onClick={() => onClickCancel(broker)}
				>
					{loading ? <CircularProgress size={12} color="white" /> : "Cancel"}
				</Button>
			)}
		</div>
	);
};

const TruckPlanPosting = ({ dataIds, showPostTruck, plan, postings }) => {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const hasSearchPermission = ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_search_on: true })
		: true;

	return (
		<div className="flex w-full flex-col">
			{postings && (
				<DisplaySection
					classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }}
					title="External Postings"
					actions={
						!hasSearchPermission
							? []
							: [
									{
										label: "Edit",
										onClick: () => {
											const reactNative = window?.ReactNativeWebView ?? false;
											if (reactNative) {
												window?.ReactNativeWebView?.postMessage(
													JSON.stringify({
														type: "OPEN_DIALOG",
														data: {
															title: "Edit Plan",
															viewId: "TRUCK_PLAN_EDIT",
															mode: "EDIT",
															dataIds: {
																carrierId: dataIds?.carrierId,
																truckId: dataIds?.truckId,
																postExternallOpened: true,
																openSection: "posttruck",
																nativeMobile: true,
															},
														},
													})
												);
											} else {
												dispatch(
													openLoadedFormDialog({
														viewId: "TRUCK_PLAN",
														dataIds: {
															...dataIds,
															postExternallOpened: true,
															openSection: "posttruck",
														},
													})
												);
											}
										},
									},
							  ]
					}
				>
					<TruckPosting plan={plan} broker="dat" posting={postings["dat"]} />
					<TruckPosting plan={plan} broker="parade" posting={postings["parade"]} />
				</DisplaySection>
			)}
		</div>
	);
};

export default TruckPlanPosting;
