import { useEffect, useMemo } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { formatDate } from "app/main/utils/dateUtils";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { createContent } from "@smarthop/form/configs/tripDetailsContent";
import { truckPlanForm } from "app/main/truck-plans/config/truckPlanForm";
import { trailerFormConfig, truckFormConfig } from "@smarthop/form/configs/truckForm";
import enterprisePayrolldetailsConf from "app/main/finance/enterprise-payroll/enterprisePayrollDetailsConf";
import enterprisePayrollRulesConf from "app/main/finance/enterprise-payroll/EnterprisePayrollRulesConf";
import { compareHistory } from "app/main/utils/historyUtils";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { useState } from "react";
import { useSelector } from "react-redux";
import tripInvoicingForm from "@smarthop/form/configs/tripInvoicingForm";
import { createUserEditFrom } from "app/main/profile/carriers/configs/userEditConfig";
import { createBrokerEditFrom } from "app/main/profile/brokers/configs/brokerEditConfig.js";
import fuelTransactionForm from "@smarthop/form/configs/fuelTransactionForm.js";

import {
	getUserRoles,
	ROLE_DRIVER_KEY,
	ROLE_INVESTOR_KEY,
	ROLE_DISPATCHER_KEY,
	preprocessFormData,
} from "app/main/profile/carriers/configs/userRolesUtils";

const HistoryViewForm = (props) => {
	useEffect(() => {
		props?.setTitle?.("History View");
		// eslint-disable-next-line
	}, []);
	let dataIds = props?.dataIds;
	const [data, setData] = useState(dataIds?.historyData);
	const [indexLeft, setIndexLeft] = useState(data[0]?.index);
	const [indexRight, setIndexRight] = useState(data[1]?.index);
	let allHistoryData = dataIds?.allHistoryData;
	const differentObjectHistory = useMemo(() => (data.length > 1 ? compareHistory(data?.[0], data?.[1]) : null), [data]);

	const user = useSelector(({ auth: { user } }) => user);

	const moveLeft = (newIndex) => {
		setData([allHistoryData[newIndex], allHistoryData[indexRight]]);
		setIndexLeft(newIndex);
		setIndexRight(data[1]?.index);
	};

	const moveRight = (newIndex) => {
		setData([allHistoryData[indexLeft], allHistoryData[newIndex]]);
		setIndexLeft(data[0]?.index);
		setIndexRight(newIndex);
	};

	const viewForm = (view, data) => {
		if (view === "TRIP_EDIT_VIEW" || view === "TRIP_VIEW") {
			return createContent({
				parsedMetadata: null,
				feature: {},
				taskView: null,
				viewSideLeft: false,
			});
		}
		if (view === "TRUCK_PLAN" || view === "TRUCK_PLAN_VIEW" || view === "TRUCK_PLAN_FORM") {
			return truckPlanForm({
				historyMode: true,
			});
		}
		if (view === "TRUCK_VIEW") {
			return truckFormConfig(false, "VAN");
		}
		if (view === "PAYROLL_VIEW") {
			return enterprisePayrolldetailsConf({
				isHistoryItem: true,
			});
		}
		if (view === "PAYROLL_RULES_VIEW") {
			return enterprisePayrollRulesConf({ mode: "VIEW" });
		}
		if (view === "TRIP_INVOICE_VIEW") {
			return tripInvoicingForm.content.view;
		}
		if (view === "TRAILER_VIEW") {
			return trailerFormConfig({ mode: "VIEW" });
		}
		if (view === "CARRIER_USER_EDIT_VIEW") {
			const roles = getUserRoles(data.roles);
			const viewerCanEditAndAccessPayroll =
				user.roleType === "INTERNAL" ||
				(user.roleType === "EXTERNAL" &&
					["CARRIER_OWNER", "CARRIER_MANAGER", "CARRIER_DISPATCHER"].includes(user.role));
			const formSettings = {
				role: roles[0],
				driverProfileAllowed: roles.includes(ROLE_DRIVER_KEY),
				accessDeactivated: false,
				driverProfileArchived: roles.includes(ROLE_DRIVER_KEY) && !!data?.driverProfile?.deleted,
				userPayrollArchived:
					(roles.includes(ROLE_DRIVER_KEY) && !data?.driverPayroll?.enroll_payroll) ||
					(roles.includes(ROLE_DISPATCHER_KEY) && !data?.dispatcherPayroll?.enroll_payroll) ||
					(roles.includes(ROLE_INVESTOR_KEY) && !data?.investorPayroll?.enroll_payroll),
				userRoleSupportsPayroll:
					roles.includes(ROLE_DRIVER_KEY) || roles.includes(ROLE_DISPATCHER_KEY) || roles.includes(ROLE_INVESTOR_KEY),
				investorEnabled: roles.includes(ROLE_INVESTOR_KEY),
				enabledPermissions: true,
				enabledRoles: false,
				multiUser: false,
				viewerCanEditAndAccessPayroll,
			};

			return createUserEditFrom(formSettings, { payrollEnabled: data?.driverPayroll, mode: "COMPARE" });
		}
		if (view === "BROKER_EDIT_VIEW") {
			return createBrokerEditFrom({}, { mode: "COMPARE" });
		}

		if (view === "FUEL_TRANSACTION_VIEW") {
			return fuelTransactionForm.content.view;
		}
	};

	const processData = (view, data) => {
		if (view === "CARRIER_USER_EDIT_VIEW") {
			data = { ...data, ...preprocessFormData(data, { payrollEnabled: data?.payroll?.settings }) };
		}
		return data;
	};

	const ButtonPrev = (disabled, index, type) => {
		return (
			<IconButton
				key={"prev"}
				className="h-40 w-40 mt-10"
				disabled={disabled}
				onClick={() => (type === "left" ? moveLeft(index) : moveRight(index))}
			>
				<Icon className="text-40">chevron_left</Icon>
			</IconButton>
		);
	};

	const ButtonRight = (disabled, index, type) => {
		return (
			<IconButton
				key="next"
				className="h-40 w-40 mt-10"
				disabled={disabled}
				onClick={() => (type === "left" ? moveLeft(index) : moveRight(index))}
			>
				<Icon className="text-40">chevron_right</Icon>
			</IconButton>
		);
	};

	const handleViewForm = (data, index, first, last, type, content) => {
		return (
			<div className="flex flex-col w-full">
				{data?.historyCreationName && (
					<div className="flex flex-row w-full">
						{/* Left Button */}
						{first ? (
							<>{ButtonPrev(!allHistoryData[indexLeft - 1], indexLeft - 1, "left")}</>
						) : last ? (
							<>{ButtonPrev(!allHistoryData[indexRight - 1] || indexRight - 1 <= indexLeft, indexRight - 1, "right")}</>
						) : null}

						<div className="flex flex-1 flex-wrap text-center items-center justify-center">
							<Typography className="font-semibold w-full mt-14 text-14">
								{`Edited by ${data?.historyCreationName}`}
							</Typography>
							<div className="flex flex-row mb-10 items-center justify-center">
								<Typography className="text-12 pt-2 text-grey-600">
									{`${formatDate(data?.historyCreationDate)}`}
								</Typography>
								<Typography
									className={`text-11 font-medium mt-2 px-6 ml-4 ${
										type === "AFTER" ? "bg-blue" : "bg-red"
									} text-white rounded-lg`}
								>
									{`${type}`}
								</Typography>
							</div>
						</div>

						{/* Right Button */}
						{first
							? ButtonRight(!allHistoryData[indexLeft + 1] || indexLeft + 1 >= indexRight, indexLeft + 1, "left")
							: last
							? ButtonRight(!allHistoryData[indexRight + 1], indexRight + 1, "right")
							: null}
					</div>
				)}
				<Divider className={"w-full mb-24 h-1 mx-6"} />
				<SmarthopFormView
					key={"version_" + data.version + "" + index + "" + Math.round(Math.random() * 800)}
					mode={"COMPARE"}
					content={content}
					data={data}
					dataIds={dataIds}
					differentObjectHistory={differentObjectHistory}
					differentObjectType={type}
				>
					{props.children}
				</SmarthopFormView>
			</div>
		);
	};

	const handleViewFormData = () => {
		let views = [];

		data?.forEach((item, index) => {
			item = processData(dataIds?.view, { ...item });
			const dataForm = viewForm(dataIds?.view, item);
			// removing undefined items
			if (!dataForm) return [];
			dataForm.items = dataForm?.items?.filter((item) => !!item);
			views.push(
				<div className="flex flex-row w-1/2 px-10">
					{handleViewForm(
						item,
						index,
						index === 0,
						index === data?.length - 1,
						index === 0 ? "AFTER" : "BEFORE",
						dataForm
					)}
				</div>
			);
			if (views.length > 0 && data.length - 1 !== index) {
				views.push(<Divider orientation="vertical" className={"bg-grey-400 h-auto"} variant="middle" />);
			}
		});
		return views;
	};

	return <div className="flex w-full flex-row m-0 p-0">{handleViewFormData()}</div>;
};

export default HistoryViewForm;
