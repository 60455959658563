import socketIOClient from "socket.io-client";
import { createSlice } from "@reduxjs/toolkit";
import { global } from "app/services/requestUtil";

const connectionSlice = createSlice({
	name: "connection",
	initialState: {
		socket: null,
		isConnected: false,
		isAuthenticated: false,
		queueRevision: 0,
	},
	reducers: {
		initConnection: (state) => {
			if (!state.socket) {
				state.socket = socketIOClient(global.SOCKET_PORT, {
					reconnection: true,
					reconnectionDelay: 1000,
					reconnectionDelayMax: 5000,
					reconnectionAttempts: Infinity,
					transports: ["websocket", "polling"],
				});
			}
		},
		setConnected: (state, action) => {
			state.isConnected = action.payload;
		},
		setAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		},
	},
});

export const { setConnected, setAuthenticated, initConnection } = connectionSlice.actions;

export default connectionSlice.reducer;
