import { useEffect, useState } from "react";
import { Paper, Button, Modal, TextField } from "@material-ui/core";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import SmarthopFormView from "../SmarthopFormView";
import { geocode } from "app/services/trimbleMapsService";
import SmarthopPreviewField from "./SmarthopPreviewField";

TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;

async function reverseGeocode(coords) {
	return new Promise((resolve, reject) => {
		TrimbleMaps.Geocoder.reverseGeocode({
			lonLat: new TrimbleMaps.LngLat(coords.lng, coords.lat),
			region: TrimbleMaps.Common.Region.NA,
			success: function (response) {
				if (!response || !response[0]) {
					return resolve(false);
				}
				const address = response[0]?.Address;
				// match search format
				const countryAbbreviation = address.CountryAbbreviation === "US" ? "USA" : address.CountryAbbreviation;
				const addressString = `${address.StreetAddress} ${address.City}, ${address.StateAbbreviation}, ${countryAbbreviation} (${coords.lat}, ${coords.lng})`;
				const val = {
					description: addressString,
					label: addressString,
					value: addressString,
					metadata: {
						street: address.StreetAddress,
						city: address.City,
						coords: { long: coords.lng, lat: coords.lat },
						country: countryAbbreviation,
						countryName: address.Country,
						state: address.StateAbbreviation,
						stateName: address.StateName,
						zip: address.Zip,
					},
				};
				return resolve(val);
			},
			failure: function (response) {
				return reject(response);
			},
		});
	});
}

function SmarthopManualLocationSelect({ open, setOpen, onLocationSelected, startingLocation }) {
	const [coords, setCoords] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [searchLocation, setSearchLocation] = useState(null);
	const [marker, setMarker] = useState(null);
	const [map, setMap] = useState(null);
	const [streetValue, setStreetValue] = useState("");

	const runReverseGeocode = async (c) => {
		try {
			const result = await reverseGeocode(c);
			if (result) {
				setSelectedLocation(result);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const setCoordsWrapper = (c) => {
		if (!c.lng) {
			c.lng = c.long;
		}

		c.lat = parseFloat(c.lat ?? 0).toFixed(6);
		c.lng = parseFloat(c.lng ?? 0).toFixed(6);
		setCoords(c);
	};

	const initMap = async () => {
		let loc = startingLocation;
		if (loc?.label && !loc?.metadata?.coords) {
			const decoded = await geocode(loc?.label);
			if (decoded?.options && decoded.options[0]) {
				loc = decoded.options[0];
			}
		}

		const latLong = loc?.metadata?.coords ?? { long: -96, lat: 35 };

		let myMap;
		try {
			myMap = new TrimbleMaps.Map({
				container: "myMapManualLocationSelect",
				center: new TrimbleMaps.LngLat(latLong.long, latLong.lat),
				zoom: loc?.metadata?.coords ? 10 : 3,
			});
		} catch (e) {
			// sometimes takes a second or 2 for the myMap container element to be ready
			return setTimeout(initMap, 100);
		}

		const marker = new TrimbleMaps.Marker({
			draggable: true,
		})
			.setLngLat([latLong.long, latLong.lat])
			.addTo(myMap);

		setMarker(marker);

		marker.on("dragend", function (e) {
			const lngLat = e.target.getLngLat();
			setCoordsWrapper(lngLat);
		});

		myMap.on("click", function (e) {
			marker.setLngLat(e.lngLat);
			setCoordsWrapper(e.lngLat);
		});

		setMap(myMap);
		setCoordsWrapper(latLong);
	};

	const onClickSave = async () => {
		const val = { ...selectedLocation };
		const m = val.metadata;

		const addressString = `${streetValue} ${m.city}, ${m.state}, ${m.country} (${m.coords.lat}, ${m.coords.long})`;
		val.description = addressString;
		val.label = addressString;
		val.value = addressString;
		val.metadata.street = streetValue;

		onLocationSelected(val);
	};

	useEffect(() => {
		if (!coords?.lat || !coords?.lng) return;

		runReverseGeocode(coords);
		// eslint-disable-next-line
	}, [coords, open]);

	useEffect(
		() => {
			if (!open) return;

			initMap();
		},
		// eslint-disable-next-line
		[open]
	);

	useEffect(() => {
		if (!searchLocation?.metadata?.coords) return;

		let data = { ...searchLocation };
		data.metadata.coords.lng = data.metadata.coords.long;
		const coords = data.metadata.coords;

		setSelectedLocation(data);
		setCoords(coords);
		marker.setLngLat(coords);
		map.setCenter(coords);
		map.setZoom(10);
		// eslint-disable-next-line
	}, [searchLocation]);

	useEffect(() => {
		if (!selectedLocation) return;

		setStreetValue(selectedLocation?.metadata?.street);
	}, [selectedLocation]);

	return (
		<Modal
			className="flex items-center justify-center"
			onClick={(event) => event.stopPropagation()}
			onMouseDown={(event) => event.stopPropagation()}
			onClose={() => setOpen(false)}
			open={open}
			keepMounted={false}
			disablePortal={false}
		>
			<Paper
				className="w-full flex bg-white flex-col p-16"
				elevation={3}
				variant={"elevation"}
				style={{ maxWidth: "700px" }}
			>
				<SmarthopFormView
					key={`SmarthopManualLocationSelectForm`}
					mode={"EDIT"}
					dataIds={{}}
					content={{
						form: {
							noErrorMessage: true,
						},
						items: [
							{
								key: `searchAddress`,
								label: "Address Search",
								type: "autocomplete",
								variant: "standard",
								autocomplete: {
									provider: "trimblemaps",
									disableManualSelect: true,
									params: { componentRestrictions: { country: "us" } },
									icon: "location",
									onValueChange: (val) => {
										if (val && val[0]) {
											setSearchLocation(val[0]);
										}
									},
								},
							},
						],
					}}
					data={{ searchAddress: startingLocation?.label, searchAddress__view: startingLocation }}
					trackChangedFields={["ALL"]}
				/>
				<div className="px-4">
					<div
						id="myMapManualLocationSelect"
						className="flex w-full"
						style={{ minHeight: "40vh", marginBottom: "1.9rem" }}
					></div>
				</div>
				<div className="flex flex-row w-full px-4" style={{ marginBottom: "1.9rem" }}>
					<div className="flex flex-col w-full">
						<TextField
							value={streetValue}
							onChange={(e) => setStreetValue(e.target.value)}
							variant="outlined"
							label="Steet Address"
							className="mb-4"
						/>
						<div className="flex flex-row w-full">
							<SmarthopPreviewField label="City" value={selectedLocation?.metadata?.city} />
							<SmarthopPreviewField label="State" value={selectedLocation?.metadata?.state} />
							<SmarthopPreviewField
								label="Coords"
								value={
									!selectedLocation?.metadata?.coords?.lat
										? ""
										: `(${selectedLocation?.metadata?.coords?.lat}, ${selectedLocation?.metadata?.coords?.long})`
								}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-row w-full px-4 justify-end">
					<Button color="primary" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button color="secondary" onClick={onClickSave}>
						Save
					</Button>
				</div>
			</Paper>
		</Modal>
	);
}

export default SmarthopManualLocationSelect;
