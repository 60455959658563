import { recurringDeductionItems } from "app/main/profile/drivers/EnterprisePayrollSection";

const createForm = ({ errors, mode, isInvestor }) => {
	let actions = [
		{
			type: "action",
			action: "SUBMIT",
			label: "Save",
			disabled: !!errors?.length,
			confirmation: {
				enable: true,
				message: `You are going to ${
					mode === "CREATE" ? "create" : "update"
				} a recurrig payroll rule. Do you want to continue?`,
			},
		},
	];

	return {
		bottom: {
			classes: { root: "pt-4 md:pt-12" },
			items: actions,
		},
		items: [...recurringDeductionItems({ edit: true, isInvestor, mode })],
	};
};

export default createForm;
