import Link from "@material-ui/core/Link";
import CellEmptyRow from "./CellEmptyRow";


function TicketCellView(props) {
	const mobile = props.mobile;
	const view = props.view;
	const variant = props.variant;
	const color = props.color;

	if (!view) {
		return null;
	}

	const font =
		variant === "large"
			? " text-16 font-semibold tracking-wide font-mono "
			: variant === "spacious"
			? " text-14 font-medium "
			: variant === "skinny" || variant === "extra-skinny"
			? " text-13 font-medium "
			: " text-14 ";

	if (!view || !view.length) {
		return null;
	}
	
	return (
    <CellEmptyRow>
      {view?.map((item, index) => { 
        return (
          <div>
          <Link
            key={index}
            className={(mobile ? "" : " tracking-wide whitespace-nowrap ") + (color ?? "") + font + " cursor-pointer "}
            color="secondary"
            onClick={(event) => {
              window.open(`https://smarthop.zendesk.com/agent/tickets/${item}`, "_blank");
            }}
          >
            {item}
          </Link>
          </div>
        ) 
      })}
    </CellEmptyRow>
    
  )
}
export default TicketCellView;
