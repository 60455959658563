// Components
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import MoneyOffOutlinedIcon from "@mui/icons-material/MoneyOffOutlined";
import Button from "@material-ui/core/Button";

// Storage
// import { setSmarthopWalletToken } from "app/store/auth/userSlice";

// Storage
import { setSmarthopWalletToken } from "app/store/wallet/smarthopWalletSlice";

// Tools
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { creditAndDebitAmountFormat } from "app/main/utils/billingUtils";

// Consts
const ACTIVE_WALLET_STATUS = "active";
const INACTIVE_WALLET_STATUS = "inactive";
const CLOSED_WALLET_STATUS = "closed";

const UNAUTHORIZED_WALLET_ACCESS = "UNAUTHORIZED_WALLET_ACCESS";
const ONBOARDING_REQUIRED = "ONBOARDING_REQUIRED";

const UNAUTHORIZED_TIER_ACCESS = "UNAUTHORIZED_TIER_ACCESS";

const WALLET_ACCESS = "WALLET_ACCESS";

const SMARTHOP_WALLET_SECTION_LABEL = "SmartHop Wallet";

const APPLICATION_STATUSES = {
	NotStarted: {
		label: "Not Started",
		description: "",
		icon: "not_started_outlined",
		color: "text-orange-700",
	},
	AwaitingDocuments: {
		label: "Awaiting Documents",
		description: "",
		icon: "hourglass_empty_outlined",
		color: "text-orange-700",
	},
	PendingReview: {
		label: "Pending Review",
		description: "",
		icon: "hourglass_empty_outlined",
		color: "text-orange-700",
	},
	Pending: {
		label: "Pending",
		description: "",
		icon: "hourglass_empty_outlined",
		color: "text-orange-700",
	},
	Approved: {
		label: "Approved",
		description: "",
		icon: "check-circle-outlined",
		color: "text-green-700",
	},
	Denied: {
		label: "Denied",
		description: "",
		icon: "do_disturb_alt_outlined",
		color: "text-red-600",
	},
	Canceled: {
		label: "Canceled",
		description: "",
		icon: "delete_outline_outlined",
		color: "text-red-600",
	},
};
const WALLET_STATUSES = {
	active: {
		label: "Active",
		iconComponent: AttachMoneyOutlinedIcon,
		color: "text-green-700",
		description: "(Approved application and account not closed)",
	},
	inactive: {
		label: "Inactive",
		iconComponent: PersonOffOutlinedIcon,
		color: "text-orange-700",
		description: "(Default state until application approved)",
	},
	closed: {
		label: "Closed",
		iconComponent: MoneyOffOutlinedIcon,
		color: "text-red-600",
		description: "(Customer opts out of an active wallet)",
	},
};
const WALLET_STATUSES_FILTER = Object.keys(WALLET_STATUSES).map((key) => {
	return {
		value: key,
		label: `${WALLET_STATUSES[key].label}`,
	};
});

/**
 * @description Converts cents to dollars
 * @param {Number|String} amount - Amount in cents
 * @returns {Number} - Amount in dollars
 */
const centsToDollars = (amount) => +(parseFloat(amount) / 100).toFixed(2);

/**
 * @description Converts dollars to cents
 * @param {Number|String} amount - Amount in dollars
 * @returns {Number} - Amount in cents
 */
const dollarsToCents = (amount) => +(parseFloat(amount) * 100).toFixed(2);

const cleanSmarthpWalletTokenStorage = (dispatch) => {
	localStorage.removeItem("@smarthopWalletToken");
	dispatch(setSmarthopWalletToken(null));
};

/**
 *
 * @param {Function} dispatch
 * @param {Function} callback
 * @param {Object} config.params
 * @param {Object} config.dataToSend
 * @param {String} config.scope
 * @param {Array} config.dataIds
 */
const unauthorizedUserHandler = (dispatch, callback, config) => {
	cleanSmarthpWalletTokenStorage(dispatch);
	const dataIds = config?.dataIds ?? [];
	let metadata = { ...dataIds };
	if (!!callback) {
		metadata.callbackData = {
			...dataIds,
			scope: config?.scope,
			params: config?.params,
			body: config?.dataToSend,
			callback,
		};
	}

	// 2 Factor Verification for user to authorize payment
	dispatch(
		openLoadedFormDialog({
			viewId: "SMARTHOP_WALLET_VERIFICATION_CODE_VIEW",
			dataIds: metadata,
		})
	);
};

const renderOtpRequiredMessage = (dispatch) => {
	return (
		<Button
			variant="contained"
			color="secondary"
			className={"text-white m-20"}
			onClick={() => unauthorizedUserHandler(dispatch)}
		>
			Authenticate
		</Button>
	);
};

const renderUpdateTierMessage = (dispatch, userTier, carrierId, restrictions) => {
	return (
		<Button
			variant="contained"
			color="secondary"
			className={"text-white m-20"}
			onClick={() => {
				dispatch(
					openLoadedFormDialog({
						viewId: userTier ? "BILLING_PLAN" : "BILLING_PLAN_CHANGE",
						dataIds: { carrierId, updatePlan: true, restrictions },
					})
				);
			}}
		>
			Unlock Now
		</Button>
	);
};

const UPDATE_TIER_ERROR_MESSAGE = {
	response: {
		data: {
			errors: [
				{
					type: "generic",
					message: "",
					code: UNAUTHORIZED_TIER_ACCESS,
				},
			],
		},
	},
};

const OTP_ERROR_MESSAGE_REQUIRED = {
	response: {
		data: {
			errors: [
				{
					type: "generic",
					message: "Unauthorized user. 2-Factor Verification required",
					code: UNAUTHORIZED_WALLET_ACCESS,
				},
			],
		},
	},
};

const formatLedgerAmount = (amountInCents, type) => {
	if (!["CREDIT", "DEBIT"].includes(type)) return "-";

	const amount = centsToDollars(Math.abs(amountInCents));
	const signAndColors = {
		CREDIT: { sign: "+", color: " text-green" },
		DEBIT: { sign: "-", color: " text-red" },
	};
	const value = `${signAndColors[type].sign} ${formatCurrency(amount)}`;
	return creditAndDebitAmountFormat(value, signAndColors[type].color);
};

export {
	SMARTHOP_WALLET_SECTION_LABEL,
	APPLICATION_STATUSES,
	WALLET_STATUSES,
	WALLET_STATUSES_FILTER,
	ACTIVE_WALLET_STATUS,
	INACTIVE_WALLET_STATUS,
	CLOSED_WALLET_STATUS,
	centsToDollars,
	dollarsToCents,
	unauthorizedUserHandler,
	cleanSmarthpWalletTokenStorage,
	renderOtpRequiredMessage,
	renderUpdateTierMessage,
	OTP_ERROR_MESSAGE_REQUIRED,
	UNAUTHORIZED_TIER_ACCESS,
	UPDATE_TIER_ERROR_MESSAGE,
	UNAUTHORIZED_WALLET_ACCESS,
	WALLET_ACCESS,
	ONBOARDING_REQUIRED,
	formatLedgerAmount,
};
