import React from "react";
import { Typography, Button } from "@material-ui/core";
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatLabel } from "./utils";
import { Divider } from "@material-ui/core";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

function BannerContainer(props) {
	const { description, value, url, subtitle, track, type } = props;

	const sendLink = (event) => {
		event.stopPropagation();
		createTrackOrPage(track, { type }, "track");
		window.open(url);
	};

	return (
		<>
			<Divider className="w-full bg-grey-300 mt-10" />
			{value > 0 && formatLabel("Potential savings", formatCurrency(value ?? 0), description)}
			<Button
				style={{
					borderRadius: 5,
					backgroundColor: "#002756",
					color: "white",
				}}
				className={(value > 0 ? "-mt-10" : "mt-16") + " mb-10"}
				variant={"contained"}
				onClick={(e) => {
					sendLink(e);
				}}
			>
				Apply Now
			</Button>
			<Typography className="font-medium text-13 text-grey-600 italic">{subtitle}</Typography>
		</>
	);
}

export default BannerContainer;
