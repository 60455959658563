import React from "react";
import Image from "material-ui-image";
import Typography from "@material-ui/core/Typography";
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import Icon from "@material-ui/core/Icon";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { createTooltip } from "app/main/utils/tableUtils";

// Services
import { getBrokerSearch } from "app/services/brokersServices";

// Const
const MCI_DEST_DESCRIPTION =
	"Market conditions illustrate where markets are currently hot and cold. Want to learn more? Reach out!";

const getLogoConfig = (name, skinny) => {
	let style;
	let src;
	let label;
	let aggregator = false;
	let noLogoName = false;
	if (name === "abi") {
		style = skinny ? { width: "46px", height: "14px" } : { width: "80px", height: "26px" };
		src = "assets/images/broker-logos/abi.png";
		label = "ABInBev";
	} else if (name === "arrive" || name === "787104") {
		style = skinny ? { width: "64px", height: "15px" } : { width: "90px", height: "28px" };
		src = "assets/images/broker-logos/arrive.png";
		label = "Arrive";
	} else if (name === "cargobot" || name === "19485") {
		style = skinny ? { width: "16px", height: "14px" } : { width: "26px", height: "24px" };
		src = "assets/images/broker-logos/cargobot@3x.png";
		label = "Cargobot";
		noLogoName = true;
	} else if (name === "convoy" || name === "917364") {
		style = skinny ? { width: "56px", height: "15px" } : { width: "80px", height: "22px" };
		src = "assets/images/broker-logos/convoy.jpg";
		label = "Convoy";
	} else if (name === "coyote" || name === "561135") {
		style = skinny ? { width: "76px", height: "12px" } : { width: "100px", height: "16px" };
		src = "assets/images/broker-logos/coyote.png";
		label = "Coyote";
	} else if (name === "chrobinson" || name === "384859") {
		style = skinny ? { width: "14px", height: "14px" } : { width: "24px", height: "24px" };
		src = "assets/images/broker-logos/chrobinson@3x.png";
		label = "CH Robinson";
		noLogoName = true;
	} else if (name === "dat") {
		style = skinny ? { width: "46px", height: "14px" } : { width: "72px", height: "20px" };
		src = "assets/images/broker-logos/dat.png";
		label = "DAT";
		aggregator = true;
	} else if (name === "edge") {
		style = skinny ? { width: "70px", height: "15px" } : { width: "90px", height: "24px" };
		src = "assets/images/broker-logos/edge.png";
		label = "Edge Capacity";
	} else if (name === "emerge" || name === "945637") {
		style = skinny ? { width: "46px", height: "14px" } : { width: "76px", height: "22px" };
		src = "assets/images/broker-logos/emerge@3x.png";
		label = "Emerge";
	} else if (name === "fedex" || name === "179059") {
		style = skinny ? { width: "40px", height: "12px" } : { width: "80px", height: "22px" };
		src = "assets/images/broker-logos/fedex@3x.png";
		label = "Fedex";
	} else if (name === "loadsmart" || name === "872918") {
		style = skinny ? { width: "80px", height: "14px" } : { width: "110px", height: "20px" };
		src = "assets/images/broker-logos/loadsmart.png";
		label = "Loadsmart";
	} else if (name === "nolan" || name === "567093") {
		style = skinny ? { width: "15px", height: "14px" } : { width: "32px", height: "30px" };
		src = "assets/images/broker-logos/nolan.png";
		label = "NTG";
		noLogoName = true;
	} else if (name === "parade") {
		style = skinny ? { width: "55px", height: "16px" } : { width: "80px", height: "22px" };
		src = "assets/images/broker-logos/parade2.png";
		label = "Parade";
		aggregator = true;
	} else if (name === "pepsico") {
		style = skinny ? { width: "28px", height: "14px" } : { width: "40px", height: "24px" };
		src = "assets/images/broker-logos/pepsico@3x.png";
		label = "Pepsico";
	} else if (name === "redwood" || name === "412533") {
		style = skinny ? { width: "18px", height: "14px" } : { width: "36px", height: "24px" };
		src = "assets/images/broker-logos/redwood@3x.png";
		label = "Redwood";
		noLogoName = true;
	} else if (name === "schneider") {
		style = skinny ? { width: "70px", height: "14px" } : { width: "80px", height: "20px" };
		src = "assets/images/broker-logos/schneider@3x.png";
		label = "Schneider";
	} else if (name === "smarthop") {
		style = skinny ? { width: "36px", height: "10px" } : { width: "50px", height: "16px" };
		src = "assets/images/broker-logos/smarthop.png";
		label = "SmartHop";
		aggregator = true;
	} else if (name === "tql" || name === "322572") {
		style = skinny ? { width: "16px", height: "14px" } : { width: "26px", height: "24px" };
		src = "assets/images/broker-logos/tql@3x.png";
		label = "TQL";
		noLogoName = true;
	} else if (name === "uber" || name === "uber-fleet" || name === "987790") {
		style = skinny ? { width: "38px", height: "12px" } : { width: "60px", height: "20px" };
		src = "assets/images/broker-logos/uber@3x.png";
		label = "Uber";
	} else if (name === "newtrul") {
		style = skinny ? { width: "28px", height: "16px" } : { width: "50px", height: "28px" };
		src = "assets/images/broker-logos/newtrul.png";
		label = "Newtrul";
		aggregator = true;
	} else if (name === "echo" || name === "511639") {
		style = skinny ? { width: "54px", height: "12px" } : { width: "72px", height: "16px" };
		src = "assets/images/broker-logos/echo.png";
		label = "Echo";
	}
	return { style, src, label, aggregator, noLogoName };
};

const getLogoImage = (config) => {
	return (
		<Image
			disableSpinner={true}
			disableTransition={true}
			imageStyle={config.style}
			style={{ ...config.style, paddingTop: null }}
			color="none"
			src={config.src}
		/>
	);
};

const createDestinationView = (trip) => {
	const dest_blacklisted = trip?.flexibleReasons?.includes("STATES_BLACKLISTED");

	return (
		<div className="whitespace-nowrap flex flex-row md:mt-0 md:mb-0 ">
			<Typography
				component={"div"}
				className={"text-11 lg:text-12 text-black-600 " + (dest_blacklisted ? "line-through" : "")}
			>
				{trip?.destination}
			</Typography>
		</div>
	);
};

const createOriginView = (trip, skinny, variant) => {
	return (
		<div className="whitespace-nowrap flex flex-row md:mt-0 md:mb-0 ">
			<Typography component={"div"} className={"text-11 lg:text-12 text-black-600 "}>
				{trip?.origin}
				{trip?.distance_to_origin >= 0 ? ` (DH-O ${trip?.distance_to_origin})` : ""}
			</Typography>
		</div>
	);
};

const getMVIStyle = (mvi) => {
	let textMVI = "";
	let colorBar = {};
	if (mvi < 33 && mvi >= 0) {
		colorBar = { firstBar: "bg-blue-900", secondBar: "bg-gray-400", thirdBar: "bg-gray-400" };
		textMVI = "Fewer options for getting a reload";
	} else if (mvi < 66 && mvi >= 33) {
		colorBar = { firstBar: "bg-blue-900", secondBar: "bg-blue-900", thirdBar: "bg-gray-300" };
		textMVI = "Average options for getting a reload";
	} else if (mvi >= 66) {
		colorBar = { firstBar: "bg-blue-900", secondBar: "bg-blue-900", thirdBar: "bg-blue-900" };
		textMVI = "Many of options for getting a reload";
	}

	return { textMVI, colorBar };
};

const createMPIViewManual = (mci, mvi, skinny, extraSmall) => {
	let textMPI,
		textMVI = null;

	if (mci > 0) {
		mci = parseInt(mci * 100);
		textMPI = mci >= 90 ? "Best" : mci >= 80 ? "Great" : mci >= 70 ? "Good" : mci >= 60 ? "Medium" : "Bad";
	}

	if (mvi > 0) {
		mvi = mvi * 100;
		textMVI = getMVIStyle(mvi).textMVI;
	}

	if (mci) {
		return (
			<TooltipClickAwayView
				classes={{ tooltip: "drop-shadow-md " }}
				title={
					<Typography color="inherit" className={"text-12 p-6"} component={"div"}>
						{`Market Profitability Index: ${textMPI} \n\n`}
						<br />
						<br />
						{`Reload Score: ${textMVI} `}
					</Typography>
				}
			>
				<div className="flex flex-col">
					{mci && createMciIconView(mci, skinny, extraSmall)}
					{mci && mvi && mvi > 0 && createMviIconView(mvi, skinny, extraSmall)}
				</div>
			</TooltipClickAwayView>
		);
	} else {
		return <div className="text-14 md:text-13 py-2">-</div>;
	}
};

const createMPIView = (trip, skinny, extraSmall, showText, variant) => {
	let mci;
	let textMPI = "";
	if (trip?.rank_mci > 0) {
		mci = parseInt(trip?.rank_mci * 100);
		textMPI = mci >= 90 ? "Best" : mci >= 80 ? "Great" : mci >= 70 ? "Good" : mci >= 60 ? "Medium" : "Bad";
	}

	let mvi;
	let textMVI;
	if (trip?.rank_mvi && trip?.rank_mvi > 0) {
		mvi = trip?.rank_mvi * 100;
		textMVI = getMVIStyle(mvi).textMVI;
	}

	if (mci) {
		return (
			<TooltipClickAwayView
				classes={{ tooltip: "drop-shadow-md " }}
				title={
					<Typography
						color="inherit"
						className={"text-11 md:text-12 p-6 font-semibold " + (extraSmall ? "text-11" : "text-11 md:text-12")}
						component={"div"}
					>
						{`Market Profitability Index: ${textMPI} \n\n`}
						{textMVI && (
							<>
								<br />
								<br />
								{`Reload Score: ${textMVI} `}
							</>
						)}
					</Typography>
				}
			>
				<div className="flex flex-col">
					{mci && createMciIconView(mci, skinny, extraSmall, showText, variant)}
					{mci && mvi && mvi > 0 && createMviIconView(mvi, skinny, extraSmall)}
				</div>
			</TooltipClickAwayView>
		);
	} else {
		return <div className="text-14 md:text-13 py-2">-</div>;
	}
};

const createMciIconView = (mci, skinny, extraSmall, showFullText, variant) => {
	let mciIcon;
	let mciColor;
	let mciOpacity;

	if (mci > 0) {
		mci = parseInt(mci);
	}

	if (mci >= 60) {
		mciIcon = "local_fire_department";
		mciColor = mci >= 90 ? "text-red-500" : mci >= 80 ? "text-orange-700" : "text-yellow-800";
		mciOpacity = mci >= 90 ? "opacity-100" : mci >= 80 ? "opacity-90" : mci >= 70 ? "opacity-70" : "opacity-30";
	} else if (mci > 0) {
		mciIcon = "ac_unit";
		mciColor = "text-blue-500";
		mciOpacity = mci < 30 ? "opacity-100" : mci < 40 ? "opacity-80" : mci < 50 ? "opacity-60" : "opacity-30";
	}

	return mci > 0 ? (
		<div className="whitespace-nowrap flex flex-row">
			<Icon
				className={
					(extraSmall
						? " text-12 ml:text-13 mr-2 -mb-4 "
						: skinny
						? " text-13 ml:text-14 mr-2 mt-1 "
						: showFullText
						? variant === "side"
							? "text-25 mr-4 "
							: "text-18 mr-4 "
						: " text-18 mr-4 ") +
					(mciColor ?? "") +
					" " +
					(mciOpacity ?? "")
				}
			>
				{mciIcon}
			</Icon>
			<Typography
				component={"div"}
				className={
					"break-words " +
					(extraSmall
						? " text-9 ml:text-10 pr-4 "
						: skinny
						? " text-10 ml:text-11 pr-10 "
						: showFullText
						? (variant === "side" ? " text-13 ml:text-14 " : " text-11 ml:text-12 ") + " self-center font-normal pr-10 "
						: " text-12 ml:text-13 pr-10 ")
				}
			>
				{showFullText ? `MPI ${mci}` : mci}
			</Typography>
		</div>
	) : (
		<Typography className={"text-14 md:text-13 pr-10 break-words"}>-</Typography>
	);
};

const createMviIconView = (mvi, skinny, extraSmall) => {
	const colorBar = getMVIStyle(mvi).colorBar;
	return (
		<div className={"flex justify-center " + (extraSmall ? " mt-1 mb-1 " : " mt-2 mb-2 ")}>
			<div className={`h-1 ${extraSmall ? "w-6" : "w-8"} mr-2 ${colorBar.firstBar}`}></div>
			<div className={`h-1 ${extraSmall ? "w-6" : "w-8"} mr-2 ${colorBar.secondBar}`}></div>
			<div className={`h-1 ${extraSmall ? "w-6" : "w-8"} ${colorBar.thirdBar}`}></div>
		</div>
	);
};

const createDestMciView = (data, classes, dhdVisible = false) => (
	<div className="flex flex-col w-full">
		{createDestinationView(data, dhdVisible)}
		<div className="flex flex-wrap align-items-start">
			<Typography className={"text-13 md:text-14 pr-10 " + classes?.mciTitle}>MPI: </Typography>
			{createMPIView(data)}
			<TooltipClickAwayView
				title={MCI_DEST_DESCRIPTION}
				classes={{ tooltip: "drop-shadow-md " + (classes?.tooltip ?? "") }}
			>
				<Icon className={"text-16 pt-1 " + classes?.tooltipContent}>info</Icon>
			</TooltipClickAwayView>
		</div>
	</div>
);

const createCreditScoreView = (data, classes = "") => {
	const label = (
		<Typography
			component={"div"}
			className={`cursor-pointer text-10 ml:text-11 text-grey pl-3 pr-3 ml:px-4 mr-4 font-menium w-32 ml:w-48 ${classes}`}
		>
			CS {data.creditScore ?? " -"}
		</Typography>
	);

	if (!data.creditScore) {
		return label;
	}
	let risk = data.creditScore < 70 ? "high risk" : data.creditScore < 87 ? "medium risk" : "low risk";
	return createTooltip(
		label,
		<React.Fragment>
			<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-semibold">
				{!data.smartpayElegible ? "No " : ""}Eligible for SmartPay
			</Typography>
			<Typography color="inherit" variant="body2" className="p-6 font-light">
				This load is {!data.smartpayElegible ? "no " : ""} eligible for SmartPay factoring
			</Typography>
			<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-semibold">
				{`Credit score - ${risk.toUpperCase()}`}
			</Typography>
			<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
				{`This broker has a credit score of `}
				<span className={"font-semibold "}>{data.creditScore}</span>
				{`, It’s considered a ${risk} broker`}
			</Typography>
			<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
				{`0-69 high-risk broker`}
			</Typography>
			<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
				{`70-86 medium-risk broker`}
			</Typography>
			<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
				{`87-100 low-risk broker`}
			</Typography>
			{data.creditScoreSource && (
				<Typography component={"div"} color="inherit" variant="body2" className="p-6 font-light">
					{`Source: ${data.creditScoreSource}`}
				</Typography>
			)}
		</React.Fragment>,
		""
	);
};

const createBrokerView = (
	trip,
	carrier,
	dispatch,
	snackbar,
	{ skinny, variant, mobileScreen, smallScreen, showSource, showInfo = true }
) => {
	const onClickSummary = async (mcnumber) => {
		try {
			const brokerFound = await getBrokerSearch(mcnumber, carrier);

			const dataIds = {
				brokerId: brokerFound?._id,
				carrierId: carrier,
				cache: brokerFound,
			};

			if (!brokerFound) {
				showErrorSnackbar(snackbar, { message: "Broker not found" });
				return;
			}

			dispatch &&
				dispatch(
					openLoadedFormDialog({
						dataIds,
						viewId: "BROKER_INFO_VIEW",
						mode: "VIEW",
					})
				);
		} catch (error) {
			showErrorSnackbar(snackbar, error);
		}
	};

	let icon;
	let iconColor = "text-black";
	let brokerBlacklisted = false;
	if (trip?.flexibleReasons?.includes("BROKER_POSSIBLE_SCAMMER")) {
		icon = "report";
		iconColor = "text-red";
	} else if (trip?.flexibleReasons?.includes("BROKER_BLACKLISTED")) {
		icon = "close";
		iconColor = "text-red";
		brokerBlacklisted = true;
	} else if (trip?.isOnBoarded) {
		icon = "done";
	} else {
		icon = null;
	}

	let colorName = variant === "dark" ? "text-white" : "text-grey-800";
	let ownerIcon = getLogoConfig(trip?.owner, true);
	let ownerLogo = ownerIcon.src ? getLogoImage(ownerIcon) : null;

	let brokeIcon = getLogoConfig(trip?.mcnumber, skinny);
	let brokeLogo = brokeIcon.src ? (
		getLogoImage(brokeIcon)
	) : (
		<Typography
			className={
				"p-0 m-0 -mt-2 -mb-2 " +
				(skinny ? " text-12 ml:text-13 " : " text-14 md:text-18 font-medium md:w-full truncate ") +
				colorName
			}
			component={"div"}
		>
			{trip?.clientid?.length < (smallScreen ? 12 : skinny ? 32 : 28)
				? trip?.clientid
				: trip?.clientid?.substring(0, smallScreen ? 12 : skinny ? 32 : 26) + "..."}
		</Typography>
	);

	if (skinny) {
		return (
			<div
				className={
					"flex items-start -mb-4 -mt-3 space-y-1" +
					(smallScreen || mobileScreen ? " flex-col-reverse " : " flex-wrap ")
				}
			>
				{showSource && (
					<div className="whitespace-nowrap flex flex-row items-center ">
						<div className="whitespace-nowrap flex flex-row items-center">
							{(smallScreen || mobileScreen) && ownerIcon.aggregator && ownerLogo && (
								<Typography className={"text-11 p-0 pr-4 -mt-2 text-grey-600"}>Powered by</Typography>
							)}
							{ownerIcon.aggregator && ownerLogo}
							{!smallScreen && !mobileScreen && (ownerIcon.aggregator || trip?.ondemand) && (
								<Typography className="text-11 text-grey-700 tracking-wide pl-4 pr-4">|</Typography>
							)}
						</div>
					</div>
				)}
				<div className="whitespace-nowrap flex flex-row items-center ">
					{createCreditScoreView(trip)}
					{showInfo && trip?.mcnumber && (
						<Typography
							component={"div"}
							className="text-blue rounded-md pr-2 cursor-pointer"
							onClick={(e) => {
								e.stopPropagation();
								onClickSummary(trip?.mcnumber);
							}}
						>
							<Icon className="text-13 ml:text-14 -mb-3">info</Icon>
						</Typography>
					)}
					{icon && <Icon className={"text-16 -mt-2 " + iconColor}>{icon}</Icon>}
					<div className="whitespace-nowrap flex flex-row items-center">
						<div
							className={
								"whitespace-nowrap flex items-center space-x-4 flex-row " + (brokerBlacklisted ? "line-through" : "")
							}
						>
							{brokeLogo}
							{brokeIcon.noLogoName ? (
								<Typography component={"div"} className={"tracking-wide text-12 ml:text-13 " + colorName}>
									{brokeIcon.label}
								</Typography>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="whitespace-nowrap flex flex-row items-center">
				{icon && <Icon className={"text-24 py-2 -ml-2 mr-8"}>{icon}</Icon>}
				<div
					className={
						"whitespace-nowrap flex flex-col flex items-start " + (showSource && ownerIcon.aggregator ? " -mb-6 " : "")
					}
				>
					<div className="whitespace-nowrap flex flex-row flex items-center">
						{brokeLogo}
						{brokeIcon.noLogoName ? (
							<Typography component={"div"} className={"pl-4 text-14 md:text-18 font-medium pt-1 " + colorName}>
								{brokeIcon.label}
							</Typography>
						) : null}
						{trip?.creditScore && createCreditScoreView(trip, "ml-3")}
					</div>
					{showSource && ownerIcon.aggregator && (
						<div className="whitespace-nowrap flex flex-row">
							<Typography className={"text-12 p-0 pr-4 -mt-2 text-grey-600"}>Powered by</Typography>
							{ownerLogo}
						</div>
					)}
				</div>
			</div>
		);
	}
};

const createSourceView = (trip) => {
	let ownerIcon = getLogoConfig(trip?.owner, true);
	let ownerLogo = ownerIcon.src ? getLogoImage(ownerIcon) : null;
	return (
		<div className="whitespace-nowrap flex flex-row items-center ">
			<div className="whitespace-nowrap flex flex-row items-center">{ownerIcon.aggregator && ownerLogo}</div>
		</div>
	);
};

const createBrokerMessageTitle = (mcnumber, clientid, owner, textClasses = "text-14 md:text-16 font-medium") => {
	let ownerIcon = getLogoConfig(owner, true);
	let ownerLogo = ownerIcon.src ? getLogoImage(ownerIcon) : null;

	let brokeIcon = getLogoConfig(mcnumber, false);
	let brokeLogo = brokeIcon.src ? (
		getLogoImage(brokeIcon)
	) : (
		<Typography className={textClasses}>
			{clientid?.length < 28 ? clientid : clientid ? clientid?.substring(0, 26) + "..." : ""}
		</Typography>
	);

	return (
		<div className="whitespace-nowrap flex flex-row items-center">
			<div className={"whitespace-nowrap flex flex-col flex items-start "}>
				<div className="whitespace-nowrap flex flex-row flex items-center">
					{brokeLogo}
					{brokeIcon.noLogoName ? (
						<Typography component={"div"} className={textClasses}>
							{brokeIcon.label}
						</Typography>
					) : null}
				</div>
				{ownerIcon.aggregator && (
					<div className="whitespace-nowrap flex flex-row">
						<Typography className={"text-12 p-0 pr-4 -mt-2 text-grey-600"}>Powered by</Typography>
						{ownerLogo}
					</div>
				)}
			</div>
		</div>
	);
};

export {
	createBrokerView,
	createBrokerMessageTitle,
	getLogoImage,
	getLogoConfig,
	createDestinationView,
	createOriginView,
	createMPIView,
	createMciIconView,
	createDestMciView,
	createCreditScoreView,
	createMviIconView,
	createMPIViewManual,
	MCI_DEST_DESCRIPTION,
	createSourceView,
};
