import Typography from "@material-ui/core/Typography";
import { motion } from "framer-motion";

function ErrorGenericPage(props) {
	return (
		<div className="flex h-full flex-col flex-1 items-center justify-center p-16">
			<div className="max-w-512 text-center">
				{props.status && (
					<motion.div
						initial={{ opacity: 0, scale: 0.6 }}
						animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
					>
						<Typography variant="h2" color="inherit" className="font-medium mb-16">
							{props.status}
						</Typography>
					</motion.div>
				)}

				{props.status === 401 ? (
					<>
						<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
							<Typography color="textSecondary" className="text-20 font-light tracking-wide">
								Your Session Expired
							</Typography>
							<Typography color="textSecondary" className="text-20 mb-14 font-light tracking-wide">
								Please Login Again
							</Typography>
						</motion.div>
					</>
				) : props.status === 403 ? (
					<>
						<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
							<Typography color="textSecondary" className="text-20 font-light tracking-wide">
								Your Access Restricted
							</Typography>
							<Typography color="textSecondary" className="text-20 mb-14 font-light tracking-wide">
								Please Contact Smarthop
							</Typography>
						</motion.div>
					</>
				) : (
					<>
						<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
							<Typography color="textSecondary" className="text-16 ml:text-20 mb-16 font-light tracking-wide">
								{props.status > 0 ? "Well, you broke the internet!" : "Service Unavailable"}
							</Typography>
						</motion.div>
						<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}>
							<Typography color="textSecondary" className="text-14 ml:text-16 mb-16 font-light tracking-wide">
								{props.status > 0
									? "Just kidding, looks like we have an internal issue, please try again in couple minutes..."
									: "Looks like you have connection issues, please try again in couple minutes..."}
							</Typography>
						</motion.div>
					</>
				)}
			</div>
		</div>
	);
}

export default ErrorGenericPage;
