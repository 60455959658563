export const TruckReminderFormConfig = () => {
	return {
		items: [
			{
				type: "group",
				content: {
					items: [
						{
							key: "repeats",
							type: "select",
							label: "Repeats",
							required: true,
							defaultValue: "Daily",
							options: [
								{
									value: "Daily",
									label: "Daily",
								},
								{
									value: "Weekly",
									label: "Weekly",
								},
								{
									value: "Monthly",
									label: "Monthly",
								},
								{
									value: "Anually",
									label: "Anually",
								},
								{
									value: "Weekdays",
									label: "Weekdays",
								},
							],
						},
						{
							key: "start_date",
							type: "picker",
							label: "Start Date",
							picker: {
								disablePast: true,
								type: "date",
							},
						},
					],
				},
			},
			{
				key: "type",
				type: "select",
				label: "Type",
				required: true,
				defaultValue: "General",
				options: [
					{
						value: "General",
						label: "General",
					},
				],
			},
			{ key: "notes", type: "text", label: "Notes", multiline: { rows: 5 }, required: true },
			{ type: "action", action: "SUBMIT" },
		],
	};
};

export const TruckReminderForm = {
	urlGET: "api/profile/carriers/:carrierId/trucks/:truckId/reminders/:reminderId",
	urlPOST: "api/profile/carriers/:carrierId/trucks/:truckId/reminders",
	urlDELETE: "api/profile/carriers/:carrierId/trucks/:truckId/reminders/:reminderId",
	urlPUT: "api/profile/carriers/:carrierId/trucks/:truckId/reminders/:reminderId",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		create: { label: "Add New Reminder" },
		edit: { label: "Edit Reminder" },
		delete: { label: "Delete Reminder" },
		view: { label: "View Reminder" },
	},
	messages: {
		delete: { success: "Reminder deleted.", disabled: false },
		create: { success: "Reminder added.", disabled: false },
		edit: { success: "Reminder updated.", disabled: false },
	},
	content: {
		edit: TruckReminderFormConfig(),
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to permanently delete this reminder?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};

export default TruckReminderForm;
