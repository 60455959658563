// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { centsToDollars } from "app/main/utils/financeUtils";

const trackerFormatter = (amount, rule_execution) => {
	return `${formatCurrency((amount * rule_execution) / 100)}/${rule_execution ?? 0}`;
};

const payrollTimeZoneSection = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "start_of_week",
					type: "select",
					label: "Start of Week",
					resource: "PAYROLL_TIMEZONE_START_OF_WEEK",
					options: [
						{
							value: "7",
							label: "Sunday",
						},
						{
							value: "1",
							label: "Monday",
						},
						{
							value: "2",
							label: "Tuesday",
						},
						{
							value: "3",
							label: "Wednesday",
						},
						{
							value: "4",
							label: "Thursday",
						},
						{
							value: "5",
							label: "Friday",
						},
						{
							value: "6",
							label: "Saturday",
						},
					],
				},
			],
		},
	},
];

const payrollReferenceSection = [
	{
		key: "counter_next_ref",
		type: "text",
		label: "Payroll Reference",
	},
];

const WEEKLY = "WEEKLY";
const BI_WEEKLY = "BI_WEEKLY";
const MONTHLY = "MONTHLY";
const NUMBER_DAYS = "NUMBER_DAYS";
const NUMBER_WEEKS = "NUMBER_WEEKS";
const NUMBER_MONTHS = "NUMBER_MONTHS";
const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const CREDIT = "CREDIT";
const DEBIT = "DEBIT";

const scheduleOptions = {
	[WEEKLY]: { value: WEEKLY, label: "Weekly" },
	[BI_WEEKLY]: { value: BI_WEEKLY, label: "Bi-Weekly" },
	[MONTHLY]: { value: MONTHLY, label: "Monthly" },
};

const programOptions = {
	[NUMBER_DAYS]: {
		value: NUMBER_DAYS,
		label: "By Day",
	},
	[NUMBER_WEEKS]: {
		value: NUMBER_WEEKS,
		label: "By Week",
	},
	[NUMBER_MONTHS]: {
		value: NUMBER_MONTHS,
		label: "By Month",
	},
};

const statusOptions = {
	[ACTIVE]: {
		value: ACTIVE,
		label: "Active",
	},
	[INACTIVE]: {
		value: INACTIVE,
		label: "Inactive",
	},
};

const directionOptions = {
	[CREDIT]: {
		value: CREDIT,
		label: "Addition",
	},
	[DEBIT]: {
		value: DEBIT,
		label: "Deduction",
	},
};
const scheduleDescription = "Determines how often the rule executes based on start date and status";
const directionDescription = "Determines if rule is an addition or deduction in payee payroll";
const amountDescription = "Amount to be included in payee payroll report";
const nameDescription = "Description is used in the ledger for Payee to understand the purpose of this rule";
const statusDescription =
	"Determines if this rule is eligible for execution. End Date must be a future date to remain active";

const startDateDescription =
	"Date of first rule execution. Rules are executed at end of calendar day. Rules automatically recur per schedule until End Date arrives or rule is inactive";

const endDateDescription = "Date to inactivate rule, stop all processing. Rule terminates at beginning of business day";
const payPeriodDescription = "Payroll requires a recurring rule or manual entry to see amounts in ledger";

const recurringDeductionItems = ({ isInvestor, mode }) => [
	{
		type: "group",
		content: {
			items: [
				{
					key: "name",
					type: "text",
					label: "Description",
					required: true,
					field: { maxLength: 100 },
					description: nameDescription,
				},
				{
					key: "total_amount",
					builder: (item) => centsToDollars(item?.total_amount),
					type: "currency",
					label: "Amount",
					required: true,
					field: { manageCents: true },
					min: 0,
					description: amountDescription,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "direction",
					type: "select",
					label: "Direction",
					defaultValue: CREDIT,
					required: true,
					options: Object.values(directionOptions).map((value) => value),
					description: directionDescription,
				},
				{
					key: "schedule",
					type: "select",
					label: "Schedule",
					required: true,
					defaultValue: WEEKLY,
					options: Object.values(scheduleOptions).map((value) => value),
					description: scheduleDescription,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "start_date",
					type: "picker",
					label: "Start Date",
					required: true,
					picker: {
						notClearable: true,
						initialValAsMin: true,
						disablePast: true,
						type: "date",
						classes: {},
					},
				},
				{
					key: "end_date",
					type: "picker",
					label: "End Date",
					picker: {
						disablePast: true,
						type: "date",
						classes: {},
					},
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "status",
					type: "select",
					label: "Status",
					required: true,
					defaultValue: "ACTIVE",
					options: Object.values(statusOptions).map((value) => value),
					description:
						"Determines if this rule is eligible for execution. End Date must be a future date to remain active",
				},
				{
					key: "next_execution_date",
					type: "picker",
					label: "Next Execution Date",
					required: true,
					picker: {
						notClearable: true,
						initialValAsMin: true,
						disablePast: true,
						type: "date",
						classes: {},
					},
				},
			],
		},
	},
	...(isInvestor
		? [
				{
					type: "group",
					content: {
						items: [
							{
								key: "truck",
								type: "autocomplete",
								label: "Truck",
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/carriers/:carrierId/investor/:userId/trucks",
									preloadDefault: true,
									params: { options_format_show_carrier: true, options_active_only: true },
								},
							},
						],
					},
				},
		  ]
		: []),
];

/**
 * Payroll Fields
 **/

const PAYMENT_CONFIG = {
	key: "payment_type",
	type: "select",
	label: "Payment Type",
	resource: "DRIVER_FORM_PAYMENT_TYPE_POPUP",
	required: true,
	select: { alignment: "horizontal" },
	options: [
		{
			value: "PAY_BY_PERCENTAGE",
			label: "Gross Income (%)",
			showItems: [
				{
					key: "gross",
					type: "percentage",
					label: "Percentage",
					translate: "PERCENTAGE",
					required: true,
					min: 1,
					max: 100,
				},
			],
		},
		{
			value: "PAY_BY_PROFIT",
			label: "Profit (%)",
			showItems: [
				{
					key: "profit_pct",
					type: "percentage",
					label: "Percentage",
					translate: "PERCENTAGE",
					required: true,
					min: 1,
					max: 100,
				},
			],
		},
		{
			value: "PAY_BY_MILES",
			label: "Pay By Miles",
			translate: "PAY_BY_MILES",
			showItems: [
				{
					key: "empty_miles_payment",
					type: "currency",
					label: "Empty miles payment",
					translate: "EMPTY_MILES_PAYMENT",
					min: 0,
					max: 1000,
					defaultValue: 0,
					required: true,
					field: { manageCents: false },
				},
				{
					key: "loaded_miles_payment",
					type: "currency",
					label: "Loaded miles payment",
					translate: "LOADED_MILES_PAYMENT",
					min: 0,
					max: 1000,
					defaultValue: 0,
					required: true,
					field: { manageCents: false },
				},
			],
		},
		{
			value: "FLAT_FEE",
			label: "Flat Fee",
			translate: "FLAT_FEE",
			showItems: [
				{
					key: "flat_fee_amount",
					type: "currency",
					label: "Amount",
					translate: "FLAT_FEE_AMOUNT",
					min: 0,
					required: true,
					field: { manageCents: false },
				},
			],
		},
	],
};

const EXTRA_DROPS = {
	key: "extra_drops",
	type: "currency",
	label: "Extra Drop Payment",
	min: 0,
	max: 1000,
	defaultValue: 0,
	resource: "DRIVER_FORM_EXTRA_DROP_PAYMENT_POPUP",
	field: { manageCents: false },
};

const MINIMUM_PAYMENT = {
	type: "group",
	content: {
		items: [
			{
				key: "minimum_payment_type",
				type: "select",
				label: "Minimum Payment Type",
				required: true,
				defaultValue: "TRIP",
				options: [
					{
						value: "PERIOD",
						label: "Per Pay Period",
					},
					{
						value: "TRIP",
						label: "Per Trip ",
					},
				],
			},
			{
				key: "minimum_payment",
				type: "currency",
				label: "Minimum Payment",
				required: true,
				defaultValue: 0,
				min: 0,
				field: { manageCents: false },
			},
		],
	},
};

const DISTRIBUTION_PRIORITY_VALUE = {
	key: "distribution_priority_value",
	type: "percentage",
	label: "Distribution Priority Percentage",
	required: true,
	min: 0,
	max: 100,
};

const DISTRIBUTION_PRIORITY = {
	key: "distribution_priority",
	type: "select",
	label: "Distribution Priority",
	defaultValue: "CARRIER_FEE",
	required: true,
	description: payPeriodDescription,
	options: [
		{
			value: "CARRIER_FEE",
			label: "Carrier Fee",
		},
		{
			value: "INVESTOR_SHARE",
			label: "Investor Share",
		},
	],
};

const PAY_PERIOD = {
	key: "pay_period",
	type: "select",
	label: "Pay Period",
	defaultValue: "MANUAL",
	required: true,
	description: payPeriodDescription,
	options: [
		{
			value: "MANUAL",
			label: "Manual",
		},
		{
			value: "DAILY",
			label: "Daily",
		},
		{
			value: "WEEKLY",
			label: "Weekly",
		},
		{
			value: "BI_WEEKLY",
			label: "Bi-Weekly",
		},
	],
};

const BONUS_STRUCTURE = {
	key: "bonus_structure",
	type: "select",
	label: "Bonus Structure",
	required: true,
	defaultValue: "__NOT_SELECTED__",
	select: { alignment: "horizontal" },
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "None",
		},
		{
			value: "MILES",
			label: "Miles",
			showItems: [
				{
					key: "bonus_miles_number_elegibility",
					type: "number",
					label: "Number of Miles to Reach Bonus Elegibility",
					required: true,
				},
			],
		},
		{
			value: "DAYS_LOADED",
			label: "Days Loaded",
			showItems: [
				{
					key: "bonus_days_number_elegibility",
					type: "number",
					label: "Number of Loaded Days to Reach Bonus Elegibility",
					required: true,
				},
			],
		},
		{
			value: "REVENUE_EARNED",
			label: "Revenue Earned",
			showItems: [
				{
					key: "bonus_revenue_target_elegibility",
					type: "currency",
					label: "Revenue Target to Reach Bonus Elegibility",
					required: true,
					field: { manageCents: false },
				},
			],
		},
	],
};

const DRIVER_PAY_FIELDS = [PAYMENT_CONFIG, EXTRA_DROPS];

const FUEL_PAYROLL_ELIGIBLE_FIELD = (showPercentage) => ({
	key: "fuelPayrollEligible",
	type: "select",
	label: "Include Fuel Charges to Payroll",
	defaultValue: "DISABLED",
	select: { alignment: "horizontal" },
	options: [
		{
			value: "DISABLED",
			label: "Disabled",
		},
		{
			value: "ENABLED",
			label: "Enabled",
			showItems: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "fuelChargePrice",
								type: "select",
								label: "Charge Price",
								required: true,
								defaultValue: "DISCOUNTED",
								options: [
									{
										value: "DISCOUNTED",
										label: "Discounted Amount",
									},
									{
										value: "RETAILS",
										label: "Retail Amount",
									},
								],
							},
							showPercentage && {
								key: "fuelPercentage",
								type: "percentage",
								label: "Percentage",
								translate: "PERCENTAGE",
								defaultValue: 100,
								required: true,
								min: 1,
								max: 100,
							},
						],
					},
				},
			],
		},
	],
});

const LAST_CLOSED_PAYROLL_DATE = {
	key: "last_payroll_period_close_date",
	type: "picker",
	label: "Last Closing Period Date",
	viewOnly: true,
	picker: {
		notClearable: true,
		initialValAsMin: true,
		disablePast: true,
		type: "date",
		classes: {},
	},
};

const BOOKING_FIELD = {
	key: "booking_fee_type",
	type: "select",
	label: "Dispatcher Booking Fee",
	required: true,
	select: { alignment: "horizontal" },
	options: [
		{
			value: "PAY_BY_PERCENTAGE",
			label: "Pay By Percentage",
			showItems: [
				{
					key: "booking_fee_percentage",
					type: "percentage",
					label: "Value",
					translate: "PAY_BY_PERCENTAGE",
					required: true,
					min: 1,
					max: 100,
				},
			],
		},
		{
			value: "FLAT_FEE",
			label: "Flat Fee",
			translate: "FLAT_FEE",
			showItems: [
				{
					key: "booking_fee_flat_amount",
					type: "currency",
					label: "Amount",
					translate: "FLAT_FEE_AMOUNT",
					min: 0,
					required: true,
					field: { manageCents: false },
				},
			],
		},
	],
};

const INVESTOR_PAYROLL_FIELDS = ({ fuelEnabled, mode } = {}) => [
	...(fuelEnabled ? [FUEL_PAYROLL_ELIGIBLE_FIELD(false)] : []),
	DISTRIBUTION_PRIORITY,
	DISTRIBUTION_PRIORITY_VALUE,
	PAY_PERIOD,
	...(mode === "VIEW" ? [LAST_CLOSED_PAYROLL_DATE] : []),
];

const DISPATCHER_PAYROLL_FIELDS = ({ mode } = {}) => [
	PAY_PERIOD,
	BOOKING_FIELD,
	...(mode === "VIEW" ? [LAST_CLOSED_PAYROLL_DATE] : []),
];

const DRIVER_PAYROLL_FIELDS = ({ fuelEnabled, mode } = {}) => [
	...(fuelEnabled ? [FUEL_PAYROLL_ELIGIBLE_FIELD(true)] : []),
	PAYMENT_CONFIG,
	EXTRA_DROPS,
	MINIMUM_PAYMENT,
	PAY_PERIOD,
	BONUS_STRUCTURE,
	...(mode === "VIEW" ? [LAST_CLOSED_PAYROLL_DATE] : []),
];

export {
	DRIVER_PAY_FIELDS,
	DRIVER_PAYROLL_FIELDS,
	INVESTOR_PAYROLL_FIELDS,
	DISPATCHER_PAYROLL_FIELDS,
	recurringDeductionItems,
	// FIXME all options should be just defined in one single place,
	// in edit it's selector and in view it's text, it should not
	// be implemented that way
	scheduleOptions,
	programOptions,
	statusOptions,
	directionOptions,
	trackerFormatter,
	// FIXME all descriptions should be just in one single field that is used in mupltiple places
	scheduleDescription,
	directionDescription,
	amountDescription,
	nameDescription,
	statusDescription,
	startDateDescription,
	endDateDescription,
	payrollTimeZoneSection,
	payrollReferenceSection,
};
