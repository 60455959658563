const createValidate2FAForm = (type, email, phone) => ({
	noInitValidation: true,
	items: [
		{
			key: "message",
			type: "message",
			label: `To login please enter verification code, we've sent a confirmation code to your ${
				type === "EMAIL" ? "email" : "phone"
			} ${type === "EMAIL" ? email : phone}`,
		},
		{
			key: "code",
			type: "text",
			label: "Validation Code",
			required: true,
			maxCharacter: 10,
		},
		{
			type: "action",
			label: "Continue",
			action: "SUBMIT",
			button: {
				classes: { root: "mb-16" },
			},
		},
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
				classes: { root: "mb-16" },
			},
		},
	],
});

export default createValidate2FAForm;
