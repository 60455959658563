import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// Components
import Typography from "@material-ui/core/Typography";
import SmartHopWalletLanding from "./landing/SmartHopWalletLanding";

// Common
import PageWrapper from "app/main/common/PageWrapper";
import { loadingViewComponent } from "app/main/common/LoadingView";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { SMARTHOP_WALLET_SECTION_LABEL } from "app/main/utils/financeUtils";

// Tools
import FormSection from "app/main/tools/FormSection";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Forms
import smarthopWalletApplicationForm from "@smarthop/form/configs/smartHopWalletApplicationForm";
import smarthopWalletApplicationFormForm from "@smarthop/form/configs/smartHopWalletApplicationFormForm";

// Services
import { getCarrierId, getRoleLabel } from "app/services/LoginService";
import { UnitFinanceService } from "app/services/unitFinanceServices";

const SmartHopWalletApplicationPage = (props) => {
	const dispatch = useDispatch();
	const params = useParams();
	const snackbar = useSnackbar();

	const [applicationData, setApplicationData] = useState(null);

	const isAdmin = getRoleLabel() === "administrator";
	const isCarrier = getRoleLabel() === "carrier";

	const carrierId = props?.params?.carrierId ?? params.carrierId ?? (isCarrier ? getCarrierId() : undefined);
	const canReApply = isAdmin && applicationData?.data?.applications[0]?.data?.attributes.status === "Denied";

	const handleApplyForUnit = async () => {
		const applicationResult = await UnitFinanceService.apply({
			applicantId: carrierId,
			config: {
				redirectUrl: window.location.href,
			},
			isAdmin,
		});

		if (!applicationResult.url) {
			showSnackbar(snackbar, `Something went wrong.`, "error", 5000);
		} else {
			window.location.assign(applicationResult.url);
		}
	};

	const handleAllowApplicantReapply = async () => {
		const allowResult = await UnitFinanceService.allowApply({
			applicantId: carrierId,
		});

		if (allowResult.ok) {
			showSnackbar(snackbar, `Application enabled`, "success", 5000);
			dispatch(incrementDataRevision({ event: "smarthopWalletRevision" }));
		} else {
			showSnackbar(snackbar, `Something went wrong.`, "error", 5000);
		}
	};

	const fetchData = useCallback(async () => {
		const applicationData = await UnitFinanceService.getApplicationData({ applicantId: carrierId, isAdmin });
		// Check there is an ongoing application_form but is at first stage
		if (
			applicationData.status === "APPLICATION" &&
			applicationData.data?.applications[0]?.data?.attributes.status === "EnterBusinessInformation"
		) {
			// Change status to APPLICATION_PRISTINE
			applicationData.status = "APPLICATION_PRISTINE";
		}
		setApplicationData(applicationData);
	}, [carrierId, isAdmin]);

	useEffect(() => fetchData(), [fetchData]);

	const allowNewApplicationAction = {
		action: {
			confirmation: {
				message: "Please confirm you want to allow applicant to re-apply",
				onAccept: () => handleAllowApplicantReapply(),
			},
		},
		mode: "VIEW",
		label: "Allow new application",
		auth: ["administrator", "ops support"],
	};

	const applicationFormDoneStatuses = ["PendingReview", "Pending", "Approved", "Denied", "Canceled"];

	const formConfig = [
		{
			exp: (data) =>
				data?.status === "APPLICATION" &&
				!applicationFormDoneStatuses.includes(data?.data?.applications[0]?.data?.attributes?.status),
			key: "application_form_information",
			title: "Ongoing Application Form",
			form: smarthopWalletApplicationFormForm({ isCarrier, isAdmin }),
			titleActions: [],
		},
		{
			exp: (data) =>
				data?.status === "APPLICATION" &&
				applicationFormDoneStatuses.includes(data?.data?.applications[0]?.data?.attributes?.status),
			key: "application_information",
			title: "Application Submitted",
			form: smarthopWalletApplicationForm({ isAdmin }),
			titleActions: [],
			actions: [...(canReApply ? [allowNewApplicationAction] : [])],
		},
	];

	const renderForm = formConfig.filter((item) => item.exp(applicationData));
	const createForm = () => {
		return renderForm.map((form) => (
			<FormSection
				key={form.key}
				title={form.title}
				formId={form.formId}
				form={form.form}
				actions={form.actions}
				dataIds={{ entityId: carrierId }}
			/>
		));
	};

	const renderApplicationDetails = () => {
		return (
			<>
				{["NOT_A_CUSTOMER", "APPLICATION_PRISTINE"].includes(applicationData.status) ? (
					isCarrier ? (
						<SmartHopWalletLanding handleApplyForUnit={handleApplyForUnit} applicationData={applicationData} />
					) : (
						<div style={{ margin: "200px auto" }}>
							<Typography key={"no_carrier_msg"} color="primary" className="font-semibold">
								{`Only a customer can apply for a ${SMARTHOP_WALLET_SECTION_LABEL} Account`}
							</Typography>
						</div>
					)
				) : (
					<PageWrapper
						title={SMARTHOP_WALLET_SECTION_LABEL}
						restrictions={["ONBOARDING", "ACTIVATION", "TIER_BASIC"]}
						variant="narrow"
					>
						{createForm()}
					</PageWrapper>
				)}
			</>
		);
	};

	return !applicationData ? loadingViewComponent() : renderApplicationDetails();
};

export default SmartHopWalletApplicationPage;
