const carrierOnboardingDocUploadTypeForm = {
	urlGET: "api/profile/carriers/:carrierId/details/documents",
	urlPUT: "api/profile/carriers/:carrierId/details/documents-upload-type",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Document Uploading Type" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			submitOnChange: true,
			items: [
				{
					key: "documentUploadType",
					type: "select",
					label: "Way to Upload Documents",
					required: true,
					options: [
						{
							value: "ONE_BY_ONE",
							label: "Upload one by one (multiple PDF files)",
						},
						{
							value: "SINGLE_PACKET",
							label: "As a single packet (one PDF file)",
						},
					],
				},
			],
		},
	},
};

export default carrierOnboardingDocUploadTypeForm;
