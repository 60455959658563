const platformSubscriptionDiscountForm = {
	urlPOST: "billing/carriers/:carrierId/platform-plans/discount",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision"],
	header: { create: { label: "Add Discount" } },
	content: {
		create: {
			items: [
				{ key: "amount", type: "number", label: "$ Amount", required: true, max: 999, min: 1 },
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default platformSubscriptionDiscountForm;
