import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

function LoadStrategyWarning(props) {
	return (
		<div className="flex py-14 px-10 ml:mx-10 mb-10 items-center rounded-8 justify-center bg-orange-600">
			<Icon className={"text-white text-20 ml:text-28 mr-10"}>warning</Icon>
			<Typography className={"text-white text-13 ml:text-15 font-normal"}>
				Please note: The following are projections based on current market conditions and are subject to change.
				SmartHop's strategies have a high probability of meeting estimated profits; however, exact amounts can not be
				guaranteed.
			</Typography>
		</div>
	);
}

export default LoadStrategyWarning;
