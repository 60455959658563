// Services
import { getRoleLabel } from "app/services/LoginService";

const isAdmin = getRoleLabel() === "administrator";

const dispatchingPlanSummaryForm = {
	urlGET: "billing/carriers/:carrierId/dispatching-plan",
	urlPOST: "billing/carriers/:carrierId/customer-plan/dispatching",
	idKey: "_id",
	header: {
		view: { label: "Dispatching Plan" },
		create: { label: "Assign Dispatching Plan" },
	},
	triggerEvent: "planRevision",
	listenEvent: "planRevision",
	messages: { create: { success: "Dispatching plan assigned." } },
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							...(isAdmin
								? [
										{
											key: "nickname",
											type: "text",
											label: "Name",
											translate: "",
										},
								  ]
								: []),
							{
								key: "active",
								type: "text",
								translate: "",
								label: "Status",
								builder: (item) => (item?.active ? "Active" : "Inactive"),
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "flat_fee_amount",
								type: "currency",
								label: "Flat Fee ($)",
								translate: "",
							},
							{
								key: "percentage",
								type: "number",
								label: "Fee per Trip (%)",
								translate: "",
								builder: (item) => item?.percentage && `${item.percentage}%`,
							},
						],
					},
				},
			],
		},
		create: {
			items: [
				{
					key: "dispatching_plan",
					type: "autocomplete",
					label: "Dispatching Plan",
					translate: "",
					required: true,
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					autocomplete: {
						provider: "smarthop",
						url: "billing/dispatching-plans",
						params: {
							sortOrder: "asc",
							sortBy: "nickname",
						},
						preloadDefault: true,
						listenEvent: "planRevision",
						triggerEvent: "planRevision",
						actionOptions: [
							{
								value: "ADD_NEW_DISPATCHING_PLAN",
								label: "Add New Dispatching Plan",
								icon: "add",
								dialog: {
									formId: "ADMIN_DISPATCHING_PLAN_FORM",
									mode: "CREATE",
								},
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT", label: "Save" },
			],
		},
	},
};

export default dispatchingPlanSummaryForm;
