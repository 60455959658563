import { authRoles } from "app/main/consts";
import store from "app/store";
import { logoutUser } from "app/store/auth/userSlice";
import { cleanActions } from "app/store//actions/actionsUserSlice";

const LogoutConfig = {
	auth: authRoles.user,
	routes: [
		{
			path: "/logout",
			component: () => {
				store.dispatch(logoutUser());
				store.dispatch(cleanActions());
				return "Logging out..";
			},
		},
	],
};

export default LogoutConfig;
