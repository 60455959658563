const axleTruckImport = () => ({
	urlGET: "api/axle_import/carriers/:carrierId/trucks",
	idKey: "id",
	listenEvent: "profileRevision",
	segmentKey: "AXLE_TRUCK_IMPORT",
	content: {
		pagination: {
			enabled: true,
			default: { size: 10 },
			disableRowsPerPage: true,
			resetScroll: true,
		},
		table: {
			variant: "skinny",
		},
		order: {
			defaut: {
				key: "name",
				dir: "asc",
			},
		},
		defaults: {
			initial: {
				filters: {
					import_status: "__IMPORTABLE__",
				},
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "import_status",
					type: "select",
					label: "Import Status",
					defaultValue: "__IMPORTABLE__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__IMPORTABLE__",
							label: "Importable",
						},
						{
							value: "__NOT_IMPORTABLE__",
							label: "Not Importable",
						},
					],
				},
			],
		},
		items: [
			{
				key: "name",
				type: "text",
				label: "Truck",
				table: { width: "20%" },
			},
			{
				key: "model",
				type: "text",
				label: "Model",
				table: { width: "20%" },
			},
			{
				key: "model_year",
				type: "text",
				label: "Year",
				table: { width: "20%" },
			},
			{
				key: "vin",
				type: "text",
				label: "VIN",
				table: { width: "20%" },
			},
			{
				key: "import_status",
				type: "text",
				label: "Import Status",
				table: { width: "20%" },
			},
		],
	},
});

export default axleTruckImport;
