import { loadingViewComponent } from "app/main/common/LoadingView";
import { strategyConnect } from "../../providers/StrategyContextProvider";

const StrategyLoadingContainer = ({ loading, children }) => {
	return loading ? loadingViewComponent() : children;
};

const mapStateToProps = (state) => ({
	loading: state.loading,
});

export default strategyConnect(mapStateToProps)(StrategyLoadingContainer);
