import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography, Icon, makeStyles, IconButton, Slide, Box, Menu, MenuItem } from "@material-ui/core";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { saveCheckAvailability } from "app/store/search/searchV3Slice";

// Tools
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { createBadgeView } from "app/main/search/searchUtils";

// Utils
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { DisplaySection } from "app/main/utils/uiUtils";
import { openDialogTitle, openDialogMessage } from "app/main/search/config/suggestUtils";
import LoadingDialog from "app/main/tools/LoadingDialog";
import { calculateProfit } from "app/main/profile/cost-structure/calculator/CostStructureCalculatorSettings";
import { createSourceView } from "app/main/utils/brokerUtils";
import { descriptionLoadId } from "app/main/search/searchUtils";

// View components
import LoadSearchSummaryDetailsView from "app/main/search/result/LoadSearchSummaryDetailsView";
import LoadSearchSummaryRateView from "app/main/search/result/LoadSearchSummaryRateView";
import LoadSearchSummaryBrokerView from "app/main/search/result/LoadSearchSummaryBrokerView";
import LoadAvailability from "app/main/searchV3/components/LoadAvailability";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog.js";
import LoadSearchBidBookModalView from "app/main/search/result/LoadSearchBidBookModalView";

// Services
import { getBrokerSearch } from "app/services/brokersServices";
import { isHelpEnabled } from "app/services/LoginService";
import { getLoadForDispatcherRefreshed, getLoadAvailability } from "app/services/searchServices";
import { selectLoadsById } from "app/store/loads/loadsSlice";
import { runAction, updateLoad, getProfitData, onLocationChange } from "app/main/search/result/LoadSearchViewHelper";
import LoadSearchViewActions from "app/main/search/result/LoadSearchViewActions";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

const useStyles = makeStyles((theme) => {
	return {
		rootTable: {
			width: "100%",
			height: "calc(100vh - 250px) !important",
		},
		rootGridExt: {
			width: "100%",
			height: "calc(100vh - 210px) !important",
		},
		rootGridInt: {
			width: "100%",
			height: "calc(100vh - 150px) !important",
		},
		container: {
			width: "100%",
		},
		containerInformation: {
			height: "-webkit-fill-available",
		},
		"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
		flicker: {
			animationName: "$flicker",
			animationDuration: "600ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		gradient: {
			"--tw-gradient-from": "#fff var(--tw-gradient-from-position)",
			"--tw-gradient-from-position": " ",
			"--tw-gradient-to": "rgb(255 255 255 / 0) var(--tw-gradient-from-position)",
			"--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
		},
	};
});

function LoadSummary({ load, carrierStatus, loadId, onClose, smallScreen, truckSearchData }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const snackbar = useSnackbar();

	// const revision = useSelector(({ tools }) => tools.revision["searchSummaryRevision"]);
	const user = useSelector(({ auth }) => auth.user);
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const socket = useSelector(({ socket }) => socket.connection.socket);
	const currentSearch = searches[currentSearchId];

	const userId = user?._id;
	const cActive = carrierStatus?.active;
	const tActive = carrierStatus?.truckActive;
	const searchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const requestRef = useRef(false);
	const [request, setRequest] = useState(null);
	const [result, setResult] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	// Trip Warnings
	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
	const [ignoreWarnings, setIgnoreInvoiceWarnings] = useState(false);
	const [tripInvoiceWarnings, setTripInvoiceWarnings] = useState();

	const editedLoad = useSelector((state) => selectLoadsById(state.loads, loadId) ?? {});

	const loadToUse = useMemo(() => {
		const loadToUse = { ...(result?.load ?? {}), ...editedLoad };

		const pdata = getProfitData(loadToUse, truckSearchData);
		const res = calculateProfit(pdata);

		loadToUse.profitData = { data: pdata, ...res };

		loadToUse.total_miles = res.totalMiles;
		loadToUse.duration = res.duration;
		loadToUse.stopsNumber = 2 + (loadToUse.locations?.length ?? 0);
		return loadToUse;
	}, [editedLoad, result, truckSearchData]);

	const [open, setOpen] = useState(false);
	const [progress, setProgress] = useState({ flag: false, message: null });
	const [flicker, setFlicker] = useState(false);
	const [availabilityLoading, setAvailabilityLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [confirmDialog, setConfirmDialog] = useState({
		title: null,
		prevTitle: null,
		flag: false,
		action: null,
		isWarning: false,
	});
	const [openBidDialog, setOpenBidDialog] = useState(false);

	const hasBookPermission = roleRestrictions.permission_book_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_book_on: true })
		: true;
	const hasRatePermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;
	const permission = {
		book: hasBookPermission,
		help: isHelpEnabled(),
		price: hasRatePermission,
	};
	const restrictions = {
		bookCarrierActive: cActive && tActive,
	};
	const onRateChange = (newRate) => {
		updateLoad(searchId, loadId, { price: newRate, originalPrice: loadToUse.price });
	};

	useEffect(() => {
		if (!load) return;
		if (
			requestRef.current?.userId !== userId ||
			requestRef.current?.searchId !== searchId ||
			requestRef.current?.loadId !== loadId
		) {
			setOpen(false);

			setTimeout(() => {
				setOpen(true);
				setResult({ load });
			}, 150);
		}
		// eslint-disable-next-line
	}, [load]);

	useEffect(() => {
		if (!userId || !searchId || !loadId || !requestRef) {
			return;
		}
		if (
			requestRef.current?.userId !== userId ||
			requestRef.current?.searchId !== searchId ||
			requestRef.current?.loadId !== loadId
		) {
			// Workaround to make sure we never call load request
			// 2 times for the same input params
			requestRef.current = { userId, searchId, loadId };
			setRequest({ userId, searchId, loadId });
		}
		// eslint-disable-next-line
	}, [userId, searchId, loadId]);

	//get updated load data (without checking availability)
	useEffect(() => {
		let inactive = false;
		if (!request || load?.deleted) {
			return;
		}

		setError(null);
		setFlicker(true);

		const options = { tollDiscouraged: true, skipAvailability: true };
		getLoadForDispatcherRefreshed(request.userId, request.searchId, request.loadId, options).then(
			(load) => {
				if (inactive) return;

				if (loadId === load?.tripid) {
					setFlicker(false);
					//TODO: Improve this workaround
					updateLoad(searchId, load.tripid, load);
					setResult({ request, load: { ...load, fullLoadRecord: true, loadId: load.tripid } });
				}
				if (load.availabilityError) console.log(`[Load check availability] Error ${load.availabilityError}`);
			},
			(error) => {
				if (inactive) return;
				setFlicker(false);
				setError(error.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);

		return () => {
			inactive = true;
		};
		// eslint-disable-next-line
	}, [request]);

	//Load availabilitty on demand
	const getAvailability = (inactive) => {
		setAvailabilityLoading(true);
		getLoadAvailability(request.userId, request.searchId, request.loadId, currentSearch?.filters?.carrier).then(
			(load) => {
				if (inactive) return;

				if (loadId === load?.tripid) {
					setAvailabilityLoading(false);
					updateLoad(searchId, load.tripid, load);
					setResult({ request, load: { ...load, fullLoadRecord: true, loadId: load.tripid } });
					dispatch(saveCheckAvailability({ searchId: currentSearchId, loads: [{ tripid: request.loadId }] }));
				}
				if (load.availabilityError)
					console.log(`[Load check availability] Error ${load.availabilityError}`, load.availabilityError);
			},
			(error) => {
				if (inactive) return;
				setAvailabilityLoading(false);
				setError(error.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);
	};

	const showBrokerSummary = async () => {
		try {
			const { mcnumber } = load;
			const broker = await getBrokerSearch(mcnumber, loadToUse.carrier);
			if (!broker) {
				showErrorSnackbar(snackbar, "Broker Not Found");
			}
			const dataIds = { brokerId: broker._id, carrierId: loadToUse.carrier, cache: broker };
			dispatch(
				openLoadedFormDialog({
					viewId: "BROKER_INFO_VIEW",
					dataIds: dataIds,
					mode: "VIEW",
				})
			);
		} catch (error) {
			showErrorSnackbar(snackbar, error);
		}
	};

	const openFullScreen = () => {
		const dialog = {
			viewId: "LOAD_SUGGEST_VIEW",
			dataIds: {
				truckData: truckSearchData,
				load: loadToUse,
				loadId: loadId,
				userId: userId,
				searchId: currentSearchId,
				carrierId: currentSearch?.filters?.carrier,
				carrier__view: currentSearch?.filters?.carrier__view,
				truckId: currentSearch?.filters?.truck,
				truck__view: currentSearch?.filters?.truck__view,
				driverId: currentSearch?.filters?.driver,
				driver__view: currentSearch?.filters?.driver__view,
				hidePrice: !permission.price,
				restrictions: restrictions,
				isFromSearch: true,
				filters: currentSearch?.filters,
			},
		};
		dispatch(openLoadedFormDialog(dialog));
	};

	const handleMenuClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const onAcceptWarningChanges = () => {
		cleanTripInvoiceWarnings();
		setIgnoreInvoiceWarnings(true);
	};

	const cleanTripInvoiceWarnings = () => {
		setTripInvoiceWarnings();
	};

	const executeAction = useCallback(
		async (action, data) => {
			try {
				setOpenBidDialog(false);
				if (action === "COVERED") {
					//Call fire and forget for covered load
					socket.emit("message", {
						type: "SEARCH_COVERED_LOAD",
						data: { userId: loadToUse.userId, searchId: loadToUse.searchId, tripid: loadToUse.loadId },
					});
				}
				const response = await runAction(
					action,
					setConfirmDialog,
					dispatch,
					setProgress,
					{ ...loadToUse, ...data },
					{ truckId: currentSearch?.filters?.truck, loadId: loadId, userId: userId, searchId: currentSearchId },
					snackbar,
					setSaving,
					ignoreWarnings
				);
				setIgnoreInvoiceWarnings(false);
				return response;
			} catch (err) {
				const tripWarnings = err?.errors?.[0]?.metadata?.warnings;
				setTripInvoiceWarnings(tripWarnings);
				if (tripWarnings?.length) {
					setIsWarningDialogOpen(true);
				}
			}
		},
		// eslint-disable-next-line
		[currentSearchId, currentSearch?.filters?.truck, loadToUse, dispatch, snackbar, setSaving, ignoreWarnings, loadId]
	);

	useEffect(() => {
		if (ignoreWarnings) {
			executeAction("TRIP");
		}
		// eslint-disable-next-line
	}, [ignoreWarnings]);

	const openConfirmDialog = async (type) => {
		if (type === "BID") {
			setOpenBidDialog(true);
			return;
		}

		const title = openDialogTitle(type);
		const message = openDialogMessage(type);
		let confirmDialog = { title: title, message: message, flag: true, action: type };

		if (["RESTRICTION_BOOK_CARRIER_HAS_MCNUMBER", "RESTRICTION_BOOK_CARRIER_STATUS"].includes(type)) {
			confirmDialog.isWarning = true;
		}

		setConfirmDialog(confirmDialog);
	};

	const showBadges = () => {
		const LIST_BADGES = createBadgeView(loadToUse, "SUMMARY");
		return LIST_BADGES;
	};

	// if (!menuPanel.length > 0 || !loadToUse) {
	// 	return null;
	// }

	const variant = smallScreen ? "skinny" : null;
	const panelWidth = smallScreen ? 350 : 450;
	const panelTop = smallScreen ? "top-240" : "top-230";

	return (
		<Box
			className={"flex bg-white ml-10 overflow-y-scroll overflow-x-hidden z-10 fixed right-0 bottom-0 " + panelTop}
			style={{
				width: panelWidth + "px",
			}}
			in={open}
		>
			<Slide direction="left" in={!!open} mountOnEnter unmountOnExit>
				<div className={"flex bg-white flex-col mt-0  " + (smallScreen ? " mx-18 " : " mx-22 ") + classes.container}>
					<div
						className={"flex flex-col justify-between overflow-y-scroll scrollbar-hide " + classes.containerInformation}
					>
						<div className="flex flex-col sticky w-full z-40 top-0 ">
							<div className={"flex items-center bg-white " + (error ? "justify-end" : "justify-between")}>
								<IconButton
									size="small"
									onClick={() => {
										requestRef.current = null;
										setResult(null);
										setOpen(false);
										onClose();
									}}
									title={"Close"}
									className="-ml-6"
								>
									<Icon className="text-22 text-grey">close</Icon>
								</IconButton>
								{!error && (
									<div className={"flex items-center justify-center "}>
										<div
											className={"flex flex-wrap items-center justify-center mr-5" + (flicker ? classes.flicker : "")}
										>
											{showBadges()}
										</div>
										<IconButton size="small" onClick={() => openFullScreen()} title={"Open Full View"} className="ml-8">
											<Icon className={"text-16"}>open_in_full</Icon>
										</IconButton>
										<IconButton
											size="small"
											title={"More actions"}
											className="ml-8"
											id="actions-button"
											aria-controls="actions-menu"
											aria-haspopup="true"
											aria-expanded={!!anchorEl}
											onClick={handleMenuClick}
										>
											<Icon className={"text-20"}>more_vert</Icon>
										</IconButton>
										<Menu
											id="actions-menu"
											aria-labelledby="actions-button"
											anchorEl={anchorEl}
											open={!!anchorEl}
											onClose={handleCloseMenu}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
											getContentAnchorEl={null}
										>
											<MenuItem
												onClick={() => {
													handleCloseMenu();
													openConfirmDialog("COVERED");
												}}
											>
												Mark as Covered
											</MenuItem>
										</Menu>
									</div>
								)}
							</div>
							<div className={"h-10 w-full bg-gradient-to-b " + classes.gradient} />
						</div>
						{!error && (
							<div className="flex flex-col w-full">
								<DisplaySection
									title={"Broker MC " + loadToUse?.mcnumber}
									classes={{ root: "mx-0 mt-0", labelsContainer: "items-center" }}
									labels={[{ label: createSourceView(loadToUse), classes: { label: " pl-0 " } }]}
								>
									<LoadSearchSummaryBrokerView
										variant={variant}
										permission={permission}
										load={loadToUse}
										onShowBrokerSummary={showBrokerSummary}
									/>
								</DisplaySection>

								<DisplaySection title={descriptionLoadId(loadToUse)} classes={{ root: "-mt-20 mx-0" }}>
									<LoadSearchSummaryDetailsView
										variant={variant}
										permission={permission}
										load={loadToUse}
										loading={flicker}
										onLocationChange={(update) =>
											onLocationChange(
												update,
												loadToUse,
												{ loadId, truckId: currentSearch?.filters?.truck, userId, searchId },
												dispatch
											)
										}
										onEditRoute={() => openFullScreen()}
									/>
								</DisplaySection>
							</div>
						)}
						{!error && (
							<div className="flex flex-col sticky bottom-0 w-full z-40">
								<div className={"h-20 w-full bg-gradient-to-t " + classes.gradient} />
								<div className="flex flex-col bg-white">
									<LoadSearchSummaryRateView
										permission={permission}
										load={loadToUse}
										loading={flicker}
										onUpdateRate={onRateChange}
										loadId={loadId}
									/>
									<div className="flex flex-row pb-8 justify-center ml:px-8">
										<LoadAvailability
											load={loadToUse}
											onLoadAvailabilityCheck={() => getAvailability(false)}
											availabilityLoading={availabilityLoading}
											hasError={!!error || !!loadToUse.availabilityError}
											lastCheckAvailability={currentSearch?.loadsChecked?.[loadToUse.tripid]}
											availabilityError={loadToUse.availabilityError}
										/>
									</div>
									<div className="flex flex-col pb-8">
										<LoadSearchViewActions
											flicker={flicker}
											loadToUse={loadToUse}
											permission={permission}
											restrictions={restrictions}
											handleAction={executeAction}
											openFullScreen={openFullScreen}
											openConfirmDialog={openConfirmDialog}
											fullDetailsMode={false}
											saving={saving}
											truckData={truckSearchData}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					{error && (
						<Box className="flex h-full justify-center items-center">
							<Typography color="error" className="flex pt-10 pb-8 items-center justify-center">
								{error}
							</Typography>
						</Box>
					)}

					<LoadingDialog open={progress.flag} message={progress.message} />
					<LoadSearchBidBookModalView
						open={openBidDialog}
						price={!loadToUse?.bidnow && loadToUse.originalPrice ? loadToUse.originalPrice : loadToUse?.price}
						estBidPrice={loadToUse?.est_bid_price}
						bookNow={loadToUse?.booknow}
						bidNow={loadToUse?.bidnow}
						onClose={() => setOpenBidDialog(false)}
						onAccept={(action, data) => executeAction(action, data)}
						loadId={loadToUse?.tripid}
						bidOffline={loadToUse?.owner === "echo"}
						brokerBlacklisted={loadToUse?.brokerBlacklisted}
					/>
					<SmarthopConfirmDialog
						open={!!confirmDialog.flag}
						title={confirmDialog.prevTitle ?? confirmDialog.title}
						message={confirmDialog.message}
						handleClose={() =>
							setConfirmDialog({ title: null, message: null, flag: false, action: null, isWarning: false })
						}
						handleAccept={() => {
							if (!confirmDialog?.prevTitle) {
								executeAction(confirmDialog.action);
							} else {
								setConfirmDialog({ ...confirmDialog, prevTitle: null, message: null });
							}
						}}
						mode={confirmDialog?.isWarning ? "WARNING" : "NORMAL"}
					></SmarthopConfirmDialog>
					{tripInvoiceWarnings?.length && (
						<WarningConfirmDialog
							open={isWarningDialogOpen}
							warnings={tripInvoiceWarnings}
							onAccept={onAcceptWarningChanges}
							onClose={cleanTripInvoiceWarnings}
						/>
					)}
				</div>
			</Slide>
		</Box>
	);
}

export default LoadSummary;
