const getDistanceMaps = async (origin, destination, stops = []) => {
	if (!origin || !destination) return;

	const distanceMatrixApi = new window.google.maps.DistanceMatrixService();

	let origins = [origin];
	let destinations = [destination];

	if (stops.length > 0) {
		origins = [...origins, ...stops];
		destinations = [...stops, ...destinations];
	}
	let totalMiles = 0.0;
	return distanceMatrixApi
		.getDistanceMatrix({
			origins,
			destinations,
			travelMode: "DRIVING",
		})
		.then((res) => {
			if (!res || !res.rows) console.log("[calculatorUtils] Distance matrix error is empty");
			res.rows.forEach((row, index) => {
				let element = row.elements[index];
				if (element?.status !== "OK") return;
				totalMiles += element.distance.value;
			});

			const breakdown = {};
			origins.forEach((l, key) => {
				breakdown[l] = Math.round(res?.rows?.[key]?.elements?.[key]?.distance?.value / 1609);
			});

			return {
				totalMiles: parseInt(totalMiles / 1609),
				breakdown,
			};
		});
};

const getRPM = (price, distanceOrigin, miles) => {
	if (!miles || !price || typeof distanceOrigin === "undefined") return;
	return miles + distanceOrigin ? (price / (miles + distanceOrigin)).toFixed(2) : 0;
};

export { getDistanceMaps, getRPM };
