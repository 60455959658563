import { authRoles } from "app/main/consts";
import Login from "./Login";

const LoginConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.guest,
	routes: [
		{
			path: "/login",
			component: Login,
		},
	],
};

export default LoginConfig;
