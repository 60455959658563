import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles({
	"@keyframes flicker": {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.2,
		},
	},
	flicker: {
		animationName: "$flicker",
		animationDuration: "1000ms",
		animationIterationCount: "infinite",
		animationDirection: "alternate",
		animationTimingFunction: "ease-in-out",
	},
	withAnimation: ({ disabled }) => ({
		animationPlayState: disabled ? "paused" : "running",
	}),
});

function LoadSearchEmpty(props) {
	const { flicker, withAnimation } = useStyles({ disabled: false });

	const stats = props.stats;
	const onReloadClick = props.onReloadClick;

	const removedReasons = stats?.removed ? Object.keys(stats.removed) : [];
	const countTotal = stats?.total?.count;

	const tableCacheKey = "search_result_" + props.searchId;
	const subscriberMeta = useSelector(({ tools }) => tools.table[tableCacheKey]);
	const stillFetching = subscriberMeta?.result?.metadata?.status?.state === "FINISHED_PRELOADED";
	const stillFinishing = subscriberMeta?.result?.metadata?.status?.state === "FINISHED_MANDATORY";

	const content = removedReasons.includes("BOOKNOW") ? (
		<CardContent className={`${flicker} ${withAnimation} w-full bg-white`}>
			<Typography variant="subtitle1" color="primary" className="mt-20 mb-10 font-medium">
				We will continue searching new loads while this search request is active... Reset filters to see current{" "}
				<b>{countTotal}</b> loads...
			</Typography>
			{onReloadClick && (
				<Button
					className="mx-8 mt-16 mb-10"
					variant="contained"
					color="secondary"
					aria-label="Follow"
					onClick={() => {
						onReloadClick();
					}}
				>
					Reset Filters
				</Button>
			)}
		</CardContent>
	) : stillFetching || stillFinishing ? (
		<CardContent className="w-full ">
			<Typography variant="subtitle1" color="textSecondary" className="mt-8 pt-8 pb-8 font-semibold">
				Finishing...
			</Typography>
			<LinearProgress color="secondary" className="mt-14 mb-14 flex flex-1 w-full" />
		</CardContent>
	) : (
		<CardContent className="w-full">
			<Typography variant="subtitle1" color="primary" className="mt-20 font-semibold">
				Sorry, no matching loads...
			</Typography>
			{countTotal > 0 ? (
				<Typography color="primary" className="mt-10 pt-10 pb-8 leading-relaxed">
					Reset filters to default to see all <b>{countTotal}</b> loads which match to your origin and pickup dates.
				</Typography>
			) : (
				<>
					<Typography color="primary" className="mt-10 pt-10 leading-relaxed">
						Please modify your search request to find more loads.
					</Typography>
					<Typography color="primary" className="pb-8 leading-relaxed">
						Try changing DH-O, DH-D or Destination.
					</Typography>
				</>
			)}
			{onReloadClick && countTotal > 0 && (
				<Button
					className="mx-8 mt-16 mb-10"
					variant="contained"
					color="secondary"
					aria-label="Follow"
					onClick={() => {
						onReloadClick();
					}}
				>
					Reset Filters
				</Button>
			)}
		</CardContent>
	);

	return (
		<div className="flex flex-row w-full items-center justify-center px-20 min-w-390 -mt-32 md:mt-0">
			<Card className={"flex flex-col w-full items-center justify-center px-8 sm:px-16 text-center"}>{content}</Card>
		</div>
	);
}

export default LoadSearchEmpty;
