const sendPasswordResetCodeForm = {
	items: [
		{
			key: "email",
			type: "email",
			label: "Email",
			required: true,
		},
		{
			type: "action",
			action: "SUBMIT",
			label: "Send Code",
		},
	],
};

export default sendPasswordResetCodeForm;
