import { axios } from "app/services/requestUtil";
export const getPublicMarket = () => {
	return axios
		.get("/public/markets", {
			headers: { "Content-Type": "application/json" },
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let response = error?.response;
			console.log(`publicMarkets fail: status=${response?.status}, data=`, response?.data, error);
		});
};
