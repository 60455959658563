import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { formatFields } from "@smarthop/utils/fieldsHelpers";

function SmarthopTableView(props) {
	const values = props?.values;
	const content = props?.content;
	let infoValues = {};
	let columns = [];
	for (let item of content?.items) {
		if (item.type === "group") {
			for (let group of item?.content?.items) {
				if (group?.label) {
					infoValues[group.key] = {
						label: group?.label,
						type: group?.type,
						options: group?.options,
						value: group?.label,
					};
					columns.push({ headerName: group?.label, field: group.key });
				}
			}
		} else if (item.key !== "" && item?.key) {
			infoValues[item.key] = { label: item?.label, type: item?.type, options: item?.options };
			columns.push({ headerName: item?.label, field: item.key });
		}
	}

	let rows = [];
	for (let item of values) {
		let keys = Object.keys(item);
		let value = {};
		for (let key of keys) {
			if (infoValues[key] !== undefined) {
				let text = formatFields(
					infoValues[key]?.type === "autocomplete" ? { label: item[key], value: item[key] } : item[key],
					infoValues[key]?.type,
					props?.component,
					props?.overrides,
					props?.nativeMobile,
					props?.dataIds,
					props?.dispatch,
					props?.content,
					infoValues[key]?.options,
					props?.history
				);

				value = { ...value, [key]: text, id: 1 };
			}
		}
		rows.push(value);
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					{columns.map((header, index) => (
						<TableCell
							variant="header"
							size="small"
							style={{
								minWidth: 100,
								fontWeight: "600",
								color: "white",
								paddingRight: "6px",
								paddingLeft: "8px",
								background: "#062246",
								borderTopLeftRadius: index === 0 ? "4px" : undefined,
								borderTopRightRadius: index === columns.length - 1 ? "4px" : undefined,
								fontFamily: 'Poppins,Roboto,"Helvetica",Arial,sans-serif',
							}}
						>
							{header?.headerName}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((emp, index) => (
					<TableRow key={index}>
						{columns.map((header) => (
							<TableCell
								size="small"
								style={{
									color: "#6B7280",
									fontWeight: "400",
									paddingRight: "6px",
									paddingLeft: "8px",
									fontFamily: 'Poppins,Roboto,"Helvetica",Arial,sans-serif',
								}}
							>
								{emp[header?.field] ?? "-"}
							</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

export default SmarthopTableView;
