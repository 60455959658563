import { useState, useEffect } from "react";
import { SmarthopErrorView } from "@smarthop/list";
import SmarthopForm from "@smarthop/form/SmarthopForm";
import { readURLParameters, convertURLParamsToModel } from "../utils/urlUtils";
import { getPublicTrip } from "app/services/linkServices";
import PageWrapper from "../common/PageWrapper";
import PageTabWrapper from "../common/PageTabWrapper";
import LoadSearchView from "app/main/search/result/LoadSearchView";
import TripView from "app/main/profile/trips/TripView";
import LoadTrackingMap from "../dashboard/tracking/LoadTrackingMap";
import { buildForm } from "@smarthop/form/registy";
import { useSelector } from "react-redux";

function LinkForm() {
	const [dataIds] = useState(convertURLParamsToModel(readURLParameters(), {}, { notJson: true }));
	const mode = "VIEW";
	const publicView = true;
	const isAdminOrDispatcher = localStorage.getItem("roleUser") === "0" || localStorage.getItem("roleUser") === "1";
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [formId, setFormId] = useState("");
	const [viewId, setViewId] = useState("");
	const [trackingView, setTrackingView] = useState("");
	const [decodeDataIds, setDecodeDataIds] = useState("");

	const user = useSelector(({ auth }) => auth.user);

	const addDialogEditLoad = {
		viewId: "LOAD_SUGGEST_VIEW",
		dataIds: {
			loadId: decodeDataIds?.loadId,
			userId: decodeDataIds?.userId,
			searchId: decodeDataIds?.searchId,
			carrierId: decodeDataIds?.carrierId,
			carrier__view: decodeDataIds?.carrier__view,
			truckId: decodeDataIds?.truckId,
			truck__view: decodeDataIds?.truck__view,
			driverId: decodeDataIds?.driverId,
			driver__view: decodeDataIds?.driver__view,
			isFromExternal: true,
		},
		mode: "EDIT",
	};
	const editDetailsLoad = { auth: ["dispatcher", "administrator"], label: "Edit Load", dialog: addDialogEditLoad };
	const renderView = (viewId, dataIds, trackingViewId = null) => {
		let params = {
			dataIds,
		};
		const hideRate = isAdminOrDispatcher ? false : dataIds?.hideRate;
		if (viewId === "LOAD_SUGGEST_VIEW") {
			params.dataIds.hidePrice = hideRate;
			if (trackingView) {
				return (
					<PageTabWrapper
						title="Load Details"
						titleActions={[editDetailsLoad]}
						tabs={[
							{
								label: "Load Details",
								component: <LoadSearchView {...params} />,
							},
							{
								label: "Tracking",
								component: <LoadTrackingMap {...params} />,
							},
						]}
					/>
				);
			}
			return (
				<PageWrapper title="Load Details" titleActions={[editDetailsLoad]}>
					<LoadSearchView {...params} />
				</PageWrapper>
			);
		} else if (viewId === "TRIP_VIEW") {
			params.dataIds.hidePrice = hideRate;
			if (trackingView) {
				return (
					<PageTabWrapper
						title="Load Details"
						titleActions={[editDetailsLoad]}
						tabs={[
							{
								label: "Load Details",
								component: <TripView {...params} />,
							},
							{
								label: "Tracking",
								component: <LoadTrackingMap {...params} />,
							},
						]}
					/>
				);
			}
			return (
				<PageWrapper title="Trip Details">
					<div className="p-0 md:p-10 m-0 md:m-10 border-1 bg-white rounded-lg">
						<TripView {...params} />
					</div>
				</PageWrapper>
			);
		}
	};
	useEffect(() => {
		if (!dataIds?.token) {
			setError("Token is missing...");
			return;
		}
		getPublicTrip(dataIds.token, publicView).then(
			(res) => {
				if (res.viewId) {
					const data = { ...res, public: publicView };
					setViewId(res.viewId);
					if (res?.trackingView) {
						setTrackingView(res.trackingView);
					}
					setDecodeDataIds(data);
					const view = renderView(res.viewId, res);
					if (!view) {
						setError(`View not found for ${res.viewId}...`);
						return;
					}
					setData({});
					return;
				} else if (res.formId) {
					setFormId(res.formId);
					const form = res.formId ? buildForm(res.formId, { user }) : undefined;
					if (!form) {
						setError(`View not found for ${res.formId}...`);
						return;
					}
					setData(res.data);
				} else {
					setError("Missing form Id or view Id...");
					return;
				}
			},
			(e) => {
				setError(e?.errors?.[0]?.message ?? "Invalid Token...");
			}
		);

		// eslint-disable-next-line
	}, [dataIds?.token]);

	if (error) {
		return <SmarthopErrorView message={error} />;
	} else if (!data) {
		return null;
	}
	if (viewId) {
		let view;
		if (trackingView) {
			view = renderView(viewId, decodeDataIds, trackingView);
		} else {
			view = renderView(viewId, decodeDataIds);
		}
		if (!view) return <SmarthopErrorView message="Invalid view Id..." />;
		return view;
	} else if (formId) {
		const form = buildForm(formId, { user });
		return (
			<div className="flex w-full items-center justify-center my-10 px-4">
				<SmarthopForm data={data} form={form} dataIds={decodeDataIds} mode={mode} />
			</div>
		);
	} else {
		return <SmarthopErrorView message="Missing form or view Id..." />;
	}
}

export default LinkForm;
