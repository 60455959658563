import { formatCurrency } from "app/main/utils/tableUtils";

const bidCounterOfferForm = {
	urlGET: "/api/transaction/bid/:transactionId/counter-offer",
	urlPUT: "/api/transaction/bid/:transactionId/counter-offer/response",
	idKey: "_id",
	triggerEvent: "transactionUpdateRevision",
	header: {
		edit: { label: "Counteroffer Response" },
	},
	content: {
		edit: {
			items: [
				{
					key: "text",
					type: "message",
					builder: (item) => {
						return `Bid Offer ${formatCurrency(item?.bidPrice)} for load ${item.loadId}`;
					},
				},
				{
					key: "response_type",
					type: "select",
					label: "Action",
					defaultValue: "",
					options: [
						{
							value: "COUNTER_OFFER_ACCEPT",
							label: "Accept Counteroffer",
						},
						{
							value: "COUNTER_OFFER_REJECT",
							label: "Reject Counteroffer",
						},
						{
							value: "COUNTER_OFFER_NEW",
							label: "Make Another Offer",
							showItems: [{ key: "counterRate", type: "number", label: "Amount" }],
						},
					],
				},
				{ type: "action", label: "Submit", action: "SUBMIT" },
			],
		},
	},
};

export default bidCounterOfferForm;
