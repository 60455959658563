const carrierSyncForm = {
	urlGET: "api/fuel/transactions/:transactionId",
	urlPUT: "api/fuel/transactions/:transactionId",
	listenEvent: "fuelRevision",
	triggerEvent: "fuelRevision",
	idKey: "_id",
	header: {
		edit: { label: "Edit Transaction" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "section",
					label: "Transaction Details",
					section: { icon: "local_gas_station", classes: { root: " mt-4" } },
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "driver",
								type: "autocomplete",
								label: "Driver",
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/carriers/:carrierId/drivers",
									params: { options_metadata_driver: true, options_active_only: true },
									preloadDefault: true,
									listenEvent: "profileRevision",
								},
							},
							{
								key: "investor",
								type: "autocomplete",
								label: "Investor",
								autocomplete: {
									provider: "smarthop",
									preloadDefault: true,
									listenEvent: "profileRevision",
									url: "api/profile/investors",
								},
								rules: {
									params: {
										valueRef: "carrier",
										paramKey: "options_carrier_id_search",
									},
								},
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "truck",
								type: "autocomplete",
								label: "Truck",
								field: {
									noErrorMessage: true,
									classes: {},
									variant: undefined,
								},
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/carriers/:carrierId/trucks",
									preloadDefault: true,
									params: { options_format_show_carrier: true, options_active_only: true },
								},
							},
						],
					},
				},
				{
					type: "section",
					label: "Payroll Details",
					section: { icon: "attach_money", classes: { root: " mt-4" } },
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "payroll_eligible",
								label: "Payroll Eligible",
								type: "checkbox",
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierSyncForm;
