const loadReportReasonForm = {
	urlGET: "api/search/users/:userId/requests/:searchId/loads/:loadId",
	urlPOST: "api/search/users/:userId/requests/:searchId/loads/:loadId/report",
	idKey: "_id",
	triggerEvent: "searchItemChange",
	listenEvent: "searchItemChange",
	header: {
		create: { label: "Load Report Reason" },
	},
	content: {
		create: {
			items: [
				{
					key: "report_type",
					type: "select",
					label: "Report type",
					required: true,
					options: [
						{
							value: "INCORRECTLY_MARKED_AS_BEST",
							label: "Incorrectly marked as Best",
							showItems: [
								{
									key: "going_to_a_bad_market",
									type: "checkbox",
									label: "Going to a bad market",
								},
								{
									key: "low_rate",
									type: "checkbox",
									label: "Low Rate",
								},
							],
						},
						{
							value: "SHOULD_BE_MARKED_AS_BEST",
							label: "Should be marked as Best",
							showItems: [
								{
									key: "going_to_good_a_market",
									type: "checkbox",
									label: "Going to a good market",
								},
								{
									key: "high_rate",
									type: "checkbox",
									label: "High Rate",
								},
							],
						},
					],
				},
				{
					type: "action",
					actions: "SUBMIT",
					label: "Report",
					button: { classes: { root: "bg-red" } },
				},
			],
		},
	},
};

export default loadReportReasonForm;
