const handlerField = ({ showTrial }) => ({
	key: "type",
	type: "select",
	label: "Handler",
	required: true,
	options: [
		{
			value: "STRIPE",
			label: "Stripe",
			showItems: [
				showTrial && { key: "trialDays", type: "number", label: "Initial Trial Days" },
				showTrial && { key: "postTrialDays", type: "number", label: "Post-Purchase Trial Days" },
				{
					key: "product",
					type: "autocomplete",
					label: "Stripe Product",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/stripe-products",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{
					key: "price",
					type: "autocomplete",
					label: "Stripe Price (Default)",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/stripe-prices",
						preloadDefault: true,
						showDescription: true,
					},
					rules: {
						params: {
							valueRef: "product",
							paramKey: "option_product_id_search",
						},
					},
				},
				{
					key: "secondary_price",
					type: "autocomplete",
					label: "Stripe Price (Secondary)",
					autocomplete: {
						provider: "smarthop",
						url: "billing/stripe-prices",
						preloadDefault: true,
						showDescription: true,
					},
					rules: {
						params: {
							valueRef: "product",
							paramKey: "option_product_id_search",
						},
					},
				},
			],
		},
		{
			value: "CUSTOM",
			label: "Custom",
		},
	],
});

const adminPlatformPlanForm = {
	urlPOST: "billing/platform-plan",
	urlPUT: "billing/platform-plan/:id",
	urlDELETE: "billing/platform-plan/:id",
	urlGET: "billing/platform-plan/:id",
	idKey: "_id",
	triggerEvent: "planRevision",
	listenEvent: "planRevision",
	header: {
		create: { label: "Create Platform Plan" },
		delete: { label: "Delete Platform Plan" },
		edit: { label: "Edit Platform Plan" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete plan '${item.nickname}'?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
								confirmation: {
									enable: true,
									typedConfirmation: "delete",
									message: "Are you sure you want to continue?",
								},
							},
						],
					},
				},
			],
		},
		create: { sameAs: "edit" },
		edit: {
			items: [
				{
					key: "nickname",
					type: "text",
					label: "Name",
					required: true,
				},
				{
					key: "description",
					type: "text",
					label: "Description",
					multiline: { rows: 5 },
				},

				{
					key: "plan_type",
					type: "select",
					label: "Type",
					required: true,
					options: [
						{
							value: "MAIN",
							label: "Main",
							showItems: [
								{
									key: "bulletPoints",
									type: "text",
									label: "Bullet Points",
									multiline: { rows: 10 },
								},
								{
									key: "tier",
									type: "select",
									label: "Tier",
									required: true,
									options: [
										{
											value: "TIER_LIMITED",
											label: "Limited Tier",
										},
										{
											value: "TIER_STARTER",
											label: "Starter Tier",
										},
										{
											value: "TIER_BASIC",
											label: "Basic Tier",
										},
										{
											value: "TIER_BASIC_PLUS",
											label: "Basic Plus Tier",
										},
										{
											value: "TIER_PROFESSIONAL",
											label: "Professional Tier",
										},
										{
											value: "TIER_GROWTH",
											label: "Growth Tier",
										},
										{
											value: "TIER_ENTERPRISE",
											label: "Enterprise Tier",
										},
									],
								},
								{ key: "priority", type: "number", label: "Priority (Order in Onboarding)" },
								{
									type: "group",
									content: {
										items: [
											{ key: "public", type: "checkbox", label: "Public (Visible in Onboarding)" },
											{ key: "excludeDiscounts", type: "checkbox", label: "Exclude Discounts" },
											{ key: "popular", type: "checkbox", label: "Most Popular" },
										],
									},
								},
								handlerField({ showTrial: true }),
							],
						},
						{
							value: "ADDON",
							label: "Addon",
							showItems: [
								{
									key: "feature",
									type: "select",
									label: "Feature",
									required: true,
									options: [
										{
											value: "ADDITIONAL_ACCOUNT",
											label: "Additional Account x 1",
										},
										{
											value: "ADDITIONAL_ACCOUNT_5",
											label: "Additional Account x 5",
										},
										{
											value: "ADDITIONAL_DAT_SEAT",
											label: "Additional DAT Seat",
										},
									],
								},
								handlerField({ showTrial: false }),
							],
						},
					],
				},

				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "Are you sure you want to continue?",
					},
				},
			],
		},
	},
};

export default adminPlatformPlanForm;
