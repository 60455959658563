import React from "react";
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatLabel, formatTitle } from "./utils";
import { Divider, Icon, Tooltip, Typography } from "@material-ui/core";
import BannerContainer from "./BannerContainer.js";
// Segment
import { SMARTPAY_EVENT } from "app/main/segment/segmentType";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { useMemo } from "react";
import { getCarrierId } from "app/services/LoginService";
import { useState } from "react";

const formConfig = (carrierId) => {
	return {
		form: {
			noErrorMessage: true,
		},
		items: [
			{
				key: "broker",
				type: "autocomplete",
				//label: "Smartpay Eligible Brokers",
				required: false,
				field: {
					noErrorMessage: true,
					variant: "row",
					classes: { container: "border-" },
				},
				autocomplete: {
					provider: "smarthop",
					url: "api/consts/brokers",
					showDescription: true,
					listenEvent: "profileRevision",
					params: {
						sortOrder: "asc",
						sortBy: "clientid",
						options_carrier_id_search: carrierId,
						limit: 10,
					},
				},
			},
		],
	};
};

function SmartPayContainer(props) {
	const configuration = useMemo(() => formConfig(getCarrierId()), []);
	const [formData, setFormData] = useState({});
	const brokerSelected = useMemo(() => !!formData?.broker__view?.metadata, [formData]);

	const brokerSearch = useMemo(() => {
		return (
			<div className="flex flex-col mt-10">
				<Typography className="font-medium text-13 text-grey-600 mr-10">SmartPay Eligible Broker Lookup</Typography>
				<div className="flex flex-row justify-center items-center -mx-4">
					<SmarthopFormView
						key={`smartpaybrokersearch`}
						mode={"EDIT"}
						dataIds={{}}
						content={configuration}
						data={formData}
						onChangeCommitted={(model, value) => setFormData(model)}
						trackChangedFields={["ALL"]}
					/>
					{brokerSelected && formData?.broker__view?.metadata?.smartpayEnabled && (
						<Tooltip title="Smartpay Eligible">
							<Icon style={{ color: "green" }}>checkmark</Icon>
						</Tooltip>
					)}
					{brokerSelected && !formData?.broker__view?.metadata?.smartpayEnabled && (
						<Tooltip title="Broker is not SmartPay Eligible">
							<Icon style={{ color: "red" }}>cancel</Icon>
						</Tooltip>
					)}
				</div>
				<div className="flex flex-col">
					<Typography style={{ fontSize: 9 }} className="text-grey-600">
						* Disclaimer: Final eligibility is based on invoice total
					</Typography>
				</div>
			</div>
		);
	}, [brokerSelected, formData, configuration]);

	const { data } = props;
	return (
		<>
			{formatTitle("Factoring", data?.hasSmartPayProgram ? true : false)}
			{data?.hasSmartPayProgram ? (
				<>
					<Divider className="w-full bg-grey-300 mt-10" />
					{formatLabel(
						"Funded",
						formatCurrency(data?.smartpay?.Funded ?? 0),
						"",
						"(" +
							(data?.smartpay?.Count ?? 0) +
							` Invoice${data?.smartpay?.Count > 1 || data?.smartpay?.Count === 0 ? "s" : ""})`
					)}
					<Divider className="w-full bg-grey-300" />
					{brokerSearch}
				</>
			) : (
				<>
					<BannerContainer
						title={"SmartPay"}
						subtitle={"*Get industry leading rates with our preferred partner"}
						value={data?.saving}
						url={"https://cctfactoring.com/get-started/"}
						description={
							"Estimates based on your current delivered trips and the lowest factoring rate you can expect, terms and conditions may apply."
						}
						track={SMARTPAY_EVENT}
						type="smartpay"
					/>
				</>
			)}
		</>
	);
}

export default SmartPayContainer;
