import _ from "@lodash";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { submitRegister, clearErrors, setRegisterStatus } from "app/store/auth/registerSlice";
import createRegistrationForm from "../config/registrationForm";
import { createValidationForm, createValidationTypeForm } from "../config/validationForm";

import { readURLParameters, convertURLParamsToModel } from "../../utils/urlUtils";
import { changeLanguage } from "app/store/language/i18nSlice";
import { SIGN_UP_PAGE_EVENT } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";
import { useLocation } from "react-router-dom";

function RegisterForm(props) {
	const onDoneOverride = props.onDoneOverride;
	const onProgressOverride = props.onProgressOverride;
	const onDialogOpenOverride = props.onDialogOpenOverride;
	//flag to see if the register is going to be simple
	const isSimple = props.isSimple;

	const dispatch = useDispatch();
	const location = useLocation();
	const authRegister = useSelector(({ auth }) => auth.register);

	const [model, setModel] = useState(null);
	const [typeCompany, setTypeCompany] = useState(null);
	const [roleCompany, setRoleCompany] = useState(null);

	/**
	 * Verify in search url if all mandatory inputs in for are in the url search
	 * @param {[]} dataUrl
	 * @returns true || false
	 */
	const isAllMandatoryParams = (dataUrl = []) => {
		const mandatoryItems = ["first_name", "last_name", "email", "phone"];
		return mandatoryItems.every((mandatoryItem) => dataUrl?.includes(mandatoryItem));
	};
	const getDataUrl = () => {
		let params = convertURLParamsToModel(readURLParameters(), {}, { notJson: true }) ?? {};
		let data = { ...params };
		data.hideQualifier = {
			typeCompany: false,
			roleCompany: false,
			numberTrucks: false,
			equipment: false,
		};

		if (params?.coupon) {
			localStorage.setItem("coupon", params?.coupon);
		}

		if (params?.carrier_type) {
			data.typeCompany = params.carrier_type?.toUpperCase()?.replaceAll(" ", "_");
			data.hideQualifier.typeCompany = true;
			setTypeCompany(data.typeCompany);
		}
		if (params?.role_company) {
			data.roleCompany = params.role_company?.toUpperCase()?.replaceAll(" ", "_");
			data.hideQualifier.roleCompany = true;
			setRoleCompany(data.roleCompany);
		}
		if (params?.number_trucks) {
			data.hideQualifier.numberTrucks = true;
			data.numberTrucks = params.number_trucks;
		}
		if (params?.dry_van_or_reefer) {
			data.equipment_type = params.dry_van_or_reefer?.toUpperCase()?.replaceAll(" ", "_");
		}
		if (params?.phone_number) {
			data.phone = params?.phone_number;
		}
		if (params.email) {
			data.email = params.email;
		}
		if (params?.equipment) {
			data.hideQualifier.equipment = true;
			data.equipment = params.equipment;
		}

		if (location.state) {
			Object.keys(location.state).forEach((k) => {
				data[k] = location.state[k];
			});
		}
		if (params.name) {
			const nameParts = params.name.split(" ");
			data.last_name = nameParts[nameParts.length - 1] ?? "";
			data.first_name = nameParts.join(" ").replace(params.last_name, "") ?? "";
		} else {
			data.last_name = params.last_name ?? "";
			data.first_name = params.first_name ?? "";
		}

		if (params.MC_Number__c) {
			data.carrier_mc_number = params.MC_Number__c;
		}

		if (location?.search && isAllMandatoryParams(Object.keys(data))) {
			data.hideFilledElements = true;
			data.terms_and_condition = true;
		}
		return data;
	};

	const queryData = useMemo(
		() => getDataUrl(),
		//eslint-disable-next-line
		[location]
	);

	const [queryParams] = useState(queryData);
	if (!_.isEmpty(queryParams)) {
		localStorage.setItem("campaign", JSON.stringify(queryParams));
	}

	const setEnglishLanguage = () => dispatch(changeLanguage("en"));
	createTrackOrPage(SIGN_UP_PAGE_EVENT, window, "page", "Landing Page - Sign Up");

	useEffect(() => {
		return () => {
			dispatch(clearErrors());
			setEnglishLanguage();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	function onSubmitData(dataModel) {
		dataModel.preferred_language = "EN";
		dataModel.captcha = process.env.REACT_APP_RECAPTCHA;
		dataModel.not_mc = !dataModel.carrier_mc_number;
		dataModel.iagree = dataModel.terms_and_condition;
		dataModel.self_service = true;
		dataModel.ref_code = null;
		dataModel.send_slack_message = true;
		dataModel.no_default_trucks = true;
		if (location.search) {
			dataModel = { ...getDataUrl(), ...dataModel };
		}
		if (!dataModel.email && queryParams?.socialData?.email) {
			dataModel.email = queryParams.socialData.email;
		}
		if (!dataModel.first_name && queryParams?.socialData?.first_name) {
			dataModel.first_name = queryParams.socialData.first_name;
		}
		if (!dataModel.last_name && queryParams?.socialData?.last_name) {
			dataModel.last_name = queryParams.socialData.last_name;
		}
		setModel(dataModel);
		dispatch(submitRegister({ ...(queryParams ?? {}), ...dataModel }, onProgressOverride));
	}

	function onSubmitType(typeModel) {
		let dataModel = { ...model, verification_type: typeModel.validationType };
		setModel(dataModel);
		dispatch(submitRegister({ ...(queryParams ?? {}), ...dataModel }, onProgressOverride, onDoneOverride));
		setEnglishLanguage();
	}

	function onSubmitCode(codeModel) {
		let dataModel = { ...model, verification_code: codeModel.code };
		dispatch(submitRegister({ ...(queryParams ?? {}), ...dataModel }, onProgressOverride, onDoneOverride));
		setEnglishLanguage();
	}

	function onGoBack() {
		dispatch(setRegisterStatus(null));
	}

	return (
		<>
			<div
				className={
					(authRegister.status === "EMAIL_VERIFICATION_REQUIRED" ||
					authRegister.status === "PHONE_VERIFICATION_REQUIRED" ||
					authRegister.status === "VERIFICATION_TYPE_REQUIRED"
						? " hidden "
						: authRegister.inProgress
						? "opacity-60 "
						: "") + " w-full "
				}
			>
				<SmarthopFormView
					key="registration"
					content={createRegistrationForm(
						(data) => {
							if (onDialogOpenOverride) {
								onDialogOpenOverride(data);
							} else {
								dispatch(openLoadedFormDialog(data));
							}
						},
						queryParams?.hideFilledElements,
						typeCompany,
						roleCompany,
						queryParams?.hideQualifier,
						isSimple,
						queryParams?.socialData?.providerId
					)}
					errors={authRegister.errors}
					mode={"CREATE"}
					data={queryParams}
					trackChangedFields={["typeCompany", "roleCompany"]}
					onChangeCommitted={(model, key) => {
						if (key === "typeCompany") setTypeCompany(model[key]);
						if (key === "roleCompany") setRoleCompany(model[key]);
					}}
					onSubmit={onSubmitData}
				/>
			</div>
			{authRegister.status === "VERIFICATION_TYPE_REQUIRED" && (
				<div className={(authRegister.inProgress ? "opacity-60 " : "") + " w-full "}>
					<SmarthopFormView
						key={"validation_type"}
						content={createValidationTypeForm(model?.email, model?.phone)}
						errors={authRegister.errors}
						mode={"CREATE"}
						onSubmit={onSubmitType}
						onCustom={onGoBack}
					/>
				</div>
			)}
			{authRegister.status === "EMAIL_VERIFICATION_REQUIRED" && (
				<div className={(authRegister.inProgress ? "opacity-60 " : "") + " w-full "}>
					<SmarthopFormView
						key={"validate_" + model?.email}
						content={createValidationForm("EMAIL", model?.email)}
						errors={authRegister.errors}
						mode={"CREATE"}
						onSubmit={onSubmitCode}
						onCustom={onGoBack}
					/>
				</div>
			)}
			{authRegister.status === "PHONE_VERIFICATION_REQUIRED" && (
				<div className={(authRegister.inProgress ? "opacity-60 " : "") + " w-full "}>
					<SmarthopFormView
						key={"validate_" + model?.phone}
						content={createValidationForm("PHONE", model?.phone)}
						errors={authRegister.errors}
						mode={"CREATE"}
						onSubmit={onSubmitCode}
						onCustom={onGoBack}
					/>
				</div>
			)}
		</>
	);
}

export default RegisterForm;
