import { useEffect } from "react";

export const useDebouncedEffect = (effect, deps, delay, cleanupCallback) => {
	useEffect(() => {
		const handler = setTimeout(() => effect(), delay);
		return () => {
			if (cleanupCallback) cleanupCallback();
			clearTimeout(handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...(deps || []), delay]);
};
