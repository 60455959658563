import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
// Config
import carrierGoalsContent from "@smarthop/form/configs/carrierGoalsContent";
// Services
import { createGoals } from "app/services/goalsServices";
// Tools
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";

const CreateNewGoalsView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Create Goals");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dataIds = props.dataIds;

	const dispatch = useDispatch();

	const [errors, setErrors] = useState([]);

	const form = useMemo(
		() => carrierGoalsContent(),
		//eslint-disable-next-line
		[dataIds]
	);

	const handleSubmit = async (model) => {
		try {
			await createGoals({ ...model, carrier: dataIds?.carrierId });

			dispatch(incrementDataRevision({ event: "goalsRevision" }));
			dispatch(closeFormDialog());
		} catch (error) {
			const errors = error?.errors ?? [
				{ type: "generic", message: "Oops... something went wrong, please try again..." },
			];

			setErrors(errors);
		}
	};

	return <SmarthopFormView mode="CREATE" content={form} errors={errors} onSubmit={handleSubmit} />;
};

export default CreateNewGoalsView;
