import _ from "lodash";
import { useEffect } from "react";
import { strategyConnect } from "../../providers/StrategyContextProvider";
import {
	addCarrierId,
	addDiagnosticMessage,
	changeModel,
	changeResult,
	changeTruckInfo,
	getTempStrategy,
	setDataMap,
	startLoading,
	stopLoading,
} from "../../providers/reducer/actions";
import { convertURLParamsToModel, readURLParameters, STRATEGY_URL_PARAMS_MAPPING } from "app/main/utils/urlUtils";
import { getSavedStrategy } from "../../providers/reducer/actions/requestActions";
import { useCallback } from "react";
import {
	getLatestStrategyTreeByTruckId,
	getLatestStrategyWithLoad,
	getStrategyTreeById,
} from "app/services/strategyServices";
import { dataMapModel, getLoadedModel, getModelWithTruckInfo } from "../../utils/strategyUtils";
import { getRoleLabel } from "app/services/LoginService";
import { useSelector } from "react-redux";
import { getCurrentTruckPlan, getTrucksInformation } from "app/services/truckServices";

function StrategyEntryContainer({
	contentHidden,
	contentListDisabled,
	children,
	getTempStrategy,
	getSavedStrategy,
	entryPoint,
	dataIds,
	changeResult,
	stopLoading,
	startLoading,
	changeModel,
	addDiagnosticMessage,
	addCarrierId,
	setDataMap,
}) {
	const topLevel = entryPoint === "topLevel";
	const fromLoad = entryPoint === "load";
	const fromSavedList = entryPoint === "savedList";
	const noLatestStrategyEntryPoints = ["topLevel", "load", "savedList"];

	const user = useSelector(({ auth }) => auth.user);
	const role = getRoleLabel();
	const internalUser = ["administrator", "dispatcher", "ops support"].includes(role);

	const fetchDataMap = useCallback(async () => {
		const currentTruckPlan = await getCurrentTruckPlan(dataIds?.carrierId, dataIds?.truckId);
		if (!_.isEmpty(currentTruckPlan.plan)) {
			setDataMap(dataMapModel(currentTruckPlan.truck.equipment, currentTruckPlan.plan));
		} else {
			setDataMap("VAN", {});
		}
		stopLoading();
		//eslint-disable-next-line
	}, []);

	const getTruckInfo = useCallback(async (truckId, carrierId) => {
		const trucks = await getTrucksInformation({
			options_active_only: true,
			options_carrier_ids_search: carrierId,
			options_truck_ids_search: truckId,
		});
		const truckInfo = trucks?.items?.[0];
		return truckInfo;
	}, []);

	const getLatestStrategy = useCallback(async () => {
		const { truckId, carrierId } = dataIds;
		const latestStrategy = await getLatestStrategyTreeByTruckId(truckId, carrierId, {
			compute_plan_data: true,
		});
		if (latestStrategy.tree) {
			changeResult(latestStrategy);
			changeModel(getLoadedModel(latestStrategy));
			stopLoading();
		} else {
			fetchDataMap();
			const truckInfo = await getTruckInfo(truckId, carrierId);
			changeModel(getModelWithTruckInfo(truckInfo));
		}
		//eslint-disable-next-line
	}, []);

	const getLoadStrategy = useCallback(async () => {
		const { truckId, carrierId, searchId, loadId } = dataIds;
		try {
			const result = await getLatestStrategyWithLoad(truckId, carrierId, searchId, loadId);
			changeResult(result);
			if (!result.tree) {
				addDiagnosticMessage({
					type: "warning",
					componentName: "RequestError",
					props: { messages: ["Load strategy not available"] },
				});
			}
		} catch (error) {
			const messages = error.errors?.map((e) => e.message);
			addDiagnosticMessage({
				type: "warning",
				componentName: "RequestError",
				props: { messages },
			});
		}
		stopLoading();
		//eslint-disable-next-line
	}, []);

	const getStrategyFromDataIds = useCallback(async () => {
		const { strategyId } = dataIds;
		const result = await getStrategyTreeById(strategyId);
		changeResult(result);
		stopLoading();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!internalUser) {
			addCarrierId(user.carrierId);
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!topLevel) return;
		// This waits for the params of the newly clicked tab to be loaded
		// in order to populate the strategy panel with the new data
		let timeOutId;
		if (!timeOutId) {
			timeOutId = setTimeout(() => {
				const data = convertURLParamsToModel(readURLParameters(), STRATEGY_URL_PARAMS_MAPPING);
				if (!_.isEmpty(data) && !contentHidden) {
					const { _id: strategyId, response: tempVirtualContractId } = data;
					startLoading();
					if (strategyId) {
						getSavedStrategy(strategyId);
					} else if (tempVirtualContractId) {
						getTempStrategy(tempVirtualContractId);
					}
				}
			}, 500);
		}
		return () => clearTimeout(timeOutId);
		//eslint-disable-next-line
	}, [contentListDisabled]);

	useEffect(() => {
		if (noLatestStrategyEntryPoints.includes(entryPoint)) return;
		startLoading();
		getLatestStrategy();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!fromLoad) return;
		startLoading();
		getLoadStrategy();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!fromSavedList) return;
		startLoading();
		getStrategyFromDataIds();
		//eslint-disable-next-line
	}, []);

	if (topLevel && contentListDisabled) return null;
	return <div className={contentHidden ? "hidden" : ""}>{children}</div>;
}

const mapStateToProps = (state) => ({
	entryPoint: state.entryPoint,
	dataIds: state.dataIds,
});

const mapDispatchToProps = (dispatch) => ({
	setTruckInfo: (truckInfo) => dispatch(changeTruckInfo(truckInfo)),
	changeModel: (model) => dispatch(changeModel(model)),
	getTempStrategy: (tempVirtualContractId) => dispatch(getTempStrategy({ dispatch, tempVirtualContractId })),
	getSavedStrategy: (strategyId) => dispatch(getSavedStrategy({ dispatch, strategyId })),
	startLoading: () => dispatch(startLoading()),
	stopLoading: () => dispatch(stopLoading()),
	changeResult: (result) => dispatch(changeResult(result)),
	addDiagnosticMessage: (diagnosticMessage) => dispatch(addDiagnosticMessage(diagnosticMessage)),
	addCarrierId: (carrier) => dispatch(addCarrierId(carrier)),
	setDataMap: (dataMap) => dispatch(setDataMap(dataMap)),
});

export default strategyConnect(mapStateToProps, mapDispatchToProps)(StrategyEntryContainer);
