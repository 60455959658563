import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export function cancelPlatformPlan(carrierId, data) {
	const url = `/billing/carriers/${carrierId}/platform-plans/cancel`;
	return createRequest()
		.put(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function deletePlatformPlan(carrierId, resetTrial) {
	const url = `/billing/carriers/${carrierId}/platform-plans`;
	return createRequest()
		.delete(url, { data: { resetTrial } }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function restorePlatformPlanSubscription(carrierId) {
	const url = `/billing/carriers/${carrierId}/platform-plans/restore`;
	return createRequest()
		.put(url)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getPlatformPlanForCarrier(carrierId) {
	const url = `billing/carriers/${carrierId}/platform-plans`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getPlatformPublicPlans(carrierId) {
	const url = `/billing/carreirs/${carrierId}/platform-plans-public`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getPlatformPublicPlanById(carrierId, planId) {
	const url = `/billing/carreirs/${carrierId}/platform-plans-public/${planId}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getCarrierPaymentMethods(carrierId) {
	const url = `/billing/carriers/${carrierId}/stripe-sources`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getCarrierDefaultPaymentMethod(carrierId) {
	const url = `/billing/carriers/${carrierId}/stripe-default-source`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function assignCarrierPlatformPlan(carrierId, planId, priceId, trialAllowed, equipment = null) {
	const url = `/billing/carriers/${carrierId}/platform-plans`;
	return createRequest()
		.post(url, { data: { planId, priceId, trialAllowed, equipment } }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

// Addons
export function getCarrierUpcomingPlatformAddonPlan(carrierId, planId) {
	const url = `/billing/carriers/${carrierId}/platform-addons-preview`;
	return createRequest()
		.post(url, { data: { planId } }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getCarrierAvailableAddons(carrierId) {
	const url = `/billing/carriers/${carrierId}/platform-plans-addons`;
	const params = { options_feature_key: "ADDITIONAL_ACCOUNT" };
	return createRequest()
		.get(url, { params, headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function addPlanAddonToCarrier(carrierId, addonId) {
	const url = `/billing/carriers/${carrierId}/platform-addons-plans`;
	return createRequest()
		.post(url, { data: { platform_addon_plan: addonId } }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function cancelTrialPlatformPlan(carrierId, planId) {
	const url = `/billing/carriers/${carrierId}/platform-plans/post-trial`;
	return createRequest()
		.delete(url, { data: {} }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
