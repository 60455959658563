import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography, Card, CardContent, Button, Grid } from "@material-ui/core";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { fetchSubAccount } from "app/store/auth/accountSlice";
import { closeAllDialogs } from "app/store/tools/formDialogSlice";

// Services
import { getCarrierAvailableAddons, addPlanAddonToCarrier } from "app/services/platformPlanServices";
import { getAccountId } from "app/services/LoginService";

function AddPlanAddonCarrierView(props) {
	useEffect(() => {
		props?.setTitle?.("Add Extra");
		props.setSize?.("max-w-l");
		// eslint-disable-next-line
	}, []);
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const onDone = props.onDone;
	const dataIds = props?.dataIds;
	const carrierId = dataIds?.carrierId;
	const callback = props.dataIds.callback;

	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!carrierId) {
			return;
		}

		setLoading(true);
		getCarrierAvailableAddons(carrierId).then(
			(response) => {
				setLoading(false);
				setData(response.items);
			},
			(err) => {
				setLoading(false);
				setError(err.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);
	}, [carrierId]);

	const handlerBuyAddon = (addonId) => {
		if (processing) {
			return;
		}

		setProcessing(true);

		addPlanAddonToCarrier(carrierId, addonId).then(
			(response) => {
				setProcessing(false);
				setData(response.items);
				showSnackbar(snackbar, "The addon was added successfully.", "success");

				onDone?.();
				dispatch(fetchSubAccount({ accountId: getAccountId() }));
				if (callback?.restriction?.()) {
					dispatch(closeAllDialogs());
					return callback.function?.(dispatch);
				}
			},
			(err) => {
				setProcessing(false);
				showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
			}
		);
	};

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
					Loading...
				</Typography>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<div className="flex flex-wrap md:flex-nowrap justify-center md:overflow-x-scroll my-20">
			<Grid container columns={1}>
				{data?.map((item, index) => {
					const stripe = item?.price__view?.metadata?.stripe;

					return (
						<div key={"item" + index} className={"w-full justify-center items-center p-12 md:mx-20"}>
							<Card className={"relative rounded-16 min-w-420 pb-0 ml:min-w-450"} raised>
								<CardContent className="flex flex-row text-left p-0 pt-24">
									<div className="flex flex-col w-5/12 justify-center items-center min-h-150 border-r-1">
										{stripe?.unit_amount / 100}
									</div>

									<div className="flex flex-col w-7/12 justify-between px-16">
										<div>
											<Typography
												variant="subtitle1"
												color="inherit"
												className="text-16 ml:text-16 font-semibold text-left"
											>
												{item.nickname}
											</Typography>
											<div className="flex flex-col w-full items-start mt-10">
												{item.description?.split("\n").map((paragraph, i) => {
													return paragraph?.length > 0 ? (
														<Typography
															key={"paragraph" + i}
															className="w-full text-left text-13 ml:text-14 leading-snug"
														>
															{paragraph}
														</Typography>
													) : null;
												})}
											</div>
										</div>
										<Button
											variant="contained"
											color="secondary"
											className="w-full"
											onClick={() => {
												handlerBuyAddon(item._id);
											}}
										>
											Buy Addon
										</Button>
									</div>
								</CardContent>
							</Card>
						</div>
					);
				})}
			</Grid>
		</div>
	);
}

export default AddPlanAddonCarrierView;
