import { useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { usePlaidLink } from "react-plaid-link";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { createPlaidDevAccessToken, createAchAccount } from "app/services/billingServices";
import { showSnackbar } from "app/main/utils/snackbarUtil";

function PlaidLink({
	token,
	carrierId,
	isOauth,
	plaidConfig,
	isDevelopment,
	isSmartpayPayoutAccount,
	isSmartFuelAccount,
	isReauthentication,
}) {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const formStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const visibleForm = formStack.length > 0 ? formStack[formStack.length - 1] : null;

	const onSuccess = useCallback(
		async (publicToken, metadata) => {
			const data = {
				publicToken,
				metadata,
				account_id: metadata.account_id,
				isSmartFuelAccount,
				isSmartpayPayoutAccount,
			};
			try {
				if (isDevelopment) {
					await createPlaidDevAccessToken(carrierId, data);
				} else {
					await createAchAccount(carrierId, data);
				}
				dispatch(incrementDataRevision({ event: "paymentRevision" }));
				dispatch(incrementDataRevision({ event: "onboardingFuelModuleStatusRevision" }));
				const addLabel = isReauthentication ? "reauthenticated" : "added";
				showSnackbar(
					snackbar,
					isSmartpayPayoutAccount ? `Payout Account ${addLabel}` : `ACH account ${addLabel}`,
					"success"
				);
			} catch (e) {
				showSnackbar(snackbar, e.errors[0].message, "error", { persist: true });
			}
		},
		// eslint-disable-next-line
		[carrierId]
	);

	const onExit = (error, metadata) => {
		if (isOauth && plaidConfig) {
			history.push(plaidConfig.url);
			if (plaidConfig.form) dispatch(openFormDialog(plaidConfig.form));
		}
		localStorage.removeItem("plaidConfig");
	};

	const onEvent = (eventName, metadata) => {
		// Event before success
		if (eventName === "HANDOFF") onExit();
	};

	const config = {
		token,
		onExit,
		onEvent,
		onSuccess,
	};

	if (isOauth) {
		// receivedRedirectUri must include the query params
		config.receivedRedirectUri = window.location.href;
	}

	const { open, ready } = usePlaidLink(config);

	// this opens link as soon as it's ready
	useEffect(() => {
		// initiallizes Link automatically
		if (isOauth && ready) {
			open();
		} else if (ready) {
			localStorage.setItem(
				"plaidConfig",
				JSON.stringify({ token, url: location.pathname, form: visibleForm, carrierId, isDevelopment })
			);
			open();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready, open, token, isOauth]);

	// don't render anything, just open Link
	return null;
}

export default PlaidLink;
