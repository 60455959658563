import { Box, makeStyles } from "@material-ui/core";
import { useCallback, useContext, useEffect, useRef, useState, forwardRef } from "react";
import { unselectPath } from "../../providers/reducer/actions";
import { StrategyActionContext, StrategyStateContext } from "../../providers/StrategyContextProvider";
import DrawerBody from "./drawer-body/DrawerBody";
import DrawerButtons from "./DrawerButtons";
import DrawerHeader from "./DrawerHeader";
import clsx from "clsx";

const findPathIndex = (moves, paths) => {
	const pathId = moves[moves.length - 1].data.stop.pathId;
	return paths.findIndex((path) => path.path_id === pathId);
};

const useStyles = makeStyles({
	portalDrawerBox: {
		position: "relative",
		width: "100%",
	},
	portalElem: {
		position: "fixed",
		zIndex: 1000,
		width: "100vw",
		boxSizing: "border-box",
		backgroundColor: "rgba(255,255,255,.9)",
		transition: "all 1s ease-in-out",
	},
});

const StrategyDrawerPortal = forwardRef((props, ref) => {
	const {
		header,
		body: strategyData,
		setDrawerHeader,
		strategyReqData,
		firstOpen,
		setFirstOpen,
		disableSaving,
	} = props;

	const classes = useStyles();

	const [open, setOpen] = useState(firstOpen && header);
	const [index, setIndex] = useState();
	const cardRef = useRef();
	const headerRef = useRef();
	const drawerRef = useRef();
	const { selectedPaths } = useContext(StrategyStateContext);
	const contextDispatch = useContext(StrategyActionContext);
	const isSaved = disableSaving();

	const getInitialSelectedMoves = useCallback(() => {
		// Access pathId located in last node
		const pathId = header[header.length - 1].data.stop.pathId;
		const selectedMoves = selectedPaths[pathId];
		return selectedMoves ?? header;
	}, [header, selectedPaths]);
	const [selectedMoves, setSelectedMoves] = useState(getInitialSelectedMoves);

	const handleCollapse = () => {
		setOpen((o) => !o);
	};

	const handleClose = () => {
		setDrawerHeader(null);
		contextDispatch(unselectPath());
	};

	useEffect(() => {
		if (!header || !strategyData) return;
		setSelectedMoves(getInitialSelectedMoves);
		setIndex(findPathIndex(header, strategyData?.strategyResult?.paths));
	}, [header, strategyData, getInitialSelectedMoves]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const portalElem = ref?.current;
			portalElem.className = clsx(classes.portalElem);
			portalElem.style.top = `${window.innerHeight}px`;
			const drawerClientHeight = drawerRef?.current?.clientHeight;
			const openedHeight = Math.min(drawerClientHeight, window.innerHeight - 100);
			const cardClientHeight = cardRef?.current?.clientHeight + 30;
			portalElem.style.transform = !!open ? `translateY(-${openedHeight}px)` : `translateY(-${cardClientHeight}px)`;
		}, 20);
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line
	}, [ref.current, open, drawerRef.current, headerRef.current]);

	useEffect(() => {
		let timeoutId;
		if (!!open && firstOpen) {
			timeoutId = setTimeout(() => {
				setOpen(false);
				setFirstOpen(false);
			}, 1500);
		}
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line
	}, [open, firstOpen, setFirstOpen, drawerRef.current, headerRef.current]);

	return (
		<>
			<DrawerButtons open={open} handleCollapse={handleCollapse} handleClose={handleClose} />
			<Box
				className={clsx(classes.portalDrawerBox)}
				style={{
					paddingRight: open ? "0px" : "8px",
					borderRadius: "30px 0 0 0",
					borderRight: "1px solid #e0e0e0",
					overflow: open ? "auto" : "visible",
				}}
			>
				<Box ref={drawerRef}>
					<DrawerHeader
						ref={headerRef}
						header={header}
						handleCollapse={handleCollapse}
						selectedMoves={selectedMoves}
						setSelectedMoves={setSelectedMoves}
						cardRef={cardRef}
						isSaved={isSaved}
					/>
					<DrawerBody
						index={index}
						strategyData={strategyData}
						strategyReqData={strategyReqData}
						pathMoves={header}
						selectedMoves={selectedMoves}
					/>
				</Box>
			</Box>
		</>
	);
});

export default StrategyDrawerPortal;
