import moment from "moment";

const TOTAL_LOADS = {
	key: "total_loads",
	type: "number",
	label: "Total Loads",
	min: 0,
	required: true,
	field: { maxLength: 5, noErrorMessage: true },
	translate: "",
};

const BOOK_LOADS = {
	key: "book_loads",
	type: "number",
	label: "Book Now Loads",
	required: true,
	min: 0,
	field: { maxLength: 5, noErrorMessage: true },
	translate: "",
};

const BEST_LOADS = {
	key: "best_loads",
	type: "number",
	label: "Best Loads",
	required: true,
	min: 0,
	field: { maxLength: 5, noErrorMessage: true },
	translate: "",
};

const BID_LOADS = {
	key: "bid_loads",
	type: "number",
	label: "Bid Now Loads",
	required: true,
	min: 0,
	field: { maxLength: 5, noErrorMessage: true },
	translate: "",
};

const WEIGHT = {
	key: "weight",
	type: "number",
	label: "Weight",
	required: true,
	min: 0,
	field: { maxLength: 6, noErrorMessage: true },
	translate: "",
};

const EQUIPMENT = () => {
	return {
		key: "equipment",
		type: "select",
		label: "Equipment",
		required: true,
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		options: [
			{
				value: "VAN",
				label: "Van",
				translate: "VAN",
			},
			{
				value: "REEFER",
				label: "Reefer",
				translate: "REEFER",
			},
			{
				value: "FLATBED",
				label: "Flatbed",
			},
			{
				value: "PO",
				label: "Power Only",
			},
		],
	};
};

const LOCATION_ORIGIN = (origin) => {
	return {
		key: "location_origin",
		type: "autocomplete",
		label: "Origin",
		required: true,
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "trimblemaps",
			formatST: true,
			params: {
				componentRestrictions: { country: "us", includeOnly: "city" },
				types: ["(cities)"],
			},
			multiple: false,
			anyValue: false,
			icon: "location",
		},
	};
};

const PICKUP_DATE = () => {
	return {
		key: "pickup_date",
		type: "picker",
		label: "Pickup Date",
		required: true,
		defaultValue: "NOW",
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		input: {
			classes: {},
		},
		picker: {
			disablePast: true,
			notClearable: true,
			type: "date",
			classes: {},
		},
	};
};

const LOCATION_DESTINATION = () => {
	return {
		key: "location_destination",
		type: "autocomplete",
		label: "Destination",
		required: true,
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "trimblemaps",
			formatST: true,
			params: {
				componentRestrictions: { country: "us", includeOnly: "city" },
				types: ["(cities)"],
			},
			multiple: true,
			preloadDefault: true,
			anyValue: false,
			icon: "location",
		},
	};
};

const DELIVERY_DATE = () => {
	return {
		key: "delivery_date",
		type: "picker",
		label: "Delivery Date",
		required: true,
		defaultValue: moment().add(1, "day").toISOString(),
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		input: {
			classes: {},
		},
		picker: {
			disablePast: true,
			notClearable: true,
			type: "date",
			classes: {},
		},
	};
};

const BROKERS = () => {
	return {
		key: "brokers",
		type: "autocomplete",
		label: "Brokers",
		required: true,
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/consts/brokers",
			multiple: true,
			preloadDefault: true,
		},
	};
};

const RPM = {
	key: "rpm",
	type: "slider",
	label: "RPM Range",
	defaultValueMin: 0,
	defaultValueMax: 5,
	slider: {
		type: "range",
		prefix: "$",
		step: 0.1,
		min: 0,
		max: 5,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const MILES = {
	key: "miles",
	type: "slider",
	label: "Miles Range",
	defaultValueMin: 0,
	defaultValueMax: 10000,
	slider: {
		type: "range",
		postfix: " mi",
		min: 0,
		max: 4000,
		step: 50,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const QUICK_ACTIONS_SELECT = {
	key: "quick_action",
	type: "select",
	label: "Quick action",
	required: true,
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Select an action" },
		{
			value: "SEND_LOAD_WITH_PAYLOAD",
			label: "Send 1 fake load with payload",
		},
		{
			value: "SEND_LOAD_WITH_REFRESH",
			label: "Send 1 fake load with refresh",
		},
		{
			value: "TRIGGER_SEARCH_REFRESH",
			label: "Trigger search resfresh with sockets",
		},
	],
};

const createInternalLoadFrom = () => {
	return {
		quickActions: {
			noInitValidation: true,
			items: [
				{
					type: "section",
					label: "Run Quick Action",
					translate: "Quick actions",
				},
				{
					type: "group",
					content: {
						items: [QUICK_ACTIONS_SELECT, { key: "total_stub", type: "stub" }],
					},
				},
				{
					key: "execute_action",
					type: "action",
					label: "Run",
					button: { classes: { root: "h-46 rounded-4" } },
				},
			],
		},
		loadGeneration: {
			noInitValidation: true,
			items: [
				{
					type: "section",
					label: "Add Loads to Cache",
					translate: "Quantity",
				},
				{
					type: "group",
					content: {
						items: [TOTAL_LOADS, { key: "total_stub", type: "stub" }, { key: "total_stub", type: "stub" }],
					},
				},
				{
					type: "group",
					content: {
						items: [BEST_LOADS, BOOK_LOADS, BID_LOADS],
					},
				},
				{
					type: "section",
					label: "Cargo",
					translate: "Cargo",
				},
				{
					type: "group",
					content: {
						items: [EQUIPMENT(), WEIGHT],
					},
				},
				{
					type: "section",
					label: "Stops",
					translate: "Stops",
				},
				{
					type: "group",
					content: {
						items: [LOCATION_ORIGIN(), PICKUP_DATE()],
					},
				},
				{
					type: "group",
					content: {
						items: [LOCATION_DESTINATION(), DELIVERY_DATE()],
					},
				},
				{
					type: "section",
					label: "Broker",
					translate: "Broker",
				},
				BROKERS(),
				{
					type: "section",
					label: "Miles and RPM",
					translate: "Miles and RPM",
				},
				{
					type: "group",
					content: {
						items: [MILES, RPM],
					},
				},
				{ key: "action", type: "action", button: { alwaysEnabledIfValid: true, sticky: true } },
			],
		},
	};
};

export { createInternalLoadFrom };
