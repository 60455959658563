import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export function getPlaidLinkToken(carrierid, data) {
	let url = `/billing/carriers/${carrierid}/plaid/link`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getPlaidDevLinkToken(carrierid) {
	let url = `/billing/carriers/${carrierid}/plaid/link/development`;
	return createRequest()
		.post(url, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function createPlaidAccessToken(carrierId, data) {
	let url = `/billing/carriers/${carrierId}/plaid/token`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function createPlaidDevAccessToken(carrierId, data) {
	let url = `/billing/carriers/${carrierId}/plaid/token/development`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function deleteReautenticateErrors(carrierId, sourceId, reason) {
	let url = `/billing/carriers/${carrierId}/sources/${sourceId}/errors/${reason}`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function addPaymentMethod(carrierId, data) {
	let url = `/billing/carriers/${carrierId}/stripe-sources`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function createAchAccount(carrierId, data) {
	const url = `/billing/carriers/${carrierId}/ach-account`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function removeSmartpayPayoutAccount(carrierId) {
	const url = `/billing/carriers/${carrierId}/payout-account`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function itemLoginRequiredHandler(carrierId) {
	const url = `/billing/carriers/${carrierId}/payout-account/item-login-required-error`;
	return createRequest()
		.post(url, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function addTestPayoutAccount(carrierId) {
	const url = `/billing/carriers/${carrierId}/payout-account/test`;
	return createRequest()
		.post(url, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function removeAchAccount(carrierId) {
	const url = `/billing/carriers/${carrierId}/ach-account`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function itemLoginRequiredAchAccountHandler(carrierId) {
	const url = `/billing/carriers/${carrierId}/ach-account/item-login-required-error`;
	return createRequest()
		.post(url, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function addTestAchAccount(carrierId) {
	const url = `/billing/carriers/${carrierId}/ach-account/test`;
	return createRequest()
		.post(url, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

/*******************************************************************************
 *                              	Invoices
 *******************************************************************************/

export function invoiceCharge(endpoint) {
	let url = endpoint;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

/*******************************************************************************
 *                              	Payroll V3
 *******************************************************************************/

export function closePayroll(data) {
	const url = `/billing/v3/payroll/close`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function approvePayroll(data) {
	const url = `/billing/v3/payroll/approve`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function rollbackPayroll(data) {
	const url = `/billing/v3/payroll/rollback`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function generateDriverPayroll(data) {
	const url = `/billing/v3/payroll/generate-report`;
	return createRequest()
		.post(url, data, { responseType: "arraybuffer", headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function generateDriverPayrollForDriver(carrierId, driverId, userId, data) {
	const url = `/billing/v3/payroll/carrier/${carrierId}/${
		driverId ? `driver/${driverId}` : `user/${userId}`
	}/users/generate-report`;
	return createRequest()
		.post(url, data, { responseType: "arraybuffer", headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function removeTripsFromPayroll(carrierId, payrollId, data) {
	const url = `/billing/v3/carriers/${carrierId}/payroll/${payrollId}/details/trips/remove`;
	return createRequest()
		.post(url, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getPayrollById(payrollId) {
	const url = `/billing/v3/payroll/${payrollId}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

/*******************************************************************************
 *                              	SmartPay Payouts
 *******************************************************************************/

export function getPayout(carrierId, payoutId) {
	const url = `/api/trip-invoicing/carriers/${carrierId}/payouts/${payoutId}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function transitionPayout(carrierId, payoutId, status, params = {}) {
	const url = `/api/trip-invoicing/carriers/${carrierId}/payouts/${payoutId}/${status}`;
	return createRequest()
		.put(url, params, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
