export const SAVE = {
	key: "save",
	type: "button",
	label: "Save",
	config: {
		startIcon: "save",
	},
	disabledView: {
		label: "Saved",
		config: {
			startIcon: "save",
		},
	},
};

export const SAVEDISABLED = {
	key: "save",
	type: "button",
	label: "Save",
	disabled: true,
	config: {
		startIcon: "save",
	},
};

export const LOAD = {
	key: "load",
	type: "button",
	label: "Load",
	config: {
		startIcon: "publish",
	},
};

export const LOADDISABLED = {
	key: "load",
	type: "button",
	label: "Load",
	disabled: true,
	config: {
		startIcon: "publish",
	},
};

export const PREV = {
	key: "previous",
	type: "button",
	label: "Prev",
	config: {
		startIcon: "keyboard_arrow_left",
	},
};

export const NEXT = {
	key: "next",
	type: "button",
	label: "Next",
	config: {
		endIcon: "keyboard_arrow_right",
	},
};

export const LATEST = {
	key: "latest",
	type: "button",
	label: "Latest",
	config: {
		startIcon: "last_page",
	},
};
