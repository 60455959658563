const STATUS_TRUCK_PLAN = {
	key: "status_truck_plan",
	type: "select",
	label: "Plan Status",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "PUBLISHED",
			label: "Active (Edited)",
		},
		{
			value: "DRAFT",
			label: "Active (Auto-Generated)",
		},
		{
			value: "__CURRENT__",
			label: "Active (All)",
		},
		{
			value: "COVERED",
			label: "Inactive (Covered)",
		},
		{
			value: "NO_TRUCK_PLAN",
			label: "Inactive (No plan)",
		},
		{
			value: "CANCELLED",
			label: "Inactive (Cancelled)",
		},
		{
			value: "__EMPTY_ALL__",
			label: "Inactive (All)",
		},
	],
};
const STATUS_TRUCK_LOAD = {
	key: "status_truck_load",
	type: "select",
	label: "Load Status",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "HAS_LOAD",
			label: "Has Active Load",
		},
		{
			value: "EMPTY",
			label: "Empty",
		},
	],
};
const CARRIER = {
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	field: {
		noErrorMessage: true,
		classes: {},
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		listenEvent: "profileRevision",
	},
};

const TRUCK = {
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	translate: "",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/trucks",
		params: {
			options_metadata_truck: true,
			options_metadata_driver: true,
			options_active_only: true,
			options_format_show_driver_phone: true,
		},
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
	},
};
const TRIP_AVAILABILITY = {
	key: "trip_availability",
	type: "select",
	label: "Truck Availability",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "PAST",
			label: "Past",
		},
		{
			value: "UPCOMING",
			label: "Upcoming",
		},
	],
};
const TRUCK_ACTIVE = {
	key: "truck_active",
	type: "select",
	label: "Truck Status",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "ACTIVE",
			label: "Active",
		},
		{
			value: "INACTIVE",
			label: "Inactive",
		},
	],
};
const CARRIER_ACTIVE = {
	key: "carrier_active",
	type: "select",
	label: "Carrier Status",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "ACTIVE",
			label: "Active",
		},
		{
			value: "INACTIVE",
			label: "Inactive",
		},
	],
};
const CARRIERS_TYPE = {
	key: "carriers_type",
	type: "select",
	label: "Internal Carrier Type",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "REAL_CARRIERS",
			label: "Real Carriers",
		},
		{
			value: "TEST_CARRIERS",
			label: "Test Carriers",
		},
	],
};
const TRUCK_POSTER = {
	key: "truck_posted",
	type: "select",
	label: "External Postings",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "ACTIVE",
			label: "Enabled",
		},
		{
			value: "INACTIVE",
			label: "Disabled",
		},
	],
};
const LOAD_SCOUT = {
	key: "load_scout_status",
	type: "select",
	label: "Load Scout",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "ACTIVE",
			label: "Enabled",
		},
		{
			value: "INACTIVE",
			label: "Disabled",
		},
	],
};
const DIFFICULT_MARKET = {
	key: "difficult_market",
	type: "select",
	label: "Market condition",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Not Selected" },
		{ value: true, label: "Difficult market" },
		{ value: false, label: "Good market" },
	],
};
const PLAN_VALIDITY = {
	key: "outdated_flag",
	type: "select",
	label: "Plan Validity",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Not Selected" },
		{ value: true, label: "Outdated" },
		{ value: false, label: "Valid" },
	],
};
const CARRIER_ACCOUNT_TYPE = {
	key: "carrierAccountType",
	type: "select",
	label: `Account Type`,
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "MAIN_ACCOUNT",
			label: "Main Account",
		},
		{
			value: "SUB_ACCOUNT",
			label: "Sub-Account",
		},
	],
};

const filters = (isInternal, isMainHandler) =>
	[
		[STATUS_TRUCK_PLAN, isInternal],
		[STATUS_TRUCK_LOAD],
		[CARRIER, isInternal || isMainHandler],
		[TRUCK, isInternal || isMainHandler],
		[TRUCK_ACTIVE],
		[CARRIER_ACTIVE, isInternal],
		[CARRIERS_TYPE, isInternal],
		[CARRIER_ACCOUNT_TYPE, isInternal || isMainHandler],
		[TRIP_AVAILABILITY],
		[PLAN_VALIDITY],
		[TRUCK_POSTER],
		[LOAD_SCOUT],
		[DIFFICULT_MARKET],
	]
		.filter(([filterKey, showIf]) => showIf === undefined || showIf)
		.map(([filterKey, showIf]) => filterKey);

export default filters;
