const profiles = {
	urlGET: "api/profile/profiles",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "INTERNAL_PROFILES",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		defaults: {
			// Initial value of fields
			initial: {
				filters: {
					userStatus: "ACTIVE",
					multiaccountType: "__NOT_SELECTED__",
				},
			},
			// Additional quick filters
			items: [
				{
					label: "All",
					filters: { multiaccountType: "__NOT_SELECTED__" },
				},
				{
					label: "Single-User",
					filters: { multiaccountType: "SINGLE_USER" },
				},
				{
					label: "Multi-User",
					filters: { multiaccountType: "MULTI_USER" },
				},
			],
		},
		filters: {
			default: {},
			search: true,
			countVisible: 2,
			items: [
				{
					key: "userStatus",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ACTIVE",
							label: "Has 1+ Active Users",
						},
						{
							value: "INACTIVE",
							label: "Has Only Inactive Users",
						},
					],
				},
				{
					key: "multiaccountType",
					type: "select",
					label: "Multi-Users Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "MULTI_CARRIER",
							label: "Profile Has Multiple Users",
						},
						{
							value: "SINGLE_CARRIER",
							label: "Profile Has Single User",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "Account ID", group: "internal" },
			{
				key: "profile__view",
				type: "profile__view",
				label: "Profile",
				builder: (item) => item.profile__view,
				viewConfig: {
					showTotalUsersCount: true,
					showActiveUsersCount: true,
					showEmail: true,
				},
			},
			{
				key: "users__view",
				type: "user__view",
				label: "User",
				builder: (item) => item.users__view,
				viewConfig: {
					showRole: true,
					showAccountStatus: true,
				},
			},
			{ key: "createdAt", type: "date", label: "Created" },
			{ key: "usersCount", type: "text", label: "Users #" },
			{ key: "usersActiveCount", type: "text", label: "Active #" },
		],
	},
};

export default profiles;
