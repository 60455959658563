import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";
import Divider from "@material-ui/core/Divider";
import ViewTitleSection from "./ViewTitleSection";
import ViewContentSection from "./ViewContentSection";
import Typography from "@material-ui/core/Typography";

function MessageViewCommandHelp(props) {
	const { message } = props;
	const variant = props.variant;

	const commandsList = message?.content?.commands.reduce((acc, command) => {
		acc.push({
			type: "TEXT",
			value: command.message ? command.message : `/${command.key}`,
			classes: { text: "font-bold text-13 mt-4" },
		});
		acc.push({
			type: "TEXT",
			value: command.params ? `Params: ${command.params.join(", ")}` : command.description,
		});
		acc.push({
			type: "LABEL_VALUE",
			label: "Usage",
			value: command.usage,
			classes: { label: "italic text-13 text-gray-500 mt-4", value: "italic text-13 text-gray-500 mt-4" },
		});
		acc.push({
			type: "DIVIDER",
		});

		return acc;
	}, []);

	console.log("COMMAND LIST ", commandsList);

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={true}
			headerConfig={{ title: message?.content?.title, subtitle: message?.content?.subtitle, showDivider: true }}
			contentFields={commandsList}
			variant={variant}
			contentComponents={
				<ViewContentSection variant={variant}>
					<ViewTitleSection variant={variant} title={message?.content?.title} message={message?.content?.subtitle} />
					{message?.content?.commands.map((command) => {
						return (
							<>
								<Typography className="font-bold text-13 mt-4 max-w-450 md:max-w-600 lg:max-w-450">
									{command.message ? command.message : `/${command.key}`}
								</Typography>
								<Typography className="font-normal text-13 mt-4 max-w-450 md:max-w-600 lg:max-w-450">
									{command.params ? `Params: ${command.params.join(", ")}` : command.description}
								</Typography>
								<Typography className="font-normal italic text-13 text-gray-500 mt-4 max-w-450 md:max-w-600 lg:max-w-450">
									Usage: {command.usage}
								</Typography>
								<Divider className="mb-10 mt-5" />
							</>
						);
					})}
				</ViewContentSection>
			}
		/>
	);
}
export default MessageViewCommandHelp;
