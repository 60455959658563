import { fetchChats } from "app/store/messenger/chatSlice";
import { getUserId } from "app/services/LoginService";

const truckStatus = {
	urlGET: "api/profile/carriers/:carrierId/trucks/:id",
	urlPUT: "api/profile/carriers/:carrierId/trucks/:id/status/:status",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Change Truck Status" },
	},
	content: {
		edit: {
			items: [
				{
					type: "message",
					builder: (item) =>
						`Would you like to ${!!item.active ? "archive" : "activate"} truck with ID '${item.truckid}'?`,
				},
				{
					key: "deactivateTrailer",
					type: "checkbox",
					label: "Archive Trailer Assigned",
					defaultValue: true,
					checkbox: {
						noErrorMessage: true,
						disclaimer: "If you archive the trailer it will be unassigned from the truck",
					},
					hidden: (item) => !item?.trailerId || !item.active,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE", label: "Continue" },
						],
					},
				},
			],
			onFinished: (data, dispatch) => {
				dispatch(fetchChats({ userId: getUserId() }));
				if (window?.ReactNativeWebView) {
					window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRUCK_LIST" }));
				}
			},
		},
	},
};

export default truckStatus;
