// Utils
import { centsToDollars } from "app/main/utils/financeUtils";
import { capitalizeFirstLetter } from "app/main/utils/chatUtils";

const carrierTransactionDetailsForm = {
	urlGET: "api/finance/carriers/:carrierId/transactions/:transactionId",
	idKey: "id",
	triggerEvent: "",
	listenEvent: "",
	header: {
		view: { label: "Transaction Details" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "createdAt",
								type: "picker",
								label: "Date",
							},
							{
								key: "latest_charge.payment_method_details.type",
								type: "text",
								label: "Payment Method",
								builder: (item) => capitalizeFirstLetter(item?.latest_charge?.payment_method_details?.type),
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{ key: "description", type: "text", label: "Description" },
							{
								key: "latest_charge.status",
								type: "text",
								label: "Status",
								builder: (item) => capitalizeFirstLetter(item?.latest_charge?.status),
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "amount",
								type: "currency",
								label: "Amount",
								builder: (item) => centsToDollars(item?.amount),
							},
							{
								key: "latest_charge.amount_refunded",
								type: "currency",
								label: "Refunded",
								hidden: (item) => !item?.latest_charge?.refunded,
								builder: (item) => centsToDollars(item?.amount),
							},
						],
					},
				},
				{
					key: "latest_charge.invoice.lines.data",
					type: "pattern",
					label: "Invoice Items",
					hidden: (item) => !item?.latest_charge?.invoice?.lines?.data,
					pattern: {
						classes: { label: "-mt-8" },
						variant: "narrow",
						disableMoveButton: true,
						disableInsertButtons: true,
						disablDeleteButton: true,
						disablAddButton: true,
						hideIndex: true,
					},
					content: {
						items: [
							{
								type: "group",
								content: {
									items: [
										{ key: "description", type: "text", label: "Description" },
										{ key: "quantity", type: "number", label: "Quantity" },
										{
											key: "amount",
											type: "currency",
											label: "Amount",
											builder: (item) => centsToDollars(item?.amount),
											translate: "",
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
};

export default carrierTransactionDetailsForm;
