import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

function ViewBanner(props) {
	const variant = props.variant;
	const message = props.message;
	const icon = props.icon;

	return (
		<div className="flex flex-col">
			<div className={"flex items-center w-full mt-4 mb-10 " + (variant === "notification" ? " pr-44 " : "  ")}>
				<div
					className={
						"flex flex-row flex-full rounded-12 min-h-32 items-center max-w-450 md:max-w-600 lg:max-w-450 " +
							(!props.classess?.banner?.includes("bg-") ? " bg-blue " : "") +
							props.classess?.banner ?? ""
					}
				>
					{icon && (
						<Icon
							className={
								"text-24 text-white ml-14 " +
									(!props.classess?.icon?.includes("text-") ? " text-white text-24 " : "") +
									props.classess?.icon ?? ""
							}
							color="action"
						>
							{icon}
						</Icon>
					)}
					{message && (
						<Typography
							className={
								"font-normal p-12 text-12 md:text-13 " +
									(!props.classess?.text?.includes("text-") ? " text-white text-12 md:text-13 " : "") +
									props.classess?.text ?? ""
							}
						>
							{message}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
}

export default ViewBanner;
