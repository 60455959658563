import { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

// Form
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { carrierTruckOnboardingForm } from "@smarthop/form/configs/carrierTruckOnboardingForm";

// Service
import { getTruck, createTruck, updateTruck } from "app/services/truckServices";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

function TruckViewOnboarding(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const { mode, carrierId, truckId } = props;

	const isCreate = mode === "CREATE";

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!carrierId || !truckId || isCreate) {
			return;
		}
		getTruck(carrierId, truckId).then(
			(response) => {
				setData(response);
			},
			(err) => {
				setError(true);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);

		// eslint-disable-next-line
	}, [carrierId, truckId]);

	const form = useMemo(() => carrierTruckOnboardingForm(), []);

	if (!data && !isCreate && !error) {
		return (
			<div className="flex flex-col w-full items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	const handledSave = (action = null) => {
		setLoading(true);
		if (action) data.action = action;
		data.onboarding = true;
		const promise = isCreate ? createTruck(carrierId, data) : updateTruck(carrierId, truckId, data);

		promise.then(
			() => {
				setLoading(false);
				showSnackbar(snackbar, "Successfully Saved Information", "success");

				dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			},
			(err) => {
				setLoading(false);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);
	};

	return (
		<>
			<SmarthopFormView
				key={"truck-view-onboarding"}
				mode={mode}
				content={form}
				data={data}
				noInitValidation={true}
				dataIds={{ carrierId, truckId }}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					setData({ ...data, ...model });
				}}
				onSubmit={() => {
					handledSave();
				}}
				processing={loading}
			/>
		</>
	);
}

export default TruckViewOnboarding;
