const fuel = {
	urlGET: "billing/carriers/:carrierId/fuel-cards/:id",
	urlPUT: "billing/carriers/:carrierId/fuel-cards/:id",
	urlPOST: "billing/carriers/:carrierId/fuel-cards",
	urlDELETE: "billing/carriers/:carrierId/fuel-cards/:id",
	idKey: "_id",
	triggerEvent: "fuelRevision",
	listenEvent: "fuelRevision",
	header: {
		create: { label: "Assign Fuel Card" },
		edit: { label: "Edit Fuel Card" },
		delete: { label: "Delete Fuel Card" },
	},
	messages: {
		create: { success: "Fuel card assigned." },
		edit: { success: "Fuel card updated." },
		delete: { success: "Fuel card deleted." },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete card ${item.card_number}?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		create: {
			items: [
				{
					key: "card_number",
					type: "autocomplete",
					label: "Number Card",
					required: true,
					translate: "AVAILABLE_FUEL_CARDS",
					autocomplete: {
						provider: "smarthop",
						url: "billing/carriers/:carrierId/fuel-cards",
						params: { options_available_only: true },
						preloadDefault: true,
						listenEvent: "fuelRevision",
					},
				},
				{
					key: "truck",
					type: "autocomplete",
					label: "Truck",
					required: true,
					translate: "TRUCK",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers/:carrierId/trucks",
						params: { options_active_only: true, options_metadata_driver: true, label_format_show_driver: true },
						preloadDefault: true,
						listenEvent: "profileRevision",
					},
				},
				{
					key: "status",
					type: "select",
					label: "Status",
					required: true,
					defaultValue: "active",
					options: [
						{
							value: "active",
							label: "Active",
							translate: "ACTIVE",
						},
						{
							value: "hold",
							label: "Inactive",
							translate: "INACTIVE",
						},
					],
				},
				{ type: "action", action: "SUBMIT", label: "Save" },
			],
		},
		edit: {
			items: [
				{
					key: "truck",
					type: "autocomplete",
					label: "Truck",
					required: true,
					translate: "TRUCK",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers/:carrierId/trucks",
						params: { options_active_only: true, options_metadata_driver: true, label_format_show_driver: true },
						preloadDefault: true,
						listenEvent: "profileRevision",
					},
				},
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "active",
					required: true,
					options: [
						{
							value: "active",
							label: "Active",
							translate: "ACTIVE",
						},
						{
							value: "hold",
							label: "Inactive",
							translate: "INACTIVE",
						},
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
		view: {
			sameAs: "edit",
		},
	},
};

export default fuel;
