import { combineReducers } from "@reduxjs/toolkit";
import carrierCredentials from "./credentialsSlice";
import searchV3Slice from "./searchV3Slice";

const reducer = combineReducers({
	carrierCredentials,
	searchV3Slice,
});

export default reducer;
