import { useState, useEffect } from "react";
import { getMarketLocations } from "app/services/marketConditionsServices";

const useLocations = ({ props, mapObj }) => {
	const data = props.data;
	const activeLocations = !!data.activeLocations;
	const origin = data.origin;
	const destination = data.destination;
	const locations = data.locations;
	const moves = data.moves;
	const activeHeatMap = !!data?.activeHeatMap;
	const hasLatLong = !!data?.hasLatLong;
	const stopSequence = props.stopSequence;

	const [loadingCoords, setLoadingCoords] = useState(false);
	const [locationsError, setLocationsError] = useState(null);
	const [centerMap, setCenterMap] = useState([37.09024, -95.712891]);
	const [locationsCoord, setLocationsCoord] = useState([]);
	const [movesCoord, setMovesCoord] = useState([]);
	const [polylines, setPolylines] = useState([]);
	const [movesPolylines, setMovesPolylines] = useState([]);

	const removePolylines = () => {
		polylines.forEach((polyline) => {
			polyline.setMap(null);
		});

		setPolylines([]);
	};
	const removeMovesPolylines = () => {
		movesPolylines.forEach((polyline) => {
			polyline.setMap(null);
		});

		setMovesPolylines([]);
	};

	useEffect(() => {
		if (activeLocations) {
			setLoadingCoords(true);
			if (hasLatLong) {
				setLoadingCoords(false);
				return setLocationsCoord(locations ?? []);
			}
			getMarketLocations({ origin, destination, locations }).then(
				(res) => {
					setLoadingCoords(false);
					setLocationsCoord(res);
					setLocationsError(null);
				},
				(error) => {
					setLoadingCoords(false);
					setLocationsError(error?.errors?.[0].message ?? "Oops, failed to get locations");
				}
			);
		} else {
			setLocationsCoord([]);
     	removePolylines();
		}
		// eslint-disable-next-line
	}, [origin, destination, locations, activeLocations]);

	useEffect(() => {
		if (stopSequence) {
			setLoadingCoords(false);
			return setMovesCoord(moves ?? []);
		} else {
			setMovesCoord([]);
     		removeMovesPolylines();
		}
		// eslint-disable-next-line
	}, [moves, stopSequence]);

	useEffect(() => {
    if (locationsCoord?.length && mapObj.map && mapObj.maps) {
      removePolylines();

      const { map, maps } = mapObj;

      const updatedPolylines = [];
      locationsCoord.forEach((location, idx) => {
        if (idx === locationsCoord?.length -1) return

        const isDestination = idx === locationsCoord.length - 2 && destination;
        const isOrigin = idx === 0 && origin;

        const color = isOrigin || isDestination ? "rgb(6, 34, 70)" : "#ff751a";
        const path = [
          { lat: location.lat, lng: location.lng },
          { lat: locationsCoord[idx + 1].lat, lng: locationsCoord[idx + 1].lng }
        ];

        if (activeHeatMap) {
          const whitePolyline = new maps.Polyline({
            map,
            path,
            strokeColor: color, //((idx === 0 && origin) || isDestination) ? color : "rgb(6, 34, 70)",
            strokeWeight: 4,
            zIndex: 9
          });
  
          updatedPolylines.push(whitePolyline);
        }
        
        const polyline = new maps.Polyline({
          map,
          path,
          strokeColor: color,
          strokeWeight: 3.5,
          strokeOpacity: 1.0,
          zIndex: 10
        });

        updatedPolylines.push(polyline);
      });

      setCenterMap([ locationsCoord[0].lat, locationsCoord[0].lng ]);
      setPolylines(updatedPolylines);
    }
	// eslint-disable-next-line
	}, [locationsCoord, mapObj, activeHeatMap]);

  useEffect(() => {
    if (movesCoord?.length && mapObj.map && mapObj.maps) {
      removeMovesPolylines();

      const { map, maps } = mapObj;

      const updatedPolylines = [];
      movesCoord.forEach((move, idx) => {
       
        const path = [
          { lat: move.o_lat, lng: move.o_lng },
          { lat: move.d_lat, lng: move.d_lng }
        ];
        
        const polyline = new maps.Polyline({
          map,
          path,
          strokeColor:  "rgb(6, 34, 70)",
          strokeWeight: 3.5,
          strokeOpacity: 1.0,
          zIndex: 10
        });

        updatedPolylines.push(polyline);
      });

      setCenterMap([movesCoord[0].o_lat, movesCoord[0].o_lng ]);
      setMovesPolylines(updatedPolylines);
    }
    // eslint-disable-next-line
  	}, [movesCoord, mapObj, activeHeatMap]);

	return { centerMap, locationsCoord, loadingCoords, locationsError, movesCoord };
};

export default useLocations;
