import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function ProfileCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const view = props.view;

	if (!view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "PROFILE_INFO_VIEW",
		dataIds: { profileId: view?.value },
	};

	// Required during migration phase, no every profile is generated at this point
	const profileMissing = view?.metadata?.noProfile;

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			key={view.value}
			first={<CellFirstRow mobile={mobile} view={view} variant={variant} dialog={profileMissing ? null : dialog} />}
			second={
				profileMissing ? null : (
					<div className="flex flex-wrap">
						{config?.showActiveUsersCount && (
							<CellSecondRow
								mobile={mobile}
								label={`Active ${view?.metadata?.usersActiveCount}`}
								variant={variant}
								icon={view?.metadata?.usersActiveCount > 0 ? "done" : "close"}
								color={view?.metadata?.usersActiveCount > 0 ? "text-green-700" : "text-red-700"}
							/>
						)}
						{config?.showTotalUsersCount && (
							<CellSecondRow
								mobile={mobile}
								label={`Total ${view?.metadata?.usersCount}`}
								variant={variant}
								color={"text-grey-700"}
							/>
						)}
						{config?.showEmail && (
							<CellSecondRow
								mobile={mobile}
								icon={"mail"}
								label={view?.metadata?.email ?? "No Email"}
								variant={variant}
							/>
						)}
					</div>
				)
			}
		/>
	);
}

export default ProfileCellView;
