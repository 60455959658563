const { formatCurrency } = require("app/main/utils/tableUtils");

/**
 * Maps logistics costs to a summarized table data form
 * @param {*} logicticsCosts
 * @returns
 */
const mapLogisticsCosts = (logicticsCosts, { ignoreDescriptions } = {}) => {
	const logisticCost = [];
	if (logicticsCosts?.length) {
		for (let logistic of logicticsCosts) {
			logisticCost.push([
				{
					value: formatCurrency(logistic.amount, 2) ?? "-",
					label: logistic.direction === "CREDIT" ? "Addition" : "Deduction",
				},
				{
					value: logistic.reason ?? "-",
					label: "Reason",
				},
				...(logistic.description?.length > 0 && !ignoreDescriptions
					? [
							{
								value: logistic.description ?? "-",
								label: "Description",
								vertical: true,
							},
					  ]
					: []),
			]);
		}
	}
	return logisticCost;
};

/**
 * Maps  Contacts costs to a summarized table data form
 * @param {*} logicticsCosts
 * @returns
 */
const mapExtraContact = (extraContacts) => {
	const results = [];
	if (extraContacts?.length) {
		for (let contact of extraContacts) {
			results.push([
				{
					value: contact?.contact ?? "-",
					label: "Contact",
				},
				{
					value: contact?.phone ?? "-",
					label: "Phone",
				},
				{
					value: contact?.email ?? "-",
					label: "Email",
				},
			]);
		}
	}
	return results;
};

export const tripHelpers = {
	mapLogisticsCosts,
	mapExtraContact,
};
