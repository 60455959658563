import { formatCurrency } from "app/main/utils/tableUtils";

const bidFinalOfferForm = {
	urlGET: "/api/transaction/bid/:transactionId/counter-offer",
	urlPUT: "/api/transaction/bid/:transactionId/counter-offer/response",
	idKey: "_id",
	triggerEvent: "transactionUpdateRevision",
	header: {
		edit: { label: "Final offer Response" },
	},
	content: {
		edit: {
			items: [
				{
					key: "text",
					type: "message",
					builder: (item) => {
						return `Counteroffer of ${formatCurrency(item?.counterRate)} received for load ${item.loadId}`;
					},
				},
				{
					key: "response_type",
					type: "select",
					label: "Action",
					defaultValue: "",
					options: [
						{
							value: "FINAL_OFFER_ACCEPT",
							label: "Accept Offer",
						},
						{
							value: "FINAL_OFFER_REJECT",
							label: "Reject Offer",
						},
					],
				},
				{ type: "action", label: "Submit", action: "SUBMIT" },
			],
		},
	},
};

export default bidFinalOfferForm;
