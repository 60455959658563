import { Typography, Icon } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useMemo } from "react";
import SmarthopTooltipHover from "@smarthop/views/SmarthopTooltipHover";

function LoadSearchSummaryCarrierView(props) {
	const dispatch = useDispatch();

	const createRow = (label, value, onClick) => {
		const reactNative = window?.ReactNativeWebView ?? false;
		return (
			<div className={"flex flex-row justify-between border-b-1 border-grey-300 mt-10"}>
				<Typography component="div" className="flex text-black text-12">
					{label}
				</Typography>
				<Typography
					component="div"
					className={`flex justify-end text-black text-12 ${
						props?.isPublicView || !onClick || reactNative ? "" : "cursor-pointer text-blue-700"
					}`}
					style={{ textAlign: "end", padding: 0 }}
					onClick={props?.isPublicView || !onClick || reactNative ? null : onClick}
				>
					{value ?? "No Information"}
					{value && onClick && <Icon className="text-15 ml-4 mt-2">info</Icon>}
				</Typography>
			</div>
		);
	};

	const primaryDriverValue = useMemo(() => {
		const value = props?.primaryDriver?.name ?? props?.primaryDriver?.id;
		let warning = null;

		if (props?.primaryDriver?.hasOwnProperty("phone") && !props?.primaryDriver?.phone) {
			warning = "Driver profile is missing phone number";
		}

		if (!warning) return value;

		return (
			<SmarthopTooltipHover title={warning}>
				<div className="flex flex-row space-x-4 justify-center items-center">
					<Icon className="text-18 text-orange">warning</Icon>
					<Typography>{value}</Typography>
				</div>
			</SmarthopTooltipHover>
		);
	}, [props?.primaryDriver]);

	return (
		<div className={"flex flex-col "}>
			{props?.carrier?.id &&
				!props?.carrier?.hidden &&
				createRow("Carrier", props?.carrier?.name ?? props?.carrier?.id, () => {
					dispatch(
						openFormDialog({
							viewId: "CARRIER_INFO_VIEW",
							dataIds: { carrierId: props?.carrier?.id },
						})
					);
				})}
			{props?.truck?.id &&
				createRow(
					"Truck",
					`${props?.truck?.id?.includes("ANY_") ? "Generic" : ""} ${props?.truck?.name ?? props?.truck?.id}`,
					props?.truck?.textOnly || props?.truck?.id?.includes("ANY_")
						? null
						: () => {
								dispatch(
									openFormDialog({
										viewId: "TRUCK_DETAILS_VIEW",
										dataIds: { carrierId: props?.carrier?.id, truckId: props?.truck?.id },
									})
								);
						  }
				)}
			{props?.trailer?.id && createRow("Trailer", props?.trailer?.name ?? props?.trailer?.id, null)}
			{props?.primaryDriver?.id &&
				createRow(props?.secondaryDriver?.id ? "Primary Driver" : "Driver", primaryDriverValue, () => {
					dispatch(
						openFormDialog({
							viewId: "USER_DETAILS_VIEW",
							dataIds: { carrierId: props?.carrier?.id, driverId: props?.primaryDriver?.id },
						})
					);
				})}
			{props?.secondaryDriver?.id &&
				createRow("Secondary Driver", props?.secondaryDriver?.name ?? props?.secondaryDriver?.id, () => {
					dispatch(
						openFormDialog({
							viewId: "USER_DETAILS_VIEW",
							dataIds: { carrierId: props?.carrier?.id, driverId: props?.secondaryDriver?.id },
						})
					);
				})}
			{props?.investor?.id &&
				createRow("Investor", props?.investor?.name ?? props?.investor?.id, () => {
					dispatch(
						openFormDialog({
							viewId: "USER_DETAILS_VIEW",
							dataIds: { carrierId: props?.carrier?.id, userId: props?.investor?.id },
						})
					);
				})}
		</div>
	);
}

export default LoadSearchSummaryCarrierView;
