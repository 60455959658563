export const videoFormConfig = () => {
	return {
		items: [
			{
				key: "pageURL",
				type: "text",
				label: "Page URL",
				description: "Ports are not supported, make sure to use a production link",
			},
			{
				key: "videoURL",
				type: "text",
				label: "Video URL",
			},
			{ type: "action", action: "SUBMIT" },
		],
	};
};

export const videoForm = {
	urlGET: "api/videos/:videoId",
	urlPOST: "api/videos",
	urlDELETE: "api/videos/:videoId",
	urlPUT: "api/videos/:videoId",
	triggerEvent: "videoRevision",
	listenEvent: "videoRevision",
	header: {
		create: { label: "Add New Video" },
		edit: { label: "Edit Video" },
		delete: { label: "Delete Video" },
		view: { label: "View Video" },
	},
	messages: {
		delete: { success: "Video deleted.", disabled: false },
		create: { success: "Video added.", disabled: false },
		edit: { success: "Video updated.", disabled: false },
	},
	content: {
		edit: videoFormConfig(),
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to permanently delete this video?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};

export default videoForm;
