import { useSelector } from "react-redux";
import { Divider } from "@material-ui/core";

import PageTitle from "./PageTitle";
import { parseRolesInfo } from "../utils/rolesUtils";

function PageSection(props) {
	const auth = props.auth;
	const user = useSelector(({ auth }) => auth.user);
	const roleStatus = parseRolesInfo(auth, user);

	if (!roleStatus.visible) {
		return null;
	}

	const hasToolbarCompontents = props.title || props.titleActions?.length > 0 || props.toolbarView;
	return (
		<div className="flex w-full flex-col pb-12">
			{hasToolbarCompontents && (
				<PageTitle
					variant="section"
					text={props.isView ? "" : props.title}
					actions={props.titleActions}
					resource={props?.resource}
				/>
			)}
			{hasToolbarCompontents && <Divider className="flex w-full bg-grey-300 mb-14" />}
			{props.children}
		</div>
	);
}

export default PageSection;
