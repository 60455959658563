import { useEffect, useState } from "react";
import { getSignupTerms } from "app/services/LoginService";
import { Typography } from "@material-ui/core";

const titles = {
	PRIVACY_POLICY: "Privacy Policy",
	SERVICE_TERMS: "Terms of Service Agreement",
	SMS_TEMRS: "SMS Terms and Conditions",
};

function TermsView(props) {
	const type = props.type ?? props.dataIds?.type;

	const [loading, setLoading] = useState(true);
	const [content, setContent] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		props?.setTitle?.(titles[type] ?? "");
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let stopped = false;
		(async () => {
			try {
				setLoading(true);
				setError(null);

				const data = await getSignupTerms(type);
				if (stopped) return;

				setContent(data);
			} catch (e) {
				setError(e.errors?.[0]?.message ?? "Oops, something went wrong");
			}

			setLoading(false);
		})();

		return () => {
			stopped = true;
		};
		// eslint-disable-next-line
	}, [type]);

	if (loading) {
		return (
			<div className="flex w-full h-screen70 flex-col items-center justify-center">
				<Typography color="primary" className="text-13">
					Loading...
				</Typography>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex w-full h-screen70 flex-col items-center justify-center">
				<Typography className="w-full text-center text-13" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<div className={"flex flex-col w-full text-14 h-screen70 pb-40 px-10 pt-10"}>
			<div dangerouslySetInnerHTML={{ __html: content }} />
		</div>
	);
}

export default TermsView;
