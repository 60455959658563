// Dependencies
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import createForm from "./EnterprisePayrollRulesConf";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Components
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Services
import { createPayrollRule, getPayrollRule, editPayrollRule } from "app/services/enterprisePayrollServices";
import { getUserDetails } from "app/services/usersServices";

const EnterprisePayrollRulesView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Payroll Rule");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const dataIds = props.dataIds;
	const mode = props?.mode;

	const recurrentPaymentId = dataIds?.recurrentPaymentId;
	const carrierId = dataIds?.carrierId;
	const driverId = dataIds?.driverId;
	const userId = dataIds?.userId;
	const historyData = dataIds.historyData;

	const [isInvestor, setInvestor] = useState(false);
	const [data, setData] = useState(historyData);
	const [loading, setLoading] = useState(false);

	const title = "Payroll Rule";

	useEffect(() => {
		(async () => {
			const promises = [getUserDetails(userId, carrierId)];
			if (recurrentPaymentId && mode !== "CREATE") {
				setLoading(true);
				promises.push(getPayrollRule({ recurrentPaymentId }));
			}
			const [userData, data] = await Promise.all(promises);
			setInvestor(userData?.gatekeepers?.permission_investor_on);
			setData(data);
			setLoading(false);
		})();
	}, [userId, carrierId, recurrentPaymentId, mode]);

	const handleSubmit = async () => {
		let message, success;
		try {
			if (["EDIT", "CREATE"].includes(mode)) {
				if (mode === "CREATE") {
					await createPayrollRule({ data, driverId, userId, carrierId });
				} else {
					await editPayrollRule({ data, driverId, userId, carrierId, recurrentPaymentId });
				}
				dispatch(closeFormDialog());
				dispatch(incrementDataRevision({ event: "payrollRevision" }));
				message = `${title} ${mode === "CREATE" ? "created" : "updated"}.`;
				success = true;
			}
		} catch (e) {
			message = `${e.errors?.[0]?.message} Contact support`;
			success = false;
		}
		showSnackbar(snackbar, message, success ? "success" : "error", {
			duration: 5000,
		});
	};

	const customFormHandler = async (model) => {
		let newData = { ...data, ...model };
		setData(newData);
	};

	let form = createForm({ ...dataIds, mode, isInvestor });

	return (
		<SmarthopFormView
			mode={mode}
			data={data}
			content={form}
			loading={loading}
			noInitValidation={true}
			dataIds={dataIds}
			trackChangedFields={["ALL"]}
			onChangeCommitted={customFormHandler}
			onSubmit={handleSubmit}
		>
			{props.children}
		</SmarthopFormView>
	);
};

export default EnterprisePayrollRulesView;
