import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { formatDate } from "app/main/utils/dateUtils";
import { getReasonConfig } from "app/main/utils/planUtils";

function ReasonsCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const view = Array.isArray(props.view) ? props.view?.[props.view.length - 1] : props.view;

	if (!view?.reason) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const buildItem = (i, view, more) => {
		const config = getReasonConfig(view?.reason);
		return (
			<CellContainerRow
				key={view?.reason + i}
				mobile={mobile}
				variant={variant}
				first={
					<CellFirstRow
						mobile={mobile}
						label={config.label}
						icon={config.icon}
						color={config.color}
						variant={variant}
					/>
				}
				second={<CellSecondRow label={formatDate(view?.createAt)} variant={variant} color={"text-grey-500"} />}
				more={more}
			/>
		);
	};

	let extra = [];
	if (Array.isArray(props.view)) {
		for (let i = props.view.length - 2; i >= 0; i--) {
			extra.push(buildItem(i, props.view[i]));
		}
	}

	return buildItem(0, view, extra);
}

export default ReasonsCellView;
