import { useState } from "react";
import { Typography, Icon, IconButton, Tooltip } from "@material-ui/core";
import { getResourceByKey } from "app/main/resource/resourceUtils";
import ResourceView from "app/main/tools/ResourceView";

function WidgetCardContainer(props) {
	const type = props.type;
	const label = props.label;
	const mutable = props.mutable;
	const description = props.description;
	const children = props.children;
	const nameWidget = props.nameWidget;
	const resourceInformation = getResourceByKey(nameWidget);

	const [openDescription, setOpenDescription] = useState(false);

	return type === "DYNAMIC" ? (
		<>
			<div className="flex flex-row w-full">
				{label && <Typography className={"flex px-10 py-12 text-16 font-medium text-grey-800"}>{label}</Typography>}
				<div className="flex flex-1 flex-row w-full" />

				{description && (
					<Tooltip
						title={
							<Typography component={"pre"} className={"text-white whitespace-pre-wrap break-words"}>
								{description}
							</Typography>
						}
						placement="top"
						interactive={true}
						open={openDescription}
						onClose={() => setOpenDescription(false)}
					>
						<IconButton className="p-14" color="inherit" onClick={() => setOpenDescription(!openDescription)}>
							<Icon className={"text-20 text-grey-500 cursor-pointer"}>info</Icon>
						</IconButton>
					</Tooltip>
				)}

				{resourceInformation && (
					<ResourceView
						type={"icon"}
						description={resourceInformation?.description}
						link={resourceInformation?.link}
						classes={{ icon: "mr-6" }}
					/>
				)}

				{mutable && (
					<IconButton className="p-12 mr-5" color="inherit" onClick={(event) => props.onReloadWidget?.(event)}>
						<Icon className={"text-20 text-grey-600"}>refresh</Icon>
					</IconButton>
				)}
				{mutable && (
					<IconButton className="p-12 mr-5" color="inherit" onClick={(event) => props.onCopyWidget?.(event)}>
						<Icon className={"text-20 text-grey-600"}>copy</Icon>
					</IconButton>
				)}
				{mutable && (
					<IconButton className="p-12 mr-5" color="inherit" onClick={(event) => props.onOpenConfig?.(event)}>
						<Icon className={"text-20 text-grey-600"}>analytics</Icon>
					</IconButton>
				)}
				{mutable && (
					<IconButton className="p-12" color="inherit" onClick={(event) => props.onCloseWidget?.(event)}>
						<Icon className={"text-20 text-grey-600"}>close</Icon>
					</IconButton>
				)}
			</div>
			<div className="flex flex-row w-full h-full -mt-44 pt-44 pb-4 pl-5 pr-4 ">{children}</div>
		</>
	) : type === "STATIC" ? (
		<div className={"flex flex-col w-full h-full"}>
			{resourceInformation && (
				<div className="flex ml-auto">
					<ResourceView
						type={"icon"}
						description={resourceInformation?.description}
						link={resourceInformation?.link}
						classes={{ icon: "mr-6" }}
					/>
				</div>
			)}
			{children}
			{mutable && (
				<div className="absolute flex flex-row w-full -ml-20">
					<div className="flex flex-1 flex-row w-full" />
					<IconButton
						className="p-12 bg-grey-600 mr-5"
						color="inherit"
						onClick={(event) => props.onOpenConfig?.(event)}
					>
						<Icon className={"text-20 text-white"}>analytics</Icon>
					</IconButton>
					<IconButton className="p-12 bg-grey-600" color="inherit" onClick={(event) => props.onCloseWidget?.(event)}>
						<Icon className={"text-20 text-white"}>close</Icon>
					</IconButton>
				</div>
			)}
		</div>
	) : (
		children
	);
}

export default WidgetCardContainer;
