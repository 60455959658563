import { forwardRef } from "react";
import { SnackbarContent } from "notistack";
import MessageNotificationCard from "./MessageNotificationCard";

const MessageNotificationTemplate = forwardRef((props, ref) => {
	const { message } = props;
	return (
		<SnackbarContent ref={ref} className="mx-auto max-w-sm w-full relative pointer-events-auto py-4">
			<MessageNotificationCard message={message} onClose={props.onClose} />
		</SnackbarContent>
	);
});

export default MessageNotificationTemplate;
