import axios_core from "axios";
let SERVER_NAME;
let SOCKET_PORT;
let LOCALHOST_OVERRIDE_IP;

if (
	// Only if we use local host
	process.env.REACT_APP_HTTP_SERVER?.includes("localhost") &&
	// Only if ip override part of URL
	window.location.search?.includes("serverIp") &&
	// Only if ip override is not empty
	!window.location.search?.includes("serverIp=null") &&
	!window.location.search?.includes("serverIp=undefined")
) {
	const urlParams = new URLSearchParams(window.location.search);
	LOCALHOST_OVERRIDE_IP = urlParams.get("serverIp");
}

if (process.env.REACT_APP_UI === "CHAMELION") {
	SERVER_NAME = process.env.REACT_APP_HTTP_SERVER;
	SOCKET_PORT = process.env.REACT_APP_WEBSOCKET_SERVER;
} else {
	SERVER_NAME = process.env.REACT_APP_BACKEND_SERVER;
	SOCKET_PORT = process.env.REACT_APP_SOCKET_PORT;
}

if (LOCALHOST_OVERRIDE_IP?.length > 0) {
	SERVER_NAME = SERVER_NAME.replace("localhost", LOCALHOST_OVERRIDE_IP);
	SOCKET_PORT = SOCKET_PORT.replace("localhost", LOCALHOST_OVERRIDE_IP);
}

export const global = {
	SERVER_NAME: SERVER_NAME,
	SOCKET_PORT: SOCKET_PORT,
	LOCALHOST_OVERRIDE_IP: LOCALHOST_OVERRIDE_IP,
	HEADERS: {
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		"Content-Type": "application/json",
	},
};

export const createHeaders = (isCacheDisabled) => {
	const shBearerToken = `Bearer ${localStorage.getItem("tokenSmarthop")}`;
	const shWalletBearerToken = `Bearer ${localStorage.getItem("@smarthopWalletToken")}`;
	return {
		Authorization: `${shBearerToken}${shWalletBearerToken ? ", " + shWalletBearerToken : ""}`,
		"Content-Type": "application/json",
		...(isCacheDisabled
			? {
					"Cache-Control": "no-cache",
					Pragma: "no-cache",
					Expires: "0",
			  }
			: {}),
	};
};

export const createRequest = (isCacheDisabled, timeout) => {
	return axios_core.create({
		baseURL: global.SERVER_NAME,
		headers: createHeaders(!!isCacheDisabled),
		timeout: timeout,
	});
};

export const throwResErrors = (error) => {
	let response = error?.response;
	let errors = response?.data?.errors?.map
		? { status: response?.status, errors: response?.data?.errors }
		: {
				status: response?.status,
				errors: [
					{
						type: "generic",
						message: `Something went wrong, please try again later (Network error: ${error?.message ?? "Unknown"})`,
					},
				],
		  };
	throw errors;
};

export const axios = axios_core.create({
	baseURL: global.SERVER_NAME,
	headers: global.HEADERS,
});
