import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { global } from "app/services/requestUtil";

export const fetchCredentialsStatus = createAsyncThunk("credentials/list/fetch", async ({ carrierId, noReload }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};
	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.get(`api/profile/carriers/${carrierId}/integrations/status`, {
			headers: headers,
		})
		.catch(function (error) {
			console.log(error);
		});
	return {
		carrierId,
		credentials: response?.data?.credentials ?? {},
		carrierAccountStatus: response?.data?.carrierAccountStatus,
		carrierOnboardingStatus: response?.data?.carrierOnboardingStatus,
		onboardingInformationStatus: response?.data?.onboardingInformationStatus,
	};
});

const credentialsSlice = createSlice({
	name: "credentials/list",
	initialState: {
		credentials: {},
		carrierId: undefined,
		carrierAccountStatus: undefined,
		carrierOnboardingStatus: undefined,
		loading: false,
	},
	reducers: {
		clearCredentials: (state, action) => {
			state.credentials = {};
			state.carrierId = undefined;
			state.carrierAccountStatus = undefined;
			state.carrierOnboardingStatus = undefined;
			state.onboardingInformationStatus = undefined;
			state.loading = false;
			return state;
		},
	},
	extraReducers: {
		[fetchCredentialsStatus.fulfilled]: (state, action) => {
			state.credentials = action?.payload?.credentials;
			state.carrierId = action?.payload?.carrierId;
			state.carrierAccountStatus = action?.payload?.carrierAccountStatus;
			state.carrierOnboardingStatus = action?.payload?.carrierOnboardingStatus;
			state.onboardingInformationStatus = action?.payload?.onboardingInformationStatus;
			state.errors = null;
			state.loading = false;
		},
		[fetchCredentialsStatus.pending]: (state, action) => {
			state.loading = action?.meta?.arg?.noReload ? false : true;
			state.carrierId = action?.meta?.arg?.noReload ? state.carrierId : null;
			state.carrierAccountStatus = null;
			state.carrierOnboardingStatus = null;
			state.onboardingInformationStatus = null;
			state.errors = null;
			state.onboardingInformationStatus = null;
		},
		[fetchCredentialsStatus.rejected]: (state, action) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to get the credentials..." }];
		},
	},
});

export const { clearCredentials } = credentialsSlice.actions;

export default credentialsSlice.reducer;
