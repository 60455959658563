import { Button } from "@material-ui/core";
import { useEffect } from "react";

const DownloadApp = (props) => {
	useEffect(() => {
		props?.setTitle?.("Download Smarthop Mobile App");
		props?.setSize?.("max-w-sm");
		// eslint-disable-next-line
	}, []);

	const buttonAppStore = ({ url, icon, label, index, containerClasses, buttonClasses }) => {
		return (
			<div key={index} className={"flex flex-col items-center " + containerClasses}>
				<Button
					className={"hover:bg-transparent " + buttonClasses}
					onClick={() => {
						window.open(url);
					}}
				>
					<img className="w-full" src={`/assets/images/app-download/${icon}`} alt={label} />
				</Button>
			</div>
		);
	};

	const items = [
		{
			url: "https://play.google.com/store/apps/details?id=com.hopcopilotapp&hl=en_US&gl=US",
			icon: "google-play.png",
			label: "android",
			containerClasses: "min-w-180 max-w-180 md:min-w-210 md:max-w-210",
			buttonClasses: "h-64 md:h-80",
		},
		{
			url: "https://apps.apple.com/ng/app/smarthop/id1573988400",
			icon: "app-store.png",
			label: "ios",
			containerClasses: "min-w-180 max-w-180 md:min-w-210 md:max-w-210",
			buttonClasses: "h-64 md:h-80",
		},
	];

	return (
		<div className="flex flex-row justify-center items-center">
			{items?.map((data, index) => buttonAppStore({ ...data, index }))}
		</div>
	);
};

export default DownloadApp;
