import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const featureSlice = createSlice({
	name: "feature",
	initialState,
	reducers: {
		setFeatures: (state, action) => action.payload,
	},
	extraReducers: {},
});

export const { setFeatures } = featureSlice.actions;

export default featureSlice.reducer;
