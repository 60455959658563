import Typography from "@material-ui/core/Typography";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";

const createElement = (label, element) => (
	<div className={"flex flex-col w-full pt-10 pb-10"}>
		<Typography color="primary" className="font-semibold">
			{label}
		</Typography>
		<Typography color="textSecondary" className="mt-5 break-all ml:break-words">
			{formatCurrency(element)}
		</Typography>
	</div>
);

const carrierLedgerSummaryForm = {
	urlGET: "api/finance/carriers/:carrierId/carrier-ledger-summary?start_date=:start_date&end_date=:end_date",
	header: {
		view: { label: "View Ledger Summary" },
	},
	content: {
		view: {
			items: [
				{
					key: "balance",
					type: "component",
					builder: (item) => createElement("Total Balance", item?.balance),
				},
			],
		},
	},
};

export default carrierLedgerSummaryForm;
