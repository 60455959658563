import PublicTripUploadFiles from "./PublicTripUploadFiles";
const isLogged = localStorage.getItem("tokenSmarthop") ? true : false;

const config = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: isLogged,
				},
				toolbar: {
					display: isLogged,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: isLogged,
				},
				rightSidePanel: {
					display: isLogged,
				},
			},
		},
	},
	routes: [
		{
			path: "/sh-trip-upload-files",
			component: PublicTripUploadFiles,
		},
	],
};

export default config;
