const adminTruckConfiguration = {
	urlGET: "api/profile/carriers/:carrierId/trucks/:id",
	urlPUT: "api/profile/carriers/:carrierId/trucks/:id/internal/config",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Truck Internal Config" },
	},
	content: {
		view: {
			items: [
				{
					key: "dispatcher",
					type: "autocomplete",
					label: "Main Dispatcher",
					autocomplete: {
						provider: "smarthop",
						url: "api/users",
						preloadDefault: true,
					},
				},
				{
					key: "secondary_dispatcher",
					type: "autocomplete",
					label: "Secondary Dispatcher",
					autocomplete: {
						provider: "smarthop",
						url: "api/users",
						preloadDefault: true,
					},
				},
				{
					key: "default_plan_status",
					type: "select",
					label: "Default Truck Plan Status",
					required: true,
					defaultValue: "SUGGEST_ONLY",
					options: [
						{ label: "Suggest Only", value: "SUGGEST_ONLY" },
						{ label: "Auto Book", value: "AUTO_BOOK" },
						{ label: "Contact", value: "CONTACT" },
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
		edit: {
			items: [
				{
					key: "truckid",
					type: "text",
					label: "Truck Id (DO NOT MODIFY)",
					viewOnly: true,
				},
				{
					key: "dispatcher",
					type: "autocomplete",
					label: "Main Dispatcher",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
						preloadDefault: true,
						params: {
							options_internal_only: true,
						},
					},
					rules: {
						disabled: {
							ifEmptyRef: "verified",
						},
					},
				},
				{
					key: "secondary_dispatcher",
					type: "autocomplete",
					label: "Secondary Dispatcher",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
						preloadDefault: true,
						params: {
							options_internal_only: true,
						},
					},
					rules: {
						disabled: {
							ifEmptyRef: "verified",
						},
					},
				},
				{
					key: "default_plan_status",
					type: "select",
					label: "Default Truck Plan Status",
					required: true,
					defaultValue: "SUGGEST_ONLY",
					options: [
						{ label: "Suggest Only", value: "SUGGEST_ONLY" },
						{ label: "Auto Book", value: "AUTO_BOOK" },
						{ label: "Contact", value: "CONTACT" },
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminTruckConfiguration;
