import { payrollEntrySection } from "app/main/profile/drivers/EnterprisePayrollJournalEntrySection";

const title = "Addition/Deduction";

const payrollAdditionsDeductionsForm = ({ isInvestor }) => ({
	urlPOST: `billing/v3/carrier/:carrierId/user/:userId/payroll/:payrollId/journalEntry`,
	urlGET: "billing/v3/payroll/:payrollId/journalEntry/:entryId",
	// urlPUT: "billing/v3/payroll/:payrollId/journalEntry/:entryId",
	urlDELETE: "billing/v3/payroll/:payrollId/journalEntry/:entryId",
	idKey: "_id",
	triggerEvent: "payrollRevision",
	header: {
		view: { label: `View ${title}` },
		delete: { label: `Delete ${title}` },
		create: { label: `Create ${title}` },
	},
	messages: {
		create: { success: () => `${title} created.` },
		delete: { success: () => `${title} deleted.` },
	},
	content: {
		view: {
			sameAs: "create",
		},
		create: {
			items: [
				...payrollEntrySection(),
				...(isInvestor
					? [
							{
								type: "group",
								content: {
									items: [
										{
											key: "truck",
											type: "autocomplete",
											label: "Truck",
											autocomplete: {
												provider: "smarthop",
												url: "api/profile/carriers/:carrierId/investor/:userId/trucks",
												preloadDefault: true,
												params: { options_format_show_carrier: true, options_active_only: true },
											},
										},
									],
								},
							},
					  ]
					: []),
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "You are going to create an addition/deduction. Do you want to continue?.",
					},
				},
			],
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete addition/deduction '${item.name}'?.`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
});

export default payrollAdditionsDeductionsForm;
