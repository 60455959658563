import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";

// Analytics
import { createTrackOrPage } from "../segment/segmentEvent";
import { COMPLETE_ONBOARDING_EVENT } from "../segment/segmentType";

// Services
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { getCarrierId, getUserId, isRoleExternal } from "app/services/LoginService";
import { getOnboardingData, updatedOnboardingData } from "app/services/onboardingServices";
import { getDetailsUser } from "app/services/usersServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { setUserData } from "app/store/auth/userSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { signInWithToken } from "app/services/LoginService";

// Views
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { isAllInformationStepsComplete } from "./OnboardSteps/onboardUtil";
import OnboardSteps from "./OnboardSteps";
import config from "./onboardingStepsConfig";

function OnboardingPage(props) {
	useEffect(() => {
		props?.setTitle?.("Onboarding");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const history = useHistory();

	// Native form
	const onProcessing = props.onProcessing;
	const onSwitchSteps = props.onSwitchSteps;
	const dataIds = props.dataIds;

	const isCreateSubAccount = !!dataIds?.createSubAccount;
	const carrierId = isCreateSubAccount ? dataIds?.carrierId : getCarrierId();
	const userId = isCreateSubAccount ? dataIds?.userId : getUserId();
	const isDialog = isCreateSubAccount;
	const externalRole = isRoleExternal();

	const onboardingRevision = useSelector(({ tools }) => tools.revision["onboardingRevision"]);
	const paymentRevision = useSelector(({ tools }) => tools.revision["paymentRevision"]);
	const profileRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);
	const verificationRevision = useSelector(({ tools }) => tools.revision["codeVerificationRevision"]);
	const moveToSummaryRevision = useSelector(({ tools }) => tools.revision["onboardingMoveToSummaryRevision"]);

	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [saving, setSaving] = useState(false);
	const [moveLastStep, setMoveLastStep] = useState(false);
	const [loadingLastStep, setLoadingLastStep] = useState(false);
	const [lastSummaryRevision, setLastSummaryRevision] = useState(moveToSummaryRevision);

	const onCompleteSteps = async (onDone) => {
		if (saving) return;

		try {
			setSaving(true);

			let data = await updatedOnboardingData(carrierId);
			let userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData));

			setLoadingLastStep(false);
			dispatch(fetchActions({ carrierId }));
			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			dispatch(incrementDataRevision({ event: "planChangeRevisionSoft" }));
			if (isCreateSubAccount) dispatch(incrementDataRevision({ event: "accountRevision" }));

			createTrackOrPage(COMPLETE_ONBOARDING_EVENT, data, "track");
			showSnackbar(snackbar, "You have finished all the onboarding! Your account will be activated soon.", "success", {
				autoHideDuration: 10000,
			});

			onDone?.(true);
			setData((statusData) => ({ ...statusData, ...data }));
			setSaving(false);

			const reactNative = props.nativeMobile ?? window?.ReactNativeWebView ?? false;
			if (reactNative) {
				window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_ACTIONS", data: carrierId }));
			}
		} catch (err) {
			onDone?.(false);
			setSaving(false);
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Failed to submit data", "error", {
				autoHideDuration: 4000,
			});
		}
	};

	useEffect(() => {
		if (isCreateSubAccount) return;
		if (!externalRole) history.push("/load");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadOnboaringData = async () => {
		const [carrierData, userProfile] = await Promise.all([
			getOnboardingData(carrierId),
			getDetailsUser(userId, carrierId),
		]);

		let isVideoStepComplete;
		if (data?.complete_wizzard) {
			isVideoStepComplete = true;
		} else {
			isVideoStepComplete = props.reactNative
				? data?.video_step_complete
				: localStorage.getItem("onboardingVideoStepComplete");
		}
		const loadedData = { ...carrierData, video_step_complete: isVideoStepComplete, user: userProfile };
		return loadedData;
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await loadOnboaringData();
				setData({ ...data, isCreateSubAccount });
				onProcessing?.(false);
			} catch (error) {
				setError(`Failed to load onboarding information (${error.message ?? "Unknown"})`);
			}
		})();
		// eslint-disable-next-line
	}, [carrierId, onboardingRevision, paymentRevision, verificationRevision, profileRevision]);

	useEffect(() => {
		if (moveToSummaryRevision !== lastSummaryRevision) {
			(async () => {
				try {
					console.log("[OnboardingPage] Moving to last step");
					setLastSummaryRevision(moveToSummaryRevision);
					setLoadingLastStep(true);
					setMoveLastStep(true);

					const [carrierData, onboardingData] = await Promise.all([
						await getOnboardingData(carrierId),
						loadOnboaringData(),
					]);

					let isAllInfoStepsComplete = isAllInformationStepsComplete({ config, data: onboardingData });
					if (
						// If onboaring has NOT been submitted but all steps completed we can submit data automatically
						// however that would happend in specific conditions only
						isAllInfoStepsComplete &&
						!onboardingData.complete_wizzard &&
						// We only allow automatic submission of onboarding that when user actually has a plan,
						// otherwise user would need to click start trial manually
						!!carrierData?.billing_plan_subscription_complete &&
						// We would not automatically submit if application was rejected or changes requested
						carrierData?.onboardingStatus !== "REJECTED" &&
						carrierData?.onboardingStatus !== "CHANGES_REQUESTED"
					) {
						console.log("[OnboardingPage] Automatically submit onboarding data");
						onCompleteSteps(() => {
							setLoadingLastStep(false);
							setMoveLastStep(false);
						});
					} else {
						setLoadingLastStep(false);
						setMoveLastStep(false);
					}
				} catch (error) {
					setError(`Failed to load onboarding information (${error.message ?? "Unknown"})`);
				}
			})();
		}
		// eslint-disable-next-line
	}, [moveToSummaryRevision]);

	if (error) {
		return <Typography className="text-13 pb-10 text-red text-center pt-80 px-20">{error}</Typography>;
	}

	if (!data) {
		if (isDialog) {
			return (
				<div className="flex w-full h-512 flex-col items-center justify-center">
					<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
						Loading...
					</Typography>
				</div>
			);
		} else {
			return <FuseSplashScreen />;
		}
	}

	return (
		<OnboardSteps
			config={config}
			data={data}
			onCompleteSteps={onCompleteSteps}
			loadingLastStep={loadingLastStep}
			onSwitchSteps={(prevStep, newStep) => {
				onSwitchSteps?.();
				if (prevStep === 0 && !data?.video_step_complete) {
					setData((data) => ({ ...data, video_step_complete: true }));
					if (!props.reactNative) localStorage.setItem("onboardingVideoStepComplete", true);
				}
			}}
			props={{ ...props, verificationRevision, profileRevision, moveLastStep, isCreateSubAccount }}
		/>
	);
}

export default OnboardingPage;
