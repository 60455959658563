import { downloadFile } from "app/main/utils/fileUtils";

const transactions_report = {
	urlPOST: "api/finance/carriers/:carrierId/carrier-ledger/download/csv",
	idKey: "_id",
	triggerEvent: "",
	listenEvent: "",
	header: { create: { label: "Download Report" } },
	messages: { create: { success: "Report generated." } },
	content: {
		create: {
			items: [
				{
					key: "report_start_date",
					type: "picker",
					label: "Start date",
					translate: "",
					required: true,
					field: {
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
				{
					key: "report_end_date",
					type: "picker",
					label: "End date",
					translate: "",
					required: true,
					field: {
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
				{
					key: "amount",
					type: "select",
					label: "Amount",
					defaultValue: "__EXCLUDE_ZERO__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "All",
						},
						{
							value: "__EXCLUDE_ZERO__",
							label: "Exclude Zero",
						},
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
			onFinished: (data) => {
				downloadFile(data, "Transactions balance report.csv", "text/csv");
			},
		},
	},
};

export default transactions_report;
