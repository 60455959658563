import { useSelector, useDispatch } from "react-redux";

import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";
import { isOnboardingNotSubmitted, getUserTier } from "app/services/LoginService";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { getCarrierId } from "app/services/LoginService";
import { useMemo } from "react";
import { Badge, CircularProgress, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import { useEffect } from "react";
import { fetchFiles } from "app/store/upload/filesSlice";

function UploadPanelMenuItem({ type }) {
	const dispatch = useDispatch();

	const carrierId = getCarrierId();
	const userTier = getUserTier();

	const user = useSelector(({ auth }) => auth.user);
	const formStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);

	let files = useSelector(({ upload }) => upload?.files?.list ?? []);
	if (userTier === "TIER_STARTER") files = [];

	const created = useMemo(
		() => files.filter((file) => ["error", "error_mimetype", "ready", "updated"].includes(file.status)).length,
		[files]
	);
	const inProgress = useMemo(() => files.filter((file) => ["ocr", "gpt"].includes(file.status)).length, [files]);

	const hasNewsFiles = useMemo(() => {
		const oldFilesIds = localStorage.getItem("filesIds");
		const oldFileIdsSave = oldFilesIds ? JSON.parse(oldFilesIds) : [];
		let hasNewItem = false;
		for (let file of files) {
			if (!oldFileIdsSave.includes(file.metadata.uploadId)) {
				hasNewItem = true;
				break;
			}
		}
		return hasNewItem;
	}, [files]);

	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const hasRatesOnPermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;

	useEffect(() => {
		dispatch(fetchFiles());
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (formStack.length === 0 && hasNewsFiles && (hasEditTripsPermission || hasRatesOnPermission)) {
			dispatch(openFormDialog({ viewId: "MULTI_FILE_UPLOAD_VIEW", dataIds: { carrierId } }));
		}
		// eslint-disable-next-line
	}, [hasNewsFiles]);

	if (!hasEditTripsPermission || !hasRatesOnPermission) {
		return null;
	}
	if (isOnboardingNotSubmitted()) {
		return null;
	}

	const openPanel = () => {
		dispatch(openFormDialog({ viewId: "MULTI_FILE_UPLOAD_VIEW", dataIds: { carrierId } }));
	};

	return type === "btn" ? (
		<>
			<Badge badgeContent={created} classes={{ badge: `bg-green text-white mt-5 mr-2` }}>
				<Button
					style={{
						borderRadius: 5,
						color: "white",
						height: "35px",
						marginTop: "2px",
						marginLeft: "8px",
					}}
					color="secondary"
					variant="contained"
					onClick={openPanel}
				>
					<Typography className="text-12">Add RateCon</Typography>
				</Button>
			</Badge>
			{inProgress ? <CircularProgress className="ml-8 mt-5" size={28} style={{ color: "#e7683d99" }} /> : null}
		</>
	) : (
		<MenuItem onClick={openPanel} role="button">
			<ListItemIcon className="min-w-40">
				<Icon>upload</Icon>
			</ListItemIcon>
			<ListItemText primary="Upload Files" />
		</MenuItem>
	);
}

export default UploadPanelMenuItem;
