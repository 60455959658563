import { createRequest, throwResErrors, createHeaders } from "./requestUtil";
import { buildURLParameters } from "app/main/utils/urlUtils";

export const getLoad = (userId, searchId, loadId) => {
	return createRequest()
		.get(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLoadForCarrierRefreshed = (userId, searchId, loadId) => {
	return createRequest()
		.get(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/carrier/refreshed`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLoadForDispatcher = (userId, searchId, loadId) => {
	return createRequest()
		.get(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/dispatcher`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const refreshRouteInformation = (userId, searchId, loadId, data) => {
	return createRequest()
		.post(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/route-information`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const checkActiveBookTransactions = (userId, carrierId, truckId) => {
	return createRequest()
		.get(`api/search/users/${userId}/carrier/${carrierId}/truck/${truckId}/check-active-book-transaction`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLoadForDispatcherRefreshed = (userId, searchId, loadId, options) => {
	return createRequest()
		.get(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/dispatcher/refreshed`, {
			headers: createHeaders(),
			params: options ?? {},
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLoadAvailability = (userId, searchId, loadId, carrierId) => {
	return createRequest()
		.get(`api/search/users/${userId}/carrier/${carrierId}/requests/${searchId}/loads/${loadId}/availability`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const executeLoadAction = (userId, searchId, loadId, data, actionType, from, ignoreWarnings) => {
	return createRequest()
		.put(
			`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/dispatcher`,
			{ data, actionType, from, ignoreWarnings },
			{ headers: createHeaders() }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

// TODO: Maybe we need to remove this endpoint
export const saveSeenLoad = (userId, searchId, loadId) => {
	return createRequest()
		.get(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/seen`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getLoadStrategy = (userId, searchId, loadId, params) => {
	return createRequest()
		.get(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/strategy`, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const acceptLoad = (userId, searchId, loadId, data) => {
	return createRequest()
		.post(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/accept`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const shareLoad = (userId, searchId, loadId, data) => {
	return createRequest()
		.post(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/share`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createTrip = (userId, searchId, loadId, data) => {
	return createRequest()
		.post(`api/search/users/${userId}/requests/${searchId}/loads/${loadId}/create`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getSearchStatus = (userId, searchId) => {
	return createRequest(10000)
		.get(`api/search/users/${userId}/requests/${searchId}/status`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveViewedSearchVersions = (userId, searchId, data) => {
	return createRequest()
		.put(`api/search/users/${userId}/requests/${searchId}/versions/viewed`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveFavoriteLoad = (userId, searchId, data) => {
	return createRequest()
		.post(`api/search/users/${userId}/requests/${searchId}/favorite/loads`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getUIFiltersSearch = (searchId) => {
	return createRequest()
		.get(`api/search/requests/${searchId}/filters`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const deleteFavoriteLoad = (favoriteLoadId) => {
	return createRequest()
		.delete(`api/search/favorite/loads/${favoriteLoadId}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const detailsLoadManually = (data) => {
	return createRequest()
		.post(`api/search/detail/load-manually`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveLoadManually = (data, userId) => {
	return createRequest()
		.post(`api/search/load-create/dispatcher/${userId}`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierStatus = (userId, carrierId, data) => {
	return createRequest()
		.post(`api/search/carrier/${carrierId}/status`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTruckSearchData = (carrierId, truckSearch, equipment, uiFilters) => {
	return createRequest()
		.get(`api/search/carrier/${carrierId}/truck/${truckSearch}/truckSearch/${equipment}`, {
			headers: createHeaders(),
			params: uiFilters ?? {},
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createMarketMonitor = (userId, data) => {
	return createRequest()
		.post(`api/search/users/${userId}/monitor/market`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createWidgetMonitor = (userId, searchModel, onlyExisting) => {
	return createRequest()
		.post(
			`api/search/users/${userId}/monitor/widget`,
			{ data: searchModel, onlyExisting },
			{ headers: createHeaders() }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getMonitorResult = (userId, searchId, options) => {
	return createRequest(10000)
		.get(`api/search/users/${userId}/monitor/${searchId}/result`, { params: options }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export function getPriceLoad({ userId, searchId, loadId }) {
	let url = `api/search/users/${userId}/requests/${searchId}/loads/${loadId}/bid-price`;
	return createRequest()
		.get(url, {}, {})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getMarketConditions(data) {
	let { equipment, field, toplanes } = data;
	let params = { equipment, orientation: field, filter_best: toplanes };
	if (data.location) {
		params.location = data.location;
	}
	let url = buildURLParameters("/api/market", params);
	return createRequest()
		.get(url, {}, {})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getBookDayAndMarketCondition(data) {
	let { equipment, available, location } = data;
	let params = { equipment, available, location };

	let url = buildURLParameters("/api/market/conditions", params);
	return createRequest()
		.get(url, {}, {})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
