import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

function ChatNotSelectedTruckView(props) {
	return (
		<div className="flex flex-col flex-1 items-center justify-center p-24">
			<Paper className="rounded-full p-48 md:p-64 shadow-xl">
				<Icon className="block text-48 md:text-64" color="secondary">
					wb_incandescent
				</Icon>
			</Paper>

			<Typography variant="h6" className="mt-24 mb-12 text-32 font-700">
				No Truck Assigned
			</Typography>
		</div>
	);
}

export default ChatNotSelectedTruckView;
