import moment from "moment";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

// Utils
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { formatCurrency } from "./tableUtils";
import { getRoleLabel } from "app/services/LoginService";

// Dates
const today = moment().startOf("day").format();

// Current Month
const startCurrentMonth = moment().startOf("month").format();
const endCurrentMonth = moment().endOf("month").format();
const titleCurrentMonth = `Current Month: ${moment(endCurrentMonth).format("MMMM YYYY")}`;

// Prevous Month
const endPreviousMonth = moment().subtract(1, "month").endOf("month").format();
const startPreviousMonth = moment().subtract(1, "month").startOf("month").format();
const titlePreviousMonth = `Previous Month: ${moment(endPreviousMonth).format("MMMM YYYY")}`;

const summaryAveragesSection = (isTruck, isSimulator) => {
	return [
		{ type: "section", label: isTruck ? "Averages" : "Averages Per Truck" },
		...(isSimulator
			? []
			: [
					{
						key: "averageAboveMarketPerTruck",
						type: "number",
						label: "Avg Above / Below Market",
						builder: (item) => formatPerformanceCurrency(item?.averageAboveMarketPerTruck),
					},
			  ]),
		{ key: "averageLoadedDaysPerTruck", type: "number", label: "Avg Loaded Days", viewOnly: true },
		...(isTruck
			? []
			: [
					{
						key: "averageAmountPerTruck",
						type: "number",
						label: "Avg Gross",
						viewOnly: true,
						builder: (item) => formatPerformanceCurrency(item?.averageAmountPerTruck),
					},
					...(isSimulator ? [] : [{ key: "averageTripsPerTruck", type: "number", label: "Avg Trips", viewOnly: true }]),
					{ key: "averageMilesPerTruck", type: "number", label: "Avg Loaded Miles", viewOnly: true },
			  ]),
		{
			key: "averageAmountPerDayTruck",
			type: "number",
			label: "Avg Gross Per Day",
			viewOnly: true,
			builder: (item) => formatPerformanceCurrency(item?.averageAmountPerDayTruck),
		},
		{
			key: "averageCostPerMileTruck",
			type: "number",
			label: "Avg Cost Per Mile",
			viewOnly: true,
			builder: (item) => formatPerformanceCurrency(item?.averageTotalVariableCosts),
		},
		{
			key: "averageCostPerDayTruck",
			type: "number",
			label: "Avg Cost Per Day",
			viewOnly: true,
			builder: (item) => formatPerformanceCurrency(item?.averageTotalFixedCosts),
		},
	];
};

const summaryComparisonsSection = () => {
	return [
		{ type: "section", label: "Comparison" },
		{
			key: "smarthopRpmValue",
			type: "number",
			label: "SH RPM",
			viewOnly: true,
			builder: (item) =>
				item?.smarthopRpmValue === "N/A" ? item?.smarthopRpmValue : formatCurrency(item?.smarthopRpmValue),
		},
		{ key: "truckRpmVsSmarthopRpm", type: "number", label: "VS Truck", viewOnly: true },
		{ key: "smarthopTotalAvgMilesTruck", type: "number", label: "SH Avg Loaded Miles Per Truck", viewOnly: true },
		{ key: "truckVsTotalAvgMilesTruck", type: "number", label: "VS Truck Avg", viewOnly: true },
		{
			key: "smarthopTotalAvgGrossTruck",
			type: "number",
			label: "SH Avg Gross Per Truck",
			viewOnly: true,
			builder: (item) =>
				item?.smarthopTotalAvgGrossTruck === "N/A"
					? item?.smarthopTotalAvgGrossTruck
					: formatCurrency(item?.smarthopTotalAvgGrossTruck),
		},
		{
			key: "truckVsTotalAvgGrossTruck",
			type: "number",
			label: "VS Truck Avg",
			viewOnly: true,
		},
		{
			key: "smarthopTotalAvgGrossDayTruck",
			type: "number",
			label: "SH Avg Gross Per Day Per Truck",
			viewOnly: true,
			builder: (item) =>
				item?.smarthopTotalAvgGrossDayTruck === "N/A"
					? item?.smarthopTotalAvgGrossDayTruck
					: formatCurrency(item?.smarthopTotalAvgGrossDayTruck),
		},
		{
			key: "truckVsTotalAvgGrossDayTruck",
			type: "number",
			label: "VS Truck Avg",
			viewOnly: true,
		},
		{
			key: "smarthopTotalAvgLoadedDaysPerTruck",
			type: "number",
			label: "SH Avg Loaded Days Per Truck",
			viewOnly: true,
		},
		{
			key: "truckVsTotalAvgLoadedDaysTruck",
			type: "number",
			label: "VS Truck Avg",
			viewOnly: true,
		},
	];
};

const formatPerformanceCurrency = (value) => {
	value = Number(value);
	return !isNaN(value) && value ? formatCurrency(value) : "N/A";
};

const createSummaryGroups = (items) => {
	let groups = [];
	let counter = 0;
	for (let i = 0; i < Math.round(items.length / 2); i++) {
		groups.push([]);
		for (let j = 0; j < 2; j++) {
			if (items[counter]) {
				groups[i].push(items[counter]);
			}
			counter++;
		}
	}
	return groups;
};

const createSummarySections = (items) => {
	let sections = [];
	items.forEach((item) => {
		if (item.type === "section") {
			let newObj = { section: item, values: [] };
			sections.push(newObj);
		} else {
			sections[sections.length - 1].values.push(item);
		}
	});
	return sections;
};

const generateSections = (items) => {
	let sections = [];
	createSummarySections(items).forEach((item) => {
		let groups = createSummaryGroups(item.values).map((items) => ({
			type: "group",
			content: {
				items,
			},
		}));
		sections.push(item.section, ...groups);
	});
	return sections;
};

const performanceTruckDetailUrl = (item, dataIds) => {
	if (!item.truckId) return null;
	let url = `/reports/carriers/${item.carrierId ?? dataIds.carrierId}/performance/detail/trucks/${
		item.truckId
	}?truckid=${item?.truck__view?.metadata?.truckid}`;
	if (item.equipment) url += `&equipment=${item.equipment}`;
	return url;
};

const performanceCarrierDetailUrl = (item) => {
	let url = `/reports/carriers/${item?.carrierId}/performance`;
	if (item.carrier__view?.label) url += `?carrier_name=${item.carrier__view?.label}`;
	return url;
};

const performanceDispatcherDetailUrl = (item) => {
	if (!item?.dispatcherId) return null;
	let url = `/reports/dispatchers/${item?.dispatcherId}/performance`;
	if (item?.dispatcher__view?.label) url += `?dispatcher_name=${item?.dispatcher__view?.label}`;
	return url;
};

const ProfitCalculationMessageLink = (item, message, editCostStructure) => {
	const dispatch = useDispatch();
	const isCarrier = getRoleLabel() === "carrier";
	const handleClick = () => {
		let dialog = {
			viewId: "PROFIT_CALCULATION",
			dataIds: {
				costStructure: item.costStructure,
				equipment: item.costStructure?.equipment,
				profit: item.profit,
				rate: item.totalAmount,
				totalMiles: Number(item.totalMiles) + Number(item.totalEmpty),
				duration: item.totalLoadedDays,
			},
		};
		if (editCostStructure && (!isCarrier || item?.costStructure?.carrier)) {
			dialog = {
				viewId: "COST_STRUCTURE",
				mode: "EDIT",
				dataIds: {
					id: item?.costStructure?._id,
				},
			};
		}
		dispatch(openLoadedFormDialog(dialog));
	};

	return (
		<div className="w-full pt-10 pb-10 pl-6 pr-6 ">
			<Typography color="primary" className="break-all md:break-words tracking-wide" paragraph={true}>
				{message}
				<Link className="cursor-pointer -mt-6 break-all md:break-words" color="textSecondary" onClick={handleClick}>
					<Typography>Click here.</Typography>
				</Link>
			</Typography>
		</div>
	);
};

/**
 * Return a Hex random color based on a random 0 to 1 value
 * @param {*} seed 0 to 1 value. If not provided Math.random() will be used
 * @returns Hex color
 */
const generateRandomColor = (seed) => {
	return (
		"#" +
		Math.floor((seed ?? Math.random()) * 16777215)
			.toString(16)
			.padStart(6, "0")
			.toUpperCase()
	);
};
const now = new Date();
const actualMonth = now.getMonth() + 1;

/**
 * Returns a hex color based on the input string
 * @param {*} str input string
 * @returns Hex color
 */
const stringToColor = (str) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	let colour = "#";
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xff;
		const hexStr = "00" + value.toString(16);
		colour += hexStr.substring(hexStr.length - 2);
	}
	return colour;
};

export {
	today,
	actualMonth,
	// Current Month
	startCurrentMonth,
	endCurrentMonth,
	titleCurrentMonth,
	// Summary Dates
	startPreviousMonth,
	endPreviousMonth,
	titlePreviousMonth,
	// Sections
	generateSections,
	performanceTruckDetailUrl,
	performanceCarrierDetailUrl,
	performanceDispatcherDetailUrl,
	ProfitCalculationMessageLink,
	formatPerformanceCurrency,
	summaryAveragesSection,
	summaryComparisonsSection,
	generateRandomColor,
	stringToColor,
};
