import { useState, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { Tab, Tabs } from "@material-ui/core";
import LoadStrategyWarning from "./LoadStrategyWarning";

// Services
import { getLoadStrategy } from "app/services/searchServices";

// Component
import LoadStrategy from "./LoadStrategy";
import { loadingViewComponent } from "app/main/common/LoadingView";
import { getStrategyByLocation } from "app/services/strategyServices";

const LoadStrategyTabsView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Load Trip Strategy");
		// eslint-disable-next-line
	}, []);

	const nativeMobile = props.nativeMobile;
	const dataIds = props.dataIds;
	const loadId = dataIds?.id;
	const userId = dataIds?.userId;
	const searchId = dataIds?.searchId;
	const generalStrategy = dataIds?.isGeneralStrategy ?? false;
	const locationStrategy = dataIds?.locationStrategy;
	const truckId = dataIds?.truckId;
	const carrierId = dataIds?.carrierId;

	const [strategyTabs, setStrategyTabs] = useState(null);
	const [typeShow, setType] = useState("STRATEGY_0");
	const [errors, setErrors] = useState(null);

	useEffect(() => {
		if ((!loadId || !userId || !searchId) && !generalStrategy) {
			return;
		}
		props?.onProgress(true);

		if (searchId) {
			getLoadStrategy(userId, searchId, loadId, ...(generalStrategy ? [{ options_general_strategy: true }] : [])).then(
				(data) => {
					generateTabs(data);
					props?.onProgress(false);
				},
				(error) => {
					props?.onProgress(false);
					setErrors(error.errors);
				}
			);
		} else if (locationStrategy) {
			getStrategyByLocation(truckId, carrierId, { origin: locationStrategy }).then(
				(data) => {
					generateTabs(data);
					props.onProgress(false);
				},
				(error) => {
					props?.onProgress(false);
					setErrors(error.errors);
				}
			);
		}
		// eslint-disable-next-line
	}, [loadId]);

	function generateTabs(strategyData) {
		let tabs = [];
		for (let i = 0; i < strategyData?.strategyResult?.paths?.length; i++) {
			let tabInfo = {
				key: `STRATEGY_${i}`,
				label: `Strategy ${i + 1}`,
				component: (
					<LoadStrategy
						isGeneralStrategy={generalStrategy}
						index={i}
						load={strategyData?.load}
						truckPosition={strategyData?.truckPosition}
						strategy={strategyData?.strategyResult.paths[i]}
						legs={strategyData?.strategyResult.legs}
						strategyReqData={strategyData?.strategyResult?.queryData}
						searchId={searchId}
						nativeMobile={nativeMobile}
					/>
				),
			};
			tabs.push(tabInfo);
		}

		setStrategyTabs(tabs);
	}

	const onTabChange = (_, value) => {
		setType(value);
	};

	if (errors?.length > 0) {
		return (
			<div className="flex w-full h-512 flex-col p-10 items-center justify-center">
				<Typography color="error" className="flex pt-10 pb-8 items-center justify-center">
					{errors[0].message}
				</Typography>
			</div>
		);
	}

	if (!strategyTabs) {
		return loadingViewComponent();
	}

	return (
		<div className="w-full">
			<div className="hidden ml:flex">
				<LoadStrategyWarning />
			</div>
			{strategyTabs.length > 1 && (
				<Tabs value={typeShow} onChange={onTabChange} variant="fullWidth">
					{strategyTabs.map((item) => (
						<Tab label={item.label} value={item.key} key={`${item.key}-Tab`} />
					))}
				</Tabs>
			)}
			<div className="flex overflow-x-hidden overflow-scroll flex-col">
				{strategyTabs.map((item) => (
					<Fragment key={`${item.key}-Fragment`}>{typeShow === item.key && item.component}</Fragment>
				))}
			</div>
		</div>
	);
};

export default LoadStrategyTabsView;
