import { Controller } from "react-hook-form";
import { useMemo, useState } from "react";

import Typography from "@material-ui/core/Typography";
import SmarthopFormView from "../SmarthopFormView";

function SmarthopObjectField(props) {
	const dataIds = props.dataIds;
	const noInitValidation = props.noInitValidation;
	const object = props.object;
	const onChange = props.onChange;
	const onBlurField = props.onBlur;
	const __setValue = props.__setValue;
	const customSchema = useMemo(() => object?.schema?.(), [object]);
	const [modified, setModified] = useState(false);

	const onBlur = (keyName) => {
		if (onBlurField) onBlurField(keyName);
	};

	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field }) => {
				const handleSubmitModelItem = (model, { valid, errors }, key) => {
					const result = { value: model, valid, errors };
					if (field?.value?.valid || valid) {
						field.onChange(result);
					} else {
						// No need to trigger validetion outside of nested form if internal does not have visible errors
						const hasVisibleErrors = Object.keys(errors?.visible ?? {}).length > 0;
						__setValue(props.name, result, {
							shouldValidate: hasVisibleErrors,
						});
					}
					onChange?.(result);
				};

				return (
					<div
						className={
							(props.disabled ? " opacity-60 " : "") + " w-full flex flex-col " + (object?.classes?.root ?? "")
						}
					>
						<SmarthopFormView
							key={"object_" + props.name}
							noInitValidation={noInitValidation}
							content={props.content}
							nestedKey={props.name}
							nestedForm={true}
							data={field?.value?.value}
							originalData={props.orignialValue}
							dataIds={dataIds}
							mode={props.mode}
							schema={object?.schema?.()}
							trackChangedFields={["ALL"]}
							overrides={props.styleOverrides}
							onChangeCommitted={(model, key, { valid, errors }) => {
								if (!modified) setModified(true);
								handleSubmitModelItem(model, { valid, errors }, key);
							}}
							onBlurField={onBlur}
						/>
						{modified && customSchema && (
							<Typography variant="caption" color="error" className="text-10">
								{!props.disabled ? field?.value?.errors?.validation : ""}
							</Typography>
						)}
					</div>
				);
			}}
		/>
	);
}

export default SmarthopObjectField;
