const transferOwnershipConfig = () => {
	return {
		items: [
			{
				key: "currentUser",
				label: "Users",
				type: "autocomplete",
				required: true,
				autocomplete: {
					provider: "smarthop",
					url: "api/profile/carriers/:carrierId/users",
					preloadDefault: true,
					params: {
						filters: { accountStatus: "active", roles: "__CONTAINS_INT__22" },
					},
				},
			},
			{ key: "action", type: "action", label: "Transfer" },
		],
	};
};
export { transferOwnershipConfig };
