const userAssignmentsForm = {
	urlGET: "api/profile/users/:id/assignments",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		view: { label: "View User Assignments" },
	},
	content: {
		view: {
			items: [
				{
					key: "main_dispatcher_trucks",
					type: "autocomplete",
					label: "Main Dispatcher Trucks",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/trucks",
						multiple: true,
						preloadDefault: true,
						params: {
							options_metadata_truck: true,
							options_metadata_carrier: true,
							options_format_show_carrier: true,
							options_metadata_driver: true,
							options_active_only: true,
						},
						preview: {
							format: "LIST",
						},
					},
				},
				{
					key: "secondary_dispatcher_trucks",
					type: "autocomplete",
					label: "Secondary Dispatcher Trucks",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/trucks",
						multiple: true,
						preloadDefault: true,
						params: {
							options_metadata_truck: true,
							options_metadata_carrier: true,
							options_format_show_carrier: true,
							options_metadata_driver: true,
							options_active_only: true,
						},
						preview: {
							format: "LIST",
						},
					},
				},
				{ type: "action", action: "SUBMIT", button: { alwaysEnabled: true } },
			],
		},
	},
};

export default userAssignmentsForm;
