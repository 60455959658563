import { columns, groups, orderBy, filters } from "app/main/profile/carriers/fuel/SmartfuelUtils.js";

const fuel_transactions = {
	// TODO | URL for admin view
	urlGET: `api/fuel/transactions/list`,
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision", "fuelRevision"],
	segmentKey: "SMARTFUEL_TRANSACTIONS",
	content: {
		table: {
			variant: "skinny",
			orderBy,
		},
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		export: {
			fileName: "SmartFuel Transactions",
		},
		order: {
			defaut: {
				key: columns.TRANSACTION_DATE.key,
				dir: "desc",
			},
		},
		filters: {
			search: true,
			countVisible: 3,
			items: [
				filters.TRANSACTION_DATE,
				filters.CARRIER,
				filters.ASSIGMENT_STATUS,
				{
					key: "carriers_type",
					type: "select",
					label: "Internal Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internalColumns",
					type: "checkbox",
					label: "Show Internal Fields",
				},
				groups.PRODUCT_DETAILS,
				groups.PAYROLL_DETAILS,
				groups.PRICE_DETAILS,
			],
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			dialogBuilder: (item) => ({
				viewId: "FUEL_TRANSACTION_VIEW",
				mode: "VIEW",
				dataIds: {
					transactionId: item._id,
					carrierId: item.carrier,
				},
			}),
		},
		items: [
			{ key: "_id", type: "id", label: "Id", group: "internalColumns" },
			{ type: "separator", group: "internalColumns" },
			columns.TRANSACTION_DATE,
			{ type: "separator" },
			columns.TRANSACTION_NUMBER,
			columns.CARRIER,
			columns.USER,
			columns.INVESTOR,
			columns.TRUCK,
			{ type: "separator" },
			columns.LOCATION,
			columns.PRODUCT_TYPE,
			columns.PAYROLL,
			columns.GALLONS,
			columns.PPG,
			columns.PPG_DISCOUNTED,
			columns.ACTUAL_COST,
			columns.DISCOUNT_TOTAL,
			{ type: "separator", table: { width: 8, sticky: true } },
			columns.TOTAL_AMOUNT,
		],
	},
};

export default fuel_transactions;
