import { useState, useEffect } from "react";
import { getTrip } from "app/services/tripsServices";
import { getTripPublic } from "app/services/publicServices";
import { getInvoiceForTrip } from "app/services/tripInvoicingServices";
import { onLoadedPlan } from "helpers/trimbleMapsHelper";
import { useSelector } from "react-redux";

// Const
const _ENABLE_ROLE_INVOICE = ["CARRIER_OWNER", "CARRIER_MANAGER", "CARRIER_DISPATCHER"];

const useTripInformation = (dataIds, onLoading, nativeMobile) => {
	const user = useSelector(({ auth }) => auth.user);
	const dataRevision = useSelector(({ tools }) => tools.revision["tripsRevision"]);
	const profileRevision = useSelector(({ tools }) => tools.revision?.profileRevision);

	const tripId = dataIds.tripId;
	const carrierId = dataIds.carrierId;
	const enabledInvoice = _ENABLE_ROLE_INVOICE.includes(user.role) || user.roleType === "INTERNAL";

	const [trip, setTrip] = useState(null);
	const [hidePrice, setHidePrice] = useState(Boolean(dataIds?.hidePrice));
	const [tripPlan, setTripPlan] = useState(null);
	const [tripInvoice, setTripInvoice] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		let unmounted;
		if (!tripId) {
			setError("Missing trip ID");
			return;
		}
		if (!carrierId) {
			setError("Missing carrier ID");
			return;
		}

		if (!dataIds.public) {
			(async () => {
				try {
					if (!trip) onLoading?.(true);
					const [tripData, invoiceData] = await Promise.all([
						getTrip(tripId, carrierId),
						enabledInvoice ? getInvoiceForTrip(carrierId, tripId) : null,
					]);
					if (unmounted) return;

					tripData.hidePrice = hidePrice;
					setTrip(tripData);
					setTripInvoice(invoiceData);
					onLoading?.(false);
					if (tripData?.booked_trip_plan?.map_config) setTripPlan(onLoadedPlan(tripData.booked_trip_plan.map_config));
				} catch (e) {
					if (unmounted) return;
					setError(e.errors?.[0]?.message ?? "Opps, something went wrong, please reload the page...");
					onLoading?.(false);
				}
			})();
		} else if (dataIds.public && nativeMobile) {
			(async () => {
				try {
					if (!trip) onLoading?.(true);
					const tripData = await getTripPublic(tripId, carrierId);
					if (unmounted) return;

					tripData.hidePrice = hidePrice;
					setTrip(tripData);
					onLoading?.(false);
				} catch (e) {
					if (unmounted) return;
					setError(e.errors?.[0]?.message ?? "Opps, something went wrong, please reload the page...");
					onLoading?.(false);
				}
			})();
		} else {
			setTrip(dataIds);
			setHidePrice(!dataIds?.showRate);
		}

		return () => {
			unmounted = true;
		};
		// eslint-disable-next-line
	}, [tripId, carrierId, dataIds, dataRevision, profileRevision]);

	return [trip, tripPlan, tripInvoice, hidePrice, error];
};

export { useTripInformation };
