import { MenuItem, Select, Typography } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

function AccountCarrierSelect({ onChange, classes = {} }) {
	const user = useSelector(({ auth }) => auth.user);
	const linkedSubAccounts = user?.linkedSubAccounts?.filter(
		(item) => item.carrier.accountStatus !== "DEACTIVATED" && !item.user.deactivated
	);
	const activeSubaccounts = linkedSubAccounts?.map((account) => account.carrier);

	const gk = user.gatekeepers;
	const currentAccount = useMemo(() => {
		if (user.role === "CARRIER_DISPATCHER" && (!gk.trucks_full_access || gk.trucks_access_ids.length === 0)) {
			return null;
		}
		return localStorage.getItem("carrier_company_name");
	}, [user, gk]);

	const lastSelected = localStorage.getItem("lastSubcarrierSelected");
	const [val, setVal] = useState(lastSelected ? lastSelected : currentAccount ? "__CURRENT__" : "__ALL__");

	if (!linkedSubAccounts?.length) return null;

	return (
		<Select
			classes={classes}
			style={{ minWidth: "200px", height: "34px" }}
			variant="outlined"
			value={val}
			onChange={(e) => {
				setVal(e.target.value);
				onChange?.(e.target.value === "__CURRENT__" ? null : e.target.value);
				if (e.target.value !== "__CURRENT__") {
					localStorage.setItem("lastSubcarrierSelected", e.target.value);
				} else {
					localStorage.removeItem("lastSubcarrierSelected");
				}
			}}
		>
			<MenuItem value={"__ALL__"}>
				<Typography display="inline">All Accounts</Typography>
			</MenuItem>
			{currentAccount && (
				<MenuItem value={"__CURRENT__"}>
					<Typography display="inline">{currentAccount}</Typography>
				</MenuItem>
			)}
			{activeSubaccounts?.map((carrier) => (
				<MenuItem key={carrier._id} value={carrier._id}>
					<Typography display="inline">{carrier.carrier_company_name}</Typography>
				</MenuItem>
			))}
		</Select>
	);
}

export default AccountCarrierSelect;
