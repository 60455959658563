import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createTripsFromImport, discardPendingImport } from "app/services/tripsServices";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { showSuccessSnackbar, showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { SmarthopConfirmDialog } from "@smarthop/form";
import TripImportSummary from "./TripImportSummary";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

const ImportWarnings = ({ summary }) => {
	const hasDuplicates = !!summary.duplicate;
	const hasValid = !!summary.valid;
	return (
		(!hasValid || hasDuplicates) && (
			<Box
				bgcolor="rgba(255,0,0,.05)"
				mt="10px"
				p="5px"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				{hasDuplicates && (
					<Typography className="text-red-500">Rows with duplicate load id will be ignored upon upload</Typography>
				)}
				{!hasValid && <Typography className="text-red-500">No valid rows are available to upload</Typography>}
			</Box>
		)
	);
};

const dataTransformConfig = {
	items: [
		{
			type: "group",
			content: {
				items: [
					{
						type: "menu",
						icon: "tune",
						button: { color: "primary" },
						content: {
							items: [
								{
									type: "section",
									label: "Filters",
									translate: "",
									section: { classes: { label: "text-base md:text-lg md:-mb-4 mt-10 md:mt-0" } },
								},
								{
									key: "recordFilter",
									type: "select",
									label: "Filter records by",
									defaultValue: "__NOT_SELECTED__",
									field: {
										noErrorMessage: true,
										classes: {},
										variant: undefined,
									},
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
											selected: true,
										},
										{
											value: "valid",
											label: "Valid",
										},
										{
											value: "error",
											label: "Error",
										},
										{
											value: "duplicate",
											label: "Duplicate",
										},
									],
								},
							],
						},
					},
				],
			},
		},
	],
};

const DataTranformationPanel = ({ setRecordFilter, setPaginationData }) => {
	const handleChange = (props) => {
		const { recordFilter } = props;
		if (recordFilter === "__NOT_SELECTED__") {
			setRecordFilter(null);
		} else {
			setRecordFilter(recordFilter);
		}
		setPaginationData((oldPaginationData) => ({ ...oldPaginationData, offset: 0 }));
	};
	return (
		<div className="inline-flex transform scale-75">
			<SmarthopFormView content={dataTransformConfig} onChangeCommitted={handleChange} trackChangedFields={["ALL"]} />
		</div>
	);
};

const TripImportConfirmHeader = ({
	summary,
	carrierId,
	setUploading,
	uploading,
	ingestionStatus,
	processStatus,
	getTripsFromRedis,
	setRecordFilter,
	setPaginationData,
}) => {
	const [discardConfirmDialog, setDiscardConfirmDialog] = useState(false);
	const [uploadConfirmDialog, setUploadConfirmDialog] = useState(false);

	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const { valid, uploaded } = summary;

	const uploadMessage = `You are about to create ${valid} trip(s)`;
	const discardMessage = "You are about to discard all your changes. Do you want to continue?";
	const doneMessage = `You have successfully uploaded ${uploaded} trips.`;

	const handleDiscard = async () => {
		setDiscardConfirmDialog(false);
		await discardPendingImport(carrierId);
		dispatch(closeFormDialog());
	};

	const handleUpload = async () => {
		let trips;
		try {
			setUploading(true);
			setUploadConfirmDialog(false);
			trips = await createTripsFromImport(carrierId);
		} catch (err) {
		} finally {
			trips
				? showSuccessSnackbar(snackbar, `${trips.length} trip(s) were successfully created`, { duration: 3000 })
				: showErrorSnackbar(snackbar, { message: `No trips were created` }, { duration: 3000 });
			await getTripsFromRedis(carrierId);
			setUploading(false);
		}
	};

	return (
		<>
			{summary && (
				<Box my={2} padding={2} border={1} borderColor={"black"} borderRadius={5}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<TripImportSummary summary={summary} />
						{!uploading ? (
							<Box>
								<DataTranformationPanel setRecordFilter={setRecordFilter} setPaginationData={setPaginationData} />
								<Button
									className="mr-12"
									variant={"contained"}
									onClick={() => {
										setDiscardConfirmDialog(true);
									}}
								>
									Finish
								</Button>
								<Button
									variant={"contained"}
									color={"secondary"}
									disabled={!summary?.valid}
									onClick={() => {
										setUploadConfirmDialog(true);
									}}
								>
									Upload
								</Button>
							</Box>
						) : (
							<Button variant={"contained"} color={"primary"}>
								{processStatus} &nbsp;
								<CircularProgress size="2rem" color={"inherit"} />
							</Button>
						)}
					</Box>
					{summary && <ImportWarnings summary={summary} />}
				</Box>
			)}
			<SmarthopConfirmDialog
				key={ingestionStatus ? `done-${uploaded}` : "discard"}
				open={discardConfirmDialog}
				message={ingestionStatus ? doneMessage : discardMessage}
				handleClose={() => {
					setDiscardConfirmDialog(false);
				}}
				handleAccept={handleDiscard}
				closeMsg={"Cancel"}
				acceptMsg={ingestionStatus ? "Accept" : "Discard"}
			/>
			<SmarthopConfirmDialog
				key={`confirm_${valid}`}
				open={uploadConfirmDialog}
				message={uploadMessage}
				handleClose={() => {
					setUploadConfirmDialog(false);
				}}
				handleAccept={handleUpload}
				closeMsg={"Cancel"}
				acceptMsg={"Upload"}
			/>
		</>
	);
};

export default TripImportConfirmHeader;
