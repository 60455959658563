const userEmailAliasForm = {
	urlGET: "api/profile/users/:userId/alias/:aliasId",
	urlPOST: "api/profile/users/:userId/alias",
	urlDELETE: "api/profile/users/:userId/alias/:aliasId",
	urlPUT: "api/profile/users/:userId/alias/:aliasId/resend",
	idKey: "_id",
	triggerEvent: "emailAliasRevision",
	size: "max-w-lg",
	header: {
		create: { label: "Add Email" },
		delete: { label: "Delete Email" },
		edit: { label: "Resend Code" },
	},
	content: {
		create: {
			items: [
				{
					key: "email",
					type: "email",
					label: "Email",
					required: true,
				},
				{
					type: "action",
					action: "SUBMIT",
					label: "Save",
				},
			],
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete '${item.email}' from the list of email alias?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
							},
						],
					},
				},
			],
		},
		edit: {
			items: [
				{
					type: "message",
					builder: (item) => `Do you want to resend the confirmation email to '${item.email}'?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "SUBMIT",
								label: "Resend Code",
								button: { alwaysEnabled: true },
							},
						],
					},
				},
			],
		},
	},
};

export default userEmailAliasForm;
