import CostStructureResults from "./CostStructureResults";
import VisualizeEquations from "./VisualizeEquations";
import ExpenseRevenueChart from "./ExpenseRevenueChart";
import { Button, CircularProgress, FormControlLabel, Icon, Switch, Tooltip, Typography } from "@material-ui/core";
import { useState, useMemo } from "react";
import { costSectionsFields, getProfitDataToUse } from "./CostStructureCalculatorSettings";
import { useDispatch, useSelector } from "react-redux";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import ProfitWarnings from "./ProfitWarnings";

const CostsBreakdownPage = ({ data }) => {
	const dispatch = useDispatch();
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);
	const [includeFixed, setIncludeFixed] = useState(true);

	const formattedProfitData = useMemo(() => getProfitDataToUse(data.profitData.data), [data?.profitData?.data]);

	const onEditCostStructure = () => {
		dispatch(
			openFormDialog({
				viewId: "COST_STRUCTURE",
				dataIds: { history: data?.snapshots?.cost_structure?.data ?? {}, tripId: data?._id },
			})
		);
	};

	if (!data) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	const expensesSwitcher = (
		<FormControlLabel
			className="-mt-4 mr-0"
			control={<Switch size="small" checked={includeFixed} onChange={(e) => setIncludeFixed(e.target.checked)} />}
			label={
				<Tooltip
					classes={{ tooltip: "text-12 font-normal" }}
					title={costSectionsFields["Allocated Monthly Expenses"].description}
				>
					<div className="flex justify-between flex-row items-center ml-4 pt-1">
						<Typography className="text-12 mr-4">Include Monthly Expenses</Typography>
						<Icon className="text-16">info</Icon>
					</div>
				</Tooltip>
			}
		/>
	);

	return (
		<>
			{data.profitData && (
				<div className="flex flex-col">
					<div className="flex flex-col md:flex-row w-full">
						<div className="flex flex-col w-full ">
							<CostStructureResults
								titleControls={[expensesSwitcher]}
								data={formattedProfitData}
								withFixed={includeFixed}
							/>
							<ProfitWarnings data={formattedProfitData} />
							<div className="ml-8 mt-8">
								{data?.snapshots?.cost_structure?.data?._id && !nativeMobile && (
									<Button variant="contained" onClick={onEditCostStructure}>
										Edit Trip Cost Structure
									</Button>
								)}
							</div>
						</div>
						<div className="flex flex-col w-full ml-10 ">
							<ExpenseRevenueChart
								revenue={data.profitData.revenue}
								data={formattedProfitData}
								height={400}
								withFixed={includeFixed}
							/>
						</div>
					</div>
				</div>
			)}
			{data.profitData && (
				<div className="flex flex-col w-full">
					<VisualizeEquations data={formattedProfitData} withFixed={includeFixed} />
				</div>
			)}
		</>
	);
};

export default CostsBreakdownPage;
