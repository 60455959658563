import clsx from "clsx";
import _ from "@lodash";
import { motion } from "framer-motion";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Waypoint } from "react-waypoint";

import Divider from "@material-ui/core/Divider";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

import ProfileCellView from "./views/ProfileCellView";
import AccountCellView from "./views/AccountCellView";
import CarrierCellView from "./views/CarrierCellView";
import TruckCellView from "./views/TruckCellView";
import ChatCellView from "./views/ChatCellView";
import SearchesCellView from "./views/SearchesCellView";
import DriverCellView from "./views/DriverCellView";
import PlanCellView from "./views/PlanCellView";
import UserCellView from "./views/UserCellView";
import TripsCellView from "./views/TripsCellView";
import ReasonsCellView from "./views/ReasonsCellView";
import TrackingCellView from "./views/TrackingCellView";
import InvoiceCellView from "./views/InvoiceCellView";
import PodCellView from "./views/PodCellView";
import StrategyCellView from "./views/StrategyCellView";
import TicketCellView from "./views/TicketCellView";
import { formatCurrency } from "app/main/utils/tableUtils";
import CashAdvanceCellView from "./views/CashAdvanceCellView";
import TripStatusPayrollCellView from "./views/TripStatusPayrollCellView";
import MetricCellView from "./views/MetricCellView";
import PeriodCellView from "./views/PeriodCellView";
import { CellTypes } from "./SmarthopCellTypes";

import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { openMenuPanel } from "app/store/tools/menuSlice";
import { isVisibleForUser } from "app/main/utils/rolesUtils";
import { formatDate } from "app/main/utils/dateUtils";

const useStyles = makeStyles((theme) => {
	return {
		"@keyframes flicker": {
			from: {
				opacity: 1,
			},
			to: {
				opacity: 0.2,
			},
		},
		tile: {
			background: theme.palette.background.paper,
		},
		content: {
			boxShadow: "none",
		},
		flicker: {
			animationName: "$flicker",
			animationDuration: "600ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		withAnimation: ({ disabled }) => ({
			animationPlayState: disabled ? "paused" : "running",
		}),
	};
});

const tileVariants = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 },
};

const ENABLE_LOAD_MORE_ANIMATION = false;
const ENABLE_INIT_ANIMATION = true;

function SmarthopGridViewItem(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [moreMenuEl, setMoreMenuEl] = useState(null);
	const { flicker, withAnimation } = useStyles({ disabled: false });
	const user = useSelector(({ auth }) => auth.user);
	const totalLength = props.totalLength;
	let rowIndex = props.rowIndex;
	let itemId = props.itemId;
	let item = props.item;
	let content = props.content;
	let mobile = props.mobile;
	let variant = props.variant;
	let theme = props.theme;
	let stub = props.stub;
	let loadMoreEnabled = props.loadMoreEnabled;

	let active = !content.isActive || content.isActive(item);
	let layout = content?.grid?.layout;

	let onMenuItemClick = props.onMenuItemClick;
	let onEnter = props.onEnter;

	let highlighted = props.highlighted;

	const handleMenuItemClick = (itemId, item, config) => {
		if (onMenuItemClick) onMenuItemClick(itemId, item, config);
		setMoreMenuEl(null);
	};
	const handleMoreMenuClick = (event) => {
		setMoreMenuEl(event.target);
	};
	const handleMoreMenuClose = (event) => {
		setMoreMenuEl(null);
	};

	return useMemo(() => {
		let title = [];
		let subtitle = [];

		let menuButtons = [];
		let menuActions = [];
		let menuIcons = [];
		let contentItems = [];
		let rowItems = [];

		let grid = mobile && content?.grid?.mobile ? content?.grid?.mobile : content?.grid;
		let layout = grid?.layout;

		// configure tile, value provided in "config?.classes?.tile" can determine
		// number of columns in the grid: for example md:w-1/2 or md:w-1/4, default 1
		const tileClasses = {
			root: "w-full " + (" md:w-1/" + grid?.tilesPerRow ?? 1),
			tile: clsx(classes.tile, "rounded-12 border-1 border-grey-300"),
		};

		if (content.click) {
			if (content.click?.grid?.variant === "menu") {
				menuActions.push(
					<MenuItem
						key={content.click.key ?? "view"}
						className="px-28 py-14"
						onClick={(event) => handleMenuItemClick(itemId, item, content.click)}
					>
						{content.click.label ?? "View"}
					</MenuItem>
				);
			} else {
				menuButtons.push(
					<Button
						key={content.click.key ?? "view"}
						className={
							(content.click?.button?.classes?.root ?? "") +
							(mobile ? " mt-10 py-6 flex-1 mx-6 " : " ml-14 mt-1 px-48 ")
						}
						variant="contained"
						color={content.click?.button?.color ?? "secondary"}
						aria-label="Follow"
						onClick={(event) => handleMenuItemClick(itemId, item, content.click)}
					>
						{content.click.label ?? "View"}
					</Button>
				);
			}
		}

		const renderView = (config) => {
			let hidden = !!config?.hidden;

			if (config.internal || hidden) {
				return null;
			}

			let createView = (viewValue, originalValue, viewConfig, viewKey) => {
				let titleStyle = theme === "light" ? " text-grey-600 font-light " : " font-semibold ";
				let contentStyle = theme === "light" ? " font-normal " : " text-grey-700 ";

				let titleFont = variant === "skinny" ? " text-12 ml:text-13 " : " text-12 ml:text-13 ";
				let contentFont =
					theme === "light"
						? variant === "skinny"
							? " text-11 ml:text-13 "
							: " text-13 ml:text-16 "
						: variant === "skinny"
						? " text-12 ml:text-13 "
						: " text-12 ml:text-13 ";

				let cellContent;
				let cellViewParams = { item, view: originalValue, variant, config: config.viewConfig, mobile: true };
				if (config.type === "component") {
					cellContent = viewValue;
				} else if (config.type === "profile__view") {
					cellContent = <ProfileCellView {...cellViewParams} />;
				} else if (config.type === "account__view") {
					cellContent = <AccountCellView {...cellViewParams} />;
				} else if (config.type === "carrier__view") {
					cellContent = <CarrierCellView {...cellViewParams} />;
				} else if (config.type === "truck__view") {
					cellContent = <TruckCellView {...cellViewParams} />;
				} else if (config.type === "chat__view") {
					cellContent = <ChatCellView {...cellViewParams} />;
				} else if (config.type === "chat__view") {
					cellContent = <SearchesCellView {...cellViewParams} />;
				} else if (config.type === "driver__view") {
					cellContent = <DriverCellView {...cellViewParams} />;
				} else if (config.type === "user__view") {
					cellContent = <UserCellView {...cellViewParams} />;
				} else if (config.type === "plan__view") {
					cellContent = <PlanCellView {...cellViewParams} />;
				} else if (config.type === "trips__view") {
					cellContent = <TripsCellView {...cellViewParams} />;
				} else if (config.type === "reasons__view") {
					cellContent = <ReasonsCellView {...cellViewParams} />;
				} else if (config.type === "traking__view") {
					cellContent = <TrackingCellView {...cellViewParams} />;
				} else if (config.type === "invoice__view") {
					cellContent = <InvoiceCellView {...cellViewParams} />;
				} else if (config.type === "pod__view") {
					cellContent = <PodCellView {...cellViewParams} />;
				} else if (config.type === "strategy__view") {
					cellContent = <StrategyCellView {...cellViewParams} />;
				} else if (config.type === "support__view") {
					cellContent = <TicketCellView {...cellViewParams} />;
				} else if (config.type === "metric__view") {
					cellContent = <MetricCellView {...cellViewParams} />;
				} else if (config.type === "period__view") {
					cellContent = <PeriodCellView {...cellViewParams} />;
				} else if (config.type === "currency") {
					cellContent = (
						<Typography
							component={"span"}
							color={viewValue || theme === "light" ? "inherit" : "textSecondary"}
							className={clsx(contentStyle, contentFont, " break-all ml:break-words tracking-wide ")}
						>
							{formatCurrency(originalValue, config?.valueConfig?.decimals, config?.valueConfig?.locale)}
						</Typography>
					);
				} else if (config.type === "cash_advance__view") {
					cellContent = <CashAdvanceCellView {...cellViewParams} />;
				} else if (config.type === "trip_status_payroll__view") {
					cellContent = <TripStatusPayrollCellView {...cellViewParams} />;
				} else {
					cellContent = (
						<Typography
							component={"span"}
							color={viewValue || theme === "light" ? "inherit" : "textSecondary"}
							className={clsx(contentStyle, contentFont, " break-all ml:break-words tracking-wide ")}
						>
							{viewValue ? viewValue : theme === "light" ? "-" : "No Information"}
						</Typography>
					);
				}

				return (
					<div
						key={viewKey}
						className={"w-full" + (variant === "skinny" ? " px-2 py-2 ml:py-0 ml:px-4 " : " px-6 ml:px-8 ")}
					>
						<Typography className={clsx(titleStyle, titleFont, " break-all ml:break-words tracking-wide")}>
							{Array.isArray(viewConfig.label) && viewConfig.label.length > 1
								? viewConfig.label.join(" ")
								: viewConfig.label}
						</Typography>
						{cellContent}
					</div>
				);
			};

			if (config.type === "menu") {
				let prevDivider;
				config.items?.forEach((menuItemConfig, menuIndx) => {
					let menuItemKey = rowIndex + "_menu_" + menuIndx;
					if (!isVisibleForUser(menuItemConfig.auth, user)) {
						return;
					}
					if (menuItemConfig.isVisible && !menuItemConfig.isVisible(item)) {
						return;
					}
					if (prevDivider) {
						prevDivider = false;
						menuActions.push(<Divider key={menuItemKey + "_divider"} />);
					}

					if (menuItemConfig.variant === "button") {
						let builderButton = menuItemConfig?.button?.builder;
						menuButtons.push(
							<Button
								key={menuItemKey}
								className={
									(menuItemConfig?.button?.classes?.root ?? "") +
									(mobile
										? " mt-10 py-6 flex-1 mx-6 "
										: variant === "skinny"
										? " ml-10 mt-1 px-20 py-4 text-13 "
										: " ml-14 mt-1 px-48 ")
								}
								variant="contained"
								color={menuItemConfig?.button?.color ?? "secondary"}
								aria-label="Follow"
								onClick={(event) => handleMenuItemClick(itemId, item, menuItemConfig)}
							>
								{builderButton ? builderButton(item) : menuItemConfig.label}
							</Button>
						);
					} else if (menuItemConfig.variant === "icon") {
						menuButtons.push(
							<Tooltip title={menuItemConfig?.label}>
								<Button
									key={menuItemKey}
									className={
										(menuItemConfig?.button?.classes?.root ?? "") +
										(mobile
											? " mt-10 py-6 flex-1 mx-6 "
											: variant === "skinny"
											? " ml-10 mt-1 px-16 py-4 text-13 "
											: " ml-14 mt-1 px-20 ")
									}
									variant="contained"
									color={menuItemConfig?.button?.color ?? "secondary"}
									aria-label="Follow"
									onClick={(event) => handleMenuItemClick(itemId, item, menuItemConfig)}
								>
									{mobile && menuItemConfig?.label}
									{!mobile && menuItemConfig?.button?.icon && (
										<Icon className="text-20">{menuItemConfig?.button?.icon}</Icon>
									)}
								</Button>
							</Tooltip>
						);
					} else if (menuItemConfig.variant === "iconButton") {
						menuIcons.push(
							<IconButton
								key={menuItemKey}
								className={menuItemConfig?.button?.classes ?? ""}
								variant="contained"
								color="inherit"
								aria-label="Follow"
								onClick={(event) => handleMenuItemClick(itemId, item, menuItemConfig)}
							>
								<Icon className="text-20">{menuItemConfig?.button?.icon}</Icon>
							</IconButton>
						);
					} else if (menuItemConfig.variant === "divider") {
						prevDivider = true;
					} else {
						menuActions.push(
							<MenuItem
								key={menuItemKey}
								className="px-28 py-14"
								onClick={(event) => handleMenuItemClick(itemId, item, menuItemConfig)}
							>
								{menuItemConfig.label}
							</MenuItem>
						);
					}
				});
			} else {
				let originalValue;
				if (config?.builder) {
					originalValue = config?.builder(item, dispatch, snackbar);
				} else {
					let keys = config.key?.split(".");
					keys?.forEach((key) => {
						originalValue = originalValue ? originalValue[key] : item[key];
					});
				}

				let viewValue = originalValue;

				if (Object.keys(CellTypes).includes(config.type)) {
					viewValue = CellTypes[config.type]({ item, cellValue: viewValue, variant });
				} else {
					if (Array.isArray(viewValue)) {
						viewValue = viewValue.join(", ");
					}
					if (_.isObject(viewValue)) {
						if (viewValue.label) {
							viewValue = viewValue.label;
						} else if (viewValue.value) {
							viewValue = viewValue.value;
						} else if (!viewValue?.props) {
							viewValue = JSON.stringify(viewValue);
						}
					}

					if (config.type === "date" && viewValue) {
						viewValue = viewValue ? formatDate(viewValue) : "-";
					} else if (config.type === "bool") {
						viewValue = viewValue === true || viewValue === 1 ? "Yes" : "No";
					}

					if (config.grid?.valuePefix) {
						viewValue = config.grid?.valuePefix + viewValue;
					}
					if (config.grid?.valuePostfix) {
						viewValue = viewValue + config.grid?.valuePostfix;
					}
				}

				if (config.click) {
					menuButtons.push(
						<Button
							key={config.key}
							className={mobile ? " mt-10 py-6 flex-1 mx-6 " : " ml-14 mt-1 px-48 "}
							variant="contained"
							color={config.click?.button?.color ?? "secondary"}
							aria-label="Follow"
							onClick={(event) => handleMenuItemClick(itemId, item, config.click)}
						>
							{config.click.label}
						</Button>
					);
				}

				if (config.grid?.headerTitle) {
					title.push(
						<Typography
							key={title.length + "_title"}
							component={"span"}
							variant="subtitle1"
							color="inherit"
							className={
								"pl-12 font-medium break-all ml:break-words" +
								(variant === "skinny" ? " text-13 ml:text-14 " : " text-13 ml:text-15 ")
							}
						>
							{viewValue}
						</Typography>
					);
					return;
				}
				if (config.grid?.headerSubtitle) {
					subtitle.push(
						<Typography
							key={subtitle.length + "_subtitle"}
							component={"span"}
							variant="caption"
							color="inherit"
							className={
								"pl-12 font-medium break-all ml:break-words" +
								(variant === "skinny" ? " text-11 ml:text-12 -mt-2 " : " text-11 ml:text-12 ")
							}
						>
							{viewValue}
						</Typography>
					);
					return;
				}

				if (config?.click?.mobile?.replaceField && mobile) return null;
				let view = createView(stub ? "-" : viewValue, originalValue, config, "content_" + (config.key ?? config.label));
				return view;
			}
		};

		let cardView;
		if (content?.grid?.builder) {
			const component = content.grid.builder(itemId, item, { highlighted, mobile, stub, content });
			cardView = component ? (
				<div
					className={"flex w-full " + (content.click ? " cursor-pointer" : "")}
					onClick={content.click && (() => handleMenuItemClick(itemId, item, content.click))}
				>
					{component}
				</div>
			) : (
				<div className="flex py-92 w-full border-1 rounded-lg items-center justify-center">
					<Typography className="">Empty Content, ID {itemId ?? "Loading..."}</Typography>
				</div>
			);
		} else {
			let elementsSet = {};
			content?.items?.forEach((config) => {
				if (config.group) {
					return;
				}

				let view = renderView(config);
				if (!view) {
					return;
				}

				rowItems.push(view);
				elementsSet[config.key] = view;

				// check if last in the row, if not specified default value is 2
				let lastRowItem = !!config.grid?.longRow || rowItems.length === (grid?.itemsPerRow ?? 2);
				if (lastRowItem) {
					contentItems.push(rowItems);
					rowItems = [];
				}
			});

			if (rowItems.length > 0) {
				contentItems.push(rowItems);
				rowItems = [];
			}

			if (layout) {
				contentItems = [];
				layout.forEach((row) => {
					rowItems = [];
					row?.forEach((col, index) =>
						rowItems.push(
							elementsSet[col] ?? (
								<div
									key={"stub_" + index}
									className={"w-full" + (variant === "skinny" ? " px-2 ml:px-4 " : " px-6 ml:px-8 ")}
								/>
							)
						)
					);
					contentItems.push(rowItems);
				});
			}

			let rowsCount = contentItems.length;
			const conventRowViews = contentItems.map((contentRow, index) => (
				<div
					key={"row_" + index}
					className={
						"w-full flex flex-row " + (index === rowsCount - 1 ? "" : variant === "skinny" ? " pb-6 " : " pb-16 ")
					}
				>
					{contentRow}
				</div>
			));

			if (stub) {
				title = [
					<Typography
						key={title.length + "_title"}
						component={"span"}
						variant="subtitle1"
						color="inherit"
						className="pl-12 font-medium"
					>
						Loading...
					</Typography>,
				];
			}

			cardView = (
				<Paper
					component={motion.div}
					variants={tileVariants ?? "outlined"}
					className={clsx(classes.content, theme === "light" ? "divide-y" : "")}
				>
					<AppBar
						position="static"
						color={theme === "light" ? "transparent" : highlighted ? "secondary" : "primary"}
						className={variant === "skinny" ? " h-48 " : ""}
						elevation={0}
					>
						<Toolbar className={variant === "skinny" ? " px-6 ml:px-8 -mt-4 ml:-mt-8 " : " px-10 ml:px-12 "}>
							<div className={"flex flex-1 flex-col"}>
								<div className="flex items-center">{title}</div>
								<div className="flex">{subtitle}</div>
							</div>
							{menuButtons.length > 0 && !mobile && <>{menuButtons}</>}
							{menuIcons.length > 0 && <>{menuIcons}</>}
							{menuActions.length > 0 && (
								<>
									<IconButton
										color="inherit"
										aria-label="more"
										aria-owns={!!moreMenuEl ? "chats-more-menu" : null}
										aria-haspopup="true"
										onClick={(event) => handleMoreMenuClick(event)}
									>
										<Icon>more_vert</Icon>
									</IconButton>
									<Menu id="chats-more-menu" anchorEl={moreMenuEl} open={!!moreMenuEl} onClose={handleMoreMenuClose}>
										{menuActions}
									</Menu>
								</>
							)}
						</Toolbar>
					</AppBar>
					<CardContent className={variant === "skinny" ? " py-8 -mb-8 " : ""}>
						{conventRowViews}
						{menuButtons.length > 0 && mobile && (
							<div className={"flex mt-10 flex-1" + (menuButtons?.length > 2 ? " flex-col " : " flex-row ")}>
								{menuButtons}
							</div>
						)}
					</CardContent>
				</Paper>
			);
		}

		return (
			<GridListTile
				component={motion.div}
				initial={ENABLE_INIT_ANIMATION ? { opacity: 0, y: 20 } : {}}
				animate={
					ENABLE_INIT_ANIMATION
						? { opacity: stub || !active ? 0.6 : 1, y: 0, transition: { delay: 0.1 * (rowIndex < 10 ? rowIndex : 10) } }
						: {}
				}
				variants={tileVariants}
				classes={tileClasses}
				className={
					(variant === "skinny" ? " p-8 " : " p-8 ml:p-12 ") +
					(stub || !active ? " opacity-60 " : "") +
					(stub ? (ENABLE_LOAD_MORE_ANIMATION ? ` ${flicker} ${withAnimation} ` : "") : "")
				}
			>
				{cardView}
				{loadMoreEnabled && (
					<Waypoint
						onEnter={() => {
							if (onEnter && rowIndex >= 0 && totalLength === rowIndex + 1) {
								onEnter(rowIndex);
							}
						}}
					/>
				)}
			</GridListTile>
		);
		// eslint-disable-next-line
	}, [rowIndex, itemId, item, moreMenuEl, mobile, highlighted, layout]);
}

/**
 * SmarthopGridView component allows to render grid view.
 */
function SmarthopGridView(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const snackbar = useSnackbar();

	const classes = useStyles();
	const content = props.content;
	const contentIdKey = props.contentIdKey;
	const data = props.data;
	const dataIds = props.dataIds;
	const mobile = props.mobile;
	const hasMore = props.hasMore;
	const moreWhen = props.moreWhen;
	const onLoadMore = props.onLoadMore;

	const variant = content?.grid?.variant ?? "default";
	const theme = content?.grid?.theme ?? "default";
	const isHighLighted = content?.isHighlighted;

	const showAlert = content?.showAlert;

	function handleMenuItemClick(itemId, item, config) {
		if (config?.redirectURL || config?.redirectURLBuilder?.(item, dataIds)) {
			let url = config?.redirectURLBuilder ? config?.redirectURLBuilder(item, dataIds) : config?.redirectURL;
			if (config.newTab) {
				const win = window.open(url, "_blank");
				win.focus();
			} else {
				history.push(url);
			}
		} else if (config?.dialog || config?.dialogBuilder) {
			let dialog = config?.dialogBuilder
				? config?.dialogBuilder(item, dataIds)
				: {
						viewId: config?.dialog?.viewId,
						formId: config?.dialog?.formId,
						formInstance: config?.dialog?.formInstance,
						mode: config?.dialog?.mode,
						dataIds: { ...dataIds, id: itemId },
				  };

			dispatch(openLoadedFormDialog(dialog));
		} else if (config?.dispatchAction) {
			config?.dispatchAction(item, dataIds, dispatch, history, snackbar);
		} else if (config?.openMenuPanel) {
			let menu = config?.openMenuPanel(item, dataIds);
			dispatch(openMenuPanel(menu));
		}
	}

	const handleLoadMore = (index) => {
		if (index === data.items.length - 5 || index === data.items.length - 1) {
			if (onLoadMore) onLoadMore(data.items.length);
		}
	};

	if (!content || !contentIdKey) {
		return (
			<div className="flex-col h-full flex items-center justify-center">
				<Typography color="error">Items config is not provided</Typography>
			</div>
		);
	}

	let items = data?.items?.map((item, rowIndex) => {
		if (!item) {
			return (
				<Typography color="primary" className="m-10">
					Grid Item {rowIndex} Failed to Render
				</Typography>
			);
		}

		let itemId = item[contentIdKey] ?? rowIndex;

		let highlighted = isHighLighted ? isHighLighted(item, dataIds) : null;
		if (item.type === "separator") {
			return undefined;
		}

		return (
			<SmarthopGridViewItem
				key={itemId + "_id"}
				variant={variant}
				theme={theme}
				highlighted={highlighted}
				itemId={itemId}
				content={content}
				rowIndex={rowIndex}
				totalLength={data?.items?.length}
				item={item}
				classes={classes}
				mobile={mobile}
				loadMoreEnabled={moreWhen !== "on-last"}
				onMenuItemClick={handleMenuItemClick}
				onEnter={handleLoadMore}
			/>
		);
	});

	return (
		<div className={"flex w-full h-full overflow-hidden "}>
			<div className="flex flex-col flex-1">
				<div className="flex flex-col flex-1 p-4 ml-0 ml:ml-8 mt-0 ml:mt-10 mr-0 ml:mr-2">
					{showAlert?.active && (
						<Card
							className={clsx(
								"flex items-center justify-center relative w-full rounded-8 p-10 ml:p-20 min-h-40 ml:min-h-40 shadow text-white mb-10 " +
									showAlert?.color
							)}
							elevation={0}
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
						>
							<div className="flex-auto flex flex-col items-center justify-center cursor-pointer">
								<Typography className="font-normal tracking-wide text-12 sm:text-13 text-center">
									{showAlert?.message}
								</Typography>
							</div>
						</Card>
					)}
					<GridList className="overflow-hidden" spacing={20} cols={0}>
						{items}
						{moreWhen === "on-last" && (
							<Waypoint
								onEnter={() => {
									onLoadMore(data.items.length);
								}}
							/>
						)}
						{hasMore && (
							<SmarthopGridViewItem
								variant={variant}
								theme={theme}
								itemId={-1}
								rowIndex={-1}
								content={content}
								item={{}}
								classes={classes}
								mobile={mobile}
								stub={true}
							/>
						)}
					</GridList>
				</div>
			</div>
		</div>
	);
}

export default SmarthopGridView;
