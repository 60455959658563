export const EXPAND = {
	key: "expanded",
	type: "button",
	label: "Expand",
	config: {
		startIcon: "swap_horiz",
	},
	altState: {
		label: "Collapse",
		config: {
			startIcon: "compare_arrows",
		},
	},
};

export const FOLD = {
	key: "folded",
	type: "button",
	label: "Fold",
	config: {
		startIcon: "unfold_less",
	},
	altState: {
		label: "Unfold",
		config: {
			startIcon: "unfold_more",
		},
	},
};

export const EDGEWIDTH = {
	key: "edgeWidthEnabled",
	type: "checkbox",
	label: "Edge width",
	config: {
		icon: "timeline",
		checkedIcon: "timeline",
	},
};

export const TOOLTIP = {
	key: "tooltipEnabled",
	type: "checkbox",
	label: "Tooltip",
	config: {
		icon: "subject",
		checkedIcon: "playlist_add_check",
	},
};

export const FLEXPATHS = {
	key: "flexPathsEnabled",
	type: "checkbox",
	label: "Flex paths",
	config: {
		icon: "visibility_off",
		checkedIcon: "visibility",
	},
};

export const HIGHLIGHTFLEXPATHS = {
	key: "highlightFlexEnabled",
	type: "checkbox",
	label: "Flex highlight",
	dependingOn: "flexPathsEnabled",
	config: {
		icon: "highlight",
		checkedIcon: "highlight",
	},
};

export const STRATEGYSUMMARY = {
	key: "strategySummary",
	type: "button",
	label: "Summary",
	config: {
		startIcon: "info",
	},
};

export const CYCLE = {
	key: "cycle",
	type: "button",
	label: "Cycle",
	config: {
		startIcon: "cached",
	},
	altState: {
		label: "Destination",
		config: {
			startIcon: "location_on",
		},
	},
};

export const LATEST = {
	key: "latest",
	type: "button",
	label: "Latest",
	config: {
		endIcon: "last_page",
	},
};

export const LEGEND = {
	key: "legend",
	type: "button",
	label: "Legend",
	config: {
		startIcon: "legend_toggle",
	},
};
