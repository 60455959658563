import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

const capitalizeFirstLetter = (str) => str[0]?.toUpperCase() + str?.slice(1);

const summaryKeyToLabel = {
	rowNums: "Records",
	error: "Error",
	label: "New value",
	applyChange: "Apply",
};

const SummaryComponent = ({ summary, summaryKey, fieldKey, state, checkboxOnChange }) => {
	if (summaryKey === "rowNums") {
		return <Typography>{summary[summaryKey].length}</Typography>;
	}
	if (summaryKey === "applyChange") {
		return <Checkbox onChange={checkboxOnChange} name={fieldKey} checked={state[fieldKey]?.applyChange} />;
	}
	return (
		<Typography color={summaryKey === "error" ? "error" : "inherit"}>
			{capitalizeFirstLetter(summary[summaryKey])}
		</Typography>
	);
};

const TripImportMultipleUpdate = ({ duplicateSummary, handleSkip, handleApply, handleChange }) => {
	const summaryKeys = Object.keys(Object.values(duplicateSummary)[0]).filter((key) => key !== "value");

	return (
		<Box>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell> Field </TableCell>
							{summaryKeys.map((summaryKey) => (
								<TableCell key={`dup_header_${summaryKey}`}>{summaryKeyToLabel[summaryKey]}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(duplicateSummary).map(([fieldKey, summary], i) => (
							<TableRow>
								<TableCell key={`dup_body_${fieldKey}_${i}`}>{capitalizeFirstLetter(fieldKey)}</TableCell>
								{summaryKeys.map((summaryKey) => (
									<TableCell key={`dup_body_${summaryKey}_${i}`}>
										<SummaryComponent
											summary={summary}
											summaryKey={summaryKey}
											fieldKey={fieldKey}
											state={duplicateSummary}
											checkboxOnChange={handleChange}
										/>
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<DialogActions>
				<Box className="mx-6 mb-14">
					<Button
						className="tracking-wide min-w-120 mx-12 text-14 font-medium text-grey-800"
						variant="outlined"
						onClick={handleSkip}
					>
						Skip
					</Button>
					<Button
						className="tracking-wide min-w-120 mx-12 text-14 font-medium"
						variant="outlined"
						color="secondary"
						onClick={handleApply}
						disabled={
							!Object.values(Object.values(duplicateSummary).map((summary) => summary.applyChange)).some(Boolean)
						}
					>
						Apply
					</Button>
				</Box>
			</DialogActions>
		</Box>
	);
};

export default TripImportMultipleUpdate;
