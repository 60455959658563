import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { useDispatch, useSelector } from "react-redux";

import { SmarthopList, searchResultsDispatcher } from "@smarthop/list";
import SearchStatusPanel from "../SearchStatusPanel";
import { useEffect, useMemo, useState } from "react";
import SearchOrderByFilter from "../SearchOrderByfilter";
import { Checkbox, FormControlLabel } from "@material-ui/core";

function LoadSearchResultView(props) {
	const dispatch = useDispatch();

	const [orderBy, setOrderBy] = useState("RATE");
	const [planMatch, setPlanMatch] = useState(false);
	const [smallScreen] = useState(window.innerWidth < 1800 && window.innerWidth > 960);
	const [mobileScreen] = useState(window.innerWidth < 960);
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);
	const userId = useSelector(({ auth }) => auth.user._id);
	const hasSmartPayProgram = useSelector(({ auth }) => auth.user.hasSmartPayProgram);

	useEffect(() => {
		props?.setTitle?.("Search Results");
		props?.setSize?.("max-w-5xl");
		// eslint-disable-next-line
	}, []);

	const config = useMemo(() => {
		return searchResultsDispatcher(
			props.dataIds.searchId,
			false,
			false,
			smallScreen,
			mobileScreen,
			true,
			false,
			props.dataIds.carrierId,
			props.dataIds.userId,
			dispatch,
			hasSmartPayProgram,
			"api/search/requests/:searchId/details",
			true
		);
		// eslint-disable-next-line
	}, [props.dataIds, dispatch, smallScreen, mobileScreen]);

	return (
		<div className="flex flex-col p-8 md:p-2">
			<div className={"flex flex-row p-2"}>
				<div className={"flex flex-row w-full mb-4 min-h-40 items-center rounded-lg p-12 bg-orange-600"}>
					<Icon className={"text-24 mr-10 text-white"}>warning</Icon>
					<Typography className={"flex-1 text-13 text-white"}>
						This is a market snapshot at the moment of analysis, you can only see loads basic details. If you'd like to
						book a load and see loads available right now please start a new search
					</Typography>
				</div>
			</div>
			{(nativeMobile || mobileScreen) && (
				<SearchOrderByFilter
					onChange={(value) => {
						setOrderBy(value);
					}}
					value={orderBy}
					mobile={nativeMobile}
				/>
			)}
			<div className="w-100 mx-7 px-10">
				<FormControlLabel
					control={<Checkbox checked={planMatch} onChange={(event) => setPlanMatch(event.target.checked)} />}
					label="Show Only Plan Matches"
				/>
			</div>
			<div className="flex flex-row mb-10 ml:mb-0">
				<SearchStatusPanel viewOnly={true} smallScreen={smallScreen} searchId={props.dataIds.searchId} />
			</div>
			<SmarthopList
				key={`search_list_detail${orderBy}`}
				mode="table"
				config={config}
				dataIds={{
					searchId: props.dataIds.searchId,
					userId: userId,
					carrierId: props.dataIds.carrierId,
					truckId: props.dataIds.truckId,
				}}
				onLoading={() => {
					props?.onLoading?.(true);
				}}
				onListLoaded={() => {
					props?.onLoading?.(false);
				}}
				onListLoadError={() => {
					props?.onLoading?.(false);
				}}
				filters={{
					...(props.dataIds.filters ?? {}),
					system_show_plan_match_only: planMatch,
					system_hide_all_flexible: planMatch,
					...(nativeMobile
						? {
								order_by: orderBy,
						  }
						: {}),
				}}
			/>
		</div>
	);
}

export default LoadSearchResultView;
