import { authRoles } from "app/main/consts";
import i18next from "i18next";
import en from "./i18n/en";
import es from "./i18n/es";

import StrategyTab from "./StrategyTab";

i18next.addResourceBundle("en", "StrategyPage", en);
i18next.addResourceBundle("es", "StrategyPage", es);

const LoadSearchResultConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false,
				},
				header: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.all,
	routes: [
		{
			path: "/strategy",
			component: StrategyTab,
		},
	],
};

export default LoadSearchResultConfig;
