import { Typography } from "@material-ui/core";

const TRIP_DETAILS_ETA = {
	key: "eta",
	label: "New ETA",
	type: "picker",
	field: {
		classes: {},
		variant: undefined,
		noErrorMessage: true,
	},
	input: {
		classes: {},
	},
	picker: {
		disablePast: false,
		notClearable: false,
		type: "datetime",
		classes: {},
	},
};

const ETA_STOP = (nextStop) => {
	return {
		key: "eta_stop",
		type: "select",
		label: "Stop",
		required: true,
		field: {
			classes: {},
			variant: undefined,
			noErrorMessage: true,
		},
		defaultValue: "to DEL",
		options: [
			{
				value: "to PU",
				label: "To Pickup",
			},
			{
				value: "to STP",
				label: `To ${nextStop ? nextStop : "Next Stop"}`,
			},
			{
				value: "to DEL",
				label: "To Delivery",
			},
		],
	};
};

const DEBUG_TRIGGER_EVENT = {
	key: "trigger_event",
	type: "checkbox",
	label: "Add event from location (> 4h since the last event)",
};

const DEBUG_SOURCE = {
	key: "source",
	type: "select",
	label: "Source (Debug)",
	required: true,
	field: {
		classes: {},
		variant: undefined,
		noErrorMessage: true,
	},
	defaultValue: "TMS",
	options: [
		{
			value: "TMS",
			label: "TMS",
		},
		{
			value: "AXLE",
			label: "AXLE",
			showItems: [DEBUG_TRIGGER_EVENT],
		},
		{
			value: "GPS",
			label: "App",
			showItems: [DEBUG_TRIGGER_EVENT],
		},
	],
};

const DEBUG_EVENT_DATE = {
	key: "event_date",
	label: "Event Date (Debug)",
	type: "picker",
	field: {
		classes: {},
		variant: undefined,
		noErrorMessage: true,
	},
	input: {
		classes: {},
	},
	picker: {
		disablePast: false,
		notClearable: false,
		type: "datetime",
		classes: {},
	},
};

const TRIP_DETAILS_LAST_LOCATION = {
	key: "last_location",
	label: "Last Location",
	type: "autocomplete",
	required: true,
	autocomplete: {
		provider: "trimblemaps",
		params: { componentRestrictions: { country: "us" } },
		icon: "location",
	},
};

const ETA_DISCLAIMER = {
	key: "eta_disclaimer",
	type: "component",
	builder: () => (
		<Typography component={"div"} className="hidden md:block max-w-600 text-grey text-11 pl-4 mb-10">
			We will use the last ETA if a new is not provided.
		</Typography>
	),
};

const trackingEventForm = (debugMode, load) => {
	return {
		items: [
			TRIP_DETAILS_LAST_LOCATION,
			{
				type: "group",
				key: "eta_group",
				content: { items: [TRIP_DETAILS_ETA, ETA_STOP(load.next_stop)] },
			},
			ETA_DISCLAIMER,
			{ key: "comments", required: false, type: "text", label: "Comments", multiline: { rows: 4 } },
			...(debugMode ? [DEBUG_SOURCE, DEBUG_EVENT_DATE] : []),
			{ type: "action", action: "SUBMIT", label: "Add", button: { alwaysEnabled: true } },
		],
	};
};

export default trackingEventForm;
