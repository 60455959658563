// Constans
const carriers = {
	urlGET: "api/messenger/chats",
	idKey: "_id",
	listenEvent: "chatRevision",
	segmentKey: "ADMIN_CHAT_ITEMS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt", "truck", "lastSentUnix"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "lastSentUnix",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "truck",
					type: "autocomplete",
					label: "Truck",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/trucks",
						params: { options_format_show_carrier: true },
					},
				},
				{
					key: "active",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "true",
							label: "Active",
						},
						{
							value: "false",
							label: "Not Active",
						},
					],
				},
				{
					key: "reply_status",
					type: "select",
					label: "Reply Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REPLY_PENDING",
							label: "Pending",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal_ids",
					type: "checkbox",
					label: "Show Internal Ids",
				},
				{
					key: "reply",
					type: "checkbox",
					label: "Show Reply Information",
				},
				{
					key: "participants",
					type: "checkbox",
					label: "Show Participants Information",
				},
			],
		},
		isHighlighted: (item) => item?.unrepliedCount > 0,
		items: [
			{ key: "lastSentUnix", type: "unix", label: "Last Sent At" },
			{ key: "createdAt", type: "date", label: "Created", group: "internal_ids" },
			{ key: "_id", type: "id", label: "Chat ID", group: "internal_ids" },
			{ key: "truck", type: "id", label: "Truck ID", group: "internal_ids" },
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showTeam: true,
				},
			},
			{
				key: "last_user__view",
				type: "user__view",
				label: "Last Sender User",
				viewConfig: {
					showRole: true,
				},
			},
			{ key: "unrepliedCount", type: "number", label: "Unreplied" },
			{ key: "lastType", type: "text", label: "Last Message Type", maxLengthStart: 20 },
			{ key: "lastPreview", type: "text", label: "Last Message", maxLengthStart: 20 },
			{ key: "type", type: "text", label: "Chat Type" },
			{ key: "countInternal", type: "number", label: "Intenal Users", group: "participants" },
			{ key: "countOwners", type: "Owners", label: "External Users", group: "participants" },
			{ key: "countParticipants", type: "number", label: "Participants", group: "participants" },
			{ key: "active", type: "bool", label: "Active" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "open_chat",
						label: "Open Chat",
						variant: "button",
						redirectURLBuilder: (item) => "/chat/trucks?chat_id=" + item._id,
					},
					{
						key: "chat_details",
						label: "Chat Details",
						redirectURLBuilder: (item) => "/admin/chats/" + item._id + "/details",
					},
					{ key: "delete", label: "Delete", dialog: { formId: "ADMIN_CHAT_FORM", mode: "DELETE" } },
				],
			},
		],
	},
};

export default carriers;
