const markAsDefaultPaymentMethodForm = {
	urlGET: "billing/carriers/:carrierId/stripe-sources/:sourceId",
	urlPUT: "billing/carriers/:carrierId/stripe-sources/:sourceId/default",
	idKey: "_id",
	triggerEvent: "paymentRevision",
	listenEvent: "paymentRevision",
	header: { edit: { label: "Update Default Payment Method" } },
	messages: { edit: { success: "Default Payment Method updated" } },
	content: {
		edit: {
			items: [
				{
					type: "message",
					builder: (item) =>
						item?.last4
							? `Would you like to use as default the payment method ending in **** ${item?.last4}?`
							: `Would you like to use as default this payment method?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "SUBMIT", button: { alwaysEnabled: true }, label: "Accept" },
						],
					},
				},
			],
		},
	},
};
export default markAsDefaultPaymentMethodForm;
