import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Checkbox, Icon, FormControlLabel } from "@material-ui/core";

import { showSnackbar } from "app/main/utils/snackbarUtil";
import { processTierRestrictions } from "app/main/utils/rolesUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import FormSection from "app/main/tools/FormSection";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

// Services
import { getCarrierId, signInWithToken } from "app/services/LoginService";
import {
	cancelPlatformPlan,
	deletePlatformPlan,
	restorePlatformPlanSubscription,
	getPlatformPlanForCarrier,
	getPlatformPublicPlans,
} from "app/services/platformPlanServices";

import { deactivateDispatchingPlan, getDispatchingPlanForCarrier } from "app/services/dispatchingPlanServices";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

import { closeAllDialogs } from "app/store/tools/formDialogSlice";
import PlatformCancelationDialog from "app/main/billing/plans/PlatformCancelationDialog";
import { setUserData } from "app/store/auth/userSlice";

const PLATFORM_PLAN_STATUS = {
	ACTIVE: "active",
	INACTIVE: "inactive",
	CANCELLED: "cancelled",
};

const DISPATCHING_PLAN_STATUS = {
	ACTIVE: "active",
	INACTIVE: "inactive",
};

function PlansPage(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const planRevision = useSelector(({ tools }) => tools.revision["planRevision"]);
	const isAdmin = useSelector(({ auth }) => auth?.user?.rolesLabels?.includes?.("administrator"));
	const isFuelProgramEnabled = false; // TODO: FUEL_PROGRAM gk
	const carrierId = params.carrierId ?? props.params?.carrierId ?? props.carrierId ?? getCarrierId();

	const [platformPlanSubscriptionStatus, setPlatformPlanSubscriptionStatus] = useState(null);
	const [dispatchingPlanStatus, setDispatchingPlanStatus] = useState(null);
	const [openDialog, setOpenDialog] = useState(null);
	const [stripeCustomerId, setStripeCustomerId] = useState(null);
	const [hasSubscription, setHasSubscription] = useState(null);
	const [hasOverridePlan, setHasOverridePlan] = useState(null);
	const [publicPlans, setPublicPlans] = useState(null);
	const [currentTier, setCurrentTier] = useState(null);

	// Deletion fields
	const [resetTrial, setResetTrial] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				// Retrieve plans
				const [customerPlatform, dispatchingPlan, plans] = await Promise.all([
					getPlatformPlanForCarrier(carrierId),
					getDispatchingPlanForCarrier(carrierId),
					getPlatformPublicPlans(carrierId),
				]);

				// Saving plan info
				setStripeCustomerId(customerPlatform?.customer?.id);
				setHasSubscription(customerPlatform?.subscription);
				setDispatchingPlanStatus(
					dispatchingPlan?.active ? DISPATCHING_PLAN_STATUS.ACTIVE : DISPATCHING_PLAN_STATUS.INACTIVE
				);
				setHasOverridePlan(!!customerPlatform?.overrideTier);
				setCurrentTier(customerPlatform?.tier);
				setPublicPlans(plans);

				// Platform plan
				const cancelDate = customerPlatform?.subscription?.cancel_at_period_end;
				if (customerPlatform?._id) {
					setPlatformPlanSubscriptionStatus(cancelDate ? PLATFORM_PLAN_STATUS.CANCELLED : PLATFORM_PLAN_STATUS.ACTIVE);
				} else {
					setPlatformPlanSubscriptionStatus(PLATFORM_PLAN_STATUS.INACTIVE);
				}
			} catch (err) {
				console.error(err);
			}
		})();
	}, [carrierId, planRevision]);

	const handleCancelPlatformPlan = async (reasons, comments) => {
		try {
			await cancelPlatformPlan(carrierId, { reasons, comments });
			const userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData));
			dispatch(incrementDataRevision({ event: "planRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingMoveToSummaryRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			setOpenDialog(null);
			dispatch(closeAllDialogs());

			showSnackbar(snackbar, `Subscription cancelled`, "success");
		} catch (err) {
			console.error(err);
			showSnackbar(
				snackbar,
				err?.errors?.[0]?.message ?? "Could not successfully cancel the subscription. Contact support for more detail",
				"error"
			);
		}
	};

	const handleDeletePlatformPlan = async () => {
		try {
			await deletePlatformPlan(carrierId, resetTrial);
			dispatch(incrementDataRevision({ event: "planRevision" }));
			showSnackbar(snackbar, `Subscription deleted`, "success");
		} catch (err) {
			console.error(err);
			showSnackbar(
				snackbar,
				err?.errors?.[0]?.message ?? "Could not successfully delete the subscription. Contact support for more detail",
				"error"
			);
		}
	};

	const handleRestoreSubscription = async () => {
		try {
			await restorePlatformPlanSubscription(carrierId);
			dispatch(incrementDataRevision({ event: "planRevision" }));
			showSnackbar(snackbar, `Subscription restored`, "success");
		} catch (err) {
			console.error(err);
			showSnackbar(
				snackbar,
				err?.errors?.[0]?.message ?? "Could not successfully restore the subscription. Contact support for more detail",
				"error"
			);
		}
	};

	const handleDeactivateDispatchingPlan = async () => {
		try {
			await deactivateDispatchingPlan(carrierId);
			dispatch(incrementDataRevision({ event: "planRevision" }));
			showSnackbar(snackbar, `Plan deactivated`, "success");
		} catch (err) {
			console.error(err);
			showSnackbar(
				snackbar,
				err?.errors?.[0]?.message ??
					"Could not successfully deactivate the dispatching plan. Contact support for more detail",
				"error"
			);
		}
	};

	const generateButtons = (actions) => {
		let actionsArr = [];

		if (actions.includes("assign") || actions.includes("ASSIGN_PLATFORM_PLAN")) {
			actionsArr.push({ action: "create", mode: "CREATE", label: "Assign", auth: ["administrator"] });
		}
		if (actions.includes("deactivate")) {
			actionsArr.push({
				action: {
					onClick: () => handleDeactivateDispatchingPlan(),
				},
				label: "Deactivate",
				auth: ["administrator"],
			});
		}
		if (actions.includes("SELECT_PLATFORM_PLAN") || actions.includes("UPGRADE_PLATFORM_PLAN")) {
			actionsArr.push({
				action: {
					onClick: () => dispatch(openLoadedFormDialog({ viewId: "BILLING_PLAN_CHANGE", dataIds: { carrierId } })),
				},
				label: actions.includes("UPGRADE_PLATFORM_PLAN") ? "Upgrade" : "Select",
				auth: ["carrier", "carrier_manager", "administrator"],
			});
		}
		if (actions.includes("CANCEL_PLATFORM_PLAN")) {
			const plans = publicPlans?.items?.filter(
				(plan) =>
					!processTierRestrictions({ restrictions: [currentTier], tier: plan?.tier }) && plan?.tier !== "TIER_LIMITED"
			);

			if (plans?.length) {
				actionsArr.push({
					action: {
						onClick: () => dispatch(openLoadedFormDialog({ viewId: "OFFER_LOWER_PLAN", dataIds: { carrierId } })),
					},
					label: "Cancel",
					auth: ["carrier", "carrier_manager", "administrator"],
				});
			} else {
				actionsArr.push({
					action: {
						onClick: () =>
							setOpenDialog({
								action: "CANCEL_PLATFORM_PLAN",
								title: "Would you like to continue and cancel your current subscription?",
								closeMsg: "No",
								acceptMsg: "Continue",
								typedConfirmation: "cancel",
							}),
					},
					label: "Cancel",
					auth: ["carrier", "carrier_manager", "administrator"],
				});
			}
		}
		if (actions.includes("DELETE_PLATFROM_PLAN")) {
			actionsArr.push({
				action: {
					onClick: () =>
						setOpenDialog({
							action: "DELETE_PLATFROM_PLAN",
							title: "Make sure you understand what you are doing!",
							message: "That action would immediately unsubscribe customer from the plan with no refunds! ",
							closeMsg: "No",
							acceptMsg: "Continue",
							typedConfirmation: "delete",
						}),
				},
				label: "Delete (Dangerous)",
				auth: ["administrator"],
			});
		}

		if (actions.includes("CHANGE_TERMINATION_DATE")) {
			actionsArr.push({
				label: "Change Termination Date",
				auth: ["administrator"],
				action: {
					onClick: () =>
						dispatch(
							openLoadedFormDialog({
								formId: "TERMINATION_DATE_FORM",
								mode: "EDIT",
								dataIds: { carrierId },
							})
						),
				},
			});
		}

		if (actions.includes("ADD_SUBSCRIPTION_DISCOUNT")) {
			actionsArr.push({
				action: {
					onClick: () =>
						dispatch(
							openLoadedFormDialog({
								formId: "ADMIN_PLATFORM_SUBSCRIPTION_DISCOUNT_FORM",
								mode: "CREATE",
								dataIds: { carrierId },
							})
						),
				},
				label: "Add Discount",
				auth: ["administrator"],
			});
		}

		if (actions.includes("OVERRIDE_PLAN") && !hasOverridePlan && stripeCustomerId) {
			actionsArr.push({
				label: "Add Trial Plan",
				auth: ["administrator"],
				action: {
					onClick: () =>
						dispatch(
							openLoadedFormDialog({
								formId: "OVERRIDE_PLAN_FORM",
								mode: "CREATE",
								dataIds: { carrierId },
							})
						),
				},
			});
		}

		if (actions.includes("OVERRIDE_PLAN") && hasOverridePlan && stripeCustomerId) {
			actionsArr.push({
				label: "Edit Trial Plan",
				auth: ["administrator"],
				action: {
					onClick: () =>
						dispatch(
							openLoadedFormDialog({
								formId: "OVERRIDE_PLAN_FORM",
								mode: "EDIT",
								dataIds: { carrierId },
							})
						),
				},
			});
		}

		if (actions.includes("REMOVE_OVERRIDE_PLAN") && stripeCustomerId) {
			actionsArr.push({
				label: "Remove Trial Plan",
				auth: ["administrator"],
				action: {
					onClick: () =>
						dispatch(
							openLoadedFormDialog({
								formId: "OVERRIDE_PLAN_FORM",
								mode: "DELETE",
								dataIds: { carrierId },
							})
						),
				},
			});
		}

		if (actions.includes("VIEW_IN_STRIPE")) {
			actionsArr.push({
				action: {
					onClick: () => {
						const win = window.open(
							`https://dashboard.stripe.com${
								process.env.REACT_APP_ENV === "DEV" ? "/test" : ""
							}/customers/${stripeCustomerId}`,
							"_blank"
						);
						win.focus();
					},
				},
				icon: "launch",
				label: "Stripe",
				auth: ["administrator"],
			});
		}

		if (actions.includes("RESTORE_PLATFORM_PLAN")) {
			actionsArr.push({
				action: {
					onClick: () =>
						setOpenDialog({
							action: "RESTORE_PLATFORM_PLAN",
							title: "Would you like to continue and restore your current subscription?",
							closeMsg: "No",
							acceptMsg: "Continue",
						}),
				},
				label: "Restore",
				auth: ["carrier", "carrier_manager", "administrator"],
			});
		}

		return actionsArr;
	};

	const generateFormSection = (plan) => (
		<FormSection
			title={plan.title}
			formId={plan.formId}
			actions={generateButtons(plan.actions)}
			dataIds={{ carrierId }}
		/>
	);

	const FORMS = [
		{
			formId: "PLATFORM_PLAN_SUMMARY_FORM",
			title: "Platform Plan",
			actions: [
				...(!!stripeCustomerId ? ["VIEW_IN_STRIPE"] : []),
				...(platformPlanSubscriptionStatus === PLATFORM_PLAN_STATUS.INACTIVE
					? ["VIEW_IN_STRIPE", "ASSIGN_PLATFORM_PLAN", "SELECT_PLATFORM_PLAN"]
					: platformPlanSubscriptionStatus === PLATFORM_PLAN_STATUS.ACTIVE
					? [
							"UPGRADE_PLATFORM_PLAN",
							"ASSIGN_PLATFORM_PLAN",
							"ADD_SUBSCRIPTION_DISCOUNT",
							"CANCEL_PLATFORM_PLAN",
							...(isAdmin ? ["DELETE_PLATFROM_PLAN", "OVERRIDE_PLAN"] : []),
							...(isAdmin && hasOverridePlan ? ["REMOVE_OVERRIDE_PLAN"] : []),
							...(hasSubscription ? ["CHANGE_TERMINATION_DATE"] : []),
					  ]
					: platformPlanSubscriptionStatus === PLATFORM_PLAN_STATUS.CANCELLED
					? ["RESTORE_PLATFORM_PLAN", "DELETE_PLATFROM_PLAN"]
					: []),
			],
		},
		...(dispatchingPlanStatus === DISPATCHING_PLAN_STATUS.ACTIVE
			? [
					{
						formId: "DISPATCHING_PLAN_SUMMARY_FORM",
						title: "Dispatching Plan",
						actions: [
							...(dispatchingPlanStatus === DISPATCHING_PLAN_STATUS.INACTIVE
								? ["assign"]
								: dispatchingPlanStatus === DISPATCHING_PLAN_STATUS.ACTIVE
								? ["deactivate"]
								: []),
						],
					},
			  ]
			: []),
		...(isFuelProgramEnabled
			? [
					{
						formId: "FUEL_PLAN_SUMMARY_FORM",
						title: "Fuel Plan",
						actions: ["assign"],
					},
			  ]
			: []),
	];

	return (
		<>
			<div key="summary" className="grid grid-cols-1 md:gap-x-20 md:grid-cols-2 w-full">
				{FORMS.map((plan, i) => (
					<div key={"plan_" + i}>{generateFormSection(plan)}</div>
				))}
			</div>
			{openDialog?.action === "CANCEL_PLATFORM_PLAN" ? (
				<PlatformCancelationDialog
					open={!!openDialog}
					onAccept={handleCancelPlatformPlan}
					onClose={() => setOpenDialog(null)}
				/>
			) : (
				<SmarthopConfirmDialog
					open={!!openDialog}
					title={openDialog?.title}
					message={openDialog?.message}
					typedConfirmation={openDialog?.typedConfirmation}
					handleClose={() => {
						setOpenDialog(null);
						if (resetTrial) setResetTrial(false);
					}}
					handleAccept={() => {
						if (openDialog.action === "RESTORE_PLATFORM_PLAN") {
							handleRestoreSubscription();
						} else if (openDialog.action === "DELETE_PLATFROM_PLAN") {
							handleDeletePlatformPlan();
						}
						setOpenDialog(null);
						if (resetTrial) setResetTrial(false);
					}}
					closeMsg={openDialog?.closeMsg}
					acceptMsg={openDialog?.acceptMsg}
				>
					{openDialog?.action === "DELETE_PLATFROM_PLAN" ? (
						<div className="flex flex-row pb-4">
							<FormControlLabel
								control={<Checkbox checked={resetTrial} onChange={(event) => setResetTrial(event.target.checked)} />}
								className="text-red-600"
								label={
									<>
										<Icon className="text-18 -mb-5 mr-6">warning</Icon>Reset Trial (If checked the customer will get
										access to the free trial again)
									</>
								}
							/>
						</div>
					) : null}
				</SmarthopConfirmDialog>
			)}
		</>
	);
}

export default PlansPage;
