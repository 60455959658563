import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

function ChatNotSelectedView(props) {
	const onClose = props.onClose;
	return (
		<>
			<AppBar color={"inherit"} className="w-full items-end border-0" elevation={0} position="static">
				{!props.chatTruckView && (
					<Toolbar className="px-16 border-0">
						<div className="flex flex-1 flex-row">
							<IconButton color="inherit" onClick={onClose}>
								<Icon>close</Icon>
							</IconButton>
						</div>
					</Toolbar>
				)}
			</AppBar>
			<div className="flex flex-col flex-1 items-center justify-center p-24">
				{!props.loading && (
					<Paper className="rounded-full p-48 md:p-64 shadow-xl">
						<Icon className="block text-48 md:text-64" color="secondary">
							wb_incandescent
						</Icon>
					</Paper>
				)}
				{!props.loading && (
					<Typography variant="h6" className="mt-24 mb-12 text-32 font-700">
						Inbox
					</Typography>
				)}
				{props.loading && <CircularProgress color="secondary" />}
			</div>
		</>
	);
}

export default ChatNotSelectedView;
