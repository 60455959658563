import { formatCurrency } from "app/main/utils/tableUtils";

const transferPayment = ({ fee, base, max }) => {
	let transferFeeCalculationText = (
		(base > 0 ? `${formatCurrency(base / 100)}` : "") +
		(fee > 0 ? ` + ${fee}%` : "") +
		(max > 0 ? `  with a cap of ${formatCurrency(max / 100)}` : "")
	).trim();

	return transferFeeCalculationText.length > 0 ? transferFeeCalculationText : "$0";
};

const smartpayFeePlans = {
	urlGET: "billing/factoring-fee-plan",
	idKey: "_id",
	listenEvent: "smartpayFeeRevision",
	segmentKey: "ADMIN_PLANS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["nickname", "percentage", "flat_fee_amount"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		isHighlighted: (item) => !!item.default,
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{
				key: "nickname",
				type: "text",
				label: "Name",
				translate: "",
				grid: { headerTitle: true, valuePefix: "Plan " },
				builder: (item) => {
					if (!item?.default) return item.nickname;
					return `${item.nickname} (DEFAULT)`;
				},
			},
			{
				key: "default_for_tier",
				type: "text",
				label: "Default for Tier",
			},
			{
				key: "smartpay_fee_percentage",
				type: "percentage",
				label: "SmartPay Fee",
				builder: (item) => `${item.smartpay_fee_percentage}%`,
			},
			{
				key: "payment_types.ach",
				type: "component",
				label: "ACH",
				builder: (item) => {
					const typeFees = item.payment_types.ach;
					return transferPayment({
						fee: typeFees.transfer_fee_percentage,
						base: typeFees.base_transfer_fee_cents,
						max: typeFees.max_transfer_fee_cents,
					});
				},
			},
			{
				key: "payment_types.wire",
				type: "component",
				label: "Same Day",
				builder: (item) => {
					const typeFees = item.payment_types.wire;
					return transferPayment({
						fee: typeFees.transfer_fee_percentage,
						base: typeFees.base_transfer_fee_cents,
						max: typeFees.max_transfer_fee_cents,
					});
				},
			},
			{ key: "createdAt", type: "date", label: "Creation Date" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						translate: "",
						dialogBuilder: (item) => ({
							formId: "ADMIN_SMARTPAY_FEE_PLAN_FORM",
							mode: "EDIT",
							dataIds: { id: item._id },
						}),
					},
					{
						key: "delete",
						label: "Delete",
						translate: "",
						isVisible: (item) => !item?.default,
						dialogBuilder: (item) => ({
							formId: "ADMIN_SMARTPAY_FEE_PLAN_FORM",
							mode: "DELETE",
							dataIds: { id: item._id },
						}),
					},
				],
			},
		],
	},
};

export default smartpayFeePlans;
