const versionForm = {
	urlGET: "api/consts/versions?uibuild=:uibuild&uienv=:uienv",
	idKey: "_id",
	header: {
		view: { label: "Version app" },
	},
	content: {
		view: {
			items: [
				{
					type: "section",
					label: () => {
						const json = localStorage.getItem("latestRelease");
						if (!json || json === "{}") return "Release Notes";
						const latestRelease = JSON.parse(json);
						return `Release Notes V${latestRelease.version}`;
					},
				},
				{
					type: "section",
					label: "Frontend Information",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "uiBuild_app",
								type: "text",
								label: "App",
							},
							{
								key: "uiBuild_environment",
								type: "text",
								label: "Environment",
							},
						],
					},
				},
				{ key: "uiBuild_version", type: "text", label: "Build" },
				{
					type: "section",
					label: "Backend Information",
					section: { classes: { label: " -mt-4 " } },
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "backendHealthCheck_app",
								type: "text",
								label: "App",
							},
							{
								key: "backendHealthCheck_environment",
								type: "text",
								label: "Environment",
							},
						],
					},
				},
				{ key: "backendHealthCheck_version", type: "text", label: "Build" },
				{
					type: "section",
					label: "Integration Information",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "integrationHealthCheck_app",
								type: "text",
								label: "App",
							},
							{
								key: "integrationHealthCheck_environment",
								type: "text",
								label: "Environment",
							},
						],
					},
				},
				{ key: "integrationHealthCheck_version", type: "text", label: "Build" },
				{
					type: "section",
					label: "Ranker Information",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "rankerHealthCheck_app",
								type: "text",
								label: "App",
							},
							{
								key: "rankerHealthCheck_environment",
								type: "text",
								label: "Environment",
							},
						],
					},
				},
				{ key: "rankerHealthCheck_version", type: "text", label: "Build" },
			],
		},
	},
};

export default versionForm;
