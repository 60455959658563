import clsx from "clsx";

import PageWrapper from "app/main/common/PageWrapper";
import Typography from "@material-ui/core/Typography";

import SearchPanel from "../SearchPanel";
import { FIND_LOAD_PAGE_EVENT } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

function LoadSearchRequest() {
	createTrackOrPage(FIND_LOAD_PAGE_EVENT, window, "page", "Landing Page - Find Load");

	return (
		<PageWrapper
			restrictions={["ONBOARDING", "TIER_BASIC"]}
			classes={{
				content:
					"w-full max-w-4xl flex flex-1 mx-auto px-10 overflow-hidden pb-10 md:pb-92 mb-10 md:mb-92 pt-20 md:pt-0",
			}}
		>
			<div className="md:flex md:flex-col md:h-full flex-auto items-center justify-center flex-shrink-0">
				<div className={clsx("flex flex-col w-full")}>
					<Typography color="textPrimary" className={clsx("mb-14 text-center font-light text-xl md:text-2xl pt-20")}>
						Where and when are you empty? <br />
						Enter your information to <br />
						<b>get a load now</b>.
					</Typography>
					<div className={clsx("flex flex-col w-full bg-white border-1 rounded-8 p-10")}>
						<SearchPanel type="request" submitRedirectURL="/search" />
					</div>
				</div>
			</div>
		</PageWrapper>
	);
}

export default LoadSearchRequest;
