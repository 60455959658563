import { Typography } from "@material-ui/core";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";

const goalView = (item, field, subTextField) => (
	<div className="flex flex-col">
		<Typography>{formatCurrency(item?.[field])}</Typography>
		{item?.[subTextField] && (
			<Typography variant="caption" className="opacity-75">
				{formatCurrency(item[subTextField])} per truck
			</Typography>
		)}
	</div>
);

const monthlyGoalsCarrierForm = {
	urlGET: "api/cost-structures/carriers/:carrierId/goals",
	listenEvent: "goalsCarrierRevision",
  header: {
		view: { label: "Monthly Company Goals" },
  },
  content: {
    view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								type: "text",
								label: "Gross",
								builder: (item) => goalView(item, "totalRevenue", "totalTruckRevenue"),
							},
							{
								type: "text",
								label: "RPM",
								builder: (item) => goalView(item, "totalRPM", "totalTruckRPM"),
							},
						],
					},
				},
			],
		},
  }
};

export default monthlyGoalsCarrierForm;