const insurence = {
	urlPOST: "api/finance/carriers/:carrierId/insurence",
	urlGET: "api/finance/carriers/:carrierId/insurence/:id/type/:type",
	urlPUT: "api/finance/carriers/:carrierId/insurence/:id/type/:type",
	urlDELETE: "api/finance/carriers/:carrierId/insurence/:id/type/:type",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		create: { label: "Add New Insurance" },
		edit: { label: "Edit Insurance" },
		delete: { label: "Delete Insurance" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete Insurance #${item.insurance_policy_number}?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "insurance_type",
					type: "select",
					label: "Insurance Type",
					defaultValue: "insurances_general_liability",
					required: true,
					enabledOnCreateOnly: true,
					options: [
						{
							value: "insurances_general_liability",
							label: "General Liability",
							translate: "GENERAL_LIABILITY",
						},
						{
							value: "insurances_auto_liability",
							label: "Auto Liability",
							translate: "AUTO_LIABILITY",
						},
						{
							value: "insurances_motor_liability",
							label: "Motor Cargo",
							translate: "MOTOR_LIABILITY",
						},
					],
				},
				{ key: "insurance_company_name", type: "text", label: "Insurance Company", required: true },
				{ key: "insurance_policy_number", type: "text", label: "Policy Number", required: true },
				{
					key: "insurance_policy_eff",
					type: "picker",
					label: "Start date",
					translate: "",
					required: true,
					defaultValue: "NOW",
					field: {
						classes: {},
						variant: undefined,
					},
					input: {
						classes: {},
					},
					picker: {
						notClearable: true,
						type: "date",
						classes: {},
					},
				},
				{
					key: "insurance_policy_exp",
					type: "picker",
					label: "End date",
					translate: "",
					required: true,
					defaultValue: "NOW",
					field: {
						classes: {},
						variant: undefined,
					},
					input: {
						classes: {},
					},
					picker: {
						disablePast: true,
						notClearable: true,
						type: "date",
						classes: {},
					},
				},
				{
					key: "trucks",
					type: "autocomplete",
					label: "Trucks Under this Policy",
					translate: "TRUCK_POLICY",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers/:carrierId/trucks",
						params: { label_format_show_driver: true },
						preloadDefault: true,
						multiple: true,
						listenEvent: "profileRevision",
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
		view: {
			sameAs: "edit",
		},
	},
};

export default insurence;
