const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const drivers = {
	urlGET: "api/profile/drivers",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "INTERNAL_DRIVER",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["carrier__view.metadata.carrierid"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "carrierid",
				dir: "desc",
			},
		},
		defaults: {
			// Initial value of fields
			initial: {
				filters: {
					driver_active: "ACTIVE",
					carrier_active: "ACTIVE",
					...(PROD_DB ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
				},
			},
			// Additional quick filters
			items: [
				{
					label: "Active",
					filters: { driver_active: "ACTIVE", carrier_active: "ACTIVE" },
				},
				{
					label: "Inactive",
					filters: { driver_active: "INACTIVE", carrier_active: "ACTIVE" },
				},
			],
		},
		filters: {
			default: {},
			search: true,
			items: [
				{
					key: "app_access",
					type: "select",
					label: "App Access",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ENABLED",
							label: "Enabled",
						},
						{
							value: "DISABLED",
							label: "Disabled",
						},
					],
				},
				{
					key: "driver_active",
					type: "select",
					label: "Driver Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ACTIVE",
							label: "Active",
						},
						{
							value: "INACTIVE",
							label: "Inactive",
						},
					],
				},
				{
					key: "carrier_active",
					type: "select",
					label: "Carrier Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ACTIVE",
							label: "Active",
						},
						{
							value: "INACTIVE",
							label: "Inactive",
						},
					],
				},
				{
					key: "carrierAccountType",
					type: "select",
					label: `Carrier Account Type`,
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "MAIN_ACCOUNT",
							label: "Main Account",
						},
						{
							value: "SUB_ACCOUNT",
							label: "Sub Account",
						},
					],
				},
				{
					key: "carriers_type",
					type: "select",
					label: "Internal Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
				{
					key: "status",
					type: "checkbox",
					label: "Show Status",
				},
			],
		},
		items: [
			{ key: "carrier", type: "id", label: "Carrier ID", group: "internal" },
			{ key: "_id", type: "id", label: "Driver ID", group: "internal" },
			{ key: "truck", type: "id", label: "Truck ID", group: "internal" },
			{
				key: "carrier__view.metadata.carrierid",
				builder: (item) => item.carrier__view,
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
					showTestCarrier: true,
					showCarrierAccountType: true,
				},
			},
			{
				key: "driver__view",
				type: "driver__view",
				label: "Driver",
				viewConfig: {
					showPhone: true,
					showStatus: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showStatus: true,
					showTeam: true,
				},
			},
			{ key: "verified", label: "Verified", type: "bool", group: "status" },
			{ key: "deleted", builder: (item) => !item.deleted, label: "Active", type: "bool", group: "status" },
		],
	},
};

export default drivers;
