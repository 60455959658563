import moment from "moment";
import { Typography, Icon, CircularProgress, IconButton, Badge } from "@material-ui/core";
import { formatAge } from "app/main/utils/dateUtils";
import { useState, useEffect } from "react";
import { createTooltip } from "app/main/utils/tableUtils";

function LoadAvailability({
	load,
	onLoadAvailabilityCheck,
	availabilityLoading,
	hasError,
	lastCheckAvailability,
	availabilityError,
}) {
	const availabilityInProgress = !hasError && availabilityLoading && load.hasAvailabilityCheck;
	const loadIsAvailable = !hasError && !availabilityLoading && load.hasAvailabilityCheck && !load.deleted;
	const notAvailabilityCheck = !hasError && !load.hasAvailabilityCheck;
	const bidOrBookAvailable = load.bidnow || load.booknow;
	const loadNotFullyLoaded = !load.load_id;
	const loadAvailable = lastCheckAvailability && !load.deleted;
	const [updateAge, setUpdateAge] = useState(0);

	useEffect(() => {
		setUpdateAge(moment().diff(moment(lastCheckAvailability?.lastCheck), "minutes"));
		const updateCurrentAge = () => {
			setUpdateAge(moment().diff(moment(lastCheckAvailability?.lastCheck), "minutes"));
		};
		const intervalId = setInterval(updateCurrentAge, 60000);
		return () => clearInterval(intervalId);
	}, [lastCheckAvailability?.lastCheck]);

	const manualCheckAvailable = updateAge > 1;

	const textColor = loadNotFullyLoaded
		? "text-gray-600"
		: hasError || availabilityInProgress
		? "text-gray-600"
		: loadIsAvailable || (lastCheckAvailability && !load.deleted)
		? "text-green-600"
		: notAvailabilityCheck && bidOrBookAvailable
		? "text-gray-600"
		: notAvailabilityCheck
		? "text-gray-600"
		: "";
	const label = loadNotFullyLoaded
		? "Loading full details"
		: hasError
		? "Unable to validate availability digitally"
		: notAvailabilityCheck && !bidOrBookAvailable
		? "Contact the broker to confirm availability"
		: notAvailabilityCheck && bidOrBookAvailable
		? `You can book this load online in just few clicks`
		: availabilityInProgress
		? "Checking availability..."
		: loadAvailable
		? `Available (Checked ${formatAge(lastCheckAvailability?.lastCheck)} ago)`
		: loadIsAvailable
		? "Availability confirmed digitally"
		: "";

	if (load.deleted) {
		return null;
	}

	const stillAvailable = (
		<div className="flex flex-row items-center min-h-24 ml:min-h-32">
			{loadAvailable && !availabilityInProgress && !hasError && (
				<Icon className="text-20 text-green-600 mr-4">check_circle</Icon>
			)}
			{availabilityInProgress && <CircularProgress size={16} className="mr-8 text-grey" />}
			<Typography color="textSecondary" className={`text-12 ml:text-13 text-center leading-tight ${textColor}`}>
				{label}
			</Typography>
			{hasError && createTooltip(<Icon className="text-13 ml-4 mt-2">info</Icon>, availabilityError)}
			{load.hasAvailabilityCheck && !availabilityInProgress && (
				<IconButton
					disabled={!manualCheckAvailable}
					className=" mr-16 p-3 ml-4 "
					aria-label="Clear"
					onClick={() => onLoadAvailabilityCheck()}
				>
					<Badge>
						<Icon className={"text-20"}>refresh</Icon>
					</Badge>
				</IconButton>
			)}
		</div>
	);

	return <div className="flex flex-col">{stillAvailable}</div>;
}

export default LoadAvailability;
