import { Box } from "@material-ui/core";
import YouTubePlayer from "./YouTubePlayer";
import { useEffect } from "react";

const VideoDialog = ({ dataIds, setTitle, setSize }) => {
	useEffect(() => {
		setTitle?.("Video");
		setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	return (
		<Box display="flex" justifyContent={"center"}>
			<YouTubePlayer videoId={dataIds?.videoId} />
		</Box>
	);
};

export default VideoDialog;
