import CellFirstRow from "@smarthop/list/views/CellFirstRow";

const formatCellContact = (contactName, phoneNumber, email) => {
	return (
		<div className={"flex flex-row items-center"}>
			<div className={"flex flex-col"}>
				<div className={"flex flex-row items-center "}>
					<div className="flex flex-wrap">
						{contactName && <CellFirstRow label={contactName} />}
						{phoneNumber && <CellFirstRow label={phoneNumber} url={`tel:${phoneNumber}`} />}
					</div>
				</div>
				<div className={"flex flex-row items-center "}>
					<div className="flex flex-wrap">
						{email && <CellFirstRow variant="spacious" label={email} url={`mailto:${email}`} />}
					</div>
				</div>
			</div>
		</div>
	);
};

const customers = ({ hasSubaccounts }) => {
	return {
		urlGET: "api/customers/carrier/:carrierId",
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "CUSTOMERS_LIST",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: ["clientid", "mcnumber"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			filters: {
				search: true,
			},
			order: {
				defaut: {
					key: "created_at",
					dir: "desc",
				},
			},
			items: [
				...(hasSubaccounts
					? [
							{
								key: "carrier__view",
								type: "carrier__view",
								label: "Carrier",
								viewConfig: {
									showMcNumber: true,
								},
							},
					  ]
					: []),
				{
					key: "broker__view",
					type: "broker__view",
					label: "Customer",
					viewConfig: {
						showMcNumber: true,
						showScammer: false,
						showType: false,
						showSmartpay: false,
					},
					grid: { headerTitle: true },
				},
				{ key: "customerId", type: "text", label: "EIN" },
				{ key: "mcnumber", type: "text", label: "Mc Number" },
				{
					key: "contact",
					type: "component",
					label: "Contact",
					builder: (item) => {
						if (item.contact_name || item.contact || item.email) {
							return formatCellContact(item.contact_name, item.contact, item.email);
						}
						return "-";
					},
				},
				{ key: "net_payment_term", type: "number", label: "Net Payment Term" },
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "delete_broker",
							label: "Delete",
							dialogBuilder: (item, dataIds) => ({
								viewId: "BROKER_EDIT_VIEW",
								mode: "DELETE",
								dataIds: { brokerId: item._id, carrierId: dataIds.carrierId, mode: "DELETE" },
							}),
						},
					],
				},
			],
		},
	};
};

export default customers;
