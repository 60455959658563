const adminFeatureForm = {
	urlGET: "api/feature/:featureId/:env",
	urlPUT: "api/feature/:featureId/:env",
	idKey: "name",
	triggerEvent: "featureRevision",
	listenEvent: "featureRevision",
	header: {
		edit: { label: "Edit Feature" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "status",
					type: "checkbox",
					label: "Enabled for everyone",
				},
				{
					key: "userIds",
					type: "autocomplete",
					label: "Enabled for Users",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
						multiple: true,
						params: {
							options_show_limit: 200,
							options_show_carrier: true,
						},
					},
				},
				{
					key: "carrierIds",
					type: "autocomplete",
					label: "Enabled for Carriers",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						multiple: true,
						params: {
							options_show_plan: true,
							options_show_onboarding_status: true,
						},
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminFeatureForm;
