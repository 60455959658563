import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	hover: {
		"&:active:hover": {
			textDecoration: "underline",
			backgroundColor: "transparent",
		},
	},
}));

function SmarthopShowMoreView(props) {
	const classes = useStyles();
	const { text, length, textClassName } = props;

	const [showMore, setShowMore] = useState(false);

	const button = (
		<Button
			className={"text-blue uppercase font-bold text-10 py-0 pl-6 pr-10 ml-2 -mt-2 " + classes.hover}
			size="small"
			variant="text"
			startIcon={<Icon className=" -mr-8 ">{showMore ? "arrow_drop_up" : "arrow_drop_down"}</Icon>}
			onClick={() => setShowMore(!showMore)}
		>
			{showMore ? "Less" : "More"}
		</Button>
	);
	return (
		<div
			className={
				"flex " +
				(text.length > length && text.length > 120 && showMore
					? " min-w-300 "
					: text.length > length && text.length > 60 && showMore
					? " min-w-240 "
					: text.length > length && text.length > 30
					? " min-w-180 "
					: "") +
				(showMore ? " flex-col items-start max-w-360 py-4 " : " flex-row max-w-360 items-center ")
			}
		>
			{!showMore ? (
				<Typography component={"div"} className={textClassName}>
					{text.substring(0, length)}...{button}
				</Typography>
			) : (
				<Typography
					component={"pre"}
					className={textClassName}
					style={{ fontFamily: "inherit", whiteSpace: "pre-wrap", wordBreak: "break-word" }}
				>
					{text}
					{button}
				</Typography>
			)}
		</div>
	);
}

export default SmarthopShowMoreView;
