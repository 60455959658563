const LOAD_DETAILS_SECTION = {
	type: "section",
	label: "Load Details",
};

const BROKER_SECTION = {
	type: "section",
	label: "Broker Data",
};

const PICKUP_SECTION = {
	type: "section",
	label: "Pickup Data",
};

const STOPS_SECTION = {
	type: "section",
	label: "Stops Data",
};

const DELIVERY_SECTION = {
	type: "section",
	label: "Delivery Data",
};

const NOTES_SECTION = {
	type: "section",
	label: "Notes",
};

const METADATA_SECTION = {
	type: "section",
	label: "Metadata",
};

const USER_METADATA_SECTION = {
	type: "section",
	label: "User Metadata",
};

const LOAD_ID = {
	key: "data_loadId",
	label: "Load ID",
	type: "text",
};

const CARGO_TYPE = {
	key: "data_details_cargoType",
	label: "Cargo Type",
	type: "text",
};

const RATE = {
	key: "data_details_rate",
	label: "Rate",
	type: "number",
};

const WEIGHT = {
	key: "data_details_weight",
	label: "Weight",
	type: "text",
};

const EQUIPMENT = {
	key: "data_details_equipment",
	label: "Equipment",
	type: "text",
};

const SIZE = {
	key: "data_details_size",
	label: "Size",
	type: "text",
};

const TEMPERATURE = {
	key: "data_details_temperature",
	label: "Temperature",
	type: "text",
};

const PALLETS = {
	key: "data_details_pallets",
	label: "Pallets",
	type: "text",
};

const BROKER_NAME = {
	key: "data_broker_name",
	label: "Name",
	type: "text",
};

const BROKER_EMAIL = {
	key: "data_broker_email",
	label: "Email",
	type: "text",
};

const BROKER_PHONE = {
	key: "data_broker_phone",
	label: "Phone",
	type: "text",
};

const BROKER_CONTACT = {
	key: "data_broker_contact",
	label: "Contact",
	type: "text",
};

const BROKER_MC = {
	key: "data_broker_mc",
	label: "MC #",
	type: "number",
};

const METADATA_TRIP_ID = {
	key: "metadata_tripId",
	label: "Trip ID",
	type: "text",
};

const METADATA_LOAD_ID = {
	key: "metadata_loadId",
	label: "Load ID",
	type: "text",
};

const METADATA_CARRIER_ID = {
	key: "metadata_carrierId",
	label: "Carrier ID",
	type: "text",
};

const METADATA_BROKER_ID = {
	key: "metadata_brokerId",
	label: "Broker ID",
	type: "text",
};

const USER_METADATA_NAME = {
	key: "metadata_annotatedBy_name",
	label: "Name",
	type: "text",
	input: { classes: { root: "text-black", input: "text-black" } },
};

const USER_METADATA_DATE = {
	key: "metadata_annotatedBy_date",
	label: "Date",
	type: "text",
};

const USER_METADATA_ID = {
	key: "metadata_annotatedBy_userId",
	label: "User ID",
	type: "text",
};

const USER_METADATA_ENV = {
	key: "metadata_annotatedBy_env",
	label: "Env",
	type: "text",
};

const tripPreviewTestDataForm = {
	form: {
		preventClose: { enable: false },
	},
	items: [
		LOAD_ID,
		BROKER_SECTION,
		BROKER_NAME,
		{
			type: "group",
			content: {
				items: [BROKER_EMAIL, BROKER_PHONE],
			},
		},
		{
			type: "group",
			content: {
				items: [BROKER_CONTACT, BROKER_MC],
			},
		},
		PICKUP_SECTION,
		{
			type: "group",
			content: {
				items: [
					{
						key: "data_pickup_date",
						type: "picker",
						label: "Pickup Date",
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
						},
					},
					{
						key: "data_pickup_byDate",
						type: "picker",
						label: "Pickup by Date",
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
						},
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "data_pickup_address", label: "Address", type: "text" },
					{ key: "data_pickup_shipper", type: "text", label: "Shipper" },
					{ key: "data_pickup_zip", type: "zipcode", label: "Zip code" },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "data_pickup_phone", type: "text", label: "Phone" },
					{ key: "data_pickup_contact", type: "text", label: "Contact" },
				],
			},
		},
		STOPS_SECTION,
		{
			key: "data_stops",
			type: "pattern",
			addButton: { label: "Add a Stop" },
			pattern: { classes: { label: "-mt-8" }, showAllDefault: true, variant: "large" },
			content: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "date",
									type: "picker",
									label: "Pickup Date",

									picker: {
										disablePast: false,
										notClearable: false,
										type: "datetime",
									},
								},
								{
									key: "byDate",
									type: "picker",
									label: "Pickup by Date",

									picker: {
										disablePast: false,
										notClearable: false,
										type: "datetime",
									},
								},
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{ key: "shipper", type: "text", label: "Shipper" },
								{ key: "address", label: "Address", type: "text" },
								{ key: "zip", type: "zipcode", label: "Zip Code" },
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{ key: "phone", type: "text", label: "Phone" },
								{ key: "contact", type: "text", label: "Contact" },
								{
									key: "type",
									type: "select",
									label: "Type",
									options: [
										{ value: "PICKUP", label: "Pickup" },
										{ value: "DELIVERY", label: "Delivery" },
									],
								},
							],
						},
					},
				],
			},
		},
		DELIVERY_SECTION,
		{
			type: "group",
			content: {
				items: [
					{
						key: "data_deliver_date",
						type: "picker",
						label: "Delivery Date",
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
						},
					},
					{
						key: "data_deliver_byDate",
						type: "picker",
						label: "Delivery by Date",
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
						},
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "data_deliver_address", label: "Address", type: "text" },
					{ key: "data_deliver_shipper", type: "text", label: "Shipper" },
					{ key: "data_deliver_zip", type: "zipcode", label: "Zip code" },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "data_deliver_phone", type: "text", label: "Phone" },
					{ key: "data_deliver_contact", type: "text", label: "Contact" },
				],
			},
		},
		LOAD_DETAILS_SECTION,
		{
			type: "group",
			content: {
				items: [CARGO_TYPE, RATE, EQUIPMENT],
			},
		},
		{
			type: "group",
			content: {
				items: [SIZE, WEIGHT],
			},
		},
		{
			type: "group",
			content: {
				items: [TEMPERATURE, PALLETS],
			},
		},
		NOTES_SECTION,
		{ key: "data_notes", type: "text", label: "", multiline: { rows: 10 } },
		METADATA_SECTION,
		{
			type: "group",
			content: {
				items: [METADATA_TRIP_ID, METADATA_LOAD_ID],
			},
			group: {
				classes: {
					root: "opacity-90 pointer-events-none",
				},
			},
		},
		{
			type: "group",
			content: {
				items: [METADATA_CARRIER_ID, METADATA_BROKER_ID],
			},
			group: {
				classes: {
					root: "opacity-90 pointer-events-none",
				},
			},
		},
		USER_METADATA_SECTION,
		{
			type: "group",
			content: {
				items: [USER_METADATA_NAME, USER_METADATA_DATE],
			},
			group: {
				classes: {
					root: "opacity-90 pointer-events-none",
				},
			},
		},
		{
			type: "group",
			content: {
				items: [USER_METADATA_ID, USER_METADATA_ENV],
			},
			group: {
				classes: {
					root: "opacity-90 pointer-events-none",
				},
			},
		},
	],
};

export default tripPreviewTestDataForm;
