const chat = {
	urlPOST: "api/messenger/messages",
	urlGET: "api/messenger/messages/:id",
	urlDELETE: "api/messenger/messages/:id",
	idKey: "_id",
	triggerEvent: "chatRevision",
	listenEvent: "chatRevision",
	header: {
		create: { label: "Create Message" },
		delete: { label: "Delete Message" },
		view: { label: "View Message" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete chat message with ID '${item._id}'?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "chat",
					type: "autocomplete",
					label: "Chat",
					translate: "",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/messenger/chats",
						preloadDefault: true,
					},
				},
				{
					key: "participant",
					type: "autocomplete",
					label: "Participants",
					translate: "",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/messenger/participants",
						preloadDefault: true,
					},
					rules: {
						params: {
							valueRef: "chat",
							paramKey: "option_chat_id_search",
						},
					},
				},
				{
					key: "localId",
					type: "text",
					label: "Local id",
					translate: "",
					defaultValue: "__RANDOM__",
				},
				{
					key: "type",
					type: "select",
					label: "Type",
					defaultValue: "TEXT",
					options: [
						{
							value: "TEXT",
							label: "Text",
							translate: "",
							showItems: [
								{
									key: "content__text",
									type: "text",
									label: "Text",
									translate: "",
								},
							],
						},
						{
							value: "LOAD",
							label: "Load",
							translate: "",
							showItems: [
								{
									key: "content__loadType",
									type: "select",
									label: "Type",
									options: [
										{
											value: "REQUESTED",
											label: "Requested",
											translate: "",
										},
										{
											value: "SUGGESTED",
											label: "Suggested",
											translate: "",
										},
									],
								},
								{
									key: "content__loadId",
									type: "text",
									label: "Load id",
									translate: "",
								},
							],
						},
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default chat;
