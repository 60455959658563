// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { creditAndDebitAmountFormat } from "app/main/utils/billingUtils";

const factoringRequests = {
	urlGET: "factoring/factoring-requests/:factoringRequestId/payments",
	idKey: "_id",
	listenEvent: "smarthopWalletRevision",
	segmentKey: "ADMIN_FACTORING_REQUESTS_PAYMENTS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["created_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			search: true,
			items: [],
		},
		groups: {
			items: [],
		},
		items: [
			{ key: "createdAt", type: "date", label: "Date" },
			{ key: "description", type: "text", label: "Description" },
			{
				key: "amount",
				type: "component",
				label: "Amount",
				table: { align: "right" },
				builder: (item) => {
					if (!item?.direction) {
						return "...";
					}

					const amount = item.amount;
					const signAndColors = {
						Credit: ["-", " text-red"],
						Debit: ["+", " text-green"],
					};
					const value = `${signAndColors[item?.direction ?? "Debit"][0]} ${formatCurrency(amount / 100)}`;
					return creditAndDebitAmountFormat(value, signAndColors[item.direction ?? "Debit"][1]);
				},
			},
		],
	},
};

export default factoringRequests;
