import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";

// import { isEnabled } from "app/services/featureStorageService";

const walletPath = {
	path: "/profile/smarthop-wallet",
	component: lazy(() => import("./SmartHopWalletPage")),
};

export default build([...(false ? [walletPath] : [])]); // TODO: WALLET_FEATURE gk
