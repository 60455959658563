import { createSlice } from "@reduxjs/toolkit";

const smarthopWalletSlice = createSlice({
	name: "smarthopWallet",
	initialState: {
		token: localStorage.getItem("@smarthopWalletToken"),
	},
	reducers: {
		setSmarthopWalletToken: (state, action) => {
			state.token = action.payload;
			return state;
		},
	},
});

export const { setSmarthopWalletToken } = smarthopWalletSlice.actions;

export default smarthopWalletSlice.reducer;
