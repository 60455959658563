import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export function deactivateDispatchingPlan(carrierId) {
	const url = `billing/carriers/${carrierId}/customer-plan/dispatching`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getDispatchingPlanForCarrier(carrierId) {
	const url = `billing/carriers/${carrierId}/dispatching-plan`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}
