// Dependencies
import { useEffect, useState, forwardRef } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import clsx from "clsx";
import { SmarthopConfirmDialog } from "@smarthop/form";
import { global } from "app/services/requestUtil";

// Material-UI components
import { CardMedia, CircularProgress } from "@material-ui/core";

// Utils
import { readURLParameters, rewriteURLParameters } from "app/main/utils/urlUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Services
import { getCarrierId, getRoleLabel, isCarrierPermission, isInternalPermission } from "app/services/LoginService";

// Components
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";

// Lists
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";
import CarrierReportSummaryView from "./CarrierReportSummaryView";
import { getReportDetails, getReportFile } from "app/services/reportsServices";
import { downloadFile } from "../utils/fileUtils";

const CarrierReportView = forwardRef(({ nativeMobile, dataIds, setTitle }, _) => {
	useEffect(() => {
		setTitle?.(`Report Details`, dataIds?.label);
		// eslint-disable-next-line
	}, [dataIds?.label]);

	const reportId = dataIds?.reportId;
	const [processing, setProcessing] = useState(false);
	const [fileLoading, setFileLoading] = useState(false);
	const [openDialogAlert, setDialogAlert] = useState({
		flag: false,
		message: null,
		type: null,
		optionsText: {},
	});
	const [reportDetails, setReportDetails] = useState();
	const carrierId = dataIds?.carrierId ?? getCarrierId();
	const [error, setError] = useState();

	const snackbar = useSnackbar();

	const role = useMemo(() => getRoleLabel(), []);
	const isCarrier = useMemo(() => isCarrierPermission(role), [role]);
	const isInternal = useMemo(() => isInternalPermission(role), [role]);

	const readOnly = dataIds.readOnly;

	const dataRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);

	const downloadReportFile = async ({ carrierId, snackbar, reportId, type }) => {
		try {
			const report = await getReportFile({ carrierId, reportId, type });
			if (report) {
				downloadFile(report, `Report_${reportId}`, type === "pdf" ? "application/pdf" : "text/csv");
			}
		} catch (e) {
			showSnackbar(snackbar, e.errors[0]?.message, "error", 500);
		}
	};

	useEffect(() => {
		const params = readURLParameters();
		rewriteURLParameters({ tab: 0 });
		setProcessing(false);
		return () => {
			rewriteURLParameters(params);
		};
	}, []);

	const fetchReportData = async () => {
		try {
			const reportDetails = await getReportDetails({ carrierId, reportId });
			setReportDetails({ ...reportDetails });
		} catch (err) {
			setError(err.errors[0]?.message);
		} finally {
			setProcessing(false);
		}
	};

	useEffect(() => {
		setProcessing(true);
		fetchReportData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataRevision]);

	const reportPDFURI = useMemo(() => {
		if (carrierId && reportId) {
			setFileLoading(true);
			const baseUrl = `${global.SERVER_NAME}/api/reports/carriers/${carrierId}/reports/${reportId}/file?type=pdf`;
			const token = localStorage.getItem("tokenSmarthop");
			const url = `${baseUrl}&token=${token}`;
			setTimeout(() => {
				setFileLoading?.(false);
			}, 1000);
			return url;
		}
		// eslint-disable-next-line
	}, [carrierId, dataRevision]);

	if (!reportDetails || error) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={!reportDetails} error={error} />;
	}

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			processing={processing}
			sideComponents={[
				{
					key: "REPORT_PREVIEW",
					type: "PREVIEW",
					tabKeys: ["SUMMARY"],
					hideOnMobile: true,
					ratio: "5/12",
					component: (
						<div className={`flex flex-col h-full w-full  bg-grey-100 items-center `}>
							{fileLoading ? (
								<div className={clsx("flex w-full h-full items-center justify-center border-1 h-full")}>
									<CircularProgress className="text-grey-400" />
								</div>
							) : (
								<CardMedia
									component="iframe"
									className={"flex h-full relative w-full bg-grey-200 "}
									image={reportPDFURI}
									onLoad={() => {
										setFileLoading(false);
									}}
								/>
								// null
							)}
						</div>
					),
				},
			]}
			tabComponents={[
				{
					key: "SUMMARY",
					title: "Summary",
					component: (
						<div className="flex flex-col w-full">
							<CarrierReportSummaryView
								dataIds={{ ...dataIds }}
								isView={true}
								reportDetails={reportDetails}
								processing={processing}
							/>
						</div>
					),
				},
			]}
			footerActions={[
				{
					key: "DOWNLOAD_REPORT",
					title: "Download Report",
					style: { align: "LEFT", notCollapsible: true, icon: "download" },
					hideOnDesktop: readOnly || (!isInternal && !isCarrier),
					hideOnMobile: readOnly || (!isInternal && !isCarrier),
					onClick: () => {
						downloadReportFile({ carrierId, snackbar, reportId, type: "pdf" });
					},
				},
				{
					key: "DOWNLOAD_REPORT",
					title: "Download Transactions",
					style: { align: "LEFT", notCollapsible: true, icon: "download" },
					hideOnDesktop: !reportDetails?.csv_report_file__flag || readOnly || (!isInternal && !isCarrier),
					hideOnMobile: readOnly || (!isInternal && !isCarrier),
					onClick: () => {
						downloadReportFile({ carrierId, snackbar, reportId, type: "csv" });
					},
				},
			]}
		>
			<SmarthopConfirmDialog
				open={!!openDialogAlert.flag}
				title={openDialogAlert?.message}
				checkboxLabel={openDialogAlert?.checkboxLabel}
				checkboxValue={openDialogAlert?.checkboxValue}
				setCheckboxValue={(value) => {
					typeof openDialogAlert?.setCheckboxValue === "function" && openDialogAlert?.setCheckboxValue(value);
				}}
				onAccept={() => {
					openDialogAlert?.onAccept();
					setDialogAlert({ flag: false, message: null, type: null, optionsText: {} });
				}}
				handleClose={() => setDialogAlert({ flag: false, message: null, type: null, optionsText: {} })}
				acceptMsg={openDialogAlert?.optionsText?.accept}
			/>
		</SmarthopDialogViewContainer>
	);
});

export default CarrierReportView;
