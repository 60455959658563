import { createRequest, throwResErrors } from "./requestUtil";

export const confirmEmailAlias = (token) => {
	return createRequest()
		.get(`api/public/url/alias/confirm/${token}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getVerifiedTokenEditTrip = (token) => {
	return createRequest()
		.get(`api/public/url/trip-edit/verify/${token}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripUploadFilesInfoPublic = (token) => {
	return createRequest()
		.get(`api/public/url/trip-upload-files/${token}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripPublic = (tripId, carrierId) => {
	return createRequest()
		.get(`api/public/url/booking/carriers/${carrierId}/trips/${tripId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripLocationsMilesPublic = (locations, changedAddress, truck, carrier, weight, tripId, userId) => {
	return createRequest()
		.post(
			`api/public/url/booking/locations/miles`,
			{ locations, changedAddress, truck, carrier, weight, tripId },
			{ params: { options_public_user_context: userId } }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTripPublic = (tripId, carrierId, data, params) => {
	return createRequest()
		.put(
			`api/public/url/booking/carriers/${carrierId}/trips/${tripId}`,
			{ data },
			{ params: { options_public_user_context: params.userId, emailAlias: params.emailAlias } }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierConfigPublic = (carrierId, userId) => {
	return createRequest()
		.get(`api/public/url/profile/carriers/${carrierId}/details/configuration`, {
			params: { options_public_user_context: userId },
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierUsersPublic = (carrierId, userId) => {
	return createRequest()
		.get(`api/public/url/profile/carriers/${carrierId}/users`, { params: { options_public_user_context: userId } })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateCurrentDriverTruckPublic = (carrierId, truckId, data = {}, userId) => {
	return createRequest()
		.put(
			`api/public/url/profile/carriers/${carrierId}/trucks/${truckId}/driver`,
			{ data },
			{ params: { options_public_user_context: userId } }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveBrokerTripCarrierPublic = (data, userId) => {
	return createRequest()
		.post(`api/public/url/brokers/create`, { data }, { params: { options_public_user_context: userId } })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTripFilesPublic = (carrierId, tripId) => {
	return createRequest()
		.get(`api/public/url/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
