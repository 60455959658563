import { useMemo } from "react";
import SmarthopList from "@smarthop/list/SmarthopList";
import { formatDate } from "../utils/dateUtils";
import { useSelector } from "react-redux";

function tableConfig(options) {
	return {
		urlGET: `api/queue/carriers/:carrierId/trucks/:truckId/plan/:planId/search/requests`,
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "TruckPlanLoadScoutResults",
		content: {
			table: {
				variant: "skinny",
				orderBy: ["createdAt", "analytics.counters.total", "analytics.counters.planMatch", "analytics.counters.best"],
			},
			pagination: {
				defaut: { size: options?.limit ?? 10 },
				enabled: true,
				resetScroll: true,
			},
			defaults: {
				initial: {
					filters: {
						trigger: "MARKET_MONITOR",
						status: "__FINISHED__",
					},
				},
			},
			order: {
				defaut: {
					key: "createdAt",
					dir: "desc",
				},
			},
			filters: {
				search: false,
				items: [],
			},
			items: [
				{ key: "createdAt", type: "date", label: "Date", grid: { headerTitle: true } },
				...(options?.showPlanColumn
					? [
							{
								label: "Plan",
								key: "route",
								type: "component",
								builder: (item) => (
									<div className="flex flex-col">
										<div>{`${item.origin} -> ${item.destination ? item.destination : "Anywhere"}`}</div>
										<div>{formatDate(item.availStart)}</div>
									</div>
								),
							},
					  ]
					: []),
				{
					key: "analytics.counters.total",
					type: "number",
					label: ["Total Loads", "New/Total"],
					builder: (item) => `${item?.analytics?.counters?.newTotal ?? 0}/${item?.analytics?.counters?.total ?? 0}`,
				},
				{
					key: "analytics.counters.planMatch",
					type: "number",
					label: ["Matching", "New/Total"],
					builder: (item) =>
						`${item?.analytics?.counters?.newPlanMatch ?? 0}/${item?.analytics?.counters?.planMatch ?? 0}`,
				},
				{
					key: "analytics.counters.best",
					type: "number",
					label: ["Hop Loads", "New/Total"],
					builder: (item) => `${item?.analytics?.counters?.newBest ?? 0}/${item?.analytics?.counters?.best ?? 0}`,
				},
				{
					key: "menu",
					type: "menu",
					label: "",
					items: [
						{
							key: "view",
							variant: "iconButton",
							button: {
								icon: "visibility",
								color: "primary",
							},
							label: "View Search Results",
							dialogBuilder: (item, dataIds) => ({
								viewId: "LOADS_SEARCH_RESULTS_VIEW",
								dataIds: { ...dataIds, userId: options.userId, searchId: item._id },
							}),
						},
					],
				},
			],
		},
	};
}

function TruckPlanLoadScoutResults({ carrierId, truckId, planId, limit = 10, showPlanColumn = true }) {
	const userId = useSelector(({ auth }) => auth.user._id);
	const config = useMemo(() => tableConfig({ limit, showPlanColumn, userId }), [limit, showPlanColumn, userId]);

	return (
		<SmarthopList
			key="truckPlanLoadScoutResults"
			urlKey="truckPlanLoadScoutResultsUrl"
			isView
			mode="TABLE"
			config={config}
			dataIds={{
				carrierId,
				truckId,
				planId,
			}}
		/>
	);
}

export default TruckPlanLoadScoutResults;
