import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import StrategySummary from "./StrategySummary";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: "-1 !important",
	},
}));

function StrategySummaryWrapper(
	{ strategy, strategyReqData, truckInfo, setTablePaths, tablePaths, mountStackLength },
	ref
) {
	const [openDialog, setOpenDialog] = useState(false);
	const [dialogTitle, setDialogTitle] = useState(null);
	const [dialogActions, setDialogActions] = useState(null);
	const [dialogMessage, setDialogMessage] = useState(null);
	const [dialogErrorMessage, setDialogErrorMessage] = useState(null);
	const [dialogSaving, setDialogSaving] = useState(null);
	const [changeZIndex, setChangeZIndex] = useState(false);
	const [hideBackdrop, setHideBackdrop] = useState(false);
	const stack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);

	const classes = useStyles();

	useEffect(() => {
		// Change z-index to show the validation dialog
		if (stack.length > mountStackLength) {
			setChangeZIndex(true);
		} else {
			setChangeZIndex(false);
		}
	}, [stack, mountStackLength]);

	useImperativeHandle(ref, () => ({
		open: () => {
			if (!openDialog) {
				setOpenDialog(true);
			} else {
				setHideBackdrop(false);
				setChangeZIndex(false);
			}
		},
		setTitle: (title) => {
			setDialogTitle(title);
		},
		setActions: (actions) => {
			setDialogActions(actions);
		},
		setMessage: (message) => {
			setDialogMessage(message);
		},
		setError: (message) => {
			setDialogErrorMessage(message);
		},
		setSaving: (saving) => {
			setDialogSaving(saving);
		},
	}));

	const handleClose = () => {
		setHideBackdrop(true);
		setChangeZIndex(true);
		setTablePaths(null);
		setDialogSaving(false);
	};

	const getClickHandler = (onClick, closeOnClick) => () => {
		onClick();
		closeOnClick && setOpenDialog(false);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={openDialog}
			onClose={handleClose}
			fullWidth
			maxWidth={dialogSaving ? "md" : "sm"}
			className={changeZIndex ? classes.root : ""}
			hideBackdrop={hideBackdrop}
			disableScrollLock
		>
			<DialogTitle>
				<Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					{dialogTitle}
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogActions
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
					margin: "0 1.52rem",
					gap: "1rem",
				}}
			>
				{dialogMessage && (
					<Alert className="w-full" severity="warning">
						{dialogMessage}
					</Alert>
				)}
				{dialogErrorMessage && (
					<Alert className="w-full" severity="error">
						{dialogErrorMessage}
					</Alert>
				)}
				<Box>
					{dialogActions?.map((action) => (
						<Button
							key={`action-${action.label}`}
							color="primary"
							variant="contained"
							onClick={getClickHandler(action.onClick, action.closeOnClick)}
							disabled={!!dialogErrorMessage}
						>
							{action.label}
						</Button>
					))}
				</Box>
			</DialogActions>
			<StrategySummary
				strategy={strategy}
				strategyReqData={strategyReqData}
				truckInfo={truckInfo}
				saving={dialogSaving}
				setTablePaths={setTablePaths}
				tablePaths={tablePaths}
			/>
		</Dialog>
	);
}

export default forwardRef(StrategySummaryWrapper);
