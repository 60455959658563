import moment from "moment";
import { createTooltip } from "app/main/utils/tableUtils";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const progressStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "update",
};
const finishStyle = {
	color: "green-400",
	hoverColor: "green-500",
	icon: "check_circle",
};
const errorStyle = {
	color: "red-700",
	hoverColor: "red-800",
	icon: "highlight_off",
};

const createAlertView = (data) => {
	let status = data?.run?.status;
	let style;
	let text;

	if (status === "IN_PROGRESS" || status === "CREATED") {
		style = progressStyle;
		text = "In Progress";
	} else if (status === "FINISH" || status === "FINISHED") {
		style = finishStyle;
		text = "Finish Process";
	} else if (status === "ERROR" || status === "FAILED") {
		style = errorStyle;
		text = data?.run?.error;
	} else if (!status) {
		return "-";
	} else {
		return status;
	}

	return createTooltip(
		<Icon className={`text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>,
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{text}
		</Typography>
	);
};

const showProcessInfo = (data) => {
	if (data.run?.userEmail) {
		return `${data.run.process} (${data.run.userEmail})`;
	}
	if (data.run?.process) {
		return `${data.run.process}`;
	}
	return data.run?.process;
};

const createSchedulerView = (schedule) => {
	let time = schedule;
	try {
		let [minutes, hours, dayOfMonth, month, dayOfWeek] = schedule.split(" ");
		let expression = "Run At: ";

		if (dayOfWeek !== "*") {
			dayOfWeek = moment().day(dayOfWeek).format("dddd");
			expression += `${dayOfWeek}, `;
		}

		if (dayOfMonth !== "*" || month !== "*") {
			expression += `Month: `;
			if (dayOfMonth !== "*") {
				dayOfMonth = moment().date(dayOfMonth).format("DD");
				expression += `${dayOfMonth}, `;
			}

			if (month !== "*") {
				month = moment().month(month).format("MMMM");
				expression += `${month}, `;
			}
		}

		hours = hours !== "*" ? hours : "00";
		minutes = minutes !== "*" ? minutes : "00";
		expression += `Time: ${hours}:${minutes}`;
		time = expression;
	} catch {}

	return createTooltip(
		schedule,
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{time}
		</Typography>
	);
};

const cronJobList = {
	urlGET: "api/cron/users/:userId/jobs",
	idKey: "_id",
	listenEvent: "cronJobsRevision",
	segmentKey: "ADMIN_CRON_JOBS_CONFIGS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
		},
		items: [
			{ key: "name", type: "text", label: "Name" },
			{ key: "schedule", type: "component", label: "Schedule", builder: (item) => createSchedulerView(item.schedule) },
			{ key: "run.status", type: "component", label: "Last Run", builder: (item) => createAlertView(item) },
			{ key: "run.startsUnix", type: "date", label: "Last Run Time" },
			{ key: "run.finishUnix", type: "date", label: "Last Run Finish Time" },
			{ key: "run.process", type: "text", label: "Last Run Type", builder: (item) => showProcessInfo(item) },
		],
	},
};

export default cronJobList;
