import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import TruckCellView from "@smarthop/list/views/TruckCellView";

const goalsList = ({ isAdmin, onSetDefault, role }) => {
	return {
		urlGET: isAdmin ? "api/goals" : "api/goals/carriers/:carrierId",
		idKey: "_id",
		listenEvent: "goalsRevision",
		segmentKey: "GOALS",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: ["name", "createdAt"],
			},
			pagination: {
				enabled: true,
			},
			order: {
				defaut: {
					key: "name",
					dir: "desc",
				},
			},
			filters: {
				search: true,
				items: [
					{
						key: "equipment",
						type: "select",
						label: "Equipment",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "VAN",
								label: "Van",
							},
							{
								value: "REEFER",
								label: "Reefer",
							},
							{
								value: "PO",
								label: "Power Only",
							},
							{
								value: "FLATBED",
								label: "Flatbed",
							},
						],
					},
					...(isAdmin
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									translate: "",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										params: {
											sortOrder: "asc",
											sortBy: "carrier_id",
										},
									},
								},
						  ]
						: []),
				],
			},
			...(isAdmin
				? {
						groups: {
							items: [
								{
									key: "internal",
									type: "checkbox",
									label: "Show Internal Fields",
								},
							],
						},
				  }
				: {}),
			items: [
				{
					key: "_id",
					type: "id",
					label: "ID",
					hidden: !isAdmin,
					group: "internal",
				},
				{
					key: "carrier__view",
					type: "carrier__view",
					label: "Carrier",
					hidden: !isAdmin,
				},
				{
					key: "name",
					type: "text",
					label: "Name",
					grid: { headerTitle: true },
				},
				{
					key: "default_badge",
					type: "component",
					label: "Default",
					grid: { headerSubtitle: true },
					hidden: isAdmin,
					builder: (item) =>
						item.default ? (
							<Typography className="bg-green-800 px-6 rounded-lg">
								Default for <b>{item.equipment}</b>
							</Typography>
						) : null,
				},
				{
					key: "default",
					type: "bool",
					label: "Default",
					hidden: !isAdmin,
				},
				{
					key: "equipment",
					type: "text",
					label: "Equipment",
				},
				{
					key: "revenue_month",
					type: "currency",
					table: { align: "right" },
					label: "Goal Revenue",
					translate: "GOAL_REVENUE",
				},
				{
					key: "miles_month",
					type: "text",
					table: { align: "right" },
					label: "Goal Miles",
					translate: "GoAL_MILES",
				},
				{
					key: "rpm_month",
					type: "currency",
					table: {
						align: "right",
					},
					label: "Goal RPM",
					translate: "GOAL_RPM",
				},
				{
					key: "default_labled",
					type: "component",
					label: "Status",
					grid: { longRow: true },
					hidden: isAdmin,
					builder: (item) =>
						item.default ? (
							<div className="flex flex-row items-center pt-6">
								<Icon className="text-28 text-green-800 mr-8">warning</Icon>
								<Typography className="text-green-800" style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
									This goal is used by default for all <b>{item.equipment}</b> trucks. You can always override the goal
									in the truck settings
								</Typography>
							</div>
						) : (
							<div className="flex flex-col pt-6">
								<Typography className="text-grey-700 mb-2">
									This goal is selected for
									<b>{` ${item.trucks?.length ?? 0} ${item.equipment} `}</b>
									truck(s)
								</Typography>
								{item.trucks__views?.map((item) => (
									<div className="flex flex-row items-center pt-3">
										<Icon className="text-18 mr-8 text-orange-800">local_shipping</Icon>
										<TruckCellView variant="spacious" view={item} />
									</div>
								))}
							</div>
						),
				},
				{
					key: "Menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "set_goals_default",
							label: "Set As Default",
							isVisible: (item) => !isAdmin && !item.default && role !== "carrier_dispatcher",
							dispatchAction: (item) => onSetDefault?.(item._id),
						},
						{
							key: "edit_goals",
							label: "Edit",
							isVisible: (item) => (!!item.carrier || isAdmin) && role !== "carrier_dispatcher",
							dialogBuilder: (item) => ({
								formId: "CARRIER_GOALS_FORM",
								mode: "EDIT",
								dataIds: { goalId: item?._id },
							}),
						},
						{
							key: "delete_goals",
							label: "Delete",
							isVisible: (item) => (!!item.carrier || isAdmin) && role !== "carrier_dispatcher",
							dialogBuilder: (item) => ({
								formId: "CARRIER_GOALS_FORM",
								mode: "DELETE",
								dataIds: { goalId: item?._id },
							}),
						},
					],
				},
			],
		},
	};
};

export default goalsList;
