import React from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Icon, Link, CircularProgress } from "@material-ui/core";
import formatHelper from "@smarthop/utils/formatHelpers";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { global } from "app/services/requestUtil";
import { createTooltip } from "app/main/utils/tableUtils";

const createAlertView = ({ status, statusProcess, lastMessage }) => {
	let style;
	status = statusProcess || status;
	if (status === "FAILED") {
		style = formatHelper.errorStyle;
	} else if (status === "IN_PROGRESS" || status === "POSTPONED" || status === "RETRYING") {
		style = formatHelper.inprogressStyle;
	} else if (status === "FINISHED" || status === "FINISH") {
		style = formatHelper.successStyle;
	} else {
		return status;
	}

	const viewIcon = (statusView) => {
		if (statusView === "IN_PROGRESS" || statusView === "POSTPONED") {
			return (
				<Box sx={{ position: "relative", display: "inline-flex" }}>
					<CircularProgress size={30} style={{ color: "#06060633" }} sx={formatHelper.progressStyle} />
					<Box sx={formatHelper.progressBoxStyle}>
						<Icon className={`text-16 text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>
					</Box>
				</Box>
			);
		} else {
			return <Icon className={`text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>;
		}
	};

	return createTooltip(
		viewIcon(status),
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{lastMessage}
		</Typography>,
		`bg-${style.hoverColor}`
	);
};

const FilePreview = ({ content }) => {
	const dispatch = useDispatch();

	const fileId = content?.fileId;
	const carrierId = content?.carrierID;
	const fileName = content?.fileInfo?.file?.name;
	if (!fileName || !fileId) return "- ";

	const token = localStorage.getItem("tokenSmarthop");
	const url = `/api/profile/carriers/${carrierId}/files/`;
	const viewUrl = global.SERVER_NAME + url + fileId + "?token=" + token;

	return (
		<Link
			onClick={() => {
				dispatch(
					openFormDialog({
						viewId: "FILE_VISUALIZER_VIEW",
						dataIds: { urlFilePreview: viewUrl },
					})
				);
			}}
		>
			{fileName}
		</Link>
	);
};

const uploadedFile = {
	urlGET: "api/transaction/users/:userId/records/FILE_UPLOAD",
	idKey: "_id",
	triggerEvent: "transactionsRevision",
	listenEvent: "transactionsRevision",
	segmentKey: "TRANSACTIONS_STATE_FILE_UPLOAD",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: [
				"createdAt",
				"transaction__view.metadata.slower_step.stepTime",
				"carrier__view.label",
				"transaction__view.metadata.total_time",
			],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					// TODO should be dynamic as in dialog to transition from one state to another
					key: "state",
					type: "select",
					label: "State",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "Get File URI", value: "GET_FILE_URL" },
						{ label: "Call ML", value: "CALL_ML" },
						{ label: "Done", value: "DONE" },
					],
				},
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "Finished", value: "FINISHED" },
						{ label: "In Progress", value: "IN_PROGRESS" },
						{ label: "Postponed", value: "POSTPONED" },
						{ label: "Failed", value: "FAILED" },
					],
				},
				{
					key: "createdAt",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__DATE_DAYS_GT__7",
							label: "Last 7 Days",
						},
						{
							value: "__DATE_DAYS_GT__30",
							label: "Last 30 Days",
						},
						{
							value: "__DATE_DAYS_GT__90",
							label: "Last 90 Days",
						},
						{
							value: "__DATE_DAYS_GT__365",
							label: "Last 1 Year",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		isHighlighted: (item) => item?.status === "POSTPONED" || item?.status === "FAILED",
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "user", type: "id", label: "User ID", group: "internal" },
			{
				key: "content.user__view",
				type: "user__view",
				label: "User",
				builder: (item) => item?.user__view,
				viewConfig: {
					showRole: true,
				},
			},
			{ key: "createdAt", type: "date", label: "Created" },
			{
				key: "content.file.fileInfo.name",
				type: "text",
				label: "File",
				builder: (item) => <FilePreview content={item.content} />,
			},
			{
				key: "carrier__view.",
				type: "carrier__view",
				label: "Carrier",
				builder: (item) => item.carrier__view,
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
				},
			},
			{
				key: "trip__view",
				type: "trips__view",
				builder: (item) => item?.trip__view,
				label: "Trip",
				viewConfig: {
					showBroker: true,
				},
			},
			{
				key: "document_type",
				type: "text",
				builder: (item) => item?.content?.docAnalize?.metadata?.[0]?.document_type ?? " - ",
				label: "Type of Document",
			},
			{
				key: "nro_pages",
				type: "text",
				builder: (item) => item?.content?.docAnalize?.total_pages ?? " - ",
				label: "Nro. Pages",
			},
			{
				key: "n_signed_pages",
				type: "text",
				builder: (item) => item?.content?.docAnalize?.metadata?.[0]?.checks?.n_signed_pages ?? " - ",
				label: "Signed Pages",
			},
			{
				key: "n_stamped_pages",
				type: "text",
				builder: (item) => item?.content?.docAnalize?.metadata?.[0]?.checks?.n_stamped_pages ?? " - ",
				label: "Stamped Pages",
			},
			{
				key: "state",
				type: "text",
				label: "State",
				builder: (item) => {
					return item.state || item.status;
				},
			},
			{
				key: "transaction__view.metadata.slower_step.stepTime",
				label: "Slower Status",
				type: "component",
				group: "internal",
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.transaction__view?.metadata?.slower_step?.state
									? item.transaction__view?.metadata?.slower_step?.state.toUpperCase()
									: "-"}
							</Typography>
							<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
								{item.transaction__view?.metadata?.slower_step?.stepTime
									? (item.transaction__view?.metadata?.slower_step?.stepTime / 1000).toFixed(0) + "s"
									: "-"}
							</Typography>
						</div>
					);
				},
			},
			{
				key: "transaction__view.metadata.total_time",
				label: "Total Time",
				type: "component",
				group: "internal",
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.transaction__view?.metadata?.total_time
									? (item.transaction__view?.metadata?.total_time / 1000).toFixed(0) + "s"
									: "-"}
							</Typography>
						</div>
					);
				},
			},
			{ key: "error", label: "Status", type: "component", builder: (item) => createAlertView(item) },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "transition_state",
						label: "Transition to a state",
						auth: ["administrator", "ops support"],
						dialogBuilder: (item, dataIds) => ({
							formId: "TRANSACTIONS_UPLOADED_FILE_ANALIZE_FORM",
							mode: "EDIT",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
					},
					{
						key: "execute_logs",
						label: "Logs",
						dialogBuilder: (item, dataIds) => ({
							mode: "view",
							viewId: "TRANSACTIONS_EXECUTE_LOGS",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
					},
					{
						key: "check_status_logs",
						label: "Retry Process",
						dialogBuilder: (item, dataIds) => ({
							mode: "VIEW",
							formId: "TRANSACTIONS_BOOKING_STATUS_FORM",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
						isVisible: (item) => {
							return item.status === "POSTPONED" || item.status === "FAILED";
						},
					},
				],
			},
		],
	},
};

export default uploadedFile;
