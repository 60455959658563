const createValidationForm = (type, value) => ({
	noInitValidation: true,
	items: [
		{
			key: "message",
			type: "message",
			label:
				type === "PHONE"
					? `To continue please verify your phone number, we've sent a confirmation code to ${value}, please check your SMS messages`
					: `To continue please verify your email address, we've sent a confirmation code to ${value}, please check your inbox`,
		},
		{
			key: "code",
			type: "text",
			label: "Validation Code",
			required: true,
			maxCharacter: 10,
		},
		{ type: "action", label: type === "PHONE" ? "Verify Phone" : "Verify Email", action: "SUBMIT" },
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
				classes: { root: "mt-16" },
			},
		},
	],
});

const createValidationTypeForm = (email, phone) => ({
	noInitValidation: true,
	items: [
		{
			key: "message",
			type: "message",
			label: `We need to send you confirmation code to verify validity of the provided information.`,
		},
		{
			key: "validationType",
			type: "radio",
			required: true,
			defaultValue: "EMAIL",
			options: [
				...(email ? [{ value: "EMAIL", label: "Send email to " + email }] : []),
				...(phone ? [{ value: "PHONE", label: "Send SMS to " + phone }] : []),
			],
		},
		{
			type: "action",
			label: "Send Code",
			button: {
				alwaysEnabled: true,
				classes: { root: "mt-8" },
			},
			action: "SUBMIT",
		},
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
				classes: { root: "mt-16" },
			},
		},
	],
});

export { createValidationForm, createValidationTypeForm };
