const carrierDispatchingAccessForm = {
	urlGET: "api/profile/carriers/:carrierId/dispatching/details",
	idKey: "_id",
	triggerEvent: "carrierDispatchingRevision",
	listenEvent: "carrierDispatchingRevision",
	header: {
		view: { label: "Account Information" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "company",
								type: "text",
								label: "Company Name",
								builder: (item) => {
									return item?.company ?? "-";
								},
								hidden: (item) => !item?.access,
							},
							{
								key: "access",
								type: "text",
								label: "Access Level",
								builder: (item) => {
									return item?.access === "OWNER"
										? "Full"
										: item?.access === "DISPATCHING"
										? "Partial"
										: item?.access?.length > 0
										? item?.access
										: "No External Access Allowed";
								},
							},
						],
					},
				},
				{
					type: "message",
					builder: (item) => {
						if (item?.access === "OWNER") {
							return `'${item?.company}' users have full controll of your account and can edit your assets, create trips, manage invoicing and payroll. `;
						} else if (item?.access === "DISPATCHING") {
							return `'${item?.company}' users have partial controll of your account and can edit your assets, create trips, manage invoices and payroll (everything except billing). You can remove dispatcher access at any time by clicking 'Disconnect Dispatcher' in the options menu.`;
						} else if (item?.access?.length > 0) {
							return null;
						} else {
							return 'To allow an external company to control your account, generate an "Access Code" by clicking "Generate Access Code" in options menu. Provide it to the dispatching company to get connected.';
						}
					},
				},
			],
		},
	},
};

export default carrierDispatchingAccessForm;
