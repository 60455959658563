import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";

import { isOnboardingNotSubmitted, getRoleLabel } from "app/services/LoginService";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { selectChats } from "app/store/messenger/chatSlice";
import { useLocation } from "react-router";

function ChatMenuItem({ type, color }) {
	const location = useLocation();
	const dispatch = useDispatch();
	const chats = useSelector(selectChats);
	const favicon = document.getElementById("favicon");
	const faviconUrl = `${process.env.PUBLIC_URL}/favicon.png`;
	const faviconUnreadUrl = `${process.env.PUBLIC_URL}/favicon_unread.png`;
	const [showIcon, setShowIcon] = useState(true);

	const userRole = getRoleLabel();

	let unread = useMemo(() => {
		let unreadExternal = 0;

		chats.forEach(({ type, metadata }) => {
			if (metadata?.status?.unread && !metadata?.status?.silent) {
				unreadExternal++;
			}
		});

		return { unreadExternal };
	}, [chats]);

	useEffect(() => {
		if (location?.pathname === "/chat/trucks") {
			setShowIcon(false);
		} else {
			setShowIcon(true);
		}
	}, [location, showIcon]);

	const unreadCount = unread.unreadExternal;
	useEffect(() => {
		let prevTitle = document.title;
		let newTitle = prevTitle.replace(/\([0-9]+\)/, "");
		favicon.href = faviconUrl;
		if (unreadCount) {
			newTitle = `(${unreadCount}) ` + newTitle;
			favicon.href = faviconUnreadUrl;
		}
		document.title = newTitle;
	}, [unreadCount, favicon, faviconUnreadUrl, faviconUrl]);

	const handleClick = () =>
		dispatch(
			openFormDialog({
				viewId: "CHAT_APP",
			})
		);

	if (userRole === "bot") {
		return null;
	}
	if (isOnboardingNotSubmitted()) {
		return null;
	}

	return (
		showIcon &&
		(type === "icon" ? (
			<Button className="mx-8 rounded-none" onClick={handleClick}>
				<div className="flex flex-col justify-center items-center">
					<Badge badgeContent={unread.unreadExternal} color="error">
						<Icon style={{ color }} size="small">
							chat
						</Icon>
					</Badge>
					<Typography style={{ color }} className="text-10">
						INBOX
					</Typography>
				</div>
			</Button>
		) : (
			<MenuItem onClick={handleClick} role="button" className="overflow-visible">
				<ListItemIcon className="min-w-40">
					<Badge badgeContent={unreadCount} color="secondary">
						<Icon color="action">chat</Icon>
					</Badge>
				</ListItemIcon>
				<ListItemText primary="Inbox" />
			</MenuItem>
		))
	);
}

export default ChatMenuItem;
