const adminPlanForm = {
	urlPOST: "billing/dispatching-plan",
	urlPUT: "billing/dispatching-plan/:id",
	urlDELETE: "billing/dispatching-plan/:id",
	urlGET: "billing/dispatching-plan/:id",
	idKey: "_id",
	triggerEvent: "planRevision",
	listenEvent: "planRevision",
	header: {
		create: { label: "Create Dispatching Plan" },
		delete: { label: "Delete Dispatching Plan" },
		edit: { label: "Edit Dispatching Plan" },
	},
	messages: {
		create: { success: "Dispatching plan added." },
		edit: { success: "Dispatching plan updated." },
		delete: { success: "Dispatching plan deleted." },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `¿Are you sure you want to delete '${item.nickname}' dispatching plan?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		create: { sameAs: "edit" },
		edit: {
			items: [
				{
					key: "nickname",
					type: "text",
					label: "Name",
					translate: "",
					required: true,
				},
				{
					key: "percentage",
					type: "number",
					label: "Fee per Trip (%)",
					translate: "",
					required: false,
				},
				{
					key: "flat_fee_amount",
					type: "currency",
					label: "Flat Fee ($)",
					translate: "",
					required: false,
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminPlanForm;
