import { useEffect } from "react";
import CodeVerificationView from "./CodeVerificationView";

const EmailConfirmationView = ({ onDone, dataIds, setTitle, setSize }) => {
	useEffect(() => {
		setTitle?.("Email Confirmation");
		setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	return <CodeVerificationView type="EMAIL" dataIds={dataIds} onDone={onDone} />;
};

export default EmailConfirmationView;
