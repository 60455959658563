import { Button, Icon } from "@material-ui/core";
import { isStepCompleted, hasPrevStep, hasNextStep } from "./onboardUtil";
import { useMemo } from "react";

function OnboardFooter({ step, config, data, switchToStep, props }) {
	const navDisabled = useMemo(() => config.navDisabled?.({ config, data }), [config, data]);
	config.list = config.list.filter((list) => {
		if (list.hidden && list.hidden(data)) {
			return false;
		}
		return true;
	});

	return (
		<div className="flex flex-row">
			{props.isDesktop && (
				<div className="flex flex-1 bg-white items-center justify-center py-12 md:py-10 border-t-8 border-b-8 border-l-4 border-r-4 border-grey-100 ">
					{hasPrevStep({ step, config, data }) && (
						<Button
							disabled={navDisabled}
							onClick={() => !navDisabled && switchToStep(step - 1)}
							className="whitespace-nowrap text-14 md:text-14 font-bold py-6 px-16"
						>
							{"< Previous"}
						</Button>
					)}
				</div>
			)}
			{(props.isMobile || props.nativeMobile) && (
				<div className="flex flex-1 bg-white items-center justify-center py-12 md:py-10 border-t-8 border-b-8 border-l-4 border-r-4 border-grey-100 ">
					{step !== -1 && (
						<Button
							disabled={navDisabled}
							onClick={() => !navDisabled && switchToStep(-1)}
							className="whitespace-nowrap text-14 md:text-14 font-bold py-6 px-16"
						>
							<Icon>menu</Icon>
						</Button>
					)}
				</div>
			)}
			<div
				className={
					"flex flex-1 bg-white items-center justify-center py-12 md:py-10 border-t-8 border-b-8 border-l-4 border-r-4 border-grey-100 "
				}
			>
				{hasNextStep({ step, config, data }) && (
					<Button
						onClick={() => switchToStep(step + 1)}
						className={
							"whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-bold py-6 " +
							(isStepCompleted({ step, config, data }) ? " animate-pulse text-green-800 " : " text-grey ")
						}
					>
						{isStepCompleted({ step, config, data }) ? " Continue >" : " Skip >"}
					</Button>
				)}
			</div>
		</div>
	);
}

export default OnboardFooter;
