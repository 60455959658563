import { useEffect, useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openLoadedFormDialog, refreshDialog } from "app/store/tools/formDialogSlice";

// Nested Components
import { useMemo } from "react";
import TruckDetailsView from "./TruckDetailsView";

const TruckDetailsDialog = forwardRef((props, ref) => {
	useEffect(() => {
		props?.setTitle?.("Truck Details");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const viewerCanEdit = useMemo(
		() =>
			user.roleType === "INTERNAL" ||
			// Can edit all user
			(user.roleType === "EXTERNAL" && ["CARRIER_OWNER", "CARRIER_MANAGER"].includes(user.role)),
		[user]
	);

	useImperativeHandle(
		ref,
		() => {
			dispatch(refreshDialog());
			return {
				edit: viewerCanEdit
					? {
							onClick: () =>
								dispatch(
									openLoadedFormDialog({
										viewId: "TRUCK_VIEW",
										dataIds: {
											carrierId: props?.dataIds?.carrierId,
											truckId: props?.dataIds?.truckId,
											id: props?.dataIds?.truckId,
											mode: "EDIT",
										},
										mode: "EDIT",
									})
								),
							title: "Edit Truck",
					  }
					: null,
			};
		},
		[props?.dataIds, viewerCanEdit, dispatch]
	);

	return <TruckDetailsView {...props} />;
});

export default TruckDetailsDialog;
