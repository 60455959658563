import { Button } from "@material-ui/core";
import { closeFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const CarrierEmailAuthTypeForm = ({ dataIds, setTitle, setSize }) => {
	useEffect(() => {
		setTitle?.(`Select Email Provider`);
		setSize?.("max-w-sm");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();

	return (
		<div className="flex-1 p-10">
			<div className="flex flex-col w-full">
				<Button
					onClick={() => {
						window.open(
							`${process.env.REACT_APP_HTTP_SERVER}/api/profile/carriers/${
								dataIds?.carrierId
							}/oauth/authorize?token=${localStorage.getItem("tokenSmarthop")}`
						);
						dispatch(closeFormDialog());
					}}
					className="w-6/12 text-18 p-32 w-full"
				>
					Gmail
				</Button>
				<Button
					onClick={() => {
						dispatch(closeFormDialog());
						dispatch(
							openLoadedFormDialog({
								formId: "CARRIER_BRANDING_MAIL_CONF_FORM",
								dataIds,
								mode: "EDIT",
							})
						);
					}}
					className="w-6/12 text-18 p-32 w-full"
				>
					Other
				</Button>
			</div>
		</div>
	);
};

export default CarrierEmailAuthTypeForm;
