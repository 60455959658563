import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

const DRIVER_TYPE_LABELS = {
	COMPANY_DRIVER: "Company Driver",
	OWNER_OPERATOR: "Owner Operator",
};

function DriverCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const phone = props.view?.metadata?.phone;
	const email = props.view?.metadata?.email;
	const carrier = props.view?.metadata?.carrier;
	const active = !props.view?.metadata?.deleted;
	const driverType = props?.view?.metadata?.driverType;
	const type = props?.view?.metadata?.type;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "USER_DETAILS_VIEW",
		dataIds: {
			carrierId: carrier,
			driverId: props.view?.value,
		},
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<div className="flex flex-wrap">
					<CellFirstRow mobile={mobile} view={props.view} variant={variant} dialog={dialog} />
					{config?.showStatus__top && !active && (
						<CellSecondRow
							mobile={mobile}
							icon={active ? "done" : "close"}
							label={active ? "Active" : "Inactive"}
							color={active ? " text-green-700" : " text-red-600"}
							variant={variant}
							classes={{ label: "ml-4" }}
						/>
					)}
				</div>
			}
			second={
				<div className="flex flex-wrap">
					{config?.showDriverType && (
						<CellSecondRow
							mobile={mobile}
							icon={"account_box"}
							label={DRIVER_TYPE_LABELS[driverType] ?? "Unknown"}
							variant={variant}
						/>
					)}
					{config?.showPhone && (
						<CellSecondRow mobile={mobile} icon={"local_phone"} label={phone ?? "No Phone"} variant={variant} />
					)}
					{config?.showEmail && (
						<CellSecondRow mobile={mobile} icon={"mail"} label={email ?? "No Email"} variant={variant} />
					)}
					{config?.showStatus && !active && (
						<CellSecondRow
							mobile={mobile}
							icon={active ? "done" : "close"}
							label={active ? "Active" : "Inactive"}
							color={active ? " text-green-700" : " text-red-600"}
							variant={variant}
						/>
					)}
					{config?.showTypeHistory && <CellSecondRow mobile={mobile} label={type ?? "No Type"} variant={variant} />}
				</div>
			}
		/>
	);
}

export default DriverCellView;
