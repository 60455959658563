import { Typography, Icon } from "@material-ui/core";
import { createTooltip } from "app/main/utils/tableUtils";
const moment = require("moment");

const formatLabel = (label, text, description, extraInfo) => {
	return (
		<div className="flex pt-14 pb-14">
			<Typography className="font-medium text-13 text-grey-600 mr-10">{label}</Typography>
			<div className="ml-auto">
				<Typography
					component={"div"}
					className="font-medium text-15 text-grey-800 flex flex-row"
					style={{ overflowWrap: "anywhere" }}
				>
					{text}
					{extraInfo && (
						<Typography component={"div"} className="font-normal ml-4" style={{ overflowWrap: "anywhere" }}>
							{extraInfo}
						</Typography>
					)}
				</Typography>
			</div>
			{description &&
				createTooltip(
					<Icon className="text-12  text-black align-middle flex ml-3">{"info"}</Icon>,
					<Typography className="text-12 ml:text-13">{description}</Typography>,
					"bg-grey"
				)}
		</div>
	);
};

const formatTitle = (title, subtitle = true, customSubtitle = false) => {
	return (
		<>
			<Typography className="font-medium text-16 text-grey-900 pt-10">{title}</Typography>
			{subtitle && (
				<Typography className="font-medium text-13 text-grey-600 -mt-2">
					{customSubtitle ? customSubtitle : moment().format("MMMM")}
				</Typography>
			)}
		</>
	);
};

export { formatLabel, formatTitle };
