import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

// Pay at pickup
export const payAtPickup = async (tripId, data) => {
	const url = `api/wallet/admin/trips/${tripId}/pay-at-pickup`;
	try {
		const res = await createRequest().post(url, { data }, { headers: createHeaders() });
		return res.data;
	} catch (error) {
		return throwResErrors(error);
	}
};

export const checkPayAtPickupRules = async (tripId) => {
	const url = `api/wallet/admin/trips/${tripId}/pay-at-pickup-rules`;
	try {
		const res = await createRequest().get(url, { headers: createHeaders() });
		return res.data;
	} catch (error) {
		return throwResErrors(error);
	}
};

// Cards
export const requestCreateDebitCard = (params, data) => {
	const { carrierId } = params;
	const url = `api/wallet/carriers/${carrierId}/card`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const tranferFunds = (params, data) => {
	const { type, carrierId } = params;
	const url = `api/wallet/carriers/${carrierId}/${type === "counterparty" ? "counterparty-payment" : "payment"}`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createCustomerToken = (carrierId, verificationCode, verificationToken, scope) => {
	const url = `api/wallet/carriers/${carrierId}/token/customer-token`;
	return createRequest()
		.post(url, { verificationCode, verificationToken, scope }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createVerificationCode = (carrierId, scope) => {
	const url = `api/wallet/carriers/${carrierId}/token/verification-code`;
	return createRequest()
		.post(url, { scope }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
