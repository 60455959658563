import React from "react";

import { Typography } from "@material-ui/core";

const CouponDuration = ({ coupon }) => {
	const duration = coupon?.duration;
	const durationInMonths = coupon?.duration_in_months;

	const label = `${duration === "once" ? "One time discount" : `For the next ${durationInMonths} months`}`;

	return duration !== "forever" ? (
		<div className="flex flex-col mb-12">
			<Typography variant="caption" className="font-300">
				{label}
			</Typography>
			<Typography variant="caption" className="font-300">
				Regular price thereafter
			</Typography>
		</div>
	) : null;
};

export default CouponDuration;
