import moment from "moment";
import { renderAddressWithDate } from "app/main/utils/tableUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { Icon, Link, Typography } from "@material-ui/core";
import { formatCurrency } from "app/main/utils/tableUtils";
import { getAddressCity } from "app/main/utils/tripsUtils";
import { createTooltip } from "app/main/utils/tableUtils";
import Image from "material-ui-image";

const formatNumberColor = (num) => {
	if (isNaN(num) || !num) num = 0;
	const color = num < 0 ? "text-red-500" : "text-green-700";

	return (
		<Typography className={color + " text-12 ml:text-13"}>
			{num?.toLocaleString(undefined, { style: "currency", currency: "USD" })}
		</Typography>
	);
};

const CARRIER = () => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	field: {
		noErrorMessage: true,
		classes: {},
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		listenEvent: "profileRevision",
	},
});

const TRUCK = (carrierSelectionEnabled = false) => {
	const rules = carrierSelectionEnabled
		? {
				params: {
					valueRef: "carrier",
					paramKey: "options_carrier_id_search",
				},
		  }
		: null;
	return {
		key: "truck",
		type: "autocomplete",
		label: "Truck",
		translate: "",
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/trucks",
			params: {
				options_metadata_truck: true,
				options_metadata_driver: true,
				options_active_only: true,
				options_format_show_driver_phone: true,
			},
			preloadDefault: true,
			preloadImmedately: true,
			listenEvent: "profileRevision",
		},
		rules,
	};
};

const performanceCarrierTrips = ({ dispatch, readOnly, isMainHandler }) => {
	const formatProfit = (item) => {
		return (
			<div className="flex">
				{item?.metadata_hop && (
					<>
						{createTooltip(
							<div className={"bg-primary rounded-full truncate flex py-8 px-3 mr-4"}>
								<Image
									disableSpinner={true}
									disableTransition={true}
									imageStyle={{ width: "13px", height: "4px", marginRight: "1px" }}
									style={{ width: "13px", height: "4px", marginRight: "1px", paddingTop: null }}
									color="primary"
									src={"assets/images/badges/best.png"}
								/>
							</div>,
							<Typography color="inherit" variant="body2" className="p-6">
								This trip is marked as best
							</Typography>
						)}
					</>
				)}

				<Link
					onClick={() => {
						dispatch(
							openLoadedFormDialog({
								viewId: "TRIP_VIEW",
								dataIds: {
									tripId: item._id,
									carrierId: item.carrier,
									openChat: true,
									initTab: "PROFIT",
								},
							})
						);
					}}
				>
					{formatNumberColor(item?.snapshots?.cost_structure?.results?.profit)}
				</Link>
			</div>
		);
	};

	return {
		urlGET: "api/booking/trips",
		idKey: "_id",
		listenEvent: ["tripsRevision", "goalsCarrierRevision"],
		segmentKey: "PERFORMANCE_CARRIER_TRIPS",
		content: {
			table: {
				variant: "skinny",
				orderBy: [
					"data.delivery_date",
					"data.pickup_date",
					"snapshots.cost_structure.results.profit",
					"snapshots.cost_structure.results.totalExpensesWithoutFixed",
					"data.rate",
					"data.miles",
					"data.emptymiles",
					"data.total_miles",
					"data.rpm",
					"data.duration",
					"ppd",
				],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "data.delivery_date",
					dir: "desc",
				},
			},
			export: {
				fileName: "Goals & Performance - Trips",
			},
			filters: {
				search: false,
				default: {
					"data.delivery_date": `${moment().subtract(1, "month").valueOf()}__DATE_RANGE__${moment()
						.add(1, "week")
						.endOf("day")
						.valueOf()}`,
				},
				items: [
					...(isMainHandler ? [CARRIER()] : []),
					TRUCK(isMainHandler),
					{
						key: "data.delivery_date",
						type: "dateRange",
						label: "Period",
						shortcutPriority: "long",
					},
				],
			},
			groups: {
				items: [
					{
						key: "expenses",
						type: "checkbox",
						label: "Show Expenses",
					},
					{
						key: "driver_info",
						type: "checkbox",
						label: "Show Driver Information",
					},
					{
						key: "creator_data",
						type: "checkbox",
						label: "Show Creator",
					},
					{
						key: "investor_data",
						type: "checkbox",
						label: "Show Investor",
					},
				],
			},
			items: [
				...(isMainHandler
					? [
							{
								key: "carrier__view.label",
								builder: (item) => item.carrier__view,
								type: "carrier__view",
								label: "Carrier",
								viewConfig: {
									readOnly,
									showMcNumber: true,
									showTestCarrier: true,
								},
							},
					  ]
					: []),
				{
					key: "truck__view",
					type: "truck__view",
					label: "Truck",
					viewConfig: {
						readOnly,
					},
					export: {
						builder: (item) => item?.truck__view?.label + "\n #" + item?.truck__view?.metadata?.truckid,
					},
				},
				{
					key: "trip__view",
					type: "trips__view",
					label: "Trip",
					grid: { headerTitle: true, valuePefix: "Load ID " },
					viewConfig: {
						showStatus__top: true,
						showBroker: true,
						readOnly,
					},
					export: {
						builder: (item) => item?.trip__view?.label + "\n" + item?.trip__view?.metadata?.broker,
					},
				},
				{
					key: "data.username",
					builder: (item) => item?.creator__view,
					type: "user__view",
					label: "Creator",
					viewConfig: {
						readOnly,
						showRole: true,
					},
					group: "creator_data",
				},
				{
					key: "investor__view",
					builder: (item) => item?.investor__view,
					type: "user__view",
					label: "Investor",
					viewConfig: {
						readOnly,
						showRole: false,
					},
					group: "investor_data",
				},
				{
					builder: (item) =>
						renderAddressWithDate(
							item?.data?.pickup_address,
							item?.data?.pickup_date,
							item?.data?.market_indexes?.pickup
						),
					key: "data.pickup_date",
					type: "component",
					label: "Pickup",
					export: {
						builder: (item) => item?.data?.pickup_date + "\n" + getAddressCity(item?.data?.pickup_address),
					},
				},
				{
					builder: (item) =>
						renderAddressWithDate(
							item?.data?.delivery_address,
							item?.data?.delivery_date,
							item?.data?.market_indexes?.destination
						),
					key: "data.delivery_date",
					type: "component",
					label: "Delivery",
					export: {
						builder: (item) => item?.data?.delivery_date + "\n" + getAddressCity(item?.data?.delivery_address),
					},
				},
				{
					key: "data.miles",
					type: "number",
					label: "Loaded",
					builder: (i) => `${Math.round(i?.data?.miles)} mi`,
				},
				{
					key: "data.emptymiles",
					type: "number",
					label: "Empty",
					builder: (i) => `${Math.round(i?.data?.emptymiles)} mi`,
				},
				{
					key: "fixedCostExpense",
					type: "currency",
					label: "Fixed Expenses",
					group: "expenses",
					builder: (item) => {
						const res = item?.snapshots?.cost_structure?.results;
						return res?.totalExpenses - res?.totalExpensesWithoutFixed;
					},
					export: {
						builder: (item) => {
							const res = item?.snapshots?.cost_structure?.results;
							return res?.totalExpenses - res?.totalExpensesWithoutFixed;
						},
					},
				},
				{
					key: "snapshots.cost_structure.results.totalExpensesWithoutFixed",
					type: "currency",
					label: "Variable Expenses",
					group: "expenses",
				},
				{
					key: "data.rate",
					type: "component",
					label: "Rate",
					table: { align: "right" },
					builder: (item) => {
						const rate = <Typography className="text-12 ml:text-13">{formatCurrency(item?.data?.rate)}</Typography>;
						const rateVal = parseFloat(item?.data?.rate);

						let rateInd;
						if (item.data.max_price && rateVal > item.data.max_price) {
							rateInd = {
								icon: <Icon className="mr-4 text-15 ml:text-17 text-green">align_vertical_bottom</Icon>,
								title: `Rate is above the AVG lane price of (${formatCurrency(item.data.min_price)} - ${formatCurrency(
									item.data.max_price
								)})`,
							};
						} else if (item.data.min_price && rateVal < item.data.min_price) {
							rateInd = {
								icon: <Icon className="mr-4 text-15 ml:text-17 text-red">align_vertical_top</Icon>,
								title: `Rate is below the AVG lane price of (${formatCurrency(item.data.min_price)} - ${formatCurrency(
									item.data.max_price
								)})`,
							};
						} else if (item.data.min_price && item.data.max_price) {
							rateInd = {
								icon: <Icon className="mr-4 text-15 ml:text-17 text-grey">align_vertical_center</Icon>,
								title: `Rate is within the AVG lane price of (${formatCurrency(item.data.min_price)} - ${formatCurrency(
									item.data.max_price
								)})`,
							};
						} else {
							rateInd = {
								icon: <Icon className="mr-4 text-15 ml:text-17 text-gray-300">align_vertical_center</Icon>,
								title: `Market comparison is not available for this load`,
							};
						}

						if (!rateInd) {
							return rate;
						}

						return createTooltip(
							<div className="flex flex-row content-center space-x-2">
								{rate}
								{rateInd.icon}
							</div>,
							rateInd.title
						);
					},
				},
				{
					key: "data.rpm",
					type: "currency",
					label: "RPM",
				},
				{
					key: "snapshots.cost_structure.results.profit",
					type: "component",
					label: "Profit",
					builder: (item) => formatProfit(item),
					export: {
						builder: (item) => formatCurrency(item?.snapshots?.cost_structure?.results?.profit ?? 0),
					},
					table: {
						align: "right",
					},
				},
				{
					key: "data.duration",
					type: "text",
					label: "Duration",
					builder: (item) => (item?.data?.duration ? parseFloat(item.data.duration).toFixed(2) + " days" : "-"),
					table: {
						align: "right",
					},
				},
				{
					key: "ppd",
					type: "component",
					label: "PPD",
					description: "Profit Per Day",
					builder: (item) => formatNumberColor(item?.ppd),
					export: {
						builder: (item) => formatCurrency(item?.ppd ?? 0),
					},
					table: {
						align: "right",
					},
				},
				{
					key: "driver__view",
					type: "driver__view",
					label: "Driver",
					viewConfig: {
						showPhone: true,
						readOnly,
					},
					group: "driver_info",
				},
				{
					key: "driver_secondary__view",
					type: "driver__view",
					label: "Secondary Driver",
					group: "driver_info",
					viewConfig: {
						showPhone: true,
						readOnly,
					},
				},
				{
					key: "dispatcher__view",
					type: "user__view",
					label: "Dispatcher",
					viewConfig: {
						showEmail: true,
						readOnly,
					},
					group: "dispatcher_info",
				},
			],
		},
	};
};

export default performanceCarrierTrips;
