import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import WithControlPanel from "app/main/tree-visualizer/control-panel/WithControlPanel";
import strategySubPanelConfig from "app/main/tree-visualizer/control-panel/configs/strategySubPanelConfig";
import { strategyConnect } from "../../providers/StrategyContextProvider";
import StrategyLegend from "../strategy-legend/StrategyLegend";
import {
	changeControlPanel,
	changeModel,
	changeResult,
	cleanControlPanel,
	saveStrategy,
	latestStrategy,
} from "../../providers/reducer/actions";
import { getStrategyTreeById } from "app/services/strategyServices";
import { useSelector } from "react-redux";
import { getLoadedModel } from "app/main/strategy/utils/strategyUtils";
import StrategySummaryContainer from "../strategy-summary/StrategySummaryContainer";

const StrategySubpanel = ({
	result,
	model,
	saveLoading,
	changeControlPanel,
	changeResult,
	saveStrategy,
	latestStrategy,
	loadStrategy,
	cleanControlPanel,
	entryPoint,
}) => {
	const controlDefaultValues = {
		folded: true,
		flexPathsEnabled: true,
		save: false,
		edgeWidthEnabled: true,
		tooltipEnabled: false,
		highlightFlexEnabled: false,
	};
	const stack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const [mountStackLength] = useState(stack.length);

	const [SmarthopControlPanel, useControlPanelState, setControlPanelValue] = WithControlPanel({
		defaultValues: controlDefaultValues,
		content: strategySubPanelConfig(entryPoint),
	});

	useEffect(() => {
		changeControlPanel({ useControlPanelState, setControlPanelValue });
		return () => cleanControlPanel();
		//eslint-disable-next-line
	}, []);

	const legendRef = useRef();
	const summaryRef = useRef();

	const handlePrev = async () => changeResult(await getStrategyTreeById(result?.prevStrategyId));
	const handleNext = async () => changeResult(await getStrategyTreeById(result?.nextStrategyId));
	const handleLoad = () => loadStrategy();
	const handleSave = () => {
		summaryRef.current?.open();
		summaryRef.current?.setTitle("Saving Summary");
		summaryRef.current?.setActions([
			{
				label: "Save",
				onClick: () => {
					saveStrategy();
				},
				closeOnClick: true,
			},
		]);
		summaryRef.current?.setMessage("You're about to save this strategy with the summary details outlined below.");
		summaryRef.current?.setSaving(true);
	};

	const handleLatestStrategy = () => {
		latestStrategy();
	};

	const handleOpenSummary = () => {
		summaryRef.current?.open();
		summaryRef.current?.setTitle("Strategy Summary");
		summaryRef.current?.setActions([]);
		summaryRef.current?.setMessage("");
		summaryRef.current?.setSaving(false);
	};
	const handleToggleLegend = () => legendRef.current?.toggle();
	const emptyResult = _.isEmpty(result);
	const savedResult = result?.resData?.isMongo;
	const disableLatest = () => {
		const hasResult = !emptyResult;
		const hasTruckId = !!(result?.resData?.strategy?.truck || model.truck);
		const hasNextId = !!result?.nextStrategyId;
		return (!hasResult && !hasTruckId) || (hasResult && savedResult && !hasNextId);
	};

	return (
		<>
			<SmarthopControlPanel
				callbacks={{
					previous: handlePrev,
					next: handleNext,
					load: handleLoad,
					save: handleSave,
					strategySummary: handleOpenSummary,
					latest: handleLatestStrategy,
					legend: handleToggleLegend,
				}}
				disabled={{
					previous: !result?.prevStrategyId,
					next: !result?.nextStrategyId,
					save: emptyResult || result?.resData?.isMongo,
					load: emptyResult,
					strategySummary: emptyResult,
					expanded: emptyResult,
					folded: emptyResult,
					latest: disableLatest(),
				}}
				loading={{ save: saveLoading }}
			/>
			<StrategyLegend ref={legendRef} />
			<StrategySummaryContainer ref={summaryRef} mountStackLength={mountStackLength} />
		</>
	);
};

const mapStateToProps = (state) => ({
	model: state.model,
	result: state.result,
	saveLoading: state.saveLoading,
	entryPoint: state.entryPoint,
});

const mapDispatchToProps = (dispatch, ownProps, state) => ({
	changeControlPanel: (controlPanel) => dispatch(changeControlPanel(controlPanel)),
	changeResult: (result) => dispatch(changeResult(result)),
	saveStrategy: () => dispatch(saveStrategy({ dispatch })),
	latestStrategy: () => dispatch(latestStrategy({ dispatch, onStrategySubmitted: ownProps.onStrategySubmitted })),
	loadStrategy: () => dispatch(changeModel(getLoadedModel(state.result))),
	cleanControlPanel: () => dispatch(cleanControlPanel()),
});

export default strategyConnect(mapStateToProps, mapDispatchToProps)(StrategySubpanel);
