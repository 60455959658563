const INVOICE_STATUSES_COMMON = {
	PENDING: "Pending",
	GENERATED: "Generated",
};

const INVOICE_STATUSES_LABELS_WITHOUT_COMMON = {
	SUBMITTED: "Submitted",
	PAID: "Paid",
	CANCELED: "Canceled",
};

const INVOICE_STATUSES_LABELS = {
	...INVOICE_STATUSES_COMMON,
	...INVOICE_STATUSES_LABELS_WITHOUT_COMMON,
};

const INVOICE_STATUSES_ICONS = {
	PENDING: { name: "schedule", color: "text-grey-500" },
	GENERATED: { name: "receipt", color: "text-secondary" },
	SUBMITTED: { name: "play_circle_outline", color: "text-secondary" },
	PAID: { name: "check_circle", color: "text-primary-400" },
	CANCELED: { name: "cancel", color: "text-red-600" },
	SP_SUBMITTED: { name: "play_circle_outline", color: "text-secondary" },
	SP_IN_REVIEW: { name: "find_in_page", color: "text-secondary" },
	SP_CHANGE_REQUESTED: { name: "assignment_late", color: "text-secondary", text: "text-secondary font-medium" },
	SP_REJECTED: { name: "not_interested", color: "text-red-700", text: "text-red-700 font-medium" },
	SP_APPROVED: { name: "assignment_turned_in", color: "text-primary" },
	SP_FUNDED: { name: "check_circle", color: "text-primary" },
	AGING: { name: "hourglass_top", color: "text-grey-500" },
	IN_COLLECTION: { name: "get_app", color: "text-secondary" },
	COLLECTED: { name: "check_box", color: "text-primary" },
	COLLECTED_WITH_WARNINGS: { name: "warning", color: "text-red-700" },
	UNCOLLECTIBLE: { name: "error_outline", color: "text-red-700" },
};

const INVOICE_STATUSES_SP_LABELS_ADMIN_WITHOUT_COMMON = {
	SP_SUBMITTED: "Submitted",
	SP_IN_REVIEW: "In Review",
	SP_CHANGE_REQUESTED: "Changes Requested",
	SP_REJECTED: "Rejected",
	SP_APPROVED: "Approved",
	SP_FUNDED: "Funded",
};

const INVOICE_STATUSES_SP_LABELS_ADMIN = {
	...INVOICE_STATUSES_COMMON,
	...INVOICE_STATUSES_SP_LABELS_ADMIN_WITHOUT_COMMON,
};

const COLLECTION_STATUSES_LABELS = {
	AGING: "Aging",
	IN_COLLECTION: "In Collection",
	COLLECTED: "Collected",
	COLLECTED_WITH_WARNINGS: "Collected with Warnings",
	UNCOLLECTIBLE: "Uncollectible",
};

const INVOICE_STATUSES_SP_LABELS_CARRIER = {
	...INVOICE_STATUSES_COMMON,
};

const generateOptionsFromLabels = (labels) => {
	return Object.keys(labels).map((key) => ({
		value: key,
		label: labels[key],
	}));
};

const statusMap = {
	PENDING: ["PENDING", "GENERATED"],
	GENERATED: ["GENERATED", "SP_SUBMITTED"],
	SP_SUBMITTED: ["SP_SUBMITTED", "SP_IN_REVIEW", "SP_CHANGE_REQUESTED", "SP_REJECTED"],
	SP_CHANGE_REQUESTED: ["SP_CHANGE_REQUESTED"],
	SP_IN_REVIEW: ["SP_IN_REVIEW", "SP_APPROVED", "SP_CHANGE_REQUESTED", "SP_REJECTED"],
	SP_APPROVED: ["SP_APPROVED", "PENDING"],
	SP_REJECTED: ["SP_REJECTED"],
	SP_FUNDED: ["SP_FUNDED", "PENDING"],
};

const getStatusOptions = (currentStatus) => {
	if (!statusMap[currentStatus]) {
		return [];
	}

	return statusMap[currentStatus].map((status) => {
		return {
			value: status,
			label: INVOICE_STATUSES_SP_LABELS_ADMIN[status],
		};
	});
};

const INVOICE_STATUSES_OPTIONS = generateOptionsFromLabels(INVOICE_STATUSES_LABELS);
const INVOICE_STATUSES_OPTIONS_SP_ADMIN = generateOptionsFromLabels(INVOICE_STATUSES_SP_LABELS_ADMIN);
const INVOICE_STATUSES_OPTIONS_SP_CARRIER = generateOptionsFromLabels(INVOICE_STATUSES_SP_LABELS_CARRIER);

const INVOICE_COLLECTION_STATUSES_OPTIONS = generateOptionsFromLabels(COLLECTION_STATUSES_LABELS);

export {
	INVOICE_STATUSES_LABELS,
	INVOICE_STATUSES_SP_LABELS_ADMIN,
	INVOICE_STATUSES_ICONS,
	INVOICE_STATUSES_OPTIONS,
	INVOICE_STATUSES_OPTIONS_SP_ADMIN,
	INVOICE_STATUSES_OPTIONS_SP_CARRIER,
	COLLECTION_STATUSES_LABELS,
	INVOICE_COLLECTION_STATUSES_OPTIONS,
	INVOICE_STATUSES_COMMON,
	INVOICE_STATUSES_SP_LABELS_ADMIN_WITHOUT_COMMON,
	INVOICE_STATUSES_LABELS_WITHOUT_COMMON,
	getStatusOptions,
};
