const axleDriverImport = () => ({
	urlGET: "api/axle_import/carriers/:carrierId/drivers",
	idKey: "id",
	listenEvent: "profileRevision",
	segmentKey: "AXLE_DRIVER_IMPORT",
	content: {
		pagination: {
			enabled: true,
			default: { size: 10 },
			disableRowsPerPage: true,
			resetScroll: true,
		},
		table: {
			variant: "skinny",
		},
		order: {
			defaut: {
				key: "name",
				dir: "asc",
			},
		},
		defaults: {
			initial: {
				filters: {
					import_status: "__IMPORTABLE__",
				},
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "import_status",
					type: "select",
					label: "Import Status",
					defaultValue: "__IMPORTABLE__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__IMPORTABLE__",
							label: "Importable",
						},
						{
							value: "__NOT_IMPORTABLE__",
							label: "Not Importable",
						},
					],
				},
			],
		},
		items: [
			{
				key: "name",
				type: "text",
				label: "Name",
				table: { width: "20%" },
			},
			{
				key: "email",
				type: "text",
				label: "Email",
				table: { width: "20%" },
			},
			{
				key: "username",
				type: "text",
				label: "Username",
				table: { width: "18%" },
			},
			{
				key: "phone",
				type: "text",
				label: "Phone",
				table: { width: "18%" },
			},
			{
				key: "status",
				type: "text",
				label: "Status",
				table: { width: "12%" },
			},
			{
				key: "import_status",
				type: "text",
				label: "Import Status",
				table: { width: "12%" },
			},
		],
	},
});

export default axleDriverImport;
