import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Icon, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { SmarthopConfirmDialog } from "@smarthop/form";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Components
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

// Utils
import { getStatusConfig } from "app/main/utils/tripsUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { processTierRestrictions } from "app/main/utils/rolesUtils";
import { createRequest, createHeaders } from "app/services/requestUtil";
import { getUserTier } from "app/services/LoginService";

// Gatekeeper
import { isEnabled } from "app/services/featureStorageService";
import { useRef } from "react";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog";

function TripStatusSelector({ trip, userId, carrierId }) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const userTier = getUserTier();

	const config = getStatusConfig(trip?.status, true);
	const driver = trip.driver__view;
	const [processing, setProcessing] = useState(null);
	const [processed, setProcessed] = useState(false);
	const [updated, setUpdated] = useState(false);
	const [formData, setFormData] = useState({});
	const [paywallDialog, setPaywallDialog] = useState({});
	const ignoreWarnings = useRef(false);
	const [openWarningDialog, setOpenWarningDialog] = useState(false);

	useEffect(() => {
		let timeout;
		if (processed) {
			timeout = setTimeout(() => {
				setProcessed(false);
			}, 3000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [processed]);

	const submitData = async (updatedData, originalData, enableUpdated) => {
		setProcessed(false);
		setProcessing(true);

		updatedData.ignoreWarnings = ignoreWarnings.current;
		let url = `api/booking/carriers/${carrierId}/trips/${trip._id}/status`;
		try {
			await createRequest().put(url, { data: updatedData }, { headers: createHeaders() });
			showSnackbar(snackbar, "Saved", "success");
			dispatch(incrementDataRevision({ event: "tripsRevision" }));
			setProcessed(true);
			if (enableUpdated) setUpdated(true);
		} catch (errors) {
			let response = errors?.response;
			let message =
				response?.data?.errors?.[0]?.message ??
				(errors?.message ? "Unexpected Error: " + errors?.message : "Something went wrong, please try again later");

			const tripWarnings = response?.data?.errors?.[0]?.metadata?.warnings;
			if (!tripWarnings?.length) {
				showSnackbar(snackbar, message, "error");
				setFormData(originalData);
			} else {
				setOpenWarningDialog({
					warnings: tripWarnings,
					title: "Are you sure you want to continue?",
					message: errors?.errors?.[0].message,
					updatedData,
					originalData,
					enableUpdated,
				});
			}
		}
		setProcessing(false);
	};

	const dataToUse = useMemo(() => {
		return { ...(trip ?? {}), ...formData };
	}, [trip, formData]);

	const askForLocation = useMemo(() => {
		if (!formData) return false;
		let noAskLocationFor = ["Pending", "Booked", "Cancelled", "Delivered"];
		noAskLocationFor.push("At Delivery-" + trip.delivery_address);

		return !noAskLocationFor.includes(formData.status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const askForSendDriverNotification = useMemo(() => {
		if (!formData) return false;
		return (
			formData.status === "Delivered" &&
			driver.label &&
			driver.metadata.phone &&
			isEnabled("SEND_DRIVER_BOL_NOTIFICATION")
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const onChangeCommitted = async (model) => {
		let originalData = {};
		let updatedData = {};

		setFormData((data) => {
			originalData = data;
			updatedData = { ...data, ...model };
			return updatedData;
		});

		if (
			model.status === "Delivered" &&
			driver.label &&
			driver.metadata.phone &&
			isEnabled("SEND_DRIVER_BOL_NOTIFICATION")
		) {
			setUpdated(true);
			return;
		}

		submitData(model, originalData, true);
	};

	return (
		<div className={`flex flex-row`}>
			{(processing || processed) && (
				<div className={"flex items-center"}>
					<div className="flex bg-white p-2 ml:p-4 rounded-full border-1 mr-2">
						{processing ? (
							<CircularProgress size={12} />
						) : (
							<Icon className={"text-13 ml:text-14 text-green"}>done</Icon>
						)}
					</div>
				</div>
			)}
			{!processing && !processed && (
				<Icon className={(config?.color ?? "") + " text-22 ml:text-24 mr-2"}>{config?.icon ?? "receipt"}</Icon>
			)}
			<SmarthopFormView
				key={"status_dropdown"}
				content={{
					form: { noErrorMessage: true },
					items: [
						{
							key: "status",
							required: true,
							type: "autocomplete",
							classes: { cell: "min-w-100 text-white" },
							autocomplete: {
								disableClearable: true,
								provider: "smarthop",
								url: "api/booking/trips/:tripId/status",
								preloadDefault: true,
							},
							label: "",
							field: {
								noErrorMessage: true,
								variant: "row",
								classes: {
									label: "text-white",
									container: `text-white border-0 mt-2 flex min-w-192`,
									select: "text-white",
								},
							},
						},
					],
				}}
				trackChangedFields={["ALL"]}
				onChangeCommitted={onChangeCommitted}
				data={dataToUse}
				mode={"EDIT"}
				dataIds={{
					carrierId: carrierId ?? trip.carrier,
					tripId: trip._id,
					userId: userId,
				}}
			/>
			<SmarthopConfirmDialog
				open={updated && askForLocation}
				title={
					formData?.status === "Dispatched"
						? "Would you like to update the truck location now?"
						: "Seems truck location has not been updated for quite some time, would you like to update it now?"
				}
				handleClose={() => {
					setUpdated(false);
				}}
				handleAccept={() => {
					let addDialog = {
						viewId: "TRIP_TRACKING",
						mode: "CREATE",
						dataIds: {
							carrierId: carrierId,
							tripId: trip._id,
							data: trip,
							status__view: { label: getStatusConfig(formData.status).label, value: formData.status },
						},
					};
					dispatch(openLoadedFormDialog(addDialog));
					setUpdated(false);
				}}
				closeMsg={"No"}
				acceptMsg={"Yes"}
			/>

			<WarningConfirmDialog
				customMessage={openWarningDialog?.title}
				open={!!openWarningDialog}
				warnings={openWarningDialog?.warnings}
				onClose={() => {
					ignoreWarnings.current = false;
					setOpenWarningDialog(null);
					setFormData(openWarningDialog.originalData);
				}}
				onAccept={() => {
					ignoreWarnings.current = true;
					setOpenWarningDialog(null);
					submitData(openWarningDialog.updatedData, openWarningDialog.originalData, openWarningDialog.enableUpdated);
				}}
			/>

			<SmarthopConfirmDialog
				open={updated && askForSendDriverNotification}
				title={`Would you like to notify driver ${driver?.label} (${driver?.metadata?.phone}) to upload BOL documents for this trip?`}
				handleClose={() => {
					submitData(formData, formData);
					setUpdated(false);
				}}
				handleAccept={() => {
					if (!processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier })) {
						setPaywallDialog({
							open: true,
							dataIds: {
								type: "driver_notification_restriction",
								data: {
									callback: submitData,
									callbackKey: "TRIP_VIEW",
									dataIdsInherited: { tripId: trip._id, carrierId: carrierId },
								},
								extraData: { dataSend: { ...formData, actions: { SEND_DRIVER_NOTIFICATION: true } } },
							},
						});
						return;
					}
					submitData({ ...formData, actions: { SEND_DRIVER_NOTIFICATION: true } }, formData);
					setUpdated(false);
				}}
				closeMsg="No"
				acceptMsg="Send Notification"
			/>

			<PaywallDialog
				data={paywallDialog}
				onHandlerPaywall={() => {
					setUpdated(false);
					setPaywallDialog({});
				}}
				onHandlerClosePaywall={() => setPaywallDialog({})}
			/>
		</div>
	);
}

export default TripStatusSelector;
