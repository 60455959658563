import { createEntityAdapter, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { global } from "app/services/requestUtil";

export const fetchMessages = createAsyncThunk("messages/list/fetch", async ({ userId, chatId, beforeUnix = 0 }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.get(`/api/messenger/users/${userId}/chats/${chatId}/messages?beforeUnix=${beforeUnix}`, { headers: headers });
	if (beforeUnix !== 0) {
		return {
			data: response.data.items,
			loadMore: response.data?.metadata?.hasMore,
		};
	}
	return {
		messages: response.data.items,
		loadMore: response.data?.metadata?.hasMore,
	};
});

export const fetchTruckMessagesPreview = createAsyncThunk(
	"messages/list/preview/fetch",
	async ({ userId, truckId, type, carrierId }) => {
		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		};

		const response = await axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.get(`/api/messenger/users/${userId}/trucks/${truckId}/messages`, {
				params: { type },
				headers: headers,
			});
		return response.data;
	}
);

const getMessageId = (message) => (message.localId ? message.user + "_" + message.localId : message._id);

const messagesAdapter = createEntityAdapter({
	selectId: (message) => getMessageId(message),
	// We need to make sure that message in "sending" state (_id is empty) are always in the bottom of the list
	sortComparer: (m1, m2) => (m1._id && !m2._id ? -1 : !m1._id && m2._id ? 1 : (m1.sentUnix ?? 0) - (m2.sentUnix ?? 0)),
});
const initialState = messagesAdapter.upsertMany(
	messagesAdapter.getInitialState({
		visible: false,
		initialized: false,
		loading: false,
		activeChat: null,
		notifications: [],
		errors: null,
	}),
	[]
);

export const { selectAll: selectMessages, selectById: selectMessagesById } = messagesAdapter.getSelectors(
	(state) => state.messenger.messages
);

const messageSlice = createSlice({
	name: "messages/list",
	initialState,
	reducers: {
		removeAllMessage: (state) => {
			state.activeChat = null;
			return messagesAdapter.removeAll(state);
		},
		removeMessage: (state, action) => messagesAdapter.removeOne(state, action.payload),
		addMessage: (state, action) => {
			if (action.payload.chat === state.activeChat?._id) {
				if (!state.visible && !action.payload?.metadata?.status?.silent) {
					state.notifications.push(action.payload);
				}
				if (action.payload?.metadata?.user?.first_name) {
					let { first_name, last_name } = action.payload?.metadata?.user;
					let { roleName } = action.payload?.metadata;
					let nameParticipant = first_name + " " + last_name + ` (${roleName})`;
					state.users = [nameParticipant];
				}
				return messagesAdapter.upsertOne(state, action.payload);
			} else if (action.payload?.metadata?.status?.incoming && !action.payload?.metadata?.status?.silent) {
				state.notifications.push(action.payload);
			}
		},
		updateMessage: (state, action) => {
			if (action.payload.chat === state.activeChat?._id) {
				let messageId = getMessageId(action.payload);
				if (state.ids.includes(messageId)) {
					return messagesAdapter.upsertOne(state, action.payload);
				}
			}
		},
		setActiveChat: (state, action) => {
			state.activeChat = action.payload;
			state.loading = !!action.payload;
			state.errors = null;
			if (!action.payload) {
				state.initialized = false;
				return messagesAdapter.removeAll(state);
			}
		},
		removeNotificaions: (state, action) => {
			state.notifications = state.notifications.filter((message) => !action.payload.includes(message._id));
		},
		setVisible: (state, action) => {
			state.visible = action.payload;
		},
		showError: (state, action) => {
			state.errors = [{ type: "generic", message: action.payload }];
		},
		showLoadingMessage: (state, action) => {
			state.loading = true;
			state.errors = null;
		},
		updateActiveChatMute: (state, action) => {
			state.activeChat.mute = action.payload;
		},
	},
	extraReducers: {
		[fetchTruckMessagesPreview.fulfilled]: (state, action) => {
			state.activeChat = action.payload.chat;
			state.initialized = true;
			state.loading = false;
			state.errors = null;

			// In case if called from react native, calling webhook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "MESSAGES_FETCH_FULFILLED",
					data: { chatId: action?.payload?.chat?._id },
				})
			);

			return messagesAdapter.setAll(state, action.payload.items);
		},
		[fetchTruckMessagesPreview.rejected]: (state, action) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to load messages..." }];

			// In case if called from react native, calling webhook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "MESSAGES_FETCH_REJECTED",
					data: { chatId: action?.payload?.chat?._id },
				})
			);

			return messagesAdapter.setAll(state, []);
		},
		[fetchMessages.fulfilled]: (state, action) => {
			state.initialized = true;
			state.loading = false;
			state.errors = null;
			state.loadMore = action.payload.loadMore;
			state.users = action.payload.users;
			const moreMessage = action.payload.data;
			if (moreMessage) {
				return messagesAdapter.upsertMany(state, moreMessage);
			}

			// In case if called from react native, calling web hook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "MESSAGES_FETCH_FULFILLED",
					data: { chatId: action?.payload?.chat?._id },
				})
			);

			return messagesAdapter.setAll(state, action.payload.messages);
		},
		[fetchMessages.rejected]: (state, action) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to load messages..." }];

			// In case if called from react native, calling web hook
			window?.ReactNativeWebView?.postMessage?.(
				JSON.stringify({
					type: "EVENT",
					event: "MESSAGES_FETCH_REJECTED",
					data: { chatId: action?.payload?.chat?._id },
				})
			);

			return messagesAdapter.setAll(state, []);
		},
	},
});

export const {
	removeMessage,
	addMessage,
	updateMessage,
	removeAllMessage,
	setActiveChat,
	removeNotificaions,
	setVisible,
	showError,
	showLoadingMessage,
	updateActiveChatMute,
} = messageSlice.actions;
export default messageSlice.reducer;
