import React from "react";
import { Typography, Divider, Button } from "@material-ui/core";
import { formatLabel, formatTitle } from "./utils";
import { useHistory } from "react-router-dom";

function AssignedTrucksContainer(props) {
	const history = useHistory();

	const { data } = props;

	const truck = data?.[0];

	return (
		<div>
			<div className="pb-16"> {formatTitle("Assigned Truck", false)} </div>
			<Divider className="w-full bg-grey-300 mt-10" />
			{!data?.length ? (
				<div className="w-full absolute top-1/2 left-0">
					<Typography className="text-13 text-center" color="textSecondary">
						No assigned Truck
					</Typography>
				</div>
			) : (
				<>
					{formatLabel("Truck Id", truck?.truckid ?? "-")}
					<Divider className="w-full bg-grey-300" />
					{formatLabel("Weight", truck?.truck_trailer__view?.metadata?.max_weight ?? "-")}
					<Divider className="w-full bg-grey-300" />
					{formatLabel("Equipment", truck?.equipment)}
					{data?.length > 1 && (
						<div className="flex flex-row-reverse mt-10">
							<Button variant={"outlined"} color="secondary" onClick={() => history.push("/dispatching?tab=1")}>
								See More
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default AssignedTrucksContainer;
