import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@material-ui/core/ListItemText";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

function ChatListChannelItem(props) {
	const title = props.title;
	const type = props.type;
	const unread = props.unread;
	const onClick = props.onClick;
	const channelId = props.channelId;
	const channelIsOpen = props.channelIsOpen;

	return (
		<ListItem
			key={channelId}
			className={"px-0 py-0" + (type === "CARRIER" ? " bg-orange-50 border-b-1 border-t-4  " : "")}
		>
			<ListItemButton classes={{ root: " h-48 " }} onClick={() => onClick(channelId)} dense={true}>
				<>
					{type === "CARRIER" ? <Icon className={"text-20 mr-10 text-bold text-gray-700"}>{"business"}</Icon> : ""}
					<ListItemText
						primary={title}
						classes={{ primary: `text-13 whitespace-nowrap truncate pr-20 ${unread ? "font-bold" : "font-medium"}` }}
					/>
					{unread && (
						<div className="flex items-center justify-center w-6 h-6 md:h-8 md:w-8 rounded-full font-medium text-center bg-red-400"></div>
					)}
					{!channelIsOpen && <KeyboardArrowDown fontSize="large" />}
					{channelIsOpen && <KeyboardArrowRight fontSize="large" />}
				</>
			</ListItemButton>
		</ListItem>
	);
}

export default ChatListChannelItem;
