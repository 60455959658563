const formBoard = (disabled, accessType) => ({
	items: [
		{
			type: "group",
			content: {
				items: [
					{
						key: "board",
						type: "autocomplete",
						label: "Dashboard",
						disabled: disabled,
						field: { noErrorMessage: true },
						autocomplete: {
							provider: "smarthop",
							listenEvent: "analyticsJobsRevision",
							url: "api/analytics/dashboards",
							preloadDefault: true,
						},
					},
					...(accessType === "ACCOUNT"
						? [
								{
									key: "account",
									type: "autocomplete",
									label: "Account",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/accounts",
										params: {
											options_active_only: true,
										},
									},
								},
								{
									key: "profile",
									type: "autocomplete",
									label: "Profile",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/profiles",
										preloadDefault: true,
									},
									rules: {
										params: {
											valueRef: "account",
											paramKey: "options_account_id_search",
										},
									},
								},
						  ]
						: accessType === "CARRIER"
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
									},
								},
								{
									key: "user",
									type: "autocomplete",
									label: "User",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/users",
										preloadDefault: true,
									},
									rules: {
										params: {
											valueRef: "carrier",
											paramKey: "options_carrier_id_search",
										},
									},
								},
						  ]
						: accessType === "USER" || accessType === "USER_CREATOR"
						? [
								{
									key: "user",
									type: "autocomplete",
									label: "User",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/users",
										params: {},
									},
								},
						  ]
						: accessType === "TRUCK"
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
									},
								},
								{
									key: "truck",
									type: "autocomplete",
									label: "Truck",
									translate: "",
									field: { noErrorMessage: true },
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/trucks",
										params: {
											options_active_only: true,
											options_format_show_driver_phone: true,
										},
										preloadDefault: true,
										preloadImmedately: true,
										listenEvent: "profileRevision",
									},
									rules: {
										params: {
											valueRef: "carrier",
											paramKey: "options_carrier_id_search",
										},
									},
								},
						  ]
						: [{ type: "stub" }]),
					{ type: "stub" },
				],
			},
		},
	],
});

const formTab = () => ({
	items: [
		{
			key: "label",
			type: "text",
			label: "Label",
			field: { noErrorMessage: true },
		},
		{
			key: "windowType",
			type: "select",
			label: "Window Override",
			defaultValue: "__NOT_SELECTED__",
			field: { noErrorMessage: true },
			options: [
				{ value: "__NOT_SELECTED__", label: "Disabled" },
				{ value: "MONTH", label: "Enable 12 Months Override" },
				{ value: "MONTH_3_LAST", label: "Enable Last 3 Months Override" },
				{ value: "MONTH_RECENT", label: "Enable Week + 12 Months Override " },
			],
		},
		{
			key: "horizontalCellsCount",
			type: "select",
			label: "Large Screen | Horizontal Cells Count",
			defaultValue: "__NOT_SELECTED__",
			field: { noErrorMessage: true },
			options: [
				{ value: "__NOT_SELECTED__", label: "Default" },
				{ value: 6, label: "6 Cells" },
				{ value: 8, label: "8 Cells" },
				{ value: 10, label: "10 Cells" },
			],
		},
		{
			key: "verticalCellsHight",
			type: "select",
			label: "Large Sreen | Vertical Cells Hight",
			defaultValue: "__NOT_SELECTED__",
			field: { noErrorMessage: true },
			options: [
				{ value: "__NOT_SELECTED__", label: "Default" },
				{ value: 100, label: "100px" },
				{ value: 120, label: "120px" },
				{ value: 150, label: "150px" },
				{ value: 180, label: "180px" },
				{ value: 200, label: "200px" },
			],
		},
		{
			key: "gatekeeper",
			type: "text",
			label: "Gatekeeper",
		},
		{
			key: "roles",
			type: "autocomplete",
			label: "Roles",
			autocomplete: {
				provider: "smarthop",
				url: "api/consts/roles",
				types: [],
				multiple: true,
				preloadDefault: true,
			},
		},
		{
			key: "showUpdateTime",
			type: "checkbox",
			label: "Show Update Time",
			checkbox: { noErrorMessage: true },
		},
		{
			key: "hideOnMobile",
			type: "checkbox",
			label: "Hidden on Mobile",
		},
		{
			type: "action",
			action: "SUBMIT",
			label: "Save",
		},
	],
});

export { formBoard, formTab };
