import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hover: {
    '&:active:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  }
}));

function ButtonShowMore(props) {
  const classes = useStyles();
  const { text, length } = props;

  const [showMore, setShowMore] = React.useState(false);

	return (
    <div>
      {showMore ? text : `${text.substring(0, length)}`}
      <Button className={"text-blue pl-10 " + classes.hover} size="small" variant="text" onClick={() => setShowMore(!showMore)}>
        {showMore ? "Read less" : "Read more"}
      </Button>
    </div>
	);
}

export default ButtonShowMore;
