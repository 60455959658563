// FIXME: That is not util file, that is filter config that should be part of trip table
// Consts
import Icon from "@material-ui/core/Icon";
import { labelComponent } from "app/main/utils/tableUtils";
import { RATE_OPTIONS } from "app/main/consts";
import { showSnackbar } from "./snackbarUtil";
import { geturl } from "app/services/tripsServices";

const msgCreatorType = {
	INTERNAL: {
		message: "Load created by an internal user (dispatcher, admin)",
		color: "bg-orange-400",
	},
	EXTERNAL: {
		message: "Load created by an external user (carrier, driver)",
		color: "bg-orange-700",
	},
};
const msgLoadSource = {
	SEARCH: {
		message: "Load created is sourced from the search",
		color: "bg-green-400",
	},
	EXTERNAL: {
		message: "Load created is sourced from external information",
		color: "bg-green-700",
	},
};
const msgBookLocation = {
	SEARCH: {
		message: "Load created from search",
		color: "bg-teal-200",
	},
	CHAT: {
		message: "Load created from chat",
		color: "bg-teal-500",
	},
	EXTERNAL: {
		message: "Load created from external information",
		color: "bg-teal-800",
	},
	EMAIL: {
		message: "Load created from an RC attached in an email that was sent to our platform",
		color: "bg-teal-900",
	},
};
const msgBookType = {
	DIGITAL: {
		message: "Load created from digital booking",
		color: "bg-cyan-400",
	},
	MANUAL: {
		message: "Load created from the process manual",
		color: "bg-cyan-700",
	},
};
const msgBookedPlatform = {
	SMARTHOP: {
		message: "Load was booked on Smarthop",
		color: "bg-blue-900",
	},
	EXTERNAL: {
		message: "Load was booked on external platform",
		color: "bg-blue-600",
	},
};

const checkStyle = {
	color: "text-green-400",
	hoverColor: "text-green-500",
	bgColor: "bg-green-500",
	icon: "check_circle",
};

const errorStyle = {
	color: "text-red-500",
	hoverColor: "text-red-600",
	bgColor: "bg-red-600",
	icon: "highlight_off",
};

const tripsFilterFields = (role = null) => {
	return [
		{
			key: "status",
			type: "select",
			label: "Trip Status",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{
					value: "Pending",
					label: "Pending (Not Booked)",
				},
				{
					value: "Booked",
					label: "Pending (Booked)",
				},
				{
					value: "__PENDING__",
					label: "Pending (All)",
				},
				{
					value: "Dispatched",
					label: "Active (Dispatched)",
				},
				{
					value: "On Transit to Pick Up",
					label: "Active (On Transit to Pick Up)",
				},
				{
					value: "At Pickup",
					label: "Active (At Pickup)",
				},
				{
					value: "On Transit to Delivery",
					label: "Active (On Transit to Delivery)",
				},
				{
					value: "At Delivery",
					label: "Active (At Delivery)",
				},
				{
					value: "__ACTIVE__",
					label: "Active (All)",
				},
				{
					value: "Delivered",
					label: "Delivered (Not Invoiced)",
				},
				{
					value: "Invoiced",
					label: "Delivered (Invoiced)",
				},
				{
					value: "Paid",
					label: "Delivered (Paid)",
				},
				{
					value: "__DELIVERED__",
					label: "Delivered (All)",
				},
				{
					value: "Cancelled",
					label: "Cancelled",
				},
				{
					value: "Bidded",
					label: "Deprecated (Bidded)",
				},
			],
		},
		{
			key: "carrier",
			type: "autocomplete",
			label: "Carrier",
			autocomplete: {
				provider: "smarthop",
				url: `api/profile/carriers`,
				preloadDefault: true,
				listenEvent: "profileRevision",
				params: {
					options_active_only: true,
					options_my_assigned: role === "administrator" || role === "ops support" ? false : true,
				},
			},
		},
		{
			key: "truck",
			type: "autocomplete",
			label: "Truck",
			autocomplete: {
				provider: "smarthop",
				preloadDefault: true,
				listenEvent: "profileRevision",
				url: `api/profile/trucks`,
				params: {
					options_metadata_truck: true,
					options_metadata_driver: true,
					options_active_only: true,
					label_format_show_driver: true,
					options_my_assigned: role === "administrator" || role === "ops support" ? false : true,
				},
			},
			rules: {
				params: {
					valueRef: "carrier",
					paramKey: "options_carrier_id_search",
				},
			},
		},
		{
			key: "createdAt",
			type: "select",
			label: "Visible Window",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Last 30 Days",
				},
				{
					value: "__DATE_DAYS_GT__90",
					label: "Last 90 Days",
				},
				{
					value: "__DATE_DAYS_GT__365",
					label: "Last 1 Year",
				},
				{
					value: "__DATE_DAYS_GT__3650",
					label: "Last 10 Years",
				},
			],
		},
		{
			key: "broker",
			type: "autocomplete",
			label: "Broker",
			autocomplete: {
				provider: "smarthop",
				url: "api/consts/brokers",
				preloadDefault: true,
			},
		},
		{
			key: "rate_con_file",
			type: "select",
			label: "Rate Confirmation",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{ value: true, label: "Has rate confirmation" },
				{ value: false, label: "Not rate confirmation" },
			],
		},
		{
			key: "role",
			type: "select",
			label: "Creator type",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{ value: 0, label: "Admin" },
				{ value: 1, label: "Dispatcher" },
				{ value: 2, label: "Carrier" },
				{ value: 21, label: "Driver" },
			],
		},
		// {
		// 	key: "start_pickup_date",
		// 	type: "picker",
		// 	label: "Start Pickup Date",
		// 	field: {
		// 		classes: {},
		// 		variant: undefined,
		// 	},
		// 	picker: {
		// 		format: "MMMM do yyyy",
		// 		disableFuture: false,
		// 		notClearable: false,
		// 		type: "date",
		// 		classes: {},
		// 	},
		// },
		// {
		// 	key: "end_pickup_date",
		// 	type: "picker",
		// 	label: "End Pickup Date",
		// 	field: {
		// 		classes: {},
		// 		variant: undefined,
		// 	},
		// 	picker: {
		// 		format: "MMMM do yyyy",
		// 		disableFuture: false,
		// 		notClearable: false,
		// 		type: "date",
		// 		classes: {},
		// 	},
		// },
		// {
		// 	key: "start_delivery_date",
		// 	type: "picker",
		// 	label: "Start Delivery Date",
		// 	field: {
		// 		classes: {},
		// 		variant: undefined,
		// 	},
		// 	picker: {
		// 		format: "MMMM do yyyy",
		// 		disableFuture: false,
		// 		notClearable: false,
		// 		type: "date",
		// 		classes: {},
		// 	},
		// },
		// {
		// 	key: "end_delivery_date",
		// 	type: "picker",
		// 	label: "End Delivery Date",
		// 	field: {
		// 		classes: {},
		// 		variant: undefined,
		// 	},
		// 	picker: {
		// 		format: "MMMM do yyyy",
		// 		disableFuture: false,
		// 		notClearable: false,
		// 		type: "date",
		// 		classes: {},
		// 	},
		// },
		{
			key: "external",
			type: "select",
			label: "Creation type",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{ value: true, label: "Created manually" },
				{ value: false, label: "Created by request" },
			],
		},
		{
			key: "booked_now",
			type: "select",
			label: "Book now",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not Selected",
				},
				{ value: true, label: "Added by booked now" },
				{ value: false, label: "Not booked now" },
			],
		},
	];
};

const tripsReportsFilterFields = () => {
	return [
		{
			key: "start_pickup_date",
			type: "picker",
			label: "Start Pickup Date",
			field: {
				classes: {},
				variant: undefined,
			},
			picker: {
				format: "MMMM do yyyy",
				disableFuture: false,
				notClearable: false,
				type: "date",
				classes: {},
			},
		},
		{
			key: "end_pickup_date",
			type: "picker",
			label: "End Pickup Date",
			field: {
				classes: {},
				variant: undefined,
			},
			picker: {
				format: "MMMM do yyyy",
				disableFuture: false,
				notClearable: false,
				type: "date",
				classes: {},
			},
		},
		{
			key: "start_delivery_date",
			type: "picker",
			label: "Start Delivery Date",
			field: {
				classes: {},
				variant: undefined,
			},
			picker: {
				format: "MMMM do yyyy",
				disableFuture: false,
				notClearable: false,
				type: "date",
				classes: {},
			},
		},
		{
			key: "end_delivery_date",
			type: "picker",
			label: "End Delivery Date",
			field: {
				classes: {},
				variant: undefined,
			},
			picker: {
				format: "MMMM do yyyy",
				disableFuture: false,
				notClearable: false,
				type: "date",
				classes: {},
			},
		},
	];
};

const STATUS_TRIPS = {
	active: ["Dispatched", "On Transit to Pick Up", "At Pickup", "Picked Up", "On Transit to Delivery", "At Delivery"],
	delivered: ["Delivered (not verified)", "Delivered", "Invoiced", "Paid"],
	pending: ["Pending", "Not Booked", "Booked", "Bidded"],
	cancelled: ["Canceled", "Cancelled"],
};

// Function that return the next status when external user (carrier/driver)
// update the trip status
const getNextStatusExternal = (item) => {
	const hasStops = item?.locations?.length;
	const hasMoreDeliveryLocations = hasMoreLocations("DELIVERY", item);
	if (item?.status === "Picked Up") item.status = "At Pickup";
	switch (item?.status) {
		case "Pending":
		case "Booked":
		case "Not Booked":
		case "Bidded":
			return {
				value: "Dispatched",
				label: "Dispatched",
			};
		case "Dispatched":
			if (hasStops) return getLocation(item, "PICKUP", "On Transit to Pick Up");
			return {
				value: "On Transit to Pick Up",
				label: "On Transit to Pick Up",
			};
		case "On Transit to Pick Up":
			if (hasStops) return getLocation(item, "PICKUP", "At Pickup");
			return {
				value: "At Pickup",
				label: "At Pickup",
			};
		case "At Pickup":
			if (hasStops) {
				const hasMorePickupsLocations = hasMoreLocations("PICKUP", item);
				if (hasMorePickupsLocations) return getLocation(item, "PICKUP", "On Transit to Pick Up");
				if (hasMoreDeliveryLocations) return getLocation(item, "DELIVERY", "On Transit to Delivery");
				if (!hasMoreDeliveryLocations) return getLocation(item, "DELIVERY", "On Transit to Delivery", true);
			}
			return {
				value: "On Transit to Delivery",
				label: "On Transit to Delivery",
			};
		case "On Transit to Delivery":
			if (hasStops) {
				if (hasMoreDeliveryLocations) {
					return getLocation(item, "DELIVERY", "At Delivery");
				}
				return getLocation(item, "DELIVERY", "At Delivery", true);
			}
			return {
				value: "At Delivery",
				label: "At Delivery",
			};
		case "At Delivery":
			if (hasStops && hasMoreDeliveryLocations) return getLocation(item, "DELIVERY", "On Transit to Delivery");
			if (hasStops && !hasMoreDeliveryLocations && item?.next_stop?.trim() !== item?.delivery_address?.trim())
				return getLocation(item, "DELIVERY", "On Transit to Delivery", true);

			return {
				value: "Delivered",
				label: "Delivered",
			};
		default:
			return undefined;
	}
};

// Status list with labels to external users
const STATUS_TRIP_EXTERNAL = [
	{
		value: "Pending",
		label: "Pending Ratecon",
	},
	{
		value: "Booked",
		label: "Booked",
	},
	{
		value: "Bidded",
		label: "Bidded",
	},
	{
		value: "Dispatched",
		label: "Dispatched",
	},
	{
		value: "On Transit to Pick Up",
		label: "On Transit to Pick Up",
	},
	{
		value: "At Pickup",
		label: "At Pickup",
	},
	{
		value: "On Transit to Delivery",
		label: "On Transit to Delivery",
	},
	{
		value: "At Delivery",
		label: "At Delivery",
	},
	{
		value: "Delivered",
		label: "Delivered",
	},
	{
		value: "Invoiced",
		label: "Invoiced",
	},
	{
		value: "Paid",
		label: "Paid",
	},
	{
		value: "Cancelled",
		label: "Cancelled",
	},
	{
		value: "Canceled",
		label: "Canceled",
	},
];

const getStatusConfig = (status, isCarrier) => {
	let label = "Unknown";
	let category = null;
	let icon = "help_outline";
	let color = "text-black";

	if (STATUS_TRIPS.active.includes(status)) {
		category = "Active";
		label = status;
		icon = "play_circle_outline";
		color = "text-secondary";
	} else if (STATUS_TRIPS.pending.includes(status)) {
		category = "Pending";
		label =
			status === STATUS_TRIPS.pending[0] && isCarrier
				? "Pending Ratecon"
				: status === STATUS_TRIPS.pending[0]
				? "Not Booked"
				: status;
		icon = "schedule";
		color = "text-grey-500";
	} else if (STATUS_TRIPS.delivered.includes(status)) {
		category = "Delivered";
		label = status;
		icon = "check_circle";
		color = "text-primary";
	} else if (STATUS_TRIPS.cancelled.includes(status)) {
		label = `Cancelled`;
		icon = "cancel";
		color = "text-red-700";
	} else if (status === "Missing Data") {
		label = `Missing Data`;
		icon = "cancel";
		color = "text-red-700";
	}

	return {
		category,
		label,
		icon,
		color,
	};
};

const getAddressCity = (address) => {
	if (!address?.replace) return;

	const parts = address.replace(", USA", "").split(", ");
	if (parts.length < 2) {
		if (address.length > 20) {
			return "..." + address.substring(address.length - 17, address.length);
		} else {
			return address;
		}
	}

	const state = parts[parts.length - 1].trim().split(" ");
	let result;
	if (state.length > 1) {
		result = parts[parts.length - 1];
		if (result.length > 16) {
			result = "..." + result.substring(result.length - 13, result.length);
		}
	} else {
		let city = parts[parts.length - 2];
		let state = parts[parts.length - 1];
		if (city.length > 16 && parts.length === 2) {
			city = "..." + city.substring(city.length - 13, city.length);
		}
		result = city + ", " + state;
	}

	return result;
};

const hideRate = (option) => {
	if (option === RATE_OPTIONS[0]) return false;
	return true;
};

const checkPodVerified = (files) => {
	if (!files) return "No files";
	let filesPod = files?.filter((file) => file.type === "pod" || file.type === "pop");
	let filesVerfied = filesPod?.filter((file) => file?.status === "verified");
	return `${filesVerfied?.length}/${filesPod?.length}`;
};

const checkRC = (data) => {
	let style;
	style = errorStyle;
	if (data?.data?.rate_con_file__flag || data?.rate_con_file__flag) style = checkStyle;
	return <Icon className={`${style.color} hover:${style.hoverColor}`}>{style.icon}</Icon>;
};

const checkformat = (item) => {
	let style;
	style = errorStyle;
	if (item === "Yes") style = checkStyle;
	return <Icon className={`${style.color} hover:${style.hoverColor}`}>{style.icon}</Icon>;
};

const createLocationView = (location) => ({
	value: location,
	label: location,
});

const metadataInfo = (key, data) => {
	const info = data[key]?.toUpperCase();

	let metadata;
	if (key === "metadata_creator_type") {
		metadata = msgCreatorType[info];
	} else if (key === "metadata_load_source") {
		metadata = msgLoadSource[info];
	} else if (key === "metadata_book_location") {
		metadata = msgBookLocation[info];
	} else if (key === "metadata_book_type") {
		metadata = msgBookType[info];
	} else if (key === "metadata_platform") {
		let platformInfo = info === "SMARTHOP" ? "SMARTHOP" : "EXTERNAL";
		metadata = msgBookedPlatform[platformInfo];
	}

	if (info === "NONE") {
		metadata = { message: "We don't have information", color: "bg-gray-400" };
	}

	return metadata
		? labelComponent({ bgColorLabel: metadata?.color, labelText: info, messageTooltip: metadata?.message })
		: null;
};

const locationLatLong = (data) => {
	let locationStop = data?.locations?.map((location) => ({
		location: location.location,
		lat: location.lat ?? location?.location__view?.metadata?.coords?.lat,
		lng: location.long ?? location?.location__view?.metadata?.coords?.long,
	}));
	let arrayLatLong = [
		_getLatLong(data?.truckOriginObj),
		_getLatLong(data?.city, data?.origin),
		...(locationStop?.length ? locationStop : []),
		_getLatLong(data?.ecity, data?.destination),
	];
	return arrayLatLong;
};

const _getLatLong = (data, location = null) => {
	return {
		location: location ?? data?.location,
		lat: data?.lat,
		lng: data?.long,
	};
};

const onClickCreateUrl = async ({ carrierId, tripId, form, snackbar, option = null }) => {
	try {
		let data = {};
		if (form === "TRIP_VIEW") {
			data = {
				viewId: form,
				carrierId: carrierId,
				tripId: tripId,
				hideRate: option,
			};
		} else {
			data = {
				formId: form,
				carrierId: carrierId,
				tripId: tripId,
			};
		}
		geturl(data).then(
			(res) => {
				if (res) {
					if (window?.ReactNativeWebView) {
						// TODO: [PATRICIO] Switch type to COPY_TO_CLIPBOARD here and hopcopilot
						window?.ReactNativeWebView?.postMessage(
							JSON.stringify({
								type: "COPY_SMS",
								data: {
									message: res.url,
								},
							})
						);
						showSnackbar(snackbar, "Copied", "success");
					} else {
						navigator.clipboard
							.writeText(res.url)
							.then(() => {
								showSnackbar(snackbar, "Copied url", "success");
							})
							.catch(() => {
								console.log("Error copied the url");
							});
					}
				}
			},
			(error) => {
				showSnackbar(snackbar, error.errors?.[0]?.message ?? "Failed to generate url", "error");
			}
		);
	} catch (error) {
		showSnackbar(snackbar, error.errors?.[0]?.message ?? "Opps, operation failed...", "error");
	}
};

const getLocation = (item, type, status, lastLocation = false) => {
	if (type === "PICKUP") {
		if (!item.pickupComplete) {
			return {
				value: status + "-" + item.pickup_address,
				label: status + " Stop # 2",
				description: item.pickup_address,
			};
		}
	}

	if (type === "DELIVERY" && lastLocation) {
		return {
			value: status + "-" + item.delivery_address,
			label: status + ` Stop # ${item?.all_locations?.length}`,
			description: item.delivery_address,
		};
	}

	const locations = item?.locations?.filter((location) => location.type === type && !location.complete);
	const numberStop = item?.locations.findIndex(({ location }) => location === locations?.[0]?.location);
	return {
		value: status + "-" + locations?.[0]?.location,
		label: status + ` Stop # ${numberStop + 3}`,
		description: locations?.[0]?.location,
	};
};

const hasMoreLocations = (type, item) => {
	const locations = item?.locations?.filter((location) => location.type === type && !location.complete);

	return locations?.length > 0;
};

const getLocationsUpdate = (locations, address, status, deliveryAddress) => {
	if (
		["At Pickup", "At Delivery", "On Transit to Delivery", "On Transit to Pick Up"].includes(status) &&
		address?.trim() !== deliveryAddress?.trim()
	) {
		let numberStop = locations?.findIndex((location) => location.location === address);
		if (numberStop === -1) return locations;
		return locations?.map((location, index) => {
			let completeValue = false;
			const condition = ["On Transit to Delivery", "On Transit to Pick Up"].includes(status)
				? index < numberStop
				: index <= numberStop;
			if (condition) completeValue = true;
			return { ...location, complete: completeValue };
		});
	}
	//if is the last location or if is delivery every locations must be complete
	if (["Delivered", "At Delivery", "On Transit to Delivery"].includes(status)) {
		return locations.map((location) => {
			return { ...location, complete: true };
		});
	}
	return locations.map((location) => {
		return { ...location, complete: false };
	});
};

const MISSING_DATA = {
	MISSING_PICKUP_DATE: "Pickup Date",
	MISSING_PICKUP_ADDRESS: "Pickup Address",
	MISSING_DELIVERY_DATE: "Delivery Date",
	MISSING_DELIVERY_ADDRESS: "Delivery Address",
	MISSING_RATE: "Rate",
	MISSING_TRUCK: "Truck",
	MISSING_CARRIER_NOT_VALID: "Carrier",
	MISSING_LOADID_NOT_VALID: "Load Id",
	MISSING_EQUIPMENT: "Equipment",
};

const getWarningMsg = (warningCode) => {
	return MISSING_DATA[warningCode] ?? false;
};

export {
	tripsFilterFields,
	tripsReportsFilterFields,
	getStatusConfig,
	getAddressCity,
	hideRate,
	checkPodVerified,
	checkRC,
	checkformat,
	createLocationView,
	metadataInfo,
	locationLatLong,
	getNextStatusExternal,
	STATUS_TRIPS,
	STATUS_TRIP_EXTERNAL,
	onClickCreateUrl,
	getLocationsUpdate,
	MISSING_DATA,
	getWarningMsg,
};
