const carrierTruckOnboardingForm = () => {
	return {
		items: [
			{
				key: "truckid",
				type: "text",
				label: "Truck ID",
				required: true,
				preventCharacter: true,
				minCharacter: 1,
				maxCharacter: 15,
			},
			{
				key: "cab_card",
				type: "upload",
				label: "Truck CAB Card",
				upload: { url: "api/profile/carriers/:carrierId/files", documentType: "driver", fileName: "cab_card" },
			},
			{
				key: "truck_registration",
				type: "upload",
				label: "Truck Registration",
				upload: {
					url: "api/profile/carriers/:carrierId/files",
					documentType: "driver",
					fileName: "truck_registration",
				},
			},
			{ type: "action", action: "SUBMIT", label: "Save" },
		],
	};
};

export { carrierTruckOnboardingForm };
