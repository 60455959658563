import { useEffect } from "react";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import tripInvoicingForm from "@smarthop/form/configs/tripInvoicingForm";

const TripInvoicingHistoryView = (props) => {
	const dataIds = props?.dataIds;
	const historyData = dataIds?.historyData;

	useEffect(() => {
		props?.setTitle?.("Trip Invoice History View");
		props.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	return (
		<SmarthopFormView
			key={"invoice_history"}
			mode={"VIEW"}
			content={tripInvoicingForm.content.view}
			data={historyData}
			dataIds={dataIds}
			differentObjectHistory={{}}
			differentObjectType={"AFTER"}
		/>
	);
};

export default TripInvoicingHistoryView;
