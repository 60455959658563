import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";

import WidgetsBoard from "app/main/widgets/WidgetsBoard";
import AccountCarrierSelect from "app/main/common/AccountCarrierSelect";

// Services
import { getCarrierId, isRoleHasFullCarrierAccess, isRoleExternal } from "app/services/LoginService";

const AnalitycsView = (props) => {
	const isMainHandler = props.isMainHandler;

	const user = useSelector(({ auth }) => auth.user);
	const isCarrierDispatcher = user.role === "CARRIER_DISPATCHER";
	const isInvestor = user.gatekeepers?.permission_investor_on;

	const subaccounts = useSelector(({ auth }) => auth.account.subAccount);

	const [selectedCarrier, setSelectedCarrier] = useState(
		subaccounts?.list?.length ? localStorage.getItem("lastSubcarrierSelected") ?? null : null
	);
	const [selectedTruck, setSelectedTruck] = useState(null);

	const hasFullCarrierAccess = isRoleHasFullCarrierAccess() || !isRoleExternal();
	const carrierId = useMemo(
		() =>
			selectedCarrier && selectedCarrier !== "__ALL__" && subaccounts?.list?.length
				? selectedCarrier
				: props?.carrierId
				? props?.carrierId
				: getCarrierId(),
		[props, selectedCarrier, subaccounts]
	);
	const boardKey = useMemo(() => {
		if (isCarrierDispatcher && (selectedCarrier === "__ALL__" || !isMainHandler)) {
			return "DISPATCHER_PROFIT";
		} else if (isCarrierDispatcher && selectedCarrier !== "__ALL__" && isMainHandler) {
			return "CARRIER_PROFIT_V3";
		} else if (isInvestor) {
			return "INVESTOR_PROFIT";
		} else if (selectedCarrier === "__ALL__" && isMainHandler) {
			return "ACCOUNT_HOME";
		} else if (hasFullCarrierAccess) {
			return "CARRIER_PROFIT_V3";
		} else {
			return "USER_PROFIT_READONLY";
		}
	}, [isCarrierDispatcher, selectedCarrier, isMainHandler, hasFullCarrierAccess, isInvestor]);

	return (
		<>
			{isMainHandler && (
				<div className="md:absolute md:-top-40 md:right-0 pr-16">
					<AccountCarrierSelect classes={{ select: "py-10" }} onChange={(val) => setSelectedCarrier(val)} />
				</div>
			)}
			{isInvestor && (
				<div className="md:absolute md:-top-48 md:right-0" style={{ minWidth: "250px" }}>
					<SmarthopFormView
						key={"investorselectruck"}
						dataIds={{ carrierId, userId: user._id }}
						content={{
							items: [
								{
									key: "truck",
									type: "autocomplete",
									label: "Truck",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers/:carrierId/investor/:userId/trucks",
										preloadDefault: true,
										params: { options_active_only: true },
									},
								},
							],
						}}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model, key) => {
							setSelectedTruck(model[key]);
						}}
						data={{}}
						mode={"EDIT"}
					/>
				</div>
			)}
			<WidgetsBoard
				boardKey={boardKey}
				margin={[20, 20]}
				isView={props?.isView}
				userId={(isCarrierDispatcher && boardKey === "DISPATCHER_PROFIT") || isInvestor ? user._id : null}
				carrierId={isMainHandler ? selectedCarrier : carrierId}
				accountId={isMainHandler && selectedCarrier === "__ALL__" ? user.account : null}
				truckId={isInvestor ? selectedTruck : null}
			/>
		</>
	);
};

export default AnalitycsView;
