import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const createGoalsCarrier = (carrierId, data) => {
	const url = `api/goals/carriers/${carrierId}`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createGoals = (data) => {
	return createRequest()
		.post("api/goals/create", { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const setGoalAsDefault = (goaldId, data) => {
	const url = `api/goals/${goaldId}/default`;
	return createRequest()
		.put(url, data, { header: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTruckGoals = (carrierId, truckId) => {
	const url = `api/goals/carriers/${carrierId}/trucks/${truckId}/overview`;
	return createRequest()
		.get(url, { header: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
