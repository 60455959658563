const HISTORY_VIEWS = [
	"TRIP_EDIT_VIEW",
	"TRUCK_VIEW",
	"TRUCK_PLAN",
	"PAYROLL_VIEW",
	"TRIP_VIEW",
	"TRUCK_PLAN_VIEW",
	"TRUCK_PLAN_FORM",
	"PAYROLL_RULES_VIEW",
	"TRIP_INVOICE_VIEW",
	"TRAILER_FORM",
	"TRAILER_VIEW",
	"CARRIER_USER_EDIT_VIEW",
	"FUEL_TRANSACTION_VIEW",
	"BROKER_EDIT_VIEW",
];

export { HISTORY_VIEWS };
