import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { formatCurrency } from "app/main/utils/tableUtils";
import { useContext } from "react";
import { StrategyActionContext, StrategyStateContext } from "../../providers/StrategyContextProvider";
import { addToCart, removeFromCart } from "../../providers/reducer/actions";
import { useMediaQuery, useTheme } from "@material-ui/core";

const getInitVals = (keys, intiVal = 0) => keys.reduce((acc, key) => ({ ...acc, [key]: intiVal }), {});

const getRange = (value, formatter) => {
	const [min, max] = value;
	return `${formatter ? formatter(min) : min} - ${formatter ? formatter(max) : max}`;
};

const getTotalCumulatives = (selectedMoves) => {
	const totalCumulativeKeys = ["loaded_miles", "empty_miles", "profit", "duration", "min_payment", "max_payment"];
	return selectedMoves?.reduce((total, move, i, arr) => {
		totalCumulativeKeys.forEach((key) => {
			if (arr.length === 1) {
				total[key] = 0;
			} else if (i) {
				// First item skipped
				total[key] += move.data.stop[key];
			}
		});
		return total;
	}, getInitVals(totalCumulativeKeys));
};

const getTotalAverages = (selectedMoves) => {
	const totalAverageKeys = ["min_rpm", "max_rpm", "avg_volume"];

	return selectedMoves?.reduce((total, move, i, arr) => {
		totalAverageKeys.forEach((key) => {
			if (arr.length === 1) {
				total[key] = 0;
			} else if (i) {
				// First item skipped
				total[key] += move.data.stop[key] / (arr.length - 1);
			}
		});
		return total;
	}, getInitVals(totalAverageKeys));
};

const CartItem = ({ label, value, formatter }) => {
	const theme = useTheme();
	const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
	return (
		<Box style={{ display: "flex", justifyContent: "space-between" }}>
			<Typography variant={largeScreen ? "subtitle1" : "subtitle2"}>{label}</Typography>
			<Typography variant={largeScreen ? "subtitle1" : "subtitle2"}>{`${
				formatter ? formatter(value || 0, 0) : value
			}`}</Typography>
		</Box>
	);
};
const AddToCartButton = ({ selectedMoves, handler }) => (
	<Button
		color="secondary"
		variant="contained"
		disabled={selectedMoves.length <= 1}
		onClick={handler}
		style={{ margin: "5px 0", alignSelf: "center", padding: "5px 10px" }}
	>
		Approve Path
	</Button>
);
const UpdateButton = ({ updated, selectedMoves, handler }) => (
	<Button
		color="secondary"
		variant="contained"
		disabled={!updated || selectedMoves.length <= 1}
		onClick={handler}
		style={{ margin: "5px 0", alignSelf: "center", padding: "5px 10px" }}
	>
		Update
	</Button>
);
const RemoveButton = ({ handler }) => (
	<Button
		color="primary"
		variant="contained"
		onClick={handler}
		style={{ margin: "5px 0", alignSelf: "center", padding: "5px 10px" }}
	>
		Remove
	</Button>
);

const TotalCard = ({ title, selectedMoves, allMoves, isSaved }) => {
	const { selectedPaths } = useContext(StrategyStateContext);
	const dispatch = useContext(StrategyActionContext);

	const theme = useTheme();
	const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

	const totalsCummulative = getTotalCumulatives(selectedMoves);
	const totalsAverages = getTotalAverages(selectedMoves);

	const totalValues = {
		...totalsCummulative,
		...totalsAverages,
		rpm: totalsCummulative.payment / (totalsCummulative.loaded_miles + totalsCummulative.empty_miles),
		max_rpm: totalsCummulative.max_payment / (totalsCummulative.loaded_miles + totalsCummulative.empty_miles),
		min_rpm: totalsCummulative.min_payment / (totalsCummulative.loaded_miles + totalsCummulative.empty_miles),
		duration: Math.ceil((totalsCummulative.loaded_miles + totalsCummulative.empty_miles) / 500),
	};

	// Access pathId located in last node
	const pathId = allMoves?.[allMoves.length - 1]?.data?.stop?.pathId;
	const inCart = !!selectedPaths[pathId];
	const updated = inCart && selectedPaths[pathId].length !== selectedMoves.length;

	const handleAddToCart = (e) => {
		e.stopPropagation();
		dispatch(addToCart(selectedMoves, pathId));
	};

	const handleRemoveFromCart = (e) => {
		e.stopPropagation();
		dispatch(removeFromCart(pathId));
	};

	return (
		<Box
			sx={{
				minWidth: largeScreen ? 270 : 150,
				marginLeft: "auto",
			}}
		>
			<Card
				variant="outlined"
				style={{
					transition: "filter 500ms linear",
					boxSizing: "border-box",
				}}
			>
				<CardContent style={{ paddingBottom: "10px" }}>
					<Typography variant={largeScreen ? "h5" : "subtitle1"} component="div" sx={{ fontWeight: 400 }}>
						{title}
					</Typography>
					<Typography variant={"subtitle2"} component="div" sx={{ fontWeight: 100 }}>
						{`${!largeScreen ? " Averages" : ""}`}
					</Typography>
					<Box style={{ marginTop: "0.5rem", display: "flex", flexDirection: "column", fontSize: "0.5rem" }}>
						<CartItem
							label={largeScreen ? "Avg RPM" : "RPM"}
							value={
								largeScreen
									? getRange([totalValues["min_rpm"], totalValues["max_rpm"]], formatCurrency)
									: formatCurrency((totalValues["max_rpm"] + totalValues["min_rpm"]) / 2)
							}
						/>
						<CartItem
							label={"Payment"}
							value={
								largeScreen
									? getRange([totalValues["min_payment"], totalValues["max_payment"]], (payment) =>
											formatCurrency(payment, 0)
									  )
									: formatCurrency((totalValues["min_payment"] + totalValues["max_payment"]) / 2, 0)
							}
						/>
						<CartItem
							label={largeScreen ? "Loaded Miles" : "Loaded"}
							value={`${totalValues["loaded_miles"] || 0} mi`}
						/>
						<CartItem label={largeScreen ? "Empty Miles" : "Empty"} value={`${totalValues["empty_miles"] || 0} mi`} />
						<CartItem label={"Duration"} value={`${totalValues["duration"] || "-"} days`} />
						{!isSaved &&
							(inCart ? (
								<Box style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
									<UpdateButton updated={updated} selectedMoves={selectedMoves} handler={handleAddToCart} />
									<RemoveButton handler={handleRemoveFromCart} />
								</Box>
							) : (
								<AddToCartButton selectedMoves={selectedMoves} handler={handleAddToCart} />
							))}
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
};

export default TotalCard;
