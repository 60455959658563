import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCarrierId, getDriverId } from "app/services/LoginService";
import { SmarthopList, carrierTrucksListBuilder } from "@smarthop/list";
import PageWrapper from "../../common/PageWrapper";

function DriverTrucksPage(props) {
	const params = useParams();
	const carrierId = props.isView ? props?.params?.carrierId : params.carrierId ?? getCarrierId();
	const driverId = props.isView ? props.params?.driverId : params.driverId ?? getDriverId();
	const userId = props.isView ? props.params?.userId : params.userId;

	const subaccount = useSelector(({ auth }) => auth.account.subAccount);
	const isSubaccount = subaccount?.list?.find((item) => item._id + "" === carrierId + "");

	const isCarrierDispatcher = !!props?.params?.isCarrierDispatcher;
	const source = isCarrierDispatcher ? "DISPATCHER" : !!driverId ? "DRIVER" : "USER";
	return (
		<PageWrapper title="Assigned Trucks" variant={props.isView ? "full" : "narrow"} isView={props.isView}>
			<SmarthopList
				mode="TABLE"
				isView={props.isView}
				config={carrierTrucksListBuilder({ source, isSubaccount })}
				dataIds={{ carrierId, driverId, userId }}
			/>
		</PageWrapper>
	);
}

export default DriverTrucksPage;
