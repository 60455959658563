const chat = {
	urlDELETE: "api/analytics/data/metrics/:id",
	urlGET: "api/analytics/data/metrics/:id",
	idKey: "_id",
	triggerEvent: "analyticsJobsRevision",
	listenEvent: "analyticsJobsRevision",
	header: {
		delete: { label: "Delete Metric" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) =>
						`Would you like to permanently delete metric '${item.metricKey}' (${item._id})'? That actions would removed all related periods and data points.`,
				},
				{
					key: "confirmationText",
					type: "text",
					label: "Please type 'delete'",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
							},
						],
					},
				},
			],
		},
	},
};

export default chat;
