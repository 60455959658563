import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const ITEMS_PER_GROUP = 4;

const createForm = ({ elegibleItems, exceedsMax }) => {
	const actions = [
		{
			type: "action",
			action: "CUSTOM",
			label: "Export",
			button: { alwaysEnabled: true },
		},
	];

	const buildGroup = (items, index) => ({
		key: `group-${index}`,
		type: "group",
		content: { items },
	});

	const buildRows = (items) => {
		let groups = [];
		let counter = 0;
		for (let i = 0; i < Math.ceil(items.length / ITEMS_PER_GROUP); i++) {
			groups.push(buildGroup([], i));
			for (let j = 0; j < 4; j++) {
				if (items[counter]) {
					groups[i].content.items.push(items[counter]);
				}
				counter++;
			}
		}
		return groups;
	};

	const columns = elegibleItems.map((item) => ({
		key: item.key,
		type: "checkbox",
		label: item.label,
	}));

	return {
		bottom: {
			classes: { root: "pt-4 ml:pt-12" },
			items: actions,
		},
		items: [
			{
				type: "section",
				label: "Select Columns",
			},
			...buildRows(columns),
			{
				type: "message",
				builder: () =>
					exceedsMax ? (
						<div className="flex flex-row justify-center space-x-10">
							<ErrorOutlineIcon />
							<span className="font-bold text-red-500">This file exceeds 1000 rows.</span>
						</div>
					) : null,
			},
		],
	};
};

export default createForm;
