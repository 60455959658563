import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function UnitPaymentStatusCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const date = props.view?.metadata?.completedDate;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={props.view} variant={variant} />}
			second={
				<div className="flex flex-wrap">
					{date && <CellSecondRow mobile={mobile} label={date ?? "No Date"} variant={variant} />}
				</div>
			}
		/>
	);
}

export default UnitPaymentStatusCellView;
