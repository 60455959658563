import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Pages
import SmartHopWalletCustomerPage from "./SmartHopWalletCustomerPage";
import SmartHopWalletApplicationPage from "./SmartHopWalletApplicationPage";

// Services
import { UnitFinanceService } from "app/services/unitFinanceServices";
import { getCarrierId, getRoleLabel } from "app/services/LoginService";

// Utils
import { ACTIVE_WALLET_STATUS, INACTIVE_WALLET_STATUS, CLOSED_WALLET_STATUS } from "app/main/utils/financeUtils";

const SmartHopWalletPage = (props) => {
	const params = useParams();
	const [walletStatus, setWalletStatus] = useState(null);

	const isCarrier = getRoleLabel() === "carrier";
	const carrierId = props?.params?.carrierId ?? params.carrierId ?? (isCarrier ? getCarrierId() : undefined);

	useEffect(() => {
		(async () => {
			const shWalletStatus = await UnitFinanceService.getWalletStatus(carrierId);
			setWalletStatus(shWalletStatus?.status);
		})();
	}, [carrierId]);

	return INACTIVE_WALLET_STATUS === walletStatus ? (
		<SmartHopWalletApplicationPage {...props} />
	) : ACTIVE_WALLET_STATUS === walletStatus ? (
		<SmartHopWalletCustomerPage {...props} />
	) : CLOSED_WALLET_STATUS === walletStatus ? (
		<SmartHopWalletApplicationPage {...props} />
	) : (
		<></>
	);
};

export default SmartHopWalletPage;
