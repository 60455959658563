import React from "react";
import { Typography, Divider } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { useEffect } from "react";
import qs from "qs";
import { getCarrierId } from "app/services/LoginService";
import { connectAxle } from "app/services/carrierServices";

function ActionContainer(props) {
	const data = props.data;
	const history = useHistory();
	const dispatch = useDispatch();
	const carrierId = getCarrierId();

	const onClickProccess = (action) => {
		if (action?.openDialog) {
			dispatch(openLoadedFormDialog(action?.openDialog));
		} else if (action?.openUrl) {
			history.push(action?.openUrl?.url);
		} else if (action?.connectAxle) {
			connectAxle(carrierId).then((data) => {
				window.open(data.url, "_blank");
			});
		}
	};

	let badgeNumber = data?.filter((action) => action.alert)?.length;

	// opens mc validation dialog on home page if ?validate_mc is passed in
	useEffect(() => {
		const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
		if (parsedQueryString.validate_mc && carrierId) {
			dispatch(
				openLoadedFormDialog({
					formId: "CARRIER_COMPANY_VALIDATE_ONLY_FORM",
					mode: "EDIT",
					dataIds: { carrierId },
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex pb-18 pt-6">
				<Typography className="font-medium text-16 text-black pt-12">Required Actions</Typography>
				{data?.length > 0 && (
					<div className="ml-auto">
						<Typography className="font-medium text-16 text-orange-900 pt-10 pr-10">{badgeNumber}</Typography>
					</div>
				)}
			</div>
			<Divider className="w-full bg-grey-300" />

			{data?.map((item, index) => {
				return (
					<div className="pt-10" key={index}>
						<div
							className="rounded border-gray-400 h-30 border-1 pt-2 flex cursor-pointer bg-orange-50"
							onClick={() => onClickProccess(item?.web?.action)}
						>
							<Typography className="font-medium px-20 py-9 text-13 text-black">{item?.title}</Typography>
							<div className="self-center ml-auto">
								<Icon>chevron_right</Icon>
							</div>
						</div>
					</div>
				);
			})}
			{!data?.length && (
				<div className="items-center justify-center align-center flex h-4/6 ml:h-4/6">
					<Typography className="font-medium text-14 text-black center mt-20">No Actions</Typography>
				</div>
			)}
		</>
	);
}

export default ActionContainer;
