import { CARRIER_ALL } from "app/main/search/config/searchFields";

const { field, ...CARRIER_ALL_MOD } = CARRIER_ALL;

const applyCouponForm = {
	urlPOST: "api/discounts/apply",
	urlDELETE: "api/discounts/:id/deactivate",
	urlGET: "api/discounts/:id",
	idKey: "_id",
	triggerEvent: "discountRevision",
	listenEvent: "discountRevision",
	header: { create: { label: "Apply coupon" }, delete: { label: "Deactivate discount" } },
	content: {
		delete: {
			items: [
				{
					type: "message",
					label: "Would you like to deactivate the discount?",
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{
								type: "action",
								action: "DELETE",
								label: "Deactivate",
							},
						],
					},
				},
			],
		},
		create: {
			items: [
				{
					key: "coupon",
					type: "autocomplete",
					label: "Coupons",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/coupons/list",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "apply_type",
								type: "select",
								label: "Apply to",
								options: [
									{
										value: "CARRIER",
										label: "Carrier",
										showItems: [CARRIER_ALL_MOD],
									},
									{
										value: "EMAIL",
										label: "Email",
										showItems: [
											{
												key: "email",
												type: "text",
												label: "Email",
												required: true,
											},
										],
									},
								],
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT", label: "Apply" },
			],
		},
	},
};

export default applyCouponForm;
