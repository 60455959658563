const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const accounts = {
	urlGET: "api/profile/accounts",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "INTERNAL_ACCOUNTS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		defaults: {
			// Initial value of fields
			initial: {
				filters: {
					carrierStatus: "ACTIVE",
					multiaccountType: "__NOT_SELECTED__",
					...(PROD_DB ? { carriersType: "REAL_CARRIERS" } : { carriersType: "__NOT_SELECTED__" }),
				},
			},
			// Additional quick filters
			items: [
				{
					label: "All",
					filters: { multiaccountType: "__NOT_SELECTED__" },
				},
				{
					label: "Single-Carrier",
					filters: { multiaccountType: "SINGLE_CARRIER" },
				},
				{
					label: "Multi-Carrier",
					filters: { multiaccountType: "MULTI_CARRIER" },
				},
			],
		},
		filters: {
			default: {},
			search: true,
			countVisible: 2,
			items: [
				{
					key: "carrierStatus",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ACTIVE",
							label: "Has 1+ Active Carriers",
						},
						{
							value: "INACTIVE",
							label: "Has Only Inactive Carriers",
						},
					],
				},
				{
					key: "multiaccountType",
					type: "select",
					label: "Multi-Account Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "MULTI_CARRIER",
							label: "Account Has Multiple Carriers",
						},
						{
							value: "SINGLE_CARRIER",
							label: "Account Has Single Carrier",
						},
					],
				},
				{
					key: "carriersType",
					type: "select",
					label: "Account Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real (Has 1+ Real Carriers)",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test (Has Only Test Carriers)",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "Account ID", group: "internal" },
			{
				key: "profile__view",
				type: "profile__view",
				label: "Owner",
				builder: (item) => item.profile__view,
				viewConfig: {
					showEmail: true,
				},
			},
			{
				key: "account__view",
				type: "account__view",
				label: "Account",
				builder: (item) => item.account__view,
				viewConfig: {
					showTotalAccountsCount: true,
					showActiveAccountsCount: true,
					showTestCarrier: true,
				},
			},
			{
				key: "carriers__view",
				type: "carrier__view",
				label: "Carrier",
				builder: (item) => item.carriers__view,
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
					showTestCarrier: true,
				},
			},
			{ key: "createdAt", type: "date", label: "Created" },
			{ key: "carriersCount", type: "text", label: "Carriers #" },
			{ key: "carriersActiveCount", type: "text", label: "Active #" },
		],
	},
};

export default accounts;
