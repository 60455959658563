import React from "react";

import ActionContainer from "./ActionContainer.js";
import PerformanceContainer from "./PerformanceContainer.js";
import TripContainer from "./TripContainer.js";
import SmartPayContainer from "./SmartPayContainer.js";
import CalendarContainer from "./CalendarContainer.js";
import LiveTrackingContainer from "./LiveTrackingContainer.js";
import LanesMarketCondition from "./LanesMarketCondition.js";
import SmartFuelContainer from "./SmartFuelContainer";
import AssignedTrucksContainer from "./AssignedTrucksContainer";
import ProfileContainer from "./ProfileContainer";
import ActiveTripsContainer from "./ActiveTripsContainer";
import InvoiceInvestorContainer from "./InvoiceInvestorContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { fetchMap, selectByType } from "app/store/marketConditions/mapSlice";
import { getInfoStaticWidget } from "app/services/staticWidgetServices";
import { getRoleLabel } from "app/services/LoginService";
import clsx from "clsx";
//utils
import { getLaneEquipment } from "app/main/utils/marketUtils";
import { useMemo } from "react";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "absolute",
		height: "auto!important",
		left: "45%",
		top: "40%",
	},
	progressCalendar: {
		position: "absolute",
		height: "auto!important",
		left: "48%",
		top: "40%",
	},
}));

function WidgetStatic(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const isAdmin = getRoleLabel() === "administrator";

	const config = props.config;
	const dataIds = useMemo(() => props.dataIds, [props.dataIds]);
	const cellHeight = props.cellHeight;
	const onChangeHeight = props.onChangeHeight;
	const type = config?.type;
	const fetch = type === "actions" || type === "markets";
	const dataRevision = useSelector(({ tools }) => tools.revision["dashboardRevision"]);
	const actions = useSelector(({ actions }) => actions.actionsUser);
	const dataMarkets = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, getLaneEquipment(), "ocluster", "toplanes")
	);
	const user = useSelector(({ auth }) => auth.user);

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [pulling, setPulling] = useState(0);
	const subaccounts = useSelector(({ auth }) => auth.account.subAccount);
	const [selectedCarrier, setSelectedCarrier] = useState(localStorage.getItem("lastSubcarrierSelected") ?? null);

	//Case when you are in the market widgets and i dont want to show the loading when we call in the lane market the datamarkets
	const [verifiedLoading, setLoadingOnce] = useState(true);

	useEffect(() => {
		let timeOut;
		let stopped;

		if (!dataIds?.carrierId && !dataIds?.userId) {
			setData({});
			return;
		}

		if (type && !fetch) {
			(async () => {
				try {
					let config = { type, dataIds: { ...dataIds } };
					if (type === "live-tracking") {
						if (selectedCarrier) {
							config.dataIds.carrierId = selectedCarrier;
							if (selectedCarrier === "__ALL__") {
								config.dataIds.accountId = user.account;
							}
						}
					}
					if (type === "calendar" && subaccounts?.list?.length) {
						config.dataIds.accountId = user.account;
					}

					const info = await getInfoStaticWidget(config);
					if (stopped) return;

					// If the type is calendar i need the hight to put the height of the calendar
					if (type === "calendar") {
						onChangeHeight({
							[type]: info?.truckList?.length ? (info?.truckList?.length * 53 + 130) / cellHeight : null,
						});
					}

					setData(info);
				} catch (err) {
					console.error("[WidgetStatic] Failed to load widget data ", err);
					if (stopped) return;
					setError(err.message ?? err.errors?.[0]?.message ?? "Opps... Something went wrong...");
				}
			})();

			if (type === "live-tracking") {
				setTimeout(() => {
					setPulling(pulling + 1);
				}, 600000);
			}
		}
		// Case when fetch data not getting fron the endpoint
		else {
			if (type === "actions") dispatch(fetchActions({ carrierId: dataIds?.carrierId }));
			if ((!dataMarkets?.status || dataMarkets?.status === "rejected") && type === "markets") {
				dispatch(fetchMap({ equipment: getLaneEquipment(), field: "ocluster", toplanes: "toplanes" }));
			}
		}
		return () => {
			stopped = true;
			clearTimeout(timeOut);
		};
		// eslint-disable-next-line
	}, [dataIds, type, dataRevision, pulling, selectedCarrier, user, selectedCarrier]);

	const showLoading = (value) => {
		setLoadingOnce(value);
	};

	const renderDashBoard = useCallback(() => {
		if (!config?.type) {
			return <Typography className={"p-10 text-16 text-red"}>Invlalid static widget config</Typography>;
		}

		if (
			(!data && !["actions", "markets"]?.includes(config?.type)) ||
			(config?.type === "actions" && !actions?.actions && !isAdmin) ||
			(config?.type === "markets" &&
				(!dataMarkets || (dataMarkets?.status === "pending" && !dataMarkets?.errors?.length)) &&
				verifiedLoading)
		) {
			return (
				<div
					className={clsx(
						config?.type === "calendar" ? classes.progressCalendar : classes.progress,
						"flex items-center justify-center"
					)}
				>
					<CircularProgress
						style={{ color: config?.type === "wallet" || config?.type === "wallet" ? "#ffffff" : "#707070" }}
					/>
				</div>
			);
		}
		if (config?.type === "actions") {
			return <ActionContainer config={config} data={actions?.actions} />;
		}
		if (config?.type === "performance" || type === "performance-investor") {
			return <PerformanceContainer config={config} data={data} />;
		}
		if (config?.type === "trip" || config?.type === "trip-user") {
			return (
				<TripContainer
					config={config}
					data={data}
					carrierId={dataIds?.carrierId}
					isTripUser={config?.type === "trip-user"}
				/>
			);
		}
		if (config?.type === "smartpay") {
			return <SmartPayContainer config={config} data={data} />;
		}
		if (config?.type === "fuel") {
			return <SmartFuelContainer config={config} data={data} />;
		}
		if (config?.type === "calendar") {
			return <CalendarContainer config={config} data={data} />;
		}
		if (config?.type === "live-tracking") {
			return (
				<LiveTrackingContainer
					key={`LiveTrackingContainer${selectedCarrier}`}
					id={`LiveTrackingContainer${selectedCarrier}`}
					config={config}
					data={data}
					carrierId={dataIds?.carrierId}
					setSelectedCarrier={setSelectedCarrier}
				/>
			);
		}
		if (config?.type === "markets") {
			return (
				<LanesMarketCondition
					config={config}
					data={dataMarkets}
					verifiedLoading={verifiedLoading}
					showLoading={showLoading}
				/>
			);
		}
		if (config?.type === "assigned-trucks") {
			return <AssignedTrucksContainer config={config} data={data} />;
		}
		if (config?.type === "profile") {
			return <ProfileContainer config={config} data={data} />;
		}
		if (config?.type === "active-trips") {
			return <ActiveTripsContainer config={config} data={data} />;
		}
		if (config?.type === "invoice-investor") {
			return <InvoiceInvestorContainer config={config} data={data} />;
		}
		return <Typography className={"p-10 text-16 text-red"}>Not supported static widget type</Typography>;
		// eslint-disable-next-line
	}, [
		actions?.actions,
		classes.progress,
		classes.progressCalendar,
		config,
		data,
		dataMarkets,
		isAdmin,
		verifiedLoading,
	]);

	const dashboard = useMemo(() => renderDashBoard(), [renderDashBoard]);

	if (!dataIds?.carrierId && !dataIds?.userId) {
		return <Typography className={"p-10 text-16 text-red"}>Id is missing</Typography>;
	}

	if (error) {
		return (
			<div className="flex flex-1 flex-col h-full items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<div
			className={"h-full w-full overflow-scroll relative " + (config?.type !== "live-tracking" ? " px-4 -mt-2" : "")}
		>
			{dashboard}
		</div>
	);
}

export default WidgetStatic;
