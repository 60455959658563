import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

function SmarthopDialog(props) {
	const open = props.open;
	const title = props.title;
	const onClose = props.onClose;

	return (
		<Dialog open={open} classes={{ paper: "max-w-sm" }}>
			<DialogTitle className="bg-primary w-full py-20">
				<div className="flex flex-row justify-between w-full text-white px-6">
					<Typography className="font-medium text-14 ml:text-15 tracking-wide">{title}</Typography>
					<div className="flex">
						<IconButton color="inherit" className="p-0" onClick={() => onClose()}>
							<Icon className="arrow-icon">close</Icon>
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent className="px-4 py-4 md:px-8 md:py-4 sm:px-12 sm:py-8 m-24">{props.children}</DialogContent>
		</Dialog>
	);
}

export default SmarthopDialog;
