import { Typography } from "@material-ui/core";
import { formatNumber } from "app/main/utils/tableUtils";

// Utils
import { createTooltip } from "app/main/utils/tableUtils";

const _PARTIAL_DESCRIPTION = "Partial Load";

function WeightComponent(props) {
	const item = props.item;
	const partial = item.partial;
	const partialStyle = partial && !item.weight ? "mr-0" : "mr-3";

	const PartialView = (
		<div
			className={
				"flex items-center justify-center h-16 min-w-16 rounded-4 px-2 border-1 border-grey text-grey mt-1 " +
				partialStyle
			}
		>
			<Typography className={"font-500 p-2 text-grey text-11"}>{"P"}</Typography>
		</div>
	);

	return (
		<div className="flex">
			{partial && createTooltip(PartialView, _PARTIAL_DESCRIPTION, "bg-emerald-400")}
			{item.weight > 0 ? formatNumber(item.weight, 0) : !partial ? "-" : ""}
		</div>
	);
}

export default WeightComponent;
