import { capitalizaEveryWordFirstLetter } from "helpers/Formatter";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";

const createAccountSelectionForm = (options, onSelect) => ({
	items: [
		{
			key: "message",
			type: "message",
			label: `Please select account you want to access, you can always switch between accounts later in the profile menu`,
		},
		{
			key: "accounts",
			type: "component",
			builder: () => (
				<div className="flex w-full flex-col max-h-480 overflow-scroll">
					{options.map((option) => (
						<div
							className="flex flex-col cursor-pointer bg-grey-100 hover:bg-orange-100 mb-10 p-10 rounded-lg"
							onClick={() => {
								onSelect(option.user?._id);
							}}
						>
							<div className="flex flex-row">
								<Avatar src={null} alt={""} className={"mr-10 " + (!option.carrier ? " bg-indigo-900 " : "")}>
									{!option.carrier ? (
										<img className="pl-2 pr-5 w-44" src="assets/images/logos/smarthop.png" alt="logo" />
									) : option.user.role === "CARRIER_OWNER" ? (
										<Icon className="text-20">local_shipping</Icon>
									) : option.user.role === "CARRIER_DRIVER" ? (
										<Icon className="text-20">airline_seat_recline_normal</Icon>
									) : option.user.role === "CARRIER_DISPATCHER" ? (
										<Icon className="text-20">support_agent</Icon>
									) : (
										<Icon className="text-20">account_circle</Icon>
									)}
								</Avatar>
								<div className="flex flex-col pt-2">
									{option.carrier ? (
										<div className="flex flex-row whitespace-nowrap">
											<Typography className="text-13 max-w-180 truncate">
												{option.carrier?.carrier_company_name ?? "New Account"}
											</Typography>
											{option.carrier?.carrier_mc_number && (
												<>
													<Typography className="text-13 text-grey px-6">|</Typography>
													<Typography className="text-13 max-w-92 truncate">
														MC {option.carrier?.carrier_mc_number}
													</Typography>
													{option.carrier?.onboardingStatus && option.carrier?.onboardingStatus !== "ACCEPTED" && (
														<>
															<Typography className="text-13 text-grey px-6">|</Typography>
															<Typography className="text-13 font-medium text-orange-700 max-w-92 truncate">
																{capitalizaEveryWordFirstLetter(
																	option.carrier?.onboardingStatus === "INCOMPLETE"
																		? "ONBOARDING"
																		: option.carrier?.onboardingStatus
																)}
															</Typography>
														</>
													)}
												</>
											)}
										</div>
									) : (
										<Typography className="text-13">Smarthop Internal Account</Typography>
									)}
									<div className="flex flex-row">
										<>
											<Typography className="text-12 font-medium text-grey -mt-2">
												{capitalizaEveryWordFirstLetter(option.user?.role ?? "UNKNWON_ROLE")}
											</Typography>
											{!!option.user && !option.user.accessAt && (
												<Typography className="text-10 font-bold text-green ml-5">NEW ACCOUNT</Typography>
											)}
											{option.carrier?.accountStatus === "DEACTIVATED" && (
												<Typography className="text-10 font-bold text-red ml-5">INACTIVE</Typography>
											)}
										</>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			),
		},
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
				classes: { root: "mt-16" },
			},
		},
	],
});

export default createAccountSelectionForm;
