import { formatDate } from "app/main/utils/dateUtils";
import {
	getStatusConfig,
	getAddressCity,
	metadataInfo,
	STATUS_TRIPS,
	getWarningMsg,
	MISSING_DATA,
} from "app/main/utils/tripsUtils";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { authRoles } from "app/main/consts";

import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { Link } from "@material-ui/core";

import { formatCurrency, createTooltip } from "app/main/utils/tableUtils";
import { openLoadedFormDialog, openFormDialog } from "app/store/tools/formDialogSlice";
import TripEmptyLocationDialog from "app/main/profile/trips/TripEmptyLocationDialog";
import { processTierRestrictions } from "app/main/utils/rolesUtils";

// FIXME: there is no use case where you may need to ask users carrier id, it's
// should always come from the entity itself
import { getCarrierId, getUserTier } from "app/services/LoginService";

// Gatekeeper
import { isEnabled } from "app/services/featureStorageService";

const PROD_DB = process.env.REACT_APP_ENV === "PRD";

const GridTitle = ({ item }) => {
	return (
		<div className="flex flex-col">
			<div>Load # {item?.data?.load_id}</div>
			<div>{item?.trip__view?.metadata?.broker}</div>
		</div>
	);
};

const showBtnAddNow = (warningList) => {
	const missingDataList = { ...MISSING_DATA };
	delete missingDataList["MISSING_TRUCK"];
	delete missingDataList["MISSING_CARRIER_NOT_VALID"];

	const count = Object.keys(missingDataList).filter((key) => warningList.includes(key));
	return count.length > 0;
};

const internalTripsList = (
	role,
	dispatch,
	isInvoicingEnabled = false,
	fromDashboard = false,
	readOnly = false,
	isMainHandler = false,
	hasPermissionEdit = true,
	diffHeight = null
) => {
	const isInternal = authRoles.internalUsers.includes(role);
	const isCarrier = authRoles.carrierPowerUsers.includes(role) || authRoles.carrierDispatcher.includes(role);
	const isDriver = authRoles.driver.includes(role);
	const isInvestor = authRoles.carrierGeneric.includes(role);
	const isDispatcher = authRoles.carrierDispatcher.includes(role);

	return {
		urlGET: "api/booking/trips",
		idKey: "_id",
		listenEvent: ["profileRevision", "tripsRevision"],
		segmentKey: "INTERNAL_TRIPS",
		content: {
			grid: {
				tilesPerRow: 1,
				itemsPerRow: 3,
				mobile: {
					tilesPerRow: 1,
					itemsPerRow: 2,
				},
			},
			pagination: {
				defaut: { size: 10 },
				enabled: true,
				resetScroll: true,
			},
			table: {
				variant: "skinny",
				multiline: true,
				orderBy: ["data.status", "data.pickup_date", "data.delivery_date", "data.main_dispatcher", "data.username"],
				diffHeight: diffHeight,
			},
			order: {
				defaut: {
					key: "data.delivery_date",
					dir: "desc",
				},
			},
			groups: {
				items:
					isDriver || isInvestor || fromDashboard
						? []
						: isCarrier && !fromDashboard
						? [
								{
									key: "all_data",
									type: "checkbox",
									label: "Show Location Details",
								},
								{
									key: "creator_data",
									type: "checkbox",
									label: "Show Creator Details",
								},
								{
									key: "investor_data",
									type: "checkbox",
									label: "Show Investor Details",
								},
								{
									key: "tracking",
									type: "checkbox",
									label: "Show Tracking Details",
								},
								...(isInvoicingEnabled
									? [
											{
												key: "invoice",
												type: "checkbox",
												label: "Show Invoicing Details",
											},
									  ]
									: []),
								{
									key: "files",
									type: "checkbox",
									label: "Show Files Information",
								},
						  ]
						: fromDashboard
						? []
						: [
								{
									key: "creator_data",
									type: "checkbox",
									label: "Show Creator",
								},
								{
									key: "all_data",
									type: "checkbox",
									label: "Show All Data",
								},
								{
									key: "internal",
									type: "checkbox",
									label: "Show Internal Fields",
								},
								{
									key: "metadata",
									type: "checkbox",
									label: "Show Metadata",
								},
								...(isInvoicingEnabled
									? [
											{
												key: "invoice",
												type: "checkbox",
												label: "Show Invoicing Information",
											},
									  ]
									: []),
								{
									key: "tracking",
									type: "checkbox",
									label: "Show Tracking Information",
								},
						  ],
			},
			defaults: {
				// Initial value of fields
				initial: {
					filters: {
						trip_status: "__NOT_DELIVERED__",
						createdAt:
							isCarrier && !fromDashboard && !isDriver
								? "__DATE_DAYS_GT__60"
								: isDriver
								? "__DATE_DAYS_GT__3650"
								: "__DATE_DAYS_GT__30",
						...(isCarrier ? {} : { carriers_type: PROD_DB ? "REAL_CARRIERS" : "__NOT_SELECTED__" }),
					},
					groups: { invoice: false, tracking: true },
				},
				// Additional quick filters
				items: fromDashboard
					? []
					: [
							{
								label: "In Progress",
								filters: { trip_status: "__NOT_DELIVERED__" },
								groups: { invoice: false, tracking: true, files: false },
							},
							{
								label: "Dispatched",
								filters: { trip_status: "__ACTIVE__" },
								groups: { invoice: false, tracking: true, files: false },
							},
							{
								label: "Delivered",
								filters: { trip_status: "__DELIVERED__" },
								groups: { invoice: isInvoicingEnabled, tracking: false, files: true },
							},
							{
								label: "Canceled",
								filters: { trip_status: "Cancelled" },
								groups: { invoice: false, tracking: false, files: false },
							},
					  ],
			},
			filters: {
				search: fromDashboard ? false : true,
				searchDescription: "Search by Load ID, Driver name or Truck ID",
				items: [
					...(isCarrier && !isDriver && !isInvestor && !isMainHandler && !fromDashboard
						? [
								{
									key: "truck",
									type: "autocomplete",
									label: "Truck",
									translate: "",
									field: {
										noErrorMessage: true,
										classes: {
											root: "makeStyles-field-1251",
										},
										variant: "outlined",
									},
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers/:carrierId/trucks",
										params: {
											options_metadata_truck: true,
											options_metadata_driver: true,
											options_active_only: true,
											label_format_show_driver: true,
										},
										preloadDefault: true,
										preloadImmedately: true,
										listenEvent: "profileRevision",
									},
								},
						  ]
						: []),

					...(!fromDashboard
						? [
								{
									key: "trip_status",
									type: "select",
									label: "Trip Status",
									defaultValue: "__NOT_DELIVERED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "__NOT_DELIVERED__",
											label: "In Progress (Pending + Dispatched)",
										},
										{
											value: "Missing Data",
											label: "Pending (Missing Data)",
										},
										{
											value: "Pending",
											label: "Pending (Waiting for RC)",
										},
										{
											value: "Booked",
											label: "Pending (Booked)",
										},
										{
											value: "__PENDING__",
											label: "Pending (All)",
										},
										{
											value: "Dispatched",
											label: "Dispatched (Dispatched)",
										},
										{
											value: "On Transit to Pick Up",
											label: "Dispatched (On Transit to Pick Up)",
										},
										{
											value: "At Pickup",
											label: "Dispatched (At Pickup)",
										},
										{
											value: "On Transit to Delivery",
											label: "Dispatched (On Transit to Delivery)",
										},
										{
											value: "At Delivery",
											label: "Dispatched (At Delivery)",
										},
										{
											value: "__ACTIVE__",
											label: "Dispatched (All)",
										},
										{
											value: "__DELIVERED__",
											label: "Delivered",
											border: true,
										},
										{
											value: "Cancelled",
											label: "Cancelled",
										},
									],
								},
						  ]
						: []),

					...(isInternal || isMainHandler
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									field: {
										noErrorMessage: true,
										classes: {},
									},
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										preloadDefault: true,
										listenEvent: "profileRevision",
									},
								},
								{
									key: "truck",
									type: "autocomplete",
									label: "Truck",
									translate: "",
									field: {
										noErrorMessage: true,
										classes: {},
										variant: undefined,
									},
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/trucks",
										params: {
											options_active_only: true,
											options_format_show_driver_phone: true,
										},
										preloadDefault: true,
										preloadImmedately: true,
										listenEvent: "profileRevision",
									},
									rules: {
										params: {
											valueRef: "carrier",
											paramKey: "options_carrier_id_search",
										},
									},
								},
								{
									key: "driver",
									type: "autocomplete",
									label: "Driver",
									translate: "",
									field: {
										noErrorMessage: true,
										classes: {},
										variant: undefined,
									},
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/drivers",
										params: { options_active_only: true },
										preloadDefault: true,
										preloadImmedately: true,
										listenEvent: "profileRevision",
									},
									rules: {
										params: {
											valueRef: "carrier",
											paramKey: "options_carrier_id_search",
										},
									},
								},
								{
									key: "carrierAccountType",
									type: "select",
									label: `Carrier Account Type`,
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "MAIN_ACCOUNT",
											label: "Main Account",
										},
										{
											value: "SUB_ACCOUNT",
											label: "Sub Account",
										},
									],
								},
						  ]
						: []),

					...(!fromDashboard
						? [
								{
									key: "broker",
									type: "autocomplete",
									label: "Broker",
									autocomplete: {
										provider: "smarthop",
										url: "api/consts/brokers",
										preloadDefault: true,
									},
								},
						  ]
						: []),

					...(isInternal
						? [
								{
									key: "dispatcher",
									type: "autocomplete",
									label: "Trip Dispatchers",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/users",
										multiple: true,
										preloadDefault: true,
										params: {
											options_internal_only: true,
										},
									},
								},
								{
									key: "creator",
									type: "autocomplete",
									label: "Trip Creators",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/users",
										multiple: true,
										preloadDefault: true,
									},
								},
								{
									key: "not_payable",
									type: "select",
									label: "Not Payable",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: true,
											label: "Not Payable",
										},
										{
											value: false,
											label: "Payable",
										},
									],
								},
						  ]
						: []),

					...(!fromDashboard
						? [
								{
									key: "invoicing_eligible",
									type: "select",
									label: "Invoicing Eligible",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: true,
											label: "Eligible",
										},
										{
											value: false,
											label: "Not Eligible",
										},
									],
								},
								{
									key: "smartpay_eligible",
									type: "select",
									label: "SmartPay",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "__TRUE__",
											label: "Eligible",
										},
										{
											value: "__FALSE_OR_EMPTY__",
											label: "Not Eligible",
										},
									],
								},
						  ]
						: []),

					...(!isDriver && !isInvestor && !fromDashboard
						? [
								{
									key: "createdAt",
									type: "select",
									label: "Visible Window",
									defaultValue: "__DATE_DAYS_GT__30",
									options: [
										{
											value: "__DATE_DAYS_GT__30",
											label: "Last 30 Days",
										},
										{
											value: "__DATE_DAYS_GT__60",
											label: "Last 60 Days",
										},
										{
											value: "__DATE_DAYS_GT__90",
											label: "Last 90 Days",
										},
										{
											value: "__DATE_DAYS_GT__365",
											label: "Last 1 Year",
										},
										{
											value: "__DATE_DAYS_GT__3650",
											label: "Last 10 Years",
										},
									],
								},
								{
									key: "pod_file_status",
									type: "select",
									label: "Trips Files",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "__POD_FILE__",
											label: "Has BOL File",
										},
										{
											value: "__NOT_POD_FILE__",
											label: "Missing BOL File",
										},
									],
								},
						  ]
						: []),

					...(!fromDashboard
						? [
								{
									key: "tracking_type",
									type: "select",
									label: "Tracking",
									defaultValue: "__NOT_SELECTED__",
									options: isInternal
										? [
												{
													value: "__NOT_SELECTED__",
													label: "Not Selected",
												},
												{
													value: "__GPS__",
													label: "Tracked by Smarthop App",
												},
												{
													value: "__APP__ELD__",
													label: "Tracked by ELD",
												},
												{
													value: "__NOT__TRACKED__",
													label: "Not tracked",
												},
												{
													value: "__LOCATION__EXPIRED__",
													label: "Location expired",
												},
										  ]
										: [
												{
													value: "__NOT_SELECTED__",
													label: "Not Selected",
												},
												{
													value: "__TRACKING_ACTIVE__",
													label: "Active",
												},
												{
													value: "__LOCATION__EXPIRED__",
													label: "Expired or Inactive",
												},
										  ],
								},
						  ]
						: []),

					...(isInternal
						? [
								{
									key: "carriers_type",
									type: "select",
									label: "Internal Carrier Type",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "REAL_CARRIERS",
											label: "Real Carriers",
										},
										{
											value: "TEST_CARRIERS",
											label: "Test Carriers",
										},
									],
								},
						  ]
						: []),

					...(isInternal
						? [
								{
									key: "metadata_creator_type",
									type: "select",
									label: "Creator Type",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "INTERNAL",
											label: "Internal",
										},
										{
											value: "EXTERNAL",
											label: "External",
										},
									],
								},
								{
									key: "metadata_load_source",
									type: "select",
									label: "Load Source",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "SEARCH",
											label: "Search",
										},
										{
											value: "EXTERNAL",
											label: "External",
										},
									],
								},
								{
									key: "metadata_book_location",
									type: "select",
									label: "Book Location",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "SEARCH",
											label: "Search",
										},
										{
											value: "CHAT",
											label: "Chat",
										},
										{
											value: "EXTERNAL",
											label: "External",
										},
									],
								},
								{
									key: "metadata_book_type",
									type: "select",
									label: "Book Type",
									defaultValue: "__NOT_SELECTED__",
									options: [
										{
											value: "__NOT_SELECTED__",
											label: "Not Selected",
										},
										{
											value: "DIGITAL",
											label: "Digital",
										},
										{
											value: "MANUAL",
											label: "Manual",
										},
									],
								},
						  ]
						: []),
				],
			},
			items: [
				{
					key: "_id",
					type: "id",
					label: "Trip ID",
					group: "internal",
				},
				{
					key: "External",
					type: "bool",
					label: "external",
					group: "internal",
				},
				{ type: "separator", group: "internal" },
				{
					key: "data.status",
					type: "component",
					builder: (item) => {
						const editable = item?.data?.status !== "Missing Data";
						if (editable) {
							const config = getStatusConfig(item?.data?.status, isCarrier);
							return (
								<>
									<Icon className={(config?.color ?? "") + " text-22 ml:text-24 mr-2"}>
										{config?.icon ?? "receipt"}
									</Icon>
									{readOnly && (
										<div className={"flex flex-col ml-4 "}>
											<Typography className="tracking-wide">{config.label}</Typography>
											<Typography className="tracking-wide text-11 ml:text-12 text-grey-600 -mt-3">
												{config.category}
											</Typography>
										</div>
									)}
								</>
							);
						} else {
							return (
								<div className="flex flex-row justify-center items-center space-x-4 text-11">
									{createTooltip(
										<Icon className="text-red text-20 ml-2 mt-1">warning</Icon>,
										"Required missing fields: \n" +
											item?.warnings
												?.map((type) => getWarningMsg(type))
												?.filter((msg) => msg)
												?.join?.(", ")
									)}
									<div className="flex flex-col">
										<Typography noWrap className="text-12 ml:text-13">
											Trip Details Missing
										</Typography>
										{showBtnAddNow(item?.warnings) && (
											<Link
												className="font-semibold underline -mt-2"
												onClick={() => {
													dispatch(
														openLoadedFormDialog({
															viewId: "TRIP_EDIT_VIEW",
															mode: "EDIT",
															dataIds: { tripId: item._id, carrierId: item.carrier ?? getCarrierId() },
														})
													);
												}}
											>
												ADD NOW
											</Link>
										)}
									</div>
								</div>
							);
						}
					},
					table: { width: 200 },
					label: "Status",
					...(readOnly
						? {}
						: {
								editable: (item) => item?.data?.status !== "Missing Data",
								edit: {
									urlPUT: "api/booking/carriers/:carrierId/trips/:tripId/status",
									triggerEvent: "tripsRevision",
									field: {
										key: "status",
										required: true,
										type: "autocomplete",
										classes: { cell: "min-w-192" },
										autocomplete: {
											disableClearable: true,
											provider: "smarthop",
											url: "api/booking/trips/:tripId/status",
											preloadDefault: true,
										},
									},
									paramsBuilder: (item, dataIds) => {
										return {
											data: {
												status: item.data.status,
												status__view: { label: getStatusConfig(item.data.status).label, value: item.data.status },
												ignoreWarnings: true,
											},
											dataIds: {
												carrierId: item.carrier ?? item.data?.carrier_id,
												tripId: item._id,
												userId: dataIds.userId,
											},
										};
									},
									confirmation: ({ updatedData, originalItem }, onSubmit, onCancel) => {
										let noAskLocationFor = ["Pending", "Booked", "Cancelled", "Delivered"];
										noAskLocationFor.push("At Delivery-" + originalItem.data.delivery_address);

										if (
											!noAskLocationFor.includes(updatedData.status) &&
											originalItem.tracking__view?.metadata.outdated
										) {
											return (
												<SmarthopConfirmDialog
													open={true}
													title={
														updatedData.status === "Dispatched"
															? "Would you like to update the truck location now?"
															: "Seems truck location has not been updated for quite some time, would you like to update it now?"
													}
													handleClose={() => onSubmit(updatedData)}
													handleAccept={() => {
														let addDialog = {
															viewId: "TRIP_TRACKING",
															mode: "CREATE",
															dataIds: {
																carrierId: originalItem.carrier ?? originalItem.data?.carrier_id,
																tripId: originalItem._id,
																data: originalItem.data,
																status__view: updatedData.status__view,
															},
														};
														dispatch(openLoadedFormDialog(addDialog));
														onSubmit(updatedData);
													}}
													closeMsg={"No"}
													acceptMsg={"Yes"}
												/>
											);
										}

										const driver = originalItem.driver__view;
										if (
											updatedData.status === "Delivered" &&
											driver.label &&
											driver.metadata.phone &&
											isEnabled("SEND_DRIVER_BOL_NOTIFICATION")
										) {
											const userTier = getUserTier();
											const dataSend = { ...updatedData, actions: { SEND_DRIVER_NOTIFICATION: true } };
											return (
												<SmarthopConfirmDialog
													open={true}
													title={`Would you like to notify driver ${driver.label} (${driver.metadata.phone}) to upload BOL documents for this trip?`}
													handleClose={() => onSubmit(updatedData)}
													handleAccept={() => {
														if (!processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier })) {
															dispatch(
																openFormDialog({
																	viewId: "PAYWALL_VIEW",
																	dataIds: {
																		type: "driver_notification_restriction",
																		data: { callback: onSubmit },
																		extraData: { dataSend },
																	},
																})
															);
															return;
														}
														onSubmit(dataSend);
													}}
													closeMsg="No"
													acceptMsg="Send Notification"
												/>
											);
										}
									},
									customDispatch: (item) => {
										if (isCarrier) {
											return fetchActions({ carrierId: item?.carrier });
										}
										return null;
									},
								},
						  }),
				},
				{ type: "separator" },
				...(isDriver && isInvestor
					? [
							{
								key: "data.broker",
								type: "component",
								grid: { headerTitle: true },
								builder: (item) => <GridTitle item={item} readOnly={readOnly} />,
								label: "Trip",
								export: {
									builder: (item) => `# ${item?.data?.load_id} - ${item?.data?.broker ?? ""}`,
								},
								table: { minWidth: 170 },
							},
					  ]
					: [
							{
								key: "trip__view",
								type: "trips__view",
								label: "Trip",
								viewConfig: {
									readOnly,
									showBol: true,
									showBroker: true,
									showSmartPay: true,
								},
								export: {
									builder: (item) => `# ${item?.data?.load_id} - ${item?.data?.broker ?? ""}`,
								},
								table: { minWidth: 170 },
							},
					  ]),
				{ type: "separator" },
				...(hasPermissionEdit
					? [
							...(isMainHandler || isInternal
								? [
										{
											key: "carrier__view",
											type: "carrier__view",
											label: "Carrier",
											viewConfig: {
												showMcNumber: true,
												showCarrierAccountType: true,
												showTestCarrier: true,
											},
											table: { width: 250, minWidth: 230 },
											editable: (item) => !item.carrier,
											edit: {
												urlPUT: isInternal
													? "api/booking/trips/:tripId/carrier"
													: "api/booking/carriers/:carrierId/trips/:tripId/carrier",
												triggerEvent: "tripsRevision",
												field: {
													key: "carrier",
													type: "autocomplete",
													required: true,
													classes: { cell: "min-w-192 max-w-216" },
													autocomplete: {
														provider: "smarthop",
														url: "api/profile/carriers",
														params: {
															options_active_only: true,
															options_my_assigned: false,
														},
														listenEvent: "profileRevision",
														placeholder: "Select One",
														disableClearable: true,
														preloadDefault: true,
														showDescription: true,
													},
												},
												paramsBuilder: (item, dataIds) => {
													return {
														data: { carrier: item.carrier, carrier__view: item.carrier__view },
														dataIds: { ...dataIds, tripId: item._id },
													};
												},
											},
										},
								  ]
								: []),
							{
								key: "truck__view",
								type: "truck__view",
								label: "Truck",
								viewConfig: {
									readOnly,
									showPhone: false,
									showTeam: true,
								},
								table: { minWidth: 230 },
								editable: (item) => !item.truck,
								edit: {
									urlPUT: "api/booking/carriers/:carrierId/trips/:tripId/truck",
									triggerEvent: "tripsRevision",
									field: {
										key: "truck",
										type: "autocomplete",
										required: true,
										classes: { cell: "min-w-192 max-w-216" },
										autocomplete: {
											provider: "smarthop",
											url: "api/profile/trucks",
											params: {
												options_search_truck_plan: true,
												options_metadata_truck: true,
												options_metadata_driver: true,
												options_active_only: true,
												label_format_show_driver: true,
												//options_format_show_capacity: true,
												options_format_show_truckinfo: true,
											},
											actionOptions: [
												{
													value: "ADD_NEW_TRUCK",
													label: "Add New Truck",
													icon: "add",
													disabled: isDispatcher || isInvestor ? true : false,
													dialog: { viewId: "TRUCK_VIEW", dataIds: { mode: "CREATE" }, mode: "CREATE" },
												},
											],
											listenEvent: "profileRevision",
											placeholder: "Select One",
											preloadDefault: true,
											showDescription: true,
											disableClearable: true,
										},
										rules: {
											params: {
												valueRef: "carrier",
												paramKey: "options_carrier_id_search",
											},
										},
									},
									confirmation: ({ updatedData, originalItem }, onSubmit, onCancel) => {
										return (
											<TripEmptyLocationDialog
												carrierId={originalItem.carrier}
												tripId={originalItem.trip}
												truckId={updatedData.truck}
												currentLocation={updatedData.truck__view?.metadata?.current_location}
												onSubmit={(locationData) => {
													// Combining original truck data selected in table and location
													const processedData = { ...updatedData, ...locationData };
													// View not need to update trip details
													delete processedData.current_location__view;
													delete processedData.truck__view;
													onSubmit(processedData);
												}}
												onCancel={onCancel}
											/>
										);
									},
									paramsBuilder: (item, dataIds) => {
										return {
											data: { carrier: item.carrier, truck: item.truck, truck__view: item.truck__view },
											dataIds: { ...dataIds, tripId: item._id, carrierId: item.carrier },
										};
									},
								},
							},
					  ]
					: [
							...(isMainHandler || isInternal
								? [
										{
											key: "carrier",
											type: "carrier__view",
											label: "Carrier",
											builder: (item) => item?.carrier__view,
											viewConfig: {
												showMcNumber: true,
												showCarrierAccountType: true,
												showTestCarrier: true,
											},
											export: {
												builder: (item) =>
													`${item?.carrier__view?.label} - MC ${item?.carrier__view?.metadata?.mc_number}`,
											},
											table: { width: 230, minWidth: 200 },
										},
								  ]
								: []),
							{
								key: "truck__view",
								type: "truck__view",
								label: "Truck",
								viewConfig: {
									readOnly,
									showPhone: false,
									showTeam: true,
									showTrailer: true,
								},
								table: { minWidth: 220 },
							},
					  ]),
				{ type: "separator" },
				{
					key: "data.username",
					builder: (item) => item?.creator__view,
					type: "user__view",
					label: "Creator",
					viewConfig: {
						readOnly,
						showRole: false,
						showDate: true,
					},
					group: "creator_data",
					table: { minWidth: 170 },
				},
				{ type: "separator", group: "creator_data" },
				{
					key: "investor__view",
					builder: (item) => item?.investor__view,
					type: "user__view",
					label: "Investor",
					viewConfig: {
						readOnly,
						showRole: false,
					},
					group: "investor_data",
					table: { minWidth: 170 },
				},
				{ type: "separator", group: "investor_data" },
				{
					builder: (item) => {
						return (
							<div className="flex flex-col">
								<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
									{item.data?.pickup_date ? formatDate(item.data.pickup_date, item.data.pickup_window_date) : "-"}
								</Typography>
								<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
									{getAddressCity(item.data?.pickup_address)}
								</Typography>
							</div>
						);
					},
					key: "data.pickup_date",
					type: "component",
					label: "Pickup",
					table: { width: 210, minWidth: 140 },
					export: {
						builder: (item) =>
							item.data?.pickup_date ? formatDate(item.data.pickup_date, item.data.pickup_window_date) : "-",
					},
				},
				{
					key: "data.pickup_shipper",
					type: "text",
					label: "Shipper",
					group: "all_data",
					collapsibleLength: 60,
					table: { width: 220, minWidth: 220 },
				},
				{
					key: "data.pickup_address",
					type: "text",
					label: "Origin",
					maxLengthStart: 60,
					group: "all_data",
					collapsibleLength: 50,
					table: { width: 250, minWidth: 250 },
				},
				{ type: "separator", group: "all_data" },
				{
					builder: (item) => {
						return (
							<div className="flex flex-col">
								<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13 ">
									{item.data?.delivery_date ? formatDate(item.data.delivery_date, item.data.delivery_window_date) : "-"}
								</Typography>
								<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
									{getAddressCity(item.data?.delivery_address)}
								</Typography>
							</div>
						);
					},
					key: "data.delivery_date",
					type: "component",
					label: "Delivery",
					table: { width: 220, minWidth: 150 },
					export: {
						builder: (item) =>
							item.data?.delivery_date ? formatDate(item.data.delivery_date, item.data.delivery_window_date) : "-",
					},
				},
				{
					key: "data.delivery_shipper",
					type: "text",
					label: "Consignee",
					group: "all_data",
					collapsibleLength: 60,
					table: { width: 220, minWidth: 220 },
				},
				{
					key: "data.delivery_address",
					type: "text",
					label: "Destination",
					group: "all_data",
					collapsibleLength: 80,
					table: { width: 250, minWidth: 250 },
				},
				{ type: "separator", group: "invoice" },
				{
					key: "invoice__view",
					type: "invoicing__view",
					label: "Invoice",
					group: "invoice",
					viewConfig: {
						showStatus: true,
					},
					export: {
						skip: true,
					},
				},
				{ type: "separator", group: "files" },
				{
					builder: (item) =>
						item?.pod_file__flag ? (
							<Icon className="text-20">check</Icon>
						) : (
							<Icon className="text-20 text-grey">close</Icon>
						),
					key: "pod_file__flag",
					type: "text",
					label: "BOL",
					group: "files",
					export: {
						skip: true,
					},
					table: {
						align: "center",
					},
				},
				{ type: "separator", group: "tracking" },
				{
					builder: (item) =>
						[...STATUS_TRIPS.delivered, ...STATUS_TRIPS.cancelled].includes(item?.data?.status) ||
						STATUS_TRIPS.pending.includes(item?.data?.status)
							? null
							: item?.tracking__view,
					key: "tracking__view",
					type: "tracking__view",
					label: "Tracking",
					hidden: fromDashboard,
					group: "tracking",
					viewConfig: {
						readOnly,
						showETA: true,
					},
					table: { width: 220 },
				},
				{ type: "separator" },
				{
					builder: (item) => (
						<div className="flex flex-col items-end">
							<Typography className="whitespace-nowrap whitespace-normal tracking-wide leading-4 mb-2 text-12 ml:text-13 ">
								{formatCurrency(item.data?.rate, 0)}
							</Typography>
							<Typography className="whitespace-nowrap whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
								{item.data?.emptymiles > 0 ? item.data?.emptymiles + "+" : ""}
								{item.data?.miles ?? 0} mi
							</Typography>
						</div>
					),
					key: "rate",
					type: "component",
					label: "Rate",
					hidden: fromDashboard,
					export: {
						builder: (item) => item.data?.rate,
					},
					table: { align: "right", width: 100 },
				},
				{
					builder: (item) => metadataInfo("metadata_creator_type", item),
					key: "metadata_creator_type",
					type: "text",
					label: "Creator",
					group: "metadata",
				},
				{
					builder: (item) => metadataInfo("metadata_load_source", item),
					key: "metadata_load_source",
					type: "Text",
					label: "Source",
					group: "metadata",
				},
				{
					builder: (item) => metadataInfo("metadata_book_location", item),
					key: "metadata_book_location",
					type: "text",
					label: "Location",
					group: "metadata",
				},
				{
					builder: (item) => metadataInfo("metadata_book_type", item),
					key: "metadata_book_type",
					type: "text",
					label: "Type",
					group: "metadata",
				},
				{
					builder: (item) => (item.metadata_platform ? metadataInfo("metadata_platform", item) : null),
					key: "metadata_platform",
					type: "text",
					label: "Booked Platform",
					group: "metadata",
				},
				{
					type: "separator",
					table: {
						width: 8,
						sticky: true,
					},
				},
				{
					key: "menu",
					type: "menu",
					label: "",
					table: {
						width: 50,
						sticky: true,
					},
					items: [
						{
							key: "files",
							label: "Upload Files",
							dialogBuilder: (item, dataIds) => ({
								viewId: "UPLOAD_FILES_FORM",
								mode: "EDIT",
								dataIds: { tripId: item._id, carrierId: item.carrier ?? getCarrierId() },
							}),
						},
						...(hasPermissionEdit
							? [
									{
										key: "edit",
										label: "Edit Trip",
										dialogBuilder: (item, dataIds) => ({
											viewId: "TRIP_EDIT_VIEW",
											mode: "EDIT",
											dataIds: { tripId: item._id, carrierId: item.carrier ?? getCarrierId() },
										}),
									},
									{
										key: "delete",
										label: "Delete Trip",
										isVisible: (item) => item?.data?.status === "Missing Data",
										dialogBuilder: (item) => ({
											formId: "TRIP_DELETE_FORM",
											mode: "DELETE",
											dataIds: { tripId: item._id, carrierId: item.carrier ?? getCarrierId() },
										}),
									},
							  ]
							: []),
					],
				},
			],
			export: fromDashboard
				? null
				: {
						fileName: "Load Dashboard - Trips",
				  },
		},
	};
};

export default internalTripsList;
