import { combineReducers } from "@reduxjs/toolkit";
import formDialog from "./formDialogSlice";
import revision from "./revisionSlice";
import popovers from "./popoverSlice";
import pannels from "./pannelsSlice";
import table from "./tableSlice";
import formTools from "./formToolsSlice";
import widgets from "./widgetsSlice";
import menu from "./menuSlice";
import tutorialDialog from "./tutorialDialogSlice";

const toolsReducers = combineReducers({
	formDialog,
	revision,
	table,
	pannels,
	popovers,
	formTools,
	widgets,
	menu,
	tutorialDialog,
});

export default toolsReducers;
