import { Typography } from "@material-ui/core";
import Markdown from "react-markdown";

export const adminReleaseFormConfig = {
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	idKey: "_id",
	form: {
		preventClose: { enable: false },
	},
	items: [
		{ key: "version", type: "number", label: "Version" },
		{
			key: "status",
			type: "select",
			label: "Status",
			options: [
				{
					label: "Published",
					value: "PUBLISHED",
				},
				{
					label: "Draft",
					value: "DRAFT",
				},
			],
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "notes", type: "text", label: "Release Notes", multiline: { rows: 20 } },
					{
						key: "markdown",
						type: "component",
						builder: (item) => (
							<div className="flex flex-col content-start items-start m-8 -mt-32">
								<Typography className="text-lg">Markdown Preview</Typography>
								<div className="flex flex-col overflow-y-scroll" style={{ height: "300px" }}>
									<Markdown>{item.notes}</Markdown>
								</div>
							</div>
						),
					},
				],
			},
		},
		{
			type: "action",
			action: "SUBMIT",
			label: "Submit",
		},
	],
};

export default adminReleaseFormConfig;
