// Dependencies
import { useEffect, forwardRef } from "react";

// Tools and Utils
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { formatDate } from "../utils/dateUtils";

const CarrierReportSummaryView = forwardRef(({ dataIds, setTitle, reportDetails }, _) => {
	useEffect(() => {
		setTitle?.("Report");
		// eslint-disable-next-line
	}, []);

	// Revisions

	return (
		<div className={"flex flex-col h-full w-full overflow-x-hidden"}>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<DisplaySection
					classes={{ root: "md:w-full" }}
					title={
						<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
							<div>Report Details</div>
						</div>
					}
				>
					<div className="-mt-10">
						{renderDataItem("Report Type", reportDetails?.type, "", {})}
						{renderDataItem("Period", reportDetails?.period, "", {})}
						{renderDataItem("Start Date", formatDate(reportDetails?.start_date), "", {})}
						{renderDataItem("End Date", formatDate(reportDetails?.end_date), "", {})}
						{renderDataItem("Aggregated by", reportDetails?.aggregated_by, "", {})}
						{renderDataItem("Trips counts", reportDetails?.trips_count, "", {})}
					</div>
				</DisplaySection>
			</div>
		</div>
	);
});

export default CarrierReportSummaryView;
