// Services
import { getRoleLabel } from "app/services/LoginService";

const cards = ({ accountTypes, isAdmin }) => ({
	urlGET: `api/wallet/${isAdmin ? "admin/" : ""}cards`,
	idKey: "id",
	listenEvent: "smarthopWalletRevision",
	triggerEvent: "smarthopWalletRevision",
	segmentKey: "CARRIER_SMARTHOP_WALLET_DEBIT_CARDS",
	otp: getRoleLabel() === "carrier" && ["WALLET_ACCESS"],
	content: {
		table: {
			variant: "skinny",
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			default: {
				accountId: accountTypes?.find((account) => account.purpose === "checking")?.value,
				sortBy: "createdAt",
				sortOrder: "desc",
			},
		},
		items: [
			{ key: "attributes.createdAt", type: "date", label: "Request Date" },
			{ key: "attributes.last4Digits", type: "text", label: "Last 4 digits" },
			{
				key: "assigned-name",
				type: "text",
				label: "Assigned Name",
				builder: (item) => item.attributes?.fullName?.first + " " + item.attributes?.fullName?.last,
			},
			{
				key: "attributes.status",
				type: "text",
				label: "Status",
				builder: (item) => (item.attributes?.status === "ClosedByCustomer" ? "Closed" : item.attributes.status),
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "freeze",
						label: "Freeze",
						auth: ["administrator", "carrier"],
						isVisible: (item) => item.attributes?.status === "Active",
						dialogBuilder: (item) => ({
							formId: "SMARTPAY_CONFIRM_CARD_FREEZE_FORM",
							mode: "CREATE",
							dataIds: { id: item.id },
						}),
					},
					{
						key: "unfreeze",
						label: "Unfreeze",
						auth: ["administrator", "carrier"],
						isVisible: (item) => item.attributes?.status === "Frozen",
						dialogBuilder: (item) => ({
							formId: "SMARTPAY_CONFIRM_CARD_UNFREEZE_FORM",
							mode: "CREATE",
							dataIds: { id: item.id },
						}),
					},
					{
						key: "replace",
						label: "Replace",
						auth: ["administrator", "carrier"],
						isVisible: (item) => ["Inactive", "Active"].includes(item.attributes?.status),
						dialogBuilder: (item) => ({
							formId: "SMARTPAY_CONFIRM_CARD_REPLACEMENT_FORM",
							mode: "CREATE",
							dataIds: { id: item.id },
						}),
					},
					{
						key: "close",
						label: "Close",
						auth: ["administrator"],
						isVisible: (item) => item.attributes?.status !== "ClosedByCustomer",
						dialogBuilder: (item) => ({
							formId: "SMARTPAY_CONFIRM_CARD_CLOSE_FORM",
							mode: "CREATE",
							dataIds: { id: item.id },
						}),
					},
				],
			},
		],
	},
});

export default cards;
