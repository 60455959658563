import moment from "moment";
import { WINDOW_MAPPINGS } from "./windwoOptions";

const GRAPH_UNIT = {
	key: "graphUnit",
	type: "select",
	label: "Unit",
	field: { noErrorMessage: true },
	defaultValue: "__DEFAULT__",
	options: [
		{ value: "__DEFAULT__", label: "Default" },
		{ value: "CURRENCY", label: "Currency" },
		{ value: "MILES", label: "Miles" },
		{ value: "MS", label: "Milliseconds" },
		{ value: "PERSENTS", label: "Persentage" },
	],
};

const GRAPH_TABLE_MAIN_COLUMN = {
	key: "graphTableMainColumn",
	type: "select",
	label: "Primary Column",
	field: { noErrorMessage: true },
	defaultValue: null,
	options: [
		{ value: "OWNER", label: "Owner" },
		{ value: "ENTITY", label: "Entity" },
		{ value: "LINK", label: "Link" },
	],
};

const GRAPH_LABLE_STYLE = {
	key: "graphLabelStyle",
	type: "select",
	label: "Label Style",
	field: { noErrorMessage: true },
	defaultValue: "__DEFAULT__",
	options: [
		{ value: "__DEFAULT__", label: "Default" },
		{ value: "FLAT", label: "Flat" },
	],
};

const GRAPH_HEIGHT = {
	key: "graphHeightAuto",
	type: "checkbox",
	label: "Automatically Adjust Hight (View Mode Only)",
	field: { noErrorMessage: true },
};

const TABLE_SORTABLE = {
	key: "sortable",
	type: "checkbox",
	label: "Is sortable?",
};

const GRAPH_TYPE = {
	key: "graphType",
	type: "select",
	label: "Type",
	field: { noErrorMessage: true },
	defaultValue: null,
	options: [
		{ value: "LAST_VALUE", label: "Labels", showItems: [GRAPH_LABLE_STYLE, GRAPH_UNIT] },
		{ value: "TABLE", label: "Table", showItems: [GRAPH_TABLE_MAIN_COLUMN, GRAPH_HEIGHT, TABLE_SORTABLE] },
		{ value: "BAR", label: "Bar", showItems: [GRAPH_UNIT] },
		{ value: "AREA", label: "Area", showItems: [GRAPH_UNIT] },
		{ value: "DONUT", label: "Donut", showItems: [GRAPH_UNIT] },
		{ value: "HEATMAP", label: "Heatmap", showItems: [GRAPH_HEIGHT] },
	],
};

const AGGREGATION_FIEDS = {
	key: "aggregationFields",
	type: "autocomplete",
	label: "Aggregation Fields",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/analytics/data/metrics-fields?metricId=:metricId",
		multiple: true,
		preloadDefault: true,
		listenEvent: "analyticsJobsRevision",
	},
	rules: {
		params: {
			valueRef: "metric",
			paramKey: "options_metric_id_search",
		},
	},
};
const AGGREGATION_PERIOD = {
	key: "aggregationPeriod",
	type: "select",
	label: "Group Period By",
	field: { noErrorMessage: true },
	defaultValue: null,
	options: [
		{ value: "ALL", label: "All" },
		{ value: "HOUR", label: "Hour" },
		{ value: "DAY", label: "Day" },
		{ value: "WEEK", label: "Week" },
		{ value: "MONTH", label: "Month" },
		{ value: "YEAR", label: "Year" },
	],
};
const AGGREGATION_VISUALZATION = {
	key: "aggregationVisualization",
	type: "select",
	label: "Visualization",
	field: { noErrorMessage: true },
	defaultValue: "__DEFAULT__",
	options: [
		{ value: "__DEFAULT__", label: "Default" },
		{ value: "CUMULATIVE", label: "Cumulative" },
	],
};
const AGGREGATION_CUT_OFF_COUNT = {
	key: "aggregationCutOffCount",
	type: "number",
	label: "Show Top N Items",
	field: { noErrorMessage: true },
};
const AGGREGATION_WINDOW_OPTIONS = [
	{ value: "DAY_1", label: "Today" },
	{ value: "DAY_2_1", label: "Yesterday" },
	{
		value: "DAY_7",
		label: "Last 7 Days (" + moment().subtract(7, "days").format("MM/DD") + " - " + moment().format("MM/DD") + ")",
	},
	{
		value: "DAY_14_7",
		label:
			"Previous 7 Days (" +
			moment().subtract(14, "days").format("MM/DD") +
			" - " +
			moment().subtract(7, "days").format("MM/DD") +
			")",
	},
	{
		value: "MONTH_-1",
		label: "Last 30 Days (" + moment().subtract(1, "month").format("MM/DD") + " - " + moment().format("MM/DD") + ")",
	},
	{
		value: "MONTH_-2_-1",
		label:
			"Previous 30 Days (" +
			moment().subtract(2, "month").format("MM/DD") +
			" - " +
			moment().subtract(1, "month").format("MM/DD") +
			")",
	},
	{
		value: "WEEK_1",
		label: "WTD (" + moment().startOf("isoWeek").format("MM/DD") + " - " + moment().format("MM/DD") + ")",
	},
	{
		value: "WEEK_2_1",
		label:
			"Last Week (" +
			moment().subtract(1, "week").startOf("isoWeek").format("MM/DD") +
			" - " +
			moment().subtract(1, "week").endOf("isoWeek").format("MM/DD") +
			")",
	},
	{
		value: "WEEK_3_2",
		label:
			"Previous Week (" +
			moment().subtract(2, "week").startOf("isoWeek").format("MM/DD") +
			" - " +
			moment().subtract(2, "week").endOf("isoWeek").format("MM/DD") +
			")",
	},
	{ value: "MONTH_1", label: "MTD (" + moment().format("MMMM") + ")" },
	{ value: "MONTH_2_1", label: "Month Of " + moment().subtract(1, "month").format("MMMM") },
	{ value: "MONTH_3_2", label: "Month Of " + moment().subtract(2, "month").format("MMMM") },
	{
		value: "MONTH_2",
		label: moment().format("MMMM") + " - " + moment().subtract(1, "month").format("MMMM") + " (2 Months)",
	},
	{
		value: "MONTH_6",
		label: moment().format("MMMM") + " - " + moment().subtract(5, "month").format("MMMM") + " (6 Months)",
	},
	{
		value: "MONTH_12",
		label: moment().format("MMMM") + " - " + moment().subtract(11, "month").format("MMMM") + " (12 Months)",
	},
	{ value: "YEAR_1", label: "YTD (" + moment().format("YYYY") + ")" },
	{ value: "YEAR_2_1", label: "Year Of " + moment().subtract(1, "year").format("YYYY") },
	{ value: "__ALL_TIME__", label: "All Time" },
];
const AGGREGATION_WINDOW_MAIN = {
	key: "aggregationWindowMain",
	type: "select",
	label: "Primary Time Window",
	field: { noErrorMessage: true },
	defaultValue: null,
	options: AGGREGATION_WINDOW_OPTIONS,
};
const AGGREGATION_EXCLUDE_TEST_DATA_TYPE = {
	key: "aggregationExcludeTestData",
	type: "checkbox",
	label: "Exclude Test Data",
	field: { noErrorMessage: true },
};
const AGGREGATION_EXCLUDE_INACTIVE_CARRIERS = {
	key: "aggregationExcludeInactiveCarriers",
	type: "checkbox",
	label: "Exclude Carriers with no Tier",
	field: { noErrorMessage: true },
};
const AGGREGATION_ENFORCE_PERIOD_TYPE = {
	key: "aggregationEnforcePeriodType",
	type: "checkbox",
	label: "Disable 'Group Period By' Override",
	field: { noErrorMessage: true },
};
const AGGREGATION_ENFORCE_WINDOW_TYPE = {
	key: "aggregationEnforceWidowType",
	type: "checkbox",
	label: "Disable 'Time Window' Override",
	field: { noErrorMessage: true },
};
const AGGREGATION_IGNORE_FUTURE_DATA = {
	key: "aggregationIgnoreFutureData",
	type: "checkbox",
	label: "Ignore Future Data",
	field: { noErrorMessage: true },
};
const AGGREGATION_WINDOW_SECONDARY = {
	key: "aggregationWindowSecondary",
	type: "select",
	label: "Secondary Time Window",
	field: { noErrorMessage: true },
	defaultValue: null,
	options: [{ value: "__NOT_SELECTED__", label: "Not Selected" }, ...AGGREGATION_WINDOW_OPTIONS],
};
const AGGREGATION_TYPE = {
	key: "aggregationType",
	type: "select",
	label: "Aggregator",
	required: true,
	field: { noErrorMessage: true },
	defaultValue: null,
	options: [
		{ value: null, label: "Not Selected" },
		{ value: "EXISTS", label: "Exists" },
		{ value: "COUNT", label: "Count" },
		{ value: "SUM", label: "Sum" },
		{ value: "MIN", label: "Min" },
		{ value: "AVG", label: "Avg" },
		{ value: "MAX", label: "Max" },
		{ value: "UNIQUE_ENTITIES_COUNT", label: "Unique Entities Count" },
		{ value: "UNIQUE_OWNERS_COUNT", label: "Unique Owners Count" },
		{
			value: "UNIQUE_LINK_COUNT",
			label: "Unique Links Count",
			showItems: [
				{
					key: "aggregationUniqueType",
					type: "autocomplete",
					label: "Unique Link Type",
					field: { noErrorMessage: true },
					autocomplete: {
						provider: "smarthop",
						url: "api/analytics/data/metrics-fields?metricId=:metricId&uniqueLinks=true",
						preloadDefault: true,
						listenEvent: "analyticsJobsRevision",
					},
					rules: {
						params: {
							valueRef: "metric",
							paramKey: "options_metric_id_search",
						},
					},
				},
			],
		},
	],
};
const EXPRESSION_VALUE = {
	key: "expression",
	type: "text",
	label: "Expression (Reverse Polish Notation)",
	field: { noErrorMessage: true },
};
const ADDON_TYPE = {
	key: "key",
	type: "select",
	label: "Addon",
	required: true,
	field: { noErrorMessage: true },
	defaultValue: null,
	options: [
		{ value: null, label: "Not Selected" },
		{ value: "CARRIER_PROJECTED_EXPENSES", label: "Carrier Projected Expenses" },
		{ value: "CARRIER_VAN_GOAL_REVENUE", label: "Carrier Van Revenue Goal" },
		{ value: "CARRIER_REEFER_GOAL_REVENUE", label: "Carrier Refeer Revenue Goal" },
		{ value: "CARRIER_PO_GOAL_REVENUE", label: "Carrier Power Only Revenue Goal" },
		{ value: "CARRIER_FLATBED_GOAL_REVENUE", label: "Carrier Flatbed Revenue Goal" },
		{ value: "CARRIER_VAN_GOAL_RPM", label: "Carrier Van RPM Goal" },
		{ value: "CARRIER_REEFER_GOAL_RPM", label: "Carrier Refeer RPM Goal" },
		{ value: "CARRIER_FLATBED_GOAL_RPM", label: "Carrier Flatbed RPM Goal" },
		{ value: "CARRIER_PO_GOAL_RPM", label: "Carrier Power Only RPM Goal" },
		{ value: "CARRIER_GOAL_REVENUE", label: "Carrier Goal Revenue" },
		{ value: "CARRIER_GOAL_RPM", label: "Carrier Goal RPM" },
	],
};
const METRIC_LABEL = {
	key: "prefix",
	type: "text",
	label: "Prefix",
	field: { noErrorMessage: true },
};
const METRIC_COLOR = {
	key: "color",
	type: "select",
	label: "Color",
	field: { noErrorMessage: true },
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Not Selected" },
		{ value: "PRIMARY", label: "Primary" },
		{ value: "SECONDARY", label: "Secondary" },
		{ value: "GREEN", label: "Green" },
		{ value: "ORANGE", label: "Orange" },
		{ value: "BLUE", label: "Blue" },
		{ value: "PINK", label: "Pink" },
		{ value: "BROWN", label: "Brown" },
		{ value: "CYAN", label: "Cyan" },
		{ value: "INDIGO", label: "Indigo" },
		{ value: "PURPLE", label: "Purple" },
		{ value: "POSITIVE_GREEN__NEGATIVE_RED", label: "Dynamic(IF value > 0 THEN Green ELSE Red)" },
	],
};
const METRIC_COMPARISON_GROWTH = {
	key: "comparisonModel",
	type: "select",
	label: "Comparison Model (Label Graph Only)",
	field: { noErrorMessage: true },
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Not Selected" },
		{ value: "UP_GOOD", label: "Up Good" },
		{ value: "DOWN_GOOD", label: "Down Good" },
	],
};
const METRIC_FILTER = {
	key: "metric",
	type: "autocomplete",
	label: "Metric",
	required: true,
	field: { noErrorMessage: true },
	autocomplete: { provider: "smarthop", url: "api/analytics/data/metrics?type=VALUE_IS_KEY", preloadDefault: true },
};
const CARRIER_FILTER = {
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	field: { noErrorMessage: true },
	autocomplete: { provider: "smarthop", url: "api/profile/carriers", preloadDefault: true },
};
const TRUCK_FILTER = {
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	field: { noErrorMessage: true },
	autocomplete: { provider: "smarthop", url: "api/profile/trucks", preloadDefault: true },
	rules: { params: { valueRef: "carrier", paramKey: "options_carrier_id_search" } },
};
const OWNER_FILTER = {
	key: "owner",
	type: "text",
	label: "Owner",
	field: { noErrorMessage: true },
};
const ENTITY_FILTER = {
	key: "entity",
	type: "text",
	label: "Entity",
	field: { noErrorMessage: true },
};
const PARAM_FILTER = {
	key: "params",
	type: "text",
	label: "Params",
	field: { noErrorMessage: true },
};
const FILTERS = {
	key: "filters",
	type: "select",
	label: "Filters",
	field: { noErrorMessage: true },
	defaultValue: "__NOT_SELECTED__",
	options: [
		{ value: "__NOT_SELECTED__", label: "Disabled" },
		{
			value: "ENABLED",
			label: "Enabled",
			showItems: [
				{
					type: "group",
					group: { classes: { root: "" } },
					content: { items: [PARAM_FILTER] },
				},
				{
					type: "group",
					group: { classes: { root: "" } },
					content: { items: [OWNER_FILTER, ENTITY_FILTER] },
				},
				{
					type: "group",
					group: { classes: { root: "" } },
					content: { items: [CARRIER_FILTER, TRUCK_FILTER] },
				},
			],
		},
	],
};
const WIDGET_TYPE = {
	key: "type",
	type: "select",
	label: "Type",
	field: { noErrorMessage: true },
	defaultValue: "__DYNAMIC__",
	options: [
		{ value: "__DYNAMIC__", label: "Dynamic" },
		{ value: "actions", label: "[Static] Actions" },
		{ value: "performance", label: "[Static] performance" },
		{ value: "trip", label: "[Static] Trip" },
		{ value: "smartpay", label: "[Static] Smartpay" },
		{ value: "fuel", label: "[Static] Fuel" },
		{ value: "calendar", label: "[Static] Calendar" },
		{ value: "live-tracking", label: "[Static] Tracking" },
		{ value: "markets", label: "[Static] Lane Market Conditions" },
		{ value: "assigned-trucks", label: "[Static] Assigned Trucks" },
		{ value: "profile", label: "[Static] Profile" },
		{ value: "trip-user", label: "[Static] Trip Users" },
		{ value: "active-trips", label: "[Static] Active Trips" },
		{ value: "performance-investor", label: "[Static] Performance Investor" },
		{ value: "invoice-investor", label: "[Static] Invoice Investor" },
	],
};
const WIDGET_LABEL = {
	key: "label",
	type: "text",
	label: "Label",
	field: { noErrorMessage: true },
};
const WIDGET_DESCRIPTION = {
	key: "description",
	type: "text",
	label: "Description",
	multiline: { rows: 4 },
};
const WIDGET_VISIBILITY = {
	key: "noShowMobile",
	type: "checkbox",
	label: "Hidden on Mobile",
	field: { noErrorMessage: true },
};
const WIDGET_SMARTPAY = {
	key: "onlyShowIfSmartpay",
	type: "checkbox",
	label: "Hidden if smartpay is disabled",
	field: { noErrorMessage: true },
};
const ACTION = {
	type: "action",
	action: "SUBMIT",
	label: "Update",
	button: {
		loadingState: false,
		alwaysEnabled: true,
		classes: { root: "mt-10" },
	},
};

const EXPRESSION_SOURCE = {
	key: "expressionSource",
	type: "number",
	label: "Source",
	field: { noErrorMessage: true },
};
const EXPRESSION_TARGET = {
	key: "expressionTarget",
	type: "number",
	label: "Target",
	field: { noErrorMessage: true },
};

const OVERRIDE_FIELD = {
	key: "field",
	type: "select",
	label: "Field to Override",
	field: { noErrorMessage: true },
	defaultValue: "AGGREGATION_PERIOD",
	options: [
		{
			value: "AGGREGATION_PERIOD",
			label: "AGGREGATION_PERIOD",
			showItems: [AGGREGATION_PERIOD],
		},
		{ value: "AGGREGATION_VISUALZATION", label: "AGGREGATION_VISUALZATION", showItems: [AGGREGATION_VISUALZATION] },
		{ value: "GRAPH_TYPE", label: "GRAPH_TYPE", showItems: [GRAPH_TYPE] },
		{ value: "EXPRESSION", label: "EXPRESSION", showItems: [EXPRESSION_SOURCE, EXPRESSION_TARGET] },
		{ value: "WIDGET_LABEL", label: "WIDGET_LABEL", showItems: [WIDGET_LABEL] },
	],
};

const OVERRIDE_TIME_SELECT = {
	key: "timePeriod",
	type: "select",
	label: "Time Period",
	field: { noErrorMessage: true },
	defaultValue: WINDOW_MAPPINGS().MONTH.default.main,
	options: WINDOW_MAPPINGS().MONTH_RECENT.options.map((o) => ({ value: o.main, label: o.label })),
};

const OVERRIDES = {
	key: "overrides",
	type: "pattern",
	label: "",
	pattern: { classes: { label: "pt-10 font-semibold text-16" }, showAllDefault: true },
	content: { items: [OVERRIDE_TIME_SELECT, OVERRIDE_FIELD] },
};

const formDynamicGraph = {
	items: [
		{ type: "section", label: "Graph", section: { classes: { divider: "-mb-2" } } },
		GRAPH_TYPE,
		{ type: "section", label: "Overrides", section: { classes: { label: "mt-4", divider: "-mb-2" } } },
		OVERRIDES,
		{ type: "section", label: "Aggregation", section: { classes: { label: "mt-4", divider: "-mb-2" } } },
		AGGREGATION_PERIOD,
		AGGREGATION_VISUALZATION,
		AGGREGATION_CUT_OFF_COUNT,
		AGGREGATION_WINDOW_MAIN,
		AGGREGATION_WINDOW_SECONDARY,
		{
			type: "group",
			group: { classes: { root: "mt-12" } },
			content: { items: [AGGREGATION_IGNORE_FUTURE_DATA, AGGREGATION_EXCLUDE_TEST_DATA_TYPE] },
		},
		{
			type: "group",
			group: { classes: { root: "mt-12" } },
			content: { items: [AGGREGATION_ENFORCE_PERIOD_TYPE, AGGREGATION_ENFORCE_WINDOW_TYPE] },
		},
		{
			type: "group",
			group: { classes: { root: "mt-12" } },
			content: { items: [AGGREGATION_EXCLUDE_INACTIVE_CARRIERS] },
		},
		{
			key: "metrics",
			type: "pattern",
			label: "Metrics",
			pattern: { classes: { label: "pt-10 font-semibold text-16" }, showAllDefault: true },
			content: { items: [METRIC_FILTER, AGGREGATION_TYPE, AGGREGATION_FIEDS, METRIC_LABEL, FILTERS] },
		},
		{
			key: "expressions",
			type: "pattern",
			label: "Expressions",
			pattern: { classes: { label: "pt-10 font-semibold text-16" }, showAllDefault: true },
			content: { items: [EXPRESSION_VALUE, METRIC_LABEL, METRIC_COLOR, METRIC_COMPARISON_GROWTH] },
		},
		{
			key: "addons",
			type: "pattern",
			label: "Addons",
			pattern: { classes: { label: "pt-10 font-semibold text-16" }, showAllDefault: true },
			content: { items: [ADDON_TYPE] },
		},
		ACTION,
	],
};

const formDynamicCard = {
	items: [
		{ type: "section", label: "Widget", section: { classes: { label: "-mt-4", divider: "-mb-2" } } },
		WIDGET_TYPE,
		WIDGET_LABEL,
		WIDGET_DESCRIPTION,
		WIDGET_VISIBILITY,
		WIDGET_SMARTPAY,
		...formDynamicGraph.items,
	],
};

const formStaticCards = {
	items: [
		{ type: "section", label: "Widget", section: { classes: { label: "-mt-4", divider: "-mb-2" } } },
		WIDGET_TYPE,
		WIDGET_DESCRIPTION,
		WIDGET_VISIBILITY,
		ACTION,
	],
};

const formTextConfigCards = {
	items: [{ key: "config", type: "text", label: "Config", multiline: { rows: 25 } }, ACTION],
};

export { formDynamicGraph, formDynamicCard, formStaticCards, formTextConfigCards };
