import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";

export default build([
	{
		path: "/profile/integrations/brokers",
		component: lazy(() => import("./BrokersPage")),
	},
	{
		path: "/profile/carriers/:carrierId/integrations/brokers",
		component: lazy(() => import("./BrokersPage")),
	},
]);
