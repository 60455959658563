// Dependencies
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";

// Components
import { SmarthopConfirmDialog } from "@smarthop/form";

// Utils and Tools
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Services
import { getTrip } from "app/services/tripsServices";
import { updateInvoiceStatus, sendTripInvoiceEmail } from "app/services/tripInvoicingServices";
import { getCarrierBranding } from "app/services/carrierServices";

const TripInvoicingSendInvoiceView = (props) => {
	let {
		carrierId,
		tripId,
		invoiceId,
		resend,
		// If true, view sends for SmartPay flow instead
		internalMode,
	} = props.dataIds;

	//when come from react native
	if (typeof resend === "string") {
		resend = resend === "true" ? true : false;
	}

	useEffect(() => {
		props.setTitle?.(internalMode ? "Send Invoice to Broker" : "Send Invoice");
		props.setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	// States
	const [loading, setLoading] = useState(true);
	const [externalEmail, setExternalEmail] = useState();
	const [emailValue, setEmailValue] = useState();
	const [confirmationModel, setConfirmationModel] = useState();

	const sendAsCarrier = async ({ carrierId, invoiceId, emailsString }) => {
		if (resend) {
			await sendTripInvoiceEmail(carrierId, invoiceId, { recipients: emailsString, resend });
		} else {
			await updateInvoiceStatus(carrierId, invoiceId, "SUBMITTED", { recipients: emailsString });
			if (window?.ReactNativeWebView) {
				window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } }));
			}
		}
	};

	const onSubmitInvoiceHandler = () => {
		const confirmation = {
			message: "Do you want to continue with the operation?",
			acceptMsg: "Yes, send it",
			onCancel: () => {},
			onAccept: async () => {
				try {
					setLoading(true);
					// Request changing the status to generated
					if (internalMode) {
						await sendTripInvoiceEmail(carrierId, invoiceId, { recipients: emailValue, internalMode, resend });
					} else {
						await sendAsCarrier({ carrierId, invoiceId, emailsString: emailValue });
					}
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(closeFormDialog());
					showSnackbar(snackbar, "Emails sent successfully!", "success");
					setLoading(false);
				} catch (e) {
					// If update failed, show error message
					showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
					setLoading(false);
				}
			},
		};
		setConfirmationModel(confirmation);
	};

	useEffect(() => {
		(async () => {
			if (!tripId || !carrierId) return;
			const [trip, branding] = await Promise.all([getTrip(tripId, carrierId), getCarrierBranding(carrierId)]);
			const brokerEmail = trip?.mail_broker;
			if (brokerEmail) setEmailValue(brokerEmail);
			if (branding.email_smtp?.enabled) {
				const usernameEmail = branding.email_smtp?.authentication?.username;
				setExternalEmail(usernameEmail);
			}
			setLoading(false);
		})();
	}, [carrierId, tripId]);

	if (loading) {
		return (
			<div className="flex w-full h-128 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col items-top w-full space-x-0">
				{internalMode ? (
					<Typography className="whitespace-nowrap pr-4">We'll send the Invoice to the next Broker's email:</Typography>
				) : (
					<Typography className="whitespace-nowrap pr-4">
						{externalEmail
							? `This email will be sent from your own address (${externalEmail})`
							: "This email will be sent from Smarthop's address (invoices@smarthop.co)"}
					</Typography>
				)}
				<TextField
					variant="outlined"
					type="email"
					placeholder={`Recipients`}
					className="w-full mb-14 mt-6"
					value={emailValue}
					onChange={(event) => setEmailValue(event.target.value)}
				/>
				{!internalMode && (
					<Typography className="text-10 mb-9 text-grey-600">
						To change SMTP configuration go to Settings, then Details menu and SMTP Mail Configuration section.
					</Typography>
				)}{" "}
				<Button
					variant="contained"
					className={clsx("w-full", "mb-10 ")}
					color="secondary"
					onClick={() => onSubmitInvoiceHandler()}
				>
					{internalMode ? "Send Invoice to Broker" : "Send Invoice"}
				</Button>
			</div>
			<SmarthopConfirmDialog
				open={!!confirmationModel?.message}
				title={confirmationModel?.message}
				acceptMsg={confirmationModel?.acceptMsg}
				onClose={() => {
					typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
					setConfirmationModel(null);
				}}
				onAccept={() => {
					confirmationModel?.onAccept();
					setConfirmationModel(null);
				}}
			/>{" "}
		</>
	);
};

export default TripInvoicingSendInvoiceView;
