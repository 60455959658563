import moment from "moment";
import i18next from "i18next";
import es from "./i18n/es";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { darken } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import LanguageSwitcher from "app/fuse-layouts/shared-components/LanguageSwitcher";
import LandingMPIPreview from "./LandingMPIPreview";

i18next.addResourceBundle("es", "auth", es);

const useStyles = makeStyles((theme) => {
	return {
		root: {
			background: theme.palette.primary.background,
			color: theme.palette.primary.contrastText,
		},
		market: {
			background: theme.palette.primary.background,
			color: "#062246",
		},
		image: {
			background: "linear-gradient(rgb(0 0 0 / 15%), rgb(0 0 0 / 65%)), url(assets/images/carrier_house_light.jpg)",
			backgroundPosition: "center",
			backgroundSize: "cover",
			height: "100%",
		},
		leftSection: {},
		rightSection: {},
		imageOverlay: {
			background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
			opacity: 0.9,
			color: theme.palette.primary.contrastText,
		},
	};
});

function LandingPageContainer(props) {
	const classes = useStyles();

	useSelector(({ i18n }) => i18n.language);

	return (
		<div className={clsx(classes.market, "flex flex-1 flex-col flex-auto items-center justify-center flex-shrink-0")}>
			<div className="flex flex-1 w-full h-full shadow-2xl overflow-hidden">
				<Card className={clsx(classes.leftSection, "flex flex-col w-full md:max-w-md ml:max-w-lg shadow-0")} square>
					<LanguageSwitcher />
					<div className="flex flex-1 w-full h-full flex-col -mt-40 md:-mt-60 items-center justify-center ">
						<motion.div
							initial={{ opacity: 0, scale: 0.8 }}
							animate={{ opacity: 1, scale: 1, transition: { delay: 0.2 } }}
							className="flex flex-col w-full items-center justify-center"
						>
							<CardContent
								className={
									"flex flex-col items-center justify-center w-full py-20 " +
									(props.viewType === "login" ? " max-w-sm " : " max-w-md ")
								}
							>
								<div className="flex items-center mb-12 justify-center">
									<img className="logo-icon w-160 md:w-200" src="assets/images/logoSmartHop.png" alt="logo" />
								</div>
								{props.viewType === "login" ? (
									<Typography color="textSecondary" className="text-16 md:text-18 mb-8 text-center font-light">
										{i18next.t(`auth:Hi! Welcome to SmartHop, `)}
										{i18next.t(`auth:the all-in-one booking and fleet management platform`)}
										<br />
									</Typography>
								) : (
									<Typography color="textSecondary" className="text-18 mb-24 text-center font-light">
										{i18next.t(`auth:Sign Up to Start Using SmartHop's Platform`)}
									</Typography>
								)}
								{props.children}
							</CardContent>

							<div className="flex flex-col items-center justify-center pb-16">
								{props.viewType === "login" ? (
									<div>
										<div className="flex flex-row">
											<Typography className="font-normal mr-8">{i18next.t(`auth:Don't have an account?`)}</Typography>
											<Link className="font-normal" to="/register">
												<Typography className="font-normal ">{i18next.t(`auth:Register`)}</Typography>
											</Link>
										</div>
									</div>
								) : (
									<div>
										<div className="flex flex-row">
											<Typography className="font-normal mr-8">{i18next.t(`auth:Already have an account?`)}</Typography>
											<Link className="font-normal mb-32" to="/login">
												<Typography className="font-normal ">{i18next.t(`auth:Login`)}</Typography>
											</Link>
										</div>
									</div>
								)}
							</div>
						</motion.div>
					</div>
					<div className="flex flex-col items-center justify-center pt-20">
						<div
							className={
								(props.viewType === "login" ? "fixed " : "sm:fixed ") +
								"bottom-0 mb-8 flex flex-col items-center justify-center"
							}
						>
							<Link onClick={() => window.open("https://www.smarthop.com/privacy-policy", "_blank")}>
								Privacy Policy
							</Link>
							<Typography component={"div"} variant="overline" color="textSecondary">
								© {moment().format("YYYY")} All rights reserved
							</Typography>
						</div>
					</div>
				</Card>

				<div
					className={clsx(
						classes.rightSection,
						classes.image,
						"hidden md:flex flex-col flex-1 items-center justify-center"
					)}
				>
					<motion.div
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1, transition: { delay: 0.2 } }}
						className={clsx("flex flex-1 w-full justify-center")}
					>
						<LandingMPIPreview />
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default LandingPageContainer;
