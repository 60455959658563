import carrierGoalsContent from "./carrierGoalsContent";

const carrierGoalsForm = {
	urlGET: "api/goals/:goalId",
	urlPUT: "api/goals/:goalId",
	urlDELETE: "api/goals/:goalId",
	idKey: "_id",
	triggerEvent: ["goalsCarrierRevision", "goalsRevision", "profileRevision", "dataRevision"],
	header: {
		delete: { label: "Delete Goals " },
		edit: { label: "Edit Goals" },
		view: { label: "View Goals" },
	},
	messages: {
		edit: { success: "Goals updated." },
		create: { sameAs: "edit" },
		delete: { sameAs: "edit" },
	},
	content: {
		view: { sameAs: "edit" },
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete the goals ${item.name}?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		edit: {
			...carrierGoalsContent({ isEdit: true }),
		},
	},
};

export default carrierGoalsForm;
