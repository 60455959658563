import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

import { formatDate } from "app/main/utils/dateUtils";

const successStyle = {
	color: "green-400",
	hoverColor: "green-500",
	icon: "check_circle",
};
const errorStyle = {
	color: "red-700",
	hoverColor: "red-800",
	icon: "highlight_off",
};
const unexpectedStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "check_circle",
};
const progressStyle = {
	color: "#E7683D",
	marginTop: "6px",
};

const createContentView = ({ status, statusProcess, state }) => {
	let style;
	let inProgress = false;
	status = statusProcess || status;

	if (status === "FINISH" || status === "FINISHED") {
		style = successStyle;
		if (state === "BROKER_DECLINED" || state === "EXPIRED") {
			style = unexpectedStyle;
		}
	} else if (status === "FAILED") {
		style = errorStyle;
	} else if (status === "IN_PROGRESS" || status === "POSTPONED") {
		inProgress = true;
	}

	if (inProgress) {
		return (
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress size={20} style={progressStyle} />
			</Box>
		);
	}

	if (!inProgress && style) {
		return <Icon className={`text-24 mt-8 text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>;
	}

	return <Chip label={status} className="text-black" size="small" />;
};

const transactionsPinned = {
	urlGET: "api/transaction/users/:userId/records",
	idKey: "_id",
	listenEvent: "transactionsRevision",
	segmentKey: "TRANSACTIONS_PINNED_STATE_ALL",
	content: {
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		grid: {
			theme: "light",
			variant: "skinny",
			tilesPerRow: 1,
			itemsPerRow: 3,
			mobile: {
				tilesPerRow: 1,
				itemsPerRow: 2,
			},
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		items: [
			{
				key: "statusProcess",
				label: "Status",
				type: "text",
				builder: (item) => createContentView(item),
				grid: { headerTitle: true },
			},
			{
				key: "type",
				type: "text",
				label: "Type",
				grid: { headerTitle: true },
			},
			{
				key: "status",
				type: "text",
				label: "Status",
			},
			{
				key: "createdAt",
				type: "text",
				label: "Created",
				builder: (item) => formatDate(item.createdAt),
			},
		],
	},
};

export default transactionsPinned;
