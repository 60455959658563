import { useMemo } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Typography, CircularProgress } from "@material-ui/core";
import TransactionBidBookView from "app/main/profile/transactionsState/components/TransactionBidBookView";
import TransactionReportView from "app/main/profile/transactionsState/components/TransactionReportView";
import { isEnabled } from "app/services/featureStorageService";
//Component
import TransactionActionView from "./TransactionActionView";

const useStyles = makeStyles((theme) => ({
	contentHeight: {
		height: "80vh",
	},
	centered: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
}));

function TransactionStateCurrentList() {
	const transactions = useSelector(({ transactions }) => transactions.transactionsUser.transactions);
	const errors = useSelector(({ transactions }) => transactions.transactionsUser.errors);

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);
	const newUIEnabled = isEnabled("NEW_TRANSACTION_UI");
	const classes = useStyles();

	const viewTransaction = (transactions, type) => {
		if (!transactions?.length) {
			return (
				<Typography className="text-12 lg:text-13 font-light text-center">
					{type === "active" ? "No active transactions" : "No Finished transactions"}
				</Typography>
			);
		}
		return transactions?.map?.((item, i) => {
			return (
				<div key={item._id} className={item.content?.notificationData || item.content?.type ? "mb-10" : ""}>
					{item.content?.notificationData && (
						<div
							key={i}
							className={
								"flex flex-col w-full h-full rounded-20 border-grey-300 border-1 px-10 py-10 bg-white " +
								(item.status === "IN_PROGRESS" || item.status === "POSTPONED" || item.state === "STARTED" || item.unread
									? ""
									: "opacity-40")
							}
						>
							<TransactionBidBookView
								message={{ ...item.content?.notificationData, state: item?.state, hasSubaccounts }}
								incoming={false}
								enableLeftBorder={false}
							/>
						</div>
					)}
					{item.content?.type && (
						<div
							key={i}
							className={
								"flex flex-col w-full h-full rounded-20 border-grey-300 border-1 px-10 py-10 bg-white " +
								(item.status === "IN_PROGRESS" || item.status === "POSTPONED" || item.state === "STARTED" || item.unread
									? ""
									: "opacity-40")
							}
						>
							<TransactionReportView
								message={{
									...item.content,
									state: item?.state,
									hasSubaccounts,
									lastMessage: item?.lastMessage,
									status: item?.status,
								}}
								incoming={false}
							/>
						</div>
					)}
				</div>
			);
		});
	};

	let items = useMemo(() => {
		if (errors?.length) {
			return (
				<div className="flex flex-col flex-auto h-full flex flex-col pb-20 items-center justify-center">
					<Typography color="error">{errors[0].message}</Typography>
				</div>
			);
		}
		if (!transactions) {
			return (
				<div className="flex flex-col flex-auto h-full flex flex-col items-center justify-center pb-80">
					<CircularProgress color="secondary" />
				</div>
			);
		}

		return (
			<div className="flex flex-col">
				<Typography className="mt-10 mb-20 text-20 font-500 md:text-16">Active Transactions</Typography>
				{viewTransaction(transactions?.activeTransactions, "active")}
				<Typography className="mt-10 mb-20 text-20 font-500 md:text-16">Finished Transactions</Typography>
				{viewTransaction(transactions?.oldTransactions, "old")}
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors, transactions]);

	if (!newUIEnabled) {
		return <div className="flex flex-col flex-auto h-full flex flex-col overflow-y-scroll">{items}</div>;
	}

	if (errors?.length) {
		return (
			<div className={classes.contentHeight}>
				<div className={classes.centered}>
					<Typography className="text-center" color="error">
						{errors?.[0]?.message}
					</Typography>
				</div>
			</div>
		);
	}

	if (!transactions) {
		return (
			<div className={"flex flex-col items-center justify-center " + classes.contentHeight}>
				<CircularProgress color="secondary" />
			</div>
		);
	}

	return (
		<div className={"flex flex-col flex flex-col px-8 " + classes.contentHeight}>
			{transactions?.length === 0 && (
				<div className={classes.centered}>
					<Typography className={"text-12 lg:text-13 font-light relative justify-center"}>
						{"No transactions"}
					</Typography>
				</div>
			)}
			{transactions?.map((transaction, key) => {
				return (
					<div key={key} className="mb-10">
						<TransactionActionView transaction={transaction} />
					</div>
				);
			})}
		</div>
	);
}

export default TransactionStateCurrentList;
