import { createSlice } from "@reduxjs/toolkit";

const widgetsSlice = createSlice({
	name: "widgets/boards",
	initialState: {
		boardsParams: {},
		builderParams: {},
	},
	reducers: {
		saveBoardParams: (state, action) => {
			if (!action?.payload) return;

			const { key, config, edited, accessType, status } = action?.payload;
			state.boardsParams[key] = { config, accessType, edited, status };
		},
		saveBuilderParams: (state, action) => {
			if (!action?.payload) return;

			const { params, ignoreOverrides } = action?.payload;
			state.builderParams = {
				...params,
				...(ignoreOverrides
					? {
							account: state?.builderParams?.account,
							account__view: state?.builderParams?.account__view,
							profile: state?.builderParams?.profile,
							profile__view: state?.builderParams?.profile__view,
							carrier: state?.builderParams?.carrier,
							carrier__view: state?.builderParams?.carrier__view,
							user: state?.builderParams?.user,
							user__view: state?.builderParams?.user__view,
							truck: state?.builderParams?.truck,
							truck__view: state?.builderParams?.truck__view,
					  }
					: {}),
			};
		},
	},
});

export const { saveBoardParams, saveBuilderParams } = widgetsSlice.actions;

export default widgetsSlice.reducer;
