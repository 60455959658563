// Dependencies
import { useEffect, useState, useRef, forwardRef } from "react";

import { LinearProgress, MenuItem, Select, Typography } from "@material-ui/core";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { useDispatch } from "react-redux";
import { createRequest } from "app/services/requestUtil";
import Button from "@material-ui/core/Button";

const ChangeStatusView = forwardRef(({ dataIds, setTitle, setSize, onClose, onDone }, _) => {
	const dispatch = useDispatch();

	const interrupted = useRef(false);
	const unmounded = useRef(false);
	const processedCount = useRef(0);
	const failedErrors = useRef([]);

	const [stopping, setStopping] = useState(false);
	const [newStatus, setNewStatus] = useState("__NOT_SELECTED__");
	const [showErrorsList, setShowErrorsList] = useState(false);
	const [processedItems, setProcessedItems] = useState({
		processedCount: 0,
		successCount: 0,
		errorsCount: 0,
		errors: [],
	});
	const [progressStatus, setProgressStatus] = useState("SELECT");

	useEffect(() => {
		setTitle?.(dataIds?.label ?? `Change Status`);
		setSize?.("max-w-md");
		// eslint-disable-next-line
	}, [dataIds?.label]);

	const statusOptions = dataIds?.statusOptions;
	const actionURL = dataIds?.actionURL;
	const itemsIds = dataIds?.itemsIds;

	const handleChangeStatus = async () => {
		setProgressStatus("PROCESSING");
		setStopping(false);

		interrupted.current = false;
		processedCount.current = 0;
		failedErrors.current = [];

		try {
			for (const itemId of itemsIds) {
				if (!interrupted.current) {
					const url = actionURL
						.replace(":carrierId", dataIds?.carrierId)
						.replace(":itemId", itemId)
						.replace(":newStatus", newStatus);

					await createRequest()
						.put(url, { data: { isBulkAction: true } })
						.then(() => {
							processedCount.current += 1;
							setProcessedItems({ processedCount: processedCount.current });
						})
						.catch((err) => {
							processedCount.current += 1;
							setProcessedItems({ processedCount: processedCount.current });

							const error = err?.response?.data?.errors[0]?.message ?? "Oops... Failed to update...";
							failedErrors.current.push(error);
						});
				}
			}

			if (!unmounded.current) {
				setProcessedItems({
					processedCount: processedCount.current,
					successCount: processedCount.current - failedErrors.current?.length,
					errorsCount: failedErrors.current?.length,
					errors: failedErrors.current,
				});
				setTimeout(() => setProgressStatus("FINISH"), interrupted.current ? 0 : 1000);
			}

			dispatch(incrementDataRevision({ event: "tripsRevision" }));
			dispatch(incrementDataRevision({ event: "invoiceRevision" }));
		} catch (error) {
			console.log("[ChangeStatusView] failed to process: ", error);
		}
	};

	useEffect(() => {
		return () => {
			interrupted.current = true;
			unmounded.current = true;
		};
	}, []);

	return (
		<div className={`px-8 py-8 max-h-360 flex flex-col justify-around`}>
			{progressStatus === "SELECT" && (
				<>
					<div className="flex flex-col w-full min-h-60">
						<Select
							label={"Status"}
							variant="standard"
							value={newStatus}
							className="w-full"
							onChange={(event) => {
								setNewStatus(event.target.value);
							}}
						>
							<MenuItem value={"__NOT_SELECTED__"} key={"not_selected"} disabled>
								<Typography display="inline">Not Selected</Typography>
							</MenuItem>
							{statusOptions?.map((option, index) => {
								return (
									<MenuItem value={option.value} key={"new_status_" + index}>
										<Typography display="inline">{option.label}</Typography>
									</MenuItem>
								);
							})}
						</Select>
						<Typography className="text-grey-600 text-medium text-12 mt-6">
							Please select new status to update {itemsIds?.length} items(s)
						</Typography>
					</div>
					<div className="flex flex-row w-full justify-end pt-2 pb-2 min-h-40">
						<Button
							key={"cancel-action"}
							onClick={() => onDone?.()}
							variant={"contained"}
							className={"min-w-150 text-12 mr-12"}
						>
							Cancel
						</Button>
						<Button
							key={"update-action"}
							onClick={handleChangeStatus}
							variant={"contained"}
							className={"min-w-150 text-12"}
							color={"secondary"}
							disabled={newStatus === "__NOT_SELECTED__"}
						>
							Update Now
						</Button>
					</div>
				</>
			)}

			{progressStatus === "PROCESSING" && (
				<>
					<div className="flex flex-col w-full min-h-60 pt-6">
						<Typography className="text-13 pb-6">{stopping ? "Stopping..." : "Updating..."}</Typography>
						<LinearProgress className="w-full rounded-2" color="secondary" />
						<Typography className="font-normal text-12 pt-4" color="textSecondary">
							{`Processed ${processedItems?.processedCount} out ${itemsIds.length}`}
						</Typography>
					</div>
					<div className="flex flex-row w-full justify-end pt-2 pb-2 min-h-40">
						<Button
							key={"stop-action"}
							onClick={() => {
								interrupted.current = true;
								setStopping(true);
							}}
							variant={"contained"}
							className={"min-w-150 text-12"}
							color={"secondary"}
							disabled={stopping}
						>
							Stop
						</Button>
					</div>
				</>
			)}
			{progressStatus === "FINISH" && (
				<>
					<div className="flex flex-col w-full min-h-60 pt-6">
						<Typography className="text-13 pb-6">Update {processedItems?.successCount} item(s)</Typography>
						<LinearProgress value={100} variant="determinate" className="w-full rounded-2" color="secondary" />
						{processedItems?.errorsCount > 0 && (
							<div className="flex flex-row w-full items-center pt-2">
								<Typography className="text-red text-12" color="textSecondary">
									{`Failed to update ${processedItems?.errorsCount} items(s)`}
								</Typography>
								<Button
									key={"show-errors-action"}
									onClick={() => setShowErrorsList(!showErrorsList)}
									variant={"text"}
									size={"small"}
									className={"text-12 py-0 text-grey-600 font-medium"}
								>
									{showErrorsList ? "Hide Details " : "Show Details"}
								</Button>
							</div>
						)}
						{showErrorsList && (
							<div className="flex flex-col max-h-200 overflow-auto mt-10">
								{processedItems?.errors?.map((error, index) => {
									return (
										<div key={index} className="flex flex-row justify-left items-center space-x-4 text-11 ml-10">
											<div className="flex flex-col">
												<Typography className="mb-2 text-12 text-grey-600">&#x2022; {error}</Typography>
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
					<div className="flex flex-row w-full justify-end pt-2 pb-2 min-h-40">
						<Button
							key={"close-action"}
							onClick={() => onDone?.()}
							variant={"contained"}
							color={"secondary"}
							className={"min-w-150 text-12"}
						>
							Close
						</Button>
					</div>
				</>
			)}
		</div>
	);
});

export default ChangeStatusView;
