import { saveBuilderParams } from "app/store/tools/widgetsSlice";

const dashboard = {
	urlGET: "api/analytics/dashboards/:id",
	urlPOST: "api/analytics/dashboards",
	urlDELETE: "api/analytics/dashboards/:id",
	urlPUT: "api/analytics/dashboards/:id",
	idKey: "_id",
	triggerEvent: "analyticsJobsRevision",
	listenEvent: "analyticsJobsRevision",
	header: {
		create: { label: "Create Dashboard" },
		edit: { label: "Edit Dashboard" },
	},
	content: {
		create: {
			items: [
				{
					key: "key",
					type: "text",
					label: "Key",
					required: true,
				},
				{
					key: "accessType",
					label: "Access Type",
					type: "select",
					defaultValue: "ADMIN",
					options: [
						{ value: "ADMIN", label: "Admin" },
						{ value: "ACCOUNT", label: "Account" },
						{ value: "CARRIER", label: "Carrier" },
						{ value: "USER", label: "User Assigned" },
						{ value: "USER_CREATOR", label: "User Created" },
						{ value: "TRUCK", label: "Truck" },
					],
				},
				{
					key: "config",
					type: "text",
					label: "Config",
					required: true,
					multiline: { rows: 30 },
				},
				{
					type: "action",
					action: "SUBMIT",
					button: { alwaysEnabled: true },
				},
			],
			onFinished: (result, dispatch) => {
				dispatch(saveBuilderParams({ params: result.model, ignoreOverrides: true }));
			},
		},
		edit: {
			items: [
				{
					key: "accessType",
					label: "Access Type",
					type: "select",
					defaultValue: "ADMIN",
					options: [
						{ value: "ADMIN", label: "Admin" },
						{ value: "ACCOUNT", label: "Account" },
						{ value: "CARRIER", label: "Carrier" },
						{ value: "USER", label: "User Assigned" },
						{ value: "USER_CREATOR", label: "User Created" },
						{ value: "TRUCK", label: "Truck" },
					],
				},
				{
					key: "config",
					type: "text",
					label: "Config",
					required: true,
					multiline: { rows: 30 },
				},
				{
					type: "action",
					action: "SUBMIT",
					button: { alwaysEnabled: true },
				},
			],
			onFinished: (result, dispatch) => {
				dispatch(saveBuilderParams({ params: result.model, ignoreOverrides: true }));
			},
		},
	},
};

export default dashboard;
