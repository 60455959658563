// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatDate } from "app/main/utils/dateUtils";
import { centsToDollars } from "app/main/utils/financeUtils";
import { creditAndDebitAmountFormat } from "app/main/utils/billingUtils";
import UnitPaymentStatusCellView from "@smarthop/list/views/UnitPaymentStatusCellView";

// Services
import { getRoleLabel } from "app/services/LoginService";

const statusesLabels = {
	Rejected: "Rejected",
	Returned: "Returned",
	Canceled: "Canceled",
	PendingReview: "Pending",
	Clearing: "Pending",
	Pending: "Pending",
	Sent: "Completed",
};

const transactions = ({ accountTypes, isAdmin }) => ({
	urlGET: `api/wallet/${isAdmin ? "admin/" : ""}transactions/:entityId`,
	idKey: "id",
	listenEvent: "smarthopWalletRevision",
	segmentKey: "CARRIER_SMARTHOP_WALLET_TRANSACTIONS",
	otp: getRoleLabel() === "carrier" && ["WALLET_ACCESS"],
	content: {
		table: {
			variant: "skinny",
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			default: { accountId: accountTypes?.find((account) => account.purpose === "checking")?.value },
			search: false,
			items: [
				{
					key: "since",
					type: "picker",
					label: "Start date",
					translate: "",
					required: true,
					field: {
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
				{
					key: "until",
					type: "picker",
					label: "End date",
					translate: "",
					required: true,
					field: {
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
				{
					key: "accountId",
					type: "select",
					label: "Account Type",
					options: accountTypes,
				},
			],
		},
		items: [
			{ key: "attributes.createdAt", type: "date", label: "Request Date" },
			{
				key: "description",
				type: "text",
				label: "Description",
				builder: (item) => item.included?.transaction?.attributes?.summary ?? item.attributes?.description,
			},
			{
				key: "attributes.status",
				type: "component",
				label: "Status",
				builder: (item) => {
					const statusLabel = statusesLabels[item.attributes?.status];
					return (
						<UnitPaymentStatusCellView
							view={{
								value: statusLabel,
								metadata: {
									completedDate:
										statusLabel === "Completed"
											? `on ${formatDate(item.included?.transaction?.attributes?.createdAt)}`
											: undefined,
								},
							}}
						/>
					);
				},
			},
			{
				key: "attributes.amount",
				type: "component",
				label: "Amount",
				table: { align: "right" },
				builder: (item) => {
					// If no direction available, we can assume a Debit as no Transaction is present yet
					// And BookPayments have direction only when completed and Transaction is present
					const direction = item?.attributes?.direction || "Debit";
					const amount = item?.attributes?.amount;
					const signAndColors = {
						Debit: ["-", " text-red"],
						Credit: ["+", " text-green"],
					};
					const value = `${signAndColors[direction][0]} ${formatCurrency(centsToDollars(amount))}`;
					return creditAndDebitAmountFormat(value, signAndColors[direction][1]);
				},
			},
		],
	},
});

export default transactions;
