import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";
import { INVOICE_STATUSES_ICONS } from "app/main/consts/tripInvoiceConsts";

function PayoutCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const status = props.view?.metadata?.status;
	const config = props.config;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "SMARTPAY_PAYOUT_VIEW",
		dataIds: {
			carrierId: props.view?.metadata?.carrier,
			payoutId: props.view?.value,
		},
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={props.view} variant={variant} dialog={dialog} />}
			second={
				<>
					{config?.showStatus && (
						<CellSecondRow
							mobile={mobile}
							icon={INVOICE_STATUSES_ICONS[status]?.name}
							label={status}
							color={INVOICE_STATUSES_ICONS[status]?.color}
							variant={variant}
						/>
					)}
					{config?.showSmartpayEligibility && props.view?.metadata?.smartpay_eligible && (
						<CellSecondRow mobile={mobile} label={"SmartPay"} color={"text-white bg-green px-4"} variant={variant} />
					)}
				</>
			}
		/>
	);
}

export default PayoutCellView;
