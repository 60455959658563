import { centsToDollars } from "app/main/utils/financeUtils";

const listItemsHandler = (isAdmin) => {
	const items = [
		{ key: "nickname", type: "string", label: "Name" },
		{
			key: "description",
			type: "string",
			label: "Description",
			collapsibleLength: 100,
			table: { width: "54%" },
		},
		{
			key: "price.unit_amount",
			type: "currency",
			label: "Fee",
			builder: (item) => centsToDollars(item.price?.unit_amount),
		},
		{
			key: "quantity",
			type: "text",
			label: "Quantity",
			table: { width: "12%" },
			builder: (item) => "x" + (item.quantity ?? 1),
		},
	];
	if (isAdmin)
		items.push({
			key: "menu",
			type: "menu",
			label: "Menu",
			items: [
				{
					key: "delete",
					label: "Remove",
					isVisible: () => isAdmin,
					dialogBuilder: (item) => ({
						formId: "CARRIER_PLATFORM_ADDON_PLAN_FORM",
						mode: "DELETE",
						dataIds: { carrierId: item.carrier, addonId: item.addonId },
					}),
				},
			],
		});
	return items;
};

const addons = ({ isAdmin }) => ({
	urlGET: "billing/carriers/:carrierId/platform-addons-plans",
	idKey: "_id",
	listenEvent: "planRevision",
	segmentKey: "PLATFORM_PLANS_ADDONS",
	content: {
		table: {
			variant: "skinny",
			theme: "dark",
			noHeader: false,
			noBottomBorder: true,
			orderBy: ["nickname", "value", "created_at"],
			staticHeight: isAdmin ? 400 : undefined,
		},
		pagination: {
			enabled: false,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		isHighlighted: (item) => !!item.public,
		items: listItemsHandler(isAdmin),
	},
});

export default addons;
