import { uploadFile } from "./updateContentFile";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

import Typography from "@material-ui/core/Typography";

const createMessageShowTrip = (item) => {
	return (
		<Typography component="div" className="flex flex-row mb-16 font-medium break-all text-13 text-gray-900 pl-5">
			Load Id {item?.data?.load_id}
		</Typography>
	);
};

const uploadFiles = (type) => ({
	urlGET: `api/booking/carrier/:carrierId/trips/:tripId/files/type/${type}`,
	urlPUT: `api/booking/carrier/:carrierId/trips/:tripId/files/type/${type}`,
	idKey: "_id",
	triggerEvent: "tripsRevision",
	listenEvent: "tripsRevision",
	header: {
		edit: { label: "Upload Document" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					label: "",
					type: "component",
					builder: (item) => createMessageShowTrip(item),
					message: {
						classes: "justify-center mb-8",
					},
				},
				...uploadFile({ internalConfig: false, type }),
				{ type: "action", action: "SUBMIT" },
			],
			onFinished: (_, dispatch) => {
				dispatch(incrementDataRevision({ event: "dashboardRevision" }));
			},
		},
	},
});

const uploadForm = uploadFiles("pod_files");

export default uploadForm;
