// Dependencies
import { Chip, Typography, Icon } from "@material-ui/core";
import moment from "moment";

// Components
import CouponDuration from "./CouponDuration";

// Utils
import { calculateDiscountAmount } from "app/main/utils/billingUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { processTierRestrictions } from "app/main/utils/rolesUtils";

const CouponSection = ({ currency, amount, coupon, label }) => (
	<>
		<Chip
			className="m-auto mb-14 pl-6 pr-2 text-12 bg-green text-white"
			label={label}
			icon={<Icon className="text-white text-20">local_offer</Icon>}
		/>
		<div className="opacity-60">
			<div className="flex justify-center mb-2 ml:mb-4">
				<Typography variant="h6" color="textSecondary">
					{currency}
				</Typography>
				<Typography className="text-24 ml:text-28 mx-4 tracking-tight leading-none text-grey-500 line-through">
					{formatCurrency(amount, 0, {}, null, true)}
				</Typography>
				<Typography
					variant="button"
					display="block"
					gutterBottom
					color="primary"
					className="self-end text-grey-500 tracking-tight leading-none line-through"
				>
					{"/month"}
				</Typography>
			</div>
			<CouponDuration coupon={coupon} />
		</div>
	</>
);

const PromoSection = ({ plan }) => (
	<div className="flex flex-col mt-4 -mb-6 px-24">
		{plan.promoDescription && (
			<Typography variant="subtitle1" className="text-red font-bold text-12 ml:text-13">
				Promotion
			</Typography>
		)}
		{plan.promoExpiresAt && (
			<Typography variant="subtitle1" className="-mt-10 text-red-400 text-12 ml:text-13">
				Expires on {moment(plan.promoExpiresAt).format("MMM DD")}
			</Typography>
		)}
		{plan.promoDescription && (
			<Typography className="w-full text-red-400 text-center text-12 ml:text-13 pb-10 pt-8 leading-snug">
				{plan.promoDescription}
			</Typography>
		)}
	</div>
);

const PlatformPricePlan = (props) => {
	const isLimitedPlan = props.isLimitedPlan;

	const plan = props.plan;
	const price = props.price;
	const coupon = props.coupon;
	const period = props.period;
	const customer = props.customer;
	const selected = props.selected;

	const excludeDiscounts = !!plan?.excludeDiscounts;

	let currency, intervalCount, realAmountMonthly, planAmount, discountLabel, saveAmount;
	if (!isLimitedPlan) {
		currency = price?.currency === "usd" ? "$" : price?.currency;
		intervalCount = price.recurring?.interval_count || 1;

		realAmountMonthly = price.unit_amount / price.recurring.interval_count / 100;
		planAmount = realAmountMonthly;
		if (coupon && coupon !== "" && !excludeDiscounts) {
			// labeling
			const amountOff = coupon.amount_off;
			const pctOff = coupon.percent_off;
			discountLabel = `${amountOff ? currency + amountOff / 100 : pctOff + "%"} off coupon`;

			// amount
			const discountAmount = calculateDiscountAmount(price, coupon);
			planAmount = discountAmount / intervalCount;
		}

		// Saving
		if (period === "quaterly") {
			const secondaryPlan = plan.secondary_price__view?.metadata?.stripe;
			const primaryPlan = plan.price__view?.metadata?.stripe;
			saveAmount = (primaryPlan.unit_amount * secondaryPlan.recurring.interval_count - secondaryPlan.unit_amount) / 100;
		}
	}

	// Plan post trial days config, no plan selected, customer does not have previous post subscription, customer exists (current subscription exists), Do not choose post trial for downgrades
	const isHigherPlan =
		plan?.tier && customer?.tier && processTierRestrictions({ restrictions: [customer?.tier], tier: plan?.tier });
	const hasSubscriptionActive = !!customer?._id;
	const hasCustomerPostTrial = customer?.postSubscriptionPlan;
	const showPostTrialDays =
		plan?.postTrialDays && !selected && !hasCustomerPostTrial && hasSubscriptionActive && isHigherPlan;

	return (
		<div className={"flex flex-col"}>
			{/* coupon and price*/}
			{!excludeDiscounts && coupon && !isLimitedPlan && (
				<CouponSection currency={currency} amount={realAmountMonthly} coupon={coupon} label={discountLabel} />
			)}

			{/* price */}
			<div className={"flex mb-2 ml:mb-4 justify-center"}>
				<Typography variant="h5" color="primary" className="font-semibold">
					{currency}
				</Typography>
				<Typography className="text-36 ml:text-40 mx-4 tracking-tight font-semibold leading-none">
					{isLimitedPlan ? "FREE" : formatCurrency(planAmount, 0, {}, null, true)}
				</Typography>
				<Typography
					variant="button"
					display="block"
					gutterBottom
					color="primary"
					className="self-end tracking-tight leading-none"
				>
					{isLimitedPlan ? "" : "/month"}
				</Typography>
			</div>

			{/* period message */}
			{period === "quaterly" && !isLimitedPlan && (
				<Typography color="primary" className="text-13 font-semibold mt-6">
					{`billed quaterly`}
				</Typography>
			)}

			{/* saving message */}
			{saveAmount ? (
				<div className="flex flex-col mt-8 -mb-6 px-24">
					<Typography variant="subtitle1" className="text-green font-semibold text-12 ml:text-13">
						{`Save ${formatCurrency(saveAmount, 0)} per quater!`}
					</Typography>
				</div>
			) : null}

			{/* post trial message */}
			{showPostTrialDays ? (
				<div className="flex flex-col mt-8 -mb-6 px-24">
					<Typography variant="subtitle1" color="secondary" className="font-bold text-13 ml:text-14">
						{`Try for ${plan?.postTrialDays} days for FREE!`}
					</Typography>
				</div>
			) : null}

			{/* promo section */}
			{plan.promo && <PromoSection plan={plan} />}
		</div>
	);
};

export default PlatformPricePlan;
