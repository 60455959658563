import onboardingVoidCheck from "../../../assets/images/onboarding/onboarding_void_check_.png";
import onboardingFactoryCompany from "../../../assets/images/onboarding/onboarding_factoring_notice_of_assignment.png";

const ACTION = { type: "action", action: "SUBMIT" };

const carrierOnboardingPayForm = {
	urlGET: "api/profile/carriers/:carrierId/documents/billing_letter_assigment",
	urlPUT: "api/profile/carriers/:carrierId/documents/billing_letter_assigment",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Pay" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					key: "billing_document_type",
					type: "select",
					label: "Types",
					defaultValue: "FACTORING",
					options: [
						{
							value: "FACTORING",
							label: "Factoring",
							showItems: [
								{
									type: "group",
									group: { classes: { child0: "w-full md:w-5/12", child1: "w-full md:w-7/12" } },
									content: {
										items: [
											{
												type: "image",
												items: [{ name: "Factoring", url: onboardingFactoryCompany }],
											},
											{
												type: "group",
												group: { classes: { direction: "flex-col" } },
												content: {
													items: [
														{
															key: "factoring_message",
															type: "message",
															label: `Attach the letter that your factoring company sent you. 
															This is needed in order to receive immediate funds through a factoring account.`,
														},
														{
															key: "billing_letter_assigment_factoring",
															type: "upload",
															label: "Factoring Letter",
															upload: {
																url: "api/profile/carriers/:carrierId/files",
																documentType: "carrier",
																fileName: "billing_letter_assigment",
																variant: "large",
															},
														},
														ACTION,
													],
												},
											},
										],
									},
								},
							],
						},
						{
							value: "VOID_CHECK",
							label: "Void Check",
							showItems: [
								{
									type: "group",
									group: { classes: { child0: "w-full md:w-5/12", child1: "w-full md:w-7/12" } },
									content: {
										items: [
											{
												type: "image",
												items: [{ name: "Void Check", url: onboardingVoidCheck }],
											},
											{
												type: "group",
												group: { classes: { direction: "flex-col" } },
												content: {
													items: [
														{
															key: "void_check_message",
															type: "message",
															label: `Attach a copy of a check with “void” written across. 
															This is needed in order to receive invoice payments to your bank account.`,
														},
														{
															key: "billing_letter_assigment_void_check",
															type: "upload",
															label: "Void Check",
															upload: {
																url: "api/profile/carriers/:carrierId/files",
																documentType: "carrier",
																fileName: "billing_letter_assigment",
																variant: "large",
															},
														},
														ACTION,
													],
												},
											},
										],
									},
								},
							],
						},
					],
				},
			],
		},
	},
};

export default carrierOnboardingPayForm;
