import TrimbleMaps from "@trimblemaps/trimblemaps-js";

export const onLoadedPlan = (plan) => {
	let requestPlan = {};
	requestPlan.routeId = plan.routeId;

	//Route Type
	if (plan.routeType === "PRACTICAL") {
		requestPlan.routeType = TrimbleMaps.Common.RouteType.PRACTICAL;
	} else if (plan.routeType === "SHORTEST") {
		requestPlan.routeType = TrimbleMaps.Common.RouteType.SHORTEST;
	}

	requestPlan.tollDiscourage = plan.tollDiscourage;

	// Truck Config
	if (plan.truckConfig === "FIFTY_THREE") {
		requestPlan.truckConfig = TrimbleMaps.Common.TruckConfig.FIFTY_THREE;
	} else if (plan.truckConfig === "FORTY_EIGHT") {
		requestPlan.truckConfig = TrimbleMaps.Common.TruckConfig.FORTY_EIGHT;
	}

	requestPlan.trkWeight = +plan.trkWeight;

	// HOS Rule Type
	if (plan.hosRuleType === "US_FED_607_LH") {
		requestPlan.hosRuleType = TrimbleMaps.Common.HoSScheduleType.US_FED_607_LH;
	} else if (plan.hosRuleType === "US_FED_708_LH") {
		requestPlan.hosRuleType = TrimbleMaps.Common.HoSScheduleType.US_FED_708_LH;
	}

	requestPlan.hosRemDriveTimeUntilBreak = +plan.hosRemDriveTimeUntilBreak * 3600;
	requestPlan.hosRemDriveTime = +plan.hosRemDriveTime * 3600;
	requestPlan.hosRemOnDutyTime = +plan.hosRemOnDutyTime * 3600;
	requestPlan.hosRemCycleDutyTime = +plan.hosRemCycleDutyTime * 3600;

	if (plan?.governorSpeedLimit) requestPlan.governorSpeedLimit = +plan.governorSpeedLimit;
	return requestPlan;
};


export const DEFAULT_PLAN = {
	routeType: "PRACTICAL",
	tollDiscourage: true,
	truckConfig: "FIFTY_THREE",
	trkWeight: 35000,
	hosEnabled: true,
	isFerryDiscouraged: false,
	hosRuleType: "US_FED_607_LH",
	hosRemDriveTimeUntilBreak: 0,
	hosRemDriveTime: 0,
	hosRemOnDutyTime: 0,
	hosRemCycleDutyTime: 0,
	teamDriving: false,
	activeDriver: "1",
	hosRemDriveTimeUntilBreakDriver2: 0,
	hosRemDriveTimeDriver2: 0,
	hosRemOnDutyTimeDriver2: 0,
	hosRemCycleDutyTimeDriver2: 0,
};

export const formatPlanInfo = (info) => {
	let data = {};
	if (info.trailer === "53") {
		data.truckConfig = "FIFTY_THREE"
	} else if (info.trailer === "48") {
		data.truckConfig = "FORTY_EIGHT"
	}

	data.trkWeight = info.truck_weight;
	data.hosRemDriveTimeUntilBreak = info.hos;
	data.plannedStartLocation = info.plannedStartLocation;
	data.plannedStartLocation__view = info.plannedStartLocation__view;
	data.plannedStartTime = info.plannedStartTime;
	return data;
}