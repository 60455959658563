import moment from "moment";
import { strategyConnect } from "../../providers/StrategyContextProvider";

const StrategyHeader = ({ result, entryPoint }) => {
	const savedStrategy = entryPoint === "savedList";
	return result && savedStrategy ? (
		<div>
			<>
				<p className="text-14 p-14 ">
					<b>Carrier</b> {result?.resData?.truckInfo?.carrier__view?.label || "No Carrier Name"} &nbsp;|&nbsp;
					<b>Truck</b> {result?.resData?.truckInfo?.truckid || "No Truck Friendly Id"} &nbsp;|&nbsp;
					<b>Equipment</b> {result?.resData?.truckInfo?.equipment} &nbsp;|&nbsp;
					<b>Computed</b> {moment(result?.resData?.strategy.createdAt).format("MMM DD yy HH:mm")} &nbsp;|&nbsp;
					<b>Available Date</b> {moment(result?.resData?.strategy?.available_date).format("MMM DD yy")} &nbsp;|&nbsp;
					<b>Empty Location</b> {result?.resData?.strategy?.current_location}
					<br />
				</p>
			</>
		</div>
	) : null;
};

const mapStateToProps = (state) => ({
	result: state.result,
	entryPoint: state.entryPoint,
});

export default strategyConnect(mapStateToProps)(StrategyHeader);
