import { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	determinate: {
		borderRadius: "50%",
		boxShadow: (props) => `inset 0 0 0 ${props.thickness}px #d1d1d1`,
		color: (props) =>
			props.color === "lowest"
				? "#d50000"
				: props.color === "low"
				? "#d32f2f"
				: props.color === "medium"
				? "#ffa726"
				: props.color === "medium-high"
				? "#ffeb3b"
				: props.color === "good"
				? "#81c784"
				: "#388e3c",
	},
});

export default function CircularProgressWithIndicator(props) {
	const progress = props.progress;
	const thickness = props?.thickness ?? 4.8;

	const [color, setColor] = useState();
	const classes = useStyles({ color, thickness });

	useEffect(() => {
		if (!!progress) {
			let newColor;
			if (progress < 25) newColor = "lowest";
			else if (25 <= progress && progress < 50) newColor = "low";
			else if (50 <= progress && progress < 70) newColor = "medium";
			else if (70 <= progress && progress < 85) newColor = "medium-high";
			else if (85 <= progress && progress < 100) newColor = "good";
			else newColor = "very-good";
			setColor(newColor);
		}
	}, [progress]);

	return (
		<Box className={"flex items-center gap-x-5 " + (props.reverse ? " flex-row-reverse " : " flex-row ")}>
			<Box
				sx={{
					position: "relative",
					display: "inline-flex",
					mr: props.reverse ? 0 : props?.mr ?? 2,
					ml: !props.reverse ? 0 : props?.ml ?? 2,
				}}
			>
				<CircularProgress
					size={props?.size ?? 64}
					value={progress > 100 ? 100 : progress <= 0 ? 0 : progress}
					thickness={props?.thickness ?? 4.8}
					variant="determinate"
					classes={classes}
				/>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography component="div" variant="body1" className={`ml-4 self-center ${props?.textClass ?? ""}`}>
						{`${Math.round(progress)}%`}
					</Typography>
				</Box>
			</Box>
			{props.description && (
				<Typography component="div" variant="body1">
					{props.description}
				</Typography>
			)}
		</Box>
	);
}
