import { Typography, withStyles } from "@material-ui/core";
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineDot,
	TimelineConnector,
	TimelineContent,
} from "@material-ui/lab";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
	},
})(TimelineItem);

function OnboardSubSteps({ substepsViews, data }) {
	const visibleSubStepsView = substepsViews.filter((subStep) => !subStep.hidden);

	return (
		<Timeline className="flex w-full items-center">
			{visibleSubStepsView.map((substep, index) => {
				return (
					<div className="flex flex-row w-full ml:max-w-1080" key={"SubStep" + index}>
						{substep.leftRender && (
							<li className="flex justify-center content-center items-center">{substep.leftRender()}</li>
						)}
						<StyledTimelineItem className="w-full" key={"sub_step_" + index}>
							<TimelineSeparator className="md:mr-10">
								<TimelineDot
									className={
										"pt-4 font-bold pb-5 pr-12 pl-12 " +
										(substep.isComplete(data) ? "bg-green " : substep.optional ? "bg-orange " : "bg-grey-600 ")
									}
								>
									{index + 1}
								</TimelineDot>
								{visibleSubStepsView.length !== index + 1 && (
									<TimelineConnector style={{ backgroundColor: "yelllow" }} sx={{ bgcolor: "#000" }} />
								)}
							</TimelineSeparator>
							<TimelineContent className="pr-6 mb-8 md:mb-20">
								<div className="flex flex-col">
									<Typography className={`text-grey-900 text-14 -mt-4 leading-tight`}>{substep.title}</Typography>
									<Typography
										className={
											"text-13 -mt-1 md:-mt-2 font-bold" +
											(substep.isComplete(data)
												? " text-green-700 "
												: substep.optional
												? " text-orange "
												: " text-grey-600 ")
										}
									>
										{substep.isComplete(data) ? "Completed" : substep.optional ? "Optional" : "Pending"}
									</Typography>
									{substep.subtitle && (
										<Typography className={`text-grey-600 text-13 mt-4 mb`}>{substep.subtitle}</Typography>
									)}
									<div className="h-8 md:h-20" />
									{substep.render(data)}
								</div>
							</TimelineContent>
						</StyledTimelineItem>
					</div>
				);
			})}
		</Timeline>
	);
}

export default OnboardSubSteps;
