/**
 * User roles labels if tier is specified, user would have 2 roles assigned
 */
export const user_roles = [
	{
		value: -1,
		label: "deactivated",
		description: "Deactivated",
	},
	{
		value: 0,
		label: "administrator",
		description: "Administrator",
	},
	{
		value: 1,
		label: "dispatcher",
		description: "Dispatcher",
		tier_role: [
			{ value: 0, label: "", description: "" }, // generic role
			{ value: 1, label: "dispatcher-freight-analyst", description: "Freight Analyst" },
			{ value: 2, label: "dispatcher-freight-coordinators", description: "Freight Coordinators" },
			{ value: 3, label: "dispatcher-freight-specialist", description: "Freight Specialist" },
			{ value: 4, label: "dispatcher-night-dispatching", description: "Night Dispatching" },
			{ value: 5, label: "dispatcher-on-demand", description: "On Demand" },
			{ value: 6, label: "dispatcher-booking-agent", description: "Booking Agent" },
		],
	},
	{
		value: 12,
		label: "ops support",
		description: "Ops Support",
	},
	{
		value: 2,
		label: "carrier",
		description: "Carrier",
	},
	{
		value: 21,
		label: "driver",
		description: "Driver",
	},
	{
		value: 22,
		label: "carrier_manager",
		description: "Carrier Manager",
	},
	{
		value: 23,
		label: "carrier_dispatcher",
		description: "Carrier Dispatcher",
	},
	{
		value: 27,
		label: "carrier_generic",
		description: "Carrier User",
	},
	{
		value: 9,
		label: "bot",
		description: "Bot",
	},
];

/**
 * TYPE_USER
 * Used in UserForm.js,
 */
export const type_user = [
	{ value: -1, label: "Deactivated" },
	{ value: 0, label: "Administrator" },
	{ value: 1, label: "Ops Dispatcher" },
	{ value: 12, label: "Ops Support" },
	{ value: 2, label: "Carrier Owner" },
	{ value: 21, label: "Carrier Driver" },
	{ value: 22, label: "Carrier Manager" },
	{ value: 23, label: "Carrier Dispatcher" },
	{ value: 27, label: "Carrier User" },
	{ value: 9, label: "Bot" },
];

/**
 * TYPE_DISPATCHING_SERVICE
 * Used in UserForm.js, FormLogin.js, FormRegister.js,
 *         MenuSmarthop.js, TableFindLoads.js, FindLoadPage.js,
 *         MarketTrends.js
 */
export const type_dispatching = ["Dispatching", "Load Board", "Needs Lease", "Not a Fit"];

/**
 * TYPE_TIERS
 * Used in UserForm.js,
 */
export const type_tiers = [
	{ value: 0, label: "N/A" },
	{ value: 1, label: "Freight Analyst" },
	{ value: 2, label: "Freight Coordinators" },
	{ value: 3, label: "Freight Specialist" },
	{ value: 4, label: "Night Dispatching" },
	{ value: 5, label: "On Demand" },
	{ value: 6, label: "Booking Agent" },
];

/**
 * TYPE_TIERS_CARRIER
 * Used in UserForm.js,
 */
export const type_tiers_carrier = [
	{ value: 0, label: "Owner" },
	{ value: 1, label: "Driver" },
];

/**
 * STATUS_INVOICES
 * Used in TransactionsPage.js,
 */
export const status_invoiced = {
	paid: "paid",
	draft: "draft",
	dispute: "dispute",
	refund: "refund",
	cancelled: "cancelled",
	pending: "pending",
	open: "open",
	verified: "verified",
};

/**
 * TYPE_FILES
 * Used in BrokerPage.js,
 */
export const type_files = [
	"text/csv",
	"application/xlsx",
	"application/xls",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

/**
 * TYPE_IDENTIFICATION
 * Used in step_company_information.js,
 */
export const type_identification = [
	{ label: "MC Nº", value: "carrier_mc_number" },
	//{ label: "DOT Nº", value: "carrier_dot_number" },
];

/**
 * TYPE_TRIP_STATUS
 * Used in bookedTripPageWeb.js,
 */
export const type_trip_status = [
	{ value: "Booked", label: "Booked" },
	{ value: "Dispatched", label: "Dispatched" },
	{ value: "On Transit to Pick Up", label: "On Transit to Pick Up" },
	{ value: "At Pickup", label: "At Pickup" },
	{ value: "Picked Up", label: "Picked Up" },
	{ value: "On Transit to Delivery", label: "On Transit to Delivery" },
	{ value: "At Delivery", label: "At Delivery" },
	{ value: "Delivered", label: "Delivered" },
	{ value: "Invoiced", label: "Invoiced" },
	{ value: "Paid", label: "Paid" },
	{ value: "Bidded", label: "Bidded" },
	{ value: "Cancelled", label: "Cancelled" },
	{ value: "Pending", label: "Pending", isdisabled: true },
];

/**
 * SELECT_DATE
 * Used in DetailTruckPerformace.js,
 */
export const select_date = [
	{ value: "current_week", label: "Current Week" },
	{ value: "last_week", label: "Last Week" },
	{ value: "last_month", label: "Last Month" },
	{ value: "last_month_two", label: "Last 2 Months" },
	{ value: "last_year", label: "Last Year" },
];

/**
 * TYPE_LOGISTIC_COST
 * Used in bookedTripPageWeb.js,
 */
export const type_logistic_cost = [
	{
		label: "Deductions",
		options: [
			{ label: "Cash Advance", value: "Cash Advance" },
			{ label: "Late Fee", value: "Late Fee" },
			{ label: "Damage", value: "Damage" },
			{ label: "Escrow", value: "Escrow" },
			{ label: "Fuel", value: "Fuel" },
			{ label: "Insurance", value: "Insurance" },
		],
	},
	{
		label: "Additions",
		options: [
			{ label: "Layover", value: "Layover" },
			{ label: "Detention", value: "Detention" },
			{ label: "Bonus", value: "Bonus" },
		],
	},
];

/**
 * TYPE_CARGO
 * Used in TruckForm.js,
 */
export const type_cargo = [
	{ label: "General Freight", value: "General Freight" },
	{ label: "Produce", value: "Produce" },
	{ label: "Plants", value: "Plants" },
	{ label: "Electronics", value: "Electronics" },
	{ label: "Fragile Equipment", value: "Fragile Equipment" },
	{ label: "Food", value: "Food" },
	{ label: "Other", value: "Other" },
];

/**
 * TYPE_LOCATIONS
 * Used in bookedTripPageWeb.js,
 */
export const type_locations = [
	{ label: "Pickup", value: "Pickup" },
	{ label: "Delivery", value: "Delivery" },
];

/**
 * TYPE_PAYMENT
 * Used in DriverForm.js,
 */
export const type_payment = [
	{ value: "Pay by percentage %", label: "Pay by percentage %" },
	{ value: "Pay by miles", label: "Pay by miles" },
	{ value: "Flat fee", label: "Flat fee" },
];

/**
 * TYPE_PREFERRED_CONTACT_METHOD
 * Used in CompanyInformation.js,
 */
export const type_pref_contact_method = [
	{ label: "Email", value: "Email" },
	{ label: "SMS", value: "SMS" },
	{ label: "Phone", value: "Phone" },
];

/**
 * TYPE_PREFERRED_LANGUAGE
 * Used in CompanyInformation.js,
 */
export const type_pref_language = [
	{ label: "English", value: "English" },
	{ label: "Spanish", value: "Spanish" },
	{ label: "Other", value: "Other" },
];

/**
 * TYPE_EQUIPMENT
 * Used in TruckForm.js,
 */
export const type_equipment = [
	{ label: "VAN", value: "VAN" },
	{ label: "REEFER", value: "REEFER" },
	{ label: "FLATBED", value: "FLATBED" },
	{ label: "PO", value: "POWER ONLY" },
];

/**
 * TYPE_TRAILER_TYPE
 * Used in TruckForm.js,
 */
export const type_trailer_type = [
	{ label: "53", value: "53' Wedge" },
	{ label: "48", value: "48' Wedge" },
	{ label: "45", value: "45' Wedge" },
	{ label: "28", value: "28' High Cube" },
];

/**
 * TYPE_DOORS
 * Used in TruckForm.js,
 */
export const type_doors = [
	{ label: "Swing doors", value: "Swing doors" },
	{ label: "Roll up doors", value: "Roll up doors" },
];

/**
 * TYPE_FLOOR
 * Used in TruckForm.js,
 */
export const type_floor = [
	{ label: "Laminated hardwood floor", value: "Laminated hardwood floor" },
	{ label: "Aluminum floor", value: "Aluminum floor" },
	{ label: "Steel floor", value: "Steel floor" },
	{ label: "Composite floor", value: "Composite floor" },
];

/**
 * TYPE_BILLING
 * Used in BillingInformation.js,
 */
export const type_billing = [
	{ label: "Factoring Company", value: "Factoring Company" },
	{ label: "QuickPay", value: "QuickPay" },
	{ label: "Direct Deposit", value: "Direct Deposit" },
];

/**
 * TYPE_BILLING_ONBOARDING
 * Used in BillingStep.js,
 */
export const type_billing_onboarding = [
	{ label: "Factoring Company", value: "Factoring Company" },
	{ label: "QuickPay", value: "QuickPay" },
	{ label: "Direct Deposit", value: "Direct Deposit" },
	{ label: "Check", value: "Check" },
];

/**
 * SELECT_YES_OR_NOT
 * Used in LoadTracking.js, LoadSearchWizardRateStep.js, ModalEmptySearch.js
 */
export const select_yes_or_not = [
	{ label: "Yes", value: true },
	{ label: "No", value: false },
];

/**
 * TYPE_TRACKING_ACTION
 * Used in LoadTracking.js,
 */
export const type_tracking_action = [
	{ label: "Add manual log", value: "MANUAL_LOG" },
	{ label: "Send notification broker", value: "NOTIFICATION_BROKER" },
	{ label: "Send notification driver", value: "NOTIFICATION_DRIVER" },
];

/**
 * TYPE_COMPANY
 * Used in CompanyInfomation.js
 */
export const type_company = [
	{ label: "Owner operator", value: "Owner operator" },
	{ label: "Carrier company", value: "Carrier company" },
];

/**
 * Factoring loads status.
 */
export const type_factoring_status = [
	{ value: 0, label: "pending" },
	{ value: 1, label: "approved" },
	{ value: 2, label: "cancelled" },
	{ value: 3, label: "charged_full" },
	{ value: 4, label: "paid" },
];

/**
 * Sensible compatible brokers
 * Used in BookedTripPage.js
 */
export const available_sensible = [
	"ECHO GLOBAL LOGISTICS",
	"CH ROBINSON",
	"COYOTE",
	"UBER FREIGHT",
	"TOTAL QUALITY LOGISTICS TQL",
	"ARRIVE LOGISTICS",
	"LD LOGISTICS",
	"J.B. HUNT",
	"NOLAN TRANSPORTATION GROUP",
];

/**
 * TRUCK QUEUES STATUS REPORT.
 */
export const TRUCK_QUEUES_STATUS = ["covered", "current", "expired", "cancelled", "future", "confirmed"];

export const tracking_intervals = [
	{ label: "15 minutes", value: 15 },
	{ label: "30 minutes", value: 30 },
	{ label: "45 minutes", value: 45 },
	{ label: "60 minutes", value: 60 },
];

export const mc_length_requirement = [
	{ label: "0 days", value: 0 },
	{ label: "30 days", value: 30 },
	{ label: "60 days", value: 60 },
	{ label: "90 days", value: 90 },
	{ label: "120 days", value: 120 },
	{ label: "150 days", value: 150 },
	{ label: "180 days", value: 180 },
	{ label: "365 days", value: 365 },
];

export const onboarding_method_options = [
	{ label: "RMIS", value: "rmis" },
	{ label: "MyCarrierPackage", value: "my_carrier_package" },
	{ label: "DAT Onboard", value: "dat_onboard" },
	{ label: "Internal", value: "internal" },
	{ label: "PDF to email ", value: "pdf_to_email " },
	{ label: "Mcleod", value: "mcleod" },
	{ label: "Other", value: "other" },
];

export const broker_integrated = [
	{ label: "Yes (Directly)", value: "directly" },
	{ label: "Yes (Parade)", value: "parade" },
	{ label: "Yes (Newtrul)", value: "newtrul" },
	{ label: "No", value: "no" },
];

/**
 * Plan owner options
 * Used in PlansDashboard.js
 */
export const PLAN_OWNER_OPTIONS = [
	{
		value: "EFS",
		label: "EFS",
	},
];

/**
 * Plan default owner selected option
 * Used in PlansDashboard.js
 */
export const PLAN_DEFAULT_OWNER_OPTION = {
	value: "EFS",
	label: "EFS",
};

/**
 * Plan product options
 * Used in PlansDashboard.js
 */
export const PLAN_PRODUCT_OPTIONS = [
	{
		value: "ULSD",
		label: "ULSD",
	},
	{
		value: "ULSR",
		label: "ULSR",
	},
];

/**
 * Plan default product selected option
 * Used in PlansDashboard.js
 */
export const PLAN_DEFAULT_PRODUCT_OPTION = {
	value: "ULSD",
	label: "ULSD",
};

/**
 * Invoice type options
 * Used in Invoices.js
 */
export const INVOICE_TYPE_OPTIONS = [
	{ label: "Dispatching", value: "dispatching" },
	{ label: "Fuel", value: "fuel" },
	{ label: "Leasing", value: "leasing" },
	{ label: "Invoicing", value: "invoicing" },
	{ label: "Other", value: "other" },
];

/**************************************************************/
/************************ MARKETING **************************/
/************************************************************/

/**
 * TYPE_COMPANY
 */
export const type_company_marketing = [
	{ label: "Owner operator", value: "Owner operator" },
	{ label: "Company driver", value: "Company driver" },
	{ label: "Trucking company", value: "Trucking company" },
];

export const number_of_trucks_marketing = [
	{ label: "0", value: "0" },
	{ label: "1", value: "1" },
	{ label: "2", value: "2" },
	{ label: "3", value: "3" },
	{ label: "4", value: "4" },
	{ label: "5+", value: "5+" },
];

export const type_lane_marketing = [
	/*{ label: "Lane Type", value: "Lane Type" },
	{ label: "Over the road", value: "Over the road" },
  { label: "Regional", value: "Regional" },
  { label: "Intrastate, but WILL go OTR", value: "Intrastate, but WILL go OTR" },
  { label: "Intrastate, WILL NOT go OTR", value: "Intrastate, WILL NOT go OTR" },*/
	{ label: "OTR", value: "OTR" },
	{ label: "Home every weekend", value: "Home every weekend" },
	{ label: "Local (Home multiple times a week)", value: "Local (Home multiple times a week)" },
];

export const type_trailer_marketing = [
	{ label: "53' Dry Van", value: "53' Dry Van" },
	{ label: "53' Reefer", value: "53' Reefer" },
	{ label: "Power-only", value: "Power-only" },
	{ label: "Flatbed", value: "Flatbed" },
	{ label: "Box Truck", value: "Box Truck" },
	{ label: "Sprinter Van", value: "Sprinter Van" },
	{ label: "Multiple Trucks", value: "Multiple Trucks" },
	{ label: "Missing/Obtaining Trailer", value: "Missing/Obtaining Trailer" },
];

export const timezones = [
	{ value: "UTC", label: "UTC", groups: ["PAYROLL"] },
	{ value: "EDT", label: "EDT - America/New York", groups: [] },
	{ value: "EST", label: "EST - America/New York", groups: ["PAYROLL"] },
	{ value: "CDT", label: "CDT - America/Chicago", groups: [] },
	{ value: "CST", label: "CST - America/Chicago", groups: ["PAYROLL"] },
	{ value: "MDT", label: "MDT - America/Denver", groups: [] },
	{ value: "MST", label: "MST - America/Denver", groups: ["PAYROLL"] },
	{ value: "PDT", label: "PDT - America/Los_Angeles", groups: ["PAYROLL"] },
	{ value: "PST", label: "PST - America/Los_Angeles", groups: [] },
	{ value: "AKDT", label: "AKDT - America/Anchorage", groups: [] },
	{ value: "AKST", label: "AKST - America/Anchorage", groups: [] },
	{ value: "HADT", label: "HADT - America/Adak", groups: [] },
	{ value: "HAST", label: "HAST - Pacific/Honolulu", groups: [] },
	// Add more timezone abbreviations and identifiers as needed
];
