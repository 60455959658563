import {
	AMOUNT,
	BROKER,
	DELIVERED_DATE,
	INVOICE_CELL,
	INVOICE_STATUS_FIELD,
	TRIP_INVOICE_CELL,
} from "./tripInvoicingConf";

const smartPayTripInvoicingNeedAttention = ({ readOnly = false, isMainHandler = false }) => {
	return {
		urlGET: `api/trip-invoicing/carriers/:carrierId/invoices`,
		idKey: "_id",
		listenEvent: ["tripsRevision"],
		segmentKey: "TRIP_INVOICING",
		content: {
			table: {
				variant: "skinny",
				orderBy: [
					"broker_data.broker_name",
					"amount",
					"delivered_date",
					"invoiced_date",
					"factoring_date",
					"days_age",
					"submitted_date",
					"days_net_term",
				],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
				defaut: {
					size: 20,
				},
			},
			order: {
				defaut: {
					key: "delivered_date",
					dir: "desc",
				},
			},
			defaults: {
				initial: {
					filters: {
						smartpay_eligible: "__TRUE__",
					},
				},
			},
			click: {
				label: "Edit",
				grid: { variant: "menu" },
				dialogBuilder: (item) => ({
					viewId: "TRIP_INVOICE_VIEW",
					dataIds: { carrierId: item.carrier, tripId: item.trip_data.trip, invoiceId: item._id },
				}),
			},
			items: [
				INVOICE_STATUS_FIELD(readOnly),
				{ type: "separator" },
				...(isMainHandler
					? [
							{
								key: "carrier__view.label",
								builder: (item) => item.carrier__view,
								type: "carrier__view",
								label: "Carrier",
								viewConfig: {
									readOnly,
									showMcNumber: true,
									showTestCarrier: true,
								},
								table: { width: 220 },
							},
					  ]
					: []),
				INVOICE_CELL,
				TRIP_INVOICE_CELL,
				BROKER,
				DELIVERED_DATE,
				AMOUNT,
			],
		},
	};
};

export default smartPayTripInvoicingNeedAttention;
