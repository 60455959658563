import { useEffect, useState, useMemo } from "react";
import _ from "@lodash";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { NARROW_FIELDS } from "@fuse/default-settings";

function SmarthopFiltersView(props) {
	const onChange = props.onChange;
	const onExport = props.onExport;
	const onReload = props.onReload;
	const onSwitch = props.onSwitch;
	const dataIds = props.dataIds;
	const config = props.config;
	const mobile = props.mobile;
	const groupsView = props.groupsView;
	const presetsView = props.presetsView;
	const settingsView = props.settingsView;
	const exportView = props.exportView;
	const switchView = props.switchView;

	const [defaultValues] = useState(props.defaultValues);
	const [model, setModel] = useState(props.defaultValues);
	const [search, setSearch] = useState(props.defaultValues);

	const initValues = useMemo(() => {
		let initValues = { ...props.initValues };

		Object.keys(initValues).forEach((key) => {
			if (key.includes("__view")) {
				if (initValues[key].includes("[") && initValues[key].includes("]")) {
					const mainKey = key.replace("__view", "");
					let sanitizedLabels = initValues[key].replace("[", "").replace("]", "").split(",");
					let sanitizedValues = initValues[mainKey].replace("[", "").replace("]", "").split(",");

					initValues[key] = sanitizedValues.map((item, i) => ({ value: item, label: sanitizedLabels[i] }));
					initValues[mainKey] = sanitizedValues;
				} else {
					initValues[key] = { value: initValues[key.replace("__view", "")], label: initValues[key] };
				}
			}
		});

		Object.keys(initValues).forEach((key) => {
			if (key.includes(".")) {
				let obj = initValues;
				let subKeys = key.split(".");
				subKeys.forEach((subkey, i) => {
					if (i === subKeys.length - 1) {
						obj[subkey] = initValues[key];
						delete initValues[key];
					} else if (!obj[subkey]) {
						obj[subkey] = {};
						obj = obj[subkey];
					}
				});
			}
		});

		setModel(null);
		return initValues;
		// eslint-disable-next-line
	}, [props.initValues]);

	useEffect(() => {
		if (onChange && model && !_.isEqual(search, model)) {
			setSearch(model);
			onChange(model);
		}
		// eslint-disable-next-line
	}, [model]);

	if (!config) {
		return null;
	}

	let searchFilter;
	let exportAction;
	let switchAction;
	let otherFilters;
	let watchFields = [];

	if (config?.search) {
		watchFields.push("search");
		searchFilter = {
			key: "search",
			type: "text",
			label: "Search",
			field: { noAutocomplete: true, noErrorMessage: true, commitDelay: 1000 },
		};

		if (config?.searchDescription) {
			searchFilter.description = config.searchDescription;
		}
	}

	if (exportView) {
		watchFields.push("download");
		exportAction = {
			key: "export",
			type: "action",
			icon: "download",
			button: {
				alwaysEnabled: true,
				color: "primary",
				classes: {
					root: NARROW_FIELDS ? "rounded h-48 -mt-1" : "rounded h-52 -mt-1",
				},
				customType: "EXPORT",
			},
			action: "CUSTOM",
		};
	}

	if (switchView) {
		watchFields.push("switch");
		switchAction = {
			key: "switch",
			type: "action",
			icon: initValues.current === "GRID" ? "list" : "view_agenda",
			button: {
				alwaysEnabled: true,
				color: "primary",
				classes: {
					root: NARROW_FIELDS ? "rounded h-48 -mt-1" : "rounded h-52 -mt-1",
				},
				customType: "SWITCH",
			},
			action: "CUSTOM",
		};
	}

	if (config?.items?.length) {
		config?.items.forEach((item) => {
			watchFields.push(item.key);
		});
		otherFilters = [...config?.items];
	}

	let form = { form: { noErrorMessage: true }, items: [] };
	let hiddenFieldsKeys = [];
	if (searchFilter || exportAction || switchAction || otherFilters) {
		let visible = [];
		let invisible = [];

		if (switchAction) {
			visible.push(switchAction);
		}

		if (exportAction) {
			visible.push(exportAction);
		}

		if (searchFilter) {
			visible.push(searchFilter);
		}

		otherFilters?.forEach((item) => {
			if (item.hidden) return;

			if (!item.field) {
				item.field = {};
			}

			item.field.noAutocomplete = true;
			item.field.noErrorMessage = true;

			if (
				visible.length <
				(groupsView || presetsView || settingsView
					? 0
					: mobile
					? 1
					: config.countVisible >= 0
					? config.countVisible
					: 4)
			) {
				visible.push(item);
			} else {
				invisible.push(item);
				hiddenFieldsKeys.push(item.key);
			}
		});

		let badge = 0;
		let data = model ?? initValues;

		if (data) {
			Object.keys(data).forEach((key) => {
				if (hiddenFieldsKeys.includes(key) && !!data[key] && data[key] !== false && data[key] !== "__NOT_SELECTED__") {
					badge++;
				}
			});
		}

		form.items = [
			{
				type: "group",
				group: { classes: { direction: "flex-row", root: "" } },
				content: {
					items: [
						{
							type: "group",
							group: { classes: { root: "" } },
							content: {
								items: [...visible],
							},
						},
						...(invisible.length > 0
							? [
									{
										type: "menu",
										icon: groupsView ? "visibility" : settingsView ? "settings" : presetsView ? "layers" : "filter_alt",
										button: {
											color: groupsView ? "secondary" : "primary",
											classes: { root: NARROW_FIELDS ? "rounded h-48 -mt-2" : "rounded h-52 mt-1" },
										},
										badge: {
											label: badge > 0 ? badge + "" : null,
										},
										content: {
											items: [
												{
													type: "section",
													label: groupsView
														? "Visible Columns Groups"
														: settingsView
														? "Settings"
														: presetsView
														? "Quick Actions"
														: "Search Filter",
													section: { classes: { label: "text-18 mb-2" } },
												},
												...invisible,
											],
										},
									},
							  ]
							: []),
					],
				},
			},
		];
	}

	return (
		<SmarthopFormView
			content={form}
			mode={"CREATE"}
			noInitValidation={true}
			dataIds={dataIds}
			data={initValues}
			trackChangedFields={watchFields}
			onCustom={(action, metadata) => {
				if (action === "RELOAD") {
					onReload?.();
				}
				if (action === "EXPORT") {
					onExport?.();
				}
				if (action === "SWITCH") {
					onSwitch?.();
				}
			}}
			onChangeCommitted={(updatedModel, key) => {
				Object.keys(updatedModel).forEach((key) => {
					if (Array.isArray(updatedModel[key]) && key.includes("__view")) {
						if (updatedModel[key]?.[0]?.label) {
							updatedModel[key] = {
								value: "[" + updatedModel[key]?.map((item) => item?.value).join(",") + "]",
								label: "[" + updatedModel[key]?.map((item) => item?.label).join(",") + "]",
							};
							updatedModel[key.replace("__view", "")] = updatedModel[key].value;
						}
					}
				});

				Object.keys(updatedModel).forEach((key) => {
					if (key.includes("__view") && updatedModel[key]?.label) {
						updatedModel[key] = updatedModel[key]?.label;
					} else if (
						key.includes(".undefined") ||
						key.includes("__view") ||
						key.includes("__flag") ||
						(key === "search" && updatedModel[key] === "") ||
						(!defaultValues?.[key] && updatedModel[key] === "__NOT_SELECTED__") ||
						updatedModel[key] === null ||
						updatedModel[key] === undefined
					) {
						delete updatedModel[key];
					} else if (_.isObject(updatedModel[key])) {
						let newKey = key;
						let newValue = updatedModel[key];

						while (_.isObject(newValue)) {
							let firstKey = Object.keys(newValue)[0];
							newKey += "." + firstKey;
							newValue = newValue[firstKey];
						}

						if (newKey.includes(".undefined")) {
							// TODO handle this later, issue with empty multiselect field
						} else {
							updatedModel[newKey] = newValue;
						}
						delete updatedModel[key];
					}
				});

				if (onChange) {
					setModel(updatedModel);
				}
			}}
		>
			{props.children}
		</SmarthopFormView>
	);
}

export default SmarthopFiltersView;
