import { forwardRef, useState, useEffect } from "react";
import { SnackbarContent } from "notistack";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import { motion } from "framer-motion";

const NotificationMessage = forwardRef((props, ref) => {
	const { item, onClose } = props;

	const handleClose = () => {
		if (onClose) {
			onClose(item.id);
		}
	};

	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		function handleResize() {
			let diff = height - window.innerHeight;
			if (diff > 100 || diff < 100) {
				setHeight(window.innerHeight);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const successColor = props.useGreenForSuccess ? "bg-green-700" : "bg-blue-700";
	const successButtonColor = props.useGreenForSuccess ? "bg-green hover:bg-green-900" : "bg-blue";

	const bgColor = item?.option === "success" ? successColor : "bg-red-700";
	const coloButtom = item?.option === "success" ? { root: successButtonColor } : { root: "bg-red" };
	return (
		<SnackbarContent
			ref={ref}
			className="mx-auto items-center justify-center max-w-360 md:max-w-450 w-full relative pointer-events-auto py-4 top-2/4"
			style={{ marginTop: height / 2 - 100 }}
		>
			<Card
				className={clsx(
					"flex items-center justify-center relative w-full rounded-8 p-14 md:p-20 min-h-40 md:min-h-64 shadow text-white " +
						bgColor
				)}
				elevation={0}
				component={motion.div}
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
			>
				<div className="flex-auto flex flex-col items-center justify-center cursor-pointer">
					<div className="flex flex-row">
						<div className="self-center basis-1/4">
							<Icon
								className={"text-30 mr-16 " + (item?.option === "success" ? "text-blue-100" : "text-red-100")}
								color="action"
							>
								{item?.option === "success" ? "info" : "warning"}
							</Icon>
						</div>
						<div className="basis-1/2">
							<Typography className="font-normal tracking-wide text-13 md:text-14">{item?.message}</Typography>
						</div>
					</div>
					<div className="flex-auto flex flex-col items- justify-center cursor-pointer">
						<div className="flex flex-row">
							<Button
								type="submit"
								variant="contained"
								color={item?.option === "success" ? "primary" : "secondary"}
								className="w-6/12 mx-auto mt-16 mb-2 mr-10"
								value="legacy"
								classes={coloButtom}
								onClick={handleClose}
							>
								{item?.acceptButtonLabel ? "Cancel" : "Ok"}
							</Button>
							{item?.acceptButtonLabel && (
								<Button
									type="submit"
									variant="contained"
									color={item?.option === "success" ? "primary" : "secondary"}
									className="w-6/12 mx-auto mt-16 mb-2"
									value="legacy"
									classes={coloButtom}
									onClick={() => {
										if (onClose) {
											onClose(item.id);
										}
										item?.handleAccept();
									}}
								>
									{item?.acceptButtonLabel}
								</Button>
							)}
						</div>
					</div>
				</div>
			</Card>
		</SnackbarContent>
	);
});

export default NotificationMessage;
