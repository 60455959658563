const achAccountSummaryForm = (isSmartPayPayoutAccount) => ({
	urlGET: isSmartPayPayoutAccount
		? "billing/carriers/:carrierId/payout-account"
		: "billing/carriers/:carrierId/ach-account",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision", "discountRevision"],
	header: { view: { label: isSmartPayPayoutAccount ? `Payout Account Summary` : `ACH Account Summary` } },
	content: {
		view: {
			items: [
				{
					key: "reauth",
					type: "banner",
					label: "Please re-authenticate to regain account's access",
					hidden: (item) => !item?.reAuthRequired,
					banner: {
						icon: "warning",
						classes: { icon: "text-red-600", root: "bg-red-100" },
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "accountName",
								type: "text",
								label: "Name",
							},
							{
								key: "last4",
								type: "password",
								label: "Account #",
								builder: (item) => (item?.last4 ? `**** **** **** ${item?.last4}` : item?.last4),
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "status",
								type: "text",
								label: "Status",
							},
						],
					},
				},
			],
		},
	},
});

export default achAccountSummaryForm;
