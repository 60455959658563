const createsSend2FAForm = (email, phone) => ({
	noInitValidation: true,
	items: [
		{
			key: "message",
			type: "message",
			label: `To complete login please verify your identity, press continue to send verification code`,
		},
		{
			key: "type",
			type: "radio",
			label: "Where to send code?",
			required: true,
			options: [
				{
					value: "EMAIL",
					label: `Send code to your email ${email}`,
				},
				...(phone?.length > 0
					? [
							{
								value: "PHONE",
								label: `Send code to your phone ${phone}`,
							},
					  ]
					: []),
			],
		},
		{
			type: "action",
			label: "Continue",
			action: "SUBMIT",
			button: {
				classes: { root: "mb-16" },
			},
		},
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
			},
		},
	],
});

export default createsSend2FAForm;
