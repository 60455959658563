import { addComment } from "app/services/commentServices";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { showErrorSnackbar, showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { getUserId } from "app/services/LoginService";

function TripCommentPanel(props) {
	const [message, setMessage] = useState("");
	const snackbar = useSnackbar();
	const dispatch = useDispatch();
	const userId = getUserId();

	const onKeyDown = (event) => {
		if (event.ctrlKey && event.key === "Enter") {
			event.preventDefault();
			setMessage((val) => val + "\n");
		} else if (event.key === "Enter") {
			onMessage(event);
		}
	};

	const onMessage = (event) => {
		event.preventDefault();
		const data = { content: message, creatorUser: userId, tripId: props?.tripId };
		addComment({ data })
			.then(() => {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				showSnackbar(snackbar, "Done!", "success");
				setMessage("");
			})
			.catch((error) => {
				showErrorSnackbar(snackbar, error);
			});
	};
	const onInputChange = (event) => {
		let input = event.target.value;
		setMessage(input);
	};

	return (
		<form onSubmit={onMessage} className="flex w-full pb-8">
			<Paper variant="outlined" className={"flex w-full rounded-8"} style={{ backgroundColor: "#f6f7f9" }}>
				<Box className={"flex w-full rounded-8"}>
					<TextField
						multiline={true}
						id="message-input"
						className="flex-1"
						value={message}
						onChange={onInputChange}
						onKeyDown={onKeyDown}
						InputProps={{
							disableUnderline: true,
							classes: {
								root: "flex flex-grow flex-shrink-0 mx-14 ",
								input: "pt-3",
							},
							placeholder: "Type your comment",
						}}
						InputLabelProps={{
							shrink: false,
							autoComplete: "off",
						}}
					/>

					<Button
						onClick={(event) => onMessage(event)}
						className="relative m-4 right-0 rounded-8"
						style={{ backgroundColor: "#E7683D", color: "white", padding: "6px", width: "10%" }}
					>
						Save
					</Button>
				</Box>
			</Paper>
		</form>
	);
}

export default TripCommentPanel;
