import { Typography } from "@material-ui/core";
import { formatDate } from "app/main/utils/dateUtils";

/**
 * Returns icons according to cell's value
 * Mostly used in ToolkitProvider react-bootstrap-table components
 *
 * @param {string} cell selected component cell.
 * @param {object} row selected component row.
 */ function statusFormatterDash(cell) {
	if (cell) {
		let status = cell;
		let status_style = status.toLowerCase().replace(/ /g, "-");

		return (
			<span className={status_style}>
				<span className={"dot dot-" + status_style}></span>
				{status}
			</span>
		);
	}
}

const DATE_UPDATE = {
	key: "date_update",
	type: "component",
	label: "Date",
	builder: (item) => {
		return (
			<div className="flex flex-col">
				<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
					{item.date_update ? formatDate(item.date_update, item.date_update) : "-"}
				</Typography>
				<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
					{statusFormatterDash(item.status)}
				</Typography>
			</div>
		);
	},
};

const LEFT_ROUTE = {
	key: "miles_left",
	type: "component",
	label: "Left",
	builder: (item) => {
		return (
			<div className="flex flex-col">
				{item.miles_left >= 0 && (
					<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
						{item.miles_left >= 0 ? `${item.miles_left} mi` : "N/A"}
					</Typography>
				)}
				<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
					{item.current_location ?? ""}
				</Typography>
			</div>
		);
	},
};

const COMMENTS = {
	builder: (item) => item.comments,
	key: "comments",
	type: "text",
	label: "Comments",
	collapsibleLength: 80,
};

const LOGGED_BY = {
	key: "user__view",
	type: "user__view",
	viewConfig: { showEmail: false, showRole: true, showActionSource: true },
	label: "User",
};

const loadHistoryList = ({ publicView }) => {
	const items = [DATE_UPDATE, LEFT_ROUTE];
	if (!publicView) {
		items.splice(2, 0, COMMENTS);
		items.push(LOGGED_BY);
	}
	return {
		urlGET: publicView
			? "/api/public/url/carries/:carrierId/trips/:tripId"
			: "api/tracking/carries/:carrierId/trips/:tripId",
		idKey: "_id",
		triggerEvent: "historyRevision",
		listenEvent: "historyRevision",
		segmentKey: "USER_LOAD_HISTORY",
		content: {
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			table: {
				variant: "skinny",
				orderBy: ["date_update"],
			},
			order: {
				defaut: {
					key: "date_update",
					dir: "desc",
				},
			},
			groups: {
				orderDefault: {
					key: "date_update",
					dir: "desc",
					item: "comments",
				},
				items: [],
			},
			filters: {},
			items: items,
		},
	};
};

export default loadHistoryList;
