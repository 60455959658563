import { columns } from "app/main/profile/carriers/fuel/SmartfuelUtils.js";
import { centsToDollars } from "app/main/utils/financeUtils";

const fuel_transactions = {
	// TODO | URL for admin view
	urlGET: `api/fuel/balances/list`,
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision", "fuelRevision"],
	segmentKey: "SMARTFUEL_BALANCES",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["balance"],
		},
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		export: {
			fileName: "SmartFuel Balances",
		},
		order: {
			defaut: {
				key: "balance",
				dir: "desc",
			},
		},
		filters: {
			countVisible: 2,
			items: [
				{
					key: "_id",
					type: "autocomplete",
					label: "Carrier",
					field: {
						noErrorMessage: true,
						classes: {},
					},
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						preloadDefault: true,
						listenEvent: "profileRevision",
						params: {
							options_active_only: true,
						},
					},
				},
				{
					key: "smartFuelStatus",
					type: "select",
					label: "SmartFuel Module",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "ENABLED",
							label: "Enabled",
						},
						{
							value: "DISABLED",
							label: "Disabled",
						},
					],
				},
				{
					key: "carriers_type",
					type: "select",
					label: "Internal Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
			],
		},
		items: [
			columns.CARRIER,
			{
				key: "balance",
				label: "Balance",
				type: "currency",
				builder: (item) => centsToDollars(item?.balance),
				export: {
					builder: (item) => centsToDollars(item?.balance),
				},
			},
		],
	},
};

export default fuel_transactions;
