import { SmarthopList, adminLoadTrackingDetailsList } from "@smarthop/list";

function AdminLoadTrackingDetailsPage(props) {
  const trackingId = props.dataIds.id;
  
	return (
    <SmarthopList mode="table" config={adminLoadTrackingDetailsList} dataIds={{ trackingId }}/>
	);
}

export default AdminLoadTrackingDetailsPage;