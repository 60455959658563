import Typography from "@material-ui/core/Typography";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";

const createElement = (label, element) => (
	<div className={"flex flex-col w-full pt-10 pb-10"}>
		<Typography color="primary" className="font-semibold">
			{label}
		</Typography>
		<Typography color="textSecondary" className="mt-5 break-all ml:break-words">
			{formatCurrency(element)}
		</Typography>
	</div>
);

const fuelTransactionsSummaryForm = {
	urlGET: "/billing/carriers/:carrierId/fuel-transactions-summary?start_date=:start_date&end_date=:end_date&billing_cycle=:billing_cycle",
	header: {
		view: { label: "View Fuel Transactions Summary" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "total_due",
								type: "component",
								builder: (item) => createElement("Total Due", item?.total_due),
							},
							{
								key: "total_savings",
								type: "component",
								builder: (item) => createElement("Total Savings", item?.total_savings),
							},
						],
					},
				},
			],
		},
	},
};

export default fuelTransactionsSummaryForm;
