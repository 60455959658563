import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import MessageHeader from "./MessageHeader";

function MessageViewLoadBookedProcessed(props) {
	const { message } = props;
	const incoming = props.incoming;
	const variant = props.variant;

	const colorPrimary = variant === "dark" ? "text-white font-semibold" : "text-gray-600";
	const colorSecondary = variant === "dark" ? "text-white" : "text-gray-900";
	const actionType = message?.content?.actionType;
	const titleMessage = message?.content?.errorMessage ?? (actionType ? actionType + " request started" : "");

	return (
		<div className="flex flex-col w-full px-4 text-12 md:text-13 md:min-w-300">
			<MessageHeader
				message={message}
				incoming={incoming}
				variant={variant}
				showBroker={true}
				showLoadStatus={true}
				description={titleMessage}
			/>

			<Divider className={"mt-10 mb-6 " + (incoming ? "" : "bg-grey-400")} />

			<div className={"flex flex-col md:flex-row items-center"}>
				<div className="flex flex-col w-full md:pl-4">
					<Typography className={"pt-6 text-12 md:text-13 " + colorPrimary}>Load ID</Typography>
					<Typography className={"text-13 md:text-14 " + colorSecondary}>{message?.content?.loadId}</Typography>
				</div>
				{(actionType === "BID" || actionType === "BOOK") && (
					<div className="flex flex-col w-full md:pl-20">
						<Typography className={"pt-6 text-12 md:text-13 " + colorPrimary}>Rate</Typography>
						<Typography className={"text-13 md:text-14 " + colorSecondary}>{message?.content?.price}</Typography>
					</div>
				)}
				{actionType === "BID" && (
					<div className="flex flex-col w-full md:pl-20">
						<Typography className={"pt-6 text-12 md:text-13 " + colorPrimary}>Bid Rate</Typography>
						<Typography className={"text-13 md:text-14 " + colorSecondary}>{message?.content?.bidPrice}</Typography>
					</div>
				)}
			</div>
		</div>
	);
}
export default MessageViewLoadBookedProcessed;
