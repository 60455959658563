import React from "react";
import { getRoleLabel } from "app/services/LoginService";
import { authRoles } from "app/main/consts";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import ViewContentSection from "app/main/chat/messages/ViewContentSection";
import ViewTitleSection from "app/main/chat/messages/ViewTitleSection";
import ViewDescriptionSection from "app/main/chat/messages/ViewDescriptionSection";
import ViewBanner from "app/main/chat/messages/ViewBanner";
import ViewButtonSection from "app/main/chat/messages/ViewButtonSection";
import ViewButton from "app/main/chat/messages/ViewButton";

import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";

function MessageViewRequestLocation(props) {
	const isNativeEnv = window?.location?.href?.includes("/native/");
	const { message } = props;
	const role = getRoleLabel();
	const incoming = props.incoming;
	const variant = props.variant;
	const isOwner = authRoles.externalUsers.includes(role);
	const [openConfirmMessage, setOpenConfirmMessage] = React.useState(false);

	const status = message?.content?.status;
	let titleDescription =
		status === "SENT"
			? "Oops! We are unable to retrieve the truck location from your ELD device. Please enable GPS tracking in your iOS/Android Smarthop App!"
			: status === "EXPIRED"
			? "Load GPS location is out of date. Please make sure you accepted the tracking request using Smarthop iOS/Android App and granted requested permissions!"
			: status === "TRACKING"
			? "Load location is being actively tracked using GPS transmitted via Smarthop App"
			: status === "FINISHED" || status === "FINISH"
			? "Load tracking is now disabled. Thanks for using Smarthop App load tracking!"
			: "";

	const loadFields = [{ label: "Load ID", value: message?.content?.loadId }];

	const showAppBanner = !isNativeEnv && status === "SENT";
	const showTrackingButton = isOwner && variant !== "notification" && isNativeEnv && status === "SENT";

	const parseTransactionStatus = (status) => {
		if (status === "SENT") return { title: "New", color: "bg-blue" };
		if (status === "EXPIRED") return { title: "Outdated", color: "bg-red" };
		if (status === "TRACKING") return { title: "In Progress", color: "bg-orange" };
		if (status === "FINISHED" || status === "FINISH") return { title: "Done", color: "bg-green" };
		return { title: status, color: "bg-blue" };
	};

	const handleStartGSPTrackingClick = () => {
		const msg = "Please only start tracking if you are driving this load";
		setOpenConfirmMessage(msg);
	};

	const handleConfirmGPSTracking = () => {
		setOpenConfirmMessage(false);
		if (isNativeEnv) {
			const dataIds = {
				tripId: message?.content?.tripId,
				carrierId: message?.content?.carrierId,
				loadId: message?.content?.loadId,
				truckId: message?.content?.truckId,
				driverId: message?.content?.driverId,
			};
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({ type: "START_GPS_TRACKING", data: { dataIds, tripId: message?.content?.tripId } })
			);
			return;
		}
	};

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={true}
			variant={variant}
			headerConfig={{ title: "Load Tracking", subtitle: titleDescription, showDivider: true }}
			contentFields={[
				{
					type: "LABEL_VALUE",
					label: "Load ID",
					value: message?.content?.loadId,
				},
				{
					type: "BANNER",
					message:
						"Please use iOS/Android Smarthop app, open chat with this message and accept the GPS tracking request.",
					icon: "announcement",
				},
			]}
			messageActions={[
				{
					key: "START_TRACKING",
					title: "Start tracking",
					hideOnDesktop: true,
					hideOnMobile: false,
					onClick: () => {
						handleStartGSPTrackingClick();
					},
				},
			]}
			footerComponents={
				<SmarthopConfirmDialog
					open={!!openConfirmMessage}
					title={openConfirmMessage}
					onClose={() => setOpenConfirmMessage(false)}
					onAccept={handleConfirmGPSTracking}
					acceptMsg="Start tracking"
					closeMsg="Cancel"
				/>
			}
			contentComponents={
				<>
					<ViewContentSection variant={variant} incoming={incoming}>
						<ViewTitleSection
							variant={variant}
							incoming={incoming}
							title={"Load Tracking"}
							message={titleDescription}
							badge={parseTransactionStatus(status)?.title}
							classess={{ badge: parseTransactionStatus(status)?.color }}
						/>
						<ViewDescriptionSection variant={variant} incoming={incoming} items={loadFields} />
						{showAppBanner && (
							<ViewBanner
								variant={variant}
								incoming={incoming}
								icon="announcement"
								message={
									"Please use iOS/Android Smarthop app, open chat with this message and accept the GPS tracking request."
								}
							/>
						)}
						<ViewButtonSection variant={variant} incoming={incoming}>
							{showTrackingButton && (
								<ViewButton
									variant={variant}
									incoming={incoming}
									color={"secondary"}
									onClick={handleStartGSPTrackingClick}
									label={"Activate GPS tracking"}
								/>
							)}
						</ViewButtonSection>
					</ViewContentSection>
					<SmarthopConfirmDialog
						open={!!openConfirmMessage}
						title={openConfirmMessage}
						onClose={() => setOpenConfirmMessage(false)}
						onAccept={handleConfirmGPSTracking}
						acceptMsg="Start tracking"
						closeMsg="Cancel"
					/>
				</>
			}
		/>
	);
}
export default MessageViewRequestLocation;
