import PageTabWrapper from "app/main/common/PageTabWrapper";
import FormSection from "app/main/tools/FormSection";
import WidgetsBoard from "app/main/widgets/WidgetsBoard";

import { internalCarriersList, internalUsersList, SmarthopList } from "@smarthop/list";
import { useEffect } from "react";

function AccountInfoView(props) {
	useEffect(() => {
		props?.setTitle?.("Account Information");
		// eslint-disable-next-line
	}, []);
	const accountId = props.accountId ?? props.dataIds?.accountId;
	const isView = true;

	return (
		<PageTabWrapper
			isView={isView}
			tabs={[
				{
					label: "Details",
					component: (
						<div className="flex flex-row h-screen60 py-14">
							<div className="flex flex-col w-1/2">
								<FormSection title="Information" formId="ACCOUNT_INFORMATION_FORM" dataIds={{ accountId }} />
							</div>
						</div>
					),
				},
				{
					label: "Performance",
					component: <WidgetsBoard boardKey="ACCOUNT_HOME" accountId={accountId} margin={[20, 20]} />,
				},
				{
					label: "Carriers",
					component: (
						<SmarthopList
							key="accountCarriers"
							urlKey="accountCarriers"
							mode="TABLE"
							config={internalCarriersList({ accountSubset: true, isView })}
							dataIds={{ accountId }}
						/>
					),
				},
				{
					label: "Users",
					component: (
						<SmarthopList
							key="accountUsers"
							urlKey="accountUsers"
							mode="TABLE"
							config={internalUsersList({ accountSubset: true, isView })}
							dataIds={{ accountId }}
						/>
					),
				},
			]}
		/>
	);
}

export default AccountInfoView;
