import moment from "moment";

export const showTutorial = (key, user) => {
	return !getTutorialLocalData(key) && checkCreateAt(user);
};

export const setTutorialLocalData = (key, checkbox) => {
	const tutorialKeys = localStorage.getItem("tutorials");

	let tutorialJson = {};
	if (tutorialKeys && tutorialKeys !== "undefined") {
		tutorialJson = JSON.parse(tutorialKeys);
	}

	tutorialJson[key] = { firsTime: true, notShowAgain: checkbox };
	localStorage.setItem("tutorials", JSON.stringify(tutorialJson));
};

export const getTutorialLocalData = (key) => {
	try {
		const tutorialKeys = localStorage.getItem("tutorials");
		if (!tutorialKeys && tutorialKeys !== "undefined") return false;

		const tutorialJson = JSON.parse(tutorialKeys);
		if (!tutorialJson[key]) return false;

		return tutorialJson[key].notShowAgain || tutorialJson[key].firsTime;
	} catch {
		return false;
	}
};

const checkCreateAt = (user) => {
	const createAt = moment(user.created_at).utc();
	const now = moment().utc();

	const diffDay = now.diff(createAt, "days");
	return diffDay < 50;
};
