import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// Utils
import { centsToDollars } from "app/main/utils/financeUtils";

// TODO: Adjust URL format
const createElement = (label, content, type = "text") => {
	let element = "No Information";
	if (content.length) {
		element = content.map(({ value, number_invoice }) => {
			return (
				<div>
					{type === "link" ? <Link href={value} target="_blank">{`Invoice ${number_invoice} ${label}`}</Link> : value}
				</div>
			);
		});
	}
	return (
		<div className="px-4 flex">
			<div className="w-full pt-10 pb-10">
				<Typography color="primary" className="font-semibold">
					{`Invoice ${label}`}
				</Typography>
				<Typography color="textSecondary" className="mt-5 break-all ml:break-words">
					{element}
				</Typography>
			</div>
		</div>
	);
};

const carrierLedgerDetailsForm = {
	urlGET: "api/finance/carriers/:carrierId/carrier-ledger/transaction/:id",
	idKey: "_id",
	triggerEvent: "",
	listenEvent: "",
	header: {
		view: { label: "Ledger Details" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{ key: "type", type: "text", label: "Type" },
							{
								key: "date",
								type: "picker",
								label: "Transaction Date",
								translate: "",
								picker: {
									type: "date",
								},
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{ key: "description", type: "text", label: "Description" },
							{ key: "transaction_type", type: "text", label: "Transaction Type" },
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "debit_amount",
								type: "currency",
								label: "Debit Amount",
								builder: (item) => {
									const amount = item.debit_amount;
									return item?.type === "cash_advance" ? centsToDollars(amount) : amount;
								},
							},
							{
								key: "credit_amount",
								type: "currency",
								label: "Credit Amount",
								builder: (item) => {
									const amount = item.credit_amount;
									return item?.type === "cash_advance" ? centsToDollars(amount) : amount;
								},
							},
						],
					},
				},
				{
					type: "group",
					group: { classes: { root: " flex flex-col w-full " } },
					content: {
						items: [
							{
								type: "component",
								builder: (item) => {
									let invoices = [];
									item?.details?.invoices?.forEach(({ number_invoice }) => {
										if (number_invoice) invoices.push({ value: number_invoice, number_invoice });
									});
									return createElement("Number", invoices);
								},
							},
							{
								type: "component",
								builder: (item) => {
									let urls = [];
									item?.details?.invoices?.forEach(({ hosted_invoice_url, number_invoice }) => {
										if (hosted_invoice_url) urls.push({ value: hosted_invoice_url, number_invoice });
									});
									return createElement("URL", urls, "link");
								},
							},
							{
								type: "component",
								builder: (item) => {
									let urls = [];
									item?.details?.invoices?.forEach(({ invoice_pdf, number_invoice }) => {
										if (invoice_pdf) urls.push({ value: invoice_pdf, number_invoice });
									});
									return createElement("PDF", urls, "link");
								},
							},
						],
					},
				},
			],
		},
	},
};

export default carrierLedgerDetailsForm;
