import { createSlice } from "@reduxjs/toolkit";

const tutorialDialogSlice = createSlice({
	name: "tutorialDialog",
	initialState: {
		tutorialDialog: [],
	},
	reducers: {
		openTutorialDialog: (state, action) => {
			if (!action?.payload?.tutorialId) return;

			state.tutorialDialog = [
				{
					tutorialId: action.payload?.tutorialId,
					type: action.payload?.type,
				},
			];
		},
		closeTutorialDialog: (state) => {
			state.tutorialDialog = [];
		},
	},
});

export const { openTutorialDialog, closeTutorialDialog } = tutorialDialogSlice.actions;

export default tutorialDialogSlice.reducer;
