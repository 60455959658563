import { forwardRef } from "react";

// components
import LegendDialog from "app/main/legend/LegendDialog";

// config
import strategyLegendConfig from "./strategyLegendConfig";

const StrategyLegend = (props, ref) => <LegendDialog config={strategyLegendConfig} ref={ref} />;

export default forwardRef(StrategyLegend);
