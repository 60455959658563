import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const errorStyle = {
	icon: "error_outline",
	color: "text-red-600",
};

const successStyle = {
	icon: "check_circle",
	color: "text-green-600",
};

const createAlertMessage = (item) => {
	let message = "";
	let style = {};

	if (item?.status === "NEW" || item?.status === "PENDING") {
		message = "The carrier account is in review. ";
		style = errorStyle;
	} else {
		message = "The account was onboarded successfully, you can now make Book Now.";
		style = successStyle;
	}

	if (item.authority === "MISSING" || item.insurance === "MISSING" || item.w9 === "MISSING") {
		message = message + "You need to submit your information again as some documents have missing status.";
		style = errorStyle;
	}

	return (
		<div className="flex flex-row items-center py-10">
			<Icon className={`text-28 mr-8$ ${style.color}`}>{style.icon}</Icon>
			<Typography className={style.color} style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
				{message}
			</Typography>
		</div>
	);
};

const loadsmart = {
	urlGET: "api/profile/carriers/:carrierId/integrations/brokers/loadsmart/check-status",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		view: { label: "Check Status Credentials" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "name",
								type: "text",
								label: "Carrier",
							},
							{
								type: "text",
								label: "Status",
								key: "status",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "id",
								type: "text",
								label: "Integration id",
							},
							{
								key: "account_id",
								type: "text",
								label: "Account ID",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "authority",
								type: "text",
								label: "Document Authority",
							},
							{
								key: "insurance",
								type: "text",
								label: "Document Insurance",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "w9",
								type: "text",
								label: "Document W9",
							},
						],
					},
				},
				{
					label: "",
					type: "component",
					builder: (item) => createAlertMessage(item),
				},
			],
		},
	},
};

export default loadsmart;
