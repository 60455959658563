import { useDispatch, useSelector } from "react-redux";

// Service
import { getCarrierId } from "app/services/LoginService";

// Tools
import FormSection from "app/main/tools/FormSection";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

import { isEnabled } from "app/services/featureStorageService";

function DispatcherPage(props) {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);

	const viewerCarrierId = getCarrierId();
	const carrierId = props.carrierId ?? props?.params?.carrierId ?? viewerCarrierId;
	const isMyOwnerCarrier = carrierId === viewerCarrierId && user.role === "CARRIER_OWNER";
	const fromCarrierView = props.fromCarrierView;

	const listBtn = [];
	const generateDialog = { formId: "CARRIER_DISPATCHING_GENERATE_CODE_FORM", mode: "VIEW", dataIds: { carrierId } };
	const generateCodeBtn = {
		label: "Generate Access Code",
		action: {
			onClick: () => {
				dispatch(openLoadedFormDialog(generateDialog));
			},
		},
	};
	if (user.mainAccount && isMyOwnerCarrier) listBtn.push(generateCodeBtn);

	const disconnectDialog = {
		formId: "CARRIER_DISPATCHING_DISCONNECT_FROM_CARRIER_FORM",
		mode: "DELETE",
		dataIds: { carrierId },
	};
	const disconnectBtn = {
		label: "Disconnect Dispatcher",
		action: {
			onClick: () => {
				dispatch(openLoadedFormDialog(disconnectDialog));
			},
		},
	};
	if (user.carrierDispatcherAccess === "DISPATCHING" && isMyOwnerCarrier) listBtn.push(disconnectBtn);

	return (
		<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10 mt-32">
			<div className="flex flex-1">
				<FormSection
					title={"External Access"}
					formId={"CARRIER_DISPATCHING_ACCESS_FORM"}
					dataIds={{ carrierId }}
					actions={listBtn}
				/>
			</div>

			<div className="flex flex-1">
				{isEnabled("TRIP_BILLING") && fromCarrierView && (
					<FormSection
						title={"Plan"}
						formId={"CARRIER_DISPATCHING_PLAN_FORM"}
						dataIds={{ carrierId }}
						actions={[
							{
								action: "edit",
								mode: "EDIT",
								label: "Assign Plan",
								auth: ["administrator", "carrier", "carrier_manager"],
							},
						]}
					/>
				)}
			</div>
		</div>
	);
}

export default DispatcherPage;
