import _ from "@lodash";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SmarthopList, SmarthopErrorView, searchResultsDispatcher } from "@smarthop/list";
import { getUserId, isBookingEnabled } from "app/services/LoginService";
import { getSearchStatus, getCarrierStatus, getTruckSearchData } from "app/services/searchServices";
import { createGenericErrorsFrom } from "../searchUtils";
import SearchPanel from "../SearchPanel";
import LoadSearchEmptyContent from "app/main/searchV3/components/LoadSearchEmptyContent";
import LoadSearchProgress from "./LoadSearchProgress";
import LoadSearchEmpty from "./LoadSearchEmpty";
import LoadSearchSummary from "./LoadSearchSummary";

import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { useRef } from "react";
import { showWarning } from "app/main/utils/warningUtils";
import { Button, Card, CardContent, Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
	return {
		root: {
			background: "linear-gradient( rgb(0 0 0 / 10%), rgb(0 0 0 / 50%) ) no-repeat center center fixed",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
		},
		content: {
			background: theme.palette.background.default,
		},
	};
});

function LoadSearchResultContent(props) {
	// Native fields
	const initModel = props.initModel;
	const tabsVisible = props.tabsVisible;
	const contentEmpty = props.contentEmpty;
	const contentHidden = props.contentHidden;
	const contentListDisabled = props.contentListDisabled;
	const nativeMobile = props.nativeMobile;
	const onEvent = props.onEvent;
	const onSearchSubmitted = props.onSearchSubmitted;
	const onSearchStatusChanged = props.onSearchStatusChanged;
	const onPanelValuesChanged = props.onPanelValuesChanged;

	const snackbar = useSnackbar();
	const classes = useStyles();
	const userId = getUserId();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useState(null);
	const [carrierStatus, setCarrierStatus] = useState(null);

	const cachedTruckSearchId = useRef(null);
	const [truckSearchData, setTruckSearchData] = useState(null);

	const [searchStatus, setSearchStatus] = useState(null);
	const [preloaded, setPreloaded] = useState(null);
	const [checkAttempt, setCheckAttempt] = useState(0);
	const [errorAttempt, setErrorAttempt] = useState(0);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1800 && window.innerWidth > 960);
	const [mobileScreen, setMobileScreen] = useState(window.innerWidth < 960);
	const [errors, setErrors] = useState(null);
	const [ignoreLimit, setIgnoreLimit] = useState(false);
	const [carrier, setCarrier] = useState(null);
	const [loadCrendentials, setLoadCrendentials] = useState(null);

	const disableDigitalBooking = !isBookingEnabled();
	const user = useSelector(({ auth }) => auth.user);
	const roles = user.rolesLabels;
	const isInternal =
		roles.includes("administrator") ||
		roles.includes("ops support") ||
		roles.includes("dispatcher") ||
		roles.includes("carrier") ||
		roles.includes("carrier_generic") ||
		roles.includes("carrier_manager") ||
		roles.includes("carrier_dispatcher");

	const maxSearchError = useMemo(() => errors?.filter((e) => e.type === "max_searches"), [errors]);
	const maxSearchMcError = useMemo(() => errors?.filter((e) => e.type === "max_searches_mc"), [errors]);

	const handleSearchSubmitted = (searchId, filters, finished, preloaded) => {
		// Do not initiate search request if params have not been changed
		if (searchParams?.searchId === searchId && _.isEqual(searchParams?.filters, filters)) {
			console.log("[LoadSearchResult] No actions required");
			showSnackbar(snackbar, "We are still looking for more loads...", "info");
			return;
		}
		onSearchSubmitted?.(searchId, filters);

		setErrors(null);
		setLoadCrendentials(false);
		setSearchParams({
			searchId: searchId,
			filters: filters,
		});

		setPreloaded(!!preloaded);
		if (searchParams?.searchId !== searchId) {
			setSearchStatus({ meta: { origin: filters?.location_origin } });
			setCheckAttempt(1);
		}
	};

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 1800 && window.innerWidth > 960 && !smallScreen) {
				setSmallScreen(true);
			} else if ((window.innerWidth > 1800 || window.innerWidth < 960) && smallScreen) {
				setSmallScreen(false);
			}
			if (window.innerWidth < 960 && !mobileScreen) {
				setMobileScreen(true);
			} else if (window.innerWidth > 960 && mobileScreen) {
				setMobileScreen(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	// Checking status of submitted job request
	useEffect(() => {
		if (!checkAttempt) {
			return;
		}

		let stopped;
		let timer;
		let searchId = searchParams?.searchId;
		getSearchStatus(userId, searchId).then(
			(data) => {
				if (stopped) return;
				if (searchId === searchParams?.searchId) {
					if (data?.state === "FAILED" || data?.state === "FAILED_STUCK" || data?.state === "UNKNOWN") {
						console.error(`[LoadSearchResult] GET: queued job failed, state=${data?.state}`);
						setErrors(
							data?.state === "FAILED_STUCK"
								? [{ type: "generic", message: "Something went wrong, job stuck in queue, try again..." }]
								: createGenericErrorsFrom(data?.pipelines?.[0]?.meta, `Search status "${data?.state}" error`)
						);
						setSearchStatus(null);
						setCheckAttempt(0);
						setErrorAttempt(0);
					} else if (
						data?.state === "FINISHED" ||
						data?.state === "FINISHED_MANDATORY" ||
						data?.state === "FINISHED_WITH_FAILURES" ||
						data?.state === "EXPIRED"
					) {
						console.log(
							"[LoadSearchResult] GET: queued job finished",
							data?.state === "FINISHED"
								? " successfully "
								: data?.state === "FINISHED_MANDATORY"
								? " mostly finished "
								: " with failures ",
							searchStatus.state,
							data?.state
						);

						// If previous result contained partial data we need to reload list with results
						if (searchStatus?.state === "FINISHED_MANDATORY" && data?.state !== "FINISHED_MANDATORY") {
							(async () => {
								// Adding small timeout to avoid blocking UI
								await new Promise((r) => setTimeout(r, 1500));
								dispatch(incrementDataRevision({ event: "searchResultsChange" + searchParams?.searchId }));
							})();
						}

						setErrors(null);
						setErrorAttempt(0);
						setSearchStatus(data);

						if (data?.state !== "FINISHED_MANDATORY") {
							if (preloaded) {
								dispatch(incrementDataRevision({ event: "searchResultsChange" + searchParams?.searchId }));
							} else {
								setCheckAttempt(0);
							}
						} else {
							(async () => {
								try {
									// We ping server to check if search fully finished
									for (let i = 0; i < 5; i++) {
										await new Promise((r) => setTimeout(r, 3000));
										if (stopped) return;
										let statusData = await getSearchStatus(userId, searchId);
										if (stopped) return;

										// If status changed and data loaded we should reload data
										if (statusData?.state !== "FINISHED_MANDATORY") break;
										console.log("[LoadSearchResult] GET: queued job prcessing optional brokers...", statusData);
									}
								} catch {
									if (stopped) return;
									// Do nothing
								}
								setCheckAttempt(checkAttempt + 1);
							})();
						}
					} else {
						if (checkAttempt < 40) {
							console.log(`[LoadSearchResult] GET: queued job processing...`);
							if (data?.state === "FINISHED_PRELOADED") {
								setPreloaded(true);
							}

							setSearchStatus(data);
							if (preloaded && checkAttempt > 1 && checkAttempt < 5) {
								dispatch(incrementDataRevision({ event: "searchResultsChange" }));
							}
							let delay = checkAttempt === 1 ? 1000 : checkAttempt < 4 ? 2000 : checkAttempt < 10 ? 3000 : 4000;
							timer = setTimeout(() => setCheckAttempt(checkAttempt + 1), delay);
						} else {
							console.log(`[LoadSearchResult] GET: queued job timed out`);
							setErrors([{ type: "generic", message: "Search request timed out, please try again..." }]);
							setSearchStatus(null);
							setErrorAttempt(0);
							setCheckAttempt(0);
						}
					}
				}
			},
			(err) => {
				if (stopped) return;
				if (searchId === searchParams?.searchId) {
					console.error(`[LoadSearchResult] GET: queued job failed, error attempt ${errorAttempt}`, err);
					if (errorAttempt < 2) {
						timer = setTimeout(() => {
							setErrorAttempt(errorAttempt + 1);
							setCheckAttempt(checkAttempt + 1);
						}, 4000);
					} else {
						setErrors(createGenericErrorsFrom(err, "Search request error"));
						setErrorAttempt(0);
						setCheckAttempt(0);
					}
				}
			}
		);

		return () => {
			clearTimeout(timer);
			stopped = true;
		};
		// eslint-disable-next-line
	}, [checkAttempt]);

	// Checking carrier permission
	useEffect(() => {
		if (!userId || !searchParams?.filters) {
			return;
		}

		async function fetchCarrierStatus() {
			let { carrier, truck } = searchParams.filters;
			await getCarrierStatus(userId, carrier, { truckId: truck }).then(
				(data) => {
					setCarrierStatus(data);
				},
				(err) => {
					console.log("[LoadSearchResult]: Error get carrier status");
				}
			);
		}

		async function fetchTruckSearchData() {
			let { carrier, truck } = searchParams.filters;

			if (cachedTruckSearchId.current === truck) return;
			cachedTruckSearchId.current = truck;

			let equipment = truck.includes("ANY_")
				? truck.replace("ANY_", "")
				: props?.initModel?.truck__view?.metadata?.truck?.equipment ?? "VAN";

			await getTruckSearchData(carrier, truck, equipment, {
				location: searchParams?.filters?.location_origin,
				dh: searchParams?.filters?.dh_origin,
			}).then(
				(data) => {
					setTruckSearchData(data);
				},
				(err) => {
					console.log("[LoadSearchResult]: Error getting profit  status");
				}
			);
		}

		fetchTruckSearchData();
		fetchCarrierStatus();
	}, [userId, searchParams?.filters, nativeMobile, props?.initModel]);

	const dataIds = useMemo(
		() => ({
			userId: userId,
			searchId: searchParams?.searchId,
			carrierId: searchParams?.filters?.carrier,
			carrier__view: searchParams?.filters?.carrier__view,
			truckId: searchParams?.filters?.truck,
			truck__view: searchParams?.filters?.truck__view,
			driverId: searchParams?.filters?.driver,
			driver__view: searchParams?.filters?.driver__view,
			roles: carrierStatus?.roles,
			carrierOnboarded: carrierStatus?.onboarded,
			carrierActive: carrierStatus?.active,
			truckActive: carrierStatus?.truckActive,
			truckVerified: carrierStatus?.truckVerified,
			nativeMobile: nativeMobile,
		}),
		[userId, searchParams?.searchId, searchParams?.filters, carrierStatus, nativeMobile]
	);

	const listConfig = useMemo(() => {
		return searchResultsDispatcher(
			searchParams?.searchId,
			!!searchParams?.filters?.system_show_system_info, // showSystemData
			tabsVisible,
			smallScreen,
			mobileScreen,
			disableDigitalBooking,
			!!searchParams?.filters?.location_destination, // dhdVisible
			searchParams?.filters?.carrier,
			userId,
			dispatch,
			user?.hasSmartPayProgram
		);

		// eslint-disable-next-line
	}, [searchParams, tabsVisible, smallScreen, mobileScreen, disableDigitalBooking]);

	useEffect(() => {
		if (!carrier) return;
		setErrors(null);
		setLoadCrendentials(true);
	}, [carrier]);

	return (
		<div
			className={clsx(
				classes.root,
				"flex flex-col w-full flex-auto items-center justify-center",
				contentHidden ? "hidden" : ""
			)}
		>
			{props?.reachedTabLimit && !ignoreLimit && (
				<div className="flex flex-col h-full w-full items-center justify-center" style={{ background: "#f6f7f9" }}>
					<Card
						className={"max-w-450 flex flex-col w-full items-center justify-center px-8 sm:px-16 text-center mb-32"}
					>
						<CardContent className="w-full flex flex-col  items-center justify-center">
							<div className="flex flex-row space-x-8 content-center">
								<Icon className="text-orange">warning</Icon>
								<p className="">
									We recommend a maximum of {navigator.deviceMemory >= 8 ? 6 : 3} tabs for optimal search performance
								</p>
							</div>
							<Button variant="contained" onClick={() => setIgnoreLimit(true)}>
								Ignore & Search
							</Button>
						</CardContent>
					</Card>
				</div>
			)}
			{(!props?.reachedTabLimit || ignoreLimit) && (
				<SearchPanel
					type="result"
					model={initModel}
					nativeMobile={nativeMobile}
					contentHidden={contentHidden}
					onEvent={onEvent}
					onSearchSubmitted={handleSearchSubmitted}
					onSearchError={(errors) => {
						setErrors(errors);
					}}
					onSearchRequested={() => {
						setErrors(null);
					}}
					onSearchStatusChanged={(state, counters) => {
						onSearchStatusChanged?.(state, counters);
					}}
					onPanelValueChanged={(key, value) => {
						onPanelValuesChanged?.(key, value);
					}}
					updateCarrier={(carrierId) => setCarrier(carrierId)}
				>
					{!contentListDisabled && (
						<div className={clsx(classes.content, "flex flex-col w-full h-full p-12 md:p-0")}>
							{maxSearchError?.length > 0 ? (
								showWarning("MAX_SEARCHES", "", {}, ["TIER_BASIC"])
							) : maxSearchMcError?.length > 0 ? (
								showWarning("MAX_SEARCHES_MC", "", {})
							) : errors?.length > 0 ? (
								<SmarthopErrorView message={errors[0].message} />
							) : (!searchParams?.searchId && contentEmpty) || loadCrendentials ? (
								<LoadSearchEmptyContent />
							) : !preloaded &&
							  searchStatus?.state !== "FINISHED" &&
							  searchStatus?.state !== "FINISHED_MANDATORY" &&
							  searchStatus?.state !== "FINISHED_WITH_FAILURES" &&
							  searchStatus?.state !== "EXPIRED" ? (
								<LoadSearchProgress
									status={searchStatus}
									origin={searchParams?.filters?.location_origin}
									destination={searchParams?.filters?.location_destination}
								/>
							) : searchParams?.searchId ? (
								<div className="flex flex-row w-full">
									<SmarthopList
										key={searchParams?.searchId}
										mode={mobileScreen ? "GRID" : "TABLE"}
										config={listConfig}
										dataIds={dataIds}
										renderEmptyListView={(data) => {
											return (
												<LoadSearchEmpty
													stats={data?.metadata?.stats}
													searchId={searchParams?.searchId}
													origin={searchParams?.filters?.location_origin}
													destination={searchParams?.filters?.location_destination}
												/>
											);
										}}
										filters={searchParams?.filters}
									/>
									{!contentHidden && (
										<LoadSearchSummary
											dataIds={dataIds}
											isInternal={isInternal}
											tabsVisible={tabsVisible}
											smallScreen={smallScreen}
											truckData={truckSearchData}
											filters={searchParams?.filters}
										/>
									)}
								</div>
							) : null}
						</div>
					)}
				</SearchPanel>
			)}
		</div>
	);
}

export default LoadSearchResultContent;
