import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function AccountCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const view = props.view;

	if (!view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "ACCOUNT_INFO_VIEW",
		dataIds: { accountId: view?.value },
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			key={view.value}
			first={<CellFirstRow mobile={mobile} view={view} variant={variant} dialog={dialog} />}
			second={
				<div className="flex flex-wrap">
					{config?.showTestCarrier && !view?.metadata?.carriersRealCount && (
						<CellSecondRow
							mobile={mobile}
							icon={"construction"}
							label="Test"
							color="text-blue-600"
							variant={"extra-skinny"}
						/>
					)}
					{config?.showActiveAccountsCount && (
						<CellSecondRow
							mobile={mobile}
							label={`Active ${view?.metadata?.carriersActiveCount}`}
							variant={variant}
							icon={view?.metadata?.carriersActiveCount > 0 ? "done" : "close"}
							color={view?.metadata?.carriersActiveCount > 0 ? "text-green-700" : "text-red-700"}
						/>
					)}
					{config?.showTotalAccountsCount && (
						<CellSecondRow
							mobile={mobile}
							label={`Total ${view?.metadata?.carriersCount}`}
							variant={variant}
							color={"text-grey-700"}
						/>
					)}
				</div>
			}
		/>
	);
}

export default AccountCellView;
