import { useEffect, useState, memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import _ from "lodash";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { fetchMap, selectByType } from "app/store/marketConditions/mapSlice";
import { isEnabled } from "app/services/featureStorageService";

import { capitalizeFirstLetter, formatPercentage } from "app/main/utils/tableUtils";
import { DisplaySection, DisplayField } from "app/main/utils/uiUtils";
import { createMPIView } from "app/main/utils/brokerUtils";

import getSummary from "../strategy/utils/strategyUtils";
import ValidateMoveButton from "../search/load/ValidateMoveButton";

import { StrategyStateContext } from "../strategy/providers/StrategyContextProvider";

const ordinals = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "nineth", "tenth"];

const verifyColumnEnabled = isEnabled("STRATEGY_VALIDATE_MOVE_BEFORE_SAVING");

const useStyles = makeStyles((theme) => {
	return {
		field: {
			borderRadius: "4px",
			display: "block",
		},
		smarthopOrange: {
			backgroundColor: "white",
		},
	};
});

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(even)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const createArrayOrPush = (arr, i, val) => {
	if (Array.isArray(arr[i])) {
		arr[i].push(val);
	} else {
		arr[i] = val;
	}
};

// TODO: Enabled when defined what stats to use from Axle response
// const StatSection = ({ summary }) => {
// 	const displayFields = Object.entries(summary.statistics).map(([stat, { mean: avg }]) => {
// 		const isMoneyValue = stat.includes("profit") || stat.includes("payment");
// 		return (
// 			<DisplayField
// 				key={stat}
// 				label={capitalizeFirstLetter(stat.replace(/_/g, " "))}
// 				value={isMoneyValue ? formatCurrency(avg) : Number(avg).toFixed(2)}
// 			/>
// 		);
// 	});

// 	const sections = displayFields.reduce((acc, curr, i) => {
// 		if (!(i % 3)) {
// 			acc.push([curr]);
// 		} else {
// 			acc[acc.length - 1].push(curr);
// 		}
// 		return acc;
// 	}, []);

// 	return (
// 		<DisplaySection title="Statistics">
// 			{sections.map((s) => (
// 				<div className="flex flex-row ">{s.map((f) => f)}</div>
// 			))}
// 		</DisplaySection>
// 	);
// };

const TruckInfo = ({ truckInfo }) => {
	return (
		<>
			<DisplaySection title="Driver Details">
				<div className="flex flex-row ">
					<DisplayField label="Carrier" value={truckInfo?.carrier__view?.label ?? "No information"} />
					<DisplayField
						label="Truck"
						value={`${truckInfo?.truck__view?.metadata?.equipment} - ${truckInfo?.truck__view?.metadata?.truckid}`}
					/>
					<DisplayField label="Driver Name" value={truckInfo?.driver__view?.label ?? "No information"} />
				</div>
			</DisplaySection>
			<DisplaySection title="Plan Details">
				<div className="flex flex-row ">
					<DisplayField
						label="Available Date"
						value={truckInfo?.plan__view?.metadata?.current_available_date__pretty ?? "No information"}
					/>
					<DisplayField
						label="Available Location"
						value={truckInfo?.plan__view?.metadata?.current_location ?? "No information"}
					/>
					<DisplayField
						label="Desired destination"
						value={truckInfo?.plan__view?.metadata?.current_destination ?? "No information"}
					/>
				</div>
			</DisplaySection>
		</>
	);
};

const getPathIds = (summary) => {
	const paths = {};
	Object.values(summary.summaryArr[0]).forEach((origin) => {
		Object.values(origin.dests).forEach((dest) => {
			paths[dest.path_id] = { ids: dest.pathIds, selected: true };
		});
	});
	return paths;
};

const CustomTableCell = (props) => {
	return <TableCell style={{ verticalAlign: "top" }} {...props} />;
};

const TableCheckBox = (props) => {
	return <Checkbox color="primary" size="small" {...props} style={{ padding: 0 }} />;
};

const MemoCheckBox = memo(TableCheckBox);

const validateButtonIndex = 4;

const MovesTable = ({
	isFirstLevel,
	saving,
	tablePaths,
	selectedPaths,
	selectAll,
	setSelectAll,
	setTablePaths,
	summary,
	truckInfo,
	marketConditions,
	strategyReqData,
	classes,
}) => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{}}>
				<TableHead>
					<TableRow>
						<CustomTableCell>
							<Typography>Origin</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>Destination</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>RPM</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>MPI</Typography>
						</CustomTableCell>
						<CustomTableCell>
							<Typography>Chance</Typography>
						</CustomTableCell>
						<CustomTableCell className={verifyColumnEnabled && saving && isFirstLevel ? "" : "hidden"}>
							<Typography>Validate</Typography>
						</CustomTableCell>
						{saving && isFirstLevel && tablePaths && (
							<CustomTableCell style={{ textAlign: "center" }}>
								<Typography>Scan</Typography>
								<TableCheckBox
									disabled={selectedPaths && !_.isEmpty(selectedPaths)}
									checked={selectAll}
									onChange={() => {
										setSelectAll(!selectAll);
										const updatedTablePaths = { ...tablePaths };
										Object.keys(updatedTablePaths).forEach((key) => {
											updatedTablePaths[key].selected = !selectAll;
										});
										setTablePaths(updatedTablePaths);
									}}
								/>
							</CustomTableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.values(summary).map((origin, i) => (
						<StyledTableRow key={`${origin.city}-${i}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell component="th" scope="row" style={{ verticalAlign: "top" }}>
								<Typography>{origin.city}</Typography>
								{origin.prevIsEmpty && (
									<Typography style={{ color: "red", fontSize: "0.7em" }}>
										{`${origin.empty_ocity} - ${origin.city}`}
									</Typography>
								)}
							</TableCell>
							{Object.values(origin.dests)
								.reduce((acc, dest, deepIndex) => {
									const equipment = truckInfo?.truck__view?.metadata?.equipment?.toLowerCase();
									const ppm_best_norm_smooth =
										marketConditions?.map?.maps?.[equipment + "-ocluster-toplanes"]?.heatMap?.[dest.cluster]
											?.ppm_best_norm_smooth;

									createArrayOrPush(acc, 0, <Typography key={`row-${i}-dest-${deepIndex}`}>{dest.city}</Typography>);

									createArrayOrPush(
										acc,
										1,
										<Typography key={`row-${i}-rpm-${deepIndex}`}>{Number(dest.rpm).toFixed(2)}</Typography>
									);

									createArrayOrPush(
										acc,
										2,
										<div key={`row-${i}-mpi-${deepIndex}`}>
											{createMPIView({ rank_mci: ppm_best_norm_smooth }, true)}
										</div>
									);

									createArrayOrPush(
										acc,
										3,
										<Typography key={`row-${i}-probability-${deepIndex}`}>
											{dest.probability ? formatPercentage(1, dest.probability) : "-"}
										</Typography>
									);

									createArrayOrPush(
										acc,
										validateButtonIndex,
										<ValidateMoveButton
											key={`row-${i}-validate-${deepIndex}`}
											legData={dest.rawData}
											strategyReqData={strategyReqData}
											moveId={dest.moveId}
											variant="text"
										/>
									);

									if (saving && isFirstLevel && tablePaths) {
										createArrayOrPush(
											acc,
											5,
											<MemoCheckBox
												key={`row-${i}-check-${deepIndex}`}
												color="primary"
												className={classes.field}
												checked={tablePaths[dest.path_id]?.selected}
												onChange={() => {
													const tablePath = tablePaths[dest.path_id];
													const updatedTablePaths = {
														...tablePaths,
														[dest.path_id]: { ...tablePath, selected: !tablePath.selected },
													};
													setTablePaths(updatedTablePaths);
												}}
												disabled={selectedPaths && !_.isEmpty(selectedPaths)}
											/>
										);
									}

									return acc;
								}, [])
								.map((children, j) => (
									<TableCell
										key={`row-${i}-cell-${j}`}
										className={
											j === validateButtonIndex ? (verifyColumnEnabled && saving && isFirstLevel ? "" : "hidden") : ""
										}
									>
										{children}
									</TableCell>
								))}
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const MovesTableView = (props) => {
	const classes = useStyles();
	const summary = props.summary;
	const truckInfo = props.truckInfo;
	const tableIndex = props.tableIndex;

	const dispatch = useDispatch();
	const marketConditions = useSelector(({ marketConditions }) => marketConditions);
	const mapData = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, truckInfo?.equipment || "VAN", "ocluster", "toplanes")
	);

	useEffect(() => {
		if (truckInfo) {
			const equipment = truckInfo?.truck__view?.metadata?.equipment?.toLowerCase();
			if (!mapData?.status || mapData?.status === "rejected") {
				dispatch(fetchMap({ equipment, field: "ocluster", toplanes: "toplanes" }));
			}
		}
		// eslint-disable-next-line
	}, [truckInfo]);

	return !tableIndex ? (
		<MovesTable
			{...props}
			isFirstLevel={true}
			summary={summary}
			truckInfo={truckInfo}
			marketConditions={marketConditions}
			classes={classes}
		/>
	) : (
		Object.entries(groupSummaryByOrigin(summary)).map(([prev_ocity, currSummary], i) => (
			<DisplaySection key={`level-${tableIndex}-table-${i + 1}`}>
				<Box marginBottom={2}>
					<Chip
						avatar={
							<Avatar alt="via" className={classes.smarthopOrange}>
								via
							</Avatar>
						}
						label={`${prev_ocity}`}
					/>
				</Box>
				<MovesTable
					{...props}
					isFirstLevel={false}
					summary={currSummary}
					truckInfo={truckInfo}
					marketConditions={marketConditions}
					classes={classes}
				/>
			</DisplaySection>
		))
	);
};

const TableCarousel = (props) => {
	const [currIndex, setCurrIndex] = useState(0);
	const items = props.items;

	return (
		<Box>
			<div className="mt-4 flex justify-between items-center px-4">
				<IconButton
					key="previous"
					disabled={!items[currIndex - 1]}
					onClick={() => {
						setCurrIndex(currIndex - 1);
					}}
				>
					<Icon>chevron_left</Icon>
				</IconButton>
				<Typography> Loaded Moves </Typography>
				<IconButton
					key="next"
					disabled={!items[currIndex + 1]}
					onClick={() => {
						setCurrIndex(currIndex + 1);
					}}
				>
					<Icon>chevron_right</Icon>
				</IconButton>
			</div>
			<div className="mt-2 flex flex-col justify-center">
				<motion.div key={currIndex} initial={{ x: 100 }} animate={{ x: 0 }} exit={{ x: 100 }}>
					{items[currIndex]}
				</motion.div>
			</div>
		</Box>
	);
};

const groupSummaryByOrigin = (summary) => {
	const summaryGroups = Object.entries(summary).reduce((acc, [currPathId, currMove]) => {
		const { prev_ocity } = currMove;
		if (acc[prev_ocity]) {
			acc[prev_ocity] = { ...acc[prev_ocity], [currPathId]: currMove };
		} else {
			acc[prev_ocity] = { [currPathId]: currMove };
		}
		return acc;
	}, {});
	return summaryGroups;
};

const StrategySummary = ({ strategy, strategyReqData, truckInfo, saving, setTablePaths, tablePaths, setTitle }) => {
	useEffect(() => {
		setTitle?.("Strategy Summary");
		// eslint-disable-next-line
	}, []);
	const { selectedPaths } = useContext(StrategyStateContext);
	const summary = getSummary(strategy, ordinals.length, selectedPaths);
	const [selectAll, setSelectAll] = useState(true);

	useEffect(() => {
		if (saving) setTablePaths(getPathIds(summary));

		return () => {
			setTablePaths(null);
		};
	}, [saving]); // eslint-disable-line

	useEffect(() => {
		if (tablePaths) setSelectAll(Object.values(tablePaths).every((e) => e.selected));
	}, [tablePaths]); // eslint-disable-line

	return (
		<>
			{strategy && (
				<Box p={2}>
					{truckInfo && <TruckInfo truckInfo={truckInfo} />}
					<TableCarousel
						items={summary.summaryArr.map((summary, i) => (
							<DisplaySection
								key={`${ordinals[i]}-ordinal-section`}
								title={`${capitalizeFirstLetter(ordinals[i])} Loaded Moves`}
							>
								<MovesTableView
									key={ordinals[i]}
									tableIndex={i}
									summary={summary}
									tablePaths={tablePaths}
									setTablePaths={setTablePaths}
									selectAll={selectAll}
									setSelectAll={setSelectAll}
									truckInfo={truckInfo}
									strategyReqData={strategyReqData}
									selectedPaths={selectedPaths}
									saving={saving}
								/>
							</DisplaySection>
						))}
					/>
					{/* Use again when defined what stats to use from Axle response
					<StatSection summary={summary} />
					*/}
				</Box>
			)}
		</>
	);
};

export default StrategySummary;
