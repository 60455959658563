import React, { useEffect } from "react";
import { createPortal } from "react-dom";

const DrawerPortal = React.forwardRef(({ children }, ref) => {
	const elRef = ref;

	if (!elRef.current) {
		const portalElem = document.createElement("div");
		portalElem.id = "portal";
		elRef.current = portalElem;
	}

	useEffect(() => {
		const portalRoot = document.querySelector("footer");
		portalRoot.appendChild(elRef.current);

		return () => portalRoot.removeChild(elRef.current);
		// eslint-disable-next-line
	}, []);

	return createPortal(<div>{children}</div>, elRef.current);
});

export default DrawerPortal;
