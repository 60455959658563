import PublicTripEditView from "./PublicTripEditView";
const isLogged = localStorage.getItem("tokenSmarthop") ? true : false;

const config = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: isLogged,
				},
				toolbar: {
					display: isLogged,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: isLogged,
				},
				rightSidePanel: {
					display: isLogged,
				},
			},
		},
	},
	routes: [
		{
			path: "/sh-trip-edit",
			component: PublicTripEditView,
		},
	],
};

export default config;
