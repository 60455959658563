import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import factoringRequestDetail from "@smarthop/form/configs/factoringRequestDetail";
import { SmarthopList, adminFactoringRequestsPaymentsList } from "@smarthop/list";
import { useEffect } from "react";

const AdminFactoringRequestDetailView = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Factoring Request Payments");
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{
				<SmarthopFormView
					mode="VIEW"
					data={{ ...dataIds.item }}
					content={factoringRequestDetail.content.view}
					dataIds={dataIds}
				/>
			}
			<SmarthopList key="payments" mode="table" config={adminFactoringRequestsPaymentsList} dataIds={dataIds} />
		</>
	);
};

export default AdminFactoringRequestDetailView;
