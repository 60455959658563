import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const createTruck = (carrierId, data) => {
	return createRequest()
		.post(`api/profile/carriers/${carrierId}/trucks`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTruck = (carrierId, truckId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/trucks/${truckId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTruck = (carrierId, truckId, data) => {
	return createRequest()
		.put(`api/profile/carriers/${carrierId}/trucks/${truckId}`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createTrailer = (carrierId, data) => {
	return createRequest()
		.post(`api/profile/carriers/${carrierId}/trailers`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTrailer = (carrierId, trailerId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/trailers/${trailerId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTrailer = (carrierId, trailerId, data) => {
	return createRequest()
		.put(`api/profile/carriers/${carrierId}/trailers/${trailerId}`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCurrentTruckPlan = (carrierId, truckId) => {
	return createRequest()
		.get(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTruckInfo = (carrierId, truckId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/trucks/${truckId}/info`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTruckPlan = (carrierId, truckId, planId) => {
	return createRequest()
		.get(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan/${planId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createTruckPlan = (carrierId, truckId, data = {}) => {
	return createRequest()
		.post(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const replaceTruckPlan = (carrierId, truckId, data) => {
	return createRequest()
		.put(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan/replace`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const expireTruckPlan = (carrierId, truckId) => {
	return createRequest()
		.put(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan/expire`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateOrCreatePlan = (carrierId, truckId, data) => {
	return createRequest()
		.put(`api/queue/carriers/${carrierId}/trucks/${truckId}/upsert`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateTruckPlan = (carrierId, truckId, planId, data) => {
	return createRequest()
		.put(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan/${planId}`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const executePlanAction = (carrierId, truckId, planId, actionType) => {
	const url = `api/queue/carriers/${carrierId}/trucks/${truckId}/plan/${planId}/${actionType}`;
	return createRequest()
		.put(url, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export function getTruckPlanSearchHistory(carrierId, truckId, planId, filters = null) {
	let url = `api/queue/carriers/${carrierId}/trucks/${truckId}/plan/${planId}/search/requests`;
	if (filters) {
		url += `?filters=${JSON.stringify(filters)}`;
	}

	return createRequest()
		.get(url, {}, {})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export const getTrucksInformation = (params = {}) => {
	let url = `api/profile/trucks`;
	return createRequest()
		.get(url, { params })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createPostTruck = (carrierId, truckId, data = {}) => {
	return createRequest()
		.post(`api/post-truck/carriers/${carrierId}/trucks/${truckId}`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateCurrentDriverTruck = (carrierId, truckId, data = {}) => {
	return createRequest()
		.put(`api/profile/carriers/${carrierId}/trucks/${truckId}/driver`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const postTruckPrefill = (carrierId, truckId) => {
	return createRequest()
		.get(`api/post-truck/carriers/${carrierId}/trucks/${truckId}/prefill`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTrucksAnalytics = (carrierId, truckId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/trucks/${truckId}/analytics`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const snoozeLoadScoutNotifications = (carrierId, truckId, planId, data) => {
	return createRequest()
		.put(`api/queue/carriers/${carrierId}/trucks/${truckId}/plan/${planId}/loadScout/snooze`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
