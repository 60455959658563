import React from "react";
import { Divider } from "@material-ui/core";
import { formatLabel, formatTitle } from "./utils";

function ProfileContainer(props) {
	const { data } = props;

	return (
		<div>
			<div className="pb-16"> {formatTitle("Profile", false)} </div>
			<Divider className="w-full bg-grey-300 mt-10" />
			{formatLabel("Name", (data?.first_name + " " + data?.last_name)?.substring(0, 25))}
			<Divider className="w-full bg-grey-300" />
			{formatLabel("Email", data?.email)}
			<Divider className="w-full bg-grey-300" />
			{formatLabel("Phone", data?.phone ?? "-")}
		</div>
	);
}

export default ProfileContainer;
