// Dependencies
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// Tools
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Configs
import createForm from "./SmartHopWalletTransferFundsConfig.js";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import {
	UNAUTHORIZED_WALLET_ACCESS,
	OTP_ERROR_MESSAGE_REQUIRED,
	cleanSmarthpWalletTokenStorage,
} from "app/main/utils/financeUtils";

// Components
import PlaidLink from "../../billing/payments/plaid/PlaidLink";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";

// Services
import { getPlaidLinkToken } from "app/services/billingServices";
import { tranferFunds } from "app/services/smarthopWalletServices";
import { UnitFinanceService } from "app/services/unitFinanceServices";

const SmartHopWalletTransferFundsView = (props) => {
	useEffect(() => {
		props?.setTitle?.("Transfer Funds");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const dataIds = props.dataIds;
	const [data, setData] = useState();
	const [loadingError, setLoadingError] = useState(false);
	const [errors, setErrors] = useState(null);
	const [loading, setLoading] = useState(false);
	let [token, setToken] = useState(null);

	// * Smarthop Wallet: OTP (2FA verification)
	const hasSmarthopWalletToken = useSelector(({ wallet }) => !!wallet.smarthopWallet?.token);

	const handledAction = async () => {
		let dataToSend = { ...data };

		// Let service decide based on source and destination accounts types.
		dataToSend.payment_type = "auto";

		// Short description default value, as we are not receiving it through the form
		dataToSend.description = "Move Funds";

		// callbackData is a data dictionary to execute the sensitive operation in the SMARTHOP_WALLET_VERIFICATION_CODE_VIEW view,
		// when the customerToken has been created.
		const params = { type: dataIds.type, carrierId: dataIds.carrierId };
		if (hasSmarthopWalletToken) {
			try {
				setLoading(true);
				await tranferFunds(params, dataToSend);
				dispatch(incrementDataRevision({ event: "smarthopWalletRevision" }));
				dispatch(closeFormDialog());
				setLoading(false);
				showSnackbar(snackbar, "Operation successfully completed.", "success", { duration: 10000 });
			} catch (err) {
				setLoading(false);
				if (err?.errors?.[0].code === UNAUTHORIZED_WALLET_ACCESS) {
					setLoadingError(true);
					setErrors(err?.errors);
					return;
				}
				showSnackbar(snackbar, err?.errors?.[0].message, "error", { duration: 10000 });
				dispatch(closeFormDialog());
			}
		} else {
			setLoadingError(true);
			setErrors(OTP_ERROR_MESSAGE_REQUIRED?.response?.data?.errors);
		}
	};

	const handleAddAch = async () => {
		const token = await getPlaidLinkToken(dataIds.carrierId);
		setToken(token);
	};

	const customFormHandler = async (model) => {
		let newData = { ...data, ...model };
		setData(newData);
	};

	useEffect(() => {
		(async () => {
			setLoadingError(false);
			setErrors([]);

			// If the smarthop wallet token is not saved in storage
			if (!hasSmarthopWalletToken) {
				setLoadingError(true);
				setErrors(OTP_ERROR_MESSAGE_REQUIRED?.response?.data?.errors);
				return;
			}

			setLoading(true);

			// Verify if the smarthopWallet token is still valid
			try {
				await UnitFinanceService.getWalletData({ entityId: dataIds.carrierId });
			} catch (err) {
				if (err?.errors?.[0].code === UNAUTHORIZED_WALLET_ACCESS) {
					cleanSmarthpWalletTokenStorage(dispatch);
				}
			}
			setLoading(false);
		})();
	}, [hasSmarthopWalletToken, dataIds.carrierId, dispatch]);

	let form = createForm({ ...dataIds, handleAddAch: handleAddAch, sourceAccountSelected: data?.source_account });
	const mode = "CREATE";

	return (
		<>
			<SmarthopFormView
				mode={mode}
				data={data}
				content={form}
				loadingError={loadingError}
				errors={errors}
				noInitValidation={true}
				loading={loading}
				dataIds={dataIds}
				otp={true}
				trackChangedFields={["ALL"]}
				onChangeCommitted={customFormHandler}
				onCustom={handledAction}
			>
				{props.children}
			</SmarthopFormView>
			{token && <PlaidLink token={token} carrierId={dataIds.carrierId} />}
		</>
	);
};

export default SmartHopWalletTransferFundsView;
