import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

const BannerAlertView = ({ banner, startView, endView, linkOnClickHandler, noWrap }) => {
	const location = useLocation();
	const history = useHistory();

	return (
		<div
			className={
				banner.bannerClass ??
				"flex w-full items-center justify-center px-32 md:px-20 h-32 " +
					(banner.severity === "error"
						? " bg-red-600 "
						: banner.severity === "warning"
						? " bg-yellow-900 "
						: " bg-blue-600 ")
			}
		>
			{startView && <div className="flex">{startView}</div>}
			<div className="flex items-center justify-center intems-center px-20">
				{banner?.componentMessage ?? (
					<>
						<Icon className={banner.iconClass ?? "mr-10 text-white"}>{banner.severity ?? "info"}</Icon>
						<Typography
							noWrap={noWrap !== undefined ? noWrap : true}
							className={banner.messageClass ?? "font-light tracking-wide whitespace-nowrap text-12 text-white"}
						>
							{banner.message}
						</Typography>
					</>
				)}
				{(banner.navigateTo || banner.onAction || banner.details) && (
					<Link
						color="primary"
						disabled={location?.pathname === banner.navigateTo}
						onClick={() => {
							if (banner.details) {
								linkOnClickHandler({
									title: banner.message,
									message: banner.details,
								});
							} else if (banner.navigateTo) {
								history.push(banner.navigateTo);
							} else if (banner.onAction) {
								banner.onAction();
							}
						}}
					>
						<Typography
							className={
								"font-semibold text-white text-12 whitespace-nowrap ml-10 underline" +
								(location?.pathname === banner.navigateTo ? " opacity-50 " : " hover:text-orange-300  ")
							}
						>
							{banner.details ? "Details" : banner.action ?? "Click Here"}
						</Typography>
					</Link>
				)}
				{banner.buttonAction && (
					<div className="ml-6 p-8">
						<Button
							variant="contained"
							color="secondary"
							className="min-w-120 md:min-w-120"
							onClick={banner.buttonAction.onClick}
						>
							{banner.buttonAction.label ?? "ok!"}
						</Button>
					</div>
				)}
			</div>
			{endView && <div className="flex">{endView}</div>}
		</div>
	);
};

export default BannerAlertView;
