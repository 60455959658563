import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function CarrierCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const view = Array.isArray(props.view) ? props.view?.[0] : props.view;

	const buildItem = (view, more) => {
		const active = view?.metadata?.active;
		const parent = view?.metadata?.parent;
		const isTestCarrier = view?.metadata?.isTestCarrier;

		if (!view?.value) {
			return <CellEmptyRow mobile={mobile} variant={variant} />;
		}

		const dialog = {
			viewId: "CARRIER_INFO_VIEW",
			dataIds: { carrierId: view?.value },
		};

		if (config?.readOnly) {
			return view.label;
		}

		return (
			<CellContainerRow
				mobile={mobile}
				variant={variant}
				key={view.value}
				first={
					<CellFirstRow mobile={mobile} view={view} variant={variant} dialog={config?.hideDialog ? null : dialog} />
				}
				second={
					<div className="flex flex-wrap">
						{config?.showTestCarrier && isTestCarrier && (
							<CellSecondRow
								mobile={mobile}
								icon={"construction"}
								label="Test"
								color="text-blue-600"
								variant={"extra-skinny"}
							/>
						)}
						{config?.showId && (
							<CellSecondRow mobile={mobile} label={`# ${view?.metadata?.carrierid ?? "No ID"}`} variant={variant} />
						)}
						{config?.showMcNumber && (
							<CellSecondRow mobile={mobile} label={`MC ${view?.metadata?.mc_number ?? "Empty"}`} variant={variant} />
						)}
						{config?.showCarrierAccountType && parent && (
							<CellSecondRow
								mobile={mobile}
								icon={"settings_ethernet"}
								label={"Sub-Account"}
								color={"text-blue-600"}
								variant={variant}
							/>
						)}
						{config?.showStatus && !active && (
							<CellSecondRow
								mobile={mobile}
								icon={active ? "done" : "close"}
								label={active ? "Active" : "Inactive"}
								color={active ? " text-green-700" : " text-red-600"}
								variant={variant}
							/>
						)}
					</div>
				}
				more={more}
			/>
		);
	};

	let extra = [];
	if (Array.isArray(props.view)) {
		for (let i = 1; i < props.view.length; i++) {
			extra.push(buildItem(props.view[i]));
		}
	}

	return buildItem(view, extra);
}

export default CarrierCellView;
