import { getUserId, isRoleExternal } from "app/services/LoginService";
import { usersLinkedAccountsList, SmarthopList } from "@smarthop/list";
import { useEffect } from "react";

function UserAccountsView(props) {
	const viewerExternal = isRoleExternal();

	useEffect(() => {
		props?.setTitle?.("Select Account");
		props?.setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const viewerUserId = getUserId();
	return (
		<div className="flex w-full pb-2">
			<SmarthopList
				key="accounts"
				urlKey="accounts"
				mode="TABLE"
				isSection={true}
				config={usersLinkedAccountsList({
					enableSwitch: true,
					noHeader: true,
					noBottomBorder: true,
					viewerUserId: viewerUserId,
					isAdmin: !viewerExternal,
				})}
				dataIds={{ userId: viewerUserId }}
			/>
		</div>
	);
}

export default UserAccountsView;
