import { Avatar, Box, Chip } from "@material-ui/core";
import { DisplaySection } from "app/main/utils/uiUtils";
import { fetchMap, selectByType } from "app/store/marketConditions/mapSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import MovesTable from "./MovesTable";

const useStyles = makeStyles((theme) => {
	return {
		field: {
			borderRadius: "4px",
			display: "block",
		},
		smarthopOrange: {
			backgroundColor: "white",
		},
	};
});

const groupSummaryByOrigin = (summary) => {
	const summaryGroups = Object.entries(summary).reduce((acc, [currPathId, currMove]) => {
		const { prev_ocity } = currMove;
		if (acc[prev_ocity]) {
			acc[prev_ocity] = { ...acc[prev_ocity], [currPathId]: currMove };
		} else {
			acc[prev_ocity] = { [currPathId]: currMove };
		}
		return acc;
	}, {});
	return summaryGroups;
};

const MovesTableView = (props) => {
	const classes = useStyles();
	const summary = props.summary;
	const truckInfo = props.truckInfo;
	const tableIndex = props.tableIndex;
	const selectAll = props.selectAll;
	const setSelectAll = props.setSelectAll;
	const setTablePaths = props.setTablePaths;
	const strategyReqData = props.strategyReqData;
	const selectedPaths = props.selectedPaths;
	const tablePaths = props.tablePaths;
	const saving = props.saving;

	const dispatch = useDispatch();
	const marketConditions = useSelector(({ marketConditions }) => marketConditions);
	const mapData = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, truckInfo?.equipment || "VAN", "ocluster", "toplanes")
	);

	useEffect(() => {
		if (truckInfo) {
			const equipment = truckInfo?.truck__view?.metadata?.equipment?.toLowerCase();
			if (!mapData?.status || mapData?.status === "rejected") {
				dispatch(fetchMap({ equipment, field: "ocluster", toplanes: "toplanes" }));
			}
		}
		// eslint-disable-next-line
	}, [truckInfo]);

	return !tableIndex ? (
		<MovesTable
			isFirstLevel={true}
			summary={summary}
			truckInfo={truckInfo}
			marketConditions={marketConditions}
			classes={classes}
			selectAll={selectAll}
			setSelectAll={setSelectAll}
			setTablePaths={setTablePaths}
			strategyReqData={strategyReqData}
			selectedPaths={selectedPaths}
			tablePaths={tablePaths}
			saving={saving}
		/>
	) : (
		Object.entries(groupSummaryByOrigin(summary)).map(([prev_ocity, currSummary], i) => (
			<DisplaySection key={`level-${tableIndex}-table-${i + 1}`}>
				<Box marginBottom={2}>
					<Chip
						avatar={
							<Avatar alt="via" className={classes.smarthopOrange}>
								via
							</Avatar>
						}
						label={`${prev_ocity}`}
					/>
				</Box>
				<MovesTable
					{...props}
					isFirstLevel={false}
					summary={currSummary}
					truckInfo={truckInfo}
					marketConditions={marketConditions}
					classes={classes}
				/>
			</DisplaySection>
		))
	);
};

export default MovesTableView;
