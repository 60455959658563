// Const
const moment = require("moment");

/**
 * Changes location formatter (city).
 * Used mostly in PlacesAutocomplete components (React).
 *
 * @param {city} string Mostly, google API Locations.
 */
export function placesFormatter(city) {
	let result = "";

	if (city) {
		city = city.toUpperCase();

		if (city.includes("USA")) {
			result = city.toUpperCase().split(",");
			result = result[0] + result[1];
		} else {
			result = city;
		}

		if (result.includes(".")) {
			result = result.replace(/\./g, "");
		}

		if (result.includes("-")) {
			result = result.replace(/-/g, " ");
		}

		if (result.includes(",")) {
			result = result.replace(/,/g, "");
		}
	}

	return result;
}

/**
 * Rounds numerics value downwards.
 *
 * @param {value} int numeric value.
 */
export function dhFormatter(value) {
	if (value === 0 || value === "") {
		return 0;
	} else if (value !== 0) {
		return Math.floor(value);
	}
}

/**
 * Formats values with exactly two decimals.
 * Returns default value.
 *
 * @param {number} value integer.
 */
export function priceFormatter(value) {
	if (value === 0) {
		return "-";
	} else if (value !== 0) {
		return value.toFixed(2);
	}
}

/**
 * Formats values with exactly two decimals
 * Does not returns default value.
 *
 * @param {number} value float.
 */
export function truncateFormatter(value) {
	return value.toFixed(2);
}

/**
 * Formats RPM values.
 *
 * @param {number} value float number.
 */
export function rpmFormatter(value) {
	if (value === 0) {
		return "-";
	} else {
		return "$" + (value ? value.toFixed(2) : 0);
	}
}

/**
 * Calculates time differences.
 *
 * @param {number} value integer.
 */
export function ageFormatter(value) {
	var now = moment.utc();
	var entered = moment.utc(value);
	var diff = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(entered, "DD/MM/YYYY HH:mm:ss"));
	var duration = moment.duration(diff);
	var age = Math.floor(duration.asHours()) + moment.utc(diff).format(":mm");
	return age;
}

/**
 * Checks if given value is number type or not
 *
 * @param {number} value integer.
 */
export function integrateNumber(value) {
	try {
		if (value) {
			return Number(value);
		}
	} catch (error) {
		return value;
	}
}

/**
 * Gets user preferred language (browser).
 */
export function getBrowserLanguage() {
	let language = window.navigator.language.split("-");
	return language[0].toUpperCase();
}

/**
 * Formats createAt date into weeks.
 * Mostly used in ToolkitProvider and react-bootstrap-table components
 *
 * @param {object} cell selected component cell.
 * @param {object} row  selected component row.
 */
export function weekFormatter(cell, row) {
	return moment.utc(row.created_at).format("ddd, MMM, D, Y");
}

/**
 * Calculate component row's taxes.
 * Mostly used in ToolkitProvider and react-bootstrap-table (BootstrapTable) components
 *
 * @param {object} row  component's row
 */
export function taxesCalculator(row) {
	let taxes = row.tax.map((value) => {
		if (value.percentage) return parseFloat(value.percentage);
		else return parseFloat(value);
	});
	let reducer = (accumulator, currentValue) => accumulator + currentValue;
	return taxes.reduce(reducer);
}

/**
 * Convert row's tax value into percentage format.
 * Mostly used in ToolkitProvider and react-bootstrap-table (BootstrapTable) components
 *
 * @param {object} cell component's cell.
 * @param {object} row  component's row.
 */
export function taxesFormatter(cell, row) {
	if (row.tax.length > 0) {
		return taxesCalculator(row) + " %";
	} else {
		return "0 %";
	}
}

/**
 * Convert row's coupon value into percentage format.
 * Mostly used in ToolkitProvider and react-bootstrap-table (BootstrapTable) components
 *
 * @param {object} cell component's cell.
 * @param {object} row  component's row.
 */
export function couponFormatter(cell, row) {
	if (row.coupon) {
		return parseFloat(row.coupon.percent_off) + " %";
	} else {
		return "0 %";
	}
}

/**
 * Convert row's amount value into USD.
 * Mostly used in ToolkitProvider and react-bootstrap-table (BootstrapTable) components
 *
 * @param {object} cell component's cell
 * @param {object} row  component's row
 */
export function totalFormater(cell, row) {
	return "$ " + parseFloat(row.amount).toFixed(2);
}

export function moneyFormat(value) {
	// FIXME - need to simplify and verify safari, this regex breaks safari
	return value.toString(); //value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
}

/**
 * Convert row's amount value into USD.
 * Mostly used in ToolkitProvider and react-bootstrap-table (BootstrapTable) components
 *
 * @param {object} cell component's cell.
 * @param {object} row  component's row.
 */
export function subTotalFormater(cell, row) {
	let amount = (parseFloat(row.amount) / 100).toFixed(2);
	return "$ " + amount;
}

/**
 * Formats phone mask or visual structure.
 *
 * @param {string} phone number.
 */
export function phoneFormatter(phone) {
	phone = String(phone);
	let splitNumber = phone.split("+");
	let phoneNumber;
	if (splitNumber.length > 1) {
		phoneNumber = phone.replace(/[^+\d]+/g, "").replace(/[{()}-]+/g, "");
	} else {
		phoneNumber = "+1" + phone.replace(/[^+\d]+/g, "").replace(/[{()}-]+/g, "");
	}
	return phoneNumber;
}

/**
 * Formats local (no country code) phone mask or visual structure.
 *
 * @param {string} phone number.
 */
export function localPhoneFormatter(phone) {
	let splitNumber = phone.split("+");
	let phoneNumber;
	if (splitNumber.length > 1) {
		phoneNumber = phone
			.replace(/[^+\d]+/g, "")
			.replace(/[{()}-]+/g, "")
			.replace(/[{()}+]+/g, "");
	} else {
		phoneNumber = phone.replace(/[^+\d]+/g, "").replace(/[{()}-]+/g, "");
	}
	return phoneNumber;
}

export function capitalizaFirstLetter(word) {
	return word.charAt(0).toUpperCase() + word.slice(1);
}

export function capitalizaEveryWordFirstLetter(word) {
	word = word?.replace(/_/g, " ").toLowerCase();
	let words = word?.split?.(" ");
	let result = "";
	words?.forEach((item) => {
		if (result.length > 0) result += " ";
		result += item.charAt(0).toUpperCase() + item.slice(1);
	});

	return result;
}

/**
 * Formats cities.
 *
 * @param {string} address city description.
 */
export function cityFormatter(address) {
	if (address) {
		if (address.includes(",")) {
			let city = address.split(",");
			if (city.includes(" USA")) {
				city = city[city.length - 3] ? city[city.length - 3].trim() : city[city.length - 3];
				return city;
			} else {
				return address.trim();
			}
		} else {
			let city = address.split(" ").reverse();
			for (let x in city) {
				if (city[x].length === 2) {
					delete city[x];
					break;
				}
			}
			return city.reverse().join(" ").trim();
		}
	} else {
		return null;
	}
}

/**
 * Formats location formatter (address).
 *
 * @param {address} string Mostly, google API Locations.
 */
export function stateFormatter(address) {
	if (address) {
		let state = address.replace(/,/g, "").split(" ").reverse();
		for (let x of state) {
			if (x.length === 2) {
				return x.trim();
			}
		}
		return address.trim();
	}
}

/**
 * Retorna la fecha en formato MMM, DD, YYYY
 * Return date in format MMM, DD, YYYY
 *
 * @param {string} date Fecha a evaluar / Date to evaluate
 * @return {Date} date
 *
 * Se usa aqui (LoadCardMobile, LoadCard) / used here (LoadCardMobile, LoadCard)
 */
export function MDYCplFormatter(date, row) {
	if (date) {
		return moment(date).utc().format("MMM, DD, YYYY");
	} else {
		return "-";
	}
}

/**
 * Retorna la fecha en formato mes/dias
 * Return date in format month/day
 *
 * @param {string} date Fecha a evaluar / Date to evaluate
 * @return {Date} date
 *
 * Se usa aqui (LoadCardMobile, LoadCard) / used here (LoadCardMobile, LoadCard)
 */
export function monthFormatter(date, row) {
	if (date) {
		return moment(date).utc().format("MM/DD");
	} else {
		return "-";
	}
}

/**
 * Retorna las millas sin decimales
 * Return the miles without decimals
 *
 * @param {String} miles Millas a evaluar / Miles to evaluate
 * @return {Number} miles
 *
 * Se usa aqui (LoadCardMobile, LoadCard) / used here (LoadCardMobile, LoadCard)
 */
export function milesCplFormatter(miles, row) {
	if (miles) {
		return Number(miles).toFixed(0) + " mi";
	} else {
		return 0 + " mi";
	}
}

/**
 * Retorna el precio sin decimales, dependiendo del campo que este disponible (price/est_price)
 * Return the price without decimal places, depending on the field that is available (price / est_price)
 *
 * @param {object} row Objeto con la informacion de la carga / Object with load information
 * @return {Number} price
 *
 * Se usa aqui (LoadCard) / used here (LoadCard)
 */
export function priceCplFormatter(cell, row) {
	if (row.price) {
		return `$${parseFloat(row.price).toFixed(0)}`;
	} else if (row.est_price) {
		return `$${parseFloat(row.est_price).toFixed(0)}`;
	} else {
		return "-";
	}
}

/**
 * Retorna la fecha formateada en horas y minutos
 * Return the date formatted in hours and minutes
 *
 * @param {String} date
 * @return {Date} date formated
 *
 * Se usa aqui (RouterDetails) / used here (RouterDetails)
 */
export function hourFotmatter(date, row) {
	if (date) {
		return moment.utc(date).format("HH:mm A");
	} else {
		return "-";
	}
}

/**
 * Retorna la fecha formateada en mes, dia, año
 * Return the date formatted in month, days, year
 *
 * @param {String} date
 * @return {Date} date formated
 *
 * Se usa aqui (RouterDetails) / used here (RouterDetails)
 */
export function MDYFormatter(date, row) {
	if (date) {
		return moment(date).utc().format("MM/DD/YYYY");
	} else {
		return "-";
	}
}

/**
 * Retorna la fecha formateada en mes, dia, año, horas, minutos
 * Return the date formatted in month, days, year, hours, minutes
 *
 * @param {String} date
 * @return {Date} date formated
 *
 * Se usa aqui (BookedTripPageMobile) / used here (BookedTripPageMobile)
 */
export function MDYHMFormatter(date, row) {
	if (date) {
		return moment(date).format("MM/DD/YYYY hh:mm A");
	} else {
		return "-";
	}
}

/**
 * Returns values separated by commas
 *
 * Used in PlansDashboard.js
 */
export const arrayFormatter = (cell, row) => {
	if (cell) {
		const separated_by_commas = cell.join(", ");
		return separated_by_commas;
	}
};

/**
 * Returns active or inactive depending on the case
 *
 * Used in PlansDashboard.js
 */
export const statusFormatter = (cell, row) => {
	return cell ? "active" : "inactive";
};
