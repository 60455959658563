import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import TruckCellView from "@smarthop/list/views/TruckCellView";
import { deleteCostStructure } from "app/services/costStructureServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { createTooltip, formatCurrency } from "app/main/utils/tableUtils";

const costsList = ({ isAdmin, onSetDefault, role }) => {
	return {
		urlGET: isAdmin ? "api/cost-structures" : "api/cost-structures/carriers/:carrierId",
		idKey: "_id",
		listenEvent: "costStructureRevision",
		segmentKey: "COST_STRUCTURE",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: ["name", "createdAt"],
			},
			pagination: {
				enabled: true,
			},
			order: {
				defaut: {
					key: "name",
					dir: "desc",
				},
			},
			filters: {
				search: true,
				items: [
					{
						key: "equipment",
						type: "select",
						label: "Equipment",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "VAN",
								label: "Van",
							},
							{
								value: "REEFER",
								label: "Reefer",
							},
							{
								value: "PO",
								label: "Power Only",
							},
							{
								value: "FLATBED",
								label: "Flatbed",
							},
						],
					},
					...(isAdmin
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									translate: "",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										params: {
											sortOrder: "asc",
											sortBy: "carrier_id",
										},
									},
								},
								{
									label: "Filter Cost Structures with Warnings",
									key: "hasWarnings",
									type: "checkbox",
								},
						  ]
						: []),
				],
			},
			...(isAdmin
				? {
						groups: {
							items: [
								{
									key: "internal",
									type: "checkbox",
									label: "Show Internal Fields",
								},
							],
						},
				  }
				: {}),
			items: [
				{
					key: "_id",
					type: "id",
					label: "ID",
					hidden: !isAdmin,
					group: "internal",
				},
				{
					key: "carrier__view",
					type: "carrier__view",
					label: "Carrier",
					hidden: !isAdmin,
				},
				{
					key: "name_cost_structure",
					type: "text",
					label: "Name",
					grid: { headerTitle: true },
					maxLengthStart: !isAdmin ? 40 : 20,
				},
				{
					key: "default_badge",
					type: "component",
					label: "Default",
					grid: { headerSubtitle: true },
					hidden: isAdmin,
					builder: (item) =>
						item.default ? (
							<Typography className="bg-green-800 px-6 rounded-lg">
								Default for <b>{item.equipment}</b>
							</Typography>
						) : (
							<span></span>
						),
				},
				{
					key: "default",
					type: "bool",
					label: "Default",
					hidden: !isAdmin,
				},
				{
					key: "equipment",
					type: "text",
					label: "Equipment",
				},
				{
					key: "percentageCost",
					type: "text",
					label: "Fees",
					builder: (item) => (item.percentageCost ? item.percentageCost + "%" : null),
				},
				{
					key: "period",
					type: "text",
					label: ["Monthly", "Utilization"],
					builder: (item) => item.period + " Days",
				},
				{
					key: "avgMilesPerDay",
					type: "text",
					label: ["Avg Miles", "per Day"],
					builder: (item) => {
						if (item.avgMilesPerDay <= 600) return item.avgMilesPerDay;
						return (
							<div className="flex flex-row space-x-2">
								<div>{item.avgMilesPerDay}</div>
								{createTooltip(
									<Icon className="text-orange-700 text-16">warning</Icon>,
									"Average miles per day should be less than 600"
								)}
							</div>
						);
					},
				},
				{
					key: "fixedMonthlyCosts",
					type: "currency",
					label: ["Monthly", "Expenses"],
				},
				{
					key: "costPerMile",
					type: "component",
					label: ["Maintenance", "Cost Per Mile"],
					table: {
						align: "right",
					},
					builder: (item) => {
						if (item.costPerMile <= 0.2) return formatCurrency(item.costPerMile);
						return (
							<div className="flex flex-row space-x-2">
								<div>{formatCurrency(item.costPerMile)}</div>
								{createTooltip(
									<Icon className="text-orange-700 text-16">warning</Icon>,
									"Maintenance Cost Per Mile should be less than $0.2"
								)}
							</div>
						);
					},
				},
				{
					key: "calculatedFields.totalCostPerMile",
					type: "component",
					label: ["Calculated All In", "Cost Per Mile"],
					table: {
						align: "right",
					},
					grid: { longRow: true },
					builder: (item) => {
						const warning = item?.calculatedFields?.warnings?.["calculatedFields.totalCostPerMile"];

						const breakdownTooltip = createTooltip(
							<Icon className="text-16 text-blue-500">info</Icon>,
							<div className="flex flex-col">
								<Typography>{`Fuel: ${formatCurrency(item?.calculatedFields?.fuelCostPerMile, 2)}`}</Typography>
								<Typography>{`Driver: ${formatCurrency(
									item?.calculatedFields?.driverCostPerMile + item?.calculatedFields?.secondaryDriverCostPerMile,
									2
								)}`}</Typography>
								<Typography>{`Fixed: ${formatCurrency(item?.calculatedFields?.fixedCostPerMile, 2)}`}</Typography>
								<Typography>{`Maintenance: ${formatCurrency(
									item?.calculatedFields?.maintenanceCostPerMile,
									2
								)}`}</Typography>
							</div>
						);

						return (
							<div className="flex flex-row space-x-2">
								<Typography>{formatCurrency(item?.calculatedFields?.totalCostPerMile, 2)}</Typography>
								{breakdownTooltip}
								{warning && createTooltip(<Icon className="text-16 text-orange-700">warning</Icon>, warning)}
							</div>
						);
					},
				},
				{
					key: "default_labled",
					type: "component",
					label: "Status",
					grid: { longRow: true },
					hidden: isAdmin,
					builder: (item) =>
						item.default ? (
							<div className="flex flex-row items-center pt-6">
								<Icon className="text-28 text-green-800 mr-8">warning</Icon>
								<Typography className="text-green-800" style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
									This cost structure is used by default all <b>{item.equipment}</b> trucks. You can always override the
									cost structure in the truck settings
								</Typography>
							</div>
						) : (
							<div className="flex flex-col pt-6">
								<Typography className="text-grey-700 mb-2">
									This cost structure is selected for
									<b>{` ${item.trucks?.length ?? 0} ${item.equipment} `}</b>
									truck(s)
								</Typography>
								{item.trucks__views?.map((item) => (
									<div className="flex flex-row items-center pt-3">
										<Icon className="text-18 mr-8 text-orange-800">local_shipping</Icon>
										<TruckCellView variant="spacious" view={item} />
									</div>
								))}
							</div>
						),
				},
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "set_default",
							label: "Set As Default",
							isVisible: (item) => !isAdmin && !item.default,
							dispatchAction: (item) => onSetDefault(item._id, { carrier: item.carrier, equipment: item.equipment }),
						},
						{
							key: "edit",
							label: "Edit",
							isVisible: (item) => (!!item.carrier || isAdmin) && role !== "carrier_dispatcher",
							dialogBuilder: (item, dataIds) => ({
								viewId: "COST_STRUCTURE",
								mode: "EDIT",
								dataIds: { id: item._id },
							}),
						},
						{
							key: "view",
							label: "View",
							isVisible: (item) => !!item.carrier || isAdmin,
							dialogBuilder: (item, dataIds) => ({
								viewId: "COST_STRUCTURE_VIEW",
								mode: "EDIT",
								dataIds: { id: item._id, carrierId: item?.carrier?._id ?? item?.carrier },
							}),
						},
						{
							key: "delete",
							label: "Delete",
							isVisible: (item) => (!!item.carrier || isAdmin) && role !== "carrier_dispatcher",
							dispatchAction: async (item, dataIds, dispatch) => {
								await deleteCostStructure(item._id);
								dispatch(incrementDataRevision({ event: "costStructureRevision" }));
							},
						},
					],
				},
			],
		},
	};
};

export default costsList;
