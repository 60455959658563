const adminFakeTransactionForm = {
	urlPOST: "api/transaction/debug",
	idKey: "name",
	triggerEvent: "transactionsRevision",
	header: {
		create: { label: "Create Fake Transaction" },
	},
	content: {
		view: {
			sameAs: "create",
		},
		create: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "user",
								type: "autocomplete",
								label: "Users",
								required: true,
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/users",
									params: {
										options_show_limit: 200,
										options_show_carrier: true,
									},
								},
							},
							{
								key: "typeTransaction",
								type: "select",
								label: "Type",
								required: true,
								options: [
									{ label: "Succesfully", value: "SUCCESS" },
									{ label: "Required Response", value: "RESPONSE" },
									{ label: "Declined", value: "DECLINED" },
								],
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminFakeTransactionForm;
