import React, { useState } from "react";
import { IconButton, Icon, Popover, MenuItem } from "@material-ui/core";

const DropdownButton = (props) => {
	const options = props.options ?? [];
	const icon = props.icon;
	const onSelect = props.onSelect;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => setAnchorEl(event.currentTarget);

	const handleClose = (option) => {
		if (option?.onClick) option.onClick();
		if (option?.onSelect) onSelect(option?.title);
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton color="inherit" onClick={handleClick}>
				<Icon>{icon}</Icon>
			</IconButton>
			<Popover
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={() => handleClose()}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				classes={{
					paper: "py-8 w-auto",
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={`dropdown-option-${option.title}`}
						onClick={() => handleClose(option)}
						disabled={option.disabled}
					>
						{option.icon && <Icon className="text-20 mr-12 md:text-24">{option.icon}</Icon>}
						{option.title && <div className="text-12 md:text-14">{option.title}</div>}
					</MenuItem>
				))}
			</Popover>
		</>
	);
};

export default DropdownButton;
