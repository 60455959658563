import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getCsvOnboardedBrokers = (carrierId, listType) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/brokers/${listType}/download`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const uploadCsvBrokersLists = (carrierId, listType, data) => {
	return createRequest()
		.post(`api/profile/carriers/${carrierId}/brokers/${listType}/upload`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierTrucks = (carrierId) => {
	let url = `api/profile/carriers/${carrierId}/trucks`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierConfig = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/details/configuration`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarriers = ({ params }) => {
	const url = `api/profile/carriers`;
	return createRequest()
		.get(url, { headers: createHeaders(), params })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierFile = (carrierId, fileId) => {
	const url = `/api/profile/carriers/${carrierId}/files/${fileId}`;
	return createRequest()
		.get(url, { headers: createHeaders(), responseType: "blob" })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierInformation = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/details/company-information`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateCarrierInformation = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/details/company-information`;
	return createRequest()
		.put(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierDOTInformation = (carrierId, mc, dot) => {
	const url = `api/profile/carriers/${carrierId}/details/company-information/dot/sync?mc=${mc}&dot=${dot}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendCarrierDOTPhoneCode = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/details/company-information/dot/phone/send-code`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendCarrierDOTEmailCode = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/details/company-information/dot/email/send-code`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const verifyCarrierDOTValidationCode = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/details/company-information/dot/validation/verify-code`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getDotInformation = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/dot-information`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierPayrollSettings = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/details/payroll-settings`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierFuelSettings = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/details/fuel`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierInvoiceSettings = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/invoicing-settings`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierCustomer = (carrierId) => {
	const url = `billing/carriers/${carrierId}/customer`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const requestSendTestEmail = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/branding-mail-conf-test`;
	return createRequest()
		.post(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateCarrierBrandingMailConf = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/branding-mail-conf`;
	return createRequest()
		.put(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierBrandingMailConf = (carrierId) => {
	return createRequest()
		.get(`/api/profile/carriers/${carrierId}/branding-mail-conf`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierBranding = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/branding`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierFuelModule = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/details/fuel`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateCarrierFuelModule = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/details/fuel`;
	return createRequest()
		.put(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getNameOwnership = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/owner`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const changeOwnership = (carrierId, data) => {
	const url = `api/profile/carriers/${carrierId}/owner`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierInvoiceIdConfig = (carrierId) => {
	return createRequest()
		.get(`/api/profile/carriers/${carrierId}/invoicing-settings`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getInvoicingPaymentInformation = (carrierId) => {
	return createRequest()
		.get(`/api/profile/carriers/${carrierId}/factoring-settings`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const resetBrandingMailConfig = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/branding-mail-conf-reset`;
	return createRequest()
		.put(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierOnboardingStatus = (carrierId) => {
	return createRequest()
		.get(`api/onboarding/carriers/${carrierId}/status`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const resetInvoicingPaymentInformation = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/factoring-settings`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const archiveCarrier = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/archive`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const sendIntegrationBrokerRequest = (carrierId, brokerId, data) => {
	const url = `api/profile/carriers/${carrierId}/integrations/brokers/${brokerId}/request`;
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const connectAxle = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/axle/link`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const disconnectAxle = (carrierId) => {
	const url = `api/profile/carriers/${carrierId}/axle/link`;
	return createRequest()
		.delete(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getAxleImport = (carrierId) => {
	const url = `api/axle_import/carriers/${carrierId}`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const importAxleRecords = (carrierId, data) => {
	const url = `api/axle_import/carriers/${carrierId}`;
	return createRequest()
		.put(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
