// Dependencies
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

// Services
import { getCarrierId, signInWithToken } from "app/services/LoginService";
import { cancelPlatformPlan } from "app/services/platformPlanServices";
import { setUserData } from "app/store/auth/userSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { closeAllDialogs } from "app/store/tools/formDialogSlice";

// Components
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import PlatformCancelationDialog from "app/main/billing/plans/PlatformCancelationDialog";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";

const PlarformOfferLowerPlanView = (props) => {
	useEffect(() => {
		props?.setTitle?.("We have an offer for you");
		props?.setSize?.("max-w-5xl");
		props?.setClasses?.({ toolbar: "bg-primary", content: "bg-primary-50" });
		// eslint-disable-next-line
	}, []);

	const params = useParams();
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	// Props
	const carrierId = params.carrierId ?? props.params?.carrierId ?? props?.dataIds?.carrierId ?? getCarrierId();

	// States
	const [openDialog, setOpenDialog] = useState(null);

	const handleCancelDialog = () => {
		setOpenDialog(!openDialog);
	};

	const handleCancelPlatformPlan = async (reasons, comments) => {
		try {
			await cancelPlatformPlan(carrierId, { reasons, comments });
			const userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData));
			dispatch(incrementDataRevision({ event: "planRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingMoveToSummaryRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			dispatch(closeAllDialogs());
			showSnackbar(snackbar, `Subscription cancelled`, "success");
		} catch (err) {
			console.error(err);
			showSnackbar(
				snackbar,
				err?.errors?.[0]?.message ?? "Could not successfully cancel the subscription. Contact support for more detail",
				"error"
			);
		}
	};

	return (
		<>
			<PlatformAvailablePlansView
				carrierId={carrierId}
				isCancelPlanView={true}
				handleCancelDialog={handleCancelDialog}
			/>
			<PlatformCancelationDialog
				open={openDialog}
				onAccept={handleCancelPlatformPlan}
				onClose={() => handleCancelDialog()}
			/>
		</>
	);
};

export default PlarformOfferLowerPlanView;
