import Typography from "@material-ui/core/Typography";

const usersEmailAliasList = {
	urlGET: "api/profile/users/:userId/alias",
	idKey: "_id",
	listenEvent: "emailAliasRevision",
	segmentKey: "PROFILE_EMAIL_ALIAS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 1,
			mobile: {
				tilesPerRow: 1,
				itemsPerRow: 2,
				layout: [["email", "status"]],
			},
		},
		table: {
			variant: "skinny",
			theme: "dark",
		},
		items: [
			{
				key: "email",
				label: "Email",
				table: { align: "left" },
			},
			{
				key: "status",
				type: "text",
				label: "Status",
				table: { width: "17%" },
				builder: (item) => {
					if (item.status === "VERIFIED") {
						return <Typography className="text-13 max-w-180 truncate">Verified</Typography>;
					} else if (item.status === "WAITING_VALIDATION") {
						return <Typography className="text-13 max-w-180 truncate text-grey">Waiting Confirmation</Typography>;
					} else if (item.status === "RESEND_CODE") {
						return <Typography className="text-13 max-w-180 truncate text-grey">Resend Code</Typography>;
					} else {
						return "-";
					}
				},
			},
			{
				key: "menu",
				type: "menu",
				label: "",
				items: [
					{
						key: "delete",
						label: "Delete",
						dialogBuilder: (item, dataIds) => ({
							formId: "USER_EMAIL_ALIAS_FORM",
							mode: "DELETE",
							dataIds: { aliasId: item._id, userId: dataIds.userId },
						}),
					},
					{
						key: "resend",
						label: "Resend",
						isVisible: (item) => item?.status !== "VERIFIED",
						dialogBuilder: (item, dataIds) => ({
							formId: "USER_EMAIL_ALIAS_FORM",
							mode: "EDIT",
							dataIds: { aliasId: item._id, userId: dataIds.userId },
						}),
					},
				],
			},
		],
	},
};

export default usersEmailAliasList;
