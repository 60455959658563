import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { processTierRestrictions } from "app/main/utils/rolesUtils";

// Const
const _TIER_STARTER = "TIER_STARTER";
const _TIER_BASIC = "TIER_BASIC";
const _TIER_PROFESSIONAL = "TIER_PROFESSIONAL";

const getDefaultCallback = (callbackKey, dataIds) => {
	let restriction;
	let func;

	if (callbackKey === "CARRIER_COMPANY_INFORMATION_FORM") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier });
		func = (dispatch) => dispatch(openFormDialog({ formId: callbackKey, mode: "CREATE", dataIds }));
	} else if (callbackKey === "CARRIER_DISPATCHING_CONNECT_ACCOUNT_FORM") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier });
		func = (dispatch) => dispatch(openFormDialog({ formId: callbackKey, mode: "CREATE", dataIds }));
	} else if (callbackKey === "LOAD_SUGGEST_VIEW") {
		func = (dispatch) => dispatch(openLoadedFormDialog({ viewId: callbackKey, dataIds }));
	} else if (callbackKey === "CARRIER_NOTIFICATION_BROKER_CONFIGURATION_FORM") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_PROFESSIONAL], tier: tier });
		func = (dispatch) => dispatch(openLoadedFormDialog({ formId: callbackKey, mode: "EDIT", dataIds }));
	} else if (callbackKey === "UPLOAD_FILES_FORM") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_PROFESSIONAL], tier: tier });
		func = (dispatch) => dispatch(openLoadedFormDialog({ viewId: callbackKey, mode: "EDIT", dataIds }));
	} else if (callbackKey === "TRIP_EDIT_VIEW") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_PROFESSIONAL], tier: tier });
		func = (dispatch) => dispatch(openFormDialog({ viewId: callbackKey, mode: "EDIT", dataIds }));
	} else if (callbackKey === "TRIP_VIEW") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_PROFESSIONAL], tier: tier });
		func = (dispatch) => dispatch(openLoadedFormDialog({ viewId: callbackKey, dataIds }));
	} else if (callbackKey === "TRIP_INVOICE_VIEW") {
		restriction = (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier });
		func = (dispatch) => dispatch(openLoadedFormDialog({ viewId: callbackKey, dataIds }));
	}
	return { restriction, function: func };
};

const CONFIG_PAYWALL = (data, extraData) => {
	return {
		default: {
			title: "This functionality is restricted for this plan",
			description: "Update your plan now to be able to access this functionality",
			callback: getDefaultCallback(data.callbackKey, data.dataIdsInherited),
		},
		chat_restricted: {
			title: "Dispatching multiple trucks?",
			description: "Upgrade your plan to communicate with all your drivers in the app",
			callback: {
				restriction: (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier }),
				function: (dispatch) => {
					const viewId = extraData.type === "TRUCK_EXTERNAL" ? "CHAT_APP_PREVIEW_TRUCK_EXTERNAL" : "CHAT_APP";
					dispatch(openFormDialog({ viewId: viewId, dataIds: data.dataIdsInherited }));
				},
			},
		},
		truck_limit: {
			title: "You have reached your plan’s truck limit.",
			description: "Upgrade your plan to add unlimited trucks to your account",
			callback: {
				restriction: (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier }),
				function: (dispatch) => dispatch(openFormDialog({ viewId: "TRUCK_VIEW", dataIds: data.dataIdsInherited })),
			},
		},
		digital_booking_restricted: {
			title: "Want to book loads digitally?",
			description: "Upgrade your plan now to book instantly",
			callback: {
				restriction: (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier }),
				function: (dispatch) => {
					if (data.callbackKey) {
						const callback = getDefaultCallback(data.callbackKey, data.dataIdsInherited);
						return callback.function(dispatch);
					}
					data.callback("BID");
				},
			},
		},
		book_externally_restricted: {
			title: "Want to book loads externally?",
			description: "Upgrade your plan now to book instantly",
			callback: {
				restriction: (tier) => processTierRestrictions({ restrictions: [_TIER_BASIC], tier: tier }),
				function: (dispatch) => {
					if (data.callbackKey) {
						const callback = getDefaultCallback(data.callbackKey, data.dataIdsInherited);
						return callback.function(dispatch);
					}
					data.callback("EXTERNAL_BOOK");
				},
			},
		},
		driver_notification_restriction: {
			title: "Want automated driver notifications?",
			description: "Upgrade your plan now to send SMS Driver notification.",
			callback: {
				restriction: (tier) => processTierRestrictions({ restrictions: [_TIER_PROFESSIONAL], tier: tier }),
				function: async (dispatch) => {
					if (data.callback) {
						await data.callback(extraData.dataSend);
					}
					if (data.callbackKey) {
						const callback = getDefaultCallback(data.callbackKey, data.dataIdsInherited);
						return callback.function(dispatch);
					}
				},
			},
		},
		user_restriction: {
			title: "Do you like to create users with platform access?",
			description: "Upgrade your plan to create users with access to the web and mobile platform.",
			callback: {
				restriction: (tier) => tier !== _TIER_STARTER,
				function: async () => await data.callback(extraData.dataSend),
			},
		},
		automate_functionality_restriction: {
			title: "Want to automate broker status updates?",
			description: "Upgrade your plan now to see how much time you could be saving",
			callback: getDefaultCallback(data.callbackKey, data.dataIdsInherited),
		},
		subaccount_restriction: {
			title: "You have reached your plan’s sub-account limit.",
			description: "Upgrade your plan or add extra seats to your account",
			callback: getDefaultCallback(data.callbackKey, data.dataIdsInherited),
		},
		rc_transcription_restriction: {
			title: "Want to process files automatically?",
			description: "Upgrade your plan now to see how much time you could be saving",
			callback: {
				restriction: (tier) => tier !== _TIER_STARTER,
				function: async (dispatch) => {
					if (data.callback) {
						return data.callback(extraData.dataSend);
					}
					if (data.callbackKey) {
						const callback = getDefaultCallback(data.callbackKey, data.dataIdsInherited);
						return callback.function(dispatch);
					}
				},
			},
		},
	};
};
export { CONFIG_PAYWALL };
