const config = {
	title: "Legend",
	items: [
		{
			label: "Circle",
			imgSrc: "circle.png",
			description:
				"Circles represent a location in the route, The first circle is the route’s origin, every circle thereafter is a destination along the route.",
			items: [
				{
					label: "Border",
					imgSrc: "circle-border.png",
					description:
						"The border’s thickness represent the RPM of that move. The thicker it is, the higher the RPM. Circle borders are green, unless RPM is 0, in which case they are red.",
				},
				{
					imgSrc: "circle-colors.png",
					label: "Color",
					description:
						"The color of the circle represents how hot or cold the arrival market is. The hotter the market the more available loads there will be in that arrival city.",
				},
				{
					imgSrc: "main-city.png",
					label: "Label",
					description:
						"This is the location name the truck is arriving to. These names correspond to cities across the continental United States.",
				},
			],
		},
		{
			label: "Line",
			imgSrc: "line.png",
			items: [
				{
					imgSrc: "dotted-line.png",
					label: "Dotted",
					description: "A dotted line, typically represented in red color, signifies an empty move (dead head).",
				},
				{
					imgSrc: "solid-line.png",
					label: "Solid",
					description: "A solid line, represent a “move” the truck makes through a route.",
					items: [
						{
							imgSrc: "line-thickness.png",
							label: "Thickness",
							description:
								"The thickness of the line represents the average volume seen in that lane where the move is taken place. The thicker the line, the more loads we’ve seeing between those locations, thus the higher the probability to book a load.",
						},
						{
							imgSrc: "line-colors.png",
							label: "Color",
							description:
								"The color of the line discribes multiple things, but at its most basic uses green for a loaded move, and red for an empty one",
							items: [
								{
									imgSrc: "strategy-green-color.png",
									label: "Green",
									description: "Loaded move",
								},
								{
									imgSrc: "empty-color.png",
									label: "Red",
									description: "Empty move (dead head)",
								},
								{
									imgSrc: "yellow-color.png",
									label: "Yellow",
									description:
										"Flex move, this means that these lanes do not entirely satisfy your search criteria, especially when it comes to profit.",
								},
								{
									imgSrc: "purple-color.png",
									label: "Purple",
									description:
										"Indicates a move that has been selected for saving. Similar to adding an item to a cart.",
								},
								{
									imgSrc: "orange-color.png",
									label: "Orange",
									description: "A trip that matches this move criteria has been booked.",
								},
								{
									imgSrc: "taken-color.png",
									label: "Navy Blue",
									description: "A trip that matches this move criteria was completed.",
								},
								{
									imgSrc: "light-sea-green-color.png",
									label: "Aqua",
									description:
										"Describes a move that represents a load, when exploring a strategy that starts at an unbooked load destination.",
								},
							],
						},
					],
				},
			],
		},
		{
			label: "Axis",
			imgSrc: "axis-y.png",
			description:
				"The y axis marks the profit level of a move. It's minimum and maximum values correspond to the lowest and highest profits seen accross all the moves in the visualized strategy.",
		},
		{
			label: "Dates",
			imgSrc: "dates.png",
			description:
				"These blocks correspond to the date the truck arrives to its destinations. The width of the block signifies the length of the day, morning arrivals would be to the left of the center, while afternoon arrivals to the right of it.",
		},
	],
};

export default config;
