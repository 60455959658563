import React, { useRef, useState, forwardRef, useEffect } from "react";
import moment from "moment";

import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Zoom from "@material-ui/core/Zoom";
import { DatePicker } from "@material-ui/pickers";

import "antd/dist/antd.css";
import { TimePicker } from "antd";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
	box: { paddingBottom: "20px" },
});

const ZoomTransition = forwardRef(function Transition(props, ref) {
	return <Zoom direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({ isOpen, setIsOpen, configRest, children }) => {
	const classes = useStyles();

	// TODO: Check use the this function.
	const clickAwayHandler = () => {
		setIsOpen(false);
		if (configRest?.onClose) configRest.onClose();
	};
	return (
		<ClickAwayListener onClickAway={clickAwayHandler}>
			<Dialog
				open={isOpen}
				className="customDatePicker"
				disableEnforceFocus={true}
				disableAutoFocus={true}
				onClose={() => {
					setIsOpen(false);
					if (configRest?.onClose) configRest.onClose();
				}}
				TransitionComponent={ZoomTransition}
			>
				<Box>
					<Paper className={classes.box}>{children}</Paper>
				</Box>
			</Dialog>
		</ClickAwayListener>
	);
};

const SmarthopCustomDateTimePicker = (props) => {
	const propsDate = !props.value ? null : moment(props.value).local();
	const [isOpen, setIsOpen] = useState(false);
	const [date, setDate] = useState(propsDate);
	const [time, setTime] = useState(propsDate);
	const { onChange: configOnChange, ...configRest } = props;
	const localInputRef = useRef();
	const timeRef = useRef();
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);

	useEffect(() => {
		const propsDate = !props.value ? null : moment(props.value).local();
		if (propsDate) {
			setDate(propsDate);
			setTime(propsDate);
		}
	}, [props.value]);

	const checkIfDateOrMoment = (obj) => moment.isDate(obj) || moment.isMoment(obj);
	const checkIfDateStrOrDateOrNull = (obj) =>
		checkIfDateOrMoment(obj) ? obj.toISOString() : obj.match("T") ? obj : moment().toISOString();

	const updateDateTimeLocalToUtc = () => {
		// date and time come out of each corresponding component as utc
		const localDate = moment(checkIfDateOrMoment(date) ? date.toISOString() : date)
			.local()
			.format("YYYY-MM-DD");
		const localTime = moment(checkIfDateOrMoment(time) ? time.toISOString() : time)
			.local()
			.format("HH:mm:ss");
		// create new moment with local tz and send back utc as string
		const selectedDate = moment(`${localDate} ${localTime}`);
		const minDate =
			configRest.minDate && (checkIfDateOrMoment(configRest.minDate) ? configRest.minDate : moment(configRest.minDate));
		const outputDate = minDate ? moment.max([minDate, selectedDate]) : selectedDate;
		configOnChange(outputDate.toISOString());
	};

	const onDateChange = (dateTimeVal) => setDate(dateTimeVal);
	const onTimeChange = (dateTimeVal) => setTime(dateTimeVal);

	useEffect(() => {
		if (!isOpen) return;
		if (!time) {
			configOnChange(date);
		} else if (date) {
			updateDateTimeLocalToUtc();
		}
		timeRef.current?.focus();
	}, [date]); // eslint-disable-line

	useEffect(() => {
		if (!isOpen) return;
		if (!time && date) {
			configOnChange(checkIfDateStrOrDateOrNull(date));
		} else if (time && !date) {
			configOnChange(checkIfDateStrOrDateOrNull(time));
		} else {
			updateDateTimeLocalToUtc();
		}
	}, [time]); // eslint-disable-line

	const getDisabledHours = (date, minDate) => {
		if (!date || !minDate) return;
		const sameDay = moment(date).isSame(moment(minDate), "day");
		if (sameDay) {
			const disabledHours = [...Array(moment(minDate).hour() + 1).keys()];
			return disabledHours;
		}
	};

	return (
		<div className="w-full mb-4 mt-4">
			<TextField
				name={configRest.name}
				label={configRest.label}
				inputProps={configRest.inputProps}
				classes={configRest.classes}
				onBlur={configRest.onBlur}
				InputProps={configRest.InputProps}
				className={configRest.className}
				helperText={configRest.helperText}
				FormHelperTextProps={configRest.FormHelperTextProps}
				variant={configRest.inputVariant}
				value={props.value ? moment(props.value).format("MMM Do YYYY, h:mma") : ""}
				ref={(e) => {
					configRest?.inputRef?.(e);
					localInputRef.current = e;
				}}
				error={configRest.error}
				onMouseDown={configRest?.onMouseDown}
				onClick={(ev) => {
					if (!configRest.disabled) setIsOpen(true);
				}}
			/>
			{isOpen && !nativeMobile && (
				<CustomDialog {...{ setIsOpen, isOpen, configRest, localInputRef }}>
					<DatePicker
						variant="static"
						value={date}
						onChange={onDateChange}
						minDate={configRest.minDate}
						maxDate={configRest.maxDate}
						disablePast={configRest.disablePast}
					/>
					<TimePicker
						disabledHours={() =>
							getDisabledHours(date ? date : moment(), configRest.disablePast ? moment() : configRest.minDate)
						}
						allowClear={false}
						value={time}
						ref={timeRef}
						style={{
							margin: "0px auto",
							width: "40%",
							display: "flex",
							padding: "8px 16px",
						}}
						format="HH:mm"
						onChange={onTimeChange}
						onSelect={onTimeChange}
						minuteStep={configRest.minutesStep}
						popupStyle={{
							zIndex: 3000,
							fontSize: "1.6rem",
						}}
						size="large"
					/>
				</CustomDialog>
			)}
		</div>
	);
};

export default SmarthopCustomDateTimePicker;
