import moment from "moment";

import { useMemo } from "react";

import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellContainerRow from "./CellContainerRow";
import CellEmptyRow from "./CellEmptyRow";
import { formatDate, formatAge } from "app/main/utils/dateUtils";
import { getSourceText } from "app/main/utils/liveTrackingUtils";

function TrackingCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const item = props.item;
	const lastUpdate = props.view?.metadata?.lastUpdate;
	const lastEta = props.view?.metadata?.lastEta;
	const lastEtaUpdate = props.view?.metadata?.lastEtaUpdate;
	const lastEtaStop = props.view?.metadata?.lastEtaStop ?? "";
	const lastError = props.view?.metadata?.lastError;
	const lastUpdateSource = props.view?.metadata?.lastUpdateSource;
	const isTest = props.view?.metadata?.isTest;
	const lastIsOutdated = props.view?.metadata?.outdated;
	const milesLeft = props.view?.metadata?.milesLeft;
	const trackingPending = props.view?.label === "Pending...";

	const { age, eta, outdated, late, etaExpired } = useMemo(() => {
		const deliveryDate = item.data?.delivery_window_date ?? item.data?.delivery_date;
		return {
			age: !lastUpdate ? null : formatAge(lastUpdate),
			eta: !lastEta
				? null
				: formatDate(lastEta, null, true) + (lastEtaStop !== "to DEL" && !!lastEtaStop ? " " + lastEtaStop : ""),
			outdated: !lastUpdate ? false : lastIsOutdated,
			late: !lastEta || !deliveryDate ? false : moment(lastEta).isAfter(moment(deliveryDate).add(6, "hours")),
			etaExpired: !lastEta ? true : moment(lastEtaUpdate).add(12, "hours").isBefore(moment()),
		};
	}, [lastEtaStop, lastEta, lastUpdate, item, lastEtaUpdate, lastIsOutdated]);

	if (!props.view?.label) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			left={
				<div
					className={
						"w-8 h-8 mr-8 rounded-full " + (outdated || trackingPending ? " bg-red " : " bg-green animate-pulse ")
					}
				/>
			}
			first={
				config?.readOnly ? (
					props.view?.label
				) : (
					<div className="flex flex-row">
						<CellFirstRow
							mobile={mobile}
							view={props.view}
							dialog={
								!config.disableLink
									? {
											viewId: "TRIP_VIEW",
											dataIds: { tripId: item._id, carrierId: item.carrier, initTab: "TRACKING" },
									  }
									: undefined
							}
							variant={variant}
						/>
						<CellSecondRow
							mobile={mobile}
							label={trackingPending ? null : getSourceText(lastUpdateSource)}
							variant={variant}
							classes={{ label: " text-grey-600 text-bold pt-1 pl-2 " }}
						/>
					</div>
				)
			}
			second={
				!trackingPending ? (
					<div className="flex no-wrap">
						{isTest && (
							<CellSecondRow
								mobile={mobile}
								icon={"construction"}
								label="Test"
								color="text-blue-600"
								variant={"extra-skinny"}
							/>
						)}
						{config?.showETA && eta && !etaExpired && (
							<>
								<div className="flex flex-row whitespace-nowrap">
									<CellSecondRow
										mobile={mobile}
										label={`ETA`}
										variant={variant}
										icon={!late ? null : "error_outline"}
										classes={{ icon: !late ? null : "text-red font-semibold", label: "text-primary font-semibold " }}
										tooltip={late ? "It seems this truck is running >6h late" : null}
									/>
									<CellSecondRow mobile={mobile} label={eta} variant={variant} />
								</div>
							</>
						)}
						{config?.showETA && milesLeft >= 0 && (
							<div className="flex flex-row whitespace-nowrap">
								<CellSecondRow
									mobile={mobile}
									label={`${milesLeft} mi left`}
									variant={variant}
									// tooltip={`ETA has not being updated for the last 12h, please configure ELD or update ETA manually${
									// 	lastEtaUpdate ? ". Last update: " + formatDate(lastEtaUpdate, null, true) : ""
									// }`}
									// icon={"error_outline"}
									classes={{ icon: "text-red font-semibold" }}
								/>
							</div>
						)}
						<CellSecondRow
							mobile={mobile}
							icon={"cached"}
							label={age}
							variant={variant}
							color={outdated ? "text-red" : null}
							tooltip={
								outdated
									? `We have not received any updates for the past ${age}, ETA and location might be out of date` +
									  (lastError ? ` (${lastError})` : "")
									: null
							}
						/>
					</div>
				) : null
			}
		/>
	);
}

export default TrackingCellView;
