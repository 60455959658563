// Utils
import {
	scheduleOptions,
	statusOptions,
	directionOptions,
	trackerFormatter,
	scheduleDescription,
	directionDescription,
	amountDescription,
	nameDescription,
	statusDescription,
	startDateDescription,
	endDateDescription,
} from "app/main/profile/drivers/EnterprisePayrollSection";
import { formatDate } from "app/main/utils/dateUtils";

const enterprisePayrollRulesList = ({ isInvestor }) => ({
	urlGET: `billing/v3/carrier/:carrierId/user/:userId/recurrent-payments`,
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision"],
	segmentKey: "ENTERPRISE_TRIPS_PAYROLL",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["payee__view.label", "priority", "closed_date", "approved_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		click: {
			dialogBuilder: (item, dataIds) => ({
				viewId: "PAYROLL_RULES_VIEW",
				mode: "VIEW",
				dataIds: {
					recurrentPaymentId: item._id,
					carrierId: dataIds.carrierId,
					userId: dataIds.userId,
				},
			}),
		},
		filters: {
			search: true,
			searchDescription: "Search by Description",
		},
		groups: {
			items: [
				{
					key: "date",
					type: "checkbox",
					label: "Show End Date",
				},
				...(isInvestor
					? [
							{
								key: "truck",
								type: "checkbox",
								label: "Show Truck Info",
							},
					  ]
					: []),
			],
		},
		items: [
			{
				key: "name",
				type: "text",
				label: "Description",
				description: nameDescription,
			},
			{
				key: "total_amount",
				type: "currency",
				label: "Amount",
				description: amountDescription,
				table: { align: "left" },
				viewConfig: {
					colorPositive: "font-medium text-black",
					colorNegative: "text-orange-800",
					colorZero: "text-grey",
				},
				builder: (item) => item.total_amount / 100,
			},
			{
				key: "direction",
				type: "text",
				label: "Direction",
				description: directionDescription,
				builder: (item) => directionOptions[item?.direction] ?? "Deduction",
			},
			{
				key: "schedule",
				type: "text",
				label: "Schedule",
				description: scheduleDescription,
				builder: (item) => scheduleOptions[item.schedule],
			},
			{
				key: "start_date",
				type: "text",
				label: ["Start", "Date"],
				description: startDateDescription,
				builder: (item) => formatDate(item.start_date, item.start_date, false, false, true, false),
			},
			{
				key: "next_execution_date",
				type: "text",
				label: ["Next Execution", "Date"],
				description: "Next date to add the rule in a 'In Progress' payroll",
				builder: (item) =>
					formatDate(item.execution.next_execution_date, item.execution.next_execution_date, false, false, true, false),
			},
			{
				key: "end_date",
				type: "text",
				label: ["End", "Date"],
				description: endDateDescription,
				group: "date",
				builder: (item) => (item?.end_date ? formatDate(item.end_date, item.end_date, false, false, true, false) : "-"),
			},
			{
				key: "executions",
				label: "Tracker",
				type: "text",
				description: "Total amount collected/Number of rule executions",
				builder: (item) => trackerFormatter(item?.total_amount, item?.number_executions),
			},
			...(isInvestor
				? [
						{
							key: "truck__view",
							type: "truck__view",
							label: "Truck",
							group: "truck",
							description: `Truck and Driver`,
						},
				  ]
				: []),
			{
				key: "status",
				type: "text",
				label: "Status",
				builder: (item) => statusOptions[item.status],
				description: statusDescription,
			},
			{
				key: "menu",
				type: "menu",
				label: "Actions",
				items: [
					{
						key: "edit",
						variant: "iconButton",
						label: "Edit",
						auth: ["carrier", "carrier_manager"],
						button: {
							icon: "edit",
							color: "primary",
						},
						dialogBuilder: (item, dataIds) => ({
							viewId: "PAYROLL_RULES_VIEW",
							mode: "EDIT",
							dataIds: {
								recurrentPaymentId: item._id,
								carrierId: dataIds.carrierId,
								userId: dataIds.userId,
							},
						}),
					},
					{
						key: "delete",
						variant: "iconButton",
						label: "Delete",
						auth: ["carrier", "carrier_manager"],
						button: {
							icon: "delete",
							color: "primary",
						},
						dialogBuilder: (item) => ({
							formId: "PAYROLL_RULES",
							mode: "DELETE",
							dataIds: {
								recurrentPaymentId: item._id,
							},
						}),
					},
				],
			},
		],
	},
});

export default enterprisePayrollRulesList;
