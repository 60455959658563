import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

function TooltipView(props) {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		if (navigator?.clipboard?.writeText) {
			if (props.mode === "copy") {
				navigator.clipboard.writeText(props.title + "");
			}
			setOpen(true);
			if (props.mode === "copy") {
				setTimeout(() => {
					setOpen(false);
				}, 1000);
			}
		}
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					classes={props.classes}
					title={
						<div>
							<Typography color="inherit" variant="h6" className="p-7 font-light text-14">
								{props.mode === "copy" ? "Copied" : ""} {props.title}
							</Typography>
						</div>
					}
				>
					<div onClick={handleTooltipOpen} className="cursor-pointer">
						{props.children}
					</div>
				</Tooltip>
			</div>
		</ClickAwayListener>
	);
}

export default TooltipView;
