import clsx from "clsx";
import Box from "@material-ui/core/Box";
import TruckCellView from "@smarthop/list/views/TruckCellView";
import DriverCellView from "@smarthop/list/views/DriverCellView";
import { CARRIER_TRUCKS, DRIVER_MY } from "app/main/search/config/searchFields";
import { dateComponent, IngestedRecordStatus, RecordStatus } from "./tripImportConfigComponents";

const TRAILER_EQUIPMENT = {
	key: "equipment",
	type: "select",
	label: "Equipment",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	options: [
		{
			value: "VAN",
			label: "Van",
			translate: "VAN",
		},
		{
			value: "REEFER",
			label: "Reefer",
			translate: "REEFER",
		},
		{
			value: "FLATBED",
			label: "Flatbed",
		},
		{
			value: "PO",
			label: "Power Only",
		},
	],
};

const widthClass = {
	xxSmall: "min-w-72 max-w-72",
	xSmall: "min-w-100 max-w-100",
	small: "min-w-160 max-w-160",
	medium: "min-w-200 max-w-200",
	large: "min-w-250 max-w-250",
};

const locationBuilder = {
	edit: (item) => ({
		...item,
		autocomplete: {
			provider: "trimblemaps",
			formatST: true,
			params: {
				componentRestrictions: { country: "us", includeOnly: "city" },
				types: ["(cities)"],
			},
			multiple: false,
			anyValue: false,
			icon: "location",
		},
	}),
	view: (item) => ({ ...item, label: "" }),
};

const dateBuilder = (key) => ({
	edit: (item) => ({
		...item,
		picker: {
			notClearable: true,
			type: "date",
			classes: { root: widthClass[item.colWidth] },
		},
	}),
	view: (item) => ({
		...item,
		type: "component",
		builder: (builderItem) => <Box className={widthClass[item.colWidth]}>{dateComponent(builderItem?.[key])}</Box>,
	}),
});

const importConfigsBuilder = (content, actions, uploading, ingestionStatus) => {
	const builders = {
		editButton: (item) => ({
			...item,
			builder: (builderItem) => <Box className={widthClass[item.colWidth]}>{actions(builderItem)}</Box>,
		}),
		recordStatus: {
			edit: (item) => ({
				...item,
				builder: (builderItem) => (
					<Box className={widthClass[item.colWidth]}>
						<RecordStatus item={builderItem} />
					</Box>
				),
			}),
			view: (item) => ({
				...item,
				builder: (builderItem) => (
					<Box className={widthClass[item.colWidth]}>
						{uploading && ingestionStatus && builderItem.recordStatus === "valid" ? (
							<IngestedRecordStatus item={builderItem} ingestionStatus={ingestionStatus} />
						) : (
							<RecordStatus item={builderItem} />
						)}
					</Box>
				),
			}),
		},
		broker: {
			edit: (item) => ({
				...item,
				autocomplete: {
					provider: "smarthop",
					url: "api/consts/brokers",
					preloadDefault: true,
					listenEvent: "profileRevision",
				},
			}),
			view: (item) => ({ ...item, label: "" }),
		},
		equipment: {
			edit: (item) => ({
				...item,
				options: TRAILER_EQUIPMENT.options,
			}),
			view: (item) => ({ ...item, label: "", options: TRAILER_EQUIPMENT.options }),
		},
		truck: {
			edit: (item) => ({
				...item,
				autocomplete: {
					...CARRIER_TRUCKS().autocomplete,
					actionOptions: CARRIER_TRUCKS().autocomplete.actionOptions.filter(
						(action) => action.value === "ADD_NEW_TRUCK"
					),
				},
			}),
			view: (item) => ({
				...item,
				type: "component",
				builder: (builderItem) => (
					<Box className={widthClass[item.colWidth]}>
						<TruckCellView item={builderItem} view={builderItem.truck__view} />
					</Box>
				),
			}),
		},
		driver: {
			edit: (item) => ({
				...item,
				autocomplete: {
					...DRIVER_MY.autocomplete,
					actionOptions: [
						{
							value: "ADD_NEW_DRIVER",
							label: "Add New Driver",
							icon: "add",
							dialog: {
								viewId: "CARRIER_USER_EDIT_VIEW",
								mode: "CREATE",
								dataIds: { origin: "DRIVER" },
							},
						},
					],
				},
			}),
			view: (item) => ({
				...item,
				type: "component",
				builder: (builderItem) => (
					<Box className={widthClass[item.colWidth]}>
						<DriverCellView item={builderItem} view={builderItem.driver__view} />
					</Box>
				),
			}),
		},
		pickup_date: dateBuilder("pickup_date"),
		delivery_date: dateBuilder("delivery_date"),
		pickup_address: locationBuilder,
		delivery_address: locationBuilder,
		current_location: locationBuilder,
	};

	const editItems = [];
	const viewItems = [];

	for (let item of content) {
		let builtEditItem = { ...item, field: { classes: { root: widthClass[item.colWidth] } } };
		let builtViewItem = { ...item, label: "", view: { classes: { root: widthClass[item.colWidth] } } };
		if (item.key in builders) {
			const builder = builders[item.key];

			if (!("edit" in builder && "view" in builder)) {
				builtEditItem = builder(builtEditItem);
				builtViewItem = builder(builtViewItem);
			} else {
				builtEditItem = builder.edit(builtEditItem);
				builtViewItem = builder.view(builtViewItem);
			}
		}
		editItems.push(builtEditItem);
		viewItems.push(builtViewItem);
	}

	return { edit: createForm(editItems), view: createForm(viewItems) };
};

const content = [
	{
		key: "editButton",
		label: "Action",
		type: "component",
		colWidth: "xxSmall",
	},
	{
		key: "recordStatus",
		label: "Status",
		type: "component",
		colWidth: "xxSmall",
	},
	{
		key: "load_id",
		label: "Load ID",
		type: "text",
		colWidth: "small",
		required: true,
	},
	{
		key: "broker",
		label: "Broker",
		type: "autocomplete",
		colWidth: "small",
		//required: true,
	},
	{
		key: "current_location",
		label: "Empty Location",
		type: "autocomplete",
		colWidth: "small",
	},
	{
		key: "truck",
		label: "Truck",
		type: "autocomplete",
		colWidth: "small",
		required: true,
	},
	{
		key: "driver",
		label: "Driver",
		type: "autocomplete",
		colWidth: "small",
		required: true,
	},
	{
		key: "pickup_date",
		label: "Pickup Date",
		type: "picker",
		colWidth: "small",
		required: true,
	},
	{
		key: "pickup_address",
		label: "Pickup Address",
		type: "autocomplete",
		colWidth: "small",
		required: true,
	},
	{
		key: "delivery_date",
		label: "Delivery Date",
		type: "picker",
		colWidth: "small",
		required: true,
	},
	{
		key: "delivery_address",
		label: "Delivery Address",
		type: "autocomplete",
		colWidth: "small",
		required: true,
	},
	{
		key: "equipment",
		label: "Equipment",
		type: "select",
		colWidth: "small",
	},
	{
		key: "rate",
		type: "number",
		label: "Rate",
		colWidth: "xxSmall",
	},
	{
		key: "miles",
		type: "number",
		label: "Miles",
		colWidth: "xxSmall",
	},
	{
		key: "emptymiles",
		type: "number",
		label: "Empty Miles",
		colWidth: "xxSmall",
	},
	{
		key: "rpm",
		type: "number",
		label: "RPM",
		colWidth: "xxSmall",
	},
	{
		key: "weight",
		type: "number",
		label: "Weight",
		colWidth: "xxSmall",
	},
];

export const createHeaderConfig = (classes) => {
	const configItems = content.map(({ colWidth, label }) => ({
		type: "component",
		key: label,
		builder: () => (
			<div className={clsx(classes.headerCell, widthClass[colWidth])}>
				<strong>{label}</strong>
			</div>
		),
	}));

	const headerConfig = {
		items: [
			{
				type: "group",
				group: {
					classes: {
						root: clsx("w-full min-w-max overflow-x-auto p-4", classes.headerRow),
						direction: "flex-row",
					},
				},
				content: {
					items: configItems,
				},
			},
		],
	};

	return headerConfig;
};

export const createConfigs = (actions, uploading, ingestionStatus) =>
	importConfigsBuilder(content, actions, uploading, ingestionStatus);

const createForm = (items) => ({
	items: [
		{
			type: "group",
			group: {
				classes: {
					root: "w-full min-w-max overflow-x-auto",
					direction: "flex-row",
				},
			},
			content: {
				items,
			},
		},
	],
});
