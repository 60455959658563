// Constans
import { type_user } from "constants.js";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { createTooltip } from "app/main/utils/tableUtils";

const createAlertView = (item) => {
	let good = !item?.badDevice;
	let color = good ? "text-green-400" : "text-red-700";
	let hoverColor = good ? "text-green-500" : "text-red-800";
	let bgColor = good ? "bg-green-500" : "bg-red-800";
	let icon = good ? "check_circle" : "error_outline";

	const getStatusLabel = (color, hoverColor, icon) => {
		return <Icon className={`text-20 ${color} hover:${hoverColor}`}>{icon}</Icon>;
	};

	let tTitle = (text) => (
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{text}
		</Typography>
	);

	return createTooltip(getStatusLabel(color, hoverColor, icon), tTitle(good ? "Good Device" : "Bad Device"), bgColor);
};

const devices = {
	urlGET: "api/profile/devices",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "INTERNAL_DEVICES",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["email", "updatedAt", "createdAt", "type"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "updatedAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "roles",
					type: "select",
					label: "Role",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__CONTAINS_INT__0",
							label: "Administrator",
						},
						{
							value: "__CONTAINS_INT__1",
							label: "Dispatcher",
						},
						{
							value: "__CONTAINS_INT__2",
							label: "Carrier",
						},
					],
				},
				{
					key: "type",
					type: "select",
					label: "Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "All",
						},
						{
							value: "Darwin",
							label: "Darwin (IOS)",
						},
						{
							value: "Browser",
							label: "Browser",
						},
						{
							value: "Firebase",
							label: "Firebase (Android)",
						},
					],
				},
				{
					key: "badDevice",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "All",
						},
						{
							value: "true",
							label: "Bad Devices",
						},
						{
							value: "__FALSE_OR_EMPTY__",
							label: "Good Devices",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "Device ID", group: "internal" },
			{ key: "user_id", type: "id", label: "User ID", group: "internal" },
			{
				key: "email",
				builder: (item) => {
					if (!item.online) {
						return item.email;
					}

					return (
						<div className="flex flex-row flex-1 items-center pr-16">
							<div className="text-green pt-4">
								<Icon className={"text-18"}>radio_button_checked</Icon>
							</div>
							<Typography color="primary" className="p-4 hidden ml:flex">
								{item.email}
							</Typography>
						</div>
					);
				},
				type: "component",
				label: "Email",
				grid: { headerTitle: true },
			},
			{
				builder: (item) => {
					let labels = "";
					item?.roles?.forEach((role) => {
						let found = type_user.find((item) => item.value === role);
						let label = found?.label ? found.label : "Role " + role;
						if (labels) labels += ", ";
						labels += label;
					});
					return labels;
				},
				type: "text",
				label: "Roles",
			},
			{
				builder: (item) =>
					item?.type === "Darwin" ? "Darwin (IOS)" : item?.type === "Firebase" ? "Firebase (Android)" : item?.type,
				type: "text",
				label: "Type",
			},
			{ key: "type", type: "text", label: "Type" },
			{ key: "createdAt", type: "date", label: "Created" },
			{ key: "updatedAt", type: "date", label: "Updated" },
			{ key: "userAgent", type: "text", label: "User Agent", collapsibleLength: 18 },
			{ key: "firebaseToken", type: "text", label: "Token", collapsibleLength: 10 },
			{ builder: (item) => createAlertView(item), label: "Status" },
			{ key: "sandbox", type: "bool", label: "Sandbox" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "create",
						label: "Push Notification Action",
						dialogBuilder: (item, dataIds) => ({
							formId: "ADMIN_NOTIFICATION_DEVICE",
							mode: "CREATE",
							dataIds: { userId: item.user_id, deviceId: item._id },
						}),
					},
					{
						key: "delete",
						label: "Delete",
						dialogBuilder: (item, dataIds) => ({
							formId: "ADMIN_NOTIFICATION_DEVICE",
							mode: "DELETE",
							dataIds: { userId: item.user_id, deviceId: item._id },
						}),
					},
				],
			},
		],
	},
};

export default devices;
