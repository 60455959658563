import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { capitalizeFirstLetter } from "app/main/utils/tableUtils";

const buildTooltip = (enabled, icon, description, classes) => {
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
			title={<Typography className={"text-13 text-black py-10 px-10"}>{description}</Typography>}
		>
			<Icon className={"text-20 min-w-28 " + (classes?.icon ?? "") + (enabled ? "text-primary" : "text-grey")}>
				{icon}
			</Icon>
		</Tooltip>
	);
};

const trailers = {
	urlGET: "api/profile/carriers/:carrierId/trailers",
	idKey: "_id",
	listenEvent: ["trailerRevision", "profileRevision"],
	segmentKey: "CARRIER_TRAILERS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
			layout: [["trailer"], ["max_weight", "length"], ["equipment", "access"]],
		},
		order: {
			defaut: {
				key: "_id",
				dir: "desc",
			},
		},
		table: {
			variant: "skinny",
			layout: ["table_avatar", "trailerid", "max_weight", "length", "doors", "equipment", "access"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		defaults: {
			initial: {
				filters: {
					trailer_active: "ACTIVE",
				},
			},
			items: [
				{
					label: "Active",
					description: "Active",
					filters: {
						trailer_active: "ACTIVE",
					},
				},
				{
					label: "Archived",
					description: "Archived",
					filters: {
						trailer_active: "INACTIVE",
					},
				},
			],
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			table: { addToMenu: true },
			auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
			dialogBuilder: (item, dataIds) => ({
				viewId: "TRAILER_VIEW",
				mode: "EDIT",
				dataIds: { carrierId: dataIds.carrierId, id: item._id, status: "active" },
			}),
		},
		filters: {
			search: true,
			countVisible: 0,
			items: [
				{
					key: "trailer_active",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							label: "Active",
							value: "ACTIVE",
						},
						{
							label: "Archived",
							value: "INACTIVE",
						},
					],
				},
			],
		},
		items: [
			{
				key: "table_avatar",
				type: "component",
				table: { width: "30px" },
				builder: (item) => {
					return (
						<Avatar src={null} alt={""} className={"bg-white border-1 border-grey-400"}>
							<Icon className="text-20 text-primary">rv_hookup</Icon>
						</Avatar>
					);
				},
			},
			{
				key: "trailerid",
				type: "text",
				label: "Trailer",
				table: { width: "12%" },
				grid: { headerSubtitle: true, valuePefix: "ID " },
				builder: (item) => "#" + item.trailerid,
			},
			{
				key: "length",
				type: "text",
				label: "Length",
				table: { width: "10%" },
				builder: (item) => (item.trailer ? item.trailer + "'" : null),
			},
			{
				key: "max_weight",
				type: "text",
				label: "Max Weight",
				table: { width: "12%" },
				builder: (item) => (item.max_weight ? item.max_weight + " lb" : null),
			},
			{
				key: "doors",
				type: "text",
				label: "Doors",
				table: { width: "40%" },
				builder: (item) =>
					item.doors === "SWING_DOORS" ? "Swing doors" : item.doors === "ROLL_UP_DOORS" ? "Roll up doors" : item.doors,
			},
			{
				key: "equipment",
				type: "text",
				label: "Equipment",
				table: { align: "right", width: "20%" },
				builder: (item) =>
					item.equipment === "PO"
						? "Power Only"
						: item.equipment === "SPECIAL"
						? item.subtype
						: capitalizeFirstLetter(item.equipment),
			},
			{
				key: "access",
				type: "component",
				label: "Status",
				table: { width: "8%" },
				builder: (item) => {
					return (
						<div className={"flex flex-row"} onClick={(e) => e.stopPropagation()}>
							{buildTooltip(
								item.active,
								item.active ? "luggage" : "no_luggage",
								item.active ? "This trailer is active" : "This trailer has been archived",
								{ icon: " ml-2 " }
							)}
							{buildTooltip(
								item.trucks?.length > 0,
								item.trucks?.length > 0 ? "directions_bus" : "no_transfer",
								item.trucks?.length > 0
									? `This trailer is assigned to ${item.trucks?.length} truck(s)`
									: "This trailer is not assigned",
								{ icon: " ml-2 " }
							)}
						</div>
					);
				},
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "divider",
						variant: "divider",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
					},
					{
						key: "activate",
						label: "Activate",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
						dialogBuilder: (item, dataIds) => ({
							formId: "TRAILER_FORM",
							mode: "DELETE",
							dataIds: { carrierId: dataIds.carrierId, id: item._id, status: "active" },
						}),
						isVisible: (item) => !item.active,
					},
					{
						key: "inactivate",
						label: "Archive",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
						dialogBuilder: (item, dataIds) => ({
							formId: "TRAILER_FORM",
							mode: "DELETE",
							dataIds: { carrierId: dataIds.carrierId, id: item._id, status: "inactive" },
						}),
						isVisible: (item) => item.active,
					},
				],
			},
		],
	},
};

export default trailers;
