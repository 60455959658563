import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

function TooltipClickAwayView(props) {
	const [open, setOpen] = React.useState(false);

	const TooltipCustom = withStyles(() => ({
		tooltip: {
			backgroundColor: props?.custom?.color,
		},
	}))(Tooltip);

	const TooltipStyle = props?.custom ? TooltipCustom : Tooltip;

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = (event) => {
		event.stopPropagation();
		setOpen(true);
	};

	let title = props.title;
	if (title && (typeof title === "string" || title instanceof String)) {
		title = (
			<Typography color="inherit" variant="body2" className="p-3">
				{title}
			</Typography>
		);
	}

	return (
		<ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleTooltipClose}>
			<div>
				<TooltipStyle
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					classes={props.classes}
					title={title}
					interactive
				>
					<div onClick={handleTooltipOpen} className="cursor-pointer">
						{props.children}
					</div>
				</TooltipStyle>
			</div>
		</ClickAwayListener>
	);
}

export default TooltipClickAwayView;
