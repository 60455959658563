import {
	LATEST_STRATEGY,
	SAVE_STRATEGY,
	TEMP_ID_STRATEGY,
	SAVED_ID_STRATEGY,
	REQUEST_STRATEGY,
	REQUEST_LOCK,
	REQUEST_UNLOCK,
} from "../constants";

export const saveStrategy = (payload) => ({
	type: SAVE_STRATEGY,
	payload,
});

export const latestStrategy = (payload) => ({
	type: LATEST_STRATEGY,
	payload,
});

export const getTempStrategy = (payload) => ({
	type: TEMP_ID_STRATEGY,
	payload,
});

export const getSavedStrategy = (payload) => ({
	type: SAVED_ID_STRATEGY,
	payload,
});

export const requestStrategy = (payload) => ({
	type: REQUEST_STRATEGY,
	payload,
});

export const requestLock = () => ({
	type: REQUEST_LOCK,
});

export const requestUnlock = () => ({
	type: REQUEST_UNLOCK,
});
