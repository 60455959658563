/* eslint import/no-extraneous-dependencies: off*/
import { createSlice } from "@reduxjs/toolkit";
import history from "@history";
import _ from "@lodash";
import { setInitialSettings, setDefaultSettings } from "app/store/fuse/settingsSlice";
import { setSubAccount, setCurrentAccount } from "app/store/auth/accountSlice";
import * as featureService from "app/services/featureService";
import { cleanData } from "app/services/featureStorageService";
import { clearUserData, getAuthToken } from "app/services/LoginService";

export const setUserData =
	(data, options = {}) =>
	async (dispatch, getState) => {
		history.location.state = {
			redirectUrl: data.redirectUrl, // for example '/search_loads'
		};
		dispatch(setUser(data));
		if (!options.skip_settings_set) {
			dispatch(setDefaultSettings(data.settings));
		}
		if (data.subAccountContext) {
			dispatch(setSubAccount(data.subAccountContext));
		}
		if (data.currentAccountContext) {
			dispatch(setCurrentAccount({ context: data.currentAccountContext, main: data.mainAccount, tier: data.userTier }));
		}
		if (data.features) {
			cleanData();
			featureService.set(data.features);
		}
	};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));
	return dispatch(setUserData(user));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;
	if (!user.rolesLabels || user.rolesLabels.length === 0) {
		return null;
	}
	history.push({
		pathname: "/",
	});

	clearUserData();
	dispatch(setInitialSettings());
	return dispatch(userLoggedOut());
};

export const forceLogoutUser = () => async (dispatch, getState) => {
	history.push({
		pathname: "/",
	});

	clearUserData();
	dispatch(setInitialSettings());
	return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
	if (!user.rolesLabels || user.rolesLabels.length === 0) {
		// is guest
		return;
	}
	// call UserServices to unpdate user data
};

const initialState = {
	rolesLabels: [],
};

const userSlice = createSlice({
	name: "auth/user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			return { ...action.payload, token: getAuthToken() };
		},
		setUserEmailVerified: (state, action) => {
			state.email_confirmed = action.payload;
			return state;
		},
		setUserPhoneVerified: (state, action) => {
			state.phone_confirmed = action.payload;
			return state;
		},
		setUserPerformanceFlag: (state, action) => {
			state.onboardingStatusPefrormance = action.payload;
			return state;
		},
		setUserInvoiceFlag: (state, action) => {
			if (!state.enterpriseFeatures) state.enterpriseFeatures = {};
			state.enterpriseFeatures.invoicingModuleOnboarding = action.payload;
			return state;
		},
		userLoggedOut: (state, action) => initialState,
	},
	extraReducers: {},
});

export const {
	setUser,
	setUserEmailVerified,
	setUserPhoneVerified,
	userLoggedOut,
	setUserPerformanceFlag,
	setUserInvoiceFlag,
} = userSlice.actions;

export default userSlice.reducer;
