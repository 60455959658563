import CellFirstRow from "./CellFirstRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { formatCurrency } from "app/main/utils/tableUtils";

function CurrencyCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const number = parseFloat(props.view);
	const value = formatCurrency(props.view, props.valueConfig?.decimals, props.valueConfig?.locale);
	const config = props.config;

	if (isNaN(props.view) || props.view === null) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const color =
		number < 0 ? config?.colorNegative : number === 0 ? config?.colorZero : number > 0 ? config?.colorPositive : null;

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} label={value} variant={variant} color={color} />}
		/>
	);
}

export default CurrencyCellView;
