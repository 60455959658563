import { Typography, Card } from "antd";
import { useMemo } from "react";

const sliderConfig = (min, max, low = null, high = null, prefix = "$", postfix = "", step = 1) => {
	return {
		type: "input",
		prefix,
		postfix,
		step,
		min,
		max,
		classes: {},
		variant: undefined,
		noErrorMessage: true,
	};
};

const getAvgRPM = (equipment) => {
	const DEFAULT_AVG_RPMS = {
		VAN: 2.2,
		REEFER: 2.5,
		PO: 2.2,
		FLATBED: 2.77,
	};

	const cached = localStorage.getItem("AVG_RPM");
	const avgRPM = cached ? JSON.parse(cached) : DEFAULT_AVG_RPMS;

	return avgRPM[equipment];
};

const equipmentNames = {
	VAN: "Van",
	REEFER: "Reefer",
	PO: "Power Only",
	FLATBED: "Flatbed",
};

const getMetaFields = (equipment = "VAN", forOnboarding = true) => {
	const truckPaymentDefaults = {
		VAN: 700,
		REEFER: 1550,
		PO: 0,
		FLATBED: 700,
	};

	return {
		truckPayment: {
			key: "truckPayment",
			type: "slider",
			label: `What is the ${forOnboarding ? "average" : "truck"} monthly payment${forOnboarding ? " per truck" : ""}?`,
			required: true,
			defaultValue: 2000,
			slider: sliderConfig(0, 6500, null, null, "$", "/Month", 25),
		},
		trailerPayment: {
			key: "trailerPayment",
			type: "slider",
			label: `What is the ${forOnboarding ? "average" : "Trailer"} monthly payment${
				forOnboarding ? ` per ${equipment} Trailer` : ""
			}?`,
			required: true,
			defaultValue: truckPaymentDefaults[equipment],
			slider: sliderConfig(0, 2550, null, null, "$", "/Month", 25),
		},
		liabilityCargoPolicy: {
			key: "liabilityCargoPolicy",
			type: "slider",
			label: `What is your yearly Liability & Cargo Policy premium${forOnboarding ? " per truck" : ""}?`,
			required: true,
			defaultValue: 16000,
			slider: sliderConfig(5000, 30000, 12000, 25000, "$", "/Year", 10),
		},
		physicalDamagePolicy: {
			key: "physicalDamagePolicy",
			type: "slider",
			label: `What is your ${forOnboarding ? "average" : ""} truck + trailer physical damage monthly payment?`,
			required: true,
			defaultValue: 750,
			slider: sliderConfig(0, 1400, null, null, "$", "/Month", 10),
		},
		parking: {
			key: "parking",
			type: "slider",
			label: `What is your monthly parking expense?`,
			required: true,
			defaultValue: 250,
			slider: sliderConfig(0, 500, null, null, "$", "/Month", 10),
		},
		eld: {
			key: "eld",
			type: "slider",
			label: `What is your ELD Provider monthly charges${forOnboarding ? " per truck" : ""}?`,
			required: true,
			defaultValue: 40,
			slider: sliderConfig(10, 300, null, null, "$", "/Month", 10),
		},
		misc: {
			key: "misc",
			type: "slider",
			label: "How much do you spend monthly on Truck washes, trailer washouts, Misc stuff like chains, fluids, scales?",
			required: true,
			defaultValue: 500,
			slider: sliderConfig(100, 900, null, null, "$", "/Month", 10),
		},
		other: {
			key: "other",
			type: "slider",
			label: "Any other monthly expenses to include",
			required: false,
			defaultValue: 0,
			slider: sliderConfig(0, 2000, null, null, "$", "/Month", 10),
		},
		avgMilesPerDay: {
			key: "avgMilesPerDay",
			type: "slider",
			label: "How many miles per day do you average?",
			required: true,
			defaultValue: 550,
			slider: sliderConfig(100, 800, null, null, "", " Miles/Day", 10),
		},
		period: {
			key: "period",
			type: "slider",
			label: "How many driving days per month?",
			required: true,
			defaultValue: 23,
			slider: sliderConfig(1, 30, null, null, "", " Days/Month"),
		},
		tires: {
			key: "tires",
			type: "slider",
			label: "On average, how much do you spend on tires yearly?",
			required: true,
			defaultValue: 6000,
			slider: sliderConfig(1000, 7000, null, null, "$", "/Year", 25),
		},
		maintenance: {
			key: "maintenance",
			type: "slider",
			label: "How much on average do you spend on truck and trailer maintenance (PM) monthly?",
			required: true,
			defaultValue: 600,
			slider: sliderConfig(200, 1200, null, null, "$", "/Month", 10),
		},
		fees: {
			key: "fees",
			label: "If you have a Factoring service, what is your current factoring fee?",
			type: "slider",
			slider: sliderConfig(0, 3, null, null, "", "%", 0.1),
			defaultValue: 2,
		},
		otherFees: {
			key: "otherFees",
			label: "Any Other fees taken as a percentage of GMV?",
			type: "slider",
			slider: sliderConfig(0, 100, null, null, "", "%"),
			defaultValue: 0,
		},
		mpg: {
			key: "mpg",
			label: "What is the average miles per gallon for your truck?",
			type: "slider",
			slider: sliderConfig(3, 11, null, null, "", " Miles/Gallon", 0.5),
			defaultValue: 5,
		},
	};
};

const getMetaIntField = (data, field, equipment) => {
	const metaFields = getMetaFields(equipment);
	const value = !isNaN(data[field]) ? data[field] : metaFields[field].defaultValue;
	return parseInt(value);
};

const getMetaFloatField = (data, field, equipment) => {
	const metaFields = getMetaFields(equipment);
	const value = !isNaN(data[field]) ? data[field] : metaFields[field].defaultValue;
	return parseFloat(value);
};

const isFieldFilledOut = (val) => {
	return val !== null && val !== "" && val !== undefined && !isNaN(val);
};

const calculateFixedCost = (d, equipment) => {
	return (
		Math.round(
			parseFloat(
				parseFloat(
					getMetaIntField(d, "truckPayment", equipment) +
						getMetaIntField(d, "trailerPayment", equipment) +
						getMetaIntField(d, "liabilityCargoPolicy", equipment) / 12 +
						getMetaIntField(d, "physicalDamagePolicy", equipment) +
						getMetaIntField(d, "parking", equipment) +
						getMetaIntField(d, "eld", equipment) +
						getMetaIntField(d, "misc", equipment) +
						getMetaIntField(d, "other", equipment)
				)
			) / 10
		) * 10
	);
};

const calculateCostPerMile = (d, equipment) => {
	const milesPerMonth = getMetaIntField(d, "avgMilesPerDay", equipment) * getMetaIntField(d, "period", equipment);
	const milesPerYear = milesPerMonth * 12;
	const tireCostPerMile = getMetaIntField(d, "tires", equipment) / milesPerYear;
	const maintenanceCostPerMile = (getMetaIntField(d, "maintenance", equipment) * 12) / milesPerYear;
	//const fuelCostPerMile = AVG_FUEL_COST / getMetaIntField(d, "mpg", equipment);
	let total = tireCostPerMile + maintenanceCostPerMile;
	if (equipment === "REEFER") {
		total += 0.04;
	}
	return parseFloat(parseFloat(total).toFixed(4));
};

const calculateGMVs = (d, equipment) => {
	return getMetaFloatField(d, "fees", equipment) + getMetaFloatField(d, "otherFees", equipment);
};

const calculatedValues = (stepData) =>
	!stepData?.stepKnowValues?.knowValues || stepData?.stepKnowValues?.knowValues === "NO";

const getFinalValues = (stepData) => {
	if (stepData?.stepHasEquipment?.equipment === "NO") {
		return false;
	}

	const calulated = calculatedValues(stepData);
	const fixedMonthlyCosts = calulated
		? stepData?.stepFixedCostHelper?.fixedMonthlyCosts
		: stepData?.stepEnterValues?.fixedMonthlyCosts;
	const costPerMile = calulated ? stepData?.stepCostPerMileHelper?.costPerMile : stepData?.stepEnterValues?.costPerMile;
	const period = calulated ? stepData?.stepCostPerMileHelper?.period : stepData?.stepEnterValues?.period;
	const percentageCost = stepData?.stepGMV?.gmvs;
	const avgMilesPerDay = calulated
		? stepData?.stepCostPerMileHelper?.avgMilesPerDay
		: stepData?.stepEnterValues?.avgMilesPerDay;

	const revenue_month = stepData?.stepGoal?.goalRevenue;
	const rpm_month = stepData?.stepGoal?.goalRPM;

	const meta = {
		fixedHelper: stepData?.stepFixedCostHelper,
		perMileHelper: stepData?.stepCostPerMileHelper,
		gmvHelper: stepData?.stepGMV,
	};

	return {
		costStructure: {
			useHelper: true,
			fixedMonthlyCosts,
			costPerMile,
			period,
			percentageCost,
			avgMilesPerDay,
			meta,
		},
		goal: {
			revenue_month,
			rpm_month,
		},
	};
};

const LabelValue = ({ label, value }) => {
	return (
		<Card className="flex flex-col text-center justify-center items-center">
			<Typography className="text-black-800 m-4 text-center px-12 font-semibold text-lg">{label}</Typography>
			<Typography>{value}</Typography>
		</Card>
	);
};

function FormattedValue({ value, fieldType, decimals, normalColor = "text-black-600", suffix = "" }) {
	if (value === undefined) {
		return <span className="text-orange-600">Pending</span>;
	}

	let v = value;
	if (fieldType === "currency") {
		v = value?.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
		});
	} else if (fieldType === "percent") {
		v = `${value}%`;
	} else if (fieldType === "miles") {
		v = `${value} mi`;
	}

	v = `${v}${suffix}`;

	return <span className={` ${normalColor} `}>{v}</span>;
}

function KeyValues({ label, value1, value2, valueAddon }) {
	return (
		<div className="flex flex-row w-full mb-4">
			<div className="flex mr-auto pr-36">{label}</div>
			<div className="flex font-bold tracking-wide">
				<div className="flex flex-row space-x-8">
					<Typography className="text-13">{value1}</Typography>
					<Typography className="text-12 mt-1 opacity-30">|</Typography>
					<Typography className="text-13">{value2}</Typography>
					{valueAddon}
				</div>
			</div>
		</div>
	);
}

function KeyValue({ label, value, fieldType, decimals, normalColor = "text-black-600", valueAddon = null }) {
	const valToUse = useMemo(() => {
		if (value === undefined) {
			return <span className="text-orange-600">Pending</span>;
		}

		let v = value;
		if (fieldType === "currency") {
			v = value?.toLocaleString("en-US", {
				style: "currency",
				currency: "USD",
				minimumFractionDigits: decimals,
			});
		} else if (fieldType === "percent") {
			v = `${value}%`;
		} else if (fieldType === "miles") {
			v = `${value} mi`;
		}

		return <span className={` ${normalColor} `}>{v}</span>;
	}, [value, fieldType, decimals, normalColor]);

	return (
		<div className="flex flex-row w-full mb-4">
			<div className="flex mr-auto pr-36">{label}</div>
			<div className="flex flex-row space-x-4 font-bold tracking-wide">
				{valToUse}
				{valueAddon}
			</div>
		</div>
	);
}

export {
	KeyValues,
	KeyValue,
	FormattedValue,
	calculatedValues,
	getFinalValues,
	sliderConfig,
	getMetaFields,
	isFieldFilledOut,
	getMetaIntField,
	getMetaFloatField,
	calculateFixedCost,
	calculateCostPerMile,
	calculateGMVs,
	LabelValue,
	getAvgRPM,
	equipmentNames,
};
