import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getHistory = (entityId, status) => {
	return createRequest()
		.get(`api/history/entity/${entityId}/status/${status}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getDetailsHistory = (entityId, type, id) => {
	return createRequest()
		.get(`/api/history/entity/${entityId}/type/${type}/details/${id}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
