import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

import { ReactComponent as Image } from "./smartHopWalletLandingImage.svg";
import { TITLE, DESCRIPTION, APPLY_BUTTON_TEXT, TEXT_FONT_SIZE, TITLE_FONT_SIZE } from "./smarthopWalletLandingUtils";

const useStyles = makeStyles((theme) => ({
	header: {
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		textAlign: "left",
	},
}));

const handleClick = (handleApplyForUnit, applicationData) => {
	if (applicationData.status === "APPLICATION_PRISTINE") {
		window.location.assign(applicationData.data?.applications[0].data.attributes.url);
	} else {
		handleApplyForUnit();
	}
};

const title = (
	<Typography className={TITLE_FONT_SIZE + " font-semibold tracking-tight mb-32 w-full"} variant="h4">
		{TITLE}
	</Typography>
);

const description = (
	<Typography
		variant="body2"
		color="textPrimary"
		className={TEXT_FONT_SIZE + " font-light mb-32 w-10/12 mx-auto md:mx-0"}
	>
		{DESCRIPTION}
	</Typography>
);

const button = (handleApplyForUnit, applicationData) => (
	<Button
		key="unit-application"
		color="secondary"
		variant="contained"
		className={TEXT_FONT_SIZE + " mx-auto md:mx-0 w-max"}
		onClick={() => handleClick(handleApplyForUnit, applicationData)}
	>
		{APPLY_BUTTON_TEXT}
	</Button>
);

const SmartHopWalletLandingHeader = ({ handleApplyForUnit, applicationData }) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.header, " flex items-center flex-col md:flex-row mb-28 px-28")}>
			<div className="w-full md:w-1/2 flex flex-col h-fit md:h-224">
				{title}
				{description}
				{button(handleApplyForUnit, applicationData)}
			</div>
			<div className="w-1/2 justify-center hidden md:flex">
				<Image />
			</div>
		</div>
	);
};

export default SmartHopWalletLandingHeader;
