import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { global } from "app/services/requestUtil";

export const fetchTransactions = createAsyncThunk("transactions/list/fetch", async ({ userId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	const response = await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.get(`api/transaction/users/${userId}/records/pannel`, {
			headers: headers,
		})
		.catch(function (error) {
			console.log(error);
		});

	let unreadByType = {};
	response?.data?.result?.oldTransactions?.forEach((item) => {
		if (item?.unread) {
			if (!unreadByType[item.type]) unreadByType[item.type] = {};
			unreadByType[item.type].count = (unreadByType[item.type]?.count ?? 0) + 1;
			if (!unreadByType[item.type].latestTransaction) {
				unreadByType[item.type].latestTransaction = item;
			}
		}
	});

	let latestActiveId = response?.data?.result?.activeTransactions?.[0]?._id;
	let latestFailedId;
	if (response?.data?.result?.oldTransactions?.[0]?.status === "FAILED") {
		latestFailedId = response?.data?.result?.oldTransactions?.[0]?._id;
	}

	return {
		transactions: response?.data?.result ?? [],
		activeTransactions: response?.data?.activeTransactions ?? 0,
		unreadTotalTransactions: response?.data?.unreadTotalTransactions ?? 0,
		unreadFailedTransactions: response?.data?.unreadFailedTransactions ?? 0,
		notificationTransactions: response?.data?.notificationTransactions ?? [],
		requiredActionTransactions: response?.data?.requiredActionTransactions ?? [],
		unreadByType,
		latestActiveId,
		latestFailedId,
	};
});

export const markTransactionsAsRead = createAsyncThunk("transactions/list/counters/markAsRead", async ({ userId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};
	await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.put(`api/transaction/users/${userId}/records/pannel/counters/markAsRead`, {
			headers: headers,
		})
		.catch(function (error) {
			console.log(error);
		});

	return {};
});

export const markTransactionAsRead = createAsyncThunk("transactions//markAsRead", async ({ transactionId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};
	await axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.put(`api/transaction/records/${transactionId}/markAsRead`, {
			headers: headers,
		})
		.catch(function (error) {
			console.log(error);
		});

	return {};
});

const transactionsSlice = createSlice({
	name: "transactions/list",
	initialState: {
		activeTransactions: 0,
		unreadTotalTransactions: 0,
		unreadFailedTransactions: 0,
		notificationTransactions: [],
		requiredActionTransactions: [],
		unreadByType: {},
		latestActiveId: null,
		latestFailedId: null,
		initialized: false,
	},
	reducers: {
		increaseNotification: (state) => {
			state.activeTransactions++;
		},
	},
	extraReducers: {
		[markTransactionsAsRead.fulfilled]: (state) => {
			state.unreadTotalTransactions = 0;
			state.unreadFailedTransactions = 0;
		},
		[fetchTransactions.fulfilled]: (state, action) => {
			state.transactions = action?.payload?.transactions;
			state.activeTransactions = action?.payload?.activeTransactions;
			state.unreadTotalTransactions = action?.payload?.unreadTotalTransactions;
			state.unreadFailedTransactions = action?.payload?.unreadFailedTransactions;
			state.latestActiveId = action?.payload?.latestActiveId;
			state.latestFailedId = action?.payload?.latestFailedId;
			state.unreadByType = action?.payload?.unreadByType;
			state.notificationTransactions = action?.payload?.notificationTransactions;
			state.requiredActionTransactions = action?.payload?.requiredActionTransactions;
			state.errors = null;
			state.loading = false;
			state.initialized = true;
		},
		[fetchTransactions.rejected]: (state) => {
			state.loading = false;
			state.errors = [{ type: "generic", message: "Oops, failed to load transactions..." }];
		},
	},
});
export const { increaseNotification } = transactionsSlice.actions;
export default transactionsSlice.reducer;
