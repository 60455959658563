import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useEffect, useMemo, useState, useImperativeHandle, forwardRef, useCallback, useRef } from "react";
import moment from "moment";
import {
	getCurrentTruckPlan,
	executePlanAction,
	getTruckPlan,
	getTruckPlanSearchHistory,
} from "app/services/truckServices";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";
import { useSnackbar } from "notistack";
import { fetchChats } from "app/store/messenger/chatSlice";
import { getUserId, isRoleExternal } from "app/services/LoginService";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import {
	openLoadedFormDialog,
	openFormDialog,
	refreshDialog,
	replaceFormDialog,
	closeAllDialogs,
} from "app/store/tools/formDialogSlice";
import LoadStrategy from "../search/load/LoadStrategy";
import { Button, Typography, Link } from "@material-ui/core";
import { DisplaySection } from "app/main/utils/uiUtils";
import MarketAnalysisWidget from "app/main/widgets/custom/MarketAnalysisWidget";
import TruckPlanViewActions from "./TruckPlanViewActions";
import TruckPlanPosting from "./TruckPlanPosting";
import { createMciIconView } from "app/main/utils/brokerUtils";
import { formatBookDate } from "app/main/utils/dateUtils";
import { convertPlanAndTruckToSearch, saveSearchesLocalStorage } from "../searchV3/utils/searchUtils";
import { getPostings } from "app/services/postTruckServices";
import LoadSearchSummaryCarrierView from "../search/result/LoadSearchSummaryCarrierView";

import { SmarthopConfirmDialog } from "@smarthop/form";
import TruckPlanSearchChart from "./TruckPlanSearchChart";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";

// Segment
import { CALENDAR_EVENT } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";
import { toStateViews } from "../utils/stateUtils";

const getTruckPlanFields = (data) => {
	const truck = data.plan;
	let date = truck?.current_available_date
		? moment.utc(truck?.current_available_date).format("YYYY-MM-DD")
		: moment.utc().format("YYYY-MM-DD");
	let currentAvailableDate = moment(date).format("YYYY-MM-DD");
	currentAvailableDate = moment().diff(currentAvailableDate) > 0 ? moment() : currentAvailableDate;
	let updates = {
		pickup_ds__after: currentAvailableDate.valueOf(), //truck.current_available_date,
	};

	if (truck.current_location) {
		updates["location_origin"] = truck.current_location;
		updates["location_origin__view"] = {
			value: truck.current_location,
			label: truck.current_location,
		};
	}

	if (truck.current_destination) {
		updates["location_destination"] = truck.current_destination;
		updates["location_destination__view"] = {
			value: truck.current_destination,
			label: truck.current_destination,
		};
	} else if (truck.destinationStates?.length > 0) {
		updates["destinationStates"] = truck.destinationStates;
		updates["destinationStates__view"] = toStateViews(truck.destinationStates);
	}

	updates["rate_per_mile_preferred"] = truck?.rate_per_mile_preferred;
	updates["miles_preferred__max"] = truck?.miles_preferred__max;
	updates["miles_preferred__min"] = truck?.miles_preferred__min;
	updates["rate_total_preferred"] = truck?.rate_total_preferred;
	updates["extra_stops_preferred"] = truck?.extra_stops_preferred;
	updates["dh_origin"] = truck?.dh_origin_preferred;
	updates["profit_preferred"] = truck?.profit_preferred;
	updates["min_rank_mci"] = truck?.min_rank_mci;
	updates["age_mins_max_preferred"] = truck?.age_mins_max_preferred;
	updates["min_credit_score"] = truck?.min_credit_score;
	updates["carrier"] = data?.carrier._id;
	updates["truck"] = data?.truck._id;
	updates["carrier__view"] = {
		label: data?.carrier.carrier_company_name,
		value: data?.carrier._id,
	};
	updates["equipment"] = data?.trailer?.equipment ?? "PO";
	updates["truck__view"] = {
		label: `${data?.truck?.equipment} - ${data?.truck?.driver__view?.label ?? "No Driver"}`,
		value: data?.truck._id,
		metadata: {
			truck: {
				driver: data?.carrier?.carrier_drivers?._id,
				driver__view: { label: data?.carrier?.carrier_drivers?.name, value: data?.carrier?.carrier_drivers?._id },
				equipment: data?.trailer?.equipment ?? "PO",
				trailer: data?.trailer,
			},
		},
	};
	return updates;
};

const TruckPlanView = forwardRef(({ nativeMobile, dataIds, onDone, onProgress, onLoading, setTitle, setSize }, ref) => {
	useEffect(() => {
		setTitle?.("Truck Plan");
		setSize?.("max-w-4xl");
		if (dataIds?.fromCalendar) {
			createTrackOrPage(CALENDAR_EVENT, { type: "truck-plan" }, "track");
		}
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const history = useHistory();

	const [data, setData] = useState(null);
	const [searchHistory, setSearchHistory] = useState(false);
	const [postings, setPostings] = useState(null);
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [locationStrategy, setLocationStrategy] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState({ message: null, flag: false, typeAction: null });

	const viewerIsOwner = isRoleExternal();
	const planStatus = dataIds?.status;
	const [error, setError] = useState(null);
	const dialogRef = useRef(null);

	const profileRevision = useSelector(({ tools }) => tools.revision?.profileRevision);

	const user = useSelector(({ auth }) => auth.user);
	const roles = user?.rolesLabels;

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const hasSearchPermission = roleRestrictions.permission_search_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_search_on: true })
		: true;
	const hasEditTripsPermission = roleRestrictions.permission_trips_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_trips_access: "editor" })
		: true;
	const hasRatesOnPermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;

	const isLatestPlan = useMemo(() => {
		return data && data?.truck?.truck_plan_id === data?.plan?._id;
	}, [data]);

	// override setting for viewPlanByIdMode
	const [planIdViewMode, setPlanIdViewMode] = useState(!!dataIds?.planId);
	// A plan id was passed in to view a specific plan and the plan exists
	const viewPlanByIdMode = useMemo(
		() => planIdViewMode && !!dataIds?.planId && !!data?.plan?._id,
		[dataIds, data, planIdViewMode]
	);

	// if the latest plan ID changed assume we just created a new plan and disable planIdViewMode to view latest plan
	const latestPlanIDCache = useRef(null);
	const latestPlanID = useMemo(() => data?.truck?.truck_plan_id, [data]);
	useEffect(() => {
		if (latestPlanID !== latestPlanIDCache.current) {
			if (latestPlanIDCache.current) {
				setPlanIdViewMode(false);
			}
			latestPlanIDCache.current = latestPlanID;
		}
	}, [latestPlanID]);

	// no plan found or its done
	const retiredPlan = useMemo(
		() => !!data?.plan?.cancelled || !!data?.plan?.expired || !!data?.plan?.covered || (!!data && !data?.plan?._id),
		[data]
	);

	const showNoPlanView = useMemo(() => {
		return retiredPlan && !viewPlanByIdMode;
	}, [viewPlanByIdMode, retiredPlan]);

	const showLinkToLatestPlan = useMemo(() => {
		return viewPlanByIdMode && !!data?.plan?._id && data?.truck?.truck_plan_id !== data?.plan?._id;
	}, [data, viewPlanByIdMode]);

	const showLinkToNewPlan = useMemo(() => {
		return viewPlanByIdMode && retiredPlan && data && data?.truck?.truck_plan_id === data?.plan?._id;
	}, [viewPlanByIdMode, retiredPlan, data]);

	const planIsActive = useMemo(() => {
		return data?.plan?._id && !data?.plan?.cancelled && !data?.plan?.expired && !data?.plan?.covered;
	}, [data]);

	const showPostTruck = useMemo(
		() => isLatestPlan && planIsActive && !!data?.plan?._id,

		// eslint-disable-next-line
		[isLatestPlan, planStatus, planIsActive, data]
	);

	const showCreateTrip = useMemo(
		() =>
			data &&
			planIsActive &&
			isLatestPlan &&
			dataIds?.carrierId &&
			dataIds?.truckId &&
			hasEditTripsPermission &&
			hasRatesOnPermission,
		[data, dataIds, isLatestPlan, planIsActive, hasEditTripsPermission, hasRatesOnPermission]
	);

	const [smallScreen, setSmallScreen] = useState(dataIds?.nativeMobile || window.innerWidth <= 600);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 50) * 50);

	// handle screen size
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth <= 960 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth > 960 && smallScreen) {
				setSmallScreen(false);
			}
			if (parseInt(window.innerHeight / 50) * 50 !== screenHeight) {
				setScreenHeight(parseInt(window.innerHeight / 50) * 50);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			if (isLatestPlan && planIsActive && !dataIds?.openChat) {
				if (!dataIds?.nativeMobile) {
					btns.chat = {
						onClick: () =>
							dispatch(
								openFormDialog({
									viewId: "CHAT_APP_PREVIEW_TRUCK_EXTERNAL",
									dataIds: { truckId: dataIds?.truckId, carrierId: dataIds?.carrierId },
								})
							),
						icon: "chat_bubble_outline",
						title: "Chat",
					};
				}
			}

			if (isLatestPlan && planIsActive && hasSearchPermission) {
				btns.edit = {
					onClick: () =>
						dispatch(
							openFormDialog({
								viewId: "TRUCK_PLAN",
								mode: "EDIT",
								dataIds: {
									...dataIds,
									historyData: false,
								},
							})
						),
					title: "Edit",
				};
			}

			if (showCreateTrip) {
				btns.createTripRc = {
					onClick: () => {
						dispatch(
							openFormDialog({
								viewId: "MULTI_FILE_UPLOAD_VIEW",
								dataIds: {
									carrierId: dataIds?.carrierId,
								},
							})
						);
					},
					icon: "add",
					title: "Add Trip",
				};
			}

			dispatch(refreshDialog());
			return btns;
		},
		// eslint-disable-next-line
		[showPostTruck, isLatestPlan, dataIds, viewerIsOwner, locationStrategy, dispatch, planIsActive, data]
	);

	useEffect(() => {
		async function getInformation() {
			if (!data) {
				setLoading(true);
				onLoading?.(true);
			}
			try {
				setSearchHistory(false);
				const currentTruckPlan =
					dataIds?.planId && planIdViewMode
						? await getTruckPlan(dataIds?.carrierId, dataIds?.truckId, dataIds?.planId)
						: await getCurrentTruckPlan(dataIds?.carrierId, dataIds?.truckId);

				setData(currentTruckPlan);
				setLocationStrategy(currentTruckPlan?.plan?.current_destination ?? currentTruckPlan?.plan?.current_location);
				setLoading(false);
				onLoading?.(false);

				if (currentTruckPlan?.plan?._id) {
					const history = await getTruckPlanSearchHistory(
						dataIds?.carrierId,
						dataIds?.truckId,
						currentTruckPlan?.plan?._id,
						{ trigger: "MARKET_MONITOR", status: "__FINISHED__" }
					);
					setSearchHistory(history);
				} else {
					setSearchHistory([]);
				}
			} catch (error) {
				setError(error);
				setLoading(false);
				onLoading?.(false);
			}
		}

		async function getPostingData() {
			try {
				let posts = await getPostings(dataIds?.carrierId, dataIds?.truckId);
				posts = posts?.items?.reduce((acc, val) => {
					acc[val.owner] = val;
					return acc;
				}, {});
				setPostings(posts);
			} catch (error) {}
		}

		getPostingData();
		getInformation();
		// eslint-disable-next-line
	}, [dataIds, profileRevision, planIdViewMode]);

	const sendReminder = (actionType) => {
		if (!data) return;
		executePlanAction(dataIds?.carrierId, dataIds?.truckId, data.plan._id, actionType).then(
			(response) => {
				showSnackbar(snackbar, "Reminder sent!", "success");
				dispatch(fetchChats({ userId: getUserId() }));
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				onDone?.();
			},
			(err) => {
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error", {
					persist: true,
					newSnackBar: true,
				});
			}
		);
	};

	const doAction = async (actionType) => {
		setProcessing(true);
		onProgress?.(true);
		const res = await executePlanAction(dataIds?.carrierId, dataIds?.truckId, data.plan._id, actionType);
		setProcessing(false);
		onProgress?.(false);
		return res;
	};

	const onEdit = useCallback(
		async (openSection) => {
			const reactNative = window?.ReactNativeWebView ?? false;
			if (reactNative) {
				window?.ReactNativeWebView?.postMessage(
					JSON.stringify({
						type: "OPEN_DIALOG",
						data: {
							viewId: "TRUCK_PLAN_EDIT",
							mode: "EDIT",
							title: "Edit Plan",
							dataIds: { carrierId: dataIds?.carrierId, truckId: dataIds?.truckId, openSection, nativeMobile: true },
						},
					})
				);
			} else {
				dispatch(
					openFormDialog({
						viewId: "TRUCK_PLAN",
						mode: "EDIT",
						dataIds: {
							carrierId: dataIds?.carrierId,
							truckId: dataIds?.truckId,
							openSection,
						},
					})
				);
			}
		},
		[dataIds, dispatch]
	);

	const onCancel = () => {
		setOpenConfirmDialog({ message: "Delete truck plan?", flag: true, typeAction: "CANCEL" });
	};

	const isInternal = useMemo(
		() => roles.includes("administrator") || roles.includes("ops support") || roles.includes("dispatcher"),
		[roles]
	);

	const routes = useMemo(() => {
		return [
			{
				type: "PLAN",
				routeId: data?.plan?._id,
				color: "#E7683D",
				locations: [
					{
						type: "PICKUP",
						coordinates: data?.plan?.current_location_coords,
						location_date: data?.plan?.current_available_date,
						location: data?.plan?.current_location,
					},
				],
			},
		];
	}, [data]);

	const tags = useMemo(() => {
		const t = [];

		if (data?.plan?.outdated__flag) {
			t.push({
				icon: "warning",
				description: "The available date for this truck is in the past, please update it",
				label: "Outdated Plan",
				style: { badge: "bg-red" },
			});
		} else if (data?.plan?.difficult_market != null) {
			const difficult = data?.plan?.difficult_market;
			t.push({
				icon: "search",
				description: difficult ? "The MPI for the truck’s location is low" : "The MPI for the truck’s location is high",
				label: difficult ? "Difficult Market" : "Good Market",
				style: { badge: difficult ? "bg-red" : "bg-green" },
			});
		}
		return t;
	}, [data]);

	const handledAction = async (actionType) => {
		setOpenConfirmDialog({ message: null, flag: false, typeAction: null });

		if (actionType === "REMINDER") {
			sendReminder(actionType);
		} else {
			const res = await doAction(actionType);
			if (actionType === "CANCEL") {
				setPlanIdViewMode(false);
			}
			setData(res);
		}
	};

	const openLatestPlan = () => {
		dispatch(
			replaceFormDialog({
				viewId: "TRUCK_PLAN_VIEW",
				dataIds: {
					carrierId: dataIds?.carrierId,
					truckId: dataIds?.truckId,
				},
			})
		);
	};

	const openNewPlan = () => {
		const reactNative = window?.ReactNativeWebView ?? false;
		if (reactNative) {
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: "OPEN_DIALOG",
					data: { viewId: "TRUCK_PLAN_VIEW", dataIds: { carrierId: dataIds?.carrierId, truckId: dataIds?.truckId } },
				})
			);
		} else {
			dispatch(
				openFormDialog({
					viewId: "TRUCK_PLAN",
					mode: "EDIT",
					dataIds: {
						carrierId: dataIds?.carrierId,
						truckId: dataIds?.truckId,
					},
				})
			);
		}
	};

	const renderDataItem = (label, value, type, options) => {
		return (
			<div className={"flex " + (options?.vertical ? " flex-col " : " flex-row border-b-1 border-grey-300 ")}>
				<Typography className="flex w-full text-black text-12 break-normal mt-10">{label}</Typography>
				<Typography
					className={
						"text-12 break-normal  " +
						(!options?.vertical ? " mt-10 font-semibold text-primary-700 whitespace-nowrap " : " text-primary-700 ") +
						(!options?.animate ? "" : loading ? " animate-pulse " : "")
					}
				>
					{!value ? "-" : value} {!value ? "" : type}
				</Typography>
			</div>
		);
	};

	if (loading) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={loading} error={error} />;
	}

	// Check for errors
	if (error?.errors?.length > 0) {
		return (
			<div className="flex w-full h-512 flex-col p-10 items-center justify-center">
				<Typography color="error" className="flex pt-10 pb-8 items-center justify-center">
					{error?.errors[0].message}
				</Typography>
			</div>
		);
	}

	// we are not trying to view a specific historical truck plan, just the lastest plan and it's not active
	if (showNoPlanView) {
		return (
			<div className="flex w-full h-512 flex-col p-10 items-center justify-center flex-col">
				<h4>No active truck plan</h4>
				<Button
					variant="contained"
					onClick={() => {
						const reactNative = window?.ReactNativeWebView ?? false;
						if (reactNative) {
							window?.ReactNativeWebView?.postMessage(
								JSON.stringify({
									type: "OPEN_DIALOG",
									data: {
										viewId: "TRUCK_PLAN_VIEW",
										dataIds: {
											carrierId: dataIds?.carrierId,
											truckId: dataIds?.truckId,
										},
									},
								})
							);
						} else {
							dispatch(
								openFormDialog({
									viewId: "TRUCK_PLAN",
									mode: "EDIT",
									dataIds: {
										carrierId: dataIds?.carrierId,
										truckId: dataIds?.truckId,
									},
								})
							);
						}
					}}
				>
					Create Plan
				</Button>
			</div>
		);
	}

	return (
		<>
			{showLinkToLatestPlan && (
				<div className="w-full mt-64 -mb-48 md:mb-10 md:mt-0 flex flex-row bg-orange-100 items-center rounded-lg p-12">
					<Typography className="flex-1 text-12 md:text-14">
						This plan is no longer active and can not be edited
					</Typography>
					<Button variant="contained" color="primary" onClick={openLatestPlan}>
						Open Latest Plan
					</Button>
				</div>
			)}
			{showLinkToNewPlan && (
				<div className="w-full mt-64 -mb-48 md:mb-10 md:mt-0 flex flex-row bg-orange-100 items-center rounded-lg p-12">
					<Typography className="flex-1 text-12 md:text-14">
						This plan is no longer active and can not be edited
					</Typography>
					<Button variant="contained" color="primary" onClick={openNewPlan}>
						Create New Plan
					</Button>
				</div>
			)}
			<LoadStrategy />
			<SmarthopConfirmDialog
				open={!!openConfirmDialog.flag}
				title={openConfirmDialog.message}
				onClose={() => setOpenConfirmDialog({ message: null, flag: false, typeAction: null })}
				onAccept={() => handledAction(openConfirmDialog?.typeAction)}
			/>

			<SmarthopDialogViewContainer
				nativeMobile={nativeMobile}
				processing={processing}
				ref={dialogRef}
				sideComponents={[
					{
						key: "TRIP_MAP_" + data?.plan?._id,
						type: "MAP",
						hideOnMobile: true,
						tabKeys: ["DETAILS", "MARKET", "LOAD_SCOUT", "POSTINGS", "INTERNAL"],
						params: {
							equipment: data?.truck?.equipment,
							activeHeatMap: true,
							routes: routes,
						},
					},
				]}
				headerBadges={tags}
				tabComponents={[
					{
						key: "DETAILS",
						title: "Details",
						component: (
							<>
								<div className="flex w-full flex-col md:flex-row md:space-x-16">
									<div className="flex flex-col w-full">
										<DisplaySection classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }} title="Truck Details">
											<LoadSearchSummaryCarrierView
												carrier={{
													id: data?.carrier?._id,
													name: data?.carrier?.carrier_company_name,
													hidden: user.roleType !== "INTERNAL" && !hasSubaccounts,
												}}
												truck={{
													id: data?.carrier?.carrier_trucks?._id,
													name:
														(data?.carrier?.carrier_trucks?.equipment
															? (data?.carrier?.carrier_trucks?.subtype ?? data?.carrier?.carrier_trucks?.equipment) +
															  " - "
															: "") +
														"#" +
														data?.carrier?.carrier_trucks?.truckid,
												}}
												trailer={{
													id: data?.carrier?.carrier_trailers?._id,
													name: data?.carrier?.carrier_trailers?.trailerid,
												}}
												primaryDriver={{
													id: data?.carrier?.carrier_drivers?._id,
													name: data?.carrier?.carrier_drivers?.name,
												}}
												secondaryDriver={{
													id: data?.carrier?.carrier_drivers_secondary?._id,
													name: data?.carrier?.carrier_drivers_secondary?.name,
												}}
											/>
										</DisplaySection>
										<DisplaySection classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }} title="Notes">
											{renderDataItem("", data?.plan?.notes, "", { vertical: true })}
										</DisplaySection>
									</div>
									<div className="flex flex-col w-full">
										<DisplaySection classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }} title="Plan Details">
											{renderDataItem(
												"Available Date",
												data?.plan?.current_available_date
													? moment(data?.plan?.current_available_date).format("ddd, MMM Do LT")
													: undefined
											)}
											{renderDataItem("Available Location", data?.plan?.current_location)}
											{data?.plan?.destinationStates?.length > 0 &&
												renderDataItem(
													"Desired Destination",
													data?.plan?.destinationStates__view?.map((l) => l.description).join(", ")
												)}
											{!data?.plan?.destinationStates?.length &&
												!!data?.plan?.current_destination &&
												renderDataItem("Desired Destination", data?.plan?.current_destination)}
										</DisplaySection>

										{showPostTruck && (
											<TruckPlanPosting
												showPostTruck={showPostTruck}
												dataIds={dataIds}
												plan={data?.plan}
												postings={postings}
											/>
										)}

										{data?.covered_by_trip && (
											<DisplaySection classes={{ root: "mx-0 mt-0 mb-28" }} title="Trip Details">
												<div className="-mt-10">
													{renderDataItem("Pickup Address", data?.covered_by_trip?.data?.pickup_address)}
													{renderDataItem("Delivery Address", data?.covered_by_trip?.data?.delivery_address)}
													{renderDataItem(
														"Load ID",
														<Link
															color="secondary"
															className="cursor-pointer"
															onClick={() =>
																dispatch(
																	openFormDialog({
																		viewId: "TRIP_VIEW",
																		dataIds: {
																			tripId: data?.covered_by_trip?._id,
																			carrierId: data?.covered_by_trip?.data?.carrier,
																		},
																	})
																)
															}
														>
															# {data?.covered_by_trip?.data?.load_id}
														</Link>
													)}
													{renderDataItem("Broker", data?.covered_by_trip?.data?.broker)}
												</div>
											</DisplaySection>
										)}
										<DisplaySection
											classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }}
											title="Loads Scouted"
											actions={[{ label: "See More", onClick: () => dialogRef.current?.setTab("LOAD_SCOUT") }]}
										>
											<TruckPlanSearchChart data={data} height={200} tickAmount={4} history={searchHistory} />
										</DisplaySection>
									</div>
								</div>
							</>
						),
					},
					{
						key: "MARKET",
						title: "Market",
						hideOnDesktop: !isLatestPlan || !planIsActive,
						hideOnMobile: !isLatestPlan || !planIsActive,
						component: (
							<div className="w-full">
								<DisplaySection
									title="Market Analysis"
									classes={{ root: "mx-0 mt-0 mb-24", divider: "mb-0" }}
									views={[
										<div className="flex flex-row w-full">
											<Typography className={"text-12 mr-4 font-semibold"}>MPI</Typography>
											{createMciIconView((data?.plan?.market_condition?.mci * 100)?.toFixed(2))}
										</div>,
										<div className="flex flex-row ml-6">
											<Typography className={"text-12 mr-6 font-semibold"}>MVI</Typography>
											<Typography className={"text-12 mr-4"}>
												{(data?.plan?.market_condition?.mvi * 100)?.toFixed(2)}
											</Typography>
										</div>,
									]}
								>
									<div className="mb-24">
										{renderDataItem(
											"Best time to book",
											data?.plan?.bestTimeToBook ? formatBookDate(data?.plan?.bestTimeToBook) : undefined
										)}
									</div>
									<MarketAnalysisWidget
										mode="FULL"
										config={{
											height: 300,
											params: {
												carrier: data?.carrier?._id,
												truck: data?.carrier?.carrier_trucks?._id,
												driver: data?.carrier?.carrier_drivers?._id,
												equipment: data?.carrier?.carrier_trucks?.equipment,
												origin: data?.plan?.current_location,
												destination: data?.plan?.current_destination,
												pickupDs: data?.plan?.current_available_date,
											},
										}}
									/>
								</DisplaySection>
							</div>
						),
					},
					{
						key: "INTERNAL",
						title: "Internal",
						hideOnDesktop: !isInternal,
						hideOnMobile: !isInternal,
						component: (
							<div className="flex w-full flex-col">
								{data && (
									<DisplaySection classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }} title="Internal Details">
										{renderDataItem("Expired", data?.plan?.expired ? "Yes" : "No")}
										{renderDataItem("Cancelled", data?.plan?.cancelled ? "Yes" : "No")}
										{renderDataItem("Covered", data?.plan?.covered ? "Yes" : "No")}
										{renderDataItem("Book For Me", data?.plan?.bookForMe ? "Yes" : "No")}
										{renderDataItem("Published", data?.plan?.published ? "Yes" : "No")}
									</DisplaySection>
								)}
							</div>
						),
					},
					{
						key: "LOAD_SCOUT",
						title: "Load Scout",
						component: (
							<div className="w-full">
								<TruckPlanViewActions
									planData={data}
									history={searchHistory}
									onEdit={isLatestPlan && planIsActive && hasSearchPermission ? onEdit : false}
								/>
							</div>
						),
					},
				]}
				footerActions={[
					{
						key: "EDIT_PLAN",
						title: "Edit Plan",
						style: {
							align: "LEFT",
							notCollapsible: data?.plan?.outdated__flag,
							icon: "edit",
						},
						hideOnDesktop: !isLatestPlan || !planIsActive || !hasSearchPermission,
						hideOnMobile: !isLatestPlan || !planIsActive || !hasSearchPermission,
						onClick: () => {
							onEdit();
						},
					},
					{
						key: "DELETE_PLAN",
						title: "Delete Plan",
						style: { align: "LEFT", icon: "delete" },
						hideOnDesktop: !planIsActive || !hasSearchPermission,
						hideOnMobile: !planIsActive || !hasSearchPermission,
						onClick: () => {
							onCancel();
						},
					},
					{
						key: "SEARCH_LOAD",
						title: "Search for Load",
						style: {
							align: "RIGHT",
							primary: true,
							notCollapsible: !data?.plan?.outdated__flag,
							icon: "search",
						},
						hideOnDesktop:
							!isLatestPlan ||
							!data?.plan?.current_location ||
							!data?.plan?.current_available_date ||
							!hasSearchPermission,
						hideOnMobile:
							!isLatestPlan ||
							!data?.plan?.current_location ||
							!data?.plan?.current_available_date ||
							!hasSearchPermission,
						onClick: () => {
							const reactNative = window?.ReactNativeWebView ?? false;
							if (reactNative) {
								window?.ReactNativeWebView?.postMessage(
									JSON.stringify({
										type: "OPEN_SEARCH",
										data: {
											carrierId: dataIds?.carrierId,
											truckId: dataIds?.truckId,
											...getTruckPlanFields(data),
										},
									})
								);
							} else {
								if (localStorage.getItem("SEARCH_VERSION") !== "2") {
									saveSearchesLocalStorage(convertPlanAndTruckToSearch(data?.carrier, data?.truck, data?.plan)).then(
										() => {
											history.push("/search?id=EMPTY");
											dispatch(closeAllDialogs());
										}
									);
								} else {
									dispatch(
										openLoadedFormDialog({
											viewId: "SEARCH_REQUEST_REDIRECT",
											dataIds: {
												carrierId: dataIds?.carrierId,
												truckId: dataIds?.truckId,
												historyData: false,
												eventType: "SEARCH_SIMILAR",
											},
										})
									);
								}
							}
						},
					},
				]}
			></SmarthopDialogViewContainer>
		</>
	);
});

export default TruckPlanView;
