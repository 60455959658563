import { createSlice } from "@reduxjs/toolkit";

const formToolsSlice = createSlice({
	name: "formTools",
	initialState: {
		preventCloseCallback: null,
	},
	reducers: {
		setPreventDialogCloseCallback: (state, action) => {
			state.preventCloseCallback = action.payload;
		},
	},
});

export const { setPreventDialogCloseCallback } = formToolsSlice.actions;

export default formToolsSlice.reducer;
