import { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useDispatch, useSelector } from "react-redux";

// UI
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { Typography, Button, Icon } from "@material-ui/core";
import {
	MAIN_FIELDS,
	TYPE_COMPANY,
	CARRIER_REPRESENTATIVE,
} from "@smarthop/form/configs/carrierCompanyInformationForm";

// Services
import { getCarrierId } from "app/services/LoginService";
import {
	getCarrierInformation,
	getCarrierDOTInformation,
	updateCarrierInformation,
	verifyCarrierDOTValidationCode,
	sendCarrierDOTPhoneCode,
	sendCarrierDOTEmailCode,
	connectAxle,
} from "app/services/carrierServices";

// Storage
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { fetchCredentialsStatus } from "app/store/search/credentialsSlice";

const CompanyInfoConfirmationView = ({ onDone, dataIds, setTitle, setSize }) => {
	useEffect(() => {
		setTitle?.("Company Information");
		setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	// Use default user Id if not provided
	const carrierId = dataIds?.carrierId ?? getCarrierId();
	const validationFlowOnly = dataIds?.state === "VALIDATE";

	// User information
	const user = useSelector(({ auth }) => auth.user);

	// Values
	const [mc, setMC] = useState(null);
	const [dot, setDOT] = useState(null);
	const [carrierData, setCarrierData] = useState(null);
	const [uneditedDotSync, setUneditedDotSync] = useState(false);
	const [validationType, setValidationType] = useState(null);
	const [validationCode, setValidationCode] = useState(null);
	const [validationError, setValidationError] = useState(null);
	const [validationRevision, setValidationRevision] = useState(0);
	const [parentValidated, setParentValidated] = useState(false);
	const [testCarrier, setTestCarrier] = useState(false);
	const [carrierTypeCompany, setCarrierType] = useState(null);
	const [carrierRepresentative, setCarrierRepresentative] = useState(null);
	const [parent, setParent] = useState(null);
	const isDispatcher = carrierTypeCompany === "DISPATCH_SERVICE";

	const manualEditFormFormsRef = useRef(0);

	// Forcefull override of the validation of the email and phone in case
	// if carrier is a test carrier to avoid sending emails to carriers we use for tests
	const overrideEmailValue = testCarrier ? user.email ?? "support@smarthop.co" : null;
	const overridePhoneValue = testCarrier ? user.phone ?? "+10000000000" : null;

	// States
	const [state, setState] = useState("LOADING");
	const [inProgress, setInProgress] = useState(false);

	useEffect(() => {
		let clear = false;
		if (state !== "LOADING") return;

		(async () => {
			try {
				const data = await getCarrierInformation(carrierId);
				if (clear) return;

				setCarrierData(data);
				setMC(data.carrier_mc_number);
				setDOT(data.carrier_dot_number);
				setCarrierType(data.typeCompany);
				setTestCarrier(!!data.isTestCarrier);
				setCarrierRepresentative(data.carrierRepresentative);
				setParent(data?.parent);

				// If opened via validation flow, we need to go to validation process right away
				if (validationFlowOnly) {
					setValidationType(data.carrier_email ? "EMAIL" : data.carrier_phone ? "PHONE" : "NO_SUPPORTED");
					setUneditedDotSync(data.onboardingDotInfoUnedited);
					setParentValidated(data.onboardingParentValidated);
					setState("VALIDATE");
				} else {
					setState("LOADED");
				}
			} catch (e) {
				showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
			}
		})();

		return () => {
			clear = true;
		};
		// eslint-disable-next-line
	}, []);

	const syncDOTInfo = async () => {
		if (inProgress) return;
		setInProgress(true);

		try {
			const dotSyncData = await getCarrierDOTInformation(carrierId, mc, dot);
			setInProgress(false);
			setState(dotSyncData.status === "SUCCESS" ? "SYNC_SUCCESS" : "SYNC_FAILURE");

			if (dotSyncData.status === "SUCCESS") {
				setCarrierData({
					...dotSyncData.data,
					typeCompany: carrierTypeCompany,
					parent: parent,
					carrierRepresentative: carrierRepresentative,
				});
				setMC(dotSyncData.data.carrier_mc_number);
				setDOT(dotSyncData.data.carrier_dot_number);
				setUneditedDotSync(true);
				setParentValidated(dotSyncData.data.onboardingParentValidated);
				setValidationType(
					dotSyncData.data?.carrier_email ? "EMAIL" : dotSyncData.data?.carrier_phone ? "PHONE" : "NO_SUPPORTED"
				);
			}
		} catch (e) {
			setInProgress(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const continueManually = () => {
		manualEditFormFormsRef.current += 1;
		setState("EDIT_MANUALLY");
	};

	const saveCompanyInfo = async (toState) => {
		if (inProgress) return;
		setInProgress(true);

		if (!parentValidated) delete carrierData.onboardingParentValidated;
		try {
			await updateCarrierInformation(carrierId, { ...carrierData, uneditedDotSync, formUpdate: true });
			setInProgress(false);

			if (toState) {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "onboardingRevision" }));
				setState(toState);
			} else {
				showSnackbar(snackbar, "Done!", "success");
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "onboardingRevision" }));
				onDone?.();
			}
		} catch (e) {
			setInProgress(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const sendValidationCode = async () => {
		if (inProgress) return;
		setInProgress(true);

		try {
			let result;
			if (validationType === "PHONE") {
				result = await sendCarrierDOTPhoneCode(carrierId, { overridePhoneValue });
			} else if (validationType === "EMAIL") {
				result = await sendCarrierDOTEmailCode(carrierId, { overrideEmailValue });
			}
			setValidationRevision((validationRevision ?? 0) + 1);
			setValidationCode(null);
			setInProgress(false);
			if (result.message) {
				// Inernal message indicated to which email or phone code was send,
				// should be user email when overriden for test carriers
				showSnackbar(snackbar, result.message, "info");
			}
			setState("VALIDATE_SENT");
		} catch (e) {
			setInProgress(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const verifyValidationCode = async () => {
		if (inProgress) return;
		if (!validationCode) return;
		setInProgress(true);

		try {
			await verifyCarrierDOTValidationCode(carrierId, { code: validationCode });
			setInProgress(false);
			showSnackbar(snackbar, "Done!", "success");
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "urlSearchRevision" }));
			dispatch(fetchActions({ carrierId }));
			dispatch(fetchCredentialsStatus({ carrierId }));
			if (window?.ReactNativeWebView?.postMessage) {
				window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_ACTIONS", data: carrierId }));
			}
			onDone?.();
		} catch (e) {
			setInProgress(false);
			if (e.errors?.[0]?.type === "code") {
				setValidationError(e.errors?.[0]?.message);
			} else {
				showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
			}
		}
	};

	return (
		<div
			className={
				"flex flex-col items-center justify-center text-center pb-12 pt-4 " +
				(inProgress ? " opacity-50 pointer-events-none " : "")
			}
		>
			{state === "VALIDATE_SENT" ? (
				<>
					<div className="flex flex-row w-full items-center pl-10 pr-20 mb-20 border-b-1">
						<Icon className="text-36 text-green mr-6 mb-6">mark_email_unread</Icon>
						<div className="flex flex-col w-full mb-12 mt-4 ml-10 ">
							<Typography color="textPrimary" className="flex w-full text-13 font-normal text-start">
								{validationType === "ANY"
									? "Please provide verification code you received"
									: `Please check your ${
											validationType === "PHONE" ? "phone" : "inbox"
									  } for messages and enter confirmation
							code below`}
							</Typography>
							<Typography color="textPrimary" className="flex w-full text-13 text-grey font-normal text-start">
								{validationType === "PHONE"
									? "Code will stay valid for 10 minutes only"
									: validationType === "EMAIL"
									? "Code will stay valid for 24 hours"
									: null}
							</Typography>
						</div>
					</div>
					<SmarthopFormView
						key={"validate_code_data_" + validationRevision}
						mode="EDIT"
						data={{ code: validationCode }}
						content={{
							form: { noErrorMessage: true },
							items: [{ key: "code", type: "text", label: "Code", required: true }],
						}}
						errors={validationError ? [{ type: "code", message: validationError }] : null}
						noInitValidation={true}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model) => {
							setValidationCode(model.code);
						}}
					/>
					<div className="flex flex-col w-full items-center justify-center mt-2">
						<Button
							key="confirm_code"
							color="secondary"
							variant="contained"
							className="flex-1 w-full px-24"
							disabled={!validationCode}
							onClick={() => verifyValidationCode()}
						>
							Confirm
						</Button>
						<Button
							key="back_validate_sent"
							color="primary"
							variant="contained"
							className="flex-1 w-full px-24 mt-10"
							onClick={() => {
								setState("VALIDATE");
								setValidationError(null);
								setValidationCode(null);
								if (validationType === "ANY") {
									setValidationType("EMAIL");
								}
							}}
						>
							Back
						</Button>
					</div>
				</>
			) : state === "VALIDATE" ? (
				carrierData.onboardingInformationStatus === "VALIDATED" ||
				carrierData.onboardingInformationStatus === "VALIDATED_LOCKED" ? (
					// Already validated
					<>
						<div className="flex flex-row w-full items-center pl-10 pr-20 justify-center">
							<Icon className="text-36 text-green mr-6 mb-6">check_circle</Icon>
							<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal text-left">
								Company information has already been validated
							</Typography>
						</div>
						<Button
							key="back_validate"
							color="primary"
							variant="contained"
							className="flex-1 w-full px-24 mt-10"
							onClick={() => {
								if (validationFlowOnly) {
									onDone?.();
								} else {
									setState("SYNC_SUCCESS");
								}
							}}
						>
							{validationFlowOnly ? "Close" : "Back"}
						</Button>
					</>
				) : validationType === "NOT_SUPPORTED" || (user?.role !== "ADMIN" && !uneditedDotSync) ? (
					// If data has not been synced from DOT, validation would not be supported
					// (admin can validate even data that was not synced via DOT)
					<>
						<div className="flex flex-row w-full items-center pl-10 pr-20 justify-center">
							<Icon className="text-36 text-grey mr-6 mb-6">warning</Icon>
							<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
								Unfortunately, we can not validate your account information at this moment.
							</Typography>
						</div>
						<Button
							key="back_validate"
							color="primary"
							variant="contained"
							className="flex-1 w-full px-24 mt-10"
							onClick={() => {
								if (validationFlowOnly) {
									onDone?.();
								} else {
									setState("SYNC_SUCCESS");
								}
							}}
						>
							{validationFlowOnly ? "Close" : "Back"}
						</Button>
					</>
				) : (
					<>
						{user?.role === "ADMIN" && !uneditedDotSync && (
							<div className="flex flex-row w-full items-center pl-10 pr-20 mb-6 justify-center border-b-1">
								<Icon className="text-36 text-red mr-6 mb-6">warning</Icon>
								<Typography className="flex-1 text-13 mb-14 mt-10 font-normal text-red-700">
									ATTENTION: this company information was either entered manually or partially edited after sync with
									DOT, please validate information manually using DOT website before sending confirmation code!
								</Typography>
							</div>
						)}
						<div className="flex flex-row w-full items-center pl-10 pr-20 mb-12 justify-center border-b-1">
							<Icon className="text-36 text-blue mr-6 mb-6">forward_to_inbox</Icon>
							<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
								We need to send you confirmation code to verify validity of the provided information.
							</Typography>
						</div>
						{testCarrier && (
							<Typography className="text-12 mb-8 px-8 py-8 bg-red tracking-wide text-white rounded-8">
								WARNING: This is a test carrier, and for the test purposes the selected option email or phone would be
								overriden with your profile email {overrideEmailValue} or your phone number {overridePhoneValue}
							</Typography>
						)}
						<SmarthopFormView
							key="send_code_type_data"
							mode="EDIT"
							data={{ validationType }}
							content={{
								form: { noErrorMessage: true },
								items: [
									{
										key: "validationType",
										type: "radio",
										required: true,
										defaultValue: "EMAIL",
										options: [
											...(carrierData.carrier_email
												? [{ value: "EMAIL", label: "Send email to " + carrierData.carrier_email }]
												: []),
											...(carrierData.carrier_phone
												? [{ value: "PHONE", label: "Send SMS to " + carrierData.carrier_phone }]
												: []),
										],
									},
								],
							}}
							noInitValidation={true}
							trackChangedFields={["ALL"]}
							onChangeCommitted={(model) => {
								setValidationType(model.validationType);
							}}
						/>
						<div className="flex flex-col w-full items-center justify-center">
							<Button
								key="send_code"
								color="secondary"
								variant="contained"
								className="flex-1 w-full px-24"
								onClick={() => sendValidationCode()}
							>
								Send Code
							</Button>
							<Button
								key="send_code"
								color="secondary"
								variant="contained"
								className="flex-1 w-full px-24 mt-10"
								onClick={() => {
									setValidationRevision((validationRevision ?? 0) + 1);
									setState("VALIDATE_SENT");
									setValidationType("ANY");
								}}
							>
								I Already Have the Code
							</Button>
							{!carrierData?.axleConnection?.fleet_id && (
								<Button
									key="axle"
									color="secondary"
									variant="contained"
									className="flex-1 w-full px-24 mt-10"
									onClick={() => {
										connectAxle(carrierId).then((data) => {
											window.open(data.url, "_blank");
										});
									}}
								>
									Validate by Connecting ELD with Axle
								</Button>
							)}
							<Button
								key="back_validate"
								color="primary"
								variant="contained"
								className="flex-1 w-full px-24 mt-10"
								onClick={() => {
									if (validationFlowOnly) {
										onDone?.();
									} else {
										setState("SYNC_SUCCESS");
									}
								}}
							>
								{validationFlowOnly ? "Close" : "Back"}
							</Button>
						</div>
					</>
				)
			) : state === "EDIT_MANUALLY" ? (
				<>
					<div className="flex flex-row w-full items-center pl-10 pr-20 mb-20 justify-center border-b-1">
						<Icon className="text-36 text-blue mr-6">create</Icon>
						<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
							Please provide your company information. Our team will reach you out to activate your account and validate
							correctness of the provided information
						</Typography>
					</div>
					<SmarthopFormView
						key={"edit_data_manually_" + manualEditFormFormsRef.current}
						mode="EDIT"
						data={carrierData}
						content={{ items: MAIN_FIELDS(false, false, carrierData?.parent) }}
						noInitValidation={true}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model) => {
							setCarrierData({ ...model, parent });
							setUneditedDotSync(false);
							setParentValidated(false);
						}}
					/>
					<div className={"flex flex-col w-full items-center justify-center -mt-16"}>
						<Button
							key="save_edited"
							color="secondary"
							variant="contained"
							className="flex-1 w-full px-24"
							onClick={() => saveCompanyInfo()}
						>
							Save
						</Button>
					</div>
				</>
			) : state === "SYNC_SUCCESS" ? (
				<>
					<div className="flex flex-row w-full items-center pl-10 pr-20 mb-16 justify-center border-b-1">
						<Icon className="text-36 text-green mr-6">check_circle</Icon>
						<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
							We successfully loaded your company information, please check the information below and validate your
							ownership to expedite account activation.
						</Typography>
					</div>
					<SmarthopFormView
						key="synced_data"
						mode="EDIT"
						data={carrierData}
						content={{ items: MAIN_FIELDS(true, false, carrierData?.parent) }}
					/>
					{!carrierData?.onboardingParentValidated && (
						<>
							<div className="flex w-full pl-10 pr-20 border-t-1 mt-20">
								<Typography className="flex text-13 mb-6 mt-16 font-semibold">
									Validate Carrier Ownership (Recommended)
								</Typography>
							</div>
							<div className="flex flex-row w-full items-center pl-10 pr-20 pb-4 justify-center">
								<Icon className="text-32 mr-10 mb-8">mark_email_unread</Icon>
								<Typography className="flex-1 text-13 mb-10 font-normal text-left">
									Validate this carrier ownership now to expedite account activation. That would allow you to get
									connected to all brokers in an expedited manner
								</Typography>
							</div>
							<Button
								key="send"
								color="secondary"
								variant="contained"
								className="flex-1 w-full px-24"
								onClick={() => saveCompanyInfo("VALIDATE")}
							>
								Save and Validate
							</Button>
							<div className="flex w-full pl-10 pr-20 border-t-1 mt-20">
								<Typography className="flex text-13 mb-6 mt-16 font-semibold">Continue Without Validation</Typography>
							</div>
							<div className="flex flex-row w-full items-center pl-10 pr-20 pb-4 justify-center">
								<Icon className="text-32 mr-10 mb-8">warning</Icon>
								<Typography className="flex-1 text-13 mb-10 font-normal text-left">
									If you can not validate ownership of this profile or want to add any changes, we will have to contact
									you in order to validate ownership of the provided information. That may require additional time to
									activate your account
								</Typography>
							</div>
							<Button
								key="save_synced"
								color="secondary"
								variant="contained"
								className="flex-1 w-full px-24"
								onClick={() => saveCompanyInfo()}
							>
								Save without Validation
							</Button>
						</>
					)}
					{carrierData?.onboardingParentValidated && (
						<>
							<div className="flex flex-row w-full pl-10 pr-20 mt-6 pt-6 border-t-1">
								<Icon className="text-36 text-green mr-6">check_circle</Icon>
								<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal text-left">
									We already confirmed the ownership using previous accounts.
								</Typography>
							</div>
							<div className="flex flex-col w-full items-center pl-10 pr-20 pb-4 justify-center mt-16">
								<Button
									key="save_edited"
									color="secondary"
									variant="contained"
									className="flex-1 w-full px-24"
									onClick={() => saveCompanyInfo()}
								>
									Save
								</Button>
							</div>
						</>
					)}
					<Button
						key="edit_synced"
						color="primary"
						variant="contained"
						className="flex-1 w-full px-24 mt-10"
						onClick={() => continueManually()}
					>
						Edit Information
					</Button>
				</>
			) : state === "LOADED" || state === "SYNC_FAILURE" ? (
				<>
					<div className="flex flex-row w-full items-center pl-10 pr-20 mb-20 justify-center border-b-1 pt-2 pb-4">
						<Icon className="text-36 text-blue mr-6">sync</Icon>
						<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
							Please provide your company MC or/and DOT number(s) to allow Smarthop to synchronize your company
							information
						</Typography>
					</div>
					<SmarthopFormView
						key="send"
						data={{
							carrier_mc_number: carrierData.carrier_mc_number,
							carrier_dot_number: carrierData.carrier_dot_number,
							typeCompany: carrierData.typeCompany,
							parent: parent,
							carrierRepresentative: carrierData.carrierRepresentative,
						}}
						content={{
							form: { noErrorMessage: true },
							items: [
								{
									type: "group",
									content: {
										items: [TYPE_COMPANY(false), CARRIER_REPRESENTATIVE(false, false, carrierData?.parent, true)],
									},
								},

								...(!isDispatcher
									? [
											{
												type: "group",
												content: {
													items: [
														{
															key: "carrier_mc_number",
															type: "number",
															label: "MC #",
															translate: "MC_NUMBER",
														},
														{
															key: "carrier_dot_number",
															type: "number",
															label: "DOT #",
															translate: "DOT_NUMBER",
														},
													],
												},
											},
									  ]
									: []),
							],
						}}
						noInitValidation={true}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model) => {
							setMC(model.carrier_mc_number);
							setDOT(model.carrier_dot_number);
							setCarrierType(model.typeCompany);
							setCarrierRepresentative(model.carrierRepresentative);
							setCarrierData({
								...(carrierData ?? {}),
								carrier_mc_number: model.carrier_mc_number,
								carrier_dot_number: model.carrier_dot_number,
								typeCompany: model.typeCompany,
								parent: parent,
								carrierRepresentative: model.carrierRepresentative,
							});
						}}
					/>
					<div
						className={
							"flex flex-col w-full items-center justify-center mt-8 px-8 " + (isDispatcher ? "mt-10" : "mt-8")
						}
					>
						<Button
							key="sync"
							color="secondary"
							variant="contained"
							className={"flex-1 w-full px-24"}
							onClick={() => (!isDispatcher ? syncDOTInfo() : continueManually())}
						>
							{state === "SYNC_FAILURE" ? "Retry" : "Continue"}
						</Button>
						{!isDispatcher && (
							<Button
								key="no_mc"
								color="primary"
								variant="outlined"
								className="flex-1 w-full px-24 mt-10 text-grey-600"
								onClick={() => continueManually()}
							>
								I do not have MC/DOT number(s)
							</Button>
						)}
						{state === "SYNC_FAILURE" && (
							<>
								<div className="flex flex-row w-full items-center pb-6 pl-10 pr-20 justify-center border-t-1 mt-20">
									<Icon className="text-36 text-red mr-10">sync_problem</Icon>
									<Typography className="flex-1 text-13 text-red mb-10 mt-16 font-normal">
										We were unable to automatically fetch your company information. Please make sure you provide valid
										DOT or/and MC number(s). You can enter your company information manually, however, that may require
										more time to validate this information and activate your account.
									</Typography>
								</div>
								<Button
									key="provide_manually"
									color="secondary"
									variant="outlined"
									className="flex-1 w-full px-24 text-secondary-800"
									onClick={() => continueManually()}
								>
									Provide Information Manually
								</Button>
							</>
						)}
					</div>
				</>
			) : state === "LOADING" ? (
				<div className="flex flex-row items-center justify-center my-40">
					<Typography color="textPrimary" className="text-14 mb-20 mt-16 font-normal">
						Loading...
					</Typography>
				</div>
			) : null}
		</div>
	);
};

export default CompanyInfoConfirmationView;
