// Dependencies
import { useState, useMemo, useEffect } from "react";
import { CircularProgress, Icon, IconButton } from "@material-ui/core";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Toots and Utils
import Typography from "@material-ui/core/Typography";
import PageWrapper from "../../common/PageWrapper";
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { formatCurrency } from "app/main/utils/tableUtils";
import { capitalizeFirstLetter } from "app/main/utils/tableUtils";

// Services
import { getRoleLabel, isCarrierPermission, isInternalPermission } from "app/services/LoginService";
import { getTripPayeesPayrollSettings } from "app/services/enterprisePayrollServices";
import { DISTRIBUTION_PRIORITY } from "app/main/consts";

const PAYMENT_TYPES = {
	PAY_BY_PERCENTAGE: "Pay By Percentage",
	PAY_BY_MILES: "Pay By Miles",
	FLAT_FEE: "Flat Fee",
	PAY_BY_PROFIT: "Profit (%)",
};

const MINIMUM_PAYMENT_TYPES = {
	PERIOD: "Per Pay Period",
	TRIP: "Per Trip",
};

/**
 * PayeeSection Rendering Component
 * @param {*} param0
 * @returns
 */
const PayeeSection = ({
	data: { title, payeeName, tripId, payeeId, carrierId, settings, formId, payeeType, isInternal, isCarrier },
	hidden,
}) => {
	const dispatch = useDispatch();

	const onEdit = useCallback(async () => {
		dispatch(
			openLoadedFormDialog({
				formId,
				mode: "EDIT",
				dataIds: { tripId, payeeId, id: payeeId, carrierId: carrierId },
			})
		);
	}, [carrierId, dispatch, formId, payeeId, tripId]);

	if (hidden) {
		return null;
	}

	if (!settings) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className="flex w-full flex-col md:flex-row">
			<DisplaySection
				classes={{ root: "md:w-full" }}
				bannerType={settings?.edited ? "warning" : undefined}
				bannerMsg={`${capitalizeFirstLetter(
					payeeType
				)} trip payroll setting is no longer equal to current ${capitalizeFirstLetter(payeeType)} configuration`}
				title={
					<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
						<div>{`${payeeName} (${title})`}</div>
						<div className="space-x-8 float-right -mt-8 -mb-20">
							{(isInternal || isCarrier) && (
								<IconButton
									aria-label="edit"
									className="-mt-20"
									size="medium"
									color="inherit"
									onClick={() => onEdit()}
									title="Edit settings for this trip"
								>
									<Icon className={"text-20 text-black font-500"}>{"edit"}</Icon>
								</IconButton>
							)}
						</div>
					</div>
				}
			>
				<div className="-mt-10">
					{payeeType === "DRIVER" && (
						<>
							{renderDataItem("Payment Type", PAYMENT_TYPES[settings?.payment_type], "", {})}

							{settings?.payment_type === "PAY_BY_PERCENTAGE" &&
								renderDataItem("Gross", !Number.isNaN(+settings?.gross) ? settings?.gross + "%" : "-", "", {})}

							{settings?.payment_type === "PAY_BY_PROFIT" &&
								renderDataItem(
									"Percentage",
									!Number.isNaN(+settings?.profit_pct) && settings?.profit_pct + "%",
									"",
									{}
								)}

							{settings?.payment_type === "PAY_BY_MILES" && (
								<>
									{renderDataItem("Empty Miles Payment", formatCurrency(settings?.empty_miles_payment), "", {})}
									{renderDataItem("Loaded Miles Payment", formatCurrency(settings?.loaded_miles_payment), "", {})}
								</>
							)}

							{settings?.payment_type === "FLAT_FEE" &&
								renderDataItem("Amount", formatCurrency(settings?.flat_fee_amount), "", {})}

							{renderDataItem("Minimum Payment Type", MINIMUM_PAYMENT_TYPES[settings?.minimum_payment_type], "", {})}
							{renderDataItem("Minimum Payment", formatCurrency(settings?.minimum_payment), "", {})}
							{renderDataItem("Extra Drop Payment", formatCurrency(settings?.extra_drops), "", {})}
						</>
					)}

					{payeeType === "INVESTOR" && (
						<>
							{renderDataItem(
								"Distribution Priority Type",
								DISTRIBUTION_PRIORITY[settings?.distribution_priority],
								"",
								{}
							)}
							{renderDataItem(
								"Distribution Priority",
								!Number.isNaN(+settings?.distribution_priority_value)
									? settings?.distribution_priority_value + "%"
									: "-",
								"",
								"",
								{}
							)}
						</>
					)}

					{payeeType === "DISPATCHER" && (
						<>
							{renderDataItem("Booking Fee Type", PAYMENT_TYPES[settings?.booking_fee_type], "", {})}
							{settings?.booking_fee_type === "PAY_BY_PERCENTAGE" &&
								renderDataItem(
									"Booking Fee",
									!Number.isNaN(+settings?.booking_fee_percentage) ? settings?.booking_fee_percentage + "%" : "-",
									"",
									{}
								)}

							{settings?.booking_fee_type === "FLAT_FEE" &&
								renderDataItem("Booking Fee", formatCurrency(settings?.booking_fee_flat_amount), "", {})}
						</>
					)}
				</div>
			</DisplaySection>
		</div>
	);
};

function TripPayeesDetailsPayrollPage(props) {
	const profileRevision = useSelector(({ tools }) => tools.revision?.profileRevision);

	const role = useMemo(() => getRoleLabel(), []);
	const isInternal = useMemo(() => isInternalPermission(role), [role]);
	const isCarrier = useMemo(() => isCarrierPermission(role), [role]);

	const trip = props.trip;
	const tripId = trip._id;
	const carrierId = trip.carrier;

	// payees
	const driverId = trip.driver;
	const secondaryDriverId = trip.driver_secondary;
	const investorProfileId = trip.investor;
	const dispatcherId = trip.creator_user;

	const [loading, setLoading] = useState(true);
	const [driverSettings, setDriverSettings] = useState();
	const [secondaryDriverSettings, setSecondaryDriverSettings] = useState();
	const [investorSettings, setInvestorSettings] = useState();
	const [dispatcherSettings, setDispatcherSettings] = useState();

	useEffect(() => {
		(async () => {
			try {
				const payrollSettings = await getTripPayeesPayrollSettings({ carrierId, tripId });
				if (payrollSettings.primaryDriver) {
					setDriverSettings({
						settings: payrollSettings.primaryDriver,
						title: "Driver",
						payeeName: trip?.driver__view?.label,
						payeeType: "DRIVER",
						payeeId: driverId,
						formId: "TRIP_DRIVER_PAYROLL_FORM",
					});
				}
				if (payrollSettings.secondaryDriver) {
					setSecondaryDriverSettings({
						settings: payrollSettings.secondaryDriver,
						title: "Secondary Driver",
						payeeName: trip?.driver_secondary__view?.label,
						payeeType: "DRIVER",
						payeeId: secondaryDriverId,
						formId: "TRIP_DRIVER_PAYROLL_FORM",
					});
				}
				if (payrollSettings.investorProfile) {
					setInvestorSettings({
						settings: payrollSettings.investorProfile,
						title: "Investor",
						payeeName: trip?.investor__view?.label,
						payeeType: "INVESTOR",
						payeeId: investorProfileId,
						formId: "TRIP_INVESTOR_PAYROLL_FORM",
					});
				}
				if (payrollSettings.dispatcher) {
					setDispatcherSettings({
						settings: payrollSettings.dispatcher,
						title: "Dispatcher",
						payeeName: trip?.creator_user__view?.label,
						payeeType: "DISPATCHER",
						payeeId: dispatcherId,
						formId: "TRIP_DISPATCHER_PAYROLL_FORM",
					});
				}
			} catch (err) {
				console.error(err);
			}

			setLoading(false);
		})();
	}, [carrierId, driverId, secondaryDriverId, dispatcherId, trip, tripId, investorProfileId, profileRevision]);

	const renderPayeesSection = (payeeSettings) => {
		const sections = [];
		for (const payee of payeeSettings) {
			const title = payee.title;
			const settings = payee.settings;
			const payeeName = payee.payeeName;
			const payeeId = payee.payeeId;
			const formId = payee.formId;
			const payeeType = payee.payeeType;
			const data = { title, settings, payeeName, tripId, payeeId, carrierId, formId, payeeType, isInternal, isCarrier };
			if (payeeId && settings) {
				sections.push(<PayeeSection key={payeeId} data={data} hidden={!settings} />);
			}
		}
		return sections;
	};

	if (loading) {
		return (
			<div className="flex w-full h-full items-center justify-center">
				<div className="flex-col w-full flex items-center justify-center">
					<Typography color="primary" className="text-12 ml:text-13 font-light mx-20" component={"div"}>
						Loading...
					</Typography>
				</div>
			</div>
		);
	}

	if (!driverSettings && !secondaryDriverSettings && !investorSettings && !dispatcherSettings) {
		return (
			<div className="flex w-full h-full items-center justify-center">
				<div className="flex-col w-full flex items-center justify-center">
					<Typography color="primary" className="text-12 ml:text-13 font-light mx-20" component={"div"}>
						No data found
					</Typography>
				</div>
			</div>
		);
	}

	return (
		<PageWrapper title="Trip Payees" variant="narrow" isView={props.isView}>
			<div className="flex flex-col items-top w-full space-x-0 grid-cols-2">
				<div className="flex flex-col md:flex-row md:space-x-16">
					{renderPayeesSection([driverSettings ?? {}, secondaryDriverSettings ?? {}])}
				</div>
				<div className="flex flex-col md:flex-row md:space-x-16">
					{renderPayeesSection([investorSettings ?? {}, dispatcherSettings ?? {}])}
				</div>
			</div>
		</PageWrapper>
	);
}

export default TripPayeesDetailsPayrollPage;
