import moment from "moment";

let currentYear = moment().year();

const formatDate = (from, to, short, round, noTime, noWindow, windowReduced) => {
	let fromHasTz =
		from &&
		from.charAt &&
		from.charAt(from.length - 1) !== "Z" &&
		(from.charAt(from.length - 6) === "-" || from.charAt(from.length - 6) === "+");
	let mfrom = fromHasTz ? moment.parseZone(from) : moment(from);
	if (round && mfrom.minutes() === 59) {
		mfrom.add(1, "minute");
	}

	let fromTz = fromHasTz ? " (GMT" + mfrom.utcOffset() / 60 + ")" : "";
	let patternDate;
	if (mfrom.year() === currentYear) {
		patternDate = short ? "MM/DD" : "MMM DD";
	} else {
		patternDate = short ? "MM/DD/YYYY" : "MMM DD YYYY";
	}

	let fmm = mfrom.minutes() > 0 ? ":mm" : "";
	if (!to) {
		return mfrom.format(patternDate + ", h" + fmm + "a") + fromTz;
	}

	let toHasTz =
		to &&
		to.charAt &&
		to.charAt(to.length - 1) !== "Z" &&
		(to.charAt(to.length - 6) === "-" || to.charAt(to.length - 6) === "+");
	let mto = toHasTz ? moment.parseZone(to) : moment(to);
	if (round && mto.minutes() === 59) {
		mto.add(1, "minute");
	}

	let tmm = mto.minutes() > 0 ? ":mm" : "";
	if (mfrom.isSame(mto) || noWindow) {
		return mfrom.format(patternDate + (noTime ? "" : ", h" + fmm + "a"));
	} else if (mfrom.isSame(mto, "day")) {
		return (
			mfrom.format(patternDate + (noTime ? "" : ", h" + fmm + "a")) +
			(noTime ? "" : "\n - " + mto.format("h" + tmm + "a"))
		);
	} else {
		if (!windowReduced) {
			return (
				mfrom.format(patternDate + (noTime ? "" : ", h" + fmm + "a")) +
				" - \n" +
				mto.format(patternDate + (noTime ? "" : ", h" + tmm + "a"))
			);
		} else {
			const diffDays = mfrom.diff(mto, "days");
			return mfrom.format(patternDate + (noTime ? "" : ", h" + fmm + "a")) + " (+" + Math.abs(diffDays) + ")";
		}
	}
};

const formatAge = (date) => {
	let time = moment().unix() - moment(date).unix();
	let mins = parseInt((time / 60) % 60);
	let hours = parseInt((time / 60 / 60) % 24);
	let days = parseInt(time / 60 / 60 / 24);
	if (days > 30) {
		return "30d";
	} else if (days > 0) {
		return days + "d";
	} else if (hours > 4) {
		return hours + "h";
	} else {
		return (hours < 10 ? "0" + hours : hours) + ":" + (mins < 10 ? "0" + mins : mins);
	}
};

const formatTimeAgo = (date) => {
	let time = moment().unix() - moment(date).unix();
	let mins = parseInt((time / 60) % 60);
	let hours = parseInt((time / 60 / 60) % 24);
	let days = parseInt(time / 60 / 60 / 24);
	if (days > 30) {
		return ">30d";
	} else if (days > 0) {
		return days + "d";
	} else if (hours > 0) {
		return hours + "h";
	} else {
		return mins + "m";
	}
};

const formatMessageDate = (date, options) => {
	let mdate = moment(date);
	let mnow = moment();

	var duration = moment.duration(mnow.diff(mdate));
	var minDiff = parseInt(duration.asMinutes());
	if (minDiff <= 0 && !options?.noTimeFromNow) {
		return "Just now";
	} else if (minDiff < 60 && !options?.noTimeFromNow) {
		return parseInt(minDiff) + " min ago";
	} else if (moment().isSame(moment(mdate), "day")) {
		return mdate.format("h:mm:a");
	} else if (options?.noTimeWithDate) {
		return mdate.format("MMM DD");
	} else {
		return mdate.format("MMM DD, h:mm:a");
	}
};

function formatDuration(duration, hideZeros, showSign) {
	let sign = "";
	if (duration < 0) {
		duration *= -1;
		if (showSign) sign = "- ";
	} else {
		if (showSign) sign = "+ ";
	}

	var minutes = Math.floor((duration / (1000 * 60)) % 60);
	var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

	minutes = !hideZeros && minutes < 10 ? "0" + minutes : minutes;
	return sign + (!hideZeros || hours > 0 ? hours + "h " : "") + minutes + "m";
}
const formatBookDate = (date) => {
	const currentYear = moment().year();
	date = moment(date);
	if (date.year() === currentYear) {
		date = moment(date).format("ddd, MM/DD");
	} else {
		date = moment(date).format("ddd, MM/DD/YYYY");
	}
	return date;
};

export { formatDate, formatAge, formatTimeAgo, formatMessageDate, formatDuration, formatBookDate };
