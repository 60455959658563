import { useMemo } from "react";
import { PROFIT_WARNINGS, getProfitWarnings, getUnaccountedPercent } from "./CostStructureCalculatorSettings";
import { useDispatch, useSelector } from "react-redux";
import { openFormDialog, closeFormDialog } from "app/store/tools/formDialogSlice";
import { Typography, Icon, Link } from "@material-ui/core";
import { useCallback } from "react";
import history from "@history";

function ProfitWarnings({ data, variant }) {
	const warnings = useMemo(() => getProfitWarnings(data), [data]);
	const dispatch = useDispatch();
	const overflow = useMemo(() => getUnaccountedPercent(data), [data]);
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);

	const onClickUser = useCallback(
		(model) => {
			if (nativeMobile) return;
			return dispatch(
				openFormDialog({
					viewId: "USER_DETAILS_VIEW",
					dataIds: { carrierId: data?.carrier?._id, driverId: data?.[model]?._id },
				})
			);
		},
		[data, dispatch, nativeMobile]
	);

	const onClickCost = useCallback(() => {
		const reactNative = window?.ReactNativeWebView ?? false;
		//Its the default CS, link to CS configuration
		if (!data?.costStructure?._id && !reactNative) {
			dispatch(closeFormDialog());
			history.push("/profile/costs?tab=1");
		} else if (data?.costStructure?._id) {
			return dispatch(
				openFormDialog({
					viewId: "COST_STRUCTURE_VIEW",
					mode: "VIEW",
					dataIds: { id: data?.costStructure?._id, carrierId: data?.carrier?._id },
				})
			);
		}
	}, [data, dispatch]);

	const ownerLink = useMemo(() => {
		if (data?.driver?.driver_type !== "OWNER_OPERATOR") return null;
		return (
			<>
				<Link style={{ color: "blue" }} onClick={() => onClickUser("driver")}>
					Driver Gross Pay
				</Link>{" "}
				({data?.driver?.gross}%)
			</>
		);
	}, [onClickUser, data]);

	const secondaryOwnerLink = useMemo(() => {
		const secondary = data?.secondaryDriver ?? data?.driver_secondary;
		if (secondary?.driver_type !== "OWNER_OPERATOR") return null;
		return (
			<>
				<Link
					style={{ color: "blue" }}
					onClick={() => onClickUser(data?.secondaryDriver ? "secondaryDriver" : "driver_secondary")}
				>
					Secondary Driver Gross Pay
				</Link>{" "}
				({secondary?.gross}%)
			</>
		);
	}, [onClickUser, data]);

	const costLink = useMemo(
		() => (
			<>
				<Link style={{ color: "blue" }} onClick={onClickCost}>
					Cost Structure GMV
				</Link>{" "}
				({data?.costStructure?.percentageCost}%)
			</>
		),
		[onClickCost, data]
	);

	return (
		<>
			{warnings?.map((warning) => {
				if (warning === PROFIT_WARNINGS.GMV_OVERFLOW) {
					return (
						<div
							key={PROFIT_WARNINGS.GMV_OVERFLOW}
							className="bg-red-50 border-1 border-red rounded-lg p-10 mt-6 flex flex-row"
						>
							<Typography component="div" className={`text-red-700 text-12 mb-8 flex flex-row items-center`}>
								<div className="flex flex-col">
									<Typography className="font-semibold">
										<Icon className="text-18 -mb-5 text-red-400 mr-3">warning</Icon> Cost Structure Configuration Error
									</Typography>
									<Typography component={"div"} className="">
										For Owner Operator drivers, {ownerLink ? ownerLink : secondaryOwnerLink} +{" "}
										{ownerLink && secondaryOwnerLink ? secondaryOwnerLink : costLink}
										{ownerLink && secondaryOwnerLink ? " + " : ""}
										{ownerLink && secondaryOwnerLink ? costLink : ""} must equal 100%. ({100 - overflow}%)
									</Typography>
								</div>
							</Typography>
						</div>
					);
				}

				return null;
			})}
		</>
	);
}

export default ProfitWarnings;
