import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Typography } from "@material-ui/core";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import WidgetDynamic from "./dynamic/WidgetDynamic";
import WidgetStatic from "./static/WidgetStatic";
import WidgetMenuContainer from "./WidgetMenuContainer";
import WidgetCardContainer from "./WidgetCardContainer";
import WidgetContentConfig from "./WidgetContentConfig";
import { parseTimeFrameOverrides } from "./static/WidgetUtils";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";

const ResponsiveGridLayout = WidthProvider(Responsive);

function WidgetsPage(props) {
	const widgets = props.widgets;
	const dataIds = props.dataIds;
	const onLayoutChange = props.onLayoutChange;
	const onConfigChanged = props.onConfigChanged;
	const onCloseWidget = props.onCloseWidget;
	const onCopyWidget = props.onCopyWidget;
	const overrides = props.overrides;
	const mutable = props.mutable;
	const mobile = props.mobile;

	const user = useSelector(({ auth }) => auth.user);
	const isAdmin = user?.rolesLabels?.includes("administrator");
	const hasSearchPermission = ["CARRIER_MANAGER"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_search_on: true })
		: true;

	const [height, setHeight] = useState(null);
	const [openedAnalyticsConfig, setOpenedAnalyticsConfig] = useState(null);
	const [revisionAnalyticsData, setRevisionAnalyticsData] = useState({});
	const [rendered, setRendered] = useState(false);

	const onChangeHeight = (params) => {
		// We should ignore componets size changes in the  edit mode, otherwise new sizes can be
		// saved as defaut size, every time we change sizes automatically, onLayoutChange() is called,
		// that callback has logic to override layout sizes and it would affect default size
		if (mutable) return;
		setHeight({ ...(height ?? {}), ...(params ?? {}) });
	};

	useEffect(() => {
		if (rendered) {
			return;
		}

		let stopped = false;
		(async () => {
			await new Promise((r) => setTimeout(r, 5000));
			if (stopped) return;
			setRendered(false);
		})();

		return () => {
			stopped = true;
		};
	});

	const changeLayout = (item, mutable) => {
		let typeWidget = widgets?.configs?.[item?.i]?.container?.config?.type;
		return {
			...item,
			static: !mutable,
			h: height?.[typeWidget] ?? height?.[item?.i] ?? item.h,
		};
	};

	const [layouts, lgIndexes, maxY] = useMemo(() => {
		let lgOrdered = [...(widgets?.layouts?.lg ?? [])].sort((a, b) => (a.y === b.y ? a.x - b.x : a.y - b.y));
		let lgIndexes = {};
		lgOrdered.forEach((item, index) => {
			lgIndexes[item.i] = {
				x: item.x,
				y: item.y,
				index,
			};
		});

		let lgMaxY = 0;
		widgets?.layouts?.lg.forEach((item) => {
			if (item.y + item.h > lgMaxY) lgMaxY = item.y + item.h;
		});

		const result = [
			{
				lg: widgets?.layouts?.lg?.map((item) => {
					return changeLayout(item, mutable);
				}),
				md: widgets?.layouts?.md?.map((item) => {
					return changeLayout(item, mutable);
				}),
				sm: widgets?.layouts?.sm?.map((item) => {
					return changeLayout(item, mutable);
				}),
			},
			lgIndexes,
			{ lg: lgMaxY },
		];

		return result;
		// eslint-disable-next-line
	}, [widgets?.layouts, mutable, height]);

	const keys = widgets?.keys;
	const configs = widgets?.configs;

	if (!layouts || !configs || !keys) {
		return null;
	}

	const handleReloadWidget = (key) => {
		setRevisionAnalyticsData({ ...revisionAnalyticsData, [key]: (revisionAnalyticsData[key] ?? 0) + 1 });
	};
	const handleOpenConfig = (key, event) => {
		setOpenedAnalyticsConfig({ key, anchor: event?.target });
	};
	const handleCloseConfig = () => {
		setOpenedAnalyticsConfig(null);
	};

	const handleConfigChange = (key, config) => {
		if (configs[key]?.container?.config?.type !== config.type) {
			handleCloseConfig();
			if (config.type !== "__DYNAMIC__") {
				config = { type: config.type };
			} else {
				config = { type: config.type, label: key };
			}
		}
		onConfigChanged?.(key, config);
	};

	const cellHeight = overrides?.verticalCellsHight > 0 ? overrides?.verticalCellsHight : props?.height ?? 150;
	return (
		<>
			<ResponsiveGridLayout
				key={(overrides?.selectedWindow?.main ?? "grid") + "_" + (mobile ? "mobile" : "")}
				className="flex w-full mt-10"
				style={mutable && maxY.lg > 0 ? { minHeight: maxY.lg * (cellHeight + 10) + 40 } : {}}
				layouts={layouts}
				onLayoutChange={(all, layout) => onLayoutChange?.(all, layout)}
				isDraggable={!!openedAnalyticsConfig ? false : props.onLayoutChange ? true : false}
				isRearrangeable={!!openedAnalyticsConfig ? false : props.onLayoutChange ? true : false}
				isResizable={!!openedAnalyticsConfig ? false : props.onLayoutChange ? true : false}
				breakpoints={mobile ? { lg: 9999, md: 8888, sm: 0 } : { lg: 1200, md: 1, sm: 0 }}
				cols={{ lg: overrides?.horizontalCellsCount > 0 ? overrides?.horizontalCellsCount : 6, md: 2, sm: 1 }}
				margin={props?.margin ?? [10, 10]}
				containerPadding={[5, 5]}
				useCSSTransforms={false}
				measureBeforeMount={true}
				compactType={"horizontal"}
				rowHeight={cellHeight}
			>
				{keys.map((key) => {
					const lgIndex = lgIndexes[key];
					const animationDisabled = !rendered && lgIndex?.y >= 7;
					let initDelay;
					if (animationDisabled) {
						initDelay = 2500;
					} else {
						initDelay = lgIndex?.index < 3 && lgIndex.y < 3 ? 1000 : lgIndex?.index < 6 && lgIndex?.y < 6 ? 1500 : 2000;
					}
					if (configs[key]?.container?.config?.noShowMobile && mobile) {
						return null;
					}
					if (configs[key]?.container?.config?.onlyShowIfSmartpay && !user.hasSmartPayProgram && !isAdmin) {
						return null;
					}
					// TODO: This condition is momentary, the ideal is to add the permission in the .json configuration
					if (configs[key]?.container?.config.type === "calendar" && !hasSearchPermission) {
						return null;
					}

					const overridesToApply = parseTimeFrameOverrides(
						configs[key]?.container?.config?.overrides,
						overrides?.selectedWindow?.main
					);
					let label = configs[key]?.container?.config?.label;
					overridesToApply.forEach((o) => {
						switch (o.field) {
							case "WIDGET_LABEL":
								label = o.label ? o.label : label;
								break;
							default:
								break;
						}
					});

					return (
						<div
							key={key}
							item={key}
							className={
								"flex flex-col w-full h-full rounded-20 bg-white border-grey-300 border-1 " +
								(!configs[key]?.container?.classes?.card?.includes("bg-") ? " bg-white " : "") +
								(!configs[key]?.container?.classes?.card?.includes("pl-") ? " pl-16 " : "") +
								(!configs[key]?.container?.classes?.card?.includes("pr-") ? " pr-10 " : "") +
								(!configs[key]?.container?.classes?.card?.includes("pt-") ? " pt-10 " : "") +
								(!configs[key]?.container?.classes?.card?.includes("pb-") ? " pb-10 " : "") +
								(configs[key]?.container?.classes?.card ?? "")
							}
						>
							<WidgetCardContainer
								type={configs[key]?.container?.config?.type === "__DYNAMIC__" ? "DYNAMIC" : "STATIC"}
								label={label}
								description={configs[key]?.container?.config?.description}
								mutable={mutable}
								onOpenConfig={(event) => handleOpenConfig(key, event)}
								onReloadWidget={() => handleReloadWidget(key)}
								onCloseWidget={() => onCloseWidget?.(key)}
								onCopyWidget={() => onCopyWidget?.(key)}
								nameWidget={key}
							>
								<WidgetMenuContainer
									type={"FLOATING"}
									opened={openedAnalyticsConfig?.key === key}
									anchor={openedAnalyticsConfig?.key === key ? openedAnalyticsConfig?.anchor : null}
									onClose={() => handleCloseConfig(key)}
									menu={
										<WidgetContentConfig
											type={configs[key]?.container?.config?.type === "__DYNAMIC__" ? "DYNAMIC" : "STATIC"}
											config={configs[key]?.container?.config}
											onConfigChanged={(config) => handleConfigChange(key, config)}
										/>
									}
								>
									{configs[key]?.container?.config?.type === "__DYNAMIC__" ? (
										<WidgetDynamic
											key={key}
											boardAccessType={props?.boardAccessType}
											mutable={mutable}
											dataIds={dataIds}
											config={configs[key]?.container?.config}
											overrides={overrides}
											revision={revisionAnalyticsData[key] ?? 0}
											visuals={{ animationDisabled, initDelay }}
											cellHeight={cellHeight}
											onChangeHeight={(height) => {
												onChangeHeight({ [key]: height });
											}}
										/>
									) : !!configs[key]?.container?.config?.type ? (
										<WidgetStatic
											key={key}
											mutable={mutable}
											dataIds={dataIds}
											config={configs[key]?.container?.config}
											overrides={overrides}
											data={configs[key]?.container?.data}
											cellHeight={cellHeight}
											onChangeHeight={onChangeHeight}
										/>
									) : (
										<Typography className={"p-10 text-16 text-red"}>Not supported widget type</Typography>
									)}
								</WidgetMenuContainer>
							</WidgetCardContainer>
						</div>
					);
				})}
			</ResponsiveGridLayout>
		</>
	);
}

export default WidgetsPage;
