import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import moment from "moment";
import { useSnackbar } from "notistack";

import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { toStateViews } from "app/main/utils/stateUtils";
import { getTrucksInformation } from "app/services/truckServices";
import { convertModelToURLParams, buildURLParameters, SEARCH_LOAD_URL_PARAMS_MAPPING } from "app/main/utils/urlUtils";

import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

// Segment
import { CALENDAR_EVENT } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

const RedirectingViewComponent = () => {
	return (
		<div className="flex w-full h-200 flex-col items-center justify-center">
			<div className="w-1/4">
				<Typography className="flex text-14 text-grey pb-20 items-center justify-center">Redirecting...</Typography>
			</div>
		</div>
	);
};

const getOriginalFieldsTruck = (truck) => {
	return {
		equipment: truck?.equipment ?? truck?.truck?.equipment ?? "",
		subtype: truck?.subtype ?? truck?.truck?.subtype ?? undefined,
		load_type: truck?.truck_trailer__view?.metadata?.load_type ?? truck?.truck?.load_type ?? "FULL",
		trailer: truck?.trailer ?? truck?.truck?.trailer ?? "",
		max_weight: truck?.max_weight ?? truck?.truck?.max_weight ?? "",
		driver: truck?.driver ?? {},
		driver__view: truck?.driver__view ?? {},
		system_show_hazmat_loads_only: truck?.truck_trailer__view?.metadata?.hazmat,
	};
};

const getOriginalFieldsDriver = (driver) => {
	return {
		blacklisted_states_ids: driver?.blacklisted_states_ids ?? [],
		blacklisted_states_ids__view: toStateViews(driver?.blacklisted_states_ids ?? []),
	};
};

const toURLParams = (model) => {
	let copy = JSON.parse(JSON.stringify(model));

	copy = {
		...getOriginalFieldsTruck(copy?.truck__view?.metadata?.truck),
		...getOriginalFieldsDriver(copy?.truck__view?.metadata?.driver),
		...copy,
	};

	if (copy?.truck__view?.metadata) delete copy.truck__view.metadata;
	if (copy?.driver__view?.metadata) delete copy.driver__view.metadata;
	if (copy?.carrier__view?.metadata) delete copy.carrier__view.metadata;

	return convertModelToURLParams(copy, SEARCH_LOAD_URL_PARAMS_MAPPING);
};

function SearchRequestRedirectView({ dataIds, onProgress, onDone, onMinimize, setTitle, setSize }) {
	useEffect(() => {
		setTitle?.("New Search");
		setSize?.("max-w-sm");
		// eslint-disable-next-line
	}, []);
	const subaccounts = useSelector(({ auth }) => auth.account.subAccount);
	const { carrierId, truckId, hasAllFilters, searchFilters, fromCalendar } = dataIds;

	const [model, setModel] = useState(null);

	const snackbar = useSnackbar();
	const location = useLocation();
	const history = useHistory();

	const redirectToURL = (baseUrl, model) => {
		history.push(buildURLParameters(baseUrl, toURLParams(model)));
		if (location.pathname?.includes(baseUrl)) {
			window.location.reload();
		}
	};

	useEffect(() => {
		if (fromCalendar) {
			createTrackOrPage(CALENDAR_EVENT, { type: "search" }, "track");
		}
		if (!truckId) return;

		if (hasAllFilters) {
			async function fetchTruckInformation() {
				try {
					let pickupAfter = searchFilters?.pickup_ds__after;
					let truckInformation = await getTrucksInformation({
						options_carrier_id_search: carrierId,
						options_truck_ids_search: truckId,
						options_dispatcher_mode: subaccounts?.list?.length ? "true" : false,
					});
					const location_origin__view = searchFilters?.location_origin
						? { value: searchFilters.location_origin, label: searchFilters.location_origin }
						: null;

					let searchData = {
						...searchFilters,
						location_origin__view,
						pickup_ds__after: moment(pickupAfter).isBefore(moment(), "date") ? moment().toISOString() : pickupAfter,
						truck__view: truckInformation?.items?.filter((truck) => truck._id.toString() === truckId)[0]?.truck__view,
					};
					setModel(searchData);
				} catch (error) {
					showErrorSnackbar(snackbar, error);
					onProgress(false);
					onDone?.(true);
				}
			}

			fetchTruckInformation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!carrierId || !truckId || hasAllFilters) return;

		async function fetchTruckAndTruckPlan() {
			try {
				const loadDefaultData = !!searchFilters && !hasAllFilters;
				let truckInformation = await getTrucksInformation({
					options_carrier_id_search: carrierId,
					options_truck_ids_search: truckId,
					options_search_truck_plan: !loadDefaultData,
					options_dispatcher_mode: subaccounts?.list?.length ? "true" : false,
				});
				truckInformation = truckInformation?.items?.filter((truck) => truck._id.toString() === truckId);
				truckInformation = truckInformation[0];

				const currentTruckPlan = truckInformation?.plan__view?.metadata;
				const truckInfo = truckInformation?.truck__view?.metadata;
				const origin = searchFilters?.location_origin
					? searchFilters?.location_origin
					: currentTruckPlan?.current_location;
				const destination = searchFilters?.location_destination
					? searchFilters?.location_destination
					: currentTruckPlan?.current_destination;
				const destinationStates = searchFilters?.destinationStates
					? searchFilters.destinationStates
					: currentTruckPlan?.destinationStates;

				// Improvement Date
				const pickupAfter = searchFilters?.pickup_after
					? searchFilters?.pickup_after
					: currentTruckPlan?.current_available_date;
				const utcOffset = moment(pickupAfter).utcOffset();
				const pickupAfterDiff = moment(pickupAfter).subtract(utcOffset, "minutes");
				const searchModel = {
					dh_origin: currentTruckPlan?.dh_origin_preferred > 0 ? currentTruckPlan?.dh_origin_preferred : 250,
					dh_destination: 250,
					max_weight: loadDefaultData ? truckInfo?.max_weight : currentTruckPlan?.max_weight ?? truckInfo?.max_weight,
					...(!loadDefaultData && currentTruckPlan?.delivery_date_before
						? { delivery_ds__before: currentTruckPlan?.delivery_date_before }
						: {}),
					...(!loadDefaultData && currentTruckPlan?.driver_weekly_hos
						? { driver_weekly_hos: currentTruckPlan?.driver_weekly_hos }
						: {}),
					rate_per_mile_preferred:
						currentTruckPlan?.rate_per_mile_preferred > 0 ? currentTruckPlan?.rate_per_mile_preferred : 0,
					rate_total_preferred: currentTruckPlan?.rate_total_preferred > 0 ? currentTruckPlan?.rate_total_preferred : 0,
					...(currentTruckPlan?.profit_preferred ? { profit_preferred: currentTruckPlan?.profit_preferred } : {}),
					...(currentTruckPlan?.min_rank_mci ? { min_rank_mci: currentTruckPlan?.min_rank_mci } : {}),
					miles_preferred__min: currentTruckPlan?.miles_preferred__min > 0 ? currentTruckPlan?.miles_preferred__min : 0,
					miles_preferred__max:
						currentTruckPlan?.miles_preferred__max > 0 ? currentTruckPlan?.miles_preferred__max : 4000,
					carrier__view: truckInformation?.carrier__view,
					truck__view: truckInformation?.truck__view,
					driver__view: truckInformation?.driver__view,
					carrier: carrierId,
					truck: truckId,
					driver: truckInformation?.driver,
					blacklisted_states_ids: truckInformation?.driver__view?.metadata?.blacklisted_states_ids,
					blacklisted_states_ids__view: toStateViews(truckInformation?.driver__view?.metadata?.blacklisted_states_ids),
					equipment: truckInfo?.subtype ? "SPECIAL_" + truckInfo?.subtype : truckInfo?.equipment,
					trailer: truckInfo?.trailer,
					droptrailer: {
						value: Number(truckInfo?.trailer?.slice(0, 2)),
						label: truckInfo?.trailer,
					},
					location_origin: origin ?? null,
					...(origin
						? {
								location_origin__view: {
									value: origin,
									label: origin,
								},
						  }
						: {}),
					destinationStates: destinationStates,
					destinationStates__view: toStateViews(destinationStates),
					pickup_ds__after: moment(pickupAfter).isBefore(moment(), "date")
						? moment().toISOString()
						: moment(pickupAfterDiff).toISOString(),
					order_by: loadDefaultData ? "MATCH" : searchFilters?.order_by ?? "MATCH",
					remainingDriveTime: currentTruckPlan?.remainingDriveTime,
					remainingCycleDutyTime: currentTruckPlan?.remainingCycleDutyTime,
				};

				if (!(destinationStates?.length > 0)) {
					searchModel.location_destination = destination;
					searchModel.location_destination__view = {
						value: destination,
						label: destination,
					};
				}
				setModel(searchModel);
				onProgress(false);
			} catch (error) {
				showErrorSnackbar(snackbar, error);
				onProgress(false);
				onDone?.(true);
			}
		}

		fetchTruckAndTruckPlan();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (model) {
			redirectToURL("/search", model);
			onDone?.(true);
			onMinimize?.(true);
		}
		// eslint-disable-next-line
	}, [model]);

	return <RedirectingViewComponent />;
}

export default SearchRequestRedirectView;
