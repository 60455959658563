import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";

import { getTimelineTrips } from "app/services/tripTimelineServices";

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import moment from "moment";

import TripTimelinePanel from "./TripTimelinePanel";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

import Chart from "react-apexcharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import { loadingViewComponent } from "../common/LoadingView";

import { rewriteURLParameters, convertModelToURLParams, TRIP_TIMELINE_URL_PARAMS_MAPPING } from "../utils/urlUtils";
import TripTimelineVisualizer from "./TripTimelineVisualizer";
import TreeVisualizerWithTimeline from "./TreeVisualizerWithTimeline";

function WindowNavigation({ handlePrev, handleNext, response, loadingPrevNext }) {
	return (
		<>
			<Button size="small" onClick={handlePrev} disabled={!response?.prevTripId}>
				<KeyboardArrowLeft />
				PREV
			</Button>
			|
			<Button size="small" onClick={handleNext} disabled={!response?.nextTripId}>
				NEXT
				<KeyboardArrowRight />
			</Button>
			{loadingPrevNext ? (
				<>
					<CircularProgress size={13} /> Loading
				</>
			) : null}
		</>
	);
}

function Area(axisLabels = [], data = []) {
	const cumulative = data.map((d, i, a) => a.slice(0, i + 1).reduce((acc, curr) => acc + curr));

	const state = {
		options: {
			xaxis: {
				categories: axisLabels,
			},
		},
		series: [
			{
				name: "est-price",
				data,
			},
			{
				name: "cumulative",
				data: cumulative,
			},
		],
	};

	return (
		<Paper variant="outlined">
			<Chart options={state.options} series={state.series} type="area" height="200%" />
		</Paper>
	);
}

const TripTimelineView = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Trip Timeline Visualizer");
		// eslint-disable-next-line
	}, []);

	const defaultValues = dataIds?.defaultValues;
	const dispatch = useDispatch();

	const strategyAdapter = (trip) => {
		const { strategy } = trip;
		return {
			strategyId: strategy._id,
			createdAt: strategy.createdAt,
			truck: strategy.truck,
			carrierName: null,
			carrierId: strategy.carrier,
			truckFriendlyId: null,
			equipment: strategy.equipment,
			availableDate: null,
		};
	};

	// Dialog state
	const [loading, setLoading] = useState(false);
	const [loadingPrevNext, setLoadingPrevNext] = useState(false);
	const [loadingError, setLoadingError] = useState(false);
	const [errors, setErrors] = useState(null);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	// Trip Request-Response
	const [response, setResponse] = useState(null);
	const [model, setModel] = useState(defaultValues);

	const [tripTimelineOpen, setTripTimelineOpen] = useState(false);
	const [treeWithTimelineOpen, setTreeWithTimelineOpen] = useState(false);
	const [profit, setProfit] = useState("TRIP");

	// Fires when clicking submit button
	const onSubmit = (model) => {
		setModel(model);
	};

	const cleanURLParams = () => {
		const currentUrlWithOutHash = window?.location?.origin + window?.location?.pathname;
		if (window?.history?.replaceState)
			window.history.replaceState({ path: currentUrlWithOutHash }, "", currentUrlWithOutHash);
	};

	const requestTimelineTripsFromModel = useCallback((model) => {
		const { trip_date, truck, window_size } = model;
		const query = {
			date: moment.utc(trip_date ?? new Date()).toISOString(),
			windowSize: window_size,
		};
		requestTimelineTrips(truck, query);
	}, []);

	// Sends a request to the trip timeline manager when the model is updated
	useEffect(() => {
		if (!model) return;
		rewriteURLParameters(toURLParams(model));
		requestTimelineTripsFromModel(model);
		return cleanURLParams;
	}, [model, requestTimelineTripsFromModel]);

	// Calls the trip timeline service and handles states
	const requestTimelineTrips = async (truck, query) => {
		setLoading(true);
		try {
			const trips = await getTimelineTrips(truck, query);
			setResponse(trips);
		} catch (err) {
			setErrors(err);
			setLoadingError(true);
		} finally {
			setLoading(false);
		}
	};

	const handleClickOnTrip = (trip) => {
		const dataIds = strategyAdapter(trip);
		dispatch(
			openFormDialog({
				viewId: "TREE_VISUALIZER",
				dataIds,
			})
		);
	};

	// URL Manipulation
	const toURLParams = (model) => {
		let copy = JSON.parse(JSON.stringify(model));
		return convertModelToURLParams(copy, TRIP_TIMELINE_URL_PARAMS_MAPPING);
	};

	const handleNavigate = async (num) => {
		setLoadingPrevNext(true);
		setResponse(
			await getTimelineTrips(model?.truck, {
				trip: response?.tripsArray[response?.referenceTripIndex + num]?._id,
				windowSize: model.window_size,
			})
		);
		setTimeout(() => setLoadingPrevNext(false), 350);
	};

	const handlePrev = async () => handleNavigate(-1);
	const handleNext = async () => handleNavigate(1);

	const formatDate = (d, format = "YYYY-MM-DD") => moment(d).format(format);

	const takenMoveTooltip = (trip) =>
		trip.takenMove
			? `TAKEN MOVE DATA: \n 
		${trip.takenMove._id} \n 
		predicted: ${trip.takenMove.predicted} \n 
		${trip.takenMove.o_city} -> ${trip.takenMove.d_city} \n 
		${formatDate(trip.takenMove.start_date)} -> ${formatDate(trip.takenMove.end_date)}`
			: "No taken move";

	const clusterizedTripDataTooltip = (trip) =>
		trip.tripMoveEquivalent
			? `CLUSTERIZED TRIP DATA: \n 
		${trip.tripMoveEquivalent.o_cluster.coord_centroid.main_city} -> ${
					trip.tripMoveEquivalent.d_cluster.coord_centroid.main_city
			  } \n 
		${formatDate(trip.tripMoveEquivalent.start_date)} -> ${formatDate(trip.tripMoveEquivalent.end_date)}`
			: "No trip move";

	const handleProfitChange = () => (profit === "TRIP" ? setProfit("MOVE") : setProfit("TRIP"));

	return (
		<>
			<TripTimelinePanel
				defaultValues={defaultValues}
				onSubmit={onSubmit}
				errors={errors}
				loadingError={loadingError}
			/>
			{loading
				? loadingViewComponent()
				: response && (
						<>
							<WindowNavigation {...{ handlePrev, handleNext, response, loadingPrevNext }} />
							<Button onClick={() => setTripTimelineOpen(!tripTimelineOpen)}>
								<Typography>{`${tripTimelineOpen ? "Hide" : "Show"} trip timeline`}</Typography>
							</Button>
							<Button onClick={() => setTreeWithTimelineOpen(!treeWithTimelineOpen)}>
								<Typography>{`${treeWithTimelineOpen ? "Hide" : "Show"} tree with timeline`}</Typography>
							</Button>
							<Typography display="inline">
								| <Switch checked={profit === "TRIP"} onChange={handleProfitChange} />
								{`Toggle profit source (Current: ${profit === "TRIP" ? "From trips" : "From taken moves"})`}
							</Typography>

							<TreeVisualizerWithTimeline
								variant="outlined"
								open={treeWithTimelineOpen}
								tripsResponse={response}
								profit={profit}
							/>
							<TripTimelineVisualizer open={tripTimelineOpen} tripsResponse={response} />

							<Box p={2}>
								<h1>QA Tripline Under The Hood | Trips Array</h1>
								{Area(
									response.tripsArray.map((trip) => formatDate(trip.data.delivery_date, "MMM DD")),
									response.tripsArray.map((trip) => trip.data.est_price)
								)}
								<Box p={2}>
									<h4>
										Reference Trip (RT):
										{` ${response?.tripsArray[response?.referenceTripIndex]?._id}`} &nbsp;|&nbsp; Index:
										{` ${response?.referenceTripIndex}`} &nbsp;|&nbsp;
										{`Previous: ${response?.prevTripId}`} &nbsp;|&nbsp;
										{`Next: ${response?.nextTripId}`}
									</h4>

									<Table stickyHeader className="w-full" aria-labelledby="tableTitle">
										<TableHead>
											<TableRow>
												{[
													"tripId",
													"pickup address",
													"delivery address",
													"pickup date",
													"delivery date",
													"mongo id",
													"booked",
													"taken",
													"predicted",
													"strategy",
												].map((n) => (
													<TableCell>
														<Typography color="primary" className={"font-semibold tracking-wide "}>
															{n}
														</Typography>
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{response.tripsArray.map((trip, i) => (
												<TableRow
													key={trip.data.trip_id}
													style={
														response?.tripsArray[response?.referenceTripIndex]?._id === trip._id
															? { backgroundColor: `#FFAAFF` }
															: {}
													}
													title={`${takenMoveTooltip(trip)} \n\n ${clusterizedTripDataTooltip(trip)}`}
													onClick={() =>
														!_.isEmpty(trip?.strategy) ? handleClickOnTrip(trip) : setConfirmDialogOpen(true)
													}
													className={"bg-transparent "}
												>
													<TableCell>{`${trip.data.trip_id}`}</TableCell>
													<TableCell>{`${trip.data.pickup_address}`}</TableCell>
													<TableCell>{`${trip.data.delivery_address}`}</TableCell>
													<TableCell>{`${formatDate(trip.data.pickup_date)}`}</TableCell>
													<TableCell>{`${formatDate(trip.data.delivery_date)}`}</TableCell>
													<TableCell>{` ${trip._id}`}</TableCell>
													<TableCell align="center">
														{!_.isEmpty(trip?.bookedMoves) ? trip?.bookedMoves.length : 0}
													</TableCell>
													<TableCell align="center">{!_.isEmpty(trip?.takenMove) ? "✅" : "❌"}</TableCell>
													<TableCell align="center">{trip?.takenMove?.predicted ? "✅" : "❌"}</TableCell>
													<TableCell align="center">{!_.isEmpty(trip?.strategy) ? "✅" : "❌"}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Box>
							</Box>
						</>
				  )}

			<SmarthopConfirmDialog
				open={confirmDialogOpen}
				menssagee={"No strategy available for trips without a taken move."}
				handleClose={() => setConfirmDialogOpen(!confirmDialogOpen)}
			/>
		</>
	);
};

export default TripTimelineView;
