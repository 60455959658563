import { createSlice } from "@reduxjs/toolkit";

const pannelsSlice = createSlice({
	name: "pannels",
	initialState: {
		pinnedPanel: "",
	},
	reducers: {
		openPinnedPanel: (state, action) => {
			if (!action?.payload?.panelId) return;
			state.pinnedPanel = [
				{
					panelId: action.payload?.panelId,
				},
			];
		},
		closePinnedPanel: (state) => {
			state.pinnedPanel = [];
		},
		setOpenPinnedLoadsPanel: (state, action) => {
			state.pinnedLoads = action.payload;
		},
	},
});

export const { openPinnedPanel, closePinnedPanel, setOpenPinnedLoadsPanel } = pannelsSlice.actions;

export default pannelsSlice.reducer;
