import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function MetricCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const ownerType = props.view?.metadata?.ownerType;
	const entityType = props.view?.metadata?.entityType;
	const paramType = props.view?.metadata?.paramType;
	const linkType = props.view?.metadata?.linkType;
	const periodType = props.view?.metadata?.periodType;
	const periodTZ = props.view?.metadata?.periodTZ;
	const invalid = props.view?.metadata?.invalid;
	const error = props.view?.metadata?.error;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "ANALYTICS_METRIC_DETAILS",
		dataIds: {
			metricId: props.view.value,
		},
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={props.view} variant={variant} dialog={dialog} />}
			second={
				<div className="flex flex-wrap">
					<div className="flex flex-row">
						{invalid && (
							<CellSecondRow
								mobile={mobile}
								label={error ?? "Invalid Config"}
								variant={variant}
								color={"bg-red text-white px-6 mr-2"}
							/>
						)}
						<CellSecondRow
							mobile={mobile}
							icon={"schedule"}
							label={periodType + " (" + (periodTZ === "America/New_York" ? "EST" : periodTZ) + ")"}
							variant={variant}
							color={"text-green-900"}
						/>
						{ownerType && (
							<CellSecondRow
								mobile={mobile}
								icon={"filter_1"}
								label={ownerType}
								variant={variant}
								color={"text-blue-900"}
							/>
						)}
						{entityType && (
							<CellSecondRow
								mobile={mobile}
								icon={"filter_2"}
								label={entityType}
								variant={variant}
								color={"text-orange-900"}
							/>
						)}
					</div>
					{paramType && (
						<CellSecondRow
							mobile={mobile}
							icon={"filter_3"}
							label={paramType}
							variant={variant}
							color={"text-purple-800"}
						/>
					)}
					{linkType && (
						<CellSecondRow
							mobile={mobile}
							icon={"filter_4"}
							label={linkType}
							variant={variant}
							color={"text-green-800"}
						/>
					)}
				</div>
			}
		/>
	);
}

export default MetricCellView;
