import userPayrollForm from "./configs/userPayrollForm";
import truckStatusForm from "./configs/truckStatusForm";
import truckReminderForm from "./configs/truckReminderForm";
import { trailerForm } from "./configs/trailerForm";
import carrierDocumentsForm from "./configs/carrierDocumentsForm";
import carrierOnboardingStatus from "./configs/carrierOnboardingStatus";
import profileInformationForm from "./configs/profileInformationForm";
import accountInformationForm from "./configs/accountInformationForm";
import {
	carrierCompanyInformationForm,
	carrierCompanyChangeAccountStatusForm,
	carrierCompanyEditAndValidateForm,
	carrierCompanyValidateOnlyForm,
	carrierCompanyEditFleetNameForm,
} from "./configs/carrierCompanyInformationForm";
import carrierConfigurationForm from "./configs/carrierConfigurationForm";
import carrierAxleConnection from "./configs/carrierAxleConnection";
import carrierInvoiceTemplateForm from "./configs/carrierInvoiceTemplateForm";
import carrierBrokersForm from "./configs/carrierBrokersForm";
import carrierDispatchingAccessForm from "./configs/carrierDispatchingAccessForm";
import carrierDispatchingGenerateCodeForm from "./configs/carrierDispatchingGenerateCodeForm";
import carrierDispatchingDisconnectForm from "./configs//carrierDispatchingDisconnectForm";
import carrierDispatchingConnectAccountForm from "./configs/carrierDispatchingConnectAccountForm";
import { brandingForm, brandingFormConf } from "./configs/carrierBrandingForm";
import { brandingMailConfForm, brandingMailForm } from "./configs/carrierBrandingMailConfForm";
import { carrierInvoicingProtocolForm, carrierInvoicingProtocolFormConfig } from "./configs/carrierInvoicingConfForm";
import userDetailsForm from "./configs/userDetailsForm";
import userLastLogin from "./configs/userLastLogin";
import userDetailsPhoneForm from "./configs/userDetailsPhoneForm";
import userDetailsEmailForm from "./configs/userDetailsEmailForm";
import userConfigForm from "./configs/userConfigForm";
import userAssignmentsForm from "./configs/userAssignmentsForm";
import userSwitchAccountForm from "./configs/userSwitchAccountForm";
import fuelForm from "./configs/fuelForm";
import fuelCardPinForm from "./configs/fuelCardPinForm";
import truckPlanForm from "./configs/truckPlanForm";
import carrierInsurenceForm from "./configs/carrierInsurenceForm";
import carrierCostStructureGoalsForm from "./configs/carrierCostStructureGoalsForm";
import integrationsLoadsmartCheckCredentials from "./configs/integrationsLoadsmartCheckCredentials";
import integrationUpdateStatusForm from "./configs/integrationUpdateStatusForm";
import carrierLedgerDetailsForm from "./configs/carrierLedgerDetailsForm";
import carrierTransactionDetailsForm from "./configs/carrierTransactionDetailsForm";
import transactionsReportForm from "./configs/transactionsReportForm";
import versionForm from "./configs/versionForm";
import bidCounterOfferForm from "./configs/bidCounterOfferForm";
import bidFinalOfferForm from "./configs/bidFinalOfferForm";
import carrierLedgerSummaryForm from "./configs/carrierLedgerSummaryForm";
import fuelTransactionsSummaryForm from "./configs/fuelTransactionsSummaryForm";
import fuelTransactionPayeeForm from "./configs/fuelTransactionPayeeForm.js";
import loadReportReasonForm from "./configs/loadReportReasonForm";
import transactionsBookingForm from "./configs/transactionsBookingForm";
import transactionsPostTruckForm from "./configs/transactionsPostTruckForm";
import transactionsUnpostTruckForm from "./configs/transactionsUnpostTruckForm";
import transactionsBookingStatusForm from "./configs/transactionsBookingStatusForm";
import transactionsRetryRcForm from "./configs/transactionsRetryRcForm";
import userPasswordChangeForm from "./configs/userPasswordChangeForm";
import userEmailAliasForm from "./configs/userEmailAliasForm";
import createUserAdminForm from "./configs/createUserAdminForm";
import adminReleasesForm from "./configs/adminReleasesForm";
import invitationForm from "./configs/invitationForm";

import createTagForm from "./configs/createTagForm";
import unpostTruckForm from "./configs/unpostTruckForm";
import uploadFilesForm from "./configs/uploadFilesForm";

// on-boarding
import carrierOnboardingDocUploadTypeForm from "./configs/carrierOnboardingDocUploadTypeForm";
import carrierOnboardingMcAuthority from "./configs/carrierOnboardingMcAuthority";
import carrierOnboardingIRS from "./configs/carrierOnboardingIRS";
import carrierOnboardingInsurance from "./configs/carrierOnboardingInsurance";
import carrierOnboardingAllDocuments from "./configs/carrierOnboardingAllDocuments";

// Goals
import monthlyGoalsCarrierForm from "./configs/monthlyGoalsCarrierForm";
import carrierGoalsForm from "./configs/carrierGoalsForm";

// admin
import adminAnalyticsMetricForm from "./configs/adminAnalyticsMetricForm";
import adminAnalyticsDashboardForm from "./configs/adminAnalyticsDashboardForm";
import adminSocketActionForm from "./configs/adminSocketActionForm";
import adminChatForm from "./configs/adminChatForm";
import adminChatParticipantForm from "./configs/adminChatParticipantForm";
import adminChatMessageForm from "./configs/adminChatMessageForm";
import adminLoginAsForm from "./configs/adminLoginAsForm";
import adminResetOnboarding from "./configs/adminResetOnboarding";
import adminPaymentActionForm from "./configs/adminPaymentActionForm";
import deletePaymentForm from "./configs/deletePaymentForm";
import markAsDefaultPaymentMethodForm from "./configs/markAsDefaultPaymentMethodForm";
import unpaidInvoicesReportForm from "./configs/unpaidInvoicesReportForm";
import declinedTransactionsReportForm from "./configs/declinedTransactionsReportForm";
import adminFuelTransactionsReportForm from "./configs/adminFuelTransactionsReportForm";
import adminNotificationDevices from "./configs/adminNotificationDevices";
import adminFuelTransactionsSummaryForm from "./configs/adminFuelTransactionsSummaryForm";
import adminManualInvoiceForm from "./configs/adminManualInvoiceForm";
import adminFilterSoftSearchForm from "./configs/adminFilterSoftSearchForm";
import adminFilterHardSearchForm from "./configs/adminFilterHardSearchForm";
import adminTruckConfigurationForm from "./configs/adminTruckConfigurationForm";
import adminDriverConfigurationForm from "./configs/adminDriverConfigurationForm";
import adminIssueForm from "./configs/adminIssueForm";
import adminDeactivateIssueForm from "./configs/adminDeactivateIssueForm";

import videoForm from "./configs/videoForm";
import adminCheckBalanceForm from "./configs/adminCheckBalanceForm";
import adminCreateCronJobs from "./configs/adminCreateCronJobs";
import adminCreateAnalyticsJobs from "./configs/adminCreateAnalyticsJobs";
import adminFeatureForm from "./configs/adminFeatureForm";
import commentViewContentForm from "./configs/commentViewContentForm";
import itinerarySavedForm from "./configs/itinerarySavedForm";
import credentialsInformationForm from "./configs/credentialsInformationForm";

// Smartpay
import smartpayApplicationFormForm from "./configs/smartHopWalletApplicationFormForm";
import smartpayApplicationForm from "./configs/smartHopWalletApplicationForm";
import {
	smartHopConfirmCardReplacementForm,
	smartHopConfirmCardCloseForm,
	smartHopConfirmCardFreezeForm,
	smartHopConfirmCardUnFreezeForm,
} from "./configs/smartHopConfirmCardForm";
import { smartpayWalletAccountForm, smartpayPaymentsAccountForm } from "./configs/smartHopWalletAccountForm";

// Payment plans
import adminPlanForm from "./configs/adminPlanForm";
import adminPlatformPlanForm from "./configs/adminPlatformPlanForm";
import platformSubscriptionDiscountForm from "./configs/platformSubscriptionDiscountForm";
import adminPlanPromosForm from "./configs/adminPlanPromosForm";
import dispatchingPlanSummaryForm from "./configs/dispatchingPlanSummaryForm";
import platformPlanSummaryForm from "./configs/platformPlanSummaryForm";
import carrierApplyCouponForm from "./configs/carrierApplyCouponForm";
import terminationDateForm from "./configs/terminationDateForm";
import carrierPlatformAddonPlanForm from "./configs/carrierPlatformAddonPlanForm";

// performance report
import {
	adminCarriersPerformanceReportForm,
	adminTrucksPerformanceReportForm,
	adminDispatchersPerformanceReportForm,
	trucksPerformanceReportByCarrierForm,
	trucksPerformanceReportByDispatcherForm,
	truckPerformanceTripsReportForm,
	adminTruckPerformanceTripsReportForm,
	carrierPerformanceTripsReportForm,
	CarrierPerformanceTripsExternalReportForm,
	adminCarrierPerformanceTripsReportForm,
	dispatcherPerformanceTripsReportForm,
} from "./configs/performanceReportForms";
import { masterReportForm } from "./configs/masterReportForms";

// debug forms
import carrierOnboardingPayForm from "./configs/carrierOnboardingPayForm";
import transactionCreation from "./configs/transactionsCreationForm";
import adminFakeTransactionForm from "./configs/adminFakeTransactionForm";

// Payroll
import payrollAdditionsDeductionsForm from "./configs/payrollAdditionsDeductionsForm";
import payrollRulesForm from "./configs/payrollRulesForm";
import { carrierPayrollOwnerOperatorConfigurationForm } from "./configs/carrierPayrollOwnerOpConfigForm";
import { carrierPayrollDriverConfigurationForm } from "./configs/carrierPayrollDriverConfigForm";
import { carrierPayrollTimeZoneConfigurationForm } from "./configs/carrierPayrollTimeZoneConfigForm";
import { carrierPayrollReferenceConfigForm } from "./configs/carrierPayrollReferenceConfigForm";
import payrollEditForm from "./configs/payrollEditForm";

// External Ids
import externalIdSalesforceForm from "./configs/externalIdSalesforceForm";
import applyCouponForm from "./configs/applyCouponForm";

// Invoicing
import tripInvoicingForm from "./configs/tripInvoicingForm";

// Trip Delete
import tripDeleteForm from "./configs/tripDeleteForm";

// Performance
import adminCouponsForm from "./configs/adminCouponsForm";
import carrierPerformanceEmailForm from "./configs/carrierPerformanceEmailForm";
import carrierNotficationPerformanceForm from "./configs/carrierNotficationPerformanceForm";

//Broker
import carrierNotficationBrokerForm from "./configs/carrierNotficationBrokerForm";

// Fuel
import carrierSyncForm from "./configs/carrierSyncForm";
import fuelTransactionForm from "./configs/fuelTransactionForm";
import carrierFuelJournalEntryForm from "./configs/carrierFuelJournalEntryForm";

// Smartpay
import achAccountSummaryForm from "./configs/achAccountSummaryForm";
import adminSmartPayPayoutAccountForm from "./configs/adminSmartPayPayoutAccountForm";
import adminAchAccountSummaryForm from "./configs/adminAchAccountSummaryForm.js";
import adminSmartpayFeePlanForm from "./configs/adminSmartpayFeePlanForm";
import { carrierInvoicingInternalCopyFormConfig } from "./configs/carrierInvoicingInternalCopyConfForm";
import smartpayPayoutPlanSummaryForm from "./configs/smartpayPayoutPlanSummaryForm";
import carrierSmartpayForm from "./configs/carrierSmartpayForm";
import { carrierExternalFactoringFormConfig } from "./configs/carrierExternalFactoringConfForm";

// Billing
import carrierDispatchingPlanForm from "./configs/carrierDispatchingPlanForm";

// Reports
import generateReportForm from "./configs/generateCarrierReportForm";
import overridePlanFrom from "./configs/overridePlanFrom";

//Automation
import carrierAutomationForm from "./configs/carrierAutomationForm";

const registy = {
	ADMIN_ANALYTCIS_METRICS_FORM: adminAnalyticsMetricForm,
	ADMIN_ANALYTCIS_DASHBOARD_FORM: adminAnalyticsDashboardForm,
	ADMIN_SOCKET_FORM: adminSocketActionForm,
	ADMIN_CHAT_FORM: adminChatForm,
	ADMIN_CHAT_PARTICIPANT_FORM: adminChatParticipantForm,
	ADMIN_CHAT_MESSAGE_FORM: adminChatMessageForm,
	ADMIN_LOGIN_AS_FORM: adminLoginAsForm,
	ADMIN_RESET_ONBOARDING: adminResetOnboarding,
	ADMIN_TRUCK_CONFIGURATION_FORM: adminTruckConfigurationForm,
	ADMIN_DRIVER_CONFIGURATION_FORM: adminDriverConfigurationForm,
	DRIVER_PAYROLL_FORM: ({ user }) => userPayrollForm({ isDriver: true, user }),
	USER_PAYROLL_FORM: ({ user }) => userPayrollForm({ isDriver: false, user }),
	INVESTOR_PAYROLL_FORM: ({ user }) => userPayrollForm({ isDriver: false, isInvestor: true, user }),
	DISPATCHER_PAYROLL_FORM: ({ user }) => userPayrollForm({ isDispatcher: true, user }),
	TRIP_DRIVER_PAYROLL_FORM: ({ user }) => userPayrollForm({ isTrip: true, isDriver: true, user }),
	TRIP_INVESTOR_PAYROLL_FORM: ({ user }) => userPayrollForm({ isTrip: true, isInvestor: true, user }),
	TRIP_DISPATCHER_PAYROLL_FORM: ({ user }) => userPayrollForm({ isTrip: true, isDispatcher: true, user }),
	TRUCK_STATUS_FORM: truckStatusForm,
	TRUCK_REMINDER_FORM: truckReminderForm,
	VIDEO_FORM: videoForm,
	TRAILER_FORM: trailerForm,
	PROFILE_INFORMATION_FORM: profileInformationForm,
	ACCOUNT_INFORMATION_FORM: accountInformationForm,
	CARRIER_COMPANY_INFORMATION_FORM: carrierCompanyInformationForm,
	CARRIER_COMPANY_CHANGE_ACCOUNT_STATUS_FORM: carrierCompanyChangeAccountStatusForm,
	CARRIER_COMPANY_EDIT_AND_VALIDATE_FORM: carrierCompanyEditAndValidateForm,
	CARRIER_COMPANY_VALIDATE_ONLY_FORM: carrierCompanyValidateOnlyForm,
	CARRIER_COMPANY_EDIT_FLEET_NAME_FORM: carrierCompanyEditFleetNameForm,
	CARRIER_DOCUMENTS_FORM: carrierDocumentsForm,
	CARRIER_PAYROLL_TIME_ZONE_CONFIGURATION_FORM: carrierPayrollTimeZoneConfigurationForm,
	CARRIER_PAYROLL_REFERENCE_CONFIGURATION_FORM: carrierPayrollReferenceConfigForm,
	CARRIER_PAYROLL_OWNER_OPERATOR_CONFIGURATION_FORM: carrierPayrollOwnerOperatorConfigurationForm,
	CARRIER_PAYROLL_DRIVER_CONFIGURATION_FORM: carrierPayrollDriverConfigurationForm,
	CARRIER_NOTIFICATION_PERFORMANCE_CONFIGURATION_FORM: carrierNotficationPerformanceForm,
	CARRIER_NOTIFICATION_BROKER_CONFIGURATION_FORM: carrierNotficationBrokerForm,
	CARRIER_AUTOMATION_FORM: carrierAutomationForm,
	CARRIER_PERFORMANCE_EMAIL_FORM: carrierPerformanceEmailForm,
	CARRIER_ONBOARDING_STATUS_FORM: () => carrierOnboardingStatus(),
	CARRIER_BROKERS_FORM: carrierBrokersForm,
	CARRIER_CONFIGURATION_FORM: ({ user: { features } }) => carrierConfigurationForm({ features }),
	CARRIER_AXLE_CONNECTION: carrierAxleConnection(),
	CARRIER_BRANDING_FORM: () => brandingForm(),
	CARRIER_BRANDING_LOGO_ONBOARDING_FORM: brandingFormConf,
	CARRIER_BRANDING_MAIL_CONF_FORM: () => brandingMailForm(),
	CARRIER_BRANDING_EMPTY_MAIL_CONF_FORM: () => brandingMailForm(false, true),
	CARRIER_BRANDING_MAIL_CONF_GMAIL_FORM: () => brandingMailForm(true),
	CARRIER_BRANDING_MAIL_ONBOARDING_FORM: brandingMailConfForm,
	CARRIER_INVOICING_CONF_FORM: () => carrierInvoicingProtocolForm(),
	CARRIER_EXTERNAL_FACTORING_CONFIG: () => carrierExternalFactoringFormConfig,
	CARRIER_INVOICING_PROTOCOL_CONF_FORM: carrierInvoicingProtocolFormConfig,
	CARRIER_INVOICING_INTERNAL_COPY_CONF_FORM: carrierInvoicingInternalCopyFormConfig,
	CARRIER_DISPATCHING_ACCESS_FORM: carrierDispatchingAccessForm,
	CARRIER_DISPATCHING_GENERATE_CODE_FORM: carrierDispatchingGenerateCodeForm,
	CARRIER_DISPATCHING_CONNECT_ACCOUNT_FORM: carrierDispatchingConnectAccountForm,
	CARRIER_DISPATCHING_DISCONNECT_FROM_CARRIER_FORM: carrierDispatchingDisconnectForm("CARRIER"),
	CARRIER_DISPATCHING_DISCONNECT_FROM_DISPATCH_FORM: carrierDispatchingDisconnectForm("DISPATCH"),
	USER_DETAILS_FORM: userDetailsForm,
	USER_DETAILS_PHONE_FORM: userDetailsPhoneForm,
	USER_DETAILS_EMAIL_FORM: userDetailsEmailForm,
	USER_LAST_LOGIN_FORM: userLastLogin,
	USER_CONFIG_FORM: userConfigForm,
	USER_ASSIGNEMTS_FORM: userAssignmentsForm,
	USER_SWITCH_ACCOUNT_FORM: userSwitchAccountForm({ newFlow: false }),
	USER_LOGIN_AS_SUBACCOUNT_FORM: userSwitchAccountForm({ newFlow: true }),
	FUEL_FORM: fuelForm,
	FUEL_CARD_PIN_FORM: fuelCardPinForm,
	TRUCK_PLAN_FORM: truckPlanForm,
	ADMIN_CREATE_CRON_JOBS: adminCreateCronJobs,
	ADMIN_CREATE_ANALYTICS_JOBS: adminCreateAnalyticsJobs,
	INTEGRATIONS_LOADSMART_CHECK_CREDENTIALS: integrationsLoadsmartCheckCredentials,
	INTEGRATION_UPDATE_STATUS_FORM: integrationUpdateStatusForm,
	ONBOARDING_DOCUMENT_UPLOADING_TYPE: carrierOnboardingDocUploadTypeForm,
	ONBOARDING_DOCUMENT_AUTHORITY: carrierOnboardingMcAuthority,
	ONBOARDING_ALL_DOCUMENTS: carrierOnboardingAllDocuments,
	ONBOARDING_DOCUMENT_IRS: carrierOnboardingIRS,
	ONBOARDING_DOCUMENT_INSURANCE: carrierOnboardingInsurance,
	ONBOARDING_DOCUMENT_PAY: carrierOnboardingPayForm,
	ADMIN_PAYMENT_ACTION_FORM: adminPaymentActionForm,
	DELETE_PAYMENT_METHOD_FORM: deletePaymentForm,
	DELETE_PLAN_ADDON_FORM: deletePaymentForm,
	MARK_PAYMENT_METHOD_AS_DEFAULT_FORM: markAsDefaultPaymentMethodForm,
	CARRIER_INSURENCE_FORM: carrierInsurenceForm,
	COST_STRUCTURE_GOALS_FORM: carrierCostStructureGoalsForm,
	CARRIER_LEDGER_DETAILS_FORM: carrierLedgerDetailsForm,
	CARRIER_TRANSACTION_DETAILS_FORM: carrierTransactionDetailsForm,
	VERSION_FORM: versionForm,
	BID_COUNTER_OFFER_FORM: bidCounterOfferForm,
	BID_FINAL_OFFER_FORM: bidFinalOfferForm,
	ITINERARY_SAVED_FORM: itinerarySavedForm,
	ADMIN_NOTIFICATION_DEVICE: adminNotificationDevices,
	CARRIER_LEDGER_SUMMARY_FORM: carrierLedgerSummaryForm,
	FUEL_TRANSACTIONS_SUMMARY_FORM: fuelTransactionsSummaryForm,
	FUEL_TRANSACTION_PAYEE_FORM: fuelTransactionPayeeForm,
	ADMIN_FUEL_TRANSACTIONS_SUMMARY_FORM: adminFuelTransactionsSummaryForm,
	ADMIN_MANUAL_INVOICE_FORM: adminManualInvoiceForm,
	COMMENT_VIEW_CONTENT: commentViewContentForm,
	CHECK_BALANCE_FORM: adminCheckBalanceForm,
	USER_PASSWORD_CHANGE_FORM: userPasswordChangeForm,
	USER_EMAIL_ALIAS_FORM: userEmailAliasForm,
	CREATE_USER_ADMIN_FORM: createUserAdminForm,
	ADMIN_FILTER_SOFT_SEARCH_FORM: adminFilterSoftSearchForm,
	ADMIN_FILTER_HARD_SEARCH_FORM: adminFilterHardSearchForm,
	TRANSACTIONS_MULTI_UPLOAD_FORM: transactionCreation("MULTI_UPLOAD"),
	TRANSACTIONS_CREATION_TRIP_FORM: transactionCreation("CREATE_TRIP"),
	TRANSACTIONS_CREATION_TRANSFER_FUNDS_FORM: transactionCreation("TRANSFER_FUNDS"),
	TRANSACTIONS_CREATION_SH_WALLET_CLOSE_ACCOUNT_FORM: transactionCreation("SH_WALLET_CLOSE_ACCOUNT"),
	TRANSACTIONS_CREATION_REQUEST_BUSINESS_DEBIT_CARD_FORM: transactionCreation("REQUEST_BUSINESS_DEBIT_CARD"),
	TRANSACTIONS_UPLOADED_FILE_ANALIZE_FORM: transactionCreation("FILE_UPLOAD"),
	TRANSACTIONS_BOOKING_FORM: transactionsBookingForm,
	TRANSACTIONS_POST_TRUCK_FORM: transactionsPostTruckForm,
	TRANSACTIONS_UNPOST_TRUCK_FORM: transactionsUnpostTruckForm,
	TRANSACTIONS_BOOKING_STATUS_FORM: transactionsBookingStatusForm,
	TRANSACTIONS_RETRY_RC_FORM: transactionsRetryRcForm,
	CARRIER_INVOICE_TEMPLATE_FORM: carrierInvoiceTemplateForm,
	LOAD_REPORT_REASON_FORM: loadReportReasonForm,
	CREATE_TAG_FORM: createTagForm,
	UNPOST_TRUCK_FORM: unpostTruckForm,
	CREDENTIAL_INFORMATION_FORM: credentialsInformationForm,
	ADMIN_RELEASES: adminReleasesForm,
	GENERATE_REPORT: generateReportForm,
	//Download file forms
	UNPAID_INVOICES_REPORT_FORM: unpaidInvoicesReportForm,
	DECLINED_TRANSACTIONS_REPORT_FORM: declinedTransactionsReportForm,
	ADMIN_FUEL_TRANSACTIONS_REPORT_FORM: adminFuelTransactionsReportForm,
	TRANSACTIONS_REPORT_FORM: transactionsReportForm,
	ADMIN_CARRIERS_PERFORMANCE_REPORT: adminCarriersPerformanceReportForm,
	ADMIN_TRUCKS_PERFORMANCE_REPORT: adminTrucksPerformanceReportForm,
	ADMIN_DISPATCHERS_PERFORMANCE_REPORT: adminDispatchersPerformanceReportForm,
	TRUCKS_PERFORMANCE_REPORT_BY_CARRIER: trucksPerformanceReportByCarrierForm,
	TRUCKS_PERFORMANCE_REPORT_BY_DISPATCHER: trucksPerformanceReportByDispatcherForm,
	ADMIN_TRUCK_PERFORMANCE_TRIPS_REPORT: adminTruckPerformanceTripsReportForm,
	TRUCK_PERFORMANCE_TRIPS_REPORT: truckPerformanceTripsReportForm,
	ADMIN_CARRIERS_PERFORMANCE_TRIPS_REPORT: adminCarrierPerformanceTripsReportForm,
	CARRIER_PERFORMANCE_TRIPS_REPORT: carrierPerformanceTripsReportForm,
	CARRIER_PERFORMANCE_TRIPS_EXTERNAL_REPORT: CarrierPerformanceTripsExternalReportForm,
	DISPATCHER_PERFORMANCE_TRIPS_REPORT: dispatcherPerformanceTripsReportForm,
	MASTER_REPORT: masterReportForm,
	UPLOAD_FILES_FORM: uploadFilesForm,
	INVITATION_FORM: invitationForm,

	// DERECATED - keeping for now for mobile backwards compatibilty
	UPLOAD_FILE_FORM_POP: uploadFilesForm,
	TRIPS_NO_POD_FORM: uploadFilesForm,
	TRIPS_NO_POP_FORM: uploadFilesForm,

	ADMIN_FEATURE_FORM: adminFeatureForm,
	// Smartpay
	SMARTPAY_APPLICATIONFORM_FORM: smartpayApplicationFormForm,
	SMARTPAY_APPLICATION_FORM: smartpayApplicationForm,
	SMARTPAY_WALLET_ACCOUNT_FORM: smartpayWalletAccountForm,
	SMARTPAY_PAYMENTS_ACCOUNT_FORM: smartpayPaymentsAccountForm,
	SMARTPAY_CONFIRM_CARD_REPLACEMENT_FORM: smartHopConfirmCardReplacementForm,
	SMARTPAY_CONFIRM_CARD_FREEZE_FORM: smartHopConfirmCardFreezeForm,
	SMARTPAY_CONFIRM_CARD_UNFREEZE_FORM: smartHopConfirmCardUnFreezeForm,
	SMARTPAY_CONFIRM_CARD_CLOSE_FORM: smartHopConfirmCardCloseForm,
	// Payment plans
	ADMIN_DISPATCHING_PLAN_FORM: adminPlanForm,
	ADMIN_PLATFORM_PLAN_FORM: adminPlatformPlanForm,
	ADMIN_COUPONS_FORM: adminCouponsForm,
	ADMIN_SMARTPAY_FEE_PLAN_FORM: adminSmartpayFeePlanForm,
	ADMIN_PLATFORM_SUBSCRIPTION_DISCOUNT_FORM: platformSubscriptionDiscountForm,
	APPLY_COUPON_FORM: applyCouponForm,
	CARRIER_APPLY_COUPON_FORM: carrierApplyCouponForm,
	ADMIN_PLAN_PROMO_FORM: adminPlanPromosForm,
	DISPATCHING_PLAN_SUMMARY_FORM: dispatchingPlanSummaryForm,
	PLATFORM_PLAN_SUMMARY_FORM: platformPlanSummaryForm,
	CARRIER_PLATFORM_ADDON_PLAN_FORM: carrierPlatformAddonPlanForm,
	TERMINATION_DATE_FORM: terminationDateForm,
	OVERRIDE_PLAN_FORM: overridePlanFrom,
	ADMIN_FAKE_TRANSACTION: adminFakeTransactionForm,
	// Goals
	MONTHLY_GOALS_CARRIER_FORM: monthlyGoalsCarrierForm,
	CARRIER_GOALS_FORM: carrierGoalsForm,
	// Payroll
	// TODO | Remove when all drivers have users and payroll can depend on user only.
	PAYROLL_ADDITIONS_DEDUCTIONS_FORM: payrollAdditionsDeductionsForm({ isDriver: true }),
	PAYROLL_USER_ADDITIONS_DEDUCTIONS_FORM: payrollAdditionsDeductionsForm({}),
	PAYROLL_INVESTOR_ADDITIONS_DEDUCTIONS_FORM: payrollAdditionsDeductionsForm({ isDriver: false, isInvestor: true }),
	PAYROLL_RULES: payrollRulesForm,
	PAYROLL_EDIT_FORM: payrollEditForm,
	// Externals Ids (Salesforce)
	EXTERNAL_ID_SALESFORCE: externalIdSalesforceForm,
	// Trip Invoice
	TRIP_INVOICE_FORM: tripInvoicingForm,
	TRIP_DELETE_FORM: tripDeleteForm,

	// SmartFuel
	CUSTOMER_SYNC_FORM: carrierSyncForm,
	FUEL_TRANSACTION_FORM: fuelTransactionForm,
	CARRIER_FUEL_JOURNAL_ENTRY: carrierFuelJournalEntryForm,
	CARRIER_SMARTFUEL_ACCOUNT_FORM: achAccountSummaryForm(),
	ADMIN_SMARTFUEL_ACCOUNT_FORM: adminAchAccountSummaryForm,

	// SmartPay
	CARRIER_SMARTPAY_FORM: carrierSmartpayForm,
	CARRIER_SMARTPAY_PAYOUT_ACCOUNT_FORM: achAccountSummaryForm(true),
	CARRIER_SMARTPAY_PAYOUT_PLAN_FORM: smartpayPayoutPlanSummaryForm,
	ADMIN_SMARTPAY_PAYOUT_ACCOUNT_FORM: adminSmartPayPayoutAccountForm,

	ADMIN_ACH_ACCOUNT_FORM: adminAchAccountSummaryForm,
	ADMIN_ISSUES: adminIssueForm,
	ADMIN_ISSUES_DEACTIVATE: adminDeactivateIssueForm,

	// Billing
	CARRIER_DISPATCHING_PLAN_FORM: carrierDispatchingPlanForm,
};

/**
 * Enables passing params to a form builder function
 * For example:
 * KEY: ({ user }) => method(params),
 * returns a function instead of the form object to build the
 * form on the go with fresh context parameters
 * @param {*} formId
 * @param {*} params | user
 * @returns The form shape object
 */
export const buildForm = (formId, { user }) => {
	const formBuilder = registy[formId];
	if (typeof formBuilder === "function") {
		return formBuilder({ user });
	}
	return formBuilder;
};

export default registy;
