import ReactDOM from "react-dom";
import "typeface-poppins";
import "./app-init/i18n";
import "./app-init/react-chartjs-2-defaults";
import "./styles/app-base.css";
import "./styles/app-components.css";
import "./styles/app-utilities.css";
import * as serviceWorker from "./app-init/serviceWorker";
import reportWebVitals from "./app-init/reportWebVitals";

// Styles required to dashboards grid layouts
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// TODO: global file should be removed completely
// eslint-disable-next-line
import global from "app/services/requestUtil";

// Main component
import NewApp from "app/NewApp";
ReactDOM.render(<NewApp />, document.getElementById("root"));

reportWebVitals();
serviceWorker.unregister();
