import { sort } from "fast-sort";

/**
 * @description returns an array sorted by key
 * @param {Array} array
 * @param {String} key
 * @param {String} dir
 * @returns {Array}
 */
const sortArray = (array, key, dir) => {
	if (dir === "asc") return sort(array).asc((u) => u?.[key]);
	return sort(array).desc((u) => u?.[key]);
};
/**
 * @description returns the equipment from the localstorage if i don have it,in there i set the default value van
 * @returns {String}
 */
const getLaneEquipment = () => {
	const equipmentLane = localStorage.getItem("lane-equipment");
	return equipmentLane ?? "van";
};

export { sortArray, getLaneEquipment };
