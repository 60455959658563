import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import moment from "moment";
import _ from "lodash";

import { Button, CardMedia, CircularProgress, Grid, Switch, Typography, makeStyles } from "@material-ui/core";

import { showSnackbar } from "app/main/utils/snackbarUtil";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { getTripTestDataPreview, saveTripTestData } from "app/services/multiUploadServices";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import tripPreviewTestDataForm from "@smarthop/form/configs/tripPreviewTestDataForm";
import TripTestDataConfirmDialog from "./TripTestDataConfirmDialog";

const formatDate = (date) => date && moment(date).format("YYYY-MM-DD HH:mm");

const stopKeys = ["date", "byDate", "address", "shipper", "zip", "phone", "contact", "type"];

const stopKeyFilter = (stop) => {
	const result = Object.keys(stop)
		.filter((key) => stopKeys.includes(key))
		.reduce((acc, curr) => ({ ...acc, [curr]: stop[curr] }), {});
	return result;
};

const flattenObject = (obj, parentKey = "", result = {}) => {
	for (let key in obj) {
		// Construct new key
		let newKey = parentKey ? `${parentKey}_${key}` : key;

		// If this property is an object, recurse further
		if (typeof obj[key] === "object" && !Array.isArray(obj[key]) && obj[key] !== null) {
			flattenObject(obj[key], newKey, result);
		} else {
			// Otherwise, add the property to the result object
			result[newKey] = obj[key];
		}
	}
	return result;
};

const unflattenObject = (flattenedObj) => {
	const result = {};

	for (const key in flattenedObj) {
		const parts = key.split("_");
		let current = result;

		for (let i = 0; i < parts.length; i++) {
			// If we're not at the last part, we need to create an object if it doesn't exist
			if (i < parts.length - 1) {
				current[parts[i]] = current[parts[i]] || {};
				current = current[parts[i]];
			} else {
				// If we're at the last part, assign the value
				current[parts[i]] = flattenedObj[key];
			}
		}
	}

	return result;
};

const useStyles = makeStyles({
	defaultPreviewHeight: {
		height: "calc(100vh - 315px) !important",
	},
});

const TripTestDataView = ({ dataIds, onProgress, setTitle, setSize }) => {
	const classes = useStyles();
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	useEffect(() => {
		setTitle?.("Save Trip for Testing");
		setSize?.("max-w-4xl");
		// eslint-disable-next-line
	}, []);

	const tripId = dataIds?.tripId;
	const carrierId = dataIds?.carrierId;
	const fileId = dataIds?.fileId;
	const urlFilePreview = dataIds?.urlFilePreview;

	const [annotatedData, setAnnotatedData] = useState({ original: null, edited: null });
	const [source, setSource] = useState(null);
	const [newUserMetadata, setNewUserMetadata] = useState(null);

	const [previewLoading, setPreviewLoading] = useState(true);
	const [fileLoading, setFileLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [isFormView, setIsFormView] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);

	const [formRevision, setFormRevision] = useState({ revision: 0 });

	const generateTripTestData = async (regenerate) => {
		try {
			onProgress?.(true);
			const { annotatedData, source, newUserMetadata } = await getTripTestDataPreview(tripId, regenerate);
			setAnnotatedData({ original: flattenObject(annotatedData) });
			setSource(source);
			setNewUserMetadata(newUserMetadata);
			onProgress?.(false);
			setPreviewLoading(false);
		} catch (err) {
			const message = "Error loading JSON preview";
			showSnackbar(snackbar, message, "error");
			setAnnotatedData({ original: { error: "Couldn't load data preview" } });
			setPreviewLoading(false);
			onProgress?.(false);
		}
	};

	useEffect(() => {
		generateTripTestData();
		// eslint-disable-next-line
	}, []);

	const save = async () => {
		try {
			setProcessing(true);

			const saveData = getUnflattenedData(true);

			if (newUserMetadata && (source === "retrieved" || source === "regenerated")) {
				saveData.metadata.annotatedBy = newUserMetadata;
			}

			await saveTripTestData({
				carrierId,
				fileId,
				tripId,
				annotatedData: saveData,
			});

			setProcessing(false);

			const message = "Test data saved successfully";
			showSnackbar(snackbar, message, "success");

			dispatch(closeFormDialog());
		} catch (err) {
			const message = err?.errors?.[0]?.message ?? "Oops, something went wrong...";
			showSnackbar(snackbar, message, "error");
			setProcessing(false);
		}
	};

	const handleSave = () => {
		if (source === "retrieved" || source === "regenerated") {
			setDialogOpen(true);
		} else {
			save();
		}
	};

	const handleAccept = () => {
		save();
		setDialogOpen(false);
	};

	const handleClose = () => {
		setDialogOpen(false);
	};

	const handleRegenerate = () => {
		generateTripTestData(true);
	};

	const formatValue = (obj, key) => (/date/gi.test(key) ? formatDate(obj[key]) : obj[key]);

	const cleanData = useCallback((data, filterEmptyStops) => {
		const cleanedData =
			data &&
			Object.keys(data)
				.filter((key) => !key.includes("__"))
				.reduce(
					(acc, key) => ({
						...acc,
						[key]: key?.includes("annotatedBy") ? data[key] : formatValue(data, key),
					}),
					{}
				);
		cleanedData?.data_stops?.forEach((stop, i) => {
			cleanedData.data_stops[i] = Object.keys(stop).reduce(
				(acc, key) => ({ ...acc, [key]: formatValue(stop, key) }),
				{}
			);
		});

		if (filterEmptyStops) {
			cleanedData.data_stops = cleanedData?.data_stops?.filter((stop) => !_.isEmpty(stop));
		}

		return cleanedData;
	}, []);

	const getUnflattenedData = useCallback(
		(filterEmptyStops) => {
			const { original, edited } = annotatedData;
			const originalClean = cleanData(original, filterEmptyStops);
			const editedClean = cleanData(edited, filterEmptyStops);

			return unflattenObject({ ...originalClean, ...editedClean });
		},
		[annotatedData, cleanData]
	);

	const handleChange = (model) => {
		if (!annotatedData.original) return;
		getUnflattenedData();
		const originalData = annotatedData?.original;

		const { data_stops, ...modelData } = model;

		const editedStops = data_stops?.map(stopKeyFilter) ?? [];

		const editedData = {
			...originalData,
			...modelData,
			...(editedStops.length ? { data_stops: editedStops } : {}),
		};

		setAnnotatedData({ ...annotatedData, edited: editedData });
	};

	return (
		<div className={clsx("flex row-reverse", classes.defaultPreviewHeight)}>
			<div className="flex w-1/2">
				<div className="flex w-full flex-col items-center h-full justify-start">
					{previewLoading && (
						<div className={clsx("flex w-full flex-col items-center h-full justify-center")}>
							<Typography className="flex text-14 px-20 py-2 text-grey ">Loading data preview...</Typography>
							<CircularProgress />
						</div>
					)}
					{!previewLoading && (
						<div className="flex w-full flex-col items-center h-full border rounded-lg border-black p-4 mr-8">
							<div className="flex justify-between items-center text-gray-600 border-b-2 border-black w-full px-20 py-5">
								<label>Data Preview</label>
								<div className="flex justify-center items-center gap-x-10">
									<Button
										variant="text"
										color="secondary"
										disabled={!annotatedData?.edited}
										onClick={() => {
											setAnnotatedData({ ...annotatedData, edited: null });
											setFormRevision((prev) => ({
												revision: prev.revision + 1,
											}));
										}}
									>
										Reset
									</Button>
									<Typography component="div">
										<Grid component="label" container alignItems="center" spacing={1}>
											<Grid item>JSON</Grid>
											<Grid item>
												<Switch
													checked={isFormView}
													onChange={() => {
														setIsFormView((prev) => !prev);
													}}
													name="form-toggle"
												/>
											</Grid>
											<Grid item>Form</Grid>
										</Grid>
									</Typography>
								</div>
							</div>
							{source === "retrieved" && (
								<div className="flex justify-between items-center bg-orange-700 text-white border-b-2 border-black w-full px-20 py-5">
									<label>Previewing Saved Test Data</label>
									<Button onClick={handleRegenerate} variant="contained" size="small">
										{" "}
										Regenerate{" "}
									</Button>
								</div>
							)}
							<div
								className={`overflow-y-scroll overflow-x-hidden w-full px-10 py-16 ${!isFormView ? "hidden" : ""}`}
								key={`data_container_${formRevision.revision}`}
							>
								<SmarthopFormView
									key={`form_preview_data`}
									mode={"EDIT"}
									content={tripPreviewTestDataForm}
									data={annotatedData.original}
									trackChangedFields={["ALL"]}
									onChangeCommitted={handleChange}
								/>
							</div>
							<pre className={`w-full whitespace-pre-wrap px-10 py-5 ${isFormView ? "hidden" : ""}`}>
								{JSON.stringify(getUnflattenedData(), 0, 4)}
							</pre>
							<Button
								disabled={!annotatedData?.edited || processing}
								onClick={handleSave}
								color="secondary"
								className={"w-full mb-2 mt-4"}
								variant="contained"
							>
								{!processing ? "Save" : <CircularProgress size="2.2rem" />}
							</Button>
						</div>
					)}
				</div>
			</div>
			<div className="flex w-1/2">
				{fileLoading && (
					<div className={clsx("flex w-full flex-col items-center h-full justify-center")}>
						<Typography className="flex text-14 px-20 py-2 text-grey ">Loading file preview...</Typography>
						<CircularProgress />
					</div>
				)}
				<CardMedia
					component="iframe"
					className={clsx(
						"-my-8 flex w-full overflow-hidden ",
						classes.defaultPreviewHeight,
						fileLoading ? "hidden" : ""
					)}
					image={urlFilePreview}
					onLoad={() => {
						setFileLoading(false);
					}}
				/>
			</div>
			{(source === "retrieved" || source === "regenerated") && (
				<TripTestDataConfirmDialog
					open={dialogOpen}
					onAccept={handleAccept}
					onClose={handleClose}
					userMetadata={newUserMetadata}
				/>
			)}
		</div>
	);
};

export default TripTestDataView;
