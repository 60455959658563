class NetwrokError extends Error {
	constructor(message, status, data) {
		super(message);
		this.message = message;
		this.status = !!status ? status : "";
		this.data = !!data ? data : {};
		this.name = "NetwrokError";
	}
}

export default NetwrokError;
