const createUserAdminForm = {
	urlPOST: "api/profile/users",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		create: { label: "Create User" },
	},
	content: {
		create: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "first_name",
								type: "text",
								label: "First Name",
								translate: "FIRST_NAME",
								required: true,
							},
							{
								key: "last_name",
								type: "text",
								label: "Last Name",
								translate: "LAST_NAME",
								required: true,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "email",
								type: "email",
								label: "Email",
								translate: "EMAIL",
								required: true,
							},
							{
								key: "phone",
								type: "text",
								label: "Phone",
								translate: "PHONE",
								required: true,
							},
						],
					},
				},
				{
					key: "role",
					type: "select",
					label: "Role",
					translate: "ROLE",
					required: true,
					options: [
						{
							value: "0",
							label: "Administrator",
							showItems: [
								{
									key: "gatekeepers.permission_account_impersonation_on", // FIXME: FAC-2052 Invalid key format
									type: "checkbox",
									label: "Allow Account Impersonation",
								},
							],
						},
						{
							value: "1",
							label: "Ops Dispatcher",
						},
						{
							value: "12",
							label: "Ops Support",
						},
						{
							value: "9",
							label: "Bot",
						},
					],
				},
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "Are you sure you want to create this user?",
					},
				},
			],
		},
	},
};

export default createUserAdminForm;
