import { createRequest, throwResErrors, createHeaders } from "./requestUtil";
import { buildURLParameters } from "app/main/utils/urlUtils";

export const getUserConfig = (userId) => {
	return createRequest()
		.get(`api/profile/users/${userId}/config`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateUserConfig = (userId, data) => {
	return createRequest()
		.put(`api/profile/users/${userId}/config`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const addUserChatAssignment = (userId, chatId, data) => {
	return createRequest()
		.post(`api/profile/users/${userId}/assignments/chats/${chatId}`, data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getTrucksByTags = (tags) => {
	const params = {
		options_tags_search: tags?.join(","),
		options_metadata_truck: true,
		options_metadata_carrier: true,
		options_format_show_carrier: true,
		options_metadata_driver: true,
		options_active_only: true,
	};
	const url = buildURLParameters("api/profile/trucks", params);

	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getDetailsUser = (userId, carrierId) => {
	return createRequest()
		.get(`api/profile/carrier/${carrierId}/users/${userId}/details`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateUserDetails = (userId, data) => {
	return createRequest()
		.put(`api/profile/users/${userId}/details`, { headers: createHeaders(), data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const verifyPhoneConfirmation = (userId, data) => {
	return createRequest()
		.post(`api/profile/users/${userId}/phone/verify-code`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const generatePhoneConfirmation = (userId, data) => {
	return createRequest()
		.post(`api/profile/users/${userId}/phone/send-code`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const verifyEmailConfirmation = (userId, data) => {
	return createRequest()
		.post(`api/profile/users/${userId}/email/verify-code`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const generateEmailConfirmation = (userId, data) => {
	return createRequest()
		.post(`api/profile/users/${userId}/email/send-code`, { data }, { headers: createHeaders() })
		.then((res) => {
			return res.data;
		})
		.catch((error) => throwResErrors(error));
};

export const getUserInternalDetails = (userId, options) => {
	return createRequest()
		.get(`api/profile/users/${userId}/details?version=${options?.version ?? 1}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getUserDetails = (userId, carrierId, options) => {
	return createRequest()
		.get(`api/profile/carrier/${carrierId}/users/${userId}/details?version=${options?.version ?? 1}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getUserDriverDetails = (driverId, options) => {
	return createRequest()
		.get(`api/profile/drivers/${driverId}/details?version=${options?.version ?? 1}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getUsersValidation = (carrierId, data) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/users-validation`, { params: data, headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createCarriertUser = (carrierId, data) => {
	return createRequest()
		.post(`api/profile/carriers/${carrierId}/users`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateCarriertUser = (carrierId, userId, data, options) => {
	return createRequest()
		.put(
			`api/profile/carriers/${carrierId}/users/${userId}`,
			{ data },
			{ headers: createHeaders(), params: { ...options } }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const deactivateCarriertUser = (carrierId, userId, data, options) => {
	return createRequest()
		.delete(
			`api/profile/carriers/${carrierId}/users/${userId}?version=${options?.version ?? 1}`,
			{ data },
			{ headers: createHeaders() }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierMainAccountDetails = (carrierId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/main-account-details`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getCarrierUsers = (carrierId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/users`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getInviteLink = (carrierId, userId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/users/${userId}/invitation/get`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
