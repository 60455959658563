// Cells
import CellFirstRow from "./CellFirstRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

// Utils
import { WALLET_STATUSES } from "app/main/utils/financeUtils";

const SmartHopWalletCellView = (props) => {
	const mobile = props.mobile;
	const variant = props.variant;
	const view = props.view;
	const carrierId = view?.value;
	const companyName = view?.label;
	const walletData = view?.metadata;
	const walletStatus = WALLET_STATUSES[walletData?.status] ?? WALLET_STATUSES.inactive;
	const label = walletStatus?.label;

	const factoringRequestDetailDialog = {
		viewId: "SMARTHOP_WALLET_VIEW",
		dataIds: {
			carrierId,
			companyName,
		},
	};

	if (!label) return <CellEmptyRow mobile={mobile} variant={variant} />;

	const IconComponent = walletStatus?.iconComponent;
	const icon = (
		<div className="flex flex-row items-center pr-4">
			<IconComponent
				sx={{ fontSize: 26 }}
				className={`${walletStatus?.color} m-0 p-0 text-13 ml:text-18 -mb-3 ml:-mb-2`}
			/>
		</div>
	);

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			left={icon}
			first={<CellFirstRow mobile={mobile} view={{ label }} dialog={factoringRequestDetailDialog} variant={variant} />}
		/>
	);
};

export default SmartHopWalletCellView;
