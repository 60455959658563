import { reportFiltersForm } from "app/main/utils/fileUtils";

const unpaid_invoices_report = reportFiltersForm(
	"billing/unpaid-invoices/download/csv",
	"Download Report",
	"Unpaid invoices report.csv",
	"text/csv"
);

export default unpaid_invoices_report;
