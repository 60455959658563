import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Icon } from "@material-ui/core";
import { createTooltip } from "app/main/utils/tableUtils";

function SmarthopCheckboxField(props) {
	const edited =
		props.orignialValue === false || props.orignialValue === true || props.orignialValue === ""
			? props.orignialValue + "" !== (props.value ?? "") + ""
				? " (Edited)"
				: ""
			: "";

	const label =
		typeof props.label === "string" || props.label instanceof String ? (
			props.label + (props.required ? " *" : "") + edited
		) : (
			<>
				{props.label}
				{edited}
			</>
		);

	const value = props.value;
	const checked = value === true || value === false ? (props.checkbox?.inverted ? !value : value) : false;

	if (props.checkbox?.variant === "row") {
		return (
			<div className={"flex w-full flex-row justify-between border-b-1 border-grey-300 mt-10"}>
				<Typography
					className={"flex w-full text-black text-12 ml:text-13 pt-2 " + (props.disabled ? " opacity-60 " : "")}
				>
					{label}
				</Typography>
				<div className={"flex -mt-10 -mb-3"}>
					<Switch
						disabled={props.disabled}
						checked={checked}
						onChange={(event, value) => {
							if (props.checkbox?.inverted) {
								event = { target: { value: !value } };
								value = !value;
							}

							if (props.onChange) props.onChange(event, value);
							if (props.onChangeCommitted) props.onChangeCommitted(props.name, value);
						}}
					/>
				</div>
			</div>
		);
	} else {
		return (
			<div
				className={
					"w-full flex flex-col px-4 " +
						(props.checkbox?.variant !== "skinny"
							? props.checkbox?.disclaimer
								? " mb-2 mt-12 "
								: " mb-4 mt-4 "
							: " -mb-2 ") +
						(props?.checkbox?.noErrorMessage && props.checkbox?.variant !== "skinny" ? " pt-4 pb-4 " : "") +
						" " +
						props?.checkbox?.classes?.root ?? ""
				}
			>
				<div
					className={
						"w-full flex pl-2  " + (!props.checkbox?.disclaimer?.length ? " -mt-5 pb-3 " : " -mt-14 pt-4 pb-0 ")
					}
				>
					<FormControlLabel
						label={
							props.checkbox?.disclaimer ? (
								<Typography className={props.checkbox?.variant !== "skinny" ? "mb-8" : "mb-6"}>{label}</Typography>
							) : (
								label
							)
						}
						control={
							<Checkbox
								size={props.checkbox?.variant === "skinny" ? "small" : undefined}
								icon={props?.checkbox?.icon && <Icon>{props?.checkbox?.icon}</Icon>}
								checkedIcon={props?.checkbox?.checkedIcon && <Icon>{props?.checkbox?.checkedIcon}</Icon>}
								disabled={props.disabled}
								checked={checked}
								onChange={(event, value) => {
									if (props.checkbox?.inverted) {
										event = { target: { value: !value } };
										value = !value;
									}

									if (props.onChange) props.onChange(event, value);
									if (props.onChangeCommitted) props.onChangeCommitted(props.name, value);
								}}
							/>
						}
					/>
					{props?.description &&
						createTooltip(
							<Icon className="mt-8 text-20" color="action">
								{"info"}
							</Icon>,
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{props.description}
							</Typography>,
							"bg-gray"
						)}
				</div>
				{props.checkbox?.disclaimer && (
					<Typography
						disabled={true}
						className={
							"text-10 mb-9 text-grey-600 " +
							(props.disabled ? " opacity-80 " : "") +
							(props.checkbox?.variant !== "skinny" ? " -mt-20 ml-32 " : " -mt-16 ml-28 ")
						}
					>
						{props.checkbox?.disclaimer}
					</Typography>
				)}
				{!props?.checkbox?.noErrorMessage && (
					<div className="w-full flex pl-10 h-12">
						<Typography variant="caption" color="error">
							{!props.disabled ? props.errors[props.name]?.message : ""}
						</Typography>
					</div>
				)}
			</div>
		);
	}
}

export default SmarthopCheckboxField;
