import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { formatDate } from "app/main/utils/dateUtils";

function ChatCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const countTotal = props.view?.metadata?.countTotal;
	const countSuggested = props.view?.metadata?.countSuggested;
	const countRequested = props.view?.metadata?.countRequested;
	const countPlanReminders = props.view?.metadata?.countPlanReminder;
	const countUnanswered = props.view?.metadata?.countUnanswered;
	const countExternal = props.view?.metadata?.countFromCustomer;
	const countInternal = countTotal - countExternal;
	const gtDs = props.view?.metadata?.gtDs;

	const countInternalSearches = props.view?.metadata?.countInternalSearches;
	const countExternalSearches = props.view?.metadata?.countExternalSearches;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "CHAT_APP_PREVIEW_TRUCK_EXTERNAL",
		dataIds: {
			truckId: props?.item?.truck__view?.value,
			carrierId: props?.item?.carrier__view?.value,
		},
	};

	if (config?.readOnly) {
		return props.item?.plan__view?.label;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={<CellFirstRow mobile={mobile} view={props.view} variant={variant} dialog={dialog} />}
			second={
				<>
					{config?.showMetadata && (
						<>
							<CellSecondRow mobile={mobile} label={formatDate(gtDs)} variant={variant} color={"pr-8"} />
							{countUnanswered > 0 && (
								<CellSecondRow
									mobile={mobile}
									icon={"mark_chat_unread"}
									label={countUnanswered + ""}
									variant={variant}
									color={"text-red-800"}
								/>
							)}
							<CellSecondRow
								mobile={mobile}
								icon={"calendar_today"}
								label={countPlanReminders + ""}
								variant={variant}
								color={"text-blue-900"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"local_shipping"}
								label={countSuggested + ""}
								variant={variant}
								color={"text-green-900"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"thumb_up"}
								label={countRequested + ""}
								variant={variant}
								color={"text-blue-800 pr-8"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"arrow_upward"}
								label={countInternal + ""}
								variant={variant}
								color={"text-green-900"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"arrow_downward"}
								label={countExternal + ""}
								variant={variant}
								color={"text-blue-800"}
							/>
						</>
					)}
					{config?.showSearchMetadata && (
						<>
							<CellSecondRow
								mobile={mobile}
								icon={"search"}
								label={countInternalSearches + ""}
								variant={variant}
								color={"text-blue-900"}
							/>
							<CellSecondRow
								mobile={mobile}
								icon={"search"}
								label={countExternalSearches + ""}
								variant={variant}
								color={"text-green-800"}
							/>
						</>
					)}
				</>
			}
		/>
	);
}

export default ChatCellView;
