import { createTooltip } from "app/main/utils/tableUtils";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const progressStyle = {
	color: "yellow-700",
	hoverColor: "yellow-800",
	icon: "update",
};
const finishStyle = {
	color: "green-400",
	hoverColor: "green-500",
	icon: "check_circle",
};
const errorStyle = {
	color: "red-700",
	hoverColor: "red-800",
	icon: "highlight_off",
};

const createAlertView = (data) => {
	let status = data?.status;
	let style;
	let text;

	if (status === "IN_PROGRESS" || status === "CREATED") {
		style = progressStyle;
		text = "In Progress";
	} else if (status === "FINISH" || status === "FINISHED") {
		style = finishStyle;
		text = "Finish Process";
	} else if (status === "ERROR" || status === "FAILED") {
		style = errorStyle;
		text = data?.run?.error;
	} else if (!status) {
		return "-";
	} else {
		return status;
	}

	return createTooltip(
		<Icon className={`text-${style.color} hover:text-${style.hoverColor}`}>{style.icon}</Icon>,
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{text}
		</Typography>
	);
};

const showProcessInfo = (item) => {
	if (item.userEmail) {
		return `${item.process} (${item.userEmail})`;
	}
	return `${item.process}`;
};

const adminAnalyticsJobsHistoryList = {
	urlGET: "api/analytics/users/:userId/runs",
	idKey: "_id",
	listenEvent: "analyticsRevision",
	segmentKey: "ADMIN_ANALYTICS_JOBS_HISTORY",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "key",
					type: "autocomplete",
					label: "Job Name",
					autocomplete: {
						provider: "smarthop",
						url: "api/analytics/jobs",
						preloadDefault: true,
					},
				},
				{
					key: "startsUnix",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__DATE_DAYS_GT_UNIX__1",
							label: "Last Day",
						},
						{
							value: "__DATE_DAYS_GT_UNIX__7",
							label: "Last 7 Days",
						},
						{
							value: "__DATE_DAYS_GT_UNIX__15",
							label: "Last 15 Days",
						},
						{
							value: "__DATE_DAYS_GT_UNIX__30",
							label: "Last 30 Days",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show System Fields",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "ip", type: "text", label: "IP", group: "internal" },
			{
				key: "queue_prefix",
				type: "text",
				label: "Queue",
				group: "internal",
				maxLengthEnd: 15,
			},
			{ key: "name", type: "text", label: "Name" },
			{ key: "elapsedTime", type: "text", label: "Elapsed Time", builder: (item) => item.elapsedTime + " Seconds" },
			{ key: "startsUnix", type: "date", label: "Start" },
			{ key: "finishUnix", type: "date", label: "Finish" },
			{ key: "process", type: "text", label: "Type", builder: (item) => showProcessInfo(item) },
			{ key: "status", label: "Status", type: "component", builder: (item) => createAlertView(item) },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "execute_logs",
						label: "Logs",
						dialogBuilder: (item, dataIds) => ({
							viewId: "ADMIN_ANALYTICS_JOB_LOGS",
							mode: "VIEW",
							dataIds: { runId: item._id, userId: dataIds.userId },
						}),
					},
				],
			},
		],
	},
};

export default adminAnalyticsJobsHistoryList;
