import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";
import { authRoles } from "app/main/consts";

export default build(
	[
		{
			path: "/profile/details",
			component: lazy(() => import("./UserDetailsPage")),
		},
	],
	null,
	[...authRoles.all, ...authRoles.bot, ...authRoles.deactivated]
);
