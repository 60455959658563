import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "@material-ui/core";

import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openFormDialog, closeFormDialog } from "app/store/tools/formDialogSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { formatTimeAgo, formatDate } from "app/main/utils/dateUtils";
import { getCurrentTruckPlan, snoozeLoadScoutNotifications } from "app/services/truckServices";
import { getUserId, isSearchEnabled } from "app/services/LoginService";
import { updateMessage } from "app/store/messenger/messageSlice";
import { createMPIView } from "app/main/utils/brokerUtils";

import ViewDescriptionSection from "./ViewDescriptionSection";
import ViewTitleSection from "./ViewTitleSection";
import ViewContentSection from "./ViewContentSection";
import ViewButtonSection from "./ViewButtonSection";
import ViewButton from "./ViewButton";
import ViewLink from "./ViewLink";
import ViewFooterNote from "./ViewFooterNote";
import { createIconBadge } from "app/main/utils/uiUtils";
import { createTooltip } from "app/main/utils/tableUtils";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useMemo } from "react";
import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";

import { isEnabled } from "app/services/featureStorageService";

function OneCell({ label, icon, value, value2, isNewMessagesUIEnabled }) {
	return (
		<div className={`flex flex-col space-y-8 justify-between items-center content-center `}>
			<div className="flex flex-col justify-between items-center content-center">
				<Typography className="font-semibold text-11 md:text-12">{label}</Typography>
				<Typography className="font-semibold text-11 md:text-12">New/Total</Typography>
			</div>

			<div className="flex flex-row space-x-4 justify-between items-center content-center">
				{icon}
				<Typography className="text-11 md:text-12">{`${value2 ?? 0}/${value ?? 0}`}</Typography>
			</div>
		</div>
	);
}

function DisplayResult({ item, isNewMessagesUIEnabled }) {
	return (
		<div
			className={`flex flex-row w-full items-center content-center mb-8 ${
				isNewMessagesUIEnabled ? "justify-start space-x-28" : "justify-evenly"
			}`}
		>
			<OneCell
				label={"All Loads"}
				icon={createIconBadge("ALL")}
				value={item?.analytics?.counters?.total}
				value2={item?.analytics?.counters?.newTotal}
				isNewMessagesUIEnabled={isNewMessagesUIEnabled}
			/>
			<OneCell
				label={"Matching"}
				icon={createTooltip(createIconBadge("MATCH_PLAN"), "# Loads Matching Preferences")}
				value={item?.analytics?.counters?.planMatch}
				value2={item?.analytics?.counters?.newPlanMatch}
				isNewMessagesUIEnabled={isNewMessagesUIEnabled}
			/>
			<OneCell
				label={"HOP Loads"}
				icon={createIconBadge("BEST")}
				value={item?.analytics?.counters?.best}
				value2={item?.analytics?.counters?.newBest}
				isNewMessagesUIEnabled={isNewMessagesUIEnabled}
			/>
		</div>
	);
}

function DisplayTable({ items, isNewMessagesUIEnabled }) {
	return (
		<Table sx={{ width: "100%" }} className="-mt-8">
			<TableHead>
				<TableRow className="text-16">
					<TableCell></TableCell>
					<TableCell>
						<Typography
							component={"div"}
							className={`flex flex-row items-center space-x-4 w-full justify-end ${
								isNewMessagesUIEnabled ? "mr-10" : ""
							}`}
						>
							<div className="flex flex-col">
								<div>All Loads</div>
								<div>New/Total</div>
							</div>
						</Typography>
					</TableCell>
					<TableCell>
						<Typography
							component={"div"}
							className={`flex flex-row items-center space-x-4 w-full justify-end ${
								isNewMessagesUIEnabled ? "mx-10" : ""
							}`}
						>
							{createIconBadge("MATCH_PLAN")}
							<div className="flex flex-col">
								<div>Matching</div>
								<div>New/Total</div>
							</div>
						</Typography>
					</TableCell>
					<TableCell>
						<Typography
							component={"div"}
							className={`flex flex-row items-center space-x-4 w-full justify-end ${
								isNewMessagesUIEnabled ? "mx-10" : ""
							}`}
						>
							{createIconBadge("BEST")}
							<div className="flex flex-col">
								<div>Hop Loads</div>
								<div>New/Total</div>
							</div>
						</Typography>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items?.toReversed()?.map((item, k) => (
					<TableRow className={`${k === items?.length - 1 ? `bg-yellow-50 fontsemi-bold` : ``}`} key={k}>
						<TableCell>{moment(item.createdAt).format("h:mm A")}</TableCell>
						<TableCell align="right">
							{`${item?.analytics?.counters?.newTotal ?? 0}/${item?.analytics?.counters?.total ?? 0}`}
						</TableCell>
						<TableCell align="right">
							{`${item?.analytics?.counters?.newPlanMatch ?? 0}/${item?.analytics?.counters?.planMatch ?? 0}`}
						</TableCell>
						<TableCell align="right">
							{`${item?.analytics?.counters?.newBest ?? 0}/${item?.analytics?.counters?.best ?? 0}`}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

function MessageViewMarketAnalysisWarning(props) {
	const dispatch = useDispatch();

	const { message } = props;
	const variant = props.variant;
	const incoming = props.incoming;
	const nativeMobile = props.nativeMobile;
	const snoozedUntil = message?.content?.snoozedUntil;
	const snackbar = useSnackbar();

	const [openConfirmDialog, setOpenConfirmDialog] = useState({ message: null, flag: false, typeAction: null });
	const [loading, setLoading] = useState(false);

	const [screenWidth, setScreenWidth] = useState(parseInt(window.innerWidth / 50) * 50);
	const isNewMessagesUIEnabled = isEnabled("CHAT_NEW_UI_MESSAGES");

	useEffect(() => {
		function handleResize() {
			let innerWidth = window.innerWidth;
			innerWidth = parseInt(innerWidth / 50) * 50;
			if (innerWidth !== screenWidth) setScreenWidth(innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const redirectSearch = () => {
		const { carrier, truck, destination, origin, pickupAfter } = message?.content;
		dispatch(closeFormDialog());
		dispatch(
			openFormDialog({
				viewId: "SEARCH_REQUEST",
				dataIds: {
					carrierId: carrier,
					truckId: truck,
					searchFilters: {
						location_origin: origin,
						location_destination: destination,
						pickup_after: pickupAfter,
					},
					hasAllfilters: false,
					eventType: "SEARCH_SIMILAR",
				},
			})
		);
	};

	const setDialog = useCallback((type) => {
		let message = type === "SNOOZE" ? "Are you sure to snooze load scout notifications?" : "";
		let confirmDialog = { message, flag: true, typeAction: type };
		setOpenConfirmDialog(confirmDialog);
	}, []);

	const handleAction = (actionType) => {
		setOpenConfirmDialog({ message: null, flag: false, typeAction: null });
		if (actionType === "SNOOZE") {
			setLoading(true);
			const { carrier, truck, planId } = message?.content;
			const snoozedUntil = moment().local().add(1, "day").startOf("day").hour(8).minute(0).utc();
			snoozeLoadScoutNotifications(carrier, truck, planId, {
				messageId: message?._id,
				snoozedUntil: snoozedUntil.format("YYYY-MM-DD HH:mm"),
			}).then(
				(response) => {
					showSnackbar(snackbar, "Notifications for Load Scout Snoozed!", "success");
					dispatch(incrementDataRevision({ event: "chatRevision" }));
					dispatch(updateMessage({ ...message, ...{ content: response.message.content } }));
					setLoading(false);
				},
				(err) => {
					showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error", {
						persist: true,
						newSnackBar: true,
					});
					setLoading(false);
				}
			);
		}
	};

	const handleViewPlan = () => {
		const { carrier, truck } = message?.content;

		if (nativeMobile) {
			getCurrentTruckPlan(carrier, truck).then((data) => {
				const dataIds = {
					id: data?.plan?._id,
					carrierId: carrier,
					userId: getUserId(),
					truckId: truck,
					buttonEnabled: true,
				};

				window?.ReactNativeWebView?.postMessage(
					JSON.stringify({
						type: "CONFIRMPLAN", // use the same because it open edit truck plan on app
						data: { dataIds, truckid: data?.truck?.truckid },
					})
				);
			});
		} else {
			dispatch(
				openFormDialog({
					viewId: "TRUCK_PLAN_VIEW",
					dataIds: { carrierId: carrier, truckId: truck, openChat: true, startingTab: 2 },
				})
			);
		}
	};

	const planDesription = [
		{ label: "Location", value: message?.content?.origin },
		{ label: "Available", value: formatDate(message?.content?.pickupAfter) },
		{
			label: "MPI",
			value: createMPIView(
				{ rank_mci: message?.content?.conditions?.mci, rank_mvi: message?.content?.conditions?.mvi },
				true
			),
		},
	];

	const handleViewResults = useCallback(
		(filters = {}) => {
			const { search, carrier, truck } = message?.content;
			dispatch(
				openFormDialog({
					viewId: "LOADS_SEARCH_RESULTS_VIEW",
					dataIds: { searchId: search, carrierId: carrier, truckId: truck, filters },
				})
			);
		},
		[message, dispatch]
	);

	const takeAction = useMemo(() => {
		const latest = message?.content?.loadScoutResults?.[0] ?? { ...(message?.content ?? {}) };
		if (latest?.analytics?.counters?.planMatch > 0) {
			return (
				<ViewButton
					className="bg-blue-800"
					onClick={() => handleViewResults({ system_show_plan_match_only: true })}
					label={`View ${latest?.analytics?.counters?.planMatch} Matching Load${
						latest?.analytics?.counters?.planMatch > 1 ? "s" : ""
					}`}
				/>
			);
		}

		return null;
	}, [message, handleViewResults]);

	const latestResults = useMemo(
		() => message?.content?.loadScoutResults?.[0] ?? { ...(message?.content ?? {}) },
		[message]
	);

	const displayResult = useMemo(() => {
		if (screenWidth > 600 && message?.content?.loadScoutResults) {
			return <DisplayTable items={message.content.loadScoutResults} isNewMessagesUIEnabled={isNewMessagesUIEnabled} />;
		} else {
			return (
				<DisplayResult
					item={message?.content?.loadScoutResults?.[0] ?? { ...(message?.content ?? {}) }}
					isNewMessagesUIEnabled={isNewMessagesUIEnabled}
				/>
			);
		}
	}, [screenWidth, message, isNewMessagesUIEnabled]);

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={true}
			variant={variant}
			headerConfig={{
				title: "Load Scout",
				subtitle: "We have searched the market for you and found the best matches based on your preferences.",
				showDivider: true,
			}}
			contentFields={[
				{
					type: "LABEL_VALUE",
					label: "Location",
					value: message?.content?.origin,
				},
				{
					type: "LABEL_VALUE",
					label: "Available",
					value: formatDate(message?.content?.pickupAfter),
				},
				{
					type: "CUSTOM",
					label: "MPI",
					component: createMPIView(
						{ rank_mci: message?.content?.conditions?.mci, rank_mvi: message?.content?.conditions?.mvi },
						true
					),
				},
				{
					type: "TEXT",
					value: "Results",
					classes: { text: "text-14 md:text-16 mt-10 mb-10" },
				},
				{
					type: "CUSTOM",
					component: displayResult,
				},
			]}
			messageActions={[
				...(latestResults?.analytics?.counters?.planMatch > 0
					? [
							{
								key: "VIEW_MATCHES",
								title: `View ${latestResults?.analytics?.counters?.planMatch} Matching Load${
									latestResults?.analytics?.counters?.planMatch > 1 ? "s" : ""
								}`,
								hideOnDesktop: false,
								hideOnMobile: false,
								onClick: () => {
									handleViewResults({ system_show_plan_match_only: true });
								},
							},
					  ]
					: []),
				{
					key: "VIEW_PLAN",
					title: "View Preferences",
					hideOnDesktop: false,
					hideOnMobile: false,
					onClick: () => {
						handleViewPlan();
					},
				},
				...(isSearchEnabled()
					? [
							{
								key: "SEARCH_NOW",
								title: "Search Now",
								hideOnDesktop: false,
								hideOnMobile: false,
								onClick: () => {
									redirectSearch();
								},
							},
					  ]
					: []),
			]}
			footerComponents={
				<>
					{!loading && !snoozedUntil && (
						<Link
							className={"cursor-pointer text-12 md:text-13 text-blue-800 text-left py-5 mt-5 "}
							component="button"
							underline="always"
							onClick={() => setDialog("SNOOZE")}
						>
							Snooze
						</Link>
					)}
					{loading && <CircularProgress size={16} className="mt-14" />}
					{!loading && snoozedUntil && (
						<ViewFooterNote
							variant={variant}
							incoming={incoming}
							text={`Notifications snoozed until ${formatDate(
								moment.utc(snoozedUntil).local().format("YYYY-MM-DD HH:mm")
							)}.`}
						/>
					)}
					<SmarthopConfirmDialog
						open={!!openConfirmDialog.flag}
						title={openConfirmDialog.message}
						handleClose={() => setOpenConfirmDialog({ message: null, flag: false, typeAction: null })}
						handleAccept={() => handleAction(openConfirmDialog?.typeAction)}
					/>
				</>
			}
			contentComponents={
				<ViewContentSection variant={variant} incoming={incoming}>
					<SmarthopConfirmDialog
						open={!!openConfirmDialog.flag}
						title={openConfirmDialog.message}
						handleClose={() => setOpenConfirmDialog({ message: null, flag: false, typeAction: null })}
						handleAccept={() => handleAction(openConfirmDialog?.typeAction)}
					/>
					<ViewTitleSection
						variant={variant}
						incoming={incoming}
						title={"Load Scout"}
						message={"We have searched the market for you and found the best matches based on your preferences."}
						badge={formatTimeAgo(message?.content?.validatedAt) + " ago"}
					/>
					<ViewDescriptionSection variant={variant} incoming={incoming} items={planDesription} />
					<ViewTitleSection variant={variant} incoming={incoming} title={"Result"} />
					{displayResult}
					{takeAction}
					<ViewButtonSection variant={variant} incoming={incoming} orientation={"horizontal"}>
						<ViewButton variant={variant} incoming={incoming} onClick={handleViewPlan} label={"View Preferences"} />
						{isSearchEnabled() && (
							<ViewButton
								variant={variant}
								incoming={incoming}
								color={"secondary"}
								onClick={redirectSearch}
								label={"Search Now"}
							/>
						)}
					</ViewButtonSection>

					<ViewButtonSection variant={variant} incoming={incoming}>
						{!loading && !snoozedUntil && (
							<ViewLink variant={variant} incoming={incoming} onClick={() => setDialog("SNOOZE")} label={"Snooze"} />
						)}
						{loading && <CircularProgress size={16} className="mt-14" />}
						{!loading && snoozedUntil && (
							<ViewFooterNote
								variant={variant}
								incoming={incoming}
								text={`Notifications snoozed until ${formatDate(
									moment.utc(snoozedUntil).local().format("YYYY-MM-DD HH:mm")
								)}.`}
							/>
						)}
					</ViewButtonSection>
				</ViewContentSection>
			}
		/>
	);
}
export default MessageViewMarketAnalysisWarning;
