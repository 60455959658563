const smarthopYellow = {
	50: '#F8F5F4',
	100: '#F4E3DE',
	200: '#F2D0C4',
	300: '#EB9D83',
	400: '#EA8360',
	500: '#E7683D',
	600: '#D75F37',
	700: '#B7421A',
	800: '#CE4F24',
	900: '#C93B0C',
	A100: '#E7683D',
	A200: '#E7683D',
	A400: '#E7683D',
	A700: '#E7683D',
	contrastDefaultColor: 'light'
};

export default smarthopYellow;
