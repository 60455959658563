import Box from "@material-ui/core/Box";
import React, { forwardRef } from "react";
import MoveCard from "./MoveCard";
import MoveConnector from "./MoveConnector";
import TotalCard from "./TotalCard";

const DrawerHeader = forwardRef((props, ref) => {
	const { header, handleCollapse, selectedMoves, setSelectedMoves, cardRef, isSaved } = props;
	// Access pathId located in last node
	const pathId = header[header.length - 1].data.stop.pathId;
	return (
		<Box
			ref={ref}
			style={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				padding: "20px",
				rowGap: "25px",
				columnGap: "2px",
			}}
			onClick={handleCollapse}
		>
			{header.map((h, i, allMoves) => (
				<React.Fragment key={`${h.data.stop._id}-header-${i}`}>
					{!!i && <MoveConnector node={h} />}
					<MoveCard
						key={h.data.stop._id}
						ref={!i ? cardRef : null}
						data={{
							id: h?.data?.id,
							title: h?.data?.name?.split("-")[0],
							equipment: h?.data?.stop?.equipment,
							dcluster: h?.data?.stop?.dcluster,
							isOrigin: !i,
							empty: h?.data?.stop?.empty,
							avg_volume: h?.data?.stop?.avg_volume,
							profit: h?.data?.stop?.profit,
							cumulativeProfit: h?.data?.cumulativeProfit,
							end_date: h?.data?.stop?.end_date,
							start_date: h?.data?.stop?.start_date,
							ocluster: h?.data?.stop?.ocluster,
							first: i === 1,
						}}
						allMoves={allMoves}
						selectedMoves={selectedMoves}
						setSelectedMoves={setSelectedMoves}
					/>
				</React.Fragment>
			))}

			<TotalCard
				key={"total" + pathId}
				title="TOTAL"
				allMoves={header}
				selectedMoves={selectedMoves}
				isSaved={isSaved}
			/>
		</Box>
	);
});

export default DrawerHeader;
