const SEARCH_LOAD_URL_PARAMS_MAPPING = {
	// trip params
	t_pa: "pickup_ds__after",
	t_pa_tzo: "pickup_ds__after__tz_offset",
	t_pa_tz: "pickup_ds__after__tz",
	t_pb: "pickup_ds__before",
	t_pb_tzo: "pickup_ds__before__tz_offset",
	t_pb_tz: "pickup_ds__before__tz",
	t_da: "delivery_ds__after",
	t_da_tzo: "delivery_ds__after__tz_offset",
	t_da_tz: "delivery_ds__after__tz",
	t_db: "delivery_ds__before",
	t_db_tzo: "delivery_ds__before__tz_offset",
	t_db_tz: "delivery_ds__before__tz",
	dh_o: "dh_origin",
	dh_d: "dh_destination",
	l_o: "location_origin",
	l_o_v: "location_origin__view",
	l_d: "location_destination",
	l_d_v: "location_destination__view",
	// truck params
	ca: "carrier",
	ca_v: "carrier__view",
	tr: "truck",
	tr_v: "truck__view",
	tr_dt: "droptrailer",
	tr_eq: "equipment",
	tr_mw: "max_weight",
	tr_sz: "trailer",
	// driver params
	dr: "driver",
	dr_v: "driver__view",
	dr_bls: "blacklisted_states_ids",
	dr_bls_v: "blacklisted_states_ids__view",
	dr_dhos: "driver_daily_hos",
	dr_whos: "driver_weekly_hos",
	dr_ms: "multistop",
	// preferences params
	pr_ml: "miles_preferred",
	pr_ml_mi: "miles_preferred__min",
	pr_ml_ma: "miles_preferred__max",
	pr_rpm: "rate_per_mile_preferred",
	pr_rt: "rate_total_preferred",
	pr_ag_ma: "age_mins_max_preferred",
	// Order
	pr_ob: "order_by",
};

const STRATEGY_URL_PARAMS_MAPPING = {
	...SEARCH_LOAD_URL_PARAMS_MAPPING,
	// strategy params
	st: "_id",
	st_c: "city",
	st_s: "state",
	st_pl: "path_length",
	st_mv: "min_volume",
	st_md: "min_distance",
	st_mxd: "max_distance",
	st_mpr: "min_profit",
	st_mppl: "min_profit_per_leg",
	st_emp: "end_market_profit",
	st_mxp: "max_paths",
	st_sb: "sort_by",
	st_sd: "start_date",
	st_cpd: "cost_per_day",
	st_cpm: "cost_per_mile",
	// truck params
	ca_i: "carrierId",
	tr_i: "truckId",
	// virtual contract params
	vc: "response",
	sv: "save",
};

const INVOICE_URL_PARAMS_MAPPING = {
	ca_i: "carrierId",
	tp_i: "tripId",
	in_i: "invoiceId",
};

/**
 * Function to remove query params from a URL
 * @param {*} url
 * @param {*} parameter
 * @returns
 */
const removeURLParameter = (url, parameter) => {
	//better to use l.search if you have a location/link object
	let urlparts = url.split("?");
	if (urlparts.length >= 2) {
		let prefix = encodeURIComponent(parameter) + "=";
		let pars = urlparts[1].split(/[&;]/g);

		//reverse iteration as may be destructive
		for (let i = pars.length; i-- > 0; ) {
			//idiom for string.startsWith
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1);
			}
		}

		url = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
		return url;
	} else {
		return url;
	}
};

/**
 * Function to add/update query params
 * @param {*} history
 * @param {*} key
 * @param {*} value
 */
const insertURLParameter = (key, value) => {
	if (window.history.pushState) {
		let currentUrlWithOutHash = window.location.origin + window.location.pathname + window.location.search;
		let hash = window.location.hash;

		//remove any param for the same key
		currentUrlWithOutHash = removeURLParameter(currentUrlWithOutHash, key);

		//figure out if we need to add the param with a ? or a &
		let queryStart;
		if (currentUrlWithOutHash.indexOf("?") !== -1) {
			queryStart = "&";
		} else {
			queryStart = "?";
		}

		let newurl = currentUrlWithOutHash + queryStart + key + "=" + value + hash;
		window.history.pushState({ path: newurl }, "", newurl);
	}
};

/**
 * Function to replace all query params
 * @param {*} history
 * @param {*} key
 * @param {*} value
 */
const rewriteURLParameters = (params, replaceState = true) => {
	if (!params) {
		return;
	}

	if (window.history.pushState) {
		let currentUrlWithOutHash = window.location.origin + window.location.pathname;

		let query = "";
		Object.keys(params).forEach((key) => {
			if (query) query += "&";
			query += key + "=" + params[key];
		});

		var newurl = currentUrlWithOutHash + (!!query.length ? "?" + query : "");
		if (replaceState) {
			window.history.replaceState({ path: newurl }, "", newurl);
		} else {
			window.history.pushState({ path: newurl }, "", newurl);
		}
	}
};

/**
 * Function to replace specified query params
 * @param {*} history
 * @param {*} key
 * @param {*} value
 */
const replaceURLParameters = (params, replaceState = true) => {
	if (!params) {
		return;
	}

	let currentParams = readURLParameters() ?? {};
	Object.keys(params).forEach((key) => {
		if (!params[key] || params[key] === "null" || params[key] === "undefined") {
			delete currentParams[key];
		} else {
			currentParams[key] = params[key];
		}
	});

	rewriteURLParameters(currentParams, replaceState);
};

/**
 * Function to build url with params
 * @param {*} history
 * @param {*} key
 * @param {*} value
 */
const buildURLParameters = (baseUrl, params) => {
	if (!params) {
		return;
	}

	let query = "";
	Object.keys(params).forEach((key) => {
		if (query) query += "&";
		query += key + "=" + params[key];
	});

	return baseUrl + "?" + query;
};

/**
 * Reading URL params
 * @returns
 */
const readURLParameters = () => {
	try {
		let params = {};
		let query = window.location.search.substring(1);
		if (!query) {
			return;
		}

		let vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			params[pair[0]] = pair[1];
		}
		return params;
	} catch (e) {
		console.log("[UrlUtils] failed to read URL params", e);
		return;
	}
};

/**
 * Generic function to convert a model to compressed URL params
 * @param {*} model
 * @param {*} mapping
 * @returns
 */
const convertModelToURLParams = (model, mapping) => {
	if (!model || !mapping) {
		return;
	}

	let sanitize = (value) => {
		if (!value) return value;

		return encodeURIComponent(
			value
				.replaceAll('"value"', '"v"')
				.replaceAll('"label"', '"l"')
				.replaceAll('"description"', '"d"')
				.replaceAll('"state"', '"s"')
		);
	};

	try {
		let params = {};
		Object.keys(model).forEach((key) => {
			let value = model[key];
			let alias = Object.keys(mapping).find((alias) => mapping[alias] === key);
			params[alias ?? key] = sanitize(JSON.stringify(value));
		});
		return params;
	} catch (e) {
		console.log("[UrlUtils] failed to convert model to URL params", e);
		return;
	}
};

/**
 * Generic function to convert URL params to a model
 * @param {*} model
 * @param {*} mapping
 * @returns
 */
const convertURLParamsToModel = (params, mapping, options) => {
	if (!params || !mapping) {
		return;
	}

	let unsanitize = (value) => {
		if (!value) return value;
		//CHAM-3031: Workaround -IOS started failing to decode the URL at the first attempt, now requires being decoded twice.
		return decodeURIComponent(value)
			.replaceAll('"v"', '"value"')
			.replaceAll('"l"', '"label"')
			.replaceAll('"d"', '"description"')
			.replaceAll('"s"', '"state"');
	};

	let model = {};
	Object.keys(params).forEach((alias) => {
		let value = params[alias];
		let key = mapping[alias];
		let uValue = unsanitize(value);
		try {
			model[key ?? alias] = options?.notJson ? uValue : uValue ? JSON.parse(uValue) : uValue;
		} catch (e) {
			model[key ?? alias] = uValue;
		}
	});
	return model;
};

const TRIP_TIMELINE_URL_PARAMS_MAPPING = {
	ca: "carrier",
	ca_v: "carrier__view",
	tr: "truck",
	tr_v: "truck__view",
	t_d: "trip_date",
	t_d_tzo: "trip_date__tz_offset",
	t_d_tz: "trip_date__tz",
	ws: "window_size",
};

export {
	insertURLParameter,
	removeURLParameter,
	rewriteURLParameters,
	replaceURLParameters,
	readURLParameters,
	convertModelToURLParams,
	convertURLParamsToModel,
	buildURLParameters,
	SEARCH_LOAD_URL_PARAMS_MAPPING,
	TRIP_TIMELINE_URL_PARAMS_MAPPING,
	STRATEGY_URL_PARAMS_MAPPING,
	INVOICE_URL_PARAMS_MAPPING,
};
