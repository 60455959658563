const paymentAdmin = {
	urlPOST: "billing/carriers/:id/action-payment",
	idKey: "_id",
	triggerEvent: "",
	listenEvent: "",
	header: {
		create: { label: "Add New Payment" },
	},
	messages: { create: { success: "Payment added.", disabled: false } },
	content: {
		create: {
			items: [
				{
					key: "type",
					type: "select",
					label: "Action Type",
					defaultValue: "bank_account",
					options: [
						{
							value: "bank_account",
							label: "Add Bank Account",
							translate: "BANK",
							showItems: [
								{
									key: "type_bank",
									type: "select",
									label: "Type",
									required: true,
									options: [
										{
											value: "btok_us_verified_accountClosed",
											label: "Account Close",
											translate: "CLOSE",
										},
										{
											value: "btok_us_verified_insufficientFunds",
											label: "Insufficient Funds",
											translate: "INSUFFICIENT_FUND",
										},
										{
											value: "btok_us_verified_noAccount",
											label: "Not Account",
											translate: "AUTHORIZED",
										},
									],
								},
							],
						},
						{
							value: "card",
							label: "Add Credit Card",
							translate: "CARD",
							showItems: [
								{
									key: "type_card",
									type: "select",
									label: "Type",
									required: true,
									options: [
										{
											value: "tok_refundFail",
											label: "Canceled Card",
											translate: "DECLINE",
										},
										{
											value: "tok_pendingRefund",
											label: "Pending Refund",
											translate: "REFUND",
										},
									],
								},
							],
						},
						{
							value: "delete_payments",
							label: "Delete All Payments Methods",
						},
						{
							value: "delete_fuel_cards",
							label: "Delete all Fuel Cards",
						},
					],
				},
				{ type: "action", action: "SUBMIT", label: "Action" },
			],
		},
	},
};

export default paymentAdmin;
