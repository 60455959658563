import { createSlice } from "@reduxjs/toolkit";
import { setUserData } from "./userSlice";
import { signInWithEmailAndPassword } from "app/services/LoginService";

export const submitLogin = (data, onProgressOverride, onDoneOverride) => async (dispatch) => {
	if (onProgressOverride) onProgressOverride(true);
	let email = data?.email;
	let password = data?.password;
	let params = { ...data };
	delete params.email;
	delete params.password;

	dispatch(setLoginInProgress(true));
	return signInWithEmailAndPassword(email?.toLowerCase().trim(), password?.trim(), params).then(
		(loginResponse) => {
			dispatch(setLoginInProgress(false));
			if (onProgressOverride) onProgressOverride(false);

			if (loginResponse.status === "2FA_TYPE_REQUIRED") {
				console.log("[LoginSlice] login requires 2FA type");
				return dispatch(setLoginStatus(loginResponse));
			} else if (loginResponse.status === "2FA_CODE_REQUIRED") {
				console.log("[LoginSlice] login requires 2FA code");
				return dispatch(setLoginStatus(loginResponse));
			} else if (loginResponse.status === "PWD_RESET_REQUIRED") {
				console.log("[LoginSlice] password reset required");
				return dispatch(setLoginStatus(loginResponse));
			} else if (loginResponse.status === "ACCOUNT_SELECTION_REQUIRED") {
				console.log("[LoginSlice] account selection required");
				return dispatch(setLoginStatus(loginResponse));
			}

			if (onDoneOverride) onDoneOverride(loginResponse);
			return dispatchLogin(dispatch, loginResponse);
		},
		(error) => {
			dispatch(setLoginInProgress(false));
			if (onProgressOverride) onProgressOverride(false);
			if (error?.data?.errors?.length > 0) {
				return dispatch(loginError(error.data.errors));
			} else {
				return dispatch(loginError([{ type: "generic", message: "Something went wrong, please try again later." }]));
			}
		}
	);
};

export const dispatchLogin = (dispatch, userData) => {
	dispatch(setUserData(userData));
	return dispatch(loginSuccess());
};

export const clearLoginErrors = () => async (dispatch) => {
	dispatch(clearErrors());
};

const initialState = {
	success: false,
	status: null,
	metadata: null,
	inProgress: false,
	errors: [],
};

const loginSlice = createSlice({
	name: "auth/login",
	initialState,
	reducers: {
		setLoginInProgress: (state, action) => {
			state.inProgress = !!action.payload;
		},
		loginSuccess: (state) => {
			state.success = true;
			state.inProgress = false;
			state.status = null;
			state.metadata = null;
			state.errors = [];
		},
		setLoginStatus: (state, action) => {
			state.errors = [];
			state.success = action.payload?.status === "DONE";
			state.status = action.payload?.status;
			state.metadata = action.payload?.metadata ?? {
				email: action.payload?.email,
				phone: action.payload?.phone,
				verification_code: action.payload?.verification_code,
				verification_type: action.payload?.verification_type,
				selected_user_id: action.payload?.selected_user_id,
				account_options: action.payload?.account_options,
				social_token: action.payload?.social_token,
				social_provider_name: action.payload?.social_provider_name,
			};
			state.inProgress = false;
		},
		loginError: (state, action) => {
			state.success = false;
			state.inProgress = false;
			state.errors = action.payload;
		},
		clearErrors: (state) => {
			state.errors = [];
		},
	},
	extraReducers: {},
});

export const { setLoginInProgress, loginSuccess, setLoginStatus, loginError, clearErrors } = loginSlice.actions;

export default loginSlice.reducer;
