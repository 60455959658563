const genericTruckPlan = {
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit Truck Plan" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "plan.current_available_date", // FIXME: FAC-2052 Invalid key format
								type: "picker",
								label: "Truck Ready Date",
							},
							{ key: "plan.advancedFilters", type: "checkbox", label: "Load Scout Enabled" },
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "plan.current_location", // FIXME: FAC-2052 Invalid key format
								type: "text",
								label: "Truck Origin",
							},
							{
								key: "plan.current_destination", // FIXME: FAC-2052 Invalid key format
								type: "text",
								label: "Truck Destination",
							},
						],
					},
				},
				{ key: "plan.notes", type: "text", label: "Notes", multiline: { rows: 4 } }, // FIXME: FAC-2052 Invalid key format
			],
		},
	},
};

const truckPlanForm = {
	urlGET: "api/queue/carriers/:carrierId/trucks/:truckId/plan",
	urlPUT: "api/queue/carriers/:carrierId/trucks/:truckId/plan",
	...genericTruckPlan,
};

export default truckPlanForm;
