import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Service
import { isRoleExternal } from "app/services/LoginService";

const buildTooltip = (enabled, icon, description, classes) => {
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
			title={<Typography className={"text-13 text-black py-10 px-10"}>{description}</Typography>}
		>
			<Icon className={"text-20 min-w-28 " + (classes?.icon ?? "") + (enabled ? "text-primary" : "text-grey")}>
				{icon}
			</Icon>
		</Tooltip>
	);
};

const filters = [
	{
		key: "accountStatus",
		type: "select",
		label: "Account Status",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Not Selected",
			},
			{
				label: "Active",
				value: "active",
			},
			{
				label: "Inactive",
				value: "inactive",
			},
		],
	},
];

const subAccounts = (user, subAccountData, snackbar) => {
	return {
		urlGET: "api/profile/accounts/:accountId/sub-accounts",
		idKey: "_id",
		listenEvent: "accountRevision",
		segmentKey: "ACCOUNT_PAGE",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
				layout: [["tier"], ["access"]],
			},
			table: {
				variant: "skinny",
				layout: [
					"table_avatar",
					"carrier_company_name",
					"carrier_mc_number",
					"users",
					"trucks",
					"drivers",
					"tier",
					"access",
				],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			defaults: {
				initial: {
					filters: {
						accountStatus: "active",
					},
				},
				items: [
					{
						label: "Active",
						description: "Active users",
						filters: {
							accountStatus: "active",
						},
					},
					{
						label: "Inactive",
						description: "Inactive users",
						filters: {
							accountStatus: "inactive",
						},
					},
				],
			},
			filters: {
				search: true,
				countVisible: 1,
				items: filters,
			},
			click: {
				grid: { variant: "menu" },
				table: { addToMenu: true },
				dialogBuilder: (item, dataIds) => {
					const listOnboardingProcess = ["INCOMPLETE", "CHANGES_REQUESTED"];
					if (isRoleExternal() && listOnboardingProcess.includes(item.onboardingStatus)) {
						return {
							viewId: "ONBOARDING_VIEW",
							dataIds: { carrierId: item._id, userId: item.users[0]._id, createSubAccount: true },
						};
					} else {
						return {
							viewId: "CARRIER_INFO_VIEW",
							dataIds: { carrierId: item._id },
						};
					}
				},
			},
			items: [
				{
					key: "table_avatar",
					type: "component",
					builder: (item) => {
						return (
							<Avatar src={null} alt={""} className={"bg-white border-1 border-grey-400"}>
								<Icon className="text-20 text-primary">account_circle</Icon>
							</Avatar>
						);
					},
				},
				{
					key: "carrier_company_name",
					type: "text",
					label: "Name",
					grid: { headerTitle: true },
					table: { width: "38%" },
					builder: (item) => item.carrier__view.label,
				},
				{
					key: "carrier_mc_number",
					type: "text",
					label: "MC Number",
					table: { width: "15%" },
					grid: { headerSubtitle: true, valuePefix: "MC " },
					builder: (item) => item.carrier_mc_number ?? " - ",
				},
				{
					key: "users",
					label: "# Users",
					type: "text",
					table: { width: "10%" },
					builder: (item) => {
						if (item.users) {
							return item.users.length;
						}
						return "0";
					},
				},
				{
					key: "trucks",
					label: "# Trucks",
					type: "text",
					table: { width: "10%" },
					builder: (item) => {
						if (item.trucks) {
							return item.trucks.length;
						}
						return "0";
					},
				},
				{
					key: "drivers",
					label: "# Drivers",
					type: "text",
					table: { width: "40%" },
					builder: (item) => {
						if (item.drivers) {
							return item.drivers.length;
						}
						return "0";
					},
				},
				{
					key: "tier",
					label: "Plan",
					type: "text",
					table: { align: "right", width: "15%" },
					builder: (item) => {
						const tier = item?.customer?.payment_plans?.platform?.tier;
						if (tier === "TIER_STARTER") {
							return "Starter";
						} else if (tier === "TIER_BASIC") {
							return "Basic";
						} else if (tier === "TIER_BASIC_PLUS") {
							return "Basic Plus";
						} else if (tier === "TIER_PROFESSIONAL") {
							return "Professional";
						} else if (tier === "TIER_GROWTH") {
							return "Growth";
						} else if (tier === "TIER_ENTERPRISE") {
							return "Enterprise";
						} else if (tier === "TIER_LIMITED") {
							return "Limited";
						} else {
							return !tier ? "-" : tier;
						}
					},
				},
				{
					key: "access",
					type: "component",
					label: "Status",
					table: { width: "8%" },
					builder: (item) => {
						return (
							<div className={"flex flex-row"} onClick={(e) => e.stopPropagation()}>
								{buildTooltip(
									item.accountStatus === "DEACTIVATED" ? false : true,
									item.accountStatus === "ACTIVE"
										? "person"
										: item.accountStatus === "DEACTIVATED"
										? "person_off"
										: item.onboardingStatus === "IN_REVIEW"
										? "plagiarism"
										: "pending_actions",
									item.accountStatus === "ACTIVE"
										? "The account is active"
										: item.accountStatus === "DEACTIVATED"
										? "The account is deactivated"
										: item.onboardingStatus === "IN_REVIEW"
										? "The account is in activation process"
										: "The account is in the onboarding process",
									{ icon: " ml-2 " }
								)}
								{item.isConnectedAccount &&
									buildTooltip(true, "settings_ethernet", "You are connected to this account", { icon: " ml-4 " })}
							</div>
						);
					},
				},
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "divider",
							variant: "divider",
						},
						{
							key: "sub-account-login",
							label: "Login",
							dialogBuilder: (item, dataIds) => ({
								formId: "USER_LOGIN_AS_SUBACCOUNT_FORM",
								mode: "CREATE",
								dataIds: { userId: item.ownerUser },
							}),
							isVisible: (item) => item.accountStatus !== "DEACTIVATED" && user.role === "CARRIER_OWNER",
						},
						{
							key: "sub-account-activate",
							label: "Activate",
							dialogBuilder: (item, dataIds) => {
								if (subAccountData?.list?.length >= subAccountData?.totalSitsEnabled) {
									const message =
										"You cannot activate this account, you have the maximum number of accounts according to your plan.";
									return showSnackbar(snackbar, message, "info", { duration: 7000 });
								}
								return {
									formId: "CARRIER_COMPANY_CHANGE_ACCOUNT_STATUS_FORM",
									mode: "EDIT",
									dataIds: { carrierId: item._id, status: "active" },
								};
							},
							isVisible: (item) => item.accountStatus === "DEACTIVATED",
						},
						{
							key: "sub-account-disconnect",
							label: "Disconnect",
							auth: ["carrier"],
							dialogBuilder: (item, dataIds) => ({
								formId: "CARRIER_DISPATCHING_DISCONNECT_FROM_DISPATCH_FORM",
								mode: "DELETE",
								dataIds: { carrierId: item._id },
							}),
							isVisible: (item) => item.isConnectedAccount,
						},
						{
							key: "sub-account-deactivated",
							label: "Deactivate",
							dialogBuilder: (item, dataIds) => ({
								formId: "CARRIER_COMPANY_CHANGE_ACCOUNT_STATUS_FORM",
								mode: "EDIT",
								dataIds: { carrierId: item._id, status: "inactive" },
							}),
							isVisible: (item) => item.accountStatus !== "DEACTIVATED" && !item.isConnectedAccount,
						},
					],
				},
			],
		},
	};
};

export default subAccounts;
