const carrierAutomationForm = {
	urlGET: "api/profile/carriers/:carrierId/details/automation",
	urlPUT: "api/profile/carriers/:carrierId/details/automation",
	idKey: "_id",
	triggerEvent: "automationRevision",
	listenEvent: "automationRevision",
	header: {
		edit: { label: "Notification Configuration" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "automation_disable_mark_trips_as_delivered",
					type: "checkbox",
					label: "Automatically mark trips as delivered if not marked within 72 h of expected delivery date",
					checkbox: { inverted: true },
				},
				{
					key: "automation_disable_mark_chats_as_viewed",
					type: "checkbox",
					label: "Automatically mark chats as read after 72 h of inactivity",
					checkbox: { inverted: true },
				},

				{ type: "action", action: "SUBMIT", classes: "w-1/2" },
			],
		},
	},
};

export default carrierAutomationForm;
