import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import { Alert } from "@material-ui/lab";

// Service
import { getAccountId } from "app/services/LoginService";

// Tools
import { fetchSubAccount } from "app/store/auth/accountSlice";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { createTooltip } from "app/main/utils/tableUtils";

const generateTermsLink = (viewId, label, dispatch) => {
	return (
		<Link
			className="cursor-pointer text-blue-900 whitespace-nowrap pr-4"
			onClick={(event) => {
				event.preventDefault();
				event.stopPropagation();
				dispatch(
					openFormDialog({
						viewId: viewId,
					})
				);
			}}
		>
			<Typography>{label}</Typography>
		</Link>
	);
};

const TYPE_COMPANY = (viewOnly, required = false, hideDispatchOption = false) => ({
	key: "typeCompany",
	type: "select",
	label: "Type of company",
	defaultValue: required ? "" : "NOT_SELECTED",
	viewOnly: viewOnly,
	field: { noErrorMessage: true },
	required: required,
	options: [
		...(required ? [] : [{ value: "NOT_SELECTED", label: "Not Selected" }]),
		{
			value: "FLEET",
			label: "Fleet",
		},
		...(!hideDispatchOption ? [{ value: "DISPATCH_SERVICE", label: "Dispatch Service" }] : []),
		{ value: "OWNER_OPERATOR", label: "Owner Operator" },
	],
});

const CARRIER_REPRESENTATIVE = (viewOnly, showDescription = false, showAlways = false, showError = false) => ({
	key: "carrierRepresentative",
	type: "text",
	viewOnly: viewOnly,
	...(showAlways ? {} : { hidden: (item) => !item?.parent }),
	label: "Carrier Representative" + (showDescription ? " (Optional)" : ""),
	...(showDescription && {
		description: "We request the sub-account representatives name for compliance an integration purpose only",
	}),
	field: { noErrorMessage: showError },
});

const MAIN_FIELDS = (viewOnly, enableFleetName, hasParent) => [
	TYPE_COMPANY(viewOnly),
	{
		type: "group",
		content: {
			items: [
				{
					key: "carrier_company_name",
					type: "text",
					label: "Legal Name",
					translate: "COMPANY_NAME",
					required: !viewOnly,
					viewOnly,
				},
				{
					key: "dba_name",
					type: "text",
					label: "DBA Name",
					viewOnly,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "carrier_mc_number",
					type: "text",
					label: "MC #",
					translate: "MC_NUMBER",
					hidden: (item) => item?.typeCompany === "DISPATCH_SERVICE",
					builder: (item) =>
						(item.onboardingInformationStatus === "VALIDATED" ||
							item.onboardingInformationStatus === "VALIDATED_LOCKED") &&
						item.carrier_mc_number
							? createTooltip(
									<div className="flex flex-row justify-center items-center space-x-4">
										<Icon>verified</Icon>
										<Typography>{item.carrier_mc_number}</Typography>
									</div>,
									"Ownership Verified"
							  )
							: item.carrier_mc_number,
					viewOnly,
				},
				{
					key: "carrier_dot_number",
					type: "number",
					label: "DOT #",
					translate: "DOT_NUMBER",
					hidden: (item) => item?.typeCompany === "DISPATCH_SERVICE",
					viewOnly,
					builder: (item) =>
						(item.onboardingInformationStatus === "VALIDATED" ||
							item.onboardingInformationStatus === "VALIDATED_LOCKED") &&
						!item.carrier_mc_number &&
						item.carrier_dot_number
							? createTooltip(
									<div className="flex flex-row justify-center items-center space-x-4">
										<Icon>verified</Icon>
										<Typography>{item.carrier_dot_number}</Typography>
									</div>,
									"Ownership Verified"
							  )
							: item.carrier_dot_number,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "carrier_phone",
					type: "text",
					label: "Phone",
					viewOnly,
				},
				{
					key: "carrier_email",
					type: "email",
					label: "Email",
					viewOnly,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "carrier_physical_address",
					type: "autocomplete",
					label: "Physical Address",
					translate: "PHYSICAL_ADDRESS",
					viewOnly,
					autocomplete: {
						provider: "trimblemaps",
						params: { componentRestrictions: { country: "us" } },
						icon: "location",
						disableManualSelect: true,
					},
				},
				CARRIER_REPRESENTATIVE(viewOnly, false, hasParent),
			],
		},
	},
	{
		key: "carrier_billing_address",
		type: "autocomplete",
		label: "Billing Address",
		translate: "BILLING_ADDRESS",
		viewOnly,
		autocomplete: {
			provider: "trimblemaps",
			params: { componentRestrictions: { country: "us" } },
			anyValue: true,
			icon: "location",
			disableManualSelect: true,
		},
	},
];

const VALIDATION_FIELDS = (showOnlyValid, showSyncStatus) => {
	return [
		{
			key: "description",
			type: "message",
			hidden: (item) => {
				const status = item?.onboardingInformationStatus;
				const validated = status === "VALIDATED_LOCKED" || status === "VALIDATED";
				return showOnlyValid && !validated;
			},
			builder: (item) => {
				const status = item.onboardingInformationStatus;
				const locked = status === "VALIDATED_LOCKED";
				const validated = status === "VALIDATED_LOCKED" || status === "VALIDATED";
				return (
					<div className={"flex flex-row w-full items-center pt-8 pb-8"}>
						<Icon className={"text-32 ml-4 mr-14 " + (validated ? " text-green-600 " : " text-grey ")}>
							{locked ? "lock" : validated ? "check_circle" : "warning"}
						</Icon>
						<Typography className="text-12 ml:text-13">
							{validated
								? showOnlyValid
									? "Provided company information has been verified." +
									  (locked ? " To edit information please contact Smarthop." : "")
									: "Validation of the ownership has been completed successfully."
								: " Please confirm ownership of the MC/DOT number(s) to expedite the process of activation of your account. " +
								  "If you can not do it now, do not worry. Our team will reach you using your provided contact information to confirm the validity of all the provided information."}
						</Typography>
					</div>
				);
			},
		},
		...(showSyncStatus
			? [
					{
						key: "sync_status",
						type: "component",
						hidden: (item) => !item?.onboardingDotInfoUnedited,
						builder: () => {
							return (
								<div className={"flex flex-row w-full items-center -mb-2 pl-6 pt-8"}>
									<Typography className="text-grey text-10 ml:text-11">
										* Information has been automatically synchronized using MC/DOT number(s)
									</Typography>
								</div>
							);
						},
					},
			  ]
			: []),
	];
};

const carrierCompanyInformationForm = {
	urlPOST: "api/profile/users/:userId/accounts",
	urlGET: "api/profile/carriers/:carrierId/details/company-information",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	size: "max-w-m",
	header: {
		create: { label: "Create New Account" },
		edit: { label: "Edit Company Information" },
		view: { label: "View Company Information" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	callbacks: {
		onCreate: (data, dispatch) => {
			dispatch(
				openFormDialog({
					viewId: "ONBOARDING_VIEW",
					dataIds: { carrierId: data.carrierId, userId: data.userId, step: 0, createSubAccount: true },
				})
			);
			dispatch(fetchSubAccount({ accountId: getAccountId() }));
			dispatch(incrementDataRevision({ event: "accountRevision" }));
		},
	},
	content: {
		create: {
			items: [
				...[TYPE_COMPANY(false, true, true)],
				{
					key: "carrier_fleet_name",
					type: "text",
					label: "Fleet Name (Optional)",
					description: "Fleet name will help you differentiate sub-accounts",
					field: { noErrorMessage: true },
				},
				{
					key: "carrier_mc_number",
					type: "number",
					label: "MC #",
					field: { noErrorMessage: true },
				},
				{
					key: "carrier_dot_number",
					type: "number",
					label: "DOT #",
					field: { noErrorMessage: true },
				},
				CARRIER_REPRESENTATIVE(false, true, true),
				{
					key: "terms_and_condition",
					type: "checkbox",
					labelShort: "T&C",
					label: (_, dispatch) => (
						<div className="flex flex-wrap mt-5 items-center pt-6 -mb-6 sm:mb-4 ">
							<Typography className="whitespace-nowrap pr-4">I have read and agree to</Typography>
							{generateTermsLink("SMARTHOP_TERMS_VIEW", "SmartHop's Terms Of Service Agreement,", dispatch)}
							{generateTermsLink("SMS_TERMS_VIEW", "SMS Terms,", dispatch)}
							<Typography className="whitespace-nowrap pr-4">and</Typography>
							{generateTermsLink("PRIVACY_POLICY_VIEW", "Privacy Policy", dispatch)}
							<Typography className="whitespace-nowrap">*</Typography>
						</div>
					),
					required: true,
					checkbox: { classes: { root: "-mt-6" } },
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
		view: {
			noInitValidation: true,
			items: [...MAIN_FIELDS(false, false), { type: "action", action: "SUBMIT", button: { alwaysEnabled: true } }],
		},
	},
};

const carrierCompanyChangeAccountStatusForm = {
	urlGET: "api/profile/carriers/:carrierId/details/company-information",
	urlPUT: "api/profile/carriers/:carrierId/details/company-information/status/:status",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Change Account Status" },
	},
	messages: { edit: { success: "Successfully Updated Information" } },
	content: {
		edit: {
			items: [
				{
					type: "message",
					builder: (item) => (
						<div>
							{item.accountStatus !== "DEACTIVATED" && (
								<Alert className="flex flex-row w-full mb-16" severity="info">
									Users associated with this account will not be able to log in.
								</Alert>
							)}
							<span className="ml-5">
								Would you like to {item.accountStatus === "DEACTIVATED" ? "activate" : "deactivate"} the account '
								{item.carrier_company_name}'?
							</span>
						</div>
					),
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE", label: "Continue" },
						],
					},
				},
			],
			onFinished: (_, dispatch) => {
				dispatch(incrementDataRevision({ event: "accountRevision" }));
				dispatch(fetchSubAccount({ accountId: getAccountId() }));
			},
		},
	},
};

const carrierCompanyEditAndValidateForm = {
	urlGET: "api/profile/carriers/:carrierId/details/company-information",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	listenEventReloadAllStates: true,
	header: {
		edit: { label: "Company Information" },
		view: { label: "Company Information" },
	},
	onSubmitOverride: (model, dataIds, onDone, dispatch, snackbar, history, key) => {
		if (window?.ReactNativeWebView?.postMessage) {
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: "OPEN_DIALOG",
					data: { viewId: "COMPANY_INFO_CONFIRM", dataIds: { carrierId: dataIds.carrierId } },
				})
			);
		} else {
			onDone?.();
			dispatch(openFormDialog({ viewId: "COMPANY_INFO_CONFIRM", dataIds: { carrierId: dataIds.carrierId } }));
		}
	},
	content: {
		view: {
			items: [...MAIN_FIELDS(true, false)],
		},
		edit: {
			submitOnChange: true,
			noInitValidation: true,
			items: [
				...MAIN_FIELDS(true, true),
				...VALIDATION_FIELDS(true, true),
				{
					type: "action",
					action: "SUBMIT",
					label: "Edit",
					button: { alwaysEnabled: true, classes: { root: "mt-10" } },
					hidden: (item) => {
						const status = item?.onboardingInformationStatus;
						return status === "VALIDATED_LOCKED";
					},
				},
			],
		},
	},
};

const carrierCompanyValidateOnlyForm = {
	urlGET: "api/profile/carriers/:carrierId/details/company-information",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Carrier Ownership Validation" },
		view: { label: "Carrier Ownership Validation" },
	},
	onSubmitOverride: (model, dataIds, onDone, dispatch, snackbar, history) => {
		if (window?.ReactNativeWebView?.postMessage) {
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: "OPEN_DIALOG",
					data: { viewId: "COMPANY_INFO_CONFIRM", dataIds: { state: "VALIDATE", carrierId: dataIds.carrierId } },
				})
			);
		} else {
			onDone?.();
			dispatch(
				openFormDialog({ viewId: "COMPANY_INFO_CONFIRM", dataIds: { state: "VALIDATE", carrierId: dataIds.carrierId } })
			);
		}
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				...VALIDATION_FIELDS(),
				{
					type: "action",
					action: "SUBMIT",
					label: "Validate",
					hidden: (item) => {
						const status = item?.onboardingInformationStatus;
						return status === "VALIDATED_LOCKED" || status === "VALIDATED";
					},
					button: { alwaysEnabled: true, classes: { root: "mt-10" } },
				},
			],
		},
	},
};

const carrierCompanyEditFleetNameForm = {
	urlGET: "api/profile/carriers/:carrierId/details/company-information",
	urlPUT: "api/profile/carriers/:carrierId/details/company-information/fleet-name",
	idKey: "_id",
	triggerEvent: "onboardingRevision",
	listenEvent: "onboardingRevision",
	header: {
		edit: { label: "Fleet name" },
		view: { label: "Fleet name" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "carrier_fleet_name",
								type: "text",
								label: "Fleet Name",
								translate: "FLEET_NAME",
								required: true,
								viewOnly: false,
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT", button: { alwaysEnabled: true } },
			],
		},
	},
};

export {
	carrierCompanyInformationForm,
	carrierCompanyChangeAccountStatusForm,
	carrierCompanyEditAndValidateForm,
	carrierCompanyValidateOnlyForm,
	carrierCompanyEditFleetNameForm,
	MAIN_FIELDS,
	TYPE_COMPANY,
	CARRIER_REPRESENTATIVE,
};
