// Dependencies
import { useEffect, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SmarthopConfirmDialog } from "@smarthop/form";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";
import { getPayout, transitionPayout } from "app/services/billingServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useSnackbar } from "notistack";
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { formatDate } from "app/main/utils/dateUtils";
import SmartPayJournalEntriesComponent from "app/main/profile/trips/SmartPayJournalEntriesComponent";

import { getRoleLabel } from "app/services/LoginService";
import { formatCurrency } from "app/main/utils/tableUtils";

import { openFormDialog } from "app/store/tools/formDialogSlice";

const smartpayPaymentType = {
	ACH_PAYMENT: "ACH",
	WIRE_PAYMENT: "Same Day",
};

const SmartPayPayoutView = forwardRef(({ nativeMobile, dataIds, setTitle, setSize }, _) => {
	useEffect(() => {
		setTitle?.(`Payout Details`, dataIds?.label);
		setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, [dataIds?.label]);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const isAdmin = getRoleLabel() === "administrator";

	const [payout, setPayout] = useState(null);
	const [error] = useState(null);
	const [processing, setProcessing] = useState(false);

	const [unselectedEntries, setUnselectedEntries] = useState([]);

	// Revisions
	const tripsRevision = useSelector(({ tools }) => tools.revision["tripsRevision"]);

	const [openDialogAlert, setDialogAlert] = useState({
		flag: false,
		message: null,
		type: null,
		optionsText: {},
	});

	const carrierId = dataIds?.carrierId;
	const payoutId = dataIds?.payoutId;

	useEffect(() => {
		(async () => {
			const payout = await getPayout(carrierId, payoutId);
			setPayout(payout);
		})();
	}, [carrierId, payoutId, tripsRevision]);

	if (!payout || error) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={!payout} error={error} />;
	}

	const showCloseButton = isAdmin && payout?.status === "OPEN";
	const showCompleteButton = isAdmin && payout?.status !== "OPEN" && payout?.status !== "COMPLETED";
	const showEditButton = payout?.status === "OPEN";

	const baseTransferFeeCents = payout?.base_transfer_fee_cents ?? 0;
	const transferFeePercentage = payout?.transfer_fee_percentage ?? 0;
	const maxTransferFeeCents = payout?.max_transfer_fee_cents ?? 0;

	const transferFeeCents = payout?.transfer_fee_cents;

	let transferFeeCalculationText = (
		(baseTransferFeeCents > 0 ? `${formatCurrency(baseTransferFeeCents / 100)}` : "") +
		(transferFeePercentage > 0 ? ` + ${transferFeePercentage}%` : "") +
		(maxTransferFeeCents > 0 ? `  with a cap of ${formatCurrency(maxTransferFeeCents / 100)}` : "")
	).trim();

	if (transferFeeCalculationText.length === 0) {
		transferFeeCalculationText = "0$";
	}

	const netAmountCents = payout?.smartpay?.ledgerTotals?.immediateCents - transferFeeCents;

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			processing={processing}
			tabComponents={[
				{
					key: "SUMMARY",
					title: "Summary",
					component: (
						<div className="flex w-full flex-col">
							<div className="flex w-full flex-row">
								<DisplaySection classes={{ root: "md:w-full" }} title="Details">
									<div className="-mt-10">
										{renderDataItem("Payout", payout?.refLabel ? payout.refLabel : "#" + (payout?.ref ?? ""), "", {})}
										{renderDataItem("Status", payout?.status, "", {})}
										{payout?.closed_date && renderDataItem("Closed Date", formatDate(payout.closed_date), "", {})}
										{payout?.completed_date && renderDataItem("Paid Date", formatDate(payout.completed_date), "", {})}
									</div>
								</DisplaySection>
							</div>
							<div className="flex w-full flex-row pl-40">
								<SmartPayJournalEntriesComponent
									title="Line Items"
									payoutEntries={payout.journalEntries}
									hidePayout={true}
									hideStatus={true}
									unselectedEntries={!isAdmin ? null : unselectedEntries}
									setUnselectedEntries={!isAdmin ? null : setUnselectedEntries}
								/>
							</div>
							<div className="flex w-full flex-row">
								<DisplaySection classes={{ root: "md:w-full" }} title="Totals">
									<div className="-mt-10">
										{renderDataItem(
											"Transfer Fee | " + (smartpayPaymentType[payout?.payment_type] ?? payout?.payment_type),
											formatCurrency(transferFeeCents / 100),
											"",
											{
												tooltip: {
													label: !transferFeeCalculationText?.includes("%")
														? null
														: { message: transferFeeCalculationText },
												},
											}
										)}
										{renderDataItem("Net Amount", formatCurrency(netAmountCents / 100), "", {})}
									</div>
								</DisplaySection>
							</div>
						</div>
					),
				},
			]}
			footerActions={[
				{
					key: "CLOSE_PAYOUT",
					title: "Send for Payment",
					style: {
						align: "RIGHT",
						notCollapsible: true,
						primary: true,
					},
					disabled: payout?.journalEntries?.length === unselectedEntries?.length,
					hideOnDesktop: !showCloseButton,
					hideOnMobile: !showCloseButton,
					onClick: () => {
						setDialogAlert({
							flag: true,
							message: "You are about to send this payout for payment. Are you sure?",
							optionsText: { accept: "Continue" },
							onAccept: async () => {
								setProcessing(true);
								try {
									await transitionPayout(carrierId, payoutId, "PENDING", { unselectedEntries });
									dispatch(incrementDataRevision({ event: "tripsRevision" }));
									showSnackbar(snackbar, "Payout ready for payment.", "success");
								} catch (err) {
									showSnackbar(snackbar, err.errors?.[0]?.message ?? "An error ocurred", "error");
								} finally {
									setProcessing(false);
								}
							},
						});
					},
				},
				{
					key: "COMPLETE_PAYOUT",
					title: "Mark as Completed",
					style: {
						align: "LEFT",
						notCollapsible: true,
						icon: "send",
						primary: true,
					},
					hideOnDesktop: !showCompleteButton,
					hideOnMobile: !showCompleteButton,
					onClick: () => {
						setDialogAlert({
							flag: true,
							message: "This will mark Payout Invoices as Funded. Continue?",
							optionsText: { accept: "Continue" },
							onAccept: async () => {
								setProcessing(true);
								try {
									await transitionPayout(carrierId, payoutId, "COMPLETED");
									dispatch(incrementDataRevision({ event: "tripsRevision" }));
									showSnackbar(snackbar, "Payout marked as completed.", "success");
								} catch (err) {
									showSnackbar(snackbar, err.errors?.[0]?.message ?? "An error ocurred", "error");
								} finally {
									setProcessing(false);
								}
							},
						});
					},
				},
				{
					key: "EDIT_PAYOUT",
					title: "Edit",
					style: {
						align: "LEFT",
						notCollapsible: true,
						icon: "edit",
					},
					hideOnDesktop: !showEditButton,
					hideOnMobile: !showEditButton,
					onClick: () => {
						dispatch(
							openFormDialog({
								viewId: "SMARTPAY_PAYOUT_EDIT_VIEW",
								mode: "EDIT",
								dataIds: { carrierId, payoutId },
							})
						);
					},
				},
			]}
		>
			<SmarthopConfirmDialog
				open={!!openDialogAlert.flag}
				title={openDialogAlert?.message}
				checkboxLabel={openDialogAlert?.checkboxLabel}
				checkboxValue={openDialogAlert?.checkboxValue}
				setCheckboxValue={(value) => {
					typeof openDialogAlert?.setCheckboxValue === "function" && openDialogAlert?.setCheckboxValue(value);
				}}
				onAccept={() => {
					openDialogAlert?.onAccept();
					setDialogAlert({ flag: false, message: null, type: null, optionsText: {} });
				}}
				handleClose={() => setDialogAlert({ flag: false, message: null, type: null, optionsText: {} })}
				acceptMsg={openDialogAlert?.optionsText?.accept}
			/>
		</SmarthopDialogViewContainer>
	);
});

export default SmartPayPayoutView;
