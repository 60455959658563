import { CardMedia, Typography } from "@material-ui/core";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import clsx from "clsx";

const FileVisualizerView = forwardRef(({ dataIds, onDone, setTitle, setSize }, ref) => {
	useEffect(() => {
		setTitle?.("File Preview");
		setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const { urlFilePreview, errorMessage } = dataIds;
	const [smallScreen] = useState(window.innerWidth <= 1280);
	const [loading, setLoaded] = useState(true);

	useImperativeHandle(
		ref,
		() => {
			const btns = !errorMessage && {
				open_in_new: {
					onClick: () => window.open(urlFilePreview),
					title: "View in a Separate Page",
				},
			};

			return btns;
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(() => {
		if (smallScreen) {
			window.open(urlFilePreview);
			onDone?.();
		}
	}, [smallScreen, urlFilePreview, onDone]);

	return (
		<div className="flex relative h-screen80">
			{loading && !errorMessage && (
				<div className={clsx("absolute flex w-full items-center h-full justify-center")}>
					<Typography className="flex text-12 px-20 py-2 text-grey">Loading...</Typography>
				</div>
			)}
			{errorMessage && (
				<div className={clsx("absolute flex w-full items-center h-full justify-center")}>
					<Typography className="flex text-12 px-20 py-2 text-grey">{errorMessage}</Typography>
				</div>
			)}
			{!errorMessage && (
				<CardMedia
					component="iframe"
					className={clsx("absolute -my-8 h-full flex w-full overflow-hidden")}
					image={urlFilePreview}
					onLoad={() => {
						setLoaded(false);
					}}
				/>
			)}
		</div>
	);
});

export default FileVisualizerView;
