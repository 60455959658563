// Dependencies
import { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// Components
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";

// Service
import useHeatMap from "../../market-conditions/hooks/useHeatMap";
import useLocationsTrimble from "app/main/market-conditions/hooks/useLocationsTrimble";
import { getFuelLocationsNearByCarrier } from "app/services/fuelServices";

TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => {
	return {
		mapRadius: {
			borderRadius: "20px",
		},
		markerTruck: {
			position: "absolute",
			left: "50%",
			transform: "translate(-50%, 0)",
			bottom: 0,
			fontSize: "25px",
		},
		infoStyle: {
			bottom: "10px",
			width: "max-content",
			backgroundColor: "#062246",
			position: "absolute",
			left: "50%",
			transform: "translate(-50%, 0)",
		},
	};
});

const TripMapTrimble = (props) => {
	const plan = props.plan;
	const map = props.map;
	const locations = props.locations;
	const routes = props.routes;
	const loadId = props.loadId;
	const carrierId = props.carrierId;
	const truckLocation = props.truckLocation;
	const origin = props?.origin;
	const destination = props?.destination;
	const weight = props.weight;
	const classes = useStyles();

	// Fuel
	const [centerCoordinates, setCenterCoordinates] = useState();
	const [currentZoom, setCurrentZoom] = useState();
	const [fuelLocations, setFuelLocations] = useState();
	const [showFuelLocationsButton, setShowFuelLocationsButton] = useState(true);
	const [loading, setLoading] = useState(false);

	const { locationsCoord } = useLocationsTrimble({ props });
	const heatMap = useHeatMap({ props });
	const heatMapFormatted = JSON.stringify(heatMap);

	const tripPlan = useMemo(() => {
		if (plan) {
			return plan;
		} else {
			return {
				routeId: loadId,
				routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
				tollDiscourage: true,
				truckConfig: TrimbleMaps.Common.TruckConfig.FIFTY_THREE,
				trkWeight: weight ? 35000 + weight : 35000,
			};
		}
	}, [loadId, plan, weight]);

	const routesCoordinates = useMemo(() => {
		const routesCoordinates = [];
		const addPoint = (array, location) => {
			if (location?.coordinates || (location?.lat && location?.lng) || location?.stopType) {
				array.push({
					lat: location?.coordinates?.lat ?? location?.lat,
					lng: location?.coordinates?.long ?? location?.lng,
					stopType: location.stopType ?? location.type,
					metadata: location?.metadata ?? undefined,
				});
			} else {
				console.warn("[TrimbleMaps] ivalid location values", location);
			}
		};

		// Locations Data
		if (origin && destination && locationsCoord) {
			const route = { locations: [] };
			locationsCoord.forEach((location) => addPoint(route.locations, location));
			routesCoordinates.push(route);
		}
		if (routes?.length > 0) {
			for (let item of routes) {
				const route = { color: item.color, locations: [] };
				item?.locations?.forEach((location) => addPoint(route.locations, location));
				routesCoordinates.push(route);
			}
		} else if (locations?.length > 0) {
			const route = { locations: [] };
			locations.forEach((location) => addPoint(route.locations, location));
			routesCoordinates.push(route);
		}

		return routesCoordinates;
		//eslint-disable-next-line
	}, [locations, locationsCoord]);

	useEffect(() => {
		const firstRoute = routesCoordinates?.[0];
		const myMap = new TrimbleMaps.Map({
			container: map,
			center:
				truckLocation && truckLocation?.long && truckLocation?.lat
					? new TrimbleMaps.LngLat(truckLocation.long, truckLocation.lat)
					: new TrimbleMaps.LngLat(
							centerCoordinates?.long ?? firstRoute?.[0]?.lng ?? -93,
							centerCoordinates?.lat ?? firstRoute?.[0]?.lat ?? 39
					  ),
			zoom: props?.zoom ?? currentZoom ?? 3.5,
			region: TrimbleMaps.Common.Region.NA,
		});

		if (!routesCoordinates?.[0] && props.activeHeatMap && heatMap.length === 0) return;

		const saveCenterCoordinates = (mapInstance) => {
			const center = mapInstance.getCenter();
			const zoom = mapInstance.getZoom();
			const lat = center?.lat;
			const long = center?.lng;
			setCenterCoordinates({ lat, long });
			setCurrentZoom(zoom);
		};

		// First render
		if (!centerCoordinates) saveCenterCoordinates(myMap);

		let mpiRenderingTimeout;
		const myRoutes = [];
		const stopPoints = [];
		const addTrimbleRoute = (routeId, stops, color, config) => {
			myRoutes.push(
				new TrimbleMaps.Route({
					routeId: routeId,
					stops: stops,
					fuelUnits: TrimbleMaps.Common.FuelUnit.GALLONS,
					fuelType: TrimbleMaps.Common.FuelType.DIESEL,
					inclTollData: true,
					dataVersion: TrimbleMaps.Common.DataVersion.NA,
					useSites: true,
					showStops: false,
					bordersOpen: false,
					routeColor: color ?? "#E8683D",
					frameRoute: false,
					frameOptions: {
						animate: false,
					},
					...(config ?? {}),
				})
			);
		};

		const addTrimbleStop = (location, color) => {
			const properties = { color, stopType: location.stopType, status: location.status, metadata: location.metadata };
			const coordinates = [location.coordinates?.long ?? location.lng, location.coordinates?.lat ?? location.lat];
			stopPoints.push({
				type: "FeatureCollection",
				features: [{ type: "Feature", properties, geometry: { type: "Point", coordinates } }],
			});
		};

		routesCoordinates.forEach((route, index) => {
			const emptyStops = [];
			const routeStops = [];
			const connectionStops = [];

			route.locations.forEach((location, index) => {
				if (location.lng === undefined || location.lat === undefined) {
					return;
				}

				if (location.stopType === "EMPTY" && index === 0) {
					addTrimbleStop(location, "#003764");
					emptyStops.push(new TrimbleMaps.LngLat(location.lng, location.lat));
				} else {
					if (emptyStops.length === 1) {
						emptyStops.push(new TrimbleMaps.LngLat(location.lng, location.lat));
					}
					addTrimbleStop(location, route.color);
					routeStops.push(new TrimbleMaps.LngLat(location.lng, location.lat));
				}
			});

			if (index + 1 < routesCoordinates.length && !!routesCoordinates[index + 1]?.locations?.[0]) {
				const connection = [route.locations[route.locations.length - 1], routesCoordinates[index + 1].locations[0]];
				connection.forEach((location) => {
					if (location.lng === undefined || location.lat !== undefined) {
						return;
					}
					connectionStops.push(new TrimbleMaps.LngLat(location.lng, location.lat));
				});
			}

			if (emptyStops.length > 0) {
				addTrimbleRoute("EMPTY_" + index, emptyStops, "#003764");
			}
			if (routeStops.length > 0) {
				addTrimbleRoute("ROUTE_" + index, routeStops, route.color);
			}
			if (connectionStops.length > 0) {
				addTrimbleRoute("CONNECTION_" + index, connectionStops, "#424242");
			}
		});

		let truckPoint;
		if (truckLocation) {
			const coordinates = [
				truckLocation?.coordinates?.long ?? truckLocation.long,
				truckLocation?.coordinates?.lat ?? truckLocation.lat,
			];
			truckPoint = {
				type: "FeatureCollection",
				features: [{ type: "Feature", properties: {}, geometry: { type: "Point", coordinates } }],
			};
		}

		myMap.on("zoomend", () => {
			setShowFuelLocationsButton(true);
			saveCenterCoordinates(myMap);
		});

		myMap.on("dragend", () => {
			setShowFuelLocationsButton(true);
			saveCenterCoordinates(myMap);
		});

		myMap.on("load", function () {
			//myMap.setCenter({lng: routesCoordinates?.[1]?.lng, lat: routesCoordinates?.[0]?.lat});
			//Add Heat Map

			mpiRenderingTimeout = setTimeout(() => {
				try {
					if (heatMap?.length > 0) {
						heatMap.forEach((data, index) => {
							const geoJsonData = {
								type: "geojson",
								data: {
									type: "FeatureCollection",
									features: [
										{
											type: "Feature",
											properties: {},
											geometry: {
												type: "Polygon",
												coordinates: [data.polygon],
											},
										},
									],
								},
							};

							// Add GeoJSON data source to the map
							myMap.addSource("hqSource" + index, geoJsonData);

							// Add a layer to draw circles for each point in the data source
							myMap.addLayer({
								id: "hqPoly" + index,
								type: "fill",
								source: "hqSource" + index,
								paint: {
									"fill-color": data?.infoColor?.fillColor ?? "rgba(255, 255, 255, 0)",
									"fill-opacity": 0.5,
								},
							});
							// Add a black outline around the polygon.
							myMap.addLayer({
								id: "hqoutline" + index,
								type: "line",
								source: "hqSource" + index,
								layout: {},
								paint: {
									"line-color": data?.infoColor?.strokeColor ?? "rgba(255, 255, 255, 0)",
									"line-width": 1,
								},
							});

							// Listen for clicks on the hqPoints layer
							myMap.on("click", "hqPoly" + index, function (evt) {
								//eslint-disable-next-line
								const popupLocation = {
									lng: data.polygon[0][0],
									lat: data.polygon[0][1],
								};
								const popupContent = data.infoWindow.content;

								new TrimbleMaps.Popup().setLngLat(evt.lngLat).setHTML(popupContent).addTo(myMap);
							});

							// Change cursor when hovering over a feature on the hqPoints layer
							myMap.on("mouseenter", "hqPoly" + index, function () {
								myMap.getCanvas().style.cursor = "pointer";
							});

							// Change cursor back
							myMap.on("mouseleave", "hqPoly" + index, function () {
								myMap.getCanvas().style.cursor = "";
							});
						});
					}
				} catch (e) {
					console.error("Failed to render heat map", e);
				}
			}, 2500);

			myRoutes.forEach((myRoute) => myRoute.addTo(myMap));

			for (let index in stopPoints) {
				const stopPoint = stopPoints[index];
				const properties = stopPoint?.features?.[0]?.properties;
				const stopId = "stopPoint" + index;
				myMap.addSource(stopId, { type: "geojson", data: stopPoint });

				myMap.addLayer({
					id: stopId + "_text",
					source: stopId,
					type: "symbol",
					paint: { "text-color": "#ffffff", "text-halo-color": "#424242" },
					layout: {
						"icon-image":
							properties?.stopType === "EMPTY"
								? "circle-fill-blue"
								: properties?.status === "ON_TIME"
								? "circle-fill-green"
								: properties?.status === "LATE"
								? "circle-fill-red"
								: "circle-fill-orange",
						"icon-ignore-placement": true,
						"text-field": (parseInt(index) + 1).toString(),
						"text-size": 13,
					},
				});

				if (properties?.metadata) {
					// Listen for clicks on the hqPoints layer
					myMap.on("click", stopId, function (evt) {
						const popupLocation = evt.features[0].geometry.coordinates.slice();
						const dataContent = JSON.parse(evt.features[0].properties.metadata);
						const popupContent = `
							Status: ${dataContent.stopArrivalStatus}<br>
							Current ETA: <br>${moment(dataContent.currentETA).format("MM-DD-YYYY HH:mm")}<br>
							Planned ETA: <br>${moment(dataContent.plannedETA).format("MM-DD-YYYY HH:mm")}<br>
							Earliest Arrival Time: <br>${
								dataContent.earliestArrivalTime
									? moment(dataContent.earliestArrivalTime).format("MM-DD-YYYY HH:mm")
									: " - "
							}<br>
							Latest Arrival Time: <br>${
								dataContent.latestArrivalTime ? moment(dataContent.latestArrivalTime).format("MM-DD-YYYY HH:mm") : " - "
							}<br>
							Planned Departure Time: <br>${moment(dataContent.plannedDepartureTime).format("MM-DD-YYYY HH:mm")}<br>
							`;

						new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent).addTo(myMap);
					});

					// Change cursor when hovering over a feature on the hqPoints layer
					myMap.on("mouseenter", stopId, function () {
						myMap.getCanvas().style.cursor = "pointer";
					});

					// Change cursor back
					myMap.on("mouseleave", stopId, function () {
						myMap.getCanvas().style.cursor = "";
					});
				}
			}

			if (truckLocation) {
				myMap.addSource("point", { type: "geojson", data: truckPoint });
				myMap.addLayer({
					id: "point",
					source: "point",
					type: "symbol",
					maxzoom: 24,
					minzoom: 0,
					layout: {
						"icon-ignore-placement": true,
						"icon-size": 1.5,
						"icon-image": "truck-fill-orange",
					},
				});
			}

			// Show Points of Interest
			myMap.setPOIVisibility(true);
			myMap.togglePOIVisibility();

			// Fuel locations
			fuelLocations?.forEach((location, index) => {
				const key = `fuel-location-${index}`;
				const fuelPoint = {
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							properties: {},
							geometry: {
								type: "Point",
								coordinates: [location.longitude, location.latitude],
							},
						},
					],
				};

				myMap.addSource(key, {
					type: "geojson",
					data: fuelPoint,
				});

				myMap.addLayer({
					id: key,
					source: key,
					type: "symbol",
					layout: {
						"icon-image": location.isInNetwork ? "poi_fuel" : "poi_truck_stop",
						"icon-allow-overlap": true,
					},
					paint: {
						"text-color": "#ffffff",
					},
				});

				myMap.on("click", key, (evt) => {
					const popupLocation = evt.features[0].geometry.coordinates.slice();
					const popupContent = `
					<div class="px-8 py-4 pl-8">
						<div class="flex flex-row items-center mt-4">
							<p class="font-sans font-medium text-16 mb-2 leading-tight font-bold">
								${location.name}
							</p>
						</div>

						<div class="flex flex-row items-center mt-12">
							<span class="text-grey-800 material-icons MuiIcon-root-667 text-20 mr-8 self-center">
								place
							</span>
							<div>
							<p class="text-grey-800 font-sans font-medium pl-2 contents text-13">
								${location.address.street1}.
							</p>
							<p class="text-grey-800 font-sans font-medium text-13 mb-2 leading-tight">
								${location.address.city}, ${location.address.region} ${location.address.postalCode}
							</p>
							</div>
						</div>

						<div class="flex flex-row items-center mt-12">
							<span class="text-grey-800 material-icons MuiIcon-root-667 text-20 mr-8 self-center">
								shopping_cart
							</span>
							<div>
								<p class="text-grey-800 font-sans font-medium text-13 mb-2 leading-tight">
									Retail: ${formatCurrency(location.retailPrice, 3)}
								</p>
								<p class="text-gray-800 font-sans font-medium text-13 mb-2 leading-tight">
									per gallon
								</p>
							</div>
						</div>

						<div class="flex flex-row items-center mt-12">
							<span class="text-grey-800 material-icons MuiIcon-root-667 text-20 mr-8 self-center">
								attach_money
							</span>
							<div>
								<p class="text-grey-800 font-sans font-medium pl-2 contents text-13">
									Saving approx.
								</p>
								<p class="text-green-800 font-bold font-sans font-medium text-13 mb-2 leading-tight">
									${formatCurrency(location.savingAvgDiscountedPriceWithRetailPrice, 3)} per gallon
								</p>
							</div>
						</div>
					</div>
				`;
					new TrimbleMaps.Popup({ contentDisplayClass: "pr-20", closeButton: false })
						.setLngLat(popupLocation)
						.setHTML(popupContent)
						.addTo(myMap);
				});

				// Change cursor when hovering over a feature on the key layer
				myMap.on("mouseenter", key, () => {
					myMap.getCanvas().style.cursor = "pointer";
				});

				// Change cursor back
				myMap.on("mouseleave", key, () => {
					myMap.getCanvas().style.cursor = "";
				});
			});
		});

		return () => {
			clearTimeout(mpiRenderingTimeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		routesCoordinates,
		map,
		tripPlan,
		truckLocation,
		heatMapFormatted,
		fuelLocations,
		props.activeHeatMap,
		props.zoom,
	]);

	const showFuelLocationsHandler = async (renderFuelLocations) => {
		setLoading(true);
		setShowFuelLocationsButton(false);
		const lat = centerCoordinates?.lat;
		const long = centerCoordinates?.long;
		if (renderFuelLocations && carrierId && lat && long) {
			const response = await getFuelLocationsNearByCarrier(carrierId, lat, long);
			setFuelLocations(response?.locations);
		} else {
			setFuelLocations();
		}
		setLoading(false);
	};

	return (
		<div id={props.map} className={classes.mapRadius + " " + props?.classes?.root}>
			{showFuelLocationsButton && props.showFuelLocationsButton && (
				<div
					onClick={() => showFuelLocationsHandler(true)}
					className="cursor-pointer absolute m-auto bg-white text-blue-700 rounded-lg px-10 border-1 opacity-70 border-blue py-6 text-grey right-10 top-10 z-40"
				>
					Show Gas Stations
				</div>
			)}
			{loading ? (
				<CircularProgress size={32} className="absolute ml-10 top-10 z-40" />
			) : (
				fuelLocations !== undefined && (
					<div
						className={`absolute flex flex-col bg-white ml-10 px-10 py-6 border-1 border-black opacity-80 rounded-lg top-10 z-40 text-13`}
					>
						<span className="flex">Gas Stations: {fuelLocations?.length ?? 0} found</span>
					</div>
				)
			)}
		</div>
	);
};

export default TripMapTrimble;
