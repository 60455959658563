import React from "react";
import { Divider } from "@material-ui/core";
import { formatTitle, formatLabel } from "./utils";
import { formatCurrency } from "app/main/utils/tableUtils";

function InvoiceInvestorContainer(props) {
	const { data } = props;
	return (
		<>
			{formatTitle("Invoices")}
			<Divider className="w-full bg-grey-300 mt-10" />
			{formatLabel("#Invoices", data?.Count ?? 0)}
			<Divider className="w-full bg-grey-300" />
			{formatLabel("Funded", formatCurrency(data?.Funded ?? 0))}
			<Divider className="w-full bg-grey-300" />
			{formatLabel("Collected", formatCurrency(data?.Collected ?? 0))}
		</>
	);
}

export default InvoiceInvestorContainer;
