import { formatDate } from "app/main/utils/dateUtils";

const reports = {
	urlGET: "api/reports/carriers/:carrierId/reports",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "CARRIER_GENERAL_REPORTS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["created_at", "start_date", "end_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			searchDescription: "Search by ",
			items: [
				{
					key: "type",
					type: "select",
					label: "Report Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not selected",
						},
						{
							value: "IFTA",
							label: "Basic IFTA",
						},
					],
				},
			],
		},
		groups: {
			items: [],
		},
		defaults: {
			initial: {
				filters: {
					type: "__NOT_SELECTED__",
				},
			},
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			dialogBuilder: (item) => ({
				viewId: "REPORT_VIEW",
				dataIds: {
					carrierId: item.carrier,
					reportId: item._id,
				},
			}),
		},
		items: [
			{
				key: "created_at",
				label: "Generate Date",
				builder: (item) => formatDate(item.created_at, item.created_at, false, false, false, false),
			},
			{
				key: "type",
				label: "Report Type",
				builder: (item) => (item.type === "IFTA" ? " Basic IFTA" : item.type),
			},
			{
				key: "start_date",
				label: "Start Date",
				builder: (item) => formatDate(item.start_date, item.start_date, false, false, false, false),
			},
			{
				key: "end_date",
				label: "End Date",
				builder: (item) => formatDate(item.end_date, item.end_date, false, false, false, false),
			},
			{
				key: "details",
				label: "Details",
				type: "text",
			},
		],
	},
};

export default reports;
