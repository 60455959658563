import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import LoadHistoryPage from "../../profile/load/history/LoadHistoryPage";
import SmartHopMap from "app/main/profile/trips/SmartHopMap";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import CircularProgress from "@material-ui/core/CircularProgress";

// Services
import { getTrackingLocations, getPublicTrackingLocations } from "app/services/tripsServices";

const NO_LOCATION_MESSAGE = "No locations were found for this load.";

const ErrorView = ({ message }) => (
	<div className="flex flex-col justify-center items-center w-full h-full">
		<Typography color="error">{message}</Typography>
	</div>
);

const LoadTrackingMap = (props) => {
	const tripId = props?.dataIds?.tripId;
	const carrierId = props?.dataIds?.carrierId;
	const publicView = !!props?.dataIds?.public;
	const dispatch = useDispatch();

	const [locationsData, setLocationsData] = useState([]);
	const [locationTruck, setLocationTruck] = useState(null);
	const [error, setError] = useState({
		show: false,
		message: "",
	});

	useEffect(() => {
		if (tripId) {
			const getTLocations = publicView ? getPublicTrackingLocations : getTrackingLocations;
			getTLocations(tripId).then(
				(res) => {
					if (res?.items?.length) {
						const { items, truck_location } = res;
						setLocationsData(items);
						setLocationTruck(truck_location);
					} else {
						setError({
							show: true,
							message: NO_LOCATION_MESSAGE,
						});
					}
				},
				({ errors, status }) => {
					const message = status === 404 ? NO_LOCATION_MESSAGE : errors[0].message;
					setError({
						message,
						show: true,
					});
				}
			);
		}
		//eslint-disable-next-line
	}, [tripId]);

	const openDialog = () => {
		let addDialog = { viewId: "TRIP_TRACKING", mode: "CREATE", dataIds: { carrierId, tripId } };
		dispatch(openLoadedFormDialog(addDialog));
	};
	return (
		<div className="flex flex-col md:flex-row w-fullpx-4 space-x-0 md:space-x-0">
			<div className="md:w-3/5 sm:w-3/3 flex flex-col mr-4">
				{!publicView && (
					<div className="ml-auto mb-1">
						<Button onClick={() => openDialog()} variant="contained" color="secondary" className="mb-8">
							Add Tracking
						</Button>
					</div>
				)}
				<LoadHistoryPage dataIds={{ tripId: tripId, carrierId: carrierId, public: publicView }} />
			</div>
			<div className="md:w-2/5 hidden md:flex" style={{ height: "66vh" }}>
				{locationsData?.length > 0 && (
					<SmartHopMap
						provider="TRIMBLE MAPS"
						classes={{ root: "w-0 md:w-full h-full overflow-hidden m-0 hidden md:flex" }}
						map={"trackingMap_" + tripId}
						locations={locationsData}
						loadId={tripId}
						truckLocation={locationTruck}
						isTrip={true}
					/>
				)}
				{!locationsData?.length && (
					<div className="items-center justify-center flex w-full">
						<CircularProgress color="secondary" />
					</div>
				)}
			</div>
			{error.show && <ErrorView message={error.message ?? ""} />}
		</div>
	);
};

export default LoadTrackingMap;
