import { makeStyles } from "@material-ui/core/styles";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { createHeaderConfig } from "./configs/tripImportConfig";

const useStyles = makeStyles({
	headerRow: {
		backgroundColor: "#ddd",
	},
	headerCell: {
		height: "60px",
		display: "flex",
		alignItems: "center",
		padding: 4,
		color: "#777",
	},
});

const TripImportTableHeader = () => {
	const classes = useStyles();
	const headerConfig = createHeaderConfig(classes);
	return (
		<div className="sticky top-0 z-10">
			<SmarthopFormView key={`header_form`} mode={"VIEW"} content={headerConfig} />
		</div>
	);
};

export default TripImportTableHeader;
