import { createSlice } from "@reduxjs/toolkit";

const statusSlice = createSlice({
	name: "status",
	initialState: {
		activeChat: null,
	},
	reducers: {
		setActiveChat: (state, action) => {
			state.activeChat = action.payload;
		},
	},
});

export const { setActiveChat } = statusSlice.actions;
export default statusSlice.reducer;
