import Button from "@material-ui/core/Button";
import { useController } from "react-hook-form";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Icon, Tooltip, Typography } from "@material-ui/core";
import { useContext } from "react";
import { ControlPanelContext } from "../WithControlPanel";

const StyledButton = withStyles({
	root: {
		backgroundColor: "#eee",
		padding: "10px 16.18px",
		borderRadius: 7.5,
	},
})((props) => {
	const { tooltipLabel, disableTooltip, ...buttonProps } = props;
	// Tooltip logs warning when wrapping a disabled button
	return !props.disabled ? (
		<Tooltip title={tooltipLabel} disableHoverListener={disableTooltip}>
			<Button {...buttonProps} />
		</Tooltip>
	) : (
		<Button {...buttonProps} />
	);
});

const ControlButton = ({ control, item, action, ...props }) => {
	const { field } = useController({ name: item.key, control });
	const { mobile, callbacks, disabled, loading } = useContext(ControlPanelContext);

	const { [item.key]: isLoading } = loading;
	const { [item.key]: callback } = callbacks;

	const handleClick = () => {
		if (callback) callback();
		field.onChange({ target: { name: item.key, value: !field.value } });
	};

	const itemView =
		disabled[item.key] && item?.disabledView ? item.disabledView : field.value && item?.altState ? item.altState : item;

	const { startIcon, endIcon, size } = itemView?.config ?? {};
	const hasIcon = startIcon || endIcon;
	return (
		<StyledButton
			{...props}
			tooltipLabel={itemView.label}
			disableTooltip={!mobile}
			size={size ?? "small"}
			disabled={(item.disabled || disabled[item.key]) ?? false}
			startIcon={!mobile && startIcon && <Icon>{startIcon}</Icon>}
			endIcon={!mobile && endIcon && <Icon>{endIcon}</Icon>}
			onClick={handleClick}
		>
			{isLoading ? (
				<CircularProgress size="1.5em" />
			) : mobile && hasIcon ? (
				<Icon> {startIcon || endIcon} </Icon>
			) : (
				<Typography>{itemView.label}</Typography>
			)}
		</StyledButton>
	);
};

export default ControlButton;
