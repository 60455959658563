import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
	Typography,
	DialogContentText,
	CircularProgress,
	Checkbox,
	FormControlLabel,
	Link,
	Chip,
} from "@material-ui/core";

// Stores
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Services
import { getPlatformPublicPlans, getPlatformPlanForCarrier } from "app/services/platformPlanServices";
import { updateOnboardingPreselectedPlan, getOnboardingPreselectedPlan } from "app/services/onboardingServices";
import { setTutorialInfo } from "app/services/flagsServices";
import { getProfileId, getUserTier } from "app/services/LoginService";
import { getCarrierCoupon } from "app/services/couponServices";
import { processTierRestrictions } from "app/main/utils/rolesUtils";

// Components and Views
import { SmarthopConfirmDialog } from "@smarthop/form";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import PromoCodeBar from "./PromoCodeBar";
import TrialCancelationBar from "./TrialCancelationBar";
import PlatformPlanCard from "./PlatformPlanCard";
import PlatformPlanSwitch from "./PlatformPlanSwitch";

// Utils
import { PLAN_SELECTED } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";
import { showSnackbar } from "app/main/utils/snackbarUtil";

const SALES_PHONE_NUMBER = "844-467-1669";
let equipmentSupported = ["Dry Van", "Reefer", "Flatbed", "Power Only"];
let equipmentNotSupported = ["Box Truck", "Oversized", "Hotshot", "Sprinter Van", "Cargo Van", "Other"];

const equipmentPaired = equipmentSupported.concat(equipmentNotSupported).reduce(function (result, value, index, array) {
	if (index % 2 === 0) result.push(array.slice(index, index + 2));
	return result;
}, []);

const EQUIPMENT_MAP = {
	VAN: "Dry Van",
	REEFER: "Reefer",
	FLATBED: "Flatbed",
	PO: "Power Only",
};

function PlatformAvailablePlansView(props) {
	useEffect(() => {
		props?.setTitle?.("Change Your Billing Plan");
		props?.setSize?.("max-w-5xl");
		props?.setClasses?.({ toolbar: "bg-primary", content: "bg-primary-50" });
		// eslint-disable-next-line
	}, []);
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const variant = props.variant;
	const notEnabledSubscription = props.notEnabledSubscription;
	const userId = props.userId ?? props.dataIds?.userId;
	const carrierId = props.carrierId ?? props.dataIds?.carrierId;
	const onProgress = props.onProgress;
	const showPromoCodeBar = props.showPromoCodeBar;
	const isCancelPlanView = props?.isCancelPlanView;
	const handleCancelDialog = props?.handleCancelDialog;

	const userTier = getUserTier();
	const hasPaidTier = userTier && userTier !== "TIER_LIMITED";

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [preselectedPlan, setPreselectedPlan] = useState(null);
	const [priceSelected, setPriceSelected] = useState(null);
	const [inProgress, setInProgress] = useState(false);
	const [equipmentDialog, setEquipmentDialog] = useState(null);
	const [loading, setLoading] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [isSecondaryPrice, setIsSecondaryPrice] = useState(false);

	const paymentRevision = useSelector(({ tools }) => tools.revision["paymentRevision"]);
	const equipmentData = useMemo(() => {
		const defaultEquipment = props?.equipment ? EQUIPMENT_MAP[props?.equipment] : null;
		let defaultData = preselectedPlan?.companyEquipment ?? {};
		if (defaultEquipment && defaultData[defaultEquipment] === undefined) {
			defaultData[defaultEquipment] = true;
		}

		return { ...defaultData, ...(equipmentDialog?.equipment ?? {}) };
	}, [equipmentDialog?.equipment, preselectedPlan?.companyEquipment, props?.equipment]);
	const [disabledAccept, setDisabledAccept] = useState(!Object.values(equipmentData).includes(true));

	const handleCouponChange = (coupon) => {
		setData((data) => ({ ...data, coupon }));
	};

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				let [plans, current, preselected, coupon] = await Promise.all([
					getPlatformPublicPlans(carrierId),
					getPlatformPlanForCarrier(carrierId),
					getOnboardingPreselectedPlan(carrierId),
					getCarrierCoupon(carrierId),
				]);

				// Checking currently subscribed plan
				const selectedPlan = plans?.items?.find(
					(plan) => plan._id === current?._id || plan._id === preselected?.planId
				);

				if (isCancelPlanView) {
					plans = {
						items: plans?.items?.filter(
							(plan) => !processTierRestrictions({ restrictions: [selectedPlan?.tier], tier: plan?.tier })
						),
					};
				}

				// If enterpcie plan subscribed we would show only that plan
				if (selectedPlan?.tier === "TIER_ENTERPRISE") {
					plans = { items: [selectedPlan] };
				}

				if (hasPaidTier) {
					plans = { ...plans, items: plans?.items?.filter((plan) => plan?.tier !== "TIER_LIMITED") };
				}
				setPreselectedPlan(preselected);

				const pricesPlan = [current.metadata?.price, current.metadata?.secondary_price].filter((item) => !!item?.id);
				const hasPlanMultiPrices = pricesPlan.length > 1;
				if (hasPlanMultiPrices) {
					setPriceSelected({ id: current?.subscription?.plan?.id, price: current?.subscription?.plan, plan: current });
				} else {
					setPriceSelected(null);
				}

				setData((data) => ({ plans, current, coupon: coupon ?? data?.coupon }));
			} catch (err) {
				setError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			} finally {
				onProgress?.(false);
				setLoading(false);
			}
		})();

		// eslint-disable-next-line
	}, [carrierId, paymentRevision]);

	const isStillInTrial = data?.current?.subscription?.status === "trialing";
	const hasOrHasSubscriptionBefore = !!data?.current?.deactivated || !!data?.current?.subscription;
	const isTrialAllowed = isStillInTrial || !hasOrHasSubscriptionBefore;

	const onSelectPlan = (plan, priceId, equipment) => {
		const isLimitedPlan = plan?.tier === "TIER_LIMITED";
		if (allowToPreselectPlan && ((isTrialAllowed && plan.trialDays > 0) || isLimitedPlan)) {
			preselectPlan(plan, priceId, equipment);
			return;
		} else if (notEnabledSubscription) {
			showSnackbar(snackbar, "Please complete the previous steps, to subscribe to a plan", "info", 10000);
			return;
		} else {
			dispatch(
				openLoadedFormDialog({
					viewId: "BILLING_PLAN",
					dataIds: {
						carrierId,
						userId,
						planId: plan._id,
						priceId,
						updatePlan: props.dataIds?.updatePlan,
						updateSoftPlan: props.dataIds?.updateSoftPlan,
						equipment: equipment,
						callback: props.dataIds?.callback,
					},
				})
			);
		}
	};

	const preselectPlan = async (plan, priceId, equipment) => {
		try {
			setInProgress(true);
			setPreselectedPlan(plan);
			await updateOnboardingPreselectedPlan(carrierId, { planId: plan._id, priceId, isTrialAllowed, equipment });
			createTrackOrPage(PLAN_SELECTED, { carrierId, planId: plan._id, priceId, isTrialAllowed }, "track");
			setInProgress(false);
			//Only set first step if i didnt set it before
			if (!props?.completeFirstStepOnboarding) {
				setTutorialInfo(getProfileId(), { firstStepsId: "ONBOARDING" });
			}

			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
			setPreselectedPlan(null);
			setInProgress(false);
		}
	};

	// In case plan has not been selected and onboarding has not been completed
	// we allow user to preselect plan and trigger start of the trial in another place
	const allowToPreselectPlan = props.allowToPreselectPlan && !hasOrHasSubscriptionBefore;

	if (error) {
		return (
			<div className="flex w-full min-h-540 items-center justify-center">
				<Typography className="text-14 text-red">{error}</Typography>
			</div>
		);
	}
	if (!data) {
		return (
			<div className="flex flex-col w-full min-h-540 items-center justify-center">
				<CircularProgress />
				<Typography color="primary" className="mt-4 font-normal">
					Loading...
				</Typography>
			</div>
		);
	}

	if (loading) {
		return (
			<div className="flex w-full h-128 flex-col items-center justify-center">
				<CircularProgress />
				<Typography color="primary" variant="subtitle1" className="mt-10">
					{"Loading..."}
				</Typography>
			</div>
		);
	}

	const showPostTrial = data?.current?.postSubscriptionPlan?.endTrialDate;
	const postTrialPlan = data?.plans?.items?.find((plan) => plan._id === data?.current?.postSubscriptionPlan?.plan);

	const platformPlans = data?.plans?.items
		?.map((plan) => {
			const price = isSecondaryPrice
				? plan.secondary_price__view?.metadata?.stripe
				: plan.price__view?.metadata?.stripe;
			const selected = data?.current?._id === plan._id || preselectedPlan?.planId === plan._id;
			const availableToDisable = processTierRestrictions({ restrictions: [postTrialPlan?.tier], tier: plan?.tier });
			const isPlanDisabled = !availableToDisable && showPostTrial;
			const isMostPopular = !!plan.popular;
			return { ...plan, price, selected, isPlanDisabled, isMostPopular };
		})
		?.filter((plan) => !!plan.price || plan.tier === "TIER_LIMITED");

	return (
		<div className={"flex w-full flex-col " + (variant === "dialog" ? " md:py-14 " : "")}>
			{isCancelPlanView && (
				<div className="m-24 text-justify	">
					<Typography color="primary" className="text-16 ml:text-15 font-medium">
						Thank you for being a valued member of our community and for your ongoing support. We understand that
						circumstances can change, which is why we’re pleased to offer you the option to downgrade to a subscription
						plan that aligns better with your current budget. This alternative allows you to continue enjoying our
						high-quality services at a more affordable price. If you're interested in exploring this option or have any
						questions, please feel free to reach out. We're here to assist you in finding the best solution for your
						needs.
					</Typography>
				</div>
			)}

			{/* alerts */}
			{showPostTrial && (
				<TrialCancelationBar carrierId={carrierId} currentPlan={data.current} nextPlan={postTrialPlan} />
			)}
			{showPromoCodeBar && <PromoCodeBar carrierId={carrierId} onChange={handleCouponChange} />}

			{/* switch/toggle */}
			{!!platformPlans?.length && (
				<div className="flex flex-row self-center items-center">
					<Typography color="primary" variant="subtitle1" className="text-18 font-semibold">
						Monthly
					</Typography>
					<PlatformPlanSwitch
						checked={isSecondaryPrice}
						onChange={(event) => setIsSecondaryPrice(event.target.checked)}
					/>
					<Typography color="primary" variant="subtitle1" className="text-18 font-semibold">
						Quaterly
					</Typography>
				</div>
			)}

			{/* switch/toggle */}
			<div className={"flex w-full h-full items-center justify-center mt-2"}>
				<div className="flex flex-row flex-wrap md:flex-nowrap justify-center md:justify-start md:overflow-x-scroll">
					{platformPlans?.length ? (
						platformPlans.map((plan, i) => {
							const disabled = plan.isPlanDisabled ?? (inProgress && preselectedPlan?.planId === plan._id);
							return (
								<div
									key={"plan_" + i}
									className={
										"flex flex-1 w-full justify-center max-w-390 md:max-w-270 ml:max-w-330 p-12 " +
										(variant === "dialog" ? " md:mx-10 md:my-16 " : "")
									}
								>
									{/* {renderItem(plan, isPlanDisabled)} */}
									<div className="relative">
										{plan.isMostPopular && (
											<div className="-top-12 absolute z-10 right-0 w-full text-center">
												<Chip color="primary" className="text-16 font-semibold text-white" label={"MOST POPULAR"} />
											</div>
										)}
										<PlatformPlanCard
											plan={plan}
											price={plan.price}
											selected={plan.selected}
											customer={data?.current}
											coupon={data.coupon}
											period={isSecondaryPrice ? "quaterly" : "monthly"}
											disabled={disabled}
											isTrialAllowed={isTrialAllowed}
											allowToPreselectPlan={allowToPreselectPlan}
											isMostPopular={plan.isMostPopular}
											onClick={() => {
												if (inProgress || disabled) return;

												const priceId = plan.price?.id;
												if (
													equipmentDialog?.blockSubmit ||
													(!equipmentDialog?.submittedEquipment && props?.mode === "ONBOARDING")
												) {
													setEquipmentDialog((d) => ({
														...(d ?? {}),
														open: true,
														plan: plan,
														equipment: equipmentData,
													}));
													return;
												} else {
													onSelectPlan(plan, priceId, equipmentDialog?.equipment);
												}
											}}
										/>
									</div>
								</div>
							);
						})
					) : (
						<Typography variant="subtitle1" color="secondary" className="text-18 m-32 md:text-17">
							Get in touch with our Support team to uncover additional options that align with your needs.
						</Typography>
					)}
				</div>

				{equipmentDialog?.open && (
					<SmarthopConfirmDialog
						open={equipmentDialog.open}
						title={
							equipmentDialog.blockSubmit
								? "Equipment Not Supported"
								: equipmentDialog.submittedEquipment
								? "Equipment Disclaimer"
								: "What equipment do you use? "
						}
						message={
							equipmentDialog.submittedEquipment
								? ""
								: "To ensure our platform can properly serve your needs, please select the types of equipment you use."
						}
						disableAccept={disabledAccept || (!confirmed && equipmentDialog.submittedEquipment)}
						handleAccept={() => {
							// price Stripe Id selected
							const priceId = priceSelected?.id ?? equipmentDialog.plan?.metadata?.price?.id;

							if (equipmentDialog.blockSubmit) {
								setEquipmentDialog((d) => ({
									...(d ?? {}),
									submittedEquipment: false,
									open: false,
									blockSubmit: false,
								}));
							}
							if (!equipmentDialog.submittedEquipment) {
								const filteredSelected = Object.keys(equipmentDialog?.equipment)
									.filter((key) => equipmentDialog?.equipment[key] === true)
									.map((key) => key);

								const selectedUnsupported = equipmentNotSupported.filter(
									(notSupported) => equipmentDialog?.equipment?.[notSupported]
								);
								const selectedSupported = filteredSelected.filter((key) => !selectedUnsupported.includes(key));

								setEquipmentDialog((d) => ({
									...(d ?? {}),
									open: true, //selectedUnsupported.length > 0,
									selectedUnsupported,
									selectedSupported,
									submittedEquipment: true,
									blockSubmit: false, //!selectedSupported.length && selectedUnsupported.length > 0,
								}));
								if (selectedSupported.length && !selectedUnsupported.length) {
									//onSelectPlan(equipmentDialog.plan, priceId, equipmentDialog.equipment);
								} else {
									setConfirmed(false);
								}
							} else {
								if (!equipmentDialog.blockSubmit) {
									onSelectPlan(equipmentDialog.plan, priceId, equipmentDialog.equipment);
								}
								setEquipmentDialog((d) => ({
									...(d ?? {}),
									open: false,
								}));
							}
						}}
						acceptMsg={equipmentDialog.blockSubmit ? "Close" : "Continue"}
						handleClose={() => {
							if (equipmentDialog.submittedEquipment) {
								setEquipmentDialog((d) => ({
									...(d ?? {}),
									submittedEquipment: false,
									blockSubmit: false,
								}));
							} else {
								setEquipmentDialog((d) => ({
									...(d ?? {}),
									open: false,
								}));
							}
						}}
						closeMsg={equipmentDialog.submittedEquipment ? "Back" : "Cancel"}
					>
						<div className="flex flex-col pb-8">
							{!equipmentDialog.submittedEquipment && (
								<SmarthopFormView
									content={{
										form: { noErrorMessage: true },
										items: [
											...equipmentPaired.map((items) => ({
												type: "group",
												content: {
													items: [
														...items.map((e) => ({
															key: e,
															label: e,
															type: "checkbox",
															checkbox: { noErrorMessage: true },
														})),
													],
												},
											})),
										],
									}}
									trackChangedFields={["ALL"]}
									onChangeCommitted={(model) => {
										setDisabledAccept(!Object.values(model).includes(true));
										setEquipmentDialog((d) => ({
											...(d ?? {}),
											equipment: model,
										}));
									}}
									data={equipmentData}
									mode={"EDIT"}
								/>
							)}
							{equipmentDialog.submittedEquipment && equipmentDialog.selectedUnsupported?.length > 0 && (
								<div className="flex flex-col">
									<DialogContentText component={"div"} classes={{ root: "text-13 pt-14 font-semibold" }}>
										Please note that the load booking features within SmartHop are optimized for Dry Van, Reefer, and
										Flatbed equipment. If your fleet includes other types of equipment, you may experience limited load
										recommendations and profitability insights, including maps and indexes for:
									</DialogContentText>
									<div className="ml-8 my-4">
										<div className="text-13 text-red-600 font-medium">
											{equipmentDialog.selectedUnsupported.map((e) => (
												<li key={e}>{e}</li>
											))}
										</div>
									</div>

									<DialogContentText component={"div"} classes={{ root: "text-13 pt-14" }}>
										However, you will still have access to all SmartHop benefits, including viewing all available loads,
										managing trips, and automating your back-office processes. While full functionality is provided for
										Dry Van, Reefer, and Flatbed, we encourage you to explore the platform’s other powerful features
										regardless of your equipment type.
									</DialogContentText>
								</div>
							)}
							{equipmentDialog.submittedEquipment && (
								<DialogContentText component={"div"} classes={{ root: "text-13 pt-14" }}>
									All transactions are final and not refundable. Please reach out to our sales team at{" "}
									<a href="mailto:sales@smarthop.co">sales@smarthop.co</a> or call us at{" "}
									<a href={"tel:+1" + SALES_PHONE_NUMBER}>{SALES_PHONE_NUMBER}</a> for more information.
									<br />
									<br />
									<FormControlLabel
										className="text-13"
										control={
											<Checkbox
												checked={confirmed}
												onChange={(event) => setConfirmed(event.target.checked)}
												name="confirmed"
											/>
										}
										label={
											"By checking this box, you acknowledge that you have read the above message and that you understand that all transactions are final and not refundable."
										}
									/>
								</DialogContentText>
							)}
						</div>
					</SmarthopConfirmDialog>
				)}
			</div>

			{/* link */}
			<div className="flex flex-row items-center justify-center intems-center m-16 px-20 gap-x-4">
				<Typography variant="subtitle1" className="text-16 font-medium">
					{`Go to full`}
				</Typography>
				<Link
					href="https://www.smarthop.com/pricing"
					target="_blank"
					color="secondary"
					rel="noreferrer"
					underline="always"
					className="text-16 font-semibold"
				>
					{"plan comparison"}
				</Link>
				{handleCancelDialog && (
					<>
						<Typography variant="subtitle1" className="text-16 font-medium">
							{`or`}
						</Typography>
						<Link
							onClick={props?.handleCancelDialog}
							variant="subtitle1"
							color="secondary"
							className="text-16 font-semibold"
						>
							{"cancel anyway"}
						</Link>
					</>
				)}
			</div>
		</div>
	);
}

export default PlatformAvailablePlansView;
