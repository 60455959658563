import TablePagination from "@material-ui/core/TablePagination";

function SmarthopPaginationView(props) {
	return (
		<TablePagination
			className="flex-shrink-0"
			component="div"
			count={props.countTotal > 0 ? props.countTotal : 0}
			rowsPerPage={props.countPerPage > 0 ? props.countPerPage : 0}
			page={props.pageNumber > 0 ? props.pageNumber : 0}
			rowsPerPageOptions={props?.disableRowsPerPage ? [props.countPerPage] : [10, 20, 50, 100, 200]}
			classes={props.isView ? { root: "text-13", caption: "text-13" } : {}}
			backIconButtonProps={{
				"aria-label": "Previous Page",
			}}
			nextIconButtonProps={{
				"aria-label": "Next Page",
			}}
			onChangePage={(event, value) => {
				if (props.onChangePage) {
					props.onChangePage(value);
				}
			}}
			onChangeRowsPerPage={(event) => {
				if (props.onChangeRowsPerPage) {
					props.onChangeRowsPerPage(event.target.value);
				}
			}}
		/>
	);
}

export default SmarthopPaginationView;
