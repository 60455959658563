// Constans
const carriers = {
	urlGET: "api/messenger/messages",
	idKey: "_id",
	listenEvent: "chatRevision",
	segmentKey: "ADMIN_CHAT_MESSAGES",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["chat", "who", "createdAt", "sentUnix"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "sentUnix",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "user",
					type: "autocomplete",
					label: "User",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
					},
				},
				{
					key: "reply_status",
					type: "select",
					label: "Reply Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REPLY_PENDING",
							label: "Pending",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal_ids",
					type: "checkbox",
					label: "Show Internal Ids",
				},
				{
					key: "reply",
					type: "checkbox",
					label: "Show Reply Information",
				},
			],
		},
		isHighlighted: (item) => item?.replyRequired && !item?.repliedUser,
		items: [
			{ key: "sentUnix", type: "unix", label: "Sent At" },
			{ key: "_id", type: "id", label: "Message ID", group: "internal_ids" },
			{ key: "chat", type: "id", label: "Chat ID", group: "internal_ids" },
			{ key: "user", type: "id", label: "User ID", group: "internal_ids" },
			{
				key: "replyStatus",
				type: "text",
				builder: (item) => (item.replyRequired ? (item.repliedUser ? "REPLIED" : "PENDING") : "-"),
				label: "Reply Status",
				group: "reply",
			},
			{ key: "repliedUnix", type: "unix", label: "Replied At", group: "reply" },
			{
				key: "replied_user__view",
				type: "user__view",
				label: "Replied By",
				viewConfig: {
					showRole: true,
				},
				group: "reply",
			},
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showTeam: true,
				},
			},
			{
				key: "user__view",
				type: "user__view",
				label: "User",
				viewConfig: {
					showRole: true,
				},
			},
			{ key: "chat_type", type: "text", label: "Chat Type" },
			{ key: "type", type: "text", label: "Message Type" },
			{
				key: "content",
				builder: (item) => JSON.stringify(item?.content, null, 2),
				label: "Content",
				collapsibleLength: 40,
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "open_chat",
						label: "Open Chat",
						variant: "button",
						redirectURLBuilder: (item) => "/chat/trucks?chat_id=" + item.chat,
					},
					{
						key: "chat_details",
						label: "Chat Details",
						redirectURLBuilder: (item) => "/admin/chats/" + item.chat + "/details",
					},
					{
						key: "delete",
						label: "Delete",
						dialog: { formId: "ADMIN_CHAT_MESSAGE_FORM", mode: "DELETE" },
					},
				],
			},
		],
	},
};

export default carriers;
