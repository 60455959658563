const deletePaymentMethod = {
	urlGET: "billing/carriers/:carrierId/stripe-sources/:sourceId",
	urlDELETE: "billing/carriers/:carrierId/stripe-sources/:sourceId",
	idKey: "_id",
	triggerEvent: "paymentRevision",
	listenEvent: "paymentRevision",
	header: { delete: { label: "Delete Payment Method" } },
	messages: { delete: { success: "Payment method deleted." } },
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) =>
						item?.last4
							? `Would you like to permanently delete the payment method ending **** ${item?.last4}?`
							: `Would you like to permanently delete this payment method?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
	},
};
export default deletePaymentMethod;
