import { List } from "@material-ui/core";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import ChatListItemByType from "./ChatListItemByType";

function ChatListSingle(props) {
	const chats = props.chats;
	const onClickChat = props.onClick;
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);

	return (
		<List className="p-0 w-full">
			{chats?.map((chat) => (
				<motion.div
					variants={{ hidden: { opacity: 0, y: 40 }, show: { opacity: 1, y: 0 } }}
					key={"_messageList_" + chat._id}
				>
					<ChatListItemByType
						activeChatId={activeChat?._id}
						message={chat}
						viewerIsOwner={true}
						onClick={(chat) => onClickChat(chat)}
					/>
				</motion.div>
			))}
		</List>
	);
}

export default ChatListSingle;
