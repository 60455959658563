import { SELECT_PATH, UNSELECT_PATH } from "../constants";

export const selectPath = (pathId) => ({
	type: SELECT_PATH,
	payload: pathId,
});

export const unselectPath = () => ({
	type: UNSELECT_PATH,
});
