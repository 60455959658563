import _ from "@lodash";

import { useState, useEffect, useRef, useMemo } from "react";

import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";

import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { NARROW_FIELDS } from "@fuse/default-settings";
import { createTooltip } from "app/main/utils/tableUtils";
import Typography from "@material-ui/core/Typography";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";

// eslint-disable-next-line
const _REGEX_CHARACTERS = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const useStyles = makeStyles((theme) => {
	return {
		icon: {
			position: "relative",
			marginLeft: "-22px",
		},
		disabledText: {
			color: "rgb(107, 114, 128)",
		},
	};
});

function SmarthopTextField(props) {
	const classes = useStyles();
	const [visible, setVisible] = useState(false);
	const [currentValue, setCurrentValue] = useState(props?.fieldObject?.value ?? props?.value ?? "");
	const notifiedValueRef = useRef(props?.fieldObject?.value ?? props?.value ?? "");

	const typeInput = props?.type === "passwordConfirm" ? "password" : props?.type;
	const resourceInfo = props?.resource ? getResourceByKey(props?.resource) : null;

	const selectProps = useMemo(() => {
		let p = { onClick: (e) => e.stopPropagation() };
		if (props.description) {
			p.classes = { icon: classes.icon };
		}

		return p;
	}, [props.description, classes.icon]);

	useDebouncedEffect(
		() => {
			if (notifiedValueRef.current !== currentValue) {
				notifiedValueRef.current = currentValue;
				props.onChangeCommitted?.(props.name, currentValue);
			}
		},
		[currentValue],
		props?.field?.commitDelay ?? 250
	);

	useEffect(() => {
		// WORKAROUND: this component has issues wih state management, it should be done fully outside of the component,
		// current logic allows to keep state management inside, and it causing problems, adding a workaround so
		// if value was changed from outside we need to reset it internally in this text component
		const newValue = props?.fieldObject?.value ?? props?.value ?? "";
		if (currentValue !== newValue) {
			notifiedValueRef.current = currentValue;
			setCurrentValue(newValue);
		}
		// eslint-disable-next-line
	}, [props.fieldObject?.value, props.value]);

	const label =
		(props.label ?? "") +
		(props.required ? " *" : "") +
		(props.orignialValue || props.orignialValue === ""
			? props.orignialValue + "" !== (props?.fieldObject?.value ?? "") + ""
				? " (Edited)"
				: ""
			: "");

	const contenView = (
		<div
			className={
				(props.disabled ? "opacity-60 " : "") +
				" w-full px-4 " +
				(props.field?.variant !== "row" ? " mb-4 mt-4 " : " mb-2 ") +
				(props.field?.variant !== "row" && props?.field?.noErrorMessage ? " pt-4 pb-4 " : "")
			}
		>
			<TextField
				{...(props?.fieldObject ?? {})}
				inputRef={props.inputRef}
				value={currentValue}
				variant={
					props.field?.variant === "row"
						? "standard"
						: props.field?.variant === "skinny"
						? "outlined"
						: props.field?.variant ?? "outlined"
				}
				classes={props.field?.classes ?? {}}
				className="w-full pr-0"
				type={props.type ? props.type : "text"}
				size={props.field?.variant === "skinny" ? "small" : undefined}
				label={props.field?.variant === "row" || props.field?.variant === "skinny" ? "" : label}
				select={props.type === "select"}
				error={!props.disabled ? !!props?.errors?.[props.name]?.message : false}
				helperText={!props.disabled && !props?.field?.noErrorMessage ? props?.errors?.[props.name]?.message : ""}
				rows={props.multiline?.rows}
				multiline={!!props.multiline?.rows}
				disabled={props.disabled}
				FormHelperTextProps={{ classes: { root: "-mt-1 ml-1 mr-1 mb-1" } }}
				onChange={(event) => {
					let { value } = event.target;
					if (props.type === "email") {
						value = value?.toLowerCase();
					}
					if (props.preventCharacter) {
						value = value?.trim().replace(_REGEX_CHARACTERS, "");
					}
					if (_.isInteger(props.field?.maxLength) && props.field?.maxLength > 0) {
						value = value.slice(0, props.field.maxLength);
					}
					event.target.value = value;
					props?.fieldObject?.onChange(event);
					props?.onChange?.(event.target.value);
					setCurrentValue(event.target.value);
				}}
				onBlur={(event) => {
					props.onBlur?.(props.name);
				}}
				SelectProps={selectProps}
				InputProps={{
					pattern: "",
					classes: {
						...(props.input?.classes ?? {}),
						input:
							props.type === "select" && props?.fieldObject?.value === "__NOT_SELECTED__"
								? classes.disabledText
								: undefined,
						root: (props.multiline ? " leading-4 " : " ") + (props.resource && props.type === "select" ? " pr-0 " : ""),
					},
					autoComplete: props.field?.noAutocomplete ? "off" : undefined,
					type: props.icon ? (props.icon !== "visibility" || visible ? "text" : "password") : typeInput,
					endAdornment:
						props.showClearIcon || props.icon || props.description || resourceInfo ? (
							resourceInfo ? (
								<InputAdornment position="end" className={props.type === "select" ? "absolute right-40" : ""}>
									<ResourceView
										type={"icon"}
										description={resourceInfo?.description}
										link={resourceInfo?.link}
										classes={{ iconSize: "text-20" }}
									/>
								</InputAdornment>
							) : props.icon === "visibility" ? (
								<InputAdornment position="end">
									<IconButton onClick={() => setVisible(!visible)}>
										<Icon className="text-20" color="action">
											{visible ? "visibility" : "visibility_off"}
										</Icon>
									</IconButton>
								</InputAdornment>
							) : props.showClearIcon ? (
								<InputAdornment position="end" className={"-mr-10"}>
									<IconButton onClick={() => setCurrentValue("")}>
										<Icon className="text-20" color="action">
											clear
										</Icon>
									</IconButton>
								</InputAdornment>
							) : props.description ? (
								createTooltip(
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											{props.icon ?? "info"}
										</Icon>
									</InputAdornment>,
									<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
										{props.description}
									</Typography>,
									"bg-gray"
								)
							) : (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										{props.icon}
									</Icon>
								</InputAdornment>
							)
						) : null,
				}}
			>
				{props.options?.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
			{props.field?.variant !== "row" && props.field?.variant !== "skinny" && !props?.field?.noErrorMessage && (
				<div
					style={
						!props.disabled && !!props?.errors?.[props.name]?.message
							? {}
							: { height: NARROW_FIELDS ? "1.6rem" : "1.9rem" }
					}
				/>
			)}
		</div>
	);

	if (props.field?.variant === "skinny") {
		return (
			<div className={"flex w-full flex-col mt-2"}>
				<Typography className={"flex text-12 -mb-2 " + (props.disabled ? " opacity-60 " : "")}>{label}</Typography>
				{contenView}
			</div>
		);
	} else if (props.field?.variant === "row") {
		return (
			<div
				className={
					"flex w-full flex-row justify-between " +
					(props.field?.classes?.container?.includes("border-") ? "" : " border-b-1 border-grey-300 ") +
					(props.field?.classes?.container?.includes("mt-") ? "" : " mt-10 ") +
					(props.field?.classes?.container ?? "")
				}
			>
				{label && (
					<Typography
						className={"flex w-1/3 text-black text-12 ml:text-13 pt-2 " + (props.disabled ? " opacity-60 " : "")}
					>
						{label}
					</Typography>
				)}
				<div className={`flex flex-row ${label ? "w-2/3" : "w-full"}`}>{contenView}</div>
			</div>
		);
	} else {
		return contenView;
	}
}

export default SmarthopTextField;
