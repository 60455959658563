import _ from "@lodash";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { parseRolesInfo } from "app/main/utils/rolesUtils";
import { openPinnedPanel } from "app/store/tools/pannelsSlice";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";

import { isOnboardingNotSubmitted } from "app/services/LoginService";
import { increaseNotification, fetchTransactions } from "app/store/transactions/transactionsSlice";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { isEnabled } from "app/services/featureStorageService";
import TransactionStateCurrentList from "app/main/profile/transactionsState/pinned/TransactionStateCurrentList";
import Menu from "@material-ui/core/Menu";

const progressBoxStyle = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const EVENT_TRIGGERS_ACTIONS = {
	REPORT_GENERATION: (dispatch, snackbar, latestTransaction) => {
		if (latestTransaction?.status === "FAILED") {
			showSnackbar(snackbar, "Failed to generate a report", "error");
		} else if (latestTransaction?.status === "FINISHED") {
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			showSnackbar(snackbar, "Report has been generated!", "success");
		}
	},
	INTERNAL_LOADS_GENERATION: (dispatch, snackbar, latestTransaction) => {
		if (latestTransaction?.status === "FAILED") {
			showSnackbar(snackbar, "Failed to add internal loads", "error");
		} else if (latestTransaction?.status === "FINISHED") {
			dispatch(incrementDataRevision({ event: "internalLoadsRevision" }));
			showSnackbar(snackbar, "Internal loads were added!", "success");
		}
	},
};

function CurrentTransactionsPanelMenuItem({ type, color }) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [refreshCounter, setRefreshCounter] = useState(0);
	const triggeredCounters = useRef();

	const dataRevision = useSelector(({ tools }) => tools.revision["transactionUpdateRevision"]);
	const user = useSelector(({ auth }) => auth.user);
	const roleStatus = parseRolesInfo(
		["administrator", "dispatcher", "ops support", "carrier", "carrier_generic", "carrier_manager", "driver"],
		user
	);
	const unreadTotalTransactions = useSelector(
		({ transactions }) => transactions.transactionsUser.unreadTotalTransactions
	);
	const latestFailedId = useSelector(({ transactions }) => transactions.transactionsUser.latestFailedId);
	const activeTransactions = useSelector(({ transactions }) => transactions.transactionsUser.activeTransactions);
	const unreadByType = useSelector(({ transactions }) => transactions.transactionsUser.unreadByType);
	const initialized = useSelector(({ transactions }) => transactions.transactionsUser.initialized);
	const progress = activeTransactions > 0 ? true : false;

	const hasSearchPermission = ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_search_on: true })
		: true;
	const hasBookPermission = ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_book_on: true })
		: true;
	const hasSearchBookPermission = hasSearchPermission && hasBookPermission;

	//Enabled
	const newUIEnabled = isEnabled("NEW_TRANSACTION_UI");

	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		if (!progress) {
			// If nothing to reload and all transactions finsihed, reset counter
			setRefreshCounter(0);
			return;
		}

		let cleared = false;
		let updateTimeout = setTimeout(
			() => {
				if (cleared) return;
				// Reloading all counters
				dispatch(fetchTransactions({ userId: user._id }));
				setRefreshCounter(refreshCounter + 1);
				// Gradually increasing refresh timeout for reports
			},
			refreshCounter > 20 ? 30000 : ((refreshCounter % 5) + 1) * 2000
		);

		return () => {
			cleared = true;
			clearTimeout(updateTimeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTransactions, refreshCounter]);

	useEffect(() => {
		if (dataRevision) {
			dispatch(increaseNotification());
			dispatch(fetchTransactions({ userId: user._id }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataRevision]);

	useEffect(() => {
		if (!initialized) return;

		const newTriggeredCounters = _.cloneDeep(unreadByType);
		if (!triggeredCounters.current) {
			// Initialization, avoiding showing notifications after page reload
			triggeredCounters.current = newTriggeredCounters;
			return;
		}

		const keys = Object.keys(unreadByType ?? {});
		if (keys?.length > 0) {
			keys.forEach((key) => {
				if (newTriggeredCounters[key]?.count !== triggeredCounters.current[key]?.count) {
					const trigger = EVENT_TRIGGERS_ACTIONS[key];
					if (trigger) {
						console.log("[CurrentTransactions] triggering refresh action ->", key);
						trigger(dispatch, snackbar, newTriggeredCounters[key]?.latestTransaction);
					} else {
						console.log("[CurrentTransactions] no refersh trigger found ->", key);
					}
				}
			});
		}

		triggeredCounters.current = newTriggeredCounters;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unreadByType]);

	if (!roleStatus.visible || !hasSearchBookPermission) {
		return null;
	}
	if (isOnboardingNotSubmitted()) {
		return null;
	}

	const openPanel = (event) => {
		if (newUIEnabled) {
			setAnchorEl(event.currentTarget);
			return;
		}
		dispatch(
			openPinnedPanel({
				panelId: "CURRENT_TRANSACTIONS",
			})
		);
	};

	return (
		<>
			{type === "icon" ? (
				<Button className="mx-8 rounded-none" onClick={openPanel}>
					<Box sx={{ display: "inline-flex" }}>
						{progress && <CircularProgress size={30} style={{ color: "#e7683d99", marginTop: -12 }} />}
						<Box sx={progressBoxStyle}>
							<div className="flex flex-col justify-center items-center">
								<Badge
									classes={{ badge: !latestFailedId ? "bg-green text-white" : undefined }}
									badgeContent={newUIEnabled ? 0 : unreadTotalTransactions}
									color={!!latestFailedId ? "error" : "default"}
								>
									<Icon style={{ color }} size="small">
										autorenew
									</Icon>
								</Badge>
								<Typography style={{ color }} className="text-10">
									STATUS
								</Typography>
							</div>
						</Box>
					</Box>
				</Button>
			) : (
				<MenuItem onClick={openPanel} role="button">
					<ListItemIcon className="min-w-40">
						<Icon>autorenew</Icon>
					</ListItemIcon>
					<ListItemText primary="Status" />
				</MenuItem>
			)}
			<Menu
				id="menu-transaction"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				PaperProps={{ style: { width: 460 } }}
			>
				<TransactionStateCurrentList />
			</Menu>
		</>
	);
}

export default CurrentTransactionsPanelMenuItem;
