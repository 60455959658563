import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

function TooltipClickAwayView(props) {
	const TooltipCustom = withStyles(() => ({
		tooltip: {
			backgroundColor: props?.custom?.color,
		},
	}))(Tooltip);

	const TooltipStyle = props?.custom ? TooltipCustom : Tooltip;

	let title = props.title;
	if (title && (typeof title === "string" || title instanceof String)) {
		title = (
			<Typography color="inherit" variant="body2" className="p-3">
				{title}
			</Typography>
		);
	}

	return (
		<div>
			<TooltipStyle classes={props.classes} title={title} interactive>
				<div className="cursor-pointer">{props.children}</div>
			</TooltipStyle>
		</div>
	);
}

export default TooltipClickAwayView;
