import { forwardRef, useEffect, useMemo, useState } from "react";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { getMetaFields, calculateFixedCost, calculateCostPerMile, calculateGMVs } from "./onboarding/settings";
import {
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Icon,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { costStructureFields } from "app/main/profile/cost-structure/calculator/CostStructureCalculatorSettings";
import {
	getCostStructure,
	updateCostStructure,
	createtCostStructure,
	updateTripCostStructure,
} from "app/services/costStructureServices";
import { getCarrierId } from "app/services/LoginService";
import { useDispatch, useSelector } from "react-redux";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useSnackbar } from "notistack";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import CostStructureWarnings from "./CostStructureWarnings";

const carrierId = getCarrierId();

const CostStructureForm = forwardRef(({ dataIds, onDone, onMinimize, onProgress, setTitle, setSize }, _) => {
	useEffect(() => {
		setTitle?.("Cost Structure Form");
		setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [data, setData] = useState({});
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);

	const editMode = useMemo(() => !!dataIds?.id || !!dataIds?.history, [dataIds]);
	const carrierIdToUse = useMemo(() => data?.carrier ?? dataIds?.carrierId ?? carrierId, [data, dataIds]);
	const nativeMobile = useSelector(({ auth }) => auth?.user?.nativeMobile);

	const [equipment, setEquipment] = useState(editMode ? null : "VAN");
	const [useHelper, setUseHelper] = useState(editMode ? null : true);

	const metaFields = useMemo(() => getMetaFields(equipment, false), [equipment]);

	const dataToSend = useMemo(() => {
		const merge = { ...data, ...(data?.meta ?? {}) };
		const dataToUse = { ...merge, ...formData };

		const d = {};
		d.name_cost_structure = dataToUse?.name_cost_structure;
		d.equipment = equipment;
		d.useHelper = useHelper;

		if (d.useHelper) {
			if (!d?.meta) d.meta = {};
			if (!d.meta.fixedHelper) d.meta.fixedHelper = {};
			d.meta.fixedHelper.truckPayment = dataToUse?.fixedHelper?.truckPayment ?? metaFields.truckPayment.defaultValue;
			d.meta.fixedHelper.trailerPayment =
				dataToUse?.fixedHelper?.trailerPayment ?? metaFields.trailerPayment.defaultValue;
			d.meta.fixedHelper.liabilityCargoPolicy =
				dataToUse?.fixedHelper?.liabilityCargoPolicy ?? metaFields.liabilityCargoPolicy.defaultValue;
			d.meta.fixedHelper.physicalDamagePolicy =
				dataToUse?.fixedHelper?.physicalDamagePolicy ?? metaFields.physicalDamagePolicy.defaultValue;
			d.meta.fixedHelper.parking = dataToUse?.fixedHelper?.parking ?? metaFields.parking.defaultValue;
			d.meta.fixedHelper.eld = dataToUse?.fixedHelper?.eld ?? metaFields.eld.defaultValue;
			d.meta.fixedHelper.misc = dataToUse?.fixedHelper?.misc ?? metaFields.misc.defaultValue;
			d.meta.fixedHelper.other = dataToUse?.fixedHelper?.other ?? metaFields.other.defaultValue;

			if (!d.meta.perMileHelper) d.meta.perMileHelper = {};
			d.meta.perMileHelper.avgMilesPerDay =
				dataToUse?.perMileHelper?.avgMilesPerDay ?? metaFields.avgMilesPerDay.defaultValue;
			d.meta.perMileHelper.period = dataToUse?.perMileHelper?.period ?? metaFields.period.defaultValue;
			d.meta.perMileHelper.maintenance = dataToUse?.perMileHelper?.maintenance ?? metaFields.maintenance.defaultValue;
			d.meta.perMileHelper.tires = dataToUse?.perMileHelper?.tires ?? metaFields.tires.defaultValue;

			if (!d.meta.gmvHelper) d.meta.gmvHelper = {};
			d.meta.gmvHelper.fees = dataToUse?.gmvHelper?.fees ?? metaFields.fees.defaultValue;
			d.meta.gmvHelper.otherFees = dataToUse?.gmvHelper?.otherFees ?? metaFields.otherFees.defaultValue;
		}

		const helperFixedMonthlyCosts = calculateFixedCost(d?.meta?.fixedHelper ?? {}, equipment);
		const helperCostPerMile = calculateCostPerMile(d?.meta?.perMileHelper ?? {}, equipment);
		const helperGmv = calculateGMVs(d?.meta?.gmvHelper ?? {}, equipment);

		if (d.useHelper) {
			d.meta.fixedHelper.fixedMonthlyCosts = helperFixedMonthlyCosts;
			d.meta.perMileHelper.costPerMile = helperCostPerMile;
			d.meta.gmvHelper.percentageCost = helperGmv;
		}

		d.fixedMonthlyCosts = useHelper ? helperFixedMonthlyCosts : dataToUse?.fixedMonthlyCosts;
		if (isNaN(d.fixedMonthlyCosts)) {
			d.fixedMonthlyCosts = costStructureFields.fixedMonthlyCosts.defaultValue;
		}

		d.costPerMile = useHelper ? helperCostPerMile : dataToUse?.costPerMile;
		if (isNaN(d.costPerMile)) {
			d.costPerMile = costStructureFields.costPerMile.defaultValue;
		}

		d.percentageCost = useHelper ? helperGmv : dataToUse?.percentageCost;
		if (isNaN(d.percentageCost)) {
			d.percentageCost = costStructureFields.percentageCost.defaultValue;
		}

		d.avgMilesPerDay = useHelper ? dataToUse?.perMileHelper?.avgMilesPerDay : dataToUse?.avgMilesPerDay;
		if (isNaN(d.avgMilesPerDay)) {
			d.avgMilesPerDay = metaFields.avgMilesPerDay.defaultValue;
		}

		d.period = useHelper ? dataToUse?.perMileHelper?.period : dataToUse?.period;
		if (isNaN(d.period)) {
			d.period = metaFields.period.defaultValue;
		}

		d.carrier = carrierIdToUse;

		if (dataIds.history) {
			d._id = dataIds.history._id;
		}

		return d;
	}, [formData, equipment, useHelper, data, metaFields, carrierIdToUse, dataIds]);

	const warnings = useMemo(() => {
		const warns = [];
		if (dataToSend?.costPerMile > 0.2) {
			warns.push("Maintenance Cost Per Mile should be less than $0.2.");
		}

		if (dataToSend?.avgMilesPerDay > 600) {
			warns.push("Average Miles Per Day should be less than 600.");
		}

		return warns;
	}, [dataToSend]);

	const filledRequiredFields = useMemo(() => {
		return !!(
			(dataIds?.history || dataToSend?.name_cost_structure) &&
			dataToSend?.fixedMonthlyCosts &&
			dataToSend?.costPerMile &&
			dataToSend?.percentageCost !== undefined &&
			dataToSend?.avgMilesPerDay &&
			dataToSend?.period
		);
	}, [dataToSend, dataIds]);

	// resets form if equipment changes
	useEffect(() => {
		if (equipment && !editMode) {
			setFormData({
				name_cost_structure: formData?.name_cost_structure,
				useHelper: useHelper,
				fixedHelper: {
					fixedMonthlyCosts: calculateFixedCost({}, equipment),
				},
				perMileHelper: {
					costPerMile: calculateCostPerMile({}, equipment),
				},
				gmvHelper: {
					gmvs: calculateGMVs({}, equipment),
				},
			});
		}
		// eslint-disable-next-line
	}, [equipment]);

	// loads the data on edit
	useEffect(() => {
		if (editMode && loading === false) {
			if (dataIds.history) {
				setData(dataIds.history);
				setEquipment(dataIds.history.equipment);
				setUseHelper(dataIds.history.useHelper ?? false);
			} else {
				setLoading(true);
				const getCost = async () => {
					const res = await getCostStructure(dataIds.id);
					return res;
				};

				getCost().then((res) => {
					setData(res);
					setEquipment(res.equipment);
					setUseHelper(res.useHelper ?? false);
					setLoading(false);
				});
			}
		}
		// eslint-disable-next-line
	}, []);

	const onSubmit = () => {
		setSaving(true);
		const call = editMode
			? dataIds?.history
				? updateTripCostStructure(dataIds.tripId, dataToSend)
				: updateCostStructure(dataIds.id, dataToSend)
			: createtCostStructure(dataToSend);
		call
			.then((res) => {
				setSaving(false);
				dispatch(incrementDataRevision({ event: "costStructureRevision" }));
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				onDone?.();

				if (dataIds?.history && !nativeMobile) {
					dispatch(closeFormDialog());
					dispatch(
						openLoadedFormDialog({
							viewId: "TRIP_VIEW",
							dataIds: {
								tripId: dataIds.tripId,
								carrierId: carrierIdToUse,
								initTab: "PROFIT",
							},
						})
					);
				} else if (!dataIds?.openedFromView) {
					dispatch(
						openLoadedFormDialog({
							viewId: "COST_STRUCTURE_VIEW",
							dataIds: { id: res._id ?? dataIds?.id, carrierId: res.carrier },
						})
					);
				} else if (dataIds?.history && nativeMobile) {
					dispatch(closeFormDialog());
				}

				showSnackbar(snackbar, `Cost Structure ${editMode ? "Updated" : "Created"}`, "success");
			})
			.catch((e) => {
				setSaving(false);
				let errorMessage = e.errors?.[0]?.message ?? `Error ${editMode ? "updating" : "creating"} Cost Structure`;
				showSnackbar(snackbar, errorMessage, "error");
				console.error(e);
			});
	};

	const onChangeCommitted = (update, helperKey) => {
		if (helperKey) {
			setData((cur) => ({
				...cur,
				meta: { ...(cur?.meta ?? {}), [helperKey]: { ...(cur?.meta?.[helperKey] ?? {}), ...update } },
			}));

			setFormData((cur) => ({
				...cur,
				[helperKey]: { ...(cur?.[helperKey] ?? {}), ...update },
			}));
		} else {
			setData((cur) => ({
				...cur,
				...update,
			}));

			setFormData((cur) => ({
				...cur,
				...update,
			}));
		}
	};

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className="p-8">
			<Typography className="flex flex-row w-full mt-2 mb-20 min-h-60 items-center rounded-lg py-10 px-14 bg-blue-100 space-x-8">
				<Icon>info</Icon>
				<span>
					Trip profit is calculated using provided cost structure data and also using dynamically generated costs such
					as Fuel and Driver Pay, make sure you properly configure fuel consumption in trucks profile and driver pay in
					drivers profile
				</span>
			</Typography>

			<div className="flex flex-col md:flex-row md:space-x-4 mx-4">
				{!dataIds?.history && (
					<TextField
						className="mb-8 w-full"
						value={data?.name_cost_structure ?? ""}
						onChange={(e) => onChangeCommitted({ name_cost_structure: e.target.value })}
						required={!dataIds?.history}
						label={costStructureFields.name_cost_structure.label}
						variant="outlined"
						disabled={!!dataIds?.history}
					/>
				)}
				{!dataIds?.history && (
					<FormControl variant="outlined" className="mb-8 w-full">
						<InputLabel id="demo-simple-select-outlined-label">Equipment</InputLabel>
						<Select
							value={equipment ?? "VAN"}
							label="Equipment"
							onChange={(e) => {
								onChangeCommitted({ equipment: e.target.value });
								setEquipment(e.target.value);
							}}
							disabled={!!editMode}
							variant="outlined"
						>
							<MenuItem value="REEFER">Reefer</MenuItem>
							<MenuItem value="VAN">Van</MenuItem>
							<MenuItem value="PO">Power Only</MenuItem>
							<MenuItem value="FLATBED">Flatbed</MenuItem>
							<MenuItem value="SPECIAL">Special</MenuItem>
						</Select>
					</FormControl>
				)}
			</div>

			<FormControl fullWidth className="mb-8 mx-4 mt-8">
				<div className="flex flex-col md:flex-row md:space-x-16 md:content-center md:items-center border-b-1 border-black mb-16">
					<FormLabel>Do you know your cost Per Mile and Fixed Monthly Costs?</FormLabel>
					<RadioGroup
						onChange={(event) => {
							onChangeCommitted({ useHelper: event.target.value === "NO" });
							setUseHelper(event.target.value === "NO");
						}}
						value={useHelper ? "NO" : "YES"}
						className="flex flex-col md:flex-row"
					>
						<FormControlLabel value={"YES"} control={<Radio />} label="Yes" />
						<FormControlLabel value={"NO"} control={<Radio />} label="No" />
					</RadioGroup>
				</div>
			</FormControl>

			{useHelper === true && (
				<>
					<div className="flex flex-col border-1 rounded-md -mt-4">
						<div className="p-10 flex flex-row justify-between items-center content-center border-b-1 mb-12 bg-gray-100">
							<Typography className="text-18">Fixed Monthly Expenses</Typography>
							<Typography className="text-18">
								{dataToSend?.fixedMonthlyCosts?.toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
									maximumFractionDigits: 0,
								})}
								/month
							</Typography>
						</div>
						<SmarthopFormView
							key={`FixedCostHelper${equipment}`}
							data={data?.meta?.fixedHelper ?? {}}
							mode={"EDIT"}
							content={{
								form: { noErrorMessage: true },
								items: [
									{
										type: "group",
										content: {
											items: [metaFields.truckPayment, equipment !== "PO" && metaFields.trailerPayment],
										},
									},
									{
										type: "group",
										content: {
											items: [metaFields.liabilityCargoPolicy, metaFields.physicalDamagePolicy],
										},
									},
									{
										type: "group",
										content: {
											items: [metaFields.parking, metaFields.eld],
										},
									},
									{
										type: "group",
										content: {
											items: [metaFields.misc, metaFields.other],
										},
									},
								],
							}}
							trackChangedFields={["ALL"]}
							dataIds={{ carrierId: carrierIdToUse }}
							onChangeCommitted={(model) => {
								onChangeCommitted(model, "fixedHelper");
							}}
						/>
					</div>

					<div className="flex flex-col border-1 rounded-md mt-16">
						<div className="p-10 flex flex-row justify-between border-b-1 mb-12 bg-gray-100">
							<Typography className="text-18">Maintenance Cost Per Mile</Typography>
							<Typography className="text-18">
								{dataToSend?.costPerMile?.toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
									maximumFractionDigits: 4,
								})}
								/Mile
							</Typography>
						</div>
						<SmarthopFormView
							key={`CostPerMileHelper${equipment}`}
							data={data?.meta?.perMileHelper ?? {}}
							mode={"EDIT"}
							content={{
								form: { noErrorMessage: true },
								items: [
									{
										type: "group",
										content: {
											items: [metaFields.avgMilesPerDay, metaFields.period],
										},
									},
									{
										type: "group",
										content: {
											items: [metaFields.maintenance, metaFields.tires],
										},
									},
								],
							}}
							trackChangedFields={["ALL"]}
							dataIds={{ carrierId: carrierIdToUse }}
							onChangeCommitted={(model) => {
								onChangeCommitted(model, "perMileHelper");
							}}
						/>
					</div>

					<div className="flex flex-col border-1 rounded-md mt-16">
						<div className="p-10 flex flex-row justify-between border-b-1 mb-12 bg-gray-100">
							<Typography className="text-18">GMV Percentage Cost </Typography>
							<Typography className="text-18">{dataToSend?.percentageCost}%</Typography>
						</div>
						<SmarthopFormView
							key={`GmvHelper${equipment}`}
							data={data?.meta?.gmvHelper ?? {}}
							mode={"EDIT"}
							content={{
								form: { noErrorMessage: true },
								items: [
									{
										type: "group",
										content: {
											items: [metaFields.fees, metaFields.otherFees],
										},
									},
								],
							}}
							trackChangedFields={["ALL"]}
							dataIds={{ carrierId: carrierIdToUse }}
							onChangeCommitted={(model) => {
								onChangeCommitted(model, "gmvHelper");
							}}
						/>
					</div>
				</>
			)}
			{useHelper === false && (
				<SmarthopFormView
					key={`MannualEnter${equipment}`}
					data={data}
					mode={"EDIT"}
					content={{
						items: [
							{
								type: "group",
								content: {
									items: [costStructureFields.fixedMonthlyCosts],
								},
							},
							{
								type: "group",
								content: {
									items: [costStructureFields.costPerMile],
								},
							},
							{
								type: "group",
								content: {
									items: [costStructureFields.percentageCost],
								},
							},
							{
								type: "group",
								content: {
									items: [costStructureFields.period, costStructureFields.avgMilesPerDay],
								},
							},
						],
					}}
					trackChangedFields={["ALL"]}
					dataIds={{ carrierId: carrierIdToUse }}
					onChangeCommitted={(model) => {
						onChangeCommitted(model);
					}}
				/>
			)}
			<CostStructureWarnings warnings={warnings} className={useHelper === true ? "mt-8" : "-mt-16"} />
			<Button
				disabled={saving || !filledRequiredFields}
				className="w-full mt-16"
				variant="contained"
				color="secondary"
				onClick={onSubmit}
			>
				Save
			</Button>
		</div>
	);
});

export default CostStructureForm;
