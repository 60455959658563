// Utils
import { formatCurrency } from "app/main/utils/tableUtils";

const declined_transactions = (carrierId) => ({
	urlGET: "billing/carriers/:carrierId/declined-transactions",
	idKey: "_id",
	listenEvent: "",
	segmentKey: "CARRIER_DECLINED_TRANSACTIONS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["created_date", "amount"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_date",
				dir: "asc",
			},
		},
		filters: {
			search: true,
		},
		// TODO: Return payment method information
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{
				key: "created_date",
				type: "date",
				label: "Created Date",
				translate: "",
				grid: { headerTitle: true, valuePefix: "Date " },
			},
			{
				key: "failure_message",
				type: "text",
				label: "Reason",
				translate: "",
				maxLengthStart: 50,
			},
			{
				key: "invoice_id",
				type: "id",
				label: "Invoice ID",
				translate: "",
				group: "internal",
				grid: { headerSubtitle: true, valuePefix: "Invoice ID " },
			},
			{
				key: "invoice__view",
				type: "invoice__view",
				label: "Invoice",
				viewConfig: {
					showStatus: true,
				},
				grid: { headerTitle: true, valuePefix: "Invoice " },
			},
			// {
			// 	key: "source_id",
			// 	type: "text",
			// 	label: "Payment Method ID",
			// 	translate: "",
			// 	group: "payment_method_details"
			// },
			// {
			// 	key: "last4",
			// 	type: "text",
			// 	label: "last 4 Payment Method",
			// 	translate: "",
			// 	group: "payment_method_details"
			// },
			{
				key: "amount",
				type: "text",
				label: "Amount",
				translate: "",
				builder: (item) => formatCurrency(item?.amount),
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "payment-methods",
						label: "Go to Payment Methods",
						translate: "",
						redirectURLBuilder: (item) => "/billing/carriers/" + carrierId + "/payments?tab=1",
					},
					{
						key: "unpaid-invoices",
						label: "Go to Unpaid Invoices",
						translate: "",
						redirectURLBuilder: () => "/admin/billing/unpaid-invoices",
					},
				],
			},
		],
	},
});

export default declined_transactions;
