import moment from "moment";
import uniqIdGenerator from "uniqid";
import TreeVisualizer from "../tree-visualizer/TreeVisualizer";

const TripTimelineVisualizer = (props) => {
	const { open, tripsResponse } = props;

	const getDateRange = (startDate, endDate, extraDays = 3) => {
		startDate = moment.utc(startDate).add(1, "d");
		endDate = moment.utc(endDate);
		const daysDiff = endDate.diff(startDate, "d") + extraDays;
		const dateRange = Array.from(Array(daysDiff).keys()).map((num) =>
			moment.utc(startDate).add(num, "d").format("ddd MMM-DD")
		);
		return dateRange;
	};

	const getTreeAndDateRange = (tripsArray) => {
		const tree = convertTripsArrayToTree(tripsArray);
		const dates = tripsArray
			.map((l) => [moment.utc(l.data.pickup_date), moment.utc(l.data.delivery_date)])
			.flat()
			.map((d) => moment.utc(d))
			.sort((a, b) => a - b);
		const startDate = dates.shift().toISOString();
		const endDate = dates.pop().add(2, "d").toISOString();
		const dateRange = getDateRange(startDate, endDate);
		return { tree, dateRange };
	};

	const convertTripsArrayToTree = (tripsArray) => {
		const rootNode = convertTripToTreeNode(tripsArray[0]);
		rootNode.children = [convertTripToNodeRecursive(tripsArray, 0)];
		return rootNode;
	};

	const convertTripToTreeNode = (trip) => ({
		name: `${trip.data.pickup_address}-${moment(trip.data.pickup_date).format("MMM-DD-HHmm")}`,
		id: uniqIdGenerator(),
		stop: {
			end_date: trip.data.delivery_date,
			start_date: trip.data.pickup_date,
			d_city: trip.data.delivery_address,
			o_city: trip.data.pickup_address,
			taken: true,
			trip: trip._id,
			empty_miles: trip.data.emptymiles,
			loaded_miles: trip.data.miles,
			payment: trip.data.est_price,
			profit: trip.data.est_price,
			rpm: trip.data.est_price / trip.data.miles,
			o_lat: trip.data.pickup_coordinates.lat,
			o_lng: trip.data.pickup_coordinates.long,
			d_lat: trip.data.delivery_coordinates.lat,
			d_lng: trip.data.delivery_coordinates.long,
		},
		children: [],
	});

	const convertTripToNodeRecursive = (tripsArray, index) => {
		const trip = tripsArray[index];
		const node = convertTripToTreeNode(trip);
		if (index !== tripsArray.length - 1) node.children = [convertTripToNodeRecursive(tripsArray, index + 1)];
		return node;
	};

	return open ? (
		<>
			<h1>Trip timeline</h1>
			<TreeVisualizer {...getTreeAndDateRange(tripsResponse.tripsArray)} />
		</>
	) : null;
};

export default TripTimelineVisualizer;
