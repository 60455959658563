import { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => {
	return {
		maxH70vh: {
			maxHeight: "70vh",
		},
		maxH60vh: {
			maxHeight: "60vh",
		},
	};
});

function FuelTermsView(props) {
	useEffect(() => {
		props?.setTitle?.("Fuel Card Use And Reimbursement Agreement");
		// eslint-disable-next-line
	}, []);
	const classes = useStyles();
	return (
		<div className={"flex text-14 " + classes.maxH60vh + " md:" + classes.maxH70vh}>
			<Fragment>
				<div className="w-full flex flex-col m-8">
					<h4>
						<b>
							FUEL CARD USE AND REIMBURSEMENT AGREEMENT <br></br>
							Effective Date: November 01, 2020
						</b>
					</h4>
					<Divider className="flex md:hidden mx-0 mt-12 mb-16" />

					<p className="letter-space-terms-conditions">
						This Agreement, including all terms and conditions set forth herein (the “<strong>Agreement</strong>”), is
						entered into by and between Smarthop Inc. (“<strong>Provider</strong>”) and the customer receiving any Fuel
						Card(s) (as defined below) hereunder (“<strong>Customer</strong>”) (Provider and Customer each, a “
						<strong>Party</strong>” and together, the “<strong>Parties</strong>”) and shall be effective on the date any
						Customer receives any Fuel Card issued hereunder (the “<strong>Effective Date</strong>”). By proceeding with
						Provider, receiving or using one or more Fuel Cards issued hereunder, and clicking accept/agree Customer
						hereby accepts and agrees to each and every term and condition set forth herein.
					</p>

					<p className="text-content-terms">
						WHEREAS, Provider and Customer intend to enter into this Agreement to allow designated Customer drivers to
						use Provider fuel cards issued by Electronic Funds Source LLC (“<strong>Issuer</strong>”) for the purchase
						of fuel only at Pilot/Flying J locations (each, a “<strong>Fuel Stop</strong>”); and
					</p>

					<p className="text-content-terms">
						WHEREAS, Customer agrees to be responsible for all charges, fees and expenses, including theft or fraud,
						incurred with regard to the Issuer fuel cards.
					</p>

					<p className="text-content-terms">
						NOW, THEREFORE, for and in consideration of the mutual covenants, agreements, and stipulations contained
						herein, the sufficiency of which are hereby acknowledged, the Parties hereto agree as follows:
					</p>

					<div className="items-terms-conditons">1. Fuel Cards and Equipment</div>

					<p className="text-content-terms">
						Customer shall be permitted to use Issuer fuel cards designated for such use by Provider (each, a “
						<strong>Fuel Card</strong>” and collectively, the “<strong>Fuel Cards</strong>”). Any features or attributes
						of any Fuel Card issued to Customer hereunder shall be set by Issuer or Provider, as applicable, in their
						sole discretion. The Fuel Cards shall be used exclusively to purchase fuel at Fuel Stop locations only. The
						Fuel Cards shall not be used at any location other than Fuel Stops or for any products or services other
						than purchasing fuel (including, without limitation, for cash advances, atm services or equipment repairs).
						Such use shall be limited to no more than three hundred (300) gallons per day per Fuel Card. Provider or
						Issuer may provide Customer with equipment related to use of the Fuel Cards from time to time. Any such
						equipment so provided shall be utilized only for the purpose of accessing services provided by or through
						Provider or Issuer and for no other purposes, and Customer shall not alter or modify any such equipment
						without the prior written consent of Provider. Any such equipment shall remain at all times the property of
						Provider or Issuer, as applicable, and in the event of the termination of this Agreement, shall be returned
						by Customer to Provider or Issuer, as applicable, in the same condition in which it was received by
						Customer, ordinary wear and tear excepted, to the location specified by Provider at the time of return.
					</p>

					<div className="items-terms-conditons">2. Responsibilities and Duties of Customer.</div>

					<p className="text-content-terms">
						Customer shall use the Fuel Cards in accordance with all applicable laws, regulations, rules, codes and
						orders of federal, state and municipal authorities (collectively, “<strong>Applicable Law</strong>”) and the
						policies and requirements established by Provider, each Fuel Stop and Issuer. All Fuel Cards shall be
						secured in a manner to prevent theft or unauthorized use. Customer shall be responsible for, and does hereby
						assume, all risks of loss or injury arising out of Customer’s use of the Fuel Cards and shall, and does
						hereby agree to, indemnify and hold Provider and its affiliates and its and their respective directors,
						officers, employees and agents harmless for and from any and all claims, demands, suits, judgment costs or
						expenses (including, without limitation, reasonable attorney’s fees) arising out of any use of Customer’s
						Fuel Cards by any person, whether authorized, fraudulent or otherwise. Customer hereby represents and
						warrants that the Fuel Cards will be used only for lawful, commercial business purposes and will not be used
						for unlawful, personal, family or household purposes at any time. Customer further agrees to (i) accept full
						responsibility for all purchases and transactions made as a result of the use of the Fuel Cards and all
						passwords or other security codes or procedures related thereto, (ii) immediately notify Provider by e-mail
						of any loss, theft, unauthorized or fraudulent use of the Fuel Cards, codes, passwords or other security
						codes or procedures related thereto, and (iii) be fully responsible for any unauthorized or fraudulent use
						thereof.
					</p>

					<div className="items-terms-conditons">3. Payment.</div>

					<p className="text-content-terms">
						Customer shall be billed/invoiced weekly for all use of, and charges and expenses associated with, the Fuel
						Cards and hereby authorizes Provider to auto-debit the Account (as defined below) at any time 24 hours after
						receipt of said weekly invoice. Customer acknowledges and agrees that amount(s) owed to Provider hereunder
						will be calculated at an amount equal to the higher of (i) retail price per gallon at the applicable service
						station, minus 15 cents per gallon, or (ii) the price per gallon charged to Provider, in each case, as
						determined by Provider in its sole discretion. Customer shall pay a late charge in an amount equal to the
						lesser of 1.5% per month or portion thereof, or the maximum amount allowed by Applicable Law on any
						outstanding balance not paid when due under this Agreement. All payments and other sums due hereunder are
						absolute and unconditional and are not subject to any abatement, reduction, setoff, defense, counterclaim or
						recoupment for any reason whatsoever. Customer must notify Provider of any disputed item on any invoice or
						bill within 24 hours from the invoice date or it will be deemed undisputed and accepted by Customer.
						Customer hereby authorizes Provider to automatically debit the Auto-Debit Account (the “
						<strong>Account</strong>”) maintained by Customer and set up with Provider (or a third party designated by
						Provider) on or before the Effective Date for the payment of any amount(s) due hereunder. In the event that
						the funds maintained by Customer in such Account are insufficient for any payment due hereunder, Customer
						shall, immediately upon demand, remit to Provider the full amount of any such deficiency. If requested by
						Provider at any time, Customer shall provide Provider with reasonable security for the performance when due
						of its obligations hereunder. Such security shall be in the amount and form as determined by Provider at the
						time of such request. Provider reserves the right to terminate this Agreement or suspend use of the Fuel
						Cards until acceptable security is provided or if Customer refuses to pay any deficiency amount to Provider
						within 24 hours of request of the same.
					</p>

					<div className="items-terms-conditons">4. Insurance.</div>

					<p className="text-content-terms">
						Customer shall obtain, maintain, and pay the premium for insurance coverage with companies satisfactory to
						Provider that shall, at a minimum, consist of comprehensive general liability insurance with limits of at
						least $1,000,000.00 combined single limits. Such insurance shall cover, among other risks, the contractual
						liability assumed by Customer under this Agreement, including the indemnification provisions set forth in
						this Agreement. Notwithstanding the foregoing or anything else contained in this Agreement, nothing in this
						Agreement, nor any policy or limit of insurance held by or applicable to Customer, shall operate as a
						limitation on the liability of Customer under the terms of this Agreement.
					</p>

					<div className="items-terms-conditons">5. Company Policies.</div>

					<p className="text-content-terms">
						Customer does hereby adopt, implement and agree to abide by all written policies and procedures from time to
						time established or maintained by Provider, each Fuel Stop, and Issuer related to the possession or use of
						Fuel Cards, whether set forth in manuals, memoranda, directives, websites, quarterly publications or
						otherwise.
					</p>

					<div className="items-terms-conditons">6. Term and Termination; Default.</div>

					<p className="text-content-terms">
						This Agreement shall remain in full force and effect indefinitely until Customer no longer possesses or has
						access to any Fuel Card provided to Customer hereunder. Provider may, in addition to exercising any other
						remedies that may be available to Provider at law or in equity, terminate this Agreement immediately without
						notice to Customer in the event of a breach of any provision of this Agreement by Customer at any time or
						for any other reason or no reason at all. In the event of Customer’s default under this Agreement,
						including, without limitation, the payment terms provisions hereof, Provider shall have all rights under any
						Applicable Law as well as the right to immediately suspend Customer’s use of the Fuel Cards until such
						breach is cured or to terminate this Agreement effective immediately. Customer’s obligations to pay for all
						outstanding amounts incurred before the effective date of termination shall survive any such termination.
					</p>

					<div className="items-terms-conditons">7. Indemnification.</div>

					<p className="text-content-terms">
						Customer agrees to indemnify, defend and hold harmless Provider and its affiliates and its and their
						respective directors, officers, employees and agents from all actions, causes of action, claims, costs
						(including attorney’s fees), damages, fines, penalties, judgments or liabilities caused by, arising from or
						related to: (1) use and/or possession of the Fuel Cards (including but not limited to all charges, interest
						or fees, failure to return Fuel Cards, and/or fraud and theft); (2) Customer’s performance of its
						obligations under this Agreement; (3) Customer’s failure to comply with the policies or procedures of
						Provider, any Fuel Stop or Issuer; and (4) Customer’s violation of any Applicable Laws. Customer hereby
						acknowledges and agrees that the agreements and understandings of Customer pursuant to this Section include
						the payment by Customer to Provider or the reimbursement to Provider by Customer of any and all costs,
						reasonable attorney’s fees, investigation fees, court costs, litigation expenses, and damages incurred by
						Provider in connection with any claims, causes of action, actions, threatened claims or actions or
						proceedings, whether groundless or not, asserted or threatened against Provider, its affiliates or its or
						their respective directors, officers, employees, agents, or other persons for whom each is responsible.
						Customer hereby acknowledges and agrees that the provisions of this Section shall survive the termination or
						cancellation of this Agreement indefinitely.
					</p>

					<div className="items-terms-conditons">8. Limitation of Liability.</div>

					<p className="text-content-terms">
						Provider shall not be liable to Customer for any loss or damages sustained by Customer as a result of delay
						in servicing any transaction request, delay resulting from equipment failure or transmission failure,
						suspending or terminating use of any Fuel Cards, act of God or any other cause not with the control of
						Provider. IN NO EVENT SHALL PROVIDER BE RESPONSIBLE FOR CONSEQUENTIAL, SPECIAL, INCIDENTAL OR PUNITIVE
						DAMAGES, REGARDLESS OF WHETHER PROVIDER WAS MADE AWARE OF THE POSSIBILITY OF SUCH DAMAGES. PROVIDER MAKES NO
						REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF
						MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
					</p>

					<div className="items-terms-conditons">9. Confidentiality.</div>

					<p className="text-content-terms">
						Customer agrees and covenants to Provider that it shall not, during the performance of this Agreement or at
						any time after the termination or expiration hereof, use or disclose to any third party (other than as
						necessary to carry out its obligations hereunder), the terms, rates or conditions of this Agreement or any
						confidential or proprietary information of Provider disclosed to Customer at any time (including, without
						limitation, any pricing, technical information, file records, procedures, or practices utilized by Provider
						during the course of this Agreement).
					</p>

					<div className="items-terms-conditons">10. Notices.</div>

					<p className="text-content-terms">
						Any written notice required of any Party hereunder shall be provided via United States First Class Certified
						Mail, postage prepaid, return receipt requested and properly addressed to the applicable Party. Any notice
						delivered in such manner shall be deemed effective three (3) business days after being mailed.
						Alternatively, the Parties may provide any written notice required hereunder via any nationally-recognized
						courier, signed receipt or other proof of delivery required, and any notice delivered in such fashion shall
						be effective upon receipt. Written notice delivered by electronic mail or facsimile shall only be deemed
						effective if receipt of such notice is acknowledged in writing (by electronic mail, facsimile, certified
						mail or overnight courier) by an authorized agent of the Party receiving such notice.
					</p>

					<div className="items-terms-conditons">11. Entire Agreement.</div>

					<p className="text-content-terms">
						This Agreement contains the entire agreement between the Parties with respect to the subject matter hereof
						and supersedes all other such agreements, oral or written, between the Parties prior to the date of this
						Agreement.
					</p>

					<div className="items-terms-conditons">12. Inspection and Audit Rights.</div>

					<p className="text-content-terms">
						Provider shall, at its own cost and expense and upon reasonable prior written notice to Customer, have the
						right to inspect and audit records of Customer pertaining to the Account or its performance hereunder, for
						the purposes of verifying payments made or to be made hereunder or confirming the performance of Customer of
						any obligation hereunder. The inspection and audit shall be conducted during regular business hours at the
						place of business where such records are kept or at such other location agreed upon by the Parties. The
						inspection and audit rights granted pursuant to this Section shall remain in full force and effect during
						the term of this Agreement and for a period of one (1) year following the date of expiration or termination
						of this Agreement.
					</p>

					<div className="items-terms-conditons">13. Waiver.</div>

					<p className="text-content-terms">
						Failure of either Party to insist upon strict compliance with any of the terms, covenants or conditions set
						forth in this Agreement shall not be deemed a waiver of the enforceability of such term, covenant or
						condition at any other time or occasion.
					</p>

					<div className="items-terms-conditons">14. Severability.</div>

					<p className="text-content-terms">
						If any provision of this Agreement shall be held invalid by operation of law or by any court of competent
						jurisdiction, or if compliance with or enforcement of any provision of this Agreement is restrained or
						prohibited by any court of competent jurisdiction at any time, the remainder of this Agreement or the
						application of any such provision to persons or entities other than the Parties hereto shall not be affected
						thereby.
					</p>

					<div className="items-terms-conditons">15. Descriptive Headings.</div>

					<p className="text-content-terms">
						The descriptive headings on the various Sections of this Agreement are inserted for convenience only.
					</p>

					<div className="items-terms-conditons">16. Applicable Law, Jurisdiction and Venue.</div>

					<p className="text-content-terms">
						The internal laws of the State of Florida shall govern the interpretation and enforcement of this Agreement
						and the performance of the Parties hereunder without regard to its (or any other jurisdiction’s) conflict of
						laws or choice of law rules. The Parties hereby expressly and irrevocably consent to the exclusive
						jurisdiction and venue of the state and federal courts serving Miami Dade County, Florida with respect to
						any civil action, including but not limited to any injunctive relief, relating to or arising out of this
						Agreement. The Parties expressly waive any objection they may have to such jurisdiction and venue upon the
						grounds of forum non conveniens or any other basis.
					</p>

					<div className="items-terms-conditons">17. Effectiveness.</div>

					<p className="text-content-terms">
						BY CLICKING ACCEPT/AGREE AND RECEIVING OR USING ANY FUEL CARD ISSUED HEREUNDER CUSTOMER HEREBY ACCEPTS AND
						AGREES TO PERFORM AND ABIDE BY ALL THE TERMS AND CONDITIONS SET FORTH HEREIN.
					</p>

					<div className="items-terms-conditons">18. Successors.</div>

					<p className="text-content-terms">
						This Agreement shall be binding on and inure to the benefit of any successor, by merger, acquisition of
						assets or otherwise, to the Parties to the same extent as if each such successor had been an original party
						to this Agreement. Nothing expressed herein is intended or shall be construed to confer upon any person,
						firm or corporation, other than the Parties and those explicitly named herein, any right, remedy or claim
						under or by reason of this Agreement. This Agreement shall be for the sole and exclusive benefit of the
						Parties, and neither this Agreement nor any rights hereunder shall be assignable or transferable by any
						Party hereto without the prior written consent of the other Party hereto.
					</p>

					<div className="items-terms-conditons">19. Effective Date.</div>

					<p className="text-content-terms">This Agreement shall become effective as of the Effective Date.</p>

					<div className="items-terms-conditons">20. Authorized Acceptance.</div>

					<p className="text-content-terms">
						It is agreed and warranted by Customer that the individual(s) accepting the terms and conditions of this
						Agreement are authorized to do so in all respects.
					</p>

					<div className="items-terms-conditons">21. Dispute Resolution.</div>

					<p className="text-content-terms">
						Any and all disputes, claims and controversies between the Parties, including those arising out of or
						relating to this Agreement, the alleged breach of this Agreement and the rights and obligations of the
						Parties hereunder shall be resolved exclusively by binding arbitration. Any such arbitration shall be
						administered by the American Arbitration Association in accordance with its arbitration rules in effect at
						the time of submission to arbitration. The decision of any such arbitrator(s) shall be final and judgment on
						the award rendered may be entered in any court having jurisdiction thereof. Such arbitrator(s) shall have
						the authority to impose all manner of remedies including, where appropriate, injunctive relief; provided,
						however, that under no circumstances shall the arbitrator be permitted to award any punitive, special,
						incidental or consequential damages not explicitly provided for herein. Any arbitration proceedings shall be
						conducted in Miami, FL.
					</p>

					<div className="items-terms-conditons">22. Modifications.</div>

					<p className="text-content-terms">
						Notwithstanding anything contained herein to the contrary, Provider may change, modify, add to and/or remove
						terms of the Agreement (“Changes”) by providing Customer with fifteen (15) days advance written notice of
						such Changes, and Customer’s use of any Fuel Card issued to Customer hereunder after the expiration of such
						notice period identifying any Changes will evidence Customer’s agreement with the Changes to the Agreement
						and its agreement to be bound by the Changes. Any and all Changes to the Agreement made by Provider will
						only apply prospectively from the expiration of the notice period notifying Customer of the Changes. Any
						change, modification, addition or removal of terms of the Agreement proposed by Customer must be
						communicated to Provider in writing and evidenced through a written amendment to this Agreement signed by
						both Parties.
					</p>

					<p className="text-content-terms">
						<strong>
							***THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE THAT MAY BE ENFORCED BY THE PARTIES HERETO.***
						</strong>
					</p>

					<p className="text-content-terms first-line-no">
						<b>14. EXPORT.</b> Please feel free to contact SmartHop at{" "}
						<a href="mailto: hello@smarthop.co">hello@smarthop.co</a> if you have any questions about the terms of this
						Agreement.
					</p>

					<p className="text-content-terms" style={{ textAlign: "center" }}>
						{" "}
						<b>© 2020 SMARTHOP, INC. ALL RIGHTS RESERVED.</b>
					</p>
				</div>
			</Fragment>
		</div>
	);
}

export default FuelTermsView;
