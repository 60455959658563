import { incrementDataRevision } from "app/store/tools/revisionSlice";

const checkBookingStatus = {
	urlGET: "api/transaction/users/:userId/records/:id/booking-check-status",
	idKey: "_id",
	triggerEvent: "transactionsCheckRevision",
	listenEvent: "transactionsCheckRevision",
	header: {
		view: { label: "Booking Status" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{ 
								key: "type", 
								type: "text", 
								label: "Type",
							},
							{ 
								key: "message", 
								type: "text", 
								label: "Message"
							},
						],
					},
				},
			],
			onFinished: (data, dispatch) => {
				dispatch(incrementDataRevision({ event: "transactionsRevision" }));
				dispatch(incrementDataRevision({ event: "transactionInProgressRevision" }));
			},
		}
	},
};

export default checkBookingStatus;