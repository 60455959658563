import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellContainerRow from "./CellContainerRow";

import { useState } from "react";
import { Menu } from "@material-ui/core";
import { React } from "react";
import { formatCurrency } from "app/main/utils/tableUtils";

function AddonsCellView(props) {
	const addonsTotalAmount = props.item?.addonsTotalAmount;
	const addonsCount = props.item?.addonsCount;
	const addons = props.item?.addons;

	const [moreMenuEl, setMoreMenuEl] = useState(null);

	const addonItems = addons.map((item) => {
		const itemUnitCurrency = item.price?.unit_amount ? formatCurrency(item.price?.unit_amount / 100, 2) : "Custom";
		return (
			<CellContainerRow
				variant={"skinny"}
				first={<CellFirstRow textLimit={40} variant={"skinny"} label={item.nickname} />}
				second={
					<div className="flex flex-wrap">
						<CellSecondRow
							label={`${itemUnitCurrency} x ${item.quantity}`}
							variant={"skinny"}
							color={"text-grey-600"}
						/>
					</div>
				}
			/>
		);
	});

	return (
		<>
			<CellContainerRow
				variant={"skinny"}
				first={
					<CellFirstRow
						textLimit={40}
						variant={"skinny"}
						label={addonsTotalAmount}
						onClick={addons?.length ? (e) => setMoreMenuEl(e.target) : undefined}
					/>
				}
				second={
					<div className="flex flex-wrap">
						<CellSecondRow label={`${addonsCount} addon(s)`} variant={"skinny"} color={"text-grey-600"} />
					</div>
				}
			/>
			<Menu id="more-menu" anchorEl={moreMenuEl} open={!!moreMenuEl} onClose={() => setMoreMenuEl(null)}>
				<div className={"flex flex-col px-20 py-10 space-y-10"}>{addonItems}</div>
			</Menu>
		</>
	);
}

export default AddonsCellView;
