const resetOnboarding = {
	urlGET: "api/onboarding/carriers/:carrierId",
	urlPUT: "api/onboarding/carriers/:carrierId/reset",
	idKey: "_id",
	triggerEvent: "resetOnboardingRevision",
	listenEvent: "resetOnboardingRevision",
	header: {
		edit: { label: "Reset Onboarding" },
	},
	content: {
		edit: {
			items: [
				{
					type: "message",
					builder: (item) =>
            `Do you want to restart the onboarding of the user?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE", label: "Reset"},
						],
					},
				},
			],
		},
	},
};

export default resetOnboarding;
