import {
	getLatestStrategyTreeByTruckId,
	getStrategyFromTempVc,
	getStrategyTreeById,
	saveStrategy as saveStrategyService,
} from "app/services/strategyServices";

import {
	addCarrierId,
	addDiagnosticMessage,
	addTruckId,
	changeModel,
	changeResult,
	startSaveLoading,
	startLoading,
	stopLoading,
	stopSaveLoading,
	emptyCart,
} from "./actions";

import {
	ADD_TO_CART,
	CHANGE_RESULT,
	REMOVE_FROM_CART,
	EMPTY_CART,
	SELECT_PATH,
	UNSELECT_PATH,
	ADD_TRUCK_ID,
	ADD_CARRIER_ID,
	CHANGE_MODEL,
	CHANGE_CONTROL_PANEL,
	CLEAN_CONTROL_PANEL,
	CHANGE_TRUCK_INFO,
	CLEAN_TRUCK_INFO,
	START_LOADING,
	STOP_LOADING,
	SET_TABLE_PATHS,
	SAVE_STRATEGY,
	START_SAVE_LOADING,
	STOP_SAVE_LOADING,
	LATEST_STRATEGY,
	CLEAR_DIAGNOSTIC_MESSAGES,
	SET_DIAGNOSTIC_MESSAGES,
	ADD_DIAGNOSTIC_MESSAGE,
	SAVED_ID_STRATEGY,
	TEMP_ID_STRATEGY,
	SET_DATA_MAP,
} from "./constants";

import {
	createSelectedPathsCartPayload,
	flattenSelectedPaths,
	getLoadedModel,
} from "app/main/strategy/utils/strategyUtils";
import { getLoadedModelWithURL, rewriteURLWithMapping } from "../../utils/strategyUtils";
import { convertURLParamsToModel, readURLParameters, STRATEGY_URL_PARAMS_MAPPING } from "app/main/utils/urlUtils";

export const strategyInitialState = {
	model: {},
	result: {},
	dataIds: {},
	truckInfo: {},
	selectedPath: null,
	selectedPaths: {},
	controlPanel: {},
	loading: false,
	saveLoading: false,
	tablePaths: {},
	saveStrategy: true,
	latestStrategy: true,
	topLevel: null,
	diagnosticMessages: [],
	entryPoint: null,
	dataMap: null,
};

const truckInfoReducer = (state = strategyInitialState.truckInfo, action) => {
	if (action.type === CHANGE_TRUCK_INFO) {
		return action.payload;
	} else if (action.type === CLEAN_TRUCK_INFO) {
		return {};
	}
	return state;
};

const modelReducer = (state = strategyInitialState.model, action) => {
	if (action.type === CHANGE_MODEL) {
		return action.payload;
	}
	return state;
};

const loadingReducer = (state = strategyInitialState.loading, action) => {
	if (action.type === START_LOADING) {
		return true;
	} else if (action.type === STOP_LOADING) {
		return false;
	}
	return state;
};
const saveLoadingReducer = (state = strategyInitialState.saveLoading, action) => {
	if (action.type === START_SAVE_LOADING) {
		return true;
	} else if (action.type === STOP_SAVE_LOADING) {
		return false;
	}
	return state;
};

const controlPanelReducer = (state = strategyInitialState.controlPanel, action) => {
	if (action.type === CHANGE_CONTROL_PANEL) {
		return action.payload;
	} else if (action.type === CLEAN_CONTROL_PANEL) {
		return {};
	}
	return state;
};

const dataIdsReducer = (state = strategyInitialState.dataIds, action) => {
	if (action.type === ADD_TRUCK_ID) {
		const truckId = action.payload;
		return { ...state, truckId };
	} else if (action.type === ADD_CARRIER_ID) {
		const carrierId = action.payload;
		return { ...state, carrierId };
	}
	return state;
};

const resultReducer = (state = strategyInitialState.result, action) => {
	if (action.type === CHANGE_RESULT) {
		return action.payload;
	}
	return state;
};

const selectedPathsReducer = (state = strategyInitialState.selectedPaths, action) => {
	if (action.type === ADD_TO_CART) {
		const { selectedMoves, pathId } = action.payload;
		return { ...state, [pathId]: selectedMoves };
	} else if (action.type === REMOVE_FROM_CART) {
		const { [action.payload]: pathRemoved, ...selectedPaths } = state;
		return selectedPaths;
	} else if (action.type === EMPTY_CART) {
		return {};
	}
	return state;
};

const selectedPathReducer = (state = strategyInitialState.selectedPath, action) => {
	if (action.type === SELECT_PATH) {
		return action.payload;
	}
	if (action.type === UNSELECT_PATH) {
		return null;
	}
	return state;
};

const tablePathsReducer = (state = strategyInitialState.tablePaths, action) => {
	if (action.type === SET_TABLE_PATHS) {
		return action.payload;
	}
	return state;
};

const diagnosticMessagesReducer = (state = strategyInitialState.diagnosticMessages, action) => {
	if (action.type === ADD_DIAGNOSTIC_MESSAGE) {
		return [...state, action.payload];
	} else if (action.type === SET_DIAGNOSTIC_MESSAGES) {
		return action.payload;
	} else if (action.type === CLEAR_DIAGNOSTIC_MESSAGES) {
		return [];
	}
	return state;
};

export const saveStrategyReducer = (state = strategyInitialState.saveStrategy, action, entireState) => {
	if (action.type === SAVE_STRATEGY) {
		const { dispatch } = action.payload ?? {};
		const tempVirtualContractId = entireState?.result?.resData?.strategy?.response?._id;
		const data = {
			selected_paths: flattenSelectedPaths(entireState?.tablePaths ?? {}),
			selected_paths_legs: createSelectedPathsCartPayload(entireState?.selectedPaths ?? {}),
		};
		(async () => {
			dispatch?.(startSaveLoading());
			try {
				const { prevStrategyId, nextStrategyId, strategyId, reloadStrategy } = await saveStrategyService(
					tempVirtualContractId,
					data
				);
				const topLevel = entireState.entryPoint === "topLevel";
				if (topLevel) {
					const urlModel = convertURLParamsToModel(readURLParameters(), STRATEGY_URL_PARAMS_MAPPING);
					rewriteURLWithMapping({ ...urlModel, _id: strategyId }, STRATEGY_URL_PARAMS_MAPPING);
				}
				if (reloadStrategy) {
					const result = await getStrategyTreeById(strategyId);
					dispatch?.(changeResult(result));
				} else {
					const resData = { ...entireState.result.resData, isMongo: !!strategyId };
					const tempResult = { ...entireState.result, resData };
					dispatch?.(changeResult({ ...tempResult, prevStrategyId, nextStrategyId }));
				}
			} catch (error) {
				const messages = error.errors?.map((e) => e.message);
				dispatch?.(
					addDiagnosticMessage({
						type: "warning",
						componentName: "RequestError",
						props: { messages },
					})
				);
			}
			dispatch?.(emptyCart());
			dispatch?.(stopSaveLoading());
		})();
	}
	return state;
};

const latestStrategyReducer = (state = strategyInitialState.latestStrategy, action, entireState) => {
	if (action.type === LATEST_STRATEGY) {
		(async () => {
			const { dispatch, onStrategySubmitted } = action.payload ?? {};
			try {
				dispatch?.(startLoading());
				let latestStrategy = await getLatestStrategyTreeByTruckId(
					entireState?.result?.resData?.strategy?.truck ?? entireState?.model?.truck ?? entireState?.dataIds?.truckId,
					entireState?.result?.resData?.strategy?.carrier ??
						entireState?.model?.carrier ??
						entireState?.dataIds?.carrierId,
					{
						compute_plan_data: true,
					}
				);
				if (!latestStrategy?.tree) {
					dispatch?.(
						addDiagnosticMessage({
							type: "warning",
							message: "Currently there is no strategy associated with this truck.",
						})
					);
					return state;
				}
				onStrategySubmitted?.(getLoadedModel(latestStrategy));
				dispatch?.(changeResult(latestStrategy));
			} catch (err) {
				console.error(err);
			} finally {
				dispatch?.(stopLoading());
			}
		})();
	}
	return state;
};

const topLevelReducer = (state = strategyInitialState.topLevel, action) => {
	if (action.type === TEMP_ID_STRATEGY) {
		(async () => {
			const { dispatch, tempVirtualContractId } = action.payload;
			const urlModel = getLoadedModelWithURL();
			try {
				const result = await getStrategyFromTempVc(tempVirtualContractId, urlModel);
				dispatch?.(changeResult(result));
			} catch (err) {
				dispatch?.(addDiagnosticMessage({ type: "warning", componentName: "ExpiredStrategy" }));
			}
			if (urlModel.carrier) dispatch?.(addCarrierId(urlModel.carrier));
			if (urlModel.truck) dispatch?.(addTruckId(urlModel.truck));
			dispatch?.(changeModel(urlModel));
			dispatch?.(stopLoading());
		})();
	} else if (action.type === SAVED_ID_STRATEGY) {
		(async () => {
			const { dispatch, strategyId } = action.payload ?? {};
			const result = await getStrategyTreeById(strategyId);
			const urlModel = getLoadedModelWithURL();
			if (urlModel.carrier) dispatch?.(addCarrierId(urlModel.carrier));
			if (urlModel.truck) dispatch?.(addTruckId(urlModel.truck));
			dispatch?.(changeModel(urlModel));
			dispatch?.(changeResult(result));
			dispatch?.(stopLoading());
		})();
	}

	return state;
};

const dataMapReducer = (state = strategyInitialState.dataMap, action) => {
	if (action.type === SET_DATA_MAP) {
		return action.payload;
	}
	return state;
};

function combineReducers(reducers) {
	return function (state, action) {
		const nextState = {};
		Object.entries(reducers).forEach(([key, reducer]) => {
			nextState[key] = reducer(state[key], action, state);
		});
		return nextState;
	};
}

export const strategyReducer = combineReducers({
	result: resultReducer,
	selectedPaths: selectedPathsReducer,
	selectedPath: selectedPathReducer,
	dataIds: dataIdsReducer,
	model: modelReducer,
	truckInfo: truckInfoReducer,
	controlPanel: controlPanelReducer,
	loading: loadingReducer,
	saveLoading: saveLoadingReducer,
	tablePaths: tablePathsReducer,
	saveStrategy: saveStrategyReducer,
	latestStrategy: latestStrategyReducer,
	diagnosticMessages: diagnosticMessagesReducer,
	topLevel: topLevelReducer,
	dataMap: dataMapReducer,
	entryPoint: (state) => state,
});
