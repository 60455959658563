import { withStyles } from "@material-ui/core/styles";
import { Icon, IconButton, Menu } from "@material-ui/core";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

function WidgetMenuContainer(props) {
	const children = props.children;
	const type = props.type;
	const menu = props.menu;

	return (
		<div className="relative flex flex-row w-full h-full justify-end items-end">
			<div className="flex flex-1 flex-col w-full h-full">{children}</div>
			{type === "PERMANENT" ? (
				<div className="flex w-1/3 flex-col h-full bg-white rounded-lg border-grey-300 border-1 p-8 overflow-y-scroll overflow-x-hidden">
					{menu}
				</div>
			) : type === "FLOATING" ? (
				<StyledMenu
					id="widget-settings"
					anchorEl={props.anchor}
					open={props.opened}
					onClose={props.onClose}
					variant="menu"
				>
					<div className={"w-full pr-20 md:pr-0 " + (props?.classes?.container ?? "")} style={{ position: "relative" }}>
						<div
							className={
								"flex sm:max-w-sm md:max-w-md flex-col items-top pl-10 md:pl-24 pr-44 md:pr-24 pt-0 md:pt-10 pb-0 md:pb-10 max-h-720" +
								(props?.IconButton || props?.chip ? " w-full" : " w-screen")
							}
						>
							{menu}
						</div>
						<div style={{ position: "absolute", right: -2, top: -10 }}>
							{!props?.IconButton && !props?.chip && (
								<IconButton key="close" aria-label="Close" color="secondary" onClick={props.onClose}>
									<Icon className="m-5 text-24">close</Icon>
								</IconButton>
							)}
						</div>
					</div>
				</StyledMenu>
			) : props.opened ? (
				<div className="absolute flex min-w-510 flex-col h-full bg-white rounded-lg top-0 border-grey-300 border-1 p-8 overflow-y-scroll overflow-x-hidden">
					{menu}
				</div>
			) : (
				<div className="hidden flex-col h-full p-8 overflow-y-scroll overflow-x-hidden">{menu}</div>
			)}
		</div>
	);
}

export default WidgetMenuContainer;
