const transactionLogs = {
	urlGET: "api/transaction/users/:userId/records/:id/logs",
	idKey: "currentState_timestamp",
	listenEvent: "transactionsLogsRevision",
	segmentKey: "TRANSACTIONS_STATE_LOGS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		items: [
			{ key: "startState", type: "text", label: "Start State" },
			{
				key: "finishState",
				type: "text",
				label: "End State",
				builder: (item) => {
					return item.finishState || item.currentState;
				},
			},
			{ key: "user", type: "text", label: "User" },
			{ key: "type", type: "text", label: "Result" },
			{ key: "message", type: "text", label: "Message", collapsibleLength: 40 },
			{ key: "timestamp", type: "date", label: "Date" },
			{
				key: "stepsTime.stepTime",
				type: "text",
				label: "Time execution",

				builder: (item) => {
					const time = item?.stepsTime?.stepTime ? item?.stepsTime?.stepTime + " ms " : "-";
					return time;
				},
			},
			{
				key: "async",
				type: "text",
				label: "Type",

				builder: (item) => {
					const typeTransactions = item?.async ? "Async" : "Sync";
					return typeTransactions;
				},
			},
		],
	},
};

export default transactionLogs;
