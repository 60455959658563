// mui
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

// utils
import { generateLegendImageComponent } from "./utils/legend-utils";

const LegendImage = generateLegendImageComponent("strategy");

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.palette.text.primary,
	},
	image: { boxSizing: "border-box", maxHeight: "30px" },
}));

const LegendSummary = ({ icon, imgSrc, label }) => {
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item container xs={2} padding={2} alignContent="center" justify="center">
				<Box marginX={2}>
					{icon && <Icon> {icon} </Icon>}
					{imgSrc && <LegendImage fileName={imgSrc} alt={label} imgProps={{ height: "80%" }} />}
				</Box>
			</Grid>
			<Grid item container xs={10} alignContent="center">
				<Typography className={classes.text} color="inherit">
					{label}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default LegendSummary;
