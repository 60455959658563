const payrollEntrySection = (isDisabled = false) => [
	{
		type: "group",
		content: {
			items: [
				{
					key: "name",
					type: "text",
					label: "Description",
					required: true,
					maxCharacter: 100,
					...(isDisabled ? { disabled: isDisabled } : {}),
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "amount",
					type: "currency",
					label: "Amount",
					required: true,
					min: 0.01,
					...(isDisabled ? { disabled: isDisabled } : {}),
				},
				{
					key: "direction",
					type: "select",
					label: "Direction",
					required: true,
					...(isDisabled ? { disabled: isDisabled } : {}),
					options: [
						{
							value: "CREDIT",
							label: "Addition",
						},
						{
							value: "DEBIT",
							label: "Deduction",
						},
					],
				},
			],
		},
	},
];

export { payrollEntrySection };
