import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useDispatch } from "react-redux";

// UI
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { Typography, Button, Icon } from "@material-ui/core";

// Services
import { getUserId, updateAuthToken, getCarrierId } from "app/services/LoginService";
import {
	getDetailsUser,
	getUserInternalDetails,
	generateEmailConfirmation,
	verifyEmailConfirmation,
	generatePhoneConfirmation,
	verifyPhoneConfirmation,
} from "app/services/usersServices";

// Storage
import { setUserEmailVerified, setUserPhoneVerified } from "app/store/auth/userSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const VIEW_CONFIGS = {
	PHONE: {
		api: {
			valueKey: "phone",
			send: generatePhoneConfirmation,
			validate: verifyPhoneConfirmation,
		},
		dispatch: { onVerified: setUserPhoneVerified },
		snackbar: {
			send: {
				success: "Code was successfully sent to your phone number",
				failure: "Failed to send phone confirmation code",
			},
			validate: { success: "Phone number was successfully verified", failure: "Failed to verify phone number" },
		},
		visuals: {
			send: {
				fieldLabel: "Phone",
				fieldType: "phone",
				icon: "local_phone",
				content: "We need to send you confirmation code to verify your phone number.",
				sendButton: "Send Code",
			},
			validate: {
				icon: "perm_phone_msg",
				content: "An SMS with confirmation code was successfully sent to :value, please check your SMS messages.",
				validateButton: "Verify Phone",
				resendButton: "Resend Code",
			},
			done: {
				icon: "done",
				content: "Congrats, your phone :value was successfully verified.",
				closeButton: "Close",
			},
		},
	},
	EMAIL: {
		api: {
			valueKey: "email",
			send: generateEmailConfirmation,
			validate: verifyEmailConfirmation,
		},
		dispatch: { onVerified: setUserEmailVerified },
		snackbar: {
			send: { success: "Email was successfully sent", failure: "Failed to send confirmation code" },
			validate: { success: "Email was successfully verified", failure: "Failed to verify email address" },
		},
		visuals: {
			send: {
				fieldLabel: "Email",
				fieldType: "email",
				icon: "forward_to_inbox",
				content: "We need to send you confirmation code to verify your email address.",
				sendButton: "Send Confirmation Email",
			},
			validate: {
				icon: "mark_email_read",
				content: "An email confirmation was successfully sent to :value, please check your inbox.",
				validateButton: "Verify Email",
				resendButton: "Resend Confirmation Email",
			},
			done: {
				icon: "done",
				content: "Congrats, your email :value was successfully verified.",
				closeButton: "Close",
			},
		},
	},
};

const CodeVerificationView = ({ type, dataIds, onDone }) => {
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	// Use default user Id if not provided
	const userId = dataIds?.userId ?? getUserId();
	const carrierId = getCarrierId();

	// Value States
	const [value, setValue] = useState(null);
	const [valueError, setValueError] = useState(null);
	// Code State
	const [code, setCode] = useState(null);
	const [codeError, setCodeError] = useState(null);
	// Other States
	const [state, setState] = useState("LOADING");
	const [inProgress, setInProgress] = useState(false);
	const [allowToModify, setAllowToModify] = useState(null);

	const config = VIEW_CONFIGS[type];
	const isNative = window?.ReactNativeWebView ?? false;

	useEffect(() => {
		if (state !== "LOADING") return;

		const callFunction = carrierId ? getDetailsUser(userId, carrierId) : getUserInternalDetails(userId);
		callFunction.then(
			(data) => {
				setState("LOADED");
				setValue(data[config.api.valueKey]);
				setAllowToModify(true);
			},
			(err) => showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, failed to load data...", "error")
		);
		// eslint-disable-next-line
	}, []);

	const handledGeneration = () => {
		if (inProgress) return;
		setInProgress(true);

		config.api.send(userId, { value }).then(
			() => {
				setInProgress(false);
				setState("SENT");
				showSnackbar(snackbar, config.snackbar.send.success, "success");
			},
			(err) => {
				setInProgress(false);
				if (err.errors?.[0]?.type === "value") {
					setValueError(err.errors?.[0]?.message);
					showSnackbar(snackbar, config.snackbar.validate.failure, "error");
				} else {
					showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
				}
			}
		);
	};

	const handledVerification = () => {
		if (inProgress) return;
		setInProgress(true);

		config.api.validate(userId, { value, code }).then(
			({ newAuthToken }) => {
				// IMPORTANT: If auth token was updated it means email was changed, new token required
				// not to loose session, if token is not updated user won't be able to access any API
				if (newAuthToken) {
					console.log("[CodeVerificationView] auth token was updated");
					updateAuthToken(newAuthToken);
				}

				setInProgress(false);
				setState("VERIFIED");
				showSnackbar(snackbar, config.snackbar.validate.success, "success");
				dispatch(config?.dispatch?.onVerified?.(true));
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				if (carrierId) {
					if (isNative)
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_ACTIONS", data: carrierId }));
					dispatch(fetchActions({ carrierId }));
				}
				dispatch(incrementDataRevision({ event: "codeVerificationRevision" }));
			},
			(err) => {
				setInProgress(false);
				if (err.errors?.[0]?.type === "code") {
					setCodeError(err.errors?.[0]?.message);
					showSnackbar(snackbar, config.snackbar.validate.failure, "error");
				} else {
					showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
				}
			}
		);
	};

	return (
		<div
			className={
				"flex flex-col items-center justify-center text-center pb-12 pt-4 " +
				(inProgress ? " opacity-50 pointer-events-none " : "")
			}
		>
			{state === "VERIFIED" ? (
				<>
					<div className="flex flex-row w-full items-center justify-center">
						<Icon className="text-40 text-green mx-14">{config.visuals.done.icon}</Icon>
						<Typography color="textPrimary" className="flex-1 text-14 mb-20 mt-16 mb-10 font-normal">
							{config.visuals.done.content.replaceAll(":value", value)}
						</Typography>
					</div>
					<div className="flex flex-col w-full items-center justify-center mt-8 px-8">
						<Button
							key="close"
							color="secondary"
							variant="contained"
							className="flex-1 w-full px-24"
							onClick={() => onDone()}
						>
							{config.visuals.done.closeButton}
						</Button>
					</div>
				</>
			) : state === "SENT" ? (
				<>
					<div className="flex flex-row w-full items-center justify-center">
						<Icon className="text-40 text-orange mx-14">{config.visuals.validate.icon}</Icon>
						<Typography color="textPrimary" className="flex-1 text-14 mb-20 mt-16 mb-10 font-normal">
							{config.visuals.validate.content.replaceAll(":value", value)}
						</Typography>
					</div>
					<SmarthopFormView
						key="validate"
						content={{
							form: { noErrorMessage: true },
							items: [{ key: "code", type: "text", label: "Code", required: true }],
						}}
						noInitValidation={true}
						trackChangedFields={["code"]}
						errors={codeError ? [{ type: "code", message: codeError }] : null}
						onChangeCommitted={(model) => {
							setCodeError(null);
							setCode(model.code);
						}}
					/>
					<div className="flex flex-col w-full items-center justify-center mt-8 px-8">
						<Button
							key="validate"
							color="secondary"
							variant="contained"
							className="flex-1 w-full mb-16 px-24"
							disabled={!code?.length}
							onClick={() => handledVerification()}
						>
							{config.visuals.validate.validateButton}
						</Button>
						<Button
							key="resend"
							color="primary"
							variant="contained"
							className="flex-1 w-full px-24"
							onClick={() => handledGeneration()}
						>
							{config.visuals.validate.resendButton}
						</Button>
					</div>
				</>
			) : state === "LOADED" ? (
				<>
					<div className="flex flex-row w-full items-center pb-6 justify-center">
						<Icon className="text-40 text-blue mx-14">{config.visuals.send.icon}</Icon>
						<Typography color="textPrimary" className="flex-1 text-14 mb-20 mt-16 font-normal">
							{config.visuals.send.content.replaceAll(":value", value)}
						</Typography>
					</div>
					{allowToModify ? (
						<SmarthopFormView
							key="send"
							data={{ value }}
							content={{
								form: { noErrorMessage: true },
								items: [
									{
										key: "value",
										type: config.visuals.send.fieldType,
										label: config.visuals.send.fieldLabel,
										required: true,
									},
								],
							}}
							noInitValidation={true}
							trackChangedFields={["value"]}
							errors={valueError ? [{ type: "value", message: valueError }] : null}
							onChangeCommitted={(model) => {
								setCodeError(null);
								setValue(model.value);
							}}
						/>
					) : (
						<Typography color="textPrimary" className="text-14 mb-20 mt-6 pb-8 font-medium">
							{value}
						</Typography>
					)}
					<div className="flex flex-col w-full items-center justify-center mt-8 px-8">
						<Button
							key="send"
							color="secondary"
							variant="contained"
							className="flex-1 w-full px-24"
							onClick={() => handledGeneration()}
						>
							{config.visuals.send.sendButton}
						</Button>
					</div>
				</>
			) : state === "LOADING" ? (
				<div className="flex flex-row items-center justify-center my-40">
					<Typography color="textPrimary" className="text-14 mb-20 mt-16 font-normal">
						Loading...
					</Typography>
				</div>
			) : null}
		</div>
	);
};

export default CodeVerificationView;
