import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const ExpiredStrategy = ({ regenerate }) => (
	<>
		<Box sx={{ py: 2 }}>
			<Typography>
				The strategy you're trying to load has expired and market might have shifted. Please request a new one with the
				same paremeters.
			</Typography>
		</Box>
	</>
);

export default ExpiredStrategy;
