import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function BrokerCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const view = Array.isArray(props.view) ? props.view?.[0] : props.view;

	const buildItem = (view, more) => {
		if (!view?.value) {
			return <CellEmptyRow mobile={mobile} variant={variant} />;
		}

		const dialog = {
			viewId: "BROKER_INFO_VIEW",
			dataIds: {
				brokerId: view?.value,
				carrierId: view?.metadata?.carrier,
				customerId: view?.metadata?.carrier ? view?.value : undefined,
				smartpayEnabled: view?.metadata?.smartpayEnabled,
			},
		};

		if (config?.readOnly) {
			return view.label;
		}

		return (
			<CellContainerRow
				mobile={mobile}
				variant={variant}
				key={view.value}
				first={<CellFirstRow mobile={mobile} view={view} variant={variant} label={view?.label} dialog={dialog} />}
				second={
					<div className="flex flex-wrap">
						{config?.showMcNumber && (
							<CellSecondRow mobile={mobile} label={`MC ${view?.metadata?.mcnumber ?? ""}`} variant={variant} />
						)}
						{config?.showType && (
							<CellSecondRow
								mobile={mobile}
								label={`${view?.metadata?.carrier ? "External" : "Internal"}`}
								color={`text-white font-medium px-4 font-medium ${view?.metadata?.carrier ? "bg-orange" : "bg-blue"}`}
								variant={"extra-skinny"}
							/>
						)}
						{config?.showSmartpay && view?.metadata?.smartpayEnabled && (
							<CellSecondRow
								mobile={mobile}
								label={"SmartPay"}
								variant={variant}
								color={"text-white bg-green px-5 mx-2 font-medium"}
							/>
						)}
						{config?.showScammer && view?.metadata?.scammer && (
							<CellSecondRow
								mobile={mobile}
								label="Scam"
								color="text-white bg-red font-medium px-4 font-medium"
								variant={"extra-skinny"}
							/>
						)}
					</div>
				}
				more={more}
			/>
		);
	};

	let extra = [];
	if (Array.isArray(props.view)) {
		for (let i = 1; i < props.view.length; i++) {
			extra.push(buildItem(props.view[i]));
		}
	}

	return buildItem(view, extra);
}

export default BrokerCellView;
