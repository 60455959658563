import React, { useState, useEffect, useRef } from "react";
import { Box, Icon } from "@material-ui/core";

const YouTubePlayer = ({ videoId, variant, mobile, customStyles }) => {
	const playerRef = useRef(null);
	const [player, setPlayer] = useState(null);
	const [muted, setMuted] = useState(true);

	const handleUnmuteClick = () => {
		if (player && player.unMute) {
			player.unMute();
			setMuted(false);
		}
	};

	useEffect(() => {
		const createPlayer = async () => {
			const playerClass = variant ? { width: "100%", height: "100%" } : {};
			try {
				const playerInstance = new window.YT.Player(playerRef.current, {
					...playerClass,
					videoId: videoId,
					playerVars: {
						rel: 0,
						autoplay: 1,
						mute: 1,
					},
				});
				setPlayer(playerInstance);
			} catch (err) {
				console.log("[YouTubePlayer] failed to create player", err);
			}
		};

		if (!player) {
			createPlayer();
		} else if (player && player.loadVideoById) {
			player.loadVideoById(videoId);
		}

		return () => {
			if (player) {
				player.destroy();
			}
		};
		// eslint-disable-next-line
	}, [videoId]);

	const sizeClass = mobile
		? { width: "100%", height: "360px" }
		: variant === "large"
		? { width: "100%", height: "65vh" }
		: {};
	return (
		<div style={{ ...sizeClass, position: "relative", display: "inline-block", ...customStyles }}>
			<div ref={playerRef}></div>
			{muted && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(0, 0, 0, 0.6)",
						cursor: "pointer",
					}}
					onClick={handleUnmuteClick}
				>
					<button
						id="randobutton"
						style={{
							fontSize: "2rem",
							color: "white",
							backgroundColor: "transparent",
							border: "none",
							cursor: "pointer",
						}}
					>
						<Box>
							<Icon className="text-16" style={{ fontSize: 80 }}>
								volume_up
							</Icon>
						</Box>
						UNMUTE
					</button>
				</div>
			)}
		</div>
	);
};

export default YouTubePlayer;
