import i18next from "i18next";
import es from "../i18n/es";

i18next.addResourceBundle("es", "forms", es);

export const validatorDictionary = {
	// A validator function receives the Yup object and item
	ALPHANUMERIC: (fieldYup, item) => {
		return fieldYup.matches(
			"^[a-zA-Z0-9 ]*$",
			`${i18next.t("forms:Value should only contain alphanumeric characters.")}`
		);
	},
	US_PHONE: (fieldYup, item) => {
		return fieldYup.matches(
			"^\\d{10}$",
			`${i18next.t("forms:Phone must have 10 numbers. Do not include country code.")}`
		);
	},
};

export const validatorsKeys = {
	ALPHANUMERIC: "ALPHANUMERIC",
	US_PHONE: "US_PHONE",
};
