import React from "react";
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatLabel, formatTitle } from "./utils";
import { Divider } from "@material-ui/core";
import BannerContainer from "./BannerContainer.js";
// Segment
import { SMARTFUEL_EVENT } from "app/main/segment/segmentType";

function SmartFuelContainer(props) {
	const { data } = props;
	return (
		<>
			{formatTitle("Fuel", data?.hasSmartFuelProgram ? true : false)}
			{data?.hasSmartFuelProgram ? (
				<>
					<Divider className="w-full bg-grey-300 mt-10" />
					{formatLabel("Total Gallons", data?.fuel?.total_galons ? data?.fuel?.total_galons?.toFixed(2) : 0)}
					<Divider className="w-full bg-grey-300" />
					{formatLabel("Savings", formatCurrency(data?.fuel?.total_saving ?? 0))}
				</>
			) : (
				<>
					<BannerContainer
						title={"SmartFuel"}
						subtitle={"*Get national fuel discounts up to $0.56 per gallon with our preferred partner"}
						value={data?.saving}
						url={"https://partners.onrampcard.com/smarthop"}
						description={"Estimates based on your MPG and miles driven in the past 30 days."}
						track={SMARTFUEL_EVENT}
						type="fuel"
					/>
				</>
			)}
		</>
	);
}

export default SmartFuelContainer;
