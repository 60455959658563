const carrierDocumentsForm = {
	urlGET: "api/profile/carriers/:carrierId/details/documents",
	urlPUT: "api/profile/carriers/:carrierId/details/documents",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Documents" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					key: "dot_authority_acceptance",
					type: "upload",
					label: "MC Authority Acceptance Page",
					translate: "MC_AUTHORITY_ACCEPTANCE_PAGE",
					upload: {
						url: "api/profile/carriers/:carrierId/files",
						documentType: "carrier",
						fileName: "dot_authority_acceptance",
					},
				},
				{
					key: "completed_form",
					type: "upload",
					label: "Complete I.R.S. Form W9",
					translate: "COMPLETE_FORM_W9",
					upload: {
						url: "api/profile/carriers/:carrierId/files",
						documentType: "carrier",
						fileName: "completed_form",
					},
				},
				{
					key: "insurance_certificate",
					type: "upload",
					label: "Insurance Certificate",
					translate: "INSURANCE_CERTIFICATE_REFLECTING_INDUSTRY_NORMS",
					upload: {
						url: "api/profile/carriers/:carrierId/files",
						documentType: "carrier",
						fileName: "insurance_certificate",
					},
				},
				{
					type: "group",
					group: window.ismobile() ? {} : { classes: { child0: "w-8/12", child1: "w-4/12" } },
					content: {
						items: [
							{
								key: "billing_letter_assigment",
								type: "upload",
								label: "Pay (Factoring or Void Check)",
								translate: "LETTER_OF_ASSIGMENT",
								upload: {
									url: "api/profile/carriers/:carrierId/files",
									documentType: "carrier",
									fileName: "billing_letter_assigment",
								},
							},
							{
								key: "billing_document_type",
								type: "select",
								label: "Pay Document Type",
								defaultValue: "FACTORING",
								options: [
									{
										value: "FACTORING",
										label: "Factoring Letter",
									},
									{
										value: "VOID_CHECK",
										label: "Void Check",
									},
								],
							},
						],
					},
				},
				{
					key: "dot_safety_letter",
					type: "upload",
					label: "Dot Safety Rating Review Letter",
					translate: "DOT_SAFETY_RATING_REVIEW_LETTER",
					upload: {
						url: "api/profile/carriers/:carrierId/files",
						documentType: "carrier",
						fileName: "dot_safety_letter",
					},
				},
				{
					key: "carrier_registration",
					type: "upload",
					label: "Carrier Registration",
					translate: "CARRIER_REGISTRATION",
					upload: {
						url: "api/profile/carriers/:carrierId/files",
						documentType: "carrier",
						fileName: "carrier_registration",
					},
				},

				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierDocumentsForm;
