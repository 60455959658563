import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellContainerRow from "./CellContainerRow";
import TripsCellView from "./TripsCellView";
import Typography from "@material-ui/core/Typography";

// Consts
import { TRIP_JOURNAL_ENTRY_CATEGORIES, DRIVER_JOURNAL_ENTRY_CATEGORIES } from "app/main/consts";

const CategoryCellView = (props) => {
	const mobile = props.mobile;
	const variant = props.variant;
	const item = props.item;
	const categoryValue = item?.category;
	return (
		<>
			{TRIP_JOURNAL_ENTRY_CATEGORIES.find((category) => category.value === categoryValue) && (
				<TripsCellView {...props} view={item?.trip__view} config={{ showCategory: true }} />
			)}
			{categoryValue === "REPAYMENT" && (
				<CellContainerRow
					mobile={mobile}
					variant={variant}
					first={
						<CellFirstRow
							mobile={mobile}
							view={item.recurrent_payment__view}
							variant={variant}
							dialog={{
								viewId: "PAYROLL_RULES_VIEW",
								mode: "VIEW",
								dataIds: {
									recurrentPaymentId: item.recurrent_payment__view?.value,
									driverId: item.recurrent_payment__view?.metadata?.driver,
									carrierId: item.recurrent_payment__view?.metadata?.carrier,
									userId: item.recurrent_payment__view?.metadata?.user,
								},
							}}
						/>
					}
					second={
						<CellSecondRow
							mobile={mobile}
							label={DRIVER_JOURNAL_ENTRY_CATEGORIES.find((category) => category.value === categoryValue)?.label}
							variant={variant}
						/>
					}
				/>
			)}
			{categoryValue === "FUEL" && (
				<CellContainerRow
					mobile={mobile}
					variant={variant}
					first={
						<CellFirstRow
							mobile={mobile}
							view={item.fuel_transaction__view}
							variant={variant}
							dialog={{
								viewId: "FUEL_TRANSACTION_VIEW",
								mode: "VIEW",
								dataIds: {
									transactionId: item?.fuel_transaction__view?.value,
									carrierId: item?.fuel_transaction__view?.metadata?.carrier,
								},
							}}
						/>
					}
					second={
						<CellSecondRow
							mobile={mobile}
							label={DRIVER_JOURNAL_ENTRY_CATEGORIES.find((category) => category.value === categoryValue)?.label}
							variant={variant}
						/>
					}
				/>
			)}
			{["MANUAL"].includes(categoryValue) && (
				<Typography component={"div"} className={"text-12 ml:text-13"} color={"inherit"}>
					{DRIVER_JOURNAL_ENTRY_CATEGORIES.find((category) => category.value === categoryValue)?.label}
				</Typography>
			)}
		</>
	);
};

export default CategoryCellView;
