import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TimelineContent from "@material-ui/lab/TimelineContent";
import ValidateMoveButton from "app/main/search/load/ValidateMoveButton";

// Adapter for validate move button
const buildLegData = (move) => ({
	origin: move?.data?.stop?.o_city,
	destination: move?.data?.stop?.d_city,
	payment: move?.data?.stop?.payment,
	profit: move?.data?.stop?.profit,
	volume: move?.data?.stop?.avg_volume,
	rpm: move?.data?.stop?.rpm,
	loadedRPM:
		Number(move?.data?.stop?.loaded_miles) > 0
			? Number(move?.data?.stop?.payment) / Number(move?.data?.stop?.loaded_miles)
			: 0,
	loadedMiles: move?.data?.stop?.loaded_miles,
	emptyMiles: move?.data?.stop?.empty_miles,
	empty: move?.data?.stop?.empty,
});

const getMoveDataEntries = (move) =>
	move?.data?.stop?.empty
		? [{ label: "Empty Miles", value: `${move?.data?.stop?.empty_miles} mi` }]
		: [
				{ label: "Est. Payment:", value: `$${move?.data?.stop?.payment}` },
				{ label: "Est. Miles:", value: `${move?.data?.stop?.loaded_miles} mi` },
				{ label: "Est. RPM:", value: `$${move?.data?.stop?.rpm.toFixed(2)}` },
				{ label: "Empty miles:", value: `${move?.data?.stop?.empty_miles} mi` },
				{ label: "Daily Avg. Vol:", value: `${move?.data?.stop?.avg_volume.toFixed()} loads` },
				{ label: "Profit:", value: `$${move?.data?.stop?.profit}` },
		  ];

const MoveDetails = ({ move, strategyReqData, searchId, autoValidate, isLastMove = false, selected = true }) => {
	return (
		<TimelineContent>
			<Box marginTop={1}>
				<Box display="flex" justifyContent="space-between">
					<Typography className="text-13 md:text-14 tracking-wide">
						{!isLastMove ? move?.data?.stop?.o_city : move?.data?.stop?.d_city}
					</Typography>
					{!move?.data?.stop?.empty && !isLastMove && selected && (
						<ValidateMoveButton
							key={move?.data?.stop?.moveId}
							moveId={move?.data?.stop?.moveId}
							legData={buildLegData(move)}
							strategyReqData={strategyReqData}
							loadSearchId={searchId}
							autoValidate={autoValidate}
						/>
					)}
				</Box>
				{!isLastMove && (
					<Box marginTop={1}>
						{getMoveDataEntries(move).map((data) => (
							<Box key={data.label} className={"flex flex-inline pt-0"}>
								<Typography className="text-13 md:text-14 tracking-wide text-gray-600 pr-10 pb-4 min-w-120 ">
									{data.label}
								</Typography>
								<Typography className="text-13 md:text-14 tracking-wide"> {data.value}</Typography>
							</Box>
						))}
					</Box>
				)}
			</Box>
		</TimelineContent>
	);
};

export default MoveDetails;
