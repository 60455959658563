import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useMemo } from "react";

import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import * as featureService from "app/services/featureService";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import {
	FEATURE_TYPE,
	createStatusConfig,
	createUserEnabledConfig,
	createCarrierEnabledConfig,
} from "./adminFeaturesList/adminFeaturesListFilters";
import CellEmptyRow from "../views/CellEmptyRow";

const remove = async (item, dispatch) => {
	await featureService.remove(item.name);
	dispatch(incrementDataRevision({ event: "featureRevision" }));
};

const featureStatus = (feature) => {
	if (feature.status === true) {
		return <Typography className="text-13  font-semibold text-green-700">Yes</Typography>;
	} else if (feature.status === false && !feature?.userIds?.length && !feature?.carrierIds?.length) {
		return <Typography className="text-13 text-center font-semibold text-red-600">No</Typography>;
	} else {
		return (
			<div className="flex flex-col">
				{feature?.userIds?.length > 0 && (
					<Typography className="text-13 font-semibold text-orange-600">Users ({feature.userIds.length})</Typography>
				)}
				{feature?.carrierIds?.length > 0 && (
					<Typography className="text-13 font-semibold text-orange-600">
						Carriers ({feature.carrierIds.length})
					</Typography>
				)}
			</div>
		);
	}
};

const GetFeatureName = ({ item }) => {
	const dispatch = useDispatch();

	if (!item.active) {
		return (
			<div className="flex flex-row">
				<Button
					variant="contained"
					className="mr-10 h-32 my-4 px-12 bg-red text-white"
					onClick={() => remove(item, dispatch)}
				>
					Delete
				</Button>
				<div className="flex flex-col">
					<Typography className="pr-2 mt-3">{item.name}</Typography>
					<Typography className="-mt-3 text-red">Config Not Found</Typography>
				</div>
			</div>
		);
	}

	return item.name;
};

const DisplayDocumentation = ({ item, env }) => {
	const viewFeature = () => {
		return window.open(item?.documentation, "_blank", "noopener,noreferrer");
	};

	return (
		<CellEmptyRow>
			{item?.documentation && (
				<Button variant="contained" color="secondary" className="ml-1 py-4 px-12" onClick={viewFeature}>
					View
				</Button>
			)}
		</CellEmptyRow>
	);
};

const DisplayStatus = ({ item, env }) => {
	const dispatch = useDispatch();

	const editFeature = () => {
		dispatch(
			openLoadedFormDialog({
				formId: "ADMIN_FEATURE_FORM",
				mode: "EDIT",
				dataIds: { featureId: item.name, env },
			})
		);
	};

	const status = useMemo(() => featureStatus(item[env]), [item, env]);

	return (
		<div className="flex flex-row no-wrap item-center justify-center">
			<div className="flex flex-col item-center justify-center">{status}</div>
			<div className="flex flex-col item-center justify-center">
				<Button
					disabled={!item.active}
					variant="contained"
					color="primary"
					className="flex ml-10 py-4 px-12"
					onClick={editFeature}
				>
					Edit
				</Button>
			</div>
		</div>
	);
};

const envs = {
	prd: [
		{
			key: "prd",
			type: "component",
			label: "Prod Enabled",
			builder: (item) => <DisplayStatus item={item} env="prd" />,
			table: { align: "right" },
		},
		{
			key: "prd.updated",
			group: "status_updated",
			type: "date",
			label: "Prod Updated",
		},
		{
			key: "prd.user__view",
			group: "status_changed_by",
			type: "user__view",
			label: "Prod Updated By",
			viewConfig: {
				showEmail: true,
			},
		},
		{
			key: "prd.lastEnabledDate",
			group: "status_updated",
			type: "date",
			label: "Prod Released",
		},
		{
			key: "prd.releasedBy__view",
			group: "status_changed_by",
			type: "user__view",
			label: "Prod Released By",
			viewConfig: {
				showEmail: true,
			},
		},
	],
	staging: [
		{
			key: "staging",
			type: "component",
			label: "Staging Enabled",
			builder: (item) => <DisplayStatus item={item} env="staging" />,
			table: { align: "right" },
		},
		{
			key: "staging.updated",
			group: "status_updated",
			type: "date",
			label: "Staging Updated",
		},
		{
			key: "staging.user__view",
			group: "status_changed_by",
			type: "user__view",
			label: "Staging Updated By",
			viewConfig: {
				showEmail: true,
			},
		},
		{
			key: "staging.lastEnabledDate",
			group: "status_updated",
			type: "date",
			label: "Staging Released",
		},
		{
			key: "staging.releasedBy__view",
			group: "status_changed_by",
			type: "user__view",
			label: "Staging Released By",
			viewConfig: {
				showEmail: true,
			},
		},
	],
	dev: [
		{
			key: "dev",
			type: "component",
			label: "Dev Enabled",
			builder: (item) => <DisplayStatus item={item} env="dev" />,
			table: { align: "right" },
		},
		{
			key: "dev.updated",
			group: "status_updated",
			type: "date",
			label: "Dev Updated",
		},
		{
			key: "dev.user__view",
			group: "status_changed_by",
			type: "user__view",
			label: "Dev Updated By",
			viewConfig: {
				showEmail: true,
			},
		},
		{
			key: "dev.lastEnabledDate",
			group: "status_updated",
			type: "date",
			label: "Dev Released",
		},
		{
			key: "dev.releasedBy__view",
			group: "status_changed_by",
			type: "user__view",
			label: "Dev Released By",
			viewConfig: {
				showEmail: true,
			},
		},
	],
};

const envsFilters = {
	prd: [createStatusConfig("PROD"), createUserEnabledConfig("PROD"), createCarrierEnabledConfig("PROD")],
	staging: [createStatusConfig("STAGING"), createUserEnabledConfig("STAGING"), createCarrierEnabledConfig("STAGING")],
	dev: [createStatusConfig("DEV"), createUserEnabledConfig("DEV"), createCarrierEnabledConfig("DEV")],
};

const envFilters =
	process.env.REACT_APP_ENV === "DEV"
		? [...envsFilters.dev]
		: process.env.REACT_APP_ENV === "PRD"
		? [...envsFilters.prd, ...envsFilters.staging]
		: [...envsFilters.staging, ...envsFilters.prd];
const envFields = process.env.REACT_APP_ENV === "DEV" ? [...envs.dev] : [...envs.prd, ...envs.staging];
const envName = process.env.REACT_APP_ENV === "DEV" ? "dev" : process.env.REACT_APP_ENV === "PRD" ? "prd" : "staging";

const features = {
	urlGET: "api/feature/table",
	idKey: "name",
	listenEvent: "featureRevision",
	triggerEvent: "featureRevision",
	segmentKey: "ADMIN_FEATURES",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["name"],
		},
		pagination: {
			enabled: false,
			resetScroll: true,
		},
		defaults: {
			initial: {
				filters: {
					feature_type: "__NOT_SELECTED__",
				},
			},
			items: [
				{
					label: "All",
					description: "All Features",
					filters: {
						feature_type: "__NOT_SELECTED__",
					},
				},
				{
					label: "Features",
					description: "Standard Features",
					filters: {
						feature_type: "STANDARD",
					},
				},
				{
					label: "QA Features",
					description: "QA Features",
					filters: {
						feature_type: "QA",
					},
				},
			],
		},
		filters: {
			search: true,
			items: [...envFilters, FEATURE_TYPE],
		},
		groups: {
			items: [
				{
					key: "status_updated",
					type: "checkbox",
					label: "Show Updated Dates",
					defaultValue: false,
				},
				{
					key: "status_changed_by",
					type: "checkbox",
					label: "Show User",
					defaultValue: false,
				},
			],
		},
		items: [
			{
				key: "documentation",
				type: "text",
				label: "Docs",
				builder: (item) => <DisplayDocumentation item={item} env={envName} />,
			},
			{ key: "name", type: "text", label: "Name", builder: (item) => <GetFeatureName item={item} /> },
			{ key: "description", type: "text", label: "Description" },
			{ key: "poc", type: "text", label: "POC" },
			{ key: "created", type: "date", label: "Created" },
			...envFields,
		],
	},
};

export default features;
