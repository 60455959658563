import { useState } from "react";
import { motion } from "framer-motion";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const TableCarousel = (props) => {
	const [currIndex, setCurrIndex] = useState(0);
	const items = props.items;

	return (
		<Box>
			<div className="mt-4 flex justify-between items-center px-4">
				<IconButton
					key="previous"
					disabled={!items[currIndex - 1]}
					onClick={() => {
						setCurrIndex(currIndex - 1);
					}}
				>
					<Icon>chevron_left</Icon>
				</IconButton>
				<Typography> Loaded Moves </Typography>
				<IconButton
					key="next"
					disabled={!items[currIndex + 1]}
					onClick={() => {
						setCurrIndex(currIndex + 1);
					}}
				>
					<Icon>chevron_right</Icon>
				</IconButton>
			</div>
			<div className="mt-2 flex flex-col justify-center">
				<motion.div key={currIndex} initial={{ x: 100 }} animate={{ x: 0 }} exit={{ x: 100 }}>
					{items[currIndex]}
				</motion.div>
			</div>
		</Box>
	);
};

export default TableCarousel;
