import { Typography, Icon, Button } from "@material-ui/core";

// Forms / lists
import { registy, SmarthopForm } from "@smarthop/form";

// Views
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import OnboardingSummaryStep from "./OnboardingSummaryStep";
import OnboardingSidebarDescription from "./OnboardingSidebarDescription";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { isEnabled } from "app/services/featureStorageService";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";
import { getFirstIncompleteStep } from "./OnboardSteps/onboardUtil";
import TruckViewOnboarding from "app/main/profile/trucks/TruckViewOnboarding";

// Services
import { setTutorialInfo } from "app/services/flagsServices";
import { getProfileId } from "app/services/LoginService";

const onCompleteSubStep = (dispatch, data) => {
	//Only set first step if i didnt set it before
	if (!data?.completeFirstStepOnboarding) {
		setTutorialInfo(getProfileId(), { firstStepsId: "ONBOARDING" });
	}
	dispatch(incrementDataRevision({ event: "onboardingRevision" }));
};

const stepConfig = {
	path: "/onboarding",
	isComplete: (data) => data.complete_wizzard,
	sidebarHeader: "Onboarding",
	renderSidebarDescription: ({ config, data }) => <OnboardingSidebarDescription config={config} data={data} />,
	navDisabled: ({ config, data }) => data?.onboardingStatus === "REJECTED",
	list: [
		{
			title: "Welcome to SmartHop!",
			subtitle: "Quickly get started with Smarthop",
			pageTitle: "Welcome to SmartHop",
			pageSubtitle:
				"Quickly get started by watching this short video. It will show you how to use the platform and get the most out of it.",
			isComplete: (data) => !!data?.completeFirstStepOnboarding || !!data?.video_step_complete,
			hidden: (data) => !!data.isCreateSubAccount,
			render: ({ userId, data, carrierId, switchToStep, dispatch, config, onCompleteSteps, props }) => {
				return (
					<div className="flex w-11/12 md:w-10/12 mt-10">
						<ResourceView
							type={"video"}
							variant={"large"}
							description={getResourceByKey("INITIAL_ONBOARDING")?.description}
							link={getResourceByKey("INITIAL_ONBOARDING")?.link}
							options={{ showVideoMobile: true }}
						/>
					</div>
				);
			},
		},
		{
			title: "Personal Information",
			subtitle: "Confirm your personal information",
			pageTitle: "Confirm your personal information",
			pageSubtitle:
				"We need to know your personal information in order to send you notifications and information related to your fleet",
			isComplete: (data) => !!data.user?.email_confirmed,
			hidden: (data) => !!data.isCreateSubAccount,
			renderSteps: ({ userId, data, carrierId, switchToStep, config, dispatch, onCompleteSteps, props }) => {
				return [
					{
						title: "Profile Information",
						isComplete: () => true,
						render: () => (
							<div className="w-full">
								<SmarthopForm
									key="onboarding_user_details_key"
									mode={"EDIT"}
									form={registy["USER_DETAILS_FORM"]}
									dataIds={{ userId, carrierId }}
									onDone={() => onCompleteSubStep(dispatch, data)}
									onEdit={() => onCompleteSubStep(dispatch, data)}
								/>
							</div>
						),
					},
					{
						title: "Email Confirmation",
						isComplete: (data) => data?.user?.email_confirmed,
						render: () => (
							<div className="w-full -mt-10">
								<SmarthopForm
									// It's required to relaod this form fully to see new phone status
									key={"onboarding_user_email_key_" + props?.verificationRevision}
									mode={"EDIT"}
									form={registy["USER_DETAILS_EMAIL_FORM"]}
									dataIds={{ userId, carrierId }}
									onDone={() => onCompleteSubStep(dispatch, data)}
									onEdit={() => onCompleteSubStep(dispatch, data)}
								/>
							</div>
						),
					},
					{
						title: "Phone Confirmation",
						isComplete: (data) => data?.user?.phone_confirmed,
						optional: true,
						render: () => (
							<div className="w-full -mt-10">
								<SmarthopForm
									// It's required to relaod this form fully to see new phone status
									key={"onboarding_user_phone_key_" + props?.verificationRevision}
									mode={"EDIT"}
									form={registy["USER_DETAILS_PHONE_FORM"]}
									dataIds={{ userId, carrierId }}
									onDone={() => onCompleteSubStep(dispatch, data)}
									onEdit={() => onCompleteSubStep(dispatch, data)}
								/>
							</div>
						),
					},
				];
			},
			classes: {
				contentContainer: "md:w-10/12",
			},
		},
		{
			title: "Company Information",
			subtitle: "Add name, owner, address, and contact information",
			pageTitle: "Complete Your Company's Information",
			pageSubtitle: "Here's what you need to do to set up your account",
			isComplete: (data) => {
				if (data.onboardingParentValidated) {
					return !!data?.company_information_complete && !!data?.carrier_fleet_name;
				}
				return !!data?.company_information_complete;
			},
			renderSteps: ({ userId, data, carrierId, switchToStep, config, dispatch, onCompleteSteps, props, history }) => {
				return [
					...(data.onboardingParentValidated
						? [
								{
									title: "Fleet name",
									optional: false,
									isComplete: (data) => data?.carrier_fleet_name,
									render: () => {
										return (
											<div className="flex flex-col w-full">
												<SmarthopForm
													key={"onboarding_company_key_" + props?.profileRevision}
													mode={"EDIT"}
													form={registy["CARRIER_COMPANY_EDIT_FLEET_NAME_FORM"]}
													dataIds={{ carrierId }}
													onDone={() => onCompleteSubStep(dispatch, data)}
													onEdit={() => onCompleteSubStep(dispatch, data)}
												/>
											</div>
										);
									},
								},
						  ]
						: []),
					{
						title: "Company Information",
						isComplete: (data) => !!data?.company_information_complete,
						render: () => {
							return (
								<div className="flex flex-col w-full items-center justify-center">
									{isEnabled("ONBOARDING_COST_STRUCTURE") && !props?.nativeMobile && (
										<div className="flex flex-row bg-blue-600 -mt-5 md:-mt-20 mb-20 items-center justify-center rounded-lg px-10 py-6 m-5">
											<Icon className={"text-white ml-8 text-24"}>info</Icon>
											<Typography className={"text-white max-w-480 px-12"}>
												Have doubts about Smarthop platform? Learn how our platform can help you to stay profitable!
											</Typography>
											<Button
												className="text-13 text-white border-white px-24 md:px-16 whitespace-nowrap"
												variant="outlined"
												onClick={() => {
													history.push(`/profile/goals`);
												}}
											>
												Learn Now
											</Button>
										</div>
									)}
									<SmarthopForm
										key={"onboarding_company_key_" + props?.profileRevision}
										mode={"EDIT"}
										form={registy["CARRIER_COMPANY_EDIT_AND_VALIDATE_FORM"]}
										dataIds={{ carrierId }}
										onDone={() => onCompleteSubStep(dispatch, data)}
										onEdit={() => onCompleteSubStep(dispatch, data)}
									/>
								</div>
							);
						},
					},
					...(data.onboardingDotInfoUnedited
						? [
								{
									title: "Ownership Validation",
									optional: true,
									isComplete: (data) =>
										data?.onboardingInformationStatus === "VALIDATED" ||
										data?.onboardingInformationStatus === "VALIDATED_LOCKED",
									render: () => {
										return (
											<div className="flex flex-col w-full">
												<SmarthopForm
													key={"onboarding_company_key_" + props?.profileRevision}
													mode={"EDIT"}
													form={registy["CARRIER_COMPANY_VALIDATE_ONLY_FORM"]}
													dataIds={{ carrierId }}
													onDone={() => onCompleteSubStep(dispatch, data)}
													onEdit={() => onCompleteSubStep(dispatch, data)}
												/>
											</div>
										);
									},
								},
						  ]
						: []),
				];
			},
			classes: {
				contentContainer: "md:w-10/12",
			},
		},
		{
			title: "Supporting Documents",
			subtitle: "Add documents",
			pageTitle: "Add documents",
			isComplete: (data) => {
				const conditionRegistration =
					data.checkInsurance || data?.safer_data?.carrier_information?.operating_status === "NOT AUTHORIZED";
				if (conditionRegistration && !data.checkRegistrationCab) {
					return data.insurance_certificate_flag;
				}
				if (!conditionRegistration && data.checkRegistrationCab) {
					return data.truck;
				}
				return data.truck && data.insurance_certificate_flag;
			},
			hidden: (data) =>
				!data.checkInsurance &&
				!data.checkRegistrationCab &&
				data?.safer_data?.carrier_information?.operating_status !== "NOT AUTHORIZED",

			renderSteps: ({ userId, data, carrierId, switchToStep, config, dispatch, onCompleteSteps, props, history }) => {
				return [
					...(data.checkInsurance || data?.safer_data?.carrier_information?.operating_status === "NOT AUTHORIZED"
						? [
								{
									title: "Insurance Certificate",
									optional: false,
									isComplete: (data) => !!data.insurance_certificate_flag,
									render: () => {
										return (
											<div className="flex flex-col w-full">
												<SmarthopForm
													key={"onboarding_insurance_key_" + props?.profileRevision}
													mode={"EDIT"}
													form={registy["ONBOARDING_DOCUMENT_INSURANCE"]}
													dataIds={{ carrierId }}
													onDone={() => onCompleteSubStep(dispatch, data)}
													onEdit={() => onCompleteSubStep(dispatch, data)}
												/>
											</div>
										);
									},
								},
						  ]
						: []),
					...(data.checkRegistrationCab
						? [
								{
									title: "Add Truck Registration or/and CAB Card",
									optional: false,
									isComplete: (data) => data.truck,
									render: () => {
										console.log(data.truck, " data.truck", { carrierId, ...(data.truck ? { id: data.truck } : {}) });
										return (
											<div className="flex flex-col w-full">
												<TruckViewOnboarding
													mode={data.truck ? "EDIT" : "CREATE"}
													carrierId={carrierId}
													truckId={data.truck}
												/>
											</div>
										);
									},
								},
						  ]
						: []),
				];
			},
		},
		{
			title: "Plan",
			subtitle: "Select the plan that best fits your needs",
			pageTitle: "Select Plan",
			pageSubtitle: ["Select the plan that best fits your needs.", "Cancel anytime. No hidden fees ever."],
			isComplete: (data) => !!data.billing_plan_subscription_complete || !!data.onboardingPreselects?.planId,
			hidden: (data) => !!data.isCreateSubAccount,
			render: ({ userId, data, carrierId, switchToStep, dispatch, config, onCompleteSteps, step, props }) => {
				let notEnabledSubscription =
					getFirstIncompleteStep({ config, data }) < step && !data.billing_plan_subscription_complete;
				return (
					<div className="flex flex-col w-full items-center justify-center -mt-14">
						<PlatformAvailablePlansView
							showPromoCodeBar
							carrierId={carrierId}
							allowToPreselectPlan={true}
							notEnabledSubscription={notEnabledSubscription}
							completeFirstStepOnboarding={data.completeFirstStepOnboarding}
							equipment={data?.metadataQualifier?.equipment}
							mode="ONBOARDING"
						/>
					</div>
				);
			},
			classes: {
				contentContainer: "md:w-full mx-20",
			},
		},
		{
			title: "Confirmation",
			subtitle: "Submit your onboarding information",
			pageTitle: "Complete onboarding",
			pageSubtitle: "Please check your onboarding summary",
			isComplete: (data) => data.complete_wizzard,
			render: ({
				userId,
				data,
				carrierId,
				switchToStep,
				config,
				dispatch,
				onCompleteSteps,
				loadingLastStep,
				props,
			}) => (
				<OnboardingSummaryStep
					config={config}
					data={data}
					switchToStep={switchToStep}
					onCompleteSteps={onCompleteSteps}
					loadingLastStep={loadingLastStep}
					props={props}
				/>
			),
		},
	],
};

export default stepConfig;
