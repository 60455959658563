import { useState } from "react";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import HelpRequestDialog from "./HelpRequestDialog";

import { Typography } from "@material-ui/core";

function HelpRequestMenuItem({ type, color }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			{type === "icon" ? (
				<Button className="mx-8 rounded-none" onClick={() => setOpen(true)}>
					<div className="flex flex-col justify-center items-center">
						<Icon style={{ color }} size="small">
							help_outline
						</Icon>
						<Typography style={{ color }} className="text-10">
							HELP
						</Typography>
					</div>
				</Button>
			) : (
				<MenuItem onClick={() => setOpen(true)} role="button">
					<ListItemIcon className="min-w-40">
						<Icon>help_outline</Icon>
					</ListItemIcon>
					<ListItemText primary="Help" />
				</MenuItem>
			)}

			<HelpRequestDialog open={open} setClose={() => setOpen(false)} />
		</>
	);
}

export default HelpRequestMenuItem;
