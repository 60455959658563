import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";

const TripImportPagination = ({ totalRecords, paginationData, setPaginationData, uploading }) => {
	const { limit, offset } = paginationData;
	const page = parseInt(Math.floor(offset / limit));
	return (
		<Box
			className={`${uploading ? "pointer-events-none opacity-50" : ""} absolute bottom-0 right-0 bg-white w-full z-50`}
		>
			<TablePagination
				className="flex-shrink-0"
				component="div"
				count={totalRecords > 0 ? totalRecords : 0}
				rowsPerPage={limit > 0 ? limit : 0}
				page={page > 0 ? page : 0}
				rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
				backIconButtonProps={{
					"aria-label": "Previous Page",
				}}
				nextIconButtonProps={{
					"aria-label": "Next Page",
				}}
				onChangePage={(event, value) => {
					const newOffset = value * limit;
					setPaginationData((oldPaginationData) => ({
						...oldPaginationData,
						offset: newOffset,
					}));
				}}
				onChangeRowsPerPage={(event) => {
					setPaginationData({
						offset: 0,
						limit: event.target.value,
					});
				}}
			/>
		</Box>
	);
};

export default TripImportPagination;
