import CellEmptyRow from "./CellEmptyRow";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const TripStatusPayrollCellView = (props) => {
	const mobile = props.mobile;
	const variant = props.variant;

	const payrollStatus = props.item?.trip_status_payroll__view;

	const label = payrollStatus?.label;
	const actions = payrollStatus?.metadata?.actions;
	const reasons = payrollStatus?.metadata?.reasons;
	const entriesNotCreated = label === "Entries not created";

	const indexes = ["trip_details", "driver_pay", "team", "investor", "miscellaneous", "entries"];

	if (!label) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const renderIconComponent = (label, Component) => {
		const color = Component ? "text-yellow-800" : "text-red-500";
		Component = Component ?? ErrorOutlineIcon;
		return (
			<div className="flex flex-row items-center">
				<Component sx={{ fontSize: 22 }} className={color}></Component>
				<Typography className="text-13 ml:text-13 pl-6">{label}</Typography>
			</div>
		);
	};

	if (!label) <CellEmptyRow mobile={mobile} variant={variant} />;

	return (
		<CellEmptyRow mobile={mobile} variant={variant}>
			<div className="flex flex-row items-center pr-4">
				{Object.keys(reasons).length ? (
					<Tooltip
						classes={{ tooltip: "drop-shadow-md" }}
						className="cursor-pointer"
						placement="right-start"
						title={
							<List>
								{indexes?.map((index) => (
									<>
										{reasons?.[index] &&
											reasons[index]?.map((value, key) => (
												<ListItem key={value} disablePadding>
													<ListItemText
														primary={
															<Typography color="inherit" className="text-12 p-6 font-light">
																{`- ${value}`}
															</Typography>
														}
													/>
												</ListItem>
											))}
										{actions?.[index] && (
											<Typography color="inherit" className="text-12 p-6 font-light">
												<b>Recommended action:</b> {actions[index]}
											</Typography>
										)}
									</>
								))}
							</List>
						}
					>
						{renderIconComponent(label, entriesNotCreated ? WarningAmberIcon : undefined)}
					</Tooltip>
				) : (
					renderIconComponent(label, entriesNotCreated ? WarningAmberIcon : undefined)
				)}
			</div>
		</CellEmptyRow>
	);
};

export default TripStatusPayrollCellView;
