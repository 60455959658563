import { Typography } from "@material-ui/core";

const GOALS_QUESTION = {
	type: "section",
	label: "On average, what are your monthly goals per truck?"
};

const COSTS_QUESTION = {
	type: "section",
	label: "On average, what are your monthly costs per truck?"
};

const disclaimerView = (text) => (
	<Typography className="text-12 ml:text-13">
		{text}
	</Typography>
);

const goalsSection = (grossKey, rpmKey) => ({
	type: "group",
	content: {
		items: [
			{
				key: grossKey,
				type: "number",
				label: "Average Gross",
				required: true,
				min: 0,
				field: { decimals: 2 },
				description: disclaimerView(
					"This default value will be your target gross per truck; it will be aggregated to show your total monthly gross goal."
				)
			},
			{
				key: rpmKey,
				type: "number",
				label: "Average RPM",
				required: true,
				min: 0,
				field: { decimals: 2 },
				description: disclaimerView(
					"This default value will be your target rate per mile."
				)
			},
		],
	},
});

const costsSection = (loadedDaysKey, costPerMileKey) => ({
	type: "group",
	content: {
		items: [
			{
				key: loadedDaysKey,
				type: "number",
				label: "Loaded Days",
				required: true,
				min: 0,
			},
			{
				key: costPerMileKey,
				type: "number",
				label: "Average Cost Per Mile",
				required: true,
				min: 0,
				field: { decimals: 2 },
				description: disclaimerView(
					"This default value is your variable costs per mile. An example is fuel or maintenance costs, broken down per mile."
				)
			},
		],
	},
});

const createGoalsCarrierform = () => ({
	urlPOST: "api/goals/carriers/:carrierId",
	triggerEvent: ["goalsCarrierRevision", "costStructureRevision", "goalsRevision"],
	header: {
		create: { label: "Create Goals" },
	},
	content: {
		create: {
			items: [
				GOALS_QUESTION,
				goalsSection("averageGross", "averageRpm"),
				COSTS_QUESTION,
				costsSection("loadedDays", "fullCostPerMile"),
				{ type: "action", action: "SUBMIT", label: "Save" }
			],
		},
	},
});

export {
	GOALS_QUESTION,
	COSTS_QUESTION,
	goalsSection,
	costsSection,
}

export default createGoalsCarrierform;
