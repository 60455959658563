import React from "react";
import { formatCurrency } from "app/main/utils/tableUtils";
import { useHistory } from "react-router-dom";
import { formatLabel, formatTitle } from "./utils";
import { Divider } from "@material-ui/core";
import { clickWidget } from "./WidgetUtils.js";

function PerformanceContainer(props) {
	const { data } = props;
	const history = useHistory();

	return (
		<div className="cursor-pointer h-full" onClick={() => clickWidget("goal", history)}>
			{formatTitle("Performance")}
			<Divider className="w-full bg-grey-300 mt-10" />
			{formatLabel("Revenue", formatCurrency(data?.Revenue ?? 0))}
			<Divider className="w-full bg-grey-300" />
			{formatLabel("RPM", formatCurrency(data?.Rpm ?? 0))}
			<Divider className="w-full bg-grey-300" />
			{formatLabel("Est Profit", formatCurrency(data?.Profit ?? 0))}
		</div>
	);
}

export default PerformanceContainer;
