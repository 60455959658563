import _ from "@lodash";
import { forwardRef, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import { DialogContentText, DialogContent, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import SmarthopCheckboxField from "./fields/SmarthopCheckboxField";
import { useForm, Controller } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
	return <Grow ref={ref} {...props} />;
});

function SmarthopConfirmDialog(props) {
	const [viewKey, setViewKey] = useState(parseInt(Math.random() * 100000));
	const [confirmationWord, setConfirmationWord] = useState("");
	const [lastTitle, setLastTitle] = useState(props?.title);
	const [lastMessage, setLastMessage] = useState(props?.message);
	const [lastAcceptMsg, setLastAcceptMsg] = useState(props?.acceptMsg);
	const [lastCloseMsg, setLastCloseMsg] = useState(props?.closeMsg);
	const [lastCheckboxLabel, setLastCheckboxLabel] = useState(props?.checkboxLabel);
	const [typedConfirmation, setTypedConfirmation] = useState(props?.typedConfirmation);
	const { control } = useForm({ mode: "onChange" });

	const handleClose = props.handleClose ?? props.onClose;
	const handleAccept = props.handleAccept ?? props.onAccept;
	const otherActions = props.otherActions;
	const handleCheckbox = props.handleCheckbox ?? props.setCheckboxValue;
	const mode = props.mode ?? "NORMAL";
	const disableAccept = props.disableAccept ?? false;

	// Workaround to avoid dialog message disappearing when dialog is closed
	useEffect(() => {
		if (props.open) {
			setLastMessage(props?.message);
		} else {
			setViewKey(parseInt(Math.random() * 100000));
		}
		// eslint-disable-next-line
	}, [props.open, props?.message]);

	// Workaround to avoid dialog message disappearing when dialog is closed
	useEffect(() => {
		if (props.open) {
			setLastTitle(props?.title);
		}
		// eslint-disable-next-line
	}, [props.open, props?.title]);

	// Workaround to avoid dialog message disappearing when dialog is closed
	useEffect(() => {
		if (props.open) {
			setLastAcceptMsg(props?.acceptMsg);
		}
		// eslint-disable-next-line
	}, [props.open, props?.acceptMsg]);

	// Workaround to avoid dialog message disappearing when dialog is closed
	useEffect(() => {
		if (props.open) {
			setLastCloseMsg(props?.closeMsg);
		}
		// eslint-disable-next-line
	}, [props.open, props?.closeMsg]);

	// Workaround to avoid dialog message disappearing when dialog is closed
	useEffect(() => {
		if (props.open) {
			setTypedConfirmation(props?.typedConfirmation);
		}
		// eslint-disable-next-line
	}, [props.open, props?.typedConfirmation]);

	// Workaround to avoid dialog message disappearing when dialog is closed
	useEffect(() => {
		if (props.open) {
			setLastCheckboxLabel(props?.checkboxLabel);
		}
		// eslint-disable-next-line
	}, [props.open, props?.checkboxLabel]);

	const onClose = (...params) => {
		handleClose?.(...params);
		setConfirmationWord("");
	};

	const onAccept = () => {
		handleAccept?.();
		setConfirmationWord("");
	};

	const onChangeCheckbox = (value) => {
		handleCheckbox?.(value);
	};

	return (
		<div key={"view_key_" + viewKey}>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-describedby="alert-dialog-slide-description"
				PaperProps={{
					square: true,
					variant: "outlined",
				}}
				maxWidth={props.maxWidth}
			>
				{lastTitle && (
					<DialogTitle className="py-0">
						<Typography className="tracking-wide text-16 pt-20 text-grey-800">{lastTitle}</Typography>
					</DialogTitle>
				)}
				{(props.children || lastMessage || lastCheckboxLabel) && (
					<DialogContent className="py-0">
						{lastMessage && (
							<DialogContentText component={"div"} classes={{ root: "text-13 pt-14" }}>
								{lastMessage}
							</DialogContentText>
						)}
						{lastCheckboxLabel && (
							<DialogContentText component={"div"} classes={{ root: "text-13" }}>
								<Controller
									name={"checkbox_key_" + viewKey}
									control={control}
									render={({ field }) => {
										return (
											<SmarthopCheckboxField
												key={"checkbox_key_" + viewKey}
												name={"checkbox_key_" + viewKey}
												control={control}
												errors={[]}
												label={lastCheckboxLabel}
												value={field.value}
												onChange={field.onChange}
												onChangeCommitted={(model, value) => onChangeCheckbox(value)}
												checkbox={{ noErrorMessage: true }}
											/>
										);
									}}
								/>
							</DialogContentText>
						)}
						{props.children}
					</DialogContent>
				)}
				{typedConfirmation && (
					<TextField
						variant="outlined"
						type="text"
						placeholder={`Type '${typedConfirmation}' to confirm.`}
						className="w-full px-20 mb-14 mt-6"
						value={confirmationWord}
						onChange={(event) => setConfirmationWord(event.target.value)}
					/>
				)}
				{(!!handleClose || !!handleAccept || otherActions?.length > 0) && mode === "NORMAL" && (
					<DialogActions>
						<div className="mx-6 mb-14">
							{otherActions?.length > 0 &&
								otherActions?.map((action, i) => (
									<Button
										key={"other_action_" + i}
										className="tracking-wide min-w-120 ml-8 text-14 font-medium text-grey-800 my-4 md:my-2"
										variant="outlined"
										onClick={action.onClick}
									>
										{action.label}
									</Button>
								))}
							{!handleClose || props?.hideCloseBtn ? null : (
								<Button
									className="tracking-wide min-w-120 ml-8 text-14 font-medium text-grey-800 my-4 md:my-2"
									variant="outlined"
									onClick={onClose}
								>
									{lastCloseMsg ?? (handleAccept ? "Cancel" : "Close")}
								</Button>
							)}
							{!handleAccept ? null : (
								<Button
									className="tracking-wide min-w-120 ml-8 text-14 font-medium my-4 md:my-2"
									variant="outlined"
									color="secondary"
									onClick={onAccept}
									disabled={
										(typedConfirmation &&
											!_.isEqual(confirmationWord?.toLocaleLowerCase(), typedConfirmation?.toLocaleLowerCase())) ||
										disableAccept
									}
								>
									{lastAcceptMsg ?? "Continue"}
								</Button>
							)}
						</div>
					</DialogActions>
				)}

				{mode === "WARNING" && (
					<DialogActions>
						<div className="mx-6 mb-14">
							<Button
								className="tracking-wide min-w-120 ml-8 text-14 font-medium text-grey-800 my-4 md:my-2"
								variant="outlined"
								onClick={onClose}
							>
								{lastCloseMsg ?? "Close"}
							</Button>
						</div>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
}

export default SmarthopConfirmDialog;
