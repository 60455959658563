const credentialInformationForm = {
	urlGET: "api/credentials/:credentialId",
	urlPUT: "api/credentials/:credentialId",
	urlPOST: "api/credentials",
	urlDELETE: "api/credentials/:credentialId",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit Credential Information" },
		create: { label: "Add Credential" },
		view: { label: "View Credential Information" },
		delete: { label: "Delete Credential Information" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete Credential client_id #${item.client_id}?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		edit: {
			noInitValidation: true,
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "client_id",
								type: "text",
								label: "Client ID",
								translate: "CLIENT_ID",
								required: true,
							},
							{
								key: "secret",
								type: "text",
								label: "Secret",
								translate: "SECRET",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "scopes",
								type: "autocomplete",
								label: "Scopes",
								translate: "SCOPES",
								required: true,
								disabled: "",
								autocomplete: {
									provider: "smarthop",
									url: "api/consts/scopes",
									multiple: true,
									preloadDefault: true,
								},
							},
							{
								key: "broker",
								type: "autocomplete",
								label: "Broker",
								disabled: "",
								required: false,
								autocomplete: {
									provider: "smarthop",
									url: "api/consts/brokers",
									preloadDefault: true,
									showDescription: true,
									listenEvent: "profileRevision",
								},
							},
							{
								key: "owner",
								type: "text",
								label: "Owner",
								translate: "OWNER",
								required: false,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "active",
								type: "checkbox",
								label: "Active",
								translate: "ACTIVE",
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default credentialInformationForm;
