import { useEffect, useState } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// Services
import { confirmEmailAlias } from "app/services/publicServices";

// Utils
import { readURLParameters, convertURLParamsToModel } from "app/main/utils/urlUtils";

const getContentResponse = (response) => {
	const status = response.status;
	const message = response.message;

	let title;
	let titleColor;
	let messagesColor;
	if (["NOT_FOUND", "TOKEN_USED", "TOKEN_EXPIRED"].includes(status)) {
		messagesColor = "text-red";
	} else {
		title = "CONFIRMED!";
		titleColor = "text-green";
		messagesColor = "text-grey";
	}

	return (
		<div className="pt-28">
			{title && <Typography className={`pb-6 text-16 ${titleColor} font-medium`}>{title}</Typography>}
			<Typography className={`text-14 ${messagesColor} font-light`}>{message}</Typography>
		</div>
	);
};

const ConfirmEmailAliasView = () => {
	const params = convertURLParamsToModel(readURLParameters(), {}, { notJson: true });
	const token = params.token;

	const [response, setResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setLoading(true);
		if (!token) {
			setError("Oops, something went wrong...");
			return;
		}

		confirmEmailAlias(token).then(
			(response) => {
				setLoading(false);
				setResponse(response);
			},
			(error) => {
				console.error("[ConfirmEmailAliasView] Failed to load data", error);
				setLoading(false);
				setError(error.message ?? error.errors?.[0]?.message ?? "Oops, something went wrong...");
			}
		);
	}, [token]);

	return (
		<div className="flex flex-col h-full flex-auto items-center justify-center">
			<Card className={"max-w-510 flex flex-col w-full items-center justify-center sm:text-center py-24 "}>
				<CardContent className="w-full flex flex-col  items-center justify-center px-32">
					<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
					{response ? (
						getContentResponse(response)
					) : (
						<>
							{error && !loading && <Typography className="pt-28 text-18 text-red font-light">{error}</Typography>}
							{loading && (
								<Typography className="pt-28 text-14 font-light" color="textSecondary">
									Loading...
								</Typography>
							)}
						</>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

export default ConfirmEmailAliasView;
