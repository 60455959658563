export const CARRIER_INVOICE_INTERNAL_COPY_CONFIG = [
	{
		key: "internal_users_invoices_copy",
		type: "pattern",
		pattern: { showAllDefault: true, variant: "large" },
		description: "Configuration of internal users to send a copy of the invoice with selected documents",
		content: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "user_email",
								label: "Email",
								type: "text",
								required: true,
							},
							{
								type: "group",
								content: {
									items: [
										{
											key: "invoice",
											type: "checkbox",
											label: "Invoice",
											defaultValue: true,
										},
										{
											key: "rate_conf",
											type: "checkbox",
											label: "RC",
										},
										{
											key: "pod",
											type: "checkbox",
											label: "BOL",
										},
										{
											key: "others",
											type: "checkbox",
											label: "All Other",
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
];

export const carrierInvoicingInternalCopyFormConfig = {
	urlGET: "api/profile/carriers/:carrierId/invoicing-settings",
	urlPUT: "api/profile/carriers/:carrierId/invoicing-settings",
	urlPOST: "api/profile/carriers/:carrierId/invoicing-settings",
	idKey: "_id",
	triggerEvent: ["profileRevision", "invoiceOnboardingRevision"],
	listenEvent: ["profileRevision", "invoiceOnboardingRevision"],
	header: {
		create: { label: "Internal invoice copy" },
		edit: { label: "Internal invoice copy" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: {
			items: [
				{
					type: "message",
					builder: (item) =>
						`List of people who would receive copy of invoice files every time invoice is sent to a broker`,
				},
				...CARRIER_INVOICE_INTERNAL_COPY_CONFIG,
			],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "message",
					builder: (item) =>
						`Provide emails of people who would receive copy of invoice files and select types of the files you would like them to receive`,
				},
				...CARRIER_INVOICE_INTERNAL_COPY_CONFIG,
				{ type: "action", action: "SUBMIT", label: "Save" },
			],
		},
	},
};
