import { motion } from "framer-motion";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ActionButton from "./ActionButton";
import { parseRolesInfo, processTierRestrictions } from "../utils/rolesUtils";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";
import { convertURLParamsToModel, readURLParameters } from "../utils/urlUtils";
import { getUserTier, isRoleExternal } from "app/services/LoginService";

function PageTitle(props) {
	const user = useSelector(({ auth }) => auth.user);
	const [confirmationModel, setConfirmationModel] = useState(null);

	const userTier = getUserTier();
	const isExternalUser = isRoleExternal();

	const handleOnClick = (item) => {
		item.action?.confirmation
			? setConfirmationModel(item.action.confirmation)
			: item.confirmation
			? setConfirmationModel(item.confirmation)
			: item.action?.onClick
			? item.action?.onClick?.()
			: item?.onClick?.();
	};

	useMemo(() => {
		const showAction = convertURLParamsToModel(readURLParameters(), {})?.show_action;
		if (showAction) {
			const openActionByDefault = props.actions?.find((i) => i?.label === showAction);
			if (openActionByDefault?.action || openActionByDefault?.onClick) {
				handleOnClick(openActionByDefault);
			}
		}
	}, [props.actions]);

	let resourceInformation;
	if (props?.resource?.icon) {
		resourceInformation = getResourceByKey(props?.resource?.icon);
	}

	const buttons = useMemo(() => {
		const buttons = [];
		props.actions?.forEach((item, i) => {
			const { visible } = parseRolesInfo(item.auth, user, item.requiredGateKeepers);
			if (visible) {
				if (
					!(
						[
							"TIER_LIMITED",
							"TIER_STARTER",
							"TIER_BASIC",
							"TIER_BASIC_PLUS",
							"TIER_PROFESSIONAL",
							"TIER_GROWTH",
							"TIER_ENTERPRISE",
						].some((rest) => item.restrictions?.includes(rest)) &&
						isExternalUser &&
						!processTierRestrictions({ restrictions: item?.restrictions, tier: userTier })
					)
				) {
					if (item.component) {
						buttons.push(<div key={i}>{item.component}</div>);
					} else {
						buttons.push(
							<ActionButton
								key={i}
								action={item.action}
								label={item.label}
								dialog={item.dialog}
								classes={item.classes}
								onClick={() => handleOnClick(item)}
								disabled={item.disabled}
							/>
						);
					}
				}
			}
		});

		return buttons;
	}, [isExternalUser, props.actions, user, userTier]);

	return (
		<>
			<motion.div
				className="flex w-full"
				initial={{ opacity: 0, x: -40 }}
				animate={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
			>
				<div className="flex flex-row w-full px-4 items-center">
					{props.view}
					{props.text && (
						<div className="flex min-h-48 items-center">
							<Typography
								className={
									"flex mr-16 " +
									(props.variant === "section"
										? " text-grey-800 text-14 md:text-16 pt-8 "
										: " font-semibold tracking-tight text-22 ")
								}
								color="inherit"
							>
								{props.text}
								{resourceInformation && (
									<ResourceView
										type={"icon"}
										description={resourceInformation?.description}
										link={resourceInformation?.link}
										classes={{ icon: "ml-4 mt-3" }}
									/>
								)}
							</Typography>
						</div>
					)}
					<div className={"flex flex-1 items-center justify-end"}>
						{props.children}
						{buttons.length > 0 && <div className="flex min-h-48 items-center">{buttons}</div>}
					</div>
				</div>
			</motion.div>

			<SmarthopConfirmDialog
				open={!!confirmationModel?.message}
				title={confirmationModel?.message}
				checkboxLabel={confirmationModel?.checkboxLabel}
				checkboxValue={confirmationModel?.checkboxValue}
				setCheckboxValue={confirmationModel?.setCheckboxValue}
				onClose={() => {
					typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
					setConfirmationModel(null);
				}}
				onAccept={() => {
					confirmationModel?.onAccept();
					setConfirmationModel(null);
				}}
			/>
		</>
	);
}

export default PageTitle;
