import { payrollTimeZoneSection } from "app/main/profile/drivers/EnterprisePayrollSection";

export const carrierPayrollTimeZoneConfigurationForm = {
	urlGET: "api/profile/carriers/:carrierId/details/payroll-settings",
	urlPUT: "api/profile/carriers/:carrierId/details/payroll-settings",
	idKey: "_id",
	triggerEvent: ["profileRevision", "payrollOnboardingRevision"],
	listenEvent: ["profileRevision", "payrollOnboardingRevision"],
	header: {
		edit: { label: "Payroll Configuration" },
	},
	content: {
		view: { sameAs: "edit" },
		create: { sameAs: "edit" },
		edit: {
			form: { noInitValidation: true },
			items: [
				{
					type: "group",
					content: {
						items: [...payrollTimeZoneSection],
					},
				},
				{ type: "action", action: "SUBMIT", label: "Save" },
			],
		},
	},
};
