import { SmarthopConfirmDialog } from "@smarthop/form";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { setPreventDialogCloseCallback } from "app/store/tools/formToolsSlice";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { strategyConnect } from "../../providers/StrategyContextProvider";

const StrategyConfirmDialog = ({ result }) => {
	const dispatch = useDispatch();
	const stack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const isComposed = !result?.resData?.isMongo && !!result?.resData?.strategy?.composed;

	const [mountStackLength] = useState(stack.length);
	const [openDialog, setOpenDialog] = useState(false);

	useEffect(() => {
		if (stack.length === mountStackLength && isComposed) {
			dispatch(
				setPreventDialogCloseCallback(() => {
					setOpenDialog("This strategy is not saved, would you like to exit the visualizer?");
				})
			);
		} else {
			dispatch(setPreventDialogCloseCallback(null));
		}

		return () => {
			dispatch(setPreventDialogCloseCallback(null));
		};
		// eslint-disable-next-line
	}, [isComposed, stack]);

	const handleAccept = () => {
		setOpenDialog(false);
		dispatch(closeFormDialog());
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	return (
		<SmarthopConfirmDialog
			open={!!openDialog}
			message={openDialog}
			handleClose={handleDialogClose}
			handleAccept={handleAccept}
			closeMsg={"No"}
			acceptMsg={"Yes"}
		/>
	);
};

const mapStateToProps = (state) => ({
	result: state.result,
});

export default strategyConnect(mapStateToProps)(StrategyConfirmDialog);
