import { useState, useEffect, useMemo } from "react";

import { Typography, Icon } from "@material-ui/core";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import SmarthopNumberField from "@smarthop/form/fields/SmarthopNumberField";
import { createTooltip } from "app/main/utils/tableUtils";

function LoadSearchBidBookModalView({
	loadId,
	open,
	price,
	estBidPrice,
	bookNow,
	bidNow,
	bidOffline,
	onClose,
	onAccept,
	brokerBlacklisted,
}) {
	const title = bidNow ? "Bid Online" : "Book Online";
	const message = bidNow ? "Submit your offer for this load digitally:" : "Ready to book this load digitally?";

	const [rateValue, setRateValue] = useState();

	useEffect(() => {
		setRateValue(price);
	}, [price, loadId]);

	const onChangeRate = (newRate) => {
		setRateValue(newRate);
	};

	const onSendAction = () => {
		const actionType = bookNow && ((bidNow && rateValue === price) || !bidNow) ? "BOOK" : "BID";
		onAccept?.(actionType, actionType === "BID" ? { bidPrice: rateValue } : undefined);
	};

	const buttonAction = useMemo(() => {
		if (bookNow && !bidNow) return "Book Now";
		if (rateValue < price) return "Rate too low";
		return "Bid Now";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rateValue, loadId, price, bookNow, bidNow]);

	const notes = useMemo(() => {
		return `${price > 0 && price === rateValue && bidNow ? "You are bidding the posted rate. " : ""}${
			bidOffline ? "The broker will contact you directly regarding your bid" : ""
		}`;
	}, [price, rateValue, bidNow, bidOffline]);

	const rateField = useMemo(() => {
		return (
			<SmarthopNumberField
				disabled={bookNow && !bidNow}
				className={"max-w-160 border-1"}
				inputProps={{ className: "text-14 ml:text-2xl text-blue-700 text-right self-end", variant: "underline" }}
				field={{ noErrorMessage: true }}
				label={""}
				value={rateValue}
				onChange={onChangeRate}
				prefix={"$"}
			/>
		);
	}, [bookNow, bidNow, rateValue]);

	return (
		<SmarthopConfirmDialog
			open={!!open}
			title={title}
			message={message}
			handleClose={() => onClose()}
			handleAccept={onSendAction}
			mode={"NORMAL"}
			acceptMsg={buttonAction}
			disableAccept={rateValue < price}
		>
			<div className="flex flex-col">
				<div className={"flex flex-row items-center justify-end"}>
					<div className="flex flex-col">{rateField}</div>
				</div>
				{estBidPrice && bidNow && (
					<div className="flex flex-row self-end items-start">
						<Typography className="text-11 text-gray italic">{`Suggested bid: $${estBidPrice}`}</Typography>
						{createTooltip(
							<Icon className="flex text-10 ml:text-11 text-blue-500 ml-2 flex-start">info</Icon>,
							"This estimate is determined by SmartHop's algorithm"
						)}
					</div>
				)}
				<div className="flex flex-col pt-12 max-w-288">
					{brokerBlacklisted && (
						<Typography className="text-11 text-gray text-red text-left">
							Warning: This is a blacklisted broker
						</Typography>
					)}
					{notes && <Typography className="text-11 text-orange text-left">{`*Note: ${notes}`}</Typography>}
				</div>
			</div>
		</SmarthopConfirmDialog>
	);
}

export default LoadSearchBidBookModalView;
