import { Box, Icon } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { Controller, useWatch } from "react-hook-form";

const ControlCheckbox = ({ control, item }) => {
	const show = useWatch({ control, name: item.dependingOn, disabled: !item.dependingOn });

	return (
		show && (
			<Controller
				name={item.key}
				control={control}
				render={({ field }) => {
					const { icon, checkedIcon } = item?.config ?? {};
					return (
						<Box>
							<Checkbox
								checked={field.value}
								onChange={field.onChange}
								icon={icon && <Icon>{icon}</Icon>}
								checkedIcon={checkedIcon && <Icon>{checkedIcon}</Icon>}
							/>
							{item?.label}
						</Box>
					);
				}}
			/>
		)
	);
};

export default ControlCheckbox;
