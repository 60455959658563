import { FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import { SmarthopConfirmDialog } from "@smarthop/form";

// Panels
import TripDescription from "./TripDescription";
import CircularProgress from "@material-ui/core/CircularProgress";

const TripCreateTranscription = (props) => {
	const data = props.data;
	const dialogConfig = props.dialogConfig;
	const onChange = props.onChange;
	const onAccept = props.onAccept;
	const onClose = props.onClose;
	const onEdit = props.onEdit;
	const otherActions = !!dialogConfig?.showEdit
		? [
				{
					onClick: onEdit,
					label: "Edit transcription",
				},
		  ]
		: false;

	return (
		<SmarthopConfirmDialog
			open={!!dialogConfig?.flag}
			title={dialogConfig?.message}
			onClose={onClose}
			onAccept={onAccept}
			otherActions={otherActions}
			maxWidth={dialogConfig?.error ? "sm" : "md"}
			acceptMsg={dialogConfig?.optionsTex?.accept}
			closeMsg={dialogConfig?.optionsTex?.close}
		>
			{data && (
				<>
					{props?.loading && (
						<div className="absolute flex items-center justify-center" style={{ top: "50%", left: "50%" }}>
							<CircularProgress size={40} style={{ color: "#062246" }} />
						</div>
					)}
					<div className="w-full">
						<TripDescription load={data} showTruckInfo={false} />
						<Divider className="w-full mt-12" />
						<FormControlLabel
							label={"Transcription verified"}
							control={<Checkbox checked={data?.transcription_verified__flag} onChange={onChange} />}
						/>{" "}
					</div>
				</>
			)}
		</SmarthopConfirmDialog>
	);
};

export default TripCreateTranscription;
