const carrierGoalsContent = (options) => ({
	items: [
		{
			type: "group",
			content: {
				items: [
					{ key: "name", type: "text", label: "Name", required: true },
					{
						key: "equipment",
						type: "select",
						label: "Equipment",
						defaultValue: "VAN",
						disabled: !!options?.isEdit,
						required: true,
						options: [
							{
								value: "VAN",
								label: "Van",
								translate: "VAN",
							},
							{
								value: "REEFER",
								label: "Reefer",
								translate: "REEFER",
							},
							{
								value: "PO",
								label: "Power Only",
								translate: "PO",
							},
							{
								value: "FLATBED",
								label: "Flatbed",
								translate: "FLATBED",
							},
							{
								value: "SPECIAL",
								label: "Special",
								translate: "SPECIAL",
							},
						],
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: "revenue_month",
						type: "currency",
						label: "Revenue Per Month",
						required: true,
					},
					{
						key: "rpm_month",
						type: "currency",
						label: "RPM per Month",
						required: true,
					},
				],
			},
		},
		{ type: "action", action: "SUBMIT" },
	],
});

export default carrierGoalsContent;
