import { useState, useEffect } from "react";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { formDynamicGraph, formDynamicCard, formStaticCards, formTextConfigCards } from "./configs/configForm";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function WidgetContentConfig(props) {
	const [tabIndex, setTabIndex] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			setTabIndex(0);
		}, 1000);
	}, []);

	return (
		<div className="flex flex-col w-full whitespace-nowrap">
			<Tabs
				indicatorColor="primary"
				textColor="inherit"
				variant="scrollable"
				scrollButtons="off"
				className="w-full min-h-40 pb-6"
				classes={{ indicator: "flex justify-center bg-transparent w-full h-full" }}
				TabIndicatorProps={{
					children: <Divider className="w-full h-full rounded-full opacity-50" />,
					style: { transitionDuration: "200ms" },
				}}
				value={tabIndex}
				onChange={(_, value) => setTabIndex(value)}
			>
				<Tab key={0} className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-20 " disableRipple label={"Form"} />
				<Tab key={1} className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-20 " disableRipple label={"Config"} />
			</Tabs>
			<Divider className="w-full h-1 mt-10 mb-32 bg-grey-300" />
			{tabIndex === 1 ? (
				<SmarthopFormView
					key="config"
					content={formTextConfigCards}
					mode={"EDIT"}
					data={{ config: JSON.stringify(props.config, 0, 4) }}
					noInitValidation={true}
					onSubmit={(model) => {
						const config = JSON.parse(model?.config);
						props.onConfigChanged?.(config);
					}}
				/>
			) : (
				<SmarthopFormView
					key="form"
					content={
						props.type === "STATIC"
							? formStaticCards
							: props.type === "DYNAMIC_GRAPH"
							? formDynamicGraph
							: formDynamicCard
					}
					mode={"EDIT"}
					data={props.config}
					noInitValidation={true}
					onSubmit={(model) => {
						props.onConfigChanged?.(model);
					}}
				/>
			)}
		</div>
	);
}

export default WidgetContentConfig;
