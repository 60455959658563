import { reportFiltersForm } from "app/main/utils/fileUtils";

const adminFuelTransactionsReportForm = reportFiltersForm(
	"api/fuel/transactions/download/report",
	"Download SmartFuel Transactions",
	"SmartFuel Transactions.csv",
	"text/csv"
);

export default adminFuelTransactionsReportForm;
