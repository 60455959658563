// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { centsToDollars } from "app/main/utils/financeUtils";

import { capitalizeFirstLetter } from "app/main/utils/tableUtils";

const carrierTransactions = {
	urlGET: "api/finance/carriers/:carrierId/transactions",
	idKey: "id",
	listenEvent: "",
	segmentKey: "CARRIER_TRANSACTIONS",
	content: {
		click: {
			key: "transaction_details",
			label: "Transaction Details",
			dialogBuilder: (item, dataIds) => ({
				formId: "CARRIER_TRANSACTION_DETAILS_FORM",
				mode: "VIEW",
				dataIds: { ...dataIds, transactionId: item.id },
			}),
		},
		table: {
			variant: "skinny",
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "date",
				dir: "desc",
			},
		},
		filters: {
			default: {
				amount: "__NOT_SELECTED__",
			},
			search: true,
			countVisible: 2,
			items: [
				{
					key: "date",
					type: "dateRange",
					label: "Period",
				},
			],
		},
		items: [
			{
				key: "createdAt",
				type: "date",
				label: "Date",
				grid: { headerTitle: true },
			},
			{ key: "description", type: "text", label: "Description", collapsibleLength: 100 },
			{
				key: "latest_charge.status",
				type: "text",
				label: "Status",
				// TODO | Does it make sense to prioritize latest charge's status or better to always show PaymentIntent's?
				builder: (item) => capitalizeFirstLetter(item?.latest_charge?.status ?? item?.status),
			},
			{
				key: "amount",
				type: "component",
				label: "Amount",
				table: { align: "right" },
				builder: (item) => {
					const dollarAmount = centsToDollars(item?.amount);
					return formatCurrency(dollarAmount);
				},
			},
		],
	},
};

export default carrierTransactions;
