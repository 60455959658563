import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState, useImperativeHandle, forwardRef } from "react";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { getCurrentTruckPlan, updateTruckPlan, createTruckPlan, getTruckPlan } from "app/services/truckServices";
import { truckPlanForm } from "./config/truckPlanForm";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { useSnackbar } from "notistack";
import { fetchChats } from "app/store/messenger/chatSlice";
import { getRoleLabel, getUserId } from "app/services/LoginService";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { refreshDialog, replaceFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { Button, Typography } from "@material-ui/core";
import LoadStrategy from "../search/load/LoadStrategy";
import { SmarthopConfirmDialog } from "@smarthop/form";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";

const TruckPlanEdit = forwardRef(({ dataIds, onDone, onLoading, onProgress, setTitle, setSize, nativeMobile }, ref) => {
	useEffect(() => {
		setTitle?.("Edit Truck Plan");
		setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const user = useSelector(({ auth }) => auth.user);

	const hasPermission = useMemo(() => {
		const role = getRoleLabel();
		if (role !== "driver" && role !== "carrier_manager" && role !== "carrier_generic") {
			return true;
		}

		return !!user.gatekeepers?.permission_search_on;
	}, [user]);

	const ids = useMemo(() => {
		return {
			...(dataIds ?? {}),
			carrierId: dataIds.carrierId ?? dataIds.historyData?.carrier,
			truckId: dataIds.truckId ?? dataIds.historyData?.truck,
		};
	}, [dataIds]);

	const [data, setData] = useState(null);
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);

	const [resetPostingDialog, setResetPostingDialog] = useState(null);
	const [resetAdvancedDialog, setResetAdvancedDialog] = useState(null);
	const [resetTriggerAutoBookDialog, setResetTriggerAutoBookDialog] = useState(null);
	const historyMode = useMemo(() => ids.historyData, [ids]);

	const mergedData = useMemo(() => {
		const trailerData = {
			special_equipment: data?.trailer?.special_equipment,
			max_weight: data?.trailer?.max_weight,
			trailer: data?.trailer?.trailer,
			subtype: data?.trailer?.subtype,
			load_type: data?.trailer?.load_type,
		};
		const d = {
			...(data?.plan ?? {}),
			...(trailerData ?? {}),
			...formData,
			...{ truckId: ids.truckId, carrierId: ids.carrierId, carrier: ids.carrierId, truck: ids.truckId },
		};

		// default values
		if (!data?.carrier?.planTransferEnabled && !data?.carrier?.manual_queue) {
			d.bookForMe = false;
		}

		if (d.advancedFilters) {
			if (d.minMatchingPrefCount === undefined) {
				d.minMatchingPrefCount = 1;
			}
			if (d.pauseOutsideBusinessHours === undefined) {
				d.pauseOutsideBusinessHours = false;
			}
		} else {
			d.triggerAutobook = false;
		}

		if (!d.dh_origin_preferred) d.dh_origin_preferred = 250;
		if (d.current_destination || d.destinationStates?.length > 0) {
			d.miles_preferred = [0, 4000];
			d.miles_preferred__min = d.miles_preferred[0];
			d.miles_preferred__max = d.miles_preferred[1];
		} else {
			if (!d.miles_preferred) d.miles_preferred = [d.miles_preferred__min ?? 0, d.miles_preferred__max ?? 4000];
			d.miles_preferred__min = d.miles_preferred[0];
			d.miles_preferred__max = d.miles_preferred[1];
		}

		if (!d.auto_post_externally) {
			d.post_brokers = [];
			d.post_comments = null;
		}
		d.load_type = d?.load_type === "PARTIAL" || d?.load_type === "FULL" ? d?.load_type : undefined;
		d.disablePostTruck = ["Conestoga", "Box Truck"].includes(d?.subtype);

		return d;
	}, [data, formData, ids]);

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			if (ids.carrierId && ids.truckId && !nativeMobile) {
				btns.eye = {
					onClick: () => {
						dispatch(
							replaceFormDialog({
								viewId: "TRUCK_PLAN_VIEW",
								dataIds: ids,
							})
						);
					},
					icon: "remove_red_eye",
					title: "View Plan",
				};
			}
			if (!nativeMobile) dispatch(refreshDialog());
			return btns;
		},
		[ids, dispatch, nativeMobile]
	);

	const truckPlanFormConfig = useMemo(() => {
		if (formData?.advancedFilters) {
			ids.openSection = "availability-search";
		}
		return truckPlanForm({
			ids,
			data,
			formData,
			historyMode,
			mergedData,
		});
	}, [data, formData, ids, historyMode, mergedData]);

	const truckPlanUpdated = () => {
		onProgress?.(true);
		const dataToSend = { ...mergedData };
		if (dataToSend.advancedFilters) {
			const notificationPreferences = {
				minHopLoadsCount: dataToSend.minHopLoadsCount,
				minMatchingPrefCount: dataToSend.minMatchingPrefCount,
				pauseOutsideBusinessHours: dataToSend.pauseOutsideBusinessHours,
			};
			dataToSend.notificationPreferences = notificationPreferences;
		} else {
			dataToSend.notificationPreferences = {};
		}

		const call = data?.plan?._id
			? () => updateTruckPlan(mergedData.carrierId, mergedData.truckId, data.plan._id, dataToSend)
			: () => createTruckPlan(mergedData.carrierId, mergedData.truckId, dataToSend);

		setSaving(true);
		call().then(
			(response) => {
				dispatch(fetchChats({ userId: getUserId() }));
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "dashboardRevision" }));
				onDone?.(response);
				if (ids?.fromSearch) {
					dispatch(
						openLoadedFormDialog({
							viewId: "TRUCK_PLAN_VIEW",
							dataIds: { planId: data?.plan?._id, carrierId: mergedData.carrierId, truckId: mergedData.truckId },
						})
					);
				}
				setSaving(false);
				onProgress?.(false);
			},
			(error) => {
				setSaving(false);
				onProgress?.(false);
				showErrorSnackbar(snackbar, error, { duration: 6000 });
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			}
		);
	};

	const openLatestPlan = () => {
		dispatch(
			replaceFormDialog({
				viewId: "TRUCK_PLAN_VIEW",
				dataIds: ids,
			})
		);
	};

	const getPlanData = async () => {
		setLoading(true);
		onLoading?.(true);
		try {
			const currentTruckPlan = ids.planId
				? await getTruckPlan(ids.carrierId, ids.truckId, ids.planId)
				: await getCurrentTruckPlan(ids.carrierId, ids.truckId);

			if (currentTruckPlan?.plan?.notificationPreferences) {
				currentTruckPlan.plan = { ...currentTruckPlan.plan.notificationPreferences, ...currentTruckPlan.plan };
			}
			if (ids.overridesDataPlan) {
				currentTruckPlan.plan = { ...currentTruckPlan.plan, ...ids.overridesDataPlan };
			}

			if (ids.historyData) {
				setData({ ...currentTruckPlan, plan: ids.historyData });
			} else {
				setData(currentTruckPlan);
			}

			if (currentTruckPlan?.truck?.active === false) {
				showErrorSnackbar(
					snackbar,
					{ message: "Can't edit truck's plan because truck is inactive." },
					{ duration: 6000 }
				);
				setLoading(false);
				onLoading?.(false);
				return onDone?.();
			}

			setLoading(false);
			onLoading?.(false);
		} catch (error) {
			console.error(error);
		}
	};

	const updateFormData = (model, fieldName) => {
		const update = { [fieldName]: model[fieldName] };
		const autoPost = update?.auto_post_externally ?? formData?.auto_post_externally ?? data?.plan?.auto_post_externally;
		const postDate = update?.post_date ?? formData?.post_date ?? data?.plan?.post_date;
		if (autoPost && !postDate) {
			update.post_date = new Date().toISOString();
		}

		if (fieldName === "miles_preferred") {
			update.miles_preferred__min = update.miles_preferred[0];
			update.miles_preferred__max = update.miles_preferred[1];
		}

		if (fieldName === "post_brokers") {
			update.post_brokers__view = model.post_brokers__view?.options
				? model.post_brokers__view?.options
				: model.post_brokers__view;
			update.post_brokers = update?.post_brokers__view?.map?.((b) => b.value);
		}

		if (fieldName === "current_location") {
			update.current_location__view = model?.current_location__view;
			update.current_location = model?.current_location__view?.value;
		}

		if (fieldName === "current_destination") {
			update.current_destination__view = model?.current_destination__view ?? null;
			update.current_destination = model?.current_destination__view?.value ?? null;
		}

		if (fieldName === "destinationStates") {
			update.destinationStates__view = model.destinationStates__view?.options
				? model.destinationStates__view?.options
				: model.destinationStates__view;
			update.destinationStates = update?.destinationStates__view?.map?.((b) => b.value);
		}

		let updatedFormData = {};
		setFormData((data) => {
			updatedFormData = { ...data, ...update };
			return updatedFormData;
		});

		if (fieldName === "triggerAutobook" && updatedFormData["triggerAutobook"] === true) {
			setResetTriggerAutoBookDialog({
				flag: true,
				message:
					"You are authorizing Load Scout to execute a real booking for your truck, cancelling afterwards can get you bad reputation with brokers. Are you ok moving forward?",
			});
		}

		//, "destinationStates", "current_destination"
		const coreFields = ["current_location"];
		const coreFieldsChanged = coreFields.filter((f) => updatedFormData[f] && updatedFormData[f] !== data?.plan?.[f]);
		if (coreFieldsChanged.length > 0 && !!data?.plan?.auto_post_externally && resetPostingDialog === null) {
			setResetPostingDialog({
				flag: true,
				message: "Plan location changed, reset posting configuration?",
			});
		}
		if (coreFieldsChanged.length > 0 && !!data?.plan?.advancedFilters && resetAdvancedDialog === null) {
			setResetAdvancedDialog({
				flag: true,
				message: "Plan location changed, reset plan search criteria?",
			});
		}
	};

	const handleAccept = async (type) => {
		if (type === "posting") {
			setResetPostingDialog(true);
			setFormData((data) => ({
				...data,
				auto_post_externally: false,
				post_comments: "",
				post_brokers: [],
				post_brokers__view: [],
			}));
		} else if (type === "advanced") {
			setResetAdvancedDialog(true);
			setFormData((data) => ({
				...data,
				advancedFilters: false,
				max_weight: null,
				remainingDriveTime: null,
				remainingCycleDutyTime: null,
				dh_origin_preferred: null,
				miles_preferred: null,
				dh_origin: null,
				rate_total_preferred: null,
				rate_per_mile_preferred: null,
				triggerAutobook: false,
			}));
		} else if (type === "triggerAutoBook") {
			setResetTriggerAutoBookDialog(true);
			setFormData((data) => ({
				...data,
				triggerAutobook: false,
			}));
		}
	};

	useEffect(() => {
		if (ids.carrierId && ids.truckId) {
			getPlanData();
		}
		// eslint-disable-next-line
	}, [ids]);

	if (loading) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={true} />;
	}

	if (!hasPermission) {
		return (
			<SmarthopDialogViewLoadingStub
				nativeMobile={nativeMobile}
				loading={"Does not have Permission to Edit Truck Plan"}
			/>
		);
	}

	return (
		<>
			{ids.historyData && (
				<div className="w-full mb-0 md:mb-10 flex flex-row bg-orange-100 items-center rounded-lg p-12">
					<Typography className="flex-1 text-13 md:text-14">This is a historical plan and can not be edited</Typography>
					<Button variant="contained" color="primary" onClick={openLatestPlan}>
						Open Latest Plan
					</Button>
				</div>
			)}
			<LoadStrategy />
			{ids.truckId && data && (
				<div className={"mt-6" + (saving ? " pointer-events-none opacity-60 " : "")}>
					<SmarthopFormView
						key={`truck-plan-${JSON.stringify(ids)}`}
						mode={historyMode ? "COMPARE" : "EDIT"}
						content={truckPlanFormConfig}
						data={mergedData}
						noInitValidation={true}
						dataIds={ids}
						nativeMobile={nativeMobile}
						trackChangedFields={["ALL"]}
						onChangeCommitted={updateFormData}
						onSubmit={truckPlanUpdated}
					/>
				</div>
			)}
			<SmarthopConfirmDialog
				open={!!resetPostingDialog?.flag}
				title={resetPostingDialog?.message}
				onClose={() => setResetPostingDialog(false)}
				onAccept={() => handleAccept("posting")}
				acceptMsg="Reset"
				closeMsg="No, Keep"
			/>
			<SmarthopConfirmDialog
				open={!!resetAdvancedDialog?.flag}
				title={resetAdvancedDialog?.message}
				onClose={() => setResetAdvancedDialog(false)}
				onAccept={() => handleAccept("advanced")}
				acceptMsg="Reset"
				closeMsg="No, Keep"
			/>
			<SmarthopConfirmDialog
				open={!!resetTriggerAutoBookDialog?.flag}
				title={resetTriggerAutoBookDialog?.message}
				onClose={() => handleAccept("triggerAutoBook")}
				onAccept={() => setResetTriggerAutoBookDialog(false)}
				acceptMsg="Yes, Continue"
				closeMsg="No"
			/>
		</>
	);
});

export default TruckPlanEdit;
