import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";

function SnackbarHandler(props) {
	const [mobile, setMobile] = useState(window.innerWidth < 960);
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<SnackbarProvider
			maxSnack={mobile ? 1 : 3}
			anchorOrigin={{
				vertical: mobile ? "top" : "bottom",
				horizontal: mobile ? "center" : "right",
			}}
			classes={{
				containerRoot: "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-1400",
			}}
		>
			{props.children}
		</SnackbarProvider>
	);
}

export default SnackbarHandler;
