import { Tooltip, Typography } from "@material-ui/core";
import { formatSmartpayInvoiceStatus } from "app/main/profile/trips/invoicing/invoiceUtils";
import { formatDate } from "app/main/utils/dateUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const recipientsBuilder = (item) => {
	const recipients = item?.event_metadata?.email_metadata?.recipients;
	if (!recipients) return "-";
	const recipientCount = recipients.split(",").length;

	return (
		<Tooltip
			disableFocusListener
			title={<Typography variant="body2">{item.event_metadata.email_metadata.recipients.replace(/,/g, "")}</Typography>}
		>
			<Typography className="truncate max-w-150 underline cursor-pointer">
				{recipientCount > 1 ? `${recipientCount} recipients` : item.event_metadata.email_metadata.recipients}
			</Typography>
		</Tooltip>
	);
};

const tripInvoicingForm = {
	urlGET: "api/trip-invoicing/carriers/:carrierId/trips/:tripId",
	urlPUT: "api/trip-invoicing/carriers/:carrierId/trips/:tripId",
	listenEvent: "tripsRevision",
	triggerEvent: "tripsRevision",
	idKey: "_id",
	setSize: "max-w-m",
	header: {
		edit: { label: "Edit Invoice" },
	},
	content: {
		view: {
			items: [
				{
					type: "section",
					label: "Details",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "status",
								type: "text",
								label: "Status",
								builder: (item) => formatSmartpayInvoiceStatus(item.status),
							},
							{
								key: "ref",
								type: "text",
								label: "Invoice #",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "trip_data",
								type: "object",
								content: {
									items: [
										{
											key: "load_id",
											type: "text",
											label: "Load",
										},
									],
								},
							},
							{
								key: "broker_data",
								type: "object",
								content: {
									items: [
										{
											key: "broker_name",
											type: "text",
											label: "Broker Name",
										},
									],
								},
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "trip_data",
								type: "object",
								content: {
									items: [
										{
											key: "rate",
											type: "currency",
											label: "Rate",
										},
									],
								},
							},
							{
								key: "notes",
								type: "text",
								label: "Notes",
							},
						],
					},
				},
				{
					type: "section",
					label: "SmartPay",
					hidden: (item) => !item.smartpay_eligible,
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "smartpay_eligible",
								type: "checkbox",
								label: "SmartPay Eligible",
								hidden: (item) => !item.smartpay_eligible,
							},
							{
								key: "smartpay_optout",
								type: "checkbox",
								label: "Opt Out",
								hidden: (item) => !item.smartpay_eligible,
							},
						],
					},
				},
				{
					type: "section",
					label: "Additions and Deductions",
				},
				{
					key: "trip_data.additions_deductions", // FIXME: FAC-2052 Invalid key format
					type: "pattern",
					pattern: {},
					content: {
						items: [
							{
								key: "reason",
								type: "text",
								label: "Reason",
							},
							{
								key: "direction",
								type: "select",
								label: "Direction",
								options: [
									{
										value: "CREDIT",
										label: "Addition",
									},
									{
										value: "DEBIT",
										label: "Deduction",
									},
								],
							},
							{
								key: "amount",
								type: "currency",
								label: "Amount",
								min: 0,
							},
						],
					},
				},
				{
					type: "section",
					label: "Documents",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "invoice_file",
								type: "upload",
								label: "Invoice",
							},
							{
								key: "trip_data",
								type: "object",
								content: {
									items: [
										{
											key: "rate_con_file",
											type: "upload",
											label: "Rate Confirmation",
										},
									],
								},
							},
						],
					},
				},
				{
					key: "trip_data.documents", // FIXME: FAC-2052 Invalid key format
					type: "pattern",
					pattern: {},
					content: {
						items: [
							{
								key: "file_id",
								type: "upload",
								label: "Document",
							},
							{
								key: "description",
								type: "text",
								label: "Description",
							},
						],
					},
				},
				{
					type: "group",
					group: {
						variant: "vertical",
					},
					content: {
						items: [
							{
								type: "section",
								label: "Event",
							},
							{
								type: "group",
								group: {
									classes: {
										root: "px-6 pt-0 -mt-4 items-start",
									},
								},
								content: {
									items: [
										{
											key: "event_metadata.email_metadata.date",
											type: "text",
											label: "Invoice sent",
											builder: (item) => {
												const date = item?.event_metadata?.email_metadata?.date;
												return date ? formatDate(date) : "-";
											},
										},
										{
											key: "event_metadata.email_metadata.sender_name",
											type: "text",
											label: "Sender",
											builder: (item) => item?.event_metadata?.email_metadata?.sender_name ?? "-",
										},
									],
								},
							},
							{
								type: "group",
								group: {
									classes: {
										root: "px-6 pt-0 -mt-4 items-start",
									},
								},
								content: {
									items: [
										{
											key: "event_metadata.email_metadata.recipients",
											type: "text",
											label: "Recipients",
											builder: recipientsBuilder,
										},
									],
								},
							},
						],
					},
				},
			],
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "carrier_ref",
								type: "text",
								label: "Invoice #",
								required: true,
								description: "Assign a new Invoice #",
							},
							{
								key: "override_generated_date",
								type: "picker",
								label: "Invoice Date",
								defaultValue: "NOW",
								field: {
									classes: {},
									variant: undefined,
								},
								picker: {
									notClearable: false,
									type: "datetime",
								},
							},
						],
					},
				},
				{
					key: "notes",
					type: "text",
					label: "Notes",
					multiline: { rows: 5 },
					description: "Include additional notes to your invoice",
				},
				{ type: "action", action: "SUBMIT" },
			],
			onFinished: (_data, dispatch) => {
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
			},
		},
	},
};

export default tripInvoicingForm;
