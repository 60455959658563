import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import PinnedPanel from 'app/main/search/pinned/PinnedPanel';
import { memo } from 'react';

function RightSideLayout1(props) {
	return (
		<>
			<QuickPanel />
			<PinnedPanel />
		</>
	);
}

export default memo(RightSideLayout1);
