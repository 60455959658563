import { getView, formatText } from "app/main/utils/historyUtils";
import TripsCellView from "@smarthop/list/views/TripsCellView";
import TruckCellView from "@smarthop/list/views/TruckCellView";
import DriverCellView from "@smarthop/list/views/DriverCellView";
import PlanCellView from "@smarthop/list/views/PlanCellView";
import UserCellView from "@smarthop/list/views/UserCellView";
const moment = require("moment");

const ViewCellType = ({ item }) => {
	if (item?.type === "TRIP") {
		return <TripsCellView variant="skinny" view={item?.cellView} item={item} config={{ showTypeHistory: true }} />;
	} else if (item?.type === "TRUCK") {
		return <TruckCellView variant="skinny" view={item?.cellView} item={item} config={{ showTypeHistory: true }} />;
	} else if ((item?.type === "DRIVER" || item?.type === "PAYROLL") && item?.cellView) {
		return <DriverCellView variant="skinny" view={item?.cellView} item={item} config={{ showTypeHistory: true }} />;
	} else if (item?.type === "PLAN") {
		return <PlanCellView variant="skinny" view={item?.cellView} item={item?.data} config={{ showTypeHistory: true }} />;
	} else if (item?.type === "PAYROLL_RECURRENT_PAYMENT") {
		return "Payroll Rules";
	} else if (item?.type === "USER_CARRIER") {
		return <UserCellView variant="skinny" view={item?.cellView} item={item} config={{ showTypeHistory: true }} />;
	}
	if (!item?.cellView) return formatText(item?.type);
};

const historyList = (carrierId, showCarrier) => {
	const urlGET = carrierId ? `api/history/carrier/${carrierId}` : `api/history`;

	return {
		urlGET: urlGET,
		listenEvent: "profileRevision",
		idKey: "_id",
		segmentKey: "ALL_HISTORY",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "createdAt",
					dir: "desc",
				},
			},
			defaults: {
				// Initial value of fields
				initial: {
					filters: {
						createdAt: `${moment().subtract(1, "month").valueOf()}__DATE_RANGE__${moment().endOf("day").valueOf()}`,
					},
				},
			},
			filters: {
				items: [
					{
						key: "type",
						type: "select",
						label: "Entity Type",
						defaultValue: "__NOT_SELECTED__",
						options: [
							{
								value: "__NOT_SELECTED__",
								label: "Not Selected",
							},
							{
								value: "TRIP",
								label: "Trip",
							},
							{
								value: "TRUCK",
								label: "Truck",
							},
							{
								value: "DRIVER",
								label: "Driver",
							},
							{
								value: "PLAN",
								label: "Plan",
							},
							{
								value: "PAYROLL",
								label: "Payroll",
							},
							{
								value: "TRAILER",
								label: "Trailer",
							},
							{
								value: "USER_CARRIER",
								label: "Users",
							},
						],
					},
					...(showCarrier
						? [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									field: {
										noErrorMessage: true,
										classes: {},
									},
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										preloadDefault: true,
										listenEvent: "profileRevision",
									},
								},
						  ]
						: []),
					{
						key: "createdAt",
						type: "dateRange",
						label: "Period",
						shortcutPriority: "long",
					},
				],
			},
			items: [
				{
					key: "createdAt",
					type: "date",
					label: "Date",
				},
				{
					key: "carrier__view",
					type: "carrier__view",
					label: "Carrier",
					builder: (item) => item?.carrier__view,
					viewConfig: {
						showMcNumber: true,
						showCarrierAccountType: true,
						showTestCarrier: true,
					},
					hidden: !showCarrier,
				},
				{
					key: "editor__view",
					type: "user__view",
					label: "Editor",
					viewConfig: {
						showRole: true,
						showOverrideUser: true,
					},
				},
				{
					key: "type",
					type: "text",
					label: "Entity",
					builder: (item) => <ViewCellType item={item} />,
				},
				{
					key: "action",
					type: "text",
					builder: (item) => (item?.action ? formatText(item?.action) : "-"),
					label: "Action",
				},
				{
					key: "description",
					type: "text",
					label: "Description",
				},
				{
					key: "menu",
					type: "menu",
					label: "",
					items: [
						{
							key: "compare_details",
							label: "View Changes",
							dialogBuilder: (item) => {
								let { view, isViewCustom } = getView(item?.type, item?.metadata?.type);
								let type = item?.type === "DRIVER" && item?.metadata?.type ? "DRIVER_PAYROLL" : item?.type;
								let entityId = item?.trip ?? item?.truck ?? item?.plan ?? item?.driver ?? item?.entity;
								return {
									mode: "view",
									viewId: "REDIRECT_HISTORY_VIEW",
									dataIds: {
										entityId: entityId,
										type: type,
										id: item?._id,
										view,
										noHistoryRedirect: {
											viewId: isViewCustom ? view : null,
											formId: view,
											// FIXME: historyView is a convoluted way to indicate that
											// you need to show fist item in the history, we need to refactor it
											dataIds: { historyData: item?.data, historyView: true },
											mode: "VIEW",
										},
									},
								};
							},
						},
					],
				},
			],
		},
	};
};

export default historyList;
