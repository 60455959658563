import Typography from "@material-ui/core/Typography";

const INVOICES_ERRORS = {
	rateConf: "- Rate Confirmation must be provided",
	pod: "- Bill of Lading must be provided",
	ach: "- A SmartPay Payout Account must be added",
	achReAuth: "- Your SmartPay Payout Account must be re-authenticated",
	noSmartPayPlan: "- A SmartPay Plan is required",
};

export const INVOICE_AMOUNT_LIMIT = 8000;

/**
 * Formats the status label removing the SP prefix and underscores
 * @param {string} status current invoice status
 */
export const formatSmartpayInvoiceStatus = (status) => status?.replace("SP_", "").replace(/_/g, " ");

/**
 * Calculates SmartPay Transfer Fee by adding up all ledger entries in payout
 * and  applying base fee, fee percentage and max fee
 * @param {*} param0
 * @returns
 */
export const calculateTransferFee = ({
	payoutEntries,
	incomingAmountCents,
	baseTransferFeeCents,
	transferFeePercentage,
	maxTransferFeeCents,
}) => {
	const transferTotalCents = payoutEntries.reduce((total, entry) => total + +entry.amount_cents, +incomingAmountCents);
	// Get transfer fees base on selected payment type
	const transferFeeCents =
		(baseTransferFeeCents > 0 ? baseTransferFeeCents : 0) +
		(transferFeePercentage > 0 ? (transferTotalCents * transferFeePercentage) / 100 : 0);
	const cappedTransferFeeCents =
		maxTransferFeeCents > 0 ? Math.min(transferFeeCents, maxTransferFeeCents) : transferFeeCents;
	return { transferFeeCents, cappedTransferFeeCents };
};

/**
 * Calculates Smartpay Fee and Total amount to fund
 * @param {*} invoiceAmountCents
 * @param {*} smartPayFeePercentage
 * @returns { smartpayFeeCents, netFundCents }
 */
export const calculateSmartpayValues = ({ tripRateCents, invoiceAmountCents, smartPayFeePercentage }) => {
	const factorableAmountCents = Math.min(tripRateCents, invoiceAmountCents);
	const smartpayFeeCents = (factorableAmountCents * smartPayFeePercentage) / 100;
	// Net amount to fund is the eligible amount minus Smartpay Fee
	const netFundCents = factorableAmountCents - smartpayFeeCents;
	return { factorableAmountCents, netFundCents, smartpayFeeCents };
};

/**
 * @description
 * @param {Object} tripInvoice
 * @returns
 */
export const smartpayInvoiceErrorsFormatter = (tripInvoice) => {
	const smartpayInvoiceErrors = {
		...(!tripInvoice?.trip_data?.rate_con_file__flag ? { rateConf: INVOICES_ERRORS["rateConf"] } : {}),
		...(!tripInvoice?.trip_data?.pod_file__flag ? { pod: INVOICES_ERRORS["pod"] } : {}),
		...(!tripInvoice?.carrier_data?.smartpay?.hasSmartpayAccount ? { ach: INVOICES_ERRORS["ach"] } : {}),
		...(tripInvoice?.carrier_data?.smartpay?.reAuthRequired ? { achReAuth: INVOICES_ERRORS["achReAuth"] } : {}),
		...(tripInvoice?.smartpay_eligible && !tripInvoice.smartpay_plan ? { ach: INVOICES_ERRORS["noSmartPayPlan"] } : {}),
	};
	return smartpayInvoiceErrors;
};

/**
 * Generates the broker badge for Trip calculator, trip form and quick trip form
 * @param {Object} available
 * @param {Object} amount
 * @returns
 */
export const smartpayBadgeGenerator = ({ available, amount }) => {
	const eligible = (amount ?? 0) <= INVOICE_AMOUNT_LIMIT;
	return {
		show: available,
		label: (
			<div className={"flex flex-row ml-4 -mr-2 " + (eligible ? "" : "line-through")}>
				<Typography className={" text-12 font-bold " + (eligible ? "text-primary" : "text-grey")}>Smart</Typography>
				<Typography className={"text-12 font-bold " + (eligible ? "text-secondary " : "text-grey")}>Pay</Typography>
			</div>
		),
		description: eligible
			? "This broker is eligible for SmartPay"
			: `The rate exceeds the limit of $${INVOICE_AMOUNT_LIMIT}`,
		variant: "text",
	};
};
