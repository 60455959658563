import { useForm, useWatch } from "react-hook-form";
import ControlButton from "./fields/ControlButton";
import ControlCheckbox from "./fields/ControlCheckbox";
import SmarthopMenuContainer from "@smarthop/form/fields/SmarthopMenuContainer";

import { Box, ButtonGroup, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback, memo, createContext, useEffect, useState } from "react";

const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#e5e7eb",
		padding: 10,
		gap: 16.18,
	},
});

export const ControlPanelContext = createContext();

const WithControlPanel = (options = {}) => {
	const { content, defaultValues, stateCallback } = options;

	const builders = {
		button: (itemKey, item) => {
			return <ControlButton key={itemKey} control={control} item={item} />;
		},
		checkbox: (itemKey, item) => {
			return <ControlCheckbox key={itemKey} control={control} item={item} />;
		},
		group: (itemKey, item) => {
			return (
				<Box key={itemKey} className={item?.classes}>
					{item.items?.length && itemsIterator(item.items)}
				</Box>
			);
		},
		buttonGroup: (itemKey, item) => {
			return (
				<ButtonGroup key={itemKey} className={item?.classes}>
					{item.items?.length && itemsIterator(item.items)}
				</ButtonGroup>
			);
		},
		menu: (itemKey, item) => {
			return (
				<SmarthopMenuContainer key={itemKey} icon={item?.icon} button={item?.button} className={item?.classes}>
					{item.items?.length && itemsIterator(item.items)}
				</SmarthopMenuContainer>
			);
		},
		unsupported: (itemKey, item) => {
			return (
				<Typography key={itemKey} item={item}>
					{`${item.type} is not a supported type`}
				</Typography>
			);
		},
	};

	const itemsIterator = useCallback((items) => {
		const builtItems = [];
		items.forEach((item, index) => {
			let builder = builders[item.type];
			if (!builder) builder = builders.unsupported;
			const itemKey = item.key + "_" + index;
			const isGroup = item?.items;
			const hasItems = item?.items?.length;
			if (!isGroup || hasItems) builtItems.push(builder(itemKey, item));
		});
		return builtItems;
		//eslint-disable-next-line
	}, []);

	const { control, setValue } = useForm({ defaultValues });

	const SmarthopControlPanel = ({ callbacks, disabled, loading }) => {
		const [mobile, setMobile] = useState(window.innerWidth <= 1100);
		const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 10) * 10);
		const classes = useStyles();

		useEffect(() => {
			function handleResize() {
				let isNewWidthMobile = window.innerWidth < 1100;
				if (isNewWidthMobile !== mobile) setMobile(isNewWidthMobile);

				let innerHeight = window.innerHeight;
				innerHeight = parseInt(innerHeight / 10) * 10;
				if (innerHeight !== screenHeight) setScreenHeight(innerHeight);
			}
			window.addEventListener("resize", handleResize);
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}, [mobile, screenHeight]);

		return (
			<ControlPanelContext.Provider value={{ mobile, callbacks, disabled, loading }}>
				<Toolbar className={classes.toolbar + (content.classes ?? "")}>
					{content && itemsIterator(content.items)}
				</Toolbar>
			</ControlPanelContext.Provider>
		);
	};

	if (stateCallback) {
		stateCallback(getStateHook(control), setValue);
	}

	return [memo(SmarthopControlPanel), getStateHook(control), setValue];
};

const getStateHook = (control) => (name) => useWatch({ control, name });

export default WithControlPanel;
