import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";

// Utils
import { createDestMciView } from "app/main/utils/brokerUtils";
import { formatDate } from "app/main/utils/dateUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

function LoadDescription(props) {
	const load = props.load;
	const variant = props.variant;

	// Check for Notification if a driver, manager or investor can see the rate
	const user = useSelector(({ auth }) => auth.user);
	const hideRate = roleRestrictions.permission_rates_on.includes(user.role)
		? !hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;

	const colorPrimary = variant === "dark" ? "text-white font-semibold" : "text-gray-600";
	const colorSecondary = variant === "dark" ? "text-white" : "text-gray-900";

	const getDestValue = () =>
		load?.rank_mci ? (
			createDestMciView(load, {
				destValue: colorSecondary,
				mciTitle: colorPrimary,
				tooltipContent: variant !== "dark" ? "text-blue" : "",
			})
		) : (
			<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{load?.destination}</Typography>
		);

	const formatLoadMd = [
		...(variant === "notification"
			? [
					[
						{ value: load?.driverName ?? "-", label: "Driver(s)" },
						{ value: load?.truckid ? load?.truckid : "-", label: "Truck ID" },
					],
			  ]
			: []),
		[
			{ value: load?.origin, label: "Origin" },
			{ value: load?.pickupAfter ? formatDate(load?.pickupAfter) : "-", label: "Pickup" },
			{
				value: load?.totalMiles && !isNaN(parseFloat(load?.totalMiles)) ? parseInt(load?.totalMiles) + " mi" : "-",
				label: "Total Miles",
			},
		],
		[
			{ label: "Destination", component: getDestValue() },
			{ value: load?.deliverAfter ? formatDate(load?.deliverAfter) : "-", label: "Delivery" },
			{
				value: !hideRate
					? load?.price && !isNaN(parseFloat(load?.price))
						? `${formatCurrency(load?.price)}`
						: "-"
					: "-",
				label: "Rate",
			},
		],
	];

	const formatLoadSm = [
		...(variant === "notification"
			? [
					[
						{ value: load?.driverName ?? "-", label: "Driver" },
						{ value: load?.truckid ? load?.truckid : "-", label: "Truck ID" },
					],
			  ]
			: []),
		[
			{ value: load?.origin, label: "Origin" },
			{ value: load?.pickupAfter ? formatDate(load?.pickupAfter) : "-", label: "Pickup" },
		],
		[
			{ label: "Destination", component: getDestValue() },
			{ value: load?.deliverAfter ? formatDate(load?.deliverAfter) : "-", label: "Delivery" },
		],
		[
			{
				value: load?.totalMiles && !isNaN(parseFloat(load?.totalMiles)) ? parseInt(load?.totalMiles) : "-",
				label: "Total Miles",
			},
			{
				value: !hideRate
					? load?.price && !isNaN(parseFloat(load?.price))
						? `${formatCurrency(parseFloat(load?.price).toFixed(0))}`
						: "-"
					: "-",
				label: "Rate",
			},
		],
	];

	return (
		<div className={props?.classes?.root}>
			{formatLoadMd.map((data, index) => (
				<div key={index} className={"w-full hidden md:flex flex-row py-4 md:py-6 "}>
					<div className="w-2/5">
						<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[0]?.label}</Typography>
						{data[0]?.component ? (
							data[0].component
						) : (
							<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[0]?.value}</Typography>
						)}
					</div>
					<div className="w-2/5">
						<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[1]?.label}</Typography>
						<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[1]?.value}</Typography>
					</div>
					<div className="w-1/5">
						{data[2] && (
							<>
								<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[2]?.label}</Typography>
								<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[2]?.value}</Typography>
							</>
						)}
					</div>
				</div>
			))}
			{formatLoadSm.map((data, index) => (
				<div key={index} className={"w-full flex md:hidden flex-row py-4 md:py-6 "}>
					<div className="w-1/2">
						<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[0]?.label}</Typography>
						{data[0]?.component ? (
							data[0].component
						) : (
							<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[0]?.value}</Typography>
						)}
					</div>
					<div className="w-1/2">
						<Typography className={"text-12 md:text-13 pr-10 " + colorPrimary}>{data[1]?.label}</Typography>
						<Typography className={"text-13 md:text-14 pr-10 " + colorSecondary}>{data[1]?.value}</Typography>
					</div>
				</div>
			))}
		</div>
	);
}

export default LoadDescription;
