const fuelTransactionPayeeForm = {
	urlGET: "api/fuel/transactions/:transactionId/payees/:payeeId/payroll-settings",
	urlPUT: "api/fuel/transactions/:transactionId/payees/:payeeId/payroll-settings",
	idKey: "_id",
	segmentKey: "SMARTFUEL_TRANSACTIONS",
	triggerEvent: ["fuelRevision", "profileRevision"],
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit Payroll Settings" },
		view: { label: "View Payroll Settings" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: { sameAs: "edit" },
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "fuelChargePrice",
								type: "select",
								label: "Charge Price",
								options: [
									{
										value: "DISCOUNTED",
										label: "Discounted Amount",
									},
									{
										value: "RETAILS",
										label: "Retail Amount",
									},
								],
							},
							{
								key: "percentage",
								type: "percentage",
								label: "Percentage",
								translate: "PERCENTAGE",
								min: 0,
								max: 100,
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT", button: { alwaysEnabledIfValid: true } },
			],
		},
	},
};

export default fuelTransactionPayeeForm;
