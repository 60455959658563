import { createTooltip } from "app/main/utils/tableUtils";
import Typography from "@material-ui/core/Typography";

const createContentView = (item) => {
	return createTooltip(
		"View Content",
		<Typography color="inherit" variant="body2" className="p-6 font-light">
			{JSON.stringify(item)}
		</Typography>
	);
};

const booking = {
	urlGET: "api/transaction/users/:userId/records/ALL",
	idKey: "_id",
	listenEvent: "transactionsRevision",
	segmentKey: "TRANSACTIONS_STATE_ALL",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: [
				"createdAt",
				"transaction__view.metadata.slower_step.stepTime",
				"transaction__view.metadata.total_time",
			],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "createdAt",
				dir: "desc",
			},
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		filters: {
			search: true,
			items: [
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "Finished", value: "FINISHED" },
						{ label: "In Progress", value: "IN_PROGRESS" },
						{ label: "Postponed", value: "POSTPONED" },
						{ label: "Failed", value: "FAILED" },
					],
				},
				{
					key: "createdAt",
					type: "select",
					label: "Visible Window",
					defaultValue: "__DATE_DAYS_GT__30",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__DATE_DAYS_GT__7",
							label: "Last 7 Days",
						},
						{
							value: "__DATE_DAYS_GT__30",
							label: "Last 30 Days",
						},
						{
							value: "__DATE_DAYS_GT__90",
							label: "Last 90 Days",
						},
						{
							value: "__DATE_DAYS_GT__365",
							label: "Last 1 Year",
						},
					],
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "user", type: "id", label: "User ID", group: "internal" },
			{ key: "userEmail", type: "text", label: "User Email" },
			{ key: "createdAt", type: "date", label: "Created" },
			{ key: "type", type: "text", label: "Type" },
			{
				key: "transaction__view.metadata.slower_step.stepTime",
				label: "Slower Status",
				type: "component",
				group: "internal",
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.transaction__view?.metadata?.slower_step?.state
									? item.transaction__view?.metadata?.slower_step?.state.toUpperCase()
									: "-"}
							</Typography>
							<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
								{item.transaction__view?.metadata?.slower_step?.stepTime
									? (item.transaction__view?.metadata?.slower_step?.stepTime / 1000).toFixed(0) + "s"
									: "-"}
							</Typography>
						</div>
					);
				},
			},
			{
				key: "transaction__view.metadata.total_time",
				label: "Total Time",
				type: "component",
				group: "internal",
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.transaction__view?.metadata?.total_time
									? (item.transaction__view?.metadata?.total_time / 1000).toFixed(0) + "s"
									: "-"}
							</Typography>
						</div>
					);
				},
			},
			{
				key: "status",
				label: "Status",
				type: "text",
				builder: (item) => {
					return item.statusProcess || item.status;
				},
			},
			{ key: "content", label: "Content", type: "component", builder: (item) => createContentView(item) },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "execute_logs",
						label: "Logs",
						dialogBuilder: (item, dataIds) => ({
							mode: "view",
							viewId: "TRANSACTIONS_EXECUTE_LOGS",
							dataIds: { id: item._id, userId: dataIds.userId },
						}),
					},
				],
			},
		],
	},
};

export default booking;
