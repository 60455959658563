const config = {
	urlGET: "api/profile/carriers/archive",
	idKey: "_id",
	segmentKey: "ADMIN_ARCHIVE_CARRIERS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["createdAt"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			items: [
				{
					key: "carriers_type",
					type: "select",
					label: "Carrier Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "REAL_CARRIERS",
							label: "Real Carriers",
						},
						{
							value: "TEST_CARRIERS",
							label: "Test Carriers",
						},
					],
				},
			],
		},
		items: [
			{
				label: "By",
				key: "user__view",
				type: "user__view",
			},
			{
				key: "createdAt",
				type: "date",
				label: "At",
			},
			{
				key: "name",
				label: "Carrier",
				type: "text",
			},
			{
				key: "email",
				label: "Email",
				type: "text",
			},
			{
				key: "mcnumber",
				label: "Mc#",
				type: "text",
			},
			{
				key: "registrationDate",
				type: "date",
				label: "Registration",
			},
			{
				key: "linkBucket",
				type: "text",
				label: "Link",
				maxLengthStart: 60,
			},
		],
	},
};

export default config;
