import { ADD_TO_CART, EMPTY_CART, REMOVE_FROM_CART } from "../constants";

export const addToCart = (selectedMoves, pathId) => ({
	type: ADD_TO_CART,
	payload: { selectedMoves, pathId },
});

export const removeFromCart = (pathId) => ({
	type: REMOVE_FROM_CART,
	payload: pathId,
});

export const emptyCart = () => ({
	type: EMPTY_CART,
});
