import { getAllStates } from "app/main/utils/stateUtils";
import parser from "parse-address";
import { validatorsKeys } from "@smarthop/form/validation/validators";
import moment from "moment";

const buildBetterStreet = (address) => {
	const fields = ["number", "prefix", "street", "type"];
	return fields
		.filter((field) => !!address?.[field])
		.map((field) => address[field].trim())
		.join(" ");
};

const buildAddress = (carrier) => {
	const physicalAddress = carrier?.carrier_physical_address;
	const processedAddressForParsing = physicalAddress?.replace(/,/g, " ").replace(/\s+/g, " ").trim();
	if (!processedAddressForParsing) {
		return null;
	}
	const parsedAddress = parser.parseLocation(processedAddressForParsing);
	return { ...parsedAddress, street: buildBetterStreet(parsedAddress) };
};

const createForm = ({ carrierInfo }) => {
	let actions = [
		{
			type: "action",
			action: "SUBMIT",
			label: "Purchase Debit Card",
		},
		{
			type: "action",
			action: "CANCEL",
			label: "Cancel",
		},
	];
	const carrierAddress = buildAddress(carrierInfo);
	return {
		bottom: {
			classes: { root: "pt-4 md:pt-12" },
			items: actions,
		},
		items: [
			{
				type: "group",
				content: {
					items: [
						{
							key: "first-name",
							type: "text",
							label: "First Name",
							maxCharacter: 40,
							validators: [validatorsKeys.ALPHANUMERIC],
							required: true,
						},
						{
							key: "last-name",
							type: "text",
							label: "Last Name",
							maxCharacter: 40,
							validators: [validatorsKeys.ALPHANUMERIC],
							required: true,
						},
					],
				},
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "phone",
							type: "phone",
							label: "Phone Number",
							maxCharacter: 40,
							validators: [validatorsKeys.US_PHONE],
							required: true,
						},
						{
							key: "date-of-birth",
							picker: {
								notClearable: true,
								type: "date",
								classes: {},
								format: "yyyy/MM/dd",
								maxDate: moment().subtract(18, "years"),
							},
							type: "picker",
							label: "Date of Birth",
							translate: "",
							required: true,
						},
					],
				},
			},
			{
				key: "email",
				type: "email",
				label: "Email Address",
				required: true,
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "address-street",
							type: "text",
							label: "Street",
							required: true,
							maxCharacter: 40,
							validators: [validatorsKeys.ALPHANUMERIC],
							defaultValue: carrierAddress?.street ?? "",
						},
						{
							key: "address-city",
							type: "text",
							label: "City",
							required: true,
							maxCharacter: 40,
							validators: [validatorsKeys.ALPHANUMERIC],
							defaultValue: carrierAddress?.city ?? "",
						},
					],
				},
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "address-postal-code",
							label: "Postal Code",
							required: true,
							type: "number",
							min: 0,
							field: { maxLength: 5, decimals: 0 },
							maxCharacter: 40,
							defaultValue: carrierAddress?.zip ?? "",
						},
						{
							key: "address-state",
							type: "select",
							label: "State",
							required: true,
							options: getAllStates().map((element) => ({
								value: element.state,
								label: element.label,
								translate: element.state,
							})),
							defaultValue: carrierAddress?.state ?? "",
						},
					],
				},
			},
			//Shipping Address
			{
				type: "section",
				label: "Shipping Address",
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "shipping-address-street",
							type: "text",
							label: "Street",
							required: true,
							maxCharacter: 40,
							validators: [validatorsKeys.ALPHANUMERIC],
							defaultValue: carrierAddress?.street ?? "",
						},
						{
							key: "shipping-address-city",
							type: "text",
							label: "City",
							required: true,
							maxCharacter: 40,
							validators: [validatorsKeys.ALPHANUMERIC],
							defaultValue: carrierAddress?.city ?? "",
						},
					],
				},
			},
			{
				type: "group",
				content: {
					items: [
						{
							key: "shipping-address-postal-code",
							label: "Postal Code",
							required: true,
							type: "number",
							min: 0,
							maxCharacter: 40,
							field: { maxLength: 5, decimals: 0 },
							defaultValue: carrierAddress?.zip ?? "",
						},
						{
							key: "shipping-address-state",
							type: "select",
							label: "State",
							required: true,
							options: getAllStates().map((element) => ({
								value: element.state,
								label: element.label,
								translate: element.state,
							})),
							defaultValue: carrierAddress?.state ?? "",
						},
					],
				},
			},
			{
				key: "process-time-message",
				type: "message",
				builder: () => (
					<div className="flex flex-row justify-center space-x-10">
						<span className="font-bold">Shipping may take between 3-5 business days</span>
					</div>
				),
			},
		],
	};
};

export default createForm;
