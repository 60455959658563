import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import { selectByType, fetchMap } from "app/store/marketConditions/mapSlice";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import SmarthopAutocompleteField from "@smarthop/form/fields/SmarthopAutocompleteField";
//Utils
import { sortArray, getLaneEquipment } from "app/main/utils/marketUtils";
import { createMPIViewManual } from "app/main/utils/brokerUtils";
import { formatCurrency } from "app/main/utils/tableUtils";

const CustomTableCell = (props) => {
	return <TableCell style={{ verticalAlign: "top" }} {...props} />;
};

const MAX_LANES = 7;

function LanesMarketCondition(props) {
	const { verifiedLoading, showLoading } = props;
	const [equipment, setEquipment] = useState(getLaneEquipment());
	const [locationObject, setLocationObject] = useState(null);
	const [location, setLocation] = useState(null);
	const dataMarkets = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, equipment, "ocluster", "toplanes", location)
	);
	const dataMarketsEquipments = useSelector(({ marketConditions }) =>
		selectByType(marketConditions, equipment, "ocluster", "toplanes")
	);
	const dispatch = useDispatch();
	let allLanes = [];
	let infoLane = {};
	if (dataMarkets?.heatMap?.length) {
		for (let lane of dataMarkets?.heatMap) {
			infoLane[lane?.main_city] = lane;
			if (Array.isArray(lane.smooth_top_lanes)) {
				const lanes = lane?.smooth_top_lanes?.map((item) => {
					const topLane = {
						...item,
						main_city: lane?.main_city,
						mvi: lane?.vol_perc_smooth,
					};
					return topLane;
				});

				allLanes = [...allLanes, ...lanes];
			}
		}
	}
	// recalulate lanes if has location
	if (location) {
		if (dataMarketsEquipments?.heatMap?.length) {
			for (let lane of dataMarketsEquipments?.heatMap) {
				infoLane[lane?.main_city] = lane;
			}
		}
	}

	const orderLanes = sortArray(allLanes, "balanced_profit", "desc");

	const noShowLoadingWidget = () => {
		if (verifiedLoading) {
			showLoading(false);
		}
	};

	let top = [];
	// Filter out non profitable lanes and dest expectd profit above zero
	top = orderLanes.filter((lane) => lane?.profit > 0 && lane?.dest_best_exp_profit > 0 && lane?.mvi > 0.66);

	// if top is empty use all lanes with profit above zero
	if (!top.length) top = orderLanes.filter((lane) => lane?.profit > 0 && lane?.mvi > 0.66);

	if (!top.length) top = orderLanes.filter((lane) => lane?.profit > 0);
	const topLanes = top.slice(0, MAX_LANES);

	useEffect(() => {
		const params = {
			equipment: equipment,
			field: "ocluster",
			toplanes: "toplanes",
		};
		if (location) {
			params.location = location;
		}
		if (!dataMarkets?.status || dataMarkets?.status === "rejected") {
			dispatch(fetchMap(params));
		}

		// eslint-disable-next-line
	}, [location, equipment, dataMarkets]);

	const createMPIPlaceView = (city, mpi, mci) => {
		return (
			<div>
				{city}
				{createMPIViewManual(mpi, mci, true, true)}
			</div>
		);
	};

	return (
		<>
			<div className="flex pt-2 items-center px-4">
				<div className="flex flex-col flex-1">
					<Typography className="font-medium text-16 text-black -mt-20">Best Lanes</Typography>
				</div>
				<div className="w-2/4 mr-0">
					<SmarthopAutocompleteField
						name="location"
						label="Origin"
						optionsValue={[locationObject ?? null]}
						inputValue={location ?? ""}
						required={false}
						autocomplete={{
							provider: "trimblemaps",
							params: {
								componentRestrictions: { country: "us", includeOnly: "city" },
								types: ["(cities)"],
							},
							disableManualSelect: true,
							classes: { input: "h-0 mb-3", root: "py-0 px-0" },
						}}
						onSelected={(optionsValues) => {
							setLocationObject(optionsValues?.[0]);
							setLocation(optionsValues?.[0]?.value);
							noShowLoadingWidget();
						}}
					/>
				</div>
				<div className="-mt-16">
					<Select
						classes={{ select: "py-10", root: "w-64" }}
						value={equipment}
						variant="outlined"
						onChange={(e) => {
							setEquipment(e.target.value);
							localStorage.setItem("lane-equipment", e.target.value);
							noShowLoadingWidget();
						}}
					>
						<MenuItem value="van">
							<Typography display="inline">VAN</Typography>
						</MenuItem>
						<MenuItem value="reefer">
							<Typography display="inline">REEFER</Typography>
						</MenuItem>
						<MenuItem value="flatbed">
							<Typography display="inline">FLATBED</Typography>
						</MenuItem>
					</Select>
				</div>
			</div>
			{(!dataMarkets || dataMarkets?.status === "rejected") && (
				<div className="flex justify-center items-center top-1/3 py-20 px-24 relative">
					<Typography className="font-normal text-14 text-center" color="error">
						{`Currently we do not have enough information for ${equipment?.toUpperCase()} equipment.`}
					</Typography>
				</div>
			)}
			{dataMarkets && dataMarkets?.status !== "rejected" && (
				<TableContainer style={{ marginTop: "-8px" }}>
					<Table sx={{}} style={{ marginTop: "-2px" }}>
						<TableHead>
							<TableRow>
								<CustomTableCell>
									<Typography>Origin</Typography>
								</CustomTableCell>
								<CustomTableCell>
									<Typography>Destination</Typography>
								</CustomTableCell>
								<CustomTableCell>
									<Typography>Avg RPM</Typography>
								</CustomTableCell>
								<CustomTableCell>
									<Typography>Est. Profit</Typography>
								</CustomTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!topLanes?.length && dataMarkets?.status === "pending" && (
								<TableRow>
									<TableCell colSpan={4} className="border-b-0 h-">
										<div className="flex flex-col items-center justify-center w-full h-full py-36">
											<CircularProgress style={{ color: "#707070" }} />
										</div>
									</TableCell>
								</TableRow>
							)}

							{topLanes?.length ? (
								topLanes?.map((lane, index) => (
									<TableRow key={`market-best-lane` + index} sx={{}}>
										<TableCell size="small">
											{createMPIPlaceView(
												infoLane?.[lane?.main_city]?.main_city,
												infoLane?.[lane?.main_city]?.profit_best_norm_smooth,
												infoLane?.[lane?.main_city]?.vol_perc_smooth
											)}
										</TableCell>
										<TableCell size="small">
											{createMPIPlaceView(
												lane?.dcluster_main_city,
												infoLane?.[lane?.dcluster_main_city]?.profit_best_norm_smooth,
												infoLane?.[lane?.dcluster_main_city]?.vol_perc_smooth
											)}
										</TableCell>
										<TableCell size="small">{formatCurrency(lane?.rpm)}</TableCell>
										<TableCell size="small">{formatCurrency(lane?.profit, 0)}</TableCell>
									</TableRow>
								))
							) : dataMarkets?.status !== "pending" ? (
								<TableRow>
									<TableCell colSpan={4}>
										<div className="flex flex-col items-center justify-center w-full h-full">
											<Typography className="font-medium text-16" color="error">
												No good lanes found
											</Typography>
										</div>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	);
}

export default LanesMarketCondition;
