const carrierAxleFormConfig = () => {
	return {
		urlGET: "api/profile/carriers/:carrierId/details/configuration",
		idKey: "_id",
		triggerEvent: ["profileRevision"],
		listenEvent: ["profileRevision"],
		content: {
			view: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "Status",
									type: "text",
									label: "Status",
									builder: (item) => (item?.axleConnection?.fleet_id ? "Connected" : "Not Configured"),
								},
								{
									key: "fleet_id",
									hidden: (item) => !item?.axleConnection?.fleet_id,
									type: "text",
									label: "Fleet ID",
									builder: (item) => item?.axleConnection?.fleet_id,
								},
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "carrier",
									hidden: (item) => !item?.axleConnection?.fleet_id,
									type: "text",
									label: "Carrier",
									builder: (item) => item?.axleConnection?.carrier?.carrier_name,
								},
								{
									key: "dot_number",
									hidden: (item) => !item?.axleConnection?.fleet_id,
									type: "text",
									label: "Dot Number",
									builder: (item) => item?.axleConnection?.carrier?.dot_number,
								},
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "truckCount",
									hidden: (item) => !item?.axleConnection?.fleet_id,
									type: "number",
									label: "Truck Count",
									builder: (item) => item?.axleConnection?.truckCount,
								},
								{
									key: "driverCount",
									hidden: (item) => !item?.axleConnection?.fleet_id,
									type: "number",
									label: "Driver Count",
									builder: (item) => item?.axleConnection?.driverCount,
								},
							],
						},
					},
				],
			},
		},
	};
};

export default carrierAxleFormConfig;
