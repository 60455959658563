import ActionButton from "app/main/common/ActionButton";

import { UnitFinanceService } from "app/services/unitFinanceServices";

// Utils
import { downloadFile } from "app/main/utils/fileUtils";

// Services
import { getRoleLabel } from "app/services/LoginService";

const downloadStatementButton = (statementId, accountType, isAdmin) => (
	<ActionButton
		key="download_statement"
		onClick={async () => {
			const pdfBinary = await UnitFinanceService.getStatementPDF({ statementId, isAdmin });
			const name = `Statement_${accountType + "_" + statementId}`;
			downloadFile(pdfBinary, name, "application/pdf");
		}}
		label="Download"
	/>
);

const statements = ({ accountTypes, isAdmin }) => ({
	urlGET: `api/wallet/${isAdmin ? "admin/" : ""}statements/:entityId`,
	idKey: "id",
	listenEvent: "smarthopWalletRevision",
	segmentKey: "CARRIER_SMARTHOP_WALLET_STATEMENTS",
	otp: getRoleLabel() === "carrier" && ["WALLET_ACCESS"],
	content: {
		table: {
			variant: "skinny",
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			default: { accountId: accountTypes?.find((account) => account.purpose === "checking")?.value },
			search: false,
			// Add period filter
			items: [
				{
					key: "accountId",
					type: "select",
					label: "Account Type",
					options: accountTypes,
				},
			],
		},
		items: [
			{ key: "attributes.period", type: "text", label: "Period" },
			{
				key: "id",
				type: "component",
				label: "Download",
				builder: (item) => {
					const accountId = item?.relationships.account.data.id;
					const accountType = accountTypes.find((account) => account.value === accountId)?.label;
					return downloadStatementButton(item?.id, accountType, isAdmin);
				},
			},
		],
	},
});

export default statements;
