import { Controller } from "react-hook-form";

import Typography from "@material-ui/core/Typography";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";

function SmarthopRadioButtonField(props) {
	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field }) => {
				const edited =
					props.orignialValue === false || props.orignialValue === true || props.orignialValue === ""
						? props.orignialValue + "" !== (field.value ?? "") + ""
							? " (Edited)"
							: ""
						: "";

				const label =
					typeof props.label === "string" || props.label instanceof String ? (
						props.label + (props.required ? " *" : "") + edited
					) : (
						<>
							{props.label}
							{edited}
						</>
					);

				return (
					<div
						className={
							"w-full flex flex-col mb-4 mt-4 px-4 " +
								(props?.checkbox?.noErrorMessage ? " pt-4 pb-4 " : "") +
								" " +
								props?.checkbox?.classes?.root ?? ""
						}
					>
						<div className="w-full flex flex-col pl-2 -mt-5 pb-3">
							<FormControlLabel
								control={
									<RadioGroup
										{...field}
										disabled={props.disabled}
										onChange={(event, value) => {
											field.onChange(event, value);
											if (props.onChangeCommitted) props.onChangeCommitted(props.name, value);
										}}
									>
										{label && label !== "" && (
											<Typography color="textSecondary" variant="caption" className="ml-10 mb-4">
												{label}
											</Typography>
										)}
										{props.options?.map((option, i) => (
											<div key={option.value} className={"w-full flex pl-10 " + (i > 0 ? " -mt-4 " : "")}>
												<FormControlLabel value={option.value} control={<Radio />} label={option.label} />
											</div>
										))}
									</RadioGroup>
								}
							/>
						</div>
						{!props?.checkbox?.noErrorMessage && (
							<div className="w-full flex pl-10 h-12">
								<Typography variant="caption" color="error">
									{!props.disabled ? props.errors[props.name]?.message : ""}
								</Typography>
							</div>
						)}
					</div>
				);
			}}
		/>
	);
}

export default SmarthopRadioButtonField;
