import { useEffect, useState } from "react";
import { MarketGraphComponent } from "app/main/widgets/custom/MarketAnalysisWidget";
import { SmarthopList, adminSearchDetailsList } from "@smarthop/list";
import { getUserId } from "app/services/LoginService";
import SearchStatusPanel from "../SearchStatusPanel";

import Table from "@material-ui/core/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";

const ValidatedMoveInfoView = ({ dataIds, setTitle }) => {
	useEffect(() => {
		setTitle?.("Validated Move Info");
		// eslint-disable-next-line
	}, []);
	const { searchId, validationInfo, rangesData } = dataIds;
	const ranges = rangesData?.ranges?.find((r) => r?.type === "ALL");
	const [tab, setTab] = useState(0);
	const [smallScreen] = useState(window.innerWidth <= 600);
	const userId = getUserId();

	return (
		<>
			<Tabs value={tab} onChange={(e, v) => setTab(v)} variant={smallScreen ? "fullWidth" : ""}>
				<Tab label="Loads" value={0} />
				<Tab label="Lane Analysis" value={1} />
			</Tabs>
			{tab === 0 && (
				<>
					<div className="flex flex-row mb-10 ml:mb-0">
						<SearchStatusPanel viewOnly={true} smallScreen={true} searchId={searchId} />
					</div>
					<SmarthopList
						key="search_list_detail"
						mode="table"
						config={adminSearchDetailsList}
						dataIds={{ searchId: searchId, userId: userId }}
					/>
				</>
			)}
			{tab === 1 && (
				<>
					<Table stickyHeader className="w-full" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								{["Label", "Value/Range", "Mean", "Standard Deviation", "Threshold", "Move data", "Validated"].map(
									(header) => (
										<TableCell key={header} align="center">
											<Typography color="primary" className={"font-semibold tracking-wide "}>
												{header}
											</Typography>
										</TableCell>
									)
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{validationInfo?.map((row, i) => (
								<TableRow key={i} className={"bg-transparent "}>
									<TableCell align="center"> {row.label} </TableCell>
									<TableCell align="center"> {row.value} </TableCell>
									<TableCell align="center"> {row.mean ?? "None"} </TableCell>
									<TableCell align="center"> {row.std ?? "None"} </TableCell>
									<TableCell align="center"> {row.threshold ?? "None"} </TableCell>
									<TableCell align="center"> {row.moveValue} </TableCell>
									<TableCell align="center"> {`${row.validated ? "✅" : "❌"}`} </TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{ranges && (
						<div className={"flex flex-col w-full"}>
							<MarketGraphComponent
								className={"w-full"}
								key={"RATE"}
								data={ranges?.rate}
								type={"RATE"}
								height={300}
								title={"Loads Grouped By Rate"}
							/>
							<MarketGraphComponent
								className={"w-full"}
								key={"PROFIT"}
								data={ranges?.profit}
								type={"PROFIT"}
								height={300}
								title={"Loads Grouped By Profit"}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ValidatedMoveInfoView;
