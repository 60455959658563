import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { PAYROLL_STATUSES } from "app/main/consts";

const PayrollCellView = (props) => {
	const mobile = props.mobile;
	const variant = props.variant;
	const metadata = props.view?.metadata;
	const config = props.view?.config ?? props.config;
	const payrollId = props.view?.value;
	const item = props.item;

	if (!payrollId) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<CellFirstRow
					mobile={mobile}
					view={props.view}
					dialog={{
						viewId: "PAYROLL_VIEW",
						dataIds: {
							carrierId: metadata?.payee?.carrier,
							payeeType: item.payee_type ?? "DRIVER",
							payrollId: payrollId,
							// TODO: 3870: Refactor base only in user entity
							// userId: metadata?.payee?.user,
							...(item.payee_type === "DRIVER" ? { driverId: metadata?.payee?.driver } : {}),
							...(item.payee_type === "USER" ? { userId: metadata?.payee?.user } : {}),
							userId: metadata?.payee?.user,
							payrollStatus: metadata?.state,
							isInvestor: item.payee__view?.metadata?.gatekeepers?.permission_investor_on,
							label: item.payee__view?.label,
						},
					}}
					variant={variant}
				/>
			}
			second={
				<div className="flex flex-wrap">
					{config?.showState && metadata?.state === "open" && (
						<CellSecondRow
							icon={"pending"}
							mobile={mobile}
							label={`${PAYROLL_STATUSES[metadata?.state]}`}
							variant={variant}
							color={"text-orange-900"}
						/>
					)}
					{config?.showState && metadata?.state === "closed" && (
						<CellSecondRow
							icon={"preview"}
							mobile={mobile}
							label={`${PAYROLL_STATUSES[metadata?.state]}`}
							variant={variant}
							color={"text-blue-900"}
						/>
					)}
					{config?.showState && metadata?.state === "approved" && (
						<CellSecondRow
							icon={"monetization_on"}
							mobile={mobile}
							label={`${PAYROLL_STATUSES[metadata?.state]}`}
							variant={variant}
							color={"text-green-900"}
						/>
					)}
					{metadata?.locked && metadata?.state === "approved" && (
						<CellSecondRow icon={"lock"} mobile={mobile} label={`Locked`} variant={variant} color={"text-purple-900"} />
					)}
				</div>
			}
		/>
	);
};

export default PayrollCellView;
