// Services
import { isRoleExternal } from "app/services/LoginService";

import ViewContentSection from "app/main/chat/messages/ViewContentSection";
import ViewTitleSection from "app/main/chat/messages/ViewTitleSection";
import ViewButtonSection from "app/main/chat/messages/ViewButtonSection";
import ViewButton from "app/main/chat/messages/ViewButton";
import ViewBanner from "app/main/chat/messages/ViewBanner";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useDispatch } from "react-redux";
import ViewDescriptionSection from "app/main/chat/messages/ViewDescriptionSection";
import { formatDate } from "app/main/utils/dateUtils";

function TransactionReportView(props) {
	const dispatch = useDispatch();
	const incoming = props.incoming;
	const variant = props.variant;
	const hasSubaccounts = props.message?.hasSubaccounts ?? false;
	const transactionContent = props.message?.content ?? props.message;
	const type = transactionContent?.type;
	const isSkinny = variant === "skinny";
	const transactionState = transactionContent?.state;
	const transactionStatus = transactionContent?.status;

	const loadCarrierFields = [];
	if ((hasSubaccounts || !isRoleExternal()) && transactionContent?.carrier_company_name) {
		loadCarrierFields.push({ label: "Carrier", value: transactionContent?.carrier_company_name });
	}

	let reportFields = [
		{
			label: "Start Date",
			value: formatDate(
				transactionContent?.report?.start_date,
				transactionContent?.report?.start_date,
				false,
				false,
				false,
				false
			),
		},
		{
			label: "End Date",
			value: formatDate(
				transactionContent?.report?.end_date,
				transactionContent?.report?.end_date,
				false,
				false,
				false,
				false
			),
		},
	];

	const parseTransactionStatus = (state, status) => {
		if (status === "FAILED") return { title: "Failed", color: "bg-red" };
		if (state === "GET_REPORT_DATA") return { title: "Get Report Data", color: "bg-green" };
		if (state === "GENERATE_REPORT") return { title: "Generate Report", color: "bg-green" };
		if (state === "DONE") return { title: "Finished", color: "bg-green" };
		if (state === "CANCELED") return { title: "Canceled", color: "bg-red" };
		return { title: "In progress", color: "bg-blue" };
	};

	const handleViewReportClick = () => {
		dispatch(
			openFormDialog({
				viewId: "REPORT_VIEW",
				dataIds: { carrierId: transactionContent?.report?.carrier, reportId: transactionContent?.report?._id },
			})
		);
	};
	return (
		<ViewContentSection variant={variant} incoming={incoming}>
			{!isSkinny && (
				<ViewTitleSection
					variant={variant}
					title={`${type} Report`}
					badge={parseTransactionStatus(transactionState, transactionStatus)?.title}
					classess={{ badge: parseTransactionStatus(transactionState, transactionStatus)?.color }}
				/>
			)}

			{!isSkinny && (
				<ViewDescriptionSection variant={variant} incoming={incoming} items={loadCarrierFields} oneColumn={true} />
			)}
			{type === "IFTA" && <ViewDescriptionSection variant={variant} incoming={incoming} items={reportFields} />}
			{!isSkinny && transactionStatus === "FAILED" && transactionContent?.lastMessage && (
				<ViewBanner
					variant={variant}
					incoming={incoming}
					icon="warning"
					message={transactionContent?.lastMessage}
					classess={{ banner: "bg-red" }}
				/>
			)}
			{!isSkinny && transactionState === "DONE" && type === "IFTA" && (
				<ViewButtonSection variant={variant} incoming={incoming} orientation="horizontal">
					<ViewButton
						variant={variant}
						incoming={incoming}
						color={"secondary"}
						onClick={handleViewReportClick}
						label={"View Report"}
					/>
				</ViewButtonSection>
			)}
		</ViewContentSection>
	);
}
export default TransactionReportView;
