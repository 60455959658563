import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function TrailerCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;

	const length = props.view?.metadata?.trailer;
	const maxWeight = props.view?.metadata?.max_weight;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<div className="flex flex-wrap">
					<CellFirstRow mobile={mobile} view={props.view} variant={variant} />
				</div>
			}
			second={
				<div className="flex flex-wrap">
					{config?.showLength && length && (
						<CellSecondRow mobile={mobile} icon={"straighten"} label={length} variant={variant} />
					)}
					{config?.showMaxWeight && maxWeight && (
						<CellSecondRow mobile={mobile} icon={"shopping_cart"} label={maxWeight + " lb"} variant={variant} />
					)}
				</div>
			}
		/>
	);
}

export default TrailerCellView;
