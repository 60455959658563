// Dependencies
import { useState, useMemo, useEffect } from "react";
import { CircularProgress, Icon, IconButton, Typography } from "@material-ui/core";
import { motion } from "framer-motion";

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Toots and Utils
import PageWrapper from "../../common/PageWrapper";
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { capitalizeFirstLetter } from "app/main/utils/tableUtils";

// Services
import { getRoleLabel, isCarrierPermission, isInternalPermission } from "app/services/LoginService";
import { getFuelPayeesPayrollSettings } from "app/services/enterprisePayrollServices";

const FUEL_CHARGE_PRICE_LABEL = {
	DISCOUNTED: "Discounted Amount",
	RETAILS: "Retail Amount",
};

/**
 * PayeeSection Rendering Component
 * @param {*} param0
 * @returns
 */
const PayeeSection = ({
	data: { title, payeeName, transactionId, payeeId, carrierId, settings, payeeType, isInternal, isCarrier },
	hidden,
}) => {
	const dispatch = useDispatch();

	const onEdit = useCallback(async () => {
		dispatch(
			openLoadedFormDialog({
				formId: "FUEL_TRANSACTION_PAYEE_FORM",
				mode: "EDIT",
				dataIds: { transactionId, payeeId, id: payeeId, carrierId: carrierId },
			})
		);
	}, [carrierId, dispatch, payeeId, transactionId]);

	if (hidden) return null;

	if (!settings) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className="flex w-full flex-col md:flex-row">
			<DisplaySection
				classes={{ root: "md:w-full" }}
				bannerType={settings?.edited ? "warning" : undefined}
				bannerMsg={`${capitalizeFirstLetter(
					payeeType
				)} fuel transaction payroll setting is no longer equal to current ${capitalizeFirstLetter(
					payeeType
				)} configuration`}
				title={
					<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
						<div>{`${payeeName} (${title})`}</div>
						<div className="space-x-8 float-right -mt-8 -mb-20">
							{(isInternal || isCarrier) && (
								<IconButton
									aria-label="edit"
									className="-mt-20"
									size="medium"
									color="inherit"
									onClick={() => onEdit()}
									title="Edit settings for this fuel transaction"
								>
									<Icon className={"text-20 text-black font-500"}>{"edit"}</Icon>
								</IconButton>
							)}
						</div>
					</div>
				}
			>
				<div className="-mt-10">
					{payeeType === "DRIVER" && (
						<>
							{renderDataItem("Charge Price", FUEL_CHARGE_PRICE_LABEL[settings.fuelChargePrice] ?? "-", "", {})}
							{renderDataItem(
								"Percentage",
								!Number.isNaN(+settings?.percentage) && settings?.percentage !== null
									? settings?.percentage + "%"
									: "-",
								"",
								{}
							)}
						</>
					)}

					{payeeType === "INVESTOR" && (
						<>
							{renderDataItem("Charge Price", FUEL_CHARGE_PRICE_LABEL[settings.fuelChargePrice] ?? "-", "", {})}
							{renderDataItem(
								"Percentage",
								!Number.isNaN(+settings?.percentage) && settings?.percentage !== null
									? settings?.percentage + "%"
									: "-",
								"",
								{}
							)}
						</>
					)}
				</div>
			</DisplaySection>
		</div>
	);
};

function FuelTransactionPayeesDetailsPayrollView(props) {
	const fuelRevision = useSelector(({ tools }) => tools.revision?.fuelRevision);

	const role = useMemo(() => getRoleLabel(), []);
	const isInternal = useMemo(() => isInternalPermission(role), [role]);
	const isCarrier = useMemo(() => isCarrierPermission(role), [role]);

	const transactionId = props.dataIds?.transactionId;
	const [driverSettings, setDriverSettings] = useState();
	const [investorSettings, setInvestorSettings] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			if (transactionId) {
				const payrollSettings = await getFuelPayeesPayrollSettings({ transactionId });
				if (payrollSettings.driver?.driver__view?.label) {
					setDriverSettings({
						settings: payrollSettings.driver,
						title: "Driver",
						payeeName: payrollSettings.driver?.driver__view?.label,
						payeeType: "DRIVER",
						payeeId: payrollSettings.driver?.driver__view?.value,
					});
				} else setDriverSettings(undefined);
				if (payrollSettings.investor?.investor__view?.label) {
					setInvestorSettings({
						settings: payrollSettings.investor,
						title: "Investor",
						payeeName: payrollSettings.investor?.investor__view?.label,
						payeeType: "INVESTOR",
						payeeId: payrollSettings.investor?.investor__view?.value,
					});
				} else setInvestorSettings(undefined);
				setLoading(false);
			}
		})();
	}, [fuelRevision, transactionId]);

	const renderPayeesSection = (payeeSettings) => {
		const sections = [];
		for (const payee of payeeSettings) {
			const title = payee.title;
			const settings = payee.settings;
			const payeeName = payee.payeeName;
			const payeeId = payee.payeeId;
			const payeeType = payee.payeeType;
			const data = { title, settings, payeeName, transactionId, payeeId, payeeType, isInternal, isCarrier };
			if (payeeId && settings) {
				sections.push(<PayeeSection key={payeeId} data={data} hidden={!settings} />);
			}
		}
		return sections;
	};

	return (
		<PageWrapper title="Fuel Transaction Payees" variant="narrow" isView={props.isView}>
			{!driverSettings && !investorSettings && (
				<motion.div
					className="flex w-full h-full items-center justify-center"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { duration: 0.5 } }}
					style={{ paddingBottom: 24 + "px" }}
				>
					<div className="flex-col w-full flex items-center justify-center">
						<Typography color="primary" className="text-12 ml:text-13 font-light mx-20" component={"div"}>
							{loading ? "Loading..." : "No Data Found"}
						</Typography>
					</div>
				</motion.div>
			)}
			<div className="flex flex-col items-top w-full space-x-0 grid-cols-2">
				<div className="flex flex-col md:flex-row md:space-x-16">{renderPayeesSection([driverSettings ?? {}])}</div>
				<div className="flex flex-col md:flex-row md:space-x-16">{renderPayeesSection([investorSettings ?? {}])}</div>
			</div>
		</PageWrapper>
	);
}

export default FuelTransactionPayeesDetailsPayrollView;
