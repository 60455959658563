import moment from "moment";

const REJECTED_REASON = {
	key: "onboardingRejectionReason",
	type: "text",
	label: "Rejection Reason / Requested Changed",
};
const STATUS_CARRIER = {
	key: "accountStatus",
	type: "select",
	label: "Account Status",
	required: true,
	options: [
		{
			value: "ACTIVE",
			label: "Active",
		},
		{
			value: "DEACTIVATED",
			label: "Deactivated",
		},
	],
};
const ONBOARDING_STATUS = {
	key: "onboardingStatus",
	type: "select",
	label: "Onboarding Status",
	required: true,
	defaultValue: "INCOMPLETE",
	options: [
		{
			value: "INCOMPLETE",
			label: "Incomplete",
		},
		{
			value: "IN_REVIEW",
			label: "Getting Started",
		},
		{
			value: "CHANGES_REQUESTED",
			label: "Changes requested",
		},
		{
			value: "ACCEPTED",
			label: "Accepted",
		},
		{
			value: "REJECTED",
			label: "Rejected",
		},
	],
};
const DEACTIVATION_REASON = {
	type: "text",
	key: "deactivationReason",
	label: "Deactivation Reason",
};
const ONBOARDING_INFORMATION_STATUS = {
	key: "onboardingInformationStatus",
	type: "select",
	label: "Information Status",
	required: true,
	defaultValue: "UNVALIDATED",
	options: [
		{
			value: "VALIDATED_LOCKED",
			label: "Validated (Locked)",
		},
		{
			value: "VALIDATED",
			label: "Validated (Editable)",
		},
		{
			value: "UNVALIDATED",
			label: "Not Validated",
		},
	],
};
const ONBOARDING_PAYROLL_STATUS = {
	key: "payroll_module_flag",
	type: "select",
	label: "Payroll Onboarding Status",
	defaultValue: false,
	options: [
		{
			value: true,
			label: "Complete",
		},
		{
			value: false,
			label: "Incomplete",
		},
	],
};

const ONBOARDING_INVOICE_STATUS = {
	key: "invoicingModuleOnboarding",
	type: "select",
	label: "Invoice Onboarding Status",
	defaultValue: "INCOMPLETE",
	options: [
		{
			value: "COMPLETE",
			label: "Complete",
		},
		{
			value: "INCOMPLETE",
			label: "Incomplete",
		},
	],
};

const ONBOARDING_PERFORMANCE_STATUS = {
	key: "onboardingStatusPefrormance",
	type: "select",
	label: "Performance Onboarding Status",
	required: true,
	defaultValue: "INCOMPLETE",
	options: [
		{
			value: "INCOMPLETE",
			label: "Incomplete",
		},
		{
			value: "DONE",
			label: "Done",
		},
	],
};

const CHECK_INSURANCE_CARRIER = {
	key: "checkInsurance",
	type: "checkbox",
	label: "Certification On Insurance Required",
};

const CHECK_RESGISTRATION_CAB_CARRIER = {
	key: "checkRegistrationCab",
	type: "checkbox",
	label: "Truck CAB Card or Truck Registration Required",
};

const COMPANY_EQUIPMENT = {
	key: "onboardingPreselects.companyEquipment",
	type: "text",
	label: "Company Equipmnet",
	builder: (item) =>
		Object.keys(item?.onboardingPreselects?.companyEquipment ?? {})
			.filter((val) => item?.onboardingPreselects?.companyEquipment[val])
			.join(", "),
};

const SEARCH_RESTRICTION_DATE = {
	key: "searchRestrictionDate",
	type: "picker",
	label: "Search Restriction Date",
	defaultValue: moment().add(3, "days"),
	field: {
		classes: {},
		variant: undefined,
	},
	input: {
		classes: {},
	},
	picker: {
		minDate: moment().add(1, "days"),
		disablePast: true,
		notClearable: true,
		type: "date",
		classes: {},
		format: "dd/MM/yyyy",
	},
	rules: {
		// TODO: at the momento only work for noEqualsTo
		hidden: [
			{ fieldKey: "accountStatus", noEqualsTo: "ACTIVE" },
			{ fieldKey: "onboardingStatus", noEqualsTo: "CHANGES_REQUESTED" },
		],
	},
};

const carrierOnboardingStatus = () => ({
	urlGET: "api/onboarding/carriers/:carrierId/status",
	urlPUT: "api/onboarding/carriers/:carrierId/status",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: { edit: { label: "Onboarding" } },
	content: {
		view: {
			items: [
				ONBOARDING_INFORMATION_STATUS,
				{ type: "group", content: { items: [ONBOARDING_STATUS, REJECTED_REASON] } },
				{ type: "group", content: { items: [STATUS_CARRIER, DEACTIVATION_REASON] } },
				SEARCH_RESTRICTION_DATE,
				{ type: "group", content: { items: [CHECK_INSURANCE_CARRIER, CHECK_RESGISTRATION_CAB_CARRIER] } },
				COMPANY_EQUIPMENT,
				ONBOARDING_PERFORMANCE_STATUS,
				ONBOARDING_PAYROLL_STATUS,
				ONBOARDING_INVOICE_STATUS,
				{ type: "action", action: "SUBMIT" },
			],
		},
		create: { sameAs: "edit" },
		edit: {
			items: [
				ONBOARDING_INFORMATION_STATUS,
				{ type: "group", content: { items: [ONBOARDING_STATUS, REJECTED_REASON] } },
				{ type: "group", content: { items: [STATUS_CARRIER, DEACTIVATION_REASON] } },
				SEARCH_RESTRICTION_DATE,
				{ type: "group", content: { items: [CHECK_INSURANCE_CARRIER, CHECK_RESGISTRATION_CAB_CARRIER] } },
				ONBOARDING_PERFORMANCE_STATUS,
				ONBOARDING_PAYROLL_STATUS,
				ONBOARDING_INVOICE_STATUS,
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
});

export default carrierOnboardingStatus;
