import { postTruckHistory, SmarthopList } from "@smarthop/list";
import PageWrapper from "app/main/common/PageWrapper";
import { useEffect } from "react";

function PostTruckHistoryView(props) {
	useEffect(() => {
		props?.setTitle?.("Post Truck");
		// eslint-disable-next-line
	}, []);
	const truckId = props?.dataIds?.truckId;
	const carrierId = props?.dataIds?.carrierId;
	const userId = props?.dataIds?.userId;
	const equipment = props?.dataIds?.equipment;
	let addDialog = {
		viewId: "POST_TRUCK",
		mode: "CREATE",
		dataIds: { carrierId, truckId, equipment },
	};
	let addInvoice = {
		action: "create",
		label: "Create post truck",
		dialog: addDialog,
	};
	return (
		<PageWrapper titleActions={[addInvoice]}>
			<SmarthopList mode="GRID" config={postTruckHistory()} dataIds={{ carrierId, truckId, userId, equipment }} />
		</PageWrapper>
	);
}

export default PostTruckHistoryView;
