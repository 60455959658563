import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const cancelBidBookTransaction = (transactionId) => {
	return createRequest()
		.delete(`/api/transaction/bid/${transactionId}/cancel`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const retryBidBookTransactionRC = (transactionId) => {
	return createRequest()
		.post(`/api/transaction/bid/${transactionId}/retry-rc`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
