import { SmarthopList, adminCronJobsLogsList } from "@smarthop/list";
import { useEffect } from "react";

function AdminCronJobLogsView(props) {
	useEffect(() => {
		props?.setTitle?.("Cronjob Logs");
		// eslint-disable-next-line
	}, []);
	const { runId, userId } = props.dataIds;
	return <SmarthopList mode="TABLE" inDialog={true} config={adminCronJobsLogsList} dataIds={{ userId, runId }} />;
}

export default AdminCronJobLogsView;
