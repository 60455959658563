import clsx from "clsx";
import { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";

import { Box, CircularProgress, Paper, TextField, Typography, Button } from "@material-ui/core";
import { LocalOffer } from "@material-ui/icons";

import { applyPromotionCode, deactiveCarrierDiscount, getCarrierCoupon } from "app/services/couponServices";
import { showSnackbar } from "app/main/utils/snackbarUtil";

const PromoCodeBar = (props) => {
	const snackbar = useSnackbar();

	const carrierId = props.carrierId;
	const onChange = props.onChange;

	const [couponCode, setCouponCode] = useState("");
	const [loading, setLoading] = useState(false);
	const [barInitialized, setBarInitialized] = useState(false);
	const [error, setError] = useState(null);
	const [coupon, setCoupon] = useState(null);

	const changeCoupon = (coupon) => {
		setCoupon(coupon);
		onChange?.(coupon);
	};

	const handleApplyCouponCode = async () => {
		processCouponCode(couponCode);
	};

	const handleRemoveCouponCode = async () => {
		try {
			setLoading(true);
			setError(null);
			await deactiveCarrierDiscount(carrierId);
			changeCoupon(null);
		} catch (err) {
			setError(err.errors?.[0]?.message ?? "Opps, something went wrong...");
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
		} finally {
			setLoading(false);
		}
	};

	const processCouponCode = async (couponCode) => {
		try {
			setLoading(true);
			setError(null);
			await applyPromotionCode(carrierId, couponCode);
			const carrierCoupon = await getCarrierCoupon(carrierId);
			changeCoupon(carrierCoupon);
		} catch (err) {
			setError(err.errors?.[0]?.message ?? "Opps, something went wrong...");
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const carrierCoupon = await getCarrierCoupon(carrierId);
				changeCoupon(carrierCoupon);
				setBarInitialized(true);
			} catch (err) {
				setError(err.errors?.[0]?.message ?? "Opps, something went wrong, please reload the page...");
			}
		})();
		//eslint-disable-next-line
	}, []);

	const outerSharedStyle = "flex flex-col w-full py-12 px-12 justify-center items-center";
	const innerSharedStyle =
		"flex flex-col md:flex-row pt-10 pb-16 md:pt-10 md:pb-10 px-20 justify-center items-center md:min-w-720 ml:min-w-960";
	return (
		<Box className={outerSharedStyle}>
			{!barInitialized ? (
				<Paper
					className={clsx(innerSharedStyle, "bg-grey-50 text-grey-400 border-grey-500 border-2 animate-pulse")}
					elevation={4}
				>
					{error ? (
						<Typography color="error" className="flex flex-1 text-12 mr-12 my-4">
							{error}
						</Typography>
					) : (
						<Typography component={"p"} className="flex flex-1 font-semibold text-15 mr-12 my-4">
							<LocalOffer /> &nbsp;
						</Typography>
					)}
					<Button
						className="ml-10 bg-grey-700 min-w-120 h-32 my-1"
						variant="contained"
						color="secondary"
						disabled={true}
					></Button>
				</Paper>
			) : !coupon ? (
				<Paper className={clsx(innerSharedStyle, "bg-blue-50 text-blue-900 border-blue-600 border-2")} elevation={4}>
					<Typography component={"p"} className="text-15 font-semibold mr-6 my-4">
						<LocalOffer /> &nbsp; Have a Promo Code?
					</Typography>
					<Typography className="hidden ml:inline text-15 my-4">Apply to get a discount</Typography>
					<Box flexGrow={1} />
					<Box display={"flex"} justifyContent={"center"}>
						<TextField
							size="small"
							disabled={loading}
							variant="outlined"
							value={couponCode}
							className="border-blue-600"
							onChange={(event) => {
								const inputText = event.target.value;
								setError(null);
								setCouponCode(inputText.toUpperCase().replace(" ", "_"));
							}}
							InputProps={{
								style: {
									backgroundColor: "white",
									color: error ? "red" : "black",
								},
								className: "px-0 font-bold text-15 max-w-240 h-32 my-1",
							}}
						/>
						<Button
							className="ml-10 px-20 min-w-120 h-32 my-1"
							onClick={handleApplyCouponCode}
							variant="contained"
							color="secondary"
							disabled={loading || !couponCode}
						>
							{loading ? <CircularProgress color="inherit" size="1.7rem" /> : "Apply"}
						</Button>
					</Box>
				</Paper>
			) : (
				<Paper
					className={clsx(innerSharedStyle, "bg-green-50 text-green-900 border-green border-dashed border-2")}
					elevation={4}
				>
					<Typography component={"p"} className="flex flex-1 font-semibold text-15 mr-12 my-4">
						<LocalOffer /> &nbsp; Coupon succesfully applied
					</Typography>
					<Button
						className="ml-10 bg-red-700 min-w-120 h-32 my-1"
						onClick={handleRemoveCouponCode}
						variant="contained"
						color="secondary"
						disabled={loading}
					>
						{loading ? <CircularProgress color="inherit" size="1.7rem" /> : "Remove"}
					</Button>
				</Paper>
			)}
		</Box>
	);
};

export default PromoCodeBar;
