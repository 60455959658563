import Typography from "@material-ui/core/Typography";

// Utils
import { locationLatLong } from "app/main/utils/tripsUtils";

// Component
import SmartHopMap from "app/main/profile/trips/SmartHopMap";
import { useMemo } from "react";

function LoadMarketConditionMapsNew({ classes, showHeatMap, data, loading, zoom }) {
	const locations = useMemo(() => locationLatLong(data), [data]);

	const map = useMemo(() => {
		return (
			<SmartHopMap
				provider={"TRIMBLE MAPS"}
				classes={{
					root: "w-full " + (classes?.root ?? "absolute h-full bottom-0 left-0"),
				}}
				locations={locations}
				loadId={data.load_id}
				activeHeatMap={
					showHeatMap && (data.equipment === "VAN" || data.equipment === "REEFER" || data.equipment === "FLATBED")
				}
				equipment={data.equipment}
				origin={data?.truckOrigin}
				destination={data?.truckDestination}
				miles={data.miles}
				map="myMapSuggest"
				zoom={zoom}
			/>
		);
		// eslint-disable-next-line
	}, [
		data?.truckOrigin,
		data?.truckDestination,
		locations,
		data?.equipment,
		zoom,
		data?.origin,
		data?.destination,
		data?.city,
		data?.ecity,
		showHeatMap,
	]);

	if (loading || !data) {
		return (
			<div
				className={
					"flex w-full items-center bg-grey-100 justify-center mt-40 rounded-lg " +
					(classes?.root ?? " max-h-240 md:max-h-360 md:max-w-420 md:w-screen h-screen")
				}
			>
				<Typography className="flex pt-12 pb-16">Loading...</Typography>
			</div>
		);
	}

	return (
		<div className={`flex flex-col w-full h-full`}>
			<div className={"flex flex-col w-full h-full relative"}>{map}</div>
		</div>
	);
}

export default LoadMarketConditionMapsNew;
