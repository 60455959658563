import AddPlanAddonProrationComponent from "app/main/profile/carriers/add-ons/AddPlanAddonProrationComponent";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { closeFormDialog } from "app/store/tools/formDialogSlice";

const platformPlanSummaryForm = {
	urlGET: "billing/carriers/:carrierId/platform-addons-plans/:addonId",
	urlPOST: "billing/carriers/:carrierId/platform-addons-plans",
	urlDELETE: "billing/carriers/:carrierId/platform-addons-plans/:addonId",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision"],
	header: {
		create: { label: `Assign Plan Add-on` },
		delete: { label: "Remove Plan Add-on" },
	},
	messages: {
		create: { success: () => `Plan Add-on assigned.` },
		delete: { success: () => `Plan Add-on removed.` },
	},
	content: {
		create: {
			form: {
				trackChangedFields: ["platform_addon_plan"],
			},
			items: [
				{
					key: "platform_addon_plan",
					type: "autocomplete",
					label: `Add-on`,
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/carriers/:carrierId/platform-plans-addons",
						params: {
							sortOrder: "asc",
							sortBy: "nickname",
						},
						preloadDefault: true,
						preloadImmedately: true,
						listenEvent: "planRevision",
					},
				},
				{
					key: "platform_addon_fee",
					type: "component",
					value: (data, _, dataIds) => {
						const carrierId = dataIds?.carrierId;
						const planId = data?.platform_addon_plan;
						return <AddPlanAddonProrationComponent carrierId={carrierId} planId={planId} />;
					},
				},
				{ type: "action", action: "SUBMIT", label: "Save", disableAtClick: true },
			],
			onFinished: (_data, dispatch) => {
				dispatch(closeFormDialog());
			},
			onError: (errors, dispatch, snackbar) => {
				showSnackbar(snackbar, `Something went wrong.`, "error", 5000);
			},
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `You are about to remove 1 Add-on item`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE", label: "Remove" },
						],
					},
				},
			],
		},
	},
};

export default platformPlanSummaryForm;
