import { formatDate } from "app/main/utils/dateUtils";

const filterSoft = {
	urlGET: "api/search/requests/:id/filters/type/:type",
	header: {
		view: { label: "Filters soft" },
	},
	content: {
		view: {
			items: [ 
        {
					type: "group",
					content: {
						items: [
							{
                key: "location_origin",
                type: "text",
                label: "Origin"
              },
              {
                key: "origin_dh",
                type: "text",
                label: "DH-O"
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                key: "location_destination",
                type: "text",
                label: "Destination"
              },
              {
                key: "destination_dh",
                type: "text",
                label: "DH-D"
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                key: "carrierid",
                type: "text",
                label: "Carrier id"
              },
              {
                key: "truckid",
                type: "text",
                label: "Truck id"
              },
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								builder: (item) => item?.onboardedBrokersClientIds?.length > 0 ? item?.onboardedBrokersClientIds.map((item) => item).join(", ") : 'N/A',
								type: "text",
								label: "On Boarded Brokers"
							},
							{
								builder: (item) => item?.blacklistedBrokersClientIds?.length > 0 ? item?.blacklistedBrokersClientIds.map((item) => item).join(", ") : 'N/A',
								type: "text",
								label: "BlackList Brokers"
							},
						],
					},
				},
        {
					type: "group",
					content: {
						items: [
              {
                builder: (item) => item?.availStart? formatDate(item.availStart) : item?.availStart,
                type: "text",
                label: "Start Date",
              },
              {
                builder: (item) => item?.availStart? formatDate(item.availEnd) : item?.availEnd,
                type: "text",
                label: "End Date",
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                key: "minRate",
                type: "text",
                label: "Min Rate",
              },
              {
                key: "minRpm",
                type: "text",
                label: "Min Rpm",
              },
						],
					},
        },
        {
					type: "group",
					content: {
						items: [
              {
                key: "equipment",
                type: "text",
                label: "Equipment"
              }, 
              {
                key: "mcServiceDateAge",
                type: "text",
                label: "Mc Service Age Date"
              },
      
						],
					},
        },
			],
		},
	},
};

export default filterSoft;