import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import { signInWithToken } from "app/services/LoginService";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { setUserData } from "app/store/auth/userSlice";

import { Checkbox, FormControlLabel } from "@material-ui/core";

//Services
import { addPaymentMethod } from "app/services/billingServices";

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "absolute",
		height: "auto!important",
		left: 0,
		top: "40%",
	},
}));

const createOptions = (fontSize, padding) => ({
	style: {
		base: {
			fontSize,
			color: "#424770",
			letterSpacing: "0.025em",
			fontFamily: "Source Code Pro, monospace",
			"::placeholder": { color: "#aab7c4" },
			padding,
		},
		invalid: { color: "#fa755a" },
	},
});

const CheckoutFormStripe = (props) => {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const classes = useStyles();
	const stripe = useStripe();
	const elements = useElements();

	// eslint-disable-next-line
	let _element = React.createRef();
	let carrierId = props.carrierId;
	let nativeMobile = props.nativeMobile;
	let onDone = props.onDone;

	let [disable, setDisableButtonCard] = useState(true);
	const [loading, setLoading] = useState(false);
	const [isDefault, setIsDefault] = useState(false);

	const handleChange = (e) => {
		setDisableButtonCard(!e.complete);
	};

	const markAsDefaultPaymentMethodHandler = (event) => {
		setIsDefault(event.target.checked);
	};

	const handleCreateCard = async (sourceId) => {
		setDisableButtonCard(true);
		setLoading(true);
		let data = {
			sourceId,
			allow: true,
			isDefault,
		};
		try {
			let response = await addPaymentMethod(carrierId, data);
			if (response?.source) {
				setDisableButtonCard(false);
				setLoading(false);
				setIsDefault(false);
				if (nativeMobile) {
					onDone(response?.source);
				} else {
					showSnackbar(snackbar, "Credit card added", "success", { duration: 5000 });
				}
			}

			// Calling re-singin API to re-fetch major latest user information, that would help to
			// reload all banners related to any payment errors or in the case when user has plan
			// but does not have any payment methods
			const userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData));
		} catch (err) {
			setDisableButtonCard(false);
			setLoading(false);
			if (nativeMobile) {
				onDone(err);
			} else {
				showSnackbar(snackbar, err.errors[0].message, "error", { duration: 5000 });
			}
			console.log(err);
		}
	};

	const addCard = async (_) => {
		if (stripe) {
			try {
				const cardElement = elements.getElement(CardElement);
				let payload = await stripe.createSource(cardElement, { type: "card" });
				await handleCreateCard(payload.source.id);
				dispatch(incrementDataRevision({ event: "paymentRevision" }));
				dispatch(closeFormDialog());
			} catch (err) {
				console.log(err);
			}
		} else {
			let msg = "Stripe.js hasn't loaded yet.";
			console.log(msg);
		}
	};
	return (
		<div className="mt-10 px-6">
			<div className="bg-grey-400 bg-opacity-100 shadow-sm p-1 rounded-md mt-8 mb-20">
				<div className="bg-white p-10">
					<CardElement onChange={(e) => handleChange(e)} {...createOptions(15)} onReady={(c) => (_element = c)} />
				</div>
			</div>
			{loading && (
				<div className={clsx(classes.progress, "flex flex-col items-center justify-center w-full h-full pt-20 pb-10")}>
					<CircularProgress />
				</div>
			)}
			<div className="bg-white pb-6">
				<FormControlLabel
					label={"Use As Default"}
					control={<Checkbox checked={isDefault} onChange={(e) => markAsDefaultPaymentMethodHandler(e)} />}
				/>
			</div>
			<div className="flex w-full flex-row items-top justify-center divide-x pb-10">
				<Button
					className="flex w-full"
					variant="contained"
					color="secondary"
					aria-label="Follow"
					disabled={disable}
					onClick={addCard}
				>
					Add Card
				</Button>
			</div>
		</div>
	);
};

function CreditCardView(props) {
	useEffect(() => {
		props?.setTitle?.("Add Credit Card");
		props?.setSize?.("max-w-sm");
		// eslint-disable-next-line
	}, []);

	let carrierId = props.dataIds.carrierId;
	let nativeMobile = props.nativeMobile;
	let onDone = props.onDone;
	let pk = process.env.REACT_APP_STRIPE_KEY;
	let [stripe] = useState(pk);

	const stripePromise = loadStripe(stripe);
	return (
		<Elements stripe={stripePromise}>
			<CheckoutFormStripe carrierId={carrierId} nativeMobile={nativeMobile} onDone={onDone} />
		</Elements>
	);
}

export default CreditCardView;
