const profileInformationForm = {
	urlGET: "api/profile/profiles/:profileId/details",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		view: { label: "Profile Information" },
	},
	content: {
		view: {
			items: [
				{
					key: "full_name",
					label: "Name",
					type: "text",
				},
				{
					key: "email",
					label: "Email",
					type: "text",
				},
				{
					key: "phone",
					label: "Phone",
					type: "text",
				},
			],
		},
	},
};

export default profileInformationForm;
