const fuelTransactionsSummaryForm = {
	urlGET: "/billing/fuel-transactions-summary?start_date=:start_date&end_date=:end_date&billing_cycle=:billing_cycle",
	header: {
		view: { label: "View Fuel Transactions Summary" },
	},
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "total_due",
								label: "Total Due",
								type: "currency",
							},
							{
								key: "total_savings",
								label: "Total Savings",
								type: "currency",
							},
						],
					},
				},
			],
		},
	},
};

export default fuelTransactionsSummaryForm;
