import Icon from "@material-ui/core/Icon";

import CellFirstRow from "./CellFirstRow";
import CellEmptyRow from "./CellEmptyRow";
import CellSecondRow from "./CellSecondRow";
import CellContainerRow from "./CellContainerRow";

// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { centsToDollars } from "app/main/utils/financeUtils";
import { formatDate } from "app/main/utils/dateUtils";

function CashAdvanceCellView(props) {
	const mobile = props.mobile;
	const config = props.config;
	const variant = props.variant;
	const view = props.view;

	const factoringRequestId = view?.value;
	const date = formatDate(view?.metadata?.date);
	const amount = view?.metadata?.amount;
	const label = view?.label ? formatCurrency(centsToDollars(view?.label)) : null;

	const factoringRequestDetailDialog = {
		viewId: "FACTORING_REQUEST_DETAIL_VIEW",
		dataIds: {
			item: {
				_id: factoringRequestId,
				amount,
			},
			factoringRequestId,
		},
	};

	if (!label) return <CellEmptyRow mobile={mobile} variant={variant} />;

	const icon = (
		<div className="flex flex-row items-center pr-4">
			<Icon className="text-green m-0 p-0 pr-4">attach_money</Icon>
		</div>
	);

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			left={icon}
			first={<CellFirstRow mobile={mobile} view={{ label }} dialog={factoringRequestDetailDialog} variant={variant} />}
			second={config?.showDate && <CellSecondRow mobile={mobile} label={date} variant={variant} />}
		/>
	);
}

export default CashAdvanceCellView;
