import React from "react";
import StrategyLoadingContainer from "./components/strategy-loading-wrapper/StrategyLoadingWrapper";
import StrategyPanel from "./components/strategy-panel/StrategyPanel";
import StrategySubpanel from "./components/strategy-subpanel/StrategySubpanel";
import StrategyEntryContainer from "./components/strategy-type-wrapper/StrategyTypeWrapper";
import StrategyVisualizer from "./components/strategy-visualizer/StrategyVisualizer";
import StrategyContextProvider from "./providers/StrategyContextProvider";
import StrategyDiagnosticMessages from "./components/strategy-diagnostic-messages/StrategyDiagnosticMessages";
import StrategyHeatmap from "./components/strategy-heatmap/StrategyHeatmap";
import StrategyHeader from "./components/strategy-header/StrategyHeader";
import StrategyConfirmDialog from "./components/strategy-confirm-dialog/StrategyConfirmDialog";

function StrategyView(props) {
	return (
		<StrategyContextProvider {...props}>
			<StrategyEntryContainer {...props}>
				<StrategyPanel {...props} />
				<StrategySubpanel {...props} />
				<StrategyLoadingContainer>
					<StrategyHeader />
					<StrategyVisualizer />
					<StrategyHeatmap />
				</StrategyLoadingContainer>
				<StrategyDiagnosticMessages />
				<StrategyConfirmDialog />
			</StrategyEntryContainer>
		</StrategyContextProvider>
	);
}

export default StrategyView;
