import moment from "moment";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Icon, makeStyles, IconButton, Badge, Tooltip } from "@material-ui/core";
import { selectChats } from "app/store/messenger/chatSlice";
import { openFormDialog, replaceFormDialog } from "app/store/tools/formDialogSlice";
import { setSearchParams, acknowledgeNew } from "app/store/search/searchV3Slice";
import { isRoleHasFullCarrierAccess, isRoleExternal, getUserId } from "app/services/LoginService";
import { saveViewedSearchVersions } from "app/services/searchServices";
import SearchMobileSort from "./SearchMobileSort";

import { DEFAULT_SOFT_FILTERS_VALUES } from "../utils/filtersUtils";

const useStyles = makeStyles({
	"@keyframes flicker": {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.2,
		},
	},
	flicker: {
		animationName: "$flicker",
		animationDuration: "1000ms",
		animationIterationCount: "infinite",
		animationDirection: "alternate",
		animationTimingFunction: "ease-in-out",
	},
	withAnimation: ({ disabled }) => ({
		animationPlayState: disabled ? "paused" : "running",
	}),
});

function CounterField({ label, count, hiddenCount, digitalCount, smallScreen }) {
	const labelStyle = smallScreen ? " text-11 " : " text-12 ";
	const textStyle = smallScreen ? " text-11 " : " text-12 ";
	return (
		<div className={"flex flex-col " + (smallScreen ? " -ml-4 " : " mr-4 ")}>
			<Typography className={"font-normal whitespace-nowrap text-gray-800 " + labelStyle}>{label}</Typography>
			<div className={"flex flex-row " + (smallScreen ? " -mt-2 " : " ")}>
				<div className="flex flex-row whitespace-nowrap">
					<Typography
						className={
							"flex flex-row font-semibold whitespace-nowrap items-center justify-center text-black " + textStyle
						}
						component={"div"}
					>
						{label === "New" && <div className={"w-6 h-6 rounded-full mr-4 bg-cyan-700"} />}
						{hiddenCount > 0 ? "Hidden" : count?.length > 0 ? count : count >= 0 ? count : "-"}
					</Typography>
				</div>
			</div>
		</div>
	);
}

function CounterFilter({ children, updateFilters, enabled }) {
	return (
		<div className={`cursor-pointer ${enabled ? `border-b-2 border-orange-300` : ""}`} onClick={updateFilters}>
			{children}
		</div>
	);
}

const getIconButton = (icon, text, disabled, className, badge, showText, smallScreen, callback) => {
	if (!callback) {
		return null;
	}
	let button = showText ? (
		<div disabled={!!disabled} className={"flex flex-row cursor-pointer"} onClick={() => callback()}>
			<Icon className={"text-20 text-primary"}>{icon}</Icon>
			<div className="flex flex-col">
				{text?.split(" ")?.map((line) => {
					return <Typography className="ml-6 text-10 ml:text-11 leading-none text-primary">{line}</Typography>;
				})}
			</div>
		</div>
	) : (
		<IconButton
			disabled={!!disabled}
			className={(smallScreen ? " mr-16 p-3 ml-4 " : " mr-24 p-3 ml-4 ") + className}
			aria-label="Clear"
			color="secondary"
			onClick={() => callback()}
		>
			<Badge badgeContent={badge} color="secondary">
				<Icon className={"text-20"}>{icon}</Icon>
			</Badge>
		</IconButton>
	);

	if (disabled) {
		return button;
	}

	return getTooltip(
		button,
		<Typography className={"font-semibold text-12 text-white p-6"} color="primary">
			{text}
		</Typography>
	);
};

const getTooltip = (component, title) => {
	return (
		<Tooltip classes={{ tooltip: "drop-shadow-md" }} title={title}>
			{component}
		</Tooltip>
	);
};

const getWarningsTooltip = (errors, warnings, smallScreen) => {
	if (!errors?.length && !warnings?.length) {
		return null;
	}

	return getTooltip(
		<Icon
			className={
				(errors?.length > 0 ? " text-red-400 " : " text-orange-700 ") +
				(smallScreen ? " mr-20 ml-4 " : " mr-28 ml-4 ") +
				" text-20 "
			}
		>
			{errors?.length > 0 ? "warning" : "report_problem"}
		</Icon>,
		<>
			{errors?.map((error, index) => {
				return (
					<Typography key={index} color="inherit" variant="body2" className="p-6 font-semibold">
						{error}
					</Typography>
				);
			})}
			{warnings?.map((warning, index) => {
				return (
					<Typography key={index} color="inherit" variant="body2" className="p-6">
						{warning}
					</Typography>
				);
			})}
		</>
	);
};

function SearchStatusBar({ mobile, smallScreen, viewOnly, nativeMobile }) {
	const { flicker, withAnimation } = useStyles({ disabled: false });
	const dispatch = useDispatch();

	const userId = useMemo(() => getUserId(), []);
	const viewerIsCustomer = useMemo(() => isRoleExternal(), []);
	const viewerHasFullCarrierAccess = useMemo(() => isRoleHasFullCarrierAccess(), []);
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const currentSearch = searches[currentSearchId];
	const results = currentSearch?.results;
	const filtered = currentSearch?.stats?.filtered;
	const newLoads = currentSearch?.stats?.new;
	const filters = currentSearch?.filters;

	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const currentSearchState = currentSearch?.state;
	const hasNetwort = isAuthenticated || nativeMobile;

	const chats = useSelector(selectChats);
	const unreadCount = useMemo(() => {
		let data = {};
		chats?.forEach((chat) => {
			if (chat.truck === filters?.truck) {
				data[chat.type] = chat?.metadata?.status?.unread;
			}
		});
		return data;
	}, [chats, filters?.truck]);

	const preloading = currentSearch?.state === "QUEUEING";
	const preloadingFinished = currentSearch?.state === "FINISHED_PRELOADED";

	const countersIcons = (filters || viewOnly) && (
		<div className={"flex flex-row items-center justify-start " + (smallScreen ? "" : " flex-1 flex-wrap ")}>
			{
				<div className="px-10 ml:px-16 min-w-64">
					{!preloading && !preloadingFinished ? (
						<CounterField label="Total" count={results?.length ?? "-"} hiddenCount={0} />
					) : (
						<div className={`${flicker} ${withAnimation} text-green`}>
							<Icon className={"ml-2 pt-2 text-18"}>search</Icon>
						</div>
					)}
				</div>
			}
			{!viewOnly && (
				<div className="pl-8 ml:pl-12 min-w-64">
					{!preloading && !preloadingFinished ? (
						<CounterField label="Filtered" count={filtered ?? results?.length} hiddenCount={0} />
					) : (
						<div className={`${flicker} ${withAnimation} text-green`}>
							<Icon className={"ml-2 pt-2 text-18"}>filter_list</Icon>
						</div>
					)}
				</div>
			)}
			{!viewOnly && (
				<div className="flex flex-row items-center">
					{getIconButton("replay", "Reset Filters", filtered === results?.length, null, null, null, smallScreen, () => {
						dispatch(
							setSearchParams({
								searchId: currentSearchId,
								softFilters: DEFAULT_SOFT_FILTERS_VALUES,
								override: { hardFilters: false, softFilters: true }, //Don't override hard filters
							})
						);
					})}
					<div className="w-1 h-20 bg-grey-300 mr-16 ml-8 ml:-ml-2" />
				</div>
			)}
			{!mobile && !viewOnly && (
				<div className="pl-8 ml:pl-12">
					<CounterFilter
						updateFilters={() =>
							dispatch(
								setSearchParams({
									searchId: currentSearchId,
									softFilters: { onlyNew: !currentSearch.softFilters.onlyNew },
									override: { hardFilters: false, softFilters: false },
								})
							)
						}
						enabled={!!currentSearch.softFilters?.onlyNew}
					>
						<CounterField label="New" count={newLoads ?? 0} hiddenCount={0} />
					</CounterFilter>
				</div>
			)}
			{!mobile && !viewOnly && (
				<div className="flex flex-row items-center">
					{getIconButton("done", "Acknowledge New", newLoads === 0, "flex", null, null, smallScreen, () => {
						const searchId = currentSearchId;
						dispatch(
							acknowledgeNew({
								searchId: searchId,
							})
						);
						saveViewedSearchVersions(userId, searchId, currentSearch?.stats?.versions?.last).then(
							(data) => {},
							(errors) => {
								console.error(`[SearchStatusPanel] POST: failed to acknoledged viewed loads`, errors);
							}
						);
					})}
					<div className="w-1 h-20 bg-grey-300 mr-8 ml:mr-12 -ml-4" />
				</div>
			)}
			{!viewOnly && !nativeMobile && (
				<div className="flex flex-row items-center">
					{getIconButton(
						"event_note",
						"Truck Plan",
						filters?.truck?.length !== 24,
						"text-grey-900",
						null,
						false,
						smallScreen,
						() => {
							dispatch(
								openFormDialog({
									viewId: "TRUCK_PLAN_VIEW",
									dataIds: {
										truckId: filters?.truck,
										carrierId: filters?.carrier,
									},
								})
							);
						}
					)}
					<div className="w-1 h-20 bg-grey-300 mr-8 ml:mr-12 ml-6 ml:-ml-8" />
				</div>
			)}
			{!nativeMobile && !mobile && (
				<div className="flex flex-row items-center">
					{getIconButton("assessment", "Market Analytics", false, "text-grey-900", null, false, smallScreen, () => {
						dispatch(
							openFormDialog({
								viewId: "LOADS_SEARCH_MARKET_ANALYSIS_VIEW",
								dataIds: { searchId: currentSearchId },
							})
						);
					})}
					<div className="w-1 h-20 bg-grey-300 mr-12 -ml-8" />
				</div>
			)}
			{!nativeMobile &&
				!viewOnly &&
				!mobile &&
				(!viewerIsCustomer || viewerHasFullCarrierAccess) &&
				!filters?.truck?.includes("ANY_") && (
					<div className="flex flex-row items-center">
						{getIconButton(
							"textsms",
							"Dispatching Channel",
							false,
							"text-primary",
							unreadCount["TRUCK_EXTERNAL"] ? "1+" : null,
							false,
							smallScreen,
							() => {
								dispatch(
									openFormDialog({
										viewId: "CHAT_APP_PREVIEW_TRUCK_EXTERNAL",
										dataIds: { truckId: filters?.truck, carrierId: filters?.carrier },
									})
								);
							}
						)}
					</div>
				)}
		</div>
	);

	const searchStatus = filters && (
		<>
			{currentSearchState && !nativeMobile && !smallScreen && !mobile && (
				<Typography color="primary" className="p-4 flex">
					{!hasNetwort && currentSearchState
						? "Offline"
						: currentSearchState.includes("FAILED")
						? "Search Failed"
						: currentSearchState === "EXPIRED"
						? "Search Inactive"
						: "Waiting for New"}
				</Typography>
			)}
			{currentSearchState && !mobile && (
				<div
					className={
						((!hasNetwort && currentSearchState) ||
						currentSearchState === "EXPIRED" ||
						currentSearchState.includes("FAILED")
							? ` text-red `
							: `${flicker} ${withAnimation} text-green `) + ` pt-4`
					}
				>
					<Icon className={"ml-10 ml:ml-2 text-18"}>
						{!hasNetwort && currentSearchState
							? "cloud_off"
							: currentSearchState === "EXPIRED" || currentSearchState.includes("FAILED")
							? "stop_circle"
							: "cached"}
					</Icon>
				</div>
			)}
		</>
	);

	const addLoadButton = getIconButton(
		"add_circle_outlined",
		"Add Test Loads",
		false,
		null,
		null,
		null,
		smallScreen,
		() => {
			dispatch(
				replaceFormDialog({
					viewId: "INTERNAL_LOAD_VIEW",
					mode: "CREATE",
					dataIds: {
						location_origin__view: filters?.location_origin__view,
						equipment: filters?.equipment,
						pickup_date: filters?.pickup_ds__after,
						delivery_date: moment(filters?.pickup_ds__after).add(1, "day").format("YYYY-MM-DD"),
						searchId: currentSearchId,
						userId: userId,
					},
				})
			);
		}
	);

	const actionButtons = filters && (
		<div className={"flex flex-row items-center md:flex-1 justify-end pr-16 "}>
			{getWarningsTooltip(currentSearch?.pipelineErrors, currentSearch?.pipelineWarnings, smallScreen)}
			{!viewerIsCustomer && addLoadButton}
			{searchStatus}
		</div>
	);

	//Only show for tabs with search
	if (currentSearchId === "EMPTY") return null;

	return (
		<div className={`flex bg-white flex-col w-full divide-y px-16 ml:px-6 ${mobile ? "pt-16" : ""}`}>
			{mobile && (
				<SearchMobileSort
					onChange={(value) => {
						dispatch(
							setSearchParams({
								searchId: currentSearchId,
								sortConfig: [{ id: value, desc: value !== "updated" }],
							})
						);
					}}
					value={currentSearch?.sortConfig?.[0]?.id ?? "updated"}
				/>
			)}
			<div className={"flex flex-row w-full " + (mobile ? " h-60 " : smallScreen ? " h-40 " : " h-44 ")}>
				{countersIcons}
				{!mobile && actionButtons}
			</div>
			<div className="flex w-full h-1"></div>
		</div>
	);
}

export default SearchStatusBar;
