import { useState, useEffect } from "react";

const useMobileDetect = () => {
	const [mobile, setMobile] = useState(window.innerWidth <= 960);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 10) * 10);

	useEffect(() => {
		function handleResize() {
			let isNewWidthMobile = window.innerWidth <= 960;
			if (isNewWidthMobile !== mobile) setMobile(isNewWidthMobile);

			let innerHeight = window.innerHeight;
			innerHeight = parseInt(innerHeight / 10) * 10;
			if (innerHeight !== screenHeight) setScreenHeight(innerHeight);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [mobile, screenHeight]);

	return mobile;
};

export default useMobileDetect;
