const smarthopDark = {
	50: "#EBEBEB",
	100: "#D0D5DA",
	200: "#818E9F",
	300: "#546985",
	400: "#243F60",
	500: "#062246",
	600: "#041A36",
	700: "#031730",
	800: "#021227",
	900: "#000A18",
	A100: "#66A9FF",
	A200: "#4496FF",
	A400: "#127AFF",
	A700: "#0063E2",
	contrastDefaultColor: "light",
};

export default smarthopDark;
