import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { SmarthopList, carrierPaymentMethodList, carrierPlatformPlansAddonsList } from "@smarthop/list";
import carrierDiscountsList from "@smarthop/list/configs/carrierDiscountsList";

// Components and pages
import PageSection from "../../common/PageSection";
import BillingPagePlanTab from "./BillingPagePlanTab";
import PlaidLink from "./plaid/PlaidLink";

// Services
import {
	removeSmartpayPayoutAccount,
	removeAchAccount,
	addTestAchAccount,
	addTestPayoutAccount,
} from "app/services/billingServices";
import {
	getPlaidLinkToken,
	itemLoginRequiredHandler,
	itemLoginRequiredAchAccountHandler,
} from "app/services/billingServices";
import { getCarrierCoupon } from "app/services/couponServices";
import { getCarrierCustomer } from "app/services/carrierServices";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Tools
import FormSection from "app/main/tools/FormSection";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Consts
import { authRoles } from "app/main/consts";

function BillingView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const paymentRevision = useSelector(({ tools }) => tools.revision?.paymentRevision);
	const user = useSelector(({ auth }) => auth.user);
	const isAdmin = user?.rolesLabels?.includes("administrator");

	const [payoutAccount, setPayoutAccount] = useState();
	const [smartFuelAccount, setSmartFuelAccount] = useState();
	const [hasSmartPayProgram, setHasSmartPayProgram] = useState(false);
	const [hasSmartFuelProgram, setHasSmartFuelProgram] = useState(false);
	const [carrierCustomer, setCarrierCustomer] = useState();
	const [hasAddons, setHasAddons] = useState(false);
	const [hasDiscount, setHasDiscount] = useState(false);
	const [token, setToken] = useState();
	const [isSmartpayPayoutAccount, setIsSmartpayPayoutAccount] = useState(false);
	const [isSmartFuelAccount, setIsSmartFuelAccount] = useState(false);
	const [isReauthentication, setIsReauthentication] = useState(false);

	const carrierId = props.carrierId;
	const variant = props.variant ?? "narrow";

	const addCCAction = {
		action: "add_cc",
		label: "Add Credit Card",
		auth: [...authRoles.admin, ...authRoles.carrierPowerUsers],
		dialog: { viewId: "CREDIT_CARD", dataIds: { carrierId } },
	};

	const addAddonAction = {
		action: "add_addon",
		label: "Add Add-on",
		auth: [...authRoles.admin, ...authRoles.carrierPowerUsers],
		dialog: { viewId: "ADD_PLAN_ADDON", dataIds: { carrierId } },
	};

	const applyCouponAction = {
		action: "apply_coupon",
		label: "Apply coupon",
		dialog: { formId: "CARRIER_APPLY_COUPON_FORM", mode: "CREATE", dataIds: { carrierId } },
		auth: [...authRoles.admin],
	};

	const paymentMethodsActions = [addCCAction];
	const addonsActions = isAdmin ? [addAddonAction] : [];
	const discountsActions = isAdmin ? [applyCouponAction] : [];

	useEffect(() => {
		(async () => {
			const carrierCustomerData = await getCarrierCustomer(carrierId);

			setPayoutAccount(carrierCustomerData?.smartpay?.payoutSource);
			setSmartFuelAccount(carrierCustomerData?.smartfuel?.account);
			setHasSmartPayProgram(carrierCustomerData?.hasSmartPayProgram);
			setHasSmartFuelProgram(carrierCustomerData?.onboardingFuelModule === "ENABLED");
			setCarrierCustomer(carrierCustomerData);

			const addons = carrierCustomerData?.customer_data?.payment_plans?.addons ?? [];
			const activeAddons = addons.filter((addon) => !addon.removed);
			setHasAddons(activeAddons.length > 0);

			const carrierCoupon = await getCarrierCoupon(carrierId);
			setHasDiscount(!!carrierCoupon);
		})();
	}, [carrierId, paymentRevision]);

	// Remove ACH Methods/Actions
	const deleteAchAccount = async (isSmartPay) => {
		try {
			await (isSmartPay ? removeSmartpayPayoutAccount(carrierId) : removeAchAccount(carrierId));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			showSnackbar(snackbar, isSmartPay ? `Payout Account deleted` : `ACH Account deleted`, "success");
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message, "error", 10000);
		}
	};

	// Add TEST Payout accoun
	const handleAddTestAccount = async (isSmartPay) => {
		try {
			await (isSmartPay ? addTestPayoutAccount(carrierId) : addTestAchAccount(carrierId));
			dispatch(incrementDataRevision({ event: "paymentRevision" }));
			showSnackbar(snackbar, isSmartPay ? `Test Payout Account created` : `Test ACH account created`, "success");
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message, "error", 10000);
		}
	};

	// Add ACH Methods/Actions
	const handleAddAch = async ({
		accessToken,
		isSmartpayPayoutAccountHandler,
		isSmartFuelAccountHandler,
		isReauthenticationHandler,
	}) => {
		try {
			setIsSmartpayPayoutAccount();
			setIsSmartFuelAccount();
			setToken();
			const data = { accessToken };
			const linkToken = await getPlaidLinkToken(carrierId, data);
			if (!linkToken) {
				showSnackbar(snackbar, "There was an error creating token plaid", "error", 5000);
			}

			if (isSmartpayPayoutAccountHandler) setIsSmartpayPayoutAccount(isSmartpayPayoutAccountHandler);
			if (isSmartFuelAccountHandler) setIsSmartFuelAccount(isSmartFuelAccountHandler);
			if (isReauthenticationHandler) setIsReauthentication(isReauthenticationHandler);
			setToken(linkToken);
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message, "error", 10000);
		}
	};

	const forceReauthenticationHandler = async (isSmartPay) => {
		try {
			await (isSmartPay ? itemLoginRequiredHandler(carrierId) : itemLoginRequiredAchAccountHandler(carrierId));
			showSnackbar(snackbar, `Re-authentication forced`, "success");
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0]?.message, "error", 10000);
		}
		dispatch(incrementDataRevision({ event: "paymentRevision" }));
	};

	const ACTIONS = {
		remove: (isSmartPay) => ({
			action: {
				confirmation: {
					message: "Are you sure you want to remove this account?",
					onAccept: () => deleteAchAccount(isSmartPay),
				},
			},
			label: "Delete",
			auth: ["administrator", "carrier", "carrier_manager"],
		}),
		addTest: (isSmartPay) => ({
			action: { onClick: () => handleAddTestAccount(isSmartPay) },
			label: "Add Test Account",
			auth: ["administrator", "carrier", "carrier_manager"],
		}),
		add: (isSmartPay) => ({
			action: {
				onClick: () =>
					handleAddAch({ isSmartpayPayoutAccountHandler: isSmartPay, isSmartFuelAccountHandler: !isSmartPay }),
			},
			label: "Add",
			auth: ["carrier", "carrier_manager"],
		}),
		view: {
			action: {
				onClick: () => {
					dispatch(
						openLoadedFormDialog({
							formId: "ADMIN_SMARTFUEL_ACCOUNT_FORM",
							mode: "VIEW",
							dataIds: { carrierId },
						})
					);
				},
			},
			label: "View Details",
			auth: ["administrator"],
		},
		reauthentication: (isSmartPay) => ({
			action: {
				onClick: () =>
					handleAddAch({
						accessToken: payoutAccount?.access_token,
						isSmartpayPayoutAccountHandler: isSmartPay,
						isSmartFuelAccountHandler: !isSmartPay,
						isReauthenticationHandler: true,
					}),
			},
			label: "Re-authenticate",
			auth: ["carrier", "carrier_manager"],
		}),
		forceReauthentication: (isSmartPay) => ({
			action: {
				onClick: () => forceReauthenticationHandler(isSmartPay),
			},
			label: "Force Re-authentication Error",
			auth: ["administrator"],
		}),
	};

	return (
		<>
			<PageSection variant={variant} title="Billing Plans" classes={{ root: " min-h-auto " }}>
				<BillingPagePlanTab carrierId={carrierId} />
			</PageSection>
			{hasSmartPayProgram && (
				<PageSection variant={variant} title="SmartPay Payout" classes={{ root: " min-h-auto " }}>
					<div className="grid grid-cols-1 md:gap-x-20 md:grid-cols-2 w-full">
						<div>
							<FormSection
								formId={"CARRIER_SMARTPAY_PAYOUT_ACCOUNT_FORM"}
								dataIds={{ carrierId }}
								actions={[
									...(payoutAccount
										? [
												ACTIONS.remove(true),
												...(payoutAccount.access_token !== "SMARTPAY_TEST_ACCESS_TOKEN"
													? [ACTIONS.reauthentication(true)]
													: []),
												...(process.env.REACT_APP_ENV === "DEV" ? [ACTIONS.forceReauthentication(true)] : []),
										  ]
										: [...(carrierCustomer?.isTestCarrier ? [ACTIONS.addTest(true)] : [ACTIONS.add(true)])]),
								]}
							/>
						</div>
						<FormSection title={"Payout Plan"} formId={"CARRIER_SMARTPAY_PAYOUT_PLAN_FORM"} dataIds={{ carrierId }} />
					</div>
				</PageSection>
			)}
			{hasSmartFuelProgram && (
				<PageSection variant={variant} title="SmartFuel" classes={{ root: " min-h-auto " }}>
					<div className="grid grid-cols-1 md:gap-x-20 md:grid-cols-2 w-full">
						<div>
							<FormSection
								title={"ACH Account"}
								formId={"CARRIER_SMARTFUEL_ACCOUNT_FORM"}
								actions={[
									...(smartFuelAccount
										? [
												ACTIONS.remove(),
												...(isAdmin ? [ACTIONS.view] : []),
												...(smartFuelAccount.access_token !== "SMARTFUEL_TEST_ACCESS_TOKEN"
													? [ACTIONS.reauthentication()]
													: []),
												...(process.env.REACT_APP_ENV === "DEV" ? [ACTIONS.forceReauthentication()] : []),
										  ]
										: [...(carrierCustomer?.isTestCarrier ? [ACTIONS.addTest()] : [ACTIONS.add()])]),
								]}
								dataIds={{ carrierId }}
							/>
						</div>
					</div>
				</PageSection>
			)}
			{(hasAddons || isAdmin) && (
				<PageSection variant={variant} title="Add-ons" titleActions={addonsActions} classes={{ root: " min-h-auto " }}>
					<SmarthopList
						key={"addons"}
						mode="table"
						config={carrierPlatformPlansAddonsList({ isAdmin })}
						dataIds={{ carrierId }}
						isSection
					/>
				</PageSection>
			)}
			{(isAdmin || hasDiscount) && (
				<PageSection
					variant={variant}
					title="Discounts"
					titleActions={discountsActions}
					classes={{ root: " min-h-auto " }}
				>
					<SmarthopList
						key={"discounts"}
						mode="table"
						config={carrierDiscountsList({ isInternal: false, isAdmin })}
						dataIds={{ carrierId }}
						isSection
					/>
				</PageSection>
			)}
			<PageSection
				variant={variant}
				title="All Payment Methods"
				titleActions={paymentMethodsActions}
				classes={{ root: " min-h-auto " }}
			>
				<SmarthopList
					key={"payment_methods"}
					mode="table"
					config={carrierPaymentMethodList(isAdmin)}
					dataIds={{ carrierId }}
					isSection
				/>
			</PageSection>

			{token && (
				<PlaidLink
					token={token}
					carrierId={carrierId}
					isSmartpayPayoutAccount={isSmartpayPayoutAccount}
					isSmartFuelAccount={isSmartFuelAccount}
					isReauthentication={isReauthentication}
				/>
			)}
		</>
	);
}

export default BillingView;
