import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ActionButton from "./ActionButton";
import { parseRolesInfo } from "../utils/rolesUtils";

function PageSubtitle(props) {
	const user = useSelector(({ auth }) => auth.user);

	let buttons = [];
	props.actions?.forEach((item, i) => {
		const { visible } = parseRolesInfo(item.auth, user);
		if (visible) {
			if (item.component) {
				buttons.push(<div key={i}>{item.component}</div>);
			} else {
				buttons.push(
					<ActionButton
						key={i}
						action={item.action}
						label={item.label}
						dialog={item.dialog}
						classes={item.classes}
						onClick={item.onClick}
						disabled={item.disabled}
					/>
				);
			}
		}
	});

	return (
		<motion.div
			className={"divide-y-1 w-full " + (props.className ?? "")}
			initial={{ opacity: 0, x: -40 }}
			animate={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
		>
			<div className="pt-20" />
			<div className="flex flex-row px-4 pt-10 pb-14">
				<Typography
					className="text-20 ml:text-24 font-normal flex-1  tracking-tight pt-20 pb-12"
					variant="h4"
					color="inherit"
				>
					{props.label}
				</Typography>
				<div className="flex items-center justify-end pt-10">
					{props.children}
					{buttons}
				</div>
			</div>
		</motion.div>
	);
}

export default PageSubtitle;
