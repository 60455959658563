import _ from "@lodash";

import { global } from "app/services/requestUtil";
import axios from "axios";
import uniqid from "uniqid";
import { createSlice } from "@reduxjs/toolkit";
import { NetwrokError } from "errors";
import { buildForm } from "@smarthop/form/registy";

/**
 *
 * @param {Array} renderDialogStack
 * @param {String} dialogId
 * @returns Updated render dialog stack
 */
const removeDialogFromRenderStack = (renderDialogStack, dialogId) => {
	if (!dialogId) return renderDialogStack;
	return renderDialogStack.filter((dialog) => dialog.dialogId !== dialogId);
};

/**
 *
 * @param {Array} renderDialogStack
 * @param {Array} dialogIds
 * @returns Updated render dialog stack
 */
const removeDialogsFromRenderStack = (renderDialogStack, dialogIds) => {
	return renderDialogStack.filter((dialog) => !dialogIds.includes(dialog.dialogId));
};

/**
 *
 * @param {Array} renderDialogStack
 * @param {String} dialogId
 * @param {Object} updatedDialog
 * @returns Updated render dialog stack
 */
const updateDialogFromRenderStack = (renderDialogStack, dialogId, updatedDialog) => {
	if (!dialogId) return renderDialogStack;
	return renderDialogStack.map((dialog) => {
		if (dialog.dialogId === dialogId) {
			return updatedDialog;
		}
		return dialog;
	});
};

/**
 * Dispatch an event to open a FormDialog
 * @param {*} formInstance Dynamic forms can provide a formInstance instead of formId
 * @returns
 */
export const openLoadedFormDialog =
	({ viewId, formId, formInstance, dataIds, dataOverrides, mode }) =>
	async (dispatch) => {
		if (viewId) {
			dispatch(openFormDialog({ viewId: viewId, dataIds: dataIds, mode }));
			return;
		}

		if ((!formId && !formInstance) || !dataIds || !mode) {
			console.error(`[formDialogSlice] Can't open dialog, missing mandatory params`, mode);
			return;
		}

		if (mode === "CREATE") {
			dispatch(openFormDialog({ formId, formInstance, dataIds, dataOverrides, mode, data: dataIds?.data ?? {} }));
			return;
		}

		if (dataIds?.historyData) {
			dispatch(
				openFormDialog({
					formId,
					formInstance,
					dataIds,
					dataOverrides,
					mode,
					data: dataIds?.historyData,
				})
			);
			return;
		}

		// We don't need the actual form shape here
		// We use this to validate form url and required data ids only
		const form = formInstance ?? (formId ? buildForm(formId, {}) : undefined);
		if (!form) {
			console.error(`[formDialogSlice] Can't find form ${formId} in registry dialog`);
			return;
		}

		let queryMissingParams = "";
		form?.urlGET?.split("/").forEach((section) => {
			if (section.startsWith(":") && (!dataIds || !dataIds[section.substring(1)])) {
				if (queryMissingParams) queryMissingParams += ",";
				queryMissingParams += section.substring(1);
			}
		});

		if (queryMissingParams) {
			let error = new Error(`Form GET url '${form?.urlGET}' required mandatory params [${queryMissingParams}]`);
			console.error(`[formDialogSlice] GET: url '${form?.urlGET}' required mandatory params [${queryMissingParams}]`);
			dispatch(
				openFormDialog({
					formInstance,
					formId: formId,
					dataIds: dataIds,
					dataOverrides: dataOverrides,
					mode: mode,
					error: error,
				})
			);
			return;
		}

		let urlGET = form?.urlGET;
		Object.keys(dataIds).forEach((key) => {
			urlGET = urlGET?.replace(":" + key, dataIds[key]);
		});

		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		};

		return new Promise((resolve, reject) => {
			axios
				.create({ baseURL: global.SERVER_NAME, headers: headers })
				.get(urlGET, { headers: headers })
				.then((res) => {
					if (!_.isEmpty(res.data)) {
						console.log(`[formDialogSlice] GET: loaded entity, dataId=${JSON.stringify(dataIds)}`);
						dispatch(
							openFormDialog({
								formId: formId,
								formInstance,
								dataIds: dataIds,
								dataOverrides: dataOverrides,
								data: res.data,
								mode: mode,
							})
						);
						resolve(res.data);
					} else {
						let error = new Error(`Loaded empty entity from url '${urlGET}'`);
						console.error(`[formDialogSlice] GET: loaded empty entity, url=${urlGET}`);
						dispatch(
							openFormDialog({
								formId: formId,
								formInstance,
								dataIds: dataIds,
								dataOverrides: dataOverrides,
								mode: mode,
								error: error,
							})
						);
						resolve(error);
					}
				})
				.catch((error) => {
					let res = error?.response;
					let netwrokError = res?.data?.errors?.[0]?.message
						? new Error(res?.data?.errors?.[0]?.message)
						: new NetwrokError(`Failed to load entity: '${urlGET}'`, res?.status, res?.data);
					console.error(`[formDialogSlice] GET: failed to load, url=${urlGET}, data=${res?.status}, data=`, res?.data);
					dispatch(
						openFormDialog({
							formId: formId,
							formInstance,
							dataIds: dataIds,
							dataOverrides: dataOverrides,
							mode: mode,
							error: netwrokError,
						})
					);
					resolve(netwrokError);
				});
		});
	};

const formDialogSlice = createSlice({
	name: "formDialog",
	initialState: {
		renderDialogStack: [],
		formStack: [],
		minimizedStacks: [],
	},
	reducers: {
		openFormDialog: (state, action) => {
			if (!action.payload?.formId && !action.payload?.formInstance && !action.payload?.viewId) {
				return;
			}

			const dialogId = action.payload?.dialogId ?? uniqid();

			state.formStack = [
				...state.formStack,
				{
					viewId: action.payload?.viewId,
					formId: action.payload?.formId,
					formInstance: action.payload?.formInstance,
					dataIds: action.payload?.dataIds,
					mode: action.payload?.mode,
					data: action.payload?.data,
					dataOverrides: action.payload?.dataOverrides,
					titleOverrides: action.payload?.titleOverrides,
					error: action.payload?.error,
					size: "NORMAL",
					dialogId,
				},
			];

			state.renderDialogStack = [
				...state.renderDialogStack,
				{
					...action.payload,
					size: "NORMAL",
					dialogId,
				},
			];
		},
		setFormDialogSize: (state, action) => {
			const headIndex = state.formStack?.length - 1;
			if (headIndex >= 0) {
				// Updating size
				const headForm = { ...state.formStack[headIndex] };
				headForm.size = action.payload;
				// Overriding stack values
				state.formStack = [...state.formStack];
				state.formStack[headIndex] = headForm;
				state.renderDialogStack = updateDialogFromRenderStack(state.renderDialogStack, headForm.dialogId, headForm);
			}
		},
		replaceFormDialog: (state, action) => {
			const removedDialog = state.formStack.pop();
			state.renderDialogStack = removeDialogFromRenderStack(state.renderDialogStack, removedDialog?.dialogId);
			if (!action.payload?.formId && !action.payload?.viewId) {
				return;
			}

			const dialogId = action.payload?.dialogId ?? uniqid();

			state.formStack = [
				...state.formStack,
				{
					viewId: action.payload?.viewId,
					formId: action.payload?.formId,
					formInstance: action.payload?.formInstance,
					dataIds: action.payload?.dataIds,
					mode: action.payload?.mode,
					data: action.payload?.data,
					dataOverrides: action.payload?.dataOverrides,
					titleOverrides: action.payload?.titleOverrides,
					error: action.payload?.error,
					size: "NORMAL",
					dialogId,
				},
			];
			state.renderDialogStack = [
				...state.renderDialogStack,
				{
					...action.payload,
					size: "NORMAL",
					dialogId,
				},
			];
		},
		raiseDialog: (state, action) => {
			const dialogId = action.payload;
			if (!dialogId) return;
			// Find minimized stack
			const minimizedStackIndex = state.minimizedStacks.find((stack) => stack[stack.length - 1]?.dialogId === dialogId);
			if (minimizedStackIndex === -1) return;
			const minimizedStack = state.minimizedStacks.splice(minimizedStackIndex, 1)[0];
			minimizedStack[minimizedStack.length - 1].size = "NORMAL";

			// Minimize current stack
			state.minimizedStacks.push(state.formStack);

			// Maximize found stack
			state.formStack = minimizedStack;
		},
		updateFormDialog: (state, action) => {
			const { dialogId, updateData } = action?.payload ?? {};

			state.formStack = state.formStack.map((dialogItem) => {
				if (dialogItem.dialogId === dialogId) {
					const updatedDialog = {
						...dialogItem,
						...updateData,
						dataIds: {
							...dialogItem.dataIds,
							...updateData.dataIds,
						},
					};
					state.renderDialogStack = updateDialogFromRenderStack(state.renderDialogStack, dialogId, updatedDialog);
					return updatedDialog;
				}
				return dialogItem;
			});

			state.minimizedStacks = state.minimizedStacks.map((minimizedStack) =>
				minimizedStack.map((dialogItem) => {
					if (dialogItem.dialogId === dialogId) {
						const updatedDialog = {
							...dialogItem,
							...updateData,
							dataIds: {
								...dialogItem.dataIds,
								...updateData.dataIds,
							},
						};
						state.renderDialogStack = updateDialogFromRenderStack(state.renderDialogStack, dialogId, updatedDialog);
						return updatedDialog;
					}
					return dialogItem;
				})
			);
		},
		updateTopmostDialog: (state, action) => {
			const topmostDialog = state.formStack.pop();
			const newTopDialog = { ...topmostDialog, ...action.payload };
			state.renderDialogStack = updateDialogFromRenderStack(
				state.renderDialogStack,
				topmostDialog?.dialogId,
				newTopDialog
			);
			state.formStack = [...state.formStack, newTopDialog];
		},
		closeFormDialog: (state, _) => {
			const removedDialog = state.formStack.pop();
			state.renderDialogStack = removeDialogFromRenderStack(state.renderDialogStack, removedDialog?.dialogId);
		},
		closeAllDialogs: (state, _) => {
			const removedDialogIds = state.formStack.map((dialog) => dialog?.dialogId);
			state.renderDialogStack = removeDialogsFromRenderStack(state.renderDialogStack, removedDialogIds);
			state.formStack = [];
		},
		// added to refresh the btns at the top of the dialog
		refreshDialog: (state, _) => {
			const popped = state.formStack.pop();
			if (!popped) return state;
			state.formStack = [...state.formStack, popped];
			state.renderDialogStack = removeDialogFromRenderStack(state.renderDialogStack, popped?.dialogId);
			state.renderDialogStack = [...state.renderDialogStack, popped];
		},
		minimizeStack: (state, action) => {
			if (!state.formStack.length) return;

			// Updating size
			const headIndex = state.formStack?.length - 1;
			const headForm = { ...state.formStack[headIndex] };
			headForm.size = "MINIMIZED";

			state.minimizedStacks.push(state.formStack);
			state.formStack = [];
		},
		maximizeStack: (state, action) => {
			const stackIndex = action.payload;

			if (state.formStack.length) {
				state.minimizedStacks.push(state.formStack);
			}

			const minimizedStack = state.minimizedStacks.splice(stackIndex, 1).pop();
			state.formStack = minimizedStack;

			const headIndex = state.formStack?.length - 1;
			// Updating size
			const headForm = { ...state.formStack[headIndex] };
			headForm.size = "NORMAL";
			// Overriding stack values
			state.formStack = [...state.formStack];
			state.formStack[headIndex] = headForm;
		},
		closeMinimizedStack: (state, action) => {
			const stackIndex = action.payload;
			const minimizedStack = state.minimizedStacks.splice(stackIndex, 1)[0];
			const removedDialogIds = minimizedStack.map((dialog) => dialog.dialogId);
			state.renderDialogStack = removeDialogsFromRenderStack(state.renderDialogStack, removedDialogIds);
		},
	},
});

export const {
	openFormDialog,
	raiseDialog,
	updateFormDialog,
	updateTopmostDialog,
	closeFormDialog,
	refreshDialog,
	setFormDialogSize,
	replaceFormDialog,
	closeAllDialogs,
	minimizeStack,
	maximizeStack,
	closeMinimizedStack,
} = formDialogSlice.actions;

export default formDialogSlice.reducer;
