import _ from "@lodash";
import React, { memo } from "react";
import TripMap from "./TripMap";
import TripMapTrimble from "./TripMapTrimble";
import MarketConditionsMap from "app/main/market-conditions/MarketConditionsMap";
import RecommendedStrtegyMap from "./RecommendedStrtegyMap";

import { useState, useEffect } from "react";

const SmartHopMap = memo(
	(props) => {
		const classes = props.classes;
		const provider = props.provider;
		const activeHeatMap = props.activeHeatMap;
		const plan = props.plan;
		const map = props?.map;
		const loadId = props.loadId;
		const carrierId = props.carrierId;
		const showFuelLocationsButton = props.showFuelLocationsButton;
		const truckLocation = props.truckLocation;
		const weight = props.weight;
		const isTrip = props.isTrip;
		const equipment = props.equipment;
		const step = props.step;
		const inverse = props.inverse;
		const showProfit = props.showProfit;
		const showSmooth = props.showSmooth;
		const toplanes = props.toplanes;
		const miles = props.miles;
		const origin = props.origin;
		const destination = props.destination;
		const onProgress = props.onProgress;
		const recommendedStrtegy = props.recommendedStrtegy;
		const listPolyLine = props.listPolyLine;
		const highlightMarkets = props.highlightMarkets;

		const [locations, setLocations] = useState(props.locations ?? []);
		const [routes, setRoutes] = useState(props.routes ?? []);

		useEffect(() => {
			const findChanged = (newLocations, existingLocations) => {
				if ((newLocations?.length ?? 0) !== (existingLocations?.length ?? 0)) {
					return true;
				}

				let found = false;
				for (const i in newLocations) {
					found =
						newLocations[i].location !== existingLocations[i].location ||
						// Intentionally convert to int to avoid map re-rendering in case of small location changes
						parseInt(newLocations[i].lng) !== parseInt(existingLocations[i].lng) ||
						parseInt(newLocations[i].lat) !== parseInt(existingLocations[i].lat);
					if (found) return found;
				}
				return found;
			};

			if (props.routes?.length > 0 || routes.length > 0) {
				const foundChanges = !!props.routes.find((route, index) =>
					findChanged(route?.locations ?? [], routes?.[index]?.locations ?? [])
				);
				if (foundChanges || props.routes?.length !== routes.length) {
					console.log("[SmartHopMap] re-rendering map, route changes", foundChanges, props.routes, routes);
					setRoutes(props.routes);
				}
			} else {
				const foundChanges = findChanged(props.locations ?? [], locations ?? []);
				if (foundChanges) {
					console.log("[SmartHopMap] re-rendering map, location changes", props.locations, locations);
					setLocations(props.locations);
				}
			}

			// eslint-disable-next-line
		}, [props.locations, props.routes]);

		if (activeHeatMap) {
			return (
				<>
					{provider === "TRIMBLE MAPS" ? (
						<TripMapTrimble
							classes={classes}
							locations={locations}
							routes={routes}
							loadId={loadId}
							carrierId={carrierId}
							showFuelLocationsButton={showFuelLocationsButton}
							map={map}
							activeHeatMap={activeHeatMap}
							equipment={equipment}
							step={step}
							inverse={inverse}
							showProfit={showProfit}
							showSmooth={showSmooth}
							toplanes={toplanes}
							highlightMarkets={highlightMarkets}
							zoom={props.zoom}
						/>
					) : (
						<MarketConditionsMap
							classes={classes}
							data={{
								locations: locations,
								hasLatLong: true,
								defaultZoom: miles > 500 ? 3 : miles > 200 ? 4 : miles > 100 ? 5 : 4.5,
								activeLocations: true,
								origin: origin,
								destination: destination,
								activeHeatMap: activeHeatMap,
								equipment: equipment,
								highlightMarkets: highlightMarkets,
							}}
							onProgress={onProgress}
							showProfit={showProfit}
							showSmooth={showSmooth}
							step={step}
							inverse={inverse}
							toplanes={toplanes}
						/>
					)}
				</>
			);
		}

		if (recommendedStrtegy) {
			return (
				<>
					{provider === "TRIMBLE MAPS" ? (
						<TripMapTrimble
							classes={classes}
							locations={locations}
							routes={routes}
							carrierId={carrierId}
							showFuelLocationsButton={showFuelLocationsButton}
							loadId={loadId}
							map={map}
							truckLocation={truckLocation}
						/>
					) : (
						<RecommendedStrtegyMap
							truckPosition={truckLocation}
							listCoordinates={locations}
							listPolyLine={listPolyLine}
							classes={classes}
						/>
					)}
				</>
			);
		}

		return (
			<>
				{provider === "TRIMBLE MAPS" ? (
					<TripMapTrimble
						classes={classes}
						locations={locations}
						routes={routes}
						loadId={loadId}
						carrierId={carrierId}
						showFuelLocationsButton={showFuelLocationsButton}
						plan={plan}
						map={map}
						truckLocation={truckLocation}
						isTrip={isTrip}
						zoom={props.zoom}
					/>
				) : (
					<TripMap
						classes={classes}
						load={{
							all_locations: locations,
							load_id: loadId,
							weight: weight,
						}}
					/>
				)}
			</>
		);
	},
	(prevProps, nextProps) => {
		const locations = _.isEqual(prevProps?.locations, nextProps?.locations);
		const routes = _.isEqual(prevProps?.routes, nextProps?.routes);
		const classes = JSON.stringify(prevProps.classes) === JSON.stringify(nextProps.classes);
		const provider = prevProps.provider === nextProps.provider;
		const activeHeatMap = prevProps.activeHeatMap === nextProps.activeHeatMap;
		const plan = JSON.stringify(prevProps.plan) === JSON.stringify(nextProps.plan);
		const map = prevProps.map === nextProps.map;
		const loadId = prevProps.loadId === nextProps.loadId;
		const truckLocation = JSON.stringify(prevProps.truckLocation) === JSON.stringify(nextProps.truckLocation);
		const weight = prevProps.weight === nextProps.weight;
		const isTrip = prevProps.isTrip === nextProps.isTrip;
		const equipment = prevProps.equipment === nextProps.equipment;
		const step = prevProps.step === nextProps.step;
		const inverse = prevProps.inverse === nextProps.inverse;
		const showProfit = prevProps.showProfit === nextProps.showProfit;
		const showSmooth = prevProps.showSmooth === nextProps.showSmooth;
		const toplanes = prevProps.toplanes === nextProps.toplanes;
		const origin = JSON.stringify(prevProps.origin) === JSON.stringify(nextProps.origin);
		const destination = JSON.stringify(prevProps.destination) === JSON.stringify(nextProps.destination);
		const onProgress = JSON.stringify(prevProps.onProgress) === JSON.stringify(nextProps.onProgress);
		const recommendedStrtegy = prevProps.recommendedStrtegy === nextProps.recommendedStrtegy;
		const listPolyLine = JSON.stringify(prevProps.listPolyLine) === JSON.stringify(nextProps.listPolyLine);
		const noRefreshShowFuelLocationsButton = prevProps.showFuelLocationsButton === nextProps.showFuelLocationsButton;

		const noRefresh =
			locations &&
			routes &&
			classes &&
			provider &&
			activeHeatMap &&
			plan &&
			map &&
			loadId &&
			truckLocation &&
			weight &&
			isTrip &&
			equipment &&
			step &&
			inverse &&
			showProfit &&
			showSmooth &&
			toplanes &&
			origin &&
			destination &&
			onProgress &&
			recommendedStrtegy &&
			listPolyLine &&
			noRefreshShowFuelLocationsButton;

		return noRefresh;
	}
);

export default SmartHopMap;
