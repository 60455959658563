import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import { memo, useCallback, useEffect, useRef, useState } from "react";

function Countdown(props) {
	const { endDate, onComplete, timeClassName, unitClassName } = props;
	const intervalRef = useRef();

	const currDate = moment();
	const diff = endDate.diff(currDate, "seconds");
	const timeLeft = moment.duration(diff, "seconds");
	const [countdown, setCountdown] = useState({
		days: Math.floor(timeLeft.asDays()),
		hours: timeLeft.hours(),
		minutes: timeLeft.minutes(),
		seconds: timeLeft.seconds(),
		diff: diff,
	});

	const complete = useCallback(() => {
		window.clearInterval(intervalRef.current);
		onComplete?.();
	}, [onComplete]);

	const tick = useCallback(() => {
		const currDate = moment();
		let diff = endDate.diff(currDate, "seconds");
		if (diff < 0) {
			complete();
			setCountdown({
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
				diff: diff,
			});
			return;
		}

		const timeLeft = moment.duration(diff, "seconds");
		setCountdown({
			days: Math.floor(timeLeft.asDays()),
			hours: timeLeft.hours(),
			minutes: timeLeft.minutes(),
			seconds: timeLeft.seconds(),
			diff: diff,
		});
	}, [complete, endDate]);

	useEffect(() => {
		intervalRef.current = setInterval(tick, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [tick]);

	return (
		<div className={clsx("flex items-center h-20", props.className)}>
			{countdown.days > 0 && (
				<div className="flex flex-col items-center justify-center px-6">
					<Typography className={clsx(timeClassName)}>{countdown.days}</Typography>
					<Typography className={clsx("-mt-6", unitClassName)} variant="caption">
						days
					</Typography>
				</div>
			)}
			{countdown.hours > 0 && (
				<div className="flex flex-col items-center justify-center px-6">
					<Typography className={clsx(timeClassName)}>{countdown.hours}</Typography>
					<Typography className={clsx("-mt-6", unitClassName)} variant="caption">
						hours
					</Typography>
				</div>
			)}
			<div className="flex flex-col items-center justify-center px-6">
				<Typography className={clsx(timeClassName)}>{countdown.minutes}</Typography>
				<Typography className={clsx("-mt-6", unitClassName)} variant="caption">
					min
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-6">
				<Typography className={clsx(timeClassName)}>{countdown.seconds}</Typography>
				<Typography className={clsx("-mt-6 ", unitClassName)} variant="caption">
					sec
				</Typography>
			</div>
		</div>
	);
}

export default memo(Countdown);
