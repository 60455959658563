import { useParams } from "react-router-dom/cjs/react-router-dom";

/**
 * Resolves a param value from
 * props if we are in a view (View wrapped by a modal)
 * url if we are in a page
 * fallback if no such param exists in params
 * @param {*} props
 * @param {*} paramName
 * @param {*} fallback
 * @returns
 */
const useSHViewParam = (props, paramName, fallback) => {
	const urlParams = useParams();
	const fallbackValue = typeof fallback === "function" ? fallback() : fallback;
	return props.isView ? props[paramName] ?? props.params?.[paramName] : urlParams[paramName] ?? fallbackValue;
};

export default useSHViewParam;
