import React, { useEffect, useState } from "react";
import { getLongLink } from "app/services/linkServices";
import Typography from "@material-ui/core/Typography";
import { loadingViewComponent } from "../common/LoadingView";

const ShortLinkView = (props) => {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	let location = window.location.pathname;
	let id = location.split("/sh/")?.[1];

	const ErrorView = () => {
		return (
			<div className="flex flex-col justify-center items-center w-full h-full">
				<Typography className="text-16" color="error">
					{"Error obtaining Link"}
				</Typography>
			</div>
		);
	};

	useEffect(() => {
		getLongLink(id).then(
			(data) => {
				window.location.href = data;
			},
			(err) => {
				console.log(`[ShortLinkView] ${err?.errors?.[0]?.message}`);
				setLoading(false);
				setError(true);
			}
		);
	}, [id]);

	return (
		<>
			{(error || !id) && ErrorView()}
			{loading && loadingViewComponent("Redirect")}
		</>
	);
};

export default ShortLinkView;
