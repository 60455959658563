import {
	DRIVER_PAYROLL_FIELDS,
	INVESTOR_PAYROLL_FIELDS,
	DISPATCHER_PAYROLL_FIELDS,
} from "app/main/profile/drivers/EnterprisePayrollSection";

const userPayrollForm = ({ mode, isDriver, isInvestor, isDispatcher, isTrip, user }) => {
	const tripUrl = `billing/v3/carriers/:carrierId/trips/:tripId/payees/:payeeId/payroll-settings`;

	return {
		urlGET: (isTrip && tripUrl) || "billing/v3/carrier/:carrierId/user/:userId/payroll-settings",
		urlPUT: (isTrip && tripUrl) || "billing/v3/carrier/:carrierId/user/:userId/payroll-settings",
		idKey: "_id",
		triggerEvent: ["fuelRevision", "profileRevision"],
		listenEvent: "profileRevision",
		header: {
			edit: { label: "Edit Payroll Settings" },
			view: { label: "View Payroll Settings" },
		},
		messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
		content: {
			view: {
				sameAs: "edit",
			},
			edit: {
				items: [
					...(isDriver
						? DRIVER_PAYROLL_FIELDS({ user, mode })
						: isInvestor
						? INVESTOR_PAYROLL_FIELDS({ user, mode })
						: isDispatcher
						? DISPATCHER_PAYROLL_FIELDS({ mode })
						: []),
					{ type: "action", action: "SUBMIT", button: { alwaysEnabledIfValid: true } },
				],
			},
		},
	};
};

export default userPayrollForm;
