import { columns, groups, filters } from "app/main/profile/carriers/fuel/SmartfuelUtils.js";

const carrierFuelTransactionsList = ({ showCarrier }) => ({
	urlGET: `api/fuel/carriers/:carrierId/transactions`,
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision", "fuelRevision"],
	segmentKey: "SMARTFUEL_CARRIER_TRANSACTIONS",
	content: {
		table: {
			variant: "skinny",
			orderBy: [columns.TRANSACTION_DATE.key, columns.TRANSACTION_NUMBER.key, columns.USER.key, columns.LOCATION.key],
		},
		order: {
			defaut: {
				key: columns.TRANSACTION_DATE.key,
				dir: "desc",
			},
		},
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		export: {
			fileName: "SmartFuel Transactions",
		},
		filters: {
			search: true,
			items: [...(showCarrier ? [filters.CARRIER] : []), filters.TRANSACTION_DATE, filters.ASSIGMENT_STATUS],
		},
		groups: {
			items: [groups.PRODUCT_DETAILS, groups.PRICE_DETAILS, groups.PAYROLL_DETAILS],
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			dialogBuilder: (item) => ({
				viewId: "FUEL_TRANSACTION_VIEW",
				mode: "VIEW",
				dataIds: {
					transactionId: item._id,
					carrierId: item.carrier,
				},
			}),
		},
		items: [
			columns.TRANSACTION_DATE,
			{ type: "separator" },
			columns.TRANSACTION_NUMBER,
			{ type: "separator" },
			...(showCarrier ? [columns.CARRIER] : []),
			columns.USER,
			columns.INVESTOR,
			columns.TRUCK,
			{ type: "separator" },
			columns.LOCATION,
			columns.PRODUCT_TYPE,
			{ type: "separator" },
			columns.PAYROLL,
			{ type: "separator", group: "payroll_details" },
			columns.GALLONS,
			columns.PPG,
			columns.PPG_DISCOUNTED,
			columns.ACTUAL_COST,
			columns.DISCOUNT_TOTAL,
			{ type: "separator", table: { width: 8, sticky: true } },
			columns.TOTAL_AMOUNT,
		],
	},
});

export default carrierFuelTransactionsList;
