import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

//Services
import { invoiceCharge } from "app/services/billingServices";

const useStyles = makeStyles((theme) => ({
	progress: {
		position: "absolute",
		height: "auto!important",
		left: 0,
		top: "40%",
	},
}));

function InvoiceChargeView(props) {
	useEffect(() => {
		props?.setTitle?.("Invoice Charge");
		props?.setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const classes = useStyles();
	const { number_invoice: numberInvoice, type } = props.dataIds;

	const [disable, setDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleCancel = () => {
		dispatch(closeFormDialog());
	};

	const handleCharge = async () => {
		setDisable(true);
		setLoading(true);
		let endpoint = `fuel/fuel_payment_invoice/${numberInvoice}`;
		if (type === "dispatching") endpoint = `payments_process/pay/${numberInvoice}`;
		try {
			await invoiceCharge(endpoint);
			setDisable(false);
			setLoading(false);
			showSnackbar(snackbar, "Invoice Charged", "success", { duration: 5000 });
			dispatch(closeFormDialog());
		} catch (err) {
			setDisable(false);
			setLoading(false);
			showSnackbar(snackbar, err.errors[0].message, "error", { duration: 5000 });
			console.log(err);
		}
		dispatch(incrementDataRevision({ event: "invoiceRevision" }));
	};

	return (
		<div className="my-5">
			<Typography color="primary" className="mb-20 ml-6 mr-6 pt-10 pb-10 font-normal">
				Would you like to charge invoice {numberInvoice}?
			</Typography>

			{loading && (
				<div className={clsx(classes.progress, "flex flex-col items-center justify-center w-full h-full pt-20 pb-10")}>
					<CircularProgress />
				</div>
			)}
			<div className="flex w-full flex-col sm:flex-row items-top pb-10">
				<Button
					className="flex w-full mr-0 sm:mr-10 mb-10 sm:mb-0"
					variant="contained"
					color="default"
					aria-label="Follow"
					onClick={handleCancel}
				>
					Cancel
				</Button>
				<Button
					className="flex w-full"
					variant="contained"
					color="secondary"
					aria-label="Follow"
					disabled={disable}
					onClick={handleCharge}
				>
					Charge
				</Button>
			</div>
		</div>
	);
}

export default InvoiceChargeView;
