const { withStyles, Tooltip } = require("@material-ui/core");

const SmarthopTooltipHover = withStyles((theme) => ({
	tooltip: {
		fontSize: "13px",
		color: "black",
		backgroundColor: "#f1f1f1",
		maxWidth: "none",
		border: "solid thin black",
	},
}))(Tooltip);

module.exports = SmarthopTooltipHover;
