import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

function LoadSearchProgress(props) {
	const searchStatus = props.status;

	const getProcessingText = (type, meta) => {
		if (type === "SMARTHOP") {
			if (meta?.save?.count > 0 || (meta?.rank?.count > 0 && meta?.rank?.count === meta?.rank?.total)) {
				return `Finishing search requests...`;
			} else if (meta?.rank?.count >= 0) {
				return `Ranking ${(meta?.rank?.count ?? 0) + "/" + meta?.rank?.total} loads...`;
			} else if (meta?.search?.count > 0) {
				return `Analyzing ${(meta?.search?.count ?? 0) + "/" + meta?.search?.total} loads...`;
			}
		} else {
			if (meta?.save?.count > 0 || (meta?.rank?.count > 0 && meta?.rank?.count === meta?.rank?.total)) {
				return `Finishing search requests...`;
			} else if (meta?.rank?.count >= 0) {
				return `Ranking ${(meta?.rank?.count ?? 0) + "/" + meta?.rank?.total} loads...`;
			} else if (meta?.search?.count > 0) {
				return `Analyzing ${meta?.search?.count ?? 0} loads...`;
			}
		}
		return `Searching for loads...`;
	};

	let views = searchStatus?.pipelines?.map((pipeline, i) => {
		let text;
		if (pipeline.state === "QUEUEING") {
			text = "Queueing search request...";
		} else if (pipeline.state === "PROCESSING") {
			text = getProcessingText(pipeline.type, pipeline.meta);
		} else if (pipeline.state === "FAILED") {
			text = "Search request failed";
		} else if (pipeline.state === "FAILED_STUCK") {
			text = "Search request stuck";
		} else if (pipeline.state === "FINISHED") {
			text = "Search request finished";
		} else {
			text = "Unknown search status";
		}
		return (
			<Typography key={i} color="textSecondary" className="py-4">
				{pipeline.type}: {text}
			</Typography>
		);
	});

	return (
		<div className="flex flex-col h-full flex-auto items-center justify-center mb-32">
			<Card
				className={
					"w-4/5 sm:w-1/2 md:w-1/3 md:w-1/4 flex flex-col w-full items-center justify-center px-8 sm:px-16 text-center mb-92"
				}
			>
				<CardContent className="w-full">
					{props.origin && props.destination ? (
						<Typography variant="subtitle1" color="textSecondary" className="mt-16 pt-8 pb-8 font-semibold">
							{props.origin?.toUpperCase()?.replace(", USA", "")} -{" "}
							{props.destination?.toUpperCase()?.replace(", USA", "")}
						</Typography>
					) : props.origin ? (
						<Typography variant="subtitle1" color="textSecondary" className="mt-16 pt-8 pb-8 font-semibold">
							{props.origin?.toUpperCase()?.replace(", USA", "")}
						</Typography>
					) : (
						<Typography variant="subtitle1" color="textSecondary" className="mt-16 pt-8 pb-8 font-semibold">
							Searching...
						</Typography>
					)}
					{views}
					<LinearProgress color="secondary" className="mt-24 mb-10 flex flex-1 w-full" />
				</CardContent>
			</Card>
		</div>
	);
}

export default LoadSearchProgress;
