import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, Button } from "@material-ui/core";

// Services
import { getCarrierId, getUserId } from "app/services/LoginService";

// Tools
import { openFormDialog } from "app/store/tools/formDialogSlice";

// Config
import { CONFIG_PAYWALL } from "./PaywallViewConfig.js";

const PaywallView = (props) => {
	useEffect(() => {
		props.setTitle?.("Upgrade Required");
		props.setSize?.("max-w-sm");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();

	const userId = getUserId();
	const carrierId = getCarrierId();

	const keyId = props.keyId;
	const onHandlerPaywall = props.onHandlerPaywall;
	const dataIds = props.dataIds;
	const type = dataIds.type ?? "default";
	const enabledAddonOption = dataIds.enabledAddonOption ?? false;
	const btn = dataIds.btn ?? {};
	const data = dataIds.data ?? {};
	const extraData = dataIds.extraData ?? {};
	const config = CONFIG_PAYWALL(data, extraData)[type];

	const handleBtn = (upgradeType) => {
		if (upgradeType === "ADDON") config.callback.restriction = () => true;

		onHandlerPaywall?.();
		dispatch(
			openFormDialog({
				viewId: upgradeType === "PLAN" ? "BILLING_PLAN_CHANGE" : "ADD_PLAN_ADDON_CARRIER",
				dataIds: { carrierId, userId, callback: config.callback },
			})
		);
	};

	return (
		<div className={keyId === "PAYWALL_DIALOG" ? "" : "m-24"}>
			<div className="text-center">
				<Typography variant="h6" className="leading-normal">
					{config.title}
				</Typography>
			</div>
			<div className="text-center mb-20 mt-16">
				<Typography variant="subtitle1" className="leading-normal">
					{config.description}
				</Typography>
			</div>
			<Button className="w-full" variant="contained" color="secondary" onClick={() => handleBtn("PLAN")}>
				{btn.label ?? "Learn More"}
			</Button>
			{enabledAddonOption && (
				<Button className="w-full mt-5" variant="contained" color="secondary" onClick={() => handleBtn("ADDON")}>
					Add Extra Seats
				</Button>
			)}
		</div>
	);
};

export default PaywallView;
