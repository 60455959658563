const carrierSyncForm = {
	urlGET: "api/profile/carriers/:carrierId/details/fuel",
	urlPUT: "api/profile/carriers/:carrierId/details/fuel",
	listenEvent: "profileRevision",
	triggerEvent: "profileRevision",
	idKey: "_id",
	header: {
		edit: { label: "Edit Fuel Module" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "uuid",
								type: "text",
								label: "Onramp Customer ID",
							},
							{
								key: "onboardingFuelModule",
								type: "text",
								label: "Status",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "customer_sync_date",
								label: "Customer Sync Date",
								type: "picker",
								picker: {
									notClearable: true,
									type: "date",
								},
							},
							{
								key: "last_transactions_sync_date",
								label: "Last Customer Transactions Sync Date",
								type: "picker",
								picker: {
									notClearable: true,
									type: "date",
								},
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierSyncForm;
