import { useEffect } from "react";
import CodeVerificationView from "./CodeVerificationView";

const PhoneConfirmationView = ({ onDone, dataIds, setTitle, setSize }) => {
	useEffect(() => {
		setTitle?.("Phone Confirmation");
		setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	return <CodeVerificationView type="PHONE" dataIds={dataIds} onDone={onDone} />;
};

export default PhoneConfirmationView;
