import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { formatMessageDate } from "app/main/utils/dateUtils";
import { buildURLParameters, convertModelToURLParams, SEARCH_LOAD_URL_PARAMS_MAPPING } from "app/main/utils/urlUtils";
import { closePinnedPanel } from "app/store/tools/pannelsSlice";
import { getUIFiltersSearch } from "app/services/searchServices";
import { showMessage } from "app/store/fuse/messageSlice";

const redirectSearch = async (item, _, dispatch, history) => {
	try {
		const filters = await getUIFiltersSearch(item._id);

		const search = {
			// all other UI filters
			...filters,
			// search id param that would allow not to start search and just show old results
			searchId: item._id,
		};

		const url = buildURLParameters('/search', convertModelToURLParams(search, SEARCH_LOAD_URL_PARAMS_MAPPING));

		dispatch(closePinnedPanel());
		dispatch(incrementDataRevision({ event: "urlSearchRevision" }));
		history.push(url);
	}
	catch (error) {
		dispatch(showMessage({
			message: error.errors[0].message,
			variant: "error"
		}))
	}
}

const pinnedSearchesList = {
	urlGET: "api/search/users/:userId/requests",
	idKey: "_id",
	segmentKey: "PINNED_SEARCHES",
	content: {
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 20,
			},
		},
		grid: {
			theme: "light",
			variant: "skinny",
			tilesPerRow: 1,
			itemsPerRow: 3,
			mobile: {
				tilesPerRow: 1,
				itemsPerRow: 2,
			},
		},
		order: {
			defaut: { key: "createdAt", dir: "desc" },
		},
		items: [
			{
				key: "carrier",
				type: "text",
				label: "Carrier",
				builder: (item) => item?.carrier?.carrier_company_name ?? "-",
				grid: { headerTitle: true },
			},
			{
				key: "origin",
				type: "text",
				label: "Origin",
			},
			{
				key: "truck",
				type: "text",
				label: "Truck",
				builder: (item) => item?.truck__view?.label ?? "-",
			},
			{
				key: "availStart",
				type: "date",
				label: "Available",
			},
			{
				key: "expiresAt",
				type: "text",
				label: "Status",
				builder: (item) => {
					const msg = formatMessageDate(item.expiresAt);
					return msg === "Just now" ? "Active" : `Expired - ${msg}`;
				},
				grid: { headerSubtitle: true },
			},
			{
				type: "menu",
				items: [
					{
						key: "search",
						label: "View Results",
						variant: "button",
						button: {
							color: "secondary",
						},
						dispatchAction: redirectSearch,
					},
				],
			},
		],
	},
};

export default pinnedSearchesList;
