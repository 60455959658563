import { reportFiltersForm } from "app/main/utils/fileUtils";

const masterReportForm = reportFiltersForm(
	"billing/master-report/download/csv",
	"Download Master Report",
	"Master Report.csv",
	"text/csv",
	{ startDateTitle: "Start Delivery Date", endDateTitle: "End Delivery Date" }
);

export {
	masterReportForm,
};
