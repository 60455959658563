const carrierNotficationPerformanceForm = {
	urlGET: "api/profile/carriers/:carrierId/details/notification-performance",
	urlPUT: "api/profile/carriers/:carrierId/details/notification-performance",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Notification Configuration" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "message",
					label: "Allow to receive performance email",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "noMonthlyNotificationPerformance",
								type: "checkbox",
								label: "Monthly",
								checkbox: { inverted: true },
							},
							{
								key: "noWeeklyNotificationPerformance",
								type: "checkbox",
								label: "Weekly",
								checkbox: { inverted: true },
							},
						],
					},
				},

				{ type: "action", action: "SUBMIT", classes: "w-1/2" },
			],
		},
	},
};

export default carrierNotficationPerformanceForm;
