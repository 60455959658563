import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import sendPasswordResetCodeForm from "../config/sendPasswordResetCodeForm";
import resetPasswordForm from "../config/resetPasswordForm";
import { sendEmailForgotPassword, resetPassword } from "app/services/resetPasswordServices";
import { showSnackbar } from "app/main/utils/snackbarUtil";

function ResetPasswordView(props) {
	useEffect(() => {
		props?.setTitle?.("Recover Password");
		props?.setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	const snackbar = useSnackbar();
	const [email, setEmail] = useState(null);
	const [sent, setSent] = useState(null);
	const [changed, setChanged] = useState(null);

	const handledSendCode = (email) => {
		sendEmailForgotPassword(email).then(
			() => {
				setEmail(email);
				setSent(true);
			},
			(err) => {
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);
	};

	const handledResetPassword = (code, password) => {
		resetPassword(email, code, password).then(
			() => {
				setChanged(true);
			},
			(err) => {
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);
	};

	// <ResetPasswordForm />

	return (
		<div className="flex flex-col items-center justify-center px-4">
			{changed ? (
				<>
					<div className="flex flex-row items-center justify-center px-24">
						<Icon className="text-40 text-green font-bold">done</Icon>
						<Typography color="textSecondary" className="text-14 mb-20 mt-16 text-center font-normal">
							Password was successfully updated for {email ?? " your account"}, please login now
						</Typography>
					</div>
					<div className="flex w-full items-center justify-center px-2 mb-12">
						<Button color="secondary" variant="contained" className="w-full" onClick={() => props.onDone?.()}>
							Close
						</Button>
					</div>
				</>
			) : sent ? (
				<>
					<div className="flex flex-row items-center justify-center px-24">
						<Icon className="text-40 text-orange font-bold">warning</Icon>
						<Typography color="textSecondary" className="text-14 mb-20 mt-16 text-center font-normal">
							An email with verification code was successfully sent to {email ?? " your email"}, please check your inbox
						</Typography>
					</div>
					<div className="flex w-full items-center justify-center px-2">
						<SmarthopFormView
							mode={"CREATE"}
							content={resetPasswordForm}
							onSubmit={(data) => handledResetPassword(data.code, data.password)}
							onCustom={(data) => {
								setSent(null);
								setEmail(null);
							}}
						/>
					</div>
				</>
			) : (
				<>
					<Typography color="textSecondary" className="text-14 mb-14 mt-14 text-center font-normal">
						Please enter your email address to recover password
					</Typography>
					<SmarthopFormView
						mode={"CREATE"}
						content={sendPasswordResetCodeForm}
						onSubmit={(data) => handledSendCode(data.email)}
					/>
				</>
			)}
		</div>
	);
}

export default ResetPasswordView;
