import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { createForm } from "./TreeVisualizerForm";
import { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NARROW_FIELDS } from "@fuse/default-settings";
import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";
import moment from "moment";

const useStyles = makeStyles((theme) => {
	return {
		buttonNormal: {
			borderRadius: "4px",
			height: NARROW_FIELDS ? "4.6rem" : "5.2rem",
		},
		buttonDisabled: {
			background: theme.palette.grey["400"] + " !important",
		},
	};
});

const TreeVisualizerPanel = ({ onSubmit, defaultValues, loadingError, errors, role, user, options }) => {
	const classes = useStyles();
	const [selectedTruckData, setSelectedTruckData] = useState({});
	const [defaultVals, setDefaultVals] = useState(defaultValues);
	// eslint-disable-next-line
	let form = useMemo(() => createForm(role, user, options, classes), [defaultVals, options]);

	useDebouncedEffect(
		() => {
			if (!selectedTruckData || !selectedTruckData?.truck__view?.metadata) {
				return;
			}
			const truckMetadata = selectedTruckData?.truck__view?.metadata;

			const data = getStrategyFormFields(truckMetadata);

			setDefaultVals({ ...defaultVals, ...selectedTruckData, ...data });
		},
		[selectedTruckData],
		200
	);

	useEffect(() => {
		setDefaultVals(defaultValues);
	}, [defaultValues]);

	const getStrategyFormFields = (truck) => {
		let currentAvailableDate = moment(truck?.current_available_date ?? "").format("YYYY-MM-DD");
		currentAvailableDate = moment().diff(currentAvailableDate) > 0 ? moment() : currentAvailableDate;

		let updates = {
			start_date: currentAvailableDate.valueOf(), //truck.current_available_date,
		};

		if (truck.current_location) {
			updates["location_origin"] = truck.current_location;
			updates["location_origin__view"] = {
				value: truck.current_location,
				label: truck.current_location,
			};
		}

		if (truck.current_destination) {
			updates["location_destination"] = truck.current_destination;
			updates["location_destination__view"] = {
				value: truck.current_destination,
				label: truck.current_destination,
			};
		}
		return updates;
	};

	return (
		<SmarthopFormView
			content={form}
			mode={"CREATE"}
			noInitValidation={true}
			onSubmit={onSubmit}
			loadingError={loadingError}
			errors={errors}
			data={defaultVals}
			dataIds={{ carrierId: user?.carrier || user?.carrierId, driverId: user?.driverId }}
			trackChangedFields={["ALL"]}
			onChangeCommitted={(model, key) => {
				if (key === "truck") {
					setSelectedTruckData(model);
				}
			}}
		/>
	);
};

export default TreeVisualizerPanel;
