// Utils
import { formatPaymentMethodErrorReason } from "app/main/utils/tableUtils";

const paymentMethodErrorsList = (isAdmin) => ({
	urlGET: "billing/carriers/:carrierId/sources/:sourceId/errors",
	idKey: "source_id",
	listenEvent: "billingRevision",
	segmentKey: "PAYMENT_METHOD_ERRORS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["created_at"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			search: false,
			items: [
				{
					key: "start_date",
					type: "picker",
					label: "Start Date",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
				{
					key: "end_date",
					type: "picker",
					label: "End Date",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
			],
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		items: [
			{
				key: "_id",
				type: "id",
				label: "ID",
				grid: { headerTitle: isAdmin, valuePefix: "ID " },
				hidden: !isAdmin,
			},
			{
				key: "source_id",
				type: "id",
				label: "Source ID",
				grid: { headerSubtitle: isAdmin, valuePefix: "Source ID " },
				hidden: !isAdmin,
			},
			{
				type: "text",
				key: "reason",
				label: "Reason",
				maxLengthStart: 28,
				builder: (item) => formatPaymentMethodErrorReason(item.reason),
			},
			{
				type: "date",
				label: "Date",
				key: "created_at",
				grid: { headerTitle: !isAdmin },
			},
		],
	},
});

export default paymentMethodErrorsList;
