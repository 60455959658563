import { makeStyles } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { useMemo } from "react";
import * as fileService from "app/services/fileServices";
import { Fragment } from "react";

const useStyles = makeStyles({
	filebox: {
		cursor: "pointer",
		margin: "6px",
		width: "75px",
		height: "75px",
		position: "relative",
		boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		background: "white",
		borderRadius: "15px",
	},
	fileboxWide: {
		cursor: "pointer",
		margin: "5px",
		width: "150px",
		height: "75px",
		position: "relative",
		boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		background: "white",
	},
	icon: {
		position: "absolute",
		top: "-5px",
		right: "-5px",
		zIndex: 10,
		background: "white",
		border: "solid thin #ccc",
		"&:hover": {
			background: "#ccc",
		},
	},
	img: {
		width: "100%",
		height: "100%",
		borderRadius: "15px",
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		width: "100%",
		height: "100%",
		"& span": {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%,-50%)",
		},
	},
	filedesc: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		"& span": {
			marginRight: "5px",
		},
	},
});

function ChatFile({ type = "", name, fileId = null, onDelete = null }) {
	const classes = useStyles();
	const fullpath = useMemo(() => (fileId == null ? null : fileService.buildLink(fileId)), [fileId]);

	const onclick = (e) => {
		e.preventDefault();
		const reactNative = window?.ReactNativeWebView ?? false;
		if (reactNative) {
			reactNative.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: fullpath }));
		} else {
			window.open(fullpath, "_blank");
		}
	};

	return (
		<Typography color={"inherit"} className="font-normal break-all text-12 max-w-sm md:text-14" component="div">
			<Link
				onClick={onclick}
				className={type.includes("image/") ? classes.filebox : classes.fileboxWide}
				component="div"
			>
				{onDelete && (
					<IconButton size="small" className={classes.icon} type="submit" onClick={onDelete}>
						<Icon className="text-15">close</Icon>
					</IconButton>
				)}
				<RenderFile name={name} fullpath={fullpath} type={type} />
			</Link>
		</Typography>
	);
}

function RenderFile({ name = "", fullpath, type = "" }) {
	const classes = useStyles();
	const [maintype, subtype] = useMemo(() => type.split("/"), [type]);
	const filename = useMemo(() => {
		if (name.length < 13) return name;
		const ext = name.split(".")[1];
		return `${name.substring(0, 11)}.${ext}`;
	}, [name]);

	const fileVisual = useMemo(() => {
		if (fullpath == null) {
			return (
				<div className={classes.center}>
					<CircularProgress size={20} color="secondary" />
				</div>
			);
		} else if (maintype === "image") {
			return (
				<div className={classes.center}>
					<img src={fullpath} alt="File" className={classes.img} />
				</div>
			);
		} else if (subtype === "pdf") {
			return (
				<div className={classes.filedesc}>
					<Icon className="text-24" color="action">
						picture_as_pdf
					</Icon>
					{filename}
				</div>
			);
		} else {
			return (
				<div className={classes.filedesc}>
					<Icon className="text-24" color="action">
						note_add
					</Icon>
					{filename}
				</div>
			);
		}

    // eslint-disable-next-line
	}, [fullpath, maintype, subtype]);

	return <Fragment>{fileVisual}</Fragment>;
}

export default ChatFile;
