import axios from "axios";
import MobileDetect from "mobile-detect";
import {
	Typography,
	makeStyles,
	Link,
	IconButton,
	Icon,
	Radio,
	TextField,
	Select,
	MenuItem,
	CircularProgress,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { global } from "app/services/requestUtil";
import * as fileService from "app/services/fileServices";
import useMobileDetect from "@smarthop/hooks/useMobileDetect";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { isEnabled } from "app/services/featureStorageService";
import FilesIconAnalize from "./FilesIconAnalize";

const STATUS = {
	ERROR: {
		label: "Failed to analyze file.",
		icon: { value: "help_outline", style: "text-red" },
	},
	PROGRESS: { label: "Analyzing..." },
};

const useStyles = makeStyles((theme) => {
	return {
		"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
		flicker: {
			animationName: "$flicker",
			animationDuration: "1000ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		"@keyframes progress": {
			"0%": { backgroundPosition: "0 0" },
			"100%": { backgroundPosition: "-70px 0" },
		},
		dropzoneActive: {
			animation: "$progress 2s linear infinite !important",
			// eslint-disable-next-line max-len
			backgroundImage: "repeating-linear-gradient(-45deg, "
				.concat(theme.palette.secondary.light, ", ")
				.concat(theme.palette.secondary.light, " 25px, ")
				.concat(theme.palette.background.default, " 25px, ")
				.concat(theme.palette.background.default, " 50px)"),
			backgroundSize: "150% 100%",
			border: "solid",
			borderWidth: "1px",
			borderColor: "#D29C89",
			pointerEvents: "none",
		},
	};
});
const FilesView = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const isMobile = useMobileDetect();
	const snackbar = useSnackbar();
	const {
		data,
		uploadConfig,
		url,
		type,
		selectedLink,
		setFileLoading,
		setSelectedLink,
		setLoading,
		loading,
		userId,
		carrierId,
		isPublicView,
		fileInputRef,
		native,
		errorFiles,
	} = props;
	const mobileDetect = new MobileDetect(window.navigator.userAgent);

	const [isWarningDialogOpen, setIsWarningOpen] = useState(false);
	const [ignoreWarnings, setIgnoreWarnings] = useState(false);
	const [warnings, setWarnings] = useState();
	const [files, setFiles] = useState(data);
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(null);
	const [confirmDialog, setConfirmDialog] = useState({});
	const [typeFile, setTypeFile] = useState("pod");
	const [description, setDescription] = useState(null);
	const [newFile, setNewFile] = useState(null);
	const [index, setIndex] = useState(null);
	const [callActionOnce, setCallActionOnce] = useState(null);
	const [errorDescription, setErrorDescription] = useState(null);

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	useEffect(() => {
		if (ignoreWarnings) {
			handleAcceptDialog(confirmDialog?.type);
		}
		//eslint-disable-next-line
	}, [ignoreWarnings]);

	useEffect(() => {
		setFiles(data);
		//eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);
		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	});

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "ScannedDocument":
					return OnScannedDocument(dataParsed.data);
				default:
					break;
			}
		} catch (e) {}
	};

	function dataURItoBlob(dataURI, dataTYPE) {
		var binary = atob(dataURI.split(",")[1]),
			array = [];
		for (var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
		return new Blob([new Uint8Array(array)], { type: dataTYPE });
	}

	const dataURLtoFile = async (url, filename, mime) => {
		let res = await fetch(url);
		let resBlob = await res.blob();
		let file = await new File([resBlob], filename, { type: mime });
		return file;
	};

	const OnScannedDocument = async (msg) => {
		if (native) setLoading(true);
		const genFilename = `${Date.now()}.${msg?.contentType.split("/")[1]}`;
		let content = msg?.imgData;
		let file;
		if (msg?.imageList) {
			content = await fileService.convert(msg.imageList);
			const blob = dataURItoBlob(content, msg?.contentType);
			file = await new File([blob], genFilename, { type: msg?.contentType });
		} else {
			file = await dataURLtoFile(content, genFilename, msg?.contentType);
		}
		handleOnDrop([file]);
	};

	const handleFileChange = (ev) => {
		setError(null);
		if (!ev?.target?.files) return setError("An error occurred while uploading the file");
		handleOnDrop(Array.from(ev.target.files));
		ev.target.value = null;
	};

	const handleOnDrop = (newFiles) => {
		setError(null);
		if (!newFiles?.length) {
			return;
		}
		setUploading(true);
		if (native) setLoading(true);
		cleanDescription();

		let data = new FormData();
		data.append("file", newFiles[0]);
		data.append("type", uploadConfig?.type);
		data.append("document_type", uploadConfig?.documentType);
		data.append("field_name", uploadConfig?.fileName);

		const params = uploadConfig?.params;
		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.post(uploadConfig?.url, data, { headers, params })
			.then((res) => {
				setNewFile({ ...res, name: newFiles[0]?.name, type: newFiles[0]?.type });
				setUploading(false);
				if (native) setLoading(false);
				if (!native) setDescription(newFiles[0]?.name);
				setConfirmDialog({
					open: true,
					title: "Add File",
					type: "ADD",
					closeMsg: "Close",
					acceptMsg: "Save",
				});
			})
			.catch((res) => {
				setUploading(false);
				if (native) setLoading(false);
				setError("An error occurred while uploading the file");
			});
	};

	const onClickFileLink = (link) => {
		if (native) return window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: link }));
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: link,
				},
			})
		);
	};

	const cleanDescription = () => {
		setDescription(null);
		setTypeFile("pod");
	};

	const handleClose = () => {
		setConfirmDialog({});
		cleanDescription();
	};

	const onSetSelectedLink = (newLink) => {
		setFileLoading?.(true);
		setSelectedLink?.(newLink);
		setTimeout(() => {
			setFileLoading?.(false);
		}, 1000);
	};

	const handleAcceptDialog = async (type) => {
		let data;
		if ((!description || description === "") && typeFile === "other" && type !== "DELETE")
			return setErrorDescription("You must provide a description");
		setErrorDescription(null);
		setLoading(true);
		if (type === "ADD") {
			const splitFile = newFile?.name?.split(".");
			data = [
				...files,
				{
					file_id: [newFile?.data?.fileId],
					type: typeFile,
					description: description,
					typeFile: splitFile ? splitFile[splitFile?.length - 1] : "pdf",
					analize: { status: "PROGRESS", metadata: {} },
					new: true,
				},
			];
		} else if (type === "EDIT") {
			const copy = files.map((item) => ({ ...item }));
			copy[index].description = description;
			copy[index].type = typeFile;
			data = [...copy];
		} else if (type === "DELETE") {
			const copy = files.map((item) => ({ ...item }));
			if (selectedLink?.id === copy[index]?.file_id?.[0]) setSelectedLink(null);
			copy.splice(index, 1);
			data = [...copy];
		}
		setConfirmDialog({ open: false, type });
		await onSubmit(data, type);
	};

	const onSubmit = async (data, mode) => {
		setIgnoreWarnings(false);
		setIsWarningOpen(false);

		const dataSend = { data: { [type]: data, ignoreWarnings: ignoreWarnings, action: mode } };
		const params = isPublicView ? { options_public_user_context: userId } : {};

		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.put(url, dataSend, { headers, params })
			.then((res) => {
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "filesRevision" }));
				if (typeFile === "pod" && native && data.filter(({ type }) => type === "pod").length > 1 && mode === "ADD") {
					setWarningDialog();
				}
				if (carrierId && typeFile === "pod" && !callActionOnce) {
					setCallActionOnce(true);
					dispatch(fetchActions({ carrierId }));
					if (native) {
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: carrierId }));
					}
					dispatch(incrementDataRevision({ event: "profileRevision" }));
				}
				cleanDescription();
				if (mode === "DELETE") fileService.remove(files[index]?.file_id?.[0]);
				setFiles(data);
				setLoading(false);
			})
			.catch((error) => {
				let response = error?.response;
				let err = { status: response?.status, errors: response?.data?.errors };
				const warnings = err?.errors?.[0]?.metadata?.warnings;
				setLoading(false);
				setWarnings(warnings);
				const message = err?.errors?.[0]?.message ?? "Error updating";
				if (!warnings?.length) {
					cleanDescription();
					showSnackbar(snackbar, message, "error");
				} else {
					setIsWarningOpen(true);
				}
			});
	};

	const setWarningDialog = () => {
		setConfirmDialog({
			open: true,
			message:
				"Please consider re-uploading BOL file as a single document, you can use scanner to create and upload single pdf from multiple images",
			type: "WARNING",
			closeMsg: "Close",
		});
	};

	const onAcceptWarningChanges = () => {
		cleanWarnings();
		setIgnoreWarnings(true);
	};

	const cleanWarnings = () => {
		setWarnings();
	};

	if (errorFiles) {
		return (
			<Typography className="w-full text-center text-12 mt-10" color="error">
				{errorFiles}
			</Typography>
		);
	}

	return (
		<div className={loading || uploading ? "pointer-events-none" : ""}>
			{loading && (
				<div
					className={"absolute items-center justify-center top-1/2"}
					style={{ left: isMobile ? "45%" : "25%", top: isMobile ? "40%" : "" }}
				>
					<CircularProgress className="text-grey-400" />
				</div>
			)}
			<div className={!native ? "mb-10" : ""}>
				{!native && (
					<DropzoneArea
						ref={props.innerRef}
						fileObjects={[]}
						maxFileSize={50 * 1024 * 1024}
						showPreviews={false}
						showPreviewsInDropzone={false}
						showFileNamesInPreview={false}
						showFileNames={false}
						filesLimit={1}
						dropzoneClass={(error ? "min-h-120 " : "min-h-140 ") + (uploading ? classes.dropzoneActive : "")}
						dropzoneParagraphClass={"text-13 flex justify-center items-center pt-32"}
						Icon={"b"}
						dropzoneText={uploading ? "Uploading  file..." : "Drag and Drop File"}
						showAlerts={false}
						onChange={(e) => handleOnDrop(e)}
						acceptedFiles={[".pdf", ".jpg", ".jpeg", ".png"]}
					/>
				)}

				{error && (
					<div className="flex items-center justify-center">
						<Typography className="w-full text-center text-12 mt-2" color="error">
							{error}
						</Typography>
					</div>
				)}
			</div>
			{!files?.length && native && (
				<div className="h-full w-full items-center justify-center align-center flex">
					<Typography className="text-16 text-grey-700 text-center">
						{"Scanner is recommended to simplify uploading proccess of multipage documents"}
					</Typography>
				</div>
			)}

			<input
				type="file"
				style={{ display: "none" }}
				ref={fileInputRef}
				onChange={(e) => handleFileChange(e)}
				accept={".jpg, .jpeg, .png"}
			/>

			{files?.length > 0 &&
				files?.map((file, index) => {
					const bolFiles = files.filter(({ type }) => type === "pod");
					const nameFile =
						file.type === "pod"
							? "Bill Of Lading" +
							  ` ${bolFiles?.length > 1 ? "#" + (bolFiles.findIndex((bol) => bol.file_id === file.file_id) + 1) : ""}`
							: file.description;

					const analizeStatus = file?.analize?.status;

					return (
						<div className="flex content-center justify-center space-x-10">
							<div className="flex flex-row space-between w-full items-center content-center">
								{!isMobile && (
									<Radio
										className="pr-4 pl-0"
										checked={selectedLink?.id === file?.file_id?.[0]}
										onChange={() =>
											onSetSelectedLink({
												id: file?.file_id?.[0],
												link: fileService.buildLink(file?.file_id?.[0], false, isPublicView),
												type: file?.typeFile,
											})
										}
										value={file?.file_id?.[0]}
									/>
								)}
								<div className="flex flex-col">
									<div className="flex flex-row">
										<Link
											className="text-13 ml:text-14 break-normal py-4"
											key={file?.file_id?.[0]}
											onClick={(e) => onClickFileLink(fileService.buildLink(file?.file_id?.[0], false, isPublicView))}
										>
											{nameFile}
										</Link>
										{analizeStatus === "DONE" && !isMobile && isEnabled("ANALIZE_FILES_UPLOADED") && (
											<FilesIconAnalize data={file?.analize} style={{ icon: "mt-7" }} />
										)}
									</div>
									{["PROGRESS", "ERROR"].includes(analizeStatus) &&
										!isMobile &&
										isEnabled("ANALIZE_FILES_UPLOADED") && (
											<div className="flex flex-row -mt-6">
												{STATUS[analizeStatus].icon && (
													<Icon className={`text-13 mr-3 mt-1 ${STATUS[analizeStatus].icon.style}`}>
														{STATUS[analizeStatus].icon.value}
													</Icon>
												)}
												<Typography
													className={`text-grey-700 text-11 pr-16 ${
														analizeStatus === "PROGRESS" ? classes.flicker : ""
													}`}
												>
													{STATUS[analizeStatus].label}
												</Typography>
											</div>
										)}
								</div>
							</div>

							<IconButton
								onClick={() => {
									setIndex(index);
									setDescription(file.description);
									setTypeFile(file.type);
									setConfirmDialog({
										open: true,
										title: "Edit File",
										type: "EDIT",
										closeMsg: "Close",
										acceptMsg: "Edit",
									});
								}}
							>
								<Icon className="text-22">edit</Icon>
							</IconButton>

							<IconButton
								onClick={() => {
									setIndex(index);
									setConfirmDialog({
										open: true,
										message: "Are you sure you want to delete file?",
										type: "DELETE",
										closeMsg: "Close",
										acceptMsg: "delete",
									});
								}}
							>
								<Icon className="text-22" color="error">
									delete
								</Icon>
							</IconButton>
						</div>
					);
				})}

			<SmarthopConfirmDialog
				open={confirmDialog?.open}
				title={confirmDialog?.title}
				handleClose={() => {
					handleClose();
				}}
				handleAccept={
					confirmDialog?.type === "WARNING"
						? null
						: () => {
								handleAcceptDialog(confirmDialog?.type);
						  }
				}
				closeMsg={confirmDialog?.closeMsg}
				acceptMsg={confirmDialog?.acceptMsg}
			>
				{confirmDialog?.type === "DELETE" || confirmDialog?.type === "WARNING" ? (
					<Typography className="text-16">{confirmDialog?.message}</Typography>
				) : (
					<div className="md:min-w-540 py-10">
						<Select
							classes={{ select: "py-14" }}
							className="w-full"
							value={typeFile}
							variant="outlined"
							onChange={(event) => {
								setTypeFile(event.target.value);
							}}
						>
							<MenuItem value="pod">
								<Typography display="inline">Bill Of Lading</Typography>
							</MenuItem>
							<MenuItem value="other">
								<Typography display="inline">Other</Typography>
							</MenuItem>
						</Select>
						{typeFile !== "pod" && (
							<TextField
								className="w-full mt-10"
								variant="outlined"
								InputProps={{
									placeholder: "Description",
								}}
								value={description}
								onChange={(event) => setDescription(event.target.value)}
							/>
						)}
						{errorDescription && (
							<Typography className="w-full text-center text-12 mt-10" color="error">
								{errorDescription}
							</Typography>
						)}
					</div>
				)}
			</SmarthopConfirmDialog>
			{warnings?.length && (
				<WarningConfirmDialog
					open={isWarningDialogOpen}
					warnings={warnings}
					onAccept={onAcceptWarningChanges}
					onClose={cleanWarnings}
				/>
			)}
		</div>
	);
};
export default FilesView;
