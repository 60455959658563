import _ from "@lodash";
import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { global } from "app/services/requestUtil";
import { closeMenuPanel } from "app/store/tools/menuSlice";
import { fetchCredentialsStatus, clearCredentials } from "app/store/search/credentialsSlice";
import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";
import SmarthopFormView, { defaultValuesIterator, postprocessData } from "@smarthop/form/SmarthopFormView";
import { replaceFormDialog } from "app/store/tools/formDialogSlice";

import { makeStyles } from "@material-ui/core/styles";

import createForm from "./config/searchForm";
import SearchStatusPanel from "./SearchStatusPanel";
import SearchVersionSwitch from "../searchV3/components/SearchVersionSwitch";
import { getCarrierId, getUserId, getDriverId, getRoleLabel } from "app/services/LoginService";
import { toStateViews } from "app/main/utils/stateUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { NARROW_FIELDS } from "@fuse/default-settings";

import {
	rewriteURLParameters,
	readURLParameters,
	convertModelToURLParams,
	convertURLParamsToModel,
	buildURLParameters,
	SEARCH_LOAD_URL_PARAMS_MAPPING,
} from "../utils/urlUtils";

const useStyles = makeStyles((theme) => {
	return {
		field: {
			background: "white",
			borderRadius: "4px",
		},
		buttonNormal: {
			borderRadius: "4px",
			height: NARROW_FIELDS ? "4.6rem" : "5.2rem",
		},
		buttonDisabled: {
			background: theme.palette.grey["400"] + " !important",
		},
		leftPanelHeight: {
			height: "calc(100vh - 220px) !important",
		},
		"@keyframes flicker": {
			from: {
				opacity: 1,
			},
			to: {
				opacity: 0.2,
			},
		},
		flicker: {
			animationName: "$flicker",
			animationDuration: "1000ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		withAnimation: ({ disabled }) => ({
			animationPlayState: disabled ? "paused" : "running",
		}),
	};
});

function SearchPanel(props) {
	// Native fields
	// TODO: isNativeEnv works when props.nativeMobile is not being passed
	// TODO: Could isNativeEnv replace props.nativeMobile?
	const isNativeEnv = window?.location?.href?.includes("/native/");
	const location = useLocation();
	const nativeMobile = props.nativeMobile;
	const contentHidden = props.contentHidden;
	const onEvent = props.onEvent;
	const dataIds = props?.dataIds ?? {};
	const isSearchSimilar = props?.dataIds?.eventType === "SEARCH_SIMILAR";
	const isFindAnotherLoad = props?.dataIds?.eventType === "FIND_ANOTHER_LOAD";

	const user = useSelector(({ auth }) => auth.user);
	const roles = user.rolesLabels;

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	// getting role with most of the permissions
	let role = roles.includes("administrator")
		? "administrator"
		: roles.includes("dispatcher")
		? "dispatcher"
		: roles.includes("ops support")
		? "ops support"
		: roles.includes("carrier")
		? "carrier"
		: roles.includes("driver")
		? "driver"
		: roles.includes("carrier_manager")
		? "carrier_manager"
		: roles.includes("carrier_dispatcher")
		? "carrier_dispatcher"
		: undefined;

	if (!role) {
		role = getRoleLabel();
	}

	const type = props.type;
	const resetRevision = props.resetRevision;
	const onSearchRequested = props.onSearchRequested;
	const onSearchSubmitted = props.onSearchSubmitted;
	const onSearchError = props.onSearchError;
	const onSearchStatusChanged = props.onSearchStatusChanged;
	const onPanelValueChanged = props.onPanelValueChanged;
	const submitRedirectURL = props.submitRedirectURL;
	const userId = getUserId();

	const carrierId = ["carrier", "carrier_manager", "carrier_dispatcher", "driver", "carrier_generic"].includes(role)
		? getCarrierId()
		: null;
	const driverId = role === "driver" ? getDriverId() : null;
	const isInternal = ["administrator", "dispatcher", "ops support"].includes(role);

	const urlSearchRevision = useSelector(({ tools }) => tools.revision["urlSearchRevision"]);
	const [urlRevisionHandled, setUrlRevisionHandled] = useState(urlSearchRevision);

	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const [mobile, setMobile] = useState(window.innerWidth < 960);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1800);

	const [selectedCarrierData, setSelectedCarrierData] = useState({});
	const [selectedTruckData, setSelectedTruckData] = useState({});
	const [selectedDriverData, setSelectedDriverData] = useState({});
	const [initSubmitChecked, setInitSubmitChecked] = useState(false);
	const [initReserRevision, setInitReserRevision] = useState(resetRevision);
	const [outdated, setOutdated] = useState(false);
	const [searchModel, setSearchModel] = useState(null);
	const [searchId, setSearchId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [allData, setAllData] = useState({});

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
			if (window.innerWidth < 1600 && window.innerWidth > 960 && !smallScreen) {
				setSmallScreen(true);
			} else if ((window.innerWidth > 1600 || window.innerWidth < 960) && smallScreen) {
				setSmallScreen(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const getOriginalFieldsTruck = (truck) => {
		const truckEquipment = truck?.equipment ?? truck?.truck?.equipment ?? "";
		const truckSubtype = truck?.subtype ?? truck?.truck?.subtype ?? undefined;

		return {
			equipment: truckEquipment === "SPECIAL" ? "SPECIAL_" + truckSubtype : truckEquipment ?? "",
			subtype: truckSubtype,
			load_type: truck?.truck_trailer__view?.metadata?.load_type ?? truck?.truck?.load_type ?? "FULL",
			trailer: truck?.trailer ?? truck?.truck?.trailer ?? "",
			max_weight: truck?.max_weight ?? truck?.truck?.max_weight ?? "",
			driver: truck?.driver ?? {},
			driver__view: truck?.driver__view ?? {},
			system_show_hazmat_loads_only: truck?.truck_trailer__view?.metadata?.hazmat,
		};
	};

	const getOriginalFieldsDriver = (driver) => {
		return {
			blacklisted_states_ids: driver?.blacklisted_states_ids ?? [],
			blacklisted_states_ids__view: toStateViews(driver?.blacklisted_states_ids ?? []),
		};
	};

	const getTruckPlanFields = (truck) => {
		if (!truck?.activePlan || truck.cancelled || truck.expired || truck.covered) {
			return {};
		}

		let date = truck?.current_available_date
			? moment.utc(truck?.current_available_date).format("YYYY-MM-DD")
			: moment.utc().format("YYYY-MM-DD");
		let currentAvailableDate = moment(date).format("YYYY-MM-DD");
		currentAvailableDate = moment().diff(currentAvailableDate) > 0 ? moment() : currentAvailableDate;
		let updates = {
			pickup_ds__after: currentAvailableDate.valueOf(), //truck.current_available_date,
		};

		if (truck.current_location) {
			updates["location_origin"] = truck.current_location;
			updates["location_origin__view"] = {
				value: truck.current_location,
				label: truck.current_location,
			};
		}

		if (truck.current_destination) {
			updates["location_destination"] = truck.current_destination;
			updates["location_destination__view"] = {
				value: truck.current_destination,
				label: truck.current_destination,
			};
		} else if (truck.destinationStates?.length > 0) {
			updates["destinationStates"] = truck.destinationStates;
			updates["destinationStates__view"] = toStateViews(truck.destinationStates);
		}

		updates["rate_per_mile_preferred"] = truck?.rate_per_mile_preferred;
		updates["miles_preferred__max"] = truck?.miles_preferred__max;
		updates["miles_preferred__min"] = truck?.miles_preferred__min;
		updates["rate_total_preferred"] = truck?.rate_total_preferred;
		updates["extra_stops_preferred"] = truck?.extra_stops_preferred;
		updates["dh_origin"] = truck?.dh_origin_preferred;
		updates["profit_preferred"] = truck?.profit_preferred;
		updates["min_rank_mci"] = truck?.min_rank_mci;
		updates["age_mins_max_preferred"] = truck?.age_mins_max_preferred;
		updates["min_credit_score"] = truck?.min_credit_score;
		return updates;
	};
	const model =
		props?.model ?? (contentHidden ? {} : convertURLParamsToModel(readURLParameters(), SEARCH_LOAD_URL_PARAMS_MAPPING));

	const [data, setData] = useState(model ?? {});
	const [orderBy, setOrderBy] = useState(model?.order_by ?? "MATCH");

	const onSaveTruckPlan = () => {
		const dataToSend = {
			rate_per_mile_preferred: allData?.rate_per_mile_preferred,
			miles_preferred__max: allData?.miles_preferred__max,
			miles_preferred__min: allData?.miles_preferred__min,
			rate_total_preferred: allData?.rate_total_preferred,
			extra_stops_preferred: allData?.extra_stops_preferred,
			dh_origin_preferred: allData?.dh_origin,
			profit_preferred: allData?.profit_preferred,
			min_rank_mci: allData?.min_rank_mci,
			age_mins_max_preferred: allData?.age_mins_max_preferred,
			min_credit_score: allData?.min_credit_score,
			carrier: allData?.carrier,
			truck: allData?.truck,
		};

		if (allData?.destinationStates?.length) {
			dataToSend.current_destination = null;
			dataToSend.destinationStates = allData.destinationStates;
			dataToSend.destinationStates__view = allData.destinationStates__view;
		} else if (allData?.location_destination) {
			dataToSend.current_destination = allData.location_destination;
			dataToSend.current_destination__view = allData.location_destination__view;
		}

		if (allData?.location_origin) {
			dataToSend.current_location = allData?.location_origin;
			dataToSend.current_location__view = allData?.location_origin__view;
			dataToSend.current_location_coords = allData?.location_origin__view?.metadata?.coords;
		}
		if (allData?.pickup_ds__after) {
			dataToSend.current_available_date = moment
				.parseZone(allData?.pickup_ds__after)
				.utc(true)
				.add(12, "hours")
				.format("YYYY-MM-DD HH:mm");
		}

		dispatch(
			replaceFormDialog({
				viewId: "TRUCK_PLAN",
				mode: "EDIT",
				dataIds: {
					carrierId: allData?.carrier,
					truckId: allData?.truck,
					overridesDataPlan: dataToSend,
					openSection: "search",
					fromSearch: true,
				},
			})
		);
	};

	useEffect(() => {
		setAllData((d) => ({ ...d, ...data }));
	}, [data]);

	const resetSoftFilters = () => {
		let newDate = { ...searchModel };
		newDate.age_mins_max_preferred = 2 * 60;
		newDate.miles_preferred__max = 4000;
		newDate.miles_preferred__min = 0;
		newDate.profit_preferred = -1000;
		newDate.rate_per_mile_preferred = 0;
		newDate.rate_total_preferred = 0;
		newDate.min_credit_score = 0;
		newDate.extra_stops_preferred = 5;
		newDate.min_rank_mci = 0;
		newDate.system_show_bid_now_only = false;
		newDate.system_show_onboarded_only = false;
		newDate.system_hide_all_flexible = false;
		newDate.system_show_team_loads_only = false;
		newDate.system_show_special_loads_only = false;
		newDate.system_show_new_loads_only = false;
		newDate.system_show_with_price_only = false;
		newDate.system_show_plan_match_only = false;
		newDate.system_show_factorable_only = false;
		newDate.system_show_hazmat_loads_only = false;
		newDate.no_show_international_load = false;
		newDate.brokers_search = undefined;

		// WORKAROUND: new field added to the object will trigger useEffect subscribed for data object,
		// need to find a better approach to reset filters to default values
		newDate.revision = Math.random();
		setData((d) => ({ ...d, ...newDate }));
		if (searchId) {
			if (onSearchSubmitted) onSearchSubmitted(searchId, newDate);
			if (onEvent) onEvent("SEARCH_SUBMITTED", { searchId, model: newDate });
			updateURLQuery(newDate);
		}
		// eslint-disable-next-line
	};

	useEffect(() => {
		if (!props?.model) {
			if (hasSubaccounts || isInternal) dispatch(clearCredentials());
			return;
		}
		setData(props?.model);

		// eslint-disable-next-line
	}, [props.model, hasSubaccounts, isInternal]);

	// triggreing field update and reload of search results when openning a search from history

	useEffect(() => {
		if (urlSearchRevision === urlRevisionHandled) {
			return;
		}
		if (contentHidden) {
			return;
		}
		setUrlRevisionHandled(urlSearchRevision);
		const urlParams = readURLParameters();
		if (!urlParams) {
			return;
		}
		// saving search model to updated visible filters
		const model = convertURLParamsToModel(urlParams, SEARCH_LOAD_URL_PARAMS_MAPPING);
		model.order_by = orderBy;
		setData(model);
		setSearchModel(model);

		// triggering reasult loading, we only load old results and do not restart search request
		setSearchId(model.searchId);
		if (onSearchSubmitted) onSearchSubmitted(model.searchId, model, true);
		if (onEvent) onEvent("SEARCH_SUBMITTED", { searchId: model.searchId, model });
		// eslint-disable-next-line
	}, [urlSearchRevision]);

	// triggreing auto submit of data is present on init on the component and data has all mandatory field

	useEffect(() => {
		if (initSubmitChecked) {
			return;
		}

		if (model?.searchId) {
			// in case if URL or init model object contains 'searchId' params model would also contain
			// that 'searchId', in that case it's not required to start new search, we simply need
			// to show old results, must probably search was opened from search history
			setSearchModel(model);
			setSearchId(model.searchId);
			if (onSearchSubmitted) onSearchSubmitted(model.searchId, model, true);
			if (onEvent) onEvent("SEARCH_SUBMITTED", { searchId: model.searchId, model });
		} else if (data && data.truck && data.pickup_ds__after && data.location_origin) {
			const items = [...(form?.items ?? []), ...(form?.top?.items ?? []), ...(form?.left?.items ?? [])];
			// make sure first request includes all the fileds includeing all default values
			const dataDefaults = defaultValuesIterator(items, data);
			// make sure data has the same structure as smarthop view on submit
			const dataProcessed = postprocessData(items, dataDefaults);
			// in case if carrier field is not shown (for driver or carrier), default value would be ignorred
			if (!dataProcessed.carrier) {
				dataProcessed.carrier = data.carrier;
				dataProcessed.carrier__view = carrierId ? { value: data.carrier, label: "ID " + data.carrier } : null;
			}
			if (!dataProcessed.equipment) {
				dataProcessed.equipment = data.equipment;
			}
			if (!dataProcessed.order_by) {
				dataProcessed.order_by = "MATCH";
			}
			if (moment(dataProcessed.pickup_ds__after).isBefore(moment())) {
				dataProcessed.pickup_ds__after = moment();
			}
			if (
				dataProcessed.pickup_ds__before &&
				moment(dataProcessed.pickup_ds__before).isBefore(dataProcessed.pickup_ds__after)
			) {
				dataProcessed.pickup_ds__before = dataProcessed.pickup_ds__after;
				setData({ ...data, ...{ pickup_ds__before: dataProcessed.pickup_ds__before } });
			} else if (dataProcessed.pickup_ds__before && moment(dataProcessed.pickup_ds__before).isBefore(moment())) {
				dataProcessed.pickup_ds__before = moment();
				setData({ ...data, ...{ pickup_ds__before: dataProcessed.pickup_ds__before } });
			}
			setSearchModel(dataProcessed);
		}
		setInitSubmitChecked(true);
		// eslint-disable-next-line
	}, []);

	// overriding values after selection of a truck

	useDebouncedEffect(
		() => {
			if (_.isEmpty(selectedTruckData) || data.truck === selectedTruckData?.truck) {
				return;
			}
			let truckMetadata = selectedTruckData?.truck__view?.metadata;
			let driverMetadata = selectedTruckData?.truck__view?.metadata?.driver__view?.metadata;
			setData({
				...selectedTruckData,
				...getOriginalFieldsTruck(truckMetadata),
				...getOriginalFieldsDriver(driverMetadata),
				...getTruckPlanFields(truckMetadata),
			});
			// eslint-disable-next-line
		},
		[selectedTruckData],
		200
	);

	// overriding values after selection of a driver

	useDebouncedEffect(
		() => {
			if (_.isEmpty(selectedDriverData) || data.driver === selectedDriverData?.driver) {
				return;
			}
			let driver = selectedDriverData?.driver__view?.metadata?.driver;
			setData({ ...selectedDriverData, ...getOriginalFieldsDriver(driver) });
			// eslint-disable-next-line
		},
		[selectedDriverData],
		200
	);

	// overriding values after selection of a carrier

	useDebouncedEffect(
		() => {
			if (_.isEmpty(selectedCarrierData) || data.carrier === selectedCarrierData?.carrier) {
				return;
			}
			selectedCarrierData.truck = null;
			selectedCarrierData.truck__view = null;
			setData({ ...selectedCarrierData, ...getOriginalFieldsTruck() });
			// eslint-disable-next-line
		},
		[selectedCarrierData],
		200
	);

	const updateOrderBy = (value) => {
		setOrderBy(value);
		let model = { ...searchModel };
		model.order_by = value;
		setSearchModel(model);
		setSearchId(model.searchId);
		updateURLQuery(model);
	};

	const updateFilters = (update) => {
		const m = convertURLParamsToModel(readURLParameters(), SEARCH_LOAD_URL_PARAMS_MAPPING);
		const d = { ...m, ...update };
		updateURLQuery(d);
		if (searchId) {
			if (onSearchSubmitted) onSearchSubmitted(searchId, d, true);
			if (onEvent) onEvent("SEARCH_SUBMITTED", { searchId, d });
		}
	};

	// DIRTY WORKAROUND: to allow to relet filters to default values

	useEffect(() => {
		if (resetRevision === initReserRevision || _.isEmpty(searchModel) || !searchId) {
			return;
		}
		resetSoftFilters();
		setInitReserRevision(resetRevision);
		// eslint-disable-next-line
	}, [resetRevision]);

	const runSearch = (dataOverrides = false) => {
		let dataToSend = Object.assign({}, searchModel);
		if (dataOverrides) {
			const updates = {
				pickup_ds__after: moment(dataOverrides.current_available_date ?? "").format("YYYY-MM-DD"),
				location_origin: dataOverrides.current_location,
				location_destination: dataOverrides.current_destination,
				destinationStates: dataOverrides.destinationStates,
				current_location: dataOverrides.current_location,
				current_destination: dataOverrides.current_destination,
				current_available_date: dataOverrides.current_available_date,
				...dataOverrides,
			};
			dataToSend = { ...dataToSend, ...updates };
		}

		if (moment(dataToSend.pickup_ds__after).isBefore(moment())) {
			dataToSend.pickup_ds__after = moment();
		}
		if (dataToSend.pickup_ds__before && moment(dataToSend.pickup_ds__before).isBefore(dataToSend.pickup_ds__after)) {
			dataToSend.pickup_ds__before = dataToSend.pickup_ds__after;
			setData({ ...data, ...{ pickup_ds__before: dataToSend.pickup_ds__before } });
		} else if (dataToSend.pickup_ds__before && moment(dataToSend.pickup_ds__before).isBefore(moment())) {
			dataToSend.pickup_ds__before = moment();
			setData({ ...data, ...{ pickup_ds__before: dataToSend.pickup_ds__before } });
		}

		dataToSend.webMobile = mobile;
		dataToSend.webNative = nativeMobile;

		setLoading(true);
		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		};
		console.log("[SearchPanel] start search, model=", dataToSend);
		if (onSearchRequested) onSearchRequested();
		axios
			.create({
				baseURL: global.SERVER_NAME,
				headers: headers,
				timeout: 15000,
			})
			.post(`api/search/users/${userId}/requests`, { headers: headers, data: dataToSend })
			.then((res) => {
				console.log(
					`[SearchPanel] POST: created search, data=${res.data.id}, ` +
						` finished=${res.data.finished + ""}, preloaded=${res.data.preloaded + ""}`
				);
				setLoading(false);
				setOutdated(false);
				setSearchId(res.data.id);
				dispatch(closeMenuPanel());
				if (onSearchSubmitted) onSearchSubmitted(res.data.id, dataToSend, res.data.finished, res.data.preloaded);
				if (onEvent) onEvent("SEARCH_SUBMITTED", { searchId: res.data.id, model: dataToSend });
			})
			.catch((error) => {
				setLoading(false);
				let response = error?.response;
				let errors = response?.data?.errors;
				console.error(`[SearchPanel] POST: failed to create request data=${response?.status}, data=`, error);
				errors = errors?.map
					? errors
					: [
							{
								type: "generic",
								message: `Something went wrong, please try again... (Search initialization error: ${
									error?.message ?? "<empty>"
								})`,
							},
					  ];
				if (onSearchError) onSearchError(errors);
			});
	};

	let form = useMemo(
		() =>
			createForm(
				type,
				mobile,
				role,
				outdated,
				classes,
				allData?.truck && allData?.truck?.length === 24 && allData?.location_origin__view?.value && onSaveTruckPlan,
				resetSoftFilters,
				props?.model,
				loading,
				hasSubaccounts,
				() => runSearch(allData),
				allData,
				nativeMobile
			),
		// eslint-disable-next-line
		[type, mobile, outdated, role, loading, onSaveTruckPlan, allData]
	);

	// submit search request
	useEffect(() => {
		if (!searchModel || searchModel.searchId || submitRedirectURL) {
			return;
		}

		runSearch();
		// eslint-disable-next-line
	}, [searchModel]);

	const toURLParams = (model, overrideDetailsFromMetadata) => {
		let copy = JSON.parse(JSON.stringify(model));

		// in case if we do not show fields to edit truck details or driver details in the form but need
		// to redirect to the search form we to provide truck and driver information as a part of the url
		// to properly initialize fields in the beginning
		if (overrideDetailsFromMetadata) {
			copy = {
				...getOriginalFieldsTruck(copy?.truck__view?.metadata?.truck),
				...getOriginalFieldsDriver(copy?.truck__view?.metadata?.driver),
				...copy,
			};
		}

		if (copy?.truck__view?.metadata) delete copy.truck__view.metadata;
		if (copy?.driver__view?.metadata) delete copy.driver__view.metadata;
		if (copy?.carrier__view?.metadata) delete copy.carrier__view.metadata;

		return convertModelToURLParams(copy, SEARCH_LOAD_URL_PARAMS_MAPPING);
	};

	const updateURLQuery = (model) => {
		rewriteURLParameters(toURLParams(model));
	};

	const sendSummit = (model) => {
		if (searchId && !submitRedirectURL) {
			updateURLQuery(model);
			if (searchId) {
				dispatch(closeMenuPanel());
				if (onSearchSubmitted) onSearchSubmitted(searchId, model, true);
				if (onEvent) onEvent("SEARCH_SUBMITTED", { searchId, model });
			}
		}
	};

	const redirectToURL = (baseUrl, model) => {
		history.push(buildURLParameters(baseUrl, toURLParams(model, true)));
		if (location.pathname?.includes(baseUrl)) {
			window.location.reload();
		}
	};

	if (!form) {
		console.log(`[SearchPanel], form is empty, make sure your role supports search and you specified type ${type}`);
		return null;
	}

	const searchForm = (
		<SmarthopFormView
			content={form}
			mode={"CREATE"}
			data={data}
			noInitValidation={true}
			dataIds={{ carrierId, userId, driverId, ...dataIds }}
			renderPanel={(panel, location) => {
				if (panel === "center_panel" && location === "top") {
					if (!form?.left?.items?.length) {
						return (
							<SearchStatusPanel
								contentHidden={contentHidden}
								nativeMobile={nativeMobile}
								mobile={mobile}
								smallScreen={smallScreen}
								filters={convertURLParamsToModel(readURLParameters(), SEARCH_LOAD_URL_PARAMS_MAPPING)}
								updateFilters={updateFilters}
								searchId={searchId}
								orderBy={orderBy}
								onChangeOrderBy={updateOrderBy}
								onReloadClick={resetSoftFilters}
								onValuesChanged={(key, value) => onPanelValueChanged?.(key, value)}
								onReloadRequested={() => dispatch(incrementDataRevision({ event: "searchResultsChange" + searchId }))}
								onSearchStatusChanged={(state, counters) => {
									if (!!state && state !== "ACTIVE") setOutdated(true);
									onSearchStatusChanged?.(state, counters);
								}}
							/>
						);
					}
				}
			}}
			onSubmit={(model) => {
				// "Search Similar" message hook for React Native
				if (isNativeEnv && (isSearchSimilar || isFindAnotherLoad)) {
					window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "SEARCHSIMILAR", data: { model } }));
					return;
				}

				props?.closeFormDialog?.();
				if (!model.carrier) {
					// when driver and carrier searches for a load UI does not provide carrier selection,
					// so we need to use local carrier id and add it to request body
					const auxCarrierId = isNativeEnv && searchModel.carrier ? searchModel.carrier : carrierId;
					model.carrier = auxCarrierId;
					model.carrier__view = auxCarrierId ? { value: auxCarrierId, label: "ID " + auxCarrierId } : null;
				}
				if (submitRedirectURL) {
					// If driver searching some of the params would be empty but required to create proper search URL
					if (!model.driver__view) model.driver__view = model.truck__view?.metadata?.driver__view;
					if (!model.driver) model.driver = model.driver__view?.value;
					if (!model.max_weight) model.max_weight = model.truck__view?.metadata?.max_weight;
					if (!model.equipment) model.equipment = model.truck__view?.metadata?.equipment;
					if (!model.trailer) model.trailer = model.truck__view?.metadata?.trailer;
					if (!model?.blacklisted_states_ids__view) {
						model.blacklisted_states_ids = model.driver__view?.metadata?.blacklisted_states_ids ?? [];
						model.blacklisted_states_ids__view = toStateViews(
							model.driver__view?.metadata?.blacklisted_states_ids ?? []
						);
					}
					redirectToURL(submitRedirectURL, model);
					return;
				}
				setOutdated(false);
				updateURLQuery(model);
				setSearchModel(model);
				localStorage.setItem("lastSearchRun", moment.utc());
			}}
			trackChangedFields={["ALL"]}
			onChangeCommitted={(model, key) => {
				if (!model.carrier) {
					// when driver and carrier searches for a load UI does not provide carrier selection,
					// so we need to use local carrier id and add it to request body
					model.carrier = carrierId;
					model.carrier__view = carrierId ? { value: carrierId, label: "ID " + carrierId } : null;
				}
				if (props.onChangeCommitted) {
					props.onChangeCommitted(model, key);
				}
				if (key === "carrier") {
					setSelectedCarrierData(model);
					setOutdated(true);
					props?.updateCarrier?.(model.carrier);
					dispatch(fetchCredentialsStatus({ carrierId: model.carrier }));
				} else if (key === "truck") {
					setSelectedTruckData(model);
					setOutdated(true);
					props?.updateTruck?.(model.truck);
				} else if (key === "driver") {
					setSelectedDriverData(model);
					setOutdated(true);
				} else if (
					[
						"rate_total_preferred",
						"rate_per_mile_preferred",
						"miles_preferred",
						"profit_preferred",
						"min_rank_mci",
						"min_credit_score",
						"extra_stops_preferred",
						"age_mins_max_preferred",
						"system_hide_all_flexible",
						"system_show_system_info",
						"system_show_ondeman_only",
						"system_show_estimate_rates",
						"system_show_bid_now_only",
						"system_show_make_offer_only",
						"system_show_team_loads_only",
						"system_show_special_loads_only",
						"system_show_onboarded_only",
						"system_show_specific_owners_only",
						"system_show_new_loads_only",
						"system_show_with_price_only",
						"system_show_plan_match_only",
						"system_show_factorable_only",
						"system_show_hazmat_loads_only",
						"system_show_outliers_only",
						"no_show_international_load",
					].includes(key)
				) {
					setAllData((d) => ({ ...d, ...model }));
					sendSummit(model);
				} else {
					setAllData((d) => ({ ...d, ...model }));
					setOutdated(true);
				}
			}}
			onBlurField={(key, model) => {
				if (!model.carrier) {
					model.carrier = carrierId;
					model.carrier__view = carrierId ? { value: carrierId, label: "ID " + carrierId } : null;
				}
				if (key === "brokers_search") {
					sendSummit(model);
				}
			}}
		>
			{props.children}
		</SmarthopFormView>
	);

	return mobile ? (
		<div className="flex flex-col w-full h-full bg-white">
			{!isNativeEnv && <SearchVersionSwitch key="searchV3_switch" />}
			{searchForm}
		</div>
	) : (
		<>{searchForm}</>
	);
}

export default SearchPanel;
