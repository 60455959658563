const loadTrackings = {
	urlGET: "api/tracking/logs",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "LOAD_TRACKING",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["loadId", "carrier"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "type",
					type: "select",
					label: "Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{ label: "Not Selected", value: "__NOT_SELECTED__" },
						{ label: "Create", value: "create" },
						{ label: "Update", value: "update" },
						{ label: "Webhook", value: "webhook" },
						{ label: "Error", value: "error" },
						{ label: "Delete", value: "delete" },
						{ label: "Cancel", value: "cancel" },
					],
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID" },
			{ key: "carrier", type: "text", label: "Carrier" },
			{ key: "truckId", type: "text", label: "Truck ID" },
			{ key: "loadId", type: "text", label: "Load ID" },
			{ key: "type", type: "text", label: "Type" },
			{ key: "created_at", type: "date", label: "Date" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "view_tracking_details",
						label: "Details",
						dialogBuilder: (item, dataIds) => ({
							mode: "view",
							viewId: "ADMIN_LOAD_TRACKING_DETAILS",
							dataIds: { id: item._id },
						}),
					},
				],
			},
		],
	},
};

export default loadTrackings;
