// Dependencies
import PageTabWrapper from "app/main/common/PageTabWrapper";

// List
import { SmarthopList } from "@smarthop/list";
import entityLedgerList from "@smarthop/list/configs/entityLedgerList";

// Pages
import FuelTransactionPayeesDetailsPayrollView from "./FuelTransactionPayeesDetailsPayrollView";

const FuelTransactionView = ({ dataIds, isView }) => {
	let tabsComponents = [
		{
			label: "Settings",
			component: <FuelTransactionPayeesDetailsPayrollView dataIds={dataIds} isView={true} />,
		},
		{
			label: "Ledger",
			component: (
				<SmarthopList
					key="ledger-table"
					mode="table"
					isView={2}
					config={entityLedgerList({ isFuel: true })}
					dataIds={dataIds}
				/>
			),
		},
	];

	return <PageTabWrapper title="Payroll" variant="narrow" isView={isView} tabs={tabsComponents} />;
};

export default FuelTransactionView;
