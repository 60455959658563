import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";

export default build([
	{
		path: "/profile/trucks/:truckId",
		component: lazy(() => import("./TruckDetailsView")),
	},
	{
		path: "/profile/carriers/:carrierId/trucks/:truckId",
		component: lazy(() => import("./TruckDetailsView")),
	},
]);
