import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getOnboardingData = (carrierId) => {
	return createRequest()
		.get(`api/onboarding/carriers/${carrierId}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updatedOnboardingData = (carrierId) => {
	return createRequest()
		.put(`api/onboarding/carriers/${carrierId}`, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export function updateOnboardingPreselectedPlan(carrierId, data) {
	const url = `api/onboarding/carriers/${carrierId}/plan-preselect`;
	return createRequest()
		.put(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export function getOnboardingPreselectedPlan(carrierId) {
	const url = `api/onboarding/carriers/${carrierId}/plan-preselect`;
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
}

export const updateOnboardingCompanyData = (carrierId, data) => {
	return createRequest()
		.put(
			`api/profile/carriers/${carrierId}/details/company-information`,
			{ data: { ...data } },
			{ headers: createHeaders() }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getOnboardingCompanyData = (carrierId) => {
	return createRequest()
		.get(`api/profile/carriers/${carrierId}/details/company-information`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updatedCostOnboardingData = (carrierId, data) => {
	return createRequest()
		.put(`api/onboarding/carriers/${carrierId}/cost`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
