/**
 * @description returns a value from the localstorage if i don have it,in there i set the default value
 * @returns {String}
 */
const getValueLocalStorage = (value, isBoolean = false) => {
	let localValue = localStorage.getItem(value);
	const defaultValue = value === "statusTracking" ? "NO_MARKETS" : value === "showSmoothTracking" ? true : false;

	if (localValue) {
		if (isBoolean) {
			localValue = localValue === "false" ? false : true;
		}
		return localValue;
	}
	return defaultValue;
};

const getSourceText = (source) => {
	return source === "GPS" ? "via App" : source === "AXLE" ? "via ELD" : "via TMS";
};

export { getValueLocalStorage, getSourceText };
