import { useDispatch } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import PageSection from "../../common/PageSection";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { useState } from "react";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { closeFormDialog } from "app/store/tools/formDialogSlice";

// Service
import { archiveCarrier } from "app/services/carrierServices";

//Gatekeeper
import { isEnabled } from "app/services/featureStorageService";

const CarrierActionsPage = (props) => {
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const [confirmDialog, setConfirmDialog] = useState({});
	const [loading, setLoading] = useState(false);

	const carrierId = props.params.carrierId;
	const Action = ({ title, description, action, color }) => (
		<div className="flex flex-row w-full justify-between mt-28 px-12">
			<Typography className="py-5 text-16">{description}</Typography>
			<Button
				variant="contained"
				color="secondary"
				className={"text-white rounded-md " + (color ?? "")}
				onClick={action}
			>
				{title}
			</Button>
		</div>
	);

	const handleAccept = async () => {
		setLoading(true);
		try {
			await archiveCarrier(carrierId);
			setLoading(false);
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			showSnackbar(snackbar, "Delete succesfully", "success");
			dispatch(closeFormDialog());
		} catch (err) {
			setLoading(false);
			showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
		}

		closeDialog();
	};

	const closeDialog = () => {
		setConfirmDialog({ open: false });
	};

	return (
		<div className={"flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20 h-full"}>
			<PageSection variant="full" title="List Actions">
				<Action
					title="Upload Document"
					description="Bulk Upload External Trips CSV"
					action={() => {
						dispatch(openFormDialog({ viewId: "TRIPS_CSV_IMPORT_VIEW", dataIds: { carrierId } }));
					}}
				/>
				<Action
					title="Send Weekly Report"
					description="Send Weekly Performance Report"
					action={() => {
						dispatch(
							openFormDialog({
								formId: "CARRIER_PERFORMANCE_EMAIL_FORM",
								mode: "CREATE",
								dataIds: { carrierId, typeId: "weekly" },
							})
						);
					}}
				/>
				<Action
					title="Send Montly Report"
					description="Send Montly Performance Report"
					action={() => {
						dispatch(
							openFormDialog({
								formId: "CARRIER_PERFORMANCE_EMAIL_FORM",
								mode: "CREATE",
								dataIds: { carrierId, typeId: "monthly" },
							})
						);
					}}
				/>
				{isEnabled("ENABLE_ARCHIVE_CARRIER") && (
					<Action
						title="Archive Carrier"
						description="Archive carrier"
						color="bg-red"
						action={() => {
							setConfirmDialog({
								open: true,
								title: "Archive Carrier",
								closeMsg: "Close",
								acceptMsg: "Archive",
								typedConfirmation: "archive",
							});
						}}
					/>
				)}
				<>
					<SmarthopConfirmDialog
						open={confirmDialog?.open}
						title={confirmDialog?.title}
						handleClose={closeDialog}
						handleAccept={handleAccept}
						closeMsg={confirmDialog?.closeMsg}
						acceptMsg={confirmDialog?.acceptMsg}
						typedConfirmation={confirmDialog?.typedConfirmation}
					>
						{loading && (
							<div className="flex flex-col items-center">
								<CircularProgress size={32} className="absolute mt-12" />
							</div>
						)}
					</SmarthopConfirmDialog>
				</>
			</PageSection>
		</div>
	);
};

export default CarrierActionsPage;
