function ViewButtonSection(props) {
	return (
		<div
			className={
				"flex items-center w-full pt-4 " +
				(props.orientation === "horizontal"
					? " flex-col space-y-4 space-x-0 md:flex-row md:space-y-0 md:space-x-8 "
					: " flex-col space-y-4 ")
			}
		>
			{props.children}
		</div>
	);
}

export default ViewButtonSection;
