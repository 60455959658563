const createTagForm = {
  urlPOST: "api/tags",
  idKey: "_id",
  triggerEvent: "tagsRevision",
  header: {
    create: { label: "New tag" }
  },
  content: {
    create: {
      items: [
        {
          key: "type",
          type: "select",
          label: "Type",
          options: [
            {
              value: "TRUCK",
              label: "Truck"
            }
          ]
        },
        {
          key: "name",
          type: "text",
          label: "Name",
          required: true
        },
        { type: "action", action: "SUBMIT" }
      ]
    }
  }
}

export default createTagForm;