import { createRequest, createHeaders, throwResErrors } from "./requestUtil";
import { buildURLParameters } from "app/main/utils/urlUtils";

export const getMarketLocations = (data) => {
  const params = {
    origin: data.origin ?? "",
    destination: data.destination ?? "",
    locations: JSON.stringify(data?.locations ?? [])
  }

  const url = buildURLParameters("api/market/locations", params);

  return createRequest()
    .get(url, { headers: createHeaders() })
    .then(res => res.data)
    .catch(error => throwResErrors(error))
}