const chat = {
	urlPOST: "api/profile/loginas",
	idKey: "_id",
	triggerEvent: "",
	listenEvent: "",
	header: {
		create: { label: "Login As" },
		delete: { label: "Logout As" },
	},
	callbacks: {
		onCreate: (data) => {
			let originalToken = localStorage.getItem("tokenSmarthop");
			let originalUserId = localStorage.getItem("userId");
			let originalCarrierId = localStorage.getItem("carrierId");
			localStorage.clear();
			// Saving old user info
			localStorage.setItem("original_tokenSmarthop", originalToken);
			localStorage.setItem("original_userId", originalUserId);
			localStorage.setItem("original_carrierId", originalCarrierId);
			localStorage.setItem("original_location", window.location);
			// Setting new user info
			localStorage.setItem("tokenSmarthop", data.token);
			localStorage.setItem("userId", data.userId);
			localStorage.setItem("carrierId", data.carrierId);
			// Redirect
			window.location = "/";
		},
	},
	content: {
		create: {
			items: [
				{
					key: "type",
					type: "select",
					label: "Login As",
					defaultValue: "AS_CARRIER",
					options: [
						{
							value: "AS_CARRIER",
							label: "A Carrier Owner",
							translate: "",
							showItems: [
								{
									key: "carrier",
									type: "autocomplete",
									label: "Carrier",
									translate: "",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/carriers",
										params: {
											options_show_plan: true,
											options_show_onboarding_status: true,
										},
									},
								},
							],
						},
						{
							value: "AS_USER",
							label: "User",
							translate: "",
							showItems: [
								{
									key: "user",
									type: "autocomplete",
									label: "User",
									translate: "",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/users",
										params: {
											options_show_limit: 200,
											options_show_carrier: true,
										},
									},
								},
							],
						},
					],
				},
				{ type: "action", action: "SUBMIT", label: "Login" },
			],
		},
	},
};

export default chat;
