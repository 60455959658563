import { Typography, Icon } from "@material-ui/core";

import { createIconBadge } from "app/main/utils/uiUtils";
import { createTooltip } from "app/main/utils/tableUtils";

function RowSeparatorComponent({ row, classes }) {
	return (
		<td
			className={"py-6 ml:py-10 border-b-1 w-full pointer-events-none flex items-center pl-16"}
			key={"separator_" + row.original.label}
		>
			<div className="flex flex-row items-center pointer-events-none">
				{row.original.showInProgress && (
					<div className={`text-green pt-4 ${classes.flicker}`}>
						<Icon className={"ml-10 ml:ml-2 text-18"}>cached</Icon>
					</div>
				)}
				{row.original.icon && createIconBadge(row.original.icon, "extra-skiny")}
				<div className="flex flex-row items-start">
					<Typography
						className={`${
							row.original.classes?.text ? row.original.classes.text : "text-12 ml:text-13 font-medium"
						}  ml-2`}
					>
						{row.original.label}
					</Typography>
					{row.original.description &&
						createTooltip(
							<Icon className="flex text-11 ml:text-12 text-blue-500 ml-2 flex-start pointer-events-auto">info</Icon>,
							row.original.description
						)}
				</div>
			</div>
		</td>
	);
}

export default RowSeparatorComponent;
