// TODO FINTECH: check to deprecate invoice template
const carrierInvoiceTemplateForm = {
	urlGET: "billing/carriers/:carrierId/invoice-template",
	urlPUT: "billing/carriers/:carrierId/invoice-template",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Edit Invoice Template" },
	},
	messages: { edit: { success: "Invoice template updated." } },
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "name",
								type: "text",
								label: "Business Name",
								translate: "",
								required: true,
							},
							{
								key: "email",
								type: "text",
								label: "Business Email",
								translate: "",
								required: true,
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "address_line_1",
								type: "text",
								label: "Address Line 1",
								translate: "",
							},
							{
								key: "address_line_2",
								type: "text",
								label: "Address Line 2",
								translate: "",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "city",
								type: "text",
								label: "City",
								translate: "",
							},
							{
								key: "postal_code",
								type: "number",
								label: "Postal Code",
								min: 0,
								field: { maxLength: 5 },
								translate: "",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "state",
								type: "text",
								label: "State",
								translate: "",
							},
							{
								key: "country",
								type: "select",
								label: "Country",
								translate: "",
								defaultValue: "US",
								options: [
									{
										value: "US",
										label: "US",
									},
								],
							},
						],
					},
				},
				{
					key: "custom_fields",
					type: "pattern",
					label: "Custom Fields",
					pattern: { classes: { root: "-mt-16", label: "font-semibold" } },
					content: {
						items: [
							{
								type: "group",
								content: {
									items: [
										{ key: "name", type: "text", label: "Name", required: true },
										{ key: "value", type: "text", label: "Value", required: true },
									],
								},
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default carrierInvoiceTemplateForm;
