import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TooltipView from "./TooltipView";
// Segment
import { createTrackOrPage } from "app/main/segment/segmentEvent";

function CellFirstRow(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const mobile = props.mobile;
	const url = props.url;
	const onClick = props.onClick;
	const dialog = props.dialog;
	const view = props.view;
	const icon = props.icon;
	const variant = props.variant;
	const sameTab = props.sameTab;
	const color = props.color;
	const style = props.style;
	const iconButton = props?.iconButton;

	let label = view?.label?.length > 0 ? view?.label : view?.value ?? props.label;
	if (!label) {
		return null;
	}

	let textLimit =
		variant === "wrapper-multiaccount"
			? 40
			: variant === "widget-calendar"
			? 32
			: variant === "large"
			? 36
			: variant === "spacious"
			? 28
			: variant === "skinny" || variant === "extra-skinny"
			? 22
			: 24;

	const auxTextLimit = props.textLimit ?? style?.textLimit;
	if (auxTextLimit > 0) {
		textLimit = auxTextLimit;
	}

	const font =
		variant === "large"
			? " text-13 ml:text-14 " + (view ? " font-semibold tracking-wide " : "")
			: variant === "spacious"
			? " text-12 ml:text-13  " + (view ? " font-medium " : "")
			: variant === "skinny"
			? " text-12 ml:text-13 " + (view ? " font-medium " : "")
			: variant === "view"
			? " text-12 " + (view ? " font-medium " : "")
			: variant === "extra-large"
			? " text-14 "
			: variant === "widget-calendar"
			? " text-12 ml:text-13 tracking-wide "
			: variant === "wrapper-multiaccount"
			? " text-13 ml:text-14 font-medium"
			: " text-11 ml:text-12 ";

	label = label?.length > textLimit ? label.substring(0, textLimit - 3) + "..." : label;

	if (!url && !dialog && !onClick) {
		if (label?.length > textLimit) {
			return (
				<TooltipView mode="copy" title={label}>
					<Typography
						component="div"
						className={
							"pr-2 ml:pr-4 rounded-md " + (mobile ? "" : " whitespace-nowrap ") + " " + (color ?? "") + " " + font
						}
					>
						{icon && <Icon className="text-13 ml:text-14 -mb-2 mr-2">{icon}</Icon>}
						{label.substring(0, textLimit - 3) + "..."}
					</Typography>
				</TooltipView>
			);
		} else {
			return (
				<Typography
					component="div"
					className={
						"pr-2 ml:pr-4 rounded-md " + (mobile ? "" : " whitespace-nowrap ") + " " + (color ?? "") + " " + font
					}
				>
					{icon && <Icon className="text-14 -mb-2 mr-2">{icon}</Icon>}
					{label}
				</Typography>
			);
		}
	}

	return (
		<>
			{icon && <Icon className="text-13 ml:text-14 text-secondary">{icon}</Icon>}
			<Link
				className={
					(mobile ? "" : " tracking-wide whitespace-nowrap ") +
					(color ?? "") +
					font +
					" cursor-pointer " +
					(props?.disabled ? " pointer-events-none " : "")
				}
				style={variant === "widget-calendar" ? { color: "#243F60", fontWeight: "500", fontFamily: "Poppins" } : style}
				color={"primary"}
				onClick={(event) => {
					event.stopPropagation();
					if (onClick) {
						onClick(event);
					} else if (dialog) {
						if (view?.trackEvent) {
							const track = view?.trackEvent;
							createTrackOrPage(track?.type, track?.data, "track");
						}
						dispatch(openLoadedFormDialog(dialog));
					} else if (url) {
						if (sameTab) {
							history.push(url);
						} else {
							const win = window.open(url, "_blank");
							win.focus();
						}
					}
				}}
			>
				{label}
			</Link>

			{iconButton && (
				<IconButton
					color={iconButton?.icon?.color}
					aria-label="Follow"
					className={iconButton?.classes}
					onClick={iconButton?.action}
				>
					<Icon className={iconButton?.icon?.classes ?? "text-12"}> {iconButton?.icon?.name} </Icon>
				</IconButton>
			)}
		</>
	);
}

export default CellFirstRow;
