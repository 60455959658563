import moment from "moment";
import { Typography, Tabs, Tab, Divider, Select, MenuItem } from "@material-ui/core";
import { useMemo } from "react";
import { WINDOW_MAPPINGS } from "./configs/windwoOptions";

function WidgetPageWindow(props) {
	const type = props.type;
	const selected = props.selected;
	const onWindowChange = props.onWindowChange;

	const overrides = useMemo(() => {
		return {
			"This Month": moment().format("MMMM"),
			"Last Month": moment().subtract(1, "month").format("MMMM"),
			"Before Last Month": moment().subtract(2, "month").format("MMMM"),
		};
	}, []);

	const tabs = WINDOW_MAPPINGS()[type]?.options;
	const current = selected ?? WINDOW_MAPPINGS()[type]?.default;
	const index = tabs?.findIndex((tab) => tab.main === current?.main) ?? 0;

	if (!tabs) {
		return (
			<Typography className="w-full text-center" color="error">
				Not supported window type '{type}''
			</Typography>
		);
	}

	return (
		<div className="flex whitespace-nowrap">
			{tabs?.length > 5 ? (
				<div className="mr-8">
					<Select
						variant="outlined"
						classes={{ select: "py-2", root: "focus:bg-transparent" }}
						className={"flex flex-col items-start p-8 text-13 font-semibold overflow-hidden min-w-150"}
						value={index < tabs.length ? index : 0}
						onChange={(e) => onWindowChange?.(tabs[e.target.value], e.target.value)}
					>
						{tabs.map((item, i) => {
							return (
								<MenuItem key={i} className="w-full flex" value={i}>
									{overrides[item.label] ?? item.label ?? " - "}
								</MenuItem>
							);
						})}
					</Select>
				</div>
			) : (
				<Tabs
					value={index < tabs.length ? index : 0}
					onChange={(_, i) => {
						onWindowChange?.(tabs[i], i);
					}}
					indicatorColor="primary"
					textColor="inherit"
					variant="scrollable"
					className="w-full min-h-36 pl-4 min-w-150 lg:min-w-720"
					classes={{ indicator: "flex justify-center bg-transparent w-full h-full" }}
					TabIndicatorProps={{
						children: <Divider className="w-full h-full rounded-full opacity-50" />,
						style: { transitionDuration: "200ms" },
					}}
				>
					{tabs.map((item, i) => {
						return (
							<Tab
								key={i}
								className={
									"text-13 font-semibold min-h-36 min-w-72 mx-2 px-20 items-center " + (i === 0 ? " md:ml-auto " : "")
								}
								disableRipple
								label={overrides[item.label] ?? item.label ?? " - "}
							/>
						);
					})}
				</Tabs>
			)}
		</div>
	);
}

export default WidgetPageWindow;
