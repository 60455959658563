const adminStateMachineJobsList = {
	urlGET: "api/service/jobs/:serviceId",
	idKey: "_id",
	listenEvent: "jobsRevision",
	segmentKey: "ADMIN_STATE_MACHINE_JOBS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["id"],
		},
		pagination: {
			enabled: false,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "id",
				dir: "desc",
			},
		},
		filters: {
			search: false,
			items: [
				{
					key: "status",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "WAITING",
							label: "Waiting",
						},
						{
							value: "ACTIVE",
							label: "Active",
						},
						{
							value: "DELAYED",
							label: "Delayed",
						},
					],
				},
			],
		},
		groups: {},
		items: [
			{
				key: "id",
				type: "id",
				label: "ID",
			},
			{
				key: "data",
				type: "text",
				label: "Type",
				builder: (item) => item?.data?.type,
			},
			{
				key: "data",
				type: "text",
				label: "Action Type",
				builder: (item) => item?.data?.actionType,
			},
			{
				key: "data",
				type: "text",
				label: "Action ID",
				builder: (item) => item?.data?.actionId,
			},
			{
				key: "data",
				type: "text",
				label: "Process",
				builder: (item) => item?.data?.process,
			},
			{
				key: "status",
				type: "text",
				label: "Status",
			},
		],
	},
};

export default adminStateMachineJobsList;
