const axleTruckTrailerImport = () => ({
	urlGET: "api/axle_import/carriers/:carrierId/import/details",
	idKey: "id",
	listenEvent: "profileRevision",
	segmentKey: "CARRIER_TRUCKS",
	cache: {
		disableInit: true,
	},
	content: {
		pagination: {
			enabled: false,
		},
		table: {
			variant: "skinny",
		},
		defaults: {},
		filters: {},
		items: [
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				table: { width: "28%" },
				viewConfig: {
					variant: "spacious",
					showPhone: false,
					showTeam: true,
					showTrailerSize: true,
				},
			},
			{
				key: "truck_trailer",
				label: "Trailer",
				type: "autocomplete",
				required: true,
				table: { width: "36%" },
				edit: {
					urlPUT: "api/profile/carriers/:carrierId/trucks/:truckId/field",
					triggerEvent: "profileRevision",
					field: {
						key: "truck_trailer",
						required: true,
						type: "autocomplete",
						classes: { cell: "pr-16" },
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers/:carrierId/trailers",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: {
								options_active_only: true,
							},
							disableClearable: (val) => !val || val === "None" || val === "Power Only",
							actionOptions: [
								{
									value: null,
									label: "Power Only",
									metadata: {
										equipment: "PO",
									},
									selectable: true,
								},
								{
									value: "ADD_NEW_TRAILER",
									label: "Add New Trailer",
									icon: "add",
									dialog: {
										viewId: "TRAILER_VIEW",
										mode: "CREATE",
										dataIds: { mode: "CREATE" },
									},
								},
							],
						},
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: {
								truck_trailer: item.truck_trailer__view?.value ?? null,
								truck_trailer__view: {
									...item.truck_trailer__view,
									label: item.truck_trailer__view?.label ?? "Power Only",
									value: item.truck_trailer__view?.value ?? null,
								},
							},
							dataIds: {
								carrierId: dataIds.carrierId,
								truckId: item._id,
							},
						};
						return data;
					},
				},
			},
			{
				key: "driver",
				type: "driver__view",
				label: "Driver",
				table: { width: "36%" },
				required: true,
				edit: {
					urlPUT: "api/profile/carriers/:carrierId/trucks/:truckId/field",
					triggerEvent: "profileRevision",
					field: {
						key: "driver",
						required: true,
						type: "autocomplete",
						classes: { cell: "min-w-192" },
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers/:carrierId/drivers",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: { options_active_only: true },
							disableClearable: (val) => !val || val === "None",
							actionOptions: [
								{
									value: null,
									label: "None",
									selectable: true,
								},
								{
									value: "ADD_NEW_DRIVER",
									label: "Add New Driver",
									icon: "add",
									dialog: {
										viewId: "CARRIER_USER_EDIT_VIEW",
										mode: "CREATE",
										dataIds: { origin: "DRIVER", mode: "CREATE", driverType: "PRIMARY" },
									},
								},
							],
						},
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: {
								driver: item.driver ? item.driver : null,
								driver__view: {
									...(item?.driver__view ?? {}),
									label: item.driver__view?.label ?? "None",
									value: item.driver ? item.driver : null,
								},
							},
							dataIds: {
								carrierId: dataIds.carrierId,
								truckId: item._id,
							},
						};
						return data;
					},
				},
			},
		],
	},
});

export default axleTruckTrailerImport;
