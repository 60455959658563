const issueDeactivate = {
	urlGET: "api/issues",
	urlPUT: "api/issues/:id/deactivate",
	idKey: "_id",
	triggerEvent: "issueRevision",
	listenEvent: "issueRevision",
	header: {
		edit: { label: "Deactivate issue" },
	},
	content: {
		edit: {
			items: [
				{
					type: "message",
					builder: () => `Would you like to deactivate this issue?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL", label: "No", button: { alwaysEnabled: true } },
							{ type: "action", action: "SUBMIT", label: "Yes", button: { alwaysEnabled: true } },
						],
					},
				},
			],
		},
	},
};

export default issueDeactivate;
