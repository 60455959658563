import { useEffect, useState, useCallback, useMemo } from "react";

import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import LiveTrackingContainer from "app/main/widgets/static/LiveTrackingContainer";
import LanesMarketCondition from "app/main/widgets/static/LanesMarketCondition.js";

// Services
import { getUserId, getCarrierId, getAccountId } from "app/services/LoginService";
import { getInfoStaticWidget } from "app/services/staticWidgetServices";

function MarketView(props) {
	useEffect(() => {
		props.setTitle?.("Market");
		// eslint-disable-next-line
	}, []);

	const nativeMobile = props.nativeMobile;

	const userId = getUserId();
	const accountId = getAccountId();

	const [data, setData] = useState([]);
	const [carrierId, setCarrierId] = useState(localStorage.getItem("lastSubcarrierSelected") ?? getCarrierId());
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (!carrierId || !userId) return;

		const config = {
			type: "live-tracking",
			dataIds: {
				boardAccessType: "CARRIER",
				carrierId: carrierId,
				userId: userId,
				accountId: carrierId === "__ALL__" ? accountId : null,
			},
		};

		(async () => {
			setProcessing(true);
			await getInfoStaticWidget(config)
				.then((data) => {
					setData(data);
					setProcessing(false);
				})
				.catch((err) => {
					setProcessing(false);
					console.log(err, " Public Markets");
				});
		})();
	}, [carrierId, userId, accountId]);

	const renderTrackingContainer = useCallback(() => {
		return (
			<LiveTrackingContainer
				key={`TRACKING_CONTAINER_${carrierId}`}
				id={`TRACKING_CONTAINER_${carrierId}`}
				data={data}
				carrierId={carrierId}
				setSelectedCarrier={(val) => setCarrierId(val ?? getCarrierId())}
			/>
		);
		// eslint-disable-next-line
	}, [data]);

	const trackingContainer = useMemo(() => renderTrackingContainer(), [renderTrackingContainer]);

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			processing={processing}
			tabComponents={[
				{
					key: "TRACKING",
					title: "Tracking",
					component: (
						<div className="w-full flex flex-row mb-16">
							<div className="w-3/5 mr-16">{trackingContainer}</div>
							<div className="w-2/5 ml-16">
								<LanesMarketCondition verifiedLoading={processing} showLoading={(value) => setProcessing(value)} />
							</div>
						</div>
					),
				},
			]}
		/>
	);
}

export default MarketView;
