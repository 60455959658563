import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GoogleMap from "google-map-react";
import { useSnackbar } from "notistack";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, Badge, Typography } from "@material-ui/core";
import useHeatMap from "./hooks/useHeatMap";
import useLocations from "./hooks/useLocations";
import { selectByType } from "app/store/marketConditions/mapSlice";

const useStyles = makeStyles(() => ({
	mapContainer: {
		height: "60vh",
		width: "100%",
	},
}));

const StyledBadge = withStyles(() => ({
	badge: {
		top: -5,
		padding: "4px 4px",
	},
}))(Badge);

const MarketConditionsMap = (props) => {
	const classes = useStyles();
	const onProgress = props.onProgress;
	const data = props.data;
	const equipment = props.data?.equipment?.toLowerCase();
	const inverse = props.inverse ?? false;
	const toplanes = props.toplanes ?? true;
	const snackbar = useSnackbar();

	const marketConditions = useSelector(({ marketConditions }) => marketConditions);
	const mapData = useSelector(({ marketConditions }) => selectByType(marketConditions, equipment, inverse, toplanes));
	const [mapObj, setMapObj] = useState({
		map: null,
		maps: null,
	});

	const loadingHeatMap = !!marketConditions?.map?.loading;

	useHeatMap({ props, mapObj, snackbar });
	const { centerMap, locationsCoord, loadingCoords, locationsError, movesCoord } = useLocations({
		props,
		mapObj,
		snackbar,
	});

	const onLoadedMap = ({ map, maps }) => setMapObj({ map, maps });

	useEffect(() => {
		onProgress && onProgress(loadingCoords || loadingHeatMap);
		// eslint-disable-next-line
	}, [loadingCoords, loadingHeatMap]);

	const Marker = ({ text, index, color, badgeContent }) => {
		return (
			<Tooltip title={text?.toUpperCase()} placement="top">
				<StyledBadge badgeContent={badgeContent} color={color}></StyledBadge>
			</Tooltip>
		);
	};

	return (
		// TODO: [FAC-652] Create a generic component to create maps
		<div className="flex flex-col">
			<div className={props.classes?.root ?? classes.mapContainer}>
				<GoogleMap
					key="heat-map"
					bootstrapURLKeys={{
						key: process.env.REACT_APP_MAP_KEY,
					}}
					defaultZoom={data?.defaultZoom ?? 5}
					center={centerMap}
					onGoogleApiLoaded={onLoadedMap}
					yesIWantToUseGoogleMapApiInternals
				>
					{locationsCoord?.map((location, index) => {
						const isDestination = index === locationsCoord.length - 1 && !!data.destination;
						const isOrigin = index === 0 && !!data.origin;
						const stopNumber = !!data.origin ? index : index + 1;
						let color = isOrigin || isDestination ? "primary" : "secondary";
						let badgeContent = isOrigin || isDestination ? " " : stopNumber;
						return (
							<Marker
								key={`market-location-${index}`}
								text={location.location}
								color={color}
								badgeContent={badgeContent}
								lat={location.lat}
								lng={location.lng}
							/>
						);
					})}

					{movesCoord.map((move, index) => {
						let color = "primary";
						let badgeContent = index + 1;
						return (
							<Marker
								key={`move-location-${index}`}
								index={index}
								text={move.d_city}
								color={color}
								badgeContent={badgeContent}
								lat={move.d_lat}
								lng={move.d_lng}
							/>
						);
					})}
				</GoogleMap>
			</div>
			{locationsError && !loadingCoords && data.activeLocations && (
				<div className="flex w-full justify-center">
					<Typography color="error">{locationsError}</Typography>
				</div>
			)}
			{mapData?.errors && !loadingHeatMap && !!data.activeHeatMap && (
				<div className="flex w-full justify-center">
					<Typography color="error">
						{mapData?.errors?.[0]?.message ?? "Oops, something went wrong, please try again"}
					</Typography>
				</div>
			)}
		</div>
	);
};

export default MarketConditionsMap;
