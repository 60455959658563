const adminIssueForm = {
	urlPOST: "api/issues",
	urlPUT: "api/issues/:id",
	urlGET: "api/issues/:id",
	idKey: "_id",
	triggerEvent: "issueRevision",
	listenEvent: "issueRevision",
	header: {
		create: { label: "Create an Issue" },
		edit: { label: "Edit an Issue" },
	},
	messages: {
		create: { success: "Create issue" },
		edit: { success: "Update issue" },
	},
	content: {
		create: { sameAs: "edit" },
		edit: {
			items: [
				{
					key: "issue",
					type: "text",
					label: "Issue",
					required: true,
				},
				{
					key: "priority",
					type: "select",
					label: "Priority",
					required: true,
					defaultValue: "high",
					options: [
						{ label: "High", value: "high" },
						{ label: "Medium", value: "medium" },
					],
				},
				{
					key: "description",
					type: "text",
					label: "Description",
					multiline: { rows: 2 },
				},
				{
					key: "role",
					type: "select",
					label: "Roles",
					required: true,
					defaultValue: "any",
					options: [
						{ label: "Any", value: "any" },
						{
							label: "Specific",
							value: "specific",
							showItems: [
								{
									type: "group",
									content: {
										items: [
											{
												key: "owner",
												type: "checkbox",
												label: "Carrier Owner",
											},
											{
												key: "manager",
												type: "checkbox",
												label: "Carrier Manager",
											},
											{
												key: "dispatcher",
												type: "checkbox",
												label: "Carrier Dispatcher",
											},
										],
									},
								},
								{
									type: "group",
									content: {
										items: [
											{
												key: "driver",
												type: "checkbox",
												label: "Carrier Driver",
											},
											{
												key: "investor",
												type: "checkbox",
												label: "Carrier Investor",
											},
											{
												key: "internal",
												type: "checkbox",
												label: "Internal",
											},
										],
									},
								},
							],
						},
					],
				},
				{
					key: "product",
					type: "select",
					label: "Product",
					required: true,
					defaultValue: "any",
					options: [
						{ label: "Any", value: "any" },
						{
							label: "Specific",
							value: "specific",
							showItems: [
								{
									type: "group",
									content: {
										items: [
											{
												key: "fuel",
												type: "checkbox",
												label: "SmartFuel",
											},
											{
												key: "smartpay",
												type: "checkbox",
												label: "SmartPay",
											},
											{
												key: "invoicing",
												type: "checkbox",
												label: "Invoicing",
											},
											{
												key: "payroll",
												type: "checkbox",
												label: "Payroll",
											},
										],
									},
								},
							],
						},
					],
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminIssueForm;
