import { useState } from "react";

import { getUserDetails } from "app/services/usersServices";
import { useDebouncedEffect } from "./useDebouncedEffect";

const useUserDetails = (userId, carrierId) => {
	const [userDetails, setUserDetails] = useState({});

	let mounted = true;

	useDebouncedEffect(
		async () => {
			if (userId) {
				const fetchedUserDetails = (await getUserDetails(userId, carrierId)) ?? {};
				if (mounted) setUserDetails(fetchedUserDetails);
			}
		},
		[userId],
		50,
		() => (mounted = false)
	);

	return userDetails;
};

export default useUserDetails;
