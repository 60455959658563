import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Dependencies
import moment from "moment";

// Tools
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";

import Smarthop2FAField from "@smarthop/form/fields/Smarthop2FAField.js";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Services
import { createCustomerToken, createVerificationCode } from "app/services/smarthopWalletServices";
import { getCarrierId, getRoleLabel } from "app/services/LoginService";

// Storage
import { setSmarthopWalletToken } from "app/store/wallet/smarthopWalletSlice";

const SmartHopWallet2FAVerificationView = (props) => {
	useEffect(() => {
		props?.setTitle?.("2-Factor Verification (OTP)");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	// Props
	const dataIds = props.dataIds;
	const onDone = props.onDone;

	const dispatch = useDispatch();
	const params = useParams();
	const snackbar = useSnackbar();

	// States
	const [data, setData] = useState(null);
	const [code, setCode] = useState("");
	const [countDown, setCountDown] = useState(null);
	const [disabled, setDisabled] = useState(null);
	const [generatingCodeLoading, setGeneratingCodeLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const isCarrier = getRoleLabel() === "carrier";
	const carrierId =
		dataIds.carrierId ?? props?.params?.carrierId ?? params.carrierId ?? (isCarrier ? getCarrierId() : undefined);

	// When the token and code has been generated, an expiration time is set to place the code.
	useEffect(() => {
		if (data?.expiresIn) {
			const countDownDate = moment(data.expiresIn);
			const interval = setInterval(() => {
				const diff = countDownDate.diff(moment(), "seconds");
				if (diff >= 0) {
					setCountDown(diff);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [data?.expiresIn]);

	// Generate OTP when the component is rendered.
	const generateOTPHandler = async () => {
		try {
			setCode("");
			setError(false);
			setGeneratingCodeLoading(true);
			// A verification code is created with a expiration time.
			const result = await createVerificationCode(carrierId);
			const countDownDate = moment(result.expiresIn);

			setCountDown(countDownDate.diff(moment(), "seconds"));
			setData(result);
			setGeneratingCodeLoading(false);
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0].message, "error", { duration: 10000 });
			setGeneratingCodeLoading(false);
			dispatch(closeFormDialog());
		}
	};

	// Callback to be executed when submitting the form
	const handledAction = async () => {
		setError(false);
		setDisabled(true);
		setLoading(true);

		const verificationCode = data.verificationCode;
		const verificationToken = data?.verificationToken;
		const scope = dataIds.scope;

		try {
			// With the verification token returned by Unit and the verification code written by the user, they are exchanged for customer token.
			const result = await createCustomerToken(carrierId, verificationCode, verificationToken, scope);
			const smarthopWalletToken = result?.smarthopWalletToken;

			// Sensitive operation to be executed after creating the customer token
			if (!!smarthopWalletToken) {
				localStorage.setItem("@smarthopWalletToken", smarthopWalletToken);
				dispatch(setSmarthopWalletToken(smarthopWalletToken));
				if (onDone) {
					onDone();
				}
			}
			dispatch(incrementDataRevision({ event: "smarthopWalletRevision" }));
			dispatch(closeFormDialog());
		} catch (err) {
			showSnackbar(snackbar, err?.errors?.[0].message, "error", { duration: 10000 });
			dispatch(closeFormDialog());
			setDisabled(false);
			setLoading(false);
			setError(true);
		}
	};

	const onChangeCode = (value) => {
		setError(false);
		setCode(value);
		if (value?.length === 6) {
			handledAction();
		}
	};

	return (
		<Smarthop2FAField
			{...props}
			{...data}
			countDown={countDown}
			code={code}
			generatingCodeLoading={generatingCodeLoading}
			onChange={onChangeCode}
			onSendCodeClick={generateOTPHandler}
			disabled={disabled}
			loading={loading}
			error={error}
		/>
	);
};

export default SmartHopWallet2FAVerificationView;
