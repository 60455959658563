import { useMemo } from "react";
import { printCurrency, calculateProfit } from "./CostStructureCalculatorSettings";
import { Typography } from "@material-ui/core";

import { DisplaySection } from "app/main/utils/uiUtils";

const CostStructureResults = ({ data, withFixed = true, className = "", fromCalculator = false, titleControls }) => {
	const results = useMemo(() => {
		if (!data) return null;

		return calculateProfit(data, withFixed);
	}, [data, withFixed]);

	const boldtopLevelFont = "font-semibold font-medium text-14 ml:text-15";
	const topLevelFont = "text-12 ml:text-13";

	return (
		<div className={`w-full ${className}`}>
			<DisplaySection title={"Estimated Profit"} views={titleControls}>
				<div className="flex flex-col items-center justify-end">
					<div className="justify-between flex flex-row w-full pt-2 ml:pt-4 border-b-1">
						<Typography className={topLevelFont}>Total Revenue</Typography>
						<Typography component={"div"} className={topLevelFont}>
							{printCurrency(results?.revenue)}
						</Typography>
					</div>
					<div className="justify-between flex flex-row w-full pt-10 ml:pt-12 border-b-1">
						<Typography className={topLevelFont}>Trip Expenses</Typography>
						<Typography component={"div"} className={topLevelFont}>
							{printCurrency(results?.variableExpenses, "text-red-500")}
						</Typography>
					</div>
					{withFixed && (
						<div className="justify-between flex flex-row w-full pt-10 ml:pt-12 border-b-1">
							<Typography className={topLevelFont}>Allocated Monthly Expenses</Typography>
							<Typography component={"div"} className={topLevelFont}>
								{printCurrency(results?.fixedExpenses, "text-red-500")}
							</Typography>
						</div>
					)}
					<div className="justify-between flex flex-row w-full pt-24 ml:pt-32 border-b-1">
						<Typography className={boldtopLevelFont}>Trip Profit</Typography>
						<Typography component={"div"} className={boldtopLevelFont}>
							{printCurrency(results?.profit)}
						</Typography>
					</div>
					<div className="justify-between flex flex-row w-full pt-10 ml:pt-12 border-b-1">
						<Typography className={topLevelFont + " text-black-500"}>Estimated Trip Duration</Typography>
						<Typography className={topLevelFont}>{results?.duration} day(s)</Typography>
					</div>
					<div className="justify-between flex flex-row w-full pt-10 ml:pt-12 border-b-1">
						<Typography className={boldtopLevelFont}>Profit Per Day</Typography>
						<Typography component={"div"} className={boldtopLevelFont}>
							{printCurrency(results?.profit / (results?.duration < 1 ? 1 : results?.duration))}
						</Typography>
					</div>
				</div>
			</DisplaySection>
		</div>
	);
};

export default CostStructureResults;
