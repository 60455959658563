import React from "react";
import moment from "moment";
import Timeline, { TimelineHeaders, DateHeader, SidebarHeader } from "react-calendar-timeline";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { makeStyles } from "@material-ui/core/styles";
import "react-calendar-timeline/lib/Timeline.css";
import { Button, Icon, IconButton, Tooltip, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getCarrierId } from "app/services/LoginService";
import { openFormDialog, openLoadedFormDialog, closeAllDialogs } from "app/store/tools/formDialogSlice";
import TruckCellView from "@smarthop/list/views/TruckCellView";
import { useHistory } from "react-router";
import { useState, useMemo } from "react";
import CarrierCellView from "@smarthop/list/views/CarrierCellView";
import TripStopPanel from "app/main/profile/trips/TripStopPanel";
import { formatDate } from "app/main/utils/dateUtils";
import { convertPlanAndTruckToSearch, saveSearchesLocalStorage } from "app/main/searchV3/utils/searchUtils";
// Segment
import { CALENDAR_EVENT } from "app/main/segment/segmentType";

const SHOW_DEBUG_INFO = false;

export const StyleWrapper = styled.div`
	.rct-header-root {
		background: #ffffff;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
	}
	.rct-calendar-header {
		border: none !important;
	}
	.weekend {
		background-color: #99cc00;
	}
	.rct-horizontal-lines .rct-hl-odd {
		background: #ffffff;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
	}
	.rct-horizontal-lines .rct-hl-even {
		background: #ffffff;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-0 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-1 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-2 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-3 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-4 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-5 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-vertical-lines .rct-vl.rct-day-6 {
		background: none;
		border-left: 1px dashed rgba(224, 224, 224, 1);
	}
	.rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
		background: none;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
		border-right: 1px solid rgba(224, 224, 224, 1);
	}
	.rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
		background: none;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
		border-right: 1px solid rgba(224, 224, 224, 1);
	}
	.rct-sidebar {
		border-right: none;
	}
	.rct-dateHeader {
		border-bottom: none !important;
		background-color: #ff000000 !important;
		border-left: 1px solid rgba(224, 224, 224, 1);
		border-right: 0px solid rgba(224, 224, 224, 1);
		pointer-events: none;
	}
	.rct-dateHeader-primary {
		border-bottom: none !important;
		background-color: #ff000000 !important;
		border-left: 1px solid rgba(224, 224, 224, 1);
		border-right: 0px solid rgba(224, 224, 224, 1);
		pointer-events: none;
	}
	.rct-items {
	}
	.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
		padding: 0px;
	}
`;

const useStyles = makeStyles((theme) => ({
	addClass: {
		border: "2px solid #E7683D",
	},
}));

const TooltipStyle = withStyles((theme) => ({
	tooltip: {
		fontSize: "13px",
		color: "black",
		backgroundColor: "#f1f1f1",
		maxWidth: "none",
		border: "solid thin black",
	},
}))(Tooltip);

function ItemContainer({
	config: { currentWidthPx, iconWidthPx = 32 },
	content: { icon, start, pickup, delivery, status, end, otherStatuses, delivered },
	progress,
}) {
	const fixedWidthPx = icon ? iconWidthPx : 0;
	const dynamicWidthPx = currentWidthPx - fixedWidthPx;

	const cantFitAnything = currentWidthPx < 40;
	const cantFitStatus = currentWidthPx < 60;
	const cantFitLocations = dynamicWidthPx < 100;
	const cantFitDelivery = dynamicWidthPx < 200;
	const cantFitDeliveryText = dynamicWidthPx < 260;

	if (cantFitAnything) {
		return null;
	}
	return (
		<>
			{icon && (
				<div className="flex" style={{ width: iconWidthPx + "px" }}>
					<div className="flex bg-white rounded-full p-5 ml-3 opacity-80">
						<Icon className="text-grey-700 text-20">{icon}</Icon>
					</div>
				</div>
			)}
			{start && (
				<div className="absolute -mt-48">
					<Typography className="flex text-11 pb-3 font-semibold text-primary truncate whitespace-nowrap min-w-0">
						{moment(start).format("HH:mm")}
					</Typography>
				</div>
			)}
			<div className="flex flex-col flex-1 items-center justify-center" style={{ width: dynamicWidthPx + "px" }}>
				<div className="flex flex-col w-full">
					<div className="flex flex-row w-full px-4">
						{!cantFitLocations && (
							<div className="flex flex-1 items-center justify-start">
								<Icon className="text-white text-20">location_on</Icon>
								<Typography
									dir="rtl"
									className="block text-12 font-light text-white truncate whitespace-nowrap pr-6 pl-2"
									style={{
										maxWidth:
											parseInt(dynamicWidthPx * (delivery ? (cantFitDeliveryText ? 0.7 : 0.4) : status ? 0.4 : 0.9)) +
											"px",
									}}
								>
									{pickup ?? "No Information"}
								</Typography>
								{otherStatuses?.map(({ icon, title }) => (
									<TooltipStyle key={title} title={title}>
										<Icon className="text-white text-20">{icon}</Icon>
									</TooltipStyle>
								))}
							</div>
						)}
						<div className="flex flex-1" />
						{delivery && !cantFitDelivery && !cantFitLocations && (
							<div className="flex flex-1 items-center justify-end">
								<Typography
									dir="rtl"
									className="block text-13 font-light text-white truncate whitespace-nowrap min-w-0 pl-6 pr-2"
									style={{ maxWidth: parseInt(dynamicWidthPx * 0.4) + "px" }}
								>
									{cantFitDeliveryText ? "" : delivery}
								</Typography>
								<Icon className="text-white text-20">emoji_flags</Icon>
							</div>
						)}
						{status && !cantFitStatus && (
							<div className="flex flex-1 items-center justify-end">
								<Typography
									className={
										"block text-10 rounded-lg font-medium text-white truncate whitespace-nowrap min-w-0 px-6 py-1 " +
										(status === "NO PLAN"
											? " bg-red "
											: status === "OUTDATED"
											? " bg-red "
											: status === "UPDATED"
											? " bg-green "
											: "bg-grey")
									}
									style={{ maxWidth: parseInt(dynamicWidthPx * (cantFitLocations ? 0.8 : 0.35)) + "px" }}
								>
									<Icon className="text-white text-13 -mb-3 mr-2">
										{status === "OUTDATED" || status === "NO PLAN"
											? "error_outline"
											: status === "UPDATED"
											? "done_all"
											: ""}
									</Icon>
									{status}
								</Typography>
							</div>
						)}
					</div>
					{delivery && !cantFitLocations && (
						<div className="flex flex-row w-full px-4 items-center justify-start pl-10 pr-14 mt-1">
							{progress > 0 && (
								<>
									<div className="flex rounded-full bg-white w-8 h-8" />
									<div className="flex h-1 border-1 border-white mx-2 " style={{ width: `${progress}%` }} />
									<div
										className="flex h-1 border-1 border-white border-dashed mx-2 relative"
										style={{ width: `${100 - progress}%` }}
									>
										<Icon className="text-18 text-white z-50 absolute" style={{ left: "-9px", top: "-12px" }}>
											local_shipping
										</Icon>
									</div>
									<div className="flex rounded-full bg-white w-8 h-8" />
								</>
							)}
							{!progress && (
								<>
									<div className="flex rounded-full bg-white w-8 h-8" />
									<div className={`flex h-1 flex-1 ${delivered ? "" : "border-dashed"} border-1 border-white mx-2`} />
									<div className="flex rounded-full bg-white w-8 h-8" />
								</>
							)}
						</div>
					)}
				</div>
			</div>
			{end && !cantFitLocations && (
				<div className="absolute -mt-48" style={{ right: "0px" }}>
					<Typography className="flex text-11 pb-3 font-semibold text-primary truncate whitespace-nowrap min-w-0 pr-2">
						{moment(end).format("HH:mm")}
					</Typography>
				</div>
			)}
		</>
	);
}

function CalendarContainer(props) {
	let { truckList, eventList } = props.data;
	if (!truckList || !eventList) {
		truckList = [];
		eventList = [];
	}
	const [closedGroups, setClosedGroups] = useState({});

	const classes = useStyles();

	const carrierId = props?.dataIds?.carrierId ?? getCarrierId();
	const dispatch = useDispatch();
	const history = useHistory();
	eventList = eventList?.map((event) => {
		event.start = moment(event?.start).toDate();
		event.end = moment(event?.end).toDate();
		return event;
	});
	const user = useSelector(({ auth }) => auth.user);

	const canAddTruck = ["CARRIER_OWNER", "CARRIER_MANAGER"].includes(user.role);

	const trucksToShow = useMemo(() => {
		const res = [...truckList];
		return res
			.filter((t) => t.type === "__TITLE__" || !closedGroups[t.carrier.value])
			.sort((a, b) =>
				a.carrier.label > b.carrier.label ? 1 : b.carrier.label > a.carrier.label ? -1 : a.type === "__TITLE__" ? 1 : 0
			);
	}, [truckList, closedGroups]);

	const defaultTimeStart = moment().startOf("day").subtract(15, "hours").toDate();
	const defaultTimeEnd = moment()
		.startOf("day")
		.add(2 * 24 + 15, "hours")
		.toDate();

	const keys = {
		groupIdKey: "id",
		groupTitleKey: "title",
		groupRightTitleKey: "rightTitle",
		itemIdKey: "id",
		itemTitleKey: "title",
		itemDivTitleKey: "title",
		itemGroupKey: "group",
		itemTimeStartKey: "start",
		itemTimeEndKey: "end",
		groupLabelKey: "title",
	};

	const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
		const backgroundColor = item.bgColor;
		const itemWidth = parseInt(getItemProps().style.width);
		const props = getItemProps({
			style: {
				backgroundColor,
				color: item.color,
				borderColor: "white",
				borderStyle: "solid",
				borderWidth: 0.2,
				borderRadius: 4,
				lineHeight: 150,
			},
			onMouseDown: () => {
				if (item?.type === "trip") {
					dispatch(
						openLoadedFormDialog({
							viewId: "TRIP_VIEW",
							dataIds: { tripId: item.id, carrierId: carrierId, fromCalendar: true },
						})
					);
				} else if (item?.type === "plan" && !item.noPlan) {
					dispatch(
						openLoadedFormDialog({
							viewId: "TRUCK_PLAN_VIEW",
							dataIds: {
								carrierId: item.carrier,
								truckId: item.truck,
								planId: item?.noPlan ? null : item?.id,
								fromCalendar: true,
							},
						})
					);
				} else if (item?.type === "plan" && item.noPlan) {
					dispatch(
						openFormDialog({
							viewId: "TRUCK_PLAN",
							mode: "EDIT",
							dataIds: {
								carrierId: item.carrier,
								truckId: item.truck,
							},
						})
					);
				}
			},
		});

		const contentView = (
			<div
				style={{
					backgroundColor,
					left: props.style.left,
					top: props.style.top,
					width: props.style.width,
					height: itemContext.dimensions.height,
				}}
				className="absolute z-50 text-11 p-4 cursor-pointer br-3 rounded-md "
				onMouseDown={props.onMouseDown}
			>
				<div className="flex flex-row justify-between content-center items-center w-full h-full">
					{item.type === "plan" && (
						<ItemContainer
							config={{ currentWidthPx: itemWidth }}
							content={{
								icon: item.outdated ? "event_busy" : item.noPlan ? "date_range" : "event",
								start: !item.noPlan && !item.outdated ? item.start : null,
								pickup: item.location,
								status: item.outdated ? "OUTDATED" : item.noPlan ? "NO PLAN" : "UPDATED",
								otherStatuses: item?.otherStatuses ?? [],
							}}
						/>
					)}
					{item.type === "trip" && (
						<ItemContainer
							config={{ currentWidthPx: itemWidth }}
							content={{
								icon: "local_shipping",
								start: item.start,
								pickup: item.pickup,
								delivery: item.delivery,
								end: item.end,
								delivered: item.delivered,
							}}
							progress={item.metadata?.progress}
						/>
					)}

					{SHOW_DEBUG_INFO ? (
						<Typography className="absolute bg-white rounded px-3 text-11 mr-10">{itemWidth}px</Typography>
					) : null}
				</div>
			</div>
		);

		let tooltip = null;
		if (item.type === "trip") {
			tooltip = (
				<div className="flex flex-col justify-center content-center">
					<Typography className="font-semibold text-14 ml:text-16 pt-10">{`${item.metadata?.label}`}</Typography>
					<Typography className="text-13 ml:text-14">{`${item.metadata?.status}`}</Typography>
					{item.metadata?.activeTrip && (
						<div
							className={`flex flex-row space-x-8 rounded-lg mt-6 mb-4  ${
								item.metadata?.trackingError ? "bg-red" : "bg-green"
							} text-white w-full p-4 content-center justify-center items-center`}
						>
							{item.metadata?.trackingError && (
								<>
									<Icon>close</Icon>
									<Typography className="text-12">{item.metadata?.trackingError}</Typography>
								</>
							)}
							{!item.metadata?.trackingError && (
								<>
									<Icon>check</Icon>
									<Typography className="text-12">
										Load is being Tracked via {item.metadata?.loadTrackedMethod}
									</Typography>
								</>
							)}
						</div>
					)}
					<TripStopPanel condensed={true} classes={{ root: "w-full" }} load={item.metadata} />
				</div>
			);
		} else if (item.type === "plan" && item.planDate && !item.outdated) {
			tooltip = (
				<div className="flex flex-col">
					<Typography className="font-semibold">
						{item.location}
						{item.current_destination ? ` > ${item.current_destination}` : ""}
					</Typography>
					<Typography>{formatDate(item.planDate)}</Typography>
				</div>
			);
		} else if (item.type === "plan" && item.outdated) {
			tooltip = (
				<div className="flex flex-row justify-center items-center space-x-8">
					<Icon className="text-red">event_busy</Icon>
					<Typography>Plan is Outdated</Typography>
				</div>
			);
		} else if (item.type === "plan" && item.noPlan) {
			tooltip = (
				<div className="flex flex-row justify-center items-center space-x-8">
					<Icon className="text-red">date_range</Icon>
					<Typography>No Plan</Typography>
				</div>
			);
		}

		return tooltip ? (
			<TooltipStyle
				placement="top"
				interactive={true}
				leaveDelay={300}
				enterNextDelay={100}
				// with less than 3 trucks the popover will get cut off without portal approach
				PopperProps={{ disablePortal: trucksToShow?.length > 3 }}
				title={
					<div className="bg-white px-20 py-10 -mx-8 rounded -my-4 overflow-y-scroll" style={{ maxHeight: "400px" }}>
						<Typography component="div" className="flex text-12 ml:text-13">
							{tooltip}
						</Typography>
					</div>
				}
			>
				{contentView}
			</TooltipStyle>
		) : (
			contentView
		);
	};

	const onClickToggle = (carrerId) => {
		setClosedGroups((c) => ({
			...c,
			[carrerId]: !c[carrerId],
		}));
	};

	const groupRenderer = ({ group }) => {
		if (group.type === "__TITLE__") {
			return (
				<div
					onClick={() => onClickToggle(group.carrier.value)}
					className="flex flex-row cursor-pointer bg-grey-100 hover:bg-grey-300 pl-8 pr-8"
				>
					<div className="flex flex-row flex-1 h-60">
						<CarrierCellView variant="widget-calendar" view={group.carrier} config={{ showMcNumber: true }} />
					</div>

					<div className="flex flex-row h-60 items-center justify-center pr-2">
						<Icon className="text-18">
							{`${closedGroups[group.carrier.value] ? "arrow_drop_up" : "arrow_drop_down"}`}
						</Icon>
					</div>
				</div>
			);
		}

		return (
			<div className="flex flex-row">
				<div className="flex flex-row h-60 items-center ">
					{group?.metadata?.current_location ? (
						<div className="mr-3">
							<IconButton
								key="close"
								aria-label="Close"
								color="inherit"
								className="h-32 w-32"
								onClick={() => {
									if (localStorage.getItem("SEARCH_VERSION") !== "2") {
										const carrier = {
											_id: group?.carrier.value,
											carrier__view: {
												label: group?.carrier.label,
												value: group?.carrier.value,
											},
										};
										const truck = {
											_id: group?.value,
											truck__view: {
												value: group?.value,
												label: group?.label,
											},
											equipment: group?.metadata?.equipment,
										};
										saveSearchesLocalStorage(convertPlanAndTruckToSearch(carrier, truck, group?.metadata)).then(() => {
											history.push("/search?id=EMPTY");
											dispatch(closeAllDialogs());
										});
									} else {
										dispatch(
											openLoadedFormDialog({
												viewId: "SEARCH_REQUEST_REDIRECT",
												dataIds: {
													carrierId: group?.metadata?.carrier,
													truckId: group?.value,
													eventType: "SEARCH_SIMILAR",
													fromCalendar: true,
												},
											})
										);
									}
								}}
							>
								<Icon className="text-primary text-20" style={{ color: "#E7683D" }}>
									search-icon
								</Icon>
							</IconButton>
						</div>
					) : (
						<TooltipStyle
							title={
								<div className="bg-white px-20 py-10 -my-3 -mx-7 rounded">
									<Typography className="flex text-13">
										Please update plan before searching. Plan allows to automate load search using 'Load Scout'
									</Typography>
								</div>
							}
						>
							<Icon className="text-secondary text-20 mr-10 ml-6">warning</Icon>
						</TooltipStyle>
					)}
					<TruckCellView
						variant="widget-calendar"
						view={{ ...group, trackEvent: { type: CALENDAR_EVENT, data: { type: "truck" } } }}
						config={{
							showPhone: true,
							showPosted: true,
						}}
					/>
					<IconButton
						className="h-32 w-32"
						onClick={() =>
							dispatch(
								openFormDialog({
									viewId: "TRUCK_PLAN",
									mode: "EDIT",
									dataIds: {
										carrierId: group?.carrier?.value,
										truckId: group?.value,
										openSection: "loadscout",
									},
								})
							)
						}
					>
						<Icon className={"text-17  " + (group?.loadScoat?.enabled ? "text-green" : "text-grey")}>
							{group?.loadScoat?.icon}
						</Icon>
					</IconButton>
				</div>
			</div>
		);
	};

	if (!eventList) {
		return null;
	}

	const addTruck = () => {
		dispatch(
			openFormDialog({
				viewId: "TRUCK_VIEW",
				dataIds: {
					carrierId,
					mode: "CREATE",
					fromCalendar: true,
					carrierOption: true,
				},
				mode: "CREATE",
			})
		);
	};

	// We only load trips for last 30 days,
	// making sure scroll is not working beyond that time window
	const minTime = moment().add(-5, "days").startOf("day").valueOf();
	const maxTime = moment().add(14, "days").endOf("day").valueOf();
	const today = moment();
	return (
		<StyleWrapper>
			<div className="flex flex-row justify-between content-center items-center pt-10 pb-4">
				<div className="flex flex-col pt-2">
					<Typography className="text-16 font-medium">Truck Tracker</Typography>
					{truckList.length > 0 && (
						<Typography className="font-medium text-13 text-grey-600 -mt-2">{truckList.length} available</Typography>
					)}
				</div>
				{canAddTruck && (
					<div className="flex flex-row space-x-8 justify-center content-center items-center">
						<Button className={"rounded p-7 " + classes.addClass} variant="outlined" onClick={addTruck}>
							<Icon style={{ color: "#E7683D" }} className="text-12">
								add
							</Icon>
							<Typography className="text-13 font-medium" style={{ color: "#E7683D" }}>
								Add Truck
							</Typography>
						</Button>
					</div>
				)}
			</div>
			<div className="mt-12">
				<Timeline
					groups={trucksToShow}
					items={eventList}
					keys={keys}
					itemTouchSendsClick={false}
					itemHeightRatio={0.65}
					lineHeight={60}
					sidebarWidth={300}
					canMove={false}
					canResize={false}
					defaultTimeStart={defaultTimeStart}
					defaultTimeEnd={defaultTimeEnd}
					itemRenderer={itemRenderer}
					groupRenderer={groupRenderer}
					maxZoom={34 * 24 * 60 * 60 * 1000} // 34 day, need to make sure icons are visible with highest zoom
					minZoom={24 * 60 * 60 * 1000} // 1 day
					onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
						if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
							updateScrollCanvas(minTime, maxTime);
						} else if (visibleTimeStart < minTime) {
							updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
						} else if (visibleTimeEnd > maxTime) {
							updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
						} else {
							updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
						}
					}}
				>
					<TimelineHeaders>
						<SidebarHeader>
							{({ getRootProps }) => {
								return (
									<div
										style={{
											width: 300,
											alignSelf: "center",
											placeContent: "start",
											display: "flex",
											background: "#ffffff",
											height: "30px",
											borderRight: "1px solid rgba(224, 224, 224, 1)",
										}}
									></div>
								);
							}}
						</SidebarHeader>
						<DateHeader
							unit={"day"}
							labelFormat={(dates, _, width) => {
								if (width < 20) return null;
								return (
									<Typography
										className={
											"text-13 font-semibold pt-6" +
											(today.isSame(dates?.[0], "day") ? " text-grey-700 " : " text-grey ")
										}
									>
										{dates?.[0]?.format(width < 50 ? "D" : width < 150 ? "MM/D" : "ddd MM/D")}
									</Typography>
								);
							}}
							className="bg-transparent"
						/>
					</TimelineHeaders>
				</Timeline>
				{truckList.length === 0 && (
					<div
						className="flex w-full flex-col h-80 items-center justify-center absolute left-1/2 top-1/2"
						style={{ transform: "translate(-50%,-50%)" }}
					>
						<Typography color="primary" className="text-13 font-light mx-20 py-6">
							You don't have active trucks
						</Typography>
						<Button variant="contained" color="secondary" onClick={() => history.push("/profile/assets?tab=1")}>
							Manage Trucks
						</Button>
					</div>
				)}
			</div>
		</StyleWrapper>
	);
}

export default CalendarContainer;
