import { hasRequiredGateKeepers, processTierRestrictions } from "app/main/utils/rolesUtils";

const { useMemo } = require("react");
const { useSelector } = require("react-redux");

/**
 * Returns true if param user can see payroll fields
 * @param {*} user
 * @returns
 */
const checkShowPayrollFieldsToUser = (user) => {
	const hasPayrollEnabled = user?.enterpriseFeatures?.payroll_module_flag;
	const hasTier = processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: user.userTier });
	// TODO | Editor is required for now until all payroll fields support Payroll Access
	const hasPayrollAccess = hasRequiredGateKeepers(user, { permission_payroll_access: "editor" });

	const roleRequiredChecks = {
		// TODO | When should Admin view payroll stuff for other users?
		ADMIN: true,
		CARRIER_OWNER: hasTier && hasPayrollEnabled,
		CARRIER_MANAGER: hasTier && hasPayrollEnabled && hasPayrollAccess,
	};

	return !!roleRequiredChecks[user.role];
};

/**
 * Centralizes Payroll authorization logic
 * @returns Helpful payroll calculated fields
 */
const usePayrollHelper = () => {
	const user = useSelector(({ auth }) => auth.user);

	const showPayrollFieldsToSessionUser = useMemo(() => checkShowPayrollFieldsToUser(user), [user]);

	// Add here other useful payroll calculated fields
	return {
		/**
		 * Object containing all the calculated fields for loggedInUser
		 * @type {object}
		 */
		auth: {
			/**
			 * boolean true if logged in user is authorized to view payroll
			 * @type {boolean}
			 */
			showPayrollFieldsToSessionUser,
		},
	};
};

export default usePayrollHelper;
