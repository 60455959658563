import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useState } from "react";

const AccordionSummary = withStyles((theme) => ({
	root: {
		backgroundColor: "rgb(255, 244, 229)",
		color: "rgb(102, 60, 0)",
		padding: 0,
	},
	content: {
		display: "flex",
		alignItems: "center",
	},
	expanded: {
		minHeight: "0 !important",
		margin: "0 !important",
	},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		backgroundColor: "rgba(255, 244, 229,0.8)",
		color: "rgb(102, 60, 0)",
		padding: 0,
		paddingLeft: "20px",
	},
	content: {
		margin: 0,
	},
}))(MuiAccordionDetails);

const useStyles = makeStyles({
	accordion: {
		"&:before": {
			display: "none",
		},
	},
	summaryText: {
		display: "flex",
		alignItems: "center",
	},
});

const WarningDialog = (props) => {
	const handleClose = () => {
		props.setClose(null);
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth="md"
			open={!!props.open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{ style: { backgroundColor: "rgb(255, 244, 229)" } }}
		>
			<DialogContent>
				<DialogContentText component={"div"} id="alert-dialog-description">
					{props.children}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const TreeVisualizerErrorsOrWarnings = ({ errors, setErrors }) => {
	const isError = errors?.hasOwnProperty("errorMessages");
	const messages = isError ? errors.errorMessages : errors?.warnings;
	return (
		<WarningDialog open={errors} setClose={setErrors}>
			<Alert key="error" severity={"warning"}>
				<AlertTitle>Strategy Warning</AlertTitle>
				{isError ? <StrategyErrors messages={messages} /> : <> {messages} </>}
			</Alert>
		</WarningDialog>
	);
};

const StrategyErrors = ({ messages }) => (
	<>
		At this moment given the market conditions we don't have enough data to give a confident strategy. Please try again
		later.
		<StrategyErrorDetails messages={messages} />
	</>
);

const StrategyErrorDetails = ({ messages }) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	const handleChange = () => {
		setExpanded((e) => !e);
	};

	return (
		<Accordion elevation={0} className={classes.accordion} expanded={expanded} onChange={handleChange}>
			<AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Icon> {`${expanded ? "arrow_drop_down" : "arrow_right"}`} </Icon>
				<Typography className={classes.summaryText}> Show details </Typography>
			</AccordionSummary>
			<AccordionDetails>
				{messages?.map((warning, index) => {
					return (
						<Typography key={index} color="inherit" variant="body2" className="p-6 font-light">
							{warning}
						</Typography>
					);
				})}
			</AccordionDetails>
		</Accordion>
	);
};

export default TreeVisualizerErrorsOrWarnings;
