import { useState, useEffect } from "react";
import moment from "moment";
import uniqIdGenerator from "uniqid";
import _ from "lodash";
import { getStrategyTreeById } from "app/services/strategyServices";
import TreeVisualizerWithDrawer from "../tree-visualizer/TreeVisualizerWithDrawer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

const TreeVisualizerWithTimeline = (props) => {
	const { open, tripsResponse, profit } = props;
	const [treeResult, setTreeResult] = useState(null);

	useEffect(() => {
		const convertMoveToTreeNode = (move) => {
			const moveNode = {
				name: `${move.o_city}-${moment(move.start_date).format("MMM-DD-HHmm")}`,
				id: uniqIdGenerator(),
				stop: { ...move, ...move.lane, isTripLine: true },
				children: [],
			};
			if (profit === "TRIP") {
				const trip = tripsResponse.tripsArray.find((trip) => _.isEqual(trip.takenMove, move));
				moveNode.stop.payment = trip.data.est_price;
				moveNode.stop.profit = trip.data.est_price;
			}
			return moveNode;
		};
		const appendTakenMovesToTree = async (firstTrip, takenMoves) => {
			const treeResult = await getStrategyTreeById(firstTrip.strategy._id);
			const moveTree = convertMoveToNodeRecursive(takenMoves.filter(Boolean));
			treeResult?.tree?.children?.push(moveTree);
			setTreeResult(treeResult);
		};
		const convertMoveToNodeRecursive = (takenMoves, index = 0) => {
			if (!takenMoves.length) return;
			const move = takenMoves[index];
			const node = convertMoveToTreeNode(move);
			if (index !== takenMoves.length - 1) node.children = [convertMoveToNodeRecursive(takenMoves, index + 1)];
			return node;
		};

		if (tripsResponse) {
			const firstTrip = tripsResponse.tripsArray[0];
			if (firstTrip.strategy?._id) {
				const takenMoves = tripsResponse.tripsArray.map((trip) => trip.takenMove);
				appendTakenMovesToTree(firstTrip, takenMoves);
			} else {
				setTreeResult(null);
			}
		}
	}, [tripsResponse, profit]);

	return open ? (
		<>
			<h1> Tree Visualizer with timeline </h1>
			<Paper>
				{treeResult ? (
					<TreeVisualizerWithDrawer edgeWidthEnabled={false} result={treeResult} />
				) : (
					<Box p={10}>
						<Typography align="center" style={{ boxShadow: "none" }}>							
							** This trip doesn't have a taken move and/or strategy **
						</Typography>
					</Box>
				)}
			</Paper>
		</>
	) : null;
};

export default TreeVisualizerWithTimeline;
