import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	width: {
		[theme.breakpoints.down("md")]: {
			minWidth: "65vw",
		},
		minWidth: "460px",
	},
	widthNotification: {
		[theme.breakpoints.down("md")]: {
			minWidth: "90vw",
		},
		minWidth: "460px",
	},
}));

function ViewContentSection(props) {
	const classes = useStyles();
	const variant = props.variant;

	return (
		<div
			className={`flex flex-col w-full px-4 text-12 md:text-13 ${
				variant === "notification" ? classes.widthNotification : classes.width
			}`}
		>
			{props.children}
		</div>
	);
}

export default ViewContentSection;
