import Typography from "@material-ui/core/Typography";
import { MULTIFILE_STATUS } from "app/main/multi-file-upload/MultiFileUploadView";

const ParsingFileStatus = ({ status }) => {
	return (
		<div className="ml-4 flex flex-row items-center">
			<div className="flex flex-row">
				<Typography className="text-16 md:text-18">{MULTIFILE_STATUS[status].label ?? "Oops..."}</Typography>
			</div>
		</div>
	);
};

export default ParsingFileStatus;
