import { build } from "../../tools/ConfigBuilder";
import { lazy } from "react";

export default build([
	{
		path: "/admin/transactions/all",
		component: lazy(() => import("./TransactionsStateAllPage")),
	},
	{
		path: "/admin/transactions/booking",
		component: lazy(() => import("./TransactionsStateBookingPage")),
	},
	{
		path: "/dispatcher/transactions/booking",
		component: lazy(() => import("./TransactionsStateBookingPage")),
	},
	{
		path: "/admin/transactions/post-truck",
		component: lazy(() => import("../../post-truck/AdminPostTruckList")),
	},
]);
