const loadTrackingDetails = {
	urlGET: "api/tracking/:trackingId/log/details",
	idKey: "_id",
	listenEvent: "transactionsLogsRevision",
	segmentKey: "LOAD_TRACKING_DETAILS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["key"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "key",
				dir: "desc",
			},
		},
		items: [
			{ key: "key", type: "text", label: "Key" },
			{ key: "value", type: "text", label: "value" },
		],
	},
};

export default loadTrackingDetails;
