import React from "react";
import GoogleMap from "google-map-react";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const StyledBadge = withStyles((theme) => ({
	badge: {
		top: -5,
		padding: "4px 4px",
	},
}))(Badge);

const RecommendedStrtegyMap = (props) => {
  const listCoordinates = props?.listCoordinates;
  const truckPosition = props?.truckPosition;
  const listPolyLine = props?.listPolyLine;
  const classes = props?.classes;

	function renderPolyline(map, maps) {
		if (!listPolyLine?.length) {
			return;
		}

		for (let polyLine of listPolyLine) {
			updatedPolygon(map, maps, polyLine.line, polyLine.color, polyLine.dashedLine);
		}
	}

	function updatedPolygon(map, maps, listLatLong, color, dashedLine = false) {
		const lineSymbol = [
			{
				icon: {
					path: "M 0,-1 0,1",
					strokeOpacity: 1,
					scale: 4,
				},
				offset: "0",
				repeat: "20px",
			},
		];

		// Create the polyline and add the symbols via the 'icons' property.
		const polylineDashed = new maps.Polyline({
			path: listLatLong,
			strokeOpacity: dashedLine ? 0 : 1,
			strokeColor: color,
			strokeWeight: 3,
			icons: dashedLine ? lineSymbol : [],
		});
		polylineDashed.setMap(map);
	}

	function Marker({ text, index }) {
		let color = index === 0 ? "primary" : "secondary";
		let badgeContent = index === 0 ? " " : index;
		return (
			<Tooltip title={text?.toUpperCase()} placement="top">
				<StyledBadge badgeContent={badgeContent} color={color}></StyledBadge>
			</Tooltip>
		);
	}

	function MarkerTruckPosition({ text }) {
		return (
			<Tooltip title={text?.toUpperCase()} placement="top">
				<Icon className={classes.markerTruck + " secondary"}>places</Icon>
			</Tooltip>
		);
	}

	const getMapBounds = (map, maps) => {
		const bounds = new maps.LatLngBounds();

		// Truck Position
		if (truckPosition) {
			bounds.extend(new maps.LatLng(truckPosition.lat, truckPosition.long));
		}

		for (let coordinates of listCoordinates) {
			bounds.extend(new maps.LatLng(coordinates.lat, coordinates.lng));
		}
		map.fitBounds(bounds);
	};

	return (
		<GoogleMap
			key={props.index}
			bootstrapURLKeys={{
				key: process.env.REACT_APP_MAP_KEY,
			}}
			defaultZoom={10} // Disable animation to avoid issues with performance
			defaultCenter={[37.09024, -95.712891]}
			onGoogleApiLoaded={({ map, maps }) => {
				renderPolyline(map, maps);
				getMapBounds(map, maps);
			}}
			yesIWantToUseGoogleMapApiInternals
		>
			{truckPosition && (
				<MarkerTruckPosition text={truckPosition.location} lat={truckPosition.lat} lng={truckPosition.long} />
			)}
			{listCoordinates?.slice(1).map((path, index) => (
				<Marker key={`${path.lat}-${path.lng}-${index}`} text={path.name} index={index} lat={path.lat} lng={path.lng} />
			))}
		</GoogleMap>
	);
};

export default RecommendedStrtegyMap;
