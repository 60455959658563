import { initializeApp } from "firebase/app";
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import { IconButton, Typography } from "@material-ui/core";
import { FaGoogle, FaApple, FaFacebookSquare } from "react-icons/fa";
import i18next from "i18next";
import { useSelector } from "react-redux";

const firebaseConfig = {
	apiKey: "AIzaSyAWXEQ01KZV1nBqfIscOYsV_CQDVIdoZE0",
	authDomain: "hopcopilotapp.firebaseapp.com",
	projectId: "hopcopilotapp",
	storageBucket: "hopcopilotapp.appspot.com",
	messagingSenderId: "648636309969",
	appId: "1:648636309969:web:d893a083d2abe225b3c0f0",
	measurementId: "G-EZRV2E1FTY",
};

const app = initializeApp(firebaseConfig);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
googleProvider.addScope("email");
facebookProvider.addScope("email");
const auth = getAuth(app);

const Divider = ({ children }) => {
	return (
		<div className="flex items-center py-8">
			<div className="border border-solid w-full" />
			<div className="px-24">{children}</div>
			<div className="border border-solid w-full" />
		</div>
	);
};

function SocialLogin({ onSubmit }) {
	useSelector(({ i18n }) => i18n.language);

	const loginWithProvider = (name, provider) => {
		signInWithPopup(auth, provider)
			.then(async (result) => {
				const token = await auth.currentUser.getIdToken();
				return onSubmit({ token, providerName: name });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Typography variant="body2" color="textSecondary" className="md:mb-8 text-center font-light" component="div">
			<Typography
				color="textSecondary"
				className="text-18 hidden md:block text-center text-18 font-light md:pb-4 md:pt-10"
				component="div"
			>
				{i18next.t(`auth:Log in with`)}
			</Typography>
			<div className="flex flex-row items-center justify-evenly md:my-2">
				<IconButton variant="contained" color="primary" onClick={() => loginWithProvider("google", googleProvider)}>
					<FaGoogle size="2em" />
				</IconButton>
				<IconButton variant="contained" color="primary" onClick={() => loginWithProvider("facebook", facebookProvider)}>
					<FaFacebookSquare size="2em" />
				</IconButton>
				<IconButton variant="contained" color="primary" onClick={() => loginWithProvider("apple", appleProvider)}>
					<FaApple size="2em" />
				</IconButton>
			</div>
			<Divider>{i18next.t(`auth:OR`)}</Divider>
		</Typography>
	);
}

export default SocialLogin;
