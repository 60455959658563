/**
 * Turns string access levels into indexes to compare access level
 * left to right
 * @param {*} levels Array of strings representing access levels from left to righ, left being all permissions
 * @param {*} requiredLevel level required for access
 * @param {*} grantedLevel  level granted to user
 * @returns boolean true if has access. False otherwise
 */
const AccessLevelAuthorizer = (levels, requiredLevel, grantedLevel) => {
	const { reqIndex, grantedIndex } = levels.reduce((acc, current, index) => {
		if (current === requiredLevel) {
			acc.reqIndex = index;
		}
		if (current === grantedLevel) {
			acc.grantedIndex = index;
		}
		return acc;
	}, {});
	return grantedIndex <= reqIndex;
};

const tierList = [
	"TIER_LIMITED",
	"TIER_STARTER",
	"TIER_BASIC",
	"TIER_BASIC_PLUS",
	"TIER_PROFESSIONAL",
	"TIER_GROWTH",
	"TIER_ENTERPRISE",
];
const PyramidalAccessLevels = {
	permission_payroll_access: ["admin", "editor", "viewer", "no_access"],
	permission_invoice_access: ["admin", "editor", "viewer", "no_access"],
	permission_trips_access: ["editor", "viewer", "no_access"],
	tier_access: [
		"TIER_ENTERPRISE",
		"TIER_GROWTH",
		"TIER_PROFESSIONAL",
		"TIER_BASIC_PLUS",
		"TIER_BASIC",
		"TIER_STARTER",
		"TIER_LIMITED",
	],
};
const roleRestrictions = {
	permission_users_access: ["CARRIER_MANAGER", "CARRIER_GENERIC", "CARRIER_DRIVER", "CARRIER_DISPATCHER"],
	permission_fleet_config_access: ["CARRIER_MANAGER", "CARRIER_GENERIC", "CARRIER_DRIVER", "CARRIER_DISPATCHER"],
	permission_trips_access: ["CARRIER_MANAGER", "CARRIER_GENERIC", "CARRIER_DRIVER", "CARRIER_DISPATCHER"],
	permission_rates_on: ["CARRIER_MANAGER", "CARRIER_GENERIC", "CARRIER_DRIVER"],
	permission_book_on: ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"],
	permission_search_on: ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"],
	permission_messaging_on: ["CARRIER_DRIVER"],
};

const pyramidalTierRestrictions = {
	TIER_ENTERPRISE: { LIMIT_SUBACCOUNTS: "infinite", LIMIT_USERS: "infinite", LIMIT_TRUCKS: "infinite" },
	TIER_GROWTH: { LIMIT_SUBACCOUNTS: 10, LIMIT_USERS: "infinite", LIMIT_TRUCKS: "infinite" },
	TIER_PROFESSIONAL: { LIMIT_SUBACCOUNTS: 10, LIMIT_USERS: "infinite", LIMIT_TRUCKS: "infinite" },
	TIER_BASIC_PLUS: { LIMIT_SUBACCOUNTS: 3, LIMIT_USERS: "infinite", LIMIT_TRUCKS: "infinite" },
	TIER_BASIC: { LIMIT_SUBACCOUNTS: 3, LIMIT_USERS: "infinite", LIMIT_TRUCKS: "infinite" },
	TIER_STARTER: { LIMIT_SUBACCOUNTS: 1, LIMIT_USERS: 1, LIMIT_TRUCKS: 1 },
	TIER_LIMITED: { LIMIT_SUBACCOUNTS: 0, LIMIT_USERS: 0, LIMIT_TRUCKS: 0 },
};

/**
 * Checks if a user has the required gateKeepers
 * @param {*} user
 * @param {*} requiredGatekeepers object representing the required gatekeepers with values
 * @returns boolean true if has access, false otherwise
 */
const hasRequiredGateKeepers = (user, requiredGatekeepers = {}) => {
	const userGatekeepers = user?.gatekeepers ?? {};
	return Object.getOwnPropertyNames(requiredGatekeepers).every((gk) => {
		if (!userGatekeepers.hasOwnProperty(gk) || user.roleType === "INTERNAL") return true;

		if (typeof requiredGatekeepers[gk] === "string") {
			// Pyramidal gk
			return AccessLevelAuthorizer(PyramidalAccessLevels[gk], requiredGatekeepers[gk], userGatekeepers?.[gk]);
		}

		if (typeof requiredGatekeepers[gk] === "number") {
			return user?.gatekeepers?.[gk] <= requiredGatekeepers[gk];
		}

		// booleans are treated as strict equal
		return requiredGatekeepers[gk] === user?.gatekeepers?.[gk];
	});
};

const parseRolesInfo = (auth, user, requiredGatekeepers) => {
	const visible =
		hasRequiredGateKeepers(user, requiredGatekeepers) &&
		(!auth?.length || auth.find((role) => user.rolesLabels.includes(role)));
	const internalOnly = !!auth?.length && !auth.includes("driver") && !auth.includes("carrier");
	const internalRoles = internalOnly ? '"' + auth?.join('", "') + '"' : null;
	const userInternal =
		user.rolesLabels.includes("administrator") ||
		user.rolesLabels.includes("dispatcher") ||
		user.rolesLabels.includes("ops support");

	return {
		visible,
		internalOnly,
		internalRoles,
		userInternal,
	};
};

const parseGKInfo = (gkTrue, applyForRoles, user) => {
	const visible = !applyForRoles?.find((role) => user.rolesLabels.includes(role)) || !!user?.gatekeepers?.[gkTrue];

	return {
		visible,
	};
};

const isVisibleForUser = (auth, user) => {
	return !auth?.length || !!auth.find((role) => user.rolesLabels.includes(role));
};

/**
 * Checks if a user has access according to the tier
 * @param {*} restrictions array of functionality restrictions
 * @param {*} tier  user tier label
 * @returns boolean true if has access, false otherwise
 */

const processTierRestrictions = ({ restrictions, tier }) => {
	// Specific Tier Restriction
	if (restrictions.includes("ONLY_" + tier)) return false;

	// Hierarchy Tier Restriction
	var filtered = (restrictions ?? []).filter((restriction) => String(restriction).startsWith("TIER"));
	return filtered.every((restriction) => {
		return AccessLevelAuthorizer(PyramidalAccessLevels["tier_access"], restriction, tier);
	});
};

/**
 * Checks if a user can do downgrading
 * @param {Array} tierToDowngrade any tier to compare
 * @param {String} tier  any tier
 * @param {Object} restrictions restrictions to be compare
 * @returns {Boolean, Object, Object} isEnabledToDowngrading, failedRestrictions, restrictionsToDowngrade
 */
const processRestrictionsDowngradeTier = ({ tierToDowngrade, tier, restrictions }) => {
	const isDowngrading = processTierRestrictions({ restrictions: [tierToDowngrade], tier: tier });
	if (!isDowngrading) return { isEnabledToApply: true };

	const restrictionsToDowngrade = pyramidalTierRestrictions[tierToDowngrade];

	const failedRestrictions = {};
	Object.keys(restrictions).forEach((restriction) => {
		const restrictionToDowngrade = restrictionsToDowngrade[restriction];
		const carrierRestriction = restrictions[restriction];
		const isInfinite = restrictionToDowngrade === "infinite";
		failedRestrictions[restriction] = !(isInfinite || carrierRestriction <= restrictionsToDowngrade[restriction]);
	});

	const isEnabledToApply = !Object.values(failedRestrictions).some((elem) => !!elem);
	return { isEnabledToApply, failedRestrictions, restrictionsToDowngrade };
};

/**
 * Function to check user authorization based on the role, role type and gk values
 * EXAMPLE ["carrier", "carrier manager", "EXTERNAL", { permision_search_on: true }]
 */
const hasAuthorization = (authArr, user) => {
	const rolesLabels = user.rolesLabels;
	const roleType = user.roleType;
	const gk = user.gatekeepers;

	/**
	 * If auth array is not defined
	 * Pass and allow
	 */
	if (authArr === null || authArr === undefined) {
		return true;
	}

	/**
	 * if auth array is empty means,
	 * allow only user role is guest (null or empty[])
	 */
	if (authArr.length === 0) {
		return !rolesLabels || rolesLabels.length === 0;
	}

	let permission = false;

	/**
	 * Check user role
	 */
	if (rolesLabels && Array.isArray(rolesLabels)) {
		permission = authArr.some((r) => rolesLabels.indexOf(r) >= 0);
	}

	/**
	 * Check user roleType (INTERNAL, EXTERNAL)
	 * NOTE: If have permission for the user role, it is not necessary to validate the role type.
	 */
	if (!permission && roleType) {
		permission = authArr.includes(roleType);
	}

	/**
	 * Check Gatekeepers
	 * NOTE: If not have permission by role or role type, it is not necessary to validate the gk.
	 * NOTE: The gatekeeper only needs to be checked for external users.
	 * Example {  permission_payrroll: "admin" }
	 */
	const permissionItems = authArr.filter((arr) => typeof arr === "object")?.[0];
	if (permission && roleType === "EXTERNAL" && permissionItems) {
		for (const [key, value] of Object.entries(permissionItems)) {
			if (!permission) break;
			if (!gk.hasOwnProperty(key)) continue;
			permission = gk[key] === value;
		}
	}

	return permission;
};

export {
	parseRolesInfo,
	parseGKInfo,
	isVisibleForUser,
	tierList,
	roleRestrictions,
	hasRequiredGateKeepers,
	processTierRestrictions,
	hasAuthorization,
	processRestrictionsDowngradeTier,
};
