import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { global } from "app/services/requestUtil";
import { updateFormDialog } from "../tools/formDialogSlice";
import ParsingFileStatus from "app/main/profile/trips/ParsingFileStatus";
import { getUserTier } from "app/services/LoginService";

export const fetchFiles = createAsyncThunk("files/fetch", async (params, { dispatch, getState }) => {
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};
	const response = await axios.create({ baseURL: global.SERVER_NAME, headers: headers }).get(`api/multi-upload/list`, {
		headers: headers,
	});

	// Update title for dialogs that have a parsing file
	const userTier = getUserTier();
	if (userTier !== "TIER_STARTER") {
		const state = getState();
		const stateFiles = state?.upload?.files?.list;
		if (stateFiles?.length) {
			response?.data?.files?.forEach((file) => {
				const stateFile = stateFiles?.find((f) => f?.metadata?.uploadId === file?.metadata?.uploadId);
				if (stateFile?.dialogId) {
					dispatch(
						updateFormDialog({
							dialogId: stateFile?.dialogId,
							updateData: {
								titleOverrides: { content: ` - `, component: <ParsingFileStatus status={file?.status} /> },
							},
						})
					);
				}
			});
		}
	}

	return {
		list: response?.data?.files ?? [],
	};
});

export const cancelFile = createAsyncThunk("files/cancelFile", async ({ fileId, uploadId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	await axios.create({ baseURL: global.SERVER_NAME, headers: headers }).put(`api/multi-upload/file/${fileId}/cancel`, {
		headers: headers,
	});

	return {
		uploadId,
	};
});

export const deleteOneFile = createAsyncThunk("files/deleteOne", async ({ fileId, uploadId, carrierId }) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	await axios.create({ baseURL: global.SERVER_NAME, headers: headers }).delete(`api/multi-upload/file/${fileId}`, {
		headers: headers,
	});

	return {
		uploadId,
	};
});

const filesSlice = createSlice({
	name: "files",
	initialState: {
		list: [],
	},
	reducers: {
		updateFile: (state, action) => {
			const uploadId = action.payload.uploadId;
			const data = action.payload.data;
			const fileRecord = state.list.find((file) => file?.metadata?.uploadId === uploadId);
			if (fileRecord) {
				Object.assign(fileRecord, {
					...data,
					metadata: { ...(fileRecord?.metadata ?? {}), ...(data?.metadata ?? {}) },
				});
			}
		},
		addDummyFile: (state, action) => {
			const dummyFile = action.payload;
			state.list = [dummyFile, ...state.list];
		},
	},
	extraReducers: {
		[fetchFiles.fulfilled]: (state, action) => {
			let newList;
			if (state.list.length) {
				newList = action.payload.list.map((item) => {
					const newItem = state.list.find((newItem) => newItem.metadata.uploadId === item.metadata.uploadId);
					return newItem ? { ...newItem, ...item } : item;
				});
			} else {
				newList = action.payload.list;
			}
			state.list = newList;
			state.errors = null;
		},
		[fetchFiles.rejected]: (state) => {
			state.errors = [{ type: "generic", message: "Oops, failed to fetch files..." }];
		},
		[deleteOneFile.fulfilled]: (state, action) => {
			const uploadId = action.payload.uploadId;
			const newList = state.list.filter((item) => item.metadata.uploadId !== uploadId);
			state.list = newList;
			state.errors = null;
		},
		[deleteOneFile.rejected]: (state) => {
			state.errors = [{ type: "generic", message: "Oops, failed to delete file..." }];
		},
		[cancelFile.fulfilled]: (state, action) => {
			const uploadId = action.payload.uploadId;
			const newList = state.list.map((item) => {
				const newItem = item?.metadata?.uploadId === uploadId ? { ...item, status: "canceled" } : item;
				return newItem;
			});
			state.list = newList;
			state.errors = null;
		},
		[cancelFile.rejected]: (state) => {
			state.errors = [{ type: "generic", message: "Oops, failed to cancel file upload..." }];
		},
	},
});

export const { updateFile, addDummyFile } = filesSlice.actions;
export default filesSlice.reducer;
