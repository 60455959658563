import { useMemo } from "react";
import { DisplaySection } from "app/main/utils/uiUtils";
import {
	parseEquation,
	calculateCost,
	printCurrency,
	costSectionsFields,
	getActiveExpenses,
} from "./CostStructureCalculatorSettings";

import { Icon, Tooltip, Typography, withStyles } from "@material-ui/core";

const TooltipStyle = withStyles((theme) => ({
	tooltip: {
		fontSize: "13px",
		color: "red",
		backgroundColor: "#f1f1f1",
		maxWidth: "none",
		border: "solid thin black",
	},
}))(Tooltip);

const VisualizeEquation = ({ level, equation, data, fromCalculator = false }) => {
	const equationToUse = useMemo(() => parseEquation(equation, data), [equation, data]);

	return (
		<div className={"flex flex-wrap items-center justify-start"}>
			{equationToUse.map((i, index) => {
				if (Array.isArray(i[0])) {
					return (
						<div key={index} className="flex flex-row items-center justify-center">
							<div className="border-1 p-3 m-3 border-grey-300">
								<VisualizeEquation level={(level ?? 0) + 1} equation={i} data={data} />
							</div>
						</div>
					);
				}

				const [label, fieldPath, fieldType, options] = i;
				if (fieldType === "__OP__") {
					return (
						<div key={index + fieldPath} className="flex flex-row items-center justify-center">
							<Typography className="mr-8 ml-8 text-16 h-full text-red-600 font-bold h-40 text-center">
								{fieldPath === "*" ? "x" : fieldPath}
							</Typography>
						</div>
					);
				}

				let value = 0;
				let warning = null;
				if (fieldType === "__DISPLAY_RESULT__") {
					value = calculateCost(fieldPath, data, fromCalculator);
				} else if (fieldType === "__CONSTANT__") {
					value = fieldPath;
				} else if (fieldType === "__CALLBACK__") {
					value = fieldPath(data, "label");
					if (typeof value === "object") {
						warning = value.warning;
						value = value.value;
					}
				} else if (fieldType === "__SPECIAL_FIELD__") {
					if (fieldPath === "__NUMBER_OF_DRIVERS__") {
						if (fromCalculator) {
							value = data["secondaryDriver"] && data["secondaryDriver"]["secondaryDriver"] ? 2 : 1;
						} else {
							value = data["secondaryDriver"] ? 2 : 1;
						}
					}
				} else {
					const [model, field] = fieldPath.split(".");
					value = data[model] ? data[model][field] ?? 0 : 0;
				}

				if (options?.min && value < options.min) {
					value = options.min;
				}

				if (options?.default && !value) {
					value = options.default;
				}

				if (options?.formatting) {
					if (options.formatting === "__PERCENT__") {
						value = `${value}%`;
					} else if (options.formatting === "__MILES__") {
						value = `${value} mi`;
					} else if (options.formatting === "__DAYS__") {
						value = parseFloat(value).toFixed(2);
						value = `${value} day(s)`;
					} else if (options.formatting === "__USD__") {
						value = parseFloat(value).toFixed(2);
						value = `$${value}`;
					} else if (options.formatting === "__DATE__") {
						value = new Date(value).toLocaleString("en-us", {
							day: "numeric",
							hour12: true,
							hour: "numeric",
							month: "short",
						});
					}
				}

				return (
					<dl key={index + fieldPath} className="text-center p-4 m-1 bg-gray-300 rounded-lg">
						<dt className="text-xs px-4 min-w-120">{label}</dt>
						<dd className="text-xs">
							<div className="flex flex-row space-x-4 justify-center items-center">
								{warning && (
									<TooltipStyle title={warning}>
										<div className="flex flex-row space-x-4 justify-center items-center text-red">
											<Icon className="text-18">warning</Icon>
											<div>{value}</div>
										</div>
									</TooltipStyle>
								)}
								{!warning && value}
							</div>
						</dd>
					</dl>
				);
			})}
		</div>
	);
};

const VisualizeEquations = ({ data, fromCalculator = false, withFixed = true }) => {
	const activeExpenses = useMemo(() => getActiveExpenses(data), [data]);

	return (
		<>
			{activeExpenses.map((cost) => {
				if (fromCalculator && cost.label === "Secondary Driver Pay" && !data?.secondaryDriver?.secondaryDriver)
					return null;
				if (!fromCalculator && cost.label === "Secondary Driver Pay" && !data?.secondaryDriver) return null;
				if (!withFixed && cost.label === "Allocated Monthly Expenses") return null;

				const title = (
					<div className="flex flex-row items-center">
						{costSectionsFields[cost.label].icon && typeof costSectionsFields[cost.label].icon === "string" && (
							<Icon className="mr-4 text-18">{costSectionsFields[cost.label].icon}</Icon>
						)}
						{costSectionsFields[cost.label].icon &&
							typeof costSectionsFields[cost.label].icon !== "string" &&
							costSectionsFields[cost.label].icon(18)}
						<Typography className="mr-4 font-semibold font-medium text-14">{cost.label}</Typography>
						{costSectionsFields[cost.label].description && (
							<Tooltip classes={{ tooltip: "text-12 font-normal" }} title={costSectionsFields[cost.label].description}>
								<Icon className="mr-4 text-16 mb-1">info</Icon>
							</Tooltip>
						)}
					</div>
				);

				return (
					<DisplaySection key={cost.label} title={title}>
						<div className="flex flex-row items-center justify-end">
							<div className="mr-auto flex justify-center">
								<VisualizeEquation level={0} equation={cost.equation} data={data} fromCalculator={fromCalculator} />
							</div>
							<div className="flex justify-end min-w-120">
								<div className="mr-8 ml-8 text-md">=</div>
								<div className="flex text-md min-w-72 justify-end">
									{printCurrency(calculateCost(cost.equation, data, fromCalculator), "text-red-500")}
								</div>
							</div>
						</div>
					</DisplaySection>
				);
			})}
		</>
	);
};

export default VisualizeEquations;
