const chat = {
	urlGET: "api/messenger/participants/:id",
	urlPUT: "api/messenger/participants/:id",
	urlDELETE: "api/messenger/participants/:id",
	idKey: "_id",
	triggerEvent: "chatRevision",
	listenEvent: "chatRevision",
	header: {
		edit: { label: "Edit Participant" },
		delete: { label: "Delete Participant" },
		view: { label: "View Participant" },
	},
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) =>
						`Would you like to permanently delete chat participant '${item.metadata?.user?.email}' with participant ID '${item._id}' and user ID '${item._id}' from chat with ID '${item.chat}' ?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		edit: {
			items: [
				{
					key: "lastReceivedSentUnix",
					type: "number",
					label: "Last Reveived Message Unix Sent Time",
					translate: "",
				},
				{
					key: "lastSeenSentUnix",
					type: "number",
					label: "Last Seen Message Unix Sent Time",
					translate: "",
				},
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default chat;
