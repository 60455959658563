import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "app/store/auth/userSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { capitalizaEveryWordFirstLetter } from "helpers/Formatter";

function UserMenu(props) {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const linkedMainAccounts = user.linkedMainAccounts;

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const isConnected = useSelector(({ socket }) => socket.connection.isConnected);
	const connectedColor = isConnected ? "bg-green" : "bg-grey";

	return (
		<>
			<Button className="min-h-40 min-w-40 px-12 md:px-24 py-0 md:py-6 rounded-none" onClick={userMenuClick}>
				<div className="flex flex-col items-startd">
					<Badge variant="dot" classes={{ badge: `mt-4 -mr-6 ${connectedColor}` }}>
						<Typography component="span" className="font-semibold flex whitespace-nowrap text-13">
							{user.first_name} {user.last_name}
						</Typography>
					</Badge>
					<div className="flex flex-row items-start -mt-3">
						{user.isTestCarrier &&
							(user.roles?.includes(2) || user.roles?.includes(22) || user.roles?.includes(23)) && (
								<Icon className="text-12 text-blue mt-2 mr-2 -ml-2">star</Icon>
							)}
						<Typography className="text-11 font-medium whitespace-nowrap" color="textSecondary">
							{capitalizaEveryWordFirstLetter(user.role ?? "Unknown Role")}
						</Typography>
					</div>
				</div>

				<Icon className="ml-20 -mr-5" style={{ color: "#A3A3A3" }}>
					arrow_drop_down
				</Icon>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				classes={{
					paper: "py-8",
				}}
			>
				{!user.rolesLabels || user.rolesLabels.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/profile/details" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="My Profile" />
						</MenuItem>
						{user.rolesLabels.includes("administrator") &&
							!localStorage.getItem("original_tokenSmarthop") &&
							user?.gatekeepers?.permission_account_impersonation_on && (
								<MenuItem
									onClick={() => {
										dispatch(openLoadedFormDialog({ formId: "ADMIN_LOGIN_AS_FORM", mode: "CREATE", dataIds: {} }));
										userMenuClose();
									}}
								>
									<ListItemIcon className="min-w-40">
										<Icon>compare_arrows</Icon>
									</ListItemIcon>
									<ListItemText primary="Login As" />
								</MenuItem>
							)}
						{(!localStorage.getItem("switchType") || localStorage.getItem("switchType") === "SWITCH") &&
							linkedMainAccounts?.length > 1 && (
								<MenuItem
									onClick={() => {
										dispatch(openLoadedFormDialog({ viewId: "USER_ACCOUNTS_VIEW" }));
										userMenuClose();
									}}
								>
									<ListItemIcon className="min-w-40">
										<Icon>compare_arrows</Icon>
									</ListItemIcon>
									<ListItemText primary="Switch Account" />
								</MenuItem>
							)}
						{localStorage.getItem("switchType") === "LOGIN" && localStorage.getItem("main_tokenSmarthop") && (
							<MenuItem
								onClick={() => {
									let mainToken = localStorage.getItem("main_tokenSmarthop");
									let mainUserId = localStorage.getItem("main_userId");
									let mainCarrierId = localStorage.getItem("main_carrierId");
									let mainLocation = localStorage.getItem("main_location");
									localStorage.clear();
									// Setting new user info
									localStorage.setItem("tokenSmarthop", mainToken);
									localStorage.setItem("userId", mainUserId);
									localStorage.setItem("carrierId", mainCarrierId);
									// Redirect
									window.location = mainLocation ?? "/";
									userMenuClose();
								}}
							>
								<ListItemIcon className="min-w-40">
									<Icon>compare_arrows</Icon>
								</ListItemIcon>
								<ListItemText primary="Logout As Sub-account" />
							</MenuItem>
						)}
						{localStorage.getItem("original_tokenSmarthop") && (
							<MenuItem
								onClick={() => {
									let originalToken = localStorage.getItem("original_tokenSmarthop");
									let originalUserId = localStorage.getItem("original_userId");
									let originalCarrierId = localStorage.getItem("original_carrierId");
									let originalLocation = localStorage.getItem("original_location");
									localStorage.clear();
									// Setting new user info
									localStorage.setItem("tokenSmarthop", originalToken);
									localStorage.setItem("userId", originalUserId);
									localStorage.setItem("carrierId", originalCarrierId);
									// Redirect
									window.location = originalLocation ?? "/";
									userMenuClose();
								}}
							>
								<ListItemIcon className="min-w-40">
									<Icon>compare_arrows</Icon>
								</ListItemIcon>
								<ListItemText primary="Logout As Current" />
							</MenuItem>
						)}
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
