import { createTooltip } from "app/main/utils/tableUtils";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

export const INVOICE_AMOUNT_LIMIT = 8000;

const renderResultItem = (label, value, textColor, icon = null, description) => {
	const extraStyle =
		(value === "YES" || value === "NO" ? " font-bold tracking-wider " : " font-normal ") + "" + (textColor ?? "");
	return (
		<div className={"flex flex-row items-center border-b-1 border-grey-300 pb-2 pt-14 w-full"}>
			<Typography className={`flex text-black text-12 ml:text-13 break-normal`}>{label}</Typography>
			<div className={"flex flex-1 justify-end items-center"}>
				{icon ? (
					createTooltip(
						<Icon className="text-20 text-red-500">{icon}</Icon>,
						<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
							{description}
						</Typography>
					)
				) : description ? (
					createTooltip(
						<Typography className={"flex text-13 break-normal text-medium text-grey-700 " + extraStyle}>
							{!value ? "-" : value}
						</Typography>,
						<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
							{description}
						</Typography>
					)
				) : (
					<Typography className={"flex text-13 break-normal text-grey-700 " + extraStyle}>
						{!value ? "-" : value}
					</Typography>
				)}
			</div>
		</div>
	);
};

const USER_CARRIERS = (native) => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	required: true,
	field: {
		noErrorMessage: true,
		variant: !native ? "row" : null,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
		params: { options_active_only: true },
	},
});

const USER_CARRIER_TRUCKS = (native) => ({
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	required: true,
	field: {
		noErrorMessage: true,
		variant: !native ? "row" : null,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/trucks",
		params: {
			options_metadata_truck_plan: true,
			options_active_only: true,
			label_format_show_driver: true,
			options_show_cost_structure: true,
		},
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
	},
});

const CARRIER_TRUCKS = (native) => ({
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	required: true,
	field: {
		noErrorMessage: true,
		variant: !native ? "row" : null,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers/:carrierId/trucks",
		params: {
			options_metadata_truck_plan: true,
			options_active_only: true,
			label_format_show_driver: true,
			options_show_cost_structure: true,
		},
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
	},
});

const brokerCredit = (native) => ({
	key: "brokerCredit",
	type: "component",
	builder: (item) => {
		return (
			<div className="flex w-full px-6 md:px-0">
				<div className="md:flex flex-row w-full md:space-x-36">
					<div className="md:w-1/2">
						{renderResultItem(
							"Credit Score",
							item?.broker__view?.metadata?.creditScore,
							null,
							item?.broker__view?.metadata?.possible_scammer ? "warning" : null,
							"Possible scammer"
						)}
					</div>
					<div className="md:w-1/2">{renderResultItem("Contact", item?.broker__view?.metadata?.contact)}</div>
				</div>
			</div>
		);
	},
});

const brokerScammer = (native) => ({
	key: "brokerScammer",
	type: "component",
	builder: (item) => {
		const smartpayEnabled = item?.broker__view?.metadata?.smartpayEnabled;
		const exceedLimit = item?.price > INVOICE_AMOUNT_LIMIT;
		return (
			<div className="flex w-full px-6 md:px-0">
				<div className="md:flex flex-row w-full md:space-x-36">
					<div className="md:w-1/2">
						{renderResultItem("Net Payment Term", item?.broker ? item?.broker__view?.metadata?.creditScore ?? 30 : "")}
					</div>
					<div className="md:w-1/2">
						{renderResultItem(
							"SmartPay",
							smartpayEnabled === undefined
								? null
								: smartpayEnabled
								? !exceedLimit
									? "YES"
									: `NO (Rate limit $${INVOICE_AMOUNT_LIMIT})`
								: "NO",
							smartpayEnabled === undefined
								? null
								: smartpayEnabled && !exceedLimit
								? "text-green-800"
								: "text-red-800",
							null,
							exceedLimit ? `The rate exceeds the limit of $${INVOICE_AMOUNT_LIMIT}` : null
						)}
					</div>
				</div>
			</div>
		);
	},
});

const createCalculatorForm = (carrierSelection, carrierId, role, native, hasSmartPayProgram) => {
	return {
		form: { noErrorMessage: true },
		items: [
			...(hasSmartPayProgram
				? [
						{
							type: "section",
							label: "Broker Details and SmartPay Eligibility",
							section: { classes: { label: "text-14", root: native ? "mb-6" : "mt-10 mx-0 -mb-6" } },
						},
						{
							key: "broker",
							type: "autocomplete",
							label: "Broker",
							required: false,
							field: {
								noErrorMessage: true,
								variant: !native ? "row" : null,
							},
							autocomplete: {
								provider: "smarthop",
								url: "api/consts/brokers",
								showDescription: true,
								listenEvent: "profileRevision",
								params: {
									sortOrder: "asc",
									sortBy: "clientid",
									options_carrier_id_search: carrierId,
									limit: 10,
								},
								actionOptions: [
									...(!native
										? [
												{
													value: "ADD_NEW_CUSTOMER",
													label: "Add New Customer",
													disabled: ["CARRIER_OWNER", "CARRIER_MANAGER"].includes(role) && carrierId ? false : true,
													icon: "add",
													dialog: {
														viewId: "BROKER_EDIT_VIEW",
														mode: "CREATE",
														dataIds: { carrierId },
													},
												},
										  ]
										: []),
								],
							},
						},
						brokerCredit(native),
						brokerScammer(native),
				  ]
				: []),
			{
				type: "section",
				label: "Calculate Load Profitability",
				section: {
					classes: {
						label: "text-14",
						root: native ? "mb-6 mt-28" : hasSmartPayProgram ? "mx-0 -mb-6 mt-32" : "mt-10 mx-0 -mb-6",
					},
				},
			},
			...(carrierSelection ? [USER_CARRIERS(native), USER_CARRIER_TRUCKS(native)] : [CARRIER_TRUCKS(native)]),
			{
				key: "truck_origin",
				type: "autocomplete",
				label: "Empty Location",
				required: true,
				autocomplete: {
					provider: "trimblemaps",
					params: { componentRestrictions: { country: "us", includeOnly: "city" } },
					icon: "location",
				},
				field: {
					noErrorMessage: true,
					variant: !native ? "row" : null,
				},
				rules: {
					params: {
						valueRef: "truck",
						paramKey: "options_truck_id_search",
					},
				},
			},
			{
				key: "origin",
				type: "autocomplete",
				label: "Pickup Location",
				required: true,
				field: {
					noErrorMessage: true,
					variant: !native ? "row" : null,
				},
				autocomplete: {
					provider: "trimblemaps",
					params: { componentRestrictions: { country: "us" } },
					icon: "location",
				},
			},
			{
				key: "ready",
				type: "picker",
				label: "Pickup Date",
				required: true,
				field: {
					noErrorMessage: true,
					variant: !native ? "row" : null,
				},
				picker: {
					notClearable: true,
					type: "datetime",
				},
			},
			{
				key: "locations",
				type: "pattern",
				pattern: {
					disableInsertButtons: true,
					variant: !native ? "narrow" : null,
					classes: { root: !native ? "-mb-14 mt-7" : "mb-2 mt-6" },
				},
				addButton: { label: "Add Stop", noSeparators: true, classes: { root: "mt-14 mb-14" } },
				content: {
					items: [
						{
							key: "location",
							type: "autocomplete",
							label: "Stop",
							required: true,
							field: {
								noErrorMessage: true,
								variant: !native ? "row" : null,
							},
							autocomplete: {
								provider: "trimblemaps",
								params: { componentRestrictions: { country: "us" } },
								icon: "location",
							},
						},
					],
				},
			},
			{
				key: "destination",
				type: "autocomplete",
				label: "Destination",
				required: true,
				field: {
					noErrorMessage: true,
					variant: !native ? "row" : null,
				},
				autocomplete: {
					provider: "trimblemaps",
					params: { componentRestrictions: { country: "us" } },
					icon: "location",
				},
			},
			{
				key: "dropready",
				type: "picker",
				label: "Delivery Date",
				required: true,
				field: {
					noErrorMessage: true,
					variant: !native ? "row" : null,
				},
				picker: {
					notClearable: true,
					type: "datetime",
				},
			},
			{
				key: "price",
				type: "number",
				label: "Final Rate",
				required: true,
				min: 1,
				max: 99999,
				field: {
					noErrorMessage: true,
					variant: !native ? "row" : null,
				},
			},
		],
	};
};

export default createCalculatorForm;
