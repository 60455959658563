import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectChats } from "app/store/messenger/chatSlice";
import ListItemText from "@material-ui/core/ListItemText";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { setActiveChat } from "app/store/messenger/messageSlice";
import { buildChatAvatar, buildChatWarningAvatar } from "../utils/chatUtils";
import { getUserId, isRoleExternal } from "app/services/LoginService";
import { buildMessagePreview, buildChatTitle } from "../utils/chatUtils";
import MessageViewLoadBookedProcessed from "./messages/MessageViewLoadBookedProcessed";
import MessageViewRequestLocation from "./messages/MessageViewLoadRequestLocation";
import MessageGeoFence from "app/main/chat/messages/MessageGeoFence";

function MessageNotificationCard(props) {
	const dispatch = useDispatch();
	const onClose = props.onClose;
	const { message } = props;
	const { chat, user, truck } = message?.metadata ?? {};
	const chats = useSelector(selectChats);
	const visible = useSelector(({ messenger }) => messenger.chats.visible);
	const viewerIsOwner = isRoleExternal();
	const incoming = message.user !== getUserId();
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);

	const handleClose = (e) => {
		e?.stopPropagation();
		if (props.onClose) {
			if (onClose) onClose(message._id);
		}
	};

	const handleClick = () => {
		handleClose();
		let chat = chats.find((chat) => chat._id === message.chat);
		if (chat) {
			dispatch(setActiveChat(chat));
			if (!visible) {
				dispatch(openFormDialog({ viewId: "CHAT_APP" }));
			}
		}
	};
	let ackRequired = message?.content?.notification?.variant === "ACK_REQUIRED";

	const bgColor = ackRequired
		? "bg-orange-600"
		: "REQUEST_GPS_TRACKING"
		? "bg-white-600"
		: chat?.type === "TRUCK_EXTERNAL"
		? "bg-blue-700"
		: "bg-blue-700";

	useEffect(() => {
		if (activeChat && activeChat._id === message.chat && visible) {
			if (onClose) onClose(message._id);
		}
	}, [activeChat, message, visible, onClose]);

	if (message?.type === "BID_BOOK_TRANSACTION") {
		return (
			<Card
				className={clsx(
					"flex items-center relative w-full rounded-16 py-12 md:py-16 px-14 md:px-20 min-h-40 md:min-h-64 pr-40 shadow border-2 border-lime-700" +
						bgColor
				)}
				elevation={0}
			>
				<div className="flex relative flex-row items-center cursor-pointer -ml-6" onClick={handleClick}>
					<MessageViewLoadBookedProcessed
						variant="notification"
						message={message}
						incoming={incoming}
						viewerIsOwner={viewerIsOwner}
					/>
				</div>
				<IconButton
					disableRipple
					className="top-0 right-0 absolute py-14 md:py-16 px-14 md:px-16 mt-4 mr-8 md:mt-4 md:mr-0"
					size="small"
					onClick={handleClose}
				>
					<Icon className="text-20 md:text-24 opacity-75">close</Icon>
				</IconButton>
			</Card>
		);
	}

	if (message?.type === "REQUEST_GPS_TRACKING") {
		return (
			<Card
				className={clsx(
					"flex items-center relative w-full rounded-16 py-12 md:py-16 px-14 md:px-20 min-h-40 md:min-h-64 pr-40 shadow border-2 border-lime-700" +
						bgColor
				)}
				elevation={0}
			>
				<div className="flex relative flex-row items-center cursor-pointer -ml-6" onClick={handleClick}>
					<MessageViewRequestLocation
						variant="notification"
						message={message}
						incoming={incoming}
						viewerIsOwner={viewerIsOwner}
					/>
				</div>
				<IconButton
					disableRipple
					className="top-0 right-0 absolute py-14 md:py-16 px-14 md:px-16 mt-4 mr-8 md:mt-4 md:mr-0"
					size="small"
					onClick={handleClose}
				>
					<Icon className="text-20 md:text-24 opacity-75">close</Icon>
				</IconButton>
			</Card>
		);
	}

	if (message?.type === "GEO_FENCE_CONFIRM") {
		return (
			<Card
				className={clsx(
					"flex items-center relative w-full rounded-16 py-12 md:py-16 px-14 md:px-20 min-h-40 md:min-h-64 pr-40 shadow text-white border-2 border-blue-700" +
						bgColor
				)}
				elevation={0}
			>
				<div className="flex relative flex-row items-center cursor-pointer -ml-6" onClick={handleClick}>
					<MessageGeoFence variant="notification" message={message} incoming={incoming} viewerIsOwner={viewerIsOwner} />
				</div>
				<IconButton
					disableRipple
					className="top-0 right-0 absolute py-14 md:py-16 px-14 md:px-16 mt-4 mr-8 md:mt-4 md:mr-0"
					size="small"
					onClick={handleClose}
				>
					<Icon className="text-20 md:text-24 opacity-75">close</Icon>
				</IconButton>
			</Card>
		);
	}

	return (
		<Card
			className={clsx(
				"flex items-center relative w-full rounded-16 py-4 px-10 md:py-20 md:px-20 min-h-40 md:min-h-64 pr-40 shadow text-white " +
					bgColor
			)}
			elevation={0}
		>
			<div className="flex flex-row items-center cursor-pointer" onClick={handleClick}>
				{ackRequired ? buildChatWarningAvatar() : buildChatAvatar({ chat, truck, user, message }, null)}
				<div className="flex flex-col items-center cursor-pointer">
					<ListItemText
						classes={{
							root: "min-w-px px-16",
							primary: "font-medium text-13 md:text-14 truncate text-black",
							secondary:
								"font-medium opacity-90 max-w-xs text-black " +
								(ackRequired ? " text-14 md:text-18  " : " text-12 md:text-13  truncate "),
						}}
						primary={ackRequired ? "" : buildChatTitle({ chat, truck }, viewerIsOwner, { variant: "notification" })}
						secondary={buildMessagePreview({ message, user, incoming }, viewerIsOwner, { variant: "short" })}
					/>
					{ackRequired && (
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							className="w-full mx-auto mt-10 mb-6"
							aria-label="ACCEPT LOAD"
							value="legacy"
							onClick={handleClose}
						>
							I understand
						</Button>
					)}
				</div>
				{!ackRequired && (
					<IconButton
						disableRipple
						className="top-0 right-0 absolute md:mt-0 p-16 md:p-10"
						size="small"
						onClick={handleClose}
					>
						<Icon className="text-18 md:text-16 opacity-75 text-white">close</Icon>
					</IconButton>
				)}
			</div>
		</Card>
	);
}

export default MessageNotificationCard;
