import { useState, useEffect } from "react";
import { getMarketLocations } from "app/services/marketConditionsServices";

const useLocationsTrimble = ({ props }) => {
	const activeLocations = !!props.activeLocations;
	const origin = props?.origin;
	const destination = props?.destination;
	const locations = props?.locations;
	const moves = props?.moves;
	const activeHeatMap = !!props?.activeHeatMap;
	const hasLatLong = !!props?.hasLatLong;
	const stopSequence = props.stopSequence;

	const [loadingCoords, setLoadingCoords] = useState(false);
	const [locationsError, setLocationsError] = useState(null);
	const [centerMap, setCenterMap] = useState([37.09024, -95.712891]);
	const [locationsCoord, setLocationsCoord] = useState([]);
	const [movesCoord, setMovesCoord] = useState([]);

	useEffect(() => {
		if (activeLocations) {
			setLoadingCoords(true);
			if (hasLatLong) {
				setLoadingCoords(false);
				return setLocationsCoord(locations);
			}
			getMarketLocations({ locations }).then(
				(res) => {
					setLoadingCoords(false);
					setLocationsCoord(res);
					setLocationsError(null);
				},
				(error) => {
					setLoadingCoords(false);
					setLocationsError(error?.errors?.[0].message ?? "Oops, failed to get locations");
				}
			);
		} else {
			setLocationsCoord([]);
		}
		// eslint-disable-next-line
	}, [origin, destination, locations, activeLocations]);

	useEffect(() => {
		if (stopSequence) {
			setLoadingCoords(false);
			return setMovesCoord(moves);
		} else {
			setMovesCoord([]);
		}
		// eslint-disable-next-line
	}, [moves, stopSequence]);

	useEffect(() => {
    if (locationsCoord?.length) {
      setCenterMap([ locationsCoord[0].lat, locationsCoord[0].lng ]);
    }
	// eslint-disable-next-line
	}, [locationsCoord, activeHeatMap]);

  useEffect(() => {
    if (movesCoord?.length) {
      setCenterMap([movesCoord[0].o_lat, movesCoord[0].o_lng ]);
    }
    // eslint-disable-next-line
  	}, [movesCoord, activeHeatMap]);

	return { centerMap, locationsCoord, loadingCoords, locationsError, movesCoord };
};

export default useLocationsTrimble;
