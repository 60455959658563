import { authRoles } from "app/main/consts";

const build = (path, component, auth) => {
	let routes = [];

	if (Array.isArray(path)) {
		path.forEach((item) => {
			routes.push({
				path: item.path,
				component: item.component,
			});
		});
	} else {
		routes.push({
			path: path,
			component: component,
		});
	}

	return {
		settings: {
			layout: {
				config: {
					footer: {
						display: false,
					},
					header: {
						display: false,
					},
				},
			},
		},
		auth: auth ?? authRoles.all,
		routes: routes,
	};
};

export { build };
