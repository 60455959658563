import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellContainerRow from "./CellContainerRow";

const FuelTransactionCellView = (props) => {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<CellFirstRow
					mobile={mobile}
					view={props.view}
					variant={variant}
					dialog={{
						viewId: "FUEL_TRANSACTION_VIEW",
						mode: "VIEW",
						dataIds: {
							transactionId: props.view.value,
							carrierId: props.view.metadata?.carrier,
						},
					}}
				/>
			}
			second={
				<>
					{config?.showTestTransaction && props.item.test && (
						<CellSecondRow
							mobile={mobile}
							icon={"construction"}
							label="Test"
							color="text-blue-600"
							variant={"extra-skinny"}
						/>
					)}
					{config?.showLocation && props.view.metadata?.merchantCity && (
						<CellSecondRow
							mobile={mobile}
							icon={"place"}
							label={props.view.metadata?.merchantCity + ", " + props.view.metadata?.merchantState}
							variant={variant}
						/>
					)}
					{config?.showDriver && props.view.metadata?.driverName && (
						<CellSecondRow
							mobile={mobile}
							label={"#" + props.view.metadata?.driverNumber + " - " + props.view.metadata?.driverName}
							color={"text-grey-600"}
							variant={variant}
						/>
					)}
				</>
			}
		/>
	);
};

export default FuelTransactionCellView;
