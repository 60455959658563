import _ from "@lodash";

import { useState, useRef, useEffect } from "react";
import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { NARROW_FIELDS } from "@fuse/default-settings";
import { createTooltip } from "app/main/utils/tableUtils";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useMemo } from "react";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";

const CURRENCY_LIMIT = 1000000;

const formatCurrency = (value) => {
	if (!Number(value) && Number(value) !== 0) return "";

	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 2,
	}).format(parseFloat(value) / 100);
};

// Only for currency type
const isAllowed = (target, isCurrency) => {
	const value = target.floatValue;
	return isCurrency && value ? value / 100 <= CURRENCY_LIMIT : true;
};

const getFormatter = (isCurrency) => (isCurrency ? formatCurrency : undefined);

const getSuffix = (isPercentage, propsSuffix) => (propsSuffix ? propsSuffix : isPercentage ? "%" : undefined);

const processNumberValue = (value, isCurrency, manageCents) => (isCurrency && !manageCents ? value * 100 : value);

const CustomTextField = ({ field, label, required, value, ...props }) => {
	const resourceInfo = props?.resource ? getResourceByKey(props?.resource) : null;

	label =
		label +
		(required ? " *" : "") +
		(props.orignialValue || props.orignialValue === ""
			? props.orignialValue + "" !== (props?.value ?? "") + ""
				? " (Edited)"
				: ""
			: "");

	const contenView = (
		<TextField
			{...props}
			fullWidth
			value={value}
			variant={
				props?.inputProps?.variant ?? props?.variant ?? field?.variant === "row"
					? "standard"
					: field?.variant ?? "outlined"
			}
			classes={field?.classes ?? {}}
			label={field?.variant === "row" ? "" : label}
			error={!props.disabled ? !!props.errors?.[props.name]?.message : false}
			helperText={!props.disabled && !field?.noErrorMessage ? props.errors?.[props.name]?.message : ""}
			FormHelperTextProps={{ classes: { root: "mt-0 ml-1 mr-1 mb-1" } }}
			InputProps={{
				classes: props.input?.classes ?? {},
				endAdornment:
					props.icon || props.description || resourceInfo ? (
						resourceInfo ? (
							<InputAdornment position="end">
								<ResourceView
									type={"icon"}
									description={resourceInfo?.description}
									link={resourceInfo?.link}
									classes={{ iconSize: "text-20" }}
								/>
							</InputAdornment>
						) : props.description ? (
							createTooltip(
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										{props.icon ?? "info"}
									</Icon>
								</InputAdornment>,
								<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
									{props.description}
								</Typography>,
								"bg-gray"
							)
						) : (
							<InputAdornment position="end">
								<Icon className="text-20" color="action">
									{props.icon}
								</Icon>
							</InputAdornment>
						)
					) : null,
			}}
		/>
	);

	if (field?.variant === "row") {
		return (
			<div className={"flex w-full flex-row justify-between border-b-1 border-grey-300 mt-10"}>
				<Typography
					className={"flex flex-1 text-black text-12 ml:text-13 pt-2 " + (props.disabled ? " opacity-60 " : "")}
				>
					{label}
				</Typography>
				<div className={"flex flex-row w-1/4 mb-2"}>{contenView}</div>
			</div>
		);
	} else {
		return contenView;
	}
};

function SmarthopNumberField({
	name,
	label,
	value,
	required,
	type,
	field,
	disabled,
	errors,
	prefix,
	suffix,
	className,
	inputProps,
	onChange,
	onChangeCommitted,
	onBlur,
	description,
}) {
	const [currentValue, setCurrentValue] = useState(value ?? "");
	const notifiedValueRef = useRef(value ?? "");

	// If manageCents is true, it does all calculations in cents.
	const manageCents = !!field?.manageCents;
	const isCurrency = type === "currency";
	const isPercentage = type === "percentage";
	const isZipcode = type === "zipcode";

	// Config
	const decimals = _.isInteger(field?.decimals) ? field?.decimals : 2;
	const maxLength =
		type === "number" && _.isInteger(field?.maxLength) && field?.maxLength > 0 ? field?.maxLength : undefined;

	const containerClasses = useMemo(
		() =>
			clsx(
				className
					? className
					: " w-full px-4 " +
							(field?.variant !== "row" ? " mb-4 mt-4 " : " mb-2 ") +
							(field?.variant !== "row" && field?.noErrorMessage ? " pt-4 pb-4 " : ""),
				disabled ? "opacity-60 " : ""
			),
		[className, field, disabled]
	);

	useDebouncedEffect(
		() => {
			if (notifiedValueRef.current !== currentValue) {
				notifiedValueRef.current = currentValue;
				onChangeCommitted?.(name, currentValue);
			}
		},
		[currentValue],
		field?.commitDelay ?? 250
	);

	useEffect(() => {
		// WORKAROUND: this component has issues wih state management, it should be done fully outside of the component,
		// current logic allows to keep state management inside, and it causing problems, adding a workaround so
		// if value was changed from outside we need to reset it internally in this text component
		const newValue = value ?? "";
		if (currentValue !== newValue) {
			notifiedValueRef.current = currentValue;
			setCurrentValue(parseFloat(newValue));
		}
		// eslint-disable-next-line
	}, [value]);

	return (
		<div className={containerClasses}>
			<NumberFormat
				name={name}
				description={description}
				field={field}
				label={label}
				required={required}
				errors={errors}
				disabled={disabled}
				value={_.isNumber(currentValue) ? processNumberValue(currentValue, isCurrency, manageCents) : currentValue}
				format={_.isNumber(currentValue) ? getFormatter(isCurrency) : null}
				customInput={CustomTextField}
				thousandSeparator={field?.thousandSeparator}
				decimalScale={decimals}
				placeholder={field?.placeholder ?? ""}
				onValueChange={(target) => {
					if (target.value === "") {
						onChange?.("", name);
						setCurrentValue("");
						return;
					}

					let value = target.value;
					if (isCurrency && !manageCents) {
						value = (parseFloat(value) / 100).toFixed(2);
					}
					if (!isZipcode) {
						value = +value ?? undefined;
					}

					// target.value = value;
					onChange?.(value, name);
					setCurrentValue(value);
				}}
				onBlur={(event) => {
					if (event.target?.value) {
						onBlur?.(name);
					}
				}}
				isAllowed={(target) => isAllowed(target, isCurrency)}
				inputProps={{ maxLength, ...(inputProps ?? {}) }}
				allowLeadingZeros={isZipcode}
				isNumericString
				suffix={getSuffix(isPercentage, suffix)}
				prefix={prefix ? prefix : undefined}
			/>
			{field?.variant !== "row" && !field?.noErrorMessage && (
				<div style={!disabled && !!errors?.[name]?.message ? {} : { height: NARROW_FIELDS ? "1.6rem" : "1.9rem" }} />
			)}
		</div>
	);
}

export default SmarthopNumberField;
