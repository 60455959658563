import { useMemo, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ChatFile from "../ChatFile";
import Link from "@material-ui/core/Link";
import { isEnabled } from "app/services/featureStorageService";

function MessageBasic({ message }) {
	const isNewMessagesUIEnabled = isEnabled("CHAT_NEW_UI_MESSAGES");

	const files = useMemo(() => {
		return message?.content?.files ?? [];
	}, [message]);

	const checkLink = (text) => {
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text?.replace(urlRegex, function (url) {
			return `<${url}`;
		});
	};
	let text = checkLink(message?.content?.text);
	let words = text?.split(" ");

	const handleClick = (link) => {
		const reactNative = window?.ReactNativeWebView ?? false;
		if (reactNative) {
			window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "EXTERNAL_LINK", url: link }));
		} else {
			window.open(link);
		}
	};
	return (
		<div className="flex flex-wrap flex-col">
			<Typography
				color={"inherit"}
				className={`font-normal break-all text-12 ${!isNewMessagesUIEnabled ? "max-w-sm" : ""} md:text-14`}
				component="div"
				style={{ wordBreak: "break-word" }}
			>
				<Fragment>
					{words?.map((word) => {
						if (word.includes("<")) {
							let link = word.split("<")[1];
							return (
								<Link className="cursor-pointer" onClick={() => handleClick(link)}>
									{link + " "}
								</Link>
							);
						}
						if (word === "") return "  ";
						return word + " ";
					})}
				</Fragment>
				{files.length > 0 && files.length + " File(s)"}
			</Typography>
			<Box className="flex flex-wrap -mx-4 -mb-7 mt-4">
				{files.map((f) => (
					<ChatFile key={f.id} name={f.name} type={f.type} fileId={f.id} />
				))}
			</Box>
		</div>
	);
}
export default MessageBasic;
