import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { Typography, Button, Divider, Icon } from "@material-ui/core";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import TextField from "@material-ui/core/TextField";
import { fetchCredentialsStatus } from "app/store/search/credentialsSlice";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
// Utils
import { getLogoConfig, getLogoImage } from "app/main/utils/brokerUtils";
import { createTooltip } from "app/main/utils/tableUtils";
// Services
import { sendIntegrationBrokerRequest } from "app/services/carrierServices";
//Form
import { GLOBAL_FORM } from "@smarthop/form/configs/integrationsGeneralForm";

const BrokerCredentialView = ({ credentials: newCredentials, carrier, loading, onboardingInformationStatus }) => {
	const dispatch = useDispatch();

	const [loadingRequestBroker, setLoadingRequestBroker] = useState(null);
	const [dialog, setSDialog] = useState({ open: false, message: null });
	const [tCode, setTcode] = useState(null);

	const credentials = useMemo(() => {
		return Array.isArray(newCredentials) ? JSON.parse(JSON.stringify(newCredentials)) : [];
	}, [newCredentials]);

	const sendRequestIntegration = async (carrier, broker) => {
		setSDialog(null);
		try {
			setLoadingRequestBroker({ [broker]: true });
			const status = await sendIntegrationBrokerRequest(carrier, broker, {
				comment: tCode ? `T-Code: ${tCode}` : null,
			});
			const brokerIndex = credentials.findIndex((crendential) => crendential.broker === broker);
			credentials[brokerIndex].statusExternal = status?.status;
			dispatch(fetchCredentialsStatus({ carrierId: carrier, noReload: true }));
			setLoadingRequestBroker({ [broker]: false });
			setSDialog({
				message:
					"Our connection request has been submitted. Once successfully processed, the status will change from Pending to Connected. If we experience any connectivity issues, a member of our team will reach out to you",
				open: true,
				mode: "WARNING",
			});
		} catch (e) {
			setLoadingRequestBroker({ [broker]: false });
			console.log("Error sending integration broker request ", e);
		}
	};

	const onClickConnect = async (broker) => {
		if (broker === "loadsmart") {
			dispatch(
				openLoadedFormDialog({
					formInstance: GLOBAL_FORM("loadsmart", "Loadsmart Credential"),
					mode: "EDIT",
					title: "Information",
					dataIds: { carrierId: carrier, brokerId: "loadsmart" },
				})
			);
		} else if (broker === "emerge") {
			dispatch(
				openLoadedFormDialog({
					formInstance: GLOBAL_FORM(broker, "Emerge Credential"),
					mode: "EDIT",
					dataIds: { carrierId: carrier, brokerId: broker },
				})
			);
		} else {
			if (onboardingInformationStatus === "UNVALIDATED") {
				return setSDialog({
					message: "Please validate your MC first before ask for a connection",
					open: true,
					mode: "WARNING",
					title: "Information",
				});
			}
			if (broker === "chrobinson") {
				return setSDialog({
					message: "In order for us to connect you to CH-Robinson please provide your T-Code",
					open: true,
					title: "Information",
					broker: broker,
				});
			}
			sendRequestIntegration(carrier, broker);
		}
	};

	const displayStatus = (broker, credentialStatus, rejectReason) => {
		if (credentialStatus === "ACCEPTED") {
			return <Typography className="text-green-500 px-4 text-13 md:text-14">Connected</Typography>;
		} else if (credentialStatus === "REJECTED") {
			return (
				<>
					<Typography className="text-red-600 px-4 text-13 md:text-14">Rejected</Typography>
					{createTooltip(<Icon className="text-13 ml-2 mt-2 text-red-600">info</Icon>, rejectReason)}
				</>
			);
		} else if (credentialStatus === "REQUESTED") {
			return <Typography className="text-orange-600 px-4 text-13 md:text-14">Pending</Typography>;
		} else {
			return (
				<Button
					size="small"
					variant="contained"
					color="secondary"
					className="min-w-92"
					disabled={loadingRequestBroker?.[broker] ? true : false}
					onClick={() => onClickConnect(broker)}
				>
					{loadingRequestBroker?.[broker] ? "Connecting" : "Connect"}
				</Button>
			);
		}
	};

	const CrendetialView = ({ broker, credentialStatus, brokerName, rejectReason }) => {
		const brokerLogo = useMemo(() => getLogoImage(getLogoConfig(broker, false)), [broker]);

		return (
			<div className="w-full min-h-44 flex flex-row items-center justify-between py-0 ml:py-0">
				<div className="py-5 bg-white px-10 flex flex-row items-center rounded-lg">
					{brokerLogo}
					<Typography className="px-5 ml:px-10 text-14 text-grey-800 font-semibold">{brokerName}</Typography>
				</div>
				<div className="py-5 bg-white px-10 flex flex-row items-center rounded-lg">
					{displayStatus(broker, credentialStatus, rejectReason)}
				</div>
			</div>
		);
	};

	if (!carrier && !loading) {
		return null;
	}

	if (loading) {
		return (
			<>
				<Typography className="text-12 ml:text-13 pb-20 font-light text-grey text-center mt-36 mb-22">
					Loading Connections...
				</Typography>
			</>
		);
	}

	if (!credentials?.length) {
		return <Typography className="text-12 lg:text-13 font-light text-center"></Typography>;
	}

	return (
		<div className="flex flex-col w-full">
			{credentials?.map?.((integration, i) => {
				if (integration.status === "DISABLED") return null;
				return (
					<div className="flex flex-col w-full">
						<CrendetialView
							key={i}
							broker={integration.broker}
							credentialStatus={integration.statusExternal}
							brokerName={integration.brokerName}
							rejectReason={integration.rejectReason}
						/>
						{i !== credentials.length - 1 && <Divider className={"bg-grey-200"} />}
					</div>
				);
			})}
			<SmarthopConfirmDialog
				open={dialog?.open}
				title={dialog?.title}
				mode={dialog?.mode ?? null}
				message={dialog?.message}
				disableAccept={dialog?.mode !== "WARNING" && (!tCode || tCode === "") ? true : false}
				handleClose={() => setSDialog(null)}
				handleAccept={() => sendRequestIntegration(carrier, dialog?.broker)}
				closeMsg={"Close"}
			>
				{dialog?.mode !== "WARNING" && (
					<TextField
						className="w-full mt-10"
						variant="outlined"
						InputProps={{
							placeholder: "T-Code",
						}}
						onChange={(event) => setTcode(event.target.value)}
					/>
				)}
			</SmarthopConfirmDialog>
		</div>
	);
};

export default BrokerCredentialView;
