import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";

import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import CardContent from "@material-ui/core/CardContent";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

import SmarthopForm from "@smarthop/form/SmarthopForm";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { parseRolesInfo } from "../utils/rolesUtils";
import HistoryView from "app/main/history/HistoryView";
// Consts
import { HISTORY_VIEWS } from "app/main/consts";
import { NARROW_FIELDS } from "@fuse/default-settings";
import { buildForm } from "@smarthop/form/registy";

function FormSection(props) {
	const onLoaded = props.onLoaded;
	const formId = props.formId;
	const dataIds = props.dataIds;

	const user = useSelector(({ auth }) => auth.user);
	const form = props.form ?? (formId ? buildForm(formId, { user }) : undefined);

	const actions = props.actions;
	const auth = props.auth;
	const viewId = props.viewId;

	const seeHistory = HISTORY_VIEWS.includes(formId || viewId) && user?.roles?.[0] !== 21;
	const readOnly = props.readOnly;
	const roleStatus = parseRolesInfo(auth, user);
	const isViewCustom = viewId ? true : false;

	const dispatch = useDispatch();
	const [moreMenuEl, setMoreMenuEl] = useState(null);
	const [confirmationModel, setConfirmationModel] = useState(null);

	function handleMoreMenuClick(event) {
		setMoreMenuEl(event.target);
	}

	function handleMenuClick(action, mode) {
		if (action.confirmation) {
			setConfirmationModel(action.confirmation);
		}

		if (action.onClick) {
			setMoreMenuEl(null);
			return action.onClick(action);
		}

		if (mode) {
			setMoreMenuEl(null);
			const params = { formId: formId, formInstance: form, mode: mode, dataIds: dataIds };
			if (viewId) {
				params.viewId = viewId;
			}
			dispatch(openLoadedFormDialog(params));
		}
	}

	if (!form) {
		return null;
	}

	if (!roleStatus.visible) {
		return null;
	}

	if (confirmationModel && !confirmationModel.onAccept) {
		throw new Error("[FormSection] onAccept callback is required.");
	}

	let menuActions = [];
	actions?.forEach(({ action, mode, label, icon, auth }) => {
		const menuRoleStatus = parseRolesInfo(auth, user);

		if (!menuRoleStatus.visible) {
			return;
		}

		menuActions.push(
			<MenuItem
				key={`__menu__${label}`}
				onClick={() => handleMenuClick(action, mode)}
				className={clsx(action.classes?.menu ?? "", "flex w-full flex-row px-32 py-14")}
			>
				{icon && <Icon className={clsx(action.classes?.icon ?? "", "flex mr-6 -ml-2")}>{icon}</Icon>}
				{label}
			</MenuItem>
		);
	});

	return (
		<>
			<Paper
				variant="outlined"
				component={motion.div}
				raised={false}
				className={clsx(props?.classes?.root ?? "", "flex w-full flex-col mb-20 divide-y-1 rounded-12")}
			>
				<AppBar
					position="static"
					classes={{ root: "rounded-t-12" }}
					elevation={0}
					color={roleStatus.internalOnly ? "secondary" : "primary"}
				>
					<Toolbar className="px-12 divide-y-2">
						<div className="flex flex-1  flex-col ">
							<div className="flex flex-row items-center">
								{props.titleView}
								<Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-medium">
									{props.title}
								</Typography>
							</div>
							<Typography variant="caption" color="inherit" className="flex-1 px-12 font-light">
								{roleStatus.internalOnly ? "Visible only to " + roleStatus.internalRoles : props.subtitle}
							</Typography>
						</div>

						{seeHistory && !readOnly && (
							<div className="border-t-0">
								<HistoryView
									fromSection={true}
									viewId={formId || viewId}
									isViewCustom={isViewCustom}
									dataIds={dataIds}
								></HistoryView>
							</div>
						)}

						{!!menuActions.length && !readOnly && (
							<>
								<IconButton
									color="inherit"
									aria-label="more"
									aria-owns={moreMenuEl ? "chats-more-menu" : null}
									aria-haspopup="true"
									onClick={(event) => handleMoreMenuClick(event)}
								>
									<Icon>more_vert</Icon>
								</IconButton>
								<Menu
									id="chats-more-menu"
									anchorEl={moreMenuEl}
									open={!!moreMenuEl}
									onClose={() => setMoreMenuEl(null)}
								>
									{menuActions}
								</Menu>
							</>
						)}
					</Toolbar>
				</AppBar>
				<CardContent className={!NARROW_FIELDS ? "-mb-20" : "-mb-5"}>
					<SmarthopForm form={form} dataIds={dataIds} mode={"VIEW"} onLoaded={onLoaded} />
				</CardContent>
			</Paper>

			<SmarthopConfirmDialog
				open={!!confirmationModel?.message}
				title={confirmationModel?.message}
				onClose={() => {
					typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
					setConfirmationModel(null);
				}}
				onAccept={() => {
					confirmationModel?.onAccept();
					setConfirmationModel(null);
				}}
			/>
		</>
	);
}

export default FormSection;
