import { formatDate } from "app/main/utils/dateUtils";
import { createBrokerView } from "app/main/utils/brokerUtils";

const FILTERS = {
	search: true,
	searchDescription: "Search by Broker name, MC Number or Customer ID",
	items: [],
};

const adminInternalLoads = {
	urlGET: "/api/brokers/loads/list",
	idKey: "_id",
	listenEvent: "internalLoadsRevision",
	segmentKey: "ADMIN_INTERNAL_LOADS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: [
				"broker__view",
				"clientid",
				"mcnumber",
				"creditScore",
				"smartpayCollectionLimit",
				"smartpayCollectionCurrent",
				"smartpayCollectionCurrentPercentage",
				"smartpayCurrentInvoices",
			],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		groups: {
			items: [],
		},
		filters: FILTERS,
		defaults: {
			initial: {
				filters: {
					carrier: "__FALSE_OR_EMPTY__",
				},
				groups: {},
			},
			items: [],
		},
		items: [
			{ key: "tripid", type: "id", label: "Id" },
			{ key: "load_id", type: "id", label: "Load Id" },
			{ key: "price", type: "id", label: "Rate" },
			{ key: "rpm", type: "id", label: "RPM", builder: (item) => (item.price ? item.price / item.miles : "-") },
			{ key: "miles", type: "number", label: "Miles" },
			{
				builder: (item) => formatDate(item.ready, item.pickupby, true, true, true, false),
				key: "ready",
				label: "Pickup",
				type: "component",
				table: { noWrap: true, align: "right" },
			},
			{
				key: "origin",
				type: "text",
				label: "Origin",
				table: { noWrap: true, align: "left" },
			},
			{
				key: "destination",
				type: "text",
				label: "Destination",
				table: { noWrap: true, align: "left" },
			},
			{ key: "equipment", type: "text", label: "equipment" },
			{ key: "weight", type: "number", label: "Weight" },
			{
				builder: (item, dispatch, snackbar) =>
					createBrokerView(item, undefined, dispatch, snackbar, {
						skinny: true,
						mobileScreen: false,
						smallScreen: false,
						showSource: false,
						showInfo: false,
					}),
				key: "clientid",
				type: "component",
				label: "Broker",
				grid: { headerTitle: true },
			},
			{ key: "best", type: "bool", label: "Best" },
			{ key: "test", type: "bool", label: "Test" },
		],
	},
};

export default adminInternalLoads;
