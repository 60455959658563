import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { getRoleLabel } from "app/services/LoginService";
import { useDispatch } from "react-redux";
import { openFormDialog, refreshDialog } from "app/store/tools/formDialogSlice";
import CostStructureView from "./CostStructureView";

const CostStructureViewDialog = forwardRef(({ dataIds, onDone, onMinimize, onProgress, setTitle, setSize }, ref) => {
	useEffect(() => {
		setTitle?.("View Cost Structure");
		setSize?.("max-w-m");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const role = useMemo(() => getRoleLabel(), []);

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			if (!dataIds?.readOnly && role !== "carrier_dispatcher") {
				btns.edit = {
					onClick: () => {
						dispatch(
							openFormDialog({
								viewId: "COST_STRUCTURE",
								dataIds: { ...dataIds, openedFromView: true },
							})
						);
					},
					title: "Edit Cost Structure",
				};
			}

			dispatch(refreshDialog());
			return btns;
		},
		// eslint-disable-next-line
		[dataIds, dispatch, role]
	);

	return <CostStructureView dataIds={dataIds} />;
});

export default CostStructureViewDialog;
