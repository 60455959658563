import React from "react";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { formatDate, formatAge } from "app/main/utils/dateUtils";
import { createBrokerView, createMPIView, createDestinationView } from "app/main/utils/brokerUtils";
import { formatCurrency, formatNumber } from "app/main/utils/tableUtils";
import { createBadgeView, createSmartPayView } from "app/main/search/searchUtils";
import LoadCardView from "../cards/LoadCardView";
import { getLoadForDispatcherRefreshed } from "app/services/searchServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { LOAD_CALL_TO_ACTION } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";
// Gatekeerpers
import { isEnabled } from "app/services/featureStorageService";

const createStatusView = (item, viewType, place, options) => {
	const LIST_BADGES = createBadgeView(item, place, null, "extra-skiny", viewType, options);
	return LIST_BADGES;
};

const sendEventNativeSlide = async (item) => {
	window.ReactNativeWebView.postMessage(JSON.stringify({ type: "OPEN_SEARCH_SLIDE", data: item }));
};

const getTimeWindowView = (from, to, windowDisabled, hideTime) => {
	const timeString = formatDate(from, to, true, false, hideTime, windowDisabled);
	const timeSections = timeString.split("-");
	const fromString = timeSections[0];
	const toString = timeSections[1];
	return (
		<div className={"flex flex-row"}>
			<div className="flex flex-row">
				<Typography
					component={"div"}
					className="text-11 ml:text-12 whitespace-nowrap tracking-wider ml:tracking-normal"
				>
					{fromString}
				</Typography>
				{toString && <Typography className="text-11 ml:text-12 whitespace-nowrap px-3">-</Typography>}
			</div>
			{toString && (
				<Typography
					component={"div"}
					className="text-11 ml:text-12 whitespace-nowrap tracking-wider ml:tracking-normal"
				>
					{toString}
				</Typography>
			)}
		</div>
	);
};

const getCurrencyView = (from, to, decimal = 2, mobileScreen, smallScreen) => {
	const fromString = formatCurrency(from, decimal);
	const toString = to ? formatCurrency(to, decimal) : null;

	if (!toString) {
		return (
			<div
				className={"flex flex-row items-start justify-start" + (!mobileScreen ? " ml:items-end ml:justify-end " : "")}
			>
				<Typography
					component={"div"}
					className="text-11 ml:text-12 whitespace-nowrap tracking-wider ml:tracking-normal"
				>
					{fromString}
				</Typography>
			</div>
		);
	} else if (!smallScreen) {
		return (
			<div
				className={"flex flex-row items-start justify-start" + (!mobileScreen ? " ml:items-end ml:justify-end " : "")}
			>
				<div className="flex flex-row">
					<Typography
						component={"div"}
						className="text-11 ml:text-12 whitespace-nowrap tracking-wider ml:tracking-normal"
					>
						{fromString}
					</Typography>
					<Typography className="text-11 ml:text-12 whitespace-nowrap px-3">-</Typography>
				</div>
				<Typography component={"div"} className="text-11 ml:text-12 whitespace-nowrap">
					{toString}
				</Typography>
			</div>
		);
	} else {
		return (
			<div
				className={"flex flex-col items-start justify-start" + (!mobileScreen ? " ml:items-end ml:justify-end " : "")}
			>
				<div className="flex flex-row">
					<Typography className="text-11 ml:text-12 font-semibold pr-4">Min:</Typography>
					<Typography
						component={"div"}
						className="text-11 ml:text-12 whitespace-nowrap tracking-wider ml:tracking-normal"
					>
						{fromString}
					</Typography>
				</div>
				<div className="flex flex-row">
					<Typography className="text-11 ml:text-12 font-semibold pr-4">Max:</Typography>
					<Typography
						component={"div"}
						className="text-11 ml:text-12 whitespace-nowrap tracking-wider ml:tracking-normal"
					>
						{toString}
					</Typography>
				</div>
			</div>
		);
	}
};

const getBrokerContact = (item, smallScreen) => {
	let contact = item.contact;
	//Some phones with extension can be larger
	if (contact?.length > 14 && smallScreen) contact = contact.substring(0, 12) + "...";
	if (contact?.includes("@")) {
		const newLine = `%0D%0A`;
		const mailSubject = item?.hasBrokerId
			? `Load ${item?.load_id}`
			: `Load from ${item?.origin} to ${item?.destination}`;
		const mailBody = `Hi,${newLine}${newLine}I'm interested in load ${
			item?.hasBrokerId ? `ID ${item?.load_id}` : ""
		} from ${item?.origin} to ${item?.destination} on ${formatDate(item.ready)}.${newLine}${newLine}Thanks`;

		return { label: contact, ref: `mailto:${item?.contact}?subject=${mailSubject}&body=${mailBody}` };
	} else {
		if (!contact?.includes("+1")) contact = "+1" + contact;
		return { label: contact, ref: "tel:" + item.contact };
	}
};

const searchResultsDispatcher = (
	searchId,
	showSystemData,
	tabsVisible,
	smallScreen,
	mobileScreen,
	disableDigitalBooking,
	dhdVisible = true,
	carrier,
	userId,
	dispatch,
	hasSmartPayProgram = false,
	urlGet = "api/search/users/:userId/requests/:searchId/results",
	dialogOnly
) => ({
	urlGET: urlGet,
	idKey: "tripid",
	listenEvent: "searchResultsChange" + searchId,
	segmentKey: "SEARCH_RESULT_DISPATCHER",
	cache: {
		key: "search_result_:searchId",
		disableInit: true,
	},
	content: {
		internalView: showSystemData,
		grid: {
			builder: (id, item, options) => {
				return <LoadCardView id={id} item={item} options={options} />;
			},
		},
		isActive: (item) => !item.deleted,
		table: {
			variant: "extra-skinny",
			diffHeight: dialogOnly ? 305 : tabsVisible ? 235 : 205,
			orderBy: [
				"match_status",
				"price",
				"dhs",
				"est_price",
				"sort_rpm",
				"ppd",
				"profit_trip_carrier",
				"miles",
				"updated",
				"distance_to_origin",
				"distance_to_dest",
				"rank",
				"rank_pref_r",
				"rank_axle_r",
				"rank_shr_pr",
				"fingerprints",
				"ondemand",
				"weight",
				"rank_mci",
				"version",
				"new",
				"ready",
			],
		},
		pagination: {
			enabled: true,
			auto: {
				enabled: false,
			},
			resetScroll: true,
			defaut: {
				size: 100,
			},
		},
		order: {
			defaut: {
				key: "updated",
				dir: "asc",
			},
			desc: ["miles", "price", "rank_mci", "sort_rpm", "profit_trip_carrier"],
		},
		click: {
			...(!mobileScreen && !dialogOnly
				? {
						openMenuPanel: (item, dataIds) => ({
							menuId: item.load_id,
							dataIds: {
								...dataIds,
								loadId: item.tripid,
							},
							data: { ...item },
						}),
				  }
				: window?.ReactNativeWebView && isEnabled("MENU_SLICE_MOBILE")
				? {
						dispatchAction: (item, dataIds) => {
							sendEventNativeSlide({ ...item, searchId, carrier, userId, ...dataIds, truck: dataIds?.truckId });
						},
				  }
				: {
						dialogBuilder: (item, dataIds) => ({
							viewId: "LOAD_SUGGEST_VIEW",
							dataIds: {
								load: item,
								loadId: item.tripid,
								userId: dataIds.userId,
								searchId: dataIds.searchId,
								carrierId: dataIds.carrierId,
								carrier__view: dataIds.carrier__view,
								truckId: dataIds.truckId,
								truck__view: dataIds.truck__view,
								driverId: dataIds.driverId,
								driver__view: dataIds.driver__view,
								isFromSearch: true,
							},
						}),
				  }),
		},
		isHighlighted: (item) => item && item.seen,
		isCrossed: (item) => !!item?.deleted,
		items: [
			{
				builder: (item) => (
					<Typography
						className={"flex flex-row text-11 ml:text-12 whitespace-nowrap items-center justify-center"}
						component={"div"}
					>
						<div className={"w-6 h-6 rounded-full mr-4" + (item.new ? " bg-cyan-700 " : "")} />
						{formatAge(item.updated)}
					</Typography>
				),
				key: "updated",
				label: "Age",
				type: "component",
				table: { align: "right" },
			},
			{
				type: "separator",
			},
			{
				builder: (item) => createStatusView(item, null, "LIST", { hideBookNow: disableDigitalBooking }),
				key: "type",
				type: "component",
				label: "Type",
				table: { align: "right", noWrap: true },
			},
			{
				type: "separator",
			},
			{
				builder: (item) =>
					item.profit_trip_carrier > 0 || item.profit_trip_carrier < 0 ? (
						<div className={"flex flex-row items-center m-0 p-0"}>
							{getCurrencyView(item.profit_trip_carrier, null, 0, mobileScreen, smallScreen)}
							<Icon
								className={
									"-mr-6 ml:-mr-7 -ml-2 text-20 ml:text-24 " +
									(item.profit_trip_carrier > 0 ? "text-green" : "text-red")
								}
							>
								{item.profit_trip_carrier > 0 ? "arrow_drop_up" : "arrow_drop_down"}
							</Icon>
						</div>
					) : (
						"-"
					),
				type: "component",
				key: "profit_trip_carrier",
				label: "Profit",
				table: { align: "right", noWrap: true },
			},
			{
				builder: (item) => (item?.price ? getCurrencyView(item.price, null, 0, mobileScreen, smallScreen) : "-"),
				key: "price",
				type: "component",
				label: "Rate",
				table: { align: "right", noWrap: true },
			},
			{
				builder: (item) =>
					item.rpm > 0
						? getCurrencyView(item.rpm, null, 2, mobileScreen, smallScreen)
						: item.est_rpm > 0
						? getCurrencyView(item.est_rpm, null, 2, mobileScreen, smallScreen)
						: item.min_rpm > 0 && item.est_rpm > 0
						? getCurrencyView(item.min_rpm, item.est_rpm, 2, mobileScreen, smallScreen)
						: "-",
				key: "sort_rpm",
				type: "component",
				label: "RPM",
				table: { align: "right", noWrap: true },
			},
			{
				type: "separator",
			},
			{
				key: "miles",
				type: "text",
				label: "Miles",
				builder: (item) => (item?.miles ? formatNumber(item.miles, 0) : "-"),
				table: { align: "right", classes: { text: "tracking-wider ml:tracking-normal" } },
			},
			{
				key: "distance_to_origin",
				type: "text",
				label: "DH-O",
				builder: (item) => (item?.distance_to_origin ? item.distance_to_origin : "-"),
				header: { noWrap: true },
				table: { align: "right", classes: { text: "tracking-wider ml:tracking-normal" } },
			},
			{
				type: "separator",
			},
			{
				builder: (item) => getTimeWindowView(item.ready, item.pickupby, smallScreen, true),
				key: "ready",
				label: "Pickup",
				type: "component",
				table: { noWrap: true, align: "right" },
			},
			{
				key: "origin",
				type: "text",
				label: "Origin",
				table: { noWrap: true, align: "left" },
			},
			...(dhdVisible
				? [
						{
							type: "separator",
						},
						{
							key: "distance_to_dest",
							type: "text",
							label: "DH-D",
							builder: (item) => (item?.distance_to_dest ? item.distance_to_dest : "-"),
							header: { noWrap: true },
							table: { align: "right", classes: { text: "tracking-wider ml:tracking-normal" } },
						},
				  ]
				: []),
			{
				type: "separator",
			},
			{
				key: "rank_mci",
				type: "component",
				label: "MPI",
				builder: (item) => createMPIView(item, true),
				table: { noWrap: true, align: "right" },
			},
			{
				key: "destination",
				type: "text",
				label: "Destination",
				table: { noWrap: true, align: "left" },
				builder: (item) => createDestinationView(item),
			},
			{
				type: "separator",
			},
			...(hasSmartPayProgram
				? [
						{
							key: "smartpayElegible",
							type: "component",
							builder: (item) => (item?.smartpayElegible ? createSmartPayView() : "-"),
							label: "Smartpay",
							table: { noWrap: true, align: "center" },
						},
				  ]
				: []),
			...(!hasSmartPayProgram
				? [
						{
							key: "factoring",
							type: "component",
							builder: (item) => (item?.factoring ? createSmartPayView() : "-"),
							label: "Factorable",
							table: { noWrap: true, align: "center" },
						},
				  ]
				: []),
			{
				type: "separator",
			},
			{
				key: "weight",
				type: "text",
				builder: (item) => {
					return (
						<div className="flex">
							<div className="mt-1">{createStatusView(item, null, "WEIGHT")}</div>
							{item?.weight ? formatNumber(item.weight, 0) : "-"}
						</div>
					);
				},
				label: "Weight",
				table: { align: "right", classes: { text: "tracking-wider ml:tracking-normal" } },
			},
			{
				builder: (item) => createStatusView(item, null, "FEATURE"),
				key: "feature",
				type: "component",
				label: "",
				table: { align: "right", classes: { text: "" } },
			},
			{
				builder: (item, dispatch, snackbar) =>
					createBrokerView(item, carrier, dispatch, snackbar, {
						skinny: !mobileScreen,
						mobileScreen,
						smallScreen,
						showSource: false,
						showInfo: false,
					}),
				key: "clientid",
				type: "component",
				label: "Broker",
				grid: { headerTitle: true },
			},
			{
				builder: (item) => {
					if (item.booknow || item.bidnow) {
						return (
							<Typography
								className={"text-11 ml:text-13 whitespace-nowrap text-grey min-w-40 ml:min-w-92 xl:min-w-180"}
								component={"div"}
							>
								{item.bidnow ? "Bid Online" : item.booknow ? "Book Online" : ""}
							</Typography>
						);
					} else if (item.contact) {
						const { label, ref } = getBrokerContact(item, smallScreen);
						return (
							<a
								href={ref}
								target="_blank"
								rel="noreferrer"
								onClick={(event) => {
									event.stopPropagation();
									const options = { tollDiscouraged: true };
									getLoadForDispatcherRefreshed(userId, searchId, item.tripid, options).then((load) => {
										if (load.deleted || !load.available) {
											dispatch(incrementDataRevision({ event: "searchResultsChange" + searchId }));
										}
										const data = {
											owner: item.owner,
											clientid: item.clientid,
											rank: item.rank,
											age: formatAge(item.updated),
											best: item.best,
											isEmail: item.contact?.includes("@"),
											hasAvailabilityCheck: item.hasAvailabilityCheck,
											covered: load.deleted || !load.available,
										};
										createTrackOrPage(LOAD_CALL_TO_ACTION, data, "track");
									});
								}}
							>
								<Typography
									className={
										"text-11 ml:text-13 whitespace-nowrap text-grey no-underline hover:underline truncate max-w-1000 ml:max-w-150 xl:max-w-200 "
									}
									component={"div"}
								>
									{label}
								</Typography>
							</a>
						);
					}
				},
				key: "contact",
				type: "component",
				table: { noWrap: true, align: "left" },
			},
			{
				internal: true,
				key: "initial_match",
				label: "[Initial Match]",
			},
			{
				internal: true,
				key: "match_status",
				label: "[Match Status]",
			},
			{
				internal: true,
				key: "best",
				label: "[Best]",
			},
			{
				internal: true,
				key: "new",
				type: "text",
				builder: (item) => item?.new ?? "-",
				label: "[New]",
			},
			{
				internal: true,
				key: "version",
				type: "component",
				builder: (item) => item?.version ?? "-",
				label: "[Version]",
			},
			{
				internal: true,
				key: "flexibleReasons",
				builder: (item) => item?.flexibleReasons ?? "-",
				label: "[Flex Reasons]",
			},
			{
				internal: true,
				key: "initial_rank",
				label: "[Initial Rank]",
				builder: (item) => (item.initial_rank ? item.initial_rank : "-"),
			},
			{
				internal: true,
				key: "rank",
				type: "component",
				builder: (item) => (item.rank ? item.rank : "-"),
				label: "[Rank]",
			},
			{
				internal: true,
				key: "outlier",
				type: "component",
				builder: (item) => (item.outlier ? "True" : "False"),
				label: "[Outlier]",
			},
			{
				internal: true,
				key: "seen",
				label: "[Seen]",
			},
			{
				internal: true,
				key: "booknow",
				label: "[Booknow]",
			},
			{
				internal: true,
				key: "bidnow",
				label: "[Bidnow]",
			},
			{
				internal: true,
				key: "owner",
				label: "[Owner]",
			},
			{
				internal: true,
				key: "equipment",
				label: "[Equipment]",
			},
			{
				internal: true,
				key: "initial_miles",
				label: "[Initial Miles]",
			},
			{
				internal: true,
				key: "isOnBoarded",
				label: "[Onboarded]",
			},
			{ internal: true, key: "fingerprints", type: "text", label: "[Fingerprints]" },
			{ internal: true, key: "fingerprints_hash", type: "text", label: "[Fingerprints Hash]" },
			...(!smallScreen
				? [
						{
							internal: true,
							key: "pickupby",
							label: "[Pickup By]",
						},
						{
							internal: true,
							key: "deliverby",
							label: "[Deliver By]",
						},
				  ]
				: []),
			{
				internal: true,
				key: "initial_price",
				label: "[Initial Rate]",
			},
			{
				internal: true,
				key: "initial_profit",
				label: "[Initial Profit]",
			},
		],
	},
});

export { searchResultsDispatcher, createStatusView };
