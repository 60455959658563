import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { setSearchParams, FINISHED_STATES } from "app/store/search/searchV3Slice";
import Countdown from "app/main/chat/messages/Countdown";

// Component
import BrokerCredentialView from "app/main/profile/integrations/BrokerCredentialView";

import { DEFAULT_SOFT_FILTERS_VALUES } from "../utils/filtersUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

function InfoContent({
	state,
	filtered,
	pipelineErrors,
	results,
	credentials,
	carrierId,
	loading,
	searchId,
	dispatch,
	onboardingInformationStatus,
}) {
	//Finished and no filtered loads
	if (FINISHED_STATES.includes(state) && filtered === 0) {
		if (results?.length === 0 && pipelineErrors.length === 0) {
			return (
				<>
					<Typography color="primary" className="px-20 pt-12 text-13 ml:text-14">
						Sorry, no matching loads...
					</Typography>
					<Typography color="primary" className="px-20 pb-14 pt-14 text-13 ml:text-14">
						Please modify your search request to find more loads. Try changing DH-O, DH-D or Destination.
					</Typography>
				</>
			);
		} else if (results?.length === 0 && pipelineErrors.length > 0) {
			return (
				<>
					<Typography className="px-20 py-14 text-13 ml:text-14">
						Search request failed for some connected brokers, please try again
					</Typography>
				</>
			);
		} else if (results?.length > 0) {
			return (
				<>
					<Typography color="primary" className="px-20 pt-12 text-13 ml:text-14">
						Sorry, no matching loads for provided filters...
					</Typography>
					<Typography color="primary" className="px-20 pt-14 text-13 ml:text-14">
						Reset filters to default to see all <b>{results?.length}</b> loads which match to your origin and pickup
						dates.
					</Typography>
					<Button
						className="mx-20 mt-24 mb-10"
						variant="contained"
						color="secondary"
						aria-label="Follow"
						onClick={() => {
							dispatch(
								setSearchParams({
									searchId: searchId,
									softFilters: DEFAULT_SOFT_FILTERS_VALUES,
									override: { hardFilters: false, softFilters: true }, //Don't override all hard filters
								})
							);
						}}
					>
						Reset Filters
					</Button>
				</>
			);
		}
		return null;
	} else {
		return (
			<BrokerCredentialView
				carrier={carrierId}
				credentials={credentials}
				loading={loading}
				onboardingInformationStatus={onboardingInformationStatus}
			/>
		);
	}
}

function LoadSearchEmptyContent({ processingSearch }) {
	const dispatch = useDispatch();
	const carrierCredentials = useSelector(({ search }) => search.carrierCredentials);
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const currentSearch = searches[currentSearchId];

	const credentials = carrierCredentials.credentials;
	const carrierId = carrierCredentials.carrierId;
	const carrierAccountStatus = carrierCredentials.carrierAccountStatus;
	const carrierOnboardingStatus = carrierCredentials.carrierOnboardingStatus;
	const onboardingInformationStatus = carrierCredentials.onboardingInformationStatus;
	const loading = carrierCredentials.loading;
	const credentialFirstExpired =
		credentials?.length > 0 ? credentials?.find((credential) => credential.firstToExpire) : null;
	const atLeastOnePending =
		credentials?.length > 0 && credentials?.some((credential) => credential.status === "PENDING");
	const atLeastOneExpired =
		credentials?.length > 0 && credentials?.some((credential) => credential.status === "EXPIRED");
	const credentialsPendingWithoutTrial =
		credentials?.length > 0 && credentials?.filter((c) => !c.freeTrial && c.status !== "INTEGRATED");

	const searchExpiredCondition = carrierAccountStatus === "ACTIVE" && carrierOnboardingStatus === "CHANGES_REQUESTED";
	const searchExpirationAllPendingWithoutTrial =
		// Less Parade (not used credential)
		typeof carrierAccountStatus === "string" &&
		carrierAccountStatus !== "ACTIVE" &&
		credentialsPendingWithoutTrial?.length === credentials?.length - 1;

	return (
		<div className="flex flex-col h-full flex-auto items-center justify-center">
			<Card
				className={
					"max-w-390 ml:max-w-420 flex flex-col w-full items-center justify-center px-8 sm:px-16 text-center mb-32"
				}
			>
				<div
					className={
						"w-full flex flex-row items-center justify-center px-24 ml:px-32 py-28 mr-10 " +
						(processingSearch ? " animate-pulse " : "")
					}
				>
					<img
						className="mr-16 w-44 md:w-52 ml:w-64"
						src="https://uploads-ssl.webflow.com/63e54de88ae6383cb6811f79/63f7b02a4715bfbac1165f7a_SmartHop-orange-wave.webp"
						alt="logo"
					/>
					{processingSearch ? (
						<Typography component={"div"} className=" flex flex-row text-16 ml:text-18 text-grey-700 font-light mr-24">
							Searching for loads...
						</Typography>
					) : (
						<Typography component={"div"} className=" flex flex-row text-16 ml:text-18 text-grey-700 font-light mr-6">
							<Typography className="text-16 ml:text-18 text-primary font-semibold">Smart</Typography>
							<Typography className="text-16 ml:text-18 text-secondary font-semibold mr-8">Hop</Typography>Search Engine
						</Typography>
					)}
				</div>
				{!processingSearch && carrierCredentials?.onboardingInformationStatus === "UNVALIDATED" && (
					<div className="flex flex-col mb-16">
						<Typography>Before connecting brokers, MC ownership must be verified.</Typography>
						<Button
							className="tracking-wide min-w-120 mx-12 mt-6 text-14 font-medium"
							variant="contained"
							color="secondary"
							onClick={() =>
								dispatch(
									openLoadedFormDialog({
										formId: "CARRIER_COMPANY_VALIDATE_ONLY_FORM",
										mode: "EDIT",
										dataIds: { carrierId },
									})
								)
							}
						>
							Verify Now
						</Button>
					</div>
				)}
				{(atLeastOneExpired && searchExpiredCondition) || searchExpirationAllPendingWithoutTrial ? (
					<div className="w-full mx-20 px-20 mb-36 mt-6">
						<Alert icon={false} color="error" className="flex w-full justify-center text-13 ml:text-14 py-12">
							Load search has been restricted, please contact SmartHop
						</Alert>
					</div>
				) : (
					<>
						{credentialFirstExpired?.expireAt && searchExpiredCondition && (
							<div className="w-full mx-20 px-20 mb-20">
								<Alert
									icon={false}
									color="warning"
									className="flex w-full text-13 ml:text-14"
									action={<Countdown endDate={moment(credentialFirstExpired?.expireAt).startOf("day")} />}
								>
									Search expires in:
								</Alert>
							</div>
						)}
						{!processingSearch && (carrierId || loading || currentSearch?.state) && (
							<div className="flex flex-col w-full mb-10 mb-24 -mt-4">
								<Divider className="bg-grey-200 mb-16" />
								<InfoContent
									state={currentSearch?.state}
									filtered={currentSearch?.stats?.filtered}
									pipelineErrors={currentSearch?.pipelineErrors}
									results={currentSearch?.results}
									credentials={credentials}
									carrierId={carrierId}
									loading={loading}
									searchId={currentSearchId}
									dispatch={dispatch}
									onboardingInformationStatus={onboardingInformationStatus}
								/>
								{atLeastOnePending && (
									<Typography className="px-6 pt-16 text-11 text-grey-400 font-light">
										*Some brokers require additional process on our side to connect your account. Our team is working to
										connect you to those brokers, it may take a little bit of time
									</Typography>
								)}
							</div>
						)}
					</>
				)}
			</Card>
		</div>
	);
}

export default LoadSearchEmptyContent;
