import LogRocket from "logrocket";
import _refiner from "refiner-js";

import { axios } from "app/services/requestUtil";
import { NetwrokError } from "errors";

// Constans
import { user_roles } from "constants.js";
import { createRequest, createHeaders, throwResErrors } from "./requestUtil";

//Refiner Surveys
_refiner("setProject", process.env.REACT_APP_REFINER_PROJECT_ID);

export const getSignupTerms = (type) => {
	return createRequest()
		.get(`/users/terms/${type}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/*****************************************************************
 *                     New UI Signing Logic                      *
 *****************************************************************/

/**
 * Loging using password and email
 *
 * @param {*} username
 * @param {*} password
 * @returns
 */
export function signInWithEmailAndPassword(username, password, params) {
	return new Promise((resolve, reject) => {
		axios
			.get("/users/authorization?version=2", {
				headers: { "Content-Type": "application/json", Authorization: "Basic " + btoa(username + ":" + password) },
				params: params,
			})
			.then((response) => {
				if (response && response.status === 200) {
					// do no setup interseptor for 401, just show an error
					if (
						response.data?.status !== "2FA_TYPE_REQUIRED" &&
						response.data?.status !== "2FA_CODE_REQUIRED" &&
						response.data?.status !== "PWD_RESET_REQUIRED" &&
						response.data?.status !== "ACCOUNT_SELECTION_REQUIRED"
					) {
						saveUserData(response.data, true);
					}
					resolve(response.data);
				} else {
					console.log(`LoginService::signInWithToken: !200, status=${response?.status}, data=`, response?.data);
					reject(new NetwrokError(`SignIn failed with status "${response?.status}"`, response?.status, response?.data));
				}
			})
			.catch((error) => {
				let response = error?.response;
				console.log(`LoginService::signInWithToken: status=${response?.status}, data=`, response?.data, error);
				reject(new NetwrokError(`SignIn failed with status "${response?.status}"`, response?.status, response?.data));
			});
	});
}

/**
 * Login using a token provided by google, apple, or facebook
 *
 * @param {*} token
 * @returns
 */
export function signInWithSocial(token, providerName, params) {
	return new Promise((resolve, reject) => {
		axios
			.post(
				"/users/socialauth?version=2",
				{ token, providerName },
				{ headers: { "Content-Type": "application/json" }, params: params }
			)
			.then((response) => {
				if (response && response.status === 200) {
					if (response.data.registered === true) {
						// do no setup interseptor for 401, just show an error
						if (
							response.data?.status !== "2FA_TYPE_REQUIRED" &&
							response.data?.status !== "2FA_CODE_REQUIRED" &&
							response.data?.status !== "PWD_RESET_REQUIRED" &&
							response.data?.status !== "ACCOUNT_SELECTION_REQUIRED"
						) {
							saveUserData(response.data, true);
						}
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				} else {
					console.log(`LoginService::signInWithSocial: !200, status=${response?.status}, data=`, response?.data);
					reject(new NetwrokError(`SignIn failed with status "${response?.status}"`, response?.status, response?.data));
				}
			})
			.catch((error) => {
				let response = error?.response;
				console.log(`LoginService::signInWithSocial: status=${response?.status}, data=`, response?.data);
				reject(new Error(response?.data));
			});
	});
}

/**
 * Re-login, called to ref-etch user profile on a page reload, to verify if user's session and token
 * are still valid, additionally fetches new user information
 *
 * @param {*} username
 * @param {*} password
 * @returns
 */
export function signInWithToken() {
	if (!getAuthToken()) {
		return;
	}

	let url = `/api/profile/users/${getUserId()}/info`;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + getAuthToken(),
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
			})
			.then((response) => {
				if (response && response.status === 200) {
					// do no setup interseptor for 401, just show an error
					saveUserData(response.data, false);
					resolve(response.data);
				} else {
					console.log(`LoginService::signInWithToken: !200, status=${response?.status}, data=`, response?.data);
					reject(new NetwrokError(`SignIn failed with status "${response?.status}"`, response?.status, response?.data));
				}
			})
			.catch((error) => {
				let response = error?.response;
				console.log(`LoginService::signInWithToken: status=${response?.status}, data=`, response?.data);
				reject(new NetwrokError(`SignIn failed with status "${response?.status}"`, response?.status, response?.data));
			});
	});
}

/**
 * Singing up new user
 *
 * @param {*} data
 * @returns
 */
export function signUp(data) {
	return new Promise((resolve, reject) => {
		axios
			.post("/users/create", data, { headers: { "Content-Type": "application/json" } })
			.then((response) => {
				if (response && response.status === 200) {
					if (
						response.data.status === "EMAIL_VERIFICATION_REQUIRED" ||
						response.data.status === "PHONE_VERIFICATION_REQUIRED" ||
						response.data.status === "VERIFICATION_TYPE_REQUIRED"
					) {
						// email verification required to continue with registration
						resolve(response.data);
					} else {
						// do no setup interseptor for 401, just show an error
						saveUserData(response.data, true);
						resolve(response.data);
					}
				} else {
					console.log(`LoginService::signUp: !200, status=${response?.status}, data=`, response?.data);
					reject(new NetwrokError(`SignUp failed with status "${response?.status}"`, response?.status, response.data));
				}
			})
			.catch((error) => {
				let response = error?.response;
				console.log(`LoginService::signUp: status=${response?.status}, data=`, response?.data);
				reject(new NetwrokError(`SignUp failed with status "${response?.status}"`, response?.status, response?.data));
			});
	});
}

/**
 * Set auth token
 * @returns token
 */
export function setAuthToken(token) {
	return localStorage.setItem("tokenSmarthop", token);
}

/**
 * Get auth token
 * @returns token
 */
export function getAuthToken() {
	return localStorage.getItem("tokenSmarthop");
}

/**
 * Set user id
 * @returns token
 */
export function setUserId(userId) {
	return localStorage.setItem("userId", userId);
}

/**
 * Get user id
 * @returns token
 */
export function getUserId() {
	return localStorage.getItem("userId");
}

/**
 * Get profile id
 * @returns token
 */
export function getAccountId() {
	return localStorage.getItem("accountId");
}

/**
 * Get profile id
 * @returns token
 */
export function getProfileId() {
	return localStorage.getItem("profileId");
}

/**
 * Get default carrier id
 * @returns token
 */
export function getCarrierId() {
	const carrierId = localStorage.getItem("carrierId");
	if (carrierId === "undefined" || carrierId === "null") return undefined;
	return carrierId;
}

/**
 * Get default driver id
 * @returns token
 */
export function getDriverId() {
	const driverId = localStorage.getItem("driverId");
	if (driverId === "undefined" || driverId === "null") return undefined;
	return driverId;
}

/**
 * @deprecated Get user email, should not be used, email can change
 * @returns token
 */
export function getUserEmail() {
	return localStorage.getItem("username");
}

/**
 * @deprecated Use redux user
 * Get default role label
 * @returns token
 */
export function getRoleLabel() {
	const roleUser = localStorage.getItem("roleUser");
	const role =
		roleUser === "0"
			? "administrator"
			: roleUser === "1"
			? "dispatcher"
			: roleUser === "12"
			? "ops support"
			: roleUser === "2"
			? "carrier"
			: roleUser === "21"
			? "driver"
			: roleUser === "22"
			? "carrier_manager"
			: roleUser === "23"
			? "carrier_dispatcher"
			: roleUser === "27"
			? "carrier_generic"
			: roleUser === "9"
			? "bot"
			: undefined;
	return role;
}
/**
 * return boolean value based on carrier user role
 * @returns {Boolean}
 */
export const isCarrierPermission = (role) => {
	return ["carrier", "carrier_manager", "carrier_dispatcher"].includes(role);
};

/**
 * return boolean value based on internal user role
 * @returns {Boolean}
 */
export const isInternalPermission = (role) => {
	return ["administrator", "dispatcher", "ops support"].includes(role);
};

/**
 *
 * @returns {Boolean} carrierStatus
 */
export function isActiveCarrierStatus() {
	if (!isRoleExternal()) {
		return true;
	}
	return localStorage.getItem("accountStatus") === "ACTIVE";
}

/**
 *
 * @returns {Boolean} carrierStatus
 */
export function isDeactivatedCarrierStatus() {
	if (!isRoleExternal()) {
		return false;
	}
	return localStorage.getItem("accountStatus") === "DEACTIVATED";
}

/**
 * Check if role is external
 * @returns token
 */
export function isRoleExternal() {
	const roleUser = localStorage.getItem("roleUser");
	const external = !roleUser || roleUser?.startsWith("2");
	return external;
}

/**
 * Check if role is bot
 * @returns token
 */
export function isRoleBot() {
	const roleUser = localStorage.getItem("roleUser");
	const bot = roleUser === "9";
	return bot;
}

/**
 * Check if user onboarding process is in process
 * @returns
 */
export function isOnboardingCompleted() {
	const roleExternal = isRoleExternal();
	const isOnboarding = localStorage.getItem("isOnboarding") === "true";
	const isOnboardingCompleted = localStorage.getItem("onboardingStatus") === "ACCEPTED";
	return !roleExternal || (isOnboarding && isOnboardingCompleted);
}

/**
 * Check if user onboarding process is incomplete
 * @returns
 */
export function isOnboardingIncompleted() {
	const noOnboarding = localStorage.getItem("isOnboarding") === "false";
	const isOnboardingInCompleted = localStorage.getItem("onboardingStatus") === "INCOMPLETE";
	return noOnboarding && isOnboardingInCompleted;
}

/**
 * Check the onboarding statuses that must have restrictions on the pages
 * @returns
 */
export function isOnboardingNotSubmitted() {
	const onboardingStatus = localStorage.getItem("onboardingStatus");
	return onboardingStatus === "REJECTED" || onboardingStatus === "INCOMPLETE";
}

/**
 * Check the active tier for the user
 * @returns
 */
export function getUserTier() {
	const userTier = localStorage.getItem("user_tier");
	if (userTier !== "undefined" && userTier !== "null") {
		return userTier;
	}
	return null;
}

/**
 * Check if user onboarding process is rejected
 * @returns
 */
export function isOnboardingRejected() {
	return localStorage.getItem("onboardingStatus") === "REJECTED";
}

/**
 * Check if carrier activation has been finished
 * @returns
 */
export const isActivationCompleted = () => {
	if (!isRoleExternal()) {
		return true;
	}
	return isActiveCarrierStatus() && isOnboardingCompleted();
};

/**
 * DEPRECATED, use isRoleHasFullCarrierAccess
 * Check if the user is a carrier or carrier manager
 * @returns token
 */
export function isCarrier() {
	const roleUser = localStorage.getItem("roleUser");
	const isCarrier = roleUser === "22" || roleUser === "2";
	return isCarrier;
}

/**
 * Check if the user is driver
 * @returns token
 */
export function isDriver() {
	const roleUser = localStorage.getItem("roleUser");
	return roleUser === "21";
}

/**
 * Check if the user is a carrier or carrier managercarrier && exports.migrateCarrierStatus(carrier)
 * @returns token
 */
export function isRoleHasFullCarrierAccess() {
	const roleUser = localStorage.getItem("roleUser");
	const isCarrier = roleUser === "22" || roleUser === "2" || roleUser === "23";
	return isCarrier;
}

/**
 * Check if the user is a test carrier
 * @returns
 */
export function isTestCarrier() {
	const roleUser = localStorage.getItem("roleUser");
	const isCarrier = roleUser === "22" || roleUser === "2";
	const isTestCarrier = localStorage.getItem("isTestCarrier") === "true";

	return isCarrier && isTestCarrier;
}

/**
 * @deprecated Prefer reading user data from Redux store instead
 * Get User GateKeepers
 * @returns
 */
export function getGatekeepers() {
	const gatekeepers = localStorage.getItem("gatekeepers");
	if (gatekeepers !== "undefined") {
		return JSON.parse(gatekeepers);
	}
	return {};
}

/**
 * Get permission for digital booking for carrier or driver
 * @returns
 */
export function isBookingEnabled() {
	if (!isRoleExternal()) {
		return true;
	}

	const roleUser = localStorage.getItem("roleUser");
	const isCarrier = roleUser === "2" || roleUser === "22" || roleUser === "23";
	const isDriver = roleUser === "21";

	if (isCarrier) {
		const disabledDigitalBooking = localStorage.getItem("disabledDigitalBooking");
		const enabledCarrier = disabledDigitalBooking === "false";
		return enabledCarrier;
	}

	if (isDriver) {
		const enabledDriver = isBookEnabled();
		return enabledDriver;
	}

	return true;
}

/**
 * Get permission for help request carrier or driver
 * @returns
 */
export function isHelpEnabled() {
	if (!isRoleExternal()) {
		return true;
	}

	const roleUser = localStorage.getItem("roleUser");
	const isCarrier = roleUser === "2" || roleUser === "22";
	const isDriver = roleUser === "21";

	if (isCarrier) {
		const disabledHelpRequest = localStorage.getItem("disabledHelpRequest");
		const enabledCarrier = disabledHelpRequest === "false";
		return enabledCarrier;
	}

	if (isDriver) {
		const enabledDriver = isBookEnabled();
		return enabledDriver;
	}

	return true;
}

/**
 * Get permission for search on
 * @returns
 */
export function isSearchEnabled() {
	const roleUser = localStorage.getItem("roleUser");
	const isDriver = roleUser === "21";

	if (!isDriver) {
		return true;
	}

	const gatekeepers = getGatekeepers();
	const enabledSearch = gatekeepers.permission_search_on;
	return enabledSearch;
}

/**
 * Get permission for book on
 * @returns
 */
export function isBookEnabled() {
	const roleUser = localStorage.getItem("roleUser");
	const isDriver = roleUser === "21";

	if (!isDriver) {
		return true;
	}

	const gatekeepers = getGatekeepers();
	const enabledBook = gatekeepers.permission_book_on;
	return enabledBook;
}

/**
 * Check if has SmartPayProgram
 * @returns token
 */
export function hasSmartPayProgram() {
	return localStorage.getItem("hasSmartPayProgram") === "true" ? true : false;
}

/**
 * Cleaning all the data, we should never keep any information after a user logout,
 * called on log out or number of API errors such as 401 error
 */
export function clearUserData() {
	localStorage.clear();
}

/**
 * Saving new auth token
 * @param {*} data
 * @param {*} hasToken
 */
export function updateAuthToken(token) {
	localStorage.setItem("tokenSmarthop", token);
}

/**
 * Saving user data to local storage
 * @param {*} data
 * @param {*} hasToken
 */
export function saveUserData(data, hasToken) {
	const analyticsData = {
		name: data.first_name + " " + data.last_name,
		email: data.email,
		roleUser: data.roles[0],
		roleType: data.roleType,
		carrierId: data.carrierId,
		driverId: data.driverId,
		carrierActive: data.carrierActive,
		isOnboarding: data.isOnboarding,
		isTestCarrier: data.isTestCarrier,
		carrierCompanyName: data.carrier_company_name,
		carrierMcNumber: data.carrier_mc_number,
		carrierSaleforceId: data.carrier_saleforceId,
		onboardingStatus: data.onboardingStatus,
		userTier: data.userTier,
		hasDefaultPayment: data.hasDefaultPayment,
		hasStripeSubscription: data.hasStripeSubscription,
		subscriptionStatus: data.subscriptionStatus,
		hasSmartPayProgram: data.hasSmartPayProgram,
		hasSmartFuelProgram: data.hasSmartFuelProgram,
		freeTrialActive: data.freeTrialActive,
		freeTrialEndDate: data.freeTrialEndDate,
		carrierType: data.type_tier_carrier,
		accountStatus: data.accountStatus,
		typeCompany: data.typeCompany,
		carrier_trucks: data.carrier_trucks?.length,
		carrier_drivers: data.carrier_drivers?.length,
		subAccounts: data.subAccounts?.length ?? 0,
		tripsCount: data.tripsCount,
		lastTrip: data.lastTrip,
		carrier_dot_drivers: data.carrier_dot_drivers,
		carrier_dot_trucks: data.carrier_dot_trucks,
		latestRelease: data.latestRelease,
		lastReleaseSeen: data.lastReleaseSeen,
		hasNotSeenRelease: data?.lastReleaseSeen < data?.latestRelease ?? false,
	};
	const { name, email, roleUser, roleType, driverId, userTier, ...carrierAnalyticsData } = analyticsData;

	if (process.env.REACT_APP_ENV === "PRD") {
		let impersionating = !!localStorage.getItem("original_tokenSmarthop");
		if (!impersionating && data.roles[0] !== 0) {
			window.analytics.page();
			window.analytics.alias(data.email); // Alias Device and  with user
			window.analytics.identify(data.email, analyticsData); // Track user properties
			window.analytics.track(hasToken ? "Login" : "Re-Login", analyticsData);
			window.analytics.group(data.carrierId, carrierAnalyticsData);

			_refiner("identifyUser", {
				id: data.email, // Each user needs an ID or email address
				email: data.email, // Each user needs an ID or email address
				name: data.first_name + " " + data.last_name, // The full name of the user
				...analyticsData,
			});
		}
		// LogRocket
		if (!impersionating && (data.roles[0] === 2 || data.roles[0] === 22 || data.roles[0] === 21)) {
			LogRocket.init(process.env.REACT_APP_LOGROCKET);
			LogRocket.identify(data.email, analyticsData);
		}
	}

	if (window.Appcues) {
		window.Appcues.identify(data.email, analyticsData);
	}

	if (hasToken) {
		// Storing user token
		localStorage.setItem("tokenSmarthop", data.authorization_token);
	}

	// Storing default user id and carrier id
	localStorage.setItem("userId", data.userId ?? data._id);
	localStorage.setItem("carrierId", data.carrierId);
	localStorage.setItem("accountStatus", data.accountStatus);
	localStorage.setItem("driverId", data.driverId);
	localStorage.setItem("profileId", data.profileId);
	localStorage.setItem("accountId", data.accountId);
	localStorage.setItem("isOnboarding", data.isOnboarding || false);
	localStorage.setItem("onboardingStatus", data.onboardingStatus);
	localStorage.setItem("onboardingRejectionReason", data.onboardingRejectionReason);
	localStorage.setItem("isTestCarrier", data.isTestCarrier);
	// Storing some user information. That is a legacy logic, the user's information should not be stored
	// in the local storage, only the token which can be used to query such information on a page re-load
	localStorage.setItem("_id", data._id);
	localStorage.setItem("username", data.email);
	localStorage.setItem("firstName", data.first_name + " " + data.last_name);
	localStorage.setItem("roleUser", data.roles[0]);
	localStorage.setItem("gatekeepers", JSON.stringify(data.gatekeepers));
	localStorage.setItem("disabledDigitalBooking", data.disable_digital_booking);
	localStorage.setItem("disabledHelpRequest", data.disable_help_request);
	localStorage.setItem("dispatcherType", data.type_tier ? data.type_tier : null);
	localStorage.setItem("carrierType", data.type_tier_carrier ? data.type_tier_carrier : null);
	localStorage.setItem("debugMode", data.debug_mode ? data.debug_mode : false);
	localStorage.setItem("open_searches", data.open_searches || false);
	localStorage.setItem("only_best", data.only_best || false);
	localStorage.setItem("auto_launch", !!data.auto_launch || false);
	localStorage.setItem("user_tier", data.userTier);
	localStorage.setItem("payroll_settings", JSON.stringify(data.payrollSettings));
	localStorage.setItem("hasActivePods", data.hasActivePods);
	localStorage.setItem("AVG_FUEL_COST", data.avgFuelCost);
	localStorage.setItem("AVG_RPM", JSON.stringify(data.avgRPM ?? {}));
	localStorage.setItem("tutorials", JSON.stringify(data.tutorials ?? {}));
	localStorage.setItem("lastReleaseSeen", data.lastReleaseSeen ?? null);
	localStorage.setItem("latestRelease", JSON.stringify(data.latestRelease ?? {}));
	localStorage.setItem("releaseList", JSON.stringify(data.releaseList ?? {}));
	localStorage.setItem("subAccounts", JSON.stringify(data.subAccounts ?? null));
	localStorage.setItem("carrier_company_name", data.carrier_company_name ?? null);
	localStorage.setItem("hasSmartPayProgram", !!data.hasSmartPayProgram);
	localStorage.setItem("completeFirstStepPerformance", !!data.completeFirstStepPerformance);
	localStorage.setItem("lastTrip", data.lastTrip);
	// Formatting infomration for simpler use in Fuse, we can allow to redirect to a specific page from
	// backend logic, replacing roles numbers with rolse labels
	data.redirectUrl = "/";
	data.rolesLabels = [];
	data.rolesDescription = "";
	data.roles.forEach((role) => {
		let userRole = user_roles.find((item) => item.value === role);
		if (!userRole) {
			console.error(`[LoginService] Unsupported role ${role}`);
			return;
		}

		data.rolesLabels.push(userRole.label);

		if (data.rolesDescription.length > 0) data.rolesDescription += ", ";

		data.rolesDescription += userRole.description;

		let tier = role === 2 || role === 22 ? data?.type_tier_carrier ?? 0 : role === 1 ? data?.type_tier ?? 0 : -1;
		if (tier !== -1) {
			let userTier = userRole.tier_role?.find((item) => item.value === tier);
			if (!userTier) {
				console.log(`[LoginService] Unsupported tier ${tier}`);
				return;
			}
			if (userTier.label) {
				// in some cases tier role is not assigned by default, as for 0 tier for Dispatchers
				data.rolesLabels.push(userTier.label);
				data.rolesDescription += " (" + userTier.description + ")";
			}
		}
	});
}
