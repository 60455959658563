import CircularProgressWithIndicator from "app/main/utils/circular-progress/CircularProgressWithIndicator";
import CellFirstRow from "@smarthop/list/views/CellFirstRow";

const formatCellPercentage = (progress) => {
	if (isNaN(progress)) return "-";

	return <CircularProgressWithIndicator thickness={2} textClass="text-11" progress={progress} size={40} mr={0} />;
};

const formatCellContact = (contactName, phoneNumber, email) => {
	return (
		<div className={"flex flex-row items-center"}>
			<div className={"flex flex-col"}>
				<div className={"flex flex-row items-center "}>
					<div className="flex flex-wrap">
						{contactName && <CellFirstRow label={contactName} />}
						{phoneNumber && <CellFirstRow label={phoneNumber} url={`tel:${phoneNumber}`} />}
					</div>
				</div>
				<div className={"flex flex-row items-center "}>
					<div className="flex flex-wrap">
						{email && <CellFirstRow variant="spacious" label={email} url={`mailto:${email}`} />}
					</div>
				</div>
			</div>
		</div>
	);
};

const FILTERS = {
	search: true,
	searchDescription: "Search by Broker name, MC Number or Customer ID",
	items: [
		{
			key: "smartpayEnabled",
			type: "select",
			label: "SmartPay",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not selected",
				},
				{
					value: "true",
					label: "Eligible",
				},
				{
					value: "__FALSE_OR_EMPTY__",
					label: "No eligible",
				},
			],
		},

		{
			key: "carrier",
			type: "select",
			label: "Broker Type",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not selected",
				},
				{
					value: "__NOT_EMPTY__OBJECT__",
					label: "External",
				},
				{
					value: "__FALSE_OR_EMPTY__",
					label: "Internal",
				},
			],
		},
		{
			key: "possible_scammer",
			type: "select",
			label: "Scammer",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not selected",
				},
				{
					value: "true",
					label: "Possible scammer",
				},
				{
					value: "__FALSE_OR_EMPTY__",
					label: "No scammer",
				},
			],
		},
		{
			key: "automated_parsing_supported",
			type: "select",
			label: "Auto Parsing",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not selected",
				},
				{
					value: "true",
					label: "Auto Parsing",
				},
				{
					value: "__FALSE_OR_EMPTY__",
					label: "No Auto Parsing",
				},
			],
		},
		{
			key: "partner",
			type: "select",
			label: "Partner",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not selected",
				},
				{
					value: "true",
					label: "Is Partner",
				},
				{
					value: "__FALSE_OR_EMPTY__",
					label: "No Partner",
				},
			],
		},
		{
			key: "mcAge",
			type: "select",
			label: "MC length",
			defaultValue: "__NOT_SELECTED__",
			options: [
				{
					value: "__NOT_SELECTED__",
					label: "Not selected",
				},
				{
					value: "0",
					label: "Mc age 0 days",
				},
				{
					value: "30",
					label: "Mc age 30 days",
				},
				{
					value: "90",
					label: "Mc age 90 days",
				},
				{
					value: "120",
					label: "Mc age 120 days",
				},
				{
					value: "150",
					label: "Mc age 150 days",
				},
				{
					value: "180",
					label: "Mc age 180 days",
				},
				{
					value: "365",
					label: "Mc age 365 days",
				},
			],
		},
	],
};

const brokers = {
	urlGET: "api/brokers",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "ADMIN_BROKER",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: [
				"broker__view",
				"clientid",
				"mcnumber",
				"creditScore",
				"smartpayCollectionLimit",
				"smartpayCollectionCurrent",
				"smartpayCollectionCurrentPercentage",
				"smartpayCurrentInvoices",
			],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		groups: {
			items: [
				{
					key: "internalColumns",
					type: "checkbox",
					label: "Show Internal Fields",
				},
				{
					key: "smartPayGroup",
					type: "checkbox",
					label: "Show SmartPay Fields",
				},
				{
					key: "externalGroup",
					type: "checkbox",
					label: "Show External Fields",
				},
			],
		},
		filters: FILTERS,
		defaults: {
			// Initial value of fields
			initial: {
				filters: {
					smartpayEnabled: "__NOT_SELECTED__",
					carrier: "__FALSE_OR_EMPTY__",
				},
				groups: { externalGroup: false, smartPayGroup: false },
			},
			// Additional quick filters
			items: [
				{
					label: "Internal",
					filters: {
						smartpayEnabled: "__NOT_SELECTED__",
						carrier: "__FALSE_OR_EMPTY__",
					},
					groups: { externalGroup: false, smartPayGroup: false },
				},
				{
					label: "External",
					filters: {
						smartpayEnabled: "__NOT_SELECTED__",
						carrier: "__NOT_EMPTY__OBJECT__",
					},
					groups: {
						externalGroup: true,
						smartPayGroup: false,
					},
				},

				{
					label: "SmartPay Eligible",
					filters: {
						smartpayEnabled: true,
						carrier: "__FALSE_OR_EMPTY__",
					},
					groups: {
						externalGroup: false,
						smartPayGroup: true,
					},
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "Id", group: "internalColumns" },
			{
				key: "broker__view",
				type: "broker__view",
				label: "Broker",
				viewConfig: {
					showMcNumber: true,
					showScammer: true,
					showType: true,
					showSmartpay: true,
				},
			},
			{ key: "mcnumber", type: "text", label: "McNumber" },
			{ key: "creditScore", type: "number", label: "Credit Score" },
			{
				key: "contact",
				type: "component",
				label: "Contact",
				builder: (item) => {
					if (item.contact_name || item.contact || item.email) {
						return formatCellContact(item.contact_name, item.contact, item.email);
					}
					return "-";
				},
			},
			{
				key: "possible_scammer",
				type: "bool",
				label: "Scammer",
				group: "internalColumns",
			},
			{
				key: "partner",
				type: "bool",
				label: "Partner",
				group: "internalColumns",
			},
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
				},
				group: "externalGroup",
			},
			{
				key: "smartpayEmail",
				type: "component",
				label: "SmartPay Email",
				group: "smartPayGroup",
				builder: (item) => {
					if (item.smartpayEmail) {
						return formatCellContact(undefined, undefined, item.smartpayEmail);
					}
					return "-";
				},
			},
			{
				key: "smartpayCollectionLimit",
				type: "currency",
				label: "Limit",
				group: "smartPayGroup",
			},
			{
				key: "smartpayCollectionCurrent",
				type: "currency",
				label: "In collection",
				group: "smartPayGroup",
			},
			{
				key: "smartpayCollectionCurrentPercentage",
				type: "number",
				label: "In collection %",
				group: "smartPayGroup",
				builder: (item) => {
					const percentage = item?.smartpayCollectionCurrentPercentage;
					if (percentage || percentage === 0) {
						return formatCellPercentage(percentage);
					}
					return "-";
				},
			},
			{
				key: "smartpayCurrentInvoices",
				type: "number",
				label: "In Collection Invoices",
				group: "smartPayGroup",
			},
			{
				key: "smartpayCollectedInvoices",
				type: "number",
				label: "Collected Invoices",
				group: "smartPayGroup",
			},
			{
				key: "automated_parsing_supported",
				type: "bool",
				label: "Auto Parsing",
				group: "internalColumns",
			},
		],
	},
};

export default brokers;
