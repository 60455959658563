//Type
import {
	SIGN_UP_EVENT,
	COMPLETE_OBOARDING_DOCUMENTS_EVENT,
	COMPLETE_ONBOARDING_EVENT,
	FORM_DIALOG_EVENT,
	FORM_LIST_EVENT,
	VERIFY_CONTACT,
} from "./segmentType";

export const createTrackOrPage = (event, data, type, title = null) => {
	let sendData = convertDataSegment(event, data, title);
	if (event === SIGN_UP_EVENT) {
		window.analytics.identify(data.email, sendData);
	}
	if (type === "track") {
		window.analytics.track(event, sendData);
	} else if (type === "page") window.analytics.page(event, sendData);
};

const convertDataSegment = (type, data, title) => {
	if (type === SIGN_UP_EVENT || type === VERIFY_CONTACT) return formatDataSignUp(data);
	else if (type === FORM_DIALOG_EVENT) return formatDataFormDialog(data, title);
	else if (type === FORM_LIST_EVENT) return formatDataFormList(data);
	else if (type.includes("Page")) return formatDataPage(data, title);
	else if (type === COMPLETE_OBOARDING_DOCUMENTS_EVENT) return formaDataCompleteDocument(data);
	else if (type === COMPLETE_ONBOARDING_EVENT) return formaDataCompleteOnboarding(data);
	else return data;
};

const formatDataFormList = (data) => {
	return {
		type: data.type,
	};
};

const formatDataFormDialog = (data, title) => {
	return {
		name: title,
		title: title,
		formId: data.formId ?? data.viewId,
	};
};

const formatDataSignUp = (data) => {
	return {
		email: data?.email,
		preferred_language: data?.preferred_language,
		phone: data?.phone,
	};
};

const formatDataPage = (data, title) => {
	return {
		name: title,
		title: title,
		url: data.location.href,
		search: data.location.search,
	};
};

const formaDataCompleteDocument = (data) => {
	return {
		onboading_documents: true,
	};
};

const formaDataCompleteOnboarding = (data) => {
	return {
		onboading_finish: true,
	};
};
