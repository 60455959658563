import { openFormDialog } from "app/store/tools/formDialogSlice";

// Constans
const carriers = {
	urlGET: "api/messenger/participants",
	idKey: "_id",
	listenEvent: "chatRevision",
	segmentKey: "ADMIN_CHAT_PARTICIPANTS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["chat", "user", "createdAt", "updatedAt", "lastReceivedSentUnix", "lastSeenSentUnix"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "lastSeenSentUnix",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "user",
					type: "autocomplete",
					label: "User",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
					},
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal_ids",
					type: "checkbox",
					label: "Show Internal Ids",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "Participant ID", group: "internal_ids" },
			{ key: "chat", type: "id", label: "Chat ID", group: "internal_ids" },
			{ key: "user", type: "id", label: "User ID", group: "internal_ids" },
			{ key: "createdAt", type: "date", label: "Created At" },
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showStatus: true,
				},
			},
			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				viewConfig: {
					showTeam: true,
				},
			},
			{
				key: "user__view",
				type: "user__view",
				label: "User",
				viewConfig: {
					showRole: true,
				},
			},
			{ key: "lastReceivedSentUnix", type: "unix", label: "Last Received At" },
			{ key: "lastSeenSentUnix", type: "unix", label: "Last Seen At" },
			{ key: "chat_type", type: "text", label: "Chat Type" },
			{ key: "type", type: "text", label: "Participant Type" },
			{ key: "active", type: "bool", label: "Active" },
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "chat_details",
						label: "Chat Details",
						redirectURLBuilder: (item) => "/admin/chats/" + item.chat + "/details",
					},
					{
						key: "send_message",
						label: "Send Message",
						dispatchAction: (item, dataIds, dispatch) => {
							let data = {
								chat: item.chat,
								chat__view: { value: item.chat, label: `Chat ${item.chat}` },
								participant: item._id,
								participant__view: {
									value: item._id,
									label: `Participant ${item._id} (Email ${item.metadata?.user?.email})`,
								},
							};
							let dialog = {
								formId: "ADMIN_CHAT_MESSAGE_FORM",
								dataIds: { ...dataIds },
								data: data,
								mode: "CREATE",
							};
							dispatch(openFormDialog(dialog));
						},
					},
					{
						key: "edit",
						label: "Edit",
						dialog: { formId: "ADMIN_CHAT_PARTICIPANT_FORM", mode: "EDIT" },
					},
					{
						key: "delete",
						label: "Delete",
						dialog: { formId: "ADMIN_CHAT_PARTICIPANT_FORM", mode: "DELETE" },
					},
				],
			},
		],
	},
};

export default carriers;
