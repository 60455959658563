import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

function TripBillCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const view = props.view;

	const active = view?.metadata?.active;

	if (!view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "BILL_INFO_VIEW",
		dataIds: { carrierId: view?.value },
	};

	if (config?.readOnly) {
		return view.label;
	}

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			key={view.value}
			first={<CellFirstRow mobile={mobile} view={view} variant={variant} dialog={dialog} />}
			second={
				<div className="flex flex-wrap">
					{config?.showStatus && !active && (
						<CellSecondRow
							mobile={mobile}
							icon={active ? "done" : "close"}
							label={active ? "Active" : "Inactive"}
							color={active ? " text-green-700" : " text-red-600"}
							variant={variant}
						/>
					)}
				</div>
			}
		/>
	);
}

export default TripBillCellView;
