import moment from "moment";
import authRoles from "../consts/authRoles";
import {
	LOCATION_ORIGIN,
	LOCATION_DESTINATION,
	TRAILER_EQUIPMENT,
	CARRIER_ASSIGNED,
	TRUCK_ASSIGNED,
	CARRIER_ALL,
	TRUCK_ALL,
	CARRIER_TRUCKS,
	DRIVER_TRUCKS,
} from "../search/config/searchFields";

const { actionOptions, ...autocomplete } = CARRIER_TRUCKS().autocomplete;
const CARRIER_TRUCKS_FILTERED = { ...CARRIER_TRUCKS(), autocomplete };

const createForm = (role, user, options, classes) => {
	const isAdmin = authRoles.internalPowerUsers.includes(role);
	const isDispatch = authRoles.dispatcher.includes(role);
	const isCarrier = authRoles.carrier.includes(role);
	const isManager = authRoles.carrierManager.includes(role);

	return {
		form: {
			noErrorMessage: true,
			classes: {},
		},
		items: [
			{
				type: "group",
				group: {
					classes: {
						root: "flex-col md:flex-row",
						direction: undefined,
					},
				},
				content: {
					items: [
						...(options.topLevel
							? isAdmin || isDispatch
								? [isAdmin ? CARRIER_ALL : CARRIER_ASSIGNED, isAdmin ? TRUCK_ALL : TRUCK_ASSIGNED]
								: [isCarrier || isManager ? CARRIER_TRUCKS_FILTERED : DRIVER_TRUCKS]
							: []),
						...(!options.topLevel ? [TRAILER_EQUIPMENT] : []),
						LOCATION_ORIGIN,
						{
							key: "start_date",
							type: "picker",
							label: "Start date",
							translate: "",
							field: {
								noErrorMessage: true,
								classes: {},
								variant: undefined,
							},
							picker: {
								disablePast: true,
								type: "date",
								classes: {},
								maxDate: moment().add(3, "d"),
							},
						},
						{ ...LOCATION_DESTINATION, disabled: !!options?.cycle },
						{
							key: "max_end_date",
							type: "picker",
							label: "Max. End date",
							translate: "",
							field: {
								noErrorMessage: true,
								classes: {},
								variant: undefined,
							},
							picker: {
								disablePast: true,
								type: "date",
								classes: {},
								maxDate: moment().add(13, "d"),
							},
						},

						{
							type: "menu",
							icon: "tune",
							button: { color: "primary", classes: {} },
							content: {
								items: [
									{
										key: "max_paths",
										type: "slider",
										label: "Max Paths",
										translate: "",
										defaultValue: 10,
										slider: {
											type: "input",
											prefix: "",
											postfix: "",
											step: 5,
											min: 5,
											max: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "top_first_move",
										type: "slider",
										label: "Max Top Paths",
										translate: "",
										defaultValue: 5,
										slider: {
											type: "input",
											prefix: "",
											postfix: "",
											step: 5,
											min: 5,
											max: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},

									{
										key: "path_length",
										type: "slider",
										label: "Max Legs",
										translate: "",
										defaultValue: 3,
										slider: {
											type: "input",
											prefix: "",
											postfix: " legs",
											step: 1,
											min: 1,
											max: 5,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "min_volume",
										type: "slider",
										label: "Min Volume",
										translate: "",
										defaultValue: 3,
										slider: {
											type: "input",
											prefix: "",
											postfix: " loads/day",
											step: 1,
											min: 3,
											max: 50,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "end_market_profit",
										type: "slider",
										label: "Ending Market Profit",
										translate: "",
										defaultValue: 0,
										slider: {
											type: "input",
											step: 100,
											prefix: "$",
											postfix: "",
											min: -2000,
											max: 2000,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "blacklisted_states",
										type: "autocomplete",
										label: "Blacklisted States",
										translate: "",
										field: {
											noErrorMessage: false,
											classes: {},
											variant: undefined,
										},
										autocomplete: {
											provider: "smarthop",
											url: "api/consts/states",
											types: [],
											multiple: true,
											anyValue: false,
											preloadDefault: true,
											icon: "location",
											maxValues: 10,
										},
									},
									{
										key: "min_profit",
										type: "number",
										label: "Target Profit",
										translate: "",
										defaultValue: 0,
									},
									{
										key: "max",
										type: "slider",
										label: "Min Profit per Leg",
										translate: "",
										defaultValue: -500,
										slider: {
											type: "input",
											step: 50,
											prefix: "$ ",
											postfix: "",
											min: -2000,
											max: 200,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "first_leg_volume",
										type: "slider",
										label: "First Level Total Volume",
										translate: "",
										defaultValue: 25,
										slider: {
											type: "input",
											prefix: "",
											postfix: " loads/day",
											step: 5,
											min: 10,
											max: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "miles_range",
										type: "slider",
										label: "Miles Range",
										translate: "",
										defaultValueMin: 600,
										defaultValueMax: 6000,
										slider: {
											type: "range",
											postfix: " mi",
											min: 600,
											max: 6000,
											step: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "sort_by",
										type: "select",
										label: "Sort by",
										defaultValue: "balanced_profit",
										field: {
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
										options: [
											{
												value: "balanced_profit",
												label: "Balanced Profit",
												selected: true,
												translate: "balanced_profit",
											},
											{
												value: "total_profit",
												label: "Total Profit",
												translate: "total_profit",
											},
											{
												value: "profit_per_day",
												label: "Profit per Day",
												translate: "profit_per_day",
											},
											{
												value: "total_risk",
												label: "Total Risk",
												translate: "total_risk",
											},
											{
												value: "total_payment",
												label: "Total Payment",
												translate: "total_payment",
											},
											{
												value: "total_rpm",
												label: "Total RPM",
												translate: "total_rpm",
											},
											{
												value: "total_miles",
												label: "Total Miles",
												translate: "total_miles",
											},
										],
									},
								],
							},
						},
						{
							type: "action",
							action: "SUBMIT",
							label: "Submit",
							icon: undefined,
							button: {
								alwaysEnabled: true,
								classes: {
									root: classes.buttonNormal,
									disabled: classes.buttonDisabled,
								},
							},
						},
					],
				},
			},
			{
				type: "action",
				button: {
					classes: {
						root: "hidden",
					},
				},
				confirmation: {
					enable: !!options?.cycle,
					message:
						"You have selected the cycle option, which means your destination is the same as your origin. Do you want to continue?",
				},
			},
		],
	};
};
export { createForm };
