import { Typography, Button, Divider, withStyles, Switch, Icon, Box } from "@material-ui/core";
import { createTooltip } from "app/main/utils/tableUtils";
import Alert from "@material-ui/lab/Alert";
import Image from "material-ui-image";

const DisplaySection = (props) => {
	return (
		<div
			className={
				(props.classes?.root?.includes("flex") || props.classes?.root?.includes("hidden") ? "" : " flex ") +
				" flex-col " +
				(props.classes?.root?.includes("mt-") ? "" : " mt-8 ") +
				" " +
				(props.classes?.root?.includes("mb-") ? "" : " mb-16 ") +
				" " +
				(props.classes?.root?.includes("mx-") ? "" : " mx-12 ") +
				" " +
				(props.classes?.root ?? "")
			}
		>
			<div className="flex flex-row flex-wrap">
				{props.bannerType && (
					<Alert className="w-full mb-12" severity={props.bannerType}>
						{props.bannerMsg}
					</Alert>
				)}
				<Typography
					component={"div"}
					className={
						"flex flex-1 items-end " +
							(props.classes?.title?.includes("font-") ? "" : " font-semibold ") +
							(props.classes?.title?.includes("mt-") ? "" : " text-14 ") +
							props.classes?.title ?? ""
					}
				>
					{props.title}
				</Typography>
				<div className={"flex flex-row py-0 my-0 justify-end " + (props.classes?.labelsContainer ?? "")}>
					{props.labels?.map((item, i) => (
						<Typography
							component={"div"}
							key={"label_" + item.label + "_" + i}
							className={
								"pr-2 flex font-semibold font-medium text-14 " +
								(item.classes?.label?.includes("text-") ? " " : " text-grey-700 ") +
								(item.classes?.label?.includes("pl-") ? " " : " pl-8 ") +
								" " +
								(item.classes?.label ?? "")
							}
						>
							{item.label}
						</Typography>
					))}
					{props.actions?.map((item, i) => (
						<Button
							variant={"text"}
							className={(item.label ? " px-10 " : "") + " font-bold bg-none text-primary py-0 uppercase text-12 "}
							key={"action_" + item.label + "_" + i}
							onClick={() => item.onClick?.()}
							disabled={item.disabled ?? false}
							startIcon={item.startIcon ?? false}
						>
							{item.icon && <Icon className="text-16 mx-3">{item.icon}</Icon>}
							{item.label ?? ""}
						</Button>
					))}
					{props.views?.map((item, i) => (
						<div className="flex" key={"view_" + i}>
							{item}
						</div>
					))}
				</div>
			</div>
			<Divider className={(props.classes?.divider?.includes("mb-") ? "" : " mb-8 ") + (props.classes?.divider ?? "")} />
			{props.children}
		</div>
	);
};

const DisplayField = ({ variant, label, value, noWrap, classes }) => {
	return (
		<div
			className={
				(classes?.root?.includes("flex") || classes?.root?.includes("hidden") ? "" : " flex ") +
				" flex-col w-full " +
				(classes?.root?.includes("my-") ? "" : " my-8 ") +
				" " +
				(classes?.root?.includes("mr-") ? "" : " mr-4 ") +
				" " +
				(classes?.root ?? "")
			}
		>
			<Typography
				className={(variant === "skinny" ? " text-12  " : " text-13  ") + " text-gray-600 pr-8"}
				component={"div"}
			>
				{label}
			</Typography>
			<Typography
				component={"div"}
				className={
					(variant === "skinny" ? " text-12 " : " text-13 ") +
					" pr-8 " +
					(noWrap ? " whitespace-nowrap " : !value?.includes?.(" ") ? " break-all " : "  break-words  ") +
					" " +
					(classes?.value ?? "")
				}
			>
				{value ? value : "No Information"}
			</Typography>
		</div>
	);
};

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(0.5),
	},
	switchBase: {
		padding: 1,
		"&$checked": {
			transform: "translateX(16px)",
			color: theme.palette.common.white,
			"& + $track": {
				backgroundColor: "#52d869",
				opacity: 1,
				border: "none",
			},
		},
		"&$focusVisible $thumb": {
			color: "#52d869",
			border: "6px solid #fff",
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"]),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

const renderDataItem = (label, value, type, options) => {
	return (
		<div
			className={
				"flex w-full" +
				(options?.vertical ? " flex-col " : " flex-row ") +
				(options?.classes?.root?.includes("border-") ? "" : " border-b-1 border-grey-300 ") +
				(options?.classes?.root ?? "")
			}
		>
			<Typography component={"div"} className={`flex flex w-full text-black text-12 break-normal mt-10`}>
				{label}
				{options?.tooltip?.label?.message &&
					createTooltip(
						<Icon className="text-15 -mb-3 ml-3" color="action">
							{options?.tooltip?.icon ?? "info"}
						</Icon>,
						<Typography className="break-words whitespace-normal min-w-100 text-12 font-light">
							{options.tooltip.label.message}
						</Typography>,
						"bg-gray-600"
					)}
			</Typography>
			<Typography
				className={
					"text-12 break-normal  " +
					(options?.classes?.value ?? "") +
					(!options?.vertical
						? ` mt-10 font-semibold text-primary-700 ${
								value?.length > 45 ? " whitespace-break-spaces " : " whitespace-nowrap "
						  } `
						: " text-grey-700 ") +
					(!options?.animate ? "" : options?.loading ? " animate-pulse " : "")
				}
			>
				{!value ? "-" : value} {!value ? "" : type}
			</Typography>
			{options?.tooltip?.value?.message &&
				createTooltip(
					<Icon className="text-15 mt-10 -mb-2 ml-2" color="action">
						{options?.tooltip?.value?.icon ?? "info"}
					</Icon>,
					<Typography className="break-words whitespace-normal min-w-100 text-12 ml:text-13 font-light">
						{options.tooltip.value.message}
					</Typography>,
					"bg-gray-600"
				)}
		</div>
	);
};

const BADGES_INFORMATION = {
	BEST: {
		title: "Best Load",
		color: "bg-primary",
		description: () => {
			return (
				<>
					<Typography color="inherit" variant="body2" className="p-6">
						Best Load ("HOP")
					</Typography>
					<Typography color="inherit" variant="body2" className="p-6 font-light">
						This load is expected to be profitable and the destination market will yield good profits too!
					</Typography>
				</>
			);
		},
		icon: {
			type: "IMAGE",
			value: "assets/images/badges/best.png",
			style: "bg-primary",
		},
	},
	ALL: {
		title: "All Loads",
		color: "bg-cyan",
		description: () => {
			return "All loads";
		},
		icon: {
			type: "LABEL",
			value: "A",
			style: "bg-grey",
		},
	},
	NEW: {
		title: "New Load",
		color: "bg-cyan",
		description: () => {
			return "This is a new load";
		},
		icon: {
			type: "LABEL",
			value: "N",
			style: "bg-cyan",
		},
	},
	MATCH_PLAN: {
		title: "Match",
		color: "bg-blue-900",
		description: () => {
			return "Plan Match: this load matches with your truck plan criteria.";
		},
		icon: {
			type: "LABEL",
			value: "M",
			style: "bg-blue-900",
		},
	},
};

const createIconBadge = (type, variant = "skinny") => {
	const badge = BADGES_INFORMATION[type];
	return createMatchBadge(badge.icon.type, badge.icon.value, badge.icon.style, variant);
};

const createMatchBadge = (type, value, style, variant) => {
	const contentLabel =
		variant === "skiny" ? { width: 21, height: 21, marginRight: 2 } : { width: 26, height: 26, marginRight: 5 };

	const styleLabel = variant === "skiny" ? " text-11 " : " text-12 ";

	if (type === "LABEL") {
		return (
			<Box
				component="div"
				sx={contentLabel}
				className={style + " flex items-center justify-center rounded-full truncate "}
			>
				<Typography component={"div"} className={"text-white font-500 " + styleLabel}>
					{value}
				</Typography>
			</Box>
		);
	}

	const containerIcon =
		variant === "skiny"
			? " px-1 py-1 rounded-full truncate mt-1 ml-1 "
			: variant === "extra-skiny"
			? " px-1 py-1 rounded-full truncate mt-1 ml-1 "
			: "";

	const styleIcon =
		variant === "skiny" ? " -ml-1 -mr-1 text-16 " : variant === "extra-skiny" ? " -ml-1 -mr-3 text-16 " : " mr-3 ";

	if (type === "ICON") {
		return (
			<span className={"flex inline " + containerIcon}>
				<Icon className={style + " font-500 " + styleIcon}>{value}</Icon>
			</span>
		);
	}

	const containerImage =
		variant === "skiny"
			? " py-8 px-3 mr-2 "
			: variant === "extra-skiny"
			? " py-5 px-1 my-auto mr-2 "
			: variant === "large"
			? " py-8 px-3 mr-2  "
			: " py-10 px-4 mr-5 ";

	const containerStyle =
		variant === "skiny"
			? {}
			: variant === "extra-skiny"
			? {}
			: variant === "large"
			? { width: "26px", height: "26px", marginRight: "5px" }
			: {};

	const styleImage =
		variant === "skiny"
			? { width: "14px", height: "5px", marginRight: "1px" }
			: variant === "extra-skiny"
			? { width: "10px", height: "4px", marginRight: "2px" }
			: variant === "large"
			? { width: "22px", height: "8px" }
			: { width: "16px", height: "5px", marginRight: "1px" };

	if (type === "IMAGE") {
		return (
			<div style={containerStyle} className={style + " rounded-full truncate flex " + containerImage}>
				<Image
					disableSpinner={true}
					disableTransition={true}
					imageStyle={styleImage}
					style={{ ...styleImage, paddingTop: null }}
					color="primary"
					src={value}
				/>
			</div>
		);
	}
};

const BadgeCounter = ({ icon, count, fixedWidth = false }) => {
	return (
		<div style={fixedWidth ? { width: fixedWidth } : {}} className="flex flex-row items-center space-x-4">
			<div>{icon}</div>
			<div>{count}</div>
		</div>
	);
};

export { DisplaySection, DisplayField, IOSSwitch, renderDataItem, createIconBadge, BadgeCounter };
