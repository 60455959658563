import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { formatCurrency, formatPercentage } from "../../../utils/tableUtils";

const useStyles = makeStyles({
	box: {
		display: "flex",
		flexDirection: "column",
		width: "7rem",
		marginTop: "-10px",
		justifyContent: "center",
		alignItems: "center",
		cursor: "default",
	},
});

const ArrowComponent = ({ arrowSize }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={arrowSize}
		height={arrowSize}
		viewBox={`0 0 ${arrowSize} ${arrowSize}`}
	>
		<path
			d={`M ${arrowSize * 0.8} ${arrowSize / 2} l -${arrowSize * 0.3} -${arrowSize * 0.3} v${arrowSize * 0.2} h-${
				arrowSize * 0.3
			} v${arrowSize * 0.2} h${arrowSize * 0.3} v${arrowSize * 0.2} z`}
		/>
	</svg>
);
// rgb('211','211','211')
const MoveConnector = ({ node }) => {
	const classes = useStyles();
	const probability = formatPercentage(1, node?.data?.stop?.probability || 0);
	return (
		<Box className={classes.box}>
			<Tooltip title={`${probability} probability of finding a load`}>
				<Box>
					<Typography>{probability}</Typography>
				</Box>
			</Tooltip>
			<ArrowComponent arrowSize={25} />
			<Box bgcolor="rgb(211,211,211)" p={"5px"} px={1} textAlign="center" borderRadius={"10px"} marginBottom={1}>
				<Typography style={{ color: node?.data?.stop?.payment <= 0 ? "red" : "inherit" }}>
					<b style={{ fontSize: "smaller" }}>RPM</b>
					<br />
					{formatCurrency(node?.data?.stop?.rpm || 0)}
				</Typography>
			</Box>
			<Typography style={{ color: node?.data?.stop?.payment <= 0 ? "red" : "inherit", fontSize: "1rem" }}>
				{formatCurrency(node?.data?.stop?.payment || 0, 0)}
			</Typography>
			<Typography style={{ color: node?.data?.stop?.empty ? "red" : "inherit", fontSize: "1rem" }}>
				{`${node?.data?.stop?.loaded_miles + node?.data?.stop?.empty_miles} mi`}{" "}
			</Typography>
		</Box>
	);
};

export default MoveConnector;
