import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Icon,
	Radio,
	RadioGroup,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

// Utils
import { createTooltip, formatCurrency } from "app/main/utils/tableUtils";
import { calculateSmartpayValues, calculateTransferFee } from "./invoiceUtils";
import { getInvoiceForTrip, updateInvoiceStatus } from "app/services/tripInvoicingServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { closeFormDialog } from "app/store/tools/formDialogSlice";
import SmartPayJournalEntriesComponent from "../SmartPayJournalEntriesComponent";

const { DisplaySection, renderDataItem } = require("app/main/utils/uiUtils");

const PaymentTypeDescriptions = {
	ACH_PAYMENT:
		"* If the invoice is approved before 2:30 PM ET, the payment will be processed that business day and you can expect to receive funds in 2-4 business days. If the invoice is approved after 2:30 PM ET, the payment will be processed on the next business day. The transfer fee for this payment is {VAR_TRANSFER_FEE}.",
	WIRE_PAYMENT:
		"* If the invoice is approved before 11:00 AM ET, the payment will be processed that business day, and you can expect to receive funds on the same business day. If the invoice is approved after 11:00 AM ET, the payment will be processed on the next business day. The transfer fee for this payment is {VAR_TRANSFER_FEE}.",
};

const paymentTypesMap = {
	ACH_PAYMENT: "ach",
	WIRE_PAYMENT: "wire",
};

const SmartPaySubmissionView = ({ dataIds, setTitle, setSize }) => {
	const { carrierId, tripId } = dataIds;

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [tripInvoice, setTripInvoice] = useState();
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		(async () => {
			const invoice = await getInvoiceForTrip(carrierId, tripId);
			setTripInvoice(invoice);
			setLoading(false);
		})();
		setTitle?.("Submit");
		setSize?.("max-w-md");
		// eslint-disable-next-line
	}, []);

	const [paymentType, setPaymentType] = useState("ACH_PAYMENT");

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	// Smartpay Plan Fee
	const smartPayFeePercentage = tripInvoice?.smartpay_plan?.smartpay_fee_percentage;
	// Plan's fees per payment type
	const feesPerPaymentType = tripInvoice?.smartpay_plan?.payment_types;

	if (!feesPerPaymentType) {
		console.log("No plan found for this carrier");
		return "No SmartPay Plan found for this account";
	}

	// Selected's payment type fees
	const paymentTypeFees = feesPerPaymentType ? feesPerPaymentType[paymentTypesMap[paymentType]] : {};

	const baseTransferFeeCents = paymentTypeFees?.base_transfer_fee_cents;
	const maxTransferFeeCents = paymentTypeFees?.max_transfer_fee_cents;
	const transferFeePercentage = paymentTypeFees?.transfer_fee_percentage;

	const additionsAndDeductionsSum = tripInvoice?.trip_data.additions_deductions.reduce((total, additionOrDeduction) => {
		const amount = additionOrDeduction.amount * (additionOrDeduction.direction === "CREDIT" ? 1 : -1);
		return total + +amount;
	}, 0);

	// Incoming invoice
	const { netFundCents, smartpayFeeCents } = calculateSmartpayValues({
		tripRateCents: tripInvoice?.trip_data?.rate * 100,
		invoiceAmountCents: tripInvoice?.amount * 100,
		smartPayFeePercentage,
	});

	// Next Payout Transfer Fee
	const { cappedTransferFeeCents } = calculateTransferFee({
		payoutEntries: tripInvoice?.smartpay?.nextPayout?.entries,
		incomingAmountCents: netFundCents,
		baseTransferFeeCents,
		transferFeePercentage,
		maxTransferFeeCents,
	});

	// Next Payout Total
	const totalToBeFundedCents = tripInvoice?.smartpay?.nextPayout?.entries.reduce(
		(total, entry) => total + +entry.amount_cents,
		netFundCents
	);

	const netAmountCents = totalToBeFundedCents - cappedTransferFeeCents;

	/**
	 * Handle Invoice Submission to Smartpay
	 */
	const handleSubmit = async () => {
		try {
			setProcessing(true);
			await updateInvoiceStatus(carrierId, tripInvoice._id, "SP_SUBMITTED", { smartpayPaymentType: paymentType });
			if (window?.ReactNativeWebView) {
				window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } }));
			}
			showSnackbar(snackbar, "Invoice submitted to SmartPay", "success");
			dispatch(closeFormDialog());
			dispatch(incrementDataRevision({ event: "tripsRevision" }));
		} catch (e) {
			showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
		} finally {
			setProcessing(false);
		}
	};

	let transferFeeCalculationText = (
		(baseTransferFeeCents > 0 ? `${formatCurrency(baseTransferFeeCents / 100)}` : "") +
		(transferFeePercentage > 0 ? ` + ${transferFeePercentage}%` : "") +
		(maxTransferFeeCents > 0 ? `  with a cap of ${formatCurrency(maxTransferFeeCents / 100)}` : "")
	).trim();

	if (transferFeeCalculationText.length === 0) {
		transferFeeCalculationText = "0$";
	}

	return (
		<div className={processing ? " opacity-60 pointer-events-none " : ""}>
			<FormControl fullWidth className="mb-8 mx-4 mt-8">
				<div className="flex flex-col md:flex-row md:space-x-16 md:content-center md:items-center border-b-1 border-black mb-16">
					<FormLabel>Payment Type</FormLabel>
					<RadioGroup
						onChange={(event) => {
							setPaymentType(event.target.value);
						}}
						value={paymentType}
						className="flex flex-col md:flex-row"
					>
						<FormControlLabel value={"ACH_PAYMENT"} control={<Radio />} label="ACH (2-4 Business Days*)" />
						<FormControlLabel value={"WIRE_PAYMENT"} control={<Radio />} label="Same Day*" />
					</RadioGroup>
				</div>
				<Typography className="text-10 m-10 mt-0">
					{PaymentTypeDescriptions[paymentType].replace("{VAR_TRANSFER_FEE}", transferFeeCalculationText)}
				</Typography>
			</FormControl>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<div className="flex w-full flex-col md:flex-row">
					<DisplaySection classes={{ root: "md:w-full" }} title={`New Invoice Item`}>
						<div className="-mt-10">
							{renderDataItem(`SmartPay Fee`, formatCurrency(smartpayFeeCents / 100), "", {})}
							{renderDataItem(
								`Invoice #${tripInvoice?.carrier_ref} | Trip Funding`,
								formatCurrency(netFundCents / 100),
								"",
								{}
							)}
							{additionsAndDeductionsSum > 0 &&
								renderDataItem(
									`Additions and Deductions | Outstanding (Non-fundable)`,
									formatCurrency(additionsAndDeductionsSum),
									"",
									{}
								)}
						</div>
					</DisplaySection>
				</div>
			</div>
			<SmartPayJournalEntriesComponent
				title="Other Included Items"
				payoutEntries={tripInvoice?.smartpay?.nextPayout?.entries}
				hideStatus={true}
				hidePayout={true}
			/>
			<div className="flex w-full flex-col md:flex-row">
				<DisplaySection classes={{ root: "md:w-full" }} title={`Payout Totals`}>
					<div className="-mt-10">
						{renderDataItem(
							// If calculation is very simple, and value is static, no need to show tooltip
							!transferFeeCalculationText?.includes("%")
								? "Transfer Fee"
								: createTooltip(
										<>
											<span>Transfer Fee</span>
											<Icon className="text-13 ml-4 pt-1">info</Icon>
										</>,
										transferFeeCalculationText
								  ),
							formatCurrency(cappedTransferFeeCents / 100),
							"",
							{}
						)}
						{renderDataItem("Net Amount", formatCurrency(netAmountCents / 100), "", {})}
					</div>
				</DisplaySection>
			</div>
			<div className="flex flex-col w-full">
				<Button
					//disabled={processing}
					variant="contained"
					className="bg-green text-white w-full mb-10"
					startIcon={<Icon>send</Icon>}
					onClick={handleSubmit}
				>
					Submit Invoice
				</Button>
			</div>
		</div>
	);
};

export default SmartPaySubmissionView;
