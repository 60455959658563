import { List } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Collapse } from "@mui/material/";
import { motion } from "framer-motion";
import Divider from "@material-ui/core/Divider";

import { buildChannelId } from "../utils/chatUtils";

import ChatListItemByType from "./ChatListItemByType";
import ChatListChannelItem from "./ChatListChannelItem";

//NOTE: If you add a new channel, also add it in the mobile app
const CHANNEL_NAME = { TRUCK_DELIVERY: "Delivery Channels", TRUCK_EXTERNAL: "Dispatching Channels" };
const CHAT_PRIORITY = {
	TRUCK_EXTERNAL: 1,
	TRUCK_DELIVERY: 2,
	data: 3,
};

function ChatListChannels(props) {
	const chats = props.chats;
	const subAccount = props.subAccount;
	const collapsibleStatuses = props.collapsibleStatuses;
	const onClickChat = props.onClick;
	const onClickCollapse = props.onClickCollapse;
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);
	const iconAligment = props.iconAligment ?? "left";

	let sortedChannels = chats[subAccount]
		? Object.keys(chats[subAccount]).sort((a, b) => {
				return CHAT_PRIORITY[a] - CHAT_PRIORITY[b];
		  })
		: [];
	return (
		<List className="p-0 w-full">
			{sortedChannels &&
				sortedChannels.map(
					(subchannel) =>
						subchannel !== "data" && (
							<>
								<ChatListChannelItem
									key={CHANNEL_NAME[subchannel]}
									title={CHANNEL_NAME[subchannel]}
									unread={false}
									onClick={(channelId) => onClickCollapse(channelId)}
									channelId={buildChannelId(subAccount, subchannel)}
									channelIsOpen={collapsibleStatuses?.[buildChannelId(subAccount, subchannel)]}
									iconAligment={iconAligment}
								/>
								<Collapse
									key={"collapse_" + CHANNEL_NAME[subchannel]}
									in={!collapsibleStatuses?.[buildChannelId(subAccount, subchannel)]}
									timeout="auto"
									unmountOnExit
									className="w-full"
								>
									{chats[subAccount][subchannel]?.map((chat) => (
										<motion.div
											variants={{ hidden: { opacity: 0, y: 40 }, show: { opacity: 1, y: 0 } }}
											key={`${subchannel}_${chat?._id}`}
										>
											<ChatListItemByType
												activeChatId={activeChat?._id}
												message={chat}
												viewerIsOwner={true}
												onClick={(chat) => onClickChat(chat)}
											/>
										</motion.div>
									))}
								</Collapse>
								<Divider key={"divider_" + buildChannelId(subAccount, subchannel)} />
							</>
						)
				)}
		</List>
	);
}

export default ChatListChannels;
