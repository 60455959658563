import RegisterForm from "./RegisterForm";
import LandingPageContainer from "../LandingPageContainer";

function Register() {
	return (
		<LandingPageContainer viewType="register">
			<RegisterForm viewType="register" />
		</LandingPageContainer>
	);
}

export default Register;
