import { useParams } from "react-router-dom";

// Components
import FormSection from "app/main/tools/FormSection";
import PageWrapper from "../../common/PageWrapper";

// Service
import { getCarrierId, getUserId } from "app/services/LoginService";

function UsersDetailsPayrollPage(props) {
	const params = useParams();

	const carrierId = props.isView ? props?.params?.carrierId : params.carrierId ?? getCarrierId();
	const userId = props.isView ? props.params?.userId : params.userId ?? getUserId();
	const isInvestor = props.params?.isInvestor;
	const isDispatcher = props.params?.isDispatcher;

	return (
		<PageWrapper title="User Payroll" variant="narrow" isView={props.isView}>
			<div className="flex flex-col items-top w-full space-x-0">
				<div className="flex flex-col md:flex-row items-top w-full space-x-0 md:space-x-20">
					<FormSection
						title={props.formSectionTitle ?? "Information"}
						formId={
							isInvestor ? "INVESTOR_PAYROLL_FORM" : isDispatcher ? "DISPATCHER_PAYROLL_FORM" : "DRIVER_PAYROLL_FORM"
						}
						dataIds={{ userId, carrierId: carrierId, isInvestor }}
						actions={[
							{
								action: "edit",
								mode: "EDIT",
								label: "Edit",
								auth: ["administrator", "dispatcher", "carrier", "carrier_manager"],
							},
						]}
					/>
				</div>
			</div>
		</PageWrapper>
	);
}

export default UsersDetailsPayrollPage;
