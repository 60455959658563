import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

const buildTooltip = (enabled, icon, description, classes) => {
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
			title={<Typography className={"text-13 text-black py-10 px-10"}>{description}</Typography>}
		>
			<Icon className={"text-20 min-w-28 " + (classes?.icon ?? "") + (enabled ? "text-primary" : "text-grey")}>
				{icon}
			</Icon>
		</Tooltip>
	);
};

const drivers = {
	urlGET: "api/profile/carriers/:carrierId/drivers?options_status=:status",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "CARRIER_DRIVERS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
			layout: [["email"], ["driver_type", "access"]],
		},
		table: {
			variant: "skinny",
			orderBy: ["name", "email", "driver_type"],
			layout: ["table_avatar", "name", "email", "driver_type", "access"],
		},
		isActive: (item) => !item.deleted,
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		defaults: {
			initial: {
				filters: {
					driver_active: "ACTIVE",
				},
			},
			items: [
				{
					label: "Active",
					filters: {
						driver_active: "ACTIVE",
					},
				},
				{
					label: "Archived",
					filters: {
						driver_active: "INACTIVE",
					},
				},
			],
		},
		filters: {
			search: true,
			countVisible: 0,
			items: [
				{
					key: "driver_active",
					type: "select",
					label: "Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							label: "Active",
							value: "ACTIVE",
						},
						{
							label: "Archived",
							value: "INACTIVE",
						},
					],
				},
			],
		},
		click: {
			grid: { variant: "menu" },
			table: { addToMenu: true },
			dialogBuilder: (item, dataIds) => ({
				viewId: "USER_DETAILS_VIEW",
				dataIds: {
					...dataIds,
					driverId: item._id,
				},
			}),
		},
		items: [
			{
				key: "table_avatar",
				type: "component",
				table: { width: "30px" },
				builder: (item) => {
					return (
						<Avatar src={null} alt={""} className={"bg-white border-1 border-grey-400"}>
							<Icon className="text-20 text-primary">airline_seat_recline_normal</Icon>
						</Avatar>
					);
				},
			},
			{ key: "name", type: "text", label: "Name", grid: { headerTitle: true } },
			{ key: "email", type: "text", label: "Email", grid: { longRow: true }, table: { width: "40%" } },
			{
				key: "driver_type",
				type: "text",
				label: "Type",
				table: { align: "right", width: "15%" },
				builder: (item) => {
					return item.driver_type === "COMPANY_DRIVER"
						? "Company Driver"
						: item.driver_type === "OWNER_OPERATOR"
						? "Owner Operator"
						: "No Type";
				},
			},
			{
				key: "access",
				type: "component",
				label: "Status",
				table: { width: "10%" },
				builder: (item) => {
					return (
						<div className={"flex flex-row"} onClick={(e) => e.stopPropagation()}>
							{buildTooltip(
								!item.deleted,
								!item.deleted ? "directions_bus" : "no_transfer",
								!item.deleted ? "This user has active driver profile" : " This user driver profile is not active",
								{ icon: " ml-2 " }
							)}
							{buildTooltip(
								!item.hasUserDeactivated,
								!item.hasUserDeactivated ? "phonelink" : "phonelink_off",
								!item.hasUserDeactivated
									? "This driver can access web portal"
									: "This driver can not access web portal",
								{ icon: " ml-4 " }
							)}
							{buildTooltip(
								!!item.phone?.length,
								!!item.phone?.length ? "phone_enabled" : "phone_disabled",
								!!item.phone?.length ? "Phone: " + item.phone : "This driver does not have phone number",
								{ icon: " ml-4 " }
							)}
						</div>
					);
				},
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						dialogBuilder: (item, dataIds) => ({
							viewId: "CARRIER_USER_EDIT_VIEW",
							mode: "EDIT",
							dataIds: { carrierId: dataIds.carrierId, driverId: item._id, origin: "DRIVER" },
						}),
					},
					{ variant: "divider" },
					{
						key: "activate_driver",
						label: "Activate",
						dialogBuilder: (item, dataIds) => ({
							viewId: "CARRIER_USER_EDIT_VIEW",
							mode: "EDIT",
							dataIds: { carrierId: dataIds.carrierId, driverId: item._id, origin: "DRIVER" },
						}),
						isVisible: (item) => item.deleted,
					},
					{
						key: "archive_driver",
						label: "Archive",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
						dialogBuilder: (item, dataIds) => ({
							viewId: "CARRIER_USER_EDIT_VIEW",
							mode: "DELETE",
							dataIds: { carrierId: dataIds.carrierId, driverId: item._id, origin: "DRIVER" },
						}),
						isVisible: (item) => !item.deleted,
					},
				],
			},
		],
	},
};

export default drivers;
