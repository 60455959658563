const formDefinition = ({ isViewMode, required = true, oauth = false, isEmpty = false } = {}) => {
	if (isEmpty) {
		return {
			items: [
				{
					key: "status",
					type: "text",
					label: "Status",
				},
			],
		};
	}
	return {
		items: [
			...(isViewMode
				? [
						{
							key: "information_message",
							type: "message",
							builder: () =>
								`This configuration overrides the default platform email used to send invoices and broker updates.`,
						},
						{
							key: "enabled",
							type: "text",
							label: "Status",
							builder: (item) => (item?.enabled ? "Enabled" : "Disabled"),
						},
				  ]
				: []),
			{
				type: "group",
				content: {
					items: [
						...(oauth
							? [
									{
										key: "type",
										type: "text",
										label: "Provider",
										builder: () => "GMAIL",
									},
									{
										key: "username",
										type: "text",
										label: "Connected Email",
									},
							  ]
							: [
									{
										key: "server_name",
										type: "text",
										label: "Host",
										required,
										description: "Your Email Server Name. Ex. smtp.outlook.com",
									},
									...(isViewMode
										? [
												{
													key: "username",
													type: "text",
													label: "Username",
													required,
													description: "The email address you want to send your emails from.",
												},
										  ]
										: [
												{
													key: "port",
													type: "text",
													label: "Port",
													required,
													description: "Typically 587",
												},
										  ]),
							  ]),
					],
				},
			},
			...(oauth
				? []
				: [
						{
							type: "group",
							content: {
								items: [
									...(!isViewMode
										? [
												{
													key: "username",
													type: "text",
													label: "Username",
													required,
													description: "The email address you want to send your emails from.",
												},
										  ]
										: []),
									...(isViewMode
										? []
										: [
												{
													key: "password",
													type: "text",
													label: "Password",
													required,
													builder: (item) => {
														if (isViewMode && item?.username) {
															return "********";
														}
														return item?.password;
													},
													description: "The email account password.",
												},
										  ]),
								],
							},
						},
				  ]),
			{ type: "action", action: "SUBMIT", label: "Save" },
		],
	};
};

export const brandingMailConfForm = {
	urlGET: "api/profile/carriers/:carrierId/branding-mail-conf",
	urlPOST: "api/profile/carriers/:carrierId/branding-mail-conf",
	urlPUT: "api/profile/carriers/:carrierId/branding-mail-conf",
	idKey: "_id",
	triggerEvent: ["profileRevision", "invoiceOnboardingRevision"],
	listenEvent: ["profileRevision", "invoiceOnboardingRevision"],
	header: {
		create: { label: "Add SMTP Mail Configuration" },
		edit: { label: "Edit SMTP Mail Configuration" },
	},
	messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
	content: {
		view: formDefinition({ isViewMode: true }),
		create: {
			sameAs: "edit",
		},
		edit: formDefinition({ required: true }),
	},
};

export const brandingMailForm = (oauth, isEmpty) => {
	return {
		urlGET: "api/profile/carriers/:carrierId/branding-mail-conf",
		urlPOST: "api/profile/carriers/:carrierId/branding-mail-conf",
		urlPUT: "api/profile/carriers/:carrierId/branding-mail-conf",
		idKey: "_id",
		triggerEvent: ["profileRevision", "invoiceOnboardingRevision"],
		listenEvent: ["profileRevision", "invoiceOnboardingRevision"],
		header: {
			create: { label: "Add SMTP Mail Configuration" },
			edit: { label: "Edit SMTP Mail Configuration" },
		},
		messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
		content: {
			view: formDefinition({ isViewMode: true, oauth: oauth, isEmpty }),
			create: {
				sameAs: "edit",
			},
			edit: formDefinition({ required: true }),
		},
	};
};
