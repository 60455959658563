import { centsToDollars } from "app/main/utils/financeUtils";

const factoringRequestDetail = {
	urlGET: "factoring/factoring-requests/:factoringRequestId",
	idKey: "_id",
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "_id",
								type: "text",
								label: "Id",
							},
							{
								key: "amount",
								type: "currency",
								label: "Factored Amount",
								builder: (item) => centsToDollars(item?.accounts_receivable?.amount ?? item?.amount),
							},
						],
					},
				},
			],
		},
	},
};

export default factoringRequestDetail;
