const carrierNotficationBrokerForm = {
	urlGET: "api/profile/carriers/:carrierId/details/notification-broker",
	urlPUT: "api/profile/carriers/:carrierId/details/notification-broker",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: {
		edit: { label: "Notification Configuration" },
	},
	content: {
		view: {
			sameAs: "edit",
		},
		create: {
			sameAs: "edit",
		},
		edit: {
			items: [
				{
					type: "message",
					label: "Allow to enable sending trips updates to the broker via email when",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "dispatchedNotificationBroker",
								type: "checkbox",
								label: "Dispatched",
							},
							{
								key: "atPickedUpNotificationBroker",
								type: "checkbox",
								label: "At Pickup",
							},
						],
					},
				},
				{
					key: "atDeliveryNotificationBroker",
					type: "checkbox",
					label: "At Delivery",
				},
				{ type: "action", action: "SUBMIT", classes: "w-1/2" },
			],
		},
	},
};

export default carrierNotficationBrokerForm;
