import _ from "@lodash";
import { getInvoiceForTrip } from "app/services/tripInvoicingServices";
const moment = require("moment");

const getHistoryId = async (dataIds, viewId) => {
	if (viewId === "TRIP_EDIT_VIEW" || viewId === "TRIP_VIEW") {
		return { id: dataIds?.tripId, type: "history" };
	}

	if (viewId === "TRUCK_VIEW") {
		if (dataIds?.type) {
			return {
				id: dataIds?.id,
				type: dataIds?.type,
			};
		}
		return {
			id: dataIds?.id,
			type: "history",
		};
	}
	if (viewId === "TRUCK_PLAN" || viewId === "TRUCK_PLAN_VIEW" || viewId === "TRUCK_PLAN_FORM") {
		if (dataIds?.planId) {
			return { id: dataIds?.planId, type: "history" };
		}
		return { id: dataIds?.truckId, type: "truck-plan-carrier" };
	}
	if (viewId === "PAYROLL_VIEW") {
		return { id: dataIds?.payrollId, type: "history" };
	}
	if (viewId === "DRIVER_PAYROLL_FORM") {
		return { id: dataIds?.id, type: "DRIVER_PAYROLL" };
	}
	if (viewId === "PAYROLL_RULES_VIEW") {
		return { id: dataIds?.recurrentPaymentId, type: "history" };
	}
	if (viewId === "TRIP_INVOICE_VIEW") {
		const invoice = await getInvoiceForTrip(dataIds.carrierId, dataIds.tripId);
		const msg = "No invoice history available";
		return { id: invoice._id, type: "history", ...(invoice ? {} : { msg }) };
	}
	if (viewId === "TRAILER_VIEW") {
		return { id: dataIds.id, type: "history" };
	}
	if (viewId === "CARRIER_USER_EDIT_VIEW") {
		return { id: dataIds.userId, type: "USER" };
	}
	if (viewId === "BROKER_EDIT_VIEW") {
		return { id: dataIds.brokerId, type: "history" };
	}
	if (viewId === "FUEL_TRANSACTION_VIEW") {
		return { id: dataIds?.transactionId, type: "history" };
	}
};

const compareHistory = (history1, history2) => {
	let keysHistory = Object.keys(history1);
	let objectDifferent = {};
	for (let key of keysHistory) {
		if (Array.isArray(history1?.[key])) {
			const array = cleanArray(history1?.[key]);
			const array2 = cleanArray(history2?.[key]);
			if (!_.isEqual(array, array2)) {
				objectDifferent[key] = true;
			}
		}
		if (typeof history1?.[key] === "object" && typeof history2?.[key] === "object") {
			if (typeof history1[key]?.value === "string" && typeof history2[key]?.value === "string") {
				if (history1?.[key]?.value?.trim()?.toUpperCase() !== history2?.[key]?.value?.trim()?.toUpperCase()) {
					objectDifferent[key] = true;
				}
			} else if (
				history1?.[key]?.value?.toString()?.toUpperCase() !== history2?.[key]?.value?.toString()?.toUpperCase()
			) {
				objectDifferent[key] = true;
			}
		} else if (history1?.[key]?.toString()?.toUpperCase() !== history2[key]?.toString()?.toUpperCase()) {
			if (history1[key] === "" && history2[key] === undefined) {
				objectDifferent[key] = undefined;
			} else if (typeof history1[key] === "string" && moment(history1[key]).format("LL") !== "Invalid date") {
				if (moment(history1[key]).format("LLL") !== moment(history2[key]).format("LLL")) {
					objectDifferent[key] = true;
				}
			} else if (typeof history1[key] === "string" && typeof history2[key] === "string") {
				if (history1?.[key]?.trim()?.toUpperCase() !== history2?.[key]?.trim()?.toUpperCase()) {
					objectDifferent[key] = true;
				}
			} else {
				objectDifferent[key] = true;
			}
		}
	}
	return objectDifferent;
};

const getCorrectView = (viewId) => {
	if (viewId === "TRUCK_PLAN_VIEW" || viewId === "TRUCK_PLAN_FORM") {
		return "TRUCK_PLAN";
	} else if (viewId === "TRIP_VIEW") {
		return "TRIP_EDIT_VIEW";
	} else if (viewId === "TRIP_INVOICE_VIEW") {
		return "TRIP_INVOICE_HISTORY_VIEW";
	} else if (viewId === "FUEL_TRANSACTION_VIEW") {
		return "FUEL_TRANSACTION_HISTORY_VIEW";
	}
	return viewId;
};

const getView = (type, metadata) => {
	if (type === "TRIP") {
		return { view: "TRIP_EDIT_VIEW", isViewCustom: true };
	} else if (type === "PLAN") {
		return { view: "TRUCK_PLAN", isViewCustom: true };
	} else if (type === "TRUCK") {
		return { view: "TRUCK_FORM", isViewCustom: false };
	} else if (type === "PAYROLL") {
		return { view: "PAYROLL_VIEW", isViewCustom: true };
	} else if (type === "PAYROLL_RECURRENT_PAYMENT") {
		return { view: "PAYROLL_RULES_VIEW", isViewCustom: true };
	} else if (type === "TRIP_INVOICE_VIEW") {
		return { view: "TRIP_INVOICE_HISTORY_VIEW", isViewCustom: true };
	} else if (type === "TRAILER") {
		return { view: "TRAILER_VIEW", isViewCustom: true };
	} else if (type === "USER_CARRIER") {
		return { view: "CARRIER_USER_EDIT_VIEW", isViewCustom: true };
	} else if (type === "FUEL_TRANSACTION_VIEW") {
		return { view: "FUEL_TRANSACTION_HISTORY_VIEW", isViewCustom: false };
	}
};

const formatText = (word) => {
	return word?.charAt(0) + word?.slice(1)?.toLowerCase();
};

const cleanArray = (array) => {
	return array?.map((item) => {
		const keys = Object.keys(item);
		const newItem = {
			...item,
		};
		for (let key of keys) {
			if (
				key.includes("__view") ||
				key === "_id" ||
				key.includes("__flag") ||
				key.includes("_date__tz") ||
				key.includes("_date__tz_offset") ||
				key.includes("coordinates")
			) {
				delete newItem[key];
			}
		}
		return newItem;
	});
};

export { getHistoryId, compareHistory, getCorrectView, getView, formatText };
