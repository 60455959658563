import { useState } from "react";

import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

function CellContainerRow(props) {
	const [moreMenuEl, setMoreMenuEl] = useState(null);

	const variant = props.variant;
	const more = props.more;

	const mg =
		variant === "widget-calendar"
			? " -mt-20 pb-20 "
			: variant === "large" || variant === "spacious"
			? " mt-2 "
			: variant === "skinny" || variant === "extra-skinny"
			? " -mt-1 ml:-mt-2 "
			: variant === "extra-large"
			? "-mt-20"
			: "";
	return (
		<div className={"flex flex-row items-center " + (variant === "widget-calendar" ? " -mt-2 " : "")}>
			{props.left}
			<div className={"flex flex-col"}>
				<div className={"flex flex-row items-center "}>{props.first}</div>
				{props.second && <div className={"flex flex-row items-center " + mg}>{props.second}</div>}
				{props.third && <div className={"flex flex-row items-center " + mg}>{props.third}</div>}
			</div>
			{more?.length > 0 && (
				<>
					<Button
						variant="outlined"
						onClick={(event) => {
							event.stopPropagation();
							setMoreMenuEl(event.target);
						}}
						aria-owns={!!moreMenuEl ? "more-menu" : null}
						role="button"
						className={
							"overflow-visible rounded-6 bg-white w-40 h-32 ml-4 min-w-40 border-grey-300 " +
							(!!moreMenuEl ? " opacity-0 " : "") +
							(more?.length > 9 ? "  " : "  ")
						}
					>
						<Typography className={"whitespace-nowrap font-medium tracking-wide text-secondary text-11"}>
							+{more?.length}
						</Typography>
					</Button>
					<Menu
						id="more-menu"
						anchorEl={moreMenuEl}
						open={!!moreMenuEl}
						onClose={(event) => {
							event.stopPropagation();
							setMoreMenuEl(null);
						}}
					>
						<div
							className={"flex flex-col px-20 py-10 space-y-10"}
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							{more}
						</div>
					</Menu>
				</>
			)}
		</div>
	);
}

export default CellContainerRow;
