import { Typography, Icon } from "@material-ui/core";

// Service
import { getAccountId } from "app/services/LoginService";

// Tools
import { fetchSubAccount } from "app/store/auth/accountSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const carrierDispatchingConnectionCodeForm = {
	urlPOST: "api/profile/carriers/:carrierId/dispatching/connect",
	idKey: "_id",
	header: {
		create: { label: "Connect New Carrier" },
	},
	size: "max-w-s",
	callbacks: {
		onCreate: (data, dispatch) => {
			dispatch(fetchSubAccount({ accountId: getAccountId() }));
			dispatch(incrementDataRevision({ event: "accountRevision" }));
		},
	},
	content: {
		create: {
			items: [
				{
					type: "message",
					builder: (item) => {
						return (
							<div className="flex flex-row w-full items-center justify-center">
								<Icon className="text-32 text-blue mx-16">settings_ethernet</Icon>
								<Typography color="textPrimary" className="flex-1 text-14 my-4 font-normal">
									Enter provided 'Access Code' to be able to connect another carrier.
								</Typography>
							</div>
						);
					},
				},
				{
					key: "code",
					label: "Access Code",
					type: "text",
					required: true,
				},
				{ type: "action", action: "SUBMIT", label: "Connect" },
			],
		},
	},
};

export default carrierDispatchingConnectionCodeForm;
