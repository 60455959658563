import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Button } from "@material-ui/core";
import WidgetsPage from "./WidgetsPage";
import WidgetPageWindow from "./WidgetPageWindow";
import PageTabWrapper from "../common/PageTabWrapper";
import FuseUtils from "@fuse/utils";

import { saveBoardParams, saveBuilderParams } from "app/store/tools/widgetsSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

import {
	isRoleHasFullCarrierAccess,
	isRoleExternal,
	getUserId,
	getCarrierId,
	getRoleLabel,
} from "app/services/LoginService";
import { getDashboard } from "app/services/analyticsServices";

import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { formBoard, formTab } from "./configs/boardForm";
import { WINDOW_MAPPINGS } from "./configs/windwoOptions";
import { formatTimeAgo } from "app/main/utils/dateUtils";
import { isEnabled } from "app/services/featureStorageService";

function WidgetsBoard(props) {
	const dispatch = useDispatch();
	const mutable = !props.boardKey;

	const userId = useMemo(
		// Access to analytics is limited to specific roles (user such as driver, dispatcher or investor
		// would see only subset of analitics linked to only their trucks / driver / etc.)
		() => (mutable ? null : props.userId ?? (isRoleExternal() && !isRoleHasFullCarrierAccess() ? getUserId() : null)),
		[mutable, props.userId]
	);
	const carrierId = useMemo(
		() =>
			mutable
				? null
				: props.carrierId && props.carrierId !== "__ALL__"
				? props.carrierId
				: props.carrierId !== "__ALL__"
				? getCarrierId()
				: null,
		[mutable, props.carrierId]
	);
	const truckId = useMemo(() => (mutable ? null : props.truckId), [mutable, props.truckId]);
	const builderParams = useSelector(({ tools }) => tools.widgets.builderParams);

	const profileId = mutable ? null : props.profileId;
	const accountId = mutable ? null : props.accountId;
	const boardKey = props.boardKey ?? builderParams?.board__view?.metadata?.key ?? "__NEW_BOARD__";

	const boardRevision = useSelector(({ tools }) => tools.revision["analyticsJobsRevision"]);
	const boardsParam = useSelector(({ tools }) => (boardKey ? tools.widgets.boardsParams[boardKey] : null));
	const boardConfig = boardsParam?.config;
	const boardEdited = boardsParam?.edited;
	const boardAccessType = boardsParam?.accessType;
	const boardStatus = boardsParam?.status;
	const tabItems = boardConfig?.tabItems ?? [];
	const tabWidgets = boardConfig?.tabWidgets ?? {};

	const overrideAccountId = boardAccessType === "ACCOUNT" ? builderParams.account : null;
	const overrideProfileId =
		boardAccessType === "ACCOUNT" || boardAccessType === "PROFILE" ? builderParams.profile : null;
	const overrideCarrierId = boardAccessType === "CARRIER" || boardAccessType === "TRUCK" ? builderParams.carrier : null;
	const overrideUserId =
		boardAccessType === "CARRIER" || boardAccessType === "USER" || boardAccessType === "USER_CREATOR"
			? builderParams.user
			: null;
	const overrideTruckId = boardAccessType === "TRUCK" ? builderParams.truck : null;

	const [tabState, setTabState] = useState({ selectedWindow: {} });
	const [editTabConfig, setEditTabConfig] = useState(null);
	const [modalRevision, setModalRevision] = useState(0);
	const [localRevision, setLocalRevision] = useState(0);
	const [error, setError] = useState(null);
	const [mobile, setMobile] = useState(window.innerWidth < 960);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		if (!boardKey || boardKey === "__NEW_BOARD__") {
			return;
		}

		console.log(`[WidgetsBoard] loading board ${boardKey}`);
		let stopped = false;
		(async () => {
			try {
				const data = await getDashboard(boardKey);
				if (stopped) return;
				dispatch(
					saveBoardParams({
						edited: false,
						key: data.key,
						config: data.config,
						accessType: data.accessType,
						status: data.status,
					})
				);
			} catch (e) {
				console.error("[WidgetContent] Failed to load data", e);
				setError(e.message ?? e.errors?.[0]?.message ?? "Opps... Something went wrong...");
			}
		})();

		return () => {
			stopped = true;
		};
		// eslint-disable-next-line
	}, [boardKey, boardRevision, localRevision]);

	const addTab = ({ tabKey, tabLabel }) => {
		if (!tabKey) {
			console.error("[WidgetsBoard] can not add widget, invalid params");
			return;
		}

		const newBoardConfig = !boardConfig ? { tabItems: [], tabWidgets: {} } : JSON.parse(JSON.stringify(boardConfig));
		newBoardConfig.tabItems = [...(newBoardConfig.tabItems ?? []), { key: tabKey, label: tabLabel }];
		dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
	};

	const editTab = ({
		tabKey,
		tabLabel,
		tabWindowType,
		horizontalCellsCount,
		verticalCellsHight,
		hideOnMobile,
		showUpdateTime,
		gatekeeper,
		roles,
	}) => {
		if (!tabKey) {
			console.error("[WidgetsBoard] can not edit widget, invalid params");
			return;
		}

		const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
		newBoardConfig.tabItems = JSON.parse(JSON.stringify(newBoardConfig.tabItems));
		const editedTab = newBoardConfig.tabItems.find((item) => item.key === tabKey);
		editedTab.label = tabLabel?.length > 0 ? tabLabel : tabKey;
		editedTab.windowType = tabWindowType;
		editedTab.horizontalCellsCount = horizontalCellsCount;
		editedTab.verticalCellsHight = verticalCellsHight;
		editedTab.hideOnMobile = hideOnMobile;
		editedTab.showUpdateTime = showUpdateTime;
		editedTab.gatekeeper = gatekeeper;
		editedTab.roles = roles;
		dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
	};

	const moveTab = ({ from, to }) => {
		if (
			to >= 0 &&
			to < boardConfig.tabItems?.length &&
			from >= 0 &&
			from < boardConfig.tabItems?.length &&
			to !== from
		) {
			const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
			newBoardConfig.tabItems = JSON.parse(JSON.stringify(newBoardConfig.tabItems));
			const temp = newBoardConfig.tabItems[to];
			newBoardConfig.tabItems[to] = newBoardConfig.tabItems[from];
			newBoardConfig.tabItems[from] = temp;
			dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
		} else {
			console.error(`[WidgetsBoard] can not move widget, invalid indexed, from ${from}, to ${to}`);
		}
	};

	const closeTab = ({ tabKey }) => {
		if (!tabKey || !boardConfig.tabItems) {
			console.error("[WidgetsBoard] can not add widget, invalid params or board state");
			return;
		}

		const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
		newBoardConfig.tabWidgets = { ...newBoardConfig.tabWidgets };
		delete newBoardConfig.tabWidgets[tabKey];
		newBoardConfig.tabItems = newBoardConfig.tabItems?.filter((item) => item.key !== tabKey) ?? [];
		dispatch(
			saveBoardParams({
				edited: newBoardConfig.tabItems.length > 0,
				key: boardKey,
				config: newBoardConfig,
				accessType: boardAccessType,
			})
		);
	};

	const addWidget = ({ tabKey, widgetKey, size, config }) => {
		if (!tabKey || !widgetKey || !size) {
			console.error("[WidgetsBoard] can not add widget, invalid params");
			return;
		}

		const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
		const newWidgetConfig = JSON.parse(JSON.stringify(config));
		if (!newBoardConfig.tabWidgets) newBoardConfig.tabWidgets = {};
		if (!newBoardConfig.tabWidgets[tabKey]) newBoardConfig.tabWidgets[tabKey] = {};

		const tabWidgetsConfig = newBoardConfig.tabWidgets[tabKey];
		if (!tabWidgetsConfig.layouts) tabWidgetsConfig.layouts = {};
		if (!tabWidgetsConfig.layouts.lg) tabWidgetsConfig.layouts.lg = [];
		if (!tabWidgetsConfig.layouts.md) tabWidgetsConfig.layouts.md = [];
		if (!tabWidgetsConfig.layouts.sm) tabWidgetsConfig.layouts.sm = [];
		if (!tabWidgetsConfig.keys) tabWidgetsConfig.keys = [];
		if (!tabWidgetsConfig.configs) tabWidgetsConfig.configs = {};

		let maxYlg = 0;
		tabWidgetsConfig.layouts.lg.forEach((item) => {
			if (item.y + item.h > maxYlg) maxYlg = item.y + item.h;
		});

		let maxYmd = 0;
		tabWidgetsConfig.layouts.lg.forEach((item) => {
			if (item.y + item.h > maxYmd) maxYmd = item.y + item.h;
		});

		let maxYsm = 0;
		tabWidgetsConfig.layouts.lg.forEach((item) => {
			if (item.y + item.h > maxYsm) maxYsm = item.y + item.h;
		});

		const item = { w: size.w ?? 2, h: size.h ?? 2, x: 0, y: 0, i: widgetKey };
		tabWidgetsConfig.layouts.lg = [...tabWidgetsConfig.layouts.lg, { ...item, y: maxYlg }];
		tabWidgetsConfig.layouts.md = [...tabWidgetsConfig.layouts.md, { ...item, y: maxYmd }];
		tabWidgetsConfig.layouts.sm = [...tabWidgetsConfig.layouts.sm, { ...item, y: maxYsm }];
		tabWidgetsConfig.keys = [widgetKey, ...tabWidgetsConfig.keys];
		tabWidgetsConfig.configs[widgetKey] = newWidgetConfig;
		dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
	};

	const updateWidgetLayout = ({ tabKey, layouts }) => {
		if (
			!layouts ||
			!boardConfig?.tabWidgets?.[tabKey] ||
			JSON.stringify(layouts) === JSON.stringify(boardConfig.tabWidgets[tabKey].layouts)
		) {
			console.error("[WidgetsBoard] can not update layout, invalid params or board state");
			return;
		}

		const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
		newBoardConfig.tabWidgets[tabKey].layouts = layouts;
		dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
	};

	const updateWidgetContentConfig = ({ tabKey, widgetKey, config }) => {
		if (!config || !boardConfig?.tabWidgets?.[tabKey]?.configs?.[widgetKey]?.container) {
			console.error("[WidgetsBoard] can not update widget config, invalid params or board state");
			return;
		}

		const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
		newBoardConfig.tabWidgets[tabKey].configs[widgetKey].container.config = config;
		dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
	};

	const closeWidget = ({ tabKey, widgetKey }) => {
		if (!tabKey || !widgetKey || !boardConfig.tabWidgets[tabKey]) {
			console.error("[WidgetsBoard] can not close widget, invalid params or board state");
			return;
		}

		const updated = { ...boardConfig.tabWidgets[tabKey] };
		updated.configs = { ...updated.configs };
		updated.layouts = { ...updated.layouts };
		updated.layouts.lg = [...updated.layouts.lg];
		updated.layouts.md = [...updated.layouts.md];
		updated.layouts.sm = [...updated.layouts.sm];
		updated.keys = [...updated.keys];

		updated.keys = updated.keys?.filter((item) => item !== widgetKey);
		updated.layouts.lg = updated.layouts.lg?.filter((item) => item.i !== widgetKey);
		updated.layouts.md = updated.layouts.md?.filter((item) => item.i !== widgetKey);
		updated.layouts.sm = updated.layouts.sm?.filter((item) => item.i !== widgetKey);
		delete updated.configs[widgetKey];

		const newBoardConfig = JSON.parse(JSON.stringify(boardConfig));
		newBoardConfig.tabWidgets[tabKey] = updated;
		dispatch(saveBoardParams({ edited: true, key: boardKey, config: newBoardConfig, accessType: boardAccessType }));
	};

	const createSaveDashboardButton = () => ({
		action: "edit",
		label: !boardEdited && builderParams?.board ? "Edit Board" : "Save Board",
		classes: { button: "min-w-120" },
		disabled: !builderParams?.board && !boardEdited,
		onClick: () => {
			dispatch(
				openLoadedFormDialog(
					builderParams?.board
						? {
								formId: "ADMIN_ANALYTCIS_DASHBOARD_FORM",
								mode: "EDIT",
								dataIds: { id: builderParams?.board },
								dataOverrides: boardEdited ? { config: JSON.stringify(boardConfig, 0, 4) } : {},
						  }
						: {
								formId: "ADMIN_ANALYTCIS_DASHBOARD_FORM",
								mode: "CREATE",
								dataIds: {},
								dataOverrides: { config: JSON.stringify(boardConfig, 0, 4) },
						  }
				)
			);
		},
	});

	const resetChanchesDashboardButton = () => ({
		action: "edit",
		label: "Reset Board",
		disabled: !boardEdited,
		onClick: () => {
			setLocalRevision(localRevision + 1);
		},
	});

	const createAddTabButton = () => {
		const id = "TAB_" + FuseUtils.generateGUID();
		return {
			action: "edit",
			label: "Add Tab",
			onClick: () => {
				addTab({ tabKey: id, tabLabel: id });
			},
		};
	};

	const createEditTabButton = (tabKey) => ({
		action: "edit",
		label: "Edit Tab",
		onClick: () => {
			const tab = tabItems?.find((item) => item?.key === tabKey);
			setModalRevision(modalRevision + 1);
			setEditTabConfig({
				key: tabKey,
				label: tab?.label,
				windowType: tab?.windowType,
				horizontalCellsCount: tab?.horizontalCellsCount,
				verticalCellsHight: tab?.verticalCellsHight,
				hideOnMobile: tab?.hideOnMobile,
				showUpdateTime: tab?.showUpdateTime,
				gatekeeper: tab?.gatekeeper,
				roles: tab?.roles,
				roles__view: tab?.roles?.map((r) => ({ value: r, label: r })),
			});
		},
	});

	const createMoveTabButton = (from, to, direction) => ({
		action: "edit",
		label: direction === "LEFT" ? "<" : ">",
		onClick: () => {
			moveTab({ from, to });
		},
	});

	const createCloseTabButton = (tabKey) => ({
		action: "delete",
		label: "Close Tab",
		onClick: () => {
			closeTab({ tabKey });
		},
	});

	const createAddWidgetButton = (tabKey) => {
		const widgetKey = "WIDGET_" + FuseUtils.generateGUID();
		const config = { container: { config: { type: "__DYNAMIC__", label: widgetKey } } };
		return {
			action: "edit",
			label: "Add Widget",
			onClick: () => addWidget({ tabKey, widgetKey, size: { w: 2, h: 2 }, config }),
		};
	};

	const renderWidgetPage = (
		tabKey,
		widgetsConfig,
		horizontalCellsCount,
		verticalCellsHight,
		windowType,
		showUpdateTime,
		gatekeeper,
		roles
	) => {
		if (boardAccessType === "ACCOUNT" && !overrideAccountId && !accountId) {
			return (
				<div className="flex flex-1 flex-col min-h-450 items-center justify-center">
					<Typography className="w-full text-center text-grey-600">Please select an Account</Typography>
				</div>
			);
		} else if (boardAccessType === "CARRIER" && !overrideCarrierId && !carrierId) {
			return (
				<div className="flex flex-1 flex-col min-h-450 items-center justify-center">
					<Typography className="w-full text-center text-grey-600">Please select a Carrier</Typography>
				</div>
			);
		} else if ((boardAccessType === "USER" || boardAccessType === "USER_CREATOR") && !overrideUserId && !userId) {
			return (
				<div className="flex flex-1 flex-col min-h-450 items-center justify-center">
					<Typography className="w-full text-center text-grey-600">Please select a User</Typography>
				</div>
			);
		} else if (boardAccessType === "TRUCK" && !overrideTruckId && !truckId) {
			return (
				<div className="flex flex-1 flex-col min-h-450 items-center justify-center">
					<Typography className="w-full text-center text-grey-600">Please select a Truck</Typography>
				</div>
			);
		}

		return (
			<div className="flex flex-col w-full p-0 m-0">
				{showUpdateTime && boardStatus?.endedUpdateAt && (
					<div className="flex flex-row w-full justify-end pr-4">
						<Typography
							className={
								"flex text-11 text-grey mr-10 text-end -mt-2 h-1 " +
								(boardStatus?.updateInProgress ? " animate-pulse text-green-400  " : " text-grey ")
							}
						>
							{boardStatus?.updateInProgress
								? "Updating..."
								: `Updated ${formatTimeAgo(boardStatus?.endedUpdateAt)} ago`}
						</Typography>
					</div>
				)}
				<WidgetsPage
					boardAccessType={boardAccessType}
					mutable={mutable}
					mobile={mobile}
					key={tabKey}
					dataIds={
						boardAccessType === "ACCOUNT" || !!overrideAccountId
							? {
									boardAccessType,
									accountId: overrideAccountId ?? accountId,
									profileId: overrideProfileId ?? profileId,
									userId: overrideUserId ?? userId,
							  }
							: (boardAccessType === "CARRIER" || !!overrideCarrierId) && !overrideTruckId
							? { boardAccessType, carrierId: overrideCarrierId ?? carrierId, userId: overrideUserId ?? userId }
							: boardAccessType === "USER" || boardAccessType === "USER_CREATOR"
							? {
									boardAccessType,
									userId: overrideUserId ?? userId,
									carrierId: overrideCarrierId ?? carrierId,
									truckId: overrideTruckId ?? truckId,
							  }
							: boardAccessType === "TRUCK" || !!overrideTruckId
							? { boardAccessType, truckId: overrideTruckId ?? truckId, carrierId: overrideCarrierId ?? carrierId }
							: {}
					}
					widgets={widgetsConfig}
					overrides={{
						selectedWindow: windowType
							? tabState.selectedWindow[tabKey] ?? WINDOW_MAPPINGS()[windowType]?.default
							: null,
						horizontalCellsCount,
						verticalCellsHight,
						gatekeeper,
						roles,
					}}
					status={boardStatus}
					onLayoutChange={(all, layouts) => {
						if (JSON.stringify(widgetsConfig?.layouts) !== JSON.stringify(layouts)) {
							updateWidgetLayout({ tabKey, layouts });
						}
					}}
					onCloseWidget={(widgetKey) => {
						closeWidget({ tabKey, widgetKey });
					}}
					onConfigChanged={(widgetKey, config) => {
						updateWidgetContentConfig({ tabKey, widgetKey, config });
					}}
					onCopyWidget={(widgetKey) => {
						const newConfig = JSON.parse(JSON.stringify(widgetsConfig.configs[widgetKey]));
						const newWidgetKey = "WIDGET_" + FuseUtils.generateGUID();
						addWidget({ tabKey, widgetKey: newWidgetKey, size: { w: 2, h: 2 }, config: newConfig });
					}}
					height={props?.height}
					margin={props?.margin}
				/>
			</div>
		);
	};

	const renderWindow = (tabKey, windowType) => {
		return (
			<WidgetPageWindow
				key={tabKey}
				selected={tabState.selectedWindow[tabKey]}
				type={windowType}
				onWindowChange={(window) => {
					setTabState({
						...tabState,
						selectedWindow: { ...tabState.selectedWindow, [tabKey]: window },
					});
				}}
			/>
		);
	};

	if (error) {
		return (
			<div className="flex flex-1 flex-col h-full items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
				<Button
					className="flex py-4 bg-red text-white mt-3"
					variant="contained"
					onClick={() => {
						setError(null);
						setLocalRevision(localRevision + 1);
					}}
				>
					Retry
				</Button>
			</div>
		);
	}

	const isAdmin = getRoleLabel() === "administrator";
	const visibleTabs = tabItems.filter(
		(item) =>
			(!mobile || !item.hideOnMobile) &&
			(isAdmin || !item.gatekeeper || isEnabled(item.gatekeeper)) &&
			(!item.roles?.length || isAdmin || item.roles.includes(getRoleLabel()))
	);

	if (!mutable && visibleTabs.length === 1) {
		const tab = visibleTabs[0];
		return (
			<div className="flex flex-col w-full">
				{!!tab.windowType && (
					<div className="flex flex-row w-full items-start justify-start md:items-end md:justify-end pb-2 md:pb-6">
						{renderWindow(tab.key, tab.windowType)}
					</div>
				)}
				{renderWidgetPage(
					tab.key,
					tabWidgets[tab.key],
					tab.horizontalCellsCount,
					tab.verticalCellsHight,
					tab.windowType,
					tab.showUpdateTime,
					tab.gatekeeper,
					tab.roles
				)}
			</div>
		);
	}

	const analyticsBoard = (
		<PageTabWrapper
			isView
			noPadding
			tabs={visibleTabs?.map((item, i) => ({
				label: item.label,
				actions: [
					...(!!item.windowType ? [{ component: renderWindow(item.key, item.windowType) }] : []),
					...(mutable && !mobile && !tabWidgets[item.key]?.keys?.length ? [createCloseTabButton(item.key)] : []),
					...(mutable && !mobile && i > 0 ? [createMoveTabButton(i, i - 1, "LEFT")] : []),
					...(mutable && !mobile && i < visibleTabs.length - 1 ? [createMoveTabButton(i, i + 1, "RIGHT")] : []),
					...(mutable && !mobile ? [createEditTabButton(item.key), createAddWidgetButton(item.key)] : []),
				],
				component: renderWidgetPage(
					item.key,
					tabWidgets[item.key],
					item.horizontalCellsCount,
					item.verticalCellsHight,
					item.windowType,
					item.showUpdateTime,
					item.gatekeeper,
					item.roles
				),
			}))}
		/>
	);

	if (!mutable || mobile) {
		return analyticsBoard;
	} else {
		return (
			<div className="flex flex-col w-full">
				<PageTabWrapper
					isView
					noPadding
					titleView={
						<div className="flex flex-row w-full md:w-7/12 -mb-8">
							<SmarthopFormView
								content={formBoard(boardEdited, boardsParam?.accessType)}
								mode={"EDIT"}
								data={builderParams}
								noInitValidation={true}
								trackChangedFields={["ALL"]}
								onChangeCommitted={(model, key) => {
									dispatch(saveBuilderParams({ params: model }));
								}}
							/>
						</div>
					}
					titleActions={[resetChanchesDashboardButton(), createSaveDashboardButton(), createAddTabButton()]}
					tabs={[{ component: analyticsBoard }]}
				/>
				<SmarthopConfirmDialog
					open={!!editTabConfig}
					title="Tab Config"
					handleClose={() => {
						setEditTabConfig(null);
					}}
				>
					<div className="flex min-w-420 pt-20 pb-10">
						<SmarthopFormView
							key={"modal_" + modalRevision}
							content={formTab()}
							mode={"EDIT"}
							data={editTabConfig}
							noInitValidation={true}
							trackChangedFields={["ALL"]}
							onSubmit={(model) => {
								if (model.windowType === "__NOT_SELECTED__") {
									delete model.windowType;
								}
								editTab({
									tabKey: editTabConfig.key,
									tabLabel: model.label,
									tabWindowType: model.windowType,
									horizontalCellsCount: model.horizontalCellsCount,
									verticalCellsHight: model.verticalCellsHight,
									hideOnMobile: model.hideOnMobile,
									showUpdateTime: model.showUpdateTime,
									gatekeeper: model.gatekeeper,
									roles: model.roles,
								});
								setEditTabConfig(null);
							}}
						/>
					</div>
				</SmarthopConfirmDialog>
			</div>
		);
	}
}

export default WidgetsBoard;
