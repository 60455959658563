import { useMemo } from "react";
import { useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { formatFields } from "@smarthop/utils/fieldsHelpers";
import ResourceView from "app/main/tools/ResourceView";

import { NARROW_FIELDS } from "@fuse/default-settings";

function SmarthopPreviewField(props) {
	let loading = props.loading;
	let type = props.type;
	let value = props.value;
	let component = props.component;
	let preview = component?.preview;
	let content = props.content;
	let options = props.options;
	let dataIds = props.dataIds;
	let nativeMobile = props.nativeMobile;
	let overrides = props.styleOverrides;
	let name = props?.name;
	let description = props?.description;
	let descriptionShowPreview = props?.descriptionShowPreview;
	let highLightColor;

	if (
		props?.differentObjectHistory &&
		((type === "autocomplete" && props?.differentObjectHistory?.[name + "__view"]) ||
			props?.differentObjectHistory?.[name] !== undefined)
	) {
		highLightColor = props?.differentObjectType === "AFTER" ? "text-blue" : "text-red";
	}

	const dispatch = useDispatch();

	if (type === "pattern" && !value) {
		value = [];
	} else if (type === "object" && !value) {
		value = {};
	}

	let text = useMemo(() => {
		if (type === "action") {
			return null;
		}

		let text = formatFields(
			value,
			type,
			component,
			overrides,
			nativeMobile,
			dataIds,
			dispatch,
			content,
			options,
			props?.differentObjectHistory
		);

		return text;
		// eslint-disable-next-line
	}, [component?.multiple, dataIds, type, value, options, content, nativeMobile]);

	if (
		type === "pattern" ||
		type === "object" ||
		(type === "autocomplete" && component?.multiple && preview?.format === "LIST")
	) {
		return (
			<div key={props.name} className={"w-full ml-10 " + (component?.classes?.root ?? "")}>
				{!!highLightColor || !!props.label ? (
					<Typography
						color={"primary"}
						className={
							"font-semibold break-all ml:break-words tracking-wide text-14 " +
							(preview?.classes?.label ?? "") +
							(!!highLightColor && type === "pattern" ? highLightColor : "")
						}
						paragraph={true}
					>
						{(props.label ?? "") + (!!highLightColor && type === "pattern" ? " (One or more items changed)" : "")}
					</Typography>
				) : type !== "object" && !type === "autocomplete" ? (
					<div key={props.name} className={"flex h-14 w-full"} />
				) : null}

				{preview?.labelDivider && <Divider className={"flex w-full -mt-6 mb-6 " + (preview?.classes?.divider ?? "")} />}
				{text}
			</div>
		);
	}

	const labelView = (
		<Typography
			color={"primary"}
			className={
				preview?.variant === "row"
					? "flex w-full text-black text-12 ml:text-13 pt-2"
					: "font-semibold break-words tracking-wide pb-14 " +
					  (preview?.classes?.label ?? "") +
					  (!!highLightColor ? highLightColor : "")
			}
		>
			{props.label}
		</Typography>
	);

	text = text ? text : loading ? "Loading..." : "No Information";
	description =
		description && descriptionShowPreview ? (
			<ResourceView
				type={"icon"}
				description={description}
				classes={{ icon: " ml-5 text-blue-700 " }}
				variant="hover"
			/>
		) : null;

	const textView = !preview?.paragraph ? (
		<Typography
			className={
				preview?.variant === "row"
					? "flex w-full font-semibold text-black text-12 ml:text-13 pt-2 " + (preview?.classes?.text ?? "")
					: "break-all ml:break-words text-grey-700 " +
					  (preview?.classes?.text ?? "") +
					  (NARROW_FIELDS ? " -mt-12 pb-2 " : " -mt-14 pb-4 ") +
					  (!!highLightColor ? highLightColor : "") +
					  (description ? "flex flex-row" : "")
			}
		>
			{text}
			{description}
		</Typography>
	) : (
		// WORKAROUND: manually configuring style for multiline text,
		// tailwinds is not working for this specific components,
		// need more investigation why
		<pre
			style={{
				fontSize: "1.3rem",
				fontFamily: 'Poppins,Roboto,"Helvetica",Arial,sans-serif',
				fontWeight: "400",
				lineHeight: "1.5",
				color: "rgb(97, 97, 97)",
				whiteSpace: "pre-wrap",
				wordWrap: "break-word",
				marginTop: "-12px",
				marginBottom: "-2px",
				display: description ? "flex" : "block",
			}}
		>
			{text}
			{description}
		</pre>
	);

	if (preview?.variant === "row") {
		return (
			<div className={"flex w-full flex-row justify-between border-b-1 border-grey-300 mt-10"}>
				<div className="flex">{labelView}</div>
				<div className="flex ml-8 ">
					{textView}
					{props.children}
				</div>
			</div>
		);
	} else {
		return (
			<div
				key={props.name}
				className={
					"w-full pl-6 pr-6 " + (preview?.classes?.root ?? "") + (NARROW_FIELDS ? " pt-8 pb-8 " : " pt-4 pb-14 ")
				}
			>
				<div className="flex">{labelView}</div>
				<div className="flex flex-row">
					{textView}
					{props.children}
				</div>
			</div>
		);
	}
}

export default SmarthopPreviewField;
