import { useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

import { readURLParameters } from "app/main/utils/urlUtils";
import { isStepCompleted, isComplete, getFirstIncompleteStep } from "./onboardUtil";

import OnboardSidebar from "./OnboardSidebar";
import OnboardContent from "./OnboardContent";
import OnboardFooter from "./OnboardFooter";
import { showWarning, typeWarning } from "app/main/utils/warningUtils";
import { useSelector } from "react-redux";
import { getUserTier } from "app/services/LoginService";

const _MAX_VISIBLE_STEP_KEY = "onboardingMaxVisibleStep";

function OnboardSteps({
	config,
	data,
	onCompleteSteps,
	onSwitchSteps,
	loadingLastStep,
	props,
	restrictions,
	loading,
	savedStep = null,
}) {
	const history = useHistory();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
	const [stepOverride, setStepOverride] = useState(props?.dataIds?.step ?? -1);
	const isDesktop = !isMobile && !props?.nativeMobile;
	const isDesktopDialog = isDesktop && props?.isCreateSubAccount;

	const user = useSelector(({ auth }) => auth.user);
	const warning = typeWarning(restrictions, user);

	const planChangeRevision = useSelector(({ tools }) => tools.revision["planChangeRevision"]);
	const planChangeRevisionSoft = useSelector(({ tools }) => tools.revision["planChangeRevisionSoft"]);
	const [userTier, setUserTier] = useState(getUserTier());

	const { resource } = props;

	useEffect(() => {
		if (userTier !== getUserTier()) {
			window.location.reload();
			setUserTier(getUserTier());
		}
		// eslint-disable-next-line
	}, [planChangeRevision]);

	useEffect(() => {
		if (userTier !== getUserTier()) {
			// Do not reload page in case of soft reload
			setUserTier(getUserTier());
		}
		// eslint-disable-next-line
	}, [planChangeRevisionSoft]);

	let maxVisibleStepIndex = parseInt(localStorage.getItem(_MAX_VISIBLE_STEP_KEY));
	if (!maxVisibleStepIndex) maxVisibleStepIndex = 0;

	let fistIncompleteStepIndex = savedStep ? savedStep : getFirstIncompleteStep({ config, data });
	if (!fistIncompleteStepIndex) fistIncompleteStepIndex = 0;

	const step =
		// Move to the last step if rejected
		data?.onboardingStatus === "REJECTED" && config?.list?.length > 0
			? config?.list?.length - 1
			: props?.nativeMobile || isDesktopDialog // If mobile or dialogview do not use URL query params
			? stepOverride
			: !!readURLParameters()?.step
			? parseInt(readURLParameters()?.step)
			: isMobile
			? -1
			: -1;

	const switchToStep = (newStep, replace) => {
		onSwitchSteps?.(step, newStep);

		if (props?.nativeMobile || isDesktopDialog) {
			setStepOverride(newStep);
		} else {
			if (newStep >= 0 && maxVisibleStepIndex < newStep) {
				// Saving max index of viewed onboarding step
				localStorage.setItem(_MAX_VISIBLE_STEP_KEY, newStep + "");
			}
			if (replace) {
				history.replace(`${config.path}?step=${newStep}`);
			} else {
				history.push(`${config.path}?step=${newStep}`);
			}
		}
	};

	useEffect(() => {
		if (step === -1 && !props?.nativeMobile && !isMobile) {
			const newStep =
				// Do not skip steps that has not been opened even if they have been completed by default
				maxVisibleStepIndex >= 0 &&
				maxVisibleStepIndex < fistIncompleteStepIndex &&
				data.onboardingStatus === "INCOMPLETE"
					? maxVisibleStepIndex
					: // Otherwise opent first incomplete
					  fistIncompleteStepIndex;

			return switchToStep(newStep);
		}
		// TODO: Maybe we need to improvement this logic
		if (props?.moveLastStep) {
			return switchToStep(config.list.length - 1);
		}
		// eslint-disable-next-line
	}, [data, props?.nativeMobile, props?.moveLastStep, step]);

	// Checing if screen size change to dynamically adjust size of teh table
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !isMobile) {
				setIsMobile(true);
			} else if (window.innerWidth > 960 && isMobile) {
				setIsMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const activeStepConfig = useMemo(() => config.list[step], [config, step]);
	const showSidebar = useMemo(
		() => (isDesktop || step === -1) && !activeStepConfig?.hideSidebar && !config.hideSidebar,
		[activeStepConfig, config, step, isDesktop]
	);

	if (warning) return showWarning(warning, props.title, props.classes, restrictions, resource?.onboarding);

	const height = isDesktopDialog
		? { height: "calc(100vh - 150px)" }
		: isDesktop
		? { height: "calc(100vh - 100px)" }
		: null;
	return (
		<div className="flex flex-row h-full w-full">
			{showSidebar && (
				<div
					className={"flex flex-col h-full w-full " + (!isDesktop ? " absolute pb-64 " : " md:w-3/12 bg-grey-100 ")}
					style={height}
				>
					<div className="flex flex-row w-full overflow-y-scroll overflow-x-hidden pt-20 px-6 md:pt-32 pb-40">
						<OnboardSidebar step={step} config={config} data={data} switchToStep={switchToStep} isMobile={isMobile} />
					</div>
					{!isDesktop && (
						<div className={"fixed bottom-0 bg-grey-100 w-full mt-20 mb-10 p-16"}>
							<Button
								className="min-w-95 w-full"
								variant="contained"
								color="secondary"
								onClick={() => switchToStep(getFirstIncompleteStep({ config, data }))}
							>
								{isComplete({ config, data })
									? "View Status"
									: isStepCompleted({ config, data, step: 0 })
									? "Continue"
									: "Start"}
							</Button>
						</div>
					)}
				</div>
			)}
			{(isDesktop || step !== -1) && (
				<div
					className={
						"flex flex-col " +
						(props?.nativeMobile
							? " w-screen h-screen absolute pb-80 "
							: !isDesktop
							? " w-full absolute pb-80 "
							: showSidebar
							? " md:w-9/12 w-full bg-white "
							: " w-full ")
					}
					style={height}
				>
					<div className="relative flex flex-row flex-1 w-full overflow-y-scroll overflow-x-hidden pt-20 px-6 md:pt-32 pb-20">
						<OnboardContent
							loading={loading}
							step={step}
							config={config}
							data={data}
							onCompleteSteps={onCompleteSteps}
							loadingLastStep={loadingLastStep}
							switchToStep={switchToStep}
							props={{ ...props, isDesktop, isMobile }}
						/>
					</div>
					{!config?.hideFooter && (
						<div
							className={
								!isMobile
									? "flex flex-col"
									: data.isDesktopDialog
									? "bottom-0 fixed"
									: "flex flex-col fixed bottom-0 w-full mt-20"
							}
							style={{ zIndex: 100 }}
						>
							<OnboardFooter
								step={step}
								config={config}
								data={data}
								switchToStep={switchToStep}
								props={{ ...props, isDesktop, isMobile }}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default OnboardSteps;
