import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

/**
 * Starts an application to get a SmartPay Unit account
 * @param {*} ApplicationObject containing {
 *    id: Carrier id to create application for
 * }
 * @returns Application result
 */
const apply = ({ applicantId, config, isAdmin }) => {
	return createRequest()
		.post(
			`api/wallet/${isAdmin ? "admin/" : ""}carrier/${applicantId}/application`,
			{ applicantId, config },
			{ headers: createHeaders() }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Request allowance to re-apply in case of a denied application
 * @param {*} applicantId Carrier id
 * @returns Allowance result
 */
const allowApply = ({ applicantId }) => {
	return createRequest()
		.patch(
			`api/wallet/admin/carrier/${applicantId}/application?allowReApply`,
			{ applicantId },
			{ headers: createHeaders() }
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

const closeSmarthopWallet = ({ entityId }) => {
	return createRequest()
		.patch(`api/wallet/admin/carrier/${entityId}/close`, { entityId }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Gets the current application status or accounts if already exists.
 * @param {*} ApplicationObject containing {
 * 	applicantId: The applicant Id (Carrier _id)
 * }
 * @returns
 */
const getApplicationData = ({ applicantId, isAdmin }) => {
	return createRequest(true)
		.get(`api/wallet/${isAdmin ? "admin/" : ""}application/carrier/${applicantId}`, { headers: createHeaders(true) })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

const getStatementPDF = ({ statementId, isAdmin }) => {
	return createRequest(true)
		.get(`api/wallet/${isAdmin ? "admin/" : ""}statements/${statementId}/pdf`, {
			responseType: "arraybuffer",
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

const getWalletData = ({ entityId, isAdmin }) => {
	return createRequest(true)
		.get(`api/wallet/${isAdmin ? "admin/" : ""}carrier/${entityId}`, { headers: createHeaders(true) })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

const getWalletStatus = async (carrierId) => {
	const url = `api/wallet/status/carriers/${carrierId}`;
	try {
		const res = await createRequest().get(url, { headers: createHeaders() });
		return res.data;
	} catch (err) {
		throwResErrors(err);
	}
};

const performActionToCard = async (cardId, action, body) => {
	const url = `api/wallet/admin/cards/${cardId}/${action}`;
	try {
		return await createRequest().post(url, body, { headers: createHeaders() });
	} catch (err) {
		throwResErrors(err);
	}
};

export const UnitFinanceService = {
	apply,
	allowApply,
	getApplicationData,
	getWalletData,
	getStatementPDF,
	closeSmarthopWallet,
	performActionToCard,
	getWalletStatus,
};
