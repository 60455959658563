import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const popUpsFeatures = createSlice({
	name: "popups",
	initialState,
	reducers: {
		setPopUps: (_state, action) => action.payload,
	},
});

export const { setPopUps } = popUpsFeatures.actions;

export default popUpsFeatures.reducer;
