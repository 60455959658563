const adminAchAccountSummaryForm = {
	urlGET: "billing/admin/carriers/:carrierId/ach-account",
	idKey: "_id",
	triggerEvent: ["paymentRevision", "planRevision"],
	listenEvent: ["paymentRevision", "planRevision", "discountRevision"],
	header: { view: { label: `ACH Account Summary` } },
	content: {
		view: {
			items: [
				{
					type: "section",
					label: "Details",
					translate: "Details",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "accountName",
								type: "text",
								label: "Name",
							},
							{
								key: "accountNumber",
								type: "text",
								label: "Account #",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "routing",
								type: "text",
								label: "Routing #",
							},
							{
								key: "wire_routing",
								type: "text",
								label: "Wire Routing #",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "status",
								type: "text",
								label: "Status",
							},
						],
					},
				},
				{
					type: "section",
					label: "Balance",
					translate: "Balance",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "currentBalance",
								type: "currency",
								label: "Current",
								description: "sdsds",
							},
							{
								key: "availableBalance",
								type: "currency",
								label: "Available",
								description: "sdsds",
							},
						],
					},
				},
			],
		},
	},
};

export default adminAchAccountSummaryForm;
