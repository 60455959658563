import { closeAllDialogs } from "app/store/tools/formDialogSlice";

const switchAccountForm = ({ newFlow }) => {
	const urlPOST = newFlow
		? "api/profile/loginas-subaccount?user=:userId"
		: "api/profile/loginas?user=:userId&type=AS_USER";

	return {
		urlPOST: urlPOST,
		idKey: "_id",
		header: {
			create: newFlow ? { label: "Sub-Account Login" } : { label: "Switch Account" },
		},
		callbacks: {
			onCreate: (data, dispatch) => {
				const previousRole = localStorage.getItem("roleUser");

				// Close all dialogs
				dispatch(closeAllDialogs());

				// Get main account data
				const mainToken = localStorage.getItem("tokenSmarthop");
				const mainUserId = localStorage.getItem("userId");
				const mainCarrierId = localStorage.getItem("carrierId");
				localStorage.clear();
				// Saving main account
				localStorage.setItem("main_tokenSmarthop", mainToken);
				localStorage.setItem("main_userId", mainUserId);
				localStorage.setItem("main_carrierId", mainCarrierId);
				localStorage.setItem("main_location", window.location);

				// Setting sub account info
				localStorage.setItem("tokenSmarthop", data.token);
				localStorage.setItem("userId", data.userId);
				localStorage.setItem("carrierId", data.carrierId);
				// Set type
				const type = newFlow ? "LOGIN" : "SWITCH";
				localStorage.setItem("switchType", type);

				// Reload this page
				if (newFlow) {
					window.location = "/";
				} else {
					if (data?.roles?.[0] + "" !== previousRole && !window.location.href.includes("/profile/details")) {
						window.location = "/";
					} else if (window.location.href.split("?").length > 1) {
						window.location = window.location.href.split("?")[0];
					} else {
						window.location.reload();
					}
				}
			},
		},
		content: {
			create: {
				items: [
					{
						type: "message",
						builder: (item) =>
							newFlow
								? `Would you like to continue and login to this account?`
								: `Would you like continue and switch account?`,
					},
					{
						key: "advance_search_banner",
						type: "banner",
						label: "Page would be automatically reloaded after the successful login",
						banner: {
							icon: "info",
							classes: { icon: "text-blue-600" },
						},
					},
					{
						type: "action",
						action: "SUBMIT",
						label: newFlow ? "Login" : "Switch Account",
						button: { alwaysEnabled: true },
					},
				],
			},
		},
	};
};

export default switchAccountForm;
