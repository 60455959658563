import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const downloadInvoice = (invoice_pdf, number_invoice) => {
	let element = "No Information";
	if (invoice_pdf) {
		element = (
			<Link href={invoice_pdf} target="_blank" rel="noopener noreferrer">
				{`Invoice ${number_invoice}`}
			</Link>
		);
	}
	return (
		<div className="ml-5 w-full">
			<Typography color="primary" className="font-semibold">
				Invoice PDF
			</Typography>
			<Typography color="textSecondary" className="mt-5">
				{element}
			</Typography>
		</div>
	);
};

const adminManualInvoiceForm = {
	urlPOST: "billing/invoices",
	urlGET: "billing/invoices/:id",
	urlPUT: "billing/invoices/:id",
	urlDELETE: "billing/invoices/:id",
	idKey: "_id",
	triggerEvent: "invoiceRevision",
	listenEvent: "invoiceRevision",
	header: {
		create: { label: "Add New Invoice" },
		view: { label: "Invoice Details" },
		edit: { label: "Edit Invoice" },
		delete: { label: "Delete Invoice" },
	},
	messages: {
		delete: { success: "Invoice deleted.", disabled: false },
		create: { success: "Invoice added.", disabled: false },
		edit: { success: "Invoice updated.", disabled: false },
	},
	content: {
		create: { sameAs: "edit" },
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{ key: "description", type: "text", label: "Description" },
							{ key: "footer", type: "text", label: "Footer" },
						],
					},
				},
				{
					key: "download_invoice",
					type: "component",
					builder: (item) => downloadInvoice(item?.invoice_pdf, item?.number_invoice),
				},
				{
					key: "items",
					type: "pattern",
					label: "Items",
					pattern: { classes: { root: "-mt-16", label: "font-semibold" } },
					content: {
						items: [
							{
								type: "group",
								content: {
									items: [
										{ key: "description", type: "text", label: "Description" },
										{
											key: "amount",
											type: "currency",
											label: "Amount",
											translate: "",
										},
									],
								},
							},
						],
					},
				},
			],
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently delete invoice ${item?.number_invoice}?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE" },
						],
					},
				},
			],
		},
		edit: {
			items: [
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					translate: "",
					required: true,
					enabledOnCreateOnly: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						params: {
							sortOrder: "asc",
							sortBy: "carrierid",
							filters: { customer_id: "__NOT_EMPTY__" },
						},
					},
				},
				{
					key: "type",
					type: "select",
					label: "Type",
					required: true,
					enabledOnCreateOnly: true,
					defaultValue: "dispatching",
					options: [
						{ label: "Dispatching", value: "dispatching" },
						{ label: "Fuel", value: "fuel" },
						{ label: "Invoicing", value: "invoicing" },
						{ label: "Other", value: "other" },
					],
				},
				{
					key: "items",
					type: "pattern",
					label: "Items",
					pattern: { classes: { root: "-mt-16", label: "font-semibold" } },
					content: {
						items: [
							{
								type: "group",
								content: {
									items: [
										{ key: "description", type: "text", label: "Description", required: true },
										{ key: "amount", type: "currency", label: "Amount", required: true },
									],
								},
							},
						],
					},
				},
				{
					key: "footer",
					type: "text",
					label: "Footer",
					multiline: { rows: 4 },
					defaultValue: "Stay safe; we'll keep you loaded.",
				},
				{ key: "description", type: "text", label: "Description", multiline: { rows: 4 } },
				{ type: "action", action: "SUBMIT" },
			],
		},
	},
};

export default adminManualInvoiceForm;
