import React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";

// Utils
import { formatCurrency, capitalizeFirstLetter } from "app/main/utils/tableUtils";

// Consts
import { PAYROLL_PAYMENT_TYPE_MILES, PAYROLL_PAYMENT_TYPE_PERCENTAGE } from "app/main/consts";

const checkStyle = {
	color: "text-green-400",
	hoverColor: "text-green-500",
	bgColor: "bg-green-500",
	icon: "check_circle",
};
const warningStyle = {
	color: "text-yellow-800",
	hoverColor: "text-yellow-900",
	bgColor: "bg-yellow-900",
	icon: "report_problem",
};
const errorStyle = {
	color: "text-red-500",
	hoverColor: "text-red-600",
	bgColor: "bg-red-600",
	icon: "error_outline",
};
const payrollStyle = {
	icon: "request_quote",
};
const notRequiredStyle = {
	color: "text-gray-700",
	hoverColor: "text-gray-900",
	icon: "clear",
};
const pendingActionsStyle = {
	...errorStyle,
	icon: "pending_actions",
};
const warningInfo = {
	...warningStyle,
	icon: "info",
};
const moneyOffStyle = {
	...errorStyle,
	icon: "warning",
};

/**
 *
 * @param {String} value Stripe customer id
 * @returns {String} Stripe customer url
 */
const buildStripeUrl = (value) => {
	return `https://dashboard.stripe.com/customers/${value}`;
};

/**
 *
 * @param {String} value
 * @returns
 */
const stripeCustomerLink = (value) => {
	if (!value) return;
	return (
		<div className="w-full">
			<Link href={buildStripeUrl(value)} rel="noopener noreferrer" target="_blank">
				{value}
			</Link>
		</div>
	);
};

/**
 * @param {*} amount
 * @returns
 */
const positiveAndNagativeValue = (amount, showColor = true) => {
	amount = +amount;
	if (isNaN(amount) || amount === null) return "N/A";
	let color = amount > 0 && showColor ? "text-green" : amount < 0 && showColor ? "text-red" : "";
	return (
		<Typography color="primary" className={"font-semibold text-right " + color}>
			{formatCurrency(amount)}
		</Typography>
	);
};

/**
 *
 * @param {*} title
 * @param {*} color
 * @returns
 */
const creditAndDebitAmountFormat = (title, color) => (
	<Typography color="primary" className={"pt-4" + color}>
		{title}
	</Typography>
);

/**
 * @description Apply the styles to the cell of the payroll amount
 * @param {Number} amount payroll amount
 * @returns {Object} payroll cell styles
 */
const payrollFormat = (amount) => {
	let style = {};
	if (!isNaN(+amount) && +amount > 0) {
		style = { color: checkStyle.hoverColor, hoverColor: checkStyle.hoverColor, bgColor: checkStyle.hoverColor };
	} else if (+amount <= 0) {
		style = { color: warningStyle.color, hoverColor: warningStyle.hoverColor, bgColor: warningStyle.hoverColor };
	} else {
		style = { color: errorStyle.color, hoverColor: errorStyle.color, bgColor: errorStyle.color };
	}
	style.icon = payrollStyle.icon;
	style.whitespace = "whitespace-nowrap";
	return style;
};

/**
 * @description Apply the styles to the cell of the payroll amount
 * @param {Number} amount payroll amount
 * @returns {Object} payroll cell styles
 */
const profitFormat = (amount) => {
	let style = {};
	if (!isNaN(+amount) && +amount > 0) {
		style = { color: checkStyle.color, hoverColor: checkStyle.hoverColor, bgColor: checkStyle.hoverColor };
	} else if (+amount <= 0) {
		style = { color: errorStyle.color, hoverColor: errorStyle.hoverColor, bgColor: errorStyle.hoverColor };
	} else {
		style = { color: "", hoverColor: "", bgColor: "" };
	}
	style.icon = payrollStyle.icon;
	return style;
};

/**
 * @description generates a warning/error/check icon if the isAlert flag is valid
 * @param {Boolean} isAlert flag
 * @param {String} msg alert message
 * @returns {Component}
 */
const billingConf = (isAlert, type, msg, label) => {
	if (!isAlert) return;

	let style;
	if (type === "warning") {
		style = warningStyle;
	} else if (type === "error") {
		style = errorStyle;
	} else if (type === "pending_actions") {
		style = pendingActionsStyle;
	} else if (type === "payroll") {
		style = payrollFormat(label);
		label = formatCurrency(label);
	} else if (type === "not_required") {
		style = notRequiredStyle;
	} else if (type === "check") {
		style = checkStyle;
	} else if (type === "warning_info") {
		style = warningInfo;
	} else if (type === "perfomance_profit") {
		style = profitFormat(label);
		label = formatCurrency(label);
	} else if (type === "tonu") {
		style = errorStyle;
		label = "TONU";
	} else if (type === "no_minimum_payment") {
		label = formatCurrency(label);
		style = moneyOffStyle;
	} else {
		return;
	}

	return (
		<div className="flex flex-row items-center pr-4">
			{msg ? (
				<Tooltip
					classes={{ tooltip: "drop-shadow-md " + (style.bgColor ?? "") }}
					title={
						<Typography color="inherit" variant="body2" className="p-6 font-light">
							{msg}
						</Typography>
					}
				>
					<div className="flex flex-row items-center">
						<Icon className={`${style.color} hover:${style.hoverColor}`}>{style.icon}</Icon>
						<Typography
							className={`text-12 ml:text-13 pl-6 ${style.color} hover:${style.hoverColor} ${style.whitespace ?? ""}`}
						>
							{label ?? " Failed"}
						</Typography>
					</div>
				</Tooltip>
			) : (
				<div className="flex flex-row items-center">
					<Icon className={`${style.color} hover:${style.hoverColor}`}>{style.icon}</Icon>
					<Typography
						className={`text-12 ml:text-13 pl-6 ${style.color} hover:${style.hoverColor} ${style.whitespace ?? ""}`}
					>
						{label ?? " Failed"}
					</Typography>
				</div>
			)}
		</div>
	);
};

/**
 * @description Component to display the status of a payment method
 * @param {Boolean} item Is it the default payment method or not
 * @returns status view
 */
const buildSourceStatus = (item) => {
	let style;
	let title;
	if (!item.failed_payment_method) {
		style = { icon: "done", color: "text-green" };
		title = "Chargeable";
	} else {
		style = { icon: "error_outline", color: "text-red" };
		title = item.failed_payment_method_message;
	}

	let tooltipText = title && (
		<Typography className={"text-12 ml:text-13 pl-6 text-white"} color="primary">
			{title}
		</Typography>
	);

	return (
		<>
			{
				<div className="flex flex-row items-center pr-4">
					<Tooltip classes={{ tooltip: "text-12 font-normal" }} title={tooltipText}>
						<div className="flex flex-row items-center">
							<Icon className={`${style.color} m-0 p-0 text-24`}>{style.icon}</Icon>
						</div>
					</Tooltip>
				</div>
			}
			<div className="flex flex-row items-center pr-4">
				<Tooltip
					classes={{ tooltip: "text-12 font-normal" }}
					title={
						item.isDefaultSource
							? "Default is the primary payment method"
							: "Secondary payment methods are only used if default payment method fails"
					}
				>
					<Typography className={`text-12 ml:text-13 pl-6`}>
						{item.isDefaultSource ? " Default" : " Secondary"}
					</Typography>
				</Tooltip>
			</div>
		</>
	);
};

/**
 * @description generates a check icon if the flag is valid
 * @param {Boolean|String} flag flag data
 * @returns {Component}
 */
const checkCircleIcon = (flag) => {
	if (!!flag) {
		return <Icon className="text-20 text-green">check_circle</Icon>;
	} else {
		return "";
	}
};

/**
 * @description Format payroll status
 * @param {String} status payroll status
 * @returns {String}
 */
const formatPayrollStatus = (status) => (status ? capitalizeFirstLetter(status) : "Draft");

const buildPayrollPreviewData = (item, viewId, isRecurringDeductions = false) => {
	let status = item.payroll_data?.metadata?.status;
	let isDraft = !status;
	let isOpen = status === "open";
	let isClosed = status === "closed";
	let isRollback = status === "rollback";
	return {
		viewId,
		dataIds: {
			id: isDraft ? item?._id : item?.payroll_data?.value,
			...(isRecurringDeductions ? { driverName: item?.driver__view?.label } : {}),
			isPayByMiles: item?.driver__view?.metadata?.payment_type === PAYROLL_PAYMENT_TYPE_MILES,
			isDraft,
			isOpen,
			isClosed,
			isRollback,
			isRecurringDeductions,
		},
	};
};

/**
 * @description Returns two flags, if the payment type is PAY_BY_PERCENTAGE and if the payment type is PAY_BY_MILES
 * @param {String} paymentType - Payment type
 * @returns {Object}
 */
const isPayByMilesOrPercentage = (paymentType) => {
	let isPayByPercentage = paymentType === PAYROLL_PAYMENT_TYPE_PERCENTAGE;
	let isPayByMiles = paymentType === PAYROLL_PAYMENT_TYPE_MILES;
	return { isPayByPercentage, isPayByMiles };
};

/**
 * @description Returns not required component
 * @param {String} type - Payment type
 * @returns {Object}
 */
const formatNotRequired = (type) =>
	billingConf(true, "not_required", `Data not required for payment type: ${type}`, "Not Required");

const buildMockStripeComponent = (currency, amount, interval) => {
	return {
		dynamic: true,
		currency,
		amount,
		interval,
		header: (
			<div className="flex flex-col">
				<div className="flex justify-center mb-2 ml:mb-4">
					<Typography variant="h5" color="textSecondary" className="font-semibold">
						{currency}
					</Typography>
					<Typography className="text-44 ml:text-48 mx-4 tracking-tight font-semibold leading-none">
						{amount}
					</Typography>
				</div>
				<Typography color="textSecondary" className="font-medium text-14 ml:text-15 -mt-6 -mb-6">
					{interval}
				</Typography>
			</div>
		),
	};
};

const calculateDiscountAmount = (price, coupon) => {
	if (!coupon || coupon?.length === 0) return null;
	const priceAmount = price.unit_amount;
	const couponAmountOff = coupon?.amount_off;
	const couponPercentageOff = coupon?.percent_off;
	const discountedAmountRate = (100 - coupon?.percent_off) / 100;

	const discountAmountCents = couponAmountOff
		? priceAmount - couponAmountOff
		: couponPercentageOff
		? priceAmount * discountedAmountRate
		: priceAmount;

	return Math.max(discountAmountCents / 100, 0);
};

export {
	buildStripeUrl,
	stripeCustomerLink,
	creditAndDebitAmountFormat,
	positiveAndNagativeValue,
	calculateDiscountAmount,
	billingConf,
	checkCircleIcon,
	formatPayrollStatus,
	buildPayrollPreviewData,
	isPayByMilesOrPercentage,
	formatNotRequired,
	buildSourceStatus,
	buildMockStripeComponent,
};
