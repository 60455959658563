import { useState } from "react";
import { Dialog, DialogContent, IconButton, Icon, Typography, Button, Link } from "@material-ui/core";
import { useEffect } from "react";
import { setTutorialInfo } from "app/services/flagsServices";
import { getProfileId } from "app/services/LoginService";
import { useRef } from "react";
import { useMemo } from "react";
import ReactMarkdown from "react-markdown";

function ReleaseDialog() {
	const [open, setOpen] = useState(false);
	const [newRelease, setNewRelease] = useState(false);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 10) * 10);
	const ranNewReleaseCheck = useRef(false);
	const profileId = useMemo(() => getProfileId(), []);

	useEffect(() => {
		function handleResize() {
			let innerHeight = window.innerHeight;
			innerHeight = parseInt(innerHeight / 10) * 10;
			if (innerHeight !== screenHeight) setScreenHeight(innerHeight);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		// only run one time
		if (!profileId || ranNewReleaseCheck.current) return;
		ranNewReleaseCheck.current = true;

		const lastReleaseSeen = JSON.parse(localStorage.getItem("lastReleaseSeen"));
		const latestRelease = JSON.parse(localStorage.getItem("latestRelease"));

		if (!lastReleaseSeen) {
			setTutorialInfo(getProfileId(), { lastReleaseSeen: latestRelease?.version });
		} else if (lastReleaseSeen < latestRelease.version) {
			setTutorialInfo(getProfileId(), { lastReleaseSeen: latestRelease?.version });
			setNewRelease(latestRelease);
			setOpen(true);
		}
	}, [profileId]);

	const onCloseDialog = () => {
		setOpen(false);
	};

	const parsedRelease = useMemo(() => {
		if (!newRelease?.notes) return null;

		return (
			<div className="flex flex-col">
				<ReactMarkdown
					components={{
						a: (props) => {
							const { href, children, key } = props;

							const handleClick = (link) => {
								const reactNative = window?.ReactNativeWebView ?? false;
								if (reactNative) {
									window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "EXTERNAL_LINK", url: link }));
								} else {
									window.open(link);
								}
							};

							return (
								<Link key={key} className="cursor-pointer" onClick={() => handleClick(href)}>
									{children}
								</Link>
							);
						},
					}}
				>
					{newRelease?.notes}
				</ReactMarkdown>
			</div>
		);
	}, [newRelease]);

	return (
		<Dialog open={open} fullWidth={true} maxWidth={"md"} disableBackdropClick>
			<DialogContent>
				<div className="flex flex-col w-full">
					<div className="flex flex-row w-full items-center border-b-1 pb-16 mb-16">
						<div className="flex flex-row mr-auto">
							<Typography className="text-16 font-semibold">Release Notes V{newRelease.version}</Typography>
						</div>
						<div className="flex flex-row">
							<IconButton aria-label="close" size="small" onClick={() => onCloseDialog()}>
								<Icon className="text-20">close</Icon>
							</IconButton>
						</div>
					</div>
					<div className="flex flex-col">
						<div className="overflow-y-scroll flex w-full" style={{ maxHeight: `${screenHeight - 300}px` }}>
							<div className="flex flex-col justify-center h-full w-full">
								<ul className="list-disc ml-16">{parsedRelease}</ul>
							</div>
						</div>
						<div className="flex flex-row justify-end items-center content-center border-t-1 w-full mt-16 py-16">
							<Button size="large" variant="contained" onClick={() => onCloseDialog()}>
								OK
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default ReleaseDialog;
