import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import { memo, useCallback, useEffect, useRef, useState } from "react";

// Services
import { isCarrier } from "app/services/LoginService";

function LoadStatus(props) {
	const { onComplete, variant, sentUnix, type } = props;
	const [endDate] = useState(moment(props.load?.expiresAt));
	const [sentDate] = useState(sentUnix > 0 ? moment.unix(sentUnix) : null);

	const currDate = moment();
	let diff = endDate.diff(currDate, "seconds");

	const timeLeft = moment.duration(diff, "seconds");
	const diffPassed = currDate.diff(sentDate, "seconds");
	const timePassed = moment.duration(diffPassed, "seconds");

	const [countdown, setCountdown] = useState(
		type === "COUNT_DOWN" || !sentDate
			? {
					days: timeLeft.asDays().toFixed(0),
					hours: timeLeft.hours(),
					minutes: timeLeft.minutes(),
					seconds: timeLeft.seconds(),
					diff: diff,
			  }
			: {
					days: timePassed.asDays().toFixed(0),
					hours: timePassed.hours(),
					minutes: timePassed.minutes(),
					seconds: timePassed.seconds(),
					diff: diff,
			  }
	);
	const intervalRef = useRef();

	const complete = useCallback(() => {
		window.clearInterval(intervalRef.current);
		if (onComplete) {
			onComplete();
		}
	}, [onComplete]);

	const tick = useCallback(() => {
		const currDate = moment();

		let diff = endDate.diff(currDate, "seconds");
		if (diff < 0) {
			complete();
			return;
		}

		if (type === "COUNT_DOWN" || !sentDate) {
			const timeLeft = moment.duration(diff, "seconds");

			setCountdown({
				days: timeLeft.asDays().toFixed(0),
				hours: timeLeft.hours(),
				minutes: timeLeft.minutes(),
				seconds: timeLeft.seconds(),
				diff: diff,
			});
		} else {
			const diffPassed = currDate.diff(sentDate, "seconds");
			const timePassed = moment.duration(diffPassed, "seconds");
			setCountdown({
				days: timePassed.asDays().toFixed(0),
				hours: timePassed.hours(),
				minutes: timePassed.minutes(),
				seconds: timePassed.seconds(),
				diff: diff,
			});
		}
	}, [complete, endDate, sentDate, type]);

	useEffect(() => {
		intervalRef.current = setInterval(tick, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [tick]);

	const getStatusLabel = (label, classes) => {
		return (
			<div className={"inline font-semibold rounded-full truncate text-12 md:text-13 py-4 px-4 px-10 " + classes}>
				{label}
			</div>
		);
	};

	let status = props.load?.status ?? props.load?.responseType;

	if (
		status === "ACCEPTED" ||
		status === "COUNTER_OFFER_ACCEPTED" ||
		status === "COUNTER_OFFER_NEW" ||
		status === "IN_PROCESS" ||
		status === "IN_PROCESS_BOOK" ||
		status === "IN_PROCESS_BID" ||
		status === "VALIDATING" ||
		status === "PENDING_VALIDATE" ||
		status === "CONFIRMED" ||
		status === "BROKER_COUNTER_OFFER_NEW" ||
		status === "BROKER_COUNTER_OFFER_FINAL" ||
		status === "BROKER_COUNTER_OFFER_ACCEPTED"
	) {
		let text =
			status === "COUNTER_OFFER_ACCEPTED"
				? "Bid Accepted"
				: status === "COUNTER_OFFER_NEW"
				? "Rate Increased"
				: status === "IN_PROCESS" || status === "IN_PROCESS_BOOK"
				? "Booking"
				: status === "IN_PROCESS_BID"
				? "Offer"
				: status === "VALIDATING"
				? "Validating"
				: status === "PENDING_VALIDATE"
				? "Pending Validation"
				: status === "CONFIRMED"
				? "Confirmed"
				: status === "BROKER_COUNTER_OFFER_NEW" || status === "BROKER_COUNTER_OFFER_FINAL"
				? "Counteroffer"
				: "Accepted";
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel(text, "bg-green text-white")}
			</div>
		);
	} else if (
		status === "COVERED" ||
		status === "REJECTED" ||
		status === "OUTDATED" ||
		status === "COUNTER_OFFER_REJECTED" ||
		status === "EXPIRED" ||
		status === "NOT_AVAILABLE" ||
		status === "FAILED"
	) {
		let text =
			status === "EXPIRED"
				? "Expired"
				: status === "NOT_AVAILABLE"
				? "Not available"
				: status === "COUNTER_OFFER_REJECTED"
				? "Bid Rejected"
				: status === "OUTDATED"
				? "Outdated"
				: status === "COVERED"
				? "Covered"
				: status === "FAILED"
				? "Failed"
				: "Rejected";
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel(text, "bg-red-500 text-white")}
			</div>
		);
	} else if (status === "CANCELED" || status === "Cancelled" || status === "REMINDER" || status === "FREE_TRIAL") {
		const statusDescription = {
			REMINDER: { text: "Reminder" },
			FREE_TRIAL: !isCarrier() ? { text: "Free Trial", bgColor: "bg-orange" } : { bgColor: "hidden" },
			CANCELED: { text: "Cancelled" },
			Cancelled: { text: "Cancelled" },
		};
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel(
					statusDescription[status]?.text,
					`${statusDescription[status]?.bgColor ?? "bg-orange-700"} text-white`
				)}
			</div>
		);
	} else if (
		status === "REPLIED" ||
		status === "NEW_PRICE_PROCESSED" ||
		status === "COUNTER_OFFER_PROCESSED" ||
		status === "COUNTER_OFFER" ||
		status === "BOOKED" ||
		status === "BIDDED" ||
		status === "STARTED"
	) {
		let text =
			status === "COUNTER_OFFER"
				? "Bid Placed"
				: status === "BOOKED"
				? "Booked"
				: status === "BIDDED"
				? "Offer Accepted"
				: status === "NEW_PRICE_PROCESSED"
				? "New rate requested"
				: status === "REPLIED"
				? "Replied"
				: status === "STARTED"
				? "New"
				: "Bid Processed";
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel(text, "bg-blue-700 text-white")}
			</div>
		);
	} else if (status === "Dispatched") {
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel(status, "bg-green-700 text-white")}
			</div>
		);
	} else if (!!status) {
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel(status, "bg-red-500 text-white")}
			</div>
		);
	} else if (!props.load?.expiresAt) {
		// No expiration date specified for this message
		return null;
	} else if (diff <= 0) {
		return (
			<div className={clsx("flex items-center h-20", props.className)}>
				{getStatusLabel("Expired", "bg-red-500 text-white")}
			</div>
		);
	}

	let timeColor;
	let unitColor;
	if (type === "COUNT_DOWN" || !sentDate) {
		timeColor = variant === "dark" ? "text-white" : diff < 60 ? "text-red-500 font-semibold" : "text-grey-900";
		unitColor = variant === "dark" ? "text-white" : diff < 60 ? "text-red-400" : "text-grey-500";
	} else {
		timeColor = variant === "dark" ? "text-white" : diffPassed > 900 ? "text-red-500 font-semibold" : "text-grey-900";
		unitColor = variant === "dark" ? "text-white" : diffPassed > 900 ? "text-red-400" : "text-grey-500";
	}

	return (
		<div className={clsx("flex items-center h-20", props.className)}>
			{countdown.days > 0 && (
				<div className="flex flex-col items-center justify-center px-6">
					<Typography className={clsx("text-13 md:text-14", timeColor)}>{countdown.days}</Typography>
					<Typography className={clsx("-mt-6 text-11", unitColor)} variant="caption">
						days
					</Typography>
				</div>
			)}
			{countdown.hours > 0 && (
				<div className="flex flex-col items-center justify-center px-6">
					<Typography className={clsx("text-13 md:text-14", timeColor)}>{countdown.hours}</Typography>
					<Typography className={clsx("-mt-6 text-11", unitColor)} variant="caption">
						hours
					</Typography>
				</div>
			)}
			<div className="flex flex-col items-center justify-center px-6">
				<Typography className={clsx("text-13 md:text-14", timeColor)}>{countdown.minutes}</Typography>
				<Typography className={clsx("-mt-6 text-11", unitColor)} variant="caption">
					min
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-6">
				<Typography className={clsx("text-13 md:text-14", timeColor)}>{countdown.seconds}</Typography>
				<Typography className={clsx("-mt-6 text-11", unitColor)} variant="caption">
					sec
				</Typography>
			</div>
		</div>
	);
}

export default memo(LoadStatus);
