import { incrementDataRevision } from "app/store/tools/revisionSlice";

const unpostTruck = {
	urlGET: "api/post-truck/:id",
	urlDELETE: "api/post-truck/:id",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	header: { delete: { label: "Unpost truck" } },
	messages: { delete: { success: (data) => data?.message } },
	content: {
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => `Would you like to permanently unpost the truck?`,
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE", label: "Unpost" },
						],
					},
				},
			],
			onFinished: (_data, dispatch) => {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			},
			onError: (errors, dispatch, snackbar) => {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			},
		},
	},
};

export default unpostTruck;
