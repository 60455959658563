// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { stripeCustomerLink, billingConf, buildStripeUrl } from "app/main/utils/billingUtils";

const unpaid_invoices = {
	urlGET: "billing/unpaid-invoices",
	idKey: "_id",
	listenEvent: "",
	segmentKey: "ADMIN_UNPAID_INVOICES",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["arrear_date_since", "count_invoices", "total_amount_due"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "arrear_date_since",
				dir: "asc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					translate: "",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						params: {
							sortOrder: "asc",
							sortBy: "carrierid",
							filters: { customer_id: "__NOT_EMPTY__" },
						},
					},
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{
				type: "component",
				key: "unpaid_alert",
				label: "Alert",
				translate: "",
				builder: (item) =>
					billingConf(item?.alerts, "warning", "This carrier has reported failures in their payment methods."),
			},
			{
				key: "arrear_date_since",
				type: "date",
				label: "Arrear Since",
				translate: "",
			},
			{
				key: "_id",
				type: "id",
				label: "Carrier ID",
				grid: { headerSubtitle: true, valuePefix: "ID " },
				group: "internal",
			},
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showId: true,
				},
				grid: { headerTitle: true, valuePefix: "Carrier " },
			},
			{
				key: "count_invoices",
				type: "text",
				label: "# Invoices Due",
				translate: "",
			},
			{
				key: "total_amount_due",
				type: "text",
				label: "Total Amount Due",
				translate: "",
				builder: (item) => formatCurrency(item?.total_amount_due),
			},
			{
				key: "customer",
				type: "component",
				label: "Stripe Customer",
				translate: "",
				builder: (item) => stripeCustomerLink(item?.customer),
				click: {
					label: "Go to Stripe Customer",
					redirectURLBuilder: (item) => buildStripeUrl(item?.customer),
					newTab: true,
					mobile: {
						replaceField: true,
					},
				},
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "view-declined-transactions",
						label: "Declined Transactions",
						translate: "",
						newTab: true,
						redirectURLBuilder: (item) => "/profile/carriers/" + item?._id + "/declined-transactions",
					},
					{
						key: "carrier-payment-methods",
						label: "Payment Methods",
						translate: "",
						newTab: true,
						redirectURLBuilder: (item) => "/billing/carriers/" + item?._id + "/payments",
					},
				],
			},
		],
	},
};

export default unpaid_invoices;
